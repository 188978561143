const Library_mobile_sv = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Drivs av en [cpuDisplay] processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Drivs av en [cpuDisplay] processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Precis som en hjärna styr processorn vad datorn gör. Det innebär att de videor du tittar på, spelen du spelar, de webbplatser du besöker – alla börjar med din processor.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Processorn är din dators hjärna"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Prestanda och värde.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Silver processor",
                    "tr_subtitle": "Utforska din digitala värld med prestanda som gör det enkelt att komma åt filer från webben. Enkel multitasking genom att enkelt växla mellan program och få mer gjort på mindre tid.",
                    "tr_gen_core_title": "Intel® Pentium® Silver processor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Gold processor",
                    "tr_subtitle": "Förbättrad produktivitet – förhöjd prestanda för att klara dina mest krävande dagar. Effektiviserad webbsurfning – ladda snabbt innehållsrika webbsidor för att utforska mer och vänta mindre.",
                    "tr_gen_core_title": "Intel® Pentium® Gold processor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Celeron® processor",
                    "tr_subtitle": "Prestanda som ger dig mer kraft för jobb och nöje. Njut av filmer och videor online med fantastisk detaljrikedom. Spendera mer tid på att göra och mindre tid på att vänta.",
                    "tr_gen_core_title": "Intel® Celeron® processor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® processor",
                    "tr_subtitle": "Byggd för fantastisk underhållning, videoströmning och produktivitet. Njut av filmer och videor online med fantastisk detaljrikedom. Spendera mer tid på att göra och mindre tid på att vänta.",
                    "tr_gen_core_title": "Intel® Pentium® processor"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Den prestanda du behöver. Till ett överkomligt pris.",
                "tr_title": "Intel® Processor",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Värde i en egen kategori",
                    "tr_subtitle": "En Intel® Processor kan hantera nästan alla uppgifter du behöver, till ett pris du har råd med. Navigera enkelt från ett program till ett annat, eller njut av högkvalitativ grafik med stadig prestanda.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Ger starkare anslutningar",
                    "tr_subtitle": "Mer levande videokvalitet, förbättrad ljudklarhet och nästan tre gånger snabbare internetanslutning gör att du tryggt kan samarbeta var du än befinner dig.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Skillnaden är som dag och natt",
                    "tr_subtitle": "Från filmmaraton på morgonen till sista-minuten-finputsningar, en Intel®&nbsp;Processor förlänger din enhets batteritid för fullständigt uppslukande och oavbrutna upplevelser.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Varje miljö är en lärande miljö",
                    "tr_subtitle": "Kör flera inlärningsverktyg online med lätthet från varje hörn av ditt hem."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Lär dig var du vill",
                    "tr_subtitle": "Avsluta lektioner när som helst medan du kör olika e&#8209;inlärningsverktyg samtidigt."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Få kraften för underhållning och produktivitet som passar din budget och livsstil.",
                "tr_title": "Intels prestanda för allas budget.",
                "tr_gen_core_title": "Intel® Celeron® processor",
                "processorCompare": {
                  "tr_title": "HITTA RÄTT NIVÅ AV PRESTANDA OCH PRIS",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Intel® Celeron® processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Snabb, mediarik webbsurfning",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Enkelt att växla mellan program",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Vardagsspelande",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömma 4K-videor",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Lätt foto- och videoredigering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Snabb produktivitet med intelligent prestanda",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Drivs av en 7:e generationens Intel®&nbsp;Core™&nbsp;[cpu_fam] processor"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Drivs av en 6:e generationens Intel®&nbsp;Core™&nbsp;[cpu_fam] processor"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Drivs av en 5:e generationens Intel®&nbsp;Core™&nbsp;[cpu_fam] processor"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Drivs av en 4:e generationens Intel®&nbsp;Core™&nbsp;[cpu_fam] processor"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Drivs av en 3:e generationens Intel®&nbsp;Core™&nbsp;[cpu_fam] processor"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Drivs av en 2:a generationens Intel®&nbsp;Core™&nbsp;[cpu_fam] processor"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Drivs av en 1:a generationens Intel®&nbsp;Core™&nbsp;[cpu_fam] processor"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Rekommenderas för:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Drivs av en 8:e generationens Intel® Core™ i3 processor",
                "tr_gen_core_title": "8:e gen:s Intel® Core™ i3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9:e gen:s Intel® Core™ processorer skapades för att hålla jämna steg med din digitala värld. Tack vare kombinationen av hastighet och prestanda kan du göra mer av det du gillar med datorn utan att behöva bli frustrerad.",
                "tr_title": "Drivs av en 9:e generationens Intel®&nbsp;Core™&nbsp;i3 processor",
                "tr_gen_core_title": "9:e gen:s Intel® Core™ i3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Mångsidig prestanda och generös batteritid som håller dig igång och tar dig vart du vill.",
                "tr_title": "Kraft som räcker hela dagen",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i3 processor",
                "processorCompare": {
                  "tr_title": "HITTA RÄTT NIVÅ AV 10:E GEN:S PRESTANDA",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Strömma filmer i 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Snabb och pålitlig anslutning",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bildmaskering och videofiltrering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spel i 1080p med smidiga bildsekvenser",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bäst för arbete med stora filer och kreativa program",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Gå enkelt från att strömma filmer till att skapa presentationer med gott om prestanda över.",
                "tr_title": "Kraft som räcker hela dagen",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i3 processor"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "En bärbar dator som är utvecklad särskilt för de som alltid är på språng.",
                "tr_title": "Möt en ny typ av bärbara datorer",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11:e gen:s Intel® Core™ i3 processorn är specifikt utformad för bärbara datorer och låter dig göra mer än någonsin var du än befinner dig.",
                "tr_title": "Få ut mer av din nya bärbara dator",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i3 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i3 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "När hela din familj använder en stationär dator för allt de gör behöver du kraften och mångsidigheten hos en 11:e gen:s Intel®&nbsp;Core™&nbsp;i3 processor.",
                "tr_title": "Var redo för allt med en ny nivå av prestanda.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i3 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i3 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Drivs av en 8:e generationens Intel®&nbsp;Core™&nbsp;i5 processor",
                "tr_gen_core_title": "8:e gen:s Intel® Core™ i5 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9:e gen:s Intel® Core™ processorer skapades för att hålla jämna steg med din digitala värld. Tack vare kombinationen av hastighet och prestanda kan du göra mer av det du gillar med datorn utan att behöva bli frustrerad.",
                "tr_title": "Drivs av en 9:e generationens Intel®&nbsp;Core™&nbsp;i5 processor",
                "tr_gen_core_title": "9:e gen:s Intel® Core™ i5 processor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "SPEL OCH VR BUFFERFRITT MED DE MEST KRÄVANDE TITLARNA",
                "tr_title": "Spel på allvar börjar här",
                "tr_gen_core_title": "9:e gen:s Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "UPPTÄCK VILKEN INTEL® CORE™ PROCESSOR SOM PASSAR DITT SÄTT ATT SPELA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-spel",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tävlingsbetonade spel",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spela, strömma och spela in utan kompromisser",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bästa 9:e gen:s Intel® Core™ processorn för spel",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 4<br/>Trådar: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Du kan enkelt växla mellan flera program och vara redo för vad som helst, oavsett var du befinner dig.",
                "tr_title": "Multitasking på språng",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i5 processor",
                "processorCompare": {
                  "tr_title": "HITTA RÄTT NIVÅ AV 10:E GEN:S PRESTANDA",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processor"
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Strömma filmer i 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Snabb och pålitlig anslutning",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bildmaskering och videofiltrering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spel i 1080p med smidiga bildsekvenser",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bäst för arbete med stora filer och kreativa program",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Höj dig i allt du gör från underhållning, produktivitet och innehållsskapande.",
                "tr_title": "Uppgradera till en mer kraftfull dator",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i5 processor"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "En bärbar dator som är utvecklad särskilt för de som alltid är på språng.",
                "tr_title": "Möt en ny typ av bärbara datorer",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i5 processor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Byggd för sömlös strömning och AAA-spelande",
                "tr_title": "Spela på dina villkor",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "UPPTÄCK EN 10:E GEN:S INTEL® CORE™ PROCESSOR SOM PASSAR DITT SÄTT ATT SPELA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sömlöst AAA-spelande",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tävlingsbetonade spel",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning och inspelning",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avancerad medieproduktion",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximal spelprestanda",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Olåst och överklockbar<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Byggd för sömlös strömning och AAA-spelande",
                "tr_title": "Spela på dina villkor",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "UPPTÄCK EN 10:E GEN:S INTEL® CORE™ PROCESSOR SOM PASSAR DITT SÄTT ATT SPELA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sömlöst AAA-spelande",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tävlingsbetonade spel",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning och inspelning",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avancerad medieproduktion",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximal spelprestanda",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Olåst och överklockbar<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 10<br/>Trådar: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Du kan enkelt växla mellan flera program och vara redo för vad som helst, oavsett var du befinner dig.",
                "tr_title": "Multitasking på språng",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "10:E GEN:S INTEL® CORE™ i5 PROCESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11:e gen:s Intel® Core™ i5 processorn ger livfulla visuella upplevelser och omfattande produktivitet till de tunnaste och lättaste datorerna.",
                "tr_title": "Imponerande bärbar datorkraft",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "När hela din familj använder en stationär dator för allt de gör behöver du kraften och mångsidigheten hos en 11:e gen:s Intel®&nbsp;Core™&nbsp;i5 processor.",
                "tr_title": "Var redo för allt med en ny nivå av prestanda.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11:e gen:s Intel® Core™ i5 processorn ger livfulla visuella upplevelser och bred produktivitet till tunna och lätta bärbara datorer.",
                "tr_title": "Imponerande bärbar datorkraft",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i5 PROCESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Drivs av en 8:e generationens Intel®&nbsp;Core™&nbsp;i7 processor",
                "tr_gen_core_title": "8:e gen:s Intel® Core™ i7 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "9:e gen:s Intel® Core™ processorer skapades för att hålla jämna steg med din digitala värld. Tack vare kombinationen av hastighet och prestanda kan du göra mer av det du gillar med datorn utan att behöva bli frustrerad.",
                "tr_title": "Drivs av en 9:e generationens Intel®&nbsp;Core™&nbsp;i7 processor",
                "tr_gen_core_title": "9:e gen:s Intel® Core™ i7 processor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "UPP TILL 32 % HÖGRE FPS när du spelar spel, strömmar och spelar in vs. en DATOR SOM ÄR 3 ÅR GAMMAL<sup>3</sup>",
                "tr_title": "Kraften att dela dina bästa spelögonblick",
                "tr_gen_core_title": "9:e gen:s Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "UPPTÄCK VILKEN INTEL® CORE™ PROCESSOR SOM PASSAR DITT SÄTT ATT SPELA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-spel",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tävlingsbetonade spel",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spela, strömma och spela in utan kompromisser",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bästa 9:e gen:s Intel® Core™ processorn för spel",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 4<br/>Trådar: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Den här slimmade enheten är utvecklad för effektivitet och kan ha en enorm inverkan på hur du skapar, ansluter och strömmar.",
                "tr_title": "Avancerad prestanda överallt",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i7 processor",
                "processorCompare": {
                  "tr_title": "HITTA RÄTT NIVÅ AV 10:E GEN:S PRESTANDA",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Strömma filmer i 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Snabb och pålitlig anslutning",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bildmaskering och videofiltrering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spel i 1080p med smidiga bildsekvenser",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bäst för arbete med stora filer och kreativa program",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Avancerad prestanda för att ta dig till nästa nivå i allt du gör.",
                "tr_title": "Kraft att göra allt",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i7 processor"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "En bärbar dator som är utvecklad särskilt för de som alltid är på språng.",
                "tr_title": "Möt en ny typ av bärbara datorer",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i7 processor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Spel, strömning och inspelning med konkurrensfördel",
                "tr_title": "Öka kraften i din spelvärld",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "UPPTÄCK EN 10:E GEN:S INTEL® CORE™ PROCESSOR SOM PASSAR DITT SÄTT ATT SPELA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sömlöst AAA-spelande",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tävlingsbetonade spel",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning och inspelning",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avancerad medieproduktion",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximal spelprestanda",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Olåst och överklockbar<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Spel, strömning och inspelning med konkurrensfördel",
                "tr_title": "Öka kraften i din spelvärld",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "UPPTÄCK EN 10:E GEN:S INTEL® CORE™ PROCESSOR SOM PASSAR DITT SÄTT ATT SPELA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sömlöst AAA-spelande",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tävlingsbetonade spel",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning och inspelning",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avancerad medieproduktion",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximal spelprestanda",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Olåst och överklockbar<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 10<br/>Trådar: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Utvecklad för effektivitet – den här eleganta enheten har en enorm inverkan på det sätt du skapar, ansluter och strömmar.",
                "tr_title": "Högsta prestanda, överallt",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "10:E GEN:S INTEL® CORE™ i7 PROCESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11:e gen:s Intel® Core™ i7 processorn ger ultraportabla datorer avancerad medieproduktion, smidigt spelande och en ny nivå av underhållning.",
                "tr_title": "En ny nivå av bärbar datorprestanda",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Gör din stationära dator till destinationen för allt ditt hem kan behöva med en ny nivå av prestanda med en 11:e gen:s Intel® Core™ i7 processor",
                "tr_title": "Jobba. Lär dig mer. Spela. Utan gränser.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11:e gen:s Intel® Core™ i7 processorn ger tunna och lätta bärbara datorer avancerad medieproduktion, smidigt spelande och en ny nivå av underhållning.",
                "tr_title": "En ny nivå av bärbar datorprestanda",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i7 PROCESSOR"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Gör din stationära dator till destinationen för allt ditt hem kan behöva med en ny nivå av prestanda med en 11:e gen:s Intel® Core™ i7 processor",
                "tr_title": "Skapa utan gränser",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i7 PROCESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med avancerad medieproduktion, smidigt spelande och en ny nivå av underhållning på tunna och lätta bärbara datorer blev den här 11:e gen:s Intel® Core™ i7 processorn precis bättre.",
                "tr_title": "En ny nivå av bärbar datorprestanda",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i7 PROCESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Drivs av en 8:e generationens Intel®&nbsp;Core™&nbsp;i9 processor",
                "tr_gen_core_title": "8:e gen:s Intel® Core™ i9 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9:e gen:s Intel® Core™ processorer skapades för att hålla jämna steg med din digitala värld. Tack vare kombinationen av hastighet och prestanda kan du göra mer av det du gillar med datorn utan att behöva bli frustrerad.",
                "tr_title": "Drivs av en 9:e generationens Intel®&nbsp;Core™&nbsp;i9 processor",
                "tr_gen_core_title": "9:e gen:s Intel® Core™ i9 processor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "UPP TILL 41 % HÖGRE FPS när du spelar spel, strömmar och spelar in vs. en DATOR SOM ÄR 3 ÅR GAMMAL<sup>4</sup>",
                "tr_title": "Spelare kräver. Intel® Core™ i9 levererar.",
                "tr_gen_core_title": "9:e gen:s Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "UPPTÄCK VILKEN INTEL® CORE™ PROCESSOR SOM PASSAR DITT SÄTT ATT SPELA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-spel",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tävlingsbetonade spel",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spela, strömma och spela in utan kompromisser",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bästa 9:e gen:s Intel® Core™ processorn för spel",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 4<br/>Trådar: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Öka din potential med den ultimata spelupplevelsen",
                "tr_title": "Konkurrera på högsta nivå",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "UPPTÄCK EN 10:E GEN:S INTEL® CORE™ PROCESSOR SOM PASSAR DITT SÄTT ATT SPELA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sömlöst AAA-spelande",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tävlingsbetonade spel",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning och inspelning",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avancerad medieproduktion",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximal spelprestanda",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Olåst och överklockbar<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Öka din potential med den ultimata spelupplevelsen",
                "tr_title": "Konkurrera på högsta nivå",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "UPPTÄCK EN 10:E GEN:S INTEL® CORE™ PROCESSOR SOM PASSAR DITT SÄTT ATT SPELA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sömlöst AAA-spelande",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tävlingsbetonade spel",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning och inspelning",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avancerad medieproduktion",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximal spelprestanda",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Olåst och överklockbar<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 10<br/>Trådar: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Öka din potential med den ultimata skaparupplevelsen",
                "tr_title": "Skapa på den högsta nivån",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "10:E GEN:S INTEL® CORE™ i9 PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ bärbara datorer som drivs med en 11:e gen:s Intel® Core™ i5 processor och Intel­® Iris® X<sup class='small_text_sup_xe'>e</sup> grafik ger dig otrolig hastighet och prestanda, allt i en tunn och lätt design.",
                "tr_title": "Förhöjd kraft för en utvecklad bärbar dator",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ bärbara datorer som drivs med en 11:e gen:s Intel® Core™ i7 processor och Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafik ger dig avancerad hastighet och prestanda för en förstklassig mobil upplevelse.",
                "tr_title": "Avancerad kraft för en utvecklad bärbar dator",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Njut av en balans mellan prestanda-, media- och anslutningsupplevelser till ett fantastiskt pris.",
                "tr_title": "Prestanda och anslutning till ett fantastiskt pris.",
                "tr_gen_core_title": "Intel® Pentium® Silver processor",
                "processorCompare": {
                  "tr_title": "HITTA RÄTT NIVÅ AV PRESTANDA OCH PRIS",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Snabb, mediarik webbsurfning",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Enkelt att växla mellan program",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Vardagsspelande",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömma 4K-videor",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Lätt foto- och videoredigering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Snabb produktivitet med intelligent prestanda",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Njut av sömlös multitasking, enkel foto- och videoredigering, 4K-videor och levande grafik till ett fantastiskt pris.",
                "tr_title": "Imponerande prestanda för både nytta och nöje.",
                "tr_gen_core_title": "Intel® Pentium® Gold processor",
                "processorCompare": {
                  "tr_title": "HITTA RÄTT NIVÅ AV PRESTANDA OCH PRIS",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver processor"
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Snabb, mediarik webbsurfning",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Enkelt att växla mellan program",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Vardagsspelande",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömma 4K-videor",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Lätt foto- och videoredigering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Snabb produktivitet med intelligent prestanda",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "AAA-spel och lång batteritid i en ultraportabel dator. Du behöver inte kompromissa hur du spelar.",
                "tr_title": "Du behöver inte kompromissa.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i5 processor",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "HITTA DIN INTEL® CORE™ ULTRAPORTABLA SPELPROCESSOR.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11:e gen:s Intel® Core™ i5-11300H processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11:e gen:s Intel® Core™ i7-11370H processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11:e gen:s Intel® Core™ i7-11375H SE processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Upp till <span class='style_badge'>4,4&nbsp;GHz</span>&nbsp;max klock-hastighet",
                      "tr_td2_title": "Upp till <span class='style_badge'>4,8&nbsp;GHz</span>&nbsp;max klock-hastighet",
                      "tr_td3_title": "Upp till <span class='style_badge'>5,0&nbsp;GHz</span>&nbsp;max klock-hastighet"
                    },
                    {
                      "tr_td1_title": "4 kärnor/ 8&nbsp;trådar",
                      "tr_td2_title": "4 kärnor/ 8&nbsp;trådar",
                      "tr_td3_title": "4 kärnor/ 8&nbsp;trådar"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost-teknologi",
                      "tr_td2_title": "Intel® Turbo Boost-teknologi",
                      "tr_td3_title": "Intel® Turbo Boost Max teknologi 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® Smart Cache",
                      "tr_td2_title": "12 MB Intel® Smart Cache",
                      "tr_td3_title": "12 MB Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafik",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafik",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafik"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "AAA-spel i 1080p med hög FPS på de bästa inställningarna och utmärkt prestanda när du är urkopplad i en ultraportabel dator.",
                "tr_title": "Varsågod. Få allt.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i7 processor",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "HITTA DIN INTEL® CORE™ ULTRAPORTABLA SPELPROCESSOR.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11:e gen:s Intel® Core™ i5-11300H processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11:e gen:s Intel® Core™ i7-11370H processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11:e gen:s Intel® Core™ i7-11375H SE processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Upp till <span class='style_badge'>4,4&nbsp;GHz</span>&nbsp;max klock-hastighet",
                      "tr_td2_title": "Upp till <span class='style_badge'>4,8&nbsp;GHz</span>&nbsp;max klock-hastighet",
                      "tr_td3_title": "Upp till <span class='style_badge'>5,0&nbsp;GHz</span>&nbsp;max klock-hastighet"
                    },
                    {
                      "tr_td1_title": "4 kärnor/ 8&nbsp;trådar",
                      "tr_td2_title": "4 kärnor/ 8&nbsp;trådar",
                      "tr_td3_title": "4 kärnor/ 8&nbsp;trådar"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost-teknologi",
                      "tr_td2_title": "Intel® Turbo Boost-teknologi",
                      "tr_td3_title": "Intel® Turbo Boost Max teknologi 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® Smart Cache",
                      "tr_td2_title": "12 MB Intel® Smart Cache",
                      "tr_td3_title": "12 MB Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafik",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafik",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafik"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Upp till 5,0 GHz max klockhastighet för att förhöja dina spel och en ultraportabel dator för att ta med dem vart du än går.",
                "tr_title": "Mer kraft per krona.",
                "tr_gen_core_title": "11:e gen:s Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition processor",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition processor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "HITTA DIN INTEL® CORE™ ULTRAPORTABLA SPELPROCESSOR.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11:e gen:s Intel® Core™ i5-11300H processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11:e gen:s Intel® Core™ i7-11370H processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11:e gen:s Intel® Core™ i7-11375H SE processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Upp till <span class='style_badge'>4,4&nbsp;GHz</span>&nbsp;max klock-hastighet",
                      "tr_td2_title": "Upp till <span class='style_badge'>4,8&nbsp;GHz</span>&nbsp;max klock-hastighet",
                      "tr_td3_title": "Upp till <span class='style_badge'>5,0&nbsp;GHz</span>&nbsp;max klock-hastighet"
                    },
                    {
                      "tr_td1_title": "4 kärnor/ 8&nbsp;trådar",
                      "tr_td2_title": "4 kärnor/ 8&nbsp;trådar",
                      "tr_td3_title": "4 kärnor/ 8&nbsp;trådar"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost-teknologi",
                      "tr_td2_title": "Intel® Turbo Boost-teknologi",
                      "tr_td3_title": "Intel® Turbo Boost Max teknologi 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® Smart Cache",
                      "tr_td2_title": "12 MB Intel® Smart Cache",
                      "tr_td3_title": "12 MB Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafik",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafik",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafik"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11:e gen:s Intel® Core™ i7 Special Edition processorn ger avancerad medieproduktion, bufferfritt spelande och en ny nivå av underhållning till ultrabärbara datorer.",
                "tr_title": "En ny nivå av bärbar datorprestanda",
                "tr_gen_core_title": "11:e gen:s Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i7 SPECIAL EDITION PROCESSOR"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11:e gen:s Intel® Core™ i7 Special Edition processorn ger avancerad medieproduktion, bufferfritt spelande och en ny nivå av underhållning till ultrabärbara datorer.",
                "tr_title": "En ny nivå av bärbar datorprestanda",
                "tr_gen_core_title": "11:e gen:s Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i7 SPECIAL EDITION PROCESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Snabba klockhastigheter och flera kärnor som fungerar sömlöst tillsammans för att få ut mer av tävlingsspelande och avancerad medieproduktion.",
                "tr_title": "Massiv kraft. Perfekt balanserad.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i9 processor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Med rätt balans mellan klockhastighet och kärnprestanda får du ut mer av dina spel, oavsett om det gäller tävlingsinriktade spel med hög FPS eller uppslukande AAA-upplevelser.",
                "tr_title": "Kraft med ett syfte.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i5 processor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Spela, spela in och strömma krävande titlar med hög FPS och växla enkelt till tunga arbetsbelastningar med multitasking.",
                "tr_title": "Kraft att utöka gränserna.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i7 processor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Snabba klockhastigheter och flera kärnor som fungerar sömlöst tillsammans för att få ut mer av tävlingsspelande och avancerad medieproduktion.",
                "tr_title": "Massiv kraft. Perfekt balanserad.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i9 processor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Gå enkelt från att strömma filmer till att skapa presentationer med gott om prestanda över.",
                "tr_title": "Kraft som räcker hela dagen",
                "tr_gen_core_title": "Intel® Core™ i3 processor med Intel® hybridteknologi",
                "tr_subTitle2": "Intel® Core™ i3 processor med Intel® hybridteknologi"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Mångsidig prestanda och generös batteritid som håller dig igång och tar dig vart du vill.",
                "tr_title": "Kraft som räcker hela dagen",
                "tr_gen_core_title": "Intel® Core™ i3 processor med Intel® hybridteknologi",
                "tr_subTitle2": "Intel® Core™ i3 processor med Intel® hybridteknologi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Du kan enkelt växla mellan flera program och vara redo för vad som helst, oavsett var du befinner dig.",
                "tr_title": "Multitasking på språng",
                "tr_gen_core_title": "Intel® Core™ i5 processor med Intel® hybridteknologi",
                "tr_subTitle2": "Intel® Core™ i5 processor med Intel® hybridteknologi"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Höj dig i allt du gör från underhållning, produktivitet och innehållsskapande.",
                "tr_title": "Uppgradera till en mer kraftfull dator",
                "tr_gen_core_title": "Intel® Core™ i5 processor med Intel® hybridteknologi",
                "tr_subTitle2": "Intel® Core™ i5 processor med Intel® hybridteknologi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Spelprestanda i stationär klass möter mobilitet, oavsett om det är för tävlingsspelande med hög FPS eller uppslukande AAA-upplevelser.",
                "tr_title": "Kraft med ett syfte.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i5 processor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Spelprestanda i stationär klass möter mobilitet, oavsett om det är för tävlingsspelande med hög FPS eller uppslukande AAA-upplevelser.",
                "tr_title": "Kraft med ett syfte.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "En bärbar dator som kan spela, spela in och strömma samtidigt med hög FPS och enkelt växla till tunga arbetsbelastningar med multitasking.",
                "tr_title": "Kraft att utöka gränserna.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i7 processor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "En bärbar dator som kan spela, spela in och strömma samtidigt med hög FPS och enkelt växla till tunga arbetsbelastningar med multitasking.",
                "tr_title": "Kraft att utöka gränserna.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Snabba klockhastigheter och ett stort antal kärnor arbetar sömlöst tillsammans så du kan få ut mer av tävlingsspelande och avancerad medieproduktion, oavsett vart du tar med dig din dator.",
                "tr_title": "Massiv kraft. Perfekt balanserad.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i9 processor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Snabba klockhastigheter och ett stort antal kärnor arbetar sömlöst tillsammans så du kan få ut mer av tävlingsspelande och avancerad medieproduktion, oavsett vart du tar med dig din dator.",
                "tr_title": "Massiv kraft. Perfekt balanserad.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Snabba klockhastigheter, höga kärnvärden och olåst kraft arbetar sömlöst tillsammans så att du får ut mer av tävlingsspelande och avancerad medieproduktion, oavsett vart du tar med dig din dator.",
                "tr_title": "Olåst kraft. Perfekt balanserad.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i9 processor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Snabba klockhastigheter, höga kärnvärden och olåst kraft arbetar sömlöst tillsammans så att du får ut mer av tävlingsspelande och avancerad medieproduktion, oavsett vart du tar med dig din dator.",
                "tr_title": "Olåst kraft. Perfekt balanserad.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Snabba klockhastigheter och flera kärnor som fungerar sömlöst tillsammans för att få ut mer av tävlingsspelande och avancerad medieproduktion.",
                "tr_title": "Massiv kraft. Perfekt balanserad.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Snabba klockhastigheter och höga kärnvärden arbetar sömlöst tillsammans för att få ut mer av komplex medieproduktion, oavsett vart du tar din dator.",
                "tr_title": "Massiv kraft. Perfekt balanserad.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i9 PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Snabba klockhastigheter och höga kärnvärden arbetar sömlöst tillsammans för att få ut mer av avancerad medieproduktion.",
                "tr_title": "Massiv kraft. Perfekt balanserad.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i9 PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Öka din potential med den ultimata spelupplevelsen",
                "tr_title": "Konkurrera på högsta nivå",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "UPPTÄCK EN 10:E GEN:S INTEL® CORE™ PROCESSOR SOM PASSAR DITT SÄTT ATT SPELA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sömlöst AAA-spelande",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tävlingsbetonade spel",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning och inspelning",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avancerad medieproduktion",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximal spelprestanda",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Olåst och överklockbar<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Öka din potential med den ultimata spelupplevelsen",
                "tr_title": "Konkurrera på högsta nivå",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "UPPTÄCK EN 10:E GEN:S INTEL® CORE™ PROCESSOR SOM PASSAR DITT SÄTT ATT SPELA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Sömlöst AAA-spelande",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tävlingsbetonade spel",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Strömning och inspelning",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avancerad medieproduktion",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Maximal spelprestanda",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Olåst och överklockbar<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 10<br/>Trådar: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "En bärbar dator som är utvecklad särskilt för de som alltid är på språng.",
                "tr_title": "Möt en ny typ av bärbara datorer",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i9 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Snabba klockhastigheter och flera kärnor som fungerar sömlöst tillsammans för att få ut mer av tävlingsspelande och avancerad medieproduktion.",
                "tr_title": "Olåst kraft. Perfekt balanserad.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Snabba klockhastigheter, höga kärnvärden och olåst prestanda arbetar sömlöst tillsammans för att få ut mer av komplex medieproduktion, oavsett vart du tar din dator.",
                "tr_title": "Olåst kraft. Perfekt balanserad.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i9 PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Snabba klockhastigheter och höga kärnvärden arbetar sömlöst tillsammans för att få ut mer av avancerad medieproduktion.",
                "tr_title": "Olåst kraft. Perfekt balanserad.",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11:E GEN:S INTEL® CORE™ i9 PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smartcache-minne</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Spel och VR bufferfritt med de mest krävande titlarna",
                "tr_title": "Spel på allvar börjar här",
                "tr_gen_core_title": "9:e gen:s Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "UPPTÄCK VILKEN INTEL® CORE™ PROCESSOR SOM PASSAR DITT SÄTT ATT SPELA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-spel",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tävlingsbetonade spel",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spela, strömma och spela in utan kompromisser",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bästa 9:e gen:s Intel® Core™ processorn för spel",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 4<br/>Trådar: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "UPP TILL 32 % HÖGRE FPS <br/><span class='tr_subtitle_font'>när du spelar spel, strömmar och spelar in vs. en dator som är 3 år gammal<sup>3</sup></span>",
                "tr_title": "Kraften att dela dina bästa spelögonblick",
                "tr_gen_core_title": "9:e gen:s Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "UPPTÄCK VILKEN INTEL® CORE™ PROCESSOR SOM PASSAR DITT SÄTT ATT SPELA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-spel",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tävlingsbetonade spel",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spela, strömma och spela in utan kompromisser",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bästa 9:e gen:s Intel® Core™ processorn för spel",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 4<br/>Trådar: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "UPP TILL 41 % HÖGRE FPS <br/><span class='tr_subtitle_font'>när du spelar spel, strömmar och spelar in vs. en dator som är 3 år gammal<sup>4</sup></span>",
                "tr_title": "Spelare kräver. Intel® Core™ i9 levererar.",
                "tr_gen_core_title": "9:e gen:s Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>trådar</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kärnor</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Upp till <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Olåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "UPPTÄCK VILKEN INTEL® CORE™ PROCESSOR SOM PASSAR DITT SÄTT ATT SPELA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-spel",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tävlingsbetonade spel",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spela, strömma och spela in utan kompromisser",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bästa 9:e gen:s Intel® Core™ processorn för spel",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 4<br/>Trådar: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 6<br/>Trådar: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Klockhastighet<sup>2</sup>: Upp till 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kärnor: 8<br/>Trådar: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Byggd för nästa generation av gaming",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i5 processor",
                    "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i5 PROCESSOR"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i7 processor",
                    "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i7 PROCESSOR"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i9 processor",
                    "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i9 PROCESSOR"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kärnor</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>trådar</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Battlestation möter arbetsstation",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i7 processor",
                    "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i7 PROCESSOR"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i9 processor",
                    "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i9 PROCESSOR"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kärnor</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>trådar</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Tillförlitlig prestanda när du behöver det som mest",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i3 processor",
                "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i3 PROCESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "En prestandaökning där du behöver den som mest",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i5 processor",
                "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i5 PROCESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "En prestandaökning där du behöver den som mest",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i5 processor",
                "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i5 PROCESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Överlägsen prestanda där du behöver den mest",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i7 processor",
                "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i7 PROCESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Överlägsen prestanda där du behöver den mest",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i7 processor",
                "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i7 PROCESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med Intel® Evo™ bärbara datorer får du prestanda, inbyggd intelligens för videochattar, snabbare internet, långvarigt batteri med mera.",
                "tr_title": "En prestandaökning där du behöver den som mest.",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i5 processor",
                "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med Intel® Evo™ bärbara datorer får du prestanda, inbyggd intelligens för videochattar, snabbare internet, långvarigt batteri med mera.",
                "tr_title": "Överlägsen prestanda där du behöver den mest",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i7 processor",
                "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med Intel® Evo™ bärbara datorer får du prestanda, inbyggd intelligens för videochattar, snabbare internet, långvarigt batteri med mera.",
                "tr_title": "Extrem prestanda i en förstklassig bärbar dator",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i9 processor",
                "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Med Intel® Turbo Boost-teknologi 2.0 upp till [max_turbo_frequency] GHz",
                "tr_title": "Built for Business",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i5 processor",
                "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Dämpa ljud och ta bort fokus från bakgrunden",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatisk skräddarsydd prestanda för att prioritera det du gör mest",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Bufferfria webbsamtal med hög kvalitet",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Maskinvarubaserad säkerhet out-of-the-box",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Med funktioner för fjärranslutning av enheter",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimerar Wi-Fi-prestandan automatiskt<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Drivs genom datadrivna appar",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Högst produktivitet med förbättrad prestanda",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Med Intel® Turbo Boost-teknologi 2.0 upp till [max_turbo_frequency] GHz",
                "tr_title": "Arbetar hårt, så att du kan fokusera på ditt företag.",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i7 processor",
                "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Dämpa ljud och ta bort fokus från bakgrunden",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatisk skräddarsydd prestanda för att prioritera det du gör mest",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Bufferfria webbsamtal med hög kvalitet",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Maskinvarubaserad säkerhet out-of-the-box",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Med funktioner för fjärranslutning av enheter",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimerar Wi-Fi-prestandan automatiskt<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Drivs genom datadrivna appar",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Högst produktivitet med förbättrad prestanda",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Med Intel® Turbo Boost-teknologi 2.0 upp till [max_turbo_frequency] GHz",
                "tr_title": "Accelerera din verksamhet",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i9 processor",
                "tr_gen_core_title_uppercase": "12:E GEN:S INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Dämpa ljud och ta bort fokus från bakgrunden",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatisk skräddarsydd prestanda för att prioritera det du gör mest",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Bufferfria webbsamtal med hög kvalitet",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Maskinvarubaserad säkerhet out-of-the-box",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Med funktioner för fjärranslutning av enheter",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimerar Wi-Fi-prestandan automatiskt<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Drivs genom datadrivna appar",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Högst produktivitet med förbättrad prestanda",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Pålitlig prestanda med kraft att göra mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "13:e gen:s Intel®&nbsp;Core™&nbsp;i3 processor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Prestanda avsedd att göra mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13:e gen:s Intel®&nbsp;Core™&nbsp;i5 processor",
                "tr_gen_core_title_uppercase": "13:E GEN:S INTEL® CORE™ i5 PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Prestanda avsedd att göra mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13:e gen:s Intel®&nbsp;Core™&nbsp;i5 processor",
                "tr_gen_core_title_uppercase": "13:E GEN:S INTEL® CORE™ i5 PROCESSOR",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Kraftfull prestanda med kraft att göra mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13:e gen:s Intel®&nbsp;Core™&nbsp;i7 processor",
                "tr_gen_core_title_uppercase": "13:E GEN:S INTEL® CORE™ i7 PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Kraftfull prestanda med kraft att göra mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13:e gen:s Intel®&nbsp;Core™&nbsp;i7 processor",
                "tr_gen_core_title_uppercase": "13:E GEN:S INTEL® CORE™ i7 PROCESSOR",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Redo för mer än någonsin",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "13:e gen:s Intel®&nbsp;Core™&nbsp;i9 processor",
                "tr_gen_core_title_uppercase": "13:E GEN:S INTEL® CORE™ i9 PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kärnor</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>trådar</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Anslut sömlöst till din befintliga telefon<sup>24</sup>, välj smart det bästa Wi-Fi-nätverket och ladda snabbt med ett batteri som håller länge.",
                "tr_title": "Gränsöverskridande prestanda för att få mer gjort, var du vill",
                "tr_gen_core_title": "13:e gen:s Intel®&nbsp;Core™&nbsp;i5 processor",
                "tr_gen_core_title_uppercase": "13:E GEN:S INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Anslut sömlöst till din befintliga telefon<sup>24</sup>, välj smart det bästa Wi-Fi-nätverket och ladda snabbt med ett batteri som håller länge.",
                "tr_title": "Gränsöverskridande prestanda som låter datorn göra ännu mer på samma gång",
                "tr_gen_core_title": "13:e gen:s Intel®&nbsp;Core™&nbsp;i7 processor",
                "tr_gen_core_title_uppercase": "13:E GEN:S INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Anslut sömlöst till din befintliga telefon<sup>24</sup>, välj smart det bästa Wi-Fi-nätverket och ladda snabbt med ett batteri som håller länge.",
                "tr_title": "Gränsöverskridande prestanda som snabbar på de mest krävande uppgifterna",
                "tr_gen_core_title": "13:e gen:s Intel®&nbsp;Core™&nbsp;i9 processor",
                "tr_gen_core_title_uppercase": "13:E GEN:S INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Redo för mer än någonsin",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "13:e gen:s Intel®&nbsp;Core™&nbsp;i5 processor",
                    "tr_gen_core_title_uppercase": "13:E GEN:S INTEL® CORE™ i5 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "13:e gen:s Intel®&nbsp;Core™&nbsp;i7 processor",
                    "tr_gen_core_title_uppercase": "13:E GEN:S INTEL® CORE™ i7 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "13:e gen:s Intel®&nbsp;Core™&nbsp;i9 processor",
                    "tr_gen_core_title_uppercase": "13:E GEN:S INTEL® CORE™ i9 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kärnor</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>trådar</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Redo för mer än någonsin",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kärnor</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>trådar</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Pålitlig prestanda med kraft att göra mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Intel® Core™ i3 processor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Prestanda avsedd att göra mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Kraftfull prestanda med kraft att göra mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Redo för mer än någonsin",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kärnor</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>trådar</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Pålitlig prestanda med kraft att göra mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 3 processor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Prestanda avsedd att göra mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 5 processor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 5 PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Kraftfull prestanda med kraft att göra mer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 7 processor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 7 PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Kompromisslös gaming",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 5 PROCESSOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 7 PROCESSOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;9 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 9 PROCESSOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kärnor</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core] LPE-kärnor"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>trådar</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores",
                    "tr_badgeSubTitle1": "[l_thread] på LPE-kärnor"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 9 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Kompromisslös gaming",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Kompromisslös gaming",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Kompromisslös gaming",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Kompromisslös gaming",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Kompromisslös gaming",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Kompromisslös gaming",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Småföretag förtjänar stora affärslösningar.",
                "tr_gen_core_title": "13:e gen:s Intel®&nbsp;Core™&nbsp;i5 processor",
                "tr_gen_core_title_uppercase": "13:E GEN:S INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Dämpa ljud och ta bort fokus från bakgrunden",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatisk skräddarsydd prestanda för att prioritera det du gör mest",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Bufferfria webbsamtal med hög kvalitet",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Maskinvarubaserat säkerhetsskydd direkt",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Hjälp till att skydda enheter och data från fysiska manipulationsattacker",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Småföretag förtjänar stora affärslösningar.",
                "tr_gen_core_title": "13:e gen:s Intel®&nbsp;Core™&nbsp;i7 processor",
                "tr_gen_core_title_uppercase": "13:E GEN:S INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Dämpa ljud och ta bort fokus från bakgrunden",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatisk skräddarsydd prestanda för att prioritera det du gör mest",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Bufferfria webbsamtal med hög kvalitet",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Maskinvarubaserat säkerhetsskydd direkt",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Hjälp till att skydda enheter och data från fysiska manipulationsattacker",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Småföretag förtjänar stora affärslösningar.",
                "tr_gen_core_title": "13:e gen:s Intel®&nbsp;Core™&nbsp;i9 processor",
                "tr_gen_core_title_uppercase": "13:E GEN:S INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "HÅLLER DEN HÄR PROCESSORN MÅTTET?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuell enhet",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Dämpa ljud och ta bort fokus från bakgrunden",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatisk skräddarsydd prestanda för att prioritera det du gör mest",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Bufferfria webbsamtal med hög kvalitet",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Maskinvarubaserat säkerhetsskydd direkt",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Hjälp till att skydda enheter och data från fysiska manipulationsattacker",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Frigör nya AI-upplevelser för företag",
                "tr_title": "Var redo för vad som kommer med Intel® Core™ Ultra&nbsp;[XX] processorer och Intel vPro®",
                "tr_gen_core_title": "Intel® Core™ Ultra [XX] processor",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "FÖRHÖJ DITT FÖRETAG MED AI-DATORER FRÅN INTEL",
                    "tr_badgeSubTitle": "Kraftfulla datorer som ger en bra upplevelse är kärnan i affärsproduktiviteten. Intel vPro® ökar de anställdas effektivitet och prestanda över arbetsbelastningar och program tack vare en ny arkitektur som ger överlägsen energieffektiv prestanda för olika, komplexa arbetsbelastningar genom att skicka rätt uppgift till rätt motor vid rätt tidpunkt.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "INBYGGDA SÄKERHETSFUNKTIONER I FLERA LAGER",
                    "tr_badgeSubTitle": "Nya och framväxande hot kräver en övergripande strategi för säkerhet. Intel vPro® ger skydd på maskinvarunivå och genom hela stacken så att ditt företag är bättre skyddat. Dessa unika inbyggda säkerhetsåtgärder, inklusive AI-drivna hotdetekteringsfunktioner, hjälper till att skydda dina användare, dina data och ditt företag.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "INBYGGDA VERKTYG FÖR ATT HÅLLA ANVÄNDARE PRODUKTIVA FRÅN NÄSTAN VAR SOM HELST",
                    "tr_badgeSubTitle": "Kontor finns överallt och datorer likaså. Intel vPro® tillhandahåller moderna maskinvarubaserade hanteringsfunktioner för att hjälpa företag att minska bördan av hantering av datorpark, förenkla support och förbättra slutanvändarupplevelser, samtidigt som hållbara datormetoder aktiveras.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Inte bara en fantastisk dator, en AI-dator",
                "tr_subtitle1": "DRIVS AV INTEL®&nbsp;CORE™ ULTRA PROCESSORER (SERIE&nbsp;2)",
                "tr_subtitle2": "Det är möjligt på din Intel AI-dator",
                "tr_subtitle": "Intels mest effektiva, säkra och högpresterande mobilprocessor<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 processor (Serie&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Mer kreativ</span> med AI-verktyg för att skapa snabbare"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mer produktiv</span> med tidsbesparande AI-assistans"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mer säker</span> med inbyggt dataskydd"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Inte bara en fantastisk dator, en AI-dator",
                "tr_subtitle1": "DRIVS AV INTEL®&nbsp;CORE™ ULTRA PROCESSORER (SERIE&nbsp;2)",
                "tr_subtitle2": "Det är möjligt på din Intel AI-dator",
                "tr_subtitle": "Intels mest effektiva, säkra och högpresterande mobilprocessor<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 processor (Serie&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Mer kreativ</span> med AI-verktyg för att skapa snabbare"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mer produktiv</span> med tidsbesparande AI-assistans"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mer säker</span> med inbyggt dataskydd"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Inte bara en fantastisk dator, en AI-dator",
                "tr_subtitle1": "DRIVS AV INTEL®&nbsp;CORE™ ULTRA PROCESSORER (SERIE&nbsp;2)",
                "tr_subtitle2": "Det är möjligt på din Intel AI-dator",
                "tr_subtitle": "Intels mest effektiva, säkra och högpresterande mobilprocessor<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 processor (Serie&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Inte bara en fantastisk dator, en AI-dator",
                "tr_subtitle1": "DRIVS AV INTEL®&nbsp;CORE™ ULTRA PROCESSORER (SERIE&nbsp;2)",
                "tr_subtitle2": "Det är möjligt på din Intel AI-dator",
                "tr_subtitle": "Intels mest effektiva, säkra och högpresterande mobilprocessor<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 processor (Serie&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_tileTitle1": "Dedikerad grafik",
                "tr_title": "VAD ÄR EN GRAFIKPROCESSOR?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Grafikprocessorerna (GPU) skapar de bilder du ser på skärmen.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integrerad GPU",
                    "tr_badgeSubTitle": "En integrerad GPU använder samma RAM som resten av datorn vilket gör den mer energieffektiv.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Diskret GPU",
                    "tr_badgeSubTitle": "Detta är den mest kraftfulla typen av GPU eftersom den har eget RAM-minne och andra resurser som ägnas åt att skapa enastående grafik.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "Hybrid-GPU",
                    "tr_badgeSubTitle": "Detta är den mest kraftfulla typen av GPU eftersom den har eget RAM-minne och andra resurser som ägnas åt att skapa enastående grafik.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Strömlinjeformad foto- och videoredigering",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Obegränsat videoinnehåll i 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Spela populära spel i fantastisk detaljrikedom",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Dubbla GPU:er",
                    "tr_badgeSubTitle": "Vissa system, som de med Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafik kan dra nytta av både processor och diskret GPU för att distribuera den grafiska arbetsbelastningen.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integrerad GPU",
                    "tr_badgeSubTitle": "En integrerad GPU är inbyggd i samma krets som huvudprocessorn (CPU). Den här typen av GPU använder samma RAM-minne och andra resurser som resten av datorn, vilket gör den mer energieffektiv och gör enheten tunnare och lättare.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Dedikerad GPU",
                    "tr_badgeSubTitle": "Detta är den mest kraftfulla typen av GPU eftersom den har eget RAM-minne och andra resurser som ägnas åt att skapa enastående grafik.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Standard",
                    "tr_badgeSubTitle": "Upp till 4&nbsp;GB VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Konkurrens-kraftig",
                    "tr_badgeSubTitle": "Upp till 8&nbsp;GB VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "Över <br/>8&nbsp;GB VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Kraftfull grafikkraft",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Den mest kraftfulla grafiken kräver ett särskilt grafikkort som enbart kan fokusera på att skapa vackra, skarpa bilder och jämn animering.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Inbyggd fantastisk och levande grafik",
                "tr_pretitle": "Intel® Iris® Plus grafik",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med Intel® Iris® Plus grafik kan du låta dig uppslukas av 4K HDR-video och spel i full HD utan krav på extra grafikkort.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Enastående grafik, ansträngningslös prestanda",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Grafik ger transformerande integrerad grafikprestanda för att stödja vätskedesign för skapare och rika spelupplevelser.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Intel® Iris® Pro grafik",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Intel® UHD-grafik med X<sup class='small_text_sup_xe'>e</sup> arkitektur",
                "tr_pretitle": "Uppslukande visuella upplevelser",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Upplev strömlinjeformad foto- och videoredigering, obegränsat premiumvideoinnehåll i 4K eller spela populära spel i fantastisk detaljrikedom.",
                "tr_photo_text": "Strömlinjeformad foto- och videoredigering",
                "tr_video_text": "Obegränsat videoinnehåll i 4K",
                "tr_game_text": "Spela populära spel i fantastisk detaljrikedom",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MYCKET MER ÄN FINA BILDER",
                "tr_subtitle": "Grafikkortet (eller grafikprocessorn/GPU:n) bidrar tillsammans med processorn (eller CPU:n) till hur dina spel körs och hur de ser ut. VRAM eller video-RAM, som är enbart till för att skapa grafik, är ett snabbt sätt att ta reda på hur din GPU presterar.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "FÖR INTENSIV SPELGRAFIK",
                "tr_subtitle": "Den mest kraftfulla grafiken kräver ett separat grafikkort som kan enbart fokusera på att skapa vackra, skarpa bilder och jämn animering.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "FÖR INTENSIV SPELGRAFIK",
                "tr_subtitle": "Den mest kraftfulla grafiken kräver ett separat grafikkort som kan enbart fokusera på att skapa vackra, skarpa bilder och jämn animering.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MYCKET MER ÄN FINA BILDER",
                "tr_subtitle": "Grafikkortet (eller grafikprocessorn/GPU:n) bidrar tillsammans med processorn (eller CPU:n) till hur dina spel körs och hur de ser ut. VRAM eller video-RAM, som är enbart till för att skapa grafik, är ett snabbt sätt att ta reda på hur din GPU presterar.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Kraftfull grafikkraft",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Den mest kraftfulla grafiken kräver ett särskilt grafikkort som enbart kan fokusera på att skapa vackra, skarpa bilder och jämn animering.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "FÖR INTENSIV SPELGRAFIK",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "Den mest kraftfulla grafiken kräver ett särskilt grafikkort som enbart kan fokusera på att skapa vackra, skarpa bilder och jämn animering.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Den mest kraftfulla grafiken kräver ett separat grafikkort som kan enbart fokusera på att skapa vackra, skarpa bilder och jämn animering.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Levererar massor av grafikkraft som uppfyller spelets minimikrav.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "För spelare på högre nivå som vill ha en spelupplevelse på tävlingsnivå",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "För avancerade spelare som även använder datorn för krävande medieproduktion",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Färgstark video och uppslukande spelande",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Få ut mesta möjliga av din högupplösta skärm med Intel® HD-grafik och få en avancerad multimedieupplevelse med inbyggt grafikkort.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Ger livfulla videor och bilder",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Få mer detaljerad och lysande färg i ditt strömmande av video och foton.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Fantastiskt skapande och spelande",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafik",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Från uppslukande underhållning och avancerad medieproduktion till vanligt spelande – Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafik erbjuder banbrytande grafik för tunna och lätta datorer.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_pretitle": "FÖR INTENSIV SPELGRAFIK",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> grafik",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Den mest kraftfulla grafiken kräver ett separat grafikkort som kan enbart fokusera på att skapa vackra, skarpa bilder och jämn animering.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Släpp loss kreativiteten",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX grafik",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Rör dig genom kreativa projekt och bli uppslukad i en mängd olika spel med den kombinerade kraften i processorns grafik och den första Intel® diskreta grafiska motorn.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX grafik",
                "tr_pretitle": "FÖR INTENSIV SPELGRAFIK",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Den mest kraftfulla grafiken kräver ett separat grafikkort som kan enbart fokusera på att skapa vackra, skarpa bilder och jämn animering.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Grafik",
                "tr_title": "Snygg inbyggd grafik",
                "tr_subtitle": "Upplev spel, filmer och videor online med enastående skärpa och detaljrikedom.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Öka dina arbetsflöden"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Överladda ditt spelande"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Överladda ditt spelande"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Gå upp en nivå i ditt spelande"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "SLÄPP LOSS KREATIVITETEN",
                    "tr_specText": "Öka din produktivitet vart du än går med de mest omfattande mediafunktionerna, som en <strong>AV1-maskinvarukodning, den första i branschen</strong>, och AI-accelererad medieproduktion.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "SLÄPP LOSS KREATIVITETEN",
                    "tr_specText": "Öka din produktivitet med de mest omfattande media funktioner, som en <strong>AV1-maskinvarukodning, den första i branschen</strong>, och AI-accelererad medieproduktion.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "SLÄPP LOSS KREATIVITETEN",
                    "tr_specText": "Upplev banbrytande AI-accelererad medieproduktion med <strong>AV1-maskinvarukodning, den första i branschen</strong>, och den avancerade <strong>X<sup>e</sup> Media Engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "SLÄPP LOSS KREATIVITETEN",
                    "tr_specText": "Upplev banbrytande AI-accelererad medieproduktion med <strong>AV1-maskinvarukodning, den första i branschen</strong>, och den avancerade <strong>X<sup>e</sup> Media Engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "SLÄPP LOSS KREATIVITETEN",
                    "tr_specText": "Upplev banbrytande AI-accelererad medieproduktion med <strong>AV1-maskinvarukodning, den första i branschen</strong>, och den avancerade <strong>X<sup>e</sup> Media Engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "FLYTTBARHET MÖTER PRESTANDA",
                    "tr_specText": "Spela de senaste spelen på språng och få superladdad prestanda och trohet med moderna spelfunktioner. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-förstärkt uppskalningsteknik för mer prestanda och sömlöst spelande. <br/><br/>Support för <strong>DirectX* 12 Ultimate:  </strong>Ta ditt spelande till en ny nivå av realism med grafikteknologier, som ray tracing och skuggning med variabel hastighet."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "TA KONTROLLEN FÖR DINA SPEL",
                    "tr_specText": "<strong>Intel® Arc™ kontrollcenter</strong> låter dig förfina din upplevelse med en intuitiv och modern layout. Få sömlösa uppdateringar för drivrutin, optimera dina spel och mer."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "TA KONTROLLEN FÖR DINA SPEL",
                    "tr_specText": "<strong>Intel® Arc™ kontrollcenter</strong> låter dig förfina din upplevelse med en intuitiv och modern layout. Få sömlösa uppdateringar för drivrutin, optimera dina spel och mer."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "TA KONTROLLEN FÖR DINA SPEL",
                    "tr_specText": "Få sömlösa uppdateringar för drivrutin och optimera många av dina favoritspel med programvaran <strong>Intel® Arc™ kontrollcenter</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "MAXIMERA DIN INTEL® PLATTFORM",
                    "tr_specText": "Parkoppla med kompatibla Intel® Core™ processorer för att släppa loss <strong>Intel® Deep Link teknologi</strong> för att förbättra din skapande-, spelande- och strömningsupplevelse."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "MAXIMERA DITT INTEL® SYSTEM",
                    "tr_specText": "Parkoppla med kompatibla Intel® Core™ processorer för att släppa loss <strong>Intel® Deep Link teknologi</strong> för att förbättra din skapande-, spelande- och strömningsupplevelse."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "MAXIMERA DIN INTEL® PLATTFORM",
                    "tr_specText": "Parkoppla med kompatibla Intel® Core™ processorer för att släppa loss <strong>Intel® Deep Link teknologi</strong> för att förbättra din skapande-, spelande- och strömningsupplevelse."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAXIMERA DIN INTEL® PLATTFORM",
                    "tr_specText": "Parkoppla med kompatibla Intel® Core™ processorer för att släppa loss <strong>Intel® Deep Link teknologi</strong> för att förbättra din skapande-, spelande- och strömningsupplevelse."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAXIMERA DITT INTEL® SYSTEM",
                    "tr_specText": "Parkoppla med kompatibla Intel® Core™ processorer för att släppa loss <strong>Intel® Deep Link teknologi</strong> för att förbättra din skapande-, spelande- och strömningsupplevelse."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "TA PLATS PÅ SCENEN",
                    "tr_specText": "Sänd sömlöst med avancerade strömningsteknologier som drivs av <strong>programvaran Intel® Arc™ kontrollcenter</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "TA PLATS PÅ SCENEN",
                    "tr_specText": "Sänd sömlöst med avancerade strömningsteknologier som drivs av <strong>programvaran Intel® Arc™ kontrollcenter</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "UPPLEV UPPSLUKANDE HÖGPRESTERANDE SPELANDE",
                    "tr_specText": "Placera din bästa prestanda först med de senaste AAA-spelen i höga upplösningar och höga uppdateringsfrekvenser. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-förstärkt uppskalningsteknik för mer prestanda och sömlöst spelande. <br/><br/>Support för <strong>DirectX* 12 Ultimate:  </strong>Ta ditt spelande till en ny nivå av realism med grafikteknologier, som ray tracing och skuggning med variabel hastighet."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "UPPLEV UPPSLUKANDE HÖGPRESTERANDE SPELANDE",
                    "tr_specText": "Placera din bästa prestanda först med de senaste AAA-spelen i höga upplösningar och höga uppdateringsfrekvenser. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-förstärkt uppskalningsteknik för mer prestanda och sömlöst spelande. <br/><br/>Support för <strong>DirectX* 12 Ultimate:  </strong>Ta ditt spelande till en ny nivå av realism med grafikteknologier, som ray tracing och skuggning med variabel hastighet."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "GE DITT SPEL EN BOOST",
                    "tr_specText": "Ta din spelandeupplevelse till nästa nivå med: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-förstärkt uppskalningsteknologi för mer prestanda och sömlöst spelande. <br/><br/>Support för <strong>DirectX* 12 Ultimate:  </strong>Ta ditt spelande till en ny nivå av realism med grafikteknologier, som ray tracing och skuggning med variabel hastighet."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "UPPLEV AVANCERAT SNABBT SPELANDE",
                    "tr_specText": "Njut av smidigt spelande på många AAA-spel och spelande med hög uppdateringsfrekvens från högoktaniga e-sportsskjutspel till snabba RTS-träffar. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-förstärkt uppskalningsteknik för mer prestanda och sömlöst spelande. <br/><br/>Support för <strong>DirectX* 12 Ultimate:  </strong>Ta ditt spelande till en ny nivå av realism med grafikteknologier som ray tracing och skuggning av variabel hastighet."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "UPPLEV UPPSLUKANDE HÖGPRESTERANDE SPELANDE",
                    "tr_specText": "Placera din bästa prestanda först med de senaste AAA-spelen i höga upplösningar och höga uppdateringsfrekvenser. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-förstärkt uppskalningsteknik för mer prestanda och sömlöst spelande. <br/><br/>Support för <strong>DirectX* 12 Ultimate:  </strong>Ta ditt spelande till en ny nivå av realism med grafikteknologier, som ray tracing och skuggning med variabel hastighet."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Ett grafiskt universum med plats för alla"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "ACCELERERA DINA BILDRUTOR, ACCELERERA DINA SPEL",
                    "tr_specText": "Intel® X<sup class='small_text_sup_xe'>e</sup> Super samplingsteknik (X<sup class='small_text_sup_xe'>e</sup>SS) tar ditt spelande till nästa nivå med AI-förbättrad uppskalning – för maximal prestanda med hög bildåtergivning. X<sup class='small_text_sup_xe'>e</sup>SS är optimerad för Intel® Arc™ grafikprodukter så att du kan dra full nytta av Intel® X<sup class='small_text_sup_xe'>e</sup> matrisförlängningar (Intel® XMX) maskinvaruacceleration."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "DEN ULTIMATA GRAFIKEN ÄR HÄR",
                    "tr_specText": "Med fullt stöd för DirectX* 12 Ultimate på all Intel® Arc™ grafik kommer du att njuta av häpnadsväckande, uppslukande spelande tack vare den senaste grafiktekniken som strålspårning, skuggning med variabel hastighet, maskskuggning och återkoppling från samplare – grunden för nästa generations spel."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "SÖMLÖST SKAPANDE AV FOTO, VIDEO OCH MEDIA",
                    "tr_specText": "Släpp loss din kreativitet och fängsla målgrupper med rikt digitalt innehållsskapande med en hyperavancerad mediemotor – förstärkt med AI och accelererad med Intel® teknik. Skapa spektakulärt innehåll, som drivs av grafik med stöd för alla nuvarande ledande medieformat samtidigt som du håller dig uppdaterad med de mest avancerade AV1-videokodsfunktionerna."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "UPPLEV BANBRYTANDE AI-SKAPANDE OCH SPELANDE",
                    "tr_specText": "Intel® Arc™ grafik låser upp kraftfulla AI-funktioner, från avancerat skapande till uppslukande spelande. Få ut det mesta av dagens avancerade programvara för skapande, inklusive text-till-bild-generation och AI-accelererad videoredigering. Plus, njut av högupplöst spelande genom AI-accelererad X<sup class='small_text_sup_xe'>e</sup>SS-uppskalning."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Professionell grafik börjar här"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "PERFEKT FÖR KREATIVA PERSONER, DESIGNERS OCH INGENJÖRER PÅ SPRÅNG",
                    "tr_specText": "Tillgänglig på utvalda Intel® Core™ Ultra processorer i H-serien, inbyggd Intel® Arc™ Pro grafik låser upp 2x grafikprestandaförbättringar med ISV-certifieringar från oberoende programvarusäljare för ökad tillförlitlighet och ger effektiv acceleration för maskinvaruproduktion för enastående innehållsskapande på språng.<br/><br/><span class='expandable_heading'>Programvarucertifieringar<br/></span><br/>Intel har arbetat hand i hand med hundratals programvaruföretag under åren, och denna oöverträffade upplevelse går in i våra grafikkort i Intel® Arc™ Pro A-serien. Certifieringar är lika viktiga för oss som de är för dig."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "GÖR AI LÄTTILLGÄNGLIGT",
                    "tr_specText": "Detta GPU-sortiment innehåller ett brett urval av bra teknik, inklusive stöd för dina kompatibla AI-verktyg med inbyggd dedikerad AI-acceleration där du behöver det."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "STRÅLSPÅRNING UTAN PREMIEN",
                    "tr_specText": "Grafikkort måste göra mer än att bara trycka pixlar till bildskärmar, de måste också påskynda skapandet av vackra bilder. Maskinvaruteknik för strålspårning som är inbyggd i grafik i Intel® Arc™ Pro A-serien gör det möjligt för din kompatibla programvara att börja utforska dessa nya professionella arbetsflöden. Strålspårning anses vanligtvis som endast tillgängligt på dyr grafik, men Intels senaste professionella grafikområde bryter mot den förväntan."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "GE KRAFT ÅT SKAPARE MED UPPSLUKANDE BILDSKÄRMAR",
                    "tr_specText": "Med fullt stöd för DirectX* 12 Ultimate på all Intel® Arc™ grafik kommer du att njuta av häpnadsväckande, uppslukande spelande tack vare den senaste grafiktekniken som strålspårning, skuggning med variabel hastighet, maskskuggning och återkoppling från samplare – grunden för nästa generations spel."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "DYNAMISKT OPTIMERADE BILDER",
                    "tr_specText": "Med dedikerad maskinvaruavkodning på Pro GPU:er bidrar Dolby Vision* till större djup, skarpare kontrast och fler färger för din kompatibla bildskärm. Dolby Vision* låser upp HDR-teknikens fulla potential genom att dynamiskt optimera bildkvaliteten baserat på din tjänst, bildskärmsenhet och plattform för att leverera häpnadsväckande grafik varje gång."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "EXPERTVERIFIERAD PROGRAMVARA",
                    "tr_specText": "Intel har arbetat hand i hand med hundratals programvaruföretag under åren, och denna oöverträffade upplevelse går in i det professionella grafikintervallet i Intel® Arc™ Pro A-serien. Certifieringar och kvalifikationer för arbetsflöde för programvara är lika viktiga för oss som de är för dig."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "STRÅLSPÅRNING UTAN PREMIEN",
                    "tr_specText": "Grafikkort måste göra mer än att bara trycka pixlar till bildskärmar, de måste också påskynda skapandet av vackra bilder. Maskinvaruteknik för strålspårning som är inbyggd i grafik i Intel® Arc™ Pro A-serien gör det möjligt för din kompatibla programvara att börja utforska dessa nya professionella arbetsflöden. Strålspårning anses vanligtvis som endast tillgängligt på dyr grafik, men Intels senaste professionella grafikområde bryter mot den förväntan."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "FLERA STORA BILDSKÄRMAR MED EN LITEN MOBIL GPU",
                    "tr_specText": "Intel® Arc™ Pro grafik för bärbara datorer kan stödja upp till fyra ultrastora bildskärmar för att förbättra din arbetsflödeseffektivitet. Lägg till stöd för högt dynamiskt område (HDR) så har du allt du behöver för den nästa konfigurationen för flera bildskärmar i hemmet eller på kontoret."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "FULL MINNESBANDBREDDS&shy;POTENTIAL",
                    "tr_specText": "Bandbredden för ditt grafikkort är ett viktigt övervägande i en arbetsstation. Om du gör fel kan du sakta ner professionella arbetsflöden, vilket är anledningen till att vi såg till att GPU:n i Intel® Arc™ Pro A-serien har stöd för modern PCIe* 4.0, utan att påverka bakåtkompatibiliteten. Grafikminne förbättras ytterligare av en hög bandbreddshastighet, vilket gör det möjligt för projektdata att nås av din programvara ännu snabbare."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "ANDAS LIV I VARJE BILDRUTA",
                    "tr_specText": "<span class='expandable_heading'>AI-förstärkt. Intel-stödd.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS är en AI-förbättrad uppskalningsteknik som ökar din prestanda utan att offra skönheten i varje bildruta – så att du kan uppleva ditt spelande som det var tänkt att vara."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "REALTID.<br/> RIKTIGT UPPSLUKANDE.<br/> REALISM PÅ SITT BÄSTA.",
                    "tr_specText": "Mer realistiska världar väntar. Med inbyggd strålspårning kommer du att uppleva allt från verklighetstrogna skuggor och reflektioner till mästerlig belysning. Med andra ord är det en visuell spelupplevelse som du inte vill missa."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "DITT BÄSTA SPELANDE, DELAT NÄR DET ÄR SOM BÄST",
                    "tr_specText": "Ge din publik det sömlösa spelande de förtjänar att se. Med de maskinvaruaccelererade AV1-kodningsfunktionerna i Intel® Arc™ grafik kommer de att fängslas av varje steg. Högre visuell kvalitet vid samma bithastighet är lika med att strömma ditt bästa samtidigt som du sparar bandbredd."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Välkommen alla skapare",
                    "tr_specText": "Nya och förbättrade AI-upplevelser är tillgängliga direkt till hands. Med avancerade AI-acceleratorer inbäddade över grafik i Intel® Arc™ A-serien kan du spendera mer tid på att skapa och mindre tid på att vänta över många program.<br/><br/>Ta ditt videoskapande till nästa nivå och visa vägen med högkvalitativ, lagringseffektiv AV1-video som drivs av avancerade AV1-maskinvarufunktioner som är inbyggda i Intel® Arc™ grafik."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Allt du behöver för att få jobbet gjort",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Kraftfull prestanda som är tunn, lätt och byggd för att hålla — du kan få allt. Med fantastisk batteritid, vackra pekskärmar,* och de senaste SSD-enheterna kommer du definitivt att hitta vad du söker i en Windows 10-dator.",
                "tr_noteSection": "*Maskinvaruberoende.",
                "titleArray": [
                  {
                    "tr_title": "Mindre är mer",
                    "tr_subtitle": "Dagens datorer förbrukar vanligtvis mindre ström, vilket gör att du får ut mer av batteriet — i genomsnitt åtta timmars videouppspelning eller mer.**",
                    "tr_noteSection": "**Batteritiden varierar kraftigt beroende på enhet, inställningar, användning och andra faktorer."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "ALLT DU BEHÖVER FÖR ATT FÅ JOBBET GJORT",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Kraftfull prestanda som är tunn, lätt och byggd för att hålla — du kan få allt. Med fantastisk batteritid, vackra pekskärmar,* och de senaste SSD-enheterna kommer du definitivt att hitta vad du söker i en Windows 10-dator.",
                "tr_noteSection": "*Maskinvaruberoende.",
                "titleArray": [
                  {
                    "tr_title": "MINDRE ÄR MER",
                    "tr_subtitle": "Dagens datorer förbrukar vanligtvis mindre ström, vilket gör att du får ut mer av batteriet — i genomsnitt åtta timmars videouppspelning eller mer.**",
                    "tr_noteSection": "**Batteritiden varierar kraftigt beroende på enhet, inställningar, användning och andra faktorer."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Strömlinjeformad för säkerhet och prestanda",
                "tr_pretitle": "Windows* 10 i S-läge",
                "tr_subtitle": "När du har S-läget aktiverat i Windows* 10 så får du ett extra skydd som tillåter dig att arbeta, spela och utforska med tryggheten att din enhet är säker."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "STRÖMLINJEFORMAD FÖR SÄKERHET OCH PRESTANDA",
                "tr_title": "Windows* 10 i S-läge",
                "tr_subtitle": "När du har S-läget aktiverat i Windows* 10 så får du ett extra skydd som tillåter dig att arbeta, spela och utforska med tryggheten att din enhet är säker."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Utför fantastiska saker",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Prestanda, pris och mycket att välja mellan. Från innovativa 2 i 1-enheter till tunna och lätta bärbara datorer, pekskärmar, fristående tangentbord – oavsett budget eller stil kan du hitta en Windows* dator som passar dig."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "GÖR FANTASTISKA SAKER",
                "tr_title": "Windows*",
                "tr_subtitle": "Prestanda, pris och mycket att välja mellan. Från innovativa 2 i 1-enheter till tunna och lätta bärbara datorer, pekskärmar, fristående tangentbord – oavsett budget eller stil kan du hitta en Windows* dator som passar dig."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Minne",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "HUR MYCKET RAM-MINNE BEHÖVER DU?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Vardagsbruk – 2 GB UPP TILL 8&nbsp;GB&nbsp;RAM",
                    "tr_badgeSubTitle": "Perfekt för den som främst vill använda datorn för ordbehandling och att surfa på nätet, och som tenderar att fokusera på ett program i taget."
                  },
                  {
                    "tr_badgeTitle": "Kraftfull – 8 GB UPP TILL 16 GB RAM",
                    "tr_badgeSubTitle": "Med mer RAM-minne går programmen snabbare och du kan även köra fler program samtidigt."
                  },
                  {
                    "tr_badgeTitle": "Avancerad – MER ÄN 16 GB RAM",
                    "tr_badgeSubTitle": "Om du är intresserad av spel, foto-/videoredigering eller 3D-modellering, behöver du extra RAM för att hantera dessa processorintensiva program."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM för vardaglig multitasking",
                "tr_subtitle": "Minnet gör att grundläggande program för ordbehandling och webbsurfning laddas och fungerar smidigt.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM för kraftfull multitasking",
                "tr_subtitle": "Om du använder flera program samtidigt kan du med [memory_total]&nbsp;GB växla fram och tillbaka med lätthet.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM för avancerad multitasking ",
                "tr_subtitle": "Du får rejält med minne för att köra flera processorintensiva program samtidigt utan att datorn blir långsammare.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1] GB minne = [Value2] GB Intel® Optane™ minne + [Value3] GB RAM",
                "tr_subtitle": "Den här kombinerade minneslösningen ger dig snabbare hårddiskprestanda från Intel® Optane™ minnet samt bättre svarstider från RAM.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>MINNE</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ minne",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Mindre väntan. Mer av vad du gillar.",
                "tr_subtitle": "Intel® Optane™ minne arbetar med ditt systemminne och ger dig snabbare åtkomst till de program du använder ofta så att de startar och läses in så fort du behöver dem.",
                "tr_pretitle": "[XX] GB Intel® Optane™ minne",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "VAD GÖR MINNET FÖR MIN DATOR?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Snabbare produktion av innehåll",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "En mer responsiv spelupplevelse",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Enklare multitasking",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Snabbare öppning av program",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ minne",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Accelerera ditt spelande</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ minne",
                "tr_pretitle1": "[XX] GB Intel® Optane™ minne accelererar ditt spelande",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Starta spel upp till<br/><span class=\"optane-game\">98 % snabbare</span><br/>från dataenheten med en [optane_legal_processor_name] processor med Intel® Optane™ minne<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Ladda nivåer upp till<br/><span class=\"optane-game\">4,1X snabbare</span><br/>från dataenheten med en [optane_legal_processor_name] processor med Intel® Optane™ minne<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Intel® Optane™ minne",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Accelerera ditt spelande</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ minne",
                "tr_pretitle1": "[XX] GB Intel® Optane™ minne accelererar ditt spelande",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Starta spel upp till<br/><span class=\"optane-game\">98 % snabbare</span><br/>från dataenheten med en [optane_legal_processor_name] processor med Intel® Optane™ minne<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Ladda nivåer upp till<br/><span class=\"optane-game\">4,1X snabbare</span><br/>från dataenheten med en [optane_legal_processor_name] processor med Intel® Optane™ minne<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1] GB minne = [Value2] GB Intel® Optane™ minne + [Value3] GB RAM",
                "tr_subtitle": "Den här kombinerade minneslösningen ger dig snabbare hårddiskprestanda från Intel® Optane™ minnet samt bättre svarstider från RAM.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB RAM för multitasking",
                    "tr_subtitle": "Om du använder flera program samtidigt kan du med [memory_total]&nbsp;GB växla fram och tillbaka med lätthet."
                  },
                  {
                    "tr_title": "För vardagsuppgifter",
                    "tr_subtitle": "Minnet gör att grundläggande program för ordbehandling och webbsurfning laddas och fungerar smidigt."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB RAM",
                "tr_pretitle": "FÖR MER MULTITASKING",
                "tr_subtitle": "Med RAM-minnet får processorn enklare åtkomst till programfiler, så att CPU:n kan komma åt dem snabbare och arbeta med flera uppgifter samtidigt.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standardminne",
                    "tr_badgeSubTitle": "Upp till 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Avancerat minne",
                    "tr_badgeSubTitle": "Mer än 8 GB och upp till 16 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Elitminne",
                    "tr_badgeSubTitle": "Över 16&nbsp;GB&nbsp;RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MINNE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB för mer multitasking",
                "tr_pretitle": "FÖR MER MULTITASKING",
                "tr_subtitle": "Med RAM-minnet får processorn enklare åtkomst till programfiler, så att CPU:n kan komma åt dem snabbare och arbeta med flera uppgifter samtidigt.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standardminne",
                    "tr_badgeSubTitle": "Upp till 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Avancerat minne",
                    "tr_badgeSubTitle": "Mer än 8 GB och upp till 16 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Elitminne",
                    "tr_badgeSubTitle": "Över 16&nbsp;GB&nbsp;RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HÅLLER DET HÄR MINNET MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Standard",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avancerat",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Elit",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Minne",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "HÅLLER DET HÄR MINNET MÅTTET?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "HÅLLER DET HÄR MINNET MÅTTET?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuell enhet",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Vardagsbruk",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Kraftfull",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avancerad",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Lagring",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "HUR MYCKET HAR DATORN PLATS FÖR?",
                "tr_subtitle": "Du behöver inte gissa. Vi visar exakt vad du kan lagra på den här datorn.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Skapa ett bibliotek med</span> <br/><span class=\"storage-standard\">[num_songs] låtar<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>baserat på i genomsnitt 5 MB per låt",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Skapa ett bibliotek med</span><br/><span class=\"storage-standard\">[num_games] spel<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>baserat på i genomsnitt 40&nbsp;GB per spel",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Lagringsutrymme för</span><br/><span class=\"storage-standard\">[num_videos] videor<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>baserat på i genomsnitt 5 GB per video",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Ta och lagra</span><br/><span class=\"storage-standard\">[num_photos] foton<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>baserat på i genomsnitt 4 MB per foto",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "[capacity]&nbsp;GB HDD"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "[capacity]&nbsp;GB SSD"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "[total_optane_memory] GB Intel® Optane™ minne + [convertedSSD] GB SSD"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGRING",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "[capacity] GB SSD-lagring",
                "tr_preTitle1": "[capacity] TB SSD-lagring",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Den här datorn har en SSD med [capacity] GB lagringsutrymme. Det innebär att du kan spara ett imponerande antal bilder, videor och program, och komma åt dem mycket snabbare än på en hårddisk.",
                    "tr_title": "En plats för allt på din dator"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Den här datorn har en SSD med [capacity] TB lagringsutrymme. Det innebär att du kan spara ett imponerande antal bilder, videor och program, och komma åt dem mycket snabbare än på en hårddisk.",
                    "tr_title": "Den här enheten har imponerande lagringsutrymme"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>LAGRING</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>LAGRING</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGRING",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "[capacity] GB HDD-lagring",
                "tr_preTitle1": "[capacity] TB HDD-lagring",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Hårddiskar är ett bra val för människor som vill ha stort utrymme direkt på datorn.",
                    "tr_title": "En plats för allt på din dator"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Hårddiskar är ett bra val för människor som vill ha stort utrymme direkt på datorn.",
                    "tr_title": "Den här enheten har imponerande lagringsutrymme"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>LAGRING</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGRING",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "HUR MYCKET HAR DATORN PLATS FÖR?",
                "tr_subtitle": "Du behöver inte gissa. Vi visar exakt vad du kan lagra på den här datorn.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB HDD"
                  },
                  {
                    "tr_title": "[XX] GB SSD"
                  },
                  {
                    "tr_title": "[total_capacity] GB Intel® SSD accelererad av [optane_memory_size] GB Intel® Optane™ minne"
                  },
                  {
                    "tr_title": "[total_capacity] TB Intel® SSD accelererad av [optane_memory_size] GB Intel® Optane™ minne"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;TB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;TB HDD"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>LAGRING</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>LAGRING</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>LAGRING</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>LAGRING</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>LAGRING</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>LAGRING</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGRING",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "[convertedSSD] GB SSD + [convertedHDD] GB HDD",
                "tr_preTitle1": "[convertedSSD] TB SSD + [convertedHDD] GB HDD",
                "tr_preTitle2": "[convertedSSD] GB SSD + [convertedHDD] TB HDD",
                "tr_preTitle3": "[convertedSSD] TB SSD + [convertedHDD] TB HDD",
                "titleArray": [
                  {
                    "tr_title": "En plats för allt på din dator",
                    "tr_title1": "Den här enheten har imponerande lagringsutrymme",
                    "tr_subtitle": "Den här datorn har en SSD med [convertedSSD] GB lagringsutrymme och en hårddisk på [convertedHDD] GB. Detta ger dig både enorm kapacitet och snabb lagringsprestanda.",
                    "tr_subtitle1": "Den här datorn har en SSD med [convertedSSD] TB lagringsutrymme och en hårddisk på [convertedHDD] GB. Detta ger dig både enorm kapacitet och snabb lagringsprestanda.",
                    "tr_subtitle2": "Den här datorn har en SSD med [convertedSSD] GB lagringsutrymme och en hårddisk på [convertedHDD] TB. Detta ger dig både enorm kapacitet och snabb lagringsprestanda.",
                    "tr_subtitle3": "Den här datorn har en SSD med [convertedSSD] TB lagringsutrymme och en hårddisk på [convertedHDD] TB. Detta ger dig både enorm kapacitet och snabb lagringsprestanda."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>LAGRING</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>LAGRING</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGRING",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Snabbare åtkomst till lagring med hög kapacitet med Intel® Optane™ minne med SSD.",
                "tr_title": "Stora filer? Inga problem",
                "tr_pretitle": "[total_capacity] GB Intel® SSD accelererad av [optane_memory_size] GB Intel® Optane™ minne",
                "tr_pretitle1": "[total_capacity] TB Intel® SSD accelererad av [optane_memory_size] GB Intel® Optane™ minne",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Den här revolutionerande lagringslösningen är den första med Intel® Optane™ minne och en solid state-disk på ett enda chip. Det innebär följande för dig:",
                    "tr_title": "VAD ÄR INTEL® OPTANE™ MINNE MED SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Öppna det du behöver snabbt",
                    "tr_badgeSubTitle": "Filer du använder ofta läses in i förväg vilket innebär att du kan ägna mer tid åt att skapa och mindre tid åt att vänta.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Lagring plus hastighet",
                    "tr_badgeSubTitle": "Få tillgång till det utrymme du behöver för mediefiler med samma prestanda som för en SSD-hårddisk.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Snabbare ju mer du använder det",
                    "tr_badgeSubTitle": "Intel® Optane™ minne med SSD har funktioner för att lära sig hur du arbetar, vilket gör datorn mer effektiv.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGRING",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Ha alla dina mediefiler nära till hands med gott om pålitligt lagringsutrymme.",
                "tr_title": "Utrymme för alla dina foton, videor och låtar"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bildskärm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] miljoner pixlar att svepa, nypa ihop och häpna över",
                "tr_subtitle": "Interagera med din dator precis som en smartphone med intuitiva kontroller och skarpare, mer levande detaljer.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Upplösning: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixlar: [screenResolution] miljoner"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]-tums bildskärm",
                    "tr_badgeSubTitle": "Bildskärmshöjd: [screenHeight] tum",
                    "tr_badgePreTitle": "Bildskärmsbredd: [YY] tum"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Pekskärm",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bildskärm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Bli uppslukad i [screenResolution] miljoner pixlar",
                "tr_subtitle": "Det hänger på pixlarna. Ju fler du har, desto skarpare och livfullare blir dina bilder.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Upplösning: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixlar: [screenResolution] miljoner"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]-tums bildskärm",
                    "tr_badgeSubTitle": "Bildskärmshöjd: [screenHeight] tum",
                    "tr_badgePreTitle": "Bildskärmsbredd: [YY] tum"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bildskärm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] tum <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "FÖNSTRET TILL DINA SPELVÄRLDAR",
                "tr_subtitle": "Precis som när du ska välja tv är det storlek och upplösning som är de viktigaste faktorerna för en bildskärm.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Upplösning: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixlar: [screenResolution] miljoner"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]-tums bildskärm",
                    "tr_badgeSubTitle": "Bildskärmshöjd: [screenHeight] tum",
                    "tr_badgePreTitle": "Bildskärmsbredd: [screenWidth] tum"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Pekskärm"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bildskärm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Den här enheten har en imponerande bildskärm",
                "tr_subtitle": "Med en upplösning åtta gånger högre än HD kommer dina videor och spel att nå en helt ny nivå av skärpa och färg.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Upplösning: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixlar: [screenResolution] miljoner"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]-tums bildskärm",
                    "tr_badgeSubTitle": "Bildskärmshöjd: [screenHeight] tum",
                    "tr_badgePreTitle": "Bildskärmsbredd: [YY] tum"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bildskärm",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] miljoner pixlar att trycka på, svepa och röra",
                    "tr_subTitle": "Se och interagera med allt du gillar i en skarpare och mer detaljrik bild."
                  },
                  {
                    "tr_title": "[screenResolution] miljoner pixlar gör din digitala värld ännu bättre",
                    "tr_subTitle": "Det hänger på pixlarna. Ju fler du har, desto skarpare och livfullare blir dina bilder."
                  },
                  {
                    "tr_title": "Den här enheten har en imponerande bildskärm",
                    "tr_subTitle": "Nästan fyra gånger så många pixlar som HD tar dina videoklipp och spel till en helt ny nivå när det kommer till skärpa och färg."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Anslutning",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Thunderbolt™ 3 teknologin ger den snabbaste och mest mångsidiga anslutningen till alla typer av dockor, bildskärmar och dataenheter.",
                "tr_title": "En port som klarar allt",
                "tr_title1": "EN PORT SOM KLARAR ALLT",
                "titleArray": [
                  {
                    "tr_title": "HUR KAN JAG ANVÄNDA THUNDERBOLT™ 3 TEKNOLOGIN?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 3 teknologi"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "4K-skärmar",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Anslut till bildskärmar med enastående upplösning, kontrast och färg"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Externt grafikkort",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Uppgradera datorns grafik på ett ögonblick"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Snabba nätverk",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Ger en snabb peer-to-peer-anslutning"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Anslutning",
                "detailBGUrl": "",
                "tr_subtitle": "Thunderbolt™ 3 teknologin överför data med högre hastighet än vanlig USB. Du kan snabbt flytta filer och enkelt använda kringutrustning som flera bildskärmar eller externa grafikkort.",
                "tr_title": "Thunderbolt™ 3 teknologi",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "BLIXTSNABB DATA",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbit/s",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbit/s",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Thunderbolt™ 3 teknologi",
                    "tr_badgeSubTitle": "40 Gbit/s",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Anslutning",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>En universell port och kabel som stöder alla dina favorittillbehör.</li> <li>Pålitlig laddning av bärbar dator.</li> <li>Robust certifiering som ger sinnesro.</li></ul>",
                "tr_title": "Äkta universell kabelanslutning",
                "tr_title1": "ÄKTA UNIVERSELL KABELANSLUTNING",
                "tr_noteSection": "<sup>†</sup>Baserat på de lägsta specifikationskraven",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "HUR KAN JAG ANVÄNDA THUNDERBOLT™ 4 TEKNOLOGIN?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 4 teknologi"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Skapa",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Anslut en extern monitor och en snabb lagringsenhet för att titta på och redigera foton och videoklipp."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Docka",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Skapa ett rent och trasselfritt skrivbord genom att ansluta din bärbara dator till alla dina tillbehör med en enda kabel."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Spel",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Lägg till mer SSD- och HDD-kapacitet med hastigheter upp till 3 000 MB/s för att lagra spelbibliotek och mediafiler."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximal prestanda<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4X snabbare än USB* 3.2 <br/>2X snabbare än HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Anslutning",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Njut av samma fantastiska prestanda som Thunderbolt™ 3 teknologin med ännu fler funktioner som gör att du enkelt kan ansluta och använda de senaste Thunderbolt™ dockorna, bildskärmarna och snabb lagring eller något USB*-tillbehör.",
                "tr_title": "Thunderbolt™ 4 teknologi",
                "tr_pretitle": "ÄKTA UNIVERSELL KABELANSLUTNING",
                "tr_noteSection": "<sup>†</sup>Baserat på de lägsta specifikationskraven",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "En Thunderbolt™ 4 universalkabel kan ersätta alla dina USB-C* kablar så att du snabbt och enkelt kan välja en kabel."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Thunderbolt™ 4 teknologi erbjuder upp till fyra Thunderbolt™ portar för mer flexibilitet att ansluta något av dina tillbehör."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximal prestanda<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4X snabbare än USB* 3.2 <br/>2X snabbare än HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Anslutning",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>En universell port och kabel som stöder alla dina favorittillbehör.</li> <li>Pålitlig laddning av bärbar dator.</li> <li>Robust certifiering som ger sinnesro.</li></ul>",
                "tr_title": "Maximera din upplevelse med Thunderbolt™ tillbehör",
                "tr_noteSection": "<sup>†</sup>Baserat på de lägsta specifikationskraven",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "HUR KAN JAG ANVÄNDA THUNDERBOLT™ 4 TEKNOLOGIN?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Skapa",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Anslut en extern monitor och en snabb lagringsenhet för att titta på och redigera foton och videoklipp."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Docka",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Skapa ett rent och trasselfritt skrivbord genom att ansluta din bärbara dator till alla dina tillbehör med en enda kabel."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Spel",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Lägg till mer SSD- och HDD-kapacitet med hastigheter upp till 3 000 MB/s för att lagra spelbibliotek och mediafiler."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximal prestanda<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4X snabbare än USB* 3.2 <br/>2X snabbare än HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "De vanligaste typerna av Wi‑Fi är:",
                "tr_title": "LÄR DIG MER OM Wi‑Fi-STANDARDER",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 ger högre hastigheter även när många användare använder samma signal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Med Intels senaste Wi-Fi-innovation kan du uppnå snabbare hastighet än gigabit-Wi-Fi, även när flera användare använder samma signal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Det största framsteget för Wi‑Fi under de senaste 20 åren är Wi‑Fi 6E, som öppnar upp nya höghastighetskanaler för förbättrad prestanda och pålitlighet samt färre störningar."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 ger högre hastigheter även när många användare använder samma signal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Alla fördelar med Wi-Fi 6E förenat med en Intel® Killer™ prioriteringsmotor som växlar bandbredd till de webbplatser och program som behöver det mest."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Med mindre konkurrens på dina höghastighetskanaler kommer du att få bättre prestanda och tillförlitlighet."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 ger högre hastigheter även när många användare använder samma signal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Med mindre störningar från Wi&#8209;Fi&nbsp;6 och Intel® Killer™ prioriteringsmotorns intelligenta prestanda kommer din onlineupplevelse att vara snabbare och mer responsiv.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Med mindre konkurrens på dina höghastighetskanaler kommer du att få bättre prestanda och tillförlitlighet."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Wi-Fi-hastigheter på över 5 Gbps för datorer med konsekvent ultra låg latens – dela filer på sekunder jämfört med minuter."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) är den senaste teknologin som aktiverar extrem tillförlitlighet, extremt låg latens och extrema hastigheter med hjälp av AI-driven nätverksoptimering."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 ger högre hastigheter även när många användare använder samma signal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) levererar 1 Gbit/s, tillförlitlighet och låg latens med hjälp av 6 GHz-kanaler."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Bättre effektivitet, kapacitet och hastighet jämfört med Wi-Fi 5 på överbelastade nätverk."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Håll dig uppkopplad med Wi‑Fi [XX] och surfa med högre hastigheter<sup>†</sup> och bättre pålitlighet.",
                "tr_title": "Få bättre räckvidd och hastighet",
                "tr_noteSection": "<sup>†</sup>Verklig trådlös genomströmning och/eller räckvidd varierar.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "I dessa tider av flerspelarlägen och onlinespel är det viktigare än någonsin att hålla sig uppkopplad. Utöver hastighet behöver du en trådlös signal som kan hålla samma höga hastigheter på avstånd.",
                "tr_title": "[XX]",
                "tr_pretitle": "ANSLUTNING ÄR NYCKELN",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med Intel® Wi‑Fi 6 (Gig+) kan du ansluta till Wi‑Fi 6-routers med stöd för kanaler på upp till 160 MHz och få snabbare anslutningar och större pålitlighet för mer aktiva användare.",
                "tr_title": "Nästan tre gånger snabbare hastigheter med Intel® <span class='no-warp'>Wi‑Fi 6 </span>(Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Än standard AC Wi‑Fi. Välj funktioner som endast är tillgängliga med vissa SKU:er. Hör med tillverkaren för detaljer. Om du vill läsa mer om Wi‑Fi besöker du intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Wi-Fi 6 på ingångsnivå och med två band ger bättre prestanda för mindre krävande uppgifter online som e-post och webbsurf på överbelastade nätverk.<sup>9</sup>",
                "tr_title": "Grundläggande Wi‑Fi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "I dessa tider av flerspelarlägen och onlinespel är det viktigare än någonsin att hålla sig uppkopplad. Utöver hastighet behöver du en trådlös signal som kan hålla samma höga hastigheter på avstånd.",
                "tr_title": "[XX]",
                "tr_pretitle": "ANSLUTNING ÄR NYCKELN",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Med dessa nya Wi‑Fi-kanaler behöver du inte dela bandbredden med de äldre enheterna i ditt grannskap, vilket ger dig bufferfri strömning, snabb surfning och sömlöst distansarbete och lärande.",
                "tr_title": "Slipp samsas om anslutningen",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Håll dig uppkopplad med [XX] och upplev internet med högre hastigheter<sup>†</sup> och bättre tillförlitlighet.",
                "tr_title": "Få bättre räckvidd och hastighet",
                "tr_noteSection": "<sup>†</sup>Verklig trådlös genomströmning och/eller räckvidd varierar.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "I dessa tider av flerspelarlägen och onlinespel är det viktigare än någonsin att hålla sig uppkopplad. Utöver hastighet behöver du en trådlös signal som kan hålla samma höga hastigheter på avstånd.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "ANSLUTNING ÄR NYCKELN",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Inbyggt ultrasnabbt Wi‑Fi",
                "tr_subtitle": "Koppla ur sladden utan att sluta vara ansluten till din onlinevärld."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Dedikerade höghastighetsbanor för mindre störningar och prioriteringsmotorn Intel® Killer™ som sätter spelandet först innebär en stor uppgradering för onlinespel.",
                "tr_title": "Lämna konkurrenterna bakom dig",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Dedikerade höghastighetsbanor för mindre störningar och prioriteringsmotorn Intel® Killer™ som sätter spelandet först innebär en stor uppgradering för onlinespel.",
                "tr_title": "[XX]",
                "tr_pretitle": "LÄMNA KONKURRENTERNA BAKOM DIG",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Med dedikerade höghastighetsbanor för mindre störningar som sätter spelande först har onlinespelande fått en rejäl uppgradering.",
                "tr_title": "[XX]",
                "tr_pretitle": "LÄMNA KONKURRENTERNA BAKOM DIG",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi-Fi accelererar automatiskt nätverkstrafik för de program som behöver det mest så att dina strömmade videor har skarpa detaljer och buffrar mindre.",
                "tr_title": "Ett snabbare, smartare sätt att ansluta.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "I dessa tider av flerspelarlägen och onlinespel är det viktigare än någonsin att hålla sig uppkopplad. Utöver hastighet behöver du en trådlös signal som kan hålla samma höga hastigheter på avstånd.",
                "tr_title": "[XX]",
                "tr_pretitle": "ANSLUTNING ÄR NYCKELN",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Håll dig uppkopplad med Wi-Fi 5 och upplev internet med högre hastigheter <sup>†</sup> och bättre pålitlighet.",
                "tr_title": "Få bättre räckvidd och hastighet",
                "tr_noteSection": "<sup>†</sup>Verklig trådlös genomströmning och/eller räckvidd varierar.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "I dessa tider av flerspelarlägen och onlinespel är det viktigare än någonsin att hålla sig uppkopplad. Utöver hastighet behöver du en trådlös signal som kan hålla samma höga hastigheter på avstånd.",
                "tr_title": "[XX]",
                "tr_pretitle": "LÄMNA KONKURRENTERNA BAKOM DIG",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Wi-Fi 7-funktioner kommer att bygga på och höja tidigare Wi-Fi-generationer. Detta kommer att innebära inte bara snabbare hastigheter, utan också dramatiskt förbättrad svarstid och tillförlitlighet för uppslukande konsumentupplevelser och sofistikerade framtida teknologier.",
                "tr_title": "Trådliknande dator-nätverk",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Wi-Fi 7-funktioner kommer att bygga på och höja tidigare Wi-Fi-generationer. Detta kommer att innebära inte bara snabbare hastigheter, utan också dramatiskt förbättrad svarstid och tillförlitlighet för uppslukande konsumentupplevelser och sofistikerade framtida teknologier.",
                "tr_title": "[XX]",
                "tr_pretitle": "OMVANDLAR ANVÄNDARUPPLEVELSER",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Förbättrad hastighet, latens och tillförlitlighet med hjälp av AI-baserad nätverksoptimering",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Förbättrad hastighet, latens och tillförlitlighet med hjälp av AI-baserad nätverksoptimering.",
                "tr_title": "[XX]",
                "tr_pretitle": "TRÅDLIKNANDE RESPONSIVITET",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Hur mäts det?",
                    "tr_badgeSubTitle": "De flesta tillverkare förmedlar batteritiden i timmar. Även om det här sättet att räkna inte är 100&nbsp;% korrekt så kan det hjälpa dig att jämföra olika datorers batteritid."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "FÖR DIG SOM VILL SPELA PÅ SPRÅNG",
                "tr_subtitle": "Med dagens kraftfulla bärbara speldatorer finns det ingen anledning att sitta fast vid ett skrivbord. Dina spel kan nu färdas var som helst.",
                "tr_title": "[Dynamic.battery.values.hours] timmars batteritid"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Koppla ur sladden och fortsätt att använda program, spela spel och lyssna på musik i flera timmar.",
                "tr_title": "[XX] timmars strömning på en enda laddning<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Baserat på datortillverkarens angivna batteritid.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] timmar",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Koppla ur sladden och fortsätt att använda program, spela spel och lyssna på musik i flera timmar.",
                "tr_title": "[Dynamic.battery.values.hours] timmars kraft på språng<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Baserat på datortillverkarens angivna batteritid.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] timmar",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Koppla ur sladden och fortsätt att använda program, spela spel och lyssna på musik i flera timmar.",
                "tr_title": "[Dynamic.battery.values.hours] timmar för batteritid hela dagen på en enda laddning<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Baserat på datortillverkarens angivna batteritid.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] timmar",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Den här datorn optimerar ditt batteri att hålla längre och ger dig minst 4 timmars batteritid på en 30-minuters laddning.<sup>4</sup>",
                "tr_title": "Var urkopplad längre med en batteritid på [Dynamic.battery.values.hours] timmar<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Baserat på datortillverkarens angivna batteritid.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] timmar",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Hem",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Varför den här datorn",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Visa alla specifikationer",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Remote",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Mobila enheter",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Juridisk information",
        "tr_paragraph_array": [
          "Programvara och arbetsbelastningar som används i prestandatest kan ha anpassats särskilt för att användas på Intel® mikroprocessorer. Prestandatest som SYSmark* och MobileMark* mäts med hjälp av särskilda datorsystem, komponenter och funktioner. Om någon av de här faktorerna ändras kan även utfallet av testet ändras. Du bör jämföra med annan information och andra prestandatest för att få en helt rättvis bild inför köpet, inklusive prestanda för den tänkta produkten i kombination med andra produkter. För mer information om prestanda och jämförande resultat gå in på www.intel.com/benchmarks.",
          "¹Intel® Wi‑Fi 6-produkter (Gig+)-produkter stöder valfria 160 MHz-kanaler vilket möjliggör den snabbast möjliga teoretiska hastigheten (2 402 Mbit/s) för vanliga 2x2 802.11ax Wi‑Fi-produkter för datorer. Förstklassiga Intel® Wi‑Fi 6-produkter (Gig+) möjliggör 2–4X snabbare teoretiska hastigheter jämfört med 2x2 (1201 Mbit/s) eller 1x1 (600 Mbit/s) 802.11ax Wi‑Fi-produkter för datorer, vilka endast uppfyller det obligatoriska kravet på 80 MHz-kanaler.",
          "²Rekommenderas endast i informationssyfte. Rådfråga andra källor innan du fattar ett köpbeslut.",
          "³Egenskaperna och fördelarna med Intel tekniker varierar beroende på systemkonfiguration och kan kräva aktiverad maskinvara, programvara och aktivering av tjänst. Prestandan varierar beroende på systemkonfiguration. Inget datorsystem kan vara helt säkert. Fråga tillverkaren eller återförsäljaren, eller läs mer på intel.com.",
          "⁴För system med FHD-bildskärmar, när de används för trådlös surf. Avstängd, från standardavstängningsnivå för OEM.",
          "⁵I jämförelse med andra anslutningstekniker inklusive eSATA, USB och IEEE 1394* FireWire. Prestanda varierar beroende på vilken maskinvara och programvara som används. Måste använda en enhet som stöder Thunderbolt™ teknologin.",
          "⁶Nästan 3X snabbare: 802.11ax med 2x2 160 MHz innebär en maximal teoretisk dataöverföringshastighet på 2 402 Mbit/s. Ungefär 3X snabbare än överföringshastigheten för 802.11ac med 2x2 80 MHz (867 Mbit/s) enligt IEEE-specifikationerna* för trådlösa överföringar med 802.11. En nätverksrouter för trådlösa överföringar med rätt 802.11ax-konfiguration krävs.",
          "⁷75 % latensreducering: är baserad på Intel:s simuleringsdata (79 %) av 802.11ax med och utan OFDMA med hjälp av 9 klienter. Genomsnittlig latens utan OFDM är 36 ms, med OFDMA så är genomsnittlig latens reducerad till 7,6 ms. Förbättring latens kräver att 802.11ax-routern (Wi-Fi 6) och alla klienter stöder OFDMA.",
          "⁸Baserat på den teoretiska jämförelsen av data för högsta datahastighet i 802.11-specifikationen så kan 2 x 2 160 MHz Wi-Fi 6/6E (802.11ax) leverera 2 402 Mbit/s jämfört med 2 x 2 80 MHz Wi-Fi 5 (802.11ac) som kan leverera 867 Mbit/s som motsvarar 2,8X snabbare hastigheter.",
          "⁹Klassledande Wi-Fi 6: Intel® Wi-Fi 6-produkter (Gig+) har stöd för 160 MHz-kanaler som tillval, vilket ger snabbast möjliga teoretiska maxhastigheter (2 402 Mbit/s) för vanliga 2 x 2 802.11ax Wi-Fi-produkter för datorer. Förstklassiga Intel® Wi-Fi 6-produkter (Gig+) möjliggör 2–4X snabbare teoretiska maxhastigheter jämfört med 2 x 2 (1 201 Mbit/s) eller 1 x 1 (600 Mbit/s) 802.11ax Wi-Fi-produkter för datorer, vilka endast uppfyller det obligatoriska kravet på 80 MHz-kanaler.",
          "Endast utvalda SKU:er. Funktionalitet varierar med OEM. Fråga OEM eller återförsäljare för mer information. För mer information om Wi-Fi besöker du intel.com/wifi6disclaimers.",
          "¹⁰Thunderbolt™ 4 teknologi på 40 Gbit/s levererar den snabbaste, enklaste och mest tillförlitliga kabellösningen till alla dockor, skärmar eller dataenheter i jämförelse med andra anslutningstekniker för PC inklusive eSATA*, USB* och IEEE 1394* FireWire.",
          "¹¹Kräver en router baserad på 802.11ax som stöder OFDMA och flera klienter på nätverket med stöd för AX. Bättre prestanda i täta miljöer kan uppnås med OFDMA-funktionen som stöds av 802.11ax-klienter och -åtkomstpunkter. 2 Gbit/s baserat på antaganden om cirka 70 % av IEEE 802.11* specifikation av maximala teoretiska datahastigheter på 802.11ax 160 MHz 2 402 Mbit/s.",
          "¹²Enligt mätningar av öppning av ett dokument med bakgrundsaktivitetsbelastning i jämförelse med en 8:e gen:s Intel® Core™ i7-8565U processor (SSD-hårddisk av TCL-typ på 512 GB) och en 8:e gen:s Intel® Core™ i7-8565U processor (32 GB Intel® Optane™ minne av H10-typ + Solid State-lagring på 512 GB).",
          "¹³Intel® Thread Director ingår i 12:e gen:s Intel® Core™ processorer och hjälper till att stödja operativsystem för att smartare kanalisera arbetsbelastningar till rätt kärna. Ingen användaråtgärd krävs. Besök intel.com för mer information.",
          "¹⁴Inte tillgänglig på vissa 12:e gen:s Intel® Core™ processorer. Prestandahybrid-arkitektur kombinerar två nya kärnor av mikroarkitektur, Performance-cores (P-cores) och Efficient-cores (E-cores), på en enda processorkrets. Välj 12:e gen:s Intel® Core™ processorer (vissa 12:e gen:s Intel® Core™ i5 processorer och lägre stöder inte prestandahybrid-arkitektur, endast P-cores).",
          "¹⁵Enligt vad som uppmätts vid arbetsbelastning för spelstart vid jämförelse av en 8:e generationens Intel® Core™ i7-8750H processor (32&nbsp;GB Intel® Optane™ minnesmodul + Intel® SSD 256&nbsp;GB PCIe* + 1&nbsp;TB HDD) och en 8:e generationens Intel® Core™ i7-8750H processor (Intel® SSD 256&nbsp;GB PCIe* + 1&nbsp;TB HDD). Arbetsbelastning vid spelstart – Arbetsbelastning som utvecklats av Intel med mätning av tidsåtgång för att starta Total War*: WARHAMMER* II version: 5577.0 och öppna huvudmenyn med inaktiverade introvideosekvenser. Intel® Optane™ minne – accelerationskonfigurationer för dataenhet: Intel® Core™ i7-8750H processor, PL1=45&nbsp;W TDP, 6C12T, turbo upp till 4&nbsp;GHz på OEM-system i förproduktion, grafik: NVIDIA* GeForce* GTX 1070, minne: 2&nbsp;x&nbsp;4&nbsp;GB DDR4, lagring: Intel® SSD-serien 760p 256&nbsp;GB (PCIe*) + 1&nbsp;TB HDD + 32&nbsp;GB Intel® Optane™ minne, operativsystem: Windows* 10 RS3 version 1709, MCU 0x84; Intel® Core™ i7-8750H processor, PL1=45&nbsp;W TDP, 6C12T, turbo upp till 4&nbsp;GHz på OEM-system i förproduktion, grafik: NVIDIA* GeForce* GTX 1070, minne: 2&nbsp;x&nbsp;4&nbsp;GB DDR4, lagring: Intel® SSD 760p 256&nbsp;GB (PCIe*) + 1&nbsp;TB HDD, operativsystem: Windows* 10 RS3 version 1709, MCU 0x84.",
          "¹⁶Enligt vad som uppmätts vid arbetsbelastning för nivåinläsning vid jämförelse av en 8:e generationens Intel® Core™ i7-8750H processor (32&nbsp;GB Intel® Optane™ minnesmodul) och en 8:e generationens Intel® Core™ i7-8750H processor (endast HDD). Arbetsbelastning för nivåinläsning – Arbetsbelastning utvecklad av Intel för mätning av tidsåtgång från huvudmenyn till slutförd nivåinläsning för Total War*: WARHAMMER* II version: 5577.0. Intel® Optane™ minne – accelerationskonfigurationer för dataenhet: Intel® Core™ i7-8750H processor, PL1=45&nbsp;W TDP, 6C12T, turbo upp till 4&nbsp;GHz på OEM-system i förproduktion, grafik: NVIDIA* GeForce* GTX 1070, minne: 2&nbsp;x&nbsp;4&nbsp;GB DDR4, lagring: Intel® SSD-serien 760p 256&nbsp;GB (PCIe*) + 1&nbsp;TB HDD + 32&nbsp;GB Intel® Optane™ minne, operativsystem: Windows* 10 RS3 version 1709, MCU 0x84; Intel® Core™ i7-8750H processor, PL1=45&nbsp;W TDP, 6C12T, turbo upp till 4&nbsp;GHz på OEM-system i förproduktion, grafik: NVIDIA* GeForce* GTX 1070, minne: 2&nbsp;x&nbsp;4&nbsp;GB DDR4, lagring: Intel® SSD 760p 256&nbsp;GB (PCIe*) + 1&nbsp;TB HDD, operativsystem: Windows* 10 RS3 version 1709, MCU 0x84.",
          "¹⁷På Performance-cores. Prestandan varierar beroende på användning, konfiguration och andra faktorer. Läs mer på www.intel.com/PerformanceIndex.",
          "¹⁸Genom att ändra klockfrekvensen eller spänningen kan du skada eller minska livslängden för processorn och andra systemkomponenter samt försämra systemstabiliteten och prestanda. Produktgarantier kan utgå om processorn körs bortom dess specifikationer. Kontrollera med tillverkaren av systemet och komponenterna för ytterligare information.",
          "¹⁹Enligt mätningar av Intel vPro® plattformens oöverträffade kombination av ovannämnda funktioner och säkerhetsfunktionerna, app- och dataskydd samt avancerat hotskydd för både stora och små företag samt Intels första säkerhetsmetod för produktdesign, tillverkning och support. Alla företagsdatorer med Intel vPro® plattformen har validerats mot rigorösa specifikationer, inklusive unika maskinvarubaserade säkerhetsfunktioner. Besök www.intel.com/PerformanceIndex (plattformar) för mer information. Ingen produkt eller komponent kan vara helt säker.",
          "²⁰Intel® Connectivity Performance Suite är endast tillgängligt på Windows*-system. Intel Over The Air (OTA) Wi-Fi-tester på ett överbelastat nätverk jämfört med trådlös trafikprioritering utan ICPS-programvara och smart åtkomstpunktsväxling. Besök www.intel.com/PerformanceIndex (anslutning) för mer information. Resultat kan variera.",
          "²¹Jämfört med tidigare processorgeneration.",
          "²²Intel® Arc™ grafik är först på marknaden med stöd för AV1-kodning (från och med första kvartalet 2022). Mer information på intel.com/performanceindex.",
          "²³Enligt de unika egenskaperna hos Intel® Evo™, som också verifieras för att uppfylla viktiga gränsvärden för mobila användarupplevelser. Testning från och med februari 2022.",
          "²⁴Intel® Unison™ lösning är för närvarande endast tillgängligt på kvalificerade Windows*-baserade datorer med Intel® Evo™ och parkopplar endast med Android*- eller iOS*-baserade telefoner. Alla enheter måste ett operativsystem med en version som stöds.",
          "²⁵Maximala minneshastigheter är associerade med konfigurationer med 1 DIMM per kanal (1DPC). Ytterligare DIMM-belastning på någon kanal kan påverka den maximala minneshastigheten. Upp till DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 och DDR5-5200 1Rx8, 1Rx16, 2Rx8 på utvalda SKU:er. Maximal minneskapacitet är uppnåbar med 2DPC-konfigurationer.",
          "²⁶Jämfört med standard-Wi-Fi. Besök intel.com/performance-wireless för mer information. Resultat kan variera.",
          "²⁷Prestanda-hybridarkitektur kombinerar två kärnmikroarkitekturer, Performance-cores (P-cores) och Efficient-cores (E-cores) på en enda processor som först introducerades på 12:e gen:s Intel® Core™ processorer. Valda 12:e generationens och nyare Intel® Core™ processorer har inte prestanda-hybridarkitektur, endast P-cores och har samma cachestorlek som tidigare generation. Se ark.intel.com för SKU-information.",
          "²⁸Intel® Thread Director, som är inbyggd i maskinvaran, finns endast i konfigurationer med prestandahybrid-arkitektur av 12:e gen:s eller nyare Intel® Core™ processorer. OS-aktivering är nödvändig. Tillgängliga funktioner och funktionalitet varierar beroende på OS.",
          "²⁹Intel® Thread Director, som är inbyggd i maskinvaran, finns endast i konfigurationer med prestandahybrid-arkitektur av 12:e och 13:e gen:s Intel® Core™ processorer. OS-aktivering är nödvändig. Tillgängliga funktioner och funktionalitet varierar beroende på OS.",
          "³⁰Enligt mätning av referensvalideringsplattformens prestanda för Intel®&nbsp;Core™ Ultra mobilprocessor (Serie&nbsp;2, kodnamn: Lunar Lake) kontra Intel®&nbsp;Core™ Ultra mobilprocessor (Serie&nbsp;1, kodnamn: Meteor Lake) på 3DMark Time Spy. Mer information finns på intel.com/performanceindex. Resultat kan variera.",
          "³¹Bakgrundsoskärpa och ansiktsspårning finns endast tillgängligt på Windows*-system.",
          "³²Wi-Fi 7-produkter kan få åtkomst till 320 MHz-kanaler i 6 GHz och nya 160 MHz-kanalkombinationer i 5 GHz med nya funktioner för punktering av flera resursenheter.",
          "³³Baserat på IEEE-specifikationerna för trådlösa överföringar och högsta teoretiska dataöverföringshastigheter för enheter med dubbla dataströmmar.",
          "³⁴Intel-simuleringar av överbelastade nätverksmiljöer indikerar att det är möjligt med stora latensminskningar med nya W-Fi 7-funktioner för åtgärd med flera länkar.",
          "³⁵Minsta laddning uppnådd från avstängningsnivå enligt OEM-standard. Individuella systemresultat kan variera. Besök intel.com/performance-evo för mer information.",
          "³⁶Wi-Fi-bandet på 6 GHz är inte tillgängligt för användning i alla länder/regioner.",
          "³⁷Endast Windows*",
          "³⁸Laddning uppnådd från avstängningsnivå enligt OEM-standard. Mer information om prestanda och resultat i prestandatest finns på intel.com/Evo.",
          "³⁹Avser Intel®&nbsp;Core™ Ultra mobilprocessorer (Serie&nbsp;2), baserat på de unika maskinvarubaserade säkerhetsfunktionerna, en oöverträffad kombination av ovanstående och nedanstående OS-säkerhetsfunktioner, imponerande prestanda även vid lägre strömförbrukning kontra tidigare generation (från september 2024). Mer information finns på intel.com/performanceindex. Resultat kan variera.",
          "⁴⁰Programvaruapplikationer visas endast i illustrativt syfte. AI-funktioner kan kräva ytterligare inköp av programvara, prenumeration eller aktivering av en programvaru- eller plattformsleverantör, eller kan ha specifika konfigurations- eller kompatibilitetskrav. Mer information finns på www.intel.com/PerformanceIndex. Resultat kan variera. © Intel Corporation.",
          "⁴¹Bilder som visas kan ha ändrats eller simulerats. AI-funktioner kan kräva ytterligare inköp av programvara, prenumeration eller aktivering av en programvaru- eller plattformsleverantör, eller kan ha specifika konfigurations- eller kompatibilitetskrav. Mer information finns på www.intel.com/AIPC.",
          "⁴²Lanseras gradvis i förhandsversion inom den senaste uppdateringen till Windows* 11 på utvalda globala marknader. Tillgängligheten varierar beroende på enhet och marknad. Mer information finns på aka.ms/WindowsAIFunktioner. © Intel Corporation.",
          "⁴³Intel® Unison™ lösning är för närvarande tillgänglig för kvalificerade modeller. Mer information finns på www.intel.com/PerformanceIndex.",
          "⁴⁴FPS uppskalad av Intel® X<sup>e</sup>SS kontra ursprunglig FPS enligt mätning med Dying Light 2 Stay Human på Intel® Core™ Ultra 7 processor 165H. Mer information finns på www.intel.com/PerformanceIndex. Resultat kan variera.",
          "⁴⁵Enligt mätning av lägre SoC-kraft på referensvalideringsplattformen med Intel®&nbsp;Core™ Ultra mobilprocessor (Serie&nbsp;2, kodnamn: Lunar Lake) kontra referensvalideringsplattformen med Intel®&nbsp;Core™ Ultra mobilprocessor (Serie&nbsp;1, kodnamn: Meteor Lake) på YouTube* 4K 30 FPS AV1. Mer information finns på intel.com/performanceindex. Resultat kan variera.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Intel ansvarar inte för felaktiga prisangivelser.",
          "*Övriga namn och varumärken tillhör respektive rättighetshavare.",
          "COPYRIGHT 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES OCH RELATERAD DESIGN- OCH KOMPOSITMÄRKNING ÄR VARUMÄRKEN SOM TILLHÖR INFLEXION STUDIOS INC. MED ENSAMRÄTT.",
          "Intel teknik kan kräva kompatibel maskinvara, programvara eller tjänsteaktivering.",
          "Resultat och data från NERO* tillhandahålls endast som en referens och i informationssyfte. Intel Corporation bär inget ansvar för NERO*-appens exakthet eller prestandaresultat och betyg, eller potentiella variationer från andra nedladdningsbara versioner av den app som finns på www.nero.com eller andra webbplatser från tredje part. NERO*-prestandatest för datorer mäts med hjälp av specifika datorsystem, komponenter, programvara, operationer och funktioner. Om någon av de här faktorerna ändras kan även utfallet av testet ändras. Du bör jämföra med annan information och andra prestandatest för att få en helt rättvis bild inför köpet, inklusive prestanda för den tänkta produkten i kombination med andra produkter.",
          "Alla versioner av Intel vPro® plattformen kräver en passande Intel® Core™ processor, operativsystem som stöds, Intel® LAN och/eller WLAN, firmwareförbättringar samt annan maskinvara och programvara som krävs för att leverera användningsfallen för hanterbarhet, säkerhetsfunktioner, systemprestanda och stabilitet som definierar plattformen.",
          "Prestandan varierar beroende på användning, konfiguration och andra faktorer. Läs mer på www.intel.com/PerformanceIndex.",
          "Intel kontrollerar inte och granskar inte data från tredje part. Du bör konsultera andra källor för att utvärdera exaktheten.",
          "© Intel Corporation. Intel, Intels logotyp och andra Intel-märken är varumärken som tillhör Intel Corporation eller dess dotterbolag. *Övriga namn och varumärken tillhör respektive rättighetshavare.",
          "Ingen produkt eller komponent kan vara helt säker.",
          "Dina kostnader och resultat kan variera.",
          "Intel, Intels logotyp, Intel Core, Intel Optane, Intel Iris, Thunderbolt och Thunderbolt-logotypen är varumärken som tillhör Intel Corporation eller dess dotterbolag. Övriga namn och varumärken tillhör respektive rättighetshavare.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Hem",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Skanna för att jämföra",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Trender",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "Frigör nya AI-upplevelser med Intel® Core™ Ultra processorer",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "Frigör nya AI-upplevelser med Intel® Core™ Ultra processorer",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "Frigör nya AI-upplevelser med Intel® Core™ Ultra processorer",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "Stable Diffusion*: Text till bild",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe*: Fotoredigering",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "Intel® X<sup>e</sup>SS : Bilduppskalning",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee*: Deepfake Detector",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "Samarbetseffekter (Teams)",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "IQIYI: Gestkontroll",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "Synkronisera datorn med telefonen och visa aviseringar, samtal och SMS",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "Inbyggd fantastisk och levande grafik",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "Microsoft*: Ditt liv, på ditt sätt",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "Microsoft*: Allt du behöver. Allt med en blick, alltid ett svep bort",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "Kör processorn snabbare och vid högre frekvenser genom att finjustera systemet",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "Förbättra datorns eller mobilsystemets prestanda utan att offra lagringsutrymme",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "Förbättra datorns eller mobilsystemets prestanda utan att offra lagringsutrymme",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "Samsung OLED: En fröjd för ögat",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "Förbättrad hastighet, latens och tillförlitlighet med hjälp av AI-baserad nätverksoptimering",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "Trådliknande dator-nätverk",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "Trådliknande dator-nätverk",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "Nästan tre gånger snabbare hastigheter med Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+)",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "Slipp samsas om anslutningen",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "Äkta universell kabelanslutning",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "Spela som proffsen: Se hur Intel ger kraft åt spel och spelare",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "Spela som proffsen: Bli en mästare med Intel",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "VÄLKOMMEN",
    "tr_welcomePagetrSubtitle": "Fjärrstyr den här datorn med din smartphone",
    "tr_welcomePagembSubtitle": "Välkommen, utforska den här datorns specifikationer på din smartphone",
    "tr_welcomePageBtnText": "Kom igång",
    "tr_welcomePageText": "Utforska och jämför datorns specifikationer på din smartphone",
    "tr_scrollText": "Navigera enheten framför dig",
    "tr_toolTipTextc1": "Använd dessa knappar för att navigera till olika sidor på enheten framför dig.",
    "tr_toolTipTextc2": "Om du trycker på nedanstående kort kommer du att navigera till en detaljerad sida med varje enhets specifikationer.",
    "tr_toolTipTextc3": "Använd dessa knappar för att flytta upp och ner på bildskärmen framför dig.",
    "tr_toolTipTextc4": "Stäng av fjärrläget för att se enhetsspecifikationer på din smartphone.",
    "tr_toolTipTextmb1": "Tryck på kort för att visa detaljerade innehållssidor.",
    "tr_toolTipTextmb2": "Tryck för att visa en sammanfattning av alla enhetsspecifikationer",
    "tr_toolTipTextmb3": "Tryck på knappen för att skanna QR-koden och jämföra upp till fyra enheter",
    "tr_nextButton": "Nästa",
    "tr_prevButton": "Föregående",
    "tr_gotItButton": "JAG FÖRSTÅR",
    "tr_skipButton": "Hoppa över",
    "tr_placeholderText": "*platshållare",
    "tr_processorHeading": "Processor",
    "tr_processorSubheading": "10:e gen:s Intel® Core™ i7 processor",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "[XX] GB Intel®&nbsp;Optane™ minne +&nbsp;[YY]&nbsp;GB&nbsp;SSD",
    "tr_coachmarkProcessor": "XX processor",
    "tr_coachmarkGraphics": "[XX] grafik",
    "tr_coachmarkMemory": "Specificerat värde",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "999,95 USD",
    "tr_compare": "Jämför",
    "tr_viewallspecs": "VISA ALLA SPECIFIKATIONER",
    "tr_operatingSystem": "OS",
    "tr_AmdProcessorNonIntel": "AMD* processor",
    "tr_QualcommProcessorNonIntel": "Qualcomm* processor",
    "tr_GBText": "GB",
    "tr_remoteText": "Specifikationer som visas på datorskärmen",
    "tr_scanErrorText": "Du kan bara skanna upp till tre enheter på en gång. Ta bort en eller fler enheter.",
    "tr_scanErrorText2": "Du har redan skannat den här enheten, skanna en annan.",
    "tr_scanMsg": "Skanna för att lägga till enhet",
    "tr_backToScan": "Tillbaka till jämförelse",
    "tr_scanToCompare": "Skanna för att jämföra",
    "tr_compareDevice": "Jämför enheter",
    "tr_processorTitle": "PROCESSOR",
    "tr_graphicTitle": "GRAFIK",
    "tr_storageTitle": "LAGRING",
    "tr_displayTitle": "BILDSKÄRM",
    "tr_batteryTitle": "BATTERI",
    "tr_memoryTitle": "MINNE",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "ANSLUTNING",
    "tr_priceTitle": "PRIS",
    "tr_operatingSystemTitle": "OPERATIVSYSTEM",
    "tr_batteryHoursText": "[XX] timmar",
    "tr_hrsText": "tim",
    "tr_touchscreeenText": "[screenDiagonal]” pekskärm",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, pekskärm",
    "tr_FHDTouchScreenText": "FHD, pekskärm",
    "tr_4KTouchscreenText": "4K, pekskärm",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Spara",
    "tr_device_comparison": "Dokument för enhetsjämförelse [yyyy]-[mm]-[dd]",
    "tr_Save_As_JPEG": "Spara som JPEG",
    "tr_Save_As_PDF": "Spara som PDF",
    "tr_Downloading_PDF": "Laddar ner PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Öppna",
    "tr_logo": "logotyp",
    "tr_laptop_name": "Namn på bärbar dator",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Intel® Optane™ minne",
    "tr_inchUnit": "tum",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "INTEL® OPTANE™ MINNE",
    "tr_Thunderbolt3": "Thunderbolt™ 3 teknologi",
    "tr_Thunderbolt4": "Thunderbolt™ 4 teknologi",
    "tr_processorGraphicsCaps": "PROCESSORGRAFIK",
    "tr_processorGraphicsSmall": "Processorgrafik",
    "tr_graphicsCardCaps": "DEDIKERAD GRAFIK",
    "tr_graphicsCardSmall": "Dedikerad grafik",
    "tr_processorTitleCamel": "Processor",
    "tr_graphicTitleCamel": "Grafik",
    "tr_storageTitleCamel": "Lagring",
    "tr_displayTitleCamel": "Bildskärm",
    "tr_batteryTitleCamel": "Batteri",
    "tr_memoryTitleCamel": "Minne",
    "tr_connectivityTitleCamel": "Anslutning",
    "tr_priceTitleCamel": "Pris",
    "tr_operatingSystemTitleCamel": "Operativsystem",
    "tr_viewallspecsCamel": "Visa alla specifikationer",
    "tr_displayText": "[screenDiagonal]”",
    "tr_displayTextResolution": "[screenDiagonal]” [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "[screenDiagonal]” [screenResolutionType]-pekskärm",
    "tr_OS": "OS",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "Datorspecifikationer",
    "tr_explorePC_text": "Utforska allt om den här datorn",
    "tr_mtlPC_text": "Se vad utvecklad för att göra allt verkligen betyder",
    "tr_optaneMemory": "Intel® Optane™ minne",
    "tr_displayText_Display": "[screenDiagonal]” SKÄRM",
    "tr_displayTextResolution_Display": "[screenDiagonal]” [screenResolutionType]-SKÄRM",
    "tr_displayTextResolutionTouchscreen_Display": "[screenDiagonal]” [screenResolutionType]-PEKSKÄRM",
    "tr_displayTextTouchscreeenText": "[screenDiagonal]”-PEKSKÄRM",
    "tr_learnMoreLabel": "Läs mer",
    "tr_batteryHoursTextUpperCase": "[XX] TIMMAR",
    "tr_processorGraphicsIrisXe": "11:E GEN:S INTEL® CORE™ [cpu_fam] PROCESSOR OCH INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAFIK",
    "tr_processorGraphicsIrisXeMax": "11:E GEN:S INTEL® CORE™ [cpu_fam] PROCESSOR OCH INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAFIK",
    "tr_Thunderbolt3UpperCase": "THUNDERBOLT™ 3 TEKNOLOGI",
    "tr_Thunderbolt4UpperCase": "THUNDERBOLT™ 4 TEKNOLOGI",
    "tr_processorWithArcGraphics": "12:E GEN:S INTEL® CORE™ [cpu_fam] PROCESSOR OCH INTEL® ARC™ GRAFIK",
    "tr_processorGraphicsIrisXe12Gen": "12:E GEN:S INTEL® CORE™ [cpu_fam] PROCESSOR OCH INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAFIK",
    "tr_processorGraphicsIrisXeMax12Gen": "12:E GEN:S INTEL® CORE™ [cpu_fam] PROCESSOR OCH INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAFIK",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "13:E GEN:S INTEL® CORE™ [cpu_fam] PROCESSOR OCH INTEL® ARC™ GRAFIK",
    "tr_processorGraphicsIrisXe13Gen": "13:E GEN:S INTEL® CORE™ [cpu_fam] PROCESSOR OCH INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAFIK",
    "tr_processorGraphicsIrisXeMax13Gen": "13:E GEN:S INTEL® CORE™ [cpu_fam] PROCESSOR OCH INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAFIK",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "INTEL® ARC™ A350M GRAFIK",
      "tr_A370M": "INTEL® ARC™ A370M GRAFIK",
      "tr_A730M": "INTEL® ARC™ A730M GRAFIK",
      "tr_A770M": "INTEL® ARC™ A770M GRAFIK",
      "tr_A310": "INTEL® ARC™ A310 GRAFIK",
      "tr_A380": "INTEL® ARC™ A380 GRAFIK",
      "tr_A580": "INTEL® ARC™ A580 GRAFIK",
      "tr_A750": "INTEL® ARC™ A750 GRAFIK",
      "tr_A770": "INTEL® ARC™ A770 GRAFIK"
    },
    "tr_memoryValue": "[XX] GB RAM",
    "tr_memoryWithOptaneDesc": "[Value1] GB minne = [Value2] GB Intel® Optane™ minne + [Value3] GB RAM",
    "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;GB HDD",
    "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;TB HDD",
    "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textGBSSD": "[convertedSSD]&nbsp;GB SSD",
    "tr_textTBSSD": "[convertedSSD]&nbsp;TB&nbsp;SSD",
    "tr_textGBHDD": "[convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textTBHDD": "[convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textStorageWithOptaneGB": "[XX]&nbsp;GB Intel®&nbsp;Optane™ minne +&nbsp;[convertedSSD]&nbsp;GB&nbsp;SSD",
    "tr_textStorageWithOptaneTB": "[XX]&nbsp;GB Intel®&nbsp;Optane™ minne +&nbsp;[convertedSSD]&nbsp;TB&nbsp;SSD",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home i S-läge",
      "tr_OS21": "Windows* 10 Pro i S-läge",
      "tr_OS22": "Windows* 10 Enterprise i S-läge",
      "tr_OS23": "Windows* 11 Home i S-läge",
      "tr_OS24": "Windows* 11 Pro i S-läge",
      "tr_OS25": "Windows* 11 Enterprise i S-läge"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Intel® Arc™ A310 grafik",
      "tr_GR387": "Intel® Arc™ A350M grafik",
      "tr_GR388": "Intel® Arc™ A370M grafik",
      "tr_GR389": "Intel® Arc™ A380 grafik",
      "tr_GR390": "Intel® Arc™ A530M grafik",
      "tr_GR391": "Intel® Arc™ A550M grafik",
      "tr_GR392": "Intel® Arc™ A570M grafik",
      "tr_GR393": "Intel® Arc™ A580 grafik",
      "tr_GR394": "Intel® Arc™ A730M grafik",
      "tr_GR395": "Intel® Arc™ A750 grafik",
      "tr_GR396": "Intel® Arc™ A770 grafik",
      "tr_GR397": "Intel® Arc™ A770M grafik",
      "tr_GR398": "Intel® Arc™ grafik",
      "tr_GR399": "Intel® Arc™ Pro A30M grafik",
      "tr_GR400": "Intel® Arc™ Pro A40/A50 grafik",
      "tr_GR401": "Intel® Arc™ Pro A60 grafik",
      "tr_GR402": "Intel® Arc™ Pro A60M grafik",
      "tr_GR403": "Intel® Grafik",
      "tr_GR404": "Intel® HD-grafik 2000",
      "tr_GR405": "Intel® HD-grafik 2500",
      "tr_GR406": "Intel® HD-grafik 3000",
      "tr_GR407": "Intel® HD-grafik 400",
      "tr_GR408": "Intel® HD-grafik 4000",
      "tr_GR409": "Intel® HD-grafik 405",
      "tr_GR410": "Intel® HD-grafik 4200",
      "tr_GR411": "Intel® HD-grafik 4400",
      "tr_GR412": "Intel® HD-grafik 4600",
      "tr_GR413": "Intel® HD-grafik 500",
      "tr_GR414": "Intel® HD-grafik 5000",
      "tr_GR415": "Intel® HD-grafik 505",
      "tr_GR416": "Intel® HD-grafik 510",
      "tr_GR417": "Intel® HD-grafik 515",
      "tr_GR418": "Intel® HD-grafik 520",
      "tr_GR419": "Intel® HD-grafik 530",
      "tr_GR420": "Intel® HD-grafik 5300",
      "tr_GR421": "Intel® HD-grafik 5500",
      "tr_GR422": "Intel® HD-grafik 5600",
      "tr_GR423": "Intel® HD-grafik 6000",
      "tr_GR424": "Intel® HD-grafik 610",
      "tr_GR425": "Intel® HD-grafik 615",
      "tr_GR426": "Intel® HD-grafik 620",
      "tr_GR427": "Intel® HD-grafik 630",
      "tr_GR428": "Intel® HD grafik",
      "tr_GR429": "Intel® Iris® grafik 5100",
      "tr_GR430": "Intel® Iris® grafik 540",
      "tr_GR431": "Intel® Iris® grafik 550",
      "tr_GR432": "Intel® Iris® grafik 6100",
      "tr_GR433": "Intel® Iris® Plus grafik 640",
      "tr_GR434": "Intel® Iris® Plus grafik 645",
      "tr_GR435": "Intel® Iris® Plus grafik 650",
      "tr_GR436": "Intel® Iris® Plus grafik 655",
      "tr_GR437": "Intel® Iris® Plus grafik",
      "tr_GR438": "Intel® Iris® Pro grafik 5200",
      "tr_GR439": "Intel® Iris® Pro grafik 580",
      "tr_GR440": "Intel® Iris® Pro grafik 6200",
      "tr_GR441": "Intel® Iris® X<sup>e</sup> grafik",
      "tr_GR442": "Intel® Iris® X<sup>e</sup> MAX grafik A220M",
      "tr_GR443": "Intel® Iris® X<sup>e</sup> MAX grafik",
      "tr_GR444": "Intel® integrerad grafik",
      "tr_GR445": "Intel® UHD grafik 600",
      "tr_GR446": "Intel® UHD grafik 605",
      "tr_GR447": "Intel® UHD grafik 610",
      "tr_GR448": "Intel® UHD grafik 615",
      "tr_GR449": "Intel® UHD grafik 617",
      "tr_GR450": "Intel® UHD grafik 620",
      "tr_GR451": "Intel® UHD grafik 630",
      "tr_GR452": "Intel® UHD grafik 710",
      "tr_GR453": "Intel® UHD grafik 730",
      "tr_GR454": "Intel® UHD grafik 770",
      "tr_GR455": "Intel® UHD-grafik",
      "tr_GR456": "Intel® UHD-grafik",
      "tr_GR457": "Intel® UHD-grafik",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 med Max-Q Design",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti med Max-Q Design",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 med Max-Q Design",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 med Max-Q Design",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 med Max-Q Design",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 med Max-Q Design",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti med Max-Q Design",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti med Max-Q Design",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 med Max-Q Design",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 med Max-Q Design",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super med Max-Q Design",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 med Max-Q Design",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super med Max-Q Design",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 med Max-Q Design",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 med Max-Q Design",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 med Max-Q Design",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 med Max-Q Design",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 med Max-Q Design",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 med Max-Q Design",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 med Max-Q Design",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 med Max-Q Design",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 med Max-Q Design",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 med Max-Q Design",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "Intel® Arc™ grafik",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "AMD Radeon* Pro W7900 Dual Slot"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Köp nu",
    "tr_textTryagain": "Försök igen",
    "tr_textBuynowErr": "På grund av ett anslutningsavbrott är alternativet Köp nu inte tillgängligt",
    "tr_chatbotPlaceholder_text": "Fråga mig vad som helst om enheten",
    "tr_deviceSpecs_text": "Enhetsspecifikationer"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Intel® Core™ i3 processor med Intel® hybridteknologi"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Intel® Core™ i5 processor med Intel® hybridteknologi"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Ladda upp med Intel® för en mäktigare spelupplevelse",
                "tr_subtitle": "Ge liv åt Marvels Avengers* med inbyggda PC-optimeringar i spelet som drivs av Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Upplev Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Dit världens bästa spelare kommer för att tävla",
                "tr_subtitle": "Intel® Extreme Masters är den äldsta tävlingsserien i TV-spelvärldens historia och lägger ribban för e-sport.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Spela som proffsen",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Bli en vinnare med Intel.",
                "tr_subtitle": "Efter att ha vunnit Rainbow Six Siege* nordamerikanska mästerskap 2021 reflekterar Soniqs kring sin spelarhistoria, framtiden för e-sport och deras partnerskap med Intel.",
                "tr_pretitle": "Med Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "Förberett för VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "DÄR VR KOMMER TILL LIV",
                "tr_subtitle": "Den mest fantastiska, verklighetstrogna och omslutande virtuella verklighet som körs på en dator. Med de senaste Intel® Core™ processorerna kan du vara vem du vill, ta dig vart du vill och uppleva spel i nästa dimension.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Spela över 100 förstklassiga konsolspel som Sea of Thieves* och Psychonauts* 2 på din Windows* dator, mobiltelefon och surfplatta med Xbox* Game Pass Ultimate och en kompatibel handkontroll.",
                "titleArray": [
                  {
                    "tr_title": "Spela tillsammans på olika enheter",
                    "tr_subtitle": "Upplev Xbox* till fullo med en community av miljoner spelare som väntar på att spela tillsammans. Anslut och spela med andra från ett delat spelbibliotek, oavsett om de är på andra sidan jorden eller sitter bredvid dig."
                  },
                  {
                    "tr_title": "Spela på en Windows* dator",
                    "tr_subtitle": "Spela en katalog av konsolspel från molnet med Xbox* appen för Windows* och en kompatibel handkontroll."
                  },
                  {
                    "tr_title": "Plocka upp och spela",
                    "tr_subtitle": "Ha roligt med fantastiska Xbox* spel på fler platser än tidigare. Spela över 100 spel på din dator, mobiltelefon och surfplatta oavsett om du är hemma när din TV är i bruk, på ett kafé, eller någon annanstans med en mycket snabb internetanslutning."
                  },
                  {
                    "tr_title": "Underhållning utan handkontroll",
                    "tr_subtitle": "Xbox* spel med touchstyrning öppnar upp för nya sätt att hoppa in i spelens värld, med en familjär upplevelse och spelande som du är van vid med en fysisk handkontroll."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Över 100 AI-upplevelser",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Kontrollera mer, rör mindre",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Stanna i bild i alla miljöer",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitet",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Korrigera ljus som ett proffs",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Lägg enkelt till effekter som överträffar verkligheten",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Remixa låtar med kraften i AI",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitet",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Öka din produktivitet och integritet",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Tillbringa mer tid borta från strömuttaget",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Din värld. På dina enheter.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Underhållning",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Bufferfri strömning, även utan strömsladd",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Snabbt, uppslukande spelande",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Över 100 AI-upplevelser",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Gör dina favoritlåtar verkligen dina",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Se din vision i realtid",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitet",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Den snabbaste vägen till din slutliga klippning",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Få till perfekta foton varje gång",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitet",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Öka din produktivitet och integritet",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Mer effektiv. Mer frihet.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Din värld. På dina enheter.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Underhållning",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Bufferfri HD-strömning, även utan strömsladd",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Uppslukande spelande, på fler platser",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mer kreativ",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Fotoredigering på ett enkelt sätt",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Skapa foton värda att rama in på några sekunder",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Du skriver det. AI skapar det.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mer prestanda",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Genvägen till bufferfritt spelande",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Tillbringa mer tid borta från strömuttaget",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-uppslukande",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mer produktiv",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Din dagliga AI-följeslagare",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Perfekta videosamtal",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Din värld. På dina enheter.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mer säker",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Inbyggt skydd för att hålla dina data skyddade och privata",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mer kreativ",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Fotoredigering på ett enkelt sätt",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "AI. Genvägen till din slutgiltiga version.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Transkribera dina favoritlåttexter snabbt",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Slipp timmar av redigering med AI",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mer prestanda",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Genvägen till bufferfritt spelande",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Tillbringa mer tid borta från strömuttaget",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-uppslukande",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mer produktiv",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Din dagliga AI-följeslagare",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Perfekta videosamtal",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Din värld. På dina enheter.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mer säker",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Inbyggt skydd för att hålla dina data skyddade och privata",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "All din underhållning. En dator.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "För en dator som även fungerar som underhållningscenter behöver du tillräckligt med lagringsutrymme för all din favoritmusik, alla favoritfilmer och -spel samt rätt kombination av processor och bildskärm för att få fängslande grafik. Den här datorn har alla tre.",
                "titleArray": [
                  {
                    "tr_title": "Redo för nästa generations grafik",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bygg ditt digitala bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ett fönster mot underhållning",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Sömlöst spelande och sömlös strömning",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bygg ditt digitala bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ett fönster mot underhållning",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bli mer produktiv. Var som helst.",
              "tr_tileTitle1": "Få mer gjort. Snabbt.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Den här datorn balanserar kraft och portabilitet perfekt. Datorn har en processor som är utvecklad för tunn och lätt design. Den har även den sortens Wi&#8209;Fi som behövs för att hålla dig uppkopplad samt rätt batteri för att du ska slippa ladda den.",
                "tr_subtitle1": "Den här datorn har den perfekta balansen av kraft och prestanda för att hjälpa dig att åstadkomma mer på kortare tid. Kombinationen av en robust processor som accelereras med ett stort minne och supersnabbt Wi-Fi kommer att effektivisera dina dagliga uppgifter.",
                "tr_subtitle2": "Den här datorn balanserar kraft och portabilitet perfekt. Den här datorn har en processor som är utvecklad för tunn och lätt design, har den sortens Wi‑Fi som behövs för att hålla dig uppkopplad samt rätt sorts minne för multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Byggd för att göra mer",
                    "tr_title1": "Intelligent prestanda som spelar en viktig roll",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "De bästa anslutningarna",
                    "tr_title1": "Anslut till det som är viktigt",
                    "tr_title2": "Ett stort framsteg för Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Få längre batteritider",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Växla mellan program som ett proffs",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Friheten att skapa",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Att skapa innehåll är en av de mest krävande uppgifterna för din dator. Som tur är har de här komponenterna den kraft som behövs för att förenkla din kreativa process.",
                "titleArray": [
                  {
                    "tr_title": "Ett smartare sätt att skapa",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Blås liv i dina bilder",
                    "tr_title1": "Låt oss skapa",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Minne för multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Ta en titt inuti den här datorn",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Dessa är de viktigaste komponenterna du bör titta på när du väljer en ny dator. Genom att avgöra hur du kommer att använda din nya dator har du kommit en lång bit på vägen mot att avgöra hur många av dessa tre komponenter du behöver. Komponenterna är följande:",
                "titleArray": [
                  {
                    "tr_title": "Prestanda för spelande och strömning",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Lagring",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Minne",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "All din underhållning. En dator.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "För en dator som även fungerar som underhållningscenter behöver du tillräckligt med lagringsutrymme för all din favoritmusik, alla favoritfilmer och -spel samt rätt kombination av processor och bildskärm för att få fängslande grafik. Den här datorn har alla tre.",
                "titleArray": [
                  {
                    "tr_title": "Redo för nästa generations grafik",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bygg ditt digitala bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ett fönster mot underhållning",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Sömlöst spelande och sömlös strömning",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bygg ditt digitala bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ett fönster mot underhållning",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Minne",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Bildskärm",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Multitasking i hög hastighet",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING I HÖG HASTIGHET",
                "tr_subtitle": "Gör mer utan att sakta ner",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "En processor som kan köra flera program samtidigt."
                  },
                  {
                    "tr_title": "Wi‑Fi som är snabbt, starkt och pålitligt."
                  },
                  {
                    "tr_title": "Minne som sömlöst växlar mellan program."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videochatt",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCHATT",
                "tr_subtitle": "Kristallklara anslutningar",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för videochatt:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "En processor som kan spela in och dela bilder samtidigt."
                  },
                  {
                    "tr_title": "Wi‑Fi som är snabbt, starkt och pålitligt."
                  },
                  {
                    "tr_title": "En webbkamera som ger kristallklara bilder av dig."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Förberett för VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "DÄR VR KOMMER TILL LIV",
                "tr_subtitle": "Vad krävs för att skapa en helt virtuell värld? På en dator krävs samordning mellan processorn, grafikkortet och minnet.",
                "titleArray": [
                  {
                    "tr_title": "Driver virtuella världar",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Verkligheten ligger i detaljerna",
                    "tr_title1": "Bli uppslukad",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Videoredigering",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOREDIGERING",
                "tr_subtitle": "Skapa filmmagi",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för videoredigering:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "En processor som kan hantera stora filer."
                  },
                  {
                    "tr_title": "Minne som gör programvara för redigering snabb och responsiv."
                  },
                  {
                    "tr_title": "Grafik som kortar ner renderingstider."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Multitasking i hög hastighet",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING I HÖG HASTIGHET",
                "tr_subtitle": "Gör mer utan att sakta ner",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "En processor som kan köra flera program samtidigt."
                  },
                  {
                    "tr_title": "Wi‑Fi som är snabbt, starkt och pålitligt."
                  },
                  {
                    "tr_title": "Minne som sömlöst växlar mellan program."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Förberett för VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "DÄR VR KOMMER TILL LIV",
                "tr_subtitle": "Vad krävs för att skapa en helt virtuell värld? På en dator krävs samordning mellan processorn, grafikkortet och minnet.",
                "titleArray": [
                  {
                    "tr_title": "Driver virtuella världar",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Verkligheten ligger i detaljerna",
                    "tr_title1": "Bli uppslukad",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "3D-modellering",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D-MODELLERING",
                "tr_subtitle": "En ny nivå av skapande",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för 3D-modellering:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "En processor som kan hantera avancerad programvara för kreativt skapande."
                  },
                  {
                    "tr_title": "Minne som snabbt öppnar stora filer."
                  },
                  {
                    "tr_title": "Grafik för kraftfullt bildskapande och redigering."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Samarbete i realtid",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SAMARBETE I REALTID",
                "tr_subtitle": "Arbeta från var som helst",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för samarbete online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "En processor för att jobba i produktivitetsprogram."
                  },
                  {
                    "tr_title": "Wi‑Fi som du kan lita på."
                  },
                  {
                    "tr_title": "En webbkamera som spelar in i lysande HD."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Förberett för VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "DÄR VR KOMMER TILL LIV",
                "tr_subtitle": "Vad krävs för att skapa en helt virtuell värld? På en dator krävs samordning mellan processorn, grafikkortet och minnet.",
                "titleArray": [
                  {
                    "tr_title": "Driver virtuella världar",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Verkligheten ligger i detaljerna",
                    "tr_title1": "Bli uppslukad",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ bärbara datorer",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Bärbara datorer som är så här tunna och lätta brukar inte vara så här kraftiga",
                "tr_subtitle": "Utvecklade för mobil prestanda, Intel® Evo™ bärbara datorer har all den hastighet, grafik och batteritid du behöver, i en fantastisk tunn och lätt design.",
                "titleArray": [
                  {
                    "tr_title": "Den bärbara datorn är viktigare än någonsin, så vi ser till att den klarar mer än någonsin.",
                    "tr_subtitle": "Använd din pekskärm för att utforska, rotera och zooma."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Startklar på ett ögonblick",
                    "tr_subtitle": "Med omedelbar start kan din dator gå från&nbsp;viloläge till redo på mindre än en&nbsp;sekund."
                  },
                  {
                    "tr_title": "Uppslukande visning",
                    "tr_subtitle": "En vacker bildskärm ger liv till dina bilder i skarp upplösning och intensiva färger."
                  },
                  {
                    "tr_title": "Få saker gjorda snabbt",
                    "tr_subtitle": "Njut av en extremt följsam prestanda med 11:e gen:s Intel® Core™ processorer."
                  },
                  {
                    "tr_title": "Var urkopplad längre",
                    "tr_subtitle": "Håll i gång flödet med en dator som optimerar batteritiden och laddas på ett&nbsp;ögonblick."
                  },
                  {
                    "tr_title": "Universell kabelanslutning",
                    "tr_subtitle": "Gör anslutning av andra enheter till din dator enkelt, snabbt och säkert med Thunderbolt™&nbsp;4&nbsp;teknologi.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Snabba, pålitliga anslutningar",
                    "tr_subtitle": "Genom att använda Intel® Wi‑Fi 6 (Gig+) kan du videochatta eller dela filer med en anslutning som du kan lita på.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ bärbara datorer",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Allt du behöver och mer, i en tunn och lätt bärbar dator.",
                "tr_subtitle": "Intel® Evo™ bärbara datorer är utvecklade för mobil prestanda och för att ge dig den bästa upplevelsen<sup>2</sup>, oavsett vad du gör.",
                "titleArray": [
                  {
                    "tr_title": "Den bärbara datorn är viktigare än någonsin, så vi ser till att den klarar mer än någonsin.",
                    "tr_subtitle": "Använd din pekskärm för att utforska, rotera och zooma."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Startklar på ett ögonblick",
                    "tr_subtitle": "Med omedelbar start kan din dator gå från&nbsp;viloläge till redo på mindre än en&nbsp;sekund."
                  },
                  {
                    "tr_title": "Uppslukande visning",
                    "tr_subtitle": "En vacker bildskärm ger liv till dina bilder i skarp upplösning och intensiva färger."
                  },
                  {
                    "tr_title": "Revolutionerande prestanda",
                    "tr_subtitle": "Få extra kraft där du behöver det som mest med intelligent prestanda från 12:e gen:s Intel® Core™ processorer."
                  },
                  {
                    "tr_title": "Var urkopplad längre",
                    "tr_subtitle": "Håll i gång flödet med en dator som optimerar batteritiden och laddas på ett&nbsp;ögonblick."
                  },
                  {
                    "tr_title": "Universell kabelanslutning",
                    "tr_subtitle": "Gör anslutning av andra enheter till din dator enkelt, snabbt och säkert med Thunderbolt™&nbsp;4&nbsp;teknologi.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Snabba, pålitliga anslutningar",
                    "tr_subtitle": "Med Intel® Wi-Fi 6E (Gig+) kan du videochatta och dela filer via en anslutning som du kan lita på.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ bärbara datorer",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Bärbara datorer som är så här tunna och lätta brukar inte vara så här kraftiga",
                "tr_subtitle": "Utvecklade för mobil prestanda, Intel® Evo™ bärbara datorer har all den hastighet, grafik och batteritid du behöver, i en fantastisk tunn och lätt design.",
                "titleArray": [
                  {
                    "tr_title": "Den bärbara datorn är viktigare än någonsin, så vi ser till att den klarar mer än någonsin.",
                    "tr_subtitle": "Använd din pekskärm för att utforska, rotera och zooma."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Startklar på ett ögonblick",
                    "tr_subtitle": "Med omedelbar start kan din dator gå från&nbsp;viloläge till redo på mindre än en&nbsp;sekund."
                  },
                  {
                    "tr_title": "Uppslukande visning",
                    "tr_subtitle": "En vacker bildskärm ger liv till dina bilder i skarp upplösning och intensiva färger."
                  },
                  {
                    "tr_title": "Få saker gjorda snabbt",
                    "tr_subtitle": "Njut av en extremt följsam prestanda med 11:e gen:s Intel® Core™ processorer."
                  },
                  {
                    "tr_title": "Var urkopplad längre",
                    "tr_subtitle": "Håll i gång flödet med en dator som optimerar batteritiden och laddas på ett&nbsp;ögonblick."
                  },
                  {
                    "tr_title": "Universell kabelanslutning",
                    "tr_subtitle": "Gör anslutning av andra enheter till din dator enkelt, snabbt och säkert med Thunderbolt™&nbsp;4&nbsp;teknologi.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Snabba, pålitliga anslutningar",
                    "tr_subtitle": "Genom att använda Intel® Wi‑Fi 6 (Gig+) kan du videochatta eller dela filer med en anslutning som du kan lita på.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ bärbara datorer",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Allt du behöver och mer, i en tunn och lätt bärbar dator.",
                "tr_subtitle": "Intel® Evo™ bärbara datorer är utvecklade för mobil prestanda och för att ge dig den bästa upplevelsen<sup>2</sup>, oavsett vad du gör.",
                "titleArray": [
                  {
                    "tr_title": "Den bärbara datorn är viktigare än någonsin, så vi ser till att den klarar mer än någonsin.",
                    "tr_subtitle": "Använd din pekskärm för att utforska, rotera och zooma."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Startklar på ett ögonblick",
                    "tr_subtitle": "Med omedelbar start kan din dator gå från&nbsp;viloläge till redo på mindre än en&nbsp;sekund."
                  },
                  {
                    "tr_title": "Uppslukande visning",
                    "tr_subtitle": "En vacker bildskärm ger liv till dina bilder i skarp upplösning och intensiva färger."
                  },
                  {
                    "tr_title": "Revolutionerande prestanda",
                    "tr_subtitle": "Få extra kraft där du behöver det som mest med intelligent prestanda från 12:e gen:s Intel® Core™ processorer."
                  },
                  {
                    "tr_title": "Var urkopplad längre",
                    "tr_subtitle": "Håll i gång flödet med en dator som optimerar batteritiden och laddas på ett&nbsp;ögonblick."
                  },
                  {
                    "tr_title": "Universell kabelanslutning",
                    "tr_subtitle": "Gör anslutning av andra enheter till din dator enkelt, snabbt och säkert med Thunderbolt™&nbsp;4&nbsp;teknologi.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Snabba, pålitliga anslutningar",
                    "tr_subtitle": "Med Intel® Wi-Fi 6E (Gig+) kan du videochatta och dela filer via en anslutning som du kan lita på.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ bärbara datorer",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Allt du behöver och mer, i en tunn och lätt bärbar dator.",
                "tr_subtitle": "Intel® Evo™ bärbara datorer är utvecklade för mobil prestanda och för att ge dig den bästa upplevelsen<sup>2</sup>, oavsett vad du gör.",
                "titleArray": [
                  {
                    "tr_title": "Den bärbara datorn är viktigare än någonsin, så vi ser till att den klarar mer än någonsin.",
                    "tr_subtitle": "Använd din pekskärm för att utforska, rotera och zooma."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Startklar på ett ögonblick",
                    "tr_subtitle": "Med omedelbar start kan din dator gå från&nbsp;viloläge till redo på mindre än en&nbsp;sekund."
                  },
                  {
                    "tr_title": "Uppslukande visning",
                    "tr_subtitle": "En vacker bildskärm ger liv till dina bilder i skarp upplösning och intensiva färger."
                  },
                  {
                    "tr_title": "Revolutionerande prestanda",
                    "tr_subtitle": "Få extra kraft där du behöver det som mest med intelligent prestanda från 13:e gen:s Intel® Core™ processorer."
                  },
                  {
                    "tr_title": "Var urkopplad längre",
                    "tr_subtitle": "Håll i gång flödet med en dator som optimerar batteritiden och laddas på ett&nbsp;ögonblick."
                  },
                  {
                    "tr_title": "Universell kabelanslutning",
                    "tr_subtitle": "Gör anslutning av andra enheter till din dator enkelt, snabbt och säkert med Thunderbolt™&nbsp;4&nbsp;teknologi.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Snabba, pålitliga anslutningar",
                    "tr_subtitle": "Med Intel® Wi-Fi 6E (Gig+) kan du videochatta och dela filer via en anslutning som du kan lita på.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ bärbara datorer",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Allt du behöver och mer, i en tunn och lätt bärbar dator.",
                "tr_subtitle": "Intel® Evo™ bärbara datorer är utvecklade för mobil prestanda och för att ge dig den bästa upplevelsen<sup>2</sup>, oavsett vad du gör.",
                "titleArray": [
                  {
                    "tr_title": "Den bärbara datorn är viktigare än någonsin, så vi ser till att den klarar mer än någonsin.",
                    "tr_subtitle": "Använd din pekskärm för att utforska, rotera och zooma."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Startklar på ett ögonblick",
                    "tr_subtitle": "Med omedelbar start kan din dator gå från&nbsp;viloläge till redo på mindre än en&nbsp;sekund."
                  },
                  {
                    "tr_title": "Uppslukande visning",
                    "tr_subtitle": "En vacker bildskärm ger liv till dina bilder i skarp upplösning och intensiva färger."
                  },
                  {
                    "tr_title": "Revolutionerande prestanda",
                    "tr_subtitle": "Få extra kraft där du behöver det som mest med intelligent prestanda från 13:e gen:s Intel® Core™ processorer."
                  },
                  {
                    "tr_title": "Var urkopplad längre",
                    "tr_subtitle": "Håll i gång flödet med en dator som optimerar batteritiden och laddas på ett&nbsp;ögonblick."
                  },
                  {
                    "tr_title": "Universell kabelanslutning",
                    "tr_subtitle": "Gör anslutning av andra enheter till din dator enkelt, snabbt och säkert med Thunderbolt™&nbsp;4&nbsp;teknologi.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Snabba, pålitliga anslutningar",
                    "tr_subtitle": "Med Intel® Wi-Fi 6E (Gig+) kan du videochatta och dela filer via en anslutning som du kan lita på.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Vad är Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Varför Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitet",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Var mer kreativ, på fler platser",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Fotoredigering i full fart",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Skapa originalkonst på plats",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitet",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximera produktiviteten med Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Upptäck deepfakes innan du delar med AI",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Din värld. På dina enheter.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilitet",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Säg hej till gestkontroll",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Batteritid som inte håller dig tillbaka",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Håller dig automatiskt uppkopplad",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Vad är Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Varför Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitet",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Snabb, bufferfri videouppspelning",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Producera hitlåtar med kraften i AI",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "Den snabbaste vägen till ditt slutgiltiga klipp",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitet",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximera produktiviteten med Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Upptäck deepfakes innan du delar med AI",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Din värld. På dina enheter.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilitet",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Samarbeta från praktiskt taget var som helst",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Batteritid som inte håller dig tillbaka",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Håller dig automatiskt uppkopplad",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "All din underhållning. En dator.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "För en dator som även fungerar som underhållningscenter behöver du tillräckligt med lagringsutrymme för all din favoritmusik, alla favoritfilmer och -spel samt rätt kombination av processor och bildskärm för att få fängslande grafik. Den här datorn har alla tre.",
                "titleArray": [
                  {
                    "tr_title": "Imponerade spelande och strömning",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bygg ditt digitala bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ett fönster mot underhållning",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Sömlöst spelande och sömlös strömning",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bygg ditt digitala bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ett fönster mot underhållning",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bli mer produktiv. Var som helst.",
              "tr_tileTitle1": "Få mer gjort. Snabbt.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Den här datorn balanserar kraft och portabilitet perfekt. Datorn har en processor som är utvecklad för tunn och lätt design. Den har även den sortens Wi&#8209;Fi som behövs för att hålla dig uppkopplad samt rätt batteri för att du ska slippa ladda den.",
                "tr_subtitle1": "Den här datorn har den perfekta balansen av kraft och prestanda för att hjälpa dig att åstadkomma mer på kortare tid. Kombinationen av en robust processor som accelereras med ett stort minne och supersnabbt Wi-Fi kommer att effektivisera dina dagliga uppgifter.",
                "tr_subtitle2": "Den här datorn balanserar kraft och portabilitet perfekt. Den här datorn har en processor som är utvecklad för tunn och lätt design, har den sortens Wi‑Fi som behövs för att hålla dig uppkopplad samt rätt sorts minne för multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Intelligent prestanda som spelar en viktig roll",
                    "tr_title1": "Snabba upp med intelligent prestanda",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "De bästa anslutningarna",
                    "tr_title1": "Anslut till det som är viktigt",
                    "tr_title2": "Ett stort framsteg för Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Få längre batteritider",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Växla mellan program som ett proffs",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Friheten att skapa",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Att skapa innehåll är en av de mest krävande uppgifterna för din dator. Som tur är har de här komponenterna den kraft som behövs för att förenkla din kreativa process.",
                "titleArray": [
                  {
                    "tr_title": "Accelererad kreativitet",
                    "tr_title1": "Låt kreativiteten flöda",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Förhöjt skapande",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Minne för multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Ta en titt inuti den här datorn",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Dessa är de viktigaste komponenterna du bör titta på när du väljer en ny dator. Genom att avgöra hur du kommer att använda din nya dator har du kommit en lång bit på vägen mot att avgöra hur många av dessa tre komponenter du behöver. Komponenterna är följande:",
                "titleArray": [
                  {
                    "tr_title": "Processor",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Lagring",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Minne",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Friheten att skapa",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Att skapa innehåll är en av de mest krävande uppgifterna för din dator. Som tur är har de här komponenterna den kraft som behövs för att förenkla din kreativa process.",
                "titleArray": [
                  {
                    "tr_title": "Ett smartare sätt att skapa",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Blås liv i dina bilder",
                    "tr_title1": "Låt oss skapa",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Minne för multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bli mer produktiv. Var som helst.",
              "tr_tileTitle1": "Få mer gjort. Snabbt.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Den här datorn balanserar kraft och portabilitet perfekt. Datorn har en processor som är utvecklad för tunn och lätt design. Den har även den sortens Wi&#8209;Fi som behövs för att hålla dig uppkopplad samt rätt batteri för att du ska slippa ladda den.",
                "tr_subtitle1": "Den här datorn har den perfekta balansen av kraft och prestanda för att hjälpa dig att åstadkomma mer på kortare tid. Kombinationen av en robust processor som accelereras med ett stort minne och supersnabbt Wi-Fi kommer att effektivisera dina dagliga uppgifter.",
                "tr_subtitle2": "Den här datorn balanserar kraft och portabilitet perfekt. Den här datorn har en processor som är utvecklad för tunn och lätt design, har den sortens Wi‑Fi som behövs för att hålla dig uppkopplad samt rätt sorts minne för multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Byggd för att göra mer",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "De bästa anslutningarna",
                    "tr_title1": "Anslut till det som är viktigt",
                    "tr_title2": "Ett stort framsteg för Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Få längre batteritider",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Växla mellan program som ett proffs",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Förberett för VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "DÄR VR KOMMER TILL LIV",
                "tr_subtitle": "Vad krävs för att skapa en helt virtuell värld? På en dator krävs samordning mellan processorn, grafikkortet och minnet.",
                "titleArray": [
                  {
                    "tr_title": "Driver virtuella världar",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Verkligheten ligger i detaljerna",
                    "tr_title1": "Bli uppslukad",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>En fröjd för ögat",
      "tr_sub_header": "Blått ljus kan orsaka obehag för ögonen och störa din dygnsrytm. Samsungs SGS Eye Care-testade OLED-bildskärm hjälper inte genom att ändra färger, utan istället genom att minska våglängden för potentiellt skadligt blått ljus, vilket gör det bekvämare för ögonen och minskar ögontrötthet.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Mindre blått ljus som kan skada synen"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra på alla sätt",
      "tr_sub_header": "OLED:s ultralätta, ultratunna design maximerar bildkvaliteten och minimerar vikten."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "True Black",
      "tr_sub_header": "0,0005 nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74 %<br/><span class='conventional_text'>Konventionell</span>",
      "tr_sub_header": "120 %<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Strömning",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STRÖMNING",
                "tr_subtitle": "Oändlig underhållning",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för strömning:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "En processor som kan strömma i HD."
                  },
                  {
                    "tr_title": "En bildskärm för ljusa, vackra bilder."
                  },
                  {
                    "tr_title": "Wi‑Fi som ger mindre buffring och laddningstider."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Surfa på webben",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SURFA PÅ WEBBEN",
                "tr_subtitle": "Internet, enkelt",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för surfning:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "En processor som snabbt kan ladda mediarika sidor."
                  },
                  {
                    "tr_title": "Wi‑Fi som är snabbt och pålitligt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videochatt",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCHATT",
                "tr_subtitle": "Kristallklara anslutningar",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för videochatt:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "En processor som kan spela in och dela bilder samtidigt."
                  },
                  {
                    "tr_title": "Wi‑Fi som är snabbt, starkt och pålitligt."
                  },
                  {
                    "tr_title": "En webbkamera som ger kristallklara bilder av dig."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitasking i hög hastighet",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING I HÖG HASTIGHET",
                "tr_subtitle": "Gör mer utan att sakta ner",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "En processor som kan köra flera program samtidigt."
                  },
                  {
                    "tr_title": "Wi‑Fi som är snabbt, starkt och pålitligt."
                  },
                  {
                    "tr_title": "Minne som sömlöst växlar mellan program."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videochatt",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCHATT",
                "tr_subtitle": "Kristallklara anslutningar",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för videochatt:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "En processor som kan spela in och dela bilder samtidigt."
                  },
                  {
                    "tr_title": "Wi‑Fi som är snabbt, starkt och pålitligt."
                  },
                  {
                    "tr_title": "En webbkamera som ger kristallklara bilder av dig."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Vardagsspelande",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VARDAGSSPELANDE",
                "tr_subtitle": "Dags att spela",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för lätt spelande:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "En processor som ger bufferfritt spelande."
                  },
                  {
                    "tr_title": "Minne som gör att du kan röstchatta och strömma samtidigt."
                  },
                  {
                    "tr_title": "Grafik som ger fantastiska bilder."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Fotoredigering",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "FOTOREDIGERING",
                "tr_subtitle": "Pixelperfekta foton",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för fotoredigering:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "En processor med kraft att redigera stora bildgrupper."
                  },
                  {
                    "tr_title": "Minne som accelererar din programvara för redigering."
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 teknologi gör det möjligt för dig att snabbt överföra filer."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitasking i hög hastighet",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING I HÖG HASTIGHET",
                "tr_subtitle": "Gör mer utan att sakta ner",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "En processor som kan köra flera program samtidigt."
                  },
                  {
                    "tr_title": "Wi‑Fi som är snabbt, starkt och pålitligt."
                  },
                  {
                    "tr_title": "Minne som sömlöst växlar mellan program."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Samarbete i realtid",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SAMARBETE I REALTID",
                "tr_subtitle": "Arbeta från var som helst",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för samarbete online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "En processor för att jobba i produktivitetsprogram."
                  },
                  {
                    "tr_title": "Wi‑Fi som du kan lita på."
                  },
                  {
                    "tr_title": "En webbkamera som spelar in i lysande HD."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videoredigering",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOREDIGERING",
                "tr_subtitle": "Skapa filmmagi",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för videoredigering:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "En processor som kan hantera stora filer."
                  },
                  {
                    "tr_title": "Minne som gör programvara för redigering snabb och responsiv."
                  },
                  {
                    "tr_title": "Grafik som kortar ner renderingstider."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Fotoredigering",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "FOTOREDIGERING",
                "tr_subtitle": "Pixelperfekta foton",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för fotoredigering:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "En processor med kraft att redigera stora bildgrupper."
                  },
                  {
                    "tr_title": "Minne som accelererar din programvara för redigering."
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 teknologi gör det möjligt för dig att snabbt överföra filer."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Videoredigering",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOREDIGERING",
                "tr_subtitle": "Skapa filmmagi",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för videoredigering:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "En processor som kan hantera stora filer."
                  },
                  {
                    "tr_title": "Minne som gör programvara för redigering snabb och responsiv."
                  },
                  {
                    "tr_title": "Grafik som kortar ner renderingstider."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "3D-modellering",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D-MODELLERING",
                "tr_subtitle": "En ny nivå av skapande",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för 3D-modellering:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "En processor som kan hantera avancerad programvara för kreativt skapande."
                  },
                  {
                    "tr_title": "Minne som snabbt öppnar stora filer."
                  },
                  {
                    "tr_title": "Grafik för kraftfullt bildskapande och redigering."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Samarbete i realtid",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SAMARBETE I REALTID",
                "tr_subtitle": "Arbeta från var som helst",
                "tr_subtitle1": "Här är vad vi hittade i den här datorn som är bra för samarbete online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "En processor för att jobba i produktivitetsprogram."
                  },
                  {
                    "tr_title": "Wi‑Fi som du kan lita på."
                  },
                  {
                    "tr_title": "En webbkamera som spelar in i lysande HD."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Intel® Unison™ app",
    "tr_title": "Lås upp din anslutna värld",
    "tr_subtitle": "Överför filer och foton sömlöst medan du hanterar telefonens aviseringar, samtal och SMS från din dator.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "ÖVERFÖR FILER OCH FOTON",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "RING OCH TA EMOT SAMTAL",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "SKICKA OCH TA EMOT MEDDELANDEN",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "HANTERA TELEFONAVISERINGAR",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "LÅS UPP DIN ANSLUTNA VÄRLD",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}