const Library_mobile_da = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Drevet af en [cpuDisplay]-processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Drevet af en [cpuDisplay]-processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Ligesom en hjerne styrer processoren alt det, din pc kan gøre. Det betyder, at de videoer, du ser, de spil, du spiller, de websteder, du besøger, alle starter med din processor.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Processoren er hjernen i din PC"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Ydeevne og værdi.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Silver -processor",
                    "tr_subtitle": "Hold styr på din digitale verden med en ydeevne, som kan få adgang til filer fra internettet. Multi-task ved simpelt og nemt at skifte mellem programmer og få mere gjort. Hurtigt.",
                    "tr_gen_core_title": "Intel® Pentium® Silver -processor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Gold -processor",
                    "tr_subtitle": "Forbedret produktivitet - Øget ydeevne til at støtte dig igennem dine mest krævende dage. Strømlinet webbrowsing - Indlæs hurtigt media-rich websider for mere udforskning og mindre ventetid.",
                    "tr_gen_core_title": "Intel® Pentium® Gold -processor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Celeron® -processor",
                    "tr_subtitle": "Ydeevne til at øge arbejde og underholdning. Nyd film og videoer på nettet i utrolige detaljer. Brug mere tid på at gøre og mindre på at vente.",
                    "tr_gen_core_title": "Intel® Celeron® -processor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® -processor",
                    "tr_subtitle": "Bygget til fantastisk underholdning, videostreaming og produktivitet. Nyd film og videoer på nettet i utrolige detaljer. Brug mere tid på at gøre og mindre på at vente.",
                    "tr_gen_core_title": "Intel® Pentium® -processor"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Ydelse, du har brug for. Prisen, du ønsker.",
                "tr_title": "Intel® -processor",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Værdi i en kategori for sig selv",
                    "tr_subtitle": "En Intel® -processor kan klare stort set alle de opgaver, du har brug for, til en pris, du har råd til. Navigér nemt fra den ene software til den anden. Eller nyd grafik i høj kvalitet med stabil ydeevne.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Skab stærkere forbindelser",
                    "tr_subtitle": "Mere levende videokvalitet, bedre lydklarhed og næsten 3x hurtigere internetforbindelse giver dig mulighed for at samarbejde hvor som helst.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Forskellen er som dag og nat",
                    "tr_subtitle": "Fra morgenfilmmaraton til sidste øjebliks arbejdsopgaver - Intel® -processoren forlænger batterilevetiden på din enhed for at give dig en medrivende, uafbrudt oplevelse.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Ethvert miljø er et læringsmiljø",
                    "tr_subtitle": "Du kan nemt køre flere online læringsværktøjer fra alle hjørner af dit hjem."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Lær, hvor du vil",
                    "tr_subtitle": "Afslut lektioner, hvor du har brug for det, mens du kører forskellige e-læringsværktøjer på samme tid."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Få den nødvendige ydeevne til underholdning og produktivitet, som passer til dit forbrug og livsstil.",
                "tr_title": "Intel ydeevne til ethvert budget.",
                "tr_gen_core_title": "Intel® Celeron® -processor",
                "processorCompare": {
                  "tr_title": "FIND DET RETTE NIVEAU AF YDEEVNE OG VÆRDI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Intel® Celeron® -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Hurtig, mediefyldt webbrowsing",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nemt at skifte mellem programmer",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hverdags-gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming af 4K-videoer",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Let billed og video redigering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hurtig produktivitet med intelligent ydeevne",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Drevet af en 7. generation Intel® Core™ [cpu_fam] -processor"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Drevet af en 6. generation Intel® Core™ [cpu_fam] -processor"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Drevet med en 5. generation Intel® Core™ [cpu_fam] -processor"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Drevet med en 4. generation Intel® Core™ [cpu_fam] -processor"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Drevet med en 3. generation Intel® Core™ [cpu_fam] -processor"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Drevet af en 2. generation Intel® Core™ [cpu_fam] -processor"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Drevet af en 1. generation Intel® Core™ [cpu_fam] -processor"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Anbefalet til:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Drevet af en 8. generation Intel® Core™ i3 -processor",
                "tr_gen_core_title": "8. generation Intel® Core™ i3 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9. generation af Intel® Core™ -processorer er skabt til at holde trit med din digitale verden. Med kombinationen af hastighed og ydeevne vil du kunne gøre mere af det, du elsker ved pc'en uden frustration.",
                "tr_title": "Drevet med en 9. generation Intel® Core™ i3 -processor",
                "tr_gen_core_title": "9. generation Intel® Core™ i3 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Alsidig ydeevne møder masser af batterilevetid, så du kan holde dig i gang, uanset hvor du er.",
                "tr_title": "Strøm til hele dagen",
                "tr_gen_core_title": "10. generation Intel® Core™ i3 -processor",
                "processorCompare": {
                  "tr_title": "FIND DET RETTE NIVEAU AF 10. GENERATIONS-YDEEVNE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming af film i 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hurtige og pålidelige tilslutningsmuligheder",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fotoafdækning og videofiltrering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spil med 1080p med jævne billedhastigheder",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bedst til arbejde med store filer og kreative programmer",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Du kan nemt skifte fra at streame film til at lave præsentationer med masser af ydelse til overs.",
                "tr_title": "Strøm til hele dagen",
                "tr_gen_core_title": "10. generation Intel® Core™ i3 -processor"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Til den, der altid er på farten: en bærbar computer, der er udviklet specielt til dig.",
                "tr_title": "Oplev en ny klasse af bærbare computere",
                "tr_gen_core_title": "10. generation Intel® Core™ i3 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. generation Intel® Core™ i3 -processoren er udviklet specielt til pc'er, hvilket tillader dig at gøre mere, hvor du end befinder dig.",
                "tr_title": "Få mere ud af din nye bærbare",
                "tr_gen_core_title": "11. generation Intel® Core™ i3 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i3 -PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Når hele familien bruger en stationær til alle behov, så har de brug for en 11. generation Intel® Core™ i3 -processor. ",
                "tr_title": "Vær klar til alt med banebrydende ydeevne",
                "tr_gen_core_title": "11. generation Intel® Core™ i3 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i3 -PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Drevet af en 8. generation Intel® Core™ i5 -processor",
                "tr_gen_core_title": "8. generation Intel® Core™ i5 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9. generation af Intel® Core™ -processorer er skabt til at holde trit med din digitale verden. Med kombinationen af hastighed og ydeevne vil du kunne gøre mere af det, du elsker ved pc'en uden frustration.",
                "tr_title": "Drevet med en 9. generation Intel® Core™ i5 -processor",
                "tr_gen_core_title": "9. generation Intel® Core™ i5 -processor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "GNIDNINGSLØST SPIL OG VR PÅ DE MEST KRÆVENDE SPIL",
                "tr_title": "Seriøse spillere starter her",
                "tr_gen_core_title": "9. generation Intel® Core™ i5 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FIND DEN INTEL® CORE™ -PROCESSOR, DER PASSER TIL DIN SPILLESTIL.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurrencedygtig gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spil, stream og optag uden at gå på kompromis",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bedste 9. generation Intel® Core™ -processor til gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 4<br/>Tråde: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Du kan nemt håndtere flere programmer, så du er klar til hvad some helst, uanset hvor dagen føre dig hen. ",
                "tr_title": "Multitasking på farten",
                "tr_gen_core_title": "10. generation Intel® Core™ i5 -processor",
                "processorCompare": {
                  "tr_title": "FIND DET RETTE NIVEAU AF 10. GENERATIONS-YDEEVNE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 -processor"
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming af film i 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hurtige og pålidelige tilslutningsmuligheder",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fotoafdækning og videofiltrering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spil med 1080p med jævne billedhastigheder",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bedst til arbejde med store filer og kreative programmer",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Øg kvaliteten af alt, hvad du laver fra underholdning til produktivitet og indholdsproduktion.",
                "tr_title": "Opgrader til en kraftigere pc",
                "tr_gen_core_title": "10. generation Intel® Core™ i5 -processor"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Til den, der altid er på farten: en bærbar computer, der er udviklet specielt til dig.",
                "tr_title": "Oplev en ny klasse af bærbare computere",
                "tr_gen_core_title": "10. generation Intel® Core™ i5 -processor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Bygget til problemfri streaming og AAA Gaming",
                "tr_title": "Spil på dine egne vilkår",
                "tr_gen_core_title": "10. generation Intel® Core™ i5 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FIND DEN 10. GENERATION INTEL® CORE™ PROCESSOR, DER PASSER TIL DIN SPILLESTIL.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemfri AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurrencedygtig gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og optagelse",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avanceret oprettelse af indhold",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Peak gaming-ydeevne",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og overclockable<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Bygget til problemfri streaming og AAA Gaming",
                "tr_title": "Spil på dine egne vilkår",
                "tr_gen_core_title": "10. generation Intel® Core™ i5 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FIND DEN 10. GENERATION INTEL® CORE™ PROCESSOR, DER PASSER TIL DIN SPILLESTIL.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemfri AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurrencedygtig gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og optagelse",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avanceret oprettelse af indhold",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Peak gaming-ydeevne",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og overclockable<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 10<br/>Tråde: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Du kan nemt håndtere flere programmer, så du er klar til hvad some helst, uanset hvor dagen føre dig hen. ",
                "tr_title": "Multitasking på farten",
                "tr_gen_core_title": "10. generation Intel® Core™ i5 -processor",
                "tr_gen_core_title_uppercase": "10. GENERATION INTEL® CORE™ i5 -PROCESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. generation Intel® Core™ i5 -processoren giver de tyndeste og letteste pc'er en livagtig visuel oplevelse og omfattende produktivitet.",
                "tr_title": "Imponerende bærbar ydeevne",
                "tr_gen_core_title": "11. generation Intel® Core™ i5 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i5 -PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Når hele familien bruger en stationær til alle behov, så har de brug for en 11. generation Intel® Core™ i5 -processor. ",
                "tr_title": "Vær klar til alt med banebrydende ydeevne",
                "tr_gen_core_title": "11. generation Intel® Core™ i5 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i5 -PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. generation Intel® Core™ i5 -processor bringer levende visuelle oplevelser og vidtrækkende produktivitet til de tynde og lette bærbare computere.",
                "tr_title": "Imponerende bærbar ydeevne",
                "tr_gen_core_title": "11. generation Intel® Core™ i5 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i5 -PROCESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Drevet af en 8. generation Intel® Core™ i7 -processor",
                "tr_gen_core_title": "8. generation Intel® Core™ i7 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "9. generation af Intel® Core™ -processorer er skabt til at holde trit med din digitale verden. Med kombinationen af hastighed og ydeevne vil du kunne gøre mere af det, du elsker ved pc'en uden frustration.",
                "tr_title": "Drevet med en 9. generation Intel® Core™ i7 -processor",
                "tr_gen_core_title": "9. generation Intel® Core™ i7 -processor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "OP TIL 32 % FLERE FPS, mens du gamer, streamer og optager vs. en 3-ÅR-GAMMEL-PC<sup>3</sup>",
                "tr_title": "Del dine bedste gamingminder",
                "tr_gen_core_title": "9. generation Intel® Core™ i7 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FIND DEN INTEL® CORE™ -PROCESSOR, DER PASSER TIL DIN SPILLESTIL.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurrencedygtig gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spil, stream og optag uden at gå på kompromis",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bedste 9. generation Intel® Core™ -processor til gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 4<br/>Tråde: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Denne slanke enhed er udviklet til effektivitet og kan have stor indflydelse på, hvordan du skaber, er i kontakt og streamer.",
                "tr_title": "Avanceret ydeevne hvor som helst",
                "tr_gen_core_title": "10. generation Intel® Core™ i7 -processor",
                "processorCompare": {
                  "tr_title": "FIND DET RETTE NIVEAU AF 10. GENERATIONS-YDEEVNE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming af film i 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hurtige og pålidelige tilslutningsmuligheder",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fotoafdækning og videofiltrering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spil med 1080p med jævne billedhastigheder",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bedst til arbejde med store filer og kreative programmer",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Avanceret ydelse, så du kan tage alt, hvad du gør, et skridt videre",
                "tr_title": "Kraft til at gøre det hele",
                "tr_gen_core_title": "10. generation Intel® Core™ i7 -processor"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Til den, der altid er på farten: en bærbar computer, der er udviklet specielt til dig.",
                "tr_title": "Oplev en ny klasse af bærbare computere",
                "tr_gen_core_title": "10. generation Intel® Core™ i7 -processor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Spil, stream og optag konkurrencedygtigt",
                "tr_title": "Sæt kraft i din spilverden",
                "tr_gen_core_title": "10. generation Intel® Core™ i7 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FIND DEN 10. GENERATION INTEL® CORE™ PROCESSOR, DER PASSER TIL DIN SPILLESTIL.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemfri AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurrencedygtig gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og optagelse",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avanceret oprettelse af indhold",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Peak gaming-ydeevne",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og overclockable<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Spil, stream og optag konkurrencedygtigt",
                "tr_title": "Sæt kraft i din spilverden",
                "tr_gen_core_title": "10. generation Intel® Core™ i7 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FIND DEN 10. GENERATION INTEL® CORE™ PROCESSOR, DER PASSER TIL DIN SPILLESTIL.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemfri AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurrencedygtig gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og optagelse",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avanceret oprettelse af indhold",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Peak gaming-ydeevne",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og overclockable<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 10<br/>Tråde: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Denne tynde enhed er designet til effektivitet og kan have en enorm indvirkning på den måde, du opretter, forbinder og streamer.",
                "tr_title": "Premium-ydeevne, hvor som helst",
                "tr_gen_core_title": "10. generation Intel® Core™ i7 -processor",
                "tr_gen_core_title_uppercase": "10. GENERATION INTEL® CORE™ i7 -PROCESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. generation Intel® Core™ i7 -processoren bringer avanceret indholdsoprettelse, glat gaming og underholdning til næste niveau på ultrabærbare PCer.",
                "tr_title": "Banebrydende bærbar ydeevne",
                "tr_gen_core_title": "11. generation Intel® Core™ i7 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i7 -PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Gør din stationære computer til destinationen for alt, hvad dit hjem måtte have brug for med den banebrydende ydeevne fra en 11. generation Intel® Core™ i7 -processor.",
                "tr_title": "Arbejde. Lær. Spil. Uden grænser.",
                "tr_gen_core_title": "11. generation Intel® Core™ i7 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i7 -PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. generation Intel® Core™ i7 -processor bringer avanceret indholdsoprettelse, glat gaming og underholdning til næste niveau på tynde og lette bærbare computere.",
                "tr_title": "Banebrydende bærbar ydeevne",
                "tr_gen_core_title": "11. generation Intel® Core™ i7 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i7 -PROCESSOR"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Gør din stationære computer til destinationen for alt, hvad dit hjem måtte have brug for med den banebrydende ydeevne fra en 11. generation Intel® Core™ i7 -processor.",
                "tr_title": "Skab uden grænser",
                "tr_gen_core_title": "11. generation Intel® Core™ i7 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i7 -PROCESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med avanceret oprettelse af indhold, smidig gaming og underholdning på næste niveau på tynde og lette bærbare computere er denne 11. generation Intel® Core™ i7 -processor netop blevet bedre.",
                "tr_title": "Banebrydende bærbar ydeevne",
                "tr_gen_core_title": "11. generation Intel® Core™ i7 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i7 -PROCESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Drevet af en 8. generation Intel® Core™ i9 -processor",
                "tr_gen_core_title": "8. generation Intel® Core™ i9 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "9. generation af Intel® Core™ -processorer er skabt til at holde trit med din digitale verden. Med kombinationen af hastighed og ydeevne vil du kunne gøre mere af det, du elsker ved pc'en uden frustration.",
                "tr_title": "Drevet med en 9. generation Intel® Core™ i9 -processor",
                "tr_gen_core_title": "9. generation Intel® Core™ i9 -processor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "OP TIL 41 % FLERE FPS, mens gaming, streaming og optagelse vs. en 3-ÅR-GAMMEL-PC<sup>4</sup>",
                "tr_title": "Gamere kræver. Intel® Core™ i9 leverer.",
                "tr_gen_core_title": "9. generation Intel® Core™ i9 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FIND DEN INTEL® CORE™ -PROCESSOR, DER PASSER TIL DIN SPILLESTIL.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurrencedygtig gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spil, stream og optag uden at gå på kompromis",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bedste 9. generation Intel® Core™ -processor til gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 4<br/>Tråde: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Skub dit potentiale med den ultimative gaming-oplevelse",
                "tr_title": "Konkurrer på højeste niveau",
                "tr_gen_core_title": "10. generation Intel® Core™ i9 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FIND DEN 10. GENERATION INTEL® CORE™ PROCESSOR, DER PASSER TIL DIN SPILLESTIL.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemfri AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurrencedygtig gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og optagelse",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avanceret oprettelse af indhold",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Peak gaming-ydeevne",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og overclockable<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Skub dit potentiale med den ultimative gaming-oplevelse",
                "tr_title": "Konkurrer på højeste niveau",
                "tr_gen_core_title": "10. generation Intel® Core™ i9 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FIND DEN 10. GENERATION INTEL® CORE™ PROCESSOR, DER PASSER TIL DIN SPILLESTIL.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemfri AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurrencedygtig gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og optagelse",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avanceret oprettelse af indhold",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Peak gaming-ydeevne",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og overclockable<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 10<br/>Tråde: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Skub dit potentiale med den ultimative skaberoplevelse",
                "tr_title": "Skab på højeste niveau",
                "tr_gen_core_title": "10. generation Intel® Core™ i9 -processor",
                "tr_gen_core_title_uppercase": "10. GENERATION INTEL® CORE™ i9 -PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ bærbare computere drevet af en 11. generation Intel® Core™ i5 -processor og Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>-grafik giver dig utrolig hastighed og ydeevne - alt sammen i et tyndt og let design.",
                "tr_title": "Forhøjet effekt til en udviklet bærbar",
                "tr_gen_core_title": "11. generation Intel® Core™ i5 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i5 -PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ bærbare computere drevet af en 11. generation Intel® Core™ i7 -processor og Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>-grafik giver dig avanceret hastighed og ydeevne for en førsteklasses mobil oplevelse.",
                "tr_title": "Avanceret kraft til en udviklet bærbar computer",
                "tr_gen_core_title": "11. generation Intel® Core™ i7 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i7 -PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Nyd en god balance mellem ydeevne, medier og forbindelse til en fantastisk værdi.",
                "tr_title": "Ydeevne og forbindelse til en fantastisk værdi.",
                "tr_gen_core_title": "Intel® Pentium® Silver -processor",
                "processorCompare": {
                  "tr_title": "FIND DET RETTE NIVEAU AF YDEEVNE OG VÆRDI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Hurtig, mediefyldt webbrowsing",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nemt at skifte mellem programmer",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hverdags-gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming af 4K-videoer",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Let billed og video redigering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hurtig produktivitet med intelligent ydeevne",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Nyd problemfri multitasking, enkel foto- og videoredigering, 4K-videoer og levende grafik til en utrolig værdi.",
                "tr_title": "Imponerende ydeevne til arbejde og leg.",
                "tr_gen_core_title": "Intel® Pentium® Gold -processor",
                "processorCompare": {
                  "tr_title": "FIND DET RETTE NIVEAU AF YDEEVNE OG VÆRDI",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver -processor"
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Hurtig, mediefyldt webbrowsing",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nemt at skifte mellem programmer",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hverdags-gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming af 4K-videoer",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Let billed og video redigering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hurtig produktivitet med intelligent ydeevne",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "AAA-gaming og lang batteritid I en ultrahurtig PC. Ingen grund til at gå på kompromis med den måde du spiller. ",
                "tr_title": "Ingen grund til at gå på kompromis.",
                "tr_gen_core_title": "11. generation Intel® Core™ i5 -processor",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "FIND DIN INTEL® CORE™ ULTRABÆRBARE GAMING -PROCESSOR.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. generation Intel® Core™ i5-11300H -processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. generation Intel® Core™ i7-11370H -processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. generation Intel® Core™ i7-11375H Special Edition-processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Op til<span class='style_badge'> 4,4 GHz</span> maks. clock hastighed",
                      "tr_td2_title": "Op til<span class='style_badge'>4,8 GHz</span> maks. clock hastighed",
                      "tr_td3_title": "Op til <span class='style_badge'>5,0 GHz</span> maks. clock hastighed"
                    },
                    {
                      "tr_td1_title": "4 kerner/ 8&nbsp;tråde",
                      "tr_td2_title": "4 kerner/ 8&nbsp;tråde",
                      "tr_td3_title": "4 kerner/ 8&nbsp;tråde"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost-teknologi",
                      "tr_td2_title": "Intel® Turbo Boost-teknologi",
                      "tr_td3_title": "Intel® Turbo Boost Max Teknologi 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® Smart Cache",
                      "tr_td2_title": "MB Intel® Smart Cache",
                      "tr_td3_title": "MB Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>&#8209;grafik",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>&#8209;grafik",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>&#8209;grafik"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "1080p AAA-Gaming med høj FPS og på høje indestillinger med fremragende unplugged ydeevne I en ultrabærbar PC. ",
                "tr_title": "Fortsæt. Få det hele.",
                "tr_gen_core_title": "11. generation Intel® Core™ i7 -processor",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "FIND DIN INTEL® CORE™ ULTRABÆRBARE GAMING -PROCESSOR.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. generation Intel® Core™ i5-11300H -processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. generation Intel® Core™ i7-11370H -processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. generation Intel® Core™ i7-11375H Special Edition-processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Op til<span class='style_badge'> 4,4 GHz</span> maks. clock hastighed",
                      "tr_td2_title": "Op til<span class='style_badge'>4,8 GHz</span> maks. clock hastighed",
                      "tr_td3_title": "Op til <span class='style_badge'>5,0 GHz</span> maks. clock hastighed"
                    },
                    {
                      "tr_td1_title": "4 kerner/ 8&nbsp;tråde",
                      "tr_td2_title": "4 kerner/ 8&nbsp;tråde",
                      "tr_td3_title": "4 kerner/ 8&nbsp;tråde"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost-teknologi",
                      "tr_td2_title": "Intel® Turbo Boost-teknologi",
                      "tr_td3_title": "Intel® Turbo Boost Max Teknologi 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® Smart Cache",
                      "tr_td2_title": "MB Intel® Smart Cache",
                      "tr_td3_title": "MB Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>&#8209;grafik",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>&#8209;grafik",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>&#8209;grafik"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Op til 5,0 GHz max clock-hastighed til at tage dine spil videre og en ultrabærbar PC til at tage dem med, hvorend du er.",
                "tr_title": "Mere ydeevne for pengene. ",
                "tr_gen_core_title": "11. generation Intel® Core™ i7 Special Edition-processor",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition-processor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "FIND DIN INTEL® CORE™ ULTRABÆRBARE GAMING -PROCESSOR.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11. generation Intel® Core™ i5-11300H -processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11. generation Intel® Core™ i7-11370H -processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11. generation Intel® Core™ i7-11375H Special Edition-processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Op til<span class='style_badge'> 4,4 GHz</span> maks. clock hastighed",
                      "tr_td2_title": "Op til<span class='style_badge'>4,8 GHz</span> maks. clock hastighed",
                      "tr_td3_title": "Op til <span class='style_badge'>5,0 GHz</span> maks. clock hastighed"
                    },
                    {
                      "tr_td1_title": "4 kerner/ 8&nbsp;tråde",
                      "tr_td2_title": "4 kerner/ 8&nbsp;tråde",
                      "tr_td3_title": "4 kerner/ 8&nbsp;tråde"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost-teknologi",
                      "tr_td2_title": "Intel® Turbo Boost-teknologi",
                      "tr_td3_title": "Intel® Turbo Boost Max Teknologi 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® Smart Cache",
                      "tr_td2_title": "MB Intel® Smart Cache",
                      "tr_td3_title": "MB Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>&#8209;grafik",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>&#8209;grafik",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>&#8209;grafik"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "11. generation Intel® Core™ i7 Special Edition-processor bringer avanceret indholdsoprettelse, glat gaming og underholdning til næste niveau på ultrabærbare PCer.",
                "tr_title": "Banebrydende bærbar ydeevne",
                "tr_gen_core_title": "11. generation Intel® Core™ i7 Special Edition-processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i7 SPECIAL EDITION-PROCESSOR"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "11. generation Intel® Core™ i7 Special Edition-processor bringer avanceret indholdsoprettelse, glat gaming og underholdning til næste niveau på ultrabærbare PCer.",
                "tr_title": "Banebrydende bærbar ydeevne",
                "tr_gen_core_title": "11. generation Intel® Core™ i7 Special Edition-processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i7 SPECIAL EDITION-PROCESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition-processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Hurtige clock-hastigheder og høje kernetal fungerer problemfrit sammen for at få mere ud af competetive gaming og kompleks oprettelse af indhold.",
                "tr_title": "Massiv ydeevne. Perfekt afbalanceret. ",
                "tr_gen_core_title": "11. generation Intel® Core™ i9 -processor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -processor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Med den rette balance mellem clockhastiged og kerneydelse får du mere ud af dine spil, uanset om det er til competetive gaming med forkus på høj FPS eller en fordybende AAA oplevelse.",
                "tr_title": "Ydeevne med et formål. ",
                "tr_gen_core_title": "11. generation Intel® Core™ i5 -processor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Spil, record and stream krævende titler med høj FPS og ubesværet skifte til tunge multitasking opgaver.",
                "tr_title": "Ydeevne til at skubbe grænserne.",
                "tr_gen_core_title": "11. generation Intel® Core™ i7 -processor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hurtige clock-hastigheder og høje kernetal fungerer problemfrit sammen for at få mere ud af competetive gaming og kompleks oprettelse af indhold.",
                "tr_title": "Massiv ydeevne. Perfekt afbalanceret. ",
                "tr_gen_core_title": "11. generation Intel® Core™ i9 -processor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Du kan nemt skifte fra at streame film til at lave præsentationer med masser af ydelse til overs.",
                "tr_title": "Strøm til hele dagen",
                "tr_gen_core_title": "Intel® Core™ i3 -processor med Intel® Hybrid teknologi",
                "tr_subTitle2": "Intel® Core™ i3 -processor med Intel® Hybrid teknologi"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Alsidig ydeevne møder masser af batterilevetid, så du kan holde dig i gang, uanset hvor du er.",
                "tr_title": "Strøm til hele dagen",
                "tr_gen_core_title": "Intel® Core™ i3 -processor med Intel® Hybrid teknologi",
                "tr_subTitle2": "Intel® Core™ i3 -processor med Intel® Hybrid teknologi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Du kan nemt håndtere flere programmer, så du er klar til hvad some helst, uanset hvor dagen føre dig hen. ",
                "tr_title": "Multitasking på farten",
                "tr_gen_core_title": "Intel® Core™ i5 -processor med Intel® Hybrid teknologi",
                "tr_subTitle2": "Intel® Core™ i5 -processor med Intel® Hybrid teknologi"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Øg kvaliteten af alt, hvad du laver fra underholdning til produktivitet og indholdsproduktion.",
                "tr_title": "Opgrader til en kraftigere pc",
                "tr_gen_core_title": "Intel® Core™ i5 -processor med Intel® Hybrid teknologi",
                "tr_subTitle2": "Intel® Core™ i5 -processor med Intel® Hybrid teknologi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Mobil gaming ydeevne på niveau med en stationær uanset om det er til konkurrencedygtige spil med høj FPS eller fordybende AAA-oplevelser.",
                "tr_title": "Ydeevne med et formål. ",
                "tr_gen_core_title": "11. generation Intel® Core™ i5 -processor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Mobil gaming ydeevne på niveau med en stationær uanset om det er til konkurrencedygtige spil med høj FPS eller fordybende AAA-oplevelser.",
                "tr_title": "Ydeevne med et formål. ",
                "tr_gen_core_title": "11. generation Intel® Core™ i5 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i5 -PROCESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "En bærbar computer, der kan spille, optage og streame samtidigt med høj FPS og nemt skifte til tunge multitasking arbejdsopgaver.",
                "tr_title": "Ydeevne til at skubbe grænserne.",
                "tr_gen_core_title": "11. generation Intel® Core™ i7 -processor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "En bærbar computer, der kan spille, optage og streame samtidigt med høj FPS og nemt skifte til tunge multitasking arbejdsopgaver.",
                "tr_title": "Ydeevne til at skubbe grænserne.",
                "tr_gen_core_title": "11. generation Intel® Core™ i7 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i7 -PROCESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hurtige clock hastigheder og høje kerneantal arbejder problemfrit sammen for at få mere ud af konkurrencedygtig gaming og kompleks oprettelse af indhold, uanset hvor du tager din PC.",
                "tr_title": "Massiv ydeevne. Perfekt afbalanceret. ",
                "tr_gen_core_title": "11. generation Intel® Core™ i9 -processor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hurtige clock hastigheder og høje kerneantal arbejder problemfrit sammen for at få mere ud af konkurrencedygtig gaming og kompleks oprettelse af indhold, uanset hvor du tager din PC.",
                "tr_title": "Massiv ydeevne. Perfekt afbalanceret. ",
                "tr_gen_core_title": "11. generation Intel® Core™ i9 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i9 -PROCESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hurtige clock-hastigheder, højt kerne antal og ulåst ydeevne arbejder problemfrit sammen for at få mere ud af konkurrencedygtig gaming og kompleks oprettelse af indhold, uanset hvor du tager din bærbar.",
                "tr_title": "Ulåst kraft. Perfekt afbalanceret.",
                "tr_gen_core_title": "11. generation Intel® Core™ i9 -processor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hurtige clock-hastigheder, højt kerne antal og ulåst ydeevne arbejder problemfrit sammen for at få mere ud af konkurrencedygtig gaming og kompleks oprettelse af indhold, uanset hvor du tager din bærbar.",
                "tr_title": "Ulåst kraft. Perfekt afbalanceret.",
                "tr_gen_core_title": "11. generation Intel® Core™ i9 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i9 -PROCESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hurtige clock-hastigheder og høje kernetal fungerer problemfrit sammen for at få mere ud af competetive gaming og kompleks oprettelse af indhold.",
                "tr_title": "Massiv ydeevne. Perfekt afbalanceret. ",
                "tr_gen_core_title": "11. generation Intel® Core™ i9 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i9 -PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hurtige clock-hastigheder og høje kernetal arbejder problemfrit sammen for at få mere ud af kompleks oprettelse af indhold, uanset hvor du tager din PC.",
                "tr_title": "Massiv ydeevne. Perfekt afbalanceret. ",
                "tr_gen_core_title": "11. generation Intel® Core™ i9 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i9 -PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hurtige clock-hastigheder og høje kernetal arbejder problemfrit sammen for at få mere ud af kompleks oprettelse af indhold.",
                "tr_title": "Massiv ydeevne. Perfekt afbalanceret. ",
                "tr_gen_core_title": "11. generation Intel® Core™ i9 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i9 -PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Skub dit potentiale med den ultimative gaming-oplevelse",
                "tr_title": "Konkurrer på højeste niveau",
                "tr_gen_core_title": "10. generation Intel® Core™ i9 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FIND DEN 10. GENERATION INTEL® CORE™ PROCESSOR, DER PASSER TIL DIN SPILLESTIL.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemfri AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurrencedygtig gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og optagelse",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avanceret oprettelse af indhold",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Peak gaming-ydeevne",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og overclockable<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Skub dit potentiale med den ultimative gaming-oplevelse",
                "tr_title": "Konkurrer på højeste niveau",
                "tr_gen_core_title": "10. generation Intel® Core™ i9 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FIND DEN 10. GENERATION INTEL® CORE™ PROCESSOR, DER PASSER TIL DIN SPILLESTIL.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Problemfri AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurrencedygtig gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming og optagelse",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Avanceret oprettelse af indhold",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Peak gaming-ydeevne",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ulåst og overclockable<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 10<br/>Tråde: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Til den, der altid er på farten: en bærbar computer, der er udviklet specielt til dig.",
                "tr_title": "Oplev en ny klasse af bærbare computere",
                "tr_gen_core_title": "10. generation Intel® Core™ i9 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hurtige clock-hastigheder og høje kernetal fungerer problemfrit sammen for at få mere ud af competetive gaming og kompleks oprettelse af indhold.",
                "tr_title": "Ulåst kraft. Perfekt afbalanceret.",
                "tr_gen_core_title": "11. generation Intel® Core™ i9 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i9 -PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hurtige clock-hastigheder, høje kernetal og ulåst ydeevne arbejder problemfrit sammen for at få mere ud af kompleks oprettelse af indhold, uanset hvor du tager din PC.",
                "tr_title": "Ulåst kraft. Perfekt afbalanceret.",
                "tr_gen_core_title": "11. generation Intel® Core™ i9 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i9 -PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hurtige clock-hastigheder og høje kernetal arbejder problemfrit sammen for at få mere ud af kompleks oprettelse af indhold.",
                "tr_title": "Ulåst kraft. Perfekt afbalanceret.",
                "tr_gen_core_title": "11. generation Intel® Core™ i9 -processor",
                "tr_gen_core_title_uppercase": "11. GENERATION INTEL® CORE™ i9 -PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Gnidningsløst spil og VR på de mest krævende titler",
                "tr_title": "Seriøse spillere starter her",
                "tr_gen_core_title": "9. generation Intel® Core™ i5 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FIND DEN INTEL® CORE™ -PROCESSOR, DER PASSER TIL DIN SPILLESTIL.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurrencedygtig gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spil, stream og optag uden at gå på kompromis",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bedste 9. generation Intel® Core™ -processor til gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 4<br/>Tråde: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "OP TIL 32 % FLERE FPS <br/><span class='tr_subtitle_font'>under gaming, streaming og optagelse vs. en 3 år gammel PC<sup>3</sup></span>",
                "tr_title": "Del dine bedste gamingminder",
                "tr_gen_core_title": "9. generation Intel® Core™ i7 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FIND DEN INTEL® CORE™ -PROCESSOR, DER PASSER TIL DIN SPILLESTIL.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurrencedygtig gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spil, stream og optag uden at gå på kompromis",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bedste 9. generation Intel® Core™ -processor til gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 4<br/>Tråde: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "OP TIL 41 % FLERE FPS <br/><span class='tr_subtitle_font'>under gaming, streaming og optagelse vs. en 3 år gammel PC<sup>4</sup></span>",
                "tr_title": "Gamere kræver. Intel® Core™ i9 leverer.",
                "tr_gen_core_title": "9. generation Intel® Core™ i9 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>tråde</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>kerner</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Op til<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ulåst",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "FIND DEN INTEL® CORE™ -PROCESSOR, DER PASSER TIL DIN SPILLESTIL.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 -processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 -processor"
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 -processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Konkurrencedygtig gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Spil, stream og optag uden at gå på kompromis",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bedste 9. generation Intel® Core™ -processor til gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 4<br/>Tråde: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 6<br/>Tråde: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Clock-hastighed<sup>2</sup>: Op til 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Kerner: 8<br/>Tråde: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Bygget til den næste generation af gaming",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "12. generation Intel® Core™ i5 &#8209;processor",
                    "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i5 &#8209;PROCESSOR"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12. generation Intel® Core™ i7 &#8209;processor",
                    "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i7 &#8209;PROCESSOR"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12. generation Intel® Core™ i9 &#8209;processor",
                    "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i9 &#8209;PROCESSOR"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kerner</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>tråde</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Battlestation møder arbejdsstation",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12. generation Intel® Core™ i7 &#8209;processor",
                    "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i7 &#8209;PROCESSOR"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12. generation Intel® Core™ i9 &#8209;processor",
                    "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i9 &#8209;PROCESSOR"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kerner</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>tråde</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Pålidelig ydeevne, når du har mest brug for det",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "12. generation Intel® Core™ i3 &#8209;processor",
                "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i3 &#8209;PROCESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Et løft af ydeevne, hvor det er nødvendigt mest",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12. generation Intel® Core™ i5 &#8209;processor",
                "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i5 &#8209;PROCESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Et løft af ydeevne, hvor det er nødvendigt mest",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12. generation Intel® Core™ i5 &#8209;processor",
                "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i5 &#8209;PROCESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Overlegen ydeevne, hvor du har mest brug for det",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12. generation Intel® Core™ i7 &#8209;processor",
                "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i7 &#8209;PROCESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Overlegen ydeevne, hvor du har mest brug for det",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12. generation Intel® Core™ i7 &#8209;processor",
                "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i7 &#8209;PROCESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med Intel® Evo™ bærbare computere får du ydeevne, indbygget intelligens til videochats, hurtigere internet, lang batterilevetid og mere.",
                "tr_title": "Et løft af ydeevne, hvor det er nødvendigt mest.",
                "tr_gen_core_title": "12. generation Intel® Core™ i5 &#8209;processor",
                "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i5 &#8209;PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med Intel® Evo™ bærbare computere får du ydeevne, indbygget intelligens til videochats, hurtigere internet, lang batterilevetid og mere.",
                "tr_title": "Overlegen ydeevne, hvor du har mest brug for det",
                "tr_gen_core_title": "12. generation Intel® Core™ i7 &#8209;processor",
                "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i7 &#8209;PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med Intel® Evo™ bærbare computere får du ydeevne, indbygget intelligens til videochats, hurtigere internet, lang batterilevetid og mere.",
                "tr_title": "Ekstrem ydeevne i en bærbar computer i topklasse",
                "tr_gen_core_title": "12. generation Intel® Core™ i9 &#8209;processor",
                "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i9 &#8209;PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Med Intel® Turbo Boost-teknologi 2.0 op til [max_turbo_frequency] GHz",
                "tr_title": "Built for business",
                "tr_gen_core_title": "12. generation Intel® Core™ i5 &#8209;processor",
                "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i5 &#8209;PROCESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Undertrykker støj og slører din baggrund",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatisk, skræddersyet ydeevne for at prioritere det, du gør mest",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Oplev problemfri webopkald af høj kvalitet",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Får out-of-the-box hardware-baseret sikkerhed.",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Leverer egenskaber til fjernstyret enhedsstyring",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatisk optimering af din Wi-Fi-ydeevne<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Drevet gennem datadrevne apps",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Den højeste produktivitet med forbedret ydeevne ",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Med Intel® Turbo Boost-teknologi 2.0 op til [max_turbo_frequency] GHz",
                "tr_title": "Arbejder hårdt, så du kan fokusere på din virksomhed.",
                "tr_gen_core_title": "12. generation Intel® Core™ i7 &#8209;processor",
                "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i7 &#8209;PROCESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Undertrykker støj og slører din baggrund",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatisk, skræddersyet ydeevne for at prioritere det, du gør mest",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Oplev problemfri webopkald af høj kvalitet",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Får out-of-the-box hardware-baseret sikkerhed.",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Leverer egenskaber til fjernstyret enhedsstyring",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatisk optimering af din Wi-Fi-ydeevne<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Drevet gennem datadrevne apps",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Den højeste produktivitet med forbedret ydeevne ",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Med Intel® Turbo Boost-teknologi 2.0 op til [max_turbo_frequency] GHz",
                "tr_title": "Sæt gang i din virksomhed",
                "tr_gen_core_title": "12. generation Intel® Core™ i9 &#8209;processor",
                "tr_gen_core_title_uppercase": "12. GENERATION INTEL® CORE™ i9 &#8209;PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Undertrykker støj og slører din baggrund",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatisk, skræddersyet ydeevne for at prioritere det, du gør mest",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Oplev problemfri webopkald af høj kvalitet",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Får out-of-the-box hardware-baseret sikkerhed.",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Leverer egenskaber til fjernstyret enhedsstyring",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatisk optimering af din Wi-Fi-ydeevne<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Drevet gennem datadrevne apps",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Den højeste produktivitet med forbedret ydeevne ",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Pålidelig ydeevne dedikeret til at gøre mere",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "13. generation Intel® Core™ i3 &#8209;processor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Reel ydeevne dedikeret til at gøre mere",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13. generation Intel® Core™ i5 &#8209;processor",
                "tr_gen_core_title_uppercase": "13. GENERATION INTEL® CORE™ i5 &#8209;PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Reel ydeevne dedikeret til at gøre mere",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13. generation Intel® Core™ i5 &#8209;processor",
                "tr_gen_core_title_uppercase": "13. GENERATION INTEL® CORE™ i5 &#8209;PROCESSOR",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Kraftfuld ydeevne dedikeret til at gøre mere",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13. generation Intel® Core™ i7 &#8209;processor",
                "tr_gen_core_title_uppercase": "13. GENERATION INTEL® CORE™ i7 &#8209;PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Kraftfuld ydeevne dedikeret til at gøre mere",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13. generation Intel® Core™ i7 &#8209;processor",
                "tr_gen_core_title_uppercase": "13. GENERATION INTEL® CORE™ i7 &#8209;PROCESSOR",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Usædvanlig performance",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "13. generation Intel® Core™ i9 &#8209;processor",
                "tr_gen_core_title_uppercase": "13. GENERATION INTEL® CORE™ i9 &#8209;PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kerner</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>tråde</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Du kan oprette en problemfri forbindelse til din telefon<sup>24</sup>, vælge det bedste Wi-Fi på intelligent vis og oplade hurtigt med et batteri, der holder længe.",
                "tr_title": "Overlegen ydeevne til at få mere gjort",
                "tr_gen_core_title": "13. generation Intel® Core™ i5 &#8209;processor",
                "tr_gen_core_title_uppercase": "13. GENERATION INTEL® CORE™ i5 &#8209;PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Du kan oprette en problemfri forbindelse til din telefon<sup>24</sup>, vælge det bedste Wi-Fi på intelligent vis og oplade hurtigt med et batteri, der holder længe.",
                "tr_title": "Gå ud over ydeevnen og lad din PC gøre endnu mere på én gang",
                "tr_gen_core_title": "13. generation Intel® Core™ i7 &#8209;processor",
                "tr_gen_core_title_uppercase": "13. GENERATION INTEL® CORE™ i7 &#8209;PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Du kan oprette en problemfri forbindelse til din telefon<sup>24</sup>, vælge det bedste Wi-Fi på intelligent vis og oplade hurtigt med et batteri, der holder længe.",
                "tr_title": "Gå ud over ydeevne for at fremskynde de mest krævende opgaver",
                "tr_gen_core_title": "13. generation Intel® Core™ i9 &#8209;processor",
                "tr_gen_core_title_uppercase": "13. GENERATION INTEL® CORE™ i9 &#8209;PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Usædvanlig performance",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "13. generation Intel® Core™ i5 &#8209;processor",
                    "tr_gen_core_title_uppercase": "13. GENERATION INTEL® CORE™ i5 &#8209;PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "13. generation Intel® Core™ i7 &#8209;processor",
                    "tr_gen_core_title_uppercase": "13. GENERATION INTEL® CORE™ i7 &#8209;PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "13. generation Intel® Core™ i9 &#8209;processor",
                    "tr_gen_core_title_uppercase": "13. GENERATION INTEL® CORE™ i9 &#8209;PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kerner</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>tråde</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Usædvanlig performance",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 -processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 -PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 -processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 -PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 -processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 -PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kerner</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>tråde</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Pålidelig ydeevne dedikeret til at gøre mere",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Intel® Core™ i3 -processor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Reel ydeevne dedikeret til at gøre mere",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Intel® Core™ i5 -processor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 -PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Kraftfuld ydeevne dedikeret til at gøre mere",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Intel® Core™ i7 -processor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 -PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Usædvanlig performance",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 -processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 -PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 -processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 -PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 -processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 -PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kerner</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>tråde</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Pålidelig ydeevne dedikeret til at gøre mere",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 3 -processor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Reel ydeevne dedikeret til at gøre mere",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 5 -processor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 5 -PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Kraftfuld ydeevne dedikeret til at gøre mere",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 7 -processor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 7 -PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Gaming uden kompromis",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 5 -processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 5 -PROCESSOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 7 -processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 7 -PROCESSOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 9 -processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 9 -PROCESSOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>kerner</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core] LPE-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>tråde</span></span>",
                    "tr_badgePreTitle": "[p_thread] på Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] på Efficient-cores",
                    "tr_badgeSubTitle1": "[l_thread] på LPE-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 5 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 7 -processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 9 -processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Gaming uden kompromis",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Gaming uden kompromis",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Gaming uden kompromis",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Gaming uden kompromis",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Gaming uden kompromis",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Gaming uden kompromis",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Små virksomheder fortjener store forretningsløsninger.",
                "tr_gen_core_title": "13. generation Intel® Core™ i5 &#8209;processor",
                "tr_gen_core_title_uppercase": "13. GENERATION INTEL® CORE™ i5 &#8209;PROCESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Undertrykker støj og slører din baggrund",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatisk, skræddersyet ydeevne for at prioritere det, du gør mest",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Oplev problemfri webopkald af høj kvalitet",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Hardwarebaseret sikkerhedsbeskyttelse lige til at pakke ud",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Hjælper med at beskytte enheder og data fra fysiske manipulationsangreb",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Små virksomheder fortjener store forretningsløsninger.",
                "tr_gen_core_title": "13. generation Intel® Core™ i7 &#8209;processor",
                "tr_gen_core_title_uppercase": "13. GENERATION INTEL® CORE™ i7 &#8209;PROCESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Undertrykker støj og slører din baggrund",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatisk, skræddersyet ydeevne for at prioritere det, du gør mest",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Oplev problemfri webopkald af høj kvalitet",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Hardwarebaseret sikkerhedsbeskyttelse lige til at pakke ud",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Hjælper med at beskytte enheder og data fra fysiske manipulationsangreb",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Små virksomheder fortjener store forretningsløsninger.",
                "tr_gen_core_title": "13. generation Intel® Core™ i9 &#8209;processor",
                "tr_gen_core_title_uppercase": "13. GENERATION INTEL® CORE™ i9 &#8209;PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE PROCESSOR?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuel enhed",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Undertrykker støj og slører din baggrund",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatisk, skræddersyet ydeevne for at prioritere det, du gør mest",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Oplev problemfri webopkald af høj kvalitet",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Hardwarebaseret sikkerhedsbeskyttelse lige til at pakke ud",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Hjælper med at beskytte enheder og data fra fysiske manipulationsangreb",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Lås op for nye AI-oplevelser til virksomheder",
                "tr_title": "Vær klar til en helt ny oplevelse ved hjælp af Intel® Core™ Ultra&nbsp;[XX] -processorer og Intel vPro®",
                "tr_gen_core_title": "Intel® Core™ Ultra [XX] -processor",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "GIV DIN VIRKSOMHED ET LØFT MED AI TIL PC'ER FRA INTEL",
                    "tr_badgeSubTitle": "Kraftfulde PC'er, der skaber en fantastisk oplevelse, er kernen i virksomhedernes produktivitet. Intel vPro® øger medarbejdernes effektivitet og ydeevne på tværs af arbejdsbelastninger og programmer takket være en ny arkitektur, der leverer overlegen strømeffektiv ydeevne til forskellige, komplekse virksomhedsprojekter ved at sende den rigtige opgave til den rigtige motor på det rette tidspunkt.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "SIKKERHEDSFUNKTIONER MED FLERE LAG GIVER DIG ET FORSPRING FRA START AF",
                    "tr_badgeSubTitle": "Nye og potentielle trusler kræver en omfattende tilgang til sikkerhed. Intel vPro® giver beskyttelse på hardwareniveau og i hele stakken, så din virksomhed er bedre beskyttet. Disse unikke sikkerhedsforanstaltninger, herunder AI-drevede sporingsegenskaber mod trusler, er der fra start af, så du kan beskytte dine brugere, dine data og din virksomhed.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "INDBYGGEDE VÆRKTØJER HOLDER BRUGERNE PRODUKTIVE, UANSET HVOR DE ER",
                    "tr_badgeSubTitle": "Kontoret er overalt, og det gælder også for pc'er. Intel vPro® sørger for moderne hardwarebaserede administrationsevner til at hjælpe virksomheder med at lette byrden ved pc-flådehåndtering, forenkling af support og forbedring af slutbrugeroplevelsen – alt sammen, mens bæredytig databehandlingspraksis gøres mulig.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mere end en fantastisk PC, en AI-PC ",
                "tr_subtitle1": "DREVET AF EN INTEL®&nbsp;CORE™ ULTRA &#8209;PROCESSORER (SERIEN&nbsp;2) ",
                "tr_subtitle2": "Det er muligt på din Intel AI-PC ",
                "tr_subtitle": "Intels mest effektive, sikre og højtydende processor til bærbare computere<sup>39</sup> ",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 &#8209;processor (Serien&nbsp;2) ",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Mere kreativ</span> med AI-værktøjer for at skabe hurtigere"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mere produktiv</span> med tidsbesparende AI-hjælp"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mere sikker</span> med indbygget databeskyttelse"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mere end en fantastisk PC, en AI-PC ",
                "tr_subtitle1": "DREVET AF EN INTEL®&nbsp;CORE™ ULTRA &#8209;PROCESSORER (SERIEN&nbsp;2) ",
                "tr_subtitle2": "Det er muligt på din Intel AI-PC ",
                "tr_subtitle": "Intels mest effektive, sikre og højtydende processor til bærbare computere<sup>39</sup> ",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 &#8209;processor (Serien&nbsp;2) ",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Mere kreativ</span> med AI-værktøjer for at skabe hurtigere"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mere produktiv</span> med tidsbesparende AI-hjælp"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mere sikker</span> med indbygget databeskyttelse"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mere end en fantastisk PC, en AI-PC ",
                "tr_subtitle1": "DREVET AF EN INTEL®&nbsp;CORE™ ULTRA &#8209;PROCESSORER (SERIEN&nbsp;2) ",
                "tr_subtitle2": "Det er muligt på din Intel AI-PC ",
                "tr_subtitle": "Intels mest effektive, sikre og højtydende processor til bærbare computere<sup>39</sup> ",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 &#8209;processor (Serien&nbsp;2) "
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mere end en fantastisk PC, en AI-PC ",
                "tr_subtitle1": "DREVET AF EN INTEL®&nbsp;CORE™ ULTRA &#8209;PROCESSORER (SERIEN&nbsp;2) ",
                "tr_subtitle2": "Det er muligt på din Intel AI-PC ",
                "tr_subtitle": "Intels mest effektive, sikre og højtydende processor til bærbare computere<sup>39</sup> ",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 &#8209;processor (Serien&nbsp;2) "
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_tileTitle1": "Dedikeret grafik",
                "tr_title": "HVAD ER EN GRAFIKPROCESSORENHED?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Grafikprocessorenheden eller GPU'en skaber de billeder, du ser på skærmen.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integreret GPU",
                    "tr_badgeSubTitle": "En integreret GPU bruger samme RAM som resten af computeren, hvilket gør den mere energieffektiv.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Dedikeret GPU",
                    "tr_badgeSubTitle": "Dette er den mest kraftfulde type af GPU, fordi den har sin egne RAM og andre ressourcer, der er dedikeret til at opbygge enestående grafik.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "Hybrid-GPU",
                    "tr_badgeSubTitle": "Dette er den mest kraftfulde type af GPU, fordi den har sin egne RAM og andre ressourcer, der er dedikeret til at opbygge enestående grafik.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Strømlinet foto og video redigering",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Binge 4K video indhold",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Spil populære spil i fantastiske detaljer",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Dobbelte GPU'er",
                    "tr_badgeSubTitle": "Nogle systemer, såsom dem med Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>-grafik, kan udnytte både den indbyggede processor og dedikeret GPU til at distribuere grafikkens arbejdsbelastning.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integreret GPU",
                    "tr_badgeSubTitle": "En integreret GPU er indbygget i samme chip som den primære processor eller CPU'en. Denne type GPU benytter samme RAM og andre ressourcer som resten af computeren, så den bliver mere energieffektiv og giver mulighed for en tyndere, lettere enhed.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Dedikeret GPU",
                    "tr_badgeSubTitle": "Dette er den mest kraftfulde type af GPU, fordi den har sin egne RAM og andre ressourcer, der er dedikeret til at opbygge enestående grafik.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Standard",
                    "tr_badgeSubTitle": "Op til 4 GB VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Konkurrence-dygtig",
                    "tr_badgeSubTitle": "Op til 8 GB VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "Større end 8 GB VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Intens grafik",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Den mest krævende grafikbehandling forudsætter grafikkort, som udelukkende fokuserer på at skabe smukke, skarpe billeder og flydende animation.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Rig og medrivende grafik, der er indbygget",
                "tr_pretitle": "Intel® Iris® Plus-grafik",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Med Intel® Iris® Plus-grafik kan du fordybe dig i 4K HDR-video og Full HD gaming, uden at du har brug for et ekstra grafikkort.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Enestående billeder, ubesværet ydeevne",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® -Grafik leverer transformationel grafisk ydeevne til at understøtte flydende design til skaberne og rige gamingoplevelser.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Intel® Iris® Pro-grafik",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Intel® UHD-grafik med X<sup class='small_text_sup_xe'>e</sup> arkitektur",
                "tr_pretitle": "Fordybende visuelle oplevelser",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Oplev strømlinet foto og videoredigering, binge premium video indhold i 4K eller spil populære spil med fantastiske detaljer.",
                "tr_photo_text": "Strømlinet foto og video redigering",
                "tr_video_text": "Binge 4K video indhold",
                "tr_game_text": "Spil populære spil i fantastiske detaljer",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MEGET MERE END BARE FLOTTE BILLEDER",
                "tr_subtitle": "I tæt samarbejde med processoren (CPU) hjælper grafikkortet (eller GPU) med at afgøre, hvordan dine spil kører, og hvordan de ser ud. VRAM, eller video-RAM, er dedikeret til at lave grafik, og er en hurtig måde at finde ud af hvor din GPU står.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "FOR INTENS GAMING-GRAFIK",
                "tr_subtitle": "Den mest kraftfulde grafikbehandling kræver et separat grafikkort, som udelukkende fokuserer på at skabe smukke, skarpe billeder og flydende animation.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "FOR INTENS GAMING-GRAFIK",
                "tr_subtitle": "Den mest kraftfulde grafikbehandling kræver et separat grafikkort, som udelukkende fokuserer på at skabe smukke, skarpe billeder og flydende animation.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MEGET MERE END BARE FLOTTE BILLEDER",
                "tr_subtitle": "I tæt samarbejde med processoren (CPU) hjælper grafikkortet (eller GPU) med at afgøre, hvordan dine spil kører, og hvordan de ser ud. VRAM, eller video-RAM, er dedikeret til at lave grafik, og er en hurtig måde at finde ud af hvor din GPU står.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Intens grafik",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Den mest krævende grafikbehandling forudsætter grafikkort, som udelukkende fokuserer på at skabe smukke, skarpe billeder og flydende animation.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "FOR INTENS GAMING-GRAFIK",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "Den mest krævende grafikbehandling forudsætter grafikkort, som udelukkende fokuserer på at skabe smukke, skarpe billeder og flydende animation.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Den mest kraftfulde grafikbehandling kræver et separat grafikkort, som udelukkende fokuserer på at skabe smukke, skarpe billeder og flydende animation.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Leverer masser af grafik for at opfylde dit spils minimumskrav.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "For mere avancerede spillere, der kræver gaming på konkurrenceniveau.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "For avancerede gamere, der også bruger deres pc til krævende oprettelse af indhold.",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Levende video og medrivende gaming",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Få mest muligt ud af din skærm med høj opløsning med Intel® HD-grafikkort, og nyd en fuld multimedieoplevelse og indbygget grafik.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Giver liv til levende videoer og billeder",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Få flere detaljer og strålende farve fra din streaming af video og billeder.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Fantastisk til skabelse og gaming",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>&#8209;grafik",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Fra fordybende underholdning og avanceret oprettelse af indhold til mainstream gaming: Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>-grafik bringer nytænkende grafisk præstation til tynde og lette PC'er.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_pretitle": "FOR INTENS GAMING-GRAFIK",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>&#8209;grafik",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Den mest kraftfulde grafikbehandling kræver et separat grafikkort, som udelukkende fokuserer på at skabe smukke, skarpe billeder og flydende animation.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Slip din kreativitet fri",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX grafik",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Stryg gennem kreative projekter, og indlev dig i en lang række spil på den kombinerede kraft af den indbyggede processorgrafik og den første Intel® dedikerede grafikmotor.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX grafik",
                "tr_pretitle": "FOR INTENS GAMING-GRAFIK",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Den mest kraftfulde grafikbehandling kræver et separat grafikkort, som udelukkende fokuserer på at skabe smukke, skarpe billeder og flydende animation.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Grafik",
                "tr_title": "Smuk grafik indbygget",
                "tr_subtitle": "Nyd spil, film og videoer på nettet med utrolige detaljer og klarhed.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafik",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Giv dine arbejdsgange et løft"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Giv dine spil et ekstra boost"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Giv dine spil et ekstra boost"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Få et højere niveau i dit spil"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "SLIP DIN KREATIVITET FRI",
                    "tr_specText": "Fremskynd din produktivitet, uanset hvor du er, med de mest omfattende mediefunktioner, som eksempelvis den <strong>første AV1-hardwarekodning</strong> i branchen og AI-accelereret indholdsskabelse.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "SLIP DIN KREATIVITET FRI",
                    "tr_specText": "Fremskynd din produktivitet med de mest omfattende mediefunktioner, som eksempelvis den <strong>første AV1-hardwarekodning</strong> i branchen og AI-accelereret content skabelse.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "SLIP DIN KREATIVITET FRI",
                    "tr_specText": "Oplev banebrydende AI-accelereret content skabelse med <strong>den første AV1-hardware-encode</strong> og den avancerede <strong>X<sup>e</sup> Media Engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "SLIP DIN KREATIVITET FRI",
                    "tr_specText": "Oplev banebrydende AI-accelereret content skabelse med <strong>den første AV1-hardware-encode</strong> og den avancerede <strong>X<sup>e</sup> Media Engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "SLIP DIN KREATIVITET FRI",
                    "tr_specText": "Oplev banebrydende AI-accelereret content skabelse med <strong>den første AV1-hardware-encode</strong> og den avancerede <strong>X<sup>e</sup> Media Engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "BÆRBARHED MØDER YDEEVNE",
                    "tr_specText": "Spil de nyeste spil på farten, og få en superopladet ydeevne og kvalitet med moderne spilfunktioner. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-forbedret opskaleringsteknologi for mere ydeevne og smidige spiloplevelser. <br/><br/>Understøttelse af <strong>DirectX* 12 Ultimate:  </strong>Tag din gaming til et nyt niveau af realisme med grafikteknologier som ray tracing og rate shading."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "FÅ KONTROL OVER DINE SPIL",
                    "tr_specText": "<strong>Intel® Arc™ Control</strong> giver dig mulighed for at forfine din oplevelse med et intuitivt og moderne layout. Få problemfri driveropdateringer, optimer dine spil og meget mere."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "FÅ KONTROL OVER DINE SPIL",
                    "tr_specText": "<strong>Intel® Arc™ Control</strong> giver dig mulighed for at forfine din oplevelse med et intuitivt og moderne layout. Få problemfri driveropdateringer, optimer dine spil og meget mere."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "FÅ KONTROL OVER DINE SPIL",
                    "tr_specText": "Få problemfri driveropdateringer og optimer mange af dine yndlingsspil med <strong>Intel® Arc™ Control</strong> -software."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "MAKSIMÉR DIN INTEL® -PLATFORM",
                    "tr_specText": "Par med kompatible Intel® Core™ -processorer for at frigøre <strong>Intel® Deep Link -teknologi</strong> og forbedre din oplevelse med skabelse, spil og streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "MAKSIMÉR DIT INTEL® -SYSTEM",
                    "tr_specText": "Par med kompatible Intel® Core™ -processorer for at frigøre <strong>Intel® Deep Link -teknologi</strong> og forbedre din oplevelse med skabelse, spil og streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "MAKSIMÉR DIN INTEL® -PLATFORM",
                    "tr_specText": "Par med kompatible Intel® Core™ -processorer for at frigøre <strong>Intel® Deep Link -teknologi</strong> og forbedre din oplevelse med skabelse, spil og streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAKSIMÉR DIN INTEL® -PLATFORM",
                    "tr_specText": "Par med kompatible Intel® Core™ -processorer for at frigøre <strong>Intel® Deep Link -teknologi</strong> og forbedre din oplevelse med skabelse, spil og streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAKSIMÉR DIT INTEL® -SYSTEM",
                    "tr_specText": "Par med kompatible Intel® Core™ -processorer for at frigøre <strong>Intel® Deep Link -teknologi</strong> og forbedre din oplevelse med skabelse, spil og streaming."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "STÅ I CENTRUM",
                    "tr_specText": "Broadcast uden problemer med avancerede streamingteknologier, der er muliggjort af <strong>Intel® Arc™ Control</strong> -softwaren."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "STÅ I CENTRUM",
                    "tr_specText": "Broadcast uden problemer med avancerede streamingteknologier, der er muliggjort af <strong>Intel® Arc™ Control</strong> -softwaren."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "OPLEV MEDRIVENDE SPIL MED HØJ YDEEVNE",
                    "tr_specText": "Sæt din bedste ydeevne først med de nyeste AAA-spil i høje opløsninger og med høje opdateringshastigheder. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-forbedret opskaleringsteknologi for mere ydeevne og smidige spiloplevelser. <br/><br/>Understøttelse af <strong>DirectX* 12 Ultimate:  </strong>Tag din gaming til et nyt niveau af realisme med grafikteknologier som ray tracing og rate shading."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "OPLEV MEDRIVENDE SPIL MED HØJ YDEEVNE",
                    "tr_specText": "Sæt din bedste ydeevne først med de nyeste AAA-spil i høje opløsninger og med høje opdateringshastigheder. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-forbedret opskaleringsteknologi for mere ydeevne og smidige spiloplevelser. <br/><br/>Understøttelse af <strong>DirectX* 12 Ultimate:  </strong>Tag din gaming til et nyt niveau af realisme med grafikteknologier som ray tracing og rate shading."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "GIVE DINE SPIL ET LØFT",
                    "tr_specText": "Tag din spiloplevelse til det næste niveau med: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-forbedret opskaleringsteknologi for mere ydeevne og flydende spil. <br/><br/>Understøttelse af <strong>DirectX* 12 Ultimate:  </strong>Tag din gaming til et nyt niveau af realisme med grafikteknologier som ray tracing og rate shading."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "OPLEV AVANCERET SPIL I HØJT TEMPO",
                    "tr_specText": "Nyd en flydende gaming i mange populære AAA-spil og spil med høj opdateringhastighed alt af fra højoktan esports-shootere til hurtige RTS-hits. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-forbedret opskaleringsteknologi for mere ydeevne og smidige spiloplevelser. <br/><br/>Understøttelse af <strong>DirectX* 12 Ultimate:  </strong>Tag din gaming til et nyt niveau af realisme med grafikteknologier som ray tracing og rate shading."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "OPLEV MEDRIVENDE SPIL MED HØJ YDEEVNE",
                    "tr_specText": "Sæt din bedste ydeevne først med de nyeste AAA-spil i høje opløsninger og med høje opdateringshastigheder. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-forbedret opskaleringsteknologi for mere ydeevne og smidige spiloplevelser. <br/><br/>Understøttelse af <strong>DirectX* 12 Ultimate:  </strong>Tag din gaming til et nyt niveau af realisme med grafikteknologier som ray tracing og rate shading."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Et grafikunivers med plads til alle"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "ACCELERÉR DINE FRAMEHASTIGHEDER, ACCELERÉR DINE SPIL",
                    "tr_specText": "Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling teknologi (X<sup class='small_text_sup_xe'>e</sup>SS) tager din gaming til det næste niveau med AI-forbedret opskalering—for peak ydeevne med høj billedkvalitet. X<sup class='small_text_sup_xe'>e</sup>SS er optimeret til Intel® Arc™ -grafikprodukter, så du kan drage fuld fordel af Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX) hardware acceleration."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "DE ULTIMATIVE BILLEDER ER HER",
                    "tr_specText": "Med fuld understøttelse af DirectX* 12 Ultimate på al Intel® Arc™ -grafik vil du nyde fantastisk og indlevende gaming takket være den seneste grafik som ray tracing, variabel hastighedsshading, mesh shading og sampler feedback—det grundlæggende i den næste generation af gaming."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "PROBLEMFRI OPRETTELSE AF BILLEDER, VIDEO OG MEDIER",
                    "tr_specText": "Slip din fantasi fri og fang dit publikum med rig digital multimediearbejde skabt ved hjælp af en hyperavanceret mediemaskine—forstærket af AI og accelereret med Intel® -teknologi. Opret spektakulært indhold, drevet af grafik med understøttelse af alle nuværende førende medieformater og hold dig samtidig ajour med de mest avancerede AV1-videokodningskapaciteter."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "OPLEV BANEBRYDENDE OPRETTELSE MED AI OG GAMING",
                    "tr_specText": "Intel® Arc™ -grafik låser op for kraftfulde AI-egenskaber fra avanceret oprettelse til indlevende gaming. Få mest muligt ud af nutidig, avanceret oprettelsessoftware herunder tekst-til-billede generation og AI-accelereret videoredigering. Plus, nyd gaming i høj opløsning gennem AI-accelereret X<sup class='small_text_sup_xe'>e</sup>SS-opskalering."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Grafik",
                    "tr_specText": "Professionel grafik begynder her"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "FANTASTISK TIL KREATIVE DESIGNERE OG INGENIØRER PÅ FARTEN",
                    "tr_specText": "Fås på udvalgte Intel® Core™ Ultra -processorer H-serien, indbygget Intel® Arc™ -grafik låser op for 2x forbedret grafisk ydeevne med Independent Software Vendor (ISV)-certificeringer for ekstra pålidelighed og giver effektiv AI og ray tracing hardware-acceleration til enestående multimediearbejde på farten. <br/><br/><span class='expandable_heading'>Softwarecertificeringer<br/></span><br/>Intel har arbejdet hånd i hånd med hundredvis af softwarevirksomheder gennem årene, og denne uovertruffen oplevelse afspejles ubeskåret i vores Intel® Arc™ Pro A-serien -grafikkort. Certificeringer er lige så vigtige for os, som de er for dig."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "GØR AI OPNÅELIG",
                    "tr_specText": "GPU-sortiment indeholder et bredt udvalg af god teknologi, inklusiv understøttelse dine kompatible AI-værktøjer med indbygget dedikeret AI-acceleration, hvor du har brug for det."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING UDEN PREMIUM",
                    "tr_specText": "Grafikkort skal klare mere end blot at flytte pixels på skærmene, de skal også kunne sætte fart i skabelsen af smukke billeder. Ray tracing hardwareteknologi indbygget i Intel® Arc™ Pro A-serien -grafik giver din kompatible software mulighed for at udforske disse nye pro-arbejdsgange. Ray tracing tænkes typisk som tilgængelig kun på dyre grafikkort, men Intels nyeste profesionelle grafikserie bryder den forventning."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "GIVER SKABERE MULIGHED FOR AT ARBEJDE MED FORDYBENDE SKÆRME",
                    "tr_specText": "Med fuld understøttelse af DirectX* 12 Ultimate på al Intel® Arc™ -grafik vil du nyde fantastisk og indlevende gaming takket være den seneste grafik som ray tracing, variabel hastighedsshading, mesh shading og sampler feedback—det grundlæggende i den næste generation af gaming."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "DYNAMISK OPTIMEREDE BILLEDER",
                    "tr_specText": "Med dedikeret hardware på Pro GPU'erne hjælper Dolby Vision* med at tilføje større dybde, skarpere kontrast og flere farver til din kompatible skærm. Dolby Vision* låser op for det fulde potentiale af HDR ved dynamisk at optimere billedkvaliteten baseret på din tjeneste, skærmenhed og platform for at levere fantastiske billeder hver gang."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "EKSPERTBEKRÆFTET SOFTWARE",
                    "tr_specText": "Intel har arbejdet hånd i hånd med hundredvis af softwarevirksomheder gennem årene, og denne uovertruffen oplevelse afspejles i vores professionnelle Intel® Arc™ Pro A-serien -grafikserie. Certificeringer og software arbejdsgang-kvalifikationer er lige så vigtige for os, som de er for dig."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING UDEN PREMIUM",
                    "tr_specText": "Grafikkort skal klare mere end blot at flytte pixels på skærmene, de skal også kunne sætte fart i skabelsen af smukke billeder. Ray tracing hardwareteknologi indbygget i Intel® Arc™ Pro A-serien -grafik giver din kompatible software mulighed for at udforske disse nye pro-arbejdsgange. Ray tracing tænkes typisk som tilgængelig kun på dyre grafikkort, men Intels nyeste profesionelle grafikserie bryder den forventning."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "FLERE STORE SKÆRME MED EN LILLE, MOBIL GPU",
                    "tr_specText": "Intel® Arc™ Pro -grafik til bærbare computere kan understøtte op til fire ultrastore skærme for at forbedre din arbejdsgangs effektivitet. Tilføj også høj dynamikområde (HDR) understøttelse, og du har alt, hvad du behøver til det næste social-media-worthy hjem eller kontor med flerskærmsopsætning."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "FULD HUKOMMELSESBÅNDBREDDE POTENTIALE",
                    "tr_specText": "Båndbredden på dit grafikkort er en vigtig overvejelse på en arbejdsstation. Få den forkerte, og du kan bremse de professionelle arbejdsgange. Derfor har vi sørget for, at Intel® Arc™ Pro A-serien GPU understøtter moderne PCIe* 4,0 uden at straffe baglænskompatibilitet. Grafikhukommelse forbedres yderligere med en høj hastighed på båndbredde, så projektdata kan tilgås af din software endnu hurtigere."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "PUST LIV I HVER FRAME",
                    "tr_specText": "<span class='expandable_heading'>AI-boostet. Intel-støttet.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS er en AI-forbedret opskaleringsteknologi, der booster din ydeevne uden at ofre skønheden i frame — så du kan opleve dit gameplay som det er meningen, at det skal være."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "LIGE NU.<br/> VIRKELIG INDLEVENDE.<br/> REALISME PÅ SIT FINESTE.",
                    "tr_specText": "Mere realistiske verdener venter. Med indbygget ray tracing vil du opleve alt fra naturskønne skygger og refleksioner til mesterlig belysning. Med andre ord, det er en visuel gamingoplevelse, du ikke vil gå glip af."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "DIT BEDSTE GAMEPLAY, DELT PÅ SIT FINESTE",
                    "tr_specText": "Giv dit publikum det problemfrie gameplay, de fortjener at se. Med hardware-accelerated AV1-kodningskapacitet fra Intel® Arc™ -grafik vil de blive fascineret af hvert af dine træk. Højere visuel kvalitet ved samme bitrate svarer til streaming af dit bedste, mens du sparer båndbredde."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Velkommen til alle skabere",
                    "tr_specText": "Nye og forbedrede AI-oplevelser er tilgængelige lige ved hånden. Med avancerede AI-acceleratorer indlejret på tværs af Intel® Arc™ A-serien -grafik kan du bruge mere tid på at skabe og mindre tid på at vente på tværs af mange programmer. <br/><br/>Tag videooprettelse til det næste niveau og vis vejen videre med høj kvalitet, lagereffektiv AV1-video med avancerede AV1-hardwarekapaciteter indbygget i Intel® Arc™ -grafik."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Alt, hvad du skal bruge til enhver opgave",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Høj ydeevne, der er tynd, let og bygget til at holde - du kan få det hele. Med lang batterileverid, flotte berøringsskærme* og de nyeste SSD'er finder du det, du søger efter i en Windows 10-pc.",
                "tr_noteSection": "*Hardware afhængig",
                "titleArray": [
                  {
                    "tr_title": "Mindre er mere",
                    "tr_subtitle": "Nutidens pc'er bruger typisk mindre strøm, så du får mere levetid ud af dit batteri - i gennemsnit over otte timers videoafspilning.**",
                    "tr_noteSection": "**Batterilevetiden varierer betydeligt med indstillinger, brug og andre faktorer."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "ALT, HVAD DU SKAL BRUGE TIL ENHVER OPGAVE",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Høj ydeevne, der er tynd, let og bygget til at holde - du kan få det hele. Med lang batterileverid, flotte berøringsskærme* og de nyeste SSD'er finder du det, du søger efter i en Windows 10-pc.",
                "tr_noteSection": "*Hardware afhængig",
                "titleArray": [
                  {
                    "tr_title": "MINDRE ER MERE",
                    "tr_subtitle": "Nutidens pc'er bruger typisk mindre strøm, så du får mere levetid ud af dit batteri - i gennemsnit over otte timers videoafspilning.**",
                    "tr_noteSection": "**Batterilevetiden varierer betydeligt med indstillinger, brug og andre faktorer."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Strømlinet for sikkerhed og ydeevne",
                "tr_pretitle": "Windows* 10 i S tilstand",
                "tr_subtitle": "Når du bruger Windows* 10 i S mode, får du et ekstra lags beskyttelse, det lader dig arbejde, spille og udforske i vished om, at din enhed er sikker."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "STRØMLINET FOR SIKKERHED OG YDEEVNE",
                "tr_title": "Windows* 10 i S tilstand",
                "tr_subtitle": "Når du bruger Windows* 10 i S mode, får du et ekstra lags beskyttelse, det lader dig arbejde, spille og udforske i vished om, at din enhed er sikker."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Gør store ting",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Ydeevne, pris og masser at vælge imellem. Fra innovative 2-i-1 til tynde og lette bærbare, berøringsskærme, mobile tastaturer - uanset dit budget eller din stil kan du finde en Windows* PC til dit behov."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "GØR STORE TING",
                "tr_title": "Windows*",
                "tr_subtitle": "Ydeevne, pris og masser at vælge imellem. Fra innovative 2-i-1 til tynde og lette bærbare, berøringsskærme, mobile tastaturer - uanset dit budget eller din stil kan du finde en Windows* PC til dit behov."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operativsystem",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Hukommelse",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "HVOR MEGET RAM HAR DU BRUG FOR?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Hverdag - 2 GB OP TIL 8 GB RAM",
                    "tr_badgeSubTitle": "Ideelt til dem, der primært bruger deres pc til tekstbehandling og websurfing og oftest fokuserer på ét program ad gangen."
                  },
                  {
                    "tr_badgeTitle": "Kraftig - 8 GB OP TIL 16 GB RAM",
                    "tr_badgeSubTitle": "Med mere RAM kører dine programmer hurtigere, og du vil også kunne køre flere programmer samtidig."
                  },
                  {
                    "tr_badgeTitle": "Avanceret - MERE END 16 GB RAM",
                    "tr_badgeSubTitle": "Hvis du er interesseret i gaming, foto- og videoredigering eller 3D-modellering, skal du bruge mere RAM til at håndtere disse krævende programmer."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "HUKOMMELSE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM til dagligdagens multitasking",
                "tr_subtitle": "Din hukommelse gør det muligt for grundlæggende programmer såsom tekstbehandling og søgning på nettet at blive indlæst og køre problemfrit.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "HUKOMMELSE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM til kraftfuld multitasking",
                "tr_subtitle": "Hvis du bruger flere programmer samtidig, gør [memory_total] GB hukommelse det muligt at skifte frem og tilbage med lethed.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "HUKOMMELSE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM til avanceret multitasking",
                "tr_subtitle": "Du har masser af hukommelse til at køre flere krævende programmer samtidig, uden at din PC bliver langsommere.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "HUKOMMELSE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1] GB hukommelse = [Value2]&nbsp;GB Intel® Optane™ -hukommelse +&nbsp;[Value3]&nbsp;GB RAM",
                "tr_subtitle": "Denne kombination af hukommelsesløsninger giver accelereret harddiskydeevne fra Intel® Optane™ -hukommelsen plus højere reaktionsevne fra din RAM.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>HUKOMMELSE</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ -hukommelse",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Mindre ventetid. Mere af det, du holder af.",
                "tr_subtitle": "Når Intel® Optane™ -hukommelse arbejder sammen med dit systems hukommelse, giver det dig hurtigere adgang til dine ofte anvendte programmer, så de starter og indlæses i det øjeblik, du har brug for dem.",
                "tr_pretitle": "[XX] GB Intel® Optane™ -hukommelse",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "HVAD GØR HUKOMMELSEN FOR MIN PC?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Hurtigere oprettelse af indhold",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Mere reaktionsdygtig gaming",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Nemmere multitasking",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Åbn programmer hurtigere",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ -hukommelse",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Accelerer din spiloplevelse</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ -hukommelse",
                "tr_pretitle1": "[XX] GB Intel® Optane™ &#8209;hukommelse accelererer din gaming",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Start spil op til <br/><span class=\"optane-game\">98 % hurtigere</span> <br/>fra datadrevet med en [optane_legal_processor_name] -processor med Intel® Optane™ -hukommelse<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Indlæs niveauer op til <br/><span class=\"optane-game\">4.1X hurtigere</span><br/>fra datadrevet med en [optane_legal_processor_name] -processor med Intel® Optane™ -hukommelse<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Intel® Optane™ -hukommelse",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Accelerer din spiloplevelse</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ -hukommelse",
                "tr_pretitle1": "[XX] GB Intel® Optane™ &#8209;hukommelse accelererer din gaming",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Start spil op til <br/><span class=\"optane-game\">98 % hurtigere</span> <br/>fra datadrevet med en [optane_legal_processor_name] -processor med Intel® Optane™ -hukommelse<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Indlæs niveauer op til <br/><span class=\"optane-game\">4.1X hurtigere</span><br/>fra datadrevet med en [optane_legal_processor_name] -processor med Intel® Optane™ -hukommelse<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "HUKOMMELSE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1] GB hukommelse = [Value2]&nbsp;GB Intel® Optane™ -hukommelse +&nbsp;[Value3]&nbsp;GB RAM",
                "tr_subtitle": "Denne kombination af hukommelsesløsninger giver accelereret harddiskydeevne fra Intel® Optane™ -hukommelsen plus højere reaktionsevne fra din RAM.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB RAM til multitasking",
                    "tr_subtitle": "Hvis du bruger flere programmer samtidig, gør [memory_total] GB hukommelse det muligt at skifte frem og tilbage med lethed."
                  },
                  {
                    "tr_title": "Til hverdagsopgaver",
                    "tr_subtitle": "Din hukommelse gør det muligt for grundlæggende programmer såsom tekstbehandling og søgning på nettet at blive indlæst og køre problemfrit."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "HUKOMMELSE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB RAM",
                "tr_pretitle": "FOR MERE MULTITASKING",
                "tr_subtitle": "RAM giver processoren let adgang til programfiler, så din CPU hurtigere kan åbne dem og arbejde på flere opgaver samtidigt.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standard hukommelse",
                    "tr_badgeSubTitle": "Op til 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Avanceret hukommelse",
                    "tr_badgeSubTitle": "Mere end 8 GB og op til 16 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Elite hukommelse",
                    "tr_badgeSubTitle": "Større end 16 GB RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "HUKOMMELSE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB til mere multitasking",
                "tr_pretitle": "FOR MERE MULTITASKING",
                "tr_subtitle": "RAM giver processoren let adgang til programfiler, så din CPU hurtigere kan åbne dem og arbejde på flere opgaver samtidigt.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standard hukommelse",
                    "tr_badgeSubTitle": "Op til 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Avanceret hukommelse",
                    "tr_badgeSubTitle": "Mere end 8 GB og op til 16 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Elite hukommelse",
                    "tr_badgeSubTitle": "Større end 16 GB RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE HUKOMMELSE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Standard",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avanceret",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Elite",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Hukommelse",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "HVORDAN SAMMENLIGNER DENNE HUKOMMELSE?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "HVORDAN SAMMENLIGNER DENNE HUKOMMELSE?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuel enhed",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Daglig",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Kraftig",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avanceret",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Lager",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "HVOR MEGET HAR DIN PC PLADS TIL?",
                "tr_subtitle": "Der er ikke nødvendigt at gætte. Vi viser dig præcis, hvad du kan lagre på denne pc.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Opret et bibliotek med</span> <br/><span class=\"storage-standard\">[num_songs] sange<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup> gennemsnit baseret på 5 MB per sang",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Opret et bibliotek med</span><br/><span class=\"storage-standard\">[num_games] spil<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>baseret på et gennemsnit på 40 GB pr. spil.",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Hukommelse til</span><br/><span class=\"storage-standard\">[num_videos] videoer<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup> gennemsnit baseret på 5 GB per video",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Tag og gem</span><br/><span class=\"storage-standard\">[num_photos] fotos<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup> gennemsnit baseret på 4 MB per foto",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "[capacity] GB HDD"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "[capacity] GB SSD"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "[total_optane_memory] GB Intel® Optane™ -hukommelse + [convertedSSD] GB SSD"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGER",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "[capacity] GB SSD lager",
                "tr_preTitle1": "[capacity] TB SSD lager",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Denne pc har en SSD med [capacity] GB lagerplads. Det betyder, at du kan gemme et imponerende antal fotos, videoer og programmer og tilgå dem meget hurtigere end på en harddisk.",
                    "tr_title": "Plads til alting på din PC"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Denne pc har en SSD med [capacity] TB lagerplads. Det betyder, at du kan gemme et imponerende antal fotos, videoer og programmer og tilgå dem meget hurtigere end på en harddisk.",
                    "tr_title": "Denne enhed har imponerende lagerplads"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>LAGER</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>LAGER</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGER",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "[capacity] GB HDD lager",
                "tr_preTitle1": "[capacity] TB HDD lager",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Harddiske er et godt valg for dem, der ønsker masser af lagerplads direkte på deres pc.",
                    "tr_title": "Plads til alting på din PC"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Harddiske er et godt valg for dem, der ønsker masser af lagerplads direkte på deres pc.",
                    "tr_title": "Denne enhed har imponerende lagerplads"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>LAGRING</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGER",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "HVOR MEGET HAR DIN PC PLADS TIL?",
                "tr_subtitle": "Der er ikke nødvendigt at gætte. Vi viser dig præcis, hvad du kan lagre på denne pc.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB HDD"
                  },
                  {
                    "tr_title": "[XX] GB SSD"
                  },
                  {
                    "tr_title": "[total_capacity] GB Intel® SSD accelereret af [optane_memory_size] GB Intel® Optane™ &#8209;hukommelse"
                  },
                  {
                    "tr_title": "[total_capacity] TB Intel® SSD accelereret med [optane_memory_size] GB Intel® Optane™ -hukommelse"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD] GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD] GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD] TB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD] TB HDD"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>LAGER</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>LAGER</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>LAGER</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>LAGER</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>LAGER</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>LAGER</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGER",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "[convertedSSD] GB SSD + [convertedHDD] GB HDD",
                "tr_preTitle1": "[convertedSSD] TB SSD + [convertedHDD] GB HDD",
                "tr_preTitle2": "[convertedSSD] GB SSD + [convertedHDD] TB HDD",
                "tr_preTitle3": "[convertedSSD] TB SSD + [convertedHDD] TB HDD",
                "titleArray": [
                  {
                    "tr_title": "Plads til alting på din PC",
                    "tr_title1": "Denne enhed har imponerende lagerplads",
                    "tr_subtitle": "Denne pc har et solid-state-drev med [convertedSSD] GB lagerplads og en harddisk med [convertedHDD] GB lagerplads. Det giver dig både masser af kapacitet og hurtig lagring.",
                    "tr_subtitle1": "Denne pc har et solid-state-drev med [convertedSSD] TB lagerplads og en harddisk med [convertedHDD] GB lagerplads. Det giver dig både masser af kapacitet og hurtig lagring.",
                    "tr_subtitle2": "Denne pc har et solid-state-drev med [convertedSSD] GB lagerplads og en harddisk med [convertedHDD] TB lagerplads. Det giver dig både masser af kapacitet og hurtig lagring.",
                    "tr_subtitle3": "Denne pc har et solid-state-drev med [convertedSSD] TB lagerplads og en harddisk med [convertedHDD] TB lagerplads. Det giver dig både masser af kapacitet og hurtig lagring."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>LAGER</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>LAGER</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGER",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Få hurtigere adgang til højkapacitetslagring med Intel® Optane™ -hukommelse med SSD.",
                "tr_title": "Kæmpestore filer er velkomne",
                "tr_pretitle": "[total_capacity] GB Intel® SSD accelereret af [optane_memory_size] GB Intel® Optane™ &#8209;hukommelse",
                "tr_pretitle1": "[total_capacity] TB Intel® SSD accelereret med [optane_memory_size] GB Intel® Optane™ -hukommelse",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Denne revolutionerende lagringsløsning er den første til at kombinere Intel® Optane™ -hukommelse og et SSD på én chip. Her er, hvad det gør for dig:",
                    "tr_title": "HVAD ER INTEL® OPTANE™ -HUKOMMELSE MED SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Åbn det, du har brug for, hurtigt",
                    "tr_badgeSubTitle": "Ved at forudindlæse ofte anvendte filer og programmer kan du bruge mere tid på at skabe og mindre tid på at vente.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Lager plus hastighed",
                    "tr_badgeSubTitle": "Du får den plads, du skal bruge til mediefiler, med den hurtige ydeevne fra en SSD.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Mere reaktionsdygtig over tid",
                    "tr_badgeSubTitle": "Intel® Optane™ -hukommelse med SSD lærer, hvordan du arbejder, så din pc bliver mere effektiv.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LAGER",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Hav alle dine medier lige inden for rækkevidde med masser af pålidelig lagerplads.",
                "tr_title": "Plads til alle dine billeder, videoer og sange"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Skærm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] millioner pixels til at stryge, klemme og forbløffe",
                "tr_subtitle": "Interager med din PC ligesom en smartphone med intuitive kontroller og skarpere, levende detaljer.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]\"<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Opløsning: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels: [screenResolution] mio."
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] tommer skærm",
                    "tr_badgeSubTitle": "Skærmhøjde: [screenHeight] tommer",
                    "tr_badgePreTitle": "Skærmbredde: [YY] tommer"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Berøringsskærm",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Skærm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Fordyb dig i [screenResolution] millioner pixels",
                "tr_subtitle": "Det handler om pixels. Jo flere du har, desto skarpere og mere levende bliver dine billeder.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]\"<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Opløsning: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels: [screenResolution] mio."
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] tommer skærm",
                    "tr_badgeSubTitle": "Skærmhøjde: [screenHeight] tommer",
                    "tr_badgePreTitle": "Skærmbredde: [YY] tommer"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Skærm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] tommer <br/>[screenResolutionX] X [screenResolutionY]",
                "tr_pretitle": "VINDUET MOD DINE SPILUNIVERSER",
                "tr_subtitle": "Ligesom på et tv er størrelse og opløsning de allervigtigste faktorer for din spilleskærm.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Opløsning: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels: [screenResolution] mio."
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] tommer skærm",
                    "tr_badgeSubTitle": "Skærmhøjde: [screenHeight] tommer",
                    "tr_badgePreTitle": "Skærmbredde: [screenWidth] tommer"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Berøringsskærm"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Skærm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Denne enhed har en imponerende skærm",
                "tr_subtitle": "Med mere end otte gange flere HD-pixels vil dine videoer og spil nå et helt nyt niveau af skarphed og farve.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Opløsning: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels: [screenResolution] mio."
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] tommer skærm",
                    "tr_badgeSubTitle": "Skærmhøjde: [screenHeight] tommer",
                    "tr_badgePreTitle": "Skærmbredde: [YY] tommer"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Skærm",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] mio. pixels at trykke på, stryge over og røre ved",
                    "tr_subTitle": "Se og interager med de ting, du elsker, i skarpere og mere levende detaljer."
                  },
                  {
                    "tr_title": "[screenResolution] mio. pixels til at forbedre din digitale verden",
                    "tr_subTitle": "Det handler om pixels. Jo flere du har, desto skarpere og mere levende bliver dine billeder."
                  },
                  {
                    "tr_title": "Denne enhed har en imponerende skærm",
                    "tr_subTitle": "Med næsten fire gange så mange pixels i HD vil dine videoer og spil nå et helt nyt niveau, hvad angår skarphed og farver."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tilslutning",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Thunderbolt™ 3-teknologi leverer den hurtigste, mest fleksible tilslutning til dockingstation, skærm eller dataenhed.",
                "tr_title": "En port, der gør det hele",
                "tr_title1": "EN PORT, DER GØR DET HELE",
                "titleArray": [
                  {
                    "tr_title": "HVORDAN KAN JEG ANVENDE THUNDERBOLT™ 3-TEKNOLOGI?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 3-teknologi"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "4K Skærme",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tilslut til skærme med uovertruffen opløsning, kontrast og farve"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Eksternt grafikkort",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Giv straks en hvilken som helst pc en seriøs grafikopgradering"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Hurtigt netværk",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Giver hurtige peer-to-peer-tilslutninger"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tilslutning",
                "detailBGUrl": "",
                "tr_subtitle": "Thunderbolt™ 3 teknologien overfører data med højere hastighed end konventionelle USB'er, så du hurtigt kan flytte filer og nemt integrere enheder såsom flere skærme eller eksterne grafikkort.",
                "tr_title": "Thunderbolt™ 3-teknologi",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "LYNHURTIG DATA",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Thunderbolt™ 3-teknologi",
                    "tr_badgeSubTitle": "40 Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tilslutning",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>En universalport- og kabel understøtter alt dit foretrukne tilbehør</li> <li>Pålidelig opladning af notebook</li> <li>Robust certificering giver ro i sindet</li></ul>",
                "tr_title": "En i sandhed universal kabelforbindelse",
                "tr_title1": "EN I SANDHED UNIVERSAL KABELFORBINDELSE",
                "tr_noteSection": "<sup>†</sup> baseret på minimums specifikations krav",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "HVORDAN KAN JEG ANVENDE THUNDERBOLT™ 4-TEKNOLOGI?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 4-teknologi"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Skab",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Forbind en ekstern skærm og hurtig lagring for at se og redigere fotos og videoer."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "DOCK",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Dit skrivebord bliver overskueligt og enkelt, ved at forbinde din bærbare computer til alt dit tilbehør med ét enkelt kabel."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Spil",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tilføj mere SSD og HDD-kapacitet med hastigheder på op til 3.000 MB/s til lagring af spilbiblioteker og mediefiler."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Makimal ydeevne<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x hurtigere end USB* 3.2 <br/>2x hurtigere end HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tilslutning",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Nyd samme fantastiske ydeevne fra Thunderbolt™ 3-teknologi med endnu mere kapacitetet, der giver dig mulighed for nemt at oprette og bruge de seneste Thunderbolt™ -docks, skærme, hurtig lagring eller ethvert USB*-tilbehør.",
                "tr_title": "Thunderbolt™ 4-teknologi",
                "tr_pretitle": "EN I SANDHED UNIVERSAL KABELFORBINDELSE",
                "tr_noteSection": "<sup>†</sup> baseret på minimums specifikations krav",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Thunderbolt™ 4-universalkabel kan erstatte alle dine USB-C*-kabler, så kabelvalg bliver nemt og hurtigt."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Thunderbolt™ 4-teknologi docks tilbyder op til fire Thunderbolt™ porte for mere fleksibilitet til at forbinde alt dit tilbehør"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Makimal ydeevne<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x hurtigere end USB* 3.2 <br/>2x hurtigere end HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tilslutning",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>En universalport- og kabel understøtter alt dit foretrukne tilbehør</li> <li>Pålidelig opladning af notebook</li> <li>Robust certificering giver ro i sindet</li></ul>",
                "tr_title": "Maksimer din oplevelse med Thunderbolt™ -tilbehør",
                "tr_noteSection": "<sup>†</sup> baseret på minimums specifikations krav",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "HVORDAN KAN JEG ANVENDE THUNDERBOLT™ 4-TEKNOLOGI?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Skab",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Forbind en ekstern skærm og hurtig lagring for at se og redigere fotos og videoer."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "DOCK",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Dit skrivebord bliver overskueligt og enkelt, ved at forbinde din bærbare computer til alt dit tilbehør med ét enkelt kabel."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Spil",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tilføj mere SSD og HDD-kapacitet med hastigheder på op til 3.000 MB/s til lagring af spilbiblioteker og mediefiler."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Makimal ydeevne<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x hurtigere end USB* 3.2 <br/>2x hurtigere end HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "Den mest almindelige typer Wi‑Fi er:",
                "tr_title": "FORSTÅ WI‑FI-STANDARDERNE",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 giver mulighed for større hastigheder, selv når mange brugere bruger det samme signal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Med Intels nyeste wi-fi-innovation kan du opnå hastigheder hurtigere end gigabit-wi-fi, selv med flere brugere på samme signal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Den største Wi‑Fi fremgang i 20 år, Wi‑Fi 6E åbner nye høj hastigheds kanaler for forbedret ydeevne, pålidelighed og mindre interferens."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 giver mulighed for større hastigheder, selv når mange brugere bruger det samme signal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Alle fordelene ved Wi-Fi 6E kombineret med Intel® Killer™ Prioritization Engine, der skifter båndbredde til de hjemmesider og applikationer, der har mest brug for den."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Med mindre konkurrence på dine højhastigheds kanaler vil du nyde godt af forbedret ydeevne og pålidelighed."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 giver mulighed for større hastigheder, selv når mange brugere bruger det samme signal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Med mindre interferens fra Wi-Fi 6 og den Intelligent ydeevne af Intel® Killer™ Prioritization Engine, vil din onlineoplevelse være hurtigere og mere responsiv."
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Med mindre konkurrence på dine højhastigheds kanaler vil du nyde godt af forbedret ydeevne og pålidelighed."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Højere end 5 Gbps PC Wi-Fi-hastigheder med konsekvent ultralav latenstid – del filer på sekunder vs. minutter."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) er den seneste teknologi, som tillader \"ekstrem\" pålidelighed, lav latens og hastigheder gennem AI-baseret netværksoptimering."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 giver mulighed for større hastigheder, selv når mange brugere bruger det samme signal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) hastigheder højere end 1 Gbps, pålidelighed og lav latens med brugen af 6 GHz kanalen."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Bedre ydeevne, kapacitet og hastighed vs. Wi-Fi 5 på belastede netværk."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Bevar bedre forbindelse med Wi‑Fi [XX], og nyd internettet med højere hastigheder<sup>†</sup> og større pålidelighed.",
                "tr_title": "Få forbedret rækkevidde og hastighed",
                "tr_noteSection": "<sup>†</sup>Den faktiske trådløse overførselshastighed og/eller den faktiske rækkevidde varierer.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Når det gælder multiplayer og onlinespil, er en stabil forbindelse vigtigere end nogensinde. Foruden hastighed skal du have et trådløst signal, der kan fastholde disse hastigheder på afstand.",
                "tr_title": "[XX]",
                "tr_pretitle": "FORBINDELSEN ER AFGØRENDE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Opret forbindelse med Intel® Wi‑Fi 6 (Gig+) til Wi‑Fi 6 routere med 160 MHz-kanaler og aktiver hurtigere forbindelser og større pålidelighed til flere aktive brugere.",
                "tr_title": "Næsten 3x højere hastigheder med Intel® <span class='no-warp'>Wi-Fi 6 </span> (Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>End standard AC Wi‑Fi Vælg funktioner kun tilgængelige med visse SKU'er. Tjek med producenten for detaljer. For mere Wi‑Fi-information besøg intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Indgangsniveau dualband Intel® Wi-Fi 6 understøtter forbedret ydeevne til basale forbindelsesbehov, som e-mail og internetsurfing på overbelastede netværk.<sup>9</sup>",
                "tr_title": "Essential Wi‑Fi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Når det gælder multiplayer og onlinespil, er en stabil forbindelse vigtigere end nogensinde. Foruden hastighed skal du have et trådløst signal, der kan fastholde disse hastigheder på afstand.",
                "tr_title": "[XX]",
                "tr_pretitle": "FORBINDELSEN ER AFGØRENDE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "At have disse nye Wi‑Fi kanaler betyder du ikke behøver at dele båndbredde med de ældre enheder i dit nabolag, hvilket giver dig jævn streaming, hurtig browsing, problemfri fjernarbejde og læring.",
                "tr_title": "Forbind uden konkurrence",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Hold en bedre forbindelse med [XX] og nyd internettet med højere hastigheder<sup>†</sup> og større pålidelighed.",
                "tr_title": "Få forbedret rækkevidde og hastighed",
                "tr_noteSection": "<sup>†</sup>Den faktiske trådløse overførselshastighed og/eller den faktiske rækkevidde varierer.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Når det gælder multiplayer og onlinespil, er en stabil forbindelse vigtigere end nogensinde. Foruden hastighed skal du have et trådløst signal, der kan fastholde disse hastigheder på afstand.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "FORBINDELSEN ER AFGØRENDE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Indbygget ultrahurtig Wi‑Fi",
                "tr_subtitle": "Tag stikket ud, og hold forbindelsen til din online-verden."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Med dedikerede højhastighedslinjer for mindre interferens og Intel® Killer™ Prioritization Engine, som sætter gaming først, har online gaming fået en alvorlig opgradering.",
                "tr_title": "Giv konkurrencen baghjul",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Med dedikerede højhastighedslinjer for mindre interferens og Intel® Killer™ Prioritization Engine, som sætter gaming først, har online gaming fået en alvorlig opgradering.",
                "tr_title": "[XX]",
                "tr_pretitle": "GIV KONKURRENCEN BAGHJUL",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Med dedikerede højhastighedsbaner for mindre interferens, der sætter gaming i højsædet, har online gaming lige fået en seriøs opgradering.",
                "tr_title": "[XX]",
                "tr_pretitle": "GIV KONKURRENCEN BAGHJUL",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi-Fi accelererer automatisk netværkstrafik til de applikationer, der har mest brug for det, så dine streamingvideoer har skarpe detaljer og buffer mindre.",
                "tr_title": "En hurtigere, smartere måde at oprette forbindelse på.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Når det gælder multiplayer og onlinespil, er en stabil forbindelse vigtigere end nogensinde. Foruden hastighed skal du have et trådløst signal, der kan fastholde disse hastigheder på afstand.",
                "tr_title": "[XX]",
                "tr_pretitle": "FORBINDELSEN ER AFGØRENDE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Bevar bedre forbindelse med Wi-Fi 5, og nyd internettet med hurtigere hastigheder<sup>†</sup> og større pålidelighed.",
                "tr_title": "Få forbedret rækkevidde og hastighed",
                "tr_noteSection": "<sup>†</sup>Den faktiske trådløse overførselshastighed og/eller den faktiske rækkevidde varierer.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Når det gælder multiplayer og onlinespil, er en stabil forbindelse vigtigere end nogensinde. Foruden hastighed skal du have et trådløst signal, der kan fastholde disse hastigheder på afstand.",
                "tr_title": "[XX]",
                "tr_pretitle": "GIV KONKURRENCEN BAGHJUL",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Wi-Fi 7-funktioner bygger videre på tidligere Wi-Fi-generationer og løfter niveauet. Dette betyder ikke bare hurtige hastigheder, men markant forbedret reaktionsevne og pålidelighed for inddragende forbrugeroplevelser og sofistikerede fremtidsteknologier.",
                "tr_title": "Kablet-lignende netværk",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Wi-Fi 7-funktioner bygger videre på tidligere Wi-Fi-generationer og løfter niveauet. Dette betyder ikke bare hurtige hastigheder, men markant forbedret reaktionsevne og pålidelighed for inddragende forbrugeroplevelser og sofistikerede fremtidsteknologier.",
                "tr_title": "[XX]",
                "tr_pretitle": "TRANSFORMERER BRUGEROPLEVELSER",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Forbedret hastighed, latens og pålidelighed gennem AI-baseret netværksoptimering",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Forbedret hastighed, latens og pålidelighed gennem AI-baseret netværksoptimering.",
                "tr_title": "[XX]",
                "tr_pretitle": "KABLET-LIGNENDE REAKTIONSEVNE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Hvordan måles det?",
                    "tr_badgeSubTitle": "De fleste producenter angiver deres batterilevetid i timer. Selvom denne beregning ikke er 100 % nøjagtig, kan den hjælpe dig med at sammenligne batterilevetiden for én pc med en anden."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "TIL SPIL PÅ FARTEN",
                "tr_subtitle": "Med ydeevnen i nutidens gamingbærbare er der ingen grund til at være bundet til et skrivebord. Nu kan du spille alle steder.",
                "tr_title": "[Dynamic.battery.values.hours] timers batterilevetid"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Tag stikket ud, og bevar forbindelsen til dine apps, spil og musik i timevis.",
                "tr_title": "[XX] timers streaming med en enkelt opladning<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup> Baseret på PC fabrikantens angivne batterilevetid.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] timer",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Tag stikket ud, og bevar forbindelsen til dine apps, spil og musik i timevis.",
                "tr_title": "[Dynamic.battery.values.hours] timers strøm på farten<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup> Baseret på PC fabrikantens angivne batterilevetid.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] timer",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Tag stikket ud, og bevar forbindelsen til dine apps, spil og musik i timevis.",
                "tr_title": "[Dynamic.battery.values.hours] timer til hele dagen på en enkelt opladning<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup> Baseret på PC fabrikantens angivne batterilevetid.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] timer",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batteri",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Denne PC optimerer din batterilevetid til at vare længere og giver dig mindst 4 timers batterilevetid på en 30 minutters opladning.<sup>4</sup>",
                "tr_title": "Arbejd længere med [Dynamic.battery.values.hours] timers batterilevetid<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup> Baseret på PC fabrikantens angivne batterilevetid.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] timer",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Hjem",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Hvorfor denne pc?",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Se alle specifikationer",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Fjernbetjening",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Mobil",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Juridisk",
        "tr_paragraph_array": [
          "Software og de arbejdsbelastninger, der bruges i tests af ydeevne, kan være blevet forberedt til kun at præstere optimal ydeevne på Intel® -mikroprocessorer. Test af ydeevne som f.eks. SYSmark* og MobileMark* måles ved hjælp af specifikke computersystemer, komponenter og funktioner. Ændringer af en hvilken som helst af disse faktorer kan medføre, at resultaterne varierer. Du bør konsultere andre oplysninger og tests af ydeevne for fuldt ud at kunne vurdere de køb, du overvejer at foretage, herunder det pågældende produkts ydeevne, når det kombineres med andre produkter. Du kan få mere udførlige oplysninger om ydeevne og testresultater på www.intel.com/benchmarks.",
          "¹Intel® Wi-Fi 6 (Gig+)-produkter understøtter valgfri 160 MHz-kanaler, hvilket muliggør de højst mulige teoretiske maksimale hastigheder (2402 Mbps) for typiske 2x2 802.11ax PC Wi-Fi-produkter. Premium Intel® Wi-Fi 6 (Gig+)-produkter muliggør 2-4 gange højere maksimale teoretiske hastigheder sammenlignet med standard 2x2 (1201 Mbps) eller 1x1 (600 Mbps) 802.11ax PC Wi-Fi-produkter, som kun understøtter de obligatoriske krav til 80 MHz-kanaler.",
          "²Anbefaling er kun til orientering. Konsulter andre informationskilder, når du skal træffe din beslutning om køb.",
          "³Funktionerne og fordelene ved Intels teknologier afhænger af systemkonfigurationen og kan kræve aktiveret hardware, software eller aktivering af tjenester. Ydeevnen afhænger af systemkonfiguration. Ingen computer kan være hundrede procent sikker. Kontakt systemproducenten eller forhandleren, eller se mere på intel.com.",
          "⁴Til systemer med FHD-skærme, når de bruges til trådløs webbrowsing. Når slukket, fra OEM-standardnedlukningsniveau.",
          "⁵Sammenlignet med andre PC-I/O-forbindelsesteknologier, herunder eSATA, USB og IEEE 1394* FireWire. Ydeevnen afhænger af den specifikke hardware og software, som du anvender. Skal bruge en Thunderbolt™ -teknologi-aktiveret enhed.",
          "⁶Næsten 3x hurtigere: 802.11ax 2x2 160 MHz giver mulighed for 2402 Mbps maksimum teoretiske datarater. ~3x hurtigere end standard 802.11ac 2x2 80 MHz (867 Mbps) wi‑fi som dokumenteret i IEEE 802.11* trådløse standardspecifikationer, og brug af tilsvarende konfigurerede 802.11ax trådløse netværks routere.",
          "⁷75% Lattens Reduktion: Er baseret på Intel simulation data (79%) af 802.11ax med og uden OFDMA ved hjælp af 9 klienter. Gennemsnitlig latens uden OFDM er 36ms, med OFDMA gennemsnitlige latens er reduceret til 7,6ms. Forbedringer af Latens kræver, at 802.11ax (Wi-Fi 6) routeren og alle klienter understøtter OFDMA.",
          "⁸ Baseret på den teoretiske maksimale datarate sammenligning i 802.11 specifikation, 2x2 160 MHz Wi‑Fi 6/6E (802.11ax) kan levere 2402 Mbps vs. 2x2 80 MHz Wi‑Fi 5 (802.11ac) kan levere 867 Mbps, som er lig med 2.8x højere hastigheder.",
          "⁹Førsteklasses Wi‑Fi 6: Intel® Wi‑Fi 6 (Gig+)-produkter understøtter valgfri 160 MHz-kanaler, hvilket muliggør de højest mulige teoretiske maksimale hastigheder (2402 Mbps) for typiske 2x2 802.11ax PC Wi‑Fi-produkter. Premium Intel® Wi‑Fi 6 (Gig+)-produkter tillader 2-4x hurtigere maksimal teoretiske hastigheder sammenlignet med standard 2x2 (1201 Mbps) eller 1x1 (600 Mbps) 802.11ax PC Wi‑Fi-produkter, der kun understøtter de obligatoriske krav på 80 MHz-kanalerne.",
          "Vælg kun SKU'er, funktionalitet varierer efter OEM design. Tjek med OEM eller forhandler for detaljer. For mere Wi‑Fi-information besøg intel.com/wifi6disclaimers",
          "¹⁰Thunderbolt™ 4-teknologi ved 40 Gbps, leverer den hurtigste, enkleste og mest pålidelige kabelløsning til enhver dock, skærm eller dataenhed sammenlignet med andre PC I/O-forbindelsesteknologier, herunder eSATA*, USB* og IEEE 1394* FireWire.",
          "¹¹Kræver en router baseret på 802.11ax, der understøtter OFDMA og flere klienter på netværket med understøttelse af AX. Bedre i tætte miljøer kan opnås med OFDMA-funktionen, der understøttes af 802.11ax-klienter og AP'er. 2 Gbps baseret på antagelser om ca. 70 % af IEEE 802.11* specifikation maksimum teoretiske data rater på 802.11ax 160 MHz 2402 Mbps.",
          "¹²Som målt af dokumentstart med baggrundsaktivitetsbelastning ved sammenligning af 8. generation Intel® Core™ i7-8565U-processor (512 GB TLC SSD ) vs. 8. generation Intel® Core™ i7-8565U-processor (32 GB + 512 GB Intel® Optane™ &#8209;hukommelse H10 med Solid State lager).",
          "¹³Intel® Thread Director er designet til 12. generation Intel® Core™ -processorer og hjælper med at understøtte operativsystemer til mere intelligent at kanalisere arbejdsbelastninger til den rigtige kerne. Ingen brugerhandling er påkrævet. Se intel.com for detaljer.",
          "¹⁴Ikke tilgængelig på visse 12. generation Intel® Core™ -processorer. Højtydende hybridarkitektur kombinerer 2 nye core mikroarkitekturer, Performance-cores (P-cores) og Efficient-cores (E-cores) på en enkelt processormatrice. Nogle 12. generation Intel® Core™ -processorer (visse 12. generation Intel® Core™ i5 -processorer og lavere) understøtter ikke højtydende hybridarkitektur, kun P-cores.",
          "¹⁵Måling af arbejdsbelastning ved start af spil sammenlignet med 8. generations Intel® Core™ i7-8750H (32 GB Intel® Optane™ -hukommelsesmodul + Intel® SSD 256GB PCIe* + 1 TB HDD) vs. 8. generations Intel® Core™ i7-8750H -processor (Intel® SSD 256 GB PCIe + 1TB HDD). Arbejdsbelastning ved start af spil – arbejdsbelastning udviklet af Intel under måling af forløbet tid under start af Total War*: WARHAMMER II* version: 5577.0 indtil visning af hovedmenuen med introduktionsvideoer deaktiveret (opstart). Intel® Optane™ -hukommelseskonfigurationer af datadrevacceleration: Intel® Core™ i7-8750H -processor, PL1=45W TDP, 6C12T, turbo op til 4 GHz på præ-produceret OEM-system, grafikkort: NVIDIA* GeForce* GTX 1070, hukommelse: 2x4 GB DDR4, lager: Intel® SSD-serien 760p 256 GB (PCIe*) + 1 TB HDD + 32 GB Intel® Optane™ -hukommelse, OS: Windows* 10 RS3 version 1709, MCU 0x84; Intel® Core™ i7-8750H -processor, PL1=45W TDP, 6C12T, turbo op til 4 GHz på præ-produceret OEM-system, grafikkort: NVIDIA* GeForce* GTX 1070, hukommelse: 2x4 GB DDR4, lager: Intel® SSD-serien 760p 256 GB (PCIe*) + 1 TB HDD, OS: Windows* 10 RS3 version 1709, MCU 0x84.",
          "¹⁶Måling af arbejdsbelastning ved indlæsning af spilniveau sammenlignet med 8. generations Intel® Core™ i7-8750H -processor (32GB Intel® Optane™ -hukommelsesmodul) vs. 8. generations Intel® Core™ i7-8750H -processor (kun HDD). Arbejdsbelastning ved indlæsning af spilniveau – arbejdsbelastning er udviklet af Intel under måling af den forløbne tid fra hovedmenuen til færdig indlæsning af spilniveau (belastning) på Total War*: WARHAMMER II* version: 5577.0. Intel® Optane™ -hukommelseskonfigurationer af datadrevacceleration: Intel® Core™ i7-8750H -processor, PL1=45W TDP, 6C12T, turbo op til 4 GHz på præ-produceret OEM-system, grafikkort: NVIDIA* GeForce* GTX 1070, hukommelse: 2x4 GB DDR4, lager: Intel® SSD-serien 760p 256 GB (PCIe*) + 1 TB HDD + 32 GB Intel® Optane™ -hukommelse, OS: Windows* 10 RS3 version 1709, MCU 0x84; Intel® Core™ i7-8750H -processor, PL1=45W TDP, 6C12T, turbo op til 4 GHz på præ-produceret OEM-system, grafikkort: NVIDIA* GeForce* GTX 1070, hukommelse: 2x4 GB DDR4, lager: Intel® SSD-serien 760p 256 GB (PCIe*) + 1 TB HDD, OS: Windows* 10 RS3 version 1709, MCU 0x84.",
          "¹⁷Om Performance-cores. Ydeevne varierer efter brug, konfiguration og andre faktorer. Få mere at vide på www.intel.com/PerformanceIndex.",
          "¹⁸Ændring af clockfrekvens eller spænding kan skade eller reducere processor og andre systemkomponenter og kan reducere systemstabilitet og ydeevne. Produktgarantien gælder ikke, hvis processoren bruges ud over dens specifikationer. Forhør dig hos producenterne af systemet og komponenterne for yderligere oplysninger.",
          "¹⁹Som målt på Intel vPro® -platforms uovertrufne kombination af sikkerhedsfunktioner over og under operativsystemet, app- og databeskyttelse og avanceret trusselsbeskyttelse for virksomheder af enhver størrelse samt Intels sikkerhedstankegang til produktdesign, fremstilling og support. Alle erhvervs-PC'er, der er bygget på Intel vPro® -platformen, er blevet valideret i forhold til strenge specifikationer, herunder unikke hardwarebaserede sikkerhedsfunktioner. Se www.intel.com/PerformanceIndex (platforme) for nærmere oplysninger. Intet produkt og ingen komponent kan være helt sikker.",
          "²⁰Intel® Connectivity Performance Suite er kun tilgængelig på Windows*-baserede systemer. Intel OTA-test (Over The Air) af Wi-Fi på et overbelastet netværk i forhold til et netværk uden ICPS-softwareapplikation med trådløs trafikprioritering og smart skift af adgangspunkt. Se www.intel.com/PerformanceIndex (tilslutningsmuligheder) for at få flere oplysninger. Resultater kan variere.",
          "²¹I forhold til den foregående processorgeneration.",
          "²²Intel® Arc™ -grafik er den første på markedet med understøttelse af AV1-kodning (fra 1. kvartal 2022). Nærmere oplysninger findes på intel.com/performanceindex.",
          "²³Som målt på de unikke funktioner i Intel® Evo™ -designs, som også er verificeret til at opfylde vigtige tærskelværdier for mobil brugeroplevelse. Testning fra februar 2022.",
          "²⁴Intel® Unison™ -løsningen er i øjeblikket kun tilgængelig på kvalificerede Intel® Evo™ -designs på Windows*-baserede PC'er og kan kun bruges sammen med Android- eller iOS-baserede telefoner. Alle enheder skal køre en understøttet OS-version.",
          "²⁵Maximale hukommelseshastigheder er forbundet med konfigurationer med 1 DIMM pr. kanal (1DPC). Yderligere DIMM-belastning på en kanal kan påvirke den maksimale hukommelseshastighed. Op til DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 og DDR5-5200 1Rx8, 1Rx16, 2Rx8 på udvalgte SKU'er. Den maksimale hukommelseskapacitet kan opnås med 2DPC-konfigurationer.",
          "²⁶Versus standard Wi-Fi. Se intel.com/performance-wireless for at få flere oplysninger. Resultater kan variere.",
          "²⁷Højtydende hybridarkitektur kombinerer mikroarkitektur med to cores, Performance-cores (P-cores) og Efficient-cores (E-cores) på en enkelt processorskive, der første gang introduceres på 12. generations Intel® Core™ -processorer. Udvalgte 12. generations og nyere Intel® Core™ -processorer har ikke højtydende hybridarkitektur, kun P-cores, og har samme cachestørrelse som tidligere generationer – se mere på ark.intel.com for SKU-oplysninger.",
          "²⁸Intel® Thread Director er indbygget i hardwaren og fås kun i højtydende hybridarkitekturkonfigurationer af 12. generations eller nyere Intel® Core™ -processorer, aktivering af operativsystem er nødvendig. Tilgængelige funktioner og funktionalitet varierer afhængig af operativsystem.",
          "²⁹Intel® Thread Director er indbygget i hardwaren og fås kun i højtydende hybridarkitekturkonfigurationer af 12. og 13. generations Intel® Core™ -processorer. aktivering af operativsystem er nødvendig. Tilgængelige funktioner og funktionalitet varierer afhængig af operativsystem.",
          "³⁰Som målt af referencevalideringplatform for ydeevne af Intel®&nbsp;Core™ Ultra &#8209;processor til bærbare computere (Serien 2 — Kodenavn: Lunar Lake) vs. Intel®&nbsp;Core™ Ultra &#8209;processor til bærbare computere (Serien 1 — Kodenavn: Meteor Lake) på 3DMark Time Spy. Se intel.com/performanceindex for detaljer. Resultater kan variere.",
          "³¹Baggrundssløring og ansigtssporing findes kun på Windows*-baserede systemer.",
          "³²Wi-Fi 7 -produkter kan tilgå 320 MHz kanaler i 6 GHz og nye 160 MHz kanalkombintioner i 5 GHz med nye Multi-Resource Unit Puncturing-egenskaber.",
          "³³Baseseret på IEEE-trådløse standard specifikationer og maksimum teoretiske datasatser for 2-stream enheder.",
          "³⁴Intel-simulerede belastede netværksmiljøer indikerer, at stor latensreduktion er mulig med nye Wi-Fi 7 Multi-Link Operation-egenskaber.",
          "³⁵Minimumsoplading opnået fra OEM-standardnedlukningsniveau. Individuelle systemresultater kan variere. Se intel.com/performance-evo for oplysninger.",
          "³⁶Wi-Fi-båndet på 6 GHz er ikke tilgængelig til brug i alle lande/regioner.",
          "³⁷Kun Windows*",
          "³⁸Opladning opnået fra OEM-standardnedlukningsniveau. Du kan få mere udførlige oplysninger om ydeevne og testresultater på intel.com/Evo.",
          "³⁹Refererer til Intel®&nbsp;Core™ Ultra mobile processorer (Serien&nbsp;2) &#8209;processorer til bærbare computere, baseret på de unikke hardware-baserede sikkerhedsfunktioner, uovertruffen kombination af over og under OS-sikkerhedsegenskaber, imponerende ydeevne selv ved lavere strømforbrug sammenlignet med tidligere generation (fra september 2024). Se intel.com/performanceindex for detaljer. Resultater kan variere.",
          "⁴⁰Softwareprogrammer kun afbildet til illustrative formål. AI-funktioner kan kræve køb af yderligere software, abonnement eller aktivering af software- eller platformsudbyder eller kan have specifikke konfigurations- eller kompatibilitetskrav. Detaljer på www.intel.com/PerformanceIndex. Resultater kan variere. © Intel Corporation.",
          "⁴¹Viste billeder kan være blevet ændret eller simuleret. AI-funktioner kan kræve køb af yderligere software, abonnement eller aktivering af software- eller platformsudbyder eller kan have specifikke konfigurations- eller kompatibilitetskrav. Detaljer på www.intel.com/AIPC.",
          "⁴²Gradvis udrulning i forhåndsvisning i den seneste opdatering til Windows* 11 på udvalgte globale markeder. Tidspunktet for tilgængelighed varierer efter enhed og marked.  Læs mere her: aka.ms/WindowsAIFeatures.  © Intel Corporation.",
          "⁴³Intel® Unison™ l-øsning er i øjeblikket tilgængelig for berettigede designs.  Se www.intel.com/performanceindex for detaljer.",
          "⁴⁴Billeder i sekundet opskaleret af Intel® X<sup>e</sup>SS vs. indfødte billeder i sekundet målt af Dying Light 2 Stay Human kørt på Intel® Core™ Ultra 7 -processor 165H.  Se www.intel.com/performanceindex for detaljer. Resultater kan variere.",
          "⁴⁵Som målt ved lavere SoC-strøm fra Intel®&nbsp;Core™ Ultra &#8209;processor til bærbare computere (Serien 2 — Kodenavn: Lunar Lake) referencevalideringsplatform vs. Intel®&nbsp;Core™ Ultra &#8209;processor til bærbare computere (Serien 1 — Kodenavn: Meteor Lake) referencevalideringsplatform på YouTube* 4K 30FPS AV1. Se intel.com/performanceindex for detaljer. Resultater kan variere.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Intel påtager sig intet ansvar for ukorrekte prisoplysninger.",
          "*Andre navne og varemærker kan være ejet af andre.",
          "OPHAVSRET 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES OG RELATEREDE DESIGN- OG SAMMENSATTE VAREMÆRKER ER VAREMÆRKER TILHØRENDE INFLEXION STUDIOS INC. ALLE RETTIGHEDER FORBEHOLDES.",
          "Intel -teknologier kræver muligvis aktiveret hardware, software eller tjenesteaktivering.",
          "Alle resultater og data fra NERO* er leveret som en bekvemmelighed og kun til informative formål. Intel Corporation har intet ansvar for, som eksempel, nøjagtigheden af NERO* programmet eller performanceresultater- og scores eller potentielle variationer fra andre versioner af det program, der kan downloades på www.nero.com eller andre tredjeparts websted. NERO* PC benchmark scores måles ved at bruge specifikke computersystemer, komponenter, software, handlinger og funktioner. Ændringer af en hvilken som helst af disse faktorer kan medføre, at resultaterne varierer. Du bør konsultere andre oplysninger og tests af ydeevne for fuldt ud at kunne vurdere de køb, du overvejer at foretage, herunder det pågældende produkts ydeevne, når det kombineres med andre produkter.",
          "Alle versioner af Intel vPro® -platformen kræver en Intel® Core™ -processor, et understøttet operativsystem, Intel® LAN- og/eller WLAN-silicium, firmwareforbedringer og anden hardware og software, der er nødvendig for at levere de brugssituationer med hensyn til håndterbarhed, sikkerhedsfunktioner, systemydelse og stabilitet, der definerer platformen.",
          "Ydeevne varierer efter brug, konfiguration og andre faktorer. Få mere at vide på www.intel.com/PerformanceIndex.",
          "Intel kontrollerer ikke eller reviderer tredjeparts data. Du bør konsultere andre kilder for at evaluere nøjagtighed.",
          "© Intel Corporation. Intel, Intel-logoet og andre Intel-mærker er varemærker, der tilhører Intel Corporation eller et af vore datterselskaber. *Andre navne og varemærker kan være ejet af andre.",
          "Intet produkt og ingen komponent kan være helt sikker.",
          "Dine omkostninger og resultater kan variere.",
          "Intel, Intel logoet, Intel Core, Intel Optane, Intel Iris, Thunderbolt og Thunderbolt logoet er varemærker i Intel Corporation eller dens datterselskaber. Andre navne og varemærker kan være ejet af andre.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Hjem",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Scan for at sammenligne",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Populært",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "Lås op for nye AI-oplevelser med Intel® Core™ Ultra -processorer",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "Lås op for nye AI-oplevelser med Intel® Core™ Ultra -processorer",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "Lås op for nye AI-oplevelser med Intel® Core™ Ultra -processorer",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "Stable Diffusion*: Tekst til billede",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe*: Fotoredigering",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "Intel® X<sup>e</sup>SS: Billedopskalering",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee*: Deepfake Detector",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "Samarbejdseffekter (Teams)",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "IQIYI: Bevægelseskontrol",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "Synkroniser din PC med din telefon og se notifikationer, opkald og SMS'er",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "Rig og medrivende grafik, der er indbygget",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "Microsoft*: Kør dit liv, din måde",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "Microsoft*: Alle ting du har. Alt med et blik altid et swipe væk",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "Kør din processor hurtigere og med højere frekvenser ved at finjustere dit system",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "Boost dit stationære eller mobile systems ydeevne uden at ofre lagerplads",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "Boost dit stationære eller mobile systems ydeevne uden at ofre lagerplads",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "Samsung OLED: Et vidunder til trætte øjne",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "Forbedret hastighed, latens og pålidelighed gennem AI-baseret netværksoptimering",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "Kablet-lignende netværk",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "Kablet-lignende netværk",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "Næsten 3x hurtigere med Intel® Wi-Fi 6 (Gig+)",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "Forbind uden konkurrence",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "En i sandhed universal kabelforbindelse",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "Spil som de professionelle: Se, hvordan Intel giver styrke til gaming og gamere",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "Spil som de professionelle: Vindere bliver skabt med Intel",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "Velkommen",
    "tr_welcomePagetrSubtitle": "Kontroller denne PC med din smartphone",
    "tr_welcomePagembSubtitle": "Velkommen, udforsk denne PC's specifikationer på din smartphone",
    "tr_welcomePageBtnText": "Kom i gang",
    "tr_welcomePageText": "Udforsk og sammenlign detaljerne i denne PC på din smartphone",
    "tr_scrollText": "Naviger enheden foran dig",
    "tr_toolTipTextc1": "Brug disse knapper til at navigere til forskellige sider på enheden foran dig.",
    "tr_toolTipTextc2": "Trykker du på nedenstående kort vil du blive navigeret til en detaljeret side for hver enheds specifikationer.",
    "tr_toolTipTextc3": "Brug disse knapper til at flytte op og ned på skærmen foran dig.",
    "tr_toolTipTextc4": "Sluk for fjern adgang for at se enhedens specifikationer på din smartphone.",
    "tr_toolTipTextmb1": "Tryk på kortet for at se detaljerede indholdssider.",
    "tr_toolTipTextmb2": "Tryk for at se en oversigt over alle enhedsspecifikationer",
    "tr_toolTipTextmb3": "Tryk på knappen for at scanne QR-kode og sammenligne op til 4 enheder",
    "tr_nextButton": "Næste",
    "tr_prevButton": "Tilbage",
    "tr_gotItButton": "FIK DEN",
    "tr_skipButton": "Spring over",
    "tr_placeholderText": "*placeholder",
    "tr_processorHeading": "Processor",
    "tr_processorSubheading": "10. generation Intel® Core™ i7 -processor",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "[XX] GB Intel® Optane™ -hukommelse + [YY] GB SSD",
    "tr_coachmarkProcessor": "XX processor",
    "tr_coachmarkGraphics": "[XX] grafik",
    "tr_coachmarkMemory": "Spec værdi",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "$ 999,95",
    "tr_compare": "Sammenlign",
    "tr_viewallspecs": "SE ALLE SPECIFIKATIONER",
    "tr_operatingSystem": "OS",
    "tr_AmdProcessorNonIntel": "AMD* -processor",
    "tr_QualcommProcessorNonIntel": "Qualcomm* &#8209;processor",
    "tr_GBText": "GB",
    "tr_remoteText": "Specs, vises på computerskærmen",
    "tr_scanErrorText": "Du kan kun scanne op til 3 enheder ad gangen. Slet en eller flere enheder.",
    "tr_scanErrorText2": "Du har allerede scannet denne enhed, scan venligst en anden.",
    "tr_scanMsg": "Scan for at tilføje enhed",
    "tr_backToScan": "Tilbage til sammenligning",
    "tr_scanToCompare": "Scan for at sammenligne",
    "tr_compareDevice": "Sammenlign enheder",
    "tr_processorTitle": "PROCESSOR",
    "tr_graphicTitle": "GRAFIK",
    "tr_storageTitle": "LAGER",
    "tr_displayTitle": "SKÆRM",
    "tr_batteryTitle": "BATTERI",
    "tr_memoryTitle": "HUKOMMELSE",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "TILSLUTNING",
    "tr_priceTitle": "PRIS",
    "tr_operatingSystemTitle": "OPERATIVSYSTEM",
    "tr_batteryHoursText": "[XX] timer",
    "tr_hrsText": "timer",
    "tr_touchscreeenText": "[screenDiagonal]” berøringsskærm",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, berøringsskærm",
    "tr_FHDTouchScreenText": "FHD, berøringsskærm",
    "tr_4KTouchscreenText": "4K, berøringsskærm",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Gem",
    "tr_device_comparison": "Enheds sammenlignede dokument [dd]-[mm]-[yyyy]",
    "tr_Save_As_JPEG": "Gem som JPEG",
    "tr_Save_As_PDF": "Gem som PDF",
    "tr_Downloading_PDF": "Downloader PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Åben",
    "tr_logo": "logo",
    "tr_laptop_name": "Bærbar computer navn",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Intel® Optane™ -hukommelse",
    "tr_inchUnit": "tomme",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "INTEL® OPTANE™ -HUKOMMELSE",
    "tr_Thunderbolt3": "Thunderbolt™ 3-teknologi",
    "tr_Thunderbolt4": "Thunderbolt™ 4-teknologi",
    "tr_processorGraphicsCaps": "PROCESSORGRAFIK",
    "tr_processorGraphicsSmall": "Processorgrafik",
    "tr_graphicsCardCaps": "DEDIKERET GRAFIK",
    "tr_graphicsCardSmall": "Dedikeret grafik",
    "tr_processorTitleCamel": "Processor",
    "tr_graphicTitleCamel": "Grafik",
    "tr_storageTitleCamel": "Lager",
    "tr_displayTitleCamel": "Skærm",
    "tr_batteryTitleCamel": "Batteri",
    "tr_memoryTitleCamel": "Hukommelse",
    "tr_connectivityTitleCamel": "Tilslutning",
    "tr_priceTitleCamel": "Pris",
    "tr_operatingSystemTitleCamel": "Operativsystem",
    "tr_viewallspecsCamel": "Se alle specifikationer",
    "tr_displayText": "[screenDiagonal]”",
    "tr_displayTextResolution": "[screenDiagonal]” [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "[screenDiagonal]” [screenResolutionType] berøringsskærm",
    "tr_OS": "OS",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "Pc-specifikationer",
    "tr_explorePC_text": "Udforsk alt om denne PC",
    "tr_mtlPC_text": "Se, hvad det virkelig betyder at være udviklet til at gøre det hele",
    "tr_optaneMemory": "Intel® Optane™ -hukommelse",
    "tr_displayText_Display": "[screenDiagonal]” SKÆRM",
    "tr_displayTextResolution_Display": "[screenDiagonal]” [screenResolutionType] SKÆRM",
    "tr_displayTextResolutionTouchscreen_Display": "[screenDiagonal]″ [screenResolutionType] TOUCHSKÆRM",
    "tr_displayTextTouchscreeenText": "[screenDiagonal]″ TOUCHSKÆRM",
    "tr_learnMoreLabel": "Få mere at vide",
    "tr_batteryHoursTextUpperCase": "[XX] TIMER",
    "tr_processorGraphicsIrisXe": "11. GENERATION INTEL® CORE™ [cpu_fam] PROCESSOR OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAFIK",
    "tr_processorGraphicsIrisXeMax": "11. GENERATION INTEL® CORE™ [cpu_fam] PROCESSOR OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAFIK",
    "tr_Thunderbolt3UpperCase": "THUNDERBOLT™ 3 -TEKNOLOGI",
    "tr_Thunderbolt4UpperCase": "THUNDERBOLT™ 4 -TEKNOLOGI",
    "tr_processorWithArcGraphics": "12. GENERATION INTEL® CORE™ [cpu_fam] -PROCESSOR OG INTEL® ARC™ -GRAFIK ",
    "tr_processorGraphicsIrisXe12Gen": "12. GENERATION INTEL® CORE™ [cpu_fam] -PROCESSOR OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> -GRAFIK",
    "tr_processorGraphicsIrisXeMax12Gen": "12. GENERATION INTEL® CORE™ [cpu_fam] -PROCESSOR OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX -GRAFIK ",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "13. GENERATION INTEL® CORE™ [cpu_fam] -PROCESSOR OG INTEL® ARC™ -GRAFIK ",
    "tr_processorGraphicsIrisXe13Gen": "13. GENERATION INTEL® CORE™ [cpu_fam] -PROCESSOR OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> -GRAFIK",
    "tr_processorGraphicsIrisXeMax13Gen": "13. GENERATION INTEL® CORE™ [cpu_fam] -PROCESSOR OG INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX -GRAFIK ",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "INTEL® ARC™ A350M -GRAFIK",
      "tr_A370M": "INTEL® ARC™ A370M -GRAFIK",
      "tr_A730M": "INTEL® ARC™ A730M -GRAFIK",
      "tr_A770M": "INTEL® ARC™ A770M -GRAFIK",
      "tr_A310": "INTEL® ARC™ A310 -GRAFIK",
      "tr_A380": "INTEL® ARC™ A380 -GRAFIK",
      "tr_A580": "INTEL® ARC™ A580 -GRAFIK",
      "tr_A750": "INTEL® ARC™ A750 -GRAFIK",
      "tr_A770": "INTEL® ARC™ A770 -GRAFIK"
    },
    "tr_memoryValue": "[XX] GB RAM",
    "tr_memoryWithOptaneDesc": "[Value1] GB hukommelse = [Value2]&nbsp;GB Intel® Optane™ -hukommelse +&nbsp;[Value3]&nbsp;GB RAM",
    "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textGBSSD": "[convertedSSD] GB SSD",
    "tr_textTBSSD": "[convertedSSD] TB SSD",
    "tr_textGBHDD": "[convertedHDD] GB HDD",
    "tr_textTBHDD": "[convertedHDD] TB HDD",
    "tr_textStorageWithOptaneGB": "[XX] GB Intel® Optane™ -hukommelse + [convertedSSD] GB SSD",
    "tr_textStorageWithOptaneTB": "[XX] GB Intel® Optane™ -hukommelse + [convertedSSD] TB SSD",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home i S&#8209;tilstand",
      "tr_OS21": "Windows* 10 Pro i S&#8209;tilstand",
      "tr_OS22": "Windows* 10 Enterprise i S&#8209;tilstand",
      "tr_OS23": "Windows* 11 Home i S&#8209;tilstand",
      "tr_OS24": "Windows* 11 Pro i S&#8209;tilstand",
      "tr_OS25": "Windows* 11 Enterprise i S&#8209;tilstand"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Intel® Arc™ A310 -grafik",
      "tr_GR387": "Intel® Arc™ A350M -grafik",
      "tr_GR388": "Intel® Arc™ A370M -grafik",
      "tr_GR389": "Intel® Arc™ A380 -grafik",
      "tr_GR390": "Intel® Arc™ A530M -grafik",
      "tr_GR391": "Intel® Arc™ A550M -grafik",
      "tr_GR392": "Intel® Arc™ A570M -grafik",
      "tr_GR393": "Intel® Arc™ A580 -grafik",
      "tr_GR394": "Intel® Arc™ A730M -grafik",
      "tr_GR395": "Intel® Arc™ A750 -grafik",
      "tr_GR396": "Intel® Arc™ A770 -grafik",
      "tr_GR397": "Intel® Arc™ A770M -grafik",
      "tr_GR398": "Intel® Arc™ -grafik",
      "tr_GR399": "Intel® Arc™ Pro A30M -grafik",
      "tr_GR400": "Intel® Arc™ Pro A40/A50 -grafik",
      "tr_GR401": "Intel® Arc™ Pro A60 -grafik",
      "tr_GR402": "Intel® Arc™ Pro A60M -grafik",
      "tr_GR403": "Intel® -Grafik",
      "tr_GR404": "Intel® HD-grafik 2000",
      "tr_GR405": "Intel® HD-grafik 2500",
      "tr_GR406": "Intel® HD-grafik 3000",
      "tr_GR407": "Intel® HD-grafik 400",
      "tr_GR408": "Intel® HD-grafik 4000",
      "tr_GR409": "Intel® HD-grafik 405",
      "tr_GR410": "Intel® HD-grafik 4200",
      "tr_GR411": "Intel® HD-grafik 4400",
      "tr_GR412": "Intel® HD-grafik 4600",
      "tr_GR413": "Intel® HD-grafik 500",
      "tr_GR414": "Intel® HD-grafik 5000",
      "tr_GR415": "Intel® HD-grafik 505",
      "tr_GR416": "Intel® HD-grafik 510",
      "tr_GR417": "Intel® HD-grafik 515",
      "tr_GR418": "Intel® HD-grafik 520",
      "tr_GR419": "Intel® HD-grafik 530",
      "tr_GR420": "Intel® HD-grafik 5300",
      "tr_GR421": "Intel® HD-grafik 5500",
      "tr_GR422": "Intel® HD-grafik 5600",
      "tr_GR423": "Intel® HD-grafik 6000",
      "tr_GR424": "Intel® HD-grafik 610",
      "tr_GR425": "Intel® HD-grafik 615",
      "tr_GR426": "Intel® HD-grafik 620",
      "tr_GR427": "Intel® HD-grafik 630",
      "tr_GR428": "Intel® HD-grafik",
      "tr_GR429": "Intel® Iris® -grafik 5100",
      "tr_GR430": "Intel® Iris® -grafik 540",
      "tr_GR431": "Intel® Iris® -grafik 550",
      "tr_GR432": "Intel® Iris® -grafik 6100",
      "tr_GR433": "Intel® Iris® Plus -grafik 640",
      "tr_GR434": "Intel® Iris® Plus -grafik 645",
      "tr_GR435": "Intel® Iris® Plus -grafik 650",
      "tr_GR436": "Intel® Iris® Plus -grafik 655",
      "tr_GR437": "Intel® Iris® Plus -grafik",
      "tr_GR438": "Intel® Iris® Pro -grafik 5200",
      "tr_GR439": "Intel® Iris® Pro -grafik 580",
      "tr_GR440": "Intel® Iris® Pro -grafik 6200",
      "tr_GR441": "Intel® Iris® X<sup>e</sup> -grafik",
      "tr_GR442": "Intel® Iris® X<sup>e</sup> MAX -grafik A220M",
      "tr_GR443": "Intel® Iris® X<sup>e</sup> MAX -grafik",
      "tr_GR444": "Integreret Intel® -grafikkort",
      "tr_GR445": "Intel® UHD-grafik 600",
      "tr_GR446": "Intel® UHD-grafik 605",
      "tr_GR447": "Intel® UHD-grafik 610",
      "tr_GR448": "Intel® UHD-grafik 615",
      "tr_GR449": "Intel® UHD-grafik 617",
      "tr_GR450": "Intel® UHD-grafik 620",
      "tr_GR451": "Intel® UHD-grafik 630",
      "tr_GR452": "Intel® UHD-grafik 710",
      "tr_GR453": "Intel® UHD-grafik 730",
      "tr_GR454": "Intel® UHD-grafik 770",
      "tr_GR455": "Intel® UHD-grafik",
      "tr_GR456": "Intel® UHD-grafik",
      "tr_GR457": "Intel® UHD-grafik",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 med Max-Q Design",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti med Max-Q Design",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 med Max-Q Design",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 med Max-Q Design",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 med Max-Q Design",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 med Max-Q Design",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti med Max-Q Design",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti med Max-Q Design",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN sort",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 med Max-Q Design",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 med Max-Q Design",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super med Max-Q Design",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 med Max-Q Design",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super med Max-Q Design",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 med Max-Q Design",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 med Max-Q Design",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 med Max-Q Design",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 med Max-Q Design",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 med Max-Q Design",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 med Max-Q Design",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 med Max-Q Design",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 med Max-Q Design",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 med Max-Q Design",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 med Max-Q Design",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "Intel® Arc™ grafik",
      "tr_GR813": "NVIDIA GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada -generation",
      "tr_GR815": "AMD Radeon* Pro W7900 Dual Slot"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® trådløs-N 100",
      "tr_Wifi11": "Intel® Centrino® trådløs-N 1000",
      "tr_Wifi12": "Intel® Centrino® trådløs-N 1030",
      "tr_Wifi13": "Intel® Centrino® trådløs-N 105",
      "tr_Wifi14": "Intel® Centrino® trådløs-N 135",
      "tr_Wifi15": "Intel® Centrino® trådløs-N 2200",
      "tr_Wifi16": "Intel® Centrino® trådløs-N 2230",
      "tr_Wifi17": "Intel® Centrino® trådløs-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band trådløs-AC 3160",
      "tr_Wifi19": "Intel® Dual Band trådløs-AC 3165",
      "tr_Wifi20": "Intel® Dual Band trådløs-AC 7260",
      "tr_Wifi21": "Intel® Dual Band trådløs-AC 7265",
      "tr_Wifi22": "Intel® Dual Band trådløs-N 3160",
      "tr_Wifi23": "Intel® Dual Band trådløs-N 7260",
      "tr_Wifi24": "Intel® Dual Band trådløs-N 7265",
      "tr_Wifi25": "Intel® Tri-Band trådløs-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® trådløs-AC 3165",
      "tr_Wifi36": "Intel® trådløs-AC 3168",
      "tr_Wifi37": "Intel® trådløs-AC 8265",
      "tr_Wifi38": "Intel® trådløs-AC 9260",
      "tr_Wifi39": "Intel® trådløs-AC 9461",
      "tr_Wifi40": "Intel® trådløs-AC 9462",
      "tr_Wifi41": "Intel® trådløs-AC 9560",
      "tr_Wifi42": "Intel® trådløs-N 3160",
      "tr_Wifi43": "Intel® trådløs-N 7260",
      "tr_Wifi44": "Intel® trådløs-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ trådløs-AC 1550",
      "tr_Wifi56": "Intel® Killer™ trådløs-AC 1550",
      "tr_Wifi57": "Intel® Killer™ trådløs-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Køb nu",
    "tr_textTryagain": "Prøv igen",
    "tr_textBuynowErr": "På grund af en afbrydelse af forbindelsen er muligheden Køb nu ikke tilgængelig",
    "tr_chatbotPlaceholder_text": "Spørg mig om alt om enheden",
    "tr_deviceSpecs_text": "Enhedsspecifikationer"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Intel® Core™ i3 -processor med Intel® Hybrid teknologi"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Intel® Core™ i5 -processor med Intel® Hybrid teknologi"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Gear op med Intel® for en mægtigere gaming-oplevelse",
                "tr_subtitle": "Bring Marvel Avengers* til live med spiloptimering drevet af Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Oplev Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Hvor verdens bedste gamere kommer for at konkurrere",
                "tr_subtitle": "Den længste begivenhedsserie indenfor videospilshistorien, Intel® Extreme Masters sætter standarderne for e-sport.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Spil som de professionelle",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Mestre skabes med Intel.",
                "tr_subtitle": "Efter at have vundet 2021-mesterskabet i Rainbow Six Siege* North American League reflekterer Soniqs over deres gaminghistorie, fremtiden for esport og deres partnerskab med Intel.",
                "tr_pretitle": "Med Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "VR-klar",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Når VR bliver levende",
                "tr_subtitle": "De mest fantastiske, naturtro og medrivende virtual reality-oplevelser kører på en computer. Med de nyeste Intel® Core™ -processorer kan du være hvem som helst, rejse hvor hen du vil og opleve en ny verden af gaming.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Spil 100+ konsolspil af høj kvalitet som Sea of Thieves* og Psychonauts* 2 på din Windows* PC, mobiltelefon og tablet med Xbox* Game Pass Ultimate og en kompatibel controller.",
                "titleArray": [
                  {
                    "tr_title": "Spil sammen på tværs af enheder",
                    "tr_subtitle": "Gå på opdagelse i hjertet af Xbox* med et fællesskab af millioner af spillere, der er klar og venter på at spille sammen. Opret forbindelse og spil med andre fra et delt spilbibliotek, uanset om de er på den anden side af kloden eller sidder lige ved siden af dig."
                  },
                  {
                    "tr_title": "Windows* PC gaming",
                    "tr_subtitle": "Spil et helt katalog af konsolspil fra skyen ved hjælp af Xbox*-appen til Windows* og en kompatibel controller."
                  },
                  {
                    "tr_title": "Tag det op og spil",
                    "tr_subtitle": "Nyd fantastiske Xbox*-spil flere steder end før. Spil 100+ spil på din PC, mobiltelefon og tablet uanset om du er hjemme, mens dit TV er i brug, på en café eller et hvilket som helst andet sted med en internetforbindelse."
                  },
                  {
                    "tr_title": "Controller-fri sjov",
                    "tr_subtitle": "Xbox* touch control gaming låser op for nye måder at hoppe ind i spillet på som giver en velkendt oplevelse og understøtter et spilleniveau, som du er vant til med en fysisk controller."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Mere end 100 AI-oplevelser",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Mere styring, mindre berøring",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Hold dig i rammen uanset omgivelserne",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitet",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Korriger belysningen som en professionel",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Tilføj sceneskabende effekter med lethed",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Remix sange med kraften fra AI",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitet",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Giv din produktivitet og dit privatliv et boost",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Brug mere tid væk fra stikkontakten",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Din verden. På dine enheder.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Underholdning",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Flydende streaming selv uden stik",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Hurtig, indlevende gaming",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Mere end 100 AI-oplevelser",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Gør dine yndlingssange til helt dine egne",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Din vision bliver til virkelighed foran dine øjne",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitet",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Den hurtigste vej til dit endelige klip",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Gør alle fotobilleder perfekte",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitet",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Giv din produktivitet og dit privatliv et boost",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Mere effektiv. Mere frihed.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Din verden. På dine enheder.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Underholdning",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Flydende HD-streaming selv uden stik",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Indlevende gaming på flere steder",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mere kreativ",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Billedredigering gjort let ",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Opret rammeværdige billeder på sekunder ",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Du skriver det.  AI klarer den. ",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Større ydeevne",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Snydekode til bedre gaming",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Brug mere tid væk fra stikkontakten",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-indlevende",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mere produktiv",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Din daglige AI-partner ",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Perfektion til videoopkald",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Din verden. På dine enheder.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mere sikker ",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Indbygget beskyttelse for at hjælpe med at holde dine data mere sikre og private ",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mere kreativ",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Billedredigering gjort let ",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "AI. Genvejen til dit endelige resultat.  ",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Transskriber hurtigt dine favoritsangs lyrik",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Spar timers redigering med AI ",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Større ydeevne",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Snydekode til bedre gaming",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Brug mere tid væk fra stikkontakten",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-indlevende",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mere produktiv",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Din daglige AI-partner ",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Perfektion til videoopkald",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Din verden. På dine enheder.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mere sikker ",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Indbygget beskyttelse for at hjælpe med at holde dine data mere sikre og private ",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Al din underholdning. Én pc.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Til en pc, der også fungerer som underholdningscenter, har du brug for nok lagerplads til alle dine yndlingssange, videoer og forskellige spil og den rigtige kombination af processor og skærm til at skabe medrivende grafik. Denne pc har alle tre dele.",
                "titleArray": [
                  {
                    "tr_title": "Klar til næste next-gen grafik",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Opbyg dit digitale bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Et vindue til din underholdning",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Problemfri gaming og streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Opbyg dit digitale bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Et vindue til din underholdning",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Vær mere produktiv. Hvor som helst.",
              "tr_tileTitle1": "Få mere gjort. Hurtigt.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Denne pc har den perfekte balance mellem kraft og bærbarhed. Denne pc lægger ud med en processor, der er fremstillet til tyndt og let design, og den har også den type Wi-Fi, der holder forbindelsen, og det rigtige batteri, så du er fri for ledninger.",
                "tr_subtitle1": "Denne PC har den perfekte balance mellem styrke og ydeevne, til at hjælpe dig med at nå det hele på den halve tid. Det er nemt at effektivisere dine daglige opgaver med denne kombinationen af en robust processor, der forstærkes af rigelig hukommelse og lynhurtig wi&#8209;fi.",
                "tr_subtitle2": "Denne pc har den perfekte balance mellem kraft og bærbarhed. Denne pc starter med en processor, der er fremstillet til tyndt og let design, og den har også den type Wi‑Fi, der holder forbindelsen, og nok hukommelse til multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Bygget til at klare mere",
                    "tr_title1": "Intelligent ydeevne med betydning",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Den bedste forbindelse i klassen",
                    "tr_title1": "Opret forbindelse til det, der er vigtigt",
                    "tr_title2": "Et stort fremskridt i Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Gå uden opladning længere",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Skift mellem programmer som en professionel",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Friheden til at skabe",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Oprettelse af indhold er en af de mest krævende opgaver for din pc. Heldigvis har disse komponenter kraft nok til at strømline din kreative proces.",
                "titleArray": [
                  {
                    "tr_title": "En smartere måde at skabe på",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Gør dine billeder levende",
                    "tr_title1": "Lad os kreere",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Hukommelse til multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Få et indblik i denne pc",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Dette er de tre vigtigste komponenter, som du skal være opmærksom på, når du vælger en ny pc. Når du beslutter, hvordan du vil bruge din nye pc, er du kommet langt mht. beslutningen om, hvor meget af disse tre vigtige komponenter, du skal bruge. De er:",
                "titleArray": [
                  {
                    "tr_title": "Ydeevne til gaming og streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Lager",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Hukommelse",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Al din underholdning. Én pc.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Til en pc, der også fungerer som underholdningscenter, har du brug for nok lagerplads til alle dine yndlingssange, videoer og forskellige spil og den rigtige kombination af processor og skærm til at skabe medrivende grafik. Denne pc har alle tre dele.",
                "titleArray": [
                  {
                    "tr_title": "Klar til næste next-gen grafik",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Opbyg dit digitale bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Et vindue til din underholdning",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Problemfri gaming og streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Opbyg dit digitale bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Et vindue til din underholdning",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hukommelse",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Skærm",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Multitasking med høj hastighed",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING MED HØJ HASTIGHED",
                "tr_subtitle": "Gør mere uden at sætte farten ned",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "En processor, der kan jonglere med flere programmer på én gang."
                  },
                  {
                    "tr_title": "Wi‑Fi, der er hurtigt, stærkt og pålideligt."
                  },
                  {
                    "tr_title": "Hukommelse, der problemfrit skifter mellem programmer."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videochat",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCHAT",
                "tr_subtitle": "Krystalklare forbindelser",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til videochat:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "En processor, der kan optage og dele billeder på samme tid."
                  },
                  {
                    "tr_title": "Wi‑Fi, der er hurtigt, stærkt og pålideligt."
                  },
                  {
                    "tr_title": "Et webcam, der hjælper dig med at fremstå krystalklart."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-klar",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Når VR bliver levende",
                "tr_subtitle": "Hvad kræver det at skabe en helt virtuel verden? På en pc har du brug for koordinering mellem din processor, dit grafikkort og din hukommelse.",
                "titleArray": [
                  {
                    "tr_title": "Driver virtuelle verdener",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Den virkelighedstro effekt ligger i detaljerne",
                    "tr_title1": "Gå i fordybelse",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Videoredigering",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOREDIGERING",
                "tr_subtitle": "Lav magi i film",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til videoredigering:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "En processor, der kan håndtere store filer."
                  },
                  {
                    "tr_title": "Hukommelse, der gør redigeringssoftware hurtig og responsiv."
                  },
                  {
                    "tr_title": "Grafik, der reducerer renderingstiden."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Multitasking med høj hastighed",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING MED HØJ HASTIGHED",
                "tr_subtitle": "Gør mere uden at sætte farten ned",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "En processor, der kan jonglere med flere programmer på én gang."
                  },
                  {
                    "tr_title": "Wi‑Fi, der er hurtigt, stærkt og pålideligt."
                  },
                  {
                    "tr_title": "Hukommelse, der problemfrit skifter mellem programmer."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-klar",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Når VR bliver levende",
                "tr_subtitle": "Hvad kræver det at skabe en helt virtuel verden? På en pc har du brug for koordinering mellem din processor, dit grafikkort og din hukommelse.",
                "titleArray": [
                  {
                    "tr_title": "Driver virtuelle verdener",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Den virkelighedstro effekt ligger i detaljerne",
                    "tr_title1": "Gå i fordybelse",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "3D-modellering",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D-MODELLERING",
                "tr_subtitle": "Kreationer på næste niveau",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til 3D-modellering:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "En processor, der kan håndtere avanceret kreativ software."
                  },
                  {
                    "tr_title": "Hukommelse, der hurtigt kan hente store filer."
                  },
                  {
                    "tr_title": "Grafik til kraftfuld billedfremstilling og -redigering."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Samarbejde i realtid",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SAMARBEJDE I REALTID",
                "tr_subtitle": "Du kan arbejde hvor som helst",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til online-samarbejde:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "En processor til at drive kontorsoftware."
                  },
                  {
                    "tr_title": "Wi‑Fi, som du kan stole på."
                  },
                  {
                    "tr_title": "Et webcam, der optager i strålende HD."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-klar",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Når VR bliver levende",
                "tr_subtitle": "Hvad kræver det at skabe en helt virtuel verden? På en pc har du brug for koordinering mellem din processor, dit grafikkort og din hukommelse.",
                "titleArray": [
                  {
                    "tr_title": "Driver virtuelle verdener",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Den virkelighedstro effekt ligger i detaljerne",
                    "tr_title1": "Gå i fordybelse",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ bærbare computere",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Bærbare computere så tynde og lette bør ikke være så kraftfulde",
                "tr_subtitle": "Intel® Evo™ bærbare computere er udviklet til mobil ydeevne og har al den hastighed, grafik og batterilevetid, du har brug for - alt sammen i det mest fantastiske, tynde og lette design.",
                "titleArray": [
                  {
                    "tr_title": "Den bærbare computer betyder mere end nogensinde, så vi har sørget for, at den kan gøre mere end før.",
                    "tr_subtitle": "Brug din berøringsskærm til at udforske, rotere og zoom."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Væk den på et øjeblik",
                    "tr_subtitle": "Med øjeblikkelig opvågning kan din PC gå fra dvale til klar på mindre end ét&nbsp;sekund."
                  },
                  {
                    "tr_title": "Moderne synsoplevelse",
                    "tr_subtitle": "En flot skærm giver dine visuelle billeder liv i klar opløsning og levende farver."
                  },
                  {
                    "tr_title": "Få tingene gjort hurtigt",
                    "tr_subtitle": "Nyd ultra-responsiv præstation fra 11.&nbsp;generation&nbsp;Intel®&nbsp;Core™ -processorer."
                  },
                  {
                    "tr_title": "Træk stikket i længere tid",
                    "tr_subtitle": "Vær altid med på en PC, der optimerer din&nbsp;batterilevetid og oplader på ingen tid."
                  },
                  {
                    "tr_title": "Universal kabelforbindelse",
                    "tr_subtitle": "Det er enkelt, hurtigt og sikkert at tilslutte andre enheder til din PC med&nbsp;Thunderbolt™&nbsp;4&nbsp;-teknologi <sup>10</sup>"
                  },
                  {
                    "tr_title": "Hurtige, pålidelige forbindelser",
                    "tr_subtitle": "Videochat eller del filer via en forbindelse du&nbsp;kan&nbsp;regne med Intel® Wi‑Fi 6 (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ bærbare computere",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alt du har brug for og mere i en tynd og let bærbar computer.",
                "tr_subtitle": "Intel® Evo™ bærbare computere er udviklet til mobil ydeevne og designet til at give dig den bedste oplevelse<sup>2</sup>, uanset hvad du laver.",
                "titleArray": [
                  {
                    "tr_title": "Den bærbare computer betyder mere end nogensinde, så vi har sørget for, at den kan gøre mere end før.",
                    "tr_subtitle": "Brug din berøringsskærm til at udforske, rotere og zoom."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Væk den på et øjeblik",
                    "tr_subtitle": "Med øjeblikkelig opvågning kan din PC gå fra dvale til klar på mindre end ét&nbsp;sekund."
                  },
                  {
                    "tr_title": "Moderne synsoplevelse",
                    "tr_subtitle": "En flot skærm giver dine visuelle billeder liv i klar opløsning og levende farver."
                  },
                  {
                    "tr_title": "Revolutionerende ydeevne",
                    "tr_subtitle": "Få ekstra kraft, hvor du har mest brug for det med intelligent ydeevne fra 12. generation Intel® Core™ -processorer. "
                  },
                  {
                    "tr_title": "Træk stikket i længere tid",
                    "tr_subtitle": "Vær altid med på en PC, der optimerer din&nbsp;batterilevetid og oplader på ingen tid."
                  },
                  {
                    "tr_title": "Universal kabelforbindelse",
                    "tr_subtitle": "Det er enkelt, hurtigt og sikkert at tilslutte andre enheder til din PC med&nbsp;Thunderbolt™&nbsp;4&nbsp;-teknologi <sup>10</sup>"
                  },
                  {
                    "tr_title": "Hurtige, pålidelige forbindelser",
                    "tr_subtitle": "Videochat eller del filer med en forbindelse, du kan regne med ved hjælp af Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ bærbare computere",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Bærbare computere så tynde og lette bør ikke være så kraftfulde",
                "tr_subtitle": "Intel® Evo™ bærbare computere er udviklet til mobil ydeevne og har al den hastighed, grafik og batterilevetid, du har brug for - alt sammen i det mest fantastiske, tynde og lette design.",
                "titleArray": [
                  {
                    "tr_title": "Den bærbare computer betyder mere end nogensinde, så vi har sørget for, at den kan gøre mere end før.",
                    "tr_subtitle": "Brug din berøringsskærm til at udforske, rotere og zoom."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Væk den på et øjeblik",
                    "tr_subtitle": "Med øjeblikkelig opvågning kan din PC gå fra dvale til klar på mindre end ét&nbsp;sekund."
                  },
                  {
                    "tr_title": "Moderne synsoplevelse",
                    "tr_subtitle": "En flot skærm giver dine visuelle billeder liv i klar opløsning og levende farver."
                  },
                  {
                    "tr_title": "Få tingene gjort hurtigt",
                    "tr_subtitle": "Nyd ultra-responsiv præstation fra 11.&nbsp;generation&nbsp;Intel®&nbsp;Core™ -processorer."
                  },
                  {
                    "tr_title": "Træk stikket i længere tid",
                    "tr_subtitle": "Vær altid med på en PC, der optimerer din&nbsp;batterilevetid og oplader på ingen tid."
                  },
                  {
                    "tr_title": "Universal kabelforbindelse",
                    "tr_subtitle": "Det er enkelt, hurtigt og sikkert at tilslutte andre enheder til din PC med&nbsp;Thunderbolt™&nbsp;4&nbsp;-teknologi <sup>10</sup>"
                  },
                  {
                    "tr_title": "Hurtige, pålidelige forbindelser",
                    "tr_subtitle": "Videochat eller del filer via en forbindelse du&nbsp;kan&nbsp;regne med Intel® Wi‑Fi 6 (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ bærbare computere",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alt du har brug for og mere i en tynd og let bærbar computer.",
                "tr_subtitle": "Intel® Evo™ bærbare computere er udviklet til mobil ydeevne og designet til at give dig den bedste oplevelse<sup>2</sup>, uanset hvad du laver.",
                "titleArray": [
                  {
                    "tr_title": "Den bærbare computer betyder mere end nogensinde, så vi har sørget for, at den kan gøre mere end før.",
                    "tr_subtitle": "Brug din berøringsskærm til at udforske, rotere og zoom."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Væk den på et øjeblik",
                    "tr_subtitle": "Med øjeblikkelig opvågning kan din PC gå fra dvale til klar på mindre end ét&nbsp;sekund."
                  },
                  {
                    "tr_title": "Moderne synsoplevelse",
                    "tr_subtitle": "En flot skærm giver dine visuelle billeder liv i klar opløsning og levende farver."
                  },
                  {
                    "tr_title": "Revolutionerende ydeevne",
                    "tr_subtitle": "Få ekstra kraft, hvor du har mest brug for det med intelligent ydeevne fra 12. generation Intel® Core™ -processorer. "
                  },
                  {
                    "tr_title": "Træk stikket i længere tid",
                    "tr_subtitle": "Vær altid med på en PC, der optimerer din&nbsp;batterilevetid og oplader på ingen tid."
                  },
                  {
                    "tr_title": "Universal kabelforbindelse",
                    "tr_subtitle": "Det er enkelt, hurtigt og sikkert at tilslutte andre enheder til din PC med&nbsp;Thunderbolt™&nbsp;4&nbsp;-teknologi <sup>10</sup>"
                  },
                  {
                    "tr_title": "Hurtige, pålidelige forbindelser",
                    "tr_subtitle": "Videochat eller del filer med en forbindelse, du kan regne med ved hjælp af Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ bærbare computere",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alt du har brug for og mere i en tynd og let bærbar computer.",
                "tr_subtitle": "Intel® Evo™ bærbare computere er udviklet til mobil ydeevne og designet til at give dig den bedste oplevelse<sup>2</sup>, uanset hvad du laver.",
                "titleArray": [
                  {
                    "tr_title": "Den bærbare computer betyder mere end nogensinde, så vi har sørget for, at den kan gøre mere end før.",
                    "tr_subtitle": "Brug din berøringsskærm til at udforske, rotere og zoom."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Væk den på et øjeblik",
                    "tr_subtitle": "Med øjeblikkelig opvågning kan din PC gå fra dvale til klar på mindre end ét&nbsp;sekund."
                  },
                  {
                    "tr_title": "Moderne synsoplevelse",
                    "tr_subtitle": "En flot skærm giver dine visuelle billeder liv i klar opløsning og levende farver."
                  },
                  {
                    "tr_title": "Revolutionerende ydeevne",
                    "tr_subtitle": "Få ekstra kraft, hvor du har mest brug for det med intelligent ydeevne fra 13. generation Intel® Core™ -processorer. "
                  },
                  {
                    "tr_title": "Træk stikket i længere tid",
                    "tr_subtitle": "Vær altid med på en PC, der optimerer din&nbsp;batterilevetid og oplader på ingen tid."
                  },
                  {
                    "tr_title": "Universal kabelforbindelse",
                    "tr_subtitle": "Det er enkelt, hurtigt og sikkert at tilslutte andre enheder til din PC med&nbsp;Thunderbolt™&nbsp;4&nbsp;-teknologi <sup>10</sup>"
                  },
                  {
                    "tr_title": "Hurtige, pålidelige forbindelser",
                    "tr_subtitle": "Videochat eller del filer med en forbindelse, du kan regne med ved hjælp af Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ bærbare computere",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alt du har brug for og mere i en tynd og let bærbar computer.",
                "tr_subtitle": "Intel® Evo™ bærbare computere er udviklet til mobil ydeevne og designet til at give dig den bedste oplevelse<sup>2</sup>, uanset hvad du laver.",
                "titleArray": [
                  {
                    "tr_title": "Den bærbare computer betyder mere end nogensinde, så vi har sørget for, at den kan gøre mere end før.",
                    "tr_subtitle": "Brug din berøringsskærm til at udforske, rotere og zoom."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Væk den på et øjeblik",
                    "tr_subtitle": "Med øjeblikkelig opvågning kan din PC gå fra dvale til klar på mindre end ét&nbsp;sekund."
                  },
                  {
                    "tr_title": "Moderne synsoplevelse",
                    "tr_subtitle": "En flot skærm giver dine visuelle billeder liv i klar opløsning og levende farver."
                  },
                  {
                    "tr_title": "Revolutionerende ydeevne",
                    "tr_subtitle": "Få ekstra kraft, hvor du har mest brug for det med intelligent ydeevne fra 13. generation Intel® Core™ -processorer. "
                  },
                  {
                    "tr_title": "Træk stikket i længere tid",
                    "tr_subtitle": "Vær altid med på en PC, der optimerer din&nbsp;batterilevetid og oplader på ingen tid."
                  },
                  {
                    "tr_title": "Universal kabelforbindelse",
                    "tr_subtitle": "Det er enkelt, hurtigt og sikkert at tilslutte andre enheder til din PC med&nbsp;Thunderbolt™&nbsp;4&nbsp;-teknologi <sup>10</sup>"
                  },
                  {
                    "tr_title": "Hurtige, pålidelige forbindelser",
                    "tr_subtitle": "Videochat eller del filer med en forbindelse, du kan regne med ved hjælp af Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Hvad er Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Hvorfor Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitet",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Vær mere kreativ flere steder",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Fotoredigering med fuld hastighed",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Skab original kunst på stedet",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitet",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Optimér produktiviteten med Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Opdag deepfakes, før du deler med AI",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Din verden. På dine enheder.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilitet",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Vink goddag til bevægelseskontrol",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Batterilevetid, der ikke holder dig tilbage",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Holder dig automatisk forbundet",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Hvad er Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Hvorfor Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativitet",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Hurtig, problemfri videoafspilning",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Producer hits med kraften fra AI",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "Den hurtigste vej til dit endelige klip",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivitet",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Optimér produktiviteten med Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Opdag deepfakes, før du deler med AI",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Din verden. På dine enheder.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilitet",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Samarbejd fra stort set hvor som helst",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Batterilevetid, der ikke holder dig tilbage",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Holder dig automatisk forbundet",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Al din underholdning. Én pc.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Til en pc, der også fungerer som underholdningscenter, har du brug for nok lagerplads til alle dine yndlingssange, videoer og forskellige spil og den rigtige kombination af processor og skærm til at skabe medrivende grafik. Denne pc har alle tre dele.",
                "titleArray": [
                  {
                    "tr_title": "Imponerende streaming og gaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Opbyg dit digitale bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Et vindue til din underholdning",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Problemfri gaming og streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Opbyg dit digitale bibliotek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Et vindue til din underholdning",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Vær mere produktiv. Hvor som helst.",
              "tr_tileTitle1": "Få mere gjort. Hurtigt.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Denne pc har den perfekte balance mellem kraft og bærbarhed. Denne pc lægger ud med en processor, der er fremstillet til tyndt og let design, og den har også den type Wi-Fi, der holder forbindelsen, og det rigtige batteri, så du er fri for ledninger.",
                "tr_subtitle1": "Denne PC har den perfekte balance mellem styrke og ydeevne, til at hjælpe dig med at nå det hele på den halve tid. Det er nemt at effektivisere dine daglige opgaver med denne kombinationen af en robust processor, der forstærkes af rigelig hukommelse og lynhurtig wi&#8209;fi.",
                "tr_subtitle2": "Denne pc har den perfekte balance mellem kraft og bærbarhed. Denne pc starter med en processor, der er fremstillet til tyndt og let design, og den har også den type Wi‑Fi, der holder forbindelsen, og nok hukommelse til multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Intelligent ydeevne med betydning",
                    "tr_title1": "Sæt farten op med intelligent ydeevne",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Den bedste forbindelse i klassen",
                    "tr_title1": "Opret forbindelse til det, der er vigtigt",
                    "tr_title2": "Et stort fremskridt i Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Gå uden opladning længere",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Skift mellem programmer som en professionel",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Friheden til at skabe",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Oprettelse af indhold er en af de mest krævende opgaver for din pc. Heldigvis har disse komponenter kraft nok til at strømline din kreative proces.",
                "titleArray": [
                  {
                    "tr_title": "Sæt skub i din kreativitet",
                    "tr_title1": "Flow gennem kreative projekter",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Forbedret skabelse",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Hukommelse til multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Få et indblik i denne pc",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Dette er de tre vigtigste komponenter, som du skal være opmærksom på, når du vælger en ny pc. Når du beslutter, hvordan du vil bruge din nye pc, er du kommet langt mht. beslutningen om, hvor meget af disse tre vigtige komponenter, du skal bruge. De er:",
                "titleArray": [
                  {
                    "tr_title": "Processor",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Lager",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Hukommelse",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Friheden til at skabe",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Oprettelse af indhold er en af de mest krævende opgaver for din pc. Heldigvis har disse komponenter kraft nok til at strømline din kreative proces.",
                "titleArray": [
                  {
                    "tr_title": "En smartere måde at skabe på",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Gør dine billeder levende",
                    "tr_title1": "Lad os kreere",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Hukommelse til multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Vær mere produktiv. Hvor som helst.",
              "tr_tileTitle1": "Få mere gjort. Hurtigt.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Denne pc har den perfekte balance mellem kraft og bærbarhed. Denne pc lægger ud med en processor, der er fremstillet til tyndt og let design, og den har også den type Wi-Fi, der holder forbindelsen, og det rigtige batteri, så du er fri for ledninger.",
                "tr_subtitle1": "Denne PC har den perfekte balance mellem styrke og ydeevne, til at hjælpe dig med at nå det hele på den halve tid. Det er nemt at effektivisere dine daglige opgaver med denne kombinationen af en robust processor, der forstærkes af rigelig hukommelse og lynhurtig wi&#8209;fi.",
                "tr_subtitle2": "Denne pc har den perfekte balance mellem kraft og bærbarhed. Denne pc starter med en processor, der er fremstillet til tyndt og let design, og den har også den type Wi‑Fi, der holder forbindelsen, og nok hukommelse til multitasking.",
                "titleArray": [
                  {
                    "tr_title": "Bygget til at klare mere",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Den bedste forbindelse i klassen",
                    "tr_title1": "Opret forbindelse til det, der er vigtigt",
                    "tr_title2": "Et stort fremskridt i Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Gå uden opladning længere",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Skift mellem programmer som en professionel",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "VR-klar",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Når VR bliver levende",
                "tr_subtitle": "Hvad kræver det at skabe en helt virtuel verden? På en pc har du brug for koordinering mellem din processor, dit grafikkort og din hukommelse.",
                "titleArray": [
                  {
                    "tr_title": "Driver virtuelle verdener",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Den virkelighedstro effekt ligger i detaljerne",
                    "tr_title1": "Gå i fordybelse",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>En fryd for øjet",
      "tr_sub_header": "Blåt lys kan forårsage synsgener og forstyrre din søvncyklus. Samsungs SGS Eye Care-testede OLED-skærm hjælper ikke ved at ændre farverne, men i stedet ved at reducere bølgelængden af potentielt skadeligt blåt lys, hvilket giver øjenkomfort og reduceret øjenudmattelse.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Mindre skadeligt blåt lys"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra på alle måder",
      "tr_sub_header": "OLED's ultralette, ultratynde design maksimerer billedkvaliteten og minimerer samtidig vægten."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Ægte sort",
      "tr_sub_header": "0,0005nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74 %<br/><span class='conventional_text'>Konventionel</span>",
      "tr_sub_header": "120 %<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Streaming",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STREAMING",
                "tr_subtitle": "Endeløs underholdning",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til streaming:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "En processor, der kan streame i HD."
                  },
                  {
                    "tr_title": "En skærm til lyse, smukke billeder."
                  },
                  {
                    "tr_title": "Wi‑Fi, der giver mindre buffering og indlæsningstider."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Webbrowsing",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "WEBBROWSING",
                "tr_subtitle": "Internettet gjort nemt",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til webbrowsing:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "En processor, der hurtigt kan indlæse medie-rige sider."
                  },
                  {
                    "tr_title": "Wi‑Fi, der er hurtigt og pålideligt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videochat",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCHAT",
                "tr_subtitle": "Krystalklare forbindelser",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til videochat:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "En processor, der kan optage og dele billeder på samme tid."
                  },
                  {
                    "tr_title": "Wi‑Fi, der er hurtigt, stærkt og pålideligt."
                  },
                  {
                    "tr_title": "Et webcam, der hjælper dig med at fremstå krystalklart."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitasking med høj hastighed",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING MED HØJ HASTIGHED",
                "tr_subtitle": "Gør mere uden at sætte farten ned",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "En processor, der kan jonglere med flere programmer på én gang."
                  },
                  {
                    "tr_title": "Wi‑Fi, der er hurtigt, stærkt og pålideligt."
                  },
                  {
                    "tr_title": "Hukommelse, der problemfrit skifter mellem programmer."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videochat",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCHAT",
                "tr_subtitle": "Krystalklare forbindelser",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til videochat:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "En processor, der kan optage og dele billeder på samme tid."
                  },
                  {
                    "tr_title": "Wi‑Fi, der er hurtigt, stærkt og pålideligt."
                  },
                  {
                    "tr_title": "Et webcam, der hjælper dig med at fremstå krystalklart."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hverdags-gaming",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "HVERDAGS-GAMING",
                "tr_subtitle": "Tid til at spille",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til let gaming:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "En processor, der giver et flydende gameplay."
                  },
                  {
                    "tr_title": "Hukommelse, der kan stemmechatte og streame på samme tid."
                  },
                  {
                    "tr_title": "Grafik, der giver fantastiske billeder."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Billedredigering",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "BILLEDREDIGERING",
                "tr_subtitle": "Pixel-perfekte billeder",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til billedredigering:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "En processor med mulighed for at redigere store grupper af billeder."
                  },
                  {
                    "tr_title": "Hukommelse, der fremskynder din redigeringssoftware."
                  },
                  {
                    "tr_title": "Med Thunderbolt™ 4-teknologien kan du hurtigt overføre filer."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitasking med høj hastighed",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITASKING MED HØJ HASTIGHED",
                "tr_subtitle": "Gør mere uden at sætte farten ned",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "En processor, der kan jonglere med flere programmer på én gang."
                  },
                  {
                    "tr_title": "Wi‑Fi, der er hurtigt, stærkt og pålideligt."
                  },
                  {
                    "tr_title": "Hukommelse, der problemfrit skifter mellem programmer."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Samarbejde i realtid",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SAMARBEJDE I REALTID",
                "tr_subtitle": "Du kan arbejde hvor som helst",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til online-samarbejde:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "En processor til at drive kontorsoftware."
                  },
                  {
                    "tr_title": "Wi‑Fi, som du kan stole på."
                  },
                  {
                    "tr_title": "Et webcam, der optager i strålende HD."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videoredigering",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOREDIGERING",
                "tr_subtitle": "Lav magi i film",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til videoredigering:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "En processor, der kan håndtere store filer."
                  },
                  {
                    "tr_title": "Hukommelse, der gør redigeringssoftware hurtig og responsiv."
                  },
                  {
                    "tr_title": "Grafik, der reducerer renderingstiden."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Billedredigering",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "BILLEDREDIGERING",
                "tr_subtitle": "Pixel-perfekte billeder",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til billedredigering:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "En processor med mulighed for at redigere store grupper af billeder."
                  },
                  {
                    "tr_title": "Hukommelse, der fremskynder din redigeringssoftware."
                  },
                  {
                    "tr_title": "Med Thunderbolt™ 4-teknologien kan du hurtigt overføre filer."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Videoredigering",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOREDIGERING",
                "tr_subtitle": "Lav magi i film",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til videoredigering:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "En processor, der kan håndtere store filer."
                  },
                  {
                    "tr_title": "Hukommelse, der gør redigeringssoftware hurtig og responsiv."
                  },
                  {
                    "tr_title": "Grafik, der reducerer renderingstiden."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "3D-modellering",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D-MODELLERING",
                "tr_subtitle": "Kreationer på næste niveau",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til 3D-modellering:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "En processor, der kan håndtere avanceret kreativ software."
                  },
                  {
                    "tr_title": "Hukommelse, der hurtigt kan hente store filer."
                  },
                  {
                    "tr_title": "Grafik til kraftfuld billedfremstilling og -redigering."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Samarbejde i realtid",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SAMARBEJDE I REALTID",
                "tr_subtitle": "Du kan arbejde hvor som helst",
                "tr_subtitle1": "Her er, hvad vi fandt i denne PC, der er fantastisk til online-samarbejde:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "En processor til at drive kontorsoftware."
                  },
                  {
                    "tr_title": "Wi‑Fi, som du kan stole på."
                  },
                  {
                    "tr_title": "Et webcam, der optager i strålende HD."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Intel® Unison™ app",
    "tr_title": "Lås op for din forbundne verden",
    "tr_subtitle": "Overfør filer og fotos problemfrit, mens du administrerer telefonens meddelelser, opkald og SMS'er fra din PC.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "OVERFØR FILER OG FOTOS",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "FORETAG OG MODTAG OPKALD",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "SEND OG MODTAG SMS'ER",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "ADMINISTRER TELEFON NOTIFIKATIONER",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "LÅS OP FOR DIN FORBUNDNE VERDEN",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}