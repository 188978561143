const Library_mobile = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [{
        "iposType": "GAMING",
        "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "",
          "tileBG": "",
          "detailBGUrl": "",
          "tr_subtitle": "",
          "panelType1":"header-IconTop",
          "panelType2":"", 
          "tr_title": "Powered by an [cpuDisplay] processor",
        }
      }
      ]
      }]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [{
        "iposType": "GAMING",
        "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "",
          "tileBG": "",
          "detailBGUrl": "",
          "tr_subtitle": "",
          "panelType1":"header-IconTop",
          "panelType2":"", 
          "tr_title": "Powered by an [cpuDisplay] processor",
        }
      }
      ]
      }]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "",
          "tileBG": "",
          "detailBGUrl": "",
          "tr_subtitle": "Like a brain, your processor controls what your PC does. That means the videos you watch, the games you play, the websites you visit, all start with your processor.",
          "panelType1":"header-IconTop",
          "panelType2":"", 
          "tr_title": "The processor is the brain of your PC",
        }
      }
      ]
      }]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "",
          "tileBG": "",
          "detailBGUrl": "",
          "tr_title": "Performance and value.",
          "panelType1":"header-IconTop",
          "panelType2":"", 
          "titleArray": [{
            "tr_title": "",
            "tr_pretitle": "Intel® Pentium® Silver processor",
            "tr_subtitle": "Stay on top of your digital world with the performance to access files from the web. Simple multi-task by easily switching between programs and get more done in less time.",
            "tr_gen_core_title":"Intel® Pentium® Silver processor",
          },{
            "tr_title": "",
            "tr_pretitle": "Intel® Pentium® Gold processor",
            "tr_subtitle": "Enhanced Productivity - Elevated performance to power through your most demanding days. Streamlined Web Browsing - Quickly load media-rich web pages for more exploring and less waiting.",
            "tr_gen_core_title":"Intel® Pentium® Gold processor",
          },{
            "tr_title": "",
            "tr_pretitle": "Intel® Celeron® processor",
            "tr_subtitle": "Performance to amp up your work and entertainment. Enjoy movies and web videos in amazing detail. Spend more time doing and less time waiting.",
            "tr_gen_core_title":"Intel® Celeron® processor",
          },{
            "tr_title": "",
            "tr_pretitle": "Intel® Pentium® processor",
            "tr_subtitle": "Built for great entertainment, video streaming and productivity. Enjoy movies and web videos in amazing details. Spend more time doing and less time waiting.",
            "tr_gen_core_title":"Intel® Pentium® processor",
          }
        ],
          "badgeIcons": [{
            "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
          },
          {
            "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
          },
          {
            "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
          },
          {
            "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
          }]
        }
      }
      ]
      }]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
          "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
          "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
          "tr_subtitle": "Performance you need. Affordability you want.",
          "tr_title": "Intel® Processor",
          "panelType1":"header-IconTop",
          "panelType2":"intel-inside-image-panel", 
          "titleArray": [{
            "tr_title": "",
            "tr_pretitle": "Value in a category of its own",
            "tr_subtitle": "An Intel® Processor can handle just about any task you need at a price you can afford. Easily navigate from one software to the next. Or enjoy high-quality graphics with steady performance.",
            "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
          },{
            "tr_title": "",
            "tr_pretitle": "Make stronger connections",
            "tr_subtitle": "More vivid video quality, richer audio clarity, and nearly 3x faster internet connectivity allow you to confidently collaborate wherever you need.<sup>26</sup>",
            "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
          },{
            "tr_title": "",
            "tr_pretitle": "The difference is day and night",
            "tr_subtitle": "From morning movie marathons to last-minute touch-ups, the Intel® Processor extends your device’s battery life for truly immersive, uninterrupted experiences.",
            "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
          },{
            "tr_title": "",
            "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
            "tr_pretitle": "Every environment is a learning one",
            "tr_subtitle": "Run multiple online learning tools with ease from every corner of your home.",
          },
          {
            "tr_title": "",
            "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
            "tr_pretitle": "Learn where you want",
            "tr_subtitle": "Finish lessons wherever you need while running various e-learning tools at the same time.",
          }
        ]}
      }
      ]
      }]
    },
    {
      "contentType": "INTEL_CELERON", 
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg", //CELERON Badge
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"tableFormat",    
            "tr_subtitle": "Get the power for entertainment and productivity that fits your budget and lifestyle.",
            "tr_title": "Intel performance for any budget.",
            "tr_gen_core_title":"Intel® Celeron® processor",
            "processorCompare": {
              "tr_title": "FIND THE RIGHT LEVEL OF PERFORMANCE AND VALUE",
              "background": "",
              "table_head": [
                {
                  "tr_table_heading": "Current device",
                  "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg", // Celeron badge
                  "tr_imgCaption": "Intel® Celeron® processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl":  "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg", //pentium gold badge
                  "tr_imgCaption": "Intel® Pentium® Gold processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                  "tr_imgCaption": "Intel® Core™ i3 processor"
                }
              ],
              "tabletr": [{
                "tr_row": "Fast, media-rich web browsing",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Easily switching between programs",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Casual gaming",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Streaming 4K videos",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Light photo and video editing",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Rapid productivity with intelligent performance",
                "td1": false,
                "td2": false,
                "td3": true
              }
              ]
            }
          }
        }
        ]
      }]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/[cup_badge].svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"tableFormat", 
            "tr_subtitle": "",            
            "titleArray": [{
              "genType": "7Gen",
              "tr_title": "Powered by a 7th Generation Intel® Core™ [cpu_fam] processor",
            },{
              "genType": "6Gen",
              "tr_title": "Powered by a 6th Generation Intel® Core™ [cpu_fam] processor",
            },{
              "genType": "5Gen",
              "tr_title": "Powered by a 5th Generation Intel® Core™ [cpu_fam] processor",
            },{
              "genType": "4Gen",
              "tr_title": "Powered by a 4th Generation Intel® Core™ [cpu_fam] processor",
            },{
              "genType": "3Gen",
              "tr_title": "Powered by a 3rd Generation Intel® Core™ [cpu_fam] processor",
            },{
              "genType": "2Gen",
              "tr_title": "Powered by a 2nd Generation Intel® Core™ [cpu_fam] processor",
            },{
              "genType": "1Gen",
              "tr_title": "Powered by a 1st Generation Intel® Core™ [cpu_fam] processor",
            }],
            "processorCompare": {
              "tr_title": "",
              "background": "",
              "tr_tableHead": [
                "Recommended for:",
                "<span><img src=></span>",
                "<span><img src=></span>",
              ],
              "tabletr": [{
                "tr_row": "",
                "td1": true,
                "td2": true,
                "td3": true
              }]
            }
          }
        }]
      }]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "tr_subtitle": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_title": "Powered by an 8th Generation Intel® Core™ i3 processor",
            "tr_gen_core_title":"8th Gen Intel® Core™ i3 processor",
          }
        }
        ]
      }]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "The 9th Gen Intel® Core™ processors were created to keep up with your digital world. With their combination of speed and performance, you’ll be able to do more of what you love about the PC without the frustration.",
            "tr_title": "Powered by a 9th Generation Intel® Core™ i3 processor",
            "tr_gen_core_title":"9th Gen Intel® Core™ i3 processor",
          }
        }
        ]
      }]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"tableFormat", 
            "tr_subtitle": "Versatile performance meets ample battery life to keep you going, anywhere.",
            "tr_title": "Power through your day",
            "tr_gen_core_title":"10th Gen Intel® Core™ i3 processor",
            "processorCompare": {
              "tr_title": "FIND THE RIGHT LEVEL OF 10TH GEN PERFORMANCE",
              "background": "",
              "table_head": [
                {
                  "tr_table_heading": "Current device",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                  "tr_imgCaption": "Intel® Core™ i3 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                  "tr_imgCaption": "Intel® Core™ i5 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                  "tr_imgCaption": "Intel® Core™ i7 processor"
                }
              ],
              "tabletr": [{
                "tr_row": "Streaming movies in 4K",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Fast, reliable connectivity",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Photo masking and video filtering",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Gaming at 1080p with smooth frame rates",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Best for working with large files and creative programs",
                "td1": false,
                "td2": false,
                "td3": true
              }
              ]
            }
          }
        }, {
          "deviceType": "Desktop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "Easily go from streaming movies to creating presentations with plenty of performance to spare.",
            "tr_title": "Power through your day",
            "tr_gen_core_title":"10th Gen Intel® Core™ i3 processor",
          }
        }, {
          "deviceType": "Athena",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "For those always on the go, a laptop that is designed especially for you.",
            "tr_title": "Meet a new class of laptops",
            "tr_gen_core_title":"10th Gen Intel® Core™ i3 processor",
          }
        }
        ]
      }]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType12",
            "gamingPanleType2":"gaming-panelType1",
            "tr_subtitle": "Designed specifically for portable PCs, the 11th Gen Intel® Core™ i3 processor allows to do more than ever, wherever you are.",
            "tr_title": "Get more from your new laptop",
            "tr_gen_core_title":"11th Gen Intel® Core™ i3 processor",
            "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i3 PROCESSOR",
            "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
            "enhancementCompare": {
              "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
              "panelType2":"enhancement-compare",
              "tr_table_heading": "Current device",
              "imgUrl": "assets/images/processorCompareTickBlack.svg",
              "badgeIcons": [
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                  "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                  "tr_badgeSubTitle": ""
                },
                {
                  "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                  "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                  "tr_badgeSubTitle": ""
                },
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                  "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                  "tr_badgeSubTitle": ""
                }
              ],
            }
          }
        },
        {
          "deviceType": "Desktop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
            "tileBG": "",
            "detailBGUrl": "",            
            "panelType1":"gaming-processorType12",
            "panelType2":"",
            "tr_subtitle": "When your entire family looks to one desktop PC for everything they do, you need the power and versatility of an 11th Gen Intel® Core™ i3 processor.",
            "tr_title": "Be ready for anything with boundary-breaking performance.",
            "tr_gen_core_title":"11th Gen Intel® Core™ i3 processor",
            "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i3 PROCESSOR",
            "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png",
          }
        }
      ]
      }]
    },
    {
      "contentType": "INTEL_CORE_I5_6", 
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "tr_subtitle": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_title": "Powered by an 8th Generation Intel® Core™ i5 processor",
            "tr_gen_core_title":"8th Gen Intel® Core™ i5 processor",
          }
        }
        ]
      }]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "The 9th Gen Intel® Core™ processors were created to keep up with your digital world. With their combination of speed and performance, you’ll be able to do more of what you love about the PC without the frustration.",
            "tr_title": "Powered by a 9th Generation Intel® Core™ i5 processor",
            "tr_gen_core_title":"9th Gen Intel® Core™ i5 processor",
          }
        }
        ]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType2",
            "panelType2":"tableFormat",
            "tr_subtitle": "SMOOTH GAMEPLAY AND VR ON THE MOST DEMANDING TITLES",
            "tr_title": "Serious gaming starts here",
            "tr_gen_core_title":"9th Gen Intel® Core™ i5 processor",
            "badgeIcons": [{
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }],
            "processorCompare": {
              "tr_title": "FIND THE INTEL® CORE™ PROCESSOR FOR HOW YOU GAME.",
              "background": "",
              "table_head": [
                {
                  "tr_table_heading": "Current device",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                  "tr_imgCaption": "Intel® Core™ i5 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                  "tr_imgCaption": "Intel® Core™ i7 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "", // i9 th gen badge
                  "tr_imgCaption": "Intel® Core™ i9 processor"
                }
              ],
              "tabletr": [{
                "tr_row": "AAA Gaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Competitive gaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Game, stream and record without compromise",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Best 9th Gen Intel® Core™ processor for gaming",
                "td1": false,
                "td2": false,
                "td3": true
              }
              ],
              "table_footer": [{
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.5 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 4<br/>Threads: 8</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.1 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.3 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
              }
              ]
            }
          }
        }
        ]
      }]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"tableFormat",
            "tr_subtitle": "Easily juggle multiple programs so you’re ready for anything, wherever the day takes you.",
            "tr_title": "On-the-go multitasking",
            "tr_gen_core_title":"10th Gen Intel® Core™ i5 processor",
            "processorCompare": {
              "tr_title": "FIND THE RIGHT LEVEL OF 10TH GEN PERFORMANCE",
              "background": "",
              "table_head": [
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                  "tr_imgCaption": "Intel® Core™ i3 processor"
                },
                {
                  "tr_table_heading": "Current device",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                  "tr_imgCaption": "Intel® Core™ i5 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                  "tr_imgCaption": "Intel® Core™ i7 processor"
                }
              ],
              "tabletr": [{
                "tr_row": "Streaming movies in 4K",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Fast, reliable connectivity",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Photo masking and video filtering",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Gaming at 1080p with smooth frame rates",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Best for working with large files and creative programs",
                "td1": false,
                "td2": false,
                "td3": true
              }
              ]
            }
          }
        }, {
          "deviceType": "Desktop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",            
            "panelType2":"",
            "tr_subtitle": "Elevate everything you do from entertainment to productivity and content creation.",
            "tr_title": "Upgrade to a more powerful PC",
            "tr_gen_core_title":"10th Gen Intel® Core™ i5 processor",
          }
        }, {
          "deviceType": "Athena",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "For those always on the go, a laptop that is designed especially for you.",
            "tr_title": "Meet a new class of laptops",
            "tr_gen_core_title":"10th Gen Intel® Core™ i5 processor",
          }
        }
        ]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
            "tileBG": "",
            "detailBGUrl": "",            
            "panelType1":"gaming-processorType2",
            "panelType2":"tableFormat",
            "tr_subtitle": "Built for seamless streaming and AAA Gaming",
            "tr_title": "Play on your terms",
            "tr_gen_core_title":"10th Gen Intel® Core™ i5 processor",
            "badgeIcons": [{
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }],
            "processorCompare": {
              "tr_title": "FIND THE 10TH GEN INTEL® CORE™ PROCESSOR FOR HOW YOU GAME.",
              "background": "",
              "table_head": [
                {
                  "tr_table_heading": "Current device",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                  "tr_imgCaption": "Intel® Core™ i5 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                  "tr_imgCaption": "Intel® Core™ i7 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg", // i9 10th gen badge
                  "tr_imgCaption": "Intel® Core™ i9 processor"
                }
              ],
              "tabletr": [{
                "tr_row": "Seamless AAA Gaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Streaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Competitive gaming",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Streaming and recording",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Advanced content creation",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Peak gaming performance",
                "td1": false,
                "td2": false,
                "td3": true
              }, {
                "tr_row": "Unlocked and overclockable<sup>2</sup>",
                "td1": false,
                "td2": false,
                "td3": true
              }
              ],
              "table_footer": [{
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.5 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.1 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.3 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
              }
              ]
            }
          }
        },
        {
          "deviceType": "Desktop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
            "tileBG": "",
            "detailBGUrl": "",            
            "panelType1":"gaming-processorType2",
            "panelType2":"tableFormat",
            "tr_subtitle": "Built for seamless streaming and AAA Gaming",
            "tr_title": "Play on your terms",
            "tr_gen_core_title":"10th Gen Intel® Core™ i5 processor",
            "badgeIcons": [{
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }],
            "processorCompare": {
              "tr_title": "FIND THE 10TH GEN INTEL® CORE™ PROCESSOR FOR HOW YOU GAME.",
              "background": "",
              "table_head": [
                {
                  "tr_table_heading": "Current device",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                  "tr_imgCaption": "Intel® Core™ i5 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                  "tr_imgCaption": "Intel® Core™ i7 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg", // i9 10th gen badge
                  "tr_imgCaption": "Intel® Core™ i9 processor"
                }
              ],
              "tabletr": [{
                "tr_row": "Seamless AAA Gaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Streaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Competitive gaming",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Streaming and recording",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Advanced content creation",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Peak gaming performance",
                "td1": false,
                "td2": false,
                "td3": true
              }, {
                "tr_row": "Unlocked and overclockable<sup>2</sup>",
                "td1": true,
                "td2": true,
                "td3": true
              }
              ],
              "table_footer": [{
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.8 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.1 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.3 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 10<br/>Threads: 20</span>",
              }
              ]
            }
          }
        }
        ]
      },
      {
        "iposType": "Creator",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",            
            "panelType2":"",
            "tr_subtitle": "Easily juggle multiple programs so you’re ready for anything, wherever the day takes you.",
            "tr_title": "On-the-go multitasking",
            "tr_gen_core_title":"10th Gen Intel® Core™ i5 processor",
            "tr_gen_core_title_uppercase":"10TH GEN INTEL® CORE™ i5 PROCESSOR",
          }
        }]
      }]
    },
    {
      "contentType": "INTEL_CORE_I5_9", 
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType12",
            "gamingPanleType2":"gaming-panelType1",
            "tr_subtitle": "The 11th Gen Intel® Core™ i5 processor brings vibrant visual experiences and wide-ranging productivity to the thinnest and lightest PCs.",
            "tr_title": "Impressive laptop power",
            "tr_gen_core_title":"11th Gen Intel® Core™ i5 processor",
            "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i5 PROCESSOR",
            "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
            "enhancementCompare": {
              "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
              "panelType2":"enhancement-compare",
              "tr_table_heading": "Current device",
              "imgUrl": "assets/images/processorCompareTickBlack.svg",
              "badgeIcons": [
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                  "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                  "tr_badgeSubTitle": ""
                },
                {
                  "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                  "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                  "tr_badgeSubTitle": ""
                },
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                  "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                  "tr_badgeSubTitle": ""
                }
              ],
            }
          }
        }, {
          "deviceType": "Desktop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
            "tileBG": "",
            "detailBGUrl": "",            
            "panelType1":"gaming-processorType12",
            "panelType2":"",
            "tr_subtitle": "When your entire family looks to one desktop PC for everything they do, you need the power and versatility of an 11th Gen Intel® Core™ i5 processor.",
            "tr_title": "Be ready for anything with boundary-breaking performance.",
            "tr_gen_core_title":"11th Gen Intel® Core™ i5 processor",
            "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i5 PROCESSOR",
            "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png",
          }
        }
        ]
      },
      {
        "iposType": "Creator",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
            "tileBG": "",
            "detailBGUrl": "",            
            "panelType1":"header-IconTop",
            "panelType2":"",
            "tr_subtitle": "The 11th Gen Intel® Core™ i5 processor brings vibrant visual experiences and wide-ranging productivity to the thin & light laptops.",
            "tr_title": "Impressive laptop power",
            "tr_gen_core_title":"11th Gen Intel® Core™ i5 processor",
            "tr_gen_core_title_uppercase":"11TH GEN INTEL® CORE™ i5 PROCESSOR"
          }
        }]
      }]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
            "tileBG": "",
            "panelType1":"header-IconTop",
            "panelType2":"",
            "detailBGUrl": "",
            "tr_subtitle": "",
            "tr_title": "Powered by an 8th Generation Intel® Core™ i7 processor",
            "tr_gen_core_title":"8th Gen Intel® Core™ i7 processor",
          }
        }
        ]
      }]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
            "tileBG": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "detailBGUrl": "",
            "tr_subtitle": "The 9th Gen Intel® Core™ processors were created to keep up with your digital world. With their combination of speed and performance, you’ll be able to do more of what you love about the PC without the frustration.",
            "tr_title": "Powered by a 9th Generation Intel® Core™ i7 processor",
            "tr_gen_core_title":"9th Gen Intel® Core™ i7 processor",
          }
        }
        ]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType2",
            "panelType2":"tableFormat", 
            "tr_subtitle": "UP TO 32% MORE FPS while gaming, streaming and recording vs. a 3-YEAR-OLD-PC<sup>3</sup>",
            "tr_title": "The power to share your greatest gaming moments",
            "tr_gen_core_title":"9th Gen Intel® Core™ i7 processor",
            "badgeIcons": [{
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }],
            "processorCompare": {
              "tr_title": "FIND THE INTEL® CORE™ PROCESSOR FOR HOW YOU GAME.",
              "background": "",
              "table_head": [
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                  "tr_imgCaption": "Intel® Core™ i5 processor"
                },
                {
                  "tr_table_heading": "Current device",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                  "tr_imgCaption": "Intel® Core™ i7 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg", // i9 th gen badge
                  "tr_imgCaption": "Intel® Core™ i9 processor"
                }
              ],
              "tabletr": [{
                "tr_row": "AAA Gaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Competitive gaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Game, stream and record without compromise",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Best 9th Gen Intel® Core™ processor for gaming",
                "td1": false,
                "td2": false,
                "td3": true
              }
              ],
              "table_footer": [{
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.5 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 4<br/>Threads: 8</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.1 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.3 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
              }
              ]
            }
          }
        }
        ]
      }]
    },
    {
      "contentType": "INTEL_CORE_I7_8", 
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"tableFormat", 
            "tr_subtitle": "Engineered for efficiency, this sleek device can have a huge impact on the way you create, connect and stream.",
            "tr_title": "Advanced performance anywhere",
            "tr_gen_core_title":"10th Gen Intel® Core™ i7 processor",
            "processorCompare": {
              "tr_title": "FIND THE RIGHT LEVEL OF 10TH GEN PERFORMANCE",
              "background": "",
              "table_head": [
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                  "tr_imgCaption": "Intel® Core™ i3 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                  "tr_imgCaption": "Intel® Core™ i5 processor"
                },
                {
                  "tr_table_heading": "Current device",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                  "tr_imgCaption": "Intel® Core™ i7 processor"
                }
              ],
              "tabletr": [{
                "tr_row": "Streaming movies in 4K",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Fast, reliable connectivity",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Photo masking and video filtering",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Gaming at 1080p with smooth frame rates",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Best for working with large files and creative programs",
                "td1": false,
                "td2": false,
                "td3": true
              }
              ]
            }
          }
        }, {
          "deviceType": "Desktop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "Advanced performance to take everything you do to the next level.",
            "tr_title": "Power to do it all",
            "tr_gen_core_title":"10th Gen Intel® Core™ i7 processor",
          }
        }, {
          "deviceType": "Athena",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "For those always on the go, a laptop that is designed especially for you.",
            "tr_title": "Meet a new class of laptops",
            "tr_gen_core_title":"10th Gen Intel® Core™ i7 processor",
          }
        }
        ]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType2",
            "panelType2":"tableFormat", 
            "tr_subtitle": "Game, stream and record with a competitive edge",
            "tr_title": "Power your gaming world",
            "tr_gen_core_title":"10th Gen Intel® Core™ i7 processor",
            "badgeIcons": [{
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }],
            "processorCompare": {
              "tr_title": "FIND THE 10TH GEN INTEL® CORE™ PROCESSOR FOR HOW YOU GAME.",
              "background": "",
              "table_head": [
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                  "tr_imgCaption": "Intel® Core™ i5 processor"
                },
                {
                  "tr_table_heading": "Current device",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                  "tr_imgCaption": "Intel® Core™ i7 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg", // i9 10th gen badge
                  "tr_imgCaption": "Intel® Core™ i9 processor"
                }
              ],
              "tabletr": [{
                "tr_row": "Seamless AAA Gaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Streaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Competitive gaming",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Streaming and recording",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Advanced content creation",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Peak gaming performance",
                "td1": false,
                "td2": false,
                "td3": true
              }, {
                "tr_row": "Unlocked and overclockable<sup>2</sup>",
                "td1": false,
                "td2": false,
                "td3": true
              }
              ],
              "table_footer": [{
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.5 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.1 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.3 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
              }
              ]
            }
          }
        },
        {
          "deviceType": "Desktop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType2",
            "panelType2":"tableFormat", 
            "tr_subtitle": "Game, stream and record with a competitive edge",
            "tr_title": "Power your gaming world",
            "tr_gen_core_title":"10th Gen Intel® Core™ i7 processor",
            "badgeIcons": [{
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }],
            "processorCompare": {
              "tr_title": "FIND THE 10TH GEN INTEL® CORE™ PROCESSOR FOR HOW YOU GAME.",
              "background": "",
              "table_head": [
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                  "tr_imgCaption": "Intel® Core™ i5 processor"
                },
                {
                  "tr_table_heading": "Current device",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                  "tr_imgCaption": "Intel® Core™ i7 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg", // i9 10th gen badge
                  "tr_imgCaption": "Intel® Core™ i9 processor"
                }
              ],
              "tabletr": [{
                "tr_row": "Seamless AAA Gaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Streaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Competitive gaming",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Streaming and recording",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Advanced content creation",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Peak gaming performance",
                "td1": false,
                "td2": false,
                "td3": true
              }, {
                "tr_row": "Unlocked and overclockable<sup>2</sup>",
                "td1": true,
                "td2": true,
                "td3": true
              }
              ],
              "table_footer": [{
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.8 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.1 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.3 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 10<br/>Threads: 20</span>",
              }
              ]
            }
          }
        }
        ]
      },
      {
        "iposType": "Creator",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "Designed for efficiency, this sleek device can have a huge impact on the way you create, connect and stream.",
            "tr_title": "Premium performance anywhere",
            "tr_gen_core_title":"10th Gen Intel® Core™ i7 processor",
            "tr_gen_core_title_uppercase":"10TH GEN INTEL® CORE™ i7 PROCESSOR",
          }
        }]
      }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9", 
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType12",
            "gamingPanleType2":"gaming-panelType1",
            "tr_subtitle": "The 11th Gen Intel® Core™ i7 processor brings advanced content creation, smooth gaming and next-level entertainment to ultra-portable PCs.",
            "tr_title": "Boundary-breaking laptop performance",
            "tr_gen_core_title":"11th Gen Intel® Core™ i7 processor",
            "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i7 PROCESSOR",
            "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
            "enhancementCompare": {
              "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
              "panelType2":"enhancement-compare",
              "tr_table_heading": "Current device",
              "imgUrl": "assets/images/processorCompareTickBlack.svg",
              "badgeIcons": [
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                  "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                  "tr_badgeSubTitle": ""
                },
                {
                  "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                  "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                  "tr_badgeSubTitle": ""
                },
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                  "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                  "tr_badgeSubTitle": ""
                }
              ],
            }
          }
        }, {
          "deviceType": "Desktop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType12",
            "panelType2":"", 
            "tr_subtitle": "Make your desktop PC the go-to destination for anything your home might need with the boundary-breaking performance of an 11th Gen Intel® Core™ i7 processor.",
            "tr_title": "Work. Learn. Play. Without boundaries.",
            "tr_gen_core_title":"11th Gen Intel® Core™ i7 processor",
            "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i7 PROCESSOR",
            "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png",
          }
        }
        ]
      },
      {
        "iposType": "Creator",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "The 11th Gen Intel® Core™ i7 processor brings advanced content creation, smooth gaming and next-level entertainment to thin & light laptops.",
            "tr_title": "Boundary-breaking laptop performance",
            "tr_gen_core_title":"11th Gen Intel® Core™ i7 processor",
            "tr_gen_core_title_uppercase":"11TH GEN INTEL® CORE™ i7 PROCESSOR",
          }
        },
        {
          "deviceType": "Desktop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "Make your desktop PC the go-to destination for anything your home might need with the boundary-breaking performance of an 11th Gen Intel® Core™ i7 processor.",
            "tr_title": "Create without boundaries",
            "tr_gen_core_title": "11th Gen Intel® Core™ i7 processor",
            "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i7 PROCESSOR"
          }
        }
        ]
      }]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7", 
      "iposTypes": [{
        "iposType": "Creator",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "With advanced content creation, smooth gaming, and next-level entertainment on thin & light laptops, this 11th Gen Intel® Core™ i7 processor just got better.",
            "tr_title": "Boundary-breaking laptop performance",
            "tr_gen_core_title":"11th Gen Intel® Core™ i7 processor",
            "tr_gen_core_title_uppercase":"11TH GEN INTEL® CORE™ i7 PROCESSOR",
          }
        }
        ]
      }]
    },
    {
      "contentType": "INTEL_CORE_I9_6", 
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "", 
            "tileBG": "",
            "detailBGUrl": "",
            "tr_subtitle": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_title": "Powered by an 8th Generation Intel® Core™ i9 processor",
            "tr_gen_core_title":"8th Gen Intel® Core™ i9 processor",
          }
        }
        ]
      }]
    }, {
      "contentType": "INTEL_CORE_I9_7", 
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "The 9th Gen Intel® Core™ processors were created to keep up with your digital world. With their combination of speed and performance, you’ll be able to do more of what you love about the PC without the frustration.",
            "tr_title": "Powered by a 9th Generation Intel® Core™ i9 processor",
            "tr_gen_core_title":"9th Gen Intel® Core™ i9 processor",
          }
        }
        ]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "", //i9 9th gen badge
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType2",
            "panelType2":"tableFormat", 
            "tr_subtitle": "UP TO 41% MORE FPS while gaming, streaming and recording vs. a 3-YEAR-OLD-PC<sup>4</sup>",
            "tr_title": "Gamers demand. Intel® Core™ i9 delivers.",
            "tr_gen_core_title":"9th Gen Intel® Core™ i9 processor",
            "badgeIcons": [{
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }],
            "processorCompare": {
              "tr_title": "FIND THE INTEL® CORE™ PROCESSOR FOR HOW YOU GAME.",
              "background": "",
              "table_head": [
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                  "tr_imgCaption": "Intel® Core™ i5 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                  "tr_imgCaption": "Intel® Core™ i7 processor"
                },
                {
                  "tr_table_heading": "Current device",
                  "imgUrl": "", // i9 th gen badge
                  "tr_imgCaption": "Intel® Core™ i9 processor"
                }
              ],
              "tabletr": [{
                "tr_row": "AAA Gaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Competitive gaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Game, stream and record without compromise",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Best 9th Gen Intel® Core™ processor for gaming",
                "td1": false,
                "td2": false,
                "td3": true
              }
              ],
              "table_footer": [{
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.5 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 4<br/>Threads: 8</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.1 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.3 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
              }
              ]
            }
          }
        }
        ]
      }]
    }, {
      "contentType": "INTEL_CORE_I9_8", 
      "iposTypes": [{
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType2",
            "panelType2":"tableFormat", 
            "tr_subtitle": "Push your potential with the ultimate gaming experience",
            "tr_title": "Compete at the highest levels",
            "tr_gen_core_title":"10th Gen Intel® Core™ i9 processor",
            "badgeIcons": [{
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }],
            "processorCompare": {
              "tr_title": "FIND THE 10TH GEN INTEL® CORE™ PROCESSOR FOR HOW YOU GAME.",
              "background": "",
              "table_head": [
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                  "tr_imgCaption": "Intel® Core™ i5 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                  "tr_imgCaption": "Intel® Core™ i7 processor"
                },
                {
                  "tr_table_heading": "Current device",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg", // i9 10th gen badge
                  "tr_imgCaption": "Intel® Core™ i9 processor"
                }
              ],
              "tabletr": [{
                "tr_row": "Seamless AAA Gaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Streaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Competitive gaming",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Streaming and recording",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Advanced content creation",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Peak gaming performance",
                "td1": false,
                "td2": false,
                "td3": true
              }, {
                "tr_row": "Unlocked and overclockable<sup>2</sup>",
                "td1": false,
                "td2": false,
                "td3": true
              }
              ],
              "table_footer": [{
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.5 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.1 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.3 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
              }
              ]
            }
          }
        },
        {
          "deviceType": "Desktop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType2",
            "panelType2":"tableFormat", 
            "tr_subtitle": "Push your potential with the ultimate gaming experience",
            "tr_title": "Compete at the highest levels",
            "tr_gen_core_title":"10th Gen Intel® Core™ i9 processor",
            "badgeIcons": [{
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }],
            "processorCompare": {
              "tr_title": "FIND THE 10TH GEN INTEL® CORE™ PROCESSOR FOR HOW YOU GAME.",
              "background": "",
              "table_head": [
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                  "tr_imgCaption": "Intel® Core™ i5 processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                  "tr_imgCaption": "Intel® Core™ i7 processor"
                },
                {
                  "tr_table_heading": "Current device",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg", // i9 10th gen badge
                  "tr_imgCaption": "Intel® Core™ i9 processor"
                }
              ],
              "tabletr": [{
                "tr_row": "Seamless AAA Gaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Streaming",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Competitive gaming",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Streaming and recording",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Advanced content creation",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Peak gaming performance",
                "td1": false,
                "td2": false,
                "td3": true
              }, {
                "tr_row": "Unlocked and overclockable<sup>2</sup>",
                "td1": true,
                "td2": true,
                "td3": true
              }
              ],
              "table_footer": [{
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.8 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.1 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
              },
              {
                "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.3 GHz</span>",
                "tr_subTitle": "<span class='small_font'>Cores: 10<br/>Threads: 20</span>",
              }
              ]
            }
          }
        }
        ]
      },
      {
        "iposType": "Creator",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType2",
            "panelType2":"", 
            "tr_subtitle": "Push your potential with the ultimate creator experience",
            "tr_title": "Create at the highest levels",
            "tr_gen_core_title":"10th Gen Intel® Core™ i9 processor",
            "tr_gen_core_title_uppercase":"10TH GEN INTEL® CORE™ i9 PROCESSOR",
            "badgeIcons": [{
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }]
          }
        }]
      }]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/EVO_I5_5.svg", //Evo I5 badge
            "tileBG": "",
            "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "Intel® Evo™ laptops powered by an 11th Gen Intel® Core™ i5 processor and Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics give you incredible speed and performance, all in a thin and light design.",
            "tr_title": "Elevated power for an evolved laptop",
            "tr_gen_core_title":"11th Gen Intel® Core™ i5 processor",
            "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i5 PROCESSOR",
            "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
          }
        }]
      }]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/EVO_I7.svg", // Evo I7 badge
            "tileBG": "",
            "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "Intel® Evo™ laptops powered by an 11th Gen Intel® Core™ i7 processor and Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics give you advanced speed and performance for a premium mobile experience.",
            "tr_title": "Advanced power for an evolved laptop",
            "tr_gen_core_title":"11th Gen Intel® Core™ i7 processor",
            "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i7 PROCESSOR",
            "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
          }
        }]
      }]
    },
    {
      "contentType": "PENTIUM_SILVER", 
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg", //PENTIUM_SILVER Badge
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"tableFormat", 
            "tr_subtitle": "Enjoy a great balance of performance, media and connectivity experiences at an amazing value.",
            "tr_title": "Performance and connectivity at an amazing value.",
            "tr_gen_core_title": "Intel® Pentium® Silver processor",
            "processorCompare": {
              "tr_title": "FIND THE RIGHT LEVEL OF PERFORMANCE AND VALUE",
              "background": "",
              "table_head": [
                {
                  "tr_table_heading": "Current device",
                  "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg", // PENTIUM_SILVER badge
                  "tr_imgCaption": "Intel® Pentium® Silver processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg", //pentium gold badge
                  "tr_imgCaption": "Intel® Pentium® Gold processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",       
                  "tr_imgCaption": "Intel® Core™ i3 processor"
                }
              ],
              "tabletr": [{
                "tr_row": "Fast, media-rich web browsing",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Easily switching between programs",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Casual gaming",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Streaming 4K videos",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Light photo and video editing",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Rapid productivity with intelligent performance",
                "td1": false,
                "td2": false,
                "td3": true
              }
              ]
            }
          }
        }
        ]
      }]
    },
    {
      "contentType": "PENTIUM_GOLD", 
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg", //PENTIUM_GOLD Badge
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"tableFormat", 
            "tr_subtitle": "Enjoy seamless multitasking, simple photo and video editing, 4K videos and vivid graphics at an unbelievable value.",
            "tr_title": "Impressive performance for work and play.",
            "tr_gen_core_title":"Intel® Pentium® Gold processor",
            "processorCompare": {
              "tr_title": "FIND THE RIGHT LEVEL OF PERFORMANCE AND VALUE",
              "background": "",
              "table_head": [
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg", // PENTIUM_SILVER badge
                  "tr_imgCaption": "Intel® Pentium® Silver processor"
                },
                {
                  "tr_table_heading": "Current device",
                  "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg", //PENTIUM_GOLD badge
                  "tr_imgCaption": "Intel® Pentium® Gold processor"
                },
                {
                  "tr_table_heading": "",
                  "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",       
                  "tr_imgCaption": "Intel® Core™ i3 processor"
                }
              ],
              "tabletr": [{
                "tr_row": "Fast, media-rich web browsing",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Easily switching between programs",
                "td1": true,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Casual gaming",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Streaming 4K videos",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Light photo and video editing",
                "td1": false,
                "td2": true,
                "td3": true
              }, {
                "tr_row": "Rapid productivity with intelligent performance",
                "td1": false,
                "td2": false,
                "td3": true
              }
              ]
            }
          }
        }
        ]
      }]
    }, {
      "contentType": "INTEL_CORE_I5_H35", // H35 i5 11gen type
      "iposTypes": [{
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
            "tileBG": "",
            "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
            "panelType1":"gaming-processorType2",
            "panelType2":"tableFormat2", 
            "gamingPanleType2":"gaming-panelType1",
            "tr_subtitle": "AAA gaming and long battery life in an ultraportable PC. No need to compromise the way you play.",
            "tr_title": "No need to compromise.",
            "tr_gen_core_title":"11th Gen Intel® Core™ i5 processor",
            "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
            "badgeIcons": [
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }],
            "enhancementCompare": {
              "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
              "panelType2":"enhancement-compare",
              "tr_table_heading": "Current device",
              "imgUrl": "assets/images/processorCompareTick.svg",
              "badgeIcons": [
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg", 
                  "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                  "tr_badgeSubTitle": "",
                  "active": true
                },
                {
                  "imgUrl":  "assets/images/processor/INTEL_CORE_I7_9.svg", 
                  "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                  "tr_badgeSubTitle": "",
                  "active": false
                },
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",       
                  "tr_badgeIconTile" : "Intel® Core™ i9 processor",
                  "tr_badgeSubTitle": "",
                  "active": false
                }
              ],
            },
            "processorCompare": {
              "tr_title": "FIND YOUR INTEL® CORE™ ULTRAPORTABLE GAMING PROCESSOR.",
              "background": "",
              "table_head": [
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg", //INTEL_CORE_I5_H35
                  "tr_imgCaption": "11th Gen Intel® Core™ i5-11300H processor"
                },
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg", //INTEL_CORE_I7_H35
                  "tr_imgCaption": "11th Gen Intel® Core™ i7-11370H processor"
                },
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg", // INTEL_CORE_I7_SPECIAL_EDITION_H35
                  "tr_imgCaption": "11th Gen Intel® Core™ i7-11375H Special edition processor"
                }
              ],
              "tabletr": [{
                "tr_td1_title": "Up to <span class='style_badge'>4.4 GHz</span> max clock speed",
                "tr_td2_title": "Up to <span class='style_badge'>4.8 GHz</span> max clock speed",
                "tr_td3_title": "Up to <span class='style_badge'>5.0 GHz</span> max clock speed"
              }, {
                "tr_td1_title": "4 cores/8 threads",
                "tr_td2_title": "4 cores/8 threads",
                "tr_td3_title": "4 cores/8 threads"
              }, {
                "tr_td1_title": "Intel® Turbo Boost Technology",
                "tr_td2_title": "Intel® Turbo Boost Technology",
                "tr_td3_title": "Intel® Turbo Boost Max Technology 3.0"
              }, {
                "tr_td1_title": "8 MB Intel® Smart Cache",
                "tr_td2_title": "12 MB Intel® Smart Cache",
                "tr_td3_title": "12 MB Intel® Smart Cache"
              }, {
                "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
                "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
                "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics"
              }
              ]
            }
          }
        }
        ]
      }]
    }, {
      "contentType": "INTEL_CORE_I7_H35", // H35 i7 11gen type
      "iposTypes": [{
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
            "tileBG": "",
            "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
            "panelType1":"gaming-processorType2",
            "panelType2":"tableFormat2", 
            "gamingPanleType2":"gaming-panelType1", 
            "tr_subtitle": "1080p AAA gaming with high FPS on high settings and excellent unplugged performance in an ultraportable PC.",
            "tr_title": "Go ahead. Have it all.",
            "tr_gen_core_title":"11th Gen Intel® Core™ i7 processor",
            "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
            "badgeIcons": [
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }],
            "enhancementCompare": {
              "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
              "panelType2":"enhancement-compare",
              "tr_table_heading": "Current device",
              "imgUrl": "assets/images/processorCompareTick.svg",
              "badgeIcons": [
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg", 
                  "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                  "tr_badgeSubTitle": "",
                  "active": false
                },
                {
                  "imgUrl":  "assets/images/processor/INTEL_CORE_I7_9.svg", 
                  "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                  "tr_badgeSubTitle": "",
                  "active": true
                },
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",       
                  "tr_badgeIconTile" : "Intel® Core™ i9 processor",
                  "tr_badgeSubTitle": "",
                  "active": false
                }
              ],
            },
            "processorCompare": {
              "tr_title": "FIND YOUR INTEL® CORE™ ULTRAPORTABLE GAMING PROCESSOR.",
              "background": "",
              "table_head": [
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                  "tr_imgCaption": "11th Gen Intel® Core™ i5-11300H processor"
                },
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                  "tr_imgCaption": "11th Gen Intel® Core™ i7-11370H processor"
                },
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg", // intel_core 17 11th gen special edition badge
                  "tr_imgCaption": "11th Gen Intel® Core™ i7-11375H Special edition processor"
                }
              ],
              "tabletr": [{
                "tr_td1_title": "Up to <span class='style_badge'>4.4 GHz</span> max clock speed",
                "tr_td2_title": "Up to <span class='style_badge'>4.8 GHz</span> max clock speed",
                "tr_td3_title": "Up to <span class='style_badge'>5.0 GHz</span> max clock speed"
              }, {
                "tr_td1_title": "4 cores/8 threads",
                "tr_td2_title": "4 cores/8 threads",
                "tr_td3_title": "4 cores/8 threads"
              }, {
                "tr_td1_title": "Intel® Turbo Boost Technology",
                "tr_td2_title": "Intel® Turbo Boost Technology",
                "tr_td3_title": "Intel® Turbo Boost Max Technology 3.0"
              }, {
                "tr_td1_title": "8 MB Intel® Smart Cache",
                "tr_td2_title": "12 MB Intel® Smart Cache",
                "tr_td3_title": "12 MB Intel® Smart Cache"
              }, {
                "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
                "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
                "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics"
              }
              ]
            }
          }
        }
        ]
      }]
    }, {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35", // H35 i7 11gen special edition type
      "iposTypes": [{
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
            "tileBG": "",
            "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
            "panelType1":"gaming-processorType2",
            "panelType2":"tableFormat2", 
            "gamingPanleType2":"gaming-panelType1", 
            "tr_subtitle": "Up to 5.0 GHz max clock speed to push your games further and an ultraportable PC to take them wherever you go.",
            "tr_title": "More power per pound.",
            "tr_gen_core_title":"11th Gen Intel® Core™ i7 Special Edition processor",
            "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
            "badgeIcons": [
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }],
            "enhancementCompare": {
              "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
              "panelType2":"enhancement-compare",
              "tr_table_heading": "Current device",
              "imgUrl": "assets/images/processorCompareTick.svg",
              "badgeIcons": [
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg", 
                  "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                  "tr_badgeSubTitle": "",
                  "active": false
                },
                {
                  "imgUrl":  "assets/images/processor/INTEL_CORE_I7_9.svg", 
                  "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                  "tr_badgeSubTitle": "",
                  "active": false
                },
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",       
                  "tr_badgeIconTile" : "Intel® Core™ i7 Special Edition processor",
                  "tr_badgeSubTitle": "",
                  "active": true
                }
              ],
            },
            "processorCompare": {
              "tr_title": "FIND YOUR INTEL® CORE™ ULTRAPORTABLE GAMING PROCESSOR.",
              "background": "",
              "table_head": [
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                  "tr_imgCaption": "11th Gen Intel® Core™ i5-11300H processor"
                },
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                  "tr_imgCaption": "11th Gen Intel® Core™ i7-11370H processor"
                },
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg", // intel_core 17 11th gen special edition badge
                  "tr_imgCaption": "11th Gen Intel® Core™ i7-11375H Special edition processor"
                }
              ],
              "tabletr": [{
                "tr_td1_title": "Up to <span class='style_badge'>4.4 GHz</span> max clock speed",
                "tr_td2_title": "Up to <span class='style_badge'>4.8 GHz</span> max clock speed",
                "tr_td3_title": "Up to <span class='style_badge'>5.0 GHz</span> max clock speed"
              }, {
                "tr_td1_title": "4 cores/8 threads",
                "tr_td2_title": "4 cores/8 threads",
                "tr_td3_title": "4 cores/8 threads"
              }, {
                "tr_td1_title": "Intel® Turbo Boost Technology",
                "tr_td2_title": "Intel® Turbo Boost Technology",
                "tr_td3_title": "Intel® Turbo Boost Max Technology 3.0"
              }, {
                "tr_td1_title": "8 MB Intel® Smart Cache",
                "tr_td2_title": "12 MB Intel® Smart Cache",
                "tr_td3_title": "12 MB Intel® Smart Cache"
              }, {
                "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
                "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
                "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics"
              }
              ]
            }
          }
        }
        ]
      },
        {
          "iposType": "Creator",
          "deviceTypes": [{
            "deviceType": "Laptop",
            "details": {
              "tr_tileTitle": "Processor",
              "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
              "tileBG": "",
              "detailBGUrl": "",
              "panelType1": "header-IconTop",
              "panelType2": "",
              "tr_subtitle": "The 11th Gen Intel® Core™ i7 Special Edition processor brings advanced content creation, smooth gaming and next-level entertainment to ultra&#8209;portable PCs.",
              "tr_title": "Boundary-breaking laptop performance",
              "tr_gen_core_title":"11th Gen Intel® Core™ i7 Special Edition processor",
              "tr_gen_core_title_uppercase":"11TH GEN INTEL® CORE™ i7 SPECIAL EDITION PROCESSOR"
            }
          }]
      },
      {
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "Laptop",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType12",
            "gamingPanleType2":"gaming-panelType1",
            "tr_subtitle": "The 11th Gen Intel® Core™ i7 Special Edition processor brings advanced content creation, smooth gaming and next-level entertainment to ultra&#8209;portable PCs.",
            "tr_title": "Boundary-breaking laptop performance",
            "tr_gen_core_title":"11th Gen Intel® Core™ i7 Special Edition processor",
            "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i7 SPECIAL EDITION PROCESSOR",
            "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
            "enhancementCompare": {
              "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
              "panelType2":"enhancement-compare",
              "tr_table_heading": "Current device",
              "imgUrl": "assets/images/processorCompareTickBlack.svg",
              "badgeIcons": [
                {
                  "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                  "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                  "tr_badgeSubTitle": ""
                },
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                  "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                  "tr_badgeSubTitle": ""
                },
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",       
                  "tr_badgeIconTile" : "Intel® Core™ i7 Special Edition processor",
                  "tr_badgeSubTitle": ""
                }
              ],
            }
          }
        }
        ]
      }]
    }, {
      "contentType": "INTEL_CORE_I9_H35", 
      "iposTypes": [{
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl":  "assets/images/processor/INTEL_CORE_I9_9.svg", //i9 11th gen badge
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType2",
            "panelType2":"", 
            "gamingPanleType2":"gaming-panelType1",
            "tr_subtitle": "Fast clock speeds and high core counts work seamlessly together to get more out of competitive gaming and complex content creation.",
            "tr_title": "Massive power. Perfectly balanced.",
            "tr_gen_core_title":"11th Gen Intel® Core™ i9 processor",
            "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
            "badgeIcons": [
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }],
            "enhancementCompare": {
              "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
              "panelType2":"enhancement-compare",
              "tr_table_heading": "Current device",
              "imgUrl": "assets/images/processorCompareTick.svg",
              "badgeIcons": [
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg", 
                  "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                  "tr_badgeSubTitle": "",
                  "active": false
                },
                {
                  "imgUrl":  "assets/images/processor/INTEL_CORE_I7_9.svg", 
                  "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                  "tr_badgeSubTitle": "",
                  "active": false
                },
                {
                  "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",       
                  "tr_badgeIconTile" : "Intel® Core™ i9 processor",
                  "tr_badgeSubTitle": "",
                  "active": true
                }
              ],
            },
          }
        }
        ]
      }]
    }, {
      "contentType": "INTEL_CORE_I5_RKL", // RKL i5 11gen type
      "iposTypes": [{
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
            "tileBG": "",
            "panelType1":"gaming-processorType2",
            "panelType2":"", 
            "detailBGUrl": "",
            "tr_subtitle": "The right balance of clock speed and core performance gets more from your games, whether for competitive play with high FPS or immersive AAA experiences.",
            "tr_title": "Power with a purpose.",
            "tr_gen_core_title":"11th Gen Intel® Core™ i5 processor",
            "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
            "badgeIcons": [
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }]
          }
        }
        ]
      }]
    }, {
      "contentType": "INTEL_CORE_I7_RKL", // RKL i7 11gen type
      "iposTypes": [{
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType2",
            "panelType2":"", 
            "tr_subtitle": "Play, record and stream on demanding titles with high FPS and effortlessly switch to heavy multitasking workloads.",
            "tr_title": "Power to push the limits.",
            "tr_gen_core_title":"11th Gen Intel® Core™ i7 processor",
            "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
            "badgeIcons": [
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }]
          }
        }
        ]
      }]
    }, {
      "contentType": "INTEL_CORE_I9_RKL", // RKL i9 11gen type
      "iposTypes": [{
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl":  "assets/images/processor/INTEL_CORE_I9_9.svg", //i9 11th gen badge
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"gaming-processorType2",
            "panelType2":"", 
            "tr_subtitle": "Fast clock speeds and high core counts work seamlessly together to get more out of competitive gaming and complex content creation.",
            "tr_title": "Massive power. Perfectly balanced.",
            "tr_gen_core_title":"11th Gen Intel® Core™ i9 processor",
            "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
            "badgeIcons": [
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }]
          }
        }
        ]
      }]
    },
    {
    "contentType": "INTEL_CORE_I3_HYBRID",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "Desktop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "",
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"header-IconTop",
          "panelType2":"tableFormat", 
          "tr_subtitle": "Easily go from streaming movies to creating presentations with plenty of performance to spare.",
          "tr_title": "Power through your day",
          "tr_gen_core_title":"Intel® Core™ i3 processor with Intel® Hybrid Technology",
          "tr_subTitle2": "Intel® Core™ i3 processor with Intel® Hybrid Technology",
        }
      },
      {
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "",
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"header-IconTop",
          "panelType2":"tableFormat", 
          "tr_subtitle": "Versatile performance meets ample battery life to keep you going, anywhere.",
          "tr_title": "Power through your day",
          "tr_gen_core_title":"Intel® Core™ i3 processor with Intel® Hybrid Technology",
          "tr_subTitle2": "Intel® Core™ i3 processor with Intel® Hybrid Technology",
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I5_HYBRID",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "",
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"header-IconTop",
          "panelType2":"tableFormat", 
          "tr_subtitle": "Easily juggle multiple programs so you’re ready for anything, wherever the day takes you.",
          "tr_title": "On-the-go multitasking",
          "tr_gen_core_title":"Intel® Core™ i5 processor with Intel® Hybrid Technology",
          "tr_subTitle2": "Intel® Core™ i5 processor with Intel® Hybrid Technology",
        }
      },
      {
        "deviceType": "Desktop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "",
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"header-IconTop",
          "panelType2":"tableFormat", 
          "tr_subtitle": "Elevate everything you do from entertainment to productivity and content creation.",
          "tr_title": "Upgrade to a more powerful PC",
          "tr_gen_core_title":"Intel® Core™ i5 processor with Intel® Hybrid Technology",
          "tr_subTitle2": "Intel® Core™ i5 processor with Intel® Hybrid Technology",
        }
      }
      ]
    }]
  },
  // Story IJR-16-H45 processor changes --start
  {
    "contentType": "INTEL_CORE_I5_H45", // i5 H45 ENTHUSIAST OR PERFORMANCE
    "iposTypes": [{
      "iposType": "GAMING",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl":  "assets/images/processor/INTEL_CORE_I5_11.svg", //i5 11th gen badge
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"", 
          "tr_subtitle": "Desktop-caliber gaming performance meets mobility, whether for competitive play with high FPS or immersive AAA experiences.",
          "tr_title": "Power with a purpose.",
          "tr_gen_core_title":"11th Gen Intel® Core™ i5 processor",
          "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
          "badgeIcons": [
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock.svg"
          }]
        }
      },
      ]
    },
    {
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl":  "assets/images/processor/INTEL_CORE_I5_11.svg", //i5 11th gen badge
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"", 
          "tr_subtitle": "Desktop-caliber gaming performance meets mobility, whether for competitive play with high FPS or immersive AAA experiences.",
          "tr_title": "Power with a purpose.",
          "tr_gen_core_title":"11th Gen Intel® Core™ i5 processor",
          "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i5 PROCESSOR",
          "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
          "badgeIcons": [
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock.svg"
          }]
        }
      },
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I7_H45", // i7 H45 ENTHUSIAST OR PERFORMANCE
    "iposTypes": [{
      "iposType": "GAMING",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl":  "assets/images/processor/INTEL_CORE_I7_11.svg", //i7 11th gen badge
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"", 
          "tr_subtitle": "A laptop that can play, record and stream simultaneously with high FPS and effortlessly switch to heavy multitasking workloads.",
          "tr_title": "Power to push the limits.",
          "tr_gen_core_title":"11th Gen Intel® Core™ i7 processor",
          "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
          "badgeIcons": [
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock.svg"
          }]
        }
      }
      ]
    },
    {
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl":  "assets/images/processor/INTEL_CORE_I7_11.svg", //i7 11th gen badge
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"", 
          "tr_subtitle": "A laptop that can play, record and stream simultaneously with high FPS and effortlessly switch to heavy multitasking workloads.",
          "tr_title": "Power to push the limits.",
          "tr_gen_core_title":"11th Gen Intel® Core™ i7 processor",
          "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i7 PROCESSOR",
          "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
          "badgeIcons": [
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock.svg"
          }]
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I9_H45_PERFORMANCE", // i9 H45 PERFORMANCE
    "iposTypes": [{
      "iposType": "GAMING",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl":  "assets/images/processor/INTEL_CORE_I9_11.svg", //i9 11th gen badge
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"", 
          "tr_subtitle": "Fast clock speeds and high core counts work seamlessly together to get more out of competitive gaming and complex content creation, wherever you take your PC.",
          "tr_title": "Massive power. Perfectly balanced.",
          "tr_gen_core_title":"11th Gen Intel® Core™ i9 processor",
          "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
          "badgeIcons": [
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock.svg"
          }]
        }
      }
      ]
    },
    {
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl":  "assets/images/processor/INTEL_CORE_I9_11.svg", //i9 11th gen badge
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"", 
          "tr_subtitle": "Fast clock speeds and high core counts work seamlessly together to get more out of competitive gaming and complex content creation, wherever you take your PC.",
          "tr_title": "Massive power. Perfectly balanced.",
          "tr_gen_core_title":"11th Gen Intel® Core™ i9 processor",
          "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i9 PROCESSOR",
          "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
          "badgeIcons": [
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock.svg"
          }]
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST", // i9 H45 ENTHUSIAST UNLOCKED
    "iposTypes": [{
      "iposType": "GAMING",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl":  "assets/images/processor/INTEL_CORE_I9_11.svg", //i9 11th gen badge
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"", 
          "tr_subtitle": "Fast clock speeds, high core counts and unlocked performance work seamlessly together to get more out of competitive gaming and complex content creation, wherever you take your PC.",
          "tr_title": "Unlocked power. Perfectly balanced.",
          "tr_gen_core_title":"11th Gen Intel® Core™ i9 processor",
          "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
          "badgeIcons": [
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }]
        }
      }
      ]
    },
    {
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl":  "assets/images/processor/INTEL_CORE_I9_11.svg", //i9 11th gen badge
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"", 
          "tr_subtitle": "Fast clock speeds, high core counts and unlocked performance work seamlessly together to get more out of competitive gaming and complex content creation, wherever you take your PC.",
          "tr_title": "Unlocked power. Perfectly balanced.",
          "tr_gen_core_title":"11th Gen Intel® Core™ i9 processor",
          "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i9 PROCESSOR",
          "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
          "badgeIcons": [
            {
              "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "badgeTitle": "",
              "tr_badgeSubTitle": "Unlocked",
              "badgePreTitle": "",
              "imgUrl": "assets/images/tocheck/unlock.svg"
            }]
        }
      }
      ]
    }]
  },
  // Story IJR-16-H45 processor changes --end
  // Story IJR-66 changes --start here
  {
    "contentType": "INTEL_CORE_I9_11",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl":  "assets/images/processor/INTEL_CORE_I9_11.svg", //i9 11th gen badge
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"", 
          "tr_subtitle": "Fast clock speeds and high core counts work seamlessly together to get more out of competitive gaming and complex content creation.",
          "tr_title": "Massive power. Perfectly balanced.",
          "tr_gen_core_title":"11th Gen Intel® Core™ i9 processor",
          "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i9 PROCESSOR",
          "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
          "badgeIcons": [
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock.svg"
          }]
        }
      }
      ]
    },
    {
      "iposType": "Creator",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl":  "assets/images/processor/INTEL_CORE_I9_11.svg", //i9 11th gen badge
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"", 
          "tr_subtitle": "Fast clock speeds and high core counts work seamlessly together to get more out of complex content creation, wherever you take your PC.",
          "tr_title": "Massive power. Perfectly balanced.",
          "tr_gen_core_title":"11th Gen Intel® Core™ i9 processor",
          "tr_gen_core_title_uppercase":"11TH GEN INTEL® CORE™ i9 PROCESSOR",
          "badgeIcons": [
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock.svg"
          }]
        }
      },
      {
        "deviceType": "Desktop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl":  "assets/images/processor/INTEL_CORE_I9_11.svg", //i9 11th gen badge
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"", 
          "tr_subtitle": "Fast clock speeds and high core counts work seamlessly together to get more out of complex content creation.",
          "tr_title": "Massive power. Perfectly balanced.",
          "tr_gen_core_title":"11th Gen Intel® Core™ i9 processor",
          "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i9 PROCESSOR",
          "badgeIcons": [
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock.svg"
          }]
        }
      }]
    }]
  },
  {
    "contentType": "INTEL_CORE_I9_10", 
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"tableFormat", 
          "tr_subtitle": "Push your potential with the ultimate gaming experience",
          "tr_title": "Compete at the highest levels",
          "tr_gen_core_title":"10th Gen Intel® Core™ i9 processor",
          "badgeIcons": [{
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock_blue.svg"
          }],
          "processorCompare": {
            "tr_title": "FIND THE 10TH GEN INTEL® CORE™ PROCESSOR FOR HOW YOU GAME.",
            "background": "",
            "table_head": [
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_imgCaption": "Intel® Core™ i5 processor"
              },
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_imgCaption": "Intel® Core™ i7 processor"
              },
              {
                "tr_table_heading": "Current device",
                "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg", // i9 10th gen badge
                "tr_imgCaption": "Intel® Core™ i9 processor"
              }
            ],
            "tabletr": [{
              "tr_row": "Seamless AAA Gaming",
              "td1": true,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Streaming",
              "td1": true,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Competitive gaming",
              "td1": false,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Streaming and recording",
              "td1": false,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Advanced content creation",
              "td1": false,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Peak gaming performance",
              "td1": false,
              "td2": false,
              "td3": true
            }, {
              "tr_row": "Unlocked and overclockable<sup>2</sup>",
              "td1": false,
              "td2": false,
              "td3": true
            }
            ],
            "table_footer": [{
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.5 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.1 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.3 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
            }
            ]
          }
        }
      },
      {
        "deviceType": "Desktop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"tableFormat", 
          "tr_subtitle": "Push your potential with the ultimate gaming experience",
          "tr_title": "Compete at the highest levels",
          "tr_gen_core_title":"10th Gen Intel® Core™ i9 processor",
          "badgeIcons": [{
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock_blue.svg"
          }],
          "processorCompare": {
            "tr_title": "FIND THE 10TH GEN INTEL® CORE™ PROCESSOR FOR HOW YOU GAME.",
            "background": "",
            "table_head": [
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_imgCaption": "Intel® Core™ i5 processor"
              },
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_imgCaption": "Intel® Core™ i7 processor"
              },
              {
                "tr_table_heading": "Current device",
                "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg", // i9 10th gen badge
                "tr_imgCaption": "Intel® Core™ i9 processor"
              }
            ],
            "tabletr": [{
              "tr_row": "Seamless AAA Gaming",
              "td1": true,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Streaming",
              "td1": true,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Competitive gaming",
              "td1": false,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Streaming and recording",
              "td1": false,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Advanced content creation",
              "td1": false,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Peak gaming performance",
              "td1": false,
              "td2": false,
              "td3": true
            }, {
              "tr_row": "Unlocked and overclockable<sup>2</sup>",
              "td1": true,
              "td2": true,
              "td3": true
            }
            ],
            "table_footer": [{
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.8 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.1 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5.3 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 10<br/>Threads: 20</span>",
            }
            ]
          }
        }
      },
      {
        "deviceType": "Athena",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"header-IconTop",
          "panelType2":"", 
          "tr_subtitle": "For those always on the go, a laptop that is designed especially for you.",
          "tr_title": "Meet a new class of laptops",
          "tr_gen_core_title":"10th Gen Intel® Core™ i9 processor",
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I9_11_UNLOCKED",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl":  "assets/images/processor/INTEL_CORE_I9_11.svg", //i9 11th gen badge
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"", 
          "tr_subtitle": "Fast clock speeds and high core counts work seamlessly together to get more out of competitive gaming and complex content creation.",
          "tr_title": "Unlocked power. Perfectly balanced.",
          "tr_gen_core_title":"11th Gen Intel® Core™ i9 processor",
          "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i9 PROCESSOR",
          "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
          "badgeIcons": [
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock.svg"
          }]
        }
      }
      ]
    },
    {
      "iposType": "Creator",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl":  "assets/images/processor/INTEL_CORE_I9_11.svg", //i9 11th gen badge
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"", 
          "tr_subtitle": "Fast clock speeds, high core counts and unlocked performance work seamlessly together to get more out of complex content creation, wherever you take your PC.",
          "tr_title": "Unlocked power. Perfectly balanced.",
          "tr_gen_core_title":"11th Gen Intel® Core™ i9 processor",
          "tr_gen_core_title_uppercase":"11TH GEN INTEL® CORE™ i9 PROCESSOR",
          "badgeIcons": [
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock.svg"
          }]
        }
      },
      {
        "deviceType": "Desktop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl":  "assets/images/processor/INTEL_CORE_I9_11.svg", //i9 11th gen badge
          "tileBG": "",
          "detailBGUrl": "",
          "panelType1":"gaming-processorType2",
          "panelType2":"", 
          "tr_subtitle": "Fast clock speeds and high core counts work seamlessly together to get more out of complex content creation.",
          "tr_title": "Unlocked power. Perfectly balanced.",
          "tr_gen_core_title":"11th Gen Intel® Core™ i9 processor",
          "tr_gen_core_title_uppercase": "11TH GEN INTEL® CORE™ i9 PROCESSOR",
          "badgeIcons": [
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Smart Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock.svg"
          }]
        }
      }
      ]
    }]
  },
  // Story IJR-66 changes --ends here
  // 9GenGaming mobile stories: IJR-154 Epic --starts
  {
    "contentType": "INTEL_CORE_I5_9_GAMING",
    "iposTypes": [{
      "iposType": "GAMING",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
          "tileBG": "",
          "detailBGUrl": "",            
          "panelType1":"gaming-processorType2",
          "panelType2":"tableFormat",
          "tr_subtitle": "Smooth gameplay and VR on the most demanding titles",
          "tr_title": "Serious gaming starts here",
          "tr_gen_core_title":"9th Gen Intel® Core™ i5 processor",
          "badgeIcons": [{
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock.svg"
          }],
          "processorCompare": {
            "tr_title": "FIND THE INTEL® CORE™ PROCESSOR FOR HOW YOU GAME.",
            "background": "",
            "table_head": [
              {
                "tr_table_heading": "Current device",
                "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tr_imgCaption": "Intel® Core™ i5 processor"
              },
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tr_imgCaption": "Intel® Core™ i7 processor"
              },
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tr_imgCaption": "Intel® Core™ i9 processor"
              }
            ],
            "tabletr": [{
              "tr_row": "AAA Gaming",
              "td1": true,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Competitive gaming",
              "td1": true,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Game, stream and record without compromise",
              "td1": false,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Best 9th Gen Intel® Core™ processor for gaming",
              "td1": false,
              "td2": false,
              "td3": true
            }
            ],
            "table_footer": [{
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.3 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 4<br/>Threads: 8</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.6 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
            }
            ],
            "table_footer_desktop": [{
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.6 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 6</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.9 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 8</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
            }
            ]
          }
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I7_9_GAMING",
    "iposTypes": [{
      "iposType": "GAMING",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
          "tileBG": "",
          "detailBGUrl": "",            
          "panelType1":"gaming-processorType2",
          "panelType2":"tableFormat",
          "tr_subtitle": "UP TO 32% MORE FPS <br/><span class='tr_subtitle_font'>while gaming, streaming and recording vs. a 3-year-old-PC<sup>3</sup></span>",
          "tr_title": "The power to share your greatest gaming moments",
          "tr_gen_core_title":"9th Gen Intel® Core™ i7 processor",
          "badgeIcons": [{
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock.svg"
          }],
          "processorCompare": {
            "tr_title": "FIND THE INTEL® CORE™ PROCESSOR FOR HOW YOU GAME.",
            "background": "",
            "table_head": [
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tr_imgCaption": "Intel® Core™ i5 processor"
              },
              {
                "tr_table_heading": "Current device",
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tr_imgCaption": "Intel® Core™ i7 processor"
              },
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tr_imgCaption": "Intel® Core™ i9 processor"
              }
            ],
            "tabletr": [{
              "tr_row": "AAA Gaming",
              "td1": true,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Competitive gaming",
              "td1": true,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Game, stream and record without compromise",
              "td1": false,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Best 9th Gen Intel® Core™ processor for gaming",
              "td1": false,
              "td2": false,
              "td3": true
            }
            ],
            "table_footer": [{
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.3 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 4<br/>Threads: 8</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.6 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
            }
            ],
            "table_footer_desktop": [{
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.6 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 6</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.9 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 8</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
            }
            ]
          }
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I9_9_GAMING",
    "iposTypes": [{
      "iposType": "GAMING",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
          "tileBG": "",
          "detailBGUrl": "",            
          "panelType1":"gaming-processorType2",
          "panelType2":"tableFormat",
          "tr_subtitle": "UP TO 41% MORE FPS <br/><span class='tr_subtitle_font'>while gaming, streaming and recording vs. a 3-year-old-PC<sup>4</sup></span>",
          "tr_title": "Gamers demand. Intel® Core™ i9 delivers.",
          "tr_gen_core_title":"9th Gen Intel® Core™ i9 processor",
          "badgeIcons": [{
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='subtitle_font'>Up to <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
            "tr_badgeSubTitle": "",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "",
            "badgeTitle": "",
            "tr_badgeSubTitle": "Unlocked",
            "badgePreTitle": "",
            "imgUrl": "assets/images/tocheck/unlock.svg"
          }],
          "processorCompare": {
            "tr_title": "FIND THE INTEL® CORE™ PROCESSOR FOR HOW YOU GAME.",
            "background": "",
            "table_head": [
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tr_imgCaption": "Intel® Core™ i5 processor"
              },
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tr_imgCaption": "Intel® Core™ i7 processor"
              },
              {
                "tr_table_heading": "Current device",
                "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tr_imgCaption": "Intel® Core™ i9 processor"
              }
            ],
            "tabletr": [{
              "tr_row": "AAA Gaming",
              "td1": true,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Competitive gaming",
              "td1": true,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Game, stream and record without compromise",
              "td1": false,
              "td2": true,
              "td3": true
            }, {
              "tr_row": "Best 9th Gen Intel® Core™ processor for gaming",
              "td1": false,
              "td2": false,
              "td3": true
            }
            ],
            "table_footer": [{
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.3 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 4<br/>Threads: 8</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.6 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
            }
            ],
            "table_footer_desktop": [{
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.6 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 6</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 4.9 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 8</span>",
            },
            {
              "tr_title": "<span class='small_font'>Clock speed<sup>2</sup>:<br/>Up to 5 GHz</span>",
              "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>",
            }
            ]
          }
        }
      }
      ]
    }]
  },

  {
    "contentType": "INTEL_CORE_K_GAMING",
    "iposTypes": [{
      "iposType": "GAMING",
      "deviceTypes": [{
        "deviceType": "Desktop",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Built for the next generation of gaming.",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "processorTileBadgeUrl":"assets/images/standard/i9_uwp_tile_badge.png",
          "titleArray": [{
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
            "tr_gen_core_title": "12th Gen Intel® Core™ i5 processor",
            "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i5 PROCESSOR",
          },
          {
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
            "tr_gen_core_title": "12th Gen Intel® Core™ i7 processor",
            "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i7 PROCESSOR",
          },
          {
            "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
            "tr_gen_core_title": "12th Gen Intel® Core™ i9 processor",
            "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i9 PROCESSOR",
          }],
          "badgeIcons": [
            {
              "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Cores</span></span>",
              "tr_badgePreTitle": "[p_core] Performance-cores",
              "tr_badgeSubTitle": "[e_core] Efficient-cores"
            },
            {
              "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
              "tr_badgePreTitle": "[p_thread] on Performance-cores",
              "tr_badgeSubTitle": "[e_thread] on Efficient-cores"              
            },
          ],
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTick.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I7_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",       
                "tr_badgeIconTile" : "Intel® Core™ i9 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    }]
  },

  {
    "contentType": "INTEL_CORE_HX_GAMING",
    "iposTypes": [{
      "iposType": "GAMING",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Battlestation meets workstation",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "processorTileBadgeUrl":"assets/images/standard/i9_uwp_tile_badge.png",
          "titleArray": [
          {
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
            "tr_gen_core_title": "12th Gen Intel® Core™ i7 processor",
            "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i7 PROCESSOR",
          },
          {
            "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
            "tr_gen_core_title": "12th Gen Intel® Core™ i9 processor",
            "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i9 PROCESSOR",
          }],
          "badgeIcons": [
            {
              "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Cores</span></span>",
              "tr_badgePreTitle": "[p_core] Performance-cores",
              "tr_badgeSubTitle": "[e_core] Efficient-cores"
            },
            {
              "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
              "tr_badgePreTitle": "[p_thread] on Performance-cores",
              "tr_badgeSubTitle": "[e_thread] on Efficient-cores"              
            },
          ],
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTick.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I7_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",       
                "tr_badgeIconTile" : "Intel® Core™ i9 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    }]
  },
  
  {
    "contentType": "INTEL_CORE_I3_12",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Reliable performance when you need it most.",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
          "tr_gen_core_title": "12th Gen Intel® Core™ i3 processor",
          "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i3 PROCESSOR",
          "bannerImgUrl":"assets/images/12th_gen_std_i3_processor_banner.svg",
          "processorTileBadgeUrl":"assets/images/standard/i3_uwp_tile_badge.svg",
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTickBlack.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I5_12",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "A boost of performance where it’s needed most.",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
          "tr_gen_core_title": "12th Gen Intel® Core™ i5 processor",
          "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i5 PROCESSOR",
          "bannerImgUrl":"assets/images/12th_gen_std_i5_processor_banner.svg",
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTickBlack.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    },
    {
      "iposType": "Creator",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "A boost of performance where it’s needed most.",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
          "tr_gen_core_title": "12th Gen Intel® Core™ i5 processor",
          "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i5 PROCESSOR",
          "bannerImgUrl":"assets/images/12th_gen_std_i5_processor_banner.svg",
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTick.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I7_12",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Superior performance where you need it most.",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
          "tr_gen_core_title": "12th Gen Intel® Core™ i7 processor",
          "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i7 PROCESSOR",
          "bannerImgUrl":"assets/images/12th_gen_std_i7_processor_banner.svg",
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTickBlack.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    },
    {
      "iposType": "Creator",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Superior performance where you need it most.",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
          "tr_gen_core_title": "12th Gen Intel® Core™ i7 processor",
          "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i7 PROCESSOR",
          "bannerImgUrl":"assets/images/12th_gen_std_i7_processor_banner.svg",
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTick.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
        }
      }
      ]
    }]
  },
  {
    "contentType": "EVO_I5_12GEN",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg", //Evo I5 badge
          "tileBG": "",
          "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
          "panelType1":"header-IconTop",
          "panelType2":"", 
          "tr_subtitle": "With Intel® Evo™ laptops, you get performance, built-in intelligence for confident video chats, faster internet, long-lasting battery and more.",
          "tr_title": "A boost of performance where you need it most.",
          "tr_gen_core_title":"12th Gen Intel® Core™ i5 processor",
          "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i5 PROCESSOR",
          "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
        }
      }]
    }]
  },
  {
    "contentType": "EVO_I7_12GEN",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg", //Evo I7 badge
          "tileBG": "",
          "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
          "panelType1":"header-IconTop",
          "panelType2":"", 
          "tr_subtitle": "With Intel® Evo™ laptops, you get performance, built-in intelligence for confident video chats, faster internet, long-lasting battery and more.",
          "tr_title": "Superior performance where you need it most.",
          "tr_gen_core_title":"12th Gen Intel® Core™ i7 processor",
          "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i7 PROCESSOR",
          "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
        }
      }]
    }]
  },
  {
    "contentType": "EVO_I9_12GEN",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg", //Evo I7 badge
          "tileBG": "",
          "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
          "panelType1":"header-IconTop",
          "panelType2":"", 
          "tr_subtitle": "With Intel® Evo™ laptops, you get performance, built-in intelligence for confident video chats, faster internet, long-lasting battery and more.",
          "tr_title": "Extreme performance in a premium laptop",
          "tr_gen_core_title":"12th Gen Intel® Core™ i9 processor",
          "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i9 PROCESSOR",
          "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
        }
      }]
    }]
  },
  {
    "contentType": "INTEL_CORE_I5_12GEN_VPRO", 
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
          "tileBG": "",
          "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
          "panelType1":"gaming-processorType12",
          "panelType2":"tableFormat", 
          "tr_subtitle": "With Intel® Turbo Boost Technology 2.0 up to [max_turbo_frequency] GHz",
          "tr_title": "Built for business",
          "tr_gen_core_title": "12th Gen Intel® Core™ i5 processor",
          "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i5 PROCESSOR",
          "processorTileBadgeUrl": "",
          "processorCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "background": "",
            "table_head": [
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                "tr_imgCaption": ""
              },
              {
                "tr_table_heading": "Current device",
                "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tr_imgCaption": ""
              },
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tr_imgCaption": ""
              },
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tr_imgCaption": ""
              }
            ],
            "tabletr": [{
              "tr_row": "Suppressing noise and blurring your background",
              "td1": true,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Auto-tailoring performance to prioritize what you do most",
              "td1": true,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Experiencing smooth, high-quality web calls",
              "td1": true,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Getting out-of-the-box hardware-based security",
              "td1": false,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Delivering remote device management capabilities",
              "td1": false,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Automatically optimizing your Wi-Fi performance<sup>20</sup>",
              "td1": false,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Powering through data-driven apps",
              "td1": false,
              "td2": false,
              "td3": true,
              "td4": true
            },
            {
              "tr_row": "The most productivity with enhanced performance",
              "td1": false,
              "td2": false,
              "td3": false,
              "td4": true
            }
            ]
          }
        }
      }]
    }]
  },
  {
    "contentType": "INTEL_CORE_I7_12GEN_VPRO", 
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
          "tileBG": "",
          "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
          "panelType1":"gaming-processorType12",
          "panelType2":"tableFormat", 
          "tr_subtitle": "With Intel® Turbo Boost Technology 2.0 up to [max_turbo_frequency] GHz",
          "tr_title": "Works hard, so you can focus on your business.",
          "tr_gen_core_title": "12th Gen Intel® Core™ i7 processor",
          "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i7 PROCESSOR",
          "processorTileBadgeUrl": "",
          "processorCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "background": "",
            "table_head": [
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                "tr_imgCaption": ""
              },
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tr_imgCaption": ""
              },
              {
                "tr_table_heading": "Current device",
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tr_imgCaption": ""
              },
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tr_imgCaption": ""
              }
            ],
            "tabletr": [{
              "tr_row": "Suppressing noise and blurring your background",
              "td1": true,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Auto-tailoring performance to prioritize what you do most",
              "td1": true,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Experiencing smooth, high-quality web calls",
              "td1": true,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Getting out-of-the-box hardware-based security",
              "td1": false,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Delivering remote device management capabilities",
              "td1": false,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Automatically optimizing your Wi-Fi performance<sup>20</sup>",
              "td1": false,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Powering through data-driven apps",
              "td1": false,
              "td2": false,
              "td3": true,
              "td4": true
            },
            {
              "tr_row": "The most productivity with enhanced performance",
              "td1": false,
              "td2": false,
              "td3": false,
              "td4": true
            }
            ]
          }
        }
      }]
    }]
  },
  {
    "contentType": "INTEL_CORE_I9_12GEN_VPRO", 
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
          "tileBG": "",
          "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
          "panelType1":"gaming-processorType12",
          "panelType2":"tableFormat", 
          "tr_subtitle": "With Intel® Turbo Boost Technology 2.0 up to [max_turbo_frequency] GHz",
          "tr_title": "Accelerate your business",
          "tr_gen_core_title": "12th Gen Intel® Core™ i9 processor",
          "tr_gen_core_title_uppercase": "12TH GEN INTEL® CORE™ i9 PROCESSOR",
          "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
          "processorCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "background": "",
            "table_head": [
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                "tr_imgCaption": ""
              },
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tr_imgCaption": ""
              },
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tr_imgCaption": ""
              },
              {
                "tr_table_heading": "Current device",
                "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tr_imgCaption": ""
              }
            ],
            "tabletr": [{
              "tr_row": "Suppressing noise and blurring your background",
              "td1": true,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Auto-tailoring performance to prioritize what you do most",
              "td1": true,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Experiencing smooth, high-quality web calls",
              "td1": true,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Getting out-of-the-box hardware-based security",
              "td1": false,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Delivering remote device management capabilities",
              "td1": false,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Automatically optimizing your Wi-Fi performance<sup>20</sup>",
              "td1": false,
              "td2": true,
              "td3": true,
              "td4": true
            }, {
              "tr_row": "Powering through data-driven apps",
              "td1": false,
              "td2": false,
              "td3": true,
              "td4": true
            },
            {
              "tr_row": "The most productivity with enhanced performance",
              "td1": false,
              "td2": false,
              "td3": false,
              "td4": true
            }
            ]
          }
        }
      }]
    }]
  },
  {
    "contentType": "INTEL_CORE_I3_13",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Reliable performance dedicated to doing more",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
          "tr_gen_core_title": "13th Gen Intel® Core™ i3 processor",
          "bannerImgUrl":"assets/images/13th_gen_std_i3_processor_banner.svg",
          "processorTileBadgeUrl":"assets/images/standard/13th_gen_i3_std_tile_badge.png", // need to get img
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTickBlack.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I5_13",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Real-world performance dedicated to doing more",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
          "tr_gen_core_title": "13th Gen Intel® Core™ i5 processor",
          "tr_gen_core_title_uppercase": "13TH GEN INTEL® CORE™ i5 PROCESSOR",
          "bannerImgUrl":"assets/images/13th_gen_std_i5_processor_banner.svg",
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTickBlack.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I5_13_CREATOR",
    "iposTypes": [{
      "iposType": "Creator",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Real-world performance dedicated to doing more",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
          "tr_gen_core_title": "13th Gen Intel® Core™ i5 processor",
          "tr_gen_core_title_uppercase": "13TH GEN INTEL® CORE™ i5 PROCESSOR",
          "bannerImgUrl":"assets/images/creator/13thgeni5processor.svg",
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTick.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I7_13",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Powerful performance dedicated to doing more",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
          "tr_gen_core_title": "13th Gen Intel® Core™ i7 processor",
          "tr_gen_core_title_uppercase": "13TH GEN INTEL® CORE™ i7 PROCESSOR",
          "bannerImgUrl":"assets/images/13th_gen_std_i7_processor_banner.svg",
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTickBlack.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I7_13_CREATOR",
    "iposTypes": [{
      "iposType": "Creator",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Powerful performance dedicated to doing more",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
          "tr_gen_core_title": "13th Gen Intel® Core™ i7 processor",
          "tr_gen_core_title_uppercase": "13TH GEN INTEL® CORE™ i7 PROCESSOR",
          "bannerImgUrl":"assets/images/creator/13thgeni7processor.svg",
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTick.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I9_CREATOR",
    "iposTypes": [{
      "iposType": "Creator",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Beyond performance.",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
          "tr_gen_core_title": "13th Gen Intel® Core™ i9 processor",
          "tr_gen_core_title_uppercase": "13TH GEN INTEL® CORE™ i9 PROCESSOR",
          "badgeIcons": [{
            "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Cores</span></span>",
            "tr_badgePreTitle": "[p_core] Performance-cores",
            "tr_badgeSubTitle": "[e_core] Efficient-cores"
          },
          {
            "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
            "tr_badgePreTitle": "[p_thread] on Performance-cores",
            "tr_badgeSubTitle": "[e_thread] on Efficient-cores"              
          }],
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTick.svg",
            "badgeIcons": [{
              "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg", 
              "tr_badgeIconTile" : "Intel® Core™ i5 processor",
              "tr_badgeSubTitle": ""
            },
            {
              "imgUrl":  "assets/images/processor/INTEL_CORE_I7_9.svg", 
              "tr_badgeIconTile" : "Intel® Core™ i7 processor",
              "tr_badgeSubTitle": ""
            },
            {
              "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",       
              "tr_badgeIconTile" : "Intel® Core™ i9 processor",
              "tr_badgeSubTitle": ""
            }],
          }
        }
      }]
    }]
  },

  {
    "contentType": "EVO_I5_13GEN",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg", //Evo I5 badge
          "tileBG": "",
          "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
          "panelType1":"header-IconTop",
          "panelType2":"", 
          "tr_subtitle": "Seamlessly connect to your existing phone<sup>24</sup>, intelligently select the best Wi-Fi, and charge fast with a battery that lasts.",
          "tr_title": "Go beyond performance to get more done anywhere",
          "tr_gen_core_title":"13th Gen Intel® Core™ i5 processor",
          "tr_gen_core_title_uppercase": "13TH GEN INTEL® CORE™ i5 PROCESSOR",
          "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
        }
      }]
    }]
  },
  {
    "contentType": "EVO_I7_13GEN",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg", //Evo I7 badge
          "tileBG": "",
          "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
          "panelType1":"header-IconTop",
          "panelType2":"", 
          "tr_subtitle": "Seamlessly connect to your existing phone<sup>24</sup>, intelligently select the best Wi-Fi, and charge fast with a battery that lasts.",
          "tr_title": "Go beyond performance to let your PC do even more at once",
          "tr_gen_core_title":"13th Gen Intel® Core™ i7 processor",
          "tr_gen_core_title_uppercase": "13TH GEN INTEL® CORE™ i7 PROCESSOR",
          "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
        }
      }]
    }]
  },
  {
    "contentType": "EVO_I9_13GEN",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg", //Evo I7 badge
          "tileBG": "",
          "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
          "panelType1":"header-IconTop",
          "panelType2":"", 
          "tr_subtitle": "Seamlessly connect to your existing phone<sup>24</sup>, intelligently select the best Wi-Fi, and charge fast with a battery that lasts.",
          "tr_title": "Go beyond performance to speed through the most demanding tasks",
          "tr_gen_core_title":"13th Gen Intel® Core™ i9 processor",
          "tr_gen_core_title_uppercase": "13TH GEN INTEL® CORE™ i9 PROCESSOR",
          "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
        }
      }]
    }]
  },
  {
    "contentType": "INTEL_CORE_13TH_GEN_GAMING",
    "iposTypes": [{
      "iposType": "GAMING",
      "deviceTypes": [{
        "deviceType": "Desktop",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Beyond performance.",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "titleArray": [{
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
            "tr_gen_core_title": "13th Gen Intel® Core™ i5 processor",
            "tr_gen_core_title_uppercase": "13TH GEN INTEL® CORE™ i5 PROCESSOR",
            "processorTileBadgeUrl":"assets/images/gaming/13th_gen_i5_k_tile_badge.png", // k
            "processorTileBadgeUrl1":"assets/images/gaming/13th_gen_i5_hx_tile_badge.png" // h or HX
          },
          {
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
            "tr_gen_core_title": "13th Gen Intel® Core™ i7 processor",
            "tr_gen_core_title_uppercase": "13TH GEN INTEL® CORE™ i7 PROCESSOR",
            "processorTileBadgeUrl":"assets/images/gaming/13th_gen_i7_k_tile_badge.png", // k
            "processorTileBadgeUrl1":"assets/images/gaming/13th_gen_i7_hx_tile_badge.png", // h or HX
          },
          {
            "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
            "tr_gen_core_title": "13th Gen Intel® Core™ i9 processor",
            "tr_gen_core_title_uppercase": "13TH GEN INTEL® CORE™ i9 PROCESSOR",
            "processorTileBadgeUrl":"assets/images/gaming/13th_gen_i9_k_tile_badge.png", // k
            "processorTileBadgeUrl1":"assets/images/gaming/13th_gen_i9_hx_tile_badge.png", // h or HX
            "processorTileBadgeUrl2":"assets/images/standard/13th_gen_i9_std_tile_badge.png" // std
          }],
          "badgeIcons": [{
            "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Cores</span></span>",
            "tr_badgePreTitle": "[p_core] Performance-cores",
            "tr_badgeSubTitle": "[e_core] Efficient-cores"
          },
          {
            "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
            "tr_badgePreTitle": "[p_thread] on Performance-cores",
            "tr_badgeSubTitle": "[e_thread] on Efficient-cores"              
          }],
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTick.svg",
            "badgeIcons": [{
              "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg", 
              "tr_badgeIconTile" : "Intel® Core™ i5 processor",
              "tr_badgeSubTitle": ""
            },
            {
              "imgUrl":  "assets/images/processor/INTEL_CORE_I7_9.svg", 
              "tr_badgeIconTile" : "Intel® Core™ i7 processor",
              "tr_badgeSubTitle": ""
            },
            {
              "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",       
              "tr_badgeIconTile" : "Intel® Core™ i9 processor",
              "tr_badgeSubTitle": ""
            }],
          }
        }
      }]
    }]
  },
  {
    "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
    "iposTypes": [{
      "iposType": "GAMING",
      "deviceTypes": [{
        "deviceType": "Desktop",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Beyond performance.",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "titleArray": [{
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
            "tr_gen_core_title": "Intel® Core™ i5 processor",
            "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 PROCESSOR",
            "processorTileBadgeUrl":"assets/images/gaming/13th_gen_i5_k_tile_badge.png", // k
            "processorTileBadgeUrl1":"assets/images/gaming/13th_gen_i5_hx_tile_badge.png" // h or HX
          },
          {
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
            "tr_gen_core_title": "Intel® Core™ i7 processor",
            "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 PROCESSOR",
            "processorTileBadgeUrl":"assets/images/gaming/13th_gen_i7_k_tile_badge.png", // k
            "processorTileBadgeUrl1":"assets/images/gaming/13th_gen_i7_hx_tile_badge.png", // h or HX
          },
          {
            "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
            "tr_gen_core_title": "Intel® Core™ i9 processor",
            "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 PROCESSOR",
            "processorTileBadgeUrl":"assets/images/gaming/13th_gen_i9_k_tile_badge.png", // k
            "processorTileBadgeUrl1":"assets/images/gaming/13th_gen_i9_hx_tile_badge.png", // h or HX
            "processorTileBadgeUrl2":"assets/images/standard/13th_gen_i9_std_tile_badge.png" // std
          }],
          "badgeIcons": [{
            "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Cores</span></span>",
            "tr_badgePreTitle": "[p_core] Performance-cores",
            "tr_badgeSubTitle": "[e_core] Efficient-cores"
          },
          {
            "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
            "tr_badgePreTitle": "[p_thread] on Performance-cores",
            "tr_badgeSubTitle": "[e_thread] on Efficient-cores"              
          }],
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTick.svg",
            "badgeIcons": [{
              "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg", 
              "tr_badgeIconTile" : "Intel® Core™ i5 processor",
              "tr_badgeSubTitle": ""
            },
            {
              "imgUrl":  "assets/images/processor/INTEL_CORE_I7_9.svg", 
              "tr_badgeIconTile" : "Intel® Core™ i7 processor",
              "tr_badgeSubTitle": ""
            },
            {
              "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",       
              "tr_badgeIconTile" : "Intel® Core™ i9 processor",
              "tr_badgeSubTitle": ""
            }],
          }
        }
      }]
    }]
  },
  {
    "contentType": "INTEL_CORE_I3_14",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Reliable performance dedicated to doing more",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
          "tr_gen_core_title": "Intel® Core™ i3 processor",
          "bannerImgUrl":"assets/images/13th_gen_std_i3_processor_banner.svg",
          "processorTileBadgeUrl":"assets/images/standard/13th_gen_i3_std_tile_badge.png", // need to get img
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTickBlack.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I5_14",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Real-world performance dedicated to doing more",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
          "tr_gen_core_title": "Intel® Core™ i5 processor",
          "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 PROCESSOR",
          "bannerImgUrl":"assets/images/13th_gen_std_i5_processor_banner.svg",
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTickBlack.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I7_14",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Powerful performance dedicated to doing more",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
          "tr_gen_core_title": "Intel® Core™ i7 processor",
          "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 PROCESSOR",
          "bannerImgUrl":"assets/images/13th_gen_std_i7_processor_banner.svg",
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTickBlack.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_9.svg", 
                "tr_badgeIconTile" : "Intel® Core™ i5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",       
                "tr_badgeIconTile" : "Intel® Core™ i7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_14TH_GEN_GAMING",
    "iposTypes": [{
      "iposType": "GAMING",
      "deviceTypes": [{
        "deviceType": "Desktop",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Beyond performance.",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "titleArray": [{
            "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
            "tr_gen_core_title": "Intel® Core™ i5 processor",
            "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 PROCESSOR",
            "processorTileBadgeUrl":"assets/images/gaming/13th_gen_i5_k_tile_badge.png", // k
            "processorTileBadgeUrl1":"assets/images/gaming/13th_gen_i5_hx_tile_badge.png" // h or HX
          },
          {
            "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
            "tr_gen_core_title": "Intel® Core™ i7 processor",
            "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 PROCESSOR",
            "processorTileBadgeUrl":"assets/images/gaming/13th_gen_i7_k_tile_badge.png", // k
            "processorTileBadgeUrl1":"assets/images/gaming/13th_gen_i7_hx_tile_badge.png", // h or HX
          },
          {
            "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
            "tr_gen_core_title": "Intel® Core™ i9 processor",
            "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 PROCESSOR",
            "processorTileBadgeUrl":"assets/images/gaming/13th_gen_i9_k_tile_badge.png", // k
            "processorTileBadgeUrl1":"assets/images/gaming/13th_gen_i9_hx_tile_badge.png", // h or HX
            "processorTileBadgeUrl2":"assets/images/standard/13th_gen_i9_std_tile_badge.png" // std
          }],
          "badgeIcons": [{
            "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Cores</span></span>",
            "tr_badgePreTitle": "[p_core] Performance-cores",
            "tr_badgeSubTitle": "[e_core] Efficient-cores"
          },
          {
            "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
            "tr_badgePreTitle": "[p_thread] on Performance-cores",
            "tr_badgeSubTitle": "[e_thread] on Efficient-cores"              
          }],
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTick.svg",
            "badgeIcons": [{
              "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg", 
              "tr_badgeIconTile" : "Intel® Core™ i5 processor",
              "tr_badgeSubTitle": ""
            },
            {
              "imgUrl":  "assets/images/processor/INTEL_CORE_I7_9.svg", 
              "tr_badgeIconTile" : "Intel® Core™ i7 processor",
              "tr_badgeSubTitle": ""
            },
            {
              "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",       
              "tr_badgeIconTile" : "Intel® Core™ i9 processor",
              "tr_badgeSubTitle": ""
            }],
          }
        }
      }]
    }]
  },
  {
    "contentType": "INTEL_CORE_I3_14_1",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Reliable performance dedicated to doing more",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
          "tr_gen_core_title": "Intel® Core™ 3 processor",
          "bannerImgUrl":"assets/images/13th_gen_std_i3_processor_banner.svg",
          "processorTileBadgeUrl":"assets/images/standard/14th_gen_i3_std_tile_badge.png",
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTickBlack.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg", 
                "tr_badgeIconTile" : "Intel® Core™ 3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_14_1.svg", 
                "tr_badgeIconTile" : "Intel® Core™ 5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",       
                "tr_badgeIconTile" : "Intel® Core™ 7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I5_14_1",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Real-world performance dedicated to doing more",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
          "tr_gen_core_title": "Intel® Core™ 5 processor",
          "tr_gen_core_title_uppercase": "INTEL® CORE™ 5 PROCESSOR",
          "bannerImgUrl":"assets/images/13th_gen_std_i5_processor_banner.svg",
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTickBlack.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg", 
                "tr_badgeIconTile" : "Intel® Core™ 3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_14_1.svg", 
                "tr_badgeIconTile" : "Intel® Core™ 5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",       
                "tr_badgeIconTile" : "Intel® Core™ 7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_I7_14_1",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Powerful performance dedicated to doing more",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
          "tr_gen_core_title": "Intel® Core™ 7 processor",
          "tr_gen_core_title_uppercase": "INTEL® CORE™ 7 PROCESSOR",
          "bannerImgUrl":"assets/images/13th_gen_std_i7_processor_banner.svg",
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTickBlack.svg",
            "badgeIcons": [
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg", 
                "tr_badgeIconTile" : "Intel® Core™ 3 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl":  "assets/images/processor/INTEL_CORE_I5_14_1.svg", 
                "tr_badgeIconTile" : "Intel® Core™ 5 processor",
                "tr_badgeSubTitle": ""
              },
              {
                "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",       
                "tr_badgeIconTile" : "Intel® Core™ 7 processor",
                "tr_badgeSubTitle": ""
              }
            ],
          }
         
        }
      }
      ]
    }]
  },
  {
    "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
    "iposTypes": [{
      "iposType": "GAMING",
      "deviceTypes": [{
        "deviceType": "Desktop",
        "details": {
          "tr_tileTitle": "Processor",
          "tr_title": "Game without compromise",
          "panelType1":"gaming-processorType12",
          "gamingPanleType2":"gaming-panelType1",
          "titleArray": [{
            "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
            "tr_gen_core_title": "Intel® Core™ Ultra 5 processor",
            "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 5 PROCESSOR",
            "processorTileBadgeUrl":"", // k
            "processorTileBadgeUrl1":"assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png" // h or HX
          },
          {
            "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
            "tr_gen_core_title": "Intel® Core™ Ultra 7 processor",
            "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 7 PROCESSOR",
            "processorTileBadgeUrl":"", // k
            "processorTileBadgeUrl1":"assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png", // h or HX
          },
          {
            "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
            "tr_gen_core_title": "Intel® Core™ Ultra 9 processor",
            "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 9 PROCESSOR",
            "processorTileBadgeUrl":"", // k
            "processorTileBadgeUrl1":"assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png", // h or HX
            "processorTileBadgeUrl2":"assets/images/standard/13th_gen_i9_std_tile_badge.png" // std
          }],
          "badgeIcons": [{
            "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Cores</span></span>",
            "tr_badgePreTitle": "[p_core] Performance-cores",
            "tr_badgeSubTitle": "[e_core] Efficient-cores",
            "tr_badgeSubTitle1": "[l_core] LPE-cores"
          },
          {
            "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
            "tr_badgePreTitle": "[p_thread] on Performance-cores",
            "tr_badgeSubTitle": "[e_thread] on Efficient-cores",
            "tr_badgeSubTitle1": "[l_thread] on LPE-cores"              
          }],
          "enhancementCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "panelType2":"enhancement-compare",
            "tr_table_heading": "Current device",
            "imgUrl": "assets/images/processorCompareTick.svg",
            "badgeIcons": [{
              "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg", 
              "tr_badgeIconTile" : "Intel® Core™ Ultra 5 processor",
              "tr_badgeSubTitle": ""
            },
            {
              "imgUrl":  "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg", 
              "tr_badgeIconTile" : "Intel® Core™ Ultra 7 processor",
              "tr_badgeSubTitle": ""
            },
            {
              "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",       
              "tr_badgeIconTile" : "Intel® Core™ Ultra 9 processor",
              "tr_badgeSubTitle": ""
            }],
          }
        }
      }]
    }]
  },
  {
    "contentType": "EVO_MTL_ULTRA_5",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
          "tr_title": "Game without compromise",
          "panelType1":"evo-mtl-video-panel",
          "gamingPanleType2":"gaming-panelType1",
          "videoUrl":"assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4" 
        }
      }]
    }]
  },
  {
    "contentType": "EVO_MTL_ULTRA_7",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
          "tr_title": "Game without compromise",
          "panelType1":"evo-mtl-video-panel",
          "gamingPanleType2":"gaming-panelType1",
          "videoUrl":"assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"        
        }
      }]
    }]
  }, 
  {
    "contentType": "EVO_MTL_ULTRA_9",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
          "tr_title": "Game without compromise",
          "panelType1":"evo-mtl-video-panel",
          "gamingPanleType2":"gaming-panelType1",
          "videoUrl":"assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"        
        }
      }]
    }]
  }, 
  {
    "contentType": "STD_MTL_ULTRA_5",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
          "tr_title": "Game without compromise",
          "panelType1":"evo-mtl-video-panel",
          "gamingPanleType2":"gaming-panelType1",
          "videoUrl":"assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
        }
      }]
    }]
  },
  {
    "contentType": "STD_MTL_ULTRA_7",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
          "tr_title": "Game without compromise",          
          "panelType1":"evo-mtl-video-panel",
          "gamingPanleType2":"gaming-panelType1",
          "videoUrl":"assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"                 
        }
      }]
    }]
  },
  {
    "contentType": "STD_MTL_ULTRA_9",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "Laptop",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
          "tr_title": "Game without compromise",
          "panelType1":"evo-mtl-video-panel",
          "gamingPanleType2":"gaming-panelType1",
          "videoUrl":"assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"       
        }
      }]
    }]
  },
  {
    "contentType": "INTEL_CORE_I5_13GEN_VPRO",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
          "tileBG": "",
          "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
          "panelType1":"gaming-processorType12",
          "panelType2":"tableFormat",
          "tr_subtitle": "",
          "tr_title": "Small businesses deserve big business solutions",
          "tr_gen_core_title": "13th Gen Intel® Core™ i5 processor",
          "tr_gen_core_title_uppercase": "13TH GEN INTEL® CORE™ i5 PROCESSOR",
          "processorTileBadgeUrl": "",
          "processorCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "background": "",
            "table_head": [
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                "tr_imgCaption": ""
              },
              {
                "tr_table_heading": "Current device",
                "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                "tr_imgCaption": ""
              }
            ],
            "tabletr": [{
              "tr_row": "Suppressing noise and blurring your background",
              "td1": true,
              "td2": true
            }, {
              "tr_row": "Auto-tailoring performance to prioritize what you do most",
              "td1": true,
              "td2": true
            }, {
              "tr_row": "Experiencing smooth, high-quality web calls",
              "td1": true,
              "td2": true
            }, {
              "tr_row": "Getting out-of-the-box hardware-based security protections",
              "td1": false,
              "td2": true
            }, {
              "tr_row": "Help protect devices and data from physical tampering attacks",
              "td1": false,
              "td2": true
            }
            ]
          }
        }
      }]
    }]
  },
  {
    "contentType": "INTEL_CORE_I7_13GEN_VPRO",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
          "tileBG": "",
          "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
          "panelType1":"gaming-processorType12",
          "panelType2":"tableFormat",
          "tr_subtitle": "",
          "tr_title": "Small businesses deserve big business solutions",
          "tr_gen_core_title": "13th Gen Intel® Core™ i7 processor",
          "tr_gen_core_title_uppercase": "13TH GEN INTEL® CORE™ i7 PROCESSOR",
          "processorTileBadgeUrl": "",
          "processorCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "background": "",
            "table_head": [
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                "tr_imgCaption": ""
              },
              {
                "tr_table_heading": "Current device",
                "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                "tr_imgCaption": ""
              }
            ],
            "tabletr": [{
              "tr_row": "Suppressing noise and blurring your background",
              "td1": true,
              "td2": true
            }, {
              "tr_row": "Auto-tailoring performance to prioritize what you do most",
              "td1": true,
              "td2": true
            }, {
              "tr_row": "Experiencing smooth, high-quality web calls",
              "td1": true,
              "td2": true
            }, {
              "tr_row": "Getting out-of-the-box hardware-based security protections",
              "td1": false,
              "td2": true
            }, {
              "tr_row": "Help protect devices and data from physical tampering attacks",
              "td1": false,
              "td2": true
            }
            ]
          }
        }
      }]
    }]
  },
  {
    "contentType": "INTEL_CORE_I9_13GEN_VPRO",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
          "tileBG": "",
          "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
          "panelType1":"gaming-processorType12",
          "panelType2":"tableFormat",
          "tr_subtitle": "",
          "tr_title": "Small businesses deserve big business solutions",
          "tr_gen_core_title": "13th Gen Intel® Core™ i9 processor",
          "tr_gen_core_title_uppercase": "13TH GEN INTEL® CORE™ i9 PROCESSOR",
          "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
          "processorCompare": {
            "tr_title": "HOW DOES THIS PROCESSOR COMPARE?",
            "background": "",
            "table_head": [
              {
                "tr_table_heading": "",
                "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                "tr_imgCaption": ""
              },
              {
                "tr_table_heading": "Current device",
                "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                "tr_imgCaption": ""
              }
            ],
            "tabletr": [{
              "tr_row": "Suppressing noise and blurring your background",
              "td1": true,
              "td2": true
            }, {
              "tr_row": "Auto-tailoring performance to prioritize what you do most",
              "td1": true,
              "td2": true
            }, {
              "tr_row": "Experiencing smooth, high-quality web calls",
              "td1": true,
              "td2": true
            }, {
              "tr_row": "Getting out-of-the-box hardware-based security protections",
              "td1": false,
              "td2": true
            }, {
              "tr_row": "Help protect devices and data from physical tampering attacks",
              "td1": false,
              "td2": true
            }
            ]
          }
        }
      }]
    }]
  },
  {
    "contentType": "INTEL_RPL_MTL_vPro",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
          "tileBG": "",
          "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
          "panelType1":"gaming-processorType12",
          "panelType2":"",
          "tr_subtitle": "Unlock new AI experiences for business",
          "tr_title": "Be ready for what’s next using Intel® Core™ Ultra [XX] processors and Intel vPro®",
          "tr_gen_core_title": "Intel® Core™ Ultra [XX] processor",
          "badgeIcons": [{
            "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
            "tr_badgeTitle": "GIVE YOUR BUSINESS A BOOST WITH AI PCs FROM INTEL",
            "tr_badgeSubTitle": "Powerful PCs that create a great experience are at the core of business productivity. Intel vPro® boosts employee effectiveness and performance across workloads and applications thanks to a new architecture that delivers superior power-efficient performance for various, complex business workloads by sending the right task to the right engine at the right time.",
            "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
              "tr_badgeTitle": "MULTILAYER SECURITY FEATURES ENABLED RIGHT OUT-OF-THE-BOX",
              "tr_badgeSubTitle": "New and emerging threats require a comprehensive approach to security. Intel vPro® provides protections at the hardware level and throughout the stack so your business is better protected. These unique out-of-the-box security measures, including AI-powered threat detection capabilities, help protect your users, your data, and your business.",
              "tr_badgePreTitle": ""
          },
          {
            "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
            "tr_badgeTitle": "BUILT-IN TOOLS TO KEEP USERS PRODUCTIVE FROM VIRTUALLY ANYWHERE",
            "tr_badgeSubTitle": "Offices are everywhere and so are PCs. Intel vPro® provides modern hardware-based management capabilities to help businesses ease the burden of PC fleet management, simplify support and improve end-user experiences, all while enabling sustainable computing practices.",
            "tr_badgePreTitle": ""
          }]
        }
        }]
      }]
    },
  {
    "contentType": "STD_LNL_ULTRA_5",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
          "tileBG": "",
          "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
          "panelType1":"gaming-processorType12",
          "panelType2":"",
          "tr_pretitle": "More than a great PC, an AI PC",
          "tr_subtitle1": "POWERED BY INTEL® CORE™ ULTRA PROCESSORS (SERIES 2)",
          "tr_subtitle2": "It's possible on your Intel AI PC",
          "tr_subtitle": "Intel's most efficient, secure and high-performing mobile processor<sup>39</sup>",
          "tr_gen_core_title": "Intel® Core™ Ultra 5 processor (Series 2)",
          "titleArray": [{
            "tr_title": "<span class='spec-heading'>More creative</span> with AI tools to create faster",
          },{
            "tr_title": "<span class='spec-heading'>More productive</span> with time-saving AI assistance",
          },{
            "tr_title": "<span class='spec-heading'>More secure</span> with built-in data protection",
          }],
        }
        }]
      }]
    },
  {
    "contentType": "STD_LNL_ULTRA_7",
    "iposTypes": [{
      "iposType": "STANDARD",
      "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Processor",
          "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
          "tileBG": "",
          "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
          "panelType1":"gaming-processorType12",
          "panelType2":"",
          "tr_pretitle": "More than a great PC, an AI PC",
          "tr_subtitle1": "POWERED BY INTEL® CORE™ ULTRA PROCESSORS (SERIES 2)",
          "tr_subtitle2": "It's possible on your Intel AI PC",
          "tr_subtitle": "Intel's most efficient, secure and high-performing mobile processor<sup>39</sup>",
          "tr_gen_core_title": "Intel® Core™ Ultra 7 processor (Series 2)",
          "titleArray": [{
            "tr_title": "<span class='spec-heading'>More creative</span> with AI tools to create faster",
          },{
            "tr_title": "<span class='spec-heading'>More productive</span> with time-saving AI assistance",
          },{
            "tr_title": "<span class='spec-heading'>More secure</span> with built-in data protection",
          }],
        }
        }]
      }]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
            "tileBG": "",
            "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
            "panelType1":"gaming-processorType12",
            "panelType2":"",
            "tr_pretitle": "More than a great PC, an AI PC",
          "tr_subtitle1": "POWERED BY INTEL® CORE™ ULTRA PROCESSORS (SERIES 2)",
          "tr_subtitle2": "It's possible on your Intel AI PC",
            "tr_subtitle": "Intel's most efficient, secure and high-performing mobile processor<sup>39</sup>",
            "tr_gen_core_title": "Intel® Core™ Ultra 5 processor (Series 2)"
          }
          }]
        }]
      },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Processor",
            "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
            "tileBG": "",
            "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
            "panelType1":"gaming-processorType12",
            "panelType2":"",
            "tr_pretitle": "More than a great PC, an AI PC",
          "tr_subtitle1": "POWERED BY INTEL® CORE™ ULTRA PROCESSORS (SERIES 2)",
          "tr_subtitle2": "It's possible on your Intel AI PC",
            "tr_subtitle": "Intel's most efficient, secure and high-performing mobile processor<sup>39</sup>",
            "tr_gen_core_title": "Intel® Core™ Ultra 7 processor (Series 2)"
          }
          }]
        }]
      }

  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_tileTitle1": "Dedicated Graphics",
            "tr_title": "WHAT IS A GRAPHICS PROCESSING UNIT?",
            "tr_pretitle": "",
            "panelType1":"",
            "panelType2":"badgesAndHeadings", 
            "tr_subtitle": "The graphics processing unit or GPU creates the images you see on your screen.",
            "badgeIcons": [{
              "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
              "tr_badgeTitle": "Integrated GPU",
              "tr_badgeSubTitle": "An integrated GPU uses the same RAM as the rest of the computer, making it more energy efficient.",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
              "tr_badgeTitle": "Discrete GPU",
              "tr_badgeSubTitle": "This is the most powerful class of GPU because it has its own RAM and other resources that are devoted to building exceptional graphics.",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
              "tr_badgeTitle": "Hybrid GPU",
              "tr_badgeSubTitle": "This is the most powerful class of GPU because it has its own RAM and other resources that are devoted to building exceptional graphics.",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "assets/images/video-editing.png",
              "tr_badgeTitle": "Streamlined photo and video editing",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "assets/images/binge-4k.svg",
              "tr_badgeTitle": "Binge 4K video content",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "assets/images/games.svg",
              "tr_badgeTitle": "Play popular games in stunning detail",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "assets/images/graphics_dual_gpus.svg",
              "tr_badgeTitle": "Dual GPUs",
              "tr_badgeSubTitle": "Some systems, like those with Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics, can take advantage of both the on-processor and discrete GPU to distribute the graphics workload.",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
              "tr_badgeTitle": "Integrated GPU",
              "tr_badgeSubTitle": "An integrated GPU is built into the same chip as the main processor or CPU. This type of GPU uses the same RAM and other resources as the rest of the computer, making it more energy efficient and allowing for a thinner, lighter device.",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
              "tr_badgeTitle": "Dedicated GPU",
              "tr_badgeSubTitle": "This is the most powerful class of GPU because it has its own RAM and other resources that are devoted to building exceptional graphics.",
              "tr_badgePreTitle": ""
            }
            ]
          }
        }]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_title": "",
            "tr_pretitle": "",
            "tr_subtitle": "",
            "panelType1":"",
            "panelType2":"gaming-graphics",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "Standard",
              "tr_badgeSubTitle": "Up to 4 GB VRAM",
              "tr_badgePreTitle": "",
              "imgUrl": ""
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "Competitive",
              "tr_badgeSubTitle": "Up to 8 GB VRAM",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "Ultra",
              "tr_badgeSubTitle": "Greater than 8 GB VRAM",
              "tr_badgePreTitle": ""
            }
            ]
          }
        }]
      }]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_title": "Intense graphics power",
            "tr_pretitle": "[graphic_display]",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "The most powerful graphics processing requires a graphics card that can focus exclusively on creating beautiful, crisp images and fluid animation.",
            "badgeUrl": "",
          }
        }]
      }]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_title": "Rich and vibrant graphics built in",
            "tr_pretitle": "Intel® Iris® Plus graphics",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "Intel® Iris® Plus graphics let you immerse yourself in 4K HDR video and full HD gaming with no extra graphics card required.",
            "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
          }
        }]
      }]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_title": "Stunning visuals, effortless performance",
            "tr_pretitle": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "Intel® Graphics delivers transformational integrated graphics performance to support fluid designing for creators and rich gaming experiences.",
            "badgeUrl": ""
          }
        }]
      }]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_title": "Intel® Iris® Pro graphics",
            "tr_pretitle": "",
            "tr_subtitle": "",
            "panelType1":"header-IconTop",
            "panelType2":"",
            "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
          }
        }]
      }]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_title": "Intel® UHD Graphics featuring X<sup class='small_text_sup_xe'>e</sup> Architecture",
            "tr_pretitle": "Immersive visual experiences",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "",
            "tr_text1": "Experience streamlined photo and video editing, binge premium video content in 4K or play popular games in stunning detail.",
            "tr_photo_text":"Streamlined photo and video editing",
            "tr_video_text":"Binge 4K video content",
            "tr_game_text":"Play popular games in stunning detail",
            "badgeUrl": ""
          }
        }]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_title": "[graphic_display]",
            "panelType1":"header-BadgeIconTopGaming",
            "panelType2":"", 
            "tr_pretitle": "MUCH MORE THAN PRETTY PICTURES",
            "tr_subtitle": "Working closely with the processor (or CPU), the graphics card (or GPU) helps determine how your games run and how they look. The VRAM or video RAM, dedicated just to making graphics, is a quick way to know where your GPU stands.",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "<span class ='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
              "tr_badgeSubTitle": "",
            }]
          }
        }]
      }]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [{
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_title": "[graphic_display]",
            "panelType1":"header-BadgeIconTopGaming",
            "panelType2":"", 
            "tr_pretitle": "FOR INTENSE GAMING GRAPHICS",
            "tr_subtitle": "The most powerful graphics processing requires a separate graphics card that can focus exclusively on creating beautiful, crisp images and fluid animation",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "<span class ='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
              "tr_badgeSubTitle": "",
            }]
          }
        }]
      }]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_title": "[graphic_display]",
            "panelType1":"header-BadgeIconTopGaming",
            "panelType2":"", 
            "tr_pretitle": "FOR INTENSE GAMING GRAPHICS",
            "tr_subtitle": "The most powerful graphics processing requires a separate graphics card that can focus exclusively on creating beautiful, crisp images and fluid animation",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "<span class ='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
              "tr_badgeSubTitle": "",
            }]
          }
        }]
      },{
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_title": "[graphic_display]",
            "panelType1":"header-BadgeIconTopGaming",
            "panelType2":"", 
            "tr_pretitle": "MUCH MORE THAN PRETTY PICTURES",
            "tr_subtitle": "Working closely with the processor (or CPU), the graphics card (or GPU) helps determine how your games run and how they look. The VRAM or video RAM, dedicated just to making graphics, is a quick way to know where your GPU stands.",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "<span class ='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
              "tr_badgeSubTitle": "",
            }]
          }
        }]
      }]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_title": "Intense graphics power",
            "tr_pretitle": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "The most powerful graphics processing requires a graphics card that can focus exclusively on creating beautiful, crisp images and fluid animation.",
            "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
          }
        }]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_title": "[graphic_display]",
            "tr_pretitle": "FOR INTENSE GAMING GRAPHICS",
            "panelType1":"header-BadgeIconTopGaming",
            "panelType2":"", 
            "tr_subtitle": "The most powerful graphics processing requires a graphics card that can focus exclusively on creating beautiful, crisp images and fluid animation.",
            "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "<span class ='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
              "tr_badgeSubTitle": "",
            }],
            "titleArray": [{
              "detailPageName": "LESS_THAN_0",
              "tr_subtitle": "The most powerful graphics processing requires a separate graphics card that can focus exclusively on creating beautiful, crisp images and fluid animation",
              "tr_title": ""
            }, {
              "detailPageName": "0_TO_4",
              "tr_subtitle": "Delivers plenty of graphics power to meet your game’s minimum requirements.",
              "tr_title": ""
            }, {
              "detailPageName": "4_TO_8",
              "tr_subtitle": "For higher-end players that demand competition-level gaming",
              "tr_title": ""
            }, {
              "detailPageName": "GREATER_THAN_8",
              "tr_subtitle": "For advanced gamers who also use their PC for heavy content creation",
              "tr_title": ""
            }],
          }
        }]
      }]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_title": "Vibrant video and immersive gaming",
            "tr_pretitle": "[graphic_display]",
            "tr_subtitle": "Get the most out of your high-resolution screen with Intel® HD Graphics and enjoy a rich multimedia experience and built-in graphics processing.",
            "badgeUrl": ""
          }
        }]
      }]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_title": "Powering vibrant video and images",
            "tr_pretitle": "[graphic_display]",
            "tr_subtitle": "Get more detail and brilliant color from your streaming video and photos.",
            "badgeUrl": ""
          }
        }]
      }]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_title": "Amazing creating and gaming",
            "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
            "panelType1":"header-IconTop",
            "panelType2":"",
            "tr_subtitle": "From immersive entertainment and advanced content creation to mainstream gaming, Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics brings game-changing graphics performance to thin and light PCs.",
            "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
          }
        }]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_pretitle": "FOR INTENSE GAMING GRAPHICS",
            "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
            "panelType1":"header-BadgeIconTopGaming",
            "panelType2":"", 
            "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
            "tr_subtitle": "The most powerful graphics processing requires a separate graphics card that can focus exclusively on creating beautiful, crisp images and fluid animation",
            "badgeIcons": [
            {
              "badgeUrl": "",
              "tr_badgeTitle": "<span class ='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
              "tr_badgeSubTitle": "",
            }
            ]
          }
        }]
      }]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_title": "Unleash your creativity",
            "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX graphics",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "Flow through creative projects and immerse yourself in a wide variety of games on the combined power of the on-processor graphics and the first Intel® discrete graphics engine.",
            "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
          }
        }]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Graphics",
            "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX graphics",
            "tr_pretitle": "FOR INTENSE GAMING GRAPHICS",
            "panelType1":"header-BadgeIconTopGaming",
            "panelType2":"", 
            "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
            "tr_subtitle": "The most powerful graphics processing requires a separate graphics card that can focus exclusively on creating beautiful, crisp images and fluid animation",
            "badgeIcons": [
            {
              "badgeUrl": "",
              "tr_badgeTitle": "<span class ='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
              "tr_badgeSubTitle": "",
            }
            ]
          }
        }]
      }]
    },{
      "contentType": "NON_INTEL",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_tileTitle": "Graphics",
            "tr_title": "Beautiful graphics built in",
            "tr_subtitle": "Enjoy your games, movies and web videos with amazing detail and clarity.",
            "badgeUrl": ""
          }
        }]
      }]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [{
        "iposType": "DEFAULT",
        "deviceTypes": [{
        "deviceType": "DEFAULT",
        "details": {
          "tr_tileTitle": "Graphics",
          "badgeUrl": "assets/images/arc-badge.svg",
          "tileBG": "",
          "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
          "tr_subtitle": "",
          "panelType1":"header-BadgeIconTop-arc",
          "panelType2":"", 
          "tr_title": "",
        }
        }],
        "factTagTypes":[{
          "factTagType": "Arc_Graphics_Panel_Content",
            "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                      "targetURL": "badgeIconUrl",
                      "tr_specTitle": "",
                      "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                      "tr_specTitle": "Graphics",
                      "tr_specText": "Boost your workflows."
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                      "tr_specTitle": "Graphics",
                      "tr_specText": "Supercharge your gaming."
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                      "tr_specTitle": "Graphics",
                      "tr_specText": "Supercharge your gaming."
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                      "tr_specTitle": "Graphics",
                      "tr_specText": "Level up your game."
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                      "tr_specTitle": "UNLEASH YOUR CREATIVITY",
                      "tr_specText": "Accelerate your productivity wherever you go with the most comprehensive media capabilities, like an <strong>industry-first AV1 hardware encode</strong> and AI-accelerated content creation.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                      "tr_specTitle": "UNLEASH YOUR CREATIVITY",
                      "tr_specText": "Accelerate your productivity with the most comprehensive media capabilities, like an <strong>industry-first AV1 hardware encode</strong> and AI-accelerated content creation.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                      "tr_specTitle": "UNLEASH YOUR CREATIVITY",
                      "tr_specText": "Experience cutting-edge AI-accelerated content creation with <strong>industry-first AV1 hardware encode</strong> and the advanced <strong>X<sup>e</sup> Media Engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                      "tr_specTitle": "UNLEASH YOUR CREATIVITY",
                      "tr_specText": "Experience cutting-edge AI-accelerated content creation with <strong>industry-first AV1 hardware encode</strong> and the advanced <strong>X<sup>e</sup> Media Engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                      "tr_specTitle": "UNLEASH YOUR CREATIVITY",
                      "tr_specText": "Experience cutting-edge AI-accelerated content creation with <strong>industry-first AV1 hardware encode</strong> and the advanced <strong>X<sup>e</sup> Media Engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                      "tr_specTitle": "PORTABILITY MEETS PERFORMANCE",
                      "tr_specText": "Play the latest games on the go and get supercharged performance and fidelity with modern gaming capabilities. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling): </strong>Intel® AI-enhanced upscaling technology for more performance and fluid gaming. <br/><br/>Support of <strong>DirectX* 12 Ultimate: </strong>Take your gaming to a new level of realism with graphics technologies, such as ray tracing and variable rate shading."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                      "tr_specTitle": "TAKE CONTROL OF YOUR GAMES",
                      "tr_specText": "<strong>Intel® Arc™ Control</strong> allows you to refine your experience with an intuitive and modern layout. Get seamless driver updates, optimize your games, and more."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                      "tr_specTitle": "TAKE CONTROL OF YOUR GAMES",
                      "tr_specText": "<strong>Intel® Arc™ Control</strong> allows you to refine your experience with an intuitive and modern layout. Get seamless driver updates, optimize your games, and more."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                      "tr_specTitle": "TAKE CONTROL OF YOUR GAMES",
                      "tr_specText": "Get seamless driver updates and optimize many of your favorite games with <strong>Intel® Arc™ Control</strong> software."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                      "tr_specTitle": "MAXIMIZE YOUR INTEL® PLATFORM",
                      "tr_specText": "Pair with compatible Intel® Core™ processors to unleash <strong>Intel® Deep Link Technology</strong> to enhance your creation, gaming, and streaming experience."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                      "tr_specTitle": "MAXIMIZE YOUR INTEL® SYSTEM",
                      "tr_specText": "Pair with compatible Intel® Core™ processors to unleash <strong>Intel® Deep Link Technology</strong> to enhance your creation, gaming, and streaming experience."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                      "tr_specTitle": "MAXIMIZE YOUR INTEL® PLATFORM",
                      "tr_specText": "Pair with compatible Intel® Core™ processors to unleash <strong>Intel® Deep Link Technology</strong> to enhance your creation, gaming, and streaming experience."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                      "tr_specTitle": "MAXIMIZE YOUR INTEL® PLATFORM",
                      "tr_specText": "Pair with compatible Intel® Core™ processors to unleash <strong>Intel® Deep Link Technology</strong> to enhance your creation, gaming, and streaming experience."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                      "tr_specTitle": "MAXIMIZE YOUR INTEL® SYSTEM",
                      "tr_specText": "Pair with compatible Intel® Core™ processors to unleash <strong>Intel® Deep Link Technology</strong> to enhance your creation, gaming, and streaming experience."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                      "tr_specTitle": "TAKE CENTER STAGE",
                      "tr_specText": "Broadcast seamlessly with advanced streaming technologies enabled by <strong>Intel® Arc™ Control</strong> software."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                      "tr_specTitle": "TAKE CENTER STAGE",
                      "tr_specText": "Broadcast seamlessly with advanced streaming technologies enabled by <strong>Intel® Arc™ Control</strong> software."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                      "tr_specTitle": "EXPERIENCE IMMERSIVE HIGH-PERFORMANCE GAMING",
                      "tr_specText": "Put your best performance first with the latest AAA games at high resolutions and high refresh rates. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling): </strong>Intel® AI-enhanced upscaling technology for more performance and fluid gaming. <br/><br/>Support of <strong>DirectX* 12 Ultimate: </strong>Take your gaming to a new level of realism with graphics technologies, such as ray tracing and variable rate shading."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                      "tr_specTitle": "EXPERIENCE IMMERSIVE HIGH-PERFORMANCE GAMING",
                      "tr_specText": "Put your best performance first with the latest AAA games at high resolutions and high refresh rates. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling): </strong>Intel® AI-enhanced upscaling technology for more performance and fluid gaming. <br/><br/>Support of <strong>DirectX* 12 Ultimate: </strong>Take your gaming to a new level of realism with graphics technologies, such as ray tracing and variable rate shading."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                      "tr_specTitle": "GIVE YOUR GAMES A BOOST",
                      "tr_specText": "Take your gaming experience to the next level with: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling): </strong>Intel® AI-enhanced upscaling technology for more performance and fluid gaming. <br/><br/>Support of <strong>DirectX* 12 Ultimate: </strong>Take your gaming to a new level of realism with graphics technologies, such as ray tracing and variable rate shading."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                      "tr_specTitle": "EXPERIENCE ADVANCED FAST-PACED GAMING",
                      "tr_specText": "Enjoy smooth gaming on many popular AAA games, and high-refresh gaming from high-octane esports shooters to fast-paced RTS hits. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling): </strong>Intel® AI-enhanced upscaling technology for more performance and fluid gaming. <br/><br/>Support of <strong>DirectX* 12 Ultimate: </strong>Take your gaming to a new level of realism with graphics technologies such as ray tracing and variable rate shading."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                      "tr_specTitle": "EXPERIENCE IMMERSIVE HIGH-PERFORMANCE GAMING",
                      "tr_specText": "Put your best performance first with the latest AAA games at high resolutions and high refresh rates. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling): </strong>Intel® AI-enhanced upscaling technology for more performance and fluid gaming. <br/><br/>Support of <strong>DirectX* 12 Ultimate: </strong>Take your gaming to a new level of realism with graphics technologies, such as ray tracing and variable rate shading."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                      "tr_specTitle": "Graphics",
                      "tr_specText": "A graphics universe with room for everyone"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                      "tr_specTitle": "ACCELERATE YOUR FRAMES, ACCELERATE YOUR GAMES",
                      "tr_specText": "Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling technology (X<sup class='small_text_sup_xe'>e</sup>SS) takes your gaming to the next level with AI-enhanced upscaling—for peak performance with high image fidelity. X<sup class='small_text_sup_xe'>e</sup>SS is optimized for Intel® Arc™ graphics products so you can take full advantage of Intel® X<sup>e</sup> Matrix Extensions (Intel® XMX) hardware acceleration."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                      "tr_specTitle": "THE ULTIMATE VISUALS ARE HERE",
                      "tr_specText": "With full support for DirectX* 12 Ultimate on all Intel® Arc™ graphics, you’ll enjoy stunning, immersive gameplay thanks to the latest graphics technologies like ray tracing, variable rate shading, mesh shading, and sampler feedback—the fundamentals of next-gen gaming."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                      "tr_specTitle": "SEAMLESS PHOTO, VIDEO, AND MEDIA CREATION",
                      "tr_specText": "Unleash your imagination and captivate audiences with rich digital content creation using a hyper-advanced media engine—augmented by AI and accelerated with Intel® technology. Create spectacular content, powered by graphics with support for all current leading media formats while keeping up to date with the most advanced AV1 video encode capabilities."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                      "tr_specTitle": "EXPERIENCE CUTTING-EDGE AI CREATION AND GAMING",
                      "tr_specText": "Intel® Arc™ graphics unlock powerful AI capabilities, from advanced creation to immersive gaming. Get the most out of today’s advanced creation software, including text-to-image generation and AI-accelerated video editing. Plus, enjoy high-resolution gameplay through AI-accelerated X<sup class='small_text_sup_xe'>e</sup>SS upscaling."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                      "tr_specTitle": "Graphics",
                      "tr_specText": "Professional graphics begins here"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                      "tr_specTitle": "GREAT FOR ON-THE-GO CREATIVES, DESIGNERS, AND ENGINEERS",
                      "tr_specText": "Available on select Intel® Core™ Ultra processors H-series, built-in Intel® Arc™ Pro graphics unlock 2x graphics performance improvements with Independent Software Vendor (ISV) certifications for added reliability and provide efficient AI and ray tracing hardware acceleration for stunning content creation on-the-go.<br/><br/><span class ='expandable_heading'>Software certifications<br/></span><br/>Intel has worked hand-in-hand with hundreds of software companies over the years, and this unmatched experience goes into our Intel® Arc™ Pro A-series graphics cards. Certifications are as important to us as they are to you."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                      "tr_specTitle": "MAKING AI ATTAINABLE",
                      "tr_specText": "This GPU range includes a wide selection of great technology, including support for your compatible AI tools with built-in dedicated AI acceleration where you need it."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                      "tr_specTitle": "RAY TRACING WITHOUT THE PREMIUM",
                      "tr_specText": "Graphics cards need to do more than simply push pixels to displays, they also need to accelerate the creation of beautiful images. Ray tracing hardware technology built into Intel® Arc™ Pro A-series graphics allows your compatible software to start to explore these new pro workflows. Ray tracing is typically thought of as only available on expensive graphics, but Intel’s latest professional graphics range breaks that expectation."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                      "tr_specTitle": "EMPOWERING CREATORS WITH IMMERSIVE DISPLAYS",
                      "tr_specText": "With full support for DirectX* 12 Ultimate on all Intel® Arc™ graphics, you’ll enjoy stunning, immersive gameplay thanks to the latest graphics technologies like ray tracing, variable rate shading, mesh shading, and sampler feedback—the fundamentals of next-gen gaming."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                      "tr_specTitle": "DYNAMICALLY OPTIMIZED IMAGES",
                      "tr_specText": "With dedicated hardware decoding on the Pro GPUs, Dolby Vision* helps to add greater depth, sharper contrast, and more colors to your compatible display. Dolby Vision* unlocks the full potential of HDR technology by dynamically optimizing the image quality based on your service, display device, and platform to deliver stunning visuals every time."
                  }
                }, {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                      "tr_specTitle": "EXPERTLY VERIFIED SOFTWARE",
                      "tr_specText": "Intel has worked hand-in-hand with hundreds of software companies over the years, and this unmatched experience goes into the professional Intel® Arc™ Pro A-series graphics range. Certifications and software workflow qualifications are as important to us as they are to you."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                      "tr_specTitle": "RAY TRACING WITHOUT THE PREMIUM",
                      "tr_specText": "Graphics cards need to do more than simply push pixels to displays, they also need to accelerate the creation of beautiful images. Ray tracing hardware technology built into Intel® Arc™ Pro A-series graphics allows your compatible software to start to explore these new pro workflows. Ray tracing is typically thought of as only available on expensive graphics, but Intel’s latest professional graphics range breaks that expectation."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                      "tr_specTitle": "MULTIPLE LARGE DISPLAYS WITH ONE SMALL MOBILE GPU",
                      "tr_specText": "Intel® Arc™ Pro graphics for laptops can support up to four ultra-large displays to enhance your workflow efficiencies. Add high dynamic range (HDR) support too and you have all you need for that next social-media-worthy home or office multi-display setup."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                      "tr_specTitle": "FULL MEMORY BANDWIDTH POTENTIAL",
                      "tr_specText": "The bandwidth of your graphics card is an important consideration in a workstation. Get it wrong and you can slow down professional workflows, which is why we made sure the Intel® Arc™ Pro A-series GPU has support for modern PCIe* 4.0, without penalizing backwards compatibility. Graphics memory is further enhanced by a high-bandwidth speed, allowing project data to be accessed by your software even faster."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                      "tr_specTitle": "BREATHE LIFE INTO EVERY FRAME",
                      "tr_specText": "<span class ='expandable_heading'>AI-boosted. Intel-backed.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS is an AI-enhanced upscaling technology that boosts your performance without sacrificing the beauty of every frame — so you can experience your gameplay the way it was meant to be."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                      "tr_specTitle": "REAL-TIME.<br/> REAL IMMERSIVE.<br/> REALISM AT ITS FINEST.",
                      "tr_specText": "More realistic worlds await. With built-in ray tracing, you’ll experience everything from lifelike shadows and reflections to masterful lighting. In other words, it’s a visual gaming experience you won’t want to miss."
                  }
                }, 
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                      "tr_specTitle": "YOUR BEST GAMEPLAY, SHARED AT ITS BEST",
                      "tr_specText": "Give your audience the seamless gameplay they deserve to see. With the hardware-accelerated AV1 encoding capabilities of Intel® Arc™ graphics, they’ll be captivated by your every move. Higher visual quality at the same bitrate equals streaming your best while saving bandwidth."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                      "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                      "tr_specTitle": "Welcome all creators",
                      "tr_specText": "New and enhanced AI experiences are available right at your fingertips. With advanced AI accelerators embedded across Intel® Arc™ A-series graphics, spend more time creating and less time waiting across many applications.<br/><br/>Take your video creation to the next level and lead the way with high-quality, storage-efficient AV1 video, powered by advanced AV1 hardware capabilities built into Intel® Arc™ graphics."
                  }
                },                
            ]    
          }]    
      }]
  }],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Operating System",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"header-IconTop", 
            "badgeUrl": "",
            "tr_title": "Everything you need to get the job done",
            "tr_pretitle": "Windows* 10",
            "tr_subtitle": "Powerful performance that’s thin, light and built to last — you can have it all. With great battery life, beautiful touchscreens* and the latest Solid-State Drives (SSDs), you’ll find what you’re looking for in a Windows* 10 PC.",
            "tr_noteSection": "*Hardware dependent",
            "titleArray": [{
              "tr_title": "Less is more",
              "tr_subtitle": "Today’s PCs typically use less power, so you get more life out of your battery — on average, eight-plus hours of video playback.**",
              "tr_noteSection": "**Battery life varies significantly with settings, usage and other factors."          
            }]            
          }
        }]
      },
      {
        "iposType": "GAMING",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Operating System",
            "tileBG": "",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-IconTopGaming",
            "panelType2":"header-IconTopGaming", 
            "tr_pretitle": "EVERYTHING YOU NEED TO GET THE JOB DONE",
            "tr_title": "Windows* 10",
            "tr_subtitle": "Powerful performance that’s thin, light and built to last — you can have it all. With great battery life, beautiful touchscreens* and the latest Solid-State Drives (SSDs), you’ll find what you’re looking for in a Windows* 10 PC.",
            "tr_noteSection": "*Hardware dependent",
            "titleArray": [{
              "tr_title": "LESS IS MORE",
              "tr_subtitle": "Today’s PCs typically use less power, so you get more life out of your battery — on average, eight-plus hours of video playback.**",
              "tr_noteSection": "**Battery life varies significantly with settings, usage and other factors."
            }],
          }
        }]
      }]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Operating System",
            "tileBG": "",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_title": "Streamlined for safety and performance",
            "tr_pretitle": "Windows* 10 in S mode",
            "tr_subtitle": "When running in Windows* 10 in S mode you’ll get an extra layer of protection that allows you to work, play and explore with the confidence that your device is secure."
          }
        }]
      },
      {
        "iposType": "GAMING",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Operating System",
            "tileBG": "",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-IconTopGaming",
            "panelType2":"", 
            "tr_pretitle": "STREAMLINED FOR SAFETY AND PERFORMANCE",
            "tr_title": "Windows* 10 in S mode",
            "tr_subtitle": "When running in Windows* 10 in S mode you’ll get an extra layer of protection that allows you to work, play and explore with the confidence that your device is secure."
          }
        }]
      }]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Operating System",
            "tileBG": "",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"",
            "tr_title": "Do great things",
            "tr_pretitle": "Windows*",
            "tr_subtitle": "Performance, price and plenty to choose from. From innovative 2-in-1s to thin and light laptops, touchscreens, detachable keyboards — no matter your budget or style, you can find a Windows* PC to match."
          }
        }]
      },
      {
        "iposType": "GAMING",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Operating System",
            "tileBG": "",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-IconTopGaming",
            "panelType2":"", 
            "tr_pretitle": "DO GREAT THINGS",
            "tr_title": "Windows*",
            "tr_subtitle": "Performance, price and plenty to choose from. From innovative 2-in-1s to thin and light laptops, touchscreens, detachable keyboards — no matter your budget or style, you can find a Windows* PC to match."
          }
        }]
      }]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Operating System",
            "tileBG": "",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"",
            "tr_title": "",
            "tr_pretitle": "LINUX*",
            "tr_subtitle": ""
          }
        }]
      }]
    },    
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Operating System",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"image-only-panel",
            "panelType2":"header-IconTop", 
            "badgeUrl": "",
            "tr_title": "",
            "tr_pretitle": "",            
            "badgeIcons": [{
              "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
            },{
              "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
            },{
              "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
            },{
              "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
            },{
              "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
            },{
              "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
            },{
              "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
            },{
              "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
            },{
              "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
            },{
              "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
            },{
              "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
            },{
              "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
            },{
              "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
            }]           
          }
        }]
      }
    ]
    }
  ],
  "memory":[
    {
      "contentType": "COMMON",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Memory",
            "tileBG": "",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"",
            "panelType2":"badgesAndHeadings",
            "tr_title": "HOW MUCH RAM DO YOU NEED?",
            "tr_subtitle": "",
            "badgeIcons": [{
              "tr_badgeTitle": "Everyday - 2 GB UP TO 8 GB RAM",
              "tr_badgeSubTitle": "Ideal for those who mainly use their PC for word processing and web surfing and tend to focus on one program at a time.",
            },{
              "tr_badgeTitle": "Powerful - 8 GB UP TO 16 GB RAM",
              "tr_badgeSubTitle": "With more RAM your applications will run faster and you’ll also be able to run more applications simultaneously.",
            },{
              "tr_badgeTitle": "Advanced - MORE THAN 16 GB RAM",
              "tr_badgeSubTitle": "If you’re interested in gaming, photo or video editing, or 3D modeling, you’ll need additional RAM to handle these intensive programs.",
            }]
          }
        }]
      }]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "MEMORY",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"",
            "badgeUrl": "assets/images/std12th_memory.svg",
            "tr_title": "[XX] GB RAM for everyday multitasking",
            "tr_subtitle": "Your memory will allow basic applications like word processing and web browsing to load and run smoothly.",
            "badgeIcons": [{
              "tr_badgeTitle": "<span class ='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
              "tr_badgeSubTitle": "",
            }]
          }
        }]
      }]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "MEMORY",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"",
            "badgeUrl": "assets/images/std12th_memory.svg",
            "tr_title": "[XX] GB RAM for powerful multitasking",
            "tr_subtitle": "If you use multiple programs at the same time, [memory_total] GB of memory will allow you to switch back and forth with ease.",
            "badgeIcons": [{
              "tr_badgeTitle": "<span class ='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
              "tr_badgeSubTitle": "",
            }]
          }
        }]
      }]
    },
    {
      "contentType": "BEST",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "MEMORY",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"",
            "badgeUrl": "assets/images/std12th_memory.svg",
            "tr_title": "[XX] GB RAM for advanced multitasking",
            "tr_subtitle": "You’ll have plenty of memory to run several intensive programs at the same time without slowing down your PC.",
            "badgeIcons": [{
              "tr_badgeTitle": "<span class ='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
              "tr_badgeSubTitle": "",
            }]
          }
        }]
      }]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "MEMORY",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"",
            "badgeUrl": "assets/images/std12th_memory.svg",
            "tr_title": "[Value1] GB memory = [Value2] GB Intel® Optane™ memory + [Value3] GB RAM",            
            "tr_subtitle": "This combination of memory solutions will give you accelerated hard drive performance from Intel® Optane™ memory plus more responsiveness from your RAM.",
            "badgeIcons": [{
              "tr_badgeTitle": "<span class ='value'>[XX] GB<br/></span><span class='subtitle-value'>MEMORY</span>",
              "tr_badgeSubTitle": "",
            }]
          }
        }]
      }]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Intel® Optane™ memory",
            "tileBG": "",
            "detailBGUrl": "",
            // "badgeUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"badgesAndHeadings",
            "tr_title": "Less waiting. More of what you love.",
            "tr_subtitle": "When Intel® Optane™ memory works with your system memory it gives you faster access to your frequently used programs, so they launch and load the instant you need them.",
            "tr_pretitle": "[XX] GB Intel® Optane™ memory",
            "badgeUrl": "assets/images/intel_optane_memory.svg",
            "titleArray": [{
              "tr_title": "WHAT DOES MEMORY DO FOR MY PC?"
            }],
            "badgeIcons": [{
              "badgeUrl": "assets/images/memory-optane-faster-content.png",
              "tr_badgeTitle": "Faster content creation",
              "tr_badgeSubTitle": ""
            },{
              "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
              "tr_badgeTitle": "More responsive gaming",
              "tr_badgeSubTitle": ""
            },{
              "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
              "tr_badgeTitle": "Easier multitasking",
              "tr_badgeSubTitle": ""
            },{
              "badgeUrl": "assets/images/memory-optane-open-programs.png",
              "tr_badgeTitle": "Open programs faster",
              "tr_badgeSubTitle": ""
            }]
          }
        }]
      },
      {
        "iposType": "GAMING",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Intel® Optane™ memory",
            "tileBG": "",
            "detailBGUrl": "",
            "badgeUrl": "assets/images/intel_optane_memory.svg",
            "panelType2":"",
            "panelType1":"badgesAndHeadings-memory",
            "tr_title": "<span class='intelOptanMemoryTitle'>Accelerate your gaming</span>",
            "tr_pretitle": "[XX] GB Intel® Optane™ memory",
            "tr_pretitle1": '[XX] GB Intel® Optane™ memory accelerates your gaming',
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgePreTitle": "Launch games up to<br/><span class=\"optane-game\">98% faster</span><br/>Off the data drive with an [optane_legal_processor_name] processor with Intel® Optane™ memory<sup>15</sup>",
              "tr_badgeTitle": "",
              "tr_badgeSubTitle": ""
            },{
              "badgeUrl": "",
              "tr_badgePreTitle": "Load levels up to<br/><span class=\"optane-game\">4.1X faster</span><br/>Off the data drive with an [optane_legal_processor_name] processor with Intel® Optane™ memory<sup>16</sup>",
              "tr_badgeTitle": "",
              "tr_badgeSubTitle": ""
            }]
          }
        },
        {
          "deviceType":"DEFAULT_A",
          "details": {
          "tr_tileTitle": "Intel® Optane™ memory",
          "tileBG": "",
          "detailBGUrl": "",
          "badgeUrl": "assets/images/intel_optane_memory.svg",
          "panelType2":"",
          "panelType1":"badgesAndHeadings-memory",
          "tr_title": "<span class='intelOptanMemoryTitle'>Accelerate your gaming</span>",
          "tr_pretitle": "[XX] GB Intel® Optane™ memory",
          "tr_pretitle1": '[XX] GB Intel® Optane™ memory accelerates your gaming',
          "badgeIcons": [{
          "badgeUrl": "",
          "tr_badgePreTitle": "Launch games up to<br/><span class=\"optane-game\">98% faster</span><br/>Off the data drive with an [optane_legal_processor_name] processor with Intel® Optane™ memory<sup>15</sup>",
          "tr_badgeTitle": "",
          "tr_badgeSubTitle": ""
          },{
          "badgeUrl": "",
          "tr_badgePreTitle": "Load levels up to<br/><span class=\"optane-game\">4.1X faster</span><br/>Off the data drive with an [optane_legal_processor_name] processor with Intel® Optane™ memory<sup>16</sup>",
          "tr_badgeTitle": "",
          "tr_badgeSubTitle": ""
          }]
          }
          }]
      }]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "MEMORY",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"",
            "tr_title": "[Value1] GB memory = [Value2] GB Intel® Optane™ memory + [Value3] GB RAM",
            "tr_subtitle": "This combination of memory solutions will give you accelerated hard drive performance from Intel® Optane™ memory plus more responsiveness from your RAM.",
            "titleArray": [{
              "tr_title":"[XX] GB of RAM for multitasking",
              "tr_subtitle": "If you use multiple programs at the same time, [memory_total] GB of memory will allow you to switch back and forth with ease.",
          },
          {
              "tr_title":"For everyday tasks",
              "tr_subtitle": "Your memory will allow basic applications like word processing and web browsing to load and run smoothly.",
          }]
          }
        }]
      }]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [{
        "iposType": "GAMING",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "MEMORY",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTopGaming",
            "panelType2":"gaming-graphics",
            "tr_title": "[XX] GB RAM",
            "tr_pretitle": "FOR MORE MULTI-TASKING",
            "tr_subtitle": "RAM gives your processor access to program files, letting your CPU access them faster and work on multiple tasks at once.",
            "badgeIcons": [{
              "tr_badgeTitle": "Standard memory",
               "tr_badgeSubTitle": "Up to 8 GB RAM",
            },{
              "tr_badgeTitle": "Advanced memory",
              "tr_badgeSubTitle": "More than 8 GB and up to 16 GB RAM",
            },{
              "tr_badgeTitle": "Elite memory",
              "tr_badgeSubTitle": "Greater than 16 GB RAM",
            }]
          }
        }]
      }]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [{
        "iposType": "GAMING",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "MEMORY",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTopGaming",
            "panelType2":"gaming-graphics",
            "tr_title": "[XX] GB for more multitasking",
            "tr_pretitle": "FOR MORE MULTI-TASKING",
            "tr_subtitle": "RAM gives your processor access to program files, letting your CPU access them faster and work on multiple tasks at once.",
            "gamingPanleType2":"gaming-panelType1",
            "badgeIcons": [{
              "tr_badgeTitle": "Standard memory",
               "tr_badgeSubTitle": "Up to 8 GB RAM",
            },{
              "tr_badgeTitle": "Advanced memory",
              "tr_badgeSubTitle": "More than 8 GB and up to 16 GB RAM",
            },{
              "tr_badgeTitle": "Elite memory",
              "tr_badgeSubTitle": "Greater than 16 GB RAM",
            }],
            "enhancementCompare": {
              "tr_title": "HOW DOES THIS MEMORY COMPARE?",
              "panelType2":"enhancement-compare",
              "tr_table_heading": "Current device",
              "imgUrl": "assets/images/processorCompareTick.svg",
              "badgeIcons": [
                {
                  "imgUrl": "",
                  "tr_badgeIconTile" : "Standard",
                  "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                },
                {
                  "imgUrl":  "", 
                  "tr_badgeIconTile" : "Advanced",
                  "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                },
                {
                  "imgUrl": "",      
                  "tr_badgeIconTile" : "Elite",
                  "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                }
              ],
            }
          }
        }]
      }]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Memory",
            "tileBG": "",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"",
            "panelType2":"badgesAndHeadings",
            "tr_title": "HOW DOES THIS MEMORY COMPARE?",
            "tr_subtitle": "",
            "gamingPanleType2":"gaming-panelType1",
            "enhancementCompare": {
              "tr_title": "HOW DOES THIS MEMORY COMPARE?",
              "panelType2":"enhancement-compare",
              "tr_table_heading": "Current device",
              "imgUrl": "assets/images/processorCompareTickBlack.svg",
              "badgeIcons": [
                {
                  "imgUrl": "",
                  "tr_badgeIconTile" : "Everyday",
                  "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                },
                {
                  "imgUrl":  "", 
                  "tr_badgeIconTile" : "Powerful",
                  "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                },
                {
                  "imgUrl": "",      
                  "tr_badgeIconTile" : "Advanced",
                  "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                }
              ],
            }
          }
        }]
      }]
    }
  ],
  "storage":[
    {
      "contentType": "COMMON",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Storage",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"badgesAndHeadings", 
            "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",//add badge url for gaming optane type 
            "tr_title": "HOW MUCH CAN YOUR PC HOLD?",
            "tr_subtitle": "No need to guess. We’ll show you exactly what you can store on this PC.",
            "badgeIcons": [{
              "badgeUrl": "assets/images/storage-songs-badge.png",
              "tr_badgeTitle": "<span class='storage-preTile'>Create a library of</span> <br/><span class=\"storage-standard\">[num_songs] songs<sup>†</sup></span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "<sup>†</sup>based on an average of 5 MB per song",
              "imgUrl": ""
            },{
              "badgeUrl": "assets/images/storage-game-badge.png",
              "tr_badgeTitle": "<span class='storage-preTile'>Create a library of</span><br/><span class=\"storage-standard\">[num_games] games<sup>†</sup></span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "<sup>†</sup>based on an average of 40 GB per game",
              "imgUrl": ""
            },{
              "badgeUrl": "assets/images/storage-videos-badge.png",
              "tr_badgeTitle": "<span class='storage-preTile'>Storage for</span><br/><span class=\"storage-standard\">[num_videos] videos<sup>§</sup></span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "<sup>§</sup>based on an average of 5 GB per video",
              "imgUrl": ""
            },{
              "badgeUrl": "assets/images/storage-photos-badge.png",
              "tr_badgeTitle": "<span class='storage-preTile'>Take and store</span><br/><span class=\"storage-standard\">[num_photos] photos<sup>^</sup></span>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "<sup>^</sup>based on an average of 4 MB per photo",
              "imgUrl": ""
            }],
            "titleArray": [{
              "storageType":"HDD",
              "tr_pretitle": "[capacity] GB HDD",
          },
          {
              "storageType":"SSD",
              "tr_pretitle": "[capacity] GB SSD",
          },
          {
              "storageType":"SSD_AND_HDD",
              "tr_pretitle": "",               
          },
          {
              "storageType":"OPTANE",
              "tr_pretitle":"[total_optane_memory] GB Intel® Optane™ memory + [convertedSSD] GB SSD",
          }]
          }
        }]
      }]
    },
    {
      "contentType": "SSD",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "STORAGE",
            "tileBG": "",
            "badgeUrl": "assets/images/std12storage.svg",
            "detailBGUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"",
            "tr_preTitle":"[capacity] GB SSD Storage",
            "tr_preTitle1":"[capacity] TB SSD Storage",
            "titleArray": [{
              "detailPageName": "WIN10S_Storage",
              "tr_subtitle": "This PC features a solid-state drive with [capacity] GB of storage. This means you'll be able to save an impressive number of photos, videos and programs and access them much faster than you would on a hard disk drive.",
              "tr_title": "A place for everything on your PC"
            },{
              "detailPageName": "WIN10S_Storage-1000",
              "tr_subtitle": "This PC features a solid-state drive with [capacity] TB of storage. This means you'll be able to save an impressive number of photos, videos and programs and access them much faster than you would on a hard disk drive.",
              "tr_title": "This device has impressive storage"
            }],                    
            "badgeIcons": [{
              "tr_badgeTitle": "<span class ='value'>[capacity] GB<br/></span><span class='subtitle-value'>STORAGE</span>",
              "tr_badgeTitle1": "<span class ='value'>[capacity] TB<br/></span><span class='subtitle-value'>STORAGE</span>",
              "tr_badgeSubTitle": ""
            },{
              "tr_badgeTitle": "SSD",
              "tr_badgeSubTitle": ''
            }],      
          }
        }]
      }]      
    },
    {
      "contentType": "HDD",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "STORAGE",
            "badgeUrl": "assets/images/std12storage.svg",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"badgeAndHeadings",
            "tr_preTitle":"[capacity] GB HDD Storage",
            "tr_preTitle1":"[capacity] TB HDD Storage",
            "titleArray": [{
              "detailPageName": "WIN10S_Storage",
              "tr_subtitle": "Hard disk drives are a good choice for people who want a large amount of storage directly on their PC.",
              "tr_title": "A place for everything on your PC"
            },{
              "detailPageName": "WIN10S_Storage-1000",
              "tr_subtitle": "Hard disk drives are a good choice for people who want a large amount of storage directly on their PC.",
              "tr_title": "This device has impressive storage"
            }],                    
            "badgeIcons": [{
              "tr_badgeTitle": "<span class ='value'>[capacity]<br/></span><span class='subtitle-value'>STORAGE</span>",
              "tr_badgeSubTitle": ""
            },{
              "tr_badgeTitle": "HDD",
              "tr_badgeSubTitle": ''
            }],      
          }
        }]
      },
      {
        "iposType": "GAMING",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "STORAGE",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTopGaming",
            "panelType2":"",
            "badgeUrl": "assets/images/intel_optane_memory.svg",
            "tr_title": "",
            "tr_pretitle": "HOW MUCH CAN YOUR PC HOLD?",
            "tr_subtitle": "No need to guess. We’ll show you exactly what you can store on this PC.",
            "titleArray": [{
              "tr_title": "[XX] GB HDD"
            },{
              "tr_title": "[XX] GB SSD"
            },{
              "tr_title": "[total_capacity] GB Intel® SSD accelerated by [optane_memory_size] GB Intel® Optane™ memory",
            },{
              "tr_title": "[total_capacity] TB Intel® SSD accelerated by [optane_memory_size] GB Intel® Optane™ memory",
            },{
              "tr_title": "[convertedSSD] GB SSD + [convertedHDD] GB HDD"
            },{
              "tr_title": "[convertedSSD] TB SSD + [convertedHDD] GB HDD"
            },{
              "tr_title": "[convertedSSD] GB SSD + [convertedHDD] TB HDD"
            },{
              "tr_title": "[convertedSSD] TB SSD + [convertedHDD] TB HDD"
            }],
            "badgeIcons": [
              {
                "storageType" : "GB",
                "items" : [
                  {
                    "tr_badgeTitle": "<span class ='value'>[capacity] GB<br/></span><span class='subtitle-value'>STORAGE</span>",
                    "tr_badgeSubTitle": 'SSD'
                  },
                  {
                    "tr_badgeTitle": "<span class ='value'>[capacity] GB<br/></span><span class='subtitle-value'>STORAGE</span>",
                    "tr_badgeSubTitle": 'HDD'
                  },
                  {
                    "tr_badgeTitle": "<span class ='value'>[capacity] GB<br/></span><span class='subtitle-value'>STORAGE</span>",
                    "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> + </span><br/>HDD</span>"
                  }
                ]
              },
              {
                "storageType" : "TB",
                "items" : [
                  {
                    "tr_badgeTitle": "<span class ='value'>[capacity] TB<br/></span><span class='subtitle-value'>STORAGE</span>",
                    "tr_badgeSubTitle": 'SSD'
                  },
                  {
                    "tr_badgeTitle": "<span class ='value'>[capacity] TB<br/></span><span class='subtitle-value'>STORAGE</span>",
                    "tr_badgeSubTitle": 'HDD'
                  },
                  {
                    "tr_badgeTitle": "<span class ='value'>[capacity] TB<br/></span><span class='subtitle-value'>STORAGE</span>",
                    "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> + </span><br/>HDD</span>"
                  }
                ]
              }
            ]     
          }
        }]
      }]      
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "STORAGE",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"",
            "badgeUrl": "assets/images/std12storage.svg",
            "tr_preTitle":"[convertedSSD] GB SSD + [convertedHDD] GB HDD Storage",
            "tr_preTitle1":"[convertedSSD] TB SSD + [convertedHDD] GB HDD Storage",
            "tr_preTitle2":"[convertedSSD] GB SSD + [convertedHDD] TB HDD Storage",
            "tr_preTitle3":"[convertedSSD] TB SSD + [convertedHDD] TB HDD Storage",
            "titleArray": [{
              "tr_title": "A place for everything on your PC",
              "tr_title1": "This device has impressive storage",
              "tr_subtitle": "This PC features a solid-state drive with [convertedSSD] GB of storage and a [convertedHDD] GB hard disk drive. This will give you both enormous capacities along with fast storage performance.",
              "tr_subtitle1": "This PC features a solid-state drive with [convertedSSD] TB of storage and a [convertedHDD] GB hard disk drive. This will give you both enormous capacities along with fast storage performance.",
              "tr_subtitle2": "This PC features a solid-state drive with [convertedSSD] GB of storage and a [convertedHDD] TB hard disk drive. This will give you both enormous capacities along with fast storage performance.",
              "tr_subtitle3": "This PC features a solid-state drive with [convertedSSD] TB of storage and a [convertedHDD] TB hard disk drive. This will give you both enormous capacities along with fast storage performance.",
            }],                  
            "badgeIcons": [{
              "tr_badgeTitle": "<span class ='value'>[capacity] GB<br/></span><span class='subtitle-value'>STORAGE</span>",
              "tr_badgeTitle1": "<span class ='value'>[capacity] TB<br/></span><span class='subtitle-value'>STORAGE</span>",
              "tr_badgeSubTitle": ""
            },{
              "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> + </span><br/>HDD</span>",
              "tr_badgeSubTitle": ''
            }],      
          }
        }]
      }]      
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "STORAGE",
            "tileBG": "",
            "detailBGUrl": "", 
            "badgeUrl": "assets/images/intel_optane_storage.svg",  
            "badgeUrl2": "assets/images/std12storage.svg",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"badgesAndHeadings",         
            "tr_subtitle": "Get accelerated access to high-capacity storage with Intel® Optane™ memory with SSD.",
            "tr_title": "Giant files welcome",  
            "tr_pretitle": "[total_capacity] GB Intel® SSD accelerated by [optane_memory_size] GB Intel® Optane™ memory",
            "tr_pretitle1": "[total_capacity] TB Intel® SSD accelerated by [optane_memory_size] GB Intel® Optane™ memory",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "SSD",
              "tr_badgeSubTitle": ''
            }],
            "titleArray": [{
              "tr_subtitle": "This revolutionary storage solution is the first to combine Intel® Optane™ memory and an SSD on one chip. Here’s what it does for you:",
              "tr_title": "WHAT IS INTEL® OPTANE™ MEMORY WITH SSD?"
            }],
            "panel2BadgeIcons": [{
              "badgeUrl": "assets/images/memory-frequency-badge.png",
              "tr_badgeTitle": "Open what you need fast",
              "tr_badgeSubTitle": "By preloading frequently used files and programs, you can spend more time creating and less time waiting.",
              "tr_badgePreTitle": "",
              "imgUrl": ""
            },
            {
              "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
              "tr_badgeTitle": "Storage plus speed",
              "tr_badgeSubTitle": "You’ll have the space you need for media files with the rapid performance of an SSD.",
              "tr_badgePreTitle": "",
              "imgUrl": ""
            },
            {
              "badgeUrl": "assets/images/memory-time-badge.png",
              "tr_badgeTitle": "More responsive over time",
              "tr_badgeSubTitle": "Intel® Optane™ memory with SSD learns the way you work, making your PC more efficient.",
              "tr_badgePreTitle": "",
              "imgUrl": ""
            }]
          }
        }]
      }]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "STORAGE",
            "tileBG": "",
            "panelType1":"header-IconTop",
            "panelType2":"",
            "detailBGUrl": "",
            "tr_subtitle": "Keep all your media close at hand with plenty of reliable storage.",
            "tr_title": "Space for all of your photos, videos and songs"     
          }
        }]
      }]      
    }
  ],
  "display":[
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Display",
            "tileBG": "",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"badgesAndHeadings",
            "tr_badgeTitle": "",
            "tr_title": "[screenResolution] million pixels to swipe, pinch and amaze",
            "tr_subtitle": "Interact with your PC just like a smartphone with intuitive controls and sharper, more vivid detail.",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "<span class ='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
              "tr_badgeSubTitle": ""
            },{
              "badgeUrl": "assets/images/display-badge.svg",
              "tr_badgeTitle": "[screenResolutionType]",
              "tr_badgeSubTitle": "Resolution: [screenResolutionX] X [screenResolutionY]",
              "tr_badgePreTitle": "Pixels: [screenResolution] million"
            },{
              "badgeUrl": "assets/images/display-inch-badge.svg",
              "tr_badgeTitle": "[screenDiagonal] inch display",
              "tr_badgeSubTitle": "Screen Height: [screenHeight] inches",
              "tr_badgePreTitle": "Screen Width: [YY] inches"
            },{
              "badgeUrl": "assets/images/display-touchscreen-badge.svg",
              "tr_badgeTitle": "Touchscreen display",
              "tr_badgeSubTitle": "",
            }]
          }
        }]
      }]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Display",
            "tileBG": "",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"badgesAndHeadings",
            "tr_badgeTitle": "",
            "tr_title": "Immerse yourself in [screenResolution] million pixels",
            "tr_subtitle": "It’s all about the pixels. The more you have, the sharper and more vivid your images will be.",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "<span class ='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
              "tr_badgeSubTitle": ""
            },{
              "badgeUrl": "assets/images/display-badge.svg",
              "tr_badgeTitle": "[screenResolutionType]",
              "tr_badgeSubTitle": "Resolution: [screenResolutionX] X [screenResolutionY]",
              "tr_badgePreTitle": "Pixels: [screenResolution] million"
            },{
              "badgeUrl": "assets/images/display-inch-badge.svg",
              "tr_badgeTitle": "[screenDiagonal] inch display",
              "tr_badgeSubTitle": "Screen Height: [screenHeight] inches",
              "tr_badgePreTitle": "Screen Width: [YY] inches"
            }]
          }
        }]
      },
      {
        "iposType": "GAMING",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Display",
            "tileBG": "",
            "detailBGUrl": "",
            "badgeUrl": "assets/images/badges/display-badge.svg",
            "panelType1":"header-IconTopGaming",
            "panelType2":"badgesAndHeadings",
            "tr_title": "[screenDiagonal] inch <br/>[screenResolutionX] x [screenResolutionY]",
            "tr_pretitle": "THE WINDOW TO YOUR GAMING WORLDS",
            "tr_subtitle": "Just like a TV, your main concerns for a gaming display will be size and resolution.",
            "badgeIcons": [
              {
              "badgeUrl": "assets/images/display-badge.svg",
              "tr_badgeTitle": "[screenResolutionType]",
              "tr_badgeSubTitle": "Resolution: [screenResolutionX] X [screenResolutionY]",
              "tr_badgePreTitle": "Pixels: [screenResolution] million"
            },{
              "badgeUrl": "assets/images/display-inch-badge.svg",
              "tr_badgeTitle": "[screenDiagonal] inch display",
              "tr_badgeSubTitle": "Screen Height: [screenHeight] inches",
              "tr_badgePreTitle": "Screen Width: [screenWidth] inches"
            },{
              "badgeUrl": "assets/images/display-touchscreen-badge.svg",
              "tr_badgeTitle": "Touchscreen display"
            }]
          }
        }]
      }]
    },
    {
      "contentType": "4K",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Display",
            "tileBG": "",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"badgesAndHeadings",
            "tr_badgeTitle": "4K",
            "tr_title": "This device has an impressive display",
            "tr_subtitle": "With more than eight times the pixels of HD, your videos and games will take on a whole new level of sharpness and color.",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "4K",
              "tr_badgeSubTitle": ""
            },{
              "badgeUrl": "assets/images/display-badge.svg",
              "tr_badgeTitle": "[screenResolutionType]",
              "tr_badgeSubTitle": "Resolution: [screenResolutionX] X [screenResolutionY]",
              "tr_badgePreTitle": "Pixels: [screenResolution] million"
            },{
              "badgeUrl": "assets/images/display-inch-badge.svg",
              "tr_badgeTitle": "[screenDiagonal] inch display",
              "tr_badgeSubTitle": "Screen Height: [screenHeight] inches",
              "tr_badgePreTitle": "Screen Width: [YY] inches"
            }]
          }
        }]
      }]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Display",
            "tileBG": "",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"",
            "badgeUrl": "",
            "tr_title": "",
            "tr_subtitle": "",
            "titleArray": [{
              "tr_title": "[screenResolution] million pixels to tap swipe and touch",
              "tr_subTitle": "View and interact with the things you love in sharper, more vivid detail.",
            },{
              "tr_title": "[screenResolution] million pixels to enhance your digital world",
              "tr_subTitle": "It’s all about the pixels. The more you have, the sharper and more vivid your images will be."
            },{
              "tr_title": "This device has an impressive display",
              "tr_subTitle": "With nearly four times the pixels of HD, your videos and games will take on a whole new level of sharpness and color."
            }]
          }
        }]
      }]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Connectivity",
            "detailBGUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"badgesAndHeadings",
            "tr_subtitle": "Thunderbolt™ 3 technology delivers the fastest, most versatile connections to any dock, display or data device.", // CONNECTIVITY Detail page sub Title
            "tr_title": "One port that does it all",
            "tr_title1": "ONE PORT THAT DOES IT ALL",
            "titleArray": [{
              "tr_title": "HOW CAN I USE THUNDERBOLT™ 3 TECHNOLOGY?",
              "tr_subTitle": "",
            }],
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "Thunderbolt™ 3 technology"
            },{
              "badgeUrl": "assets/images/thunderbolt-4k.png",
              "tr_badgeTitle": "4K Monitors",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "Connect to displays with stunning resolution, contrast and color"
            },
            {
              "badgeUrl": "assets/images/thunderbolt-graphic.png",
              "tr_badgeTitle": "External graphics card",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "Instantly give any PC a major graphics upgrade"
            }, {
              "badgeUrl": "assets/images/thunderbolt-networking.png",
              "tr_badgeTitle": "Fast networking",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "Provides quick peer-to-peer connections"
            }
            ]
          }
        }]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Connectivity",
            "detailBGUrl": "",
            "tr_subtitle": "Thunderbolt™ 3 technology transfers data at a higher speed than conventional USBs, letting you move files quickly and easily integrate peripherals such as multiple monitors or external graphics cards.", // CONNECTIVITY Detail page sub Title
            "tr_title": "Thunderbolt™ 3 technology",
            "panelType1":"header-IconTopGaming",
            "panelType2":"gaming-graphics",
            "tr_pretitle": "LIGHTNING FAST DATA",
            "badgeUrl": "assets/images/badges/connectivity-badge.svg",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "USB* 2.0",
              "tr_badgeSubTitle": "480 Mbps",
              "tr_badgePreTitle": "",
              "imgUrl": "" //gaming detail badge thunderbolt.gif
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "USB* 3.0",
              "tr_badgeSubTitle": "5 Gbps",
              "tr_badgePreTitle": ""
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "Thunderbolt™ 3 technology",
              "tr_badgeSubTitle": "40 Gbps",
              "tr_badgePreTitle": ""
            }
            ]
          }
        }]
      }]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Connectivity",
            "detailBGUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"badgesAndHeadings",
            "tr_subtitle": "<ul><li>One universal port and cable supports all your favorite accessories</li> <li>Reliable notebook charging</li> <li>Robust certification offers peace of mind</li></ul>", // CONNECTIVITY Detail page sub Title
            "tr_title": "Truly universal cable connectivity",
            "tr_title1": "TRULY UNIVERSAL CABLE CONNECTIVITY",
            "tr_noteSection": "<sup>†</sup>Based on minimum specification requirements",
            "badgeUrl": "assets/images/badges/connectivity-badge.svg",
            "titleArray": [{
              "tr_title": "HOW CAN I USE THUNDERBOLT™ 4 TECHNOLOGY?",
              "tr_subTitle": "",
            }],
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "Thunderbolt™ 4 technology"
            },{
              "badgeUrl": "assets/images/thunderbolt-create.png",
              "tr_badgeTitle": "Create",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "Connect an external monitor and fast storage to view and edit photos and videos."
            },
            {
              "badgeUrl": "assets/images/thunderbolt-dock.png",
              "tr_badgeTitle": "Dock",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "Create a simple, clutter-free desk set up by connecting your laptop to all your accessories with a single cable."
            }, {
              "badgeUrl": "assets/images/thunderbolt-game.png",
              "tr_badgeTitle": "Game",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "Add more SSD and HDD capacity with speeds up to 3,000 MB/s to store game libraries and media files."
            }, {
              "badgeUrl": "assets/images/thunderbolt-peformance.png",
              "tr_badgeTitle": "Maximum Performance<sup>†</sup>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "4x faster than USB* 3.2 <br/>2x faster than HDMI* 2"
            }
            ]
          }
        }]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Connectivity",
            "detailBGUrl": "",
            "panelType1":"header-IconTopGaming",
            "panelType2":"badgesAndHeadings",
            "tr_subtitle": "Enjoy the same amazing performance of Thunderbolt™ 3 technology with even more capabilities that allow you to easily connect and use the latest Thunderbolt™ docks, monitors, fast storage or any USB* accessory.", // CONNECTIVITY Detail page sub Title
            "tr_title": "Thunderbolt™ 4 technology",
            "tr_pretitle": "TRULY UNIVERSAL CABLE CONNECTIVITY",
            "tr_noteSection": "<sup>†</sup>Based on minimum specification requirements",
            "badgeUrl": "assets/images/badges/connectivity-badge.svg",
            "badgeIcons": [{
              "badgeUrl": "assets/images/thunderbolt-icon.png", // icon
              "tr_badgeTitle": "",
              "tr_badgeSubTitle": "One Thunderbolt™ 4 universal cable can replace all your USB-C* cables to make cable selection fast and easy.",
            },
            {
              "badgeUrl": "assets/images/thunderbolt-dock.png",
              "tr_badgeTitle": "",
              "tr_badgeSubTitle": "Thunderbolt™ 4 technology docks offer up to four Thunderbolt™ ports for more flexibility to connect any of your accessories.",
            }, {
              "badgeUrl": "assets/images/thunderbolt-peformance.png",
              "tr_badgeTitle": "Maximum Performance<sup>†</sup>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "4x faster than USB* 3.2 <br/>2x faster than HDMI* 2"
            }
            ]
          }
        }]
      },
      {
        "iposType": "EVO",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Connectivity",
            "detailBGUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"badgesAndHeadings",
            "tr_subtitle": "<ul><li>One universal port and cable supports all your favorite accessories</li> <li>Reliable notebook charging</li> <li>Robust certification offers peace of mind</li></ul>", // CONNECTIVITY Detail page sub Title
            "tr_title": "Maximize your experience with Thunderbolt™ accessories",
            "tr_noteSection": "<sup>†</sup>Based on minimum specification requirements",
            "badgeUrl": "assets/images/evo_thunderbolt4.png",
            "tileBG": "assets/images/badges/connectivity-badge.svg",
            "titleArray": [{
              "tr_title": "HOW CAN I USE THUNDERBOLT™ 4 TECHNOLOGY?",
              "tr_subTitle": "",
            }],
            "badgeIcons": [{
              "badgeUrl": "assets/images/thunderbolt-create.png",
              "tr_badgeTitle": "Create",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "Connect an external monitor and fast storage to view and edit photos and videos."
            },
            {
              "badgeUrl": "assets/images/thunderbolt-dock.png",
              "tr_badgeTitle": "Dock",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "Create a simple, clutter-free desk set up by connecting your laptop to all your accessories with a single cable."
            }, {
              "badgeUrl": "assets/images/thunderbolt-game.png",
              "tr_badgeTitle": "Game",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "Add more SSD and HDD capacity with speeds up to 3,000 MB/s to store game libraries and media files."
            }, {
              "badgeUrl": "assets/images/thunderbolt-peformance.png",
              "tr_badgeTitle": "Maximum Performance<sup>†</sup>",
              "tr_badgeSubTitle": "",
              "tr_badgePreTitle": "4x faster than USB* 3.2 <br/>2x faster than HDMI* 2"
            }
            ]
          }
        }]
      }]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"badgesAndHeadings", 
            "badgeUrl": "",
            "tr_subtitle": "The most common types of Wi&#8209;Fi are:",
            "tr_title": "UNDERSTANDING WI&#8209;FI STANDARDS",
            "badgeIcons": [{
              "badgeUrl": "assets/images/wifi-6-badge.png",
              "tr_badgeTitle": "Wi&#8209;Fi 6",
              "tr_badgeSubTitle": "Wi-Fi 6 allows for greater speeds even when many users are using the same signal.<sup>9</sup>",
            },
            {
              "badgeUrl": "assets/images/wifi-6E-badge.png",
              "tr_badgeTitle": "Intel® Wi&#8209;Fi 6 (Gig+)",
              "tr_badgeSubTitle": "With Intel’s latest Wi-Fi innovation, you can achieve speeds faster than gigabit Wi-Fi even with multiple users on the same signal.<sup>9</sup>",
            },
            {
              "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
              "tr_badgeTitle": "Intel® Wi&#8209;Fi 6E (Gig+)",
              "tr_badgeSubTitle": "The biggest Wi&#8209;Fi advancement in 20 years, Wi&#8209;Fi 6E opens up new high-speed channels for improved performance and reliability and less interference.",
            },
            {
              "badgeUrl": "assets/images/wifi-6-badge.png",
              "tr_badgeTitle": "Wi&#8209;Fi 6",
              "tr_badgeSubTitle": "Wi-Fi 6 allows for greater speeds even when many users are using the same signal.<sup>9</sup>",
            },
            {
              "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
              "tr_badgeTitle": "Intel® Killer™ Wi&#8209;Fi 6E (Gig+)",
              "tr_badgeSubTitle": "All the benefits of Wi&#8209;Fi 6E paired with the Intel® Killer™ Prioritization Engine that shifts bandwidth to the websites and applications that need it most.",
            },
            {
              "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
              "tr_badgeTitle": "Wi&#8209;Fi 6E",
              "tr_badgeSubTitle": "With less competition on your high-speed channels, you’ll enjoy improved performance and reliability.",
            },
            {
              "badgeUrl": "assets/images/wifi-6-badge.png",
              "tr_badgeTitle": "Intel® Wi&#8209;Fi 6",
              "tr_badgeSubTitle": "Wi-Fi 6 allows for greater speeds even when many users are using the same signal.<sup>9</sup>",
            },
            {
              "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
              "tr_badgeTitle": "Intel® Killer™ Wi&#8209;Fi 6 (Gig+)",
              "tr_badgeSubTitle": "With less interference from Wi&#8209;Fi 6 and the intelligent performance of the Intel® Killer™ Prioritization Engine, your online experience will be faster and more responsive.⁶",
            },
            {
              "badgeUrl": "assets/images/standard_generic_wifi.png",
              "tr_badgeTitle": "Wi&#8209;Fi 6",
              "tr_badgeSubTitle": "With less competition on your high-speed channels, you’ll enjoy improved performance and reliability.",
            },
            {
              "badgeUrl": "assets/images/wifi-7-badge.png",
              "tr_badgeTitle": "Intel® Wi&#8209;Fi 7 (5 Gig)",
              "tr_badgeSubTitle": "Greater than 5 Gbps PC Wi-Fi speeds with consistent ultra-low latency - share files in seconds vs minutes.",
            },
            {
              "badgeUrl": "assets/images/killerwifi-7-badge.png",
              "tr_badgeTitle": "Intel® Killer™ Wi&#8209;Fi 7",
              "tr_badgeSubTitle": 'Intel® Killer™ Wi-Fi 7 (5 Gig) is the latest technology enabling "Extreme" reliability, low-latency and speeds through AI-based networking optimization.',
            },
            {
              "badgeUrl": "assets/images/wifi-6-badge.png",
              "tr_badgeTitle": "Wi&#8209;Fi 6",
              "tr_badgeSubTitle": "Wi-Fi 6 allows for greater speeds even when many users are using the same signal.<sup>9</sup>",
            },
            {
              "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
              "tr_badgeTitle": "Intel® Wi&#8209;Fi 6E (Gig+)",
              "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) speeds greater 1Gbps, reliability, and low-latency with use of the 6GHz channel.",
            },
            {
              "badgeUrl": "assets/images/wifi-6-badge.png",
              "tr_badgeTitle": "Wi&#8209;Fi 6",
              "tr_badgeSubTitle": "Better efficiency, capacity and speed versus Wi-Fi 5 on congested networks.",
            }
          ]
          }
        }]
      }]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"", 
            "badgeUrl": "",
            "tr_subtitle": "Stay better connected with Wi&#8209;Fi [XX] and enjoy the internet with faster speeds<sup>†</sup> and more reliability.",
            "tr_title": "Get improved range and speed",
            "tr_noteSection": "<sup>†</sup>Actual wireless throughput and/or range will vary.",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi [XX]",
            }]
          }
        }]
      },{
          "iposType": "GAMING",
          "deviceTypes": [{
            "deviceType": "DEFAULT",
            "details": {
              "tr_tileTitle": "Wi&#8209;Fi",
              "detailBGUrl": "",
              "badgeUrl": "",
              "panelType1":"header-IconTopGaming",
              "panelType2":"gaming-graphics", 
              "tr_subtitle": "In the age of multiplayer and online gaming, staying connected is more important than ever. In addition to speed, you also want a wireless signal that can maintain those speeds at a distance.",
              "tr_title": "[XX]",
              "tr_pretitle": "THE CONNECTION IS KEY",
              "badgeIcons": [{
                "badgeUrl": "",
                "tr_badgeTitle": "Wi&#8209;Fi 6",
              },
              {
                "badgeUrl": "",
                "tr_badgeTitle": "Wi&#8209;Fi 6E",
              },
              {
                "badgeUrl": "",
                "tr_badgeTitle": "[XX]",
              }
            ]
            }
          }]
        }]
    }, {
      "contentType": "VERSION_6",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "badgeUrl": "assets/images/wifi-6E-badge.png",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "Connect with Intel® Wi&#8209;Fi 6 (Gig+) to Wi&#8209;Fi 6 routers with 160 MHz channel support to enable faster connections and greater reliability for more active users.",
            "tr_title": "Nearly 3x faster speeds with Intel® <span class='no-warp'>Wi&#8209;Fi 6 </span>(Gig+)<sup>†</sup>",
            "tr_noteSection": "<sup>†</sup>Than standard AC Wi&#8209;Fi. Select features only available with certain SKUs. Check with manufacturer for details. For more Wi&#8209;Fi information please visit intel.com/wifi6disclaimers"
          }
        }, {
          "deviceType": "MODEL_AX101",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "badgeUrl": "assets/images/wifi-6-badge.png",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "Entry-level dual band Intel® Wi&#8209;Fi 6 supports improved performance for basic connectivity needs, like email and Internet browsing on congested networks.<sup>9</sup>",
            "tr_title": "Essential Wi&#8209;Fi",
            "tr_noteSection": ""
          }
        }]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-IconTopGaming",
            "panelType2":"gaming-graphics", 
            "tr_subtitle": "In the age of multiplayer and online gaming, staying connected is more important than ever. In addition to speed, you also want a wireless signal that can maintain those speeds at a distance.",
            "tr_title": "[XX]",
            "tr_pretitle": "THE CONNECTION IS KEY",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6",
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6E",
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "[XX]",
            }
          ]
          }
        }]
      }]
    }, {
      "contentType": "VERSION_6E",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
            "tr_subtitle": "Having these new Wi&#8209;Fi channels means you don’t have to share bandwidth with the older devices in your neighborhood, giving you smooth streaming, fast browsing and seamless remote working and learning.",
            "tr_title": "Connect without the competition",
            "tr_noteSection": ""
          }
        }]
      }]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [{
            "deviceType": "DEFAULT",
            "details": {
              "tr_tileTitle": "Wi&#8209;Fi",
              "detailBGUrl": "",
              "panelType1":"header-IconTop",
              "panelType2":"", 
              "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
              "tr_subtitle": "Stay better connected with [XX] and enjoy the internet with faster speeds<sup>†</sup> and more reliability.",
              "tr_title": "Get improved range and speed",
              "tr_noteSection": "<sup>†</sup>Actual wireless throughput and/or range will vary.",
              "badgeIcons": [{
                "badgeUrl": "",
                "tr_badgeTitle": "Wi&#8209;Fi 6E",
              }]
            }
          }]
        },{
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-IconTopGaming",
            "panelType2":"gaming-graphics", 
            "tr_subtitle": "In the age of multiplayer and online gaming, staying connected is more important than ever. In addition to speed, you also want a wireless signal that can maintain those speeds at a distance.",
            "tr_title": "[graphic_display]",
            "tr_pretitle": "THE CONNECTION IS KEY",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6",
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6E",
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "[XX]",
            }
          ]
          }
        }]
      }]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes":[{
          "deviceType":"DEFAULT",          
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "tileBG": "",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"",
            "tr_title": "Built-in ultrafast Wi&#8209;Fi",
            "tr_subtitle": "Unplug and stay connected to your online world.",
          }
        }]
      }]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
            "tr_subtitle": "With dedicated high-speed lanes for less interference and the Intel® Killer™ Prioritization Engine that puts gaming first, online gaming just got a serious upgrade.",
            "tr_title": "Leave the competition behind",
            "tr_noteSection": ""
          }
        }]
      },
       {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "badgeUrl": "assets/images/[image_path].png",
            "panelType1":"header-IconTopGaming",
            "panelType2":"gaming-graphics", 
            "tr_subtitle": "With dedicated high-speed lanes for less interference and the Intel® Killer™ Prioritization Engine that puts gaming first, online gaming just got a serious upgrade.",
            "tr_title": "[XX]",
            "tr_pretitle": "LEAVE THE COMPETITION BEHIND",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6",
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6E",
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "[XX]",
            }
          ]
          }
        }]
      }]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [{
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "badgeUrl": "assets/images/[image_path].png",
            "panelType1":"header-IconTopGaming",
            "panelType2":"gaming-graphics", 
            "tr_subtitle": "With dedicated high-speed lanes for less interference that puts gaming first, online gaming just got a serious upgrade.",
            "tr_title": "[XX]",
            "tr_pretitle": "LEAVE THE COMPETITION BEHIND",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6",
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6E",
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "[XX]",
            }
          ]
          }
        }]
      }
      ]},
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
            "tr_subtitle": "Intel® Killer™ Wi&#8209;Fi automatically accelerates network traffic for the applications that need it the most so your streaming videos have sharp detail and buffer less.",
            "tr_title": "A faster, smarter way to connect.⁶",
            "tr_noteSection": ""
          }
        }]
      },
       {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
            "panelType1":"header-IconTopGaming",
            "panelType2":"gaming-graphics", 
            "tr_subtitle": "In the age of multiplayer and online gaming, staying connected is more important than ever. In addition to speed, you also want a wireless signal that can maintain those speeds at a distance.",
            "tr_title": "[XX]",
            "tr_pretitle": "THE CONNECTION IS KEY",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6"
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6E"
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "[XX]"
            }
          ]
          }
        }]
      }]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"", 
            "badgeUrl": "",
            "tr_subtitle": "Stay better connected with Wi&#8209;Fi 5 and enjoy the internet with faster speeds<sup>†</sup> and more reliability.",
            "tr_title": "Get improved range and speed",
            "tr_noteSection": "<sup>†</sup>Actual wireless throughput and/or range will vary.",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "",
            }]
          }
        }]
      }]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [{
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-IconTopGaming",
            "panelType2":"gaming-graphics", 
            "tr_subtitle": "In the age of multiplayer and online gaming, staying connected is more important than ever. In addition to speed, you also want a wireless signal that can maintain those speeds at a distance.",
            "tr_title": "[XX]",
            "tr_pretitle": "LEAVE THE COMPETITION BEHIND",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6"
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6E"
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "[XX]"
            }
          ]
          }
        }]
      }]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "badgeUrl": "assets/images/wifi-7-badge.png",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "Wi-Fi 7 features will build upon, and elevate, earlier Wi-Fi generations. This will mean not only faster speeds, but dramatically improved responsiveness and reliability for immersive consumer experiences and sophisticated future technologies.",
            "tr_title": "Wired-like PC Networking",
            "tr_noteSection": ""
          }
        }]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-IconTopGaming",
            "panelType2":"gaming-graphics", 
            "tr_subtitle": "Wi-Fi 7 features will build upon, and elevate, earlier Wi-Fi generations. This will mean not only faster speeds, but dramatically improved responsiveness and reliability for immersive consumer experiences and sophisticated future technologies.",
            "tr_title": "[XX]",
            "tr_pretitle": "TRANSFORMING THE USER EXPERIENCE",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6",
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6E",
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "[XX]",
            }
          ]
          }
        }]
      }]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "badgeUrl": "assets/images/killerwifi-7-badge.png",
            "panelType1":"header-IconTop",
            "panelType2":"", 
            "tr_subtitle": "",
            "tr_title": "Enhanced speed, latency, and reliability through AI-based networking optimization",
            "tr_noteSection": ""
          }
        }]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Wi&#8209;Fi",
            "detailBGUrl": "",
            "badgeUrl": "assets/images/killerwifi-7-badge.png",
            "panelType1":"header-IconTopGaming",
            "panelType2":"gaming-graphics", 
            "tr_subtitle": "Enhanced speed, latency, and reliability through AI-based networking optimization",
            "tr_title": "[XX]",
            "tr_pretitle": "WIRED-LIKE RESPONSIVENESS",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6",
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "Wi&#8209;Fi 6E",
            },
            {
              "badgeUrl": "",
              "tr_badgeTitle": "[XX]",
            }
          ]
          }
        }]
      }]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Battery",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"",
            "panelType2":"badgesAndHeadings",
            "badgeIcons": [{
              "badgeUrl": "assets/images/memory-power-icon.png", // icon
              "tr_badgeTitle": "How is it measured?",
              "tr_badgeSubTitle": "Most manufacturers express their battery life in hours. While this calculation isn’t 100% accurate, it should help you compare the battery life of one PC against another."
            }], 
            "tr_subtitle": "",
            "tr_title": ""
          }
        }]
      }, {
        "iposType": "GAMING",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Battery",
            "detailBGUrl": "",
            "badgeUrl": "assets/images/badges/battery-badge.svg",
            "panelType1":"header-IconTopGaming",
            "panelType2":"", 
            "tr_pretitle": "FOR GAMING ON THE GO",
            "tr_subtitle": "With the power of today’s gaming laptops there’s no reason to be tied to a desk. Now your games can go anywhere.",
            "tr_title": "[Dynamic.battery.values.hours] hours of battery life"
          }
        }]
      }]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Battery",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"", 
            "tr_subtitle": "Unplug and stay connected with your apps, games and music for hours.",
            "tr_title": "[XX] hours of streaming on a single charge<sup class='sup-symbol'>†</sup>",
            "tr_noteSection": "<sup>†</sup>Based on PC manufacturer’s stated battery life.",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "[XX] hrs",
              "tr_badgeSubTitle": "",
            }]
          }
        }]
      }]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Battery",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"", 
            "tr_subtitle": "Unplug and stay connected with your apps, games and music for hours.",
            "tr_title": "[Dynamic.battery.values.hours] hours of power on the go<sup class='sup-symbol'>†</sup>",
            "tr_noteSection": "<sup>†</sup>Based on PC manufacturer’s stated battery life.",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "[XX] hrs",
              "tr_badgeSubTitle": "",
            }]
          }
        }]
      }]
    },
    {
      "contentType": "BEST",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Battery",
            "detailBGUrl": "",
            "badgeUrl": "",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"", 
            "tr_subtitle": "Unplug and stay connected with your apps, games and music for hours.",
            "tr_title": "[Dynamic.battery.values.hours] hours for all-day power on a single charge<sup class='sup-symbol'>†</sup>",
            "tr_noteSection": "<sup>†</sup>Based on PC manufacturer’s stated battery life.",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "[XX] hrs",
              "tr_badgeSubTitle": ""
            }]
          }
        }]
      }]
    },
    {
      "contentType": "EVO",
      "iposTypes": [{
        "iposType": "STANDARD",
        "deviceTypes": [{
          "deviceType": "DEFAULT",
          "details": {
            "tr_tileTitle": "Battery",
            "detailBGUrl": "",
            "badgeUrl": "assets/images/badges/battery-badge.svg",
            "panelType1":"header-BadgeIconTop",
            "panelType2":"", 
            "tr_subtitle": "This PC optimizes your battery life to last longer and gives you at least 4 hours of battery life on a 30-minute charge.<sup>4</sup>",
            "tr_title": "Unplug for longer with [Dynamic.battery.values.hours] hours of battery life<sup class='sup-symbol'>†</sup>",
            "tr_noteSection": "<sup>†</sup>Based on PC manufacturer’s stated battery life.",
            "badgeIcons": [{
              "badgeUrl": "",
              "tr_badgeTitle": "[XX] hrs",
              "tr_badgeSubTitle": ""
            }]
          }
        }]
      }]
    }
  ],
  "headerTabs": [         
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Home",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Why This PC",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },         
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "View all specs",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },    
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Remote",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Mobile",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Legal",
        "tr_paragraph_array": [
          "Software and workloads used in performance tests may have been optimized for performance only on Intel® microprocessors. Performance test, such as SYSmark* and MobileMark*, are measured using specific computer systems, components, and functions. Any change to any of those factors may cause the results to vary. You should consult other information and performance tests to assist you in fully evaluating your contemplated purchases, including the performance of that product when combined with other products. For more complete information about performance and benchmark results, visit www.intel.com/benchmarks.",
          "¹Intel® Wi&#8209;Fi 6 (Gig+) products support optional 160 MHz channels, enabling the fastest possible theoretical maximum speeds (2402 Mbps) for typical 2x2 802.11ax PC Wi&#8209;Fi products. Premium Intel® Wi&#8209;Fi 6 (Gig+) products enable 2-4X faster maximum theoretical speeds compared to standard 2x2 (1201 Mbps) or 1x1 (600 Mbps) 802.11ax PC Wi&#8209;Fi products, which only support the mandatory requirements of 80 MHz channels.",
          "²Recommendation for informational purposes only. Please consult other sources in making your purchasing decision.",
          "³Intel technologies’ features and benefits depend on system configuration and may require enabled hardware, software and service activation. Performance varies depending on system configuration. No computer system can be absolutely secure. Check with your system manufacturer or retailer or learn more at intel.com.",
          "⁴For systems with FHD displays, when used for wireless web browsing. When powered off, from OEM default shutdown level.",
          "⁵As compared to other PC I/O connection technologies including eSATA, USB, and IEEE 1394* FireWire. Performance will vary depending on the specific hardware and software used. Must use a Thunderbolt™ technology-enabled device.",
          "⁶Nearly 3x faster: 802.11ax 2x2 160 MHz enables 2402 Mbps maximum theoretical data rates. ~3x faster than standard 802.11ac 2x2 80 MHz (867 Mbps) Wi&#8209;Fi as documented in IEEE 802.11* wireless standard specifications and requires the use of similarly configured 802.11ax wireless network routers.",
          "⁷75% Latency reduction: Is based on Intel simulation data (79%) of 802.11ax with and without OFDMA using 9 clients. Average latency without OFDM is 36ms, with OFDMA average latency is reduced to 7.6ms. Latency improvement requires that the 802.11ax (Wi-Fi 6) router and all clients support OFDMA.",
          "⁸Based on the theoretical maximum data rate comparison in the 802.11 specification, 2x2 160 MHz Wi&#8209;Fi 6/6E (802.11ax) can deliver 2402 Mbps vs. 2x2 80 MHz Wi&#8209;Fi 5 (802.11ac) can deliver 867 Mbps which equals 2.8X faster speeds.",
          "⁹Best in Class Wi&#8209;Fi 6: Intel® Wi&#8209;Fi 6 (Gig+) products support optional 160 MHz channels, enabling the fastest possible theoretical maximum speeds (2402 Mbps) for typical 2x2 802.11ax PC Wi&#8209;Fi products. Premium Intel® Wi&#8209;Fi 6 (Gig+) products enable 2-4x faster maximum theoretical speeds compared standard 2x2 (1201 Mbps) or 1x1 (600 Mbps) 802.11ax PC Wi&#8209;Fi products, which only support the mandatory requirements of 80 MHz channels.",
          "Select SKUs only, functionality varies by OEM design. Check with OEM or retailer for details. For more Wi&#8209;Fi information please visit intel.com/wifi6disclaimers.",
          "¹⁰Thunderbolt™ 4 technology, at 40 Gbps, delivers the fastest, simplest and most reliable cable solution to any dock, display, or data device as compared to other PC I/O connection technologies including eSATA*, USB*, and IEEE 1394* FireWire.",
          "¹¹Requires a router based on 802.11ax supporting OFDMA and multiple clients on the network with support for AX. Better in dense environments is achievable from OFDMA features supported by 802.11ax clients and APs. 2 Gbps based on assumptions of approximately 70% of IEEE 802.11* specification maximum theoretical data rates of 802.11ax 160 MHz 2402 Mbps.",
          "¹²As measured by Document Launch with Background Activity Workload comparing 8th Gen Intel® Core™ i7-8565U processor (512 GB TLC SSD) vs. 8th Gen Intel® Core™ i7-8565U processor (32 GB + 512 GB Intel® Optane™ memory H10 with Solid State Storage).",
          "¹³Intel® Thread Director is designed into 12th Gen Intel® Core™ processors and helps supporting operating systems to more intelligently channel workloads to the right core. No user action required. See intel.com for details.",
          "¹⁴Not available on certain 12th Gen Intel® Core™ processors. Performance hybrid architecture combines two new core microarchitectures, Performance-cores (P-cores) and Efficient-cores (E-cores), on a single processor die. Select 12th Gen Intel® Core™ processors (certain 12th Gen Intel® Core™ i5 processors and lower) do not support performance hybrid architecture, only P-cores.",
          "¹⁵As measured by Game Launch Workload comparing 8th Gen Intel® Core™ i7-8750H processor (32GB Intel® Optane™ memory module + Intel® SSD 256GB PCIe* + 1TB HDD) vs. 8th Gen Intel® Core™ i7-8750H processor (Intel® SSD 256GB PCIe* + 1TB HDD). Game Launch Workload - Workload developed by Intel measuring the time elapsed to launch Total War*: WARHAMMER* II Build: 5577.0 and reach the Main Menu with intro videos disabled (Launch). Intel® Optane™ memory Data Drive Acceleration Configurations: Intel® Core™ i7-8750H processor, PL1=45W TDP, 6C12T, Turbo up to 4GHz on Pre-production OEM System, Graphics: NVIDIA* GeForce* GTX 1070, Memory: 2x4GB DDR4, Storage: Intel® SSD Series 760p 256GB (PCIe*) + 1TB HDD + 32GB Intel® Optane™ memory, OS: Windows* 10 RS3 Build 1709, MCU 0x84; Intel® Core™ i7-8750H processor, PL1=45W TDP, 6C12T, Turbo up to 4GHz on Pre-production OEM System, Graphics: NVIDIA* GeForce* GTX 1070, Memory: 2x4GB DDR4, Storage: Intel® SSD Series 760p 256GB (PCIe*) + 1TB HDD, OS: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁶As measured by Game Level Load Workload comparing 8th Gen Intel® Core™ i7-8750H processor (32GB Intel® Optane™ memory module) vs. 8th Gen Intel® Core™ i7-8750H processor (HDD Only). Game Level Load Workload -Workload developed by Intel measuring the time elapsed from the main menu to completion of level loading (Level Load) on Total War*: WARHAMMER* II Build: 5577.0. Intel® Optane™ memory Data Drive Acceleration Configurations: Intel® Core™ i7-8750H processor, PL1=45W TDP, 6C12T, Turbo up to 4GHz on Pre-production OEM System, Graphics: NVIDIA* GeForce* GTX 1070, Memory: 2x4GB DDR4, Storage: Intel® SSD Series 760p 256GB (PCIe*)+ 1TB HDD + 32GB Intel® Optane™ memory, OS: Windows* 10 RS3 Build 1709, MCU 0x84; Intel® Core™ i7-8750H processor, PL1=45W TDP, 6C12T, Turbo up to 4GHz on Pre-production OEM System, Graphics: NVIDIA* GeForce* GTX 1070, Memory: 2x4GB DDR4, Storage: Intel® SSD Series 760p 256GB (PCIe*)+ 1TB HDD, OS: Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁷On Performance-cores. Performance varies by use, configuration and other factors. Learn more at www.intel.com/PerformanceIndex.",
          "¹⁸Altering clock frequency or voltage may damage or reduce the useful life of the processor and other system components, and may reduce system stability and performance. Product warranties may not apply if the processor is operated beyond its specifications. Check with the manufacturers of system and components for additional details.",
          "¹⁹As measured by the Intel vPro® Platform's unrivaled combination of above and below the OS security capabilities, app and data protections, and advanced threat protections for any sized business, as well as Intel’s security first approach to product design, manufacture, and support. All business PCs built on the Intel vPro® platform have been validated against rigorous specifications, including unique hardware-based security features. See www.intel.com/PerformanceIndex (platforms) for details. No product or component can be absolutely secure.",
          "²⁰Intel® Connectivity Performance Suite is available on Windows*-based systems only. Intel Over The Air (OTA) Wi-Fi testing on a congested network versus without ICPS software application wireless traffic prioritization and smart access point switching. See www.intel.com/PerformanceIndex (connectivity) for details. Results may vary.",
          "²¹As compared to previous processor generation",
          "²²Intel® Arc™ graphics are first in market with AV1 encoding support (as of Q1 2022). Details at intel.com/performanceindex.",
          "²³As measured by unique features of Intel® Evo™ designs, which are also verified to meet key mobile user experience thresholds. Testing as of February 2022.",
          "²⁴Intel® Unison™ solution is currently only available on eligible Intel® Evo™ designs on Windows-based PCs and only pairs with Android- or iOS-based phones; all devices must run a supported OS version.",
          "²⁵Maximum memory speeds are associated with 1 DIMM per Channel (1DPC) configurations. Additional DIMM loading on any channel may impact maximum memory speed. Up to DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 and DDR5-5200 1Rx8, 1Rx16, 2Rx8 on select SKUs. Maximum memory capacity is achievable with 2DPC configurations.",
          "²⁶Versus standard Wi-Fi. See intel.com/performance-wireless for details. Results may vary.",
          "²⁷Performance hybrid architecture combines two core microarchitectures, Performance-cores (P-cores) and Efficient-cores (E-cores) on a single processor die first introduced on 12th Gen Intel® Core™ processors. Select 12th generation and newer Intel® Core™ processors do not have performance hybrid architecture, only P-cores, and have same cache size as prior generation; see ark.intel.com for SKU details.",
          "²⁸Built into the hardware, Intel® Thread Director is provided only in performance hybrid architecture configurations of 12th Gen or newer Intel® Core™ processors; OS enablement is required. Available features and functionality vary by OS.",
          "²⁹Built into the hardware, Intel® Thread Director is provided only in performance hybrid architecture configurations of 12th and 13th Gen Intel® Core™ processors; OS enablement is required. Available features and functionality vary by OS.",
          "³⁰As measured by reference validation platform performance of Intel® Core™ Ultra mobile processor (Series 2 — Code name: Lunar Lake) versus Intel® Core™ Ultra mobile processor (Series 1 — Code name: Meteor Lake) on 3DMark Time Spy. See intel.com/performanceindex for details. Results may vary.",
          "³¹Background blur and face tracking are available on Windows*-based systems only.",
          "³²Wi-Fi 7 products can access 320 MHz channels in 6 GHz and new 160 MHz channels combinations in 5 GHz with new Multi-Resource Unit Puncturing capabilities.",
          "³³Based on IEEE wireless standard specifications and the maximum theoretical data rates for 2-stream devices.",          
          "³⁴Intel engineering simulations of congested network environments indicate major latency reduction is possible with new Wi-Fi 7 Multi-Link Operation capabilities.",
          "³⁵Minimum charge attained from OEM-default shutdown level. Individual system results may vary. See intel.com/performance-evo for details.",
          "³⁶The 6 GHz Wi-Fi band is not available for use in all countries/regions.",
          "³⁷Windows* only",
          "³⁸Charge attained from OEM-default shutdown level. For more complete information about performance and benchmark results, visit intel.com/Evo.",
          "³⁹Refers to Intel® Core™ Ultra mobile processors (Series 2), based on the unique hardware-based security features, unrivaled combination of above and below the OS security capabilities, impressive performance even at lower power compared to prior generation (as of September 2024). See intel.com/performanceindex for details. Results may vary.",
          "⁴⁰Software applications depicted for illustrative purposes only. AI features may require additional software purchase, subscription or enablement by a software or platform provider, or may have specific configuration or compatibility requirements. Details at www.intel.com/PerformanceIndex. Results may vary. © Intel Corporation.",
          "⁴¹Images shown may have been altered or simulated. AI features may require additional software purchase, subscription or enablement by a software or platform provider, or may have specific configuration or compatibility requirements. Details at www.intel.com/AIPC.",
          "⁴²Rolling out gradually in preview within the latest update to Windows* 11 in select global markets. Timing of availability varies by device and market. Learn more here: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³Intel® Unison™ solution is currently available for eligible designs. See www.intel.com/PerformanceIndex for details.",
          "⁴⁴FPS upscaled by Intel® X<sup>e</sup>SS vs. Native FPS as measured by Dying Light 2 Stay Human running on Intel® Core™ Ultra 7 processor 165H. See www.intel.com/PerformanceIndex for details. Results may vary.",
          "⁴⁵As measured by lower SoC power of Intel® Core™ Ultra mobile processor (Series 2 — Code name: Lunar Lake) reference validation platform vs Intel® Core™ Ultra mobile processor (Series 1 — Code name: Meteor Lake) reference validation platform on YouTube* 4K 30FPS AV1. See intel.com/performanceindex for details. Results may vary.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Intel accepts no liability for incorrect prices.",
          "*Other names and brands may be claimed as the property of others.",
          "COPYRIGHT 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES AND RELATED DESIGN AND COMPOSITE MARKS ARE TRADEMARKS OF INFLEXION STUDIOS INC. ALL RIGHTS RESERVED.",
          "Intel technologies may require enabled hardware, software or service activation.",
          "Any results and data by NERO* are provided as a convenience and for informational purposes only. Intel Corporation bears no responsibility for, by way of example, the accuracy of the NERO* app, performance results and scores, or potential variations from other downloadable versions of the app available on www.nero.com or other third-party websites. NERO* PC benchmark scores are measured using specific computer systems, components, software, operations, and functions. Any change to any of those factors may cause the results to vary. You should consult other information and performance tests to assist you in fully evaluating your contemplated purchases, including the performance of that product when combined with other products.",
          "All versions of the Intel vPro® platform require an eligible Intel® Core™ processor, a supported operating system, Intel® LAN and/or WLAN silicon, firmware enhancements, and other hardware and software necessary to deliver the manageability use cases, security features, system performance and stability that define the platform.",
          "Performance varies by use, configuration and other factors. Learn more at www.intel.com/PerformanceIndex.",
          "Intel does not control or audit third-party data. You should consult other sources to evaluate accuracy.",
          "© Intel Corporation. Intel, the Intel logo, and other Intel marks are trademarks of Intel Corporation or its subsidiaries. *Other names and brands may be claimed as the property of others.",
          "No product or component can be absolutely secure.",
          "Your costs and results may vary.",
          "Intel, the Intel logo, Intel Core, Intel Optane, Intel Iris, Thunderbolt, and the Thunderbolt logo are trademarks of Intel Corporation or its subsidiaries. Other names and brands may be claimed as the property of others.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Home",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"                    
        },
        {
          "tr_specTitle": "Scan To Compare",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Trending",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending":[
     //C GROUP (ranking 1)
    {
      "tr_specTitle": "Unlock new AI experiences with Intel® Core™ Ultra processors",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "Unlock new AI experiences with Intel® Core™ Ultra processors",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },  
    {
      "tr_specTitle": "Unlock new AI experiences with Intel® Core™ Ultra processors",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
     // B GROUP(ranking 3)
    {
      "tr_specTitle": "Stable Diffusion*: Text to image",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe*: Photo editing",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "Intel® X<sup>e</sup>SS : Image upscaling",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee: Deepfake Detector",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "Collaboration Effects (Teams)",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "IQIYI: Gesture Control",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    // M GROUP(Ranking 4)
    {
      "tr_specTitle": "Sync your PC with your phone and view notifications, calls and texts",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    //E GROUP(ranking 5)
    {
      "tr_specTitle": "Rich and vibrant graphics built in",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    // G GROUP(ranking 6)
    {
      "tr_specTitle": " Microsoft*: Run you life, your way",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "Microsoft*: All things you. All at a glance, always a swipe away",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
     // H GROUP(ranking 7)
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
     // I GROUP(ranking 8)
    {
      "tr_specTitle": "Run your processor faster and at higher frequencies by fine-tuning your system",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
     // J GROUP(ranking 9)
    {
      "tr_specTitle": "Boost your desktop or mobile system performance without sacrificing storage space",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
     // K GROUP(ranking 10)
    {
      "tr_specTitle": "Boost your desktop or mobile system performance without sacrificing storage space",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
     // L GROUP(ranking 11)
    {
      "tr_specTitle": "Samsung OLED: A sight for sore eyes",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
     // N GROUP(ranking 12)
    {
      "tr_specTitle": "Enhanced speed, latency, and reliability through AI-based networking optimization",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "Wired-like PC Networking",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "Wired-like PC Networking",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "Nearly 3x faster speed with Intel® Wi-Fi 6 (Gig+)",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "Connect without the competition",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    // D GROUP(ranking 13)
    {
      "tr_specTitle": "Truly universal cable connectivity",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
     // F GROUP(ranking 14)
    {
      "tr_specTitle": "Play like the pros: See how Intel powers gaming and gamers",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "Play like the pros: Champions happen with Intel",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    },

  ],
  "uiText": {
    "tr_welcomePageTitle": "WELCOME",
    "tr_welcomePagetrSubtitle": "Control this PC remotely with your smartphone",
    "tr_welcomePagembSubtitle": " Welcome, explore this PC's specifications on your smartphone",
    "tr_welcomePageBtnText" :"Get Started",
    "tr_welcomePageText": "Explore and compare the details of this PC on your smartphone",
    "tr_scrollText": "Navigate the device in front of you",
    "tr_toolTipTextc1": "Use these buttons to navigate to different pages on the device in front of you.",
    "tr_toolTipTextc2": "Tapping on the below cards will navigate to a detailed page of each device specification.",
    "tr_toolTipTextc3": "Use these buttons to move up and down on the screen before you.",
    "tr_toolTipTextc4": "Switch off the remote mode to view the device specifications on your smartphone.",
    "tr_toolTipTextmb1": "Tap on cards to view detailed content pages.",
    "tr_toolTipTextmb2": "Tap to view a summary of all device specifications",
    "tr_toolTipTextmb3": "Tap button to scan QR code and compare up to 4 devices",
    "tr_nextButton": "Next",
    "tr_prevButton": "Prev",
    "tr_gotItButton": "GOT IT",
    "tr_skipButton": "Skip",
    "tr_placeholderText": "*placeholder",
    "tr_processorHeading": "Processor", 
    "tr_processorSubheading": "10th Gen Intel® Core™ i7 processor", //static for tutorial screens
    "tr_memorySubheading": "[XX] GB", //static for tutorial screens
    "tr_storageSubheading": "[XX] GB Intel® Optane™ memory + [YY] GB SSD", //static for tutorial screens
    "tr_coachmarkProcessor": "XX processor",
    "tr_coachmarkGraphics": "[XX] Graphics",
    "tr_coachmarkMemory": "Spec value",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T", //static for tutorial screens
    "tr_price": "$ 999.95",
    "tr_compare": "Compare",
    "tr_viewallspecs": "VIEW ALL SPECS",
    "tr_operatingSystem": "OS",
    "tr_AmdProcessorNonIntel": "AMD* processor",
    "tr_QualcommProcessorNonIntel": "Qualcomm* processor",
    "tr_GBText": "GB",
    "tr_remoteText": "Specs displayed on the laptop screen",
    "tr_scanErrorText": "You can only scan up to 3 devices at one time. Please delete one or more devices.",
    "tr_scanErrorText2": "You have already scanned this device, please scan another one.",
    "tr_scanMsg": "Scan to add device",
    "tr_backToScan": "Back to Compare",
    "tr_scanToCompare": "Scan to Compare",
    "tr_compareDevice": "Compare Devices",
    "tr_processorTitle": "PROCESSOR",
    "tr_graphicTitle": "GRAPHICS",
    "tr_storageTitle": "STORAGE",
    "tr_displayTitle": "DISPLAY",
    "tr_batteryTitle": "BATTERY",
    "tr_memoryTitle": "MEMORY",
    "tr_wifiTitle": "Wi&#8209;Fi",
    "tr_connectivityTitle": "CONNECTIVITY",
    "tr_priceTitle": "PRICE",
    "tr_operatingSystemTitle": "OPERATING SYSTEM",
    "tr_batteryHoursText": "[XX] hours",
    "tr_hrsText": "hrs",
    "tr_touchscreeenText": "[screenDiagonal]” Touchscreen",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, Touchscreen",
    "tr_FHDTouchScreenText": "FHD, Touchscreen",
    "tr_4KTouchscreenText": "4K, Touchscreen",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Save",
    "tr_device_comparison": "Device comparison document [mm]-[dd]-[yyyy]",
    "tr_Save_As_JPEG": "Save as JPEG",    
    "tr_Save_As_PDF": "Save as PDF",
    "tr_Downloading_PDF": "Downloading PDF",  
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Open",
    "tr_logo": "logo",
    "tr_laptop_name": "Laptop name",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle" : "Intel® Optane™ memory",
    "tr_inchUnit": "inch",
    "tr_wifi1" : "Wi&#8209;Fi 1",
    "tr_wifi2" : "Wi&#8209;Fi 2",
    "tr_wifi3" : "Wi&#8209;Fi 3",
    "tr_wifi4" : "Wi&#8209;Fi 4",
    "tr_wifi5" : "Wi&#8209;Fi 5",
    "tr_wifi6" : "Intel® Wi&#8209;Fi 6 (Gig+)",
    "tr_wifi6Caps" : "INTEL® WI&#8209;FI 6 (GIG+)",
    "tr_wifi6e" : "6E",
    "tr_wifi6eValue" : "Wi&#8209;Fi 6E",
    "tr_wifiXX": "Wi&#8209;Fi [XX]",
    "tr_wifiXXCaps": "WI&#8209;FI [XX]",
    "tr_wifi6E" : "Intel® Wi&#8209;Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "INTEL® OPTANE™ MEMORY",
    "tr_Thunderbolt3": "Thunderbolt™ 3 technology",
    "tr_Thunderbolt4": "Thunderbolt™ 4 technology",
    "tr_processorGraphicsCaps": "PROCESSOR GRAPHICS",
    "tr_processorGraphicsSmall": "Processor Graphics",
    "tr_graphicsCardCaps": "DEDICATED GRAPHICS",
    "tr_graphicsCardSmall": "Dedicated Graphics",
    "tr_processorTitleCamel": "Processor",
    "tr_graphicTitleCamel": "Graphics",
    "tr_storageTitleCamel": "Storage",
    "tr_displayTitleCamel": "Display",
    "tr_batteryTitleCamel": "Battery",
    "tr_memoryTitleCamel": "Memory",
    "tr_connectivityTitleCamel": "Connectivity",
    "tr_priceTitleCamel": "Price",
    "tr_operatingSystemTitleCamel": "Operating System",
    "tr_viewallspecsCamel": "View all specs",
    "tr_displayText": "[screenDiagonal]”",
    "tr_displayTextResolution": "[screenDiagonal]” [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "[screenDiagonal]” [screenResolutionType] Touchscreen",
    "tr_OS": "OS",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "PC Specs",
    "tr_explorePC_text": "Explore everything about this PC",
    "tr_mtlPC_text": "See what engineered to do it all really means",    
    "tr_optaneMemory": "Intel® Optane™ memory",
    "tr_displayText_Display": "[screenDiagonal]” DISPLAY",
    "tr_displayTextResolution_Display": "[screenDiagonal]” [screenResolutionType] DISPLAY",
    "tr_displayTextResolutionTouchscreen_Display": "[screenDiagonal]” [screenResolutionType] TOUCHSCREEN DISPLAY",
    "tr_displayTextTouchscreeenText": "[screenDiagonal]” TOUCHSCREEN DISPLAY",
    "tr_learnMoreLabel": "Learn More",
    "tr_batteryHoursTextUpperCase": "[XX] HOURS",
    "tr_processorGraphicsIrisXe":  "11TH GEN INTEL® CORE™ [cpu_fam] PROCESSOR AND INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAPHICS",
    "tr_processorGraphicsIrisXeMax":  "11TH GEN INTEL® CORE™ [cpu_fam] PROCESSOR AND INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAPHICS",
    "tr_Thunderbolt3UpperCase": "THUNDERBOLT™ 3 TECHNOLOGY",
    "tr_Thunderbolt4UpperCase": "THUNDERBOLT™ 4 TECHNOLOGY",
    "tr_processorWithArcGraphics": "12TH GEN INTEL® CORE™ [cpu_fam] PROCESSOR AND INTEL® ARC™ GRAPHICS",
    "tr_processorGraphicsIrisXe12Gen":  "12TH GEN INTEL® CORE™ [cpu_fam] PROCESSOR AND INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAPHICS",
    "tr_processorGraphicsIrisXeMax12Gen":  "12TH GEN INTEL® CORE™ [cpu_fam] PROCESSOR AND INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAPHICS",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "13TH GEN INTEL® CORE™ [cpu_fam] PROCESSOR AND INTEL® ARC™ GRAPHICS",
    "tr_processorGraphicsIrisXe13Gen":  "13TH GEN INTEL® CORE™ [cpu_fam] PROCESSOR AND INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAPHICS",
    "tr_processorGraphicsIrisXeMax13Gen":  "13TH GEN INTEL® CORE™ [cpu_fam] PROCESSOR AND INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAPHICS",
    // GLS team: Please translate only value part ("INTEL® ARC™ A350M GRAPHICS"...) in below object, not the key part (A350M...) so that it won't break the code.
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "INTEL® ARC™ A350M GRAPHICS",
      "tr_A370M": "INTEL® ARC™ A370M GRAPHICS",
      "tr_A730M": "INTEL® ARC™ A730M GRAPHICS",
      "tr_A770M": "INTEL® ARC™ A770M GRAPHICS",
      "tr_A310": "INTEL® ARC™ A310 GRAPHICS",
      "tr_A380": "INTEL® ARC™ A380 GRAPHICS",
      "tr_A580": "INTEL® ARC™ A580 GRAPHICS",
      "tr_A750": "INTEL® ARC™ A750 GRAPHICS",
      "tr_A770": "INTEL® ARC™ A770 GRAPHICS"
    },
    "tr_memoryValue": "[XX] GB RAM",
    "tr_memoryWithOptaneDesc": "[Value1] GB memory = [Value2] GB Intel® Optane™ memory + [Value3] GB RAM",
    "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD] GB HDD",
    "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD] TB HDD",
    "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD] GB HDD",
    "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD] TB HDD",
    "tr_textGBSSD": "[convertedSSD] GB SSD",
    "tr_textTBSSD": "[convertedSSD] TB SSD",
    "tr_textGBHDD": "[convertedHDD] GB HDD",
    "tr_textTBHDD": "[convertedHDD] TB HDD",
    "tr_textStorageWithOptaneGB": "[XX] GB Intel® Optane™ memory + [convertedSSD] GB SSD",
    "tr_textStorageWithOptaneTB": "[XX] GB Intel® Optane™ memory + [convertedSSD] TB SSD",
    // GLS team: Please translate only value part ("Windows* 7 Home Basic"...) in below object, not the key part (OS1, OS2...) so that it won't break the code.
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",      
      "tr_OS20": "Windows* 10 Home in S mode",
      "tr_OS21": "Windows* 10 Pro in S mode",
      "tr_OS22": "Windows* 10 Enterprise in S mode",
      "tr_OS23": "Windows* 11 Home in S mode",
      "tr_OS24": "Windows* 11 Pro in S mode",
      "tr_OS25": "Windows* 11 Enterprise in S mode"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1" : "AMD* Mobility Radeon* 4100",
      "tr_GR2" : "AMD* Mobility Radeon* HD 2400",
      "tr_GR3" : "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4" : "AMD* Mobility Radeon* HD 2600",
      "tr_GR5" : "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6" : "AMD* Mobility Radeon* HD 3400",
      "tr_GR7" : "AMD* Mobility Radeon* HD 3430",
      "tr_GR8" : "AMD* Mobility Radeon* HD 3650",
      "tr_GR9" : "AMD* Mobility Radeon* HD 3670",
      "tr_GR10" : "AMD* Mobility Radeon* HD 3850",
      "tr_GR11" : "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12" : "AMD* Mobility Radeon* HD 3870",
      "tr_GR13" : "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14" : "AMD* Mobility Radeon* HD 4200",
      "tr_GR15" : "AMD* Mobility Radeon* HD 4300",
      "tr_GR16" : "AMD* Mobility Radeon* HD 4330",
      "tr_GR17" : "AMD* Mobility Radeon* HD 4500",
      "tr_GR18" : "AMD* Mobility Radeon* HD 4650",
      "tr_GR19" : "AMD* Mobility Radeon* HD 4670",
      "tr_GR20" : "AMD* Mobility Radeon* HD 4830",
      "tr_GR21" : "AMD* Mobility Radeon* HD 4850",
      "tr_GR22" : "AMD* Mobility Radeon* HD 4860",
      "tr_GR23" : "AMD* Mobility Radeon* HD 4870",
      "tr_GR24" : "AMD* Mobility Radeon* HD 5000",
      "tr_GR25" : "AMD* Mobility Radeon* HD 5570",
      "tr_GR26" : "AMD* Mobility Radeon* HD 5800",
      "tr_GR27" : "AMD* Mobility Radeon* HD 6000",
      "tr_GR28" : "AMD* Radeon* HD 2350",
      "tr_GR29" : "AMD* Radeon* HD 2400",
      "tr_GR30" : "AMD* Radeon* HD 2400 Pro",
      "tr_GR31" : "AMD* Radeon* HD 2400 XT",
      "tr_GR32" : "AMD* Radeon* HD 2600",
      "tr_GR33" : "AMD* Radeon* HD 2600 Pro",
      "tr_GR34" : "AMD* Radeon* HD 2600 XT",
      "tr_GR35" : "AMD* Radeon* HD 2900 GT",
      "tr_GR36" : "AMD* Radeon* HD 2900 Pro",
      "tr_GR37" : "AMD* Radeon* HD 2900 XT",
      "tr_GR38" : "AMD* Radeon* HD 3200",
      "tr_GR39" : "AMD* Radeon* HD 3300",
      "tr_GR40" : "AMD* Radeon* HD 3450",
      "tr_GR41" : "AMD* Radeon* HD 3470",
      "tr_GR42" : "AMD* Radeon* HD 3600",
      "tr_GR43" : "AMD* Radeon* HD 3650",
      "tr_GR44" : "AMD* Radeon* HD 3830",
      "tr_GR45" : "AMD* Radeon* HD 3850",
      "tr_GR46" : "AMD* Radeon* HD 3850 X2",
      "tr_GR47" : "AMD* Radeon* HD 3870",
      "tr_GR48" : "AMD* Radeon* HD 3870 X2",
      "tr_GR49" : "AMD* Radeon* HD 4200",
      "tr_GR50" : "AMD* Radeon* HD 4250",
      "tr_GR51" : "AMD* Radeon* HD 4290",
      "tr_GR52" : "AMD* Radeon* HD 4300",
      "tr_GR53" : "AMD* Radeon* HD 4550",
      "tr_GR54" : "AMD* Radeon* HD 4600",
      "tr_GR55" : "AMD* Radeon* HD 4700",
      "tr_GR56" : "AMD* Radeon* HD 4770",
      "tr_GR57" : "AMD* Radeon* HD 4800",
      "tr_GR58" : "AMD* Radeon* HD 4850 X2",
      "tr_GR59" : "AMD* Radeon* HD 4870 X2",
      "tr_GR60" : "AMD* Radeon* HD 5000",
      "tr_GR61" : "AMD* Radeon* HD 5400",
      "tr_GR62" : "AMD* Radeon* HD 5450",
      "tr_GR63" : "AMD* Radeon* HD 5470",
      "tr_GR64" : "AMD* Radeon* HD 5490",
      "tr_GR65" : "AMD* Radeon* HD 5500",
      "tr_GR66" : "AMD* Radeon* HD 5530",
      "tr_GR67" : "AMD* Radeon* HD 5570",
      "tr_GR68" : "AMD* Radeon* HD 5600",
      "tr_GR69" : "AMD* Radeon* HD 5630",
      "tr_GR70" : "AMD* Mobility Radeon* 4100",
      "tr_GR71" : "AMD* Radeon* HD 5690",
      "tr_GR72" : "AMD* Radeon* HD 5700",
      "tr_GR73" : "AMD* Radeon* HD 5730",
      "tr_GR74" : "AMD* Radeon* HD 5800",
      "tr_GR75" : "AMD* Radeon* HD 5850X2",
      "tr_GR76" : "AMD* Radeon* HD 5900",
      "tr_GR77" : "AMD* Radeon* HD 6230",
      "tr_GR78" : "AMD* Radeon* HD 6250",
      "tr_GR79" : "AMD* Radeon* HD 6290",
      "tr_GR80" : "AMD* Radeon* HD 6300M",
      "tr_GR81" : "AMD* Radeon* HD 6350",
      "tr_GR82" : "AMD* Radeon* HD 6350A",
      "tr_GR83" : "AMD* Radeon* HD 6390",
      "tr_GR84" : "AMD* Radeon* HD 6400",
      "tr_GR85" : "AMD* Radeon* HD 6430M",
      "tr_GR86" : "AMD* Radeon* HD 6450",
      "tr_GR87" : "AMD* Radeon* HD 6490",
      "tr_GR88" : "AMD* Radeon* HD 6500",
      "tr_GR89" : "AMD* Radeon* HD 6510",
      "tr_GR90" : "AMD* Radeon* HD 6530",
      "tr_GR91" : "AMD* Radeon* HD 6570",
      "tr_GR92" : "AMD* Radeon* HD 6600A",
      "tr_GR93" : "AMD* Radeon* HD 6610",
      "tr_GR94" : "AMD* Radeon* HD 6670",
      "tr_GR95" : "AMD* Radeon* HD 6700",
      "tr_GR96" : "AMD* Radeon* HD 6700M",
      "tr_GR97" : "AMD* Radeon* HD 6750",
      "tr_GR98" : "AMD* Radeon* HD 6800",
      "tr_GR99" : "AMD* Radeon* HD 6800M",
      "tr_GR100" : "AMD* Radeon* HD 6850",
      "tr_GR101" : "AMD* Radeon* HD 6850 X2",
      "tr_GR102" : "AMD* Radeon* HD 6870",
      "tr_GR103" : "AMD* Radeon* HD 6870 X2",
      "tr_GR104" : "AMD* Radeon* HD 6900",
      "tr_GR105" : "AMD* Radeon* HD 6900M",
      "tr_GR106" : "AMD* Radeon* HD 7000",
      "tr_GR107" : "AMD* Radeon* HD 7000M",
      "tr_GR108" : "AMD* Radeon* HD 7300",
      "tr_GR109" : "AMD* Radeon* HD 7350",
      "tr_GR110" : "AMD* Radeon* HD 7400",
      "tr_GR111" : "AMD* Radeon* HD 7400A",
      "tr_GR112" : "AMD* Radeon* HD 7400G",
      "tr_GR113" : "AMD* Radeon* HD 7400M",
      "tr_GR114" : "AMD* Radeon* HD 7420G",
      "tr_GR115" : "AMD* Radeon* HD 7450",
      "tr_GR116" : "AMD* Radeon* HD 7450A",
      "tr_GR117" : "AMD* Radeon* HD 7470",
      "tr_GR118" : "AMD* Radeon* HD 7480D",
      "tr_GR119" : "AMD* Radeon* HD 7500",
      "tr_GR120" : "AMD* Radeon* HD 7500G",
      "tr_GR121" : "AMD* Radeon* HD 7500M",
      "tr_GR122" : "AMD* Radeon* HD 7510",
      "tr_GR123" : "AMD* Radeon* HD 7520G",
      "tr_GR124" : "AMD* Radeon* HD 7540D",
      "tr_GR125" : "AMD* Radeon* HD 7560D",
      "tr_GR126" : "AMD* Radeon* HD 7570",
      "tr_GR127" : "AMD* Radeon* HD 7600",
      "tr_GR128" : "AMD* Radeon* HD 7600A",
      "tr_GR129" : "AMD* Radeon* HD 7600G",
      "tr_GR130" : "AMD* Radeon* HD 7600M",
      "tr_GR131" : "AMD* Radeon* HD 7620G",
      "tr_GR132" : "AMD* Radeon* HD 7640G",
      "tr_GR133" : "AMD* Radeon* HD 7660D",
      "tr_GR134" : "AMD* Radeon* HD 7660G",
      "tr_GR135" : "AMD* Radeon* HD 7670",
      "tr_GR136" : "AMD* Radeon* HD 7670M",
      "tr_GR137" : "AMD* Radeon* HD 7700",
      "tr_GR138" : "AMD* Radeon* HD 7700M",
      "tr_GR139" : "AMD* Radeon* HD 7720",
      "tr_GR140" : "AMD* Radeon* HD 7800",
      "tr_GR141" : "AMD* Radeon* HD 7800M",
      "tr_GR142" : "AMD* Radeon* HD 7900",
      "tr_GR143" : "AMD* Radeon* HD 7970M",
      "tr_GR144" : "AMD* Radeon* HD 8180",
      "tr_GR145" : "AMD* Radeon* HD 8200",
      "tr_GR146" : "AMD* Radeon* HD 8210",
      "tr_GR147" : "AMD* Radeon* HD 8210E",
      "tr_GR148" : "AMD* Radeon* HD 8250",
      "tr_GR149" : "AMD* Radeon* HD 8280E",
      "tr_GR150" : "AMD* Radeon* HD 8310G",
      "tr_GR151" : "AMD* Radeon* HD 8330",
      "tr_GR152" : "AMD* Radeon* HD 8330E",
      "tr_GR153" : "AMD* Radeon* HD 8350",
      "tr_GR154" : "AMD* Radeon* HD 8350G",
      "tr_GR155" : "AMD* Radeon* HD 8370D",
      "tr_GR156" : "AMD* Radeon* HD 8400",
      "tr_GR157" : "AMD* Radeon* HD 8400E",
      "tr_GR158" : "AMD* Radeon* HD 8410G",
      "tr_GR159" : "AMD* Radeon* HD 8450",
      "tr_GR160" : "AMD* Radeon* HD 8450G",
      "tr_GR161" : "AMD* Radeon* HD 8470",
      "tr_GR162" : "AMD* Radeon* HD 8470D",
      "tr_GR163" : "AMD* Radeon* HD 8490",
      "tr_GR164" : "AMD* Radeon* HD 8500M",
      "tr_GR165" : "AMD* Radeon* HD 8510",
      "tr_GR166" : "AMD* Radeon* HD 8510G",
      "tr_GR167" : "AMD* Radeon* HD 8550",
      "tr_GR168" : "AMD* Radeon* HD 8550D",
      "tr_GR169" : "AMD* Radeon* HD 8550G",
      "tr_GR170" : "AMD* Radeon* HD 8570",
      "tr_GR171" : "AMD* Radeon* HD 8570D",
      "tr_GR172" : "AMD* Radeon* HD 8600",
      "tr_GR173" : "AMD* Radeon* HD 8600M",
      "tr_GR174" : "AMD* Radeon* HD 8610G",
      "tr_GR175" : "AMD* Radeon* HD 8650D",
      "tr_GR176" : "AMD* Radeon* HD 8650G",
      "tr_GR177" : "AMD* Radeon* HD 8670D",
      "tr_GR178" : "AMD* Radeon* HD 8730",
      "tr_GR179" : "AMD* Radeon* HD 8760",
      "tr_GR180" : "AMD* Radeon* HD 8770",
      "tr_GR181" : "AMD* Radeon* HD 8790M",
      "tr_GR182" : "AMD* Radeon* HD 8800M",
      "tr_GR183" : "AMD* Radeon* HD 8870",
      "tr_GR184" : "AMD* Radeon* HD 8950",
      "tr_GR185" : "AMD* Radeon* HD 8970",
      "tr_GR186" : "AMD* Radeon* HD 8970M",
      "tr_GR187" : "AMD* Radeon* HD 8990",
      "tr_GR188" : "AMD* Radeon* HD 9000",
      "tr_GR189" : "AMD* Radeon* Pro 450",
      "tr_GR190" : "AMD* Radeon* Pro 455",
      "tr_GR191" : "AMD* Radeon* Pro 460",
      "tr_GR192" : "AMD* Radeon* Pro 465",
      "tr_GR193" : "AMD* Radeon* Pro Duo",
      "tr_GR194" : "AMD* Radeon* Pro SSG",
      "tr_GR195" : "AMD* Radeon* Pro V340",
      "tr_GR196" : "AMD* Radeon* Pro V5300X",
      "tr_GR197" : "AMD* Radeon* Pro V7300X",
      "tr_GR198" : "AMD* Radeon* Pro V7350x2",
      "tr_GR199" : "AMD* Radeon* Pro Vega 56",
      "tr_GR200" : "AMD* Radeon* Pro VII",
      "tr_GR201" : "AMD* Radeon* Pro W5500",
      "tr_GR202" : "AMD* Radeon* Pro W5500M",
      "tr_GR203" : "AMD* Radeon* Pro W5700",
      "tr_GR204" : "AMD* Radeon* Pro W6300",
      "tr_GR205" : "AMD* Radeon* Pro W6300M",
      "tr_GR206" : "AMD* Radeon* Pro W6400",
      "tr_GR207" : "AMD* Radeon* Pro W6500M",
      "tr_GR208" : "AMD* Radeon* Pro W6600",
      "tr_GR209" : "AMD* Radeon* Pro W6600M",
      "tr_GR210" : "AMD* Radeon* Pro W6800",
      "tr_GR211" : "AMD* Radeon* Pro W7500",
      "tr_GR212" : "AMD* Radeon* Pro W7600",
      "tr_GR213" : "AMD* Radeon* Pro W7700",
      "tr_GR214" : "AMD* Radeon* Pro W7800",
      "tr_GR215" : "AMD* Radeon* Pro W7900",
      "tr_GR216" : "AMD* Radeon* Pro WX 2100",
      "tr_GR217" : "AMD* Radeon* Pro WX 3100",
      "tr_GR218" : "AMD* Radeon* Pro WX 3200",
      "tr_GR219" : "AMD* Radeon* Pro WX 4100",
      "tr_GR220" : "AMD* Radeon* Pro WX 4150",
      "tr_GR221" : "AMD* Radeon* Pro WX 4170",
      "tr_GR222" : "AMD* Radeon* Pro WX 5100",
      "tr_GR223" : "AMD* Radeon* Pro WX 7100",
      "tr_GR224" : "AMD* Radeon* Pro WX 8200",
      "tr_GR225" : "AMD* Radeon* Pro WX 9100",
      "tr_GR226" : "AMD* Radeon* R5 200",
      "tr_GR227" : "AMD* Radeon* R5 220",
      "tr_GR228" : "AMD* Radeon* R5 230",
      "tr_GR229" : "AMD* Radeon* R5 235",
      "tr_GR230" : "AMD* Radeon* R5 240",
      "tr_GR231" : "AMD* Radeon* R5 340",
      "tr_GR232" : "AMD* Radeon* R5 340X",
      "tr_GR233" : "AMD* Radeon* R5 420",
      "tr_GR234" : "AMD* Radeon* R5 430",
      "tr_GR235" : "AMD* Radeon* R5 430M",
      "tr_GR236" : "AMD* Radeon* R5 M200",
      "tr_GR237" : "AMD* Radeon* R5 M230",
      "tr_GR238" : "AMD* Radeon* R5 M240",
      "tr_GR239" : "AMD* Radeon* R5 M255",
      "tr_GR240" : "AMD* Radeon* R5 M315",
      "tr_GR241" : "AMD* Radeon* R5 M320",
      "tr_GR242" : "AMD* Radeon* R5 M330",
      "tr_GR243" : "AMD* Radeon* R5 M335",
      "tr_GR244" : "AMD* Radeon* R5 M430",
      "tr_GR245" : "AMD* Radeon* R5 M435",
      "tr_GR246" : "AMD* Radeon* R6 M255DX",
      "tr_GR247" : "AMD* Radeon* R6 M335DX",
      "tr_GR248" : "AMD* Radeon* R6 M340DX",
      "tr_GR249" : "AMD* Radeon* R6 M435DX",
      "tr_GR250" : "AMD* Radeon* R7 200",
      "tr_GR251" : "AMD* Radeon* R7 240",
      "tr_GR252" : "AMD* Radeon* R7 250",
      "tr_GR253" : "AMD* Radeon* R7 250X",
      "tr_GR254" : "AMD* Radeon* R7 265",
      "tr_GR255" : "AMD* Radeon* R7 300",
      "tr_GR256" : "AMD* Radeon* R7 340",
      "tr_GR257" : "AMD* Radeon* R7 350",
      "tr_GR258" : "AMD* Radeon* R7 350X",
      "tr_GR259" : "AMD* Radeon* R7 360",
      "tr_GR260" : "AMD* Radeon* R7 370",
      "tr_GR261" : "AMD* Radeon* R7 430",
      "tr_GR262" : "AMD* Radeon* R7 450",
      "tr_GR263" : "AMD* Radeon* R7 M260",
      "tr_GR264" : "AMD* Radeon* R7 M260DX",
      "tr_GR265" : "AMD* Radeon* R7 M265",
      "tr_GR266" : "AMD* Radeon* R7 M265DX",
      "tr_GR267" : "AMD* Radeon* R7 M340",
      "tr_GR268" : "AMD* Radeon* R7 M350",
      "tr_GR269" : "AMD* Radeon* R7 M360",
      "tr_GR270" : "AMD* Radeon* R7 M370",
      "tr_GR271" : "AMD* Radeon* R7 M380",
      "tr_GR272" : "AMD* Radeon* R7 M440",
      "tr_GR273" : "AMD* Radeon* R7 M460",
      "tr_GR274" : "AMD* Radeon* R7 M465",
      "tr_GR275" : "AMD* Radeon* R7 M465X",
      "tr_GR276" : "AMD* Radeon* R8 M350DX",
      "tr_GR277" : "AMD* Radeon* R8 M435DX",
      "tr_GR278" : "AMD* Radeon* R8 M535DX",
      "tr_GR279" : "AMD* Radeon* R9 200",
      "tr_GR280" : "AMD* Radeon* R9 255",
      "tr_GR281" : "AMD* Radeon* R9 260",
      "tr_GR282" : "AMD* Radeon* R9 270",
      "tr_GR283" : "AMD* Radeon* R9 280",
      "tr_GR284" : "AMD* Radeon* R9 290",
      "tr_GR285" : "AMD* Radeon* R9 290X",
      "tr_GR286" : "AMD* Radeon* R9 295X2",
      "tr_GR287" : "AMD* Radeon* R9 360",
      "tr_GR288" : "AMD* Radeon* R9 370",
      "tr_GR289" : "AMD* Radeon* R9 370X",
      "tr_GR290" : "AMD* Radeon* R9 380",
      "tr_GR291" : "AMD* Radeon* R9 390",
      "tr_GR292" : "AMD* Radeon* R9 390X",
      "tr_GR293" : "AMD* Radeon* R9 Fury",
      "tr_GR294" : "AMD* Radeon* R9 Fury X",
      "tr_GR295" : "AMD* Radeon* R9 M200X",
      "tr_GR296" : "AMD* Radeon* R9 M275X",
      "tr_GR297" : "AMD* Radeon* R9 M280X",
      "tr_GR298" : "AMD* Radeon* R9 M360",
      "tr_GR299" : "AMD* Radeon* R9 M365X",
      "tr_GR300" : "AMD* Radeon* R9 M370X",
      "tr_GR301" : "AMD* Radeon* R9 M375",
      "tr_GR302" : "AMD* Radeon* R9 M375X",
      "tr_GR303" : "AMD* Radeon* R9 M380",
      "tr_GR304" : "AMD* Radeon* R9 M385",
      "tr_GR305" : "AMD* Radeon* R9 M385X",
      "tr_GR306" : "AMD* Radeon* R9 M390X",
      "tr_GR307" : "AMD* Radeon* R9 M395X",
      "tr_GR308" : "AMD* Radeon* R9 M470X",
      "tr_GR309" : "AMD* Radeon* RX 455",
      "tr_GR310" : "AMD* Radeon* RX 460",
      "tr_GR311" : "AMD* Radeon* RX 470",
      "tr_GR312" : "AMD* Radeon* RX 480",
      "tr_GR313" : "AMD* Radeon* RX 5300",
      "tr_GR314" : "AMD* Radeon* RX 5300M",
      "tr_GR315" : "AMD* Radeon* RX 540",
      "tr_GR316" : "AMD* Radeon* RX 540X",
      "tr_GR317" : "AMD* Radeon* RX 550",
      "tr_GR318" : "AMD* Radeon* RX 5500",
      "tr_GR319" : "AMD* Radeon* RX 5500 XT",
      "tr_GR320" : "AMD* Radeon* RX 5500M",
      "tr_GR321" : "AMD* Radeon* RX 550X",
      "tr_GR322" : "AMD* Radeon* RX 560",
      "tr_GR323" : "AMD* Radeon* RX 560 XT",
      "tr_GR324" : "AMD* Radeon* RX 5600",
      "tr_GR325" : "AMD* Radeon* RX 5600 OEM",
      "tr_GR326" : "AMD* Radeon* RX 5600 XT",
      "tr_GR327" : "AMD* Radeon* RX 5600M",
      "tr_GR328" : "AMD* Radeon* RX 560X",
      "tr_GR329" : "AMD* Radeon* RX 570",
      "tr_GR330" : "AMD* Radeon* RX 5700",
      "tr_GR331" : "AMD* Radeon* RX 5700 XT",
      "tr_GR332" : "AMD* Radeon* RX 5700M",
      "tr_GR333" : "AMD* Radeon* RX 570X",
      "tr_GR334" : "AMD* Radeon* RX 580",
      "tr_GR335" : "AMD* Radeon* RX 580X",
      "tr_GR336" : "AMD* Radeon* RX 590",
      "tr_GR337" : "AMD* Radeon* RX 590 GME",
      "tr_GR338" : "AMD* Radeon* RX 6300",
      "tr_GR339" : "AMD* Radeon* RX 6300M",
      "tr_GR340" : "AMD* Radeon* RX 640",
      "tr_GR341" : "AMD* Radeon* RX 6400",
      "tr_GR342" : "AMD* Radeon* RX 6450M",
      "tr_GR343" : "AMD* Radeon* RX 6500",
      "tr_GR344" : "AMD* Radeon* RX 6500 XT",
      "tr_GR345" : "AMD* Radeon* RX 6500M",
      "tr_GR346" : "AMD* Radeon* RX 6550M",
      "tr_GR347" : "AMD* Radeon* RX 6600",
      "tr_GR348" : "AMD* Radeon* RX 6600 LE",
      "tr_GR349" : "AMD* Radeon* RX 6600 XT",
      "tr_GR350" : "AMD* Radeon* RX 6600M",
      "tr_GR351" : "AMD* Radeon* RX 6600S",
      "tr_GR352" : "AMD* Radeon* RX 6650 XT",
      "tr_GR353" : "AMD* Radeon* RX 6650M",
      "tr_GR354" : "AMD* Radeon* RX 6650M XT",
      "tr_GR355" : "AMD* Radeon* RX 6700",
      "tr_GR356" : "AMD* Radeon* RX 6700 XT",
      "tr_GR357" : "AMD* Radeon* RX 6700M",
      "tr_GR358" : "AMD* Radeon* RX 6700S",
      "tr_GR359" : "AMD* Radeon* RX 6750 GRE",
      "tr_GR360" : "AMD* Radeon* RX 6750 XT",
      "tr_GR361" : "AMD* Radeon* RX 6800",
      "tr_GR362" : "AMD* Radeon* RX 6800 XT",
      "tr_GR363" : "AMD* Radeon* RX 6800M",
      "tr_GR364" : "AMD* Radeon* RX 6800S",
      "tr_GR365" : "AMD* Radeon* RX 6850M XT",
      "tr_GR366" : "AMD* Radeon* RX 6900 XT",
      "tr_GR367" : "AMD* Radeon* RX 6950 XT",
      "tr_GR368" : "AMD* Radeon* RX 7600",
      "tr_GR369" : "AMD* Radeon* RX 7600 XT",
      "tr_GR370" : "AMD* Radeon* RX 7600M",
      "tr_GR371" : "AMD* Radeon* RX 7600M XT",
      "tr_GR372" : "AMD* Radeon* RX 7600S",
      "tr_GR373" : "AMD* Radeon* RX 7700 XT",
      "tr_GR374" : "AMD* Radeon* RX 7700S",
      "tr_GR375" : "AMD* Radeon* RX 7800 XT",
      "tr_GR376" : "AMD* Radeon* RX 7900 GRE",
      "tr_GR377" : "AMD* Radeon* RX 7900 XT",
      "tr_GR378" : "AMD* Radeon* RX 7900 XTX",
      "tr_GR379" : "AMD* Radeon* RX 7900M",
      "tr_GR380" : "AMD* Radeon* RX Vega",
      "tr_GR381" : "AMD* Radeon* RX Vega 10",
      "tr_GR382" : "AMD* Radeon* RX Vega 11",
      "tr_GR383" : "AMD* Radeon* RX Vega 64",
      "tr_GR384" : "AMD* Radeon* RX Vega M GH",
      "tr_GR385" : "AMD* Radeon* RX Vega M GL",
      "tr_GR386" : "Intel® Arc™ A310 Graphics",
      "tr_GR387" : "Intel® Arc™ A350M Graphics",
      "tr_GR388" : "Intel® Arc™ A370M Graphics",
      "tr_GR389" : "Intel® Arc™ A380 Graphics",
      "tr_GR390" : "Intel® Arc™ A530M Graphics",
      "tr_GR391" : "Intel® Arc™ A550M Graphics",
      "tr_GR392" : "Intel® Arc™ A570M Graphics",
      "tr_GR393" : "Intel® Arc™ A580 Graphics",
      "tr_GR394" : "Intel® Arc™ A730M Graphics",
      "tr_GR395" : "Intel® Arc™ A750 Graphics",
      "tr_GR396" : "Intel® Arc™ A770 Graphics",
      "tr_GR397" : "Intel® Arc™ A770M Graphics",
      "tr_GR398" : "Intel® Arc™ graphics",
      "tr_GR399" : "Intel® Arc™ Pro A30M Graphics",
      "tr_GR400" : "Intel® Arc™ Pro A40/A50 Graphics",
      "tr_GR401" : "Intel® Arc™ Pro A60 Graphics",
      "tr_GR402" : "Intel® Arc™ Pro A60M Graphics",
      "tr_GR403" : "Intel® Graphics",
      "tr_GR404" : "Intel® HD Graphics 2000",
      "tr_GR405" : "Intel® HD Graphics 2500",
      "tr_GR406" : "Intel® HD Graphics 3000",
      "tr_GR407" : "Intel® HD Graphics 400",
      "tr_GR408" : "Intel® HD Graphics 4000",
      "tr_GR409" : "Intel® HD Graphics 405",
      "tr_GR410" : "Intel® HD Graphics 4200",
      "tr_GR411" : "Intel® HD Graphics 4400",
      "tr_GR412" : "Intel® HD Graphics 4600",
      "tr_GR413" : "Intel® HD Graphics 500",
      "tr_GR414" : "Intel® HD Graphics 5000",
      "tr_GR415" : "Intel® HD Graphics 505",
      "tr_GR416" : "Intel® HD Graphics 510",
      "tr_GR417" : "Intel® HD Graphics 515",
      "tr_GR418" : "Intel® HD Graphics 520",
      "tr_GR419" : "Intel® HD Graphics 530",
      "tr_GR420" : "Intel® HD Graphics 5300",
      "tr_GR421" : "Intel® HD Graphics 5500",
      "tr_GR422" : "Intel® HD Graphics 5600",
      "tr_GR423" : "Intel® HD Graphics 6000",
      "tr_GR424" : "Intel® HD Graphics 610",
      "tr_GR425" : "Intel® HD Graphics 615",
      "tr_GR426" : "Intel® HD Graphics 620",
      "tr_GR427" : "Intel® HD Graphics 630",
      "tr_GR428" : "Intel® HD Graphics",
      "tr_GR429" : "Intel® Iris® Graphics 5100",
      "tr_GR430" : "Intel® Iris® Graphics 540",
      "tr_GR431" : "Intel® Iris® Graphics 550",
      "tr_GR432" : "Intel® Iris® Graphics 6100",
      "tr_GR433" : "Intel® Iris® Plus Graphics 640",
      "tr_GR434" : "Intel® Iris® Plus Graphics 645",
      "tr_GR435" : "Intel® Iris® Plus Graphics 650",
      "tr_GR436" : "Intel® Iris® Plus Graphics 655",
      "tr_GR437" : "Intel® Iris® Plus Graphics",
      "tr_GR438" : "Intel® Iris® Pro Graphics 5200",
      "tr_GR439" : "Intel® Iris® Pro Graphics 580",
      "tr_GR440" : "Intel® Iris® Pro Graphics 6200",
      "tr_GR441" : "Intel® Iris® Xᵉ Graphics",
      "tr_GR442" : "Intel® Iris® Xᵉ MAX Graphics A220M",
      "tr_GR443" : "Intel® Iris® Xᵉ MAX Graphics",
      "tr_GR444" : "Intel® Integrated Graphics",
      "tr_GR445" : "Intel® UHD Graphics 600",
      "tr_GR446" : "Intel® UHD Graphics 605",
      "tr_GR447" : "Intel® UHD Graphics 610",
      "tr_GR448" : "Intel® UHD Graphics 615",
      "tr_GR449" : "Intel® UHD Graphics 617",
      "tr_GR450" : "Intel® UHD Graphics 620",
      "tr_GR451" : "Intel® UHD Graphics 630",
      "tr_GR452" : "Intel® UHD Graphics 710",
      "tr_GR453" : "Intel® UHD Graphics 730",
      "tr_GR454" : "Intel® UHD Graphics 770",
      "tr_GR455" : "Intel® UHD Graphics",
      "tr_GR456" : "Intel® UHD Graphics",
      "tr_GR457" : "Intel® UHD Graphics",
      "tr_GR458" : "NVIDIA* GeForce* MX110",
      "tr_GR459" : "NVIDIA* GeForce* MX130",
      "tr_GR460" : "NVIDIA* GeForce* MX150",
      "tr_GR461" : "NVIDIA* GeForce* MX230",
      "tr_GR462" : "NVIDIA* GeForce* MX250",
      "tr_GR463" : "NVIDIA* GeForce* MX330",
      "tr_GR464" : "NVIDIA* GeForce* MX350",
      "tr_GR465" : "NVIDIA* GeForce* 410M",
      "tr_GR466" : "NVIDIA* GeForce* MX450",
      "tr_GR467" : "NVIDIA* GeForce* 510",
      "tr_GR468" : "NVIDIA* GeForce* MX550",
      "tr_GR469" : "NVIDIA* GeForce* MX570",
      "tr_GR470" : "NVIDIA* GeForce* MX570 A",
      "tr_GR471" : "NVIDIA* GeForce* 605",
      "tr_GR472" : "NVIDIA* GeForce* 610",
      "tr_GR473" : "NVIDIA* GeForce* 610M",
      "tr_GR474" : "NVIDIA* GeForce* 615",
      "tr_GR475" : "NVIDIA* GeForce* 620M",
      "tr_GR476" : "NVIDIA* GeForce* 705A",
      "tr_GR477" : "NVIDIA* GeForce* 705M",
      "tr_GR478" : "NVIDIA* GeForce* 710A",
      "tr_GR479" : "NVIDIA* GeForce* 710M",
      "tr_GR480" : "NVIDIA* GeForce* 720A",
      "tr_GR481" : "NVIDIA* GeForce* 720M",
      "tr_GR482" : "NVIDIA* GeForce* 730A",
      "tr_GR483" : "NVIDIA* GeForce* 800A",
      "tr_GR484" : "NVIDIA* GeForce* 800M",
      "tr_GR485" : "NVIDIA* GeForce* 805A",
      "tr_GR486" : "NVIDIA* GeForce* 810A",
      "tr_GR487" : "NVIDIA* GeForce* 810M",
      "tr_GR488" : "NVIDIA* GeForce* 820A",
      "tr_GR489" : "NVIDIA* GeForce* 820M",
      "tr_GR490" : "NVIDIA* GeForce* 825M",
      "tr_GR491" : "NVIDIA* GeForce* 830A",
      "tr_GR492" : "NVIDIA* GeForce* 830M",
      "tr_GR493" : "NVIDIA* GeForce* 840A",
      "tr_GR494" : "NVIDIA* GeForce* 840M",
      "tr_GR495" : "NVIDIA* GeForce* 845M",
      "tr_GR496" : "NVIDIA* GeForce* 910M",
      "tr_GR497" : "NVIDIA* GeForce* 920A",
      "tr_GR498" : "NVIDIA* GeForce* 920M",
      "tr_GR499" : "NVIDIA* GeForce* 920MX",
      "tr_GR500" : "NVIDIA* GeForce* 930A",
      "tr_GR501" : "NVIDIA* GeForce* 930M",
      "tr_GR502" : "NVIDIA* GeForce* 930MX",
      "tr_GR503" : "NVIDIA* GeForce* 940A",
      "tr_GR504" : "NVIDIA* GeForce* 940M",
      "tr_GR505" : "NVIDIA* GeForce* 940MX",
      "tr_GR506" : "NVIDIA* GeForce* 945A",
      "tr_GR507" : "NVIDIA* GeForce* 945M",
      "tr_GR508" : "NVIDIA* GeForce* GT 1010",
      "tr_GR509" : "NVIDIA* GeForce* GT 1030",
      "tr_GR510" : "NVIDIA* GeForce* GT 415M",
      "tr_GR511" : "NVIDIA* GeForce* GT 420",
      "tr_GR512" : "NVIDIA* GeForce* GT 420M",
      "tr_GR513" : "NVIDIA* GeForce* GT 425M",
      "tr_GR514" : "NVIDIA* GeForce* GT 430",
      "tr_GR515" : "NVIDIA* GeForce* GT 435M",
      "tr_GR516" : "NVIDIA* GeForce* GT 440",
      "tr_GR517" : "NVIDIA* GeForce* GT 445M",
      "tr_GR518" : "NVIDIA* GeForce* GT 520",
      "tr_GR519" : "NVIDIA* GeForce* GT 520M",
      "tr_GR520" : "NVIDIA* GeForce* GT 520MX",
      "tr_GR521" : "NVIDIA* GeForce* GT 525M",
      "tr_GR522" : "NVIDIA* GeForce* GT 530",
      "tr_GR523" : "NVIDIA* GeForce* GT 540M",
      "tr_GR524" : "NVIDIA* GeForce* GT 545",
      "tr_GR525" : "NVIDIA* GeForce* GT 550M",
      "tr_GR526" : "NVIDIA* GeForce* GT 555M",
      "tr_GR527" : "NVIDIA* GeForce* GT 610",
      "tr_GR528" : "NVIDIA* GeForce* GT 620",
      "tr_GR529" : "NVIDIA* GeForce* GT 620M",
      "tr_GR530" : "NVIDIA* GeForce* GT 625",
      "tr_GR531" : "NVIDIA* GeForce* GT 625M",
      "tr_GR532" : "NVIDIA* GeForce* GT 630",
      "tr_GR533" : "NVIDIA* GeForce* GT 630M",
      "tr_GR534" : "NVIDIA* GeForce* GT 635",
      "tr_GR535" : "NVIDIA* GeForce* GT 635M",
      "tr_GR536" : "NVIDIA* GeForce* GT 640",
      "tr_GR537" : "NVIDIA* GeForce* GT 640M",
      "tr_GR538" : "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539" : "NVIDIA* GeForce* GT 645",
      "tr_GR540" : "NVIDIA* GeForce* GT 645M",
      "tr_GR541" : "NVIDIA* GeForce* GT 650M",
      "tr_GR542" : "NVIDIA* GeForce* GT 705",
      "tr_GR543" : "NVIDIA* GeForce* GT 710",
      "tr_GR544" : "NVIDIA* GeForce* GT 710M",
      "tr_GR545" : "NVIDIA* GeForce* GT 720",
      "tr_GR546" : "NVIDIA* GeForce* GT 720A",
      "tr_GR547" : "NVIDIA* GeForce* GT 720M",
      "tr_GR548" : "NVIDIA* GeForce* GT 730",
      "tr_GR549" : "NVIDIA* GeForce* GT 730M",
      "tr_GR550" : "NVIDIA* GeForce* GT 735M",
      "tr_GR551" : "NVIDIA* GeForce* GT 740",
      "tr_GR552" : "NVIDIA* GeForce* GT 740A",
      "tr_GR553" : "NVIDIA* GeForce* GT 740M",
      "tr_GR554" : "NVIDIA* GeForce* GT 745A",
      "tr_GR555" : "NVIDIA* GeForce* GT 745M",
      "tr_GR556" : "NVIDIA* GeForce* GT 750M",
      "tr_GR557" : "NVIDIA* GeForce* GT 755M",
      "tr_GR558" : "NVIDIA* GeForce* GT 820M",
      "tr_GR559" : "NVIDIA* GeForce* GTS 450",
      "tr_GR560" : "NVIDIA* GeForce* GTX 1050",
      "tr_GR561" : "NVIDIA* GeForce* GTX 1050 with Max-Q Design",
      "tr_GR562" : "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563" : "NVIDIA* GeForce* GTX 1050 Ti with Max-Q Design",
      "tr_GR564" : "NVIDIA* GeForce* GTX 1060",
      "tr_GR565" : "NVIDIA* GeForce* GTX 1060 with Max-Q Design",
      "tr_GR566" : "NVIDIA* GeForce* GTX 1070",
      "tr_GR567" : "NVIDIA* GeForce* GTX 1070 with Max-Q Design",
      "tr_GR568" : "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569" : "NVIDIA* GeForce* GTX 1080",
      "tr_GR570" : "NVIDIA* GeForce* GTX 1080 with Max-Q Design",
      "tr_GR571" : "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572" : "NVIDIA* GeForce* GTX 1630",
      "tr_GR573" : "NVIDIA* GeForce* GTX 1650",
      "tr_GR574" : "NVIDIA* GeForce* GTX 1650 with Max-Q Design",
      "tr_GR575" : "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576" : "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577" : "NVIDIA* GeForce* GTX 1650 Ti with Max-Q Design",
      "tr_GR578" : "NVIDIA* GeForce* GTX 1660",
      "tr_GR579" : "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580" : "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581" : "NVIDIA* GeForce* GTX 1660 Ti with Max-Q Design",
      "tr_GR582" : "NVIDIA* GeForce* GTX 460",
      "tr_GR583" : "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584" : "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585" : "NVIDIA* GeForce* GTX 460M",
      "tr_GR586" : "NVIDIA* GeForce* GTX 465",
      "tr_GR587" : "NVIDIA* GeForce* GTX 470",
      "tr_GR588" : "NVIDIA* GeForce* GTX 470M",
      "tr_GR589" : "NVIDIA* GeForce* GTX 480",
      "tr_GR590" : "NVIDIA* GeForce* GTX 480M",
      "tr_GR591" : "NVIDIA* GeForce* GTX 485M",
      "tr_GR592" : "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593" : "NVIDIA* GeForce* GTX 555",
      "tr_GR594" : "NVIDIA* GeForce* GTX 560",
      "tr_GR595" : "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596" : "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597" : "NVIDIA* GeForce* GTX 560M",
      "tr_GR598" : "NVIDIA* GeForce* GTX 570",
      "tr_GR599" : "NVIDIA* GeForce* GTX 570M",
      "tr_GR600" : "NVIDIA* GeForce* GTX 580",
      "tr_GR601" : "NVIDIA* GeForce* GTX 580M",
      "tr_GR602" : "NVIDIA* GeForce* GTX 590",
      "tr_GR603" : "NVIDIA* GeForce* GTX 645",
      "tr_GR604" : "NVIDIA* GeForce* GTX 650",
      "tr_GR605" : "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606" : "NVIDIA* GeForce* GTX 660",
      "tr_GR607" : "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608" : "NVIDIA* GeForce* GTX 660M",
      "tr_GR609" : "NVIDIA* GeForce* GTX 670",
      "tr_GR610" : "NVIDIA* GeForce* GTX 670M",
      "tr_GR611" : "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612" : "NVIDIA* GeForce* GTX 675M",
      "tr_GR613" : "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614" : "NVIDIA* GeForce* GTX 680",
      "tr_GR615" : "NVIDIA* GeForce* GTX 680M",
      "tr_GR616" : "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617" : "NVIDIA* GeForce* GTX 690",
      "tr_GR618" : "NVIDIA* GeForce* GTX 745",
      "tr_GR619" : "NVIDIA* GeForce* GTX 750",
      "tr_GR620" : "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621" : "NVIDIA* GeForce* GTX 760",
      "tr_GR622" : "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623" : "NVIDIA* GeForce* GTX 760A",
      "tr_GR624" : "NVIDIA* GeForce* GTX 760M",
      "tr_GR625" : "NVIDIA* GeForce* GTX 765M",
      "tr_GR626" : "NVIDIA* GeForce* GTX 770",
      "tr_GR627" : "NVIDIA* GeForce* GTX 770M",
      "tr_GR628" : "NVIDIA* GeForce* GTX 775M",
      "tr_GR629" : "NVIDIA* GeForce* GTX 780",
      "tr_GR630" : "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631" : "NVIDIA* GeForce* GTX 780M",
      "tr_GR632" : "NVIDIA* GeForce* GTX 850A",
      "tr_GR633" : "NVIDIA* GeForce* GTX 850M",
      "tr_GR634" : "NVIDIA* GeForce* GTX 860M",
      "tr_GR635" : "NVIDIA* GeForce* GTX 870M",
      "tr_GR636" : "NVIDIA* GeForce* GTX 880M",
      "tr_GR637" : "NVIDIA* GeForce* GTX 950",
      "tr_GR638" : "NVIDIA* GeForce* GTX 950A",
      "tr_GR639" : "NVIDIA* GeForce* GTX 950M",
      "tr_GR640" : "NVIDIA* GeForce* GTX 960",
      "tr_GR641" : "NVIDIA* GeForce* GTX 960A",
      "tr_GR642" : "NVIDIA* GeForce* GTX 960M",
      "tr_GR643" : "NVIDIA* GeForce* GTX 965M",
      "tr_GR644" : "NVIDIA* GeForce* GTX 970",
      "tr_GR645" : "NVIDIA* GeForce* GTX 970M",
      "tr_GR646" : "NVIDIA* GeForce* GTX 980",
      "tr_GR647" : "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648" : "NVIDIA* GeForce* GTX 980M",
      "tr_GR649" : "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650" : "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651" : "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652" : "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653" : "NVIDIA* GeForce* RTX 2050",
      "tr_GR654" : "NVIDIA* GeForce* RTX 2060",
      "tr_GR655" : "NVIDIA* GeForce* RTX 2060 with Max-Q Design",
      "tr_GR656" : "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657" : "NVIDIA* GeForce* RTX 2070",
      "tr_GR658" : "NVIDIA* GeForce* RTX 2070 with Max-Q Design",
      "tr_GR659" : "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660" : "NVIDIA* GeForce* RTX 2070 Super with Max-Q Design",
      "tr_GR661" : "NVIDIA* GeForce* RTX 2080",
      "tr_GR662" : "NVIDIA* GeForce* RTX 2080 with Max-Q Design",
      "tr_GR663" : "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664" : "NVIDIA* GeForce* RTX 2080 Super with Max-Q Design",
      "tr_GR665" : "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666" : "NVIDIA* GeForce* RTX 3050",
      "tr_GR667" : "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668" : "NVIDIA* GeForce* RTX 3060",
      "tr_GR669" : "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670" : "NVIDIA* GeForce* RTX 3070",
      "tr_GR671" : "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672" : "NVIDIA* GeForce* RTX 3080",
      "tr_GR673" : "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674" : "NVIDIA* GeForce* RTX 3090",
      "tr_GR675" : "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676" : "NVIDIA* GeForce* RTX 4050",
      "tr_GR677" : "NVIDIA* GeForce* RTX 4060",
      "tr_GR678" : "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679" : "NVIDIA* GeForce* RTX 4070",
      "tr_GR680" : "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681" : "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682" : "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683" : "NVIDIA* GeForce* RTX 4080",
      "tr_GR684" : "NVIDIA* GeForce* RTX 4090",
      "tr_GR685" : "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686" : "NVIDIA* NVS* 310",
      "tr_GR687" : "NVIDIA* NVS* 315",
      "tr_GR688" : "NVIDIA* NVS* 4200M",
      "tr_GR689" : "NVIDIA* NVS* 510",
      "tr_GR690" : "NVIDIA* NVS* 5200M",
      "tr_GR691" : "NVIDIA* NVS* 5400M",
      "tr_GR692" : "NVIDIA* NVS* 810",
      "tr_GR693" : "NVIDIA* Quadro* 410",
      "tr_GR694" : "NVIDIA* Quadro* GP100",
      "tr_GR695" : "NVIDIA* Quadro* GV100",
      "tr_GR696" : "NVIDIA* Quadro* K1000M",
      "tr_GR697" : "NVIDIA* Quadro* K1100M",
      "tr_GR698" : "NVIDIA* Quadro* K1200",
      "tr_GR699" : "NVIDIA* Quadro* K2000",
      "tr_GR700" : "NVIDIA* Quadro* K2000D",
      "tr_GR701" : "NVIDIA* Quadro* K2000M",
      "tr_GR702" : "NVIDIA* Quadro* K2100M",
      "tr_GR703" : "NVIDIA* Quadro* K2200",
      "tr_GR704" : "NVIDIA* Quadro* K2200M",
      "tr_GR705" : "NVIDIA* Quadro* K3000M",
      "tr_GR706" : "NVIDIA* Quadro* K3100M",
      "tr_GR707" : "NVIDIA* Quadro* K4000",
      "tr_GR708" : "NVIDIA* Quadro* K4000M",
      "tr_GR709" : "NVIDIA* Quadro* K4100M",
      "tr_GR710" : "NVIDIA* Quadro* K420",
      "tr_GR711" : "NVIDIA* Quadro* K4200",
      "tr_GR712" : "NVIDIA* Quadro* K5000",
      "tr_GR713" : "NVIDIA* Quadro* K5000M",
      "tr_GR714" : "NVIDIA* Quadro* K500M",
      "tr_GR715" : "NVIDIA* Quadro* K5100M",
      "tr_GR716" : "NVIDIA* Quadro* K510M",
      "tr_GR717" : "NVIDIA* Quadro* K5200",
      "tr_GR718" : "NVIDIA* Quadro* K600",
      "tr_GR719" : "NVIDIA* Quadro* K6000",
      "tr_GR720" : "NVIDIA* Quadro* K610M",
      "tr_GR721" : "NVIDIA* Quadro* K620",
      "tr_GR722" : "NVIDIA* Quadro* K620M",
      "tr_GR723" : "NVIDIA* Quadro* M1000M",
      "tr_GR724" : "NVIDIA* Quadro* M1200",
      "tr_GR725" : "NVIDIA* Quadro* M2000",
      "tr_GR726" : "NVIDIA* Quadro* M2000M",
      "tr_GR727" : "NVIDIA* Quadro* M2200",
      "tr_GR728" : "NVIDIA* Quadro* M3000M",
      "tr_GR729" : "NVIDIA* Quadro* M4000",
      "tr_GR730" : "NVIDIA* Quadro* M4000M",
      "tr_GR731" : "NVIDIA* Quadro* M5000",
      "tr_GR732" : "NVIDIA* Quadro* M5000M",
      "tr_GR733" : "NVIDIA* Quadro* M500M",
      "tr_GR734" : "NVIDIA* Quadro* M520",
      "tr_GR735" : "NVIDIA* Quadro* M5500",
      "tr_GR736" : "NVIDIA* Quadro* M6000",
      "tr_GR737" : "NVIDIA* Quadro* M600M",
      "tr_GR738" : "NVIDIA* Quadro* M620",
      "tr_GR739" : "NVIDIA* Quadro* P1000",
      "tr_GR740" : "NVIDIA* Quadro* P2000",
      "tr_GR741" : "NVIDIA* Quadro* P2000 with Max-Q Design",
      "tr_GR742" : "NVIDIA* Quadro* P2200",
      "tr_GR743" : "NVIDIA* Quadro* P3000",
      "tr_GR744" : "NVIDIA* Quadro* P3200",
      "tr_GR745" : "NVIDIA* Quadro* P3200 with Max-Q Design",
      "tr_GR746" : "NVIDIA* Quadro* P400",
      "tr_GR747" : "NVIDIA* Quadro* P4000",
      "tr_GR748" : "NVIDIA* Quadro* P4000 with Max-Q Design",
      "tr_GR749" : "NVIDIA* Quadro* P4200",
      "tr_GR750" : "NVIDIA* Quadro* P4200 with Max-Q Design",
      "tr_GR751" : "NVIDIA* Quadro* P500",
      "tr_GR752" : "NVIDIA* Quadro* P5000",
      "tr_GR753" : "NVIDIA* Quadro* P520",
      "tr_GR754" : "NVIDIA* Quadro* P5200",
      "tr_GR755" : "NVIDIA* Quadro* P5200 with Max-Q Design",
      "tr_GR756" : "NVIDIA* Quadro* P600",
      "tr_GR757" : "NVIDIA* Quadro* P6000",
      "tr_GR758" : "NVIDIA* Quadro* P620",
      "tr_GR759" : "NVIDIA* Quadro* RTX 3000",
      "tr_GR760" : "NVIDIA* Quadro* RTX 3000 with Max-Q Design",
      "tr_GR761" : "NVIDIA* Quadro* RTX 4000",
      "tr_GR762" : "NVIDIA* Quadro* RTX 4000 with Max-Q Design",
      "tr_GR763" : "NVIDIA* Quadro* RTX 5000",
      "tr_GR764" : "NVIDIA* Quadro* RTX 5000 with Max-Q Design",
      "tr_GR765" : "NVIDIA* Quadro* RTX 6000",
      "tr_GR766" : "NVIDIA* Quadro* RTX 8000",
      "tr_GR767" : "NVIDIA* Quadro* T1000",
      "tr_GR768" : "NVIDIA* Quadro* T1000 with Max-Q Design",
      "tr_GR769" : "NVIDIA* Quadro* T2000",
      "tr_GR770" : "NVIDIA* Quadro* T2000 with Max-Q Design",
      "tr_GR771" : "NVIDIA* T500",
      "tr_GR772" : "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773" : "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774" : "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775" : "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776" : "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777" : "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778" : "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779" : "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780" : "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781" : "NVIDIA* Tesla* C2070",
      "tr_GR782" : "NVIDIA* Tesla* C2075",
      "tr_GR783" : "NVIDIA* Tesla* K10",
      "tr_GR784" : "NVIDIA* Tesla* K20c",
      "tr_GR785" : "NVIDIA* Tesla* K20m",
      "tr_GR786" : "NVIDIA* Tesla* K20s",
      "tr_GR787" : "NVIDIA* Tesla* K20Xm",
      "tr_GR788" : "NVIDIA* Tesla* K40c",
      "tr_GR789" : "NVIDIA* Tesla* K40m",
      "tr_GR790" : "NVIDIA* Tesla* K40s",
      "tr_GR791" : "NVIDIA* Tesla* K40st",
      "tr_GR792" : "NVIDIA* Tesla* K40t",
      "tr_GR793" : "NVIDIA* Tesla* K8",
      "tr_GR794" : "NVIDIA* Tesla* K80",
      "tr_GR795" : "NVIDIA* Tesla* M2050",
      "tr_GR796" : "NVIDIA* Tesla* M2070",
      "tr_GR797" : "NVIDIA* Tesla* M2070-Q",
      "tr_GR798" : "NVIDIA* Tesla* M2075",
      "tr_GR799" : "NVIDIA* Tesla* M2090",
      "tr_GR800" : "NVIDIA* Tesla* S2050",
      "tr_GR801" : "NVIDIA* Tesla* T20",
      "tr_GR802" : "NVIDIA* Tesla* T4",
      "tr_GR803" : "NVIDIA* Tesla* X2070",
      "tr_GR804" : "NVIDIA* Tesla* X2090",
      "tr_GR805" : "NVIDIA* TITAN RTX",
      "tr_GR806" : "NVIDIA* TITAN V",
      "tr_GR807" : "NVIDIA* TITAN X",
      "tr_GR808" : "NVIDIA* TITAN Xp",
      "tr_GR809" : "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810" : "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811" : "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812" : "Intel® Arc™ Graphics",
      "tr_GR813" : "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814" : "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815" : "AMD Radeon* Pro W7900 Dual Slot"
    },
    "tr_WifiDescMapping" : {
      "tr_Wifi1" : "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2" : "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3" : "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4" : "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5" : "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6" : "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7" : "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8" : "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9" : "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10" : "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11" : "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12" : "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13" : "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14" : "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15" : "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16" : "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17" : "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18" : "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19" : "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20" : "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21" : "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22" : "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23" : "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24" : "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25" : "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26" : "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27" : "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28" : "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29" : "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30" : "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31" : "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32" : "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33" : "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34" : "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35" : "Intel® Wireless-AC 3165",
      "tr_Wifi36" : "Intel® Wireless-AC 3168",
      "tr_Wifi37" : "Intel® Wireless-AC 8265",
      "tr_Wifi38" : "Intel® Wireless-AC 9260",
      "tr_Wifi39" : "Intel® Wireless-AC 9461",
      "tr_Wifi40" : "Intel® Wireless-AC 9462",
      "tr_Wifi41" : "Intel® Wireless-AC 9560",
      "tr_Wifi42" : "Intel® Wireless-N 3160",
      "tr_Wifi43" : "Intel® Wireless-N 7260",
      "tr_Wifi44" : "Intel® Wireless-N 7265",
      "tr_Wifi45" : "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46" : "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47" : "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48" : "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49" : "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50" : "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51" : "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52" : "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53" : "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54" : "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55" : "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56" : "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57" : "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58" : "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59" : "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60" : "Intel® Killer™ Wi-Fi 7 BE1750"
  },

    "tr_textBuyNow": "Buy Now",
    "tr_textTryagain": "Try again",
    "tr_textBuynowErr": "Due to a connection interruption, the Buy Now option is not available",
    "tr_chatbotPlaceholder_text" : "Ask me anything about the device",
    "tr_deviceSpecs_text" : "Device Specs"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)" // Standard 12th Gen BG image
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }   

  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title":"Intel® Core™ i3 processor with Intel® Hybrid Technology",
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title":"Intel® Core™ i5 processor with Intel® Hybrid Technology",
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles":[
    {
      "tilesType": "GAMING", // OR standard OR evo
      "tilesDeviceTypes": [
      {
        "deviceType": "DEFAULT", // OR laptop OR desktop
        "tilesDetails": [{
          "tr_tileTitle": "Marvel's Avengers",
          "tileUrlName": "avengers",
          "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
          "tileDetails": {            
            "panelType1":"gaming-tile-panel",            
            "tr_title": "Gear up with Intel® for a Mightier Gaming Experience",
            "tr_subtitle": "Bring Marvel’s Avengers* to life with in-game PC optimizations, powered by Intel®.",
            "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
            "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
          }          
        },
        {
          "tr_tileTitle": "Experience Intel® Extreme Masters",
          "tileUrlName": "esl",
          "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
          "tileDetails": {            
            "panelType1":"gaming-tile-panel",            
            "tr_title": "Where the world’s best gamers come to compete",
            "tr_subtitle": "The longest running event series in video game history, Intel® Extreme Masters sets the standards for e-sports.",
            "videoUrl": "assets/videos/gaming/esl_video.mp4",
            "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
          }          
        },
        {
          "tr_tileTitle": "Play like the pros",
          "tileUrlName": "influencer",
          "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
          "tileDetails": {            
            "panelType1":"gaming-tile-panel",            
            "tr_title": "Champions happen with Intel.",
            "tr_subtitle": "After winning the 2021 Rainbow Six Siege* North American League championship, the Soniqs reflect on their gaming history, the future of e-sports and their partnership with Intel.",
            "tr_pretitle": "Featuring the Soniqs",
            "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
            "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
            "subtitleUrl":"assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
          }          
        },
        {
          "tr_tileTitle": "VR Ready",
          "tileUrlName": "vr",
          "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
          "tileDetails": {            
            "panelType1":"gaming-tile-panel",            
            "tr_title": "Where VR comes alive",
            "tr_subtitle": "The most amazing, life-like and immersive virtual reality experiences run on a computer. With the latest Intel® Core™ processors, you can be anyone, go anywhere and experience gaming in the next dimension.",
            "videoUrl": "assets/videos/gaming/vr_video.mp4",
            "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
          }          
        },
        {
          "tr_tileTitle": "Xbox* Game Pass Ultimate",
          "tileUrlName": "xboxcontent",
          "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
          "tileDetails": {            
            "panelType1":"gaming-tile-panel",
            "panelType2":"xbox-content-tile-panel",            
            "tr_title": "Xbox* Game Pass Ultimate",
            "tr_subtitle": "Play 100+ high-quality console games like Sea of Thieves* and Psychonauts* 2 on your Windows* PC, mobile phone, and tablet with Xbox* Game Pass Ultimate and a compatible controller.",
            "titleArray": [{ //for second panel title and subtitle
              "tr_title": "Play together across devices", 
              "tr_subtitle": "Tap into the heart of Xbox* with a community of millions of players ready and waiting to play together. Connect and play with others from a shared library of games, whether they're on the other side of the world or sitting right next to you."
            },
            {
              "tr_title": "Windows* PC gaming",
              "tr_subtitle": "Play a catalog of console games from the cloud using the Xbox* app for Windows* and a compatible controller."
            },
            {
              "tr_title": "Pick up and play",
              "tr_subtitle": "Enjoy great Xbox* games in more places than before. Play 100+ games on your PC, mobile phone, and tablet whether you’re at home while your TV is in use, at a café, or anywhere else with a high-speed internet connection."
            },
            {
              "tr_title": "Controller-free fun",
              "tr_subtitle": "Xbox* touch control gaming unlocks new ways to jump in and play that provide a familiar experience and support a level of play that you are used to with a physical controller.",
            },
          ]           
          }
        }]
      }]
    },
    {
      "tilesType": "STANDARD", // OR standard OR evo
      "tilesDeviceTypes": [
      {
        "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
        "tilesDetails": [
          {
            "tr_tileTitle": "Spotlight",
            "tileUrlName": "spotlight",
            "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "100+ AI experiences",
                "preTitle": "",
                "detailPageName": "aiexperience",
                "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
              },
              {
                "tr_title": "Control more, touch less",
                "preTitle": "",
                "detailPageName": "controlmore",
                "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
              },
              {
                "tr_title": "Stay in frame, in any environment",
                "preTitle": "",
                "detailPageName": "stayinframe",
                "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
              }]                        
            }
          },
          {
            "tr_tileTitle": "Creativity",
            "tileUrlName": "creativity",
            "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Correct lighting like a pro",
                "preTitle": "",
                "detailPageName": "lighting",
                "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
              },
              {
                "tr_title": "Add larger-than-life effects with ease",
                "preTitle": "",
                "detailPageName": "effects",
                "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
              },
              {
                "tr_title": "Remix songs with the power of AI",
                "preTitle": "",
                "detailPageName": "remix",
                "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
              }]                        
            }
          },
          {
            "tr_tileTitle": "Productivity",
            "tileUrlName": "productivity",
            "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Boost your productivity and privacy",
                "preTitle": "",
                "detailPageName": "boostyourproductivity",
                "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
              },
              {
                "tr_title": "Spend more time away from the outlet",
                "preTitle": "",
                "detailPageName": "spentmore",
                "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
              },
              {
                "tr_title": "Your world. On your devices.",
                "preTitle": "",
                "detailPageName": "yourworld",
                "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
              }]                        
            }            
          },
          {
            "tr_tileTitle": "Entertainment",
            "tileUrlName": "entertainment",
            "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Smooth streaming, even when unplugged",
                "preTitle": "",
                "detailPageName": "smoothstreaming",
                "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg", // add image url
                "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
              },
              {
                "tr_title": "Fast, immersive gaming",
                "preTitle": "",
                "detailPageName": "fastimmersive",
                "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg", // add image url
                "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
              }]                        
            }
          }
          ]
      },
      {
        "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
        "tilesDetails": [
          {
            "tr_tileTitle": "Spotlight",
            "tileUrlName": "spotlight",
            "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "100+ AI experiences",
                "preTitle": "",
                "detailPageName": "aiexperience",
                "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
              },
              {
                "tr_title": "Make your favorite songs truly yours",
                "preTitle": "",
                "detailPageName": "favouritesongs",
                "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
              },
              {
                "tr_title": "See your vision in real time",
                "preTitle": "",
                "detailPageName": "realtime",
                "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
              }]                        
            }
          },
          {
            "tr_tileTitle": "Creativity",
            "tileUrlName": "creativity",
            "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Fastest way to your final cut",
                "preTitle": "",
                "detailPageName": "fastestway",
                "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
              },
              {
                "tr_title": "Make every photo picture perfect",
                "preTitle": "",
                "detailPageName": "effects",
                "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
              }]                        
            }
          },
          {
            "tr_tileTitle": "Productivity",
            "tileUrlName": "productivity",
            "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Boost your productivity and privacy",
                "preTitle": "",
                "detailPageName": "boostyourproductivity",
                "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
              },
              {
                "tr_title": "More efficient. More freedom.",
                "preTitle": "",
                "detailPageName": "morefreedom",
                "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
              },
              {
                "tr_title": "Your world. On your devices.",
                "preTitle": "",
                "detailPageName": "yourworld",
                "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
              }]                        
            }            
          },
          {
            "tr_tileTitle": "Entertainment",
            "tileUrlName": "entertainment",
            "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Smooth HD streaming, even when unplugged",
                "preTitle": "",
                "detailPageName": "smoothHDstreaming",
                "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg", // add image url
                "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
              },
              {
                "tr_title": "Immersive gaming, in more places",
                "preTitle": "",
                "detailPageName": "fastimmersive",
                "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg", // add image url
                "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
              }]                        
            }
          }
          ]
      },
      {
        "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
        "tilesDetails": [
          {
            "tr_tileTitle": "More Creative",
            "tileUrlName": "creative",
            "tileBadgeUrl": "",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Photo editing made easy",
                "preTitle": "",
                "detailPageName": "photoediting",
                "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
              },
              {
                "tr_title": "Create frame-worthy photos in seconds",
                "preTitle": "",
                "detailPageName": "createframe",
                "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
              },
              {
                "tr_title": "You type it. AI makes it.",
                "preTitle": "",
                "detailPageName": "youtypeit",
                "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
              }]                        
            }
          },
          {
            "tr_tileTitle": "More Performance",
            "tileUrlName": "performance",
            "tileBadgeUrl": "",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "The cheat code to smoother gaming",
                "preTitle": "",
                "detailPageName": "cheatcode",
                "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
              },
              {
                "tr_title": "Spend more time away from the outlet",
                "preTitle": "",
                "detailPageName": "spendmore",
                "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
              },
              {
                "tr_title": "Ultra-immersive",
                "preTitle": "",
                "detailPageName": "ultraimmersive",
                "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
              }]                        
            }
          },
          {
            "tr_tileTitle": "More Productive",
            "tileUrlName": "productive",
            "tileBadgeUrl": "",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Your everyday AI companion",
                "preTitle": "",
                "detailPageName": "aicompanion",
                "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
              },
              {
                "tr_title": "Video call perfection",
                "preTitle": "",
                "detailPageName": "videocall",
                "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
              },
              {
                "tr_title": "Your world. On your devices.",
                "preTitle": "",
                "detailPageName": "yourworld",
                "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
              }]                        
            }            
          },
          {
            "tr_tileTitle": "More Secure",
            "tileUrlName": "secure",
            "tileBadgeUrl": "",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Built-in protections to help keep your data more secure and private",
                "preTitle": "",
                "detailPageName": "builtinprotection",
                "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg", // add image url
                "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
              }]                        
            }
          }
          ]
      },
      {
        "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
        "tilesDetails": [
          {
            "tr_tileTitle": "More Creative",
            "tileUrlName": "creative",
            "tileBadgeUrl": "",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Photo editing made easy",
                "preTitle": "",
                "detailPageName": "photoediting",
                "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
              },
              {
                "tr_title": "AI. The shortcut to your final cut.",
                "preTitle": "",
                "detailPageName": "aishortcut",
                "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
              },
              {
                "tr_title": "Quickly transcribe your favorite song lyrics",
                "preTitle": "",
                "detailPageName": "favouritesong",
                "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
              },{
                "tr_title": "Save hours of editing with AI",
                "preTitle": "",
                "detailPageName": "savehours",
                "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
              }]                        
            }
          },
          {
            "tr_tileTitle": "More Performance",
            "tileUrlName": "performance",
            "tileBadgeUrl": "",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "The cheat code to smoother gaming",
                "preTitle": "",
                "detailPageName": "cheatcode",
                "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
              },
              {
                "tr_title": "Spend more time away from the outlet",
                "preTitle": "",
                "detailPageName": "spendmore",
                "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
              },
              {
                "tr_title": "Ultra-immersive",
                "preTitle": "",
                "detailPageName": "ultraimmersive",
                "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
              }]                        
            }
          },
          {
            "tr_tileTitle": "More Productive",
            "tileUrlName": "productive",
            "tileBadgeUrl": "",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Your everyday AI companion",
                "preTitle": "",
                "detailPageName": "aicompanion",
                "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
              },
              {
                "tr_title": "Video call perfection",
                "preTitle": "",
                "detailPageName": "videocall",
                "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
              },
              {
                "tr_title": "Your world. On your devices.",
                "preTitle": "",
                "detailPageName": "yourworld",
                "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
              }]                        
            }            
          },
          {
            "tr_tileTitle": "More Secure",
            "tileUrlName": "secure",
            "tileBadgeUrl": "",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Built-in protections to help keep your data more secure and private",
                "preTitle": "",
                "detailPageName": "builtinprotection",
                "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg", // add image url
                "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
              }]                        
            }
          }
          ]
      },
      {
        "deviceType": "DEFAULT", // OR laptop OR desktop
        "tilesDetails": [{
          "tr_tileTitle": "All your entertainment. One PC.",
          "tileUrlName": "entertainment",
          "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
          "tileDetails": {            
            "panelType1":"gaming-tile-panel",
            "panelType2":"gaming-tile-panel2",            
            "tr_title": "",
            "tr_subtitle": "For a PC that doubles as an entertainment center you need enough storage for all your favorite songs, videos and games and the right combination of processor and display to create immersive visuals. This PC has all three.",
            "titleArray": [{ // Laptop
              "tr_title": "Ready for next-gen visuals",
              "preTitle": "[processor_value]",
              "detailPageName": "processor",
            },
            {
              "tr_title": "Build your digital library",
              "preTitle": "[storage_value]",
              "detailPageName": "storage",
            },
            {
              "tr_title": "A window to your entertainment",
              "preTitle": "[display_value]",
              "detailPageName": "display",
            }],
            "titleArray1": [{ // Desktop
              "tr_title": "Seamless gaming and streaming",
              "preTitle": "[processor_value]",
              "detailPageName": "processor",
            },
            {
              "tr_title": "Build your digital library",
              "preTitle": "[storage_value]",
              "detailPageName": "storage",
            },
            {
              "tr_title": "A window to your entertainment",
              "preTitle": "[display_value]",
              "detailPageName": "display",
          }]
          }          
        },
        {
          "tr_tileTitle": "Be more productive. Anywhere.", //laptop
          "tr_tileTitle1": "Get more done. Fast.", //desktop
          "tileUrlName": "productivity",
          "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
          "tileDetails": {            
            "panelType1": "gaming-tile-panel",
            "panelType2": "gaming-tile-panel2",            
            "tr_title": "",
            "tr_subtitle": "This PC has the perfect balance of power and portability. Starting with a processor made for thin and light designs, this PC also features the kind of Wi&#8209;Fi to keep you connected and the right battery to keep you unplugged.",
            "tr_subtitle1": "This PC has the perfect balance of power and performance to help you accomplish more in less time. The combination of a robust processor accelerated by ample memory and lightning fast Wi&#8209;Fi will streamline your daily tasks.",
            "tr_subtitle2": "This PC has the perfect balance of power and portability. Starting with a processor made for thin and light designs, this PC also features the kind of Wi&#8209;Fi to keep you connected and the right memory for multitasking.",
            "titleArray": [{ // Laptop
              "tr_title": "Built to do more", // 12th gen
              "tr_title1": "Intelligent performance that matters", // 11th gen
              "preTitle": "[processor_value]",
              "detailPageName": "processor",
            },
            {
              "tr_title": "Best-in-class connections",
              "tr_title1": "Connect to what matters",
              "tr_title2": "A major advancement in Wi&#8209;Fi",
              "preTitle": "[wifi_value]",
              "detailPageName": "wifi",
            },
            {
              "tr_title": "Stay unplugged longer",
              "preTitle": "[battery_value]",
              "detailPageName": "battery",
            },
            {
              "tr_title": "Juggle programs like a pro",
              "preTitle": "[memory_value]",
              "detailPageName": "memory",
            }],
          }          
        },
        {
          "tr_tileTitle": "The freedom to create",
          "tileUrlName": "content",
          "tileBadgeUrl": "assets/images/standard/content-tile.svg",
          "tileDetails": {            
            "panelType1":"gaming-tile-panel",
            "panelType2":"gaming-tile-panel2",            
            "tr_title": "",
            "tr_subtitle": "Content creation is one of the most demanding tasks for your PC. Fortunately, these components have the power to streamline your creative process.",
            "titleArray": [{ // Laptop
              "tr_title": "A smarter way to create",
              "preTitle": "[processor_value]",
              "detailPageName": "processor",
            },
            {
              "tr_title": "Bring your images to life",
              "tr_title1": "Let’s Create",
              "preTitle": "[graphics_value]",
              "detailPageName": "graphics",
            },
            {
              "tr_title": "Memory for multitasking",
              "preTitle": "[memory_value]",
              "detailPageName": "memory",
            }],            
          }          
        },
        {
          "tr_tileTitle": "Look inside this PC",
          "tileUrlName": "lookinside",
          "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
          "tileDetails": {            
            "panelType1":"gaming-tile-panel",
            "panelType2":"gaming-tile-panel2",            
            "tr_title": "",
            "tr_subtitle": "These are the three main components to keep your eye on when choosing a new PC. By deciding how you’re going to use your new PC, you’ve come a long way toward deciding how much of these three key components you’ll need. They are:",
            "titleArray": [{ // Laptop
              "tr_title": "Performance for Gaming and Streaming",
              "preTitle": "[processor_value]",
              "detailPageName": "processor",
            },
            {
              "tr_title": "Storage",
              "preTitle": "[storage_value]",
              "detailPageName": "storage",
            },
            {
              "tr_title": "Memory",
              "preTitle": "[memory_value]",
              "detailPageName": "memory",
            }],            
          }          
        }
      ]
      }]
    },
    {
      "tilesType": "UWP", // OR standard OR evo
      "tilesDeviceTypes": [
      {
        "deviceType": "DEFAULT", // OR laptop OR desktop
        "tilesDetails": [
          {
            "tr_tileTitle": "[processor_value]", //laptop
            "tileUrlName": "processor",
            "tileBadgeUrl": "assets/images/standard/[processor_badge].svg",
          },
          {
          "tr_tileTitle": "All your entertainment. One PC.",
          "tileUrlName": "entertainment",
          "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
          "tileDetails": {            
            "panelType1":"gaming-tile-panel",
            "panelType2":"gaming-tile-panel2",            
            "tr_title": "",
            "tr_subtitle": "For a PC that doubles as an entertainment center you need enough storage for all your favorite songs, videos and games and the right combination of processor and display to create immersive visuals. This PC has all three.",
            "titleArray": [{ // Laptop
              "tr_title": "Ready for next-gen visuals",
              "preTitle": "[processor_value]",
              "detailPageName": "processor",
            },
            {
              "tr_title": "Build your digital library",
              "preTitle": "[storage_value]",
              "detailPageName": "storage",
            },
            {
              "tr_title": "A window to your entertainment",
              "preTitle": "[display_value]",
              "detailPageName": "display",
            }],
            "titleArray1": [{ // Desktop
              "tr_title": "Seamless gaming and streaming",
              "preTitle": "[processor_value]",
              "detailPageName": "processor",
            },
            {
              "tr_title": "Build your digital library",
              "preTitle": "[storage_value]",
              "detailPageName": "storage",
            },
            {
              "tr_title": "A window to your entertainment",
              "preTitle": "[display_value]",
              "detailPageName": "display",
          }]
          }          
        },
        {
          "tr_tileTitle": "Memory", //laptop
          "tileUrlName": "memory",
          "tileBadgeUrl": "assets/images/standard/memory-tile.svg",
        },
        {
          "tr_tileTitle": "Display", //laptop
          "tileUrlName": "display",
          "tileBadgeUrl": "assets/images/standard/display-tile.svg",
        }        
      ]
      }]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails":
            [
              {
                "tr_tileTitle": "[processor_value]",
                "tileUrlName": "processor",
                "tileBadgeUrl": "assets/images/creator/[processor_badge].svg",
              },
              {
                "tr_tileTitle": "High-speed multitasking",
                "tileUrlName": "highspeed",
                "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "HIGH-SPEED MULTITASKING",
                  "tr_subtitle": "Do more without slowing down",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for multitasking.",
                  "tileBG": "#0093C7",
                  "titleArray": [{
                    "tr_title": "A processor that can juggle several programs at once."
                    },
                    {
                      "tr_title": "Wi&#8209;Fi that is fast, strong and reliable."
                    },
                    {
                      "tr_title": "Memory that seamlessly switches between applications."
                    }
                  ]
                }
              },
              {
                "tr_tileTitle": "Video chatting",
                "tileUrlName": "videochatting",
                "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "VIDEO CHATTING",
                  "tr_subtitle": "Crystal-clear connections",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for video chatting.",
                  "tileBG": "#E96115",
                  "titleArray": [{
                    "tr_title": "A processor that can record and share images at the same time."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi that is fast, strong and reliable."
                  },
                  {
                    "tr_title": "A webcam that will help you come across crystal clear."
                  }
                  ]
                }
              },
              {
                "tr_tileTitle": "VR Ready",
                "tileUrlName": "vr",
                "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
                "tileDetails": {            
                  "panelType1":"gaming-tile-panel",   
                  "panelType2":"gaming-tile-panel2",          
                  "tr_title": "Where VR comes alive",
                  "tr_subtitle": "What does it take to create a completely virtual world? On a PC you’ll need the coordination between your processor, graphics card and memory.",
                  "titleArray": [{
                      "tr_title": "Powering virtual worlds",
                      "preTitle": "[processor_value]",
                      "detailPageName": "processor",
                    },
                    {
                      "tr_title": "The reality is in the details",
                      "tr_title1": "Get immersed",
                      "preTitle": "[graphics_value]",
                      "detailPageName": "graphics",
                  }],
                }           
              }
            ]
        }]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails":
            [
              {
                "tr_tileTitle": "[processor_value]",
                "tileUrlName": "processor",
                "tileBadgeUrl": "assets/images/creator/[processor_badge].svg",
              },
              {
                "tr_tileTitle": "Video editing",
                "tileUrlName": "videoediting",
                "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "VIDEO EDITING",
                  "tr_subtitle": "Make movie magic",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for video editing.",
                  "tileBG": "#00376D",
                  "titleArray": [{
                    "tr_title": "A processor that can handle large files."
                  },
                  {
                    "tr_title": "Memory that makes editing software fast and responsive."
                  },
                  {
                    "tr_title": "Graphics that will cut down on rendering times."
                  }
                  ]
                }
              },
              {
                "tr_tileTitle": "High-speed multitasking",
                "tileUrlName": "highspeed",
                "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "HIGH-SPEED MULTITASKING",
                  "tr_subtitle": "Do more without slowing down",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for multitasking.",
                  "tileBG": "#0093C7",
                  "titleArray": [{
                    "tr_title": "A processor that can juggle several programs at once."
                    },
                    {
                      "tr_title": "Wi&#8209;Fi that is fast, strong and reliable."
                    },
                    {
                      "tr_title": "Memory that seamlessly switches between applications."
                    }
                  ]
                }
              },
              {
                "tr_tileTitle": "VR Ready",
                "tileUrlName": "vr",
                "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
                "tileDetails": {            
                  "panelType1":"gaming-tile-panel",   
                  "panelType2":"gaming-tile-panel2",          
                  "tr_title": "Where VR comes alive",
                  "tr_subtitle": "What does it take to create a completely virtual world? On a PC you’ll need the coordination between your processor, graphics card and memory.",
                  "titleArray": [{
                      "tr_title": "Powering virtual worlds",
                      "preTitle": "[processor_value]",
                      "detailPageName": "processor",
                    },
                    {
                      "tr_title": "The reality is in the details",
                      "tr_title1": "Get immersed",
                      "preTitle": "[graphics_value]",
                      "detailPageName": "graphics",
                  }],
                }           
              }
            ]
        }]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails":
            [
              {
                "tr_tileTitle": "[processor_value]",
                "tileUrlName": "processor",
                "tileBadgeUrl": "assets/images/creator/[processor_badge].svg",
              },
              {
                "tr_tileTitle": "3D modeling",
                "tileUrlName": "threedmodeling",
                "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "3D MODELING",
                  "tr_subtitle": "Next-level creations",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for 3D modeling.",
                  "tileBG": "#00285A",
                  "titleArray": [{
                    "tr_title": "A processor that can handle advanced creative software."
                  },
                  {
                    "tr_title": "Memory that can quickly bring up large files."
                  },
                  {
                    "tr_title": "Graphics for powerful image creating and editing."
                  }
                  ]
                }
              },
              {
                "tr_tileTitle": "Real-time collaboration",
                "tileUrlName": "realtime",
                "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "REAL-TIME COLLABORATION",
                  "tr_subtitle": "Work from anywhere",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for online collaborations.",
                  "tileBG": "#D42931",
                  "titleArray": [{
                    "tr_title": "A processor to power office software."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi that you can rely on."
                  },
                  {
                    "tr_title": "A webcam that records in brilliant HD."
                  }
                  ]
                }
              },
              {
                "tr_tileTitle": "VR Ready",
                "tileUrlName": "vr",
                "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
                "tileDetails": {            
                  "panelType1":"gaming-tile-panel",   
                  "panelType2":"gaming-tile-panel2",          
                  "tr_title": "Where VR comes alive",
                  "tr_subtitle": "What does it take to create a completely virtual world? On a PC you’ll need the coordination between your processor, graphics card and memory.",
                  "titleArray": [{
                      "tr_title": "Powering virtual worlds",
                      "preTitle": "[processor_value]",
                      "detailPageName": "processor",
                    },
                    {
                      "tr_title": "The reality is in the details",
                      "tr_title1": "Get immersed",
                      "preTitle": "[graphics_value]",
                      "detailPageName": "graphics",
                  }],
                }           
              }             
            ]
        }]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
      {
        "deviceType": "11GEN_EVO",
        "tilesDetails": [
          {
            "tr_tileTitle": "Intel® Evo™ laptops",
            "tileUrlName": "evocontent",
            "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"evo-content-tile-panel",            
              "tr_title": "Laptops this thin & light aren’t supposed to be this powerful",
              "tr_subtitle": "Engineered for mobile performance, Intel® Evo™ laptops have all the speed, graphics and battery life you need, all in the most stunning thin and light designs.",
              "titleArray": [{
                "tr_title": "The laptop means more than ever, so we made sure it can do more than ever.", 
                "tr_subtitle": "Use your touchscreen to explore, rotate, and zoom."
              }],
              "titleArray1": [{
                "tr_title": "Wake in a flash", 
                "tr_subtitle": "With instant wake your PC can go from sleep to ready in less than a second."
              },
              {
                "tr_title": "Immersive viewing", 
                "tr_subtitle": "A beautiful display brings your visuals to life in crisp resolution and vivid colors."
              },
              {
                "tr_title": "Get things done fast", 
                "tr_subtitle": "Enjoy ultra-responsive performance from the 11th Gen Intel® Core™ processors."
              },
              {
                "tr_title": "Unplug for longer", 
                "tr_subtitle": "Stay in the flow with a PC that optimizes your battery life and recharges in a flash."
              },
              {
                "tr_title": "Universal cable connectivity", 
                "tr_subtitle": "Makes plugging other devices into your PC simple, fast and secure with Thunderbolt™ 4 technology.<sup>10</sup>"
              },
              {
                "tr_title": "Fast, reliable connections", 
                "tr_subtitle": "Video chat or share files with a connection you can count on using Intel® Wi&#8209;Fi 6 (Gig+).<sup>1</sup>"
              }]            
            }
          }]
      },
      {
        "deviceType": "12GEN_EVO",
        "tilesDetails": [
          {
            "tr_tileTitle": "Intel® Evo™ laptops",
            "tileUrlName": "evocontent",
            "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"evo-content-tile-panel",            
              "tr_title": "All you need and more in a thin and light laptop.",
              "tr_subtitle": "Intel® Evo™ laptops are engineered for mobile performance and designed to give you the best experience<sup>2</sup>, no matter what you’re doing.",
              "titleArray": [{
                "tr_title": "The laptop means more than ever, so we made sure it can do more than ever.", 
                "tr_subtitle": "Use your touchscreen to explore, rotate, and zoom."
              }],
              "titleArray1": [{
                "tr_title": "Wake in a flash", 
                "tr_subtitle": "With instant wake your PC can go from sleep to ready in less than a second."
              },
              {
                "tr_title": "Immersive viewing", 
                "tr_subtitle": "A beautiful display brings your visuals to life in crisp resolution and vivid colors."
              },
              {
                "tr_title": "Revolutionary performance", 
                "tr_subtitle": "Get extra power where you need it most with intelligent performance from 12th Gen Intel® Core™ processors."
              },
              {
                "tr_title": "Unplug for longer", 
                "tr_subtitle": "Stay in the flow with a PC that optimizes your battery life and recharges in a flash."
              },
              {
                "tr_title": "Universal cable connectivity", 
                "tr_subtitle": "Makes plugging other devices into your PC simple, fast and secure with Thunderbolt™ 4 technology.<sup>10</sup>"
              },
              {
                "tr_title": "Fast, reliable connections", 
                "tr_subtitle": "Video chat or share files with a connection you can count on using Intel® Wi&#8209;Fi 6E (Gig+).<sup>1</sup>"
              }]            
            }
          }
        ]
      },
      {
        "deviceType": "11GEN_EVO_UWP",
        "tilesDetails": [
        {
          "tr_tileTitle": "[processor_value]",
          "tileUrlName": "processor",
          "tileBadgeUrl": "[processor_badge]",
        },
        {
          "tr_tileTitle": "Intel® Evo™ laptops",
          "tileUrlName": "evocontent",
          "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
          "tileDetails": {            
            "panelType1":"gaming-tile-panel",
            "panelType2":"evo-content-tile-panel",            
            "tr_title": "Laptops this thin & light aren’t supposed to be this powerful",
            "tr_subtitle": "Engineered for mobile performance, Intel® Evo™ laptops have all the speed, graphics and battery life you need, all in the most stunning thin and light designs.",
            "titleArray": [{
              "tr_title": "The laptop means more than ever, so we made sure it can do more than ever.", 
              "tr_subtitle": "Use your touchscreen to explore, rotate, and zoom."
            }],
            "titleArray1": [{
              "tr_title": "Wake in a flash", 
              "tr_subtitle": "With instant wake your PC can go from sleep to ready in less than a second."
            },
            {
              "tr_title": "Immersive viewing", 
              "tr_subtitle": "A beautiful display brings your visuals to life in crisp resolution and vivid colors."
            },
            {
              "tr_title": "Get things done fast", 
              "tr_subtitle": "Enjoy ultra-responsive performance from the 11th Gen Intel® Core™ processors."
            },
            {
              "tr_title": "Unplug for longer", 
              "tr_subtitle": "Stay in the flow with a PC that optimizes your battery life and recharges in a flash."
            },
            {
              "tr_title": "Universal cable connectivity", 
              "tr_subtitle": "Makes plugging other devices into your PC simple, fast and secure with Thunderbolt™ 4 technology.<sup>10</sup>"
            },
            {
              "tr_title": "Fast, reliable connections", 
              "tr_subtitle": "Video chat or share files with a connection you can count on using Intel® Wi&#8209;Fi 6 (Gig+).<sup>1</sup>"
            }]            
          }
        }      
      ]
      },
      {
        "deviceType": "12GEN_EVO_UWP",
        "tilesDetails": [
        {
          "tr_tileTitle": "[processor_value]",
          "tileUrlName": "processor",
          "tileBadgeUrl": "[processor_badge]",
        },        
        {
          "tr_tileTitle": "Intel® Evo™ laptops",
          "tileUrlName": "evocontent",
          "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
          "tileDetails": {            
            "panelType1":"gaming-tile-panel",
            "panelType2":"evo-content-tile-panel",            
            "tr_title": "All you need and more in a thin and light laptop.",
            "tr_subtitle": "Intel® Evo™ laptops are engineered for mobile performance and designed to give you the best experience<sup>2</sup>, no matter what you’re doing.",
            "titleArray": [{ //for second panel title and subtitle
              "tr_title": "The laptop means more than ever, so we made sure it can do more than ever.", 
              "tr_subtitle": "Use your touchscreen to explore, rotate, and zoom."
            }],
            "titleArray1": [{
              "tr_title": "Wake in a flash", 
              "tr_subtitle": "With instant wake your PC can go from sleep to ready in less than a second."
            },
            {
              "tr_title": "Immersive viewing", 
              "tr_subtitle": "A beautiful display brings your visuals to life in crisp resolution and vivid colors."
            },
            {
              "tr_title": "Revolutionary performance", 
              "tr_subtitle": "Get extra power where you need it most with intelligent performance from 12th Gen Intel® Core™ processors."
            },
            {
              "tr_title": "Unplug for longer", 
              "tr_subtitle": "Stay in the flow with a PC that optimizes your battery life and recharges in a flash."
            },
            {
              "tr_title": "Universal cable connectivity", 
              "tr_subtitle": "Makes plugging other devices into your PC simple, fast and secure with Thunderbolt™ 4 technology.<sup>10</sup>"
            },
            {
              "tr_title": "Fast, reliable connections", 
              "tr_subtitle": "Video chat or share files with a connection you can count on using Intel® Wi&#8209;Fi 6E (Gig+).<sup>1</sup>"
            }]            
          }
        }        
      ]
      },
      {
        "deviceType": "13GEN_EVO",
        "tilesDetails": [
          {
            "tr_tileTitle": "Intel® Evo™ laptops",
            "tileUrlName": "evocontent",
            "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"evo-content-tile-panel",            
              "tr_title": "All you need and more in a thin and light laptop.",
              "tr_subtitle": "Intel® Evo™ laptops are engineered for mobile performance and designed to give you the best experience<sup>2</sup>, no matter what you’re doing.",
              "titleArray": [{
                "tr_title": "The laptop means more than ever, so we made sure it can do more than ever.", 
                "tr_subtitle": "Use your touchscreen to explore, rotate, and zoom."
              }],
              "titleArray1": [{
                "tr_title": "Wake in a flash", 
                "tr_subtitle": "With instant wake your PC can go from sleep to ready in less than a second."
              },
              {
                "tr_title": "Immersive viewing", 
                "tr_subtitle": "A beautiful display brings your visuals to life in crisp resolution and vivid colors."
              },
              {
                "tr_title": "Revolutionary performance", 
                "tr_subtitle": "Get extra power where you need it most with intelligent performance from 13th Gen Intel® Core™ processors."
              },
              {
                "tr_title": "Unplug for longer", 
                "tr_subtitle": "Stay in the flow with a PC that optimizes your battery life and recharges in a flash."
              },
              {
                "tr_title": "Universal cable connectivity", 
                "tr_subtitle": "Makes plugging other devices into your PC simple, fast and secure with Thunderbolt™ 4 technology.<sup>10</sup>"
              },
              {
                "tr_title": "Fast, reliable connections", 
                "tr_subtitle": "Video chat or share files with a connection you can count on using Intel® Wi&#8209;Fi 6E (Gig+).<sup>1</sup>"
              }]            
            }
          }
        ]
      },
      {
        "deviceType": "13GEN_EVO_UWP",
        "tilesDetails": [
          {
            "tr_tileTitle": "[processor_value]",
            "tileUrlName": "processor",
            "tileBadgeUrl": "[processor_badge]",
          },
          {
            "tr_tileTitle": "Intel® Evo™ laptops",
            "tileUrlName": "evocontent",
            "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"evo-content-tile-panel",            
              "tr_title": "All you need and more in a thin and light laptop.",
              "tr_subtitle": "Intel® Evo™ laptops are engineered for mobile performance and designed to give you the best experience<sup>2</sup>, no matter what you’re doing.",
              "titleArray": [{
                "tr_title": "The laptop means more than ever, so we made sure it can do more than ever.", 
                "tr_subtitle": "Use your touchscreen to explore, rotate, and zoom."
              }],
              "titleArray1": [{
                "tr_title": "Wake in a flash", 
                "tr_subtitle": "With instant wake your PC can go from sleep to ready in less than a second."
              },
              {
                "tr_title": "Immersive viewing", 
                "tr_subtitle": "A beautiful display brings your visuals to life in crisp resolution and vivid colors."
              },
              {
                "tr_title": "Revolutionary performance", 
                "tr_subtitle": "Get extra power where you need it most with intelligent performance from 13th Gen Intel® Core™ processors."
              },
              {
                "tr_title": "Unplug for longer", 
                "tr_subtitle": "Stay in the flow with a PC that optimizes your battery life and recharges in a flash."
              },
              {
                "tr_title": "Universal cable connectivity", 
                "tr_subtitle": "Makes plugging other devices into your PC simple, fast and secure with Thunderbolt™ 4 technology.<sup>10</sup>"
              },
              {
                "tr_title": "Fast, reliable connections", 
                "tr_subtitle": "Video chat or share files with a connection you can count on using Intel® Wi&#8209;Fi 6E (Gig+).<sup>1</sup>"
              }]            
            }
          }
        ]
      },
      {
        "deviceType": "EVO_REDESIGN_ULTRA_5",
        "tilesDetails": [
          {
            "tr_tileTitle": "Spotlight",
            "tileUrlName": "spotlight",
            "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [
              {
                "tr_title": "What’s Intel® Evo™ Edition?",
                "preTitle": "",
                "detailPageName": "whatIntelEvo",
                "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
              },{
                "tr_title": "Why Intel® Evo™ Edition?",
                "preTitle": "",
                "detailPageName": "whyIntelEvo",
                "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
              }]                        
            }
          },
          {
            "tr_tileTitle": "Creativity",
            "tileUrlName": "creativity",
            "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Be more creative in more places",
                "preTitle": "",
                "detailPageName": "morecreative",
                "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
              },
              {
                "tr_title": "Full-speed photo editing",
                "preTitle": "",
                "detailPageName": "photoeditng",
                "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
              },
              {
                "tr_title": "Create original art on the spot",
                "preTitle": "",
                "detailPageName": "originalArt",
                "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
              }]                        
            }
          },
          {
            "tr_tileTitle": "Productivity",
            "tileUrlName": "productivity",
            "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Maximize productivity with Rewind AI",
                "preTitle": "",
                "detailPageName": "rewind-ai",
                "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
              },
              {
                "tr_title": "Detect deepfakes before you share with AI",
                "preTitle": "",
                "detailPageName": "deepfakes",
                "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
              },
              {
                "tr_title": "Your world. On your devices.",
                "preTitle": "",
                "detailPageName": "yourworld",
                "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
              }]                        
            }            
          },
          {
            "tr_tileTitle": "Mobility",
            "tileUrlName": "mobility",
            "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Wave hello to gesture control",
                "preTitle": "gesturecontrol",
                "detailPageName": "virtuallyanywhere",
                "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
              },
              {
                "tr_title": "Battery life that won’t hold you back",
                "preTitle": "",
                "detailPageName": "batteryLife-entertainment",
                "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
              },
              {
                "tr_title": "Automatically keeps you connected",
                "preTitle": "",
                "detailPageName": "automaticallyConnected",
                "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
              }]                        
            }
          }
          ]
      },
      {
        "deviceType": "EVO_REDESIGN_ULTRA_7",
        "tilesDetails": [
          {
            "tr_tileTitle": "Spotlight",
            "tileUrlName": "spotlight",
            "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [
              {
                "tr_title": "What’s Intel® Evo™ Edition?",
                "preTitle": "",
                "detailPageName": "whatIntelEvo",
                "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
              },{
                "tr_title": "Why Intel® Evo™ Edition?",
                "preTitle": "",
                "detailPageName": "whyIntelEvo",
                "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
              }]                        
            }
          },
          {
            "tr_tileTitle": "Creativity",
            "tileUrlName": "creativity",
            "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Quick, smooth video playback",
                "preTitle": "",
                "detailPageName": "videoplayback",
                "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
              },
              {
                "tr_title": "Produce hits with the power of AI",
                "preTitle": "",
                "detailPageName": "powerAI",
                "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
              },
              {
                "tr_title": "The fastest way to your final cut",
                "preTitle": "",
                "detailPageName": "fastestway",
                "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
              }]                        
            }
          },
          {
            "tr_tileTitle": "Productivity",
            "tileUrlName": "productivity",
            "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Maximize productivity with Rewind AI",
                "preTitle": "",
                "detailPageName": "rewind-ai",
                "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
              },
              {
                "tr_title": "Detect deepfakes before you share with AI",
                "preTitle": "",
                "detailPageName": "deepfakes",
                "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
              },
              {
                "tr_title": "Your world. On your devices.",
                "preTitle": "",
                "detailPageName": "yourworld",
                "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
              }]                        
            }            
          },
          {
            "tr_tileTitle": "Mobility",
            "tileUrlName": "mobility",
            "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "",
              "titleArray": [{
                "tr_title": "Collaborate from virtually anywhere",
                "preTitle": "",
                "detailPageName": "virtuallyanywhere",
                "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
              },
              {
                "tr_title": "Battery life that won’t hold you back",
                "preTitle": "",
                "detailPageName": "batteryLife-entertainment",
                "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
              },
              {
                "tr_title": "Automatically keeps you connected",
                "preTitle": "",
                "detailPageName": "automaticallyConnected",
                "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
              }]                        
            }
          }
          ]
      }
      
    ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
      {
        "deviceType": "DEFAULT",
        "tilesDetails": [{
          "tr_tileTitle": "All your entertainment. One PC.",
          "tileUrlName": "entertainment",
          "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
          "tileDetails": {            
            "panelType1":"gaming-tile-panel",
            "panelType2":"gaming-tile-panel2",            
            "tr_title": "",
            "tr_subtitle": "For a PC that doubles as an entertainment center you need enough storage for all your favorite songs, videos and games and the right combination of processor and display to create immersive visuals. This PC has all three.",
            "titleArray": [{ // Laptop
              "tr_title": "Impressive gaming and streaming",
              "preTitle": "[processor_value]",
              "detailPageName": "processor",
            },
            {
              "tr_title": "Build your digital library",
              "preTitle": "[storage_value]",
              "detailPageName": "storage",
            },
            {
              "tr_title": "A window to your entertainment",
              "preTitle": "[display_value]",
              "detailPageName": "display",
            }],
            "titleArray1": [{ // Desktop
              "tr_title": "Seamless gaming and streaming",
              "preTitle": "[processor_value]",
              "detailPageName": "processor",
            },
            {
              "tr_title": "Build your digital library",
              "preTitle": "[storage_value]",
              "detailPageName": "storage",
            },
            {
              "tr_title": "A window to your entertainment",
              "preTitle": "[display_value]",
              "detailPageName": "display",
          }]
          }          
        },
        {
          "tr_tileTitle": "Be more productive. Anywhere.", //laptop
          "tr_tileTitle1": "Get more done. Fast.", //desktop
          "tileUrlName": "productivity",
          "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
          "tileDetails": {            
            "panelType1": "gaming-tile-panel",
            "panelType2": "gaming-tile-panel2",            
            "tr_title": "",
            "tr_subtitle": "This PC has the perfect balance of power and portability. Starting with a processor made for thin and light designs, this PC also features the kind of Wi&#8209;Fi to keep you connected and the right battery to keep you unplugged.",
            "tr_subtitle1": "This PC has the perfect balance of power and performance to help you accomplish more in less time. The combination of a robust processor accelerated by ample memory and lightning fast Wi&#8209;Fi will streamline your daily tasks.",
            "tr_subtitle2": "This PC has the perfect balance of power and portability. Starting with a processor made for thin and light designs, this PC also features the kind of Wi&#8209;Fi to keep you connected and the right memory for multitasking.",
            "titleArray": [{
              "tr_title": "Intelligent performance that matters", // LP
              "tr_title1": "Speed up with intelligent performance", // DP
              "preTitle": "[processor_value]",
              "detailPageName": "processor",
            },
            {
              "tr_title": "Best-in-class connections",
              "tr_title1": "Connect to what matters",
              "tr_title2": "A major advancement in Wi&#8209;Fi",
              "preTitle": "[wifi_value]",
              "detailPageName": "wifi",
            },
            {
              "tr_title": "Stay unplugged longer",
              "preTitle": "[battery_value]",
              "detailPageName": "battery",
            },
            {
              "tr_title": "Juggle programs like a pro",
              "preTitle": "[memory_value]",
              "detailPageName": "memory",
            }],
          }          
        },
        {
          "tr_tileTitle": "The freedom to create",
          "tileUrlName": "content",
          "tileBadgeUrl": "assets/images/standard/content-tile.svg",
          "tileDetails": {            
            "panelType1":"gaming-tile-panel",
            "panelType2":"gaming-tile-panel2",            
            "tr_title": "",
            "tr_subtitle": "Content creation is one of the most demanding tasks for your PC. Fortunately, these components have the power to streamline your creative process.",
            "titleArray": [{ 
              "tr_title": "Accelerated creativity", // LP
              "tr_title1": "Flow through creative projects", // DP
              "preTitle": "[processor_value]",
              "detailPageName": "processor",
            },
            {
              "tr_title": "Elevated creating",
              "preTitle": "[graphics_value]",
              "detailPageName": "graphics",
            },
            {
              "tr_title": "Memory for multitasking",
              "preTitle": "[memory_value]",
              "detailPageName": "memory",
            }],            
          }          
        },
        {
          "tr_tileTitle": "Look inside this PC",
          "tileUrlName": "lookinside",
          "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
          "tileDetails": {            
            "panelType1":"gaming-tile-panel",
            "panelType2":"gaming-tile-panel2",            
            "tr_title": "",
            "tr_subtitle": "These are the three main components to keep your eye on when choosing a new PC. By deciding how you’re going to use your new PC, you’ve come a long way toward deciding how much of these three key components you’ll need. They are:",
            "titleArray": [{ 
              "tr_title": "Processor",
              "preTitle": "[processor_value]",
              "detailPageName": "processor",
            },
            {
              "tr_title": "Storage",
              "preTitle": "[storage_value]",
              "detailPageName": "storage",
            },
            {
              "tr_title": "Memory",
              "preTitle": "[memory_value]",
              "detailPageName": "memory",
            }],            
          }          
        }
      ]
      }]
    },
    {
      "tilesType": "CREATOR_12", 
      "tilesDeviceTypes": [
      {
        "deviceType": "DEFAULT",
        "tilesDetails": [
          {
            "tr_tileTitle": "[processor_value]",
            "tileUrlName": "processor",
            "tileBadgeUrl": "assets/images/creator/[processor_badge].svg",
          },
          {
            "tr_tileTitle": "The freedom to create",
            "tileUrlName": "content",
            "tileBadgeUrl": "assets/images/creator/freedom_new.png",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",
              "panelType2":"gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "Content creation is one of the most demanding tasks for your PC. Fortunately, these components have the power to streamline your creative process.",
              "titleArray": [{ // Laptop
                "tr_title": "A smarter way to create",
                "preTitle": "[processor_value]",
                "detailPageName": "processor",
              },
              {
                "tr_title": "Bring your images to life",
                "tr_title1": "Let’s Create",
                "preTitle": "[graphics_value]",
                "detailPageName": "graphics",
              },
              {
                "tr_title": "Memory for multitasking",
                "preTitle": "[memory_value]",
                "detailPageName": "memory",
              }],            
            }          
          },
          {
            "tr_tileTitle": "Be more productive. Anywhere.", //laptop
            "tr_tileTitle1": "Get more done. Fast.", //desktop
            "tileUrlName": "productivity",
            "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
            "tileDetails": {            
              "panelType1": "gaming-tile-panel",
              "panelType2": "gaming-tile-panel2",            
              "tr_title": "",
              "tr_subtitle": "This PC has the perfect balance of power and portability. Starting with a processor made for thin and light designs, this PC also features the kind of Wi&#8209;Fi to keep you connected and the right battery to keep you unplugged.",
              "tr_subtitle1": "This PC has the perfect balance of power and performance to help you accomplish more in less time. The combination of a robust processor accelerated by ample memory and lightning fast Wi&#8209;Fi will streamline your daily tasks.",
              "tr_subtitle2": "This PC has the perfect balance of power and portability. Starting with a processor made for thin and light designs, this PC also features the kind of Wi&#8209;Fi to keep you connected and the right memory for multitasking.",
              "titleArray": [{ // Laptop
                "tr_title": "Built to do more",
                "preTitle": "[processor_value]",
                "detailPageName": "processor",
              },
              {
                "tr_title": "Best-in-class connections",
                "tr_title1": "Connect to what matters",
                "tr_title2": "A major advancement in Wi&#8209;Fi",
                "preTitle": "[wifi_value]",
                "detailPageName": "wifi",
              },
              {
                "tr_title": "Stay unplugged longer",
                "preTitle": "[battery_value]",
                "detailPageName": "battery",
              },
              {
                "tr_title": "Juggle programs like a pro",
                "preTitle": "[memory_value]",
                "detailPageName": "memory",
              }],
            }          
          },
          {
            "tr_tileTitle": "VR Ready",
            "tileUrlName": "vr",
            "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
            "tileDetails": {            
              "panelType1":"gaming-tile-panel",   
              "panelType2":"gaming-tile-panel2",          
              "tr_title": "Where VR comes alive",
              "tr_subtitle": "What does it take to create a completely virtual world? On a PC you’ll need the coordination between your processor, graphics card and memory.",
              "titleArray": [{
                  "tr_title": "Powering virtual worlds",
                  "preTitle": "[processor_value]",
                  "detailPageName": "processor",
                },
                {
                  "tr_title": "The reality is in the details",
                  "tr_title1": "Get immersed",
                  "preTitle": "[graphics_value]",
                  "detailPageName": "graphics",
              }],
            }           
          }
        
        ]
      }]
    }
  ],
  "samsungOled":[
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>A sight for sore eyes",
      "tr_sub_header": "Blue light can cause visual discomfort and disrupt your sleep cycle. Samsung's SGS Eye Care display-tested OLED helps not by changing colors, but instead by reducing the wavelength of potential harmful blue light, providing eye comfort and reduced ocular fatigue.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Less Harmful Blue Light"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra in every way",
      "tr_sub_header": "OLED's ultralight, ultrathin design maximizes image quality while minimizing weight."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "True Black",
      "tr_sub_header": "0.0005nit",
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74%<br/><span class='conventional_text'>Conventional</span>",
      "tr_sub_header": "120%<br/><span class='samsungoled_text'>Samsung OLED</span>",
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails":
            [
              {
                "tr_tileTitle": "[processor_value]",
                "tileUrlName": "processor",
                "tileBadgeUrl": "[processor_badge]",
              },
              {
                "tr_tileTitle": "Streaming",
                "tileUrlName": "streaming",
                "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "STREAMING",
                  "tr_subtitle": "Endless entertainment",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for streaming.",
                  "tileBG": "#794584",
                  "titleArray": [{
                    "tr_title": "A processor that can stream in HD."
                  },
                  {
                    "tr_title": "A display for bright, beautiful images."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi that delivers less buffering and load times."
                  }
                  ]
                }
              },
              {
                "tr_tileTitle": "Web browsing",
                "tileUrlName": "webbrowsing",
                "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "WEB BROWSING",
                  "tr_subtitle": "The internet made easy",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for web browsing.",
                  "tileBG": "#87A944",
                  "titleArray": [{
                    "tr_title": "A processor that can quickly load media-rich pages."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi that is fast and reliable."
                  }
                  ]
                }
              },
              {
                "tr_tileTitle": "Video chatting",
                "tileUrlName": "videochatting",
                "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "VIDEO CHATTING",
                  "tr_subtitle": "Crystal-clear connections",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for video chatting.",
                  "tileBG": "#E96115",
                  "titleArray": [{
                    "tr_title": "A processor that can record and share images at the same time."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi that is fast, strong and reliable."
                  },
                  {
                    "tr_title": "A webcam that will help you come across crystal clear."
                  }
                  ]
                }
              }
            ]
        }]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails":
            [
              {
                "tr_tileTitle": "High-speed multitasking",
                "tileUrlName": "highspeed",
                "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "HIGH-SPEED MULTITASKING",
                  "tr_subtitle": "Do more without slowing down",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for multitasking.",
                  "tileBG": "#0093C7",
                  "titleArray": [{
                    "tr_title": "A processor that can juggle several programs at once."
                    },
                    {
                      "tr_title": "Wi&#8209;Fi that is fast, strong and reliable."
                    },
                    {
                      "tr_title": "Memory that seamlessly switches between applications."
                    }
                  ]
                }
              },
              {
                "tr_tileTitle": "Video chatting",
                "tileUrlName": "videochatting",
                "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "VIDEO CHATTING",
                  "tr_subtitle": "Crystal-clear connections",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for video chatting.",
                  "tileBG": "#E96115",
                  "titleArray": [{
                    "tr_title": "A processor that can record and share images at the same time."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi that is fast, strong and reliable."
                  },
                  {
                    "tr_title": "A webcam that will help you come across crystal clear."
                  }
                  ]
                }
              },
              {
                "tr_tileTitle": "Casual gaming",
                "tileUrlName": "casualgaming",
                "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "CASUAL GAMING",
                  "tr_subtitle": "Time to play",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for light gaming.",
                  "tileBG": "#000000",
                  "titleArray": [{
                    "tr_title": "A processor that powers smooth gameplay."
                  },
                  {
                    "tr_title": "Memory that can voice chat and stream at the same time."
                  },
                  {
                    "tr_title": "Graphics that power amazing images."
                  }
                  ]
                }
              },
              {
                "tr_tileTitle": "Photo editing",
                "tileUrlName": "photoediting",
                "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "PHOTO EDITING",
                  "tr_subtitle": "Pixel perfect photos",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for photo editing.",
                  "tileBG": "#659FBA",
                  "titleArray": [{
                    "tr_title": "A processor with the power to edit large groups of images."
                  },
                  {
                    "tr_title": "Memory that accelerates your editing software."
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 technology lets you quickly transfer files."
                  }
                  ]
                }
              }
            ]
        }]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails":
            [
              {
                "tr_tileTitle": "High-speed multitasking",
                "tileUrlName": "highspeed",
                "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "HIGH-SPEED MULTITASKING",
                  "tr_subtitle": "Do more without slowing down",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for multitasking.",
                  "tileBG": "#0093C7",
                  "titleArray": [{
                    "tr_title": "A processor that can juggle several programs at once."
                    },
                    {
                      "tr_title": "Wi&#8209;Fi that is fast, strong and reliable."
                    },
                    {
                      "tr_title": "Memory that seamlessly switches between applications."
                    }
                  ]
                }
              },
              {
                "tr_tileTitle": "Real-time collaboration",
                "tileUrlName": "realtime",
                "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "REAL-TIME COLLABORATION",
                  "tr_subtitle": "Work from anywhere",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for online collaborations.",
                  "tileBG": "#D42931",
                  "titleArray": [{
                    "tr_title": "A processor to power office software."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi that you can rely on."
                  },
                  {
                    "tr_title": "A webcam that records in brilliant HD."
                  }
                  ]
                }
              },
              {
                "tr_tileTitle": "Video editing",
                "tileUrlName": "videoediting",
                "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "VIDEO EDITING",
                  "tr_subtitle": "Make movie magic",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for video editing.",
                  "tileBG": "#00376D",
                  "titleArray": [{
                    "tr_title": "A processor that can handle large files."
                  },
                  {
                    "tr_title": "Memory that makes editing software fast and responsive."
                  },
                  {
                    "tr_title": "Graphics that will cut down on rendering times."
                  }
                  ]
                }
              },
              {
                "tr_tileTitle": "Photo editing",
                "tileUrlName": "photoediting",
                "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "PHOTO EDITING",
                  "tr_subtitle": "Pixel perfect photos",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for photo editing.",
                  "tileBG": "#659FBA",
                  "titleArray": [{
                    "tr_title": "A processor with the power to edit large groups of images."
                  },
                  {
                    "tr_title": "Memory that accelerates your editing software."
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 technology lets you quickly transfer files."
                  }
                  ]
                }
              }
            ]
        }]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails":
            [
              {
                "tr_tileTitle": "[processor_value]",
                "tileUrlName": "processor",
                "tileBadgeUrl": "[processor_badge]",
              },
              {
                "tr_tileTitle": "Video editing",
                "tileUrlName": "videoediting",
                "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "VIDEO EDITING",
                  "tr_subtitle": "Make movie magic",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for video editing.",
                  "tileBG": "#00376D",
                  "titleArray": [{
                    "tr_title": "A processor that can handle large files."
                  },
                  {
                    "tr_title": "Memory that makes editing software fast and responsive."
                  },
                  {
                    "tr_title": "Graphics that will cut down on rendering times."
                  }
                  ]
                }
              },
              {
                "tr_tileTitle": "3D modeling",
                "tileUrlName": "threedmodeling",
                "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "3D MODELING",
                  "tr_subtitle": "Next-level creations",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for 3D modeling.",
                  "tileBG": "#00285A",
                  "titleArray": [{
                    "tr_title": "A processor that can handle advanced creative software."
                  },
                  {
                    "tr_title": "Memory that can quickly bring up large files."
                  },
                  {
                    "tr_title": "Graphics for powerful image creating and editing."
                  }
                  ]
                }
              },
              {
                "tr_tileTitle": "Real-time collaboration",
                "tileUrlName": "realtime",
                "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
                "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
                "tileDetails": {
                  "panelType1": "rebuild-usage-panel",
                  "tr_title": "REAL-TIME COLLABORATION",
                  "tr_subtitle": "Work from anywhere",
                  "tr_subtitle1": "Here is what we found in this PC that’s great for online collaborations.",
                  "tileBG": "#D42931",
                  "titleArray": [{
                    "tr_title": "A processor to power office software."
                  },
                  {
                    "tr_title": "Wi&#8209;Fi that you can rely on."
                  },
                  {
                    "tr_title": "A webcam that records in brilliant HD."
                  }
                  ]
                }
              }
            ]
        }]
    }

  ],
  "unison": {          
      "panelType1":"gaming-tile-panel",
      "panelType2":"unison-panel",      
      "tr_tileTitle": "Intel® Unison™ app",
      "tr_title": "Unlock your connected world",
      "tr_subtitle": "Seamlessly transfer files and photos while managing phone notifications, calls and texts from your PC.<sup>18</sup>",
      "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
      "tileBG": "assets/images/unison/unison_hometile_img.png",
      "titleArray": [
        {
          "tr_title": "TRANSFER FILES AND PHOTOS", 
          "imgUrl": "assets/images/unison/unison_img1.png"
        },
        {
          "tr_title": "MAKE AND RECEIVE CALLS", 
          "imgUrl": "assets/images/unison/unison_img2.png"
        },
        {
          "tr_title": "SEND AND RECEIVE TEXTS", 
          "imgUrl": "assets/images/unison/unison_img3.png"
        },
        {
          "tr_title": "MANAGE PHONE NOTIFICATIONS", 
          "imgUrl": "assets/images/unison/unison_img4.png"
        },
        {
          "tr_title": "UNLOCK YOUR CONNECTED WORLD", 
          "imgUrl": "assets/images/unison/unison_img5.png"
        }
      ]       
  }
}