const Library_mobile_he = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "מופעל על ידי מעבד [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "מופעל על ידי מעבד [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "בדומה למוח, המעבד שולט בפעולות של המחשב. זה אומר הסרטונים בהם צופים, המשחקים בהם משחקים, האתרים בהם מבקרים, הכול מתחיל במעבד.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "המעבד הוא המוח של המחשב"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "ביצועים וערך.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "מעבד Intel® Pentium® Silver",
                    "tr_subtitle": "התעדכן בחידושים האחרונים בעולם הדיגיטלי עם ביצועים המאפשרים גישה לקבצים דרך האינטרנט. ריבוי משימות פשוט על ידי מעבר בין תוכניות בקלות, ולבצע יותר בפחות זמן.",
                    "tr_gen_core_title": "מעבד Intel® Pentium® Silver"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "מעבד Intel® Pentium® Gold",
                    "tr_subtitle": "פרודוקטיביות משופרת - ביצועים מוגברים שיחזקו אותך במהלך הימים התובעניים ביותר. דפדפן אינטרנט יעיל - טען במהירות דפי אינטרנט עשירים במדיה כדי לקבל יותר בפחות זמן.",
                    "tr_gen_core_title": "מעבד Intel® Pentium® Gold"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "מעבד Intel® Celeron®‎",
                    "tr_subtitle": "ביצועים מעולים לשיפור העבודה והבידור. הנאה מסרטים ומסרטונים ברשת באיכות מדהימה. להשקיע יותר זמן בעשייה ופחות זמן בהמתנה.",
                    "tr_gen_core_title": "מעבד Intel® Celeron®‎"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "מעבד Intel® Pentium®‎",
                    "tr_subtitle": "מיועד לבידור, סטרימינג ופרודוקטיביות מעולים. הנאה מאיכות מדהימה של פרטים בסרטים ובסרטונים ברשת. להשקיע יותר זמן בעשייה ופחות זמן בהמתנה.",
                    "tr_gen_core_title": "מעבד Intel® Pentium®‎"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "הביצועים שדרושים לך. המחיר שמתאים לך.",
                "tr_title": "מעבד Intel®‎",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "ערך בקטגוריה משלו",
                    "tr_subtitle": "מעבד Intel®‎ יכול לטפל כמעט בכל משימה שדרושה לך במחיר שתוכל להרשות לעצמך. ניווט קל בין תוכנות. לחלופין, תוכל ליהנות מגרפיקה באיכות גבוהה עם ביצועים יציבים.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "צור חיבורים חזקים יותר",
                    "tr_subtitle": "יותר איכות וידאו חד, צלילות קול עשירה יותר וקישוריות אינטרנט מהירה כמעט פי 3 מאפשרים שיתוף פעולה בטוח בכל מקום שתזדקק לה.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "הבדל של יום ולילה",
                    "tr_subtitle": "החל במרתונים של סרטים עד הבוקר וכלה בליטושים של הרגע האחרון, מעבד Intel®‎ מאריך את חיי הסוללה של המכשיר כדי שתוכל לקבל חוויות סוחפות אמיתיות, ללא הפרעות.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "כל סביבה היא סביבת למידה",
                    "tr_subtitle": "הפעל מספר כלי למידה מקוונים, בקלות ומכל פינה בבית שלך."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "למד היכן שתרצה",
                    "tr_subtitle": "סיים שיעורים בכל מקום שתצטרך, תוך כדי הפעלה במקביל של מגוון כלי למידה אלקטרוניים."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "הכוח לבידור ולפרודוקטיביות שמתאים לתקציב ולסגנון החיים שלך.",
                "tr_title": "ביצועים של Intel לכל תקציב.",
                "tr_gen_core_title": "מעבד Intel® Celeron®‎",
                "processorCompare": {
                  "tr_title": "למצוא את רמת הביצועים והערך המתאימים לך",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "מעבד Intel® Celeron®‎"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "מעבד Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "גלישה מהירה ועשירת מדיה באינטרנט",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "מעבר קל בין תוכנות",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג קליל",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג של סרטוני וידאו 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "עריכת תמונות וסרטונים קלה",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "פרודוקטיביות מהירה עם ביצועים חכמים",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "מופעל באמצעות מעבד Intel® Core™ [cpu_fam] דור שביעי"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "מופעל באמצעות מעבד Intel® Core™ [cpu_fam] דור שישי"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "מופעל באמצעות מעבד Intel® Core™ [cpu_fam] דור חמישי"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "מופעל באמצעות מעבד Intel® Core™ [cpu_fam] דור רביעי"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "מופעל באמצעות מעבד Intel® Core™ [cpu_fam] דור שלישי"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "מופעל באמצעות מעבד Intel®Core™[cpu_fam] דור שני"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "מופעל באמצעות מעבד Intel® Core™ [cpu_fam] דור ראשון"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "מומלץ עבור:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "מופעל באמצעות מעבד Intel® Core™ i3 דור שמיני",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i3 דור&nbsp;8"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "הדור התשיעי של מעבדי Intel® Core™‎ בנויים לעמוד בקצב של העולם הדיגיטלי. השילוב בין המהירות והביצועים שלהם מייעל את השימוש במחשב כך שתוכלו להיות מתוסכלים פחות ולהנות יותר.",
                "tr_title": "מופעל באמצעות מעבד Intel® Core™ i3 דור תשיעי",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i3 דור&nbsp;9"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "ביצועים רב-תכליתיים פוגשים חיי סוללה ארוכים, כך שניתן להמשיך בעיסוקיך, בכל מקום.",
                "tr_title": "עבודה לאורך כל היום",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i3 דור&nbsp;עשירי",
                "processorCompare": {
                  "tr_title": "למצוא את רמת הביצועים המתאימה לך בדור עשירי",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "סטרימינג של סרטים ב-4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "קישוריות מהירה ואמינה",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "מיסוך תמונות וסינון וידאו",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "משחקים ב-1080p עם קצב פריימים חלק",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "הטוב ביותר לעבודה עם קבצים גדולים ותוכנות יצירה",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "ניתן לעבור בקלות מסרטים בצפייה ישירה (סטרימינג) ליצירת מצגות עם שפע של כוח עיבוד עודף.",
                "tr_title": "עבודה לאורך כל היום",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i3 דור&nbsp;עשירי"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "לאנשים שנמצאים תמיד בתנועה, מחשב נייד שעוצב במיוחד בשבילכם.",
                "tr_title": "קטגוריה חדשה של מחשבים ניידים",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i3 דור&nbsp;עשירי"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "מעבד Intel® Core™ i3 דור 11, שתוכנן במיוחד עבור מחשבים אישיים ניידים, מאפשר לעשות יותר מאי פעם, בכל מקום.",
                "tr_title": "לקבל יותר מהמחשב הנייד החדש שלך",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i3 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i3 דור 11",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "כשמשפחה אחת משתמשת במחשב שולחני אחד כדי לעשות את כל הדברים, צריך את העוצמה ואת הרב-תכליתיות של מעבד Intel® Core™ i3 דור 11.",
                "tr_title": "ביצועים פורצי גבולות שיאפשרו לכם להיות מוכנים לכל דבר.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i3 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i3 דור 11",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "מופעל באמצעות מעבד Intel® Core™ i5 דור שמיני",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;8"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "הדור התשיעי של מעבדי Intel® Core™‎ בנויים לעמוד בקצב של העולם הדיגיטלי. השילוב בין המהירות והביצועים שלהם מייעל את השימוש במחשב כך שתוכלו להיות מתוסכלים פחות ולהנות יותר.",
                "tr_title": "מופעל באמצעות מעבד Intel® Core™ i5 דור תשיעי",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;9"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "משחק ומציאות מדומה חלקים בכותרים התובעניים ביותר",
                "tr_title": "גיימינג רציני מתחיל כאן",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון בגודל<br/></span><span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "בחרו את מעבד INTEL® CORE™‎ שמתאים למשחק שלכם.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "מעבד Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "גיימינג AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג תחרותי",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "לשחק, להזרים ולהקליט ללא פשרות",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "מעבד Intel® Core™‎ דור תשיעי הטוב ביותר למשחקים",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 4<br/>תהליכונים: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "תמרנו בקלות בין תוכנות רבות כדי להיות מוכנים לכל דבר, לא משנה לאן יוביל אתכם היום.",
                "tr_title": "ריבוי משימות בדרכים",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;עשירי",
                "processorCompare": {
                  "tr_title": "למצוא את רמת הביצועים המתאימה לך בדור עשירי",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "סטרימינג של סרטים ב-4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "קישוריות מהירה ואמינה",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "מיסוך תמונות וסינון וידאו",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "משחקים ב-1080p עם קצב פריימים חלק",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "הטוב ביותר לעבודה עם קבצים גדולים ותוכנות יצירה",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "שפרו את כל מה שתעשו, מבידור דרך תפוקה ועד יצירת תוכן.",
                "tr_title": "שדרגו למחשב אישי חזק יותר",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;עשירי"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "לאנשים שנמצאים תמיד בתנועה, מחשב נייד שעוצב במיוחד בשבילכם.",
                "tr_title": "קטגוריה חדשה של מחשבים ניידים",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;עשירי"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "בנוי לסטרימינג וגיימינג AAA ללא הפרעה",
                "tr_title": "לשחק בתנאים שלך",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;עשירי",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון בגודל<br/></span><span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "בחרו את מעבד INTEL® CORE™‎ דור עשירי שמתאים&nbsp;למשחקים&nbsp;שלכם.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "לגיימינג AAA חלק ללא הפרעות",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג תחרותי",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג והקלטה",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "יצירת תוכן מתקדם",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ביצועי שיא בגיימינג",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "לא נעול ומאפשר המהרה<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "בנוי לסטרימינג וגיימינג AAA ללא הפרעה",
                "tr_title": "לשחק בתנאים שלך",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;עשירי",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון בגודל<br/></span><span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "בחרו את מעבד INTEL® CORE™‎ דור עשירי שמתאים&nbsp;למשחקים&nbsp;שלכם.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "לגיימינג AAA חלק ללא הפרעות",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג תחרותי",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג והקלטה",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "יצירת תוכן מתקדם",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ביצועי שיא בגיימינג",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "לא נעול ומאפשר המהרה<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 10<br/>תהליכונים: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "תמרנו בקלות בין תוכנות רבות כדי להיות מוכנים לכל דבר, לא משנה לאן יוביל אתכם היום.",
                "tr_title": "ריבוי משימות בדרכים",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;עשירי",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5‎ דור עשירי"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "מעבד Intel® Core™ i5 דור 11 מספק חוויות חזותיות מרגשות ויעילות רחבת טווח למחשבים האישיים הדקים וקלי המשקל ביותר.",
                "tr_title": "עוצמת מחשב נייד מרשימה",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5‎ דור 11",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "כשמשפחה אחת משתמשת במחשב שולחני אחד כדי לעשות את כל הדברים, צריך את העוצמה ואת הרב-תכליתיות של מעבד Intel® Core™ i5 דור 11.",
                "tr_title": "ביצועים פורצי גבולות שיאפשרו לכם להיות מוכנים לכל דבר.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5‎ דור 11",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "מעבד Intel® Core™ i5 דור 11 מספק חוויות חזותיות מרגשות ויצירתיות בלתי מוגבלת למחשבים ניידים דקים וקלי משקל.",
                "tr_title": "עוצמת מחשב נייד מרשימה",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5‎ דור 11"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "מופעל באמצעות מעבד Intel® Core™ i7 דור שמיני",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;8"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "הדור התשיעי של מעבדי Intel® Core™‎ בנויים לעמוד בקצב של העולם הדיגיטלי. השילוב בין המהירות והביצועים שלהם מייעל את השימוש במחשב כך שתוכלו להיות מתוסכלים פחות ולהנות יותר.",
                "tr_title": "מופעל באמצעות מעבד Intel® Core™ i7 דור תשיעי",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;9"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "עד 32% יותר פריימים לשנייה בזמן משחק, סטרימינג והקלטה לעומת מחשב בן 3 שנים<sup>3</sup>",
                "tr_title": "הכוח לשתף את רגעי המשחק הגדולים ביותר שלך",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון בגודל<br/></span><span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "בחרו את מעבד INTEL® CORE™‎ שמתאים למשחק שלכם.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "גיימינג AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג תחרותי",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "לשחק, להזרים ולהקליט ללא פשרות",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "מעבד Intel® Core™‎ דור תשיעי הטוב ביותר למשחקים",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 4<br/>תהליכונים: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "מכשיר מהודר זה, שמתוכנן ליעילות, יכול לשנות מהיסוד את הדרך שבה אתם יוצרים, מתחברים ומזרימים.",
                "tr_title": "ביצועים מתקדמים בכל מקום",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;עשירי",
                "processorCompare": {
                  "tr_title": "למצוא את רמת הביצועים המתאימה לך בדור עשירי",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "סטרימינג של סרטים ב-4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "קישוריות מהירה ואמינה",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "מיסוך תמונות וסינון וידאו",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "משחקים ב-1080p עם קצב פריימים חלק",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "הטוב ביותר לעבודה עם קבצים גדולים ותוכנות יצירה",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "ביצועים מתקדמים שייקחו את כל מה שאתם עושים לרמה גבוהה יותר.",
                "tr_title": "הכוח לעשות את הכל",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;עשירי"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "לאנשים שנמצאים תמיד בתנועה, מחשב נייד שעוצב במיוחד בשבילכם.",
                "tr_title": "קטגוריה חדשה של מחשבים ניידים",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;עשירי"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "לשחק, לנגן ולהקליט עם יתרון תחרותי",
                "tr_title": "לספק כוח לעולם הגיימינג שלך",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;עשירי",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון בגודל<br/></span><span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "בחרו את מעבד INTEL® CORE™‎ דור עשירי שמתאים&nbsp;למשחקים&nbsp;שלכם.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "לגיימינג AAA חלק ללא הפרעות",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג תחרותי",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג והקלטה",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "יצירת תוכן מתקדם",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ביצועי שיא בגיימינג",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "לא נעול ומאפשר המהרה<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "לשחק, לנגן ולהקליט עם יתרון תחרותי",
                "tr_title": "לספק כוח לעולם הגיימינג שלך",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;עשירי",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון בגודל<br/></span><span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "בחרו את מעבד INTEL® CORE™‎ דור עשירי שמתאים&nbsp;למשחקים&nbsp;שלכם.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "לגיימינג AAA חלק ללא הפרעות",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג תחרותי",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג והקלטה",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "יצירת תוכן מתקדם",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ביצועי שיא בגיימינג",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "לא נעול ומאפשר המהרה<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 10<br/>תהליכונים: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "מכשיר מהודר זה, שמתוכנן ליעילות, יכול לשנות מהיסוד את הדרך שבה אתם יוצרים, מתחברים ומשתמשים בסטרימינג.",
                "tr_title": "ביצועים מובחרים בכל מקום",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;עשירי",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור עשירי"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "מעבד Intel® Core™ i7‎ דור 11 מביא למחשבים אישיים אולטרה-ניידים יצירת תוכן מתקדמת, חווית גיימינג חלקה ובידור ברמה הבאה.",
                "tr_title": "ביצועי מחשב נייד פורצי&nbsp;גבולות",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 11",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "המחשב השולחני יהפוך להיות היעד המועדף עליכם לכל דבר שהבית שלכם יכול להזדקק לו, עם ביצועים פורצי גבולות של מעבד Intel® Core™ i7 דור 11.",
                "tr_title": "לעבוד. ללמוד. לשחק. ללא גבולות.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 11",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "מעבד Intel® Core™ i7‎ דור 11 מאפשר יצירת תוכן מתקדם ומעניק גיימינג חלק ובידור משודרג למחשבים ניידים דקים וקלי משקל.",
                "tr_title": "ביצועי מחשב נייד פורצי&nbsp;גבולות",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 11"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "המחשב השולחני יהפוך להיות היעד המועדף עליכם לכל דבר שהבית שלכם יכול להזדקק לו, עם ביצועים פורצי גבולות של מעבד Intel® Core™ i7 דור 11.",
                "tr_title": "יצירה ללא גבולות",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 11"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "הודות ליצירת תוכן מתקדם, גיימינג חלק ובידור משודרג למחשבים ניידים דקים וקלי משקל, מעבד Intel®‎ Core™‎ i7 דור 11 השתפר אף יותר.",
                "tr_title": "ביצועי מחשב נייד פורצי&nbsp;גבולות",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 11"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "מופעל באמצעות מעבד Intel® Core™ i9 דור שמיני",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;8"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "הדור התשיעי של מעבדי Intel® Core™‎ בנויים לעמוד בקצב של העולם הדיגיטלי. השילוב בין המהירות והביצועים שלהם מייעל את השימוש במחשב כך שתוכלו להיות מתוסכלים פחות ולהנות יותר.",
                "tr_title": "מופעל באמצעות מעבד Intel® Core™ i9 דור תשיעי",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;9"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "עד 41% יותר פריימים לשנייה בזמן משחק, סטרימינג והקלטה לעומת מחשב בן 3 שנים<sup>4</sup>",
                "tr_title": "גיימרים דורשים. Intel® Core™ i9 מקיים.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון בגודל<br/></span><span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "בחרו את מעבד INTEL® CORE™‎ שמתאים למשחק שלכם.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "",
                      "tr_imgCaption": "מעבד Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "גיימינג AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג תחרותי",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "לשחק, להזרים ולהקליט ללא פשרות",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "מעבד Intel® Core™‎ דור תשיעי הטוב ביותר למשחקים",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 4<br/>תהליכונים: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "לדחוף את הפוטנציאל שלך קדימה עם חווית הגיימינג האולטימטיבית",
                "tr_title": "להתחרות ברמות הכי&nbsp;גבוהות",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;עשירי",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון בגודל<br/></span><span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "בחרו את מעבד INTEL® CORE™‎ דור עשירי שמתאים&nbsp;למשחקים&nbsp;שלכם.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "לגיימינג AAA חלק ללא הפרעות",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג תחרותי",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג והקלטה",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "יצירת תוכן מתקדם",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ביצועי שיא בגיימינג",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "לא נעול ומאפשר המהרה<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "לדחוף את הפוטנציאל שלך קדימה עם חווית הגיימינג האולטימטיבית",
                "tr_title": "להתחרות ברמות הכי&nbsp;גבוהות",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;עשירי",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון בגודל<br/></span><span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "בחרו את מעבד INTEL® CORE™‎ דור עשירי שמתאים&nbsp;למשחקים&nbsp;שלכם.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "לגיימינג AAA חלק ללא הפרעות",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג תחרותי",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג והקלטה",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "יצירת תוכן מתקדם",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ביצועי שיא בגיימינג",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "לא נעול ומאפשר המהרה<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 10<br/>תהליכונים: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "ממשו את הפוטנציאל שלכם עם הכלי המושלם ליוצרים",
                "tr_title": "יוצרים ברמות הכי&nbsp;גבוהות",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;עשירי",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9‎ דור עשירי",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "המחשביים הניידים ממשפחת Intel® Evo™‎ מופעלים באמצעות מעבד Intel®‎ Core™‎ i5 דור 11, וגרפיקת Intel®‎ Iris®‎ X<sup class='small_text_sup_xe'>e</sup> מעניקה מהירות וביצועים מדהימים, והכול בעיצוב דק וקל משקל.",
                "tr_title": "עוצמה משופרת למחשב נייד מתקדם",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5‎ דור 11",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "המחשבים הניידים ממשפחת Intel® Evo™‎ מופעלים באמצעות מעבד Intel®‎ Core™‎ i7 דור 11, וגרפיקת Intel®‎ Iris®‎ X<sup class='small_text_sup_xe'>e</sup> מעניקה מהירות וביצועים מתקדמים שיוצרים חוויית שימוש ללא מתחרים במחשוב נייד.",
                "tr_title": "עוצמה מתקדמת למחשב נייד מתקדם",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 11",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "איזון מדהים בין חוויות הביצועים, המדיה והקישוריות במחיר מדהים.",
                "tr_title": "ביצועים וקישוריות במחיר מדהים.",
                "tr_gen_core_title": "מעבד Intel® Pentium® Silver",
                "processorCompare": {
                  "tr_title": "למצוא את רמת הביצועים והערך המתאימים לך",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "מעבד Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "מעבד Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "גלישה מהירה ועשירת מדיה באינטרנט",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "מעבר קל בין תוכנות",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג קליל",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג של סרטוני וידאו 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "עריכת תמונות וסרטונים קלה",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "פרודוקטיביות מהירה עם ביצועים חכמים",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "ריבוי משימות ללא הפרעה, עריכת תמונות וסרטונים פשוטה, סרטוני 4K וגרפיקה בצבעים חיים במחיר שלא ייאמן.",
                "tr_title": "ביצועים מרשימים לעבודה ולמשחק.",
                "tr_gen_core_title": "מעבד Intel® Pentium® Gold",
                "processorCompare": {
                  "tr_title": "למצוא את רמת הביצועים והערך המתאימים לך",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "מעבד Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "מעבד Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "גלישה מהירה ועשירת מדיה באינטרנט",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "מעבר קל בין תוכנות",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג קליל",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג של סרטוני וידאו 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "עריכת תמונות וסרטונים קלה",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "פרודוקטיביות מהירה עם ביצועים חכמים",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "גיימינג AAA וחיי סוללה ארוכים במחשב קל משקל. לא צריך להתפשר על אופן המשחק שלך.",
                "tr_title": "לא צריך להתפשר.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "למצוא את מעבד הגיימינג קל המשקל של INTEL® CORE™‎ המתאים לך.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "מעבד Intel®&nbsp;Core™ i5-11300H דור&nbsp;11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "מעבד Intel®&nbsp;Core™ i7-11370H דור&nbsp;11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "מעבד Intel®&nbsp;Core™ i7-11375H SE דור&nbsp;11"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "מהירות שעון מקסימלית עד <span class='style_badge'>‎4.4 GHz</span>",
                      "tr_td2_title": "מהירות שעון מקסימלית עד <span class='style_badge'>‎4.8 GHz</span>",
                      "tr_td3_title": "מהירות שעון מקסימלית של עד <span class='style_badge'>‎5.0 GHz‎</span>"
                    },
                    {
                      "tr_td1_title": "4 ליבות/ 8&nbsp;תהליכונים",
                      "tr_td2_title": "4 ליבות/ 8&nbsp;תהליכונים",
                      "tr_td3_title": "4 ליבות/ 8&nbsp;תהליכונים"
                    },
                    {
                      "tr_td1_title": "טכנולוגיית Intel® Turbo Boost",
                      "tr_td2_title": "טכנולוגיית Intel® Turbo Boost",
                      "tr_td3_title": "טכנולוגיית Intel®‎ Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "מטמון חכם של INTEL® בנפח 8&nbsp;MB",
                      "tr_td2_title": "מטמון חכם של INTEL® בנפח 12&nbsp;MB",
                      "tr_td3_title": "מטמון חכם של INTEL® בנפח 12&nbsp;MB"
                    },
                    {
                      "tr_td1_title": "גרפיקת Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "גרפיקת Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "גרפיקת Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "גיימינג AAA ברזולוציה של 1080p עם תדירות תמונות לשניה (FPS) גבוהה במסגרות וביצועים מעולים ללא טעינה במחשב קל משקל.",
                "tr_title": "לך על זה. יכול להיות לך הכול.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "למצוא את מעבד הגיימינג קל המשקל של INTEL® CORE™‎ המתאים לך.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "מעבד Intel®&nbsp;Core™ i5-11300H דור&nbsp;11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "מעבד Intel®&nbsp;Core™ i7-11370H דור&nbsp;11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "מעבד Intel®&nbsp;Core™ i7-11375H SE דור&nbsp;11"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "מהירות שעון מקסימלית עד <span class='style_badge'>‎4.4 GHz</span>",
                      "tr_td2_title": "מהירות שעון מקסימלית עד <span class='style_badge'>‎4.8 GHz</span>",
                      "tr_td3_title": "מהירות שעון מקסימלית של עד <span class='style_badge'>‎5.0 GHz‎</span>"
                    },
                    {
                      "tr_td1_title": "4 ליבות/ 8&nbsp;תהליכונים",
                      "tr_td2_title": "4 ליבות/ 8&nbsp;תהליכונים",
                      "tr_td3_title": "4 ליבות/ 8&nbsp;תהליכונים"
                    },
                    {
                      "tr_td1_title": "טכנולוגיית Intel® Turbo Boost",
                      "tr_td2_title": "טכנולוגיית Intel® Turbo Boost",
                      "tr_td3_title": "טכנולוגיית Intel®‎ Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "מטמון חכם של INTEL® בנפח 8&nbsp;MB",
                      "tr_td2_title": "מטמון חכם של INTEL® בנפח 12&nbsp;MB",
                      "tr_td3_title": "מטמון חכם של INTEL® בנפח 12&nbsp;MB"
                    },
                    {
                      "tr_td1_title": "גרפיקת Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "גרפיקת Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "גרפיקת Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "מהירות שעון מקסימלית של עד 5.0‎ GHz שתדחף את המשחקים שלך רחוק יותר, ומחשב קל משקל שייקח אותם לכל מקום.",
                "tr_title": "יותר עוצמה לכל קילוגרם.",
                "tr_gen_core_title": "מעבד Intel® Core™ i7 SE דור&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "למצוא את מעבד הגיימינג קל המשקל של INTEL® CORE™‎ המתאים לך.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "מעבד Intel®&nbsp;Core™ i5-11300H דור&nbsp;11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "מעבד Intel®&nbsp;Core™ i7-11370H דור&nbsp;11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "מעבד Intel®&nbsp;Core™ i7-11375H SE דור&nbsp;11"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "מהירות שעון מקסימלית עד <span class='style_badge'>‎4.4 GHz</span>",
                      "tr_td2_title": "מהירות שעון מקסימלית עד <span class='style_badge'>‎4.8 GHz</span>",
                      "tr_td3_title": "מהירות שעון מקסימלית של עד <span class='style_badge'>‎5.0 GHz‎</span>"
                    },
                    {
                      "tr_td1_title": "4 ליבות/ 8&nbsp;תהליכונים",
                      "tr_td2_title": "4 ליבות/ 8&nbsp;תהליכונים",
                      "tr_td3_title": "4 ליבות/ 8&nbsp;תהליכונים"
                    },
                    {
                      "tr_td1_title": "טכנולוגיית Intel® Turbo Boost",
                      "tr_td2_title": "טכנולוגיית Intel® Turbo Boost",
                      "tr_td3_title": "טכנולוגיית Intel®‎ Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "מטמון חכם של INTEL® בנפח 8&nbsp;MB",
                      "tr_td2_title": "מטמון חכם של INTEL® בנפח 12&nbsp;MB",
                      "tr_td3_title": "מטמון חכם של INTEL® בנפח 12&nbsp;MB"
                    },
                    {
                      "tr_td1_title": "גרפיקת Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "גרפיקת Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "גרפיקת Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "מעבד Intel® Core™ i7 Special Edition דור 11 מספק יצירת תוכן מתקדם, חוויית גיימינג חלקה ובידור משודרג למחשבים אישיים אולטרה-ניידים.",
                "tr_title": "ביצועי מחשב נייד פורצי&nbsp;גבולות",
                "tr_gen_core_title": "מעבד Intel® Core™ i7 SE דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד Intel® Core™ i7 Special Edition דור 11"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "מעבד Intel® Core™ i7 Special Edition דור 11 מספק יצירת תוכן מתקדם, חוויית גיימינג חלקה ובידור משודרג למחשבים אישיים אולטרה-ניידים.",
                "tr_title": "ביצועי מחשב נייד פורצי&nbsp;גבולות",
                "tr_gen_core_title": "מעבד Intel® Core™ i7 SE דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד Intel® Core™ i7 Special Edition דור 11",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "מהירויות שעון גבוהות וספירת ליבות גבוהה עובדות יחד בצורה חלקה כדי להוציא יותר מגיימינג תחרותי ומיצירת תוכן מורכב.",
                "tr_title": "עוצמה גדולה. איזון&nbsp;מושלם.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "האיזון הנכון בין מהירות השעון לבין ביצועי הליבה מאפשר לקבל יותר מהמשחקים, בין אם למשחק תחרותי עם תדירות תמונות לשנייה (FPS) גבוהה ובין אם לחוויות AAA סוחפות.",
                "tr_title": "עוצמה עם מטרה.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "לנגן, להקליט ולשדר בסטרימינג כותרים תובעניים עם תדירות גבוהה של תמונות לשניה (FPS) ולעבור בקלות לעומסי עבודה כבדים של ריבוי משימות.",
                "tr_title": "עוצמה שדוחפת את&nbsp;הגבולות.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "מהירויות שעון גבוהות וספירת ליבות גבוהה עובדות יחד בצורה חלקה כדי להוציא יותר מגיימינג תחרותי ומיצירת תוכן מורכב.",
                "tr_title": "עוצמה גדולה. איזון&nbsp;מושלם.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "ניתן לעבור בקלות מסרטים בצפייה ישירה (סטרימינג) ליצירת מצגות עם שפע של כוח עיבוד עודף.",
                "tr_title": "עבודה לאורך כל היום",
                "tr_gen_core_title": "מעבד Intel®‎&nbsp;Core™&nbsp; i3 עם טכנולוגיית Intel®&nbsp;Hybrid",
                "tr_subTitle2": "מעבד Intel®‎&nbsp;Core™&nbsp; i3 עם טכנולוגיית Intel®&nbsp;Hybrid"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "ביצועים רב-תכליתיים פוגשים חיי סוללה ארוכים, כך שניתן להמשיך בעיסוקיך, בכל מקום.",
                "tr_title": "עבודה לאורך כל היום",
                "tr_gen_core_title": "מעבד Intel®‎&nbsp;Core™&nbsp; i3 עם טכנולוגיית Intel®&nbsp;Hybrid",
                "tr_subTitle2": "מעבד Intel®‎&nbsp;Core™&nbsp; i3 עם טכנולוגיית Intel®&nbsp;Hybrid"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "תמרנו בקלות בין תוכנות רבות כדי להיות מוכנים לכל דבר, לא משנה לאן יוביל אתכם היום.",
                "tr_title": "ריבוי משימות בדרכים",
                "tr_gen_core_title": "מעבד Intel®‎&nbsp;Core™&nbsp; i5 עם טכנולוגיית Intel®&nbsp;Hybrid",
                "tr_subTitle2": "מעבד Intel®‎&nbsp;Core™&nbsp; i5 עם טכנולוגיית Intel®&nbsp;Hybrid"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "שפרו את כל מה שתעשו, מבידור דרך תפוקה ועד יצירת תוכן.",
                "tr_title": "שדרגו למחשב אישי חזק יותר",
                "tr_gen_core_title": "מעבד Intel®‎&nbsp;Core™&nbsp; i5 עם טכנולוגיית Intel®&nbsp;Hybrid",
                "tr_subTitle2": "מעבד Intel®‎&nbsp;Core™&nbsp; i5 עם טכנולוגיית Intel®&nbsp;Hybrid"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "ביצועי גיימינג ברמת מחשב שולחני פוגשים את הניידות, לגיימינג תחרותי עם FPS גבוה או לחוויית AAA סוחפת.",
                "tr_title": "עוצמה עם מטרה.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "ביצועי גיימינג ברמת מחשב שולחני פוגשים את הניידות, לגיימינג תחרותי עם FPS גבוה או לחוויית AAA סוחפת.",
                "tr_title": "עוצמה עם מטרה.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5‎ דור 11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "מחשב נייד שאפשר לנגן, להקליט ולשדר בסטרימינג בו בו-זמנית עם FPS גבוה ולאחר מכן להחליף בקלות לעומסי עבודה כבדים של ריבוי-משימות.",
                "tr_title": "עוצמה שדוחפת את&nbsp;הגבולות.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "מחשב נייד שאפשר לנגן, להקליט ולשדר בסטרימינג בו בו-זמנית עם FPS גבוה ולאחר מכן להחליף בקלות לעומסי עבודה כבדים של ריבוי-משימות.",
                "tr_title": "עוצמה שדוחפת את&nbsp;הגבולות.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "מהירויות שעון מהירות וספירת ליבות גבוהה עובדות יחד בצורה חלקה כדי להוציא יותר מגיימינג תחרותי ומיצירת תוכן מורכב, בכל מקום שאליו תיקח את המחשב שלך.",
                "tr_title": "עוצמה גדולה. איזון&nbsp;מושלם.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "מהירויות שעון מהירות וספירת ליבות גבוהה עובדות יחד בצורה חלקה כדי להוציא יותר מגיימינג תחרותי ומיצירת תוכן מורכב, בכל מקום שאליו תיקח את המחשב שלך.",
                "tr_title": "עוצמה גדולה. איזון&nbsp;מושלם.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9‎ דור 11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "מהירויות שעון מהירות, ספירת ליבות גבוהה וביצועים משוחררים עובדים יחד בצורה חלקה כדי להוציא יותר מגיימינג תחרותי ומיצירת תוכן מורכב, בכל מקום שאליו תיקח את המחשב שלך.",
                "tr_title": "עוצמה משוחררת. איזון מושלם.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "מהירויות שעון מהירות, ספירת ליבות גבוהה וביצועים משוחררים עובדים יחד בצורה חלקה כדי להוציא יותר מגיימינג תחרותי ומיצירת תוכן מורכב, בכל מקום שאליו תיקח את המחשב שלך.",
                "tr_title": "עוצמה משוחררת. איזון מושלם.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9‎ דור 11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "מהירויות שעון גבוהות וספירת ליבות גבוהה עובדות יחד בצורה חלקה כדי להוציא יותר מגיימינג תחרותי ומיצירת תוכן מורכב.",
                "tr_title": "עוצמה גדולה. איזון&nbsp;מושלם.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9‎ דור 11",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "מהירויות שעון מהירות וספירת ליבות גבוהה עובדות יחד בצורה חלקה כדי להפיק את המרב מיצירת תוכן מורכב, מכל מקום שבו תהיו.",
                "tr_title": "עוצמה גדולה. איזון&nbsp;מושלם.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9‎ דור 11",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "מהירויות שעון גבוהות וספירת ליבות גבוהה עובדות יחד בצורה חלקה כדי להפיק את המרב מיצירת תוכן מורכב.",
                "tr_title": "עוצמה גדולה. איזון&nbsp;מושלם.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9‎ דור 11",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "לדחוף את הפוטנציאל שלך קדימה עם חווית הגיימינג האולטימטיבית",
                "tr_title": "להתחרות ברמות הכי&nbsp;גבוהות",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;עשירי",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון בגודל<br/></span><span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "בחרו את מעבד INTEL® CORE™‎ דור עשירי שמתאים&nbsp;למשחקים&nbsp;שלכם.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "לגיימינג AAA חלק ללא הפרעות",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג תחרותי",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג והקלטה",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "יצירת תוכן מתקדם",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ביצועי שיא בגיימינג",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "לא נעול ומאפשר המהרה<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "לדחוף את הפוטנציאל שלך קדימה עם חווית הגיימינג האולטימטיבית",
                "tr_title": "להתחרות ברמות הכי&nbsp;גבוהות",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;עשירי",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון בגודל<br/></span><span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "בחרו את מעבד INTEL® CORE™‎ דור עשירי שמתאים&nbsp;למשחקים&nbsp;שלכם.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "לגיימינג AAA חלק ללא הפרעות",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג תחרותי",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "סטרימינג והקלטה",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "יצירת תוכן מתקדם",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "ביצועי שיא בגיימינג",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "לא נעול ומאפשר המהרה<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5.3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 10<br/>תהליכונים: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "לאנשים שנמצאים תמיד בתנועה, מחשב נייד שעוצב במיוחד בשבילכם.",
                "tr_title": "קטגוריה חדשה של מחשבים ניידים",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;עשירי"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "מהירויות שעון גבוהות וספירת ליבות גבוהה עובדות יחד בצורה חלקה כדי להוציא יותר מגיימינג תחרותי ומיצירת תוכן מורכב.",
                "tr_title": "עוצמה משוחררת. איזון מושלם.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9‎ דור 11",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "מהירויות שעון מהירות, ספירת ליבות גבוהה וביצועים בלתי מוגבלים עובדים יחד בצורה חלקה כדי להפיק את המרב מיצירת תוכן מורכב, מכל מקום שבו תהיו.",
                "tr_title": "עוצמה משוחררת. איזון מושלם.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9‎ דור 11",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "מהירויות שעון גבוהות וספירת ליבות גבוהה עובדות יחד בצורה חלקה כדי להפיק את המרב מיצירת תוכן מורכב.",
                "tr_title": "עוצמה משוחררת. איזון מושלם.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;11",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9‎ דור 11",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון חכם של Intel®‎ של‎<br/></span> <span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "משחק ומציאות מדומה חלקים בכותרים התובעניים ביותר",
                "tr_title": "גיימינג רציני מתחיל כאן",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון בגודל<br/></span><span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "בחרו את מעבד INTEL® CORE™‎ שמתאים למשחק שלכם.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "גיימינג AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג תחרותי",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "לשחק, להזרים ולהקליט ללא פשרות",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "מעבד Intel® Core™‎ דור תשיעי הטוב ביותר למשחקים",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 4<br/>תהליכונים: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "עד 32% יותר תמונות לשנייה<br/><span class='tr_subtitle_font'>בזמן גיימינג, סטרימינג והקלטה לעומת מחשב בן 3 שנים <sup>3</sup></span>",
                "tr_title": "הכוח לשתף את רגעי המשחק הגדולים ביותר שלך",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון בגודל<br/></span><span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "בחרו את מעבד INTEL® CORE™‎ שמתאים למשחק שלכם.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "גיימינג AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג תחרותי",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "לשחק, להזרים ולהקליט ללא פשרות",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "מעבד Intel® Core™‎ דור תשיעי הטוב ביותר למשחקים",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 4<br/>תהליכונים: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "עד 41% יותר תמונות לשנייה<br/><span class='tr_subtitle_font'>בזמן גיימינג, סטרימינג והקלטה לעומת מחשב בן 3 שנים <sup>4</sup></span>",
                "tr_title": "גיימרים דורשים. Intel® Core™ i9 מקיים.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>תהליכונים</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>ליבות</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>עד <br/></span><span class='badgetitle_font'>‎[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>מטמון בגודל<br/></span><span class='badgetitle_font'>‎[XX]&nbsp;MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "לא נעול",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "בחרו את מעבד INTEL® CORE™‎ שמתאים למשחק שלכם.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "מעבד Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "גיימינג AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "גיימינג תחרותי",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "לשחק, להזרים ולהקליט ללא פשרות",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "מעבד Intel® Core™‎ דור תשיעי הטוב ביותר למשחקים",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 4<br/>תהליכונים: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 6<br/>תהליכונים: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎4.9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>מהירות שעון<sup>2</sup>:<br>עד ‎5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>ליבות: 8<br/>תהליכונים: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "בנוי לדור הגיימינג הבא",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;12",
                    "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5 דור 12"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;12",
                    "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 12"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;12",
                    "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9 דור 12"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>ליבות</span></span>",
                    "tr_badgePreTitle": "‎[p_core] Performance-cores",
                    "tr_badgeSubTitle": "‎[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>תהליכונים</span></span>",
                    "tr_badgePreTitle": "[p_thread] ב-Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] ב-Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "עמדת הקרב פוגשת את עמדת העבודה",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;12",
                    "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 12"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;12",
                    "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9 דור 12"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>ליבות</span></span>",
                    "tr_badgePreTitle": "‎[p_core] Performance-cores",
                    "tr_badgeSubTitle": "‎[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>תהליכונים</span></span>",
                    "tr_badgePreTitle": "[p_thread] ב-Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] ב-Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים אמינים ברגעים החשובים באמת",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i3 דור&nbsp;12",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i3 דור 12",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "שיפור ביצועים בהם יש הכי הרבה צורך",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;12",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5 דור 12",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "שיפור ביצועים בהם יש הכי הרבה צורך",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;12",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5 דור 12",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים מעולים במקומות החשובים ביותר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;12",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 12",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים מעולים במקומות החשובים ביותר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;12",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 12",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "עם המחשבים הניידים Intel® Evo™‎ תקבלו ביצועים, בינה מובנית שמאפשרת שיחות וידאו אמינות, אינטרנט מהיר יותר, סוללה שמחזיקה מעמד לאורך זמן ועוד.",
                "tr_title": "שיפור הביצועים, בדיוק איפה שצריך.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;12",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5 דור 12",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "עם המחשבים הניידים Intel® Evo™‎ תקבלו ביצועים, בינה מובנית שמאפשרת שיחות וידאו אמינות, אינטרנט מהיר יותר, סוללה שמחזיקה מעמד לאורך זמן ועוד.",
                "tr_title": "ביצועים מעולים במקומות החשובים ביותר",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;12",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 12",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "עם המחשבים הניידים Intel® Evo™‎ תקבלו ביצועים, בינה מובנית שמאפשרת שיחות וידאו אמינות, אינטרנט מהיר יותר, סוללה שמחזיקה מעמד לאורך זמן ועוד.",
                "tr_title": "ביצועים מעולים במחשב נייד באיכות פרימיום",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;12",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9 דור 12",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "עם טכנולוגיית Intel® Turbo Boost 2.0 עד ‎[max_turbo_frequency] GHz",
                "tr_title": "בנוי לעסקים",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;12",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5 דור 12",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "השתקת רעשים וטשטוש הרקע",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "התאמה אוטומטית של ביצועים שמתעדפת את הדברים שעושים הכי הרבה",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "חוויה חלקה ובאיכות גבוהה של שיחות אינטרנט",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "אבטחה ייחודית מבוססת חומרה ישר מהקופסה",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "יכולות ניהול מכשיר מרחוק",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "מיטוב אוטומטי של ביצועי ה-Wi-Fi שלכם<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "עבודה ללא הפסקה של יישומים מבוססי-נתונים",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "הפרודוקטיביות הגבוהה ביותר עם ביצועים משופרים",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "עם טכנולוגיית Intel® Turbo Boost 2.0 עד ‎[max_turbo_frequency] GHz",
                "tr_title": "עובד קשה, כדי שתוכלו להתמקד בעסק שלכם.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;12",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 12",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "השתקת רעשים וטשטוש הרקע",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "התאמה אוטומטית של ביצועים שמתעדפת את הדברים שעושים הכי הרבה",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "חוויה חלקה ובאיכות גבוהה של שיחות אינטרנט",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "אבטחה ייחודית מבוססת חומרה ישר מהקופסה",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "יכולות ניהול מכשיר מרחוק",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "מיטוב אוטומטי של ביצועי ה-Wi-Fi שלכם<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "עבודה ללא הפסקה של יישומים מבוססי-נתונים",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "הפרודוקטיביות הגבוהה ביותר עם ביצועים משופרים",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "עם טכנולוגיית Intel® Turbo Boost 2.0 עד ‎[max_turbo_frequency] GHz",
                "tr_title": "תתחילו להאיץ את העסק שלכם",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;12",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9 דור 12",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "השתקת רעשים וטשטוש הרקע",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "התאמה אוטומטית של ביצועים שמתעדפת את הדברים שעושים הכי הרבה",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "חוויה חלקה ובאיכות גבוהה של שיחות אינטרנט",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "אבטחה ייחודית מבוססת חומרה ישר מהקופסה",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "יכולות ניהול מכשיר מרחוק",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "מיטוב אוטומטי של ביצועי ה-Wi-Fi שלכם<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "עבודה ללא הפסקה של יישומים מבוססי-נתונים",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "הפרודוקטיביות הגבוהה ביותר עם ביצועים משופרים",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים&nbsp;מהימנים שנועדו&nbsp;לעשות יותר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i3 דור&nbsp;13",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים&nbsp;אמיתיים שנועדו&nbsp;לעשות יותר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;13",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5 דור 13",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים&nbsp;אמיתיים שנועדו&nbsp;לעשות יותר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;13",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5 דור 13",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים&nbsp;עוצמתיים שנועדו&nbsp;לעשות יותר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;13",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 13",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים&nbsp;עוצמתיים שנועדו&nbsp;לעשות יותר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;13",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 13",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים מעל ומעבר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;13",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9 דור 13",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>ליבות</span></span>",
                    "tr_badgePreTitle": "‎[p_core] Performance-cores",
                    "tr_badgeSubTitle": "‎[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>תהליכונים</span></span>",
                    "tr_badgePreTitle": "[p_thread] ב-Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] ב-Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "חיבור חלק למכשיר הטלפון<sup>24</sup>, בחירה חכמה ב-Wi-Fi הטוב ביותר וטעינה מהירה עם סוללה שמחזיקה מעמד.",
                "tr_title": "ביצועים מעל ומעבר שמאפשרים לעשות יותר בכל מקום",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;13",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5 דור 13",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "חיבור חלק למכשיר הטלפון<sup>24</sup>, בחירה חכמה ב-Wi-Fi הטוב ביותר וטעינה מהירה עם סוללה שמחזיקה מעמד.",
                "tr_title": "ביצועים מעל ומעבר שמאפשרים למחשב לעשות אפילו יותר בבת אחת",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;13",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 13",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "חיבור חלק למכשיר הטלפון<sup>24</sup>, בחירה חכמה ב-Wi-Fi הטוב ביותר וטעינה מהירה עם סוללה שמחזיקה מעמד.",
                "tr_title": "ביצועים מעל ומעבר לביצוע מהיר של המשימות התובעניות ביותר",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;13",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9 דור 13",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים מעל ומעבר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;13",
                    "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5 דור 13",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;13",
                    "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 13",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;13",
                    "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9 דור 13",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>ליבות</span></span>",
                    "tr_badgePreTitle": "‎[p_core] Performance-cores",
                    "tr_badgeSubTitle": "‎[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>תהליכונים</span></span>",
                    "tr_badgePreTitle": "[p_thread] ב-Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] ב-Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים מעל ומעבר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "מעבד Intel® Core™ i5",
                    "tr_gen_core_title_uppercase": "מעבד Intel® Core™‎ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "מעבד Intel® Core™ i7",
                    "tr_gen_core_title_uppercase": "מעבד Intel® Core™‎ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "מעבד Intel® Core™ i9",
                    "tr_gen_core_title_uppercase": "מעבד Intel® Core™‎ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>ליבות</span></span>",
                    "tr_badgePreTitle": "‎[p_core] Performance-cores",
                    "tr_badgeSubTitle": "‎[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>תהליכונים</span></span>",
                    "tr_badgePreTitle": "[p_thread] ב-Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] ב-Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים&nbsp;מהימנים שנועדו&nbsp;לעשות יותר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "מעבד Intel® Core™ i3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים&nbsp;אמיתיים שנועדו&nbsp;לעשות יותר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "מעבד Intel® Core™ i5",
                "tr_gen_core_title_uppercase": "מעבד Intel® Core™‎ i5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים&nbsp;עוצמתיים שנועדו&nbsp;לעשות יותר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "מעבד Intel® Core™ i7",
                "tr_gen_core_title_uppercase": "מעבד Intel® Core™‎ i7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים מעל ומעבר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "מעבד Intel® Core™ i5",
                    "tr_gen_core_title_uppercase": "מעבד Intel® Core™‎ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "מעבד Intel® Core™ i7",
                    "tr_gen_core_title_uppercase": "מעבד Intel® Core™‎ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "מעבד Intel® Core™ i9",
                    "tr_gen_core_title_uppercase": "מעבד Intel® Core™‎ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>ליבות</span></span>",
                    "tr_badgePreTitle": "‎[p_core] Performance-cores",
                    "tr_badgeSubTitle": "‎[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>תהליכונים</span></span>",
                    "tr_badgePreTitle": "[p_thread] ב-Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] ב-Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים&nbsp;מהימנים שנועדו&nbsp;לעשות יותר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "מעבד Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "מעבד Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "מעבד Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים&nbsp;אמיתיים שנועדו&nbsp;לעשות יותר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;5",
                "tr_gen_core_title_uppercase": "מעבד Intel®&nbsp;Core™&nbsp;5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "מעבד Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "מעבד Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "מעבד Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "ביצועים&nbsp;עוצמתיים שנועדו&nbsp;לעשות יותר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;7",
                "tr_gen_core_title_uppercase": "מעבד Intel®&nbsp;Core™&nbsp;7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "מעבד Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "מעבד Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "מעבד Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "מעבד",
                "tr_title": "לשחק בלי להתפשר",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "מעבד Intel® Core™ Ultra 5",
                    "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ ULTRA 5",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "מעבד Intel® Core™‎ Ultra 7",
                    "tr_gen_core_title_uppercase": "מעבד Intel® CORE™‎ ULTRA 7",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "מעבד Intel® Core™‎ Ultra 9",
                    "tr_gen_core_title_uppercase": "מעבד Intel® CORE™‎ ULTRA 9",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>ליבות</span></span>",
                    "tr_badgePreTitle": "‎[p_core] Performance-cores",
                    "tr_badgeSubTitle": "‎[e_core] Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core] ליבות LPE"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>תהליכונים</span></span>",
                    "tr_badgePreTitle": "[p_thread] ב-Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] ב-Efficient-cores",
                    "tr_badgeSubTitle1": "[l_thread] בליבות LPE"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™ Ultra 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™‎ Ultra 7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "מעבד Intel® Core™‎ Ultra 9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "לשחק בלי להתפשר",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "לשחק בלי להתפשר",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "לשחק בלי להתפשר",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "לשחק בלי להתפשר",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "לשחק בלי להתפשר",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "לשחק בלי להתפשר",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "לעסקים קטנים מגיעים פתרונות עסקיים גדולים.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i5 דור&nbsp;13",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i5 דור 13",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "השתקת רעשים וטשטוש הרקע",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "התאמה אוטומטית של ביצועים שמתעדפת את הדברים שעושים הכי הרבה",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "חוויה חלקה ובאיכות גבוהה של שיחות אינטרנט",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "הגנות אבטחה מבוססת חומרה ישר מהקופסה",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "עזרה בהגנה על מחשבים ונתונים ממתקפות חבלה פיזיות",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "לעסקים קטנים מגיעים פתרונות עסקיים גדולים.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;13",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i7‎ דור 13",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "השתקת רעשים וטשטוש הרקע",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "התאמה אוטומטית של ביצועים שמתעדפת את הדברים שעושים הכי הרבה",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "חוויה חלקה ובאיכות גבוהה של שיחות אינטרנט",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "הגנות אבטחה מבוססת חומרה ישר מהקופסה",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "עזרה בהגנה על מחשבים ונתונים ממתקפות חבלה פיזיות",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "לעסקים קטנים מגיעים פתרונות עסקיים גדולים.",
                "tr_gen_core_title": "מעבד Intel®&nbsp;Core™&nbsp;i9 דור&nbsp;13",
                "tr_gen_core_title_uppercase": "מעבד INTEL® CORE™ i9 דור 13",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "השוואה של מעבד זה.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "מכשיר נוכחי",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "השתקת רעשים וטשטוש הרקע",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "התאמה אוטומטית של ביצועים שמתעדפת את הדברים שעושים הכי הרבה",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "חוויה חלקה ובאיכות גבוהה של שיחות אינטרנט",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "הגנות אבטחה מבוססת חומרה ישר מהקופסה",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "עזרה בהגנה על מחשבים ונתונים ממתקפות חבלה פיזיות",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "גלו חוויות AI חדשות לעסקים",
                "tr_title": "להיות מוכנים לדבר הבא עם מעבדי Intel® Core™ Ultra&nbsp;[XX] ו-Intel vPro®‎",
                "tr_gen_core_title": "מעבד Intel® Core™ Ultra‎ [XX]",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "תנו לעסק שלכם דחיפה עם מחשבי AI מבית INTEL",
                    "tr_badgeSubTitle": "מחשבים חזקים שיוצרים חוויה נהדרת הם הליבה של היעילות העסקית. Intel vPro®‎ מגדיל את האפקטיביות והביצועים של העובדים על פני עומסי עבודה ואפליקציות, הודות לארכיטקטורה חדשה המספקת ביצועים חסכוניים בחשמל עבור עומסי עבודה עסקיים שונים ומורכבים על ידי שליחת המשימה הנכונה למנוע הנכון בזמן הנכון.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "תכונות אבטחה רב-שכבתיות מופעלות ישירות מהקופסה",
                    "tr_badgeSubTitle": "כשיש איומים חדשים ובהתהוות נדרשת גישה של אבטחה מקיפה. Intel vPro®‎ מספק הגנות ברמת החומרה ובכל המבנה, כך שהעסק שלכם מוגן טוב יותר. אמצעי האבטחה הייחודיים האלה, כולל יכולות זיהוי איומים המופעלות על ידי בינה מלאכותית, עוזרים להגן על המשתמשים, על הנתונים ועל העסק שלכם.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "הכלים המובנים שומרים על הפרודוקטיביות של המשתמשים מכל מקום כמעט",
                    "tr_badgeSubTitle": "גם משרדים וגם מחשבים אישיים נמצאים בכל מקום. Intel vPro®‎ מספק יכולות ניהול מבוססות חומרה מודרניות שנועדו לסייע לעסקים להקל על הנטל של ניהול צי מחשבים, לפשט את התמיכה ולשפר את חוויית משתמש הקצה, כל זאת תוך הענקת אפשרות לשיטות מחשוב בנות קיימא.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "יותר ממחשב אישי נהדר, מחשב בינה מלאכותית",
                "tr_subtitle1": "מופעל באמצעות מעבדי INTEL®&nbsp;CORE™ ULTRA (סדרה&nbsp;2)",
                "tr_subtitle2": "זה אפשרי במחשב Intel AI",
                "tr_subtitle": "המעבד הנייד הכי יעיל ובטוח ובעל הביצועים הטובים ביותר של Intel‏<sup>39</sup>",
                "tr_gen_core_title": "מעבד Intel®‎ Core™‎ Ultra&nbsp;5 (סדרה&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>יותר יצירתיות</span> עם כלי בינה מלאכותית שעוזרים ליצור מהר יותר"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>יותר פרודוקטיביות</span> עם עזרה של בינה מלאכותית שחוסכת בזמן"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>יותר אבטחה</span> עם הגנה מובנית על נתונים"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "יותר ממחשב אישי נהדר, מחשב בינה מלאכותית",
                "tr_subtitle1": "מופעל באמצעות מעבדי INTEL®&nbsp;CORE™ ULTRA (סדרה&nbsp;2)",
                "tr_subtitle2": "זה אפשרי במחשב Intel AI",
                "tr_subtitle": "המעבד הנייד הכי יעיל ובטוח ובעל הביצועים הטובים ביותר של Intel‏<sup>39</sup>",
                "tr_gen_core_title": "מעבד Intel®‎ Core™‎ Ultra&nbsp;7 (סדרה&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>יותר יצירתיות</span> עם כלי בינה מלאכותית שעוזרים ליצור מהר יותר"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>יותר פרודוקטיביות</span> עם עזרה של בינה מלאכותית שחוסכת בזמן"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>יותר אבטחה</span> עם הגנה מובנית על נתונים"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "יותר ממחשב אישי נהדר, מחשב בינה מלאכותית",
                "tr_subtitle1": "מופעל באמצעות מעבדי INTEL®&nbsp;CORE™ ULTRA (סדרה&nbsp;2)",
                "tr_subtitle2": "זה אפשרי במחשב Intel AI",
                "tr_subtitle": "המעבד הנייד הכי יעיל ובטוח ובעל הביצועים הטובים ביותר של Intel‏<sup>39</sup>",
                "tr_gen_core_title": "מעבד Intel®‎ Core™‎ Ultra&nbsp;5 (סדרה&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מעבד",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "יותר ממחשב אישי נהדר, מחשב בינה מלאכותית",
                "tr_subtitle1": "מופעל באמצעות מעבדי INTEL®&nbsp;CORE™ ULTRA (סדרה&nbsp;2)",
                "tr_subtitle2": "זה אפשרי במחשב Intel AI",
                "tr_subtitle": "המעבד הנייד הכי יעיל ובטוח ובעל הביצועים הטובים ביותר של Intel‏<sup>39</sup>",
                "tr_gen_core_title": "מעבד Intel®‎ Core™‎ Ultra&nbsp;7 (סדרה&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_tileTitle1": "גרפיקה ייעודית",
                "tr_title": "מהי יחידת עיבוד גרפי?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "יחידת העיבוד הגרפי, או בראשי תיבות באנגלית: GPU, יוצרת את התמונות שמוצגות על המסך.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "מעבד גרפי מובנה",
                    "tr_badgeSubTitle": "מעבד גרפי משולב משתמש באותו RAM כמו שאר המחשב, דבר שהופך אותו ליעיל יותר בצריכת חשמל.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "מעבד גרפי ייעודי",
                    "tr_badgeSubTitle": "אלו המעבדים הגרפיים החזקים ביותר מכיוון שיש להם זיכרון RAM ייעודי משלהם, בנוסף למשאבים אחרים אשר מוקדשים ליצירת גרפיקה יוצאת מן הכלל.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "מעבד גרפי היברידי",
                    "tr_badgeSubTitle": "אלו המעבדים הגרפיים החזקים ביותר מכיוון שיש להם זיכרון RAM ייעודי משלהם, בנוסף למשאבים אחרים אשר מוקדשים ליצירת גרפיקה יוצאת מן הכלל.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "עריכת תמונות ווידאו יעילה",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "צפה בבינג' בתוכן וידאו פרימיום ב-4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "שחק משחקים פופולריים בפירוט מדהים",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "מעבדים גרפיים כפולים",
                    "tr_badgeSubTitle": "מערכות מסוימות, כגון אלה עם גרפיקת Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>, יכולות לנצל הן את המעבד המובנה בלוח האם והן את המעבד הגרפי הייעודי כדי לחלק את עומס העבודה הגרפי.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "מעבד גרפי מובנה",
                    "tr_badgeSubTitle": "GPU מובנה הוא שבב עיבוד גרפי הבנוי בתוך שבב יחידת&nbsp;עיבוד&nbsp;הראשית&nbsp;(CPU). סוג זה של GPU משתמש באותו זיכרון RAM, ומשאבים נוספים, אשר משמשים את שאר חלקי המחשב ולכן הוא חסכוני באנרגיה ומאפשר לבנות מכשירים דקים וקלים יותר.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "מעבד גרפי ייעודי",
                    "tr_badgeSubTitle": "אלו המעבדים הגרפיים החזקים ביותר מכיוון שיש להם זיכרון RAM ייעודי משלהם, בנוסף למשאבים אחרים אשר מוקדשים ליצירת גרפיקה יוצאת מן הכלל.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "סטנדרטי",
                    "tr_badgeSubTitle": "עד ‎4 GB VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "תחרותי",
                    "tr_badgeSubTitle": "עד ‎8 GB VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "אולטרה",
                    "tr_badgeSubTitle": "יותר מ-‎8 GB VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_title": "עוצמה גרפית אדירה",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "העיבוד הגרפי העוצמתי ביותר זקוק לכרטיס גרפי שיכול להתמקד בלעדית ביצירת תמונות יפהפיות וחדות ואנימציה חלקה.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_title": "גרפיקה עשירה ומלאת חיים היא חלק אינטגרלי מהמכשיר",
                "tr_pretitle": "גרפיקת Intel® Iris® Plus",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "הכרטיס הגרפי Intel® Iris® Plus מאפשר לך להיסחף עם וידאו באיכות 4K HDR ומשחקים באיכות HD מלא ללא צורך בכרטיס גרפי נוסף.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_title": "אפקטים חזותיים מרהיבים, ביצועים ללא מאמץ",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "גרפיקת Intel®‎ מספקת ביצועים מהפכניים של גרפיקה משולבת לתמיכה בעיצוב גמיש ליוצרים ובחוויות גיימינג עשירות.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_title": "גרפיקת Intel® Iris® Pro",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_title": "גרפיקת Intel® UHD כולל ארכיטקטורה X<sup class='small_text_sup_xe'>e</sup>",
                "tr_pretitle": "חוויות ויזואליות סוחפות",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "לחוות עריכת תמונות ווידאו יעילה, לצפות בבינג' בתוכן וידאו פרימיום ב-4K או לשחק במשחקים פופולריים בפרטים מדהימים.",
                "tr_photo_text": "עריכת תמונות ווידאו יעילה",
                "tr_video_text": "צפה בבינג' בתוכן וידאו פרימיום ב-4K",
                "tr_game_text": "שחק משחקים פופולריים בפירוט מדהים",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "הרבה יותר מגרפיקה יפה",
                "tr_subtitle": "הכרטיס הגרפי (או GPU), שעובד בצמוד למעבד (או CPU), עוזר לקבוע את ביצועי המשחק ואת רמת הגרפיקה שבה תוכל לשחק. גודל ה-VRAM, או ה-RAM של כרטיס המסך, הוא דרך מהירה לדעת מהם ביצועי הכרטיס הגרפי שלך.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>זיכרון VRAM בנפח</span><br/><span class='value'>&lrm;[XX]&nbsp;GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "לגרפיקת גיימינג אינטנסיבית",
                "tr_subtitle": "תהליכי עיבוד הגפיקה התובעניים ביותר מחייבים שימוש בכרטיס גרפי נפרד שיכול להתמקד אך ורק ביצירת תמונות נהדרות וברורות ואנימציה חלקה",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>זיכרון VRAM בנפח</span><br/><span class='value'>&lrm;[XX]&nbsp;GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "לגרפיקת גיימינג אינטנסיבית",
                "tr_subtitle": "תהליכי עיבוד הגפיקה התובעניים ביותר מחייבים שימוש בכרטיס גרפי נפרד שיכול להתמקד אך ורק ביצירת תמונות נהדרות וברורות ואנימציה חלקה",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>זיכרון VRAM בנפח</span><br/><span class='value'>&lrm;[XX]&nbsp;GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "הרבה יותר מגרפיקה יפה",
                "tr_subtitle": "הכרטיס הגרפי (או GPU), שעובד בצמוד למעבד (או CPU), עוזר לקבוע את ביצועי המשחק ואת רמת הגרפיקה שבה תוכל לשחק. גודל ה-VRAM, או ה-RAM של כרטיס המסך, הוא דרך מהירה לדעת מהם ביצועי הכרטיס הגרפי שלך.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>זיכרון VRAM בנפח</span><br/><span class='value'>&lrm;[XX]&nbsp;GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_title": "עוצמה גרפית אדירה",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "העיבוד הגרפי העוצמתי ביותר זקוק לכרטיס גרפי שיכול להתמקד בלעדית ביצירת תמונות יפהפיות וחדות ואנימציה חלקה.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "לגרפיקת גיימינג אינטנסיבית",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "העיבוד הגרפי העוצמתי ביותר זקוק לכרטיס גרפי שיכול להתמקד בלעדית ביצירת תמונות יפהפיות וחדות ואנימציה חלקה.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>זיכרון VRAM בנפח</span><br/><span class='value'>&lrm;[XX]&nbsp;GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "תהליכי עיבוד הגפיקה התובעניים ביותר מחייבים שימוש בכרטיס גרפי נפרד שיכול להתמקד אך ורק ביצירת תמונות נהדרות וברורות ואנימציה חלקה",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "מספק שפע עוצמה גרפית כדי לענות על דרישות המינימום של המשחק שלך.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "לשחקנים מדרג גבוה שזקוקים לביצועי משחק ברמה תחרותית",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "לגיימרים מתקדמים שמשתמשים במחשב גם ליצירת תוכן כבד",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "וידאו בצבעים מלאי חיים וגיימינג סוחף",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "להפיק את המרב ממסך הרזולוציה הגבוהה שלך באמצעות Intel® HD Graphics ולשאוב הנאה מחוויית מולטימדיה עשירה ועיבוד גרפי מוכלל.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "עוצמה לתמונות וסרטונים מלאי&nbsp;חיים",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "קבלו יותר פרטים וצבע מבריק מהתמונות והווידאו המוזרם שלכם.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_title": "יצירה וגיימינג מדהימים",
                "tr_pretitle": "גרפיקת Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "מבידור סוחף ויצירת תוכן מתקדם ועד לגיימינג רציני, גרפיקת Intel® Iris® X<sup class='small_text_sup_xe'>e‎</sup> מוסיפה ביצועי גרפיקה חדשניים למחשבים דקים וקלי משקל.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_pretitle": "לגרפיקת גיימינג אינטנסיבית",
                "tr_title": "גרפיקת Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "תהליכי עיבוד הגפיקה התובעניים ביותר מחייבים שימוש בכרטיס גרפי נפרד שיכול להתמקד אך ורק ביצירת תמונות נהדרות וברורות ואנימציה חלקה",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "זיכרון <span class='subtitle-value'>VRAM</span> בנפח ‎<span class='value'>[x]&nbsp;GB<br/></span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_title": "לתת דרור ליצירתיות שלך",
                "tr_pretitle": "גרפיקת Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "לזרום בפרוייקטים יצירתיים ולצלול למגוון רחב של משחקים עם העוצמה המשולבת של המעבד הגרפי המובנה בלוח האם והמנוע הגרפי הייעודי הראשון של ®Intel.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "tr_title": "גרפיקת Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "tr_pretitle": "לגרפיקת גיימינג אינטנסיבית",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "תהליכי עיבוד הגפיקה התובעניים ביותר מחייבים שימוש בכרטיס גרפי נפרד שיכול להתמקד אך ורק ביצירת תמונות נהדרות וברורות ואנימציה חלקה",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "זיכרון <span class='subtitle-value'>VRAM</span> בנפח ‎<span class='value'>[x]&nbsp;GB<br/></span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "גרפיקה",
                "tr_title": "גרפיקה יפהפייה מוכללת",
                "tr_subtitle": "שאבו הנאה ממשחקים, סרטים וסרטונים באינטרנט בפירוט וחדות מדהימים.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "גרפיקה",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "גרפיקה",
                    "tr_specText": "משפרים את זרימות העבודה"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "גרפיקה",
                    "tr_specText": "מעצימים את חוויית הגיימינג"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "גרפיקה",
                    "tr_specText": "מעצימים את חוויית הגיימינג"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "גרפיקה",
                    "tr_specText": "משדרגים את הביצועים"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "לתת דרור ליצירתיות שלך",
                    "tr_specText": "מאיצים את הפרודוקטיביות בכל מקום הודות ליכולות המדיה המקיפות ביותר, כמו <strong>קידוד חומרה AV1 מוביל בתעשייה</strong> ויצירת תוכן מואצת באמצעות בינה מלאכותית.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "לתת דרור ליצירתיות שלך",
                    "tr_specText": "מאיצים את הפרודוקטיביות הודות ליכולות המדיה המקיפות ביותר, כמו <strong>קידוד חומרה AV1 מוביל בתעשייה</strong> ויצירת תוכן מואצת באמצעות בינה מלאכותית.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "לתת דרור ליצירתיות שלך",
                    "tr_specText": "מתנסים ביצירת תוכן משופרת באמצעות בינה מלאכותית עם <strong>קידוד חומרה AV1 מוביל בתעשייה</strong> ו-<strong>X<sup>e</sup> Media Engine</strong> המתקדם.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "לתת דרור ליצירתיות שלך",
                    "tr_specText": "מתנסים ביצירת תוכן משופרת באמצעות בינה מלאכותית עם <strong>קידוד חומרה AV1 מוביל בתעשייה</strong> ו-<strong>X<sup>e</sup> Media Engine</strong> המתקדם.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "לתת דרור ליצירתיות שלך",
                    "tr_specText": "מתנסים ביצירת תוכן משופרת באמצעות בינה מלאכותית עם <strong>קידוד חומרה AV1 מוביל בתעשייה</strong> ו-<strong>X<sup>e</sup> Media Engine</strong> המתקדם.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "ניידות פוגשת ביצועים",
                    "tr_specText": "משחקים במשחקים הכי עדכניים בדרכים ומקבלים דיוק וביצועים מדהימים עם יכולות גיימינג מודרניות. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>טכנולוגיית התאמת רזולוציה (upscaling) משופרת באמצעות Intel® AI לביצועים גבוהים יותר וגיימינג חלק. <br/><br/>תמיכה ב-<strong>DirectX* 12 Ultimate:  </strong>לוקחים את הגיימינג לרמה חדשה של ריאליזם עם טכנולוגיות גרפיות, כמו עיקוב קרניים והצללה בקצב משתנה."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "לוקחים שליטה על המשחקים",
                    "tr_specText": "<strong>מרכז בקרה Intel®‎ Arc™‎</strong> מאפשר לך לשפר את החוויה שלך הודות לפריסה אינטואיטיבית ומודרנית. מקבלים עדכונים חלקים למנהלי התקנים, מבצעים אופטימיזציה של המשחקים ועוד."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "לוקחים שליטה על המשחקים",
                    "tr_specText": "<strong>מרכז בקרה Intel®‎ Arc™‎</strong> מאפשר לך לשפר את החוויה שלך הודות לפריסה אינטואיטיבית ומודרנית. מקבלים עדכונים חלקים למנהלי התקנים, מבצעים אופטימיזציה של המשחקים ועוד."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "לוקחים שליטה על המשחקים",
                    "tr_specText": "מקבלים עדכונים חלקים למנהלי התקנים ומבצעים אופטימיזציה של המשחקים האהובים עם התוכנה <strong>מרכז בקרה </strong>Intel®‎ Arc™‎."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "מפיקים את המרב מפלטפורמת Intel®‎",
                    "tr_specText": "מחברים למעבדי Intel® Core™‎ תואמים כדי לאפשר ל-<strong>Intel® Deep Link Technology</strong> לשפר את חוויית היצירה, הגיימינג והסטרימינג."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "מפיקים את המרב ממערכת Intel®‎",
                    "tr_specText": "מחברים למעבדי Intel® Core™‎ תואמים כדי לאפשר ל-<strong>Intel® Deep Link Technology</strong> לשפר את חוויית היצירה, הגיימינג והסטרימינג."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "מפיקים את המרב מפלטפורמת Intel®‎",
                    "tr_specText": "מחברים למעבדי Intel® Core™‎ תואמים כדי לאפשר ל-<strong>Intel® Deep Link Technology</strong> לשפר את חוויית היצירה, הגיימינג והסטרימינג."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "מפיקים את המרב מפלטפורמת Intel®‎",
                    "tr_specText": "מחברים למעבדי Intel® Core™‎ תואמים כדי לאפשר ל-<strong>Intel® Deep Link Technology</strong> לשפר את חוויית היצירה, הגיימינג והסטרימינג."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "מפיקים את המרב ממערכת Intel®‎",
                    "tr_specText": "מחברים למעבדי Intel® Core™‎ תואמים כדי לאפשר ל-<strong>Intel® Deep Link Technology</strong> לשפר את חוויית היצירה, הגיימינג והסטרימינג."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "מתקדמים למרכז הבמה",
                    "tr_specText": "משדרים בצורה חלקה עם טכנולוגיות סטרימינג מתקדמות שמופעלות על ידי התוכנה <strong>מרכז בקרה Intel®‎ Arc™‎</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "מתקדמים למרכז הבמה",
                    "tr_specText": "משדרים בצורה חלקה עם טכנולוגיות סטרימינג מתקדמות שמופעלות על ידי התוכנה <strong>מרכז בקרה Intel®‎ Arc™‎</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "מתנסים בגיימינג סוחף עם ביצועים גבוהים",
                    "tr_specText": "הביצועים הטובים ביותר בעדיפות ראשונה עם משחקי AAA העדכניים ביותר ברזולוציות גבוהות וקצב רענון גבוה. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>טכנולוגיית התאמת רזולוציה (upscaling) משופרת באמצעות Intel® AI לביצועים גבוהים יותר וגיימינג חלק. <br/><br/>תמיכה ב-<strong>DirectX* 12 Ultimate:  </strong>לוקחים את הגיימינג לרמה חדשה של ריאליזם עם טכנולוגיות גרפיות, כמו עיקוב קרניים והצללה בקצב משתנה."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "מתנסים בגיימינג סוחף עם ביצועים גבוהים",
                    "tr_specText": "הביצועים הטובים ביותר בעדיפות ראשונה עם משחקי AAA העדכניים ביותר ברזולוציות גבוהות וקצב רענון גבוה. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>טכנולוגיית התאמת רזולוציה (upscaling) משופרת באמצעות Intel® AI לביצועים גבוהים יותר וגיימינג חלק. <br/><br/>תמיכה ב-<strong>DirectX* 12 Ultimate:  </strong>לוקחים את הגיימינג לרמה חדשה של ריאליזם עם טכנולוגיות גרפיות, כמו עיקוב קרניים והצללה בקצב משתנה."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "משפרים את המשחקים",
                    "tr_specText": "לוקחים את חוויית הגיימינג לשלב הבא עם: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>טכנולוגיית התאמת רזולוציה משופרת באמצעות Intel® AI לביצועים גבוהים יותר וגיימינג חלק. <br/><br/>תמיכה ב-<strong>DirectX* 12 Ultimate:  </strong>לוקחים את הגיימינג לרמה חדשה של ריאליזם עם טכנולוגיות גרפיות, כמו עיקוב קרניים והצללה בקצב משתנה."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "חווית גיימינג מתקדמת בקצב מהיר",
                    "tr_specText": "נהנים מגיימינג חלק במשחקי AAA פופולריים רבים, ומגיימינג בקצב רענון גבוה, החל ממשחקי יריות בספורט אלקטרוני מסעירים ועד להיטי RTS בקצב מהיר. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>טכנולוגיית התאמת רזולוציה (upscaling) משופרת באמצעות Intel® AI לביצועים גבוהים יותר וגיימינג חלק. <br/><br/>תמיכה ב-<strong>DirectX* 12 Ultimate:  </strong>לוקחים את הגיימינג לרמה חדשה של ריאליזם עם טכנולוגיות גרפיות, כמו עיקוב קרניים והצללה בקצב משתנה."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "מתנסים בגיימינג סוחף עם ביצועים גבוהים",
                    "tr_specText": "הביצועים הטובים ביותר בעדיפות ראשונה עם משחקי AAA העדכניים ביותר ברזולוציות גבוהות וקצב רענון גבוה. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>טכנולוגיית התאמת רזולוציה (upscaling) משופרת באמצעות Intel® AI לביצועים גבוהים יותר וגיימינג חלק. <br/><br/>תמיכה ב-<strong>DirectX* 12 Ultimate:  </strong>לוקחים את הגיימינג לרמה חדשה של ריאליזם עם טכנולוגיות גרפיות, כמו עיקוב קרניים והצללה בקצב משתנה."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "גרפיקה",
                    "tr_specText": "יקום של גרפיקה עם מקום לכולם"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "מאיצים את הפריימים שלכם, מאיצים את המשחקים שלכם",
                    "tr_specText": "טכנולוגיית Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling (X<sup class='small_text_sup_xe'>e</sup>SS) מעלה את הגיימינג ברמה עם שדרוג באמצעות בינה מלאכותית — לביצועי שיא עם אמינות תמונה גבוהה. X<sup class='small_text_sup_xe'>e</sup>SS מותאם למוצרים עם גרפיקת Intel® Arc™‎ כדי שתוכלו לנצל את מלוא היתרונות של האצת החומרה של Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX)."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "האפקטים הוויזואליים האולטימטיביים כבר כאן",
                    "tr_specText": "עם תמיכה מלאה ב-DirectX* 12 Ultimate בכל גרפיקת Intel® Arc™‎, תיהנו מחוויית משחק מדהימה וסוחפת הודות לטכנולוגיות הגרפיות העדכניות ביותר כמו Ray tracing, הצללה בקצב משתנה, הצללת רשת ומשוב מדגם — היסודות של גיימינג מהדור הבא."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "יצירת תמונות, וידיאו ומדיה בקלות",
                    "tr_specText": "תנו לדמיון שלכם להשתולל ורתקו את הקהל באמצעות יצירת תוכן דיגיטלי עשיר עם מנוע מדיה מתקדם במיוחד — בתוספת בינה מלאכותית ועם האצה של טכנולוגיית Intel®‎. צרו תוכן מרהיב, שמופעל באמצעות גרפיקה עם תמיכה בכל פורמטי המדיה המובילים הנוכחיים תוך שמירה על עדכניות עם יכולות קידוד הווידיאו AV1 המתקדמות ביותר."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "התנסו ביצירה ובגיימינג עם בינה מלאכותית בחזית הטכנולוגיה",
                    "tr_specText": "גרפיקת Intel® Arc™‎ פותחת את הדלת ליכולות בינה מלאכותית חזקות, מיצירה מתקדמת ועד לגיימינג סוחף. הפיקו את המרב מתוכנות היצירה המתקדמות של היום, כולל יצירת תמונה מטקסט ועריכת וידיאו מואצת על ידי בינה מלאכותית. בנוסף, תוכלו ליהנות מגיימינג ברזולוציה גבוהה באמצעות שדרוג X<sup class='small_text_sup_xe'>e</sup>SS מואץ על ידי בינה מלאכותית."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "גרפיקה",
                    "tr_specText": "גרפיקה מקצועית מתחילה כאן"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "נהדר עבור אנשי קריאייטיב, מעצבים ומהנדסים שנמצאים בדרכים",
                    "tr_specText": "זמין במעבדי Intel® Core™ Ultra נבחרים מסדרת H, גרפיקת Intel® Arc™ Pro מובנית עם ביצועים גרפיים משופרים פי שניים עם הסמכות Independent Software Vendor (ISV) לאמינות נוספת והאצת חומרה יעילה באמצעות AI וray tracing ליצירת תוכן מדהים תוך כדי תנועה.<br/><br/><span class='expandable_heading'>אישורי תוכנה<br/></span><br/>Intel שיתפה פעולה עם מאות חברות תוכנה לאורך השנים, והניסיון האדיר הזה נכנס לכרטיסים הגרפיים מסדרת Intel® Arc™ Pro A שלנו. אישורים חשובים לנו כמו שהם חשובים לכם."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "בינה מלאכותית ניתנת להשגה",
                    "tr_specText": "מגוון ביצועי ה-GPU כוללים מבחר טכנולוגיות מצוינות, כולל תמיכה בכלי AI תואמים עם האצת AI ייעודית מובנית היכן שצריכים אותה."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "Ray tracing ללא גרסת הפרימיום",
                    "tr_specText": "כרטיסים גרפיים צריכים לעשות יותר מסתם לדחוף פיקסלים לצגים, הם צריכים להאיץ את היצירה של תמונות יפות. טכנולוגיית חומרה של התוויית קרניים המובנית בגרפיקה מסדרת Intel® Arc™ Pro A מאפשרת לתוכנה תואמת להתחיל לחקור את תזרימי העבודה המקצועיים החדשים הללו. Ray tracing נחשב בדרך כלל לזמין רק בכרטיסי גרפיקה יקרים, אך הסדרה האחרונה של כרטיסי הגרפיקה המקצועיים של Intel מבטלת את הציפייה הזו."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "מעצימים את היוצרים עם תצוגות סוחפות",
                    "tr_specText": "עם תמיכה מלאה ב-DirectX* 12 Ultimate בכל גרפיקת Intel® Arc™‎, תיהנו מחוויית משחק מדהימה וסוחפת הודות לטכנולוגיות הגרפיות העדכניות ביותר כמו Ray tracing, הצללה בקצב משתנה, הצללת רשת ומשוב מדגם — היסודות של גיימינג מהדור הבא."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "תמונות באופטימיזציה דינמית",
                    "tr_specText": "עם פענוח החומרה הייעודי במעבדים גרפיים Pro GPU‏, Dolby Vision*‎ עוזר להוסיף עומק רב יותר, ניגודיות חדה יותר ויותר צבעים לצג תואם. Dolby Vision*‎ ממצה את מלוא הפוטנציאל של טכנולוגיית HDR באמצעות אופטימיזציה דינמית של איכות התמונה בהתבסס על השירות, התקן התצוגה והפלטפורמה כדי לספק תמונות מדהימות בכל פעם."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "תוכנה שאושרה על ידי מומחים",
                    "tr_specText": "Intel שיתפה פעולה עם מאות חברות תוכנה לאורך השנים, והניסיון האדיר הזה נכנס לכרטיסים הגרפיים המקצועיים מסדרת Intel® Arc™ Pro A. אישורים והסמכות של תזרימי עבודה בתוכנה חשובים לנו כמו שהם חשובים לכם."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "Ray tracing ללא גרסת הפרימיום",
                    "tr_specText": "כרטיסים גרפיים צריכים לעשות יותר מסתם לדחוף פיקסלים לצגים, הם צריכים להאיץ את היצירה של תמונות יפות. טכנולוגיית חומרה של התוויית קרניים המובנית בגרפיקה מסדרת Intel® Arc™ Pro A מאפשרת לתוכנה תואמת להתחיל לחקור את תזרימי העבודה המקצועיים החדשים הללו. Ray tracing נחשב בדרך כלל לזמין רק בכרטיסי גרפיקה יקרים, אך הסדרה האחרונה של כרטיסי הגרפיקה המקצועיים של Intel מבטלת את הציפייה הזו."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "מספר צגים גדולים עם מעבד גרפי נייד אחד קטן",
                    "tr_specText": "גרפיקת Intel® Arc™ Pro למחשבים ניידים יכולה לתמוך בעד ארבעה צגים גדולים במיוחד כדי לשפר את יעילות תזרימי העבודה. אם תוסיפו גם תמיכה בטווח דינמי גבוה (HDR) יהיה לכם כל מה שצריך למערכת של מספר צגים בבית או במשרד ששווה להעלות לרשתות החברתיות."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "פוטנציאל לזיכרון ברוחב פס מלא",
                    "tr_specText": "רוחב הפס של הכרטיס הגרפי הוא שיקול חשוב בתחנת עבודה. אם טועים זה עלול להאט את תזרימי העבודה המקצועיים, וזו הסיבה שדאגנו שלמעבד הגרפי מסדרת Intel® Arc™ Pro A תהיה תמיכה ב-PCIe*‎ 4.0 מודרני, מבלי לפגוע בתאימות לאחור. שיפור נוסף בזיכרון הגרפי מגיע ממהירות רוחב פס גבוהה, דבר המאפשר לתוכנות שלכם לגשת לנתוני הפרויקט אפילו מהר יותר."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "מפיחים חיים בכל פריים",
                    "tr_specText": "<span class='expandable_heading'>עם חיזוק AI. בגיבוי Intel.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS היא טכנולוגיית שדרוג באמצעות בינה מלאכותית שמשפרת את הביצועים מבלי לוותר על היופי של כל פריים — כדי שתוכלו לחוות את המשחק שלכם כפי שהוא אמור להיות."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "בזמן אמת.<br/> סוחף באמת.<br/> ריאליזם במיטבו.",
                    "tr_specText": "עולמות מציאותיים יותר מחכים לכם. עם התוויית קרניים מובנית, תוכלו לחוות הכל, החל מצללים והשתקפויות כמו בחיים ועד תאורה מופתית. במילים אחרות, זוהי חווית גיימינג ויזואלית שלא תרצו לפספס."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "מהלכי המשחק הטובים ביותר שלכם משותפים במיטבם",
                    "tr_specText": "תנו לצופים שלכם את המשחק החלק שמגיע להם לראות. עם יכולות קידוד AV1 מואץ בחומרה של גרפיקת Intel® Arc™‎, הם יהיו מרותקים לכל צעד. איכות חזותית גבוהה יותר באותו קצב סיביות משמעותה סטרימינג מיטבי תוך חיסכון ברוחב פס."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "ברוכים הבאים כל היוצרים",
                    "tr_specText": "חוויות AI חדשות ומשופרות זמינות ממש בהישג יד. עם מאיצי AI מתקדמים המוטמעים בגרפיקה מסדרת Intel® Arc™ A, תוכלו להקדיש יותר זמן ליצירה ופחות זמן להמתנה באפליקציות רבות.<br/><br/>קדמו את יצירת הווידיאו שלכם לשלב הבא והובילו את הדרך עם וידיאו AV1 בעל איכות גבוהה וחסכוני במקום אחסון, המופעל באמצעות יכולות החומרה המתקדמות של AV1 המובנות בגרפיקת Intel® Arc™‎."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מערכת הפעלה",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "כל מה שצריך כדי לבצע את העבודה.",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "ביצועים עוצמתיים, דקיקות, קלילות ועמידות - אפשר לקבל הכל. עם חיי סוללה נהדרים, מסכי מגע* יפהפיים וכונני ה-SSD הכי עדכניים, תוכלו למצוא את כל מה שאתם צריכים במחשב Windows* 10.",
                "tr_noteSection": "*תלוי חומרה",
                "titleArray": [
                  {
                    "tr_title": "פחות זה יותר",
                    "tr_subtitle": "המחשבים הניידים של ימינו צורכים בדרך כלל פחות חשמל, כך שתוכלו להפיק יותר חיים מהסוללה שלכם - בממוצע, יותר משמונה שעות של צפייה בווידאו**.",
                    "tr_noteSection": "**חיי הסוללה משתנים באופן משמעותי בשל ההגדרות, השימוש וגורמים אחרים."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מערכת הפעלה",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "כל מה שצריך כדי לבצע את העבודה.",
                "tr_title": "Windows* 10",
                "tr_subtitle": "ביצועים עוצמתיים, דקיקות, קלילות ועמידות - אפשר לקבל הכל. עם חיי סוללה נהדרים, מסכי מגע* יפהפיים וכונני ה-SSD הכי עדכניים, תוכלו למצוא את כל מה שאתם צריכים במחשב Windows* 10.",
                "tr_noteSection": "*תלוי חומרה",
                "titleArray": [
                  {
                    "tr_title": "פחות זה יותר",
                    "tr_subtitle": "המחשבים הניידים של ימינו צורכים בדרך כלל פחות חשמל, כך שתוכלו להפיק יותר חיים מהסוללה שלכם - בממוצע, יותר משמונה שעות של צפייה בווידאו**.",
                    "tr_noteSection": "**חיי הסוללה משתנים באופן משמעותי בשל ההגדרות, השימוש וגורמים אחרים."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מערכת הפעלה",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "בנוי לאבטחה ולביצועים&nbsp;גבוהים",
                "tr_pretitle": "Windows* 10 במצב S",
                "tr_subtitle": "הפעלת Windows* 10 במצב S מספקת שכבת אבטחה נוספת שמאפשרת לך לעבוד, לשחק ולגלוש באינטרנט בידיעה שהמכשיר שלך מוגן."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מערכת הפעלה",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "בנוי לאבטחה ולביצועים&nbsp;גבוהים",
                "tr_title": "Windows* 10 במצב S",
                "tr_subtitle": "הפעלת Windows* 10 במצב S מספקת שכבת אבטחה נוספת שמאפשרת לך לעבוד, לשחק ולגלוש באינטרנט בידיעה שהמכשיר שלך מוגן."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מערכת הפעלה",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "לעשות דברים נהדרים",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "ביצועים גבוהים, מחיר ומבחר גדול. לא משנה מה גובה התקציב או מה הסגנון שלכם, תמיד תוכלו למצוא מחשב Windows* שמתאים לכם מתוך המבחר הגדול הכולל מחשבי 2 ב-1 חדשניים, מחשבים ניידים דקים וקלים, מסכי מגע ומקלדות מתנתקות."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מערכת הפעלה",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "לעשות דברים נהדרים",
                "tr_title": "Windows*",
                "tr_subtitle": "ביצועים גבוהים, מחיר ומבחר גדול. לא משנה מה גובה התקציב או מה הסגנון שלכם, תמיד תוכלו למצוא מחשב Windows* שמתאים לכם מתוך המבחר הגדול הכולל מחשבי 2 ב-1 חדשניים, מחשבים ניידים דקים וקלים, מסכי מגע ומקלדות מתנתקות."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מערכת הפעלה",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "Linux*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "מערכת הפעלה",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "זיכרון",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "כמה זיכרון RAM אנו צריכים?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "רגיל - ‎2 GB עד ‎8 GB של RAM",
                    "tr_badgeSubTitle": "אידיאלי לאנשים שמשתמשים במחשב בעיקר לעיבוד תמלילים וגלישה באינטרנט, ובדרך כלל משתמשים בתוכנה אחת בכל פעם."
                  },
                  {
                    "tr_badgeTitle": "עוצמתי - ‎8 GB עד ‎16 GB של RAM",
                    "tr_badgeSubTitle": "עם יותר זיכרון RAM, היישומים שלכם יפעלו מהר יותר ותוכלו גם להפעיל יותר יישומים בו-זמנית."
                  },
                  {
                    "tr_badgeTitle": "מתקדם - יותר מ-‎16 GB של RAM",
                    "tr_badgeSubTitle": "אם אתם מתעניינים במשחקים, עריכת תמונות או וידאו, או מידול בתלת-ממד, תזדקקו ליותר זיכרון RAM כדי להריץ תוכנות תובעניות כאלה."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "זיכרון",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "RAM בגודל ‎[XX] GB לריבוי משימות ברמה יומיומית",
                "tr_subtitle": "הזיכרון יאפשר טעינה והפעלה חלקה של יישומים בסיסיים, כגון עיבוד תמלילים וגלישה באינטרנט.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>זיכרון RAM בנפח<br/></span><span class='value'>‎[XX]&nbsp;GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "זיכרון",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "RAM בגודל ‎[XX] GB לריבוי משימות רב עוצמה",
                "tr_subtitle": "כאשר מפעילים במקביל מספר תוכנות, נפח זיכרון של GB ‏[memory_total] מאפשר לעבור ביניהן בקלות.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>זיכרון RAM בנפח<br/></span><span class='value'>‎[XX]&nbsp;GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "זיכרון",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "RAM בגודל ‎[XX] GB לריבוי משימות מתקדם",
                "tr_subtitle": "יעמוד לרשותך שפע זיכרון על מנת להריץ בו-זמנית מספר תוכנות תובעניות, מבלי להאט את המחשב.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>זיכרון RAM בנפח<br/></span><span class='value'>‎[XX]&nbsp;GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "זיכרון",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "זיכרון&nbsp;GB‏ [Value1] = זיכרון&nbsp;Intel®&nbsp;Optane™‎ ‏GB‏ [Value2]  + זיכרון&nbsp;RAM GB‏&nbsp;[Value3]",
                "tr_subtitle": "שילוב זה של פתרונות זיכרון מאיץ את הביצועים של הכונן הקשיח בעזרת זיכרון Intel® Optane™‎ ובנוסף מאפשר ל-RAM להגיב מהר יותר.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>זיכרון בנפח <br/></span>‎<span class='value'>[XX]&nbsp;GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "זיכרון Intel® Optane™‎",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "פחות המתנה. יותר ממה שאתם אוהבים.",
                "tr_subtitle": "כאשר זיכרון ™Intel® Optane‎ עובד יחד עם הזיכרון של המערכת שלכם, אתם מקבלים גישה מהירה יותר לתוכנות שבשימוש תכוף כדי לאפשר פתיחה וטעינה מידיות בעת הצורך.",
                "tr_pretitle": "זיכרון Intel® Optane™‎ של ‎[XX]&nbsp;GB",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "מה תורם זיכרון למחשב?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "יצירת תוכן מהירה יותר",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "משחק עם תגובות טובות יותר",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "ריבוי משימות קל יותר",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "פתיחת תוכנות מהירה יותר",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "זיכרון Intel® Optane™‎",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>האץ את המשחקים שלך</span>",
                "tr_pretitle": "זיכרון Intel® Optane™‎ של ‎[XX]&nbsp;GB",
                "tr_pretitle1": "זיכרון Intel®‎ Optane™‎ בנפח ‎[XX]&nbsp;GB מאיץ את הגיימינג",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "הפעלת משחקים עד<br/><span class=\"optane-game\">98% מהר יותר</span><br/>מכונן הנתונים עם מעבד [optane_legal_processor_name] עם זיכרון Intel® Optane™‎‏<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "טעינת שלבי משחק<br/><span class=\"optane-game\">עד פי 4.1 מהר יותר</span><br/>מכונן הנתונים עם מעבד [optane_legal_processor_name] עם זיכרון Intel® Optane™‎‏<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "זיכרון Intel® Optane™‎",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>האץ את המשחקים שלך</span>",
                "tr_pretitle": "זיכרון Intel® Optane™‎ של ‎[XX]&nbsp;GB",
                "tr_pretitle1": "זיכרון Intel®‎ Optane™‎ בנפח ‎[XX]&nbsp;GB מאיץ את הגיימינג",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "הפעלת משחקים עד<br/><span class=\"optane-game\">98% מהר יותר</span><br/>מכונן הנתונים עם מעבד [optane_legal_processor_name] עם זיכרון Intel® Optane™‎‏<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "טעינת שלבי משחק<br/><span class=\"optane-game\">עד פי 4.1 מהר יותר</span><br/>מכונן הנתונים עם מעבד [optane_legal_processor_name] עם זיכרון Intel® Optane™‎‏<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "זיכרון",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "זיכרון&nbsp;GB‏ [Value1] = זיכרון&nbsp;Intel®&nbsp;Optane™‎ ‏GB‏ [Value2]  + זיכרון&nbsp;RAM GB‏&nbsp;[Value3]",
                "tr_subtitle": "שילוב זה של פתרונות זיכרון מאיץ את הביצועים של הכונן הקשיח בעזרת זיכרון Intel® Optane™‎ ובנוסף מאפשר ל-RAM להגיב מהר יותר.",
                "titleArray": [
                  {
                    "tr_title": "‎[XX] GB של RAM לריבוי משימות",
                    "tr_subtitle": "כאשר מפעילים במקביל מספר תוכנות, נפח זיכרון של GB ‏[memory_total] מאפשר לעבור ביניהן בקלות."
                  },
                  {
                    "tr_title": "למשימות יומיומיות",
                    "tr_subtitle": "הזיכרון יאפשר טעינה והפעלה חלקה של יישומים בסיסיים, כגון עיבוד תמלילים וגלישה באינטרנט."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "זיכרון",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "‎[XX] GB של&nbsp;RAM",
                "tr_pretitle": "לריבוי משימות",
                "tr_subtitle": "זיכרון RAM מאפשר למעבד לגשת לקובצי תוכנות מהר יותר ולבצע מספר משימות במקביל.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "זיכרון סטנדרטי",
                    "tr_badgeSubTitle": "עד ‎8&nbsp;GB זיכרון RAM"
                  },
                  {
                    "tr_badgeTitle": "זיכרון מתקדם",
                    "tr_badgeSubTitle": "זיכרון מתקדם יותר מ- ‎8 GB  ועד ‎16 GB של ‏RAM"
                  },
                  {
                    "tr_badgeTitle": "זיכרון עילית",
                    "tr_badgeSubTitle": "יותר מ-‎16 GB זיכרון RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "זיכרון",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX]GB עבור ריבוי מספר גדול יותר של משימות",
                "tr_pretitle": "לריבוי משימות",
                "tr_subtitle": "זיכרון RAM מאפשר למעבד לגשת לקובצי תוכנות מהר יותר ולבצע מספר משימות במקביל.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "זיכרון סטנדרטי",
                    "tr_badgeSubTitle": "עד ‎8&nbsp;GB זיכרון RAM"
                  },
                  {
                    "tr_badgeTitle": "זיכרון מתקדם",
                    "tr_badgeSubTitle": "זיכרון מתקדם יותר מ- ‎8 GB  ועד ‎16 GB של ‏RAM"
                  },
                  {
                    "tr_badgeTitle": "זיכרון עילית",
                    "tr_badgeSubTitle": "יותר מ-‎16 GB זיכרון RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "השוואה של זיכרון זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "סטנדרטי",
                      "tr_badgeSubTitle": "<span class='memory-value'>8GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "מתקדם",
                      "tr_badgeSubTitle": "<span class='memory-value'>16GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "עילית",
                      "tr_badgeSubTitle": "<span class='memory-value'>32GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "זיכרון",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "השוואה של זיכרון זה.",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "השוואה של זיכרון זה.",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "מכשיר נוכחי",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "יומיומי",
                      "tr_badgeSubTitle": "<span class='memory-value'>8GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "עוצמתי",
                      "tr_badgeSubTitle": "<span class='memory-value'>16GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "מתקדם",
                      "tr_badgeSubTitle": "<span class='memory-value'>32GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "אחסון",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "כמה מידע אפשר לאחסן במחשב שלך?",
                "tr_subtitle": "אין צורך לנחש. אנו נראה לך בדיוק מה ניתן לאחסן במחשב זה.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>יצירת ספרייה של</span> <br/><span class=\"storage-standard\">[num_songs] שירים<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>מבוסס על ממוצע של ‎5 MB לשיר",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>יצירת ספרייה של</span><br/><span class=\"storage-standard\">[num_games] משחקים<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>מבוסס על ממוצע של ‎40 GB למשחק",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>אחסון עבור</span><br/><span class=\"storage-standard\">[num_videos] סרטונים<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>מבוסס על ממוצע של ‎5 GB לסרטון",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>צילום ואחסון של</span><br/><span class=\"storage-standard\">[num_photos] תמונות<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>מבוסס על ממוצע של ‎4 MB לתמונה",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "‎[capacity] GB HDD"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "‎[capacity] GB SSD"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "‎[total_optane_memory] GB של זיכרון Intel® Optane™‎ + ‏‎[convertedSSD] GB ‏SSD"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "אחסון",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "אחסון כונן שבבי (SSD) בנפח [capacity]GB",
                "tr_preTitle1": "אחסון כונן שבבי (SSD) בנפח [capacity]TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "מחשב זה כולל כונן שבבי (SSD) עם קיבולת אחסון בנפח [capacity]GB. הוא מאפשר לשמור מספר עצום של תמונות, סרטונים ותוכנות, ולגשת אליהם מהר יותר מאשר בכונן קשיח.",
                    "tr_title": "המחשב יוכל לאחסן את כל מה שתרצו"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "מחשב זה כולל כונן שבבי (SSD) עם קיבולת אחסון בנפח [capacity]TB. הוא מאפשר לשמור מספר עצום של תמונות, סרטונים ותוכנות, ולגשת אליהם מהר יותר מאשר בכונן קשיח.",
                    "tr_title": "למכשיר הזה יש נפח אחסון מרשים"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]GB<br/></span><span class='subtitle-value'>של אחסון</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity]TB<br/></span><span class='subtitle-value'>של אחסון</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "אחסון",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "אחסון HDD בנפח [capacity]GB",
                "tr_preTitle1": "אחסון HDD בנפח [capacity]TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "כוננים קשיחים הם בחירה טובה למי שרוצה כמות גדולה של אחסון ישירות בתוך המחשב.",
                    "tr_title": "המחשב יוכל לאחסן את כל מה שתרצו"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "כוננים קשיחים הם בחירה טובה למי שרוצה כמות גדולה של אחסון ישירות בתוך המחשב.",
                    "tr_title": "למכשיר הזה יש נפח אחסון מרשים"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>אחסון בנפח</span>‎<span class='value'>[capacity]<br/></span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "כונן קשיח",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "אחסון",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "כמה מידע אפשר לאחסן במחשב שלך?",
                "tr_subtitle": "אין צורך לנחש. אנו נראה לך בדיוק מה ניתן לאחסן במחשב זה.",
                "titleArray": [
                  {
                    "tr_title": "דיסק קשיח ‎[XX]&nbsp;GB"
                  },
                  {
                    "tr_title": "‎[XX]&nbsp;GB SSD"
                  },
                  {
                    "tr_title": "Intel® SSD בנפח ‎[total_capacity] GB מואץ באמצעות זיכרון ™Intel® Optane בנפח ‎[optane_memory_size] GB"
                  },
                  {
                    "tr_title": "Intel® SSD בנפח ‎[total_capacity] TB מואץ באמצעות זיכרון ™Intel® Optane בנפח ‎[optane_memory_size] GB"
                  },
                  {
                    "tr_title": "‎[convertedSSD]&nbsp;GB&nbsp;SSD + [convertedHDD]&nbsp;GB&nbsp;HDD"
                  },
                  {
                    "tr_title": "‎[convertedSSD]&nbsp;TB&nbsp;SSD + [convertedHDD]&nbsp;GB&nbsp;HDD"
                  },
                  {
                    "tr_title": "‎[convertedSSD]&nbsp;GB&nbsp;SSD + [convertedHDD]&nbsp;TB&nbsp;HDD"
                  },
                  {
                    "tr_title": "‎[convertedSSD]&nbsp;TB&nbsp;SSD + [convertedHDD]&nbsp;TB&nbsp;HDD"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]GB<br/></span><span class='subtitle-value'>של אחסון</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]GB<br/></span><span class='subtitle-value'>של אחסון</span>",
                        "tr_badgeSubTitle": "כונן קשיח"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]GB<br/></span><span class='subtitle-value'>של אחסון</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> + </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]TB<br/></span><span class='subtitle-value'>של אחסון</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]TB<br/></span><span class='subtitle-value'>של אחסון</span>",
                        "tr_badgeSubTitle": "כונן קשיח"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]TB<br/></span><span class='subtitle-value'>של אחסון</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> + </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "אחסון",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "אחסון כונן שבבי (SSD) בנפח [convertedSSD]GB + ‏HDD בנפח [convertedHDD]GB",
                "tr_preTitle1": "אחסון כונן שבבי (SSD) בנפח [convertedSSD]TB + ‏HDD בנפח [convertedHDD]GB",
                "tr_preTitle2": "אחסון כונן שבבי (SSD) בנפח [convertedSSD]GB + ‏HDD בנפח [convertedHDD]TB",
                "tr_preTitle3": "אחסון כונן שבבי (SSD) בנפח [convertedSSD]TB + ‏HDD בנפח [convertedHDD]TB",
                "titleArray": [
                  {
                    "tr_title": "המחשב יוכל לאחסן את כל מה שתרצו",
                    "tr_title1": "למכשיר הזה יש נפח אחסון מרשים",
                    "tr_subtitle": "מחשב זה כולל כונן שבבי עם אחסון של ‎[convertedSSD]GB וכונן קשיח של ‎[convertedHDD]GB. תכונות אלו מעניקות קיבולת עצומה וגם ביצועי אחסון מהירים.",
                    "tr_subtitle1": "מחשב זה כולל כונן שבבי עם אחסון של ‎[convertedSSD]TB וכונן קשיח של ‎[convertedHDD]GB‏. תכונות אלו מעניקות קיבולת עצומה וגם ביצועי אחסון מהירים.",
                    "tr_subtitle2": "מחשב זה כולל כונן שבבי עם אחסון של ‎[convertedSSD]GB וכונן קשיח של ‎[convertedHDD]TB‏. תכונות אלו מעניקות קיבולת עצומה וגם ביצועי אחסון מהירים.",
                    "tr_subtitle3": "מחשב זה כולל כונן שבבי עם אחסון של ‎[convertedSSD]TB וכונן קשיח של ‎[convertedHDD]TB. תכונות אלו מעניקות קיבולת עצומה וגם ביצועי אחסון מהירים."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]GB<br/></span><span class='subtitle-value'>של אחסון</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity]TB<br/></span><span class='subtitle-value'>של אחסון</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> + </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "אחסון",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "קבלו גישה מואצת לאחסון עתיר קיבולת באמצעות זיכרון Intel®&nbsp;Optane™‎ עם SSD‏.",
                "tr_title": "קבצים ענקיים יתקבלו בברכה",
                "tr_pretitle": "Intel® SSD בנפח ‎[total_capacity] GB מואץ באמצעות זיכרון ™Intel® Optane בנפח ‎[optane_memory_size] GB",
                "tr_pretitle1": "Intel® SSD בנפח ‎[total_capacity] TB מואץ באמצעות זיכרון ™Intel® Optane בנפח ‎[optane_memory_size] GB",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "פתרון אחסון מהפכני זה הוא הראשון שמשלב זיכרון Intel® Optane™‎ וכונן SSD על שבב אחד. הנה היתרונות עבורך:",
                    "tr_title": "מהו זיכרון INTEL® OPTANE™‎ עם SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "פתיחה מהירה של מה שדרוש לך",
                    "tr_badgeSubTitle": "על ידי טעינה מראש של קבצים ותוכנות שבשימוש תכוף, ניתן להקדיש יותר זמן ליצירה ולבלות פחות זמן בהמתנה.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "אחסון פלוס מהירות",
                    "tr_badgeSubTitle": "המקום שדרוש לך לקבצי המדיה שלך, עם הביצועים המהירים של SSD.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "תגובתיות טובה יותר לאורך זמן",
                    "tr_badgeSubTitle": "זיכרון Intel® Optane™‎ עם SSD לומד את דרך העבודה שלך ומשפר את יעילות המחשב.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "אחסון",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "אחסנו את כל המדיה שלכם בהישג יד הודות לשפע מקום אחסון אמין.",
                "tr_title": "מספיק מקום לכל התמונות, הסרטונים והשירים שלכם"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "צג",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] מיליון פיקסלים להריץ, להחליק ולהמם",
                "tr_subtitle": "הפעילו את המחשב האישי שלכם בדיוק כמו טלפון חכם עם פקדים אינטואיטיביים ופרטים חדים וטבעיים יותר.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>”[screenDiagonal]<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "רזולוציה: &lrm;[screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "פיקסלים: [screenResolution] מיליון"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "מסך [screenDiagonal] אינץ'",
                    "tr_badgeSubTitle": "גובה המסך: [screenHeight] אינץ'",
                    "tr_badgePreTitle": "רוחב המסך: [YY] אינץ'"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "מסך מגע",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "צג",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "בואו לשקוע ב-[screenResolution] מיליוני פיקסלים",
                "tr_subtitle": "הפיקסלים הם שקובעים. ככל שיהיו לך יותר, כך התמונה תהיה חדה וחיה יותר.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>”[screenDiagonal]<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "רזולוציה: &lrm;[screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "פיקסלים: [screenResolution] מיליון"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "מסך [screenDiagonal] אינץ'",
                    "tr_badgeSubTitle": "גובה המסך: [screenHeight] אינץ'",
                    "tr_badgePreTitle": "רוחב המסך: [YY] אינץ'"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "צג",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] אינץ' <br/>&lrm;[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "החלון לעולמות המשחק שלך",
                "tr_subtitle": "בדיוק כמו טלוויזיה, התכונות העיקריות הנדרשות מצג למשחקים הן גודל ורזולוציה.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "רזולוציה: &lrm;[screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "פיקסלים: [screenResolution] מיליון"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "מסך [screenDiagonal] אינץ'",
                    "tr_badgeSubTitle": "גובה המסך: [screenHeight] אינץ'",
                    "tr_badgePreTitle": "רוחב המסך: [screenWidth] אינץ'"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "מסך מגע"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "צג",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "למכשיר הזה צג מרשים",
                "tr_subtitle": "עם יותר מפי שמונה פיקסלים מאשר HD, הסרטונים והמשחקים מוצגים ברמה חדשה לגמרי של חדות וצבעים.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "רזולוציה: &lrm;[screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "פיקסלים: [screenResolution] מיליון"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "מסך [screenDiagonal] אינץ'",
                    "tr_badgeSubTitle": "גובה המסך: [screenHeight] אינץ'",
                    "tr_badgePreTitle": "רוחב המסך: [YY] אינץ'"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "צג",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] מיליון פיקסלים שאפשר להקיש, להחליק ולגעת בהם",
                    "tr_subTitle": "לצפות ולתקשר עם הדברים האהובים עליך, בפירוט חד וחי יותר."
                  },
                  {
                    "tr_title": "[screenResolution] מיליון פיקסלים כדי לשפר את העולם הדיגיטלי שלכם",
                    "tr_subTitle": "הפיקסלים הם שקובעים. ככל שיהיו לך יותר, כך התמונה תהיה חדה וחיה יותר."
                  },
                  {
                    "tr_title": "למכשיר הזה צג מרשים",
                    "tr_subTitle": "עם כמעט פי ארבעה יותר פיקסלים מאשר HD, הסרטונים והמשחקים מוצגים ברמה חדשה לגמרי של חדות וצבעים."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "קישוריות",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "טכנולוגיית Thunderbolt™ 3 מעניקה את החיבור המהיר והרב תכליתי ביותר לכל עמדת עגינה, מסך או התקן נתונים.",
                "tr_title": "שקע אחד שעושה הכל",
                "tr_title1": "שקע אחד שעושה הכל",
                "titleArray": [
                  {
                    "tr_title": "כיצד אוכל להשתמש בטכנולוגיית THUNDERBOLT™&nbsp;3?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "טכנולוגיית Thunderbolt™ 3"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "צגי 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "מתחברים לצגים עם רזולוציה, ניגודיות וצבעים מהממים"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "כרטיס גרפי חיצוני",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "שדרוג מיידי ליכולות הגרפיות של כל מחשב"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "עבודה מהירה ברשת",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "מספק חיבורים מהירים בין משתמשים"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "קישוריות",
                "detailBGUrl": "",
                "tr_subtitle": "טכנולוגיית Thunderbolt™ 3 מעבירה נתונים במהירות גבוהה יותר מאשר חיבורי USB רגילים, ומאפשרת להעביר קבצים במהירות ולשלב בקלות ציוד היקפי, כגון מסכים מרובים או כרטיסים גרפיים חיצוניים.",
                "tr_title": "טכנולוגיית Thunderbolt™ 3",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "העברת נתונים מהירה כברק",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "‎480 Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "‎5 Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "טכנולוגיית Thunderbolt™ 3",
                    "tr_badgeSubTitle": "‎40 Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "קישוריות",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>שקע וכבל אוניברסלי אחד תומך בכל האביזרים האהובים עליכם</li> <li>הטענה אמינה של מחשב נייד</li> <li>תהליך סרטיפיקציה יציב מאפשר ראש שקט</li></ul>",
                "tr_title": "קישוריות כבל אוניברסלית באמת",
                "tr_title1": "קישוריות כבל אוניברסלית באמת",
                "tr_noteSection": "<sup>†</sup>מבוסס על דרישות מפרט מינימליות",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "כיצד אוכל להשתמש בטכנולוגיית THUNDERBOLT™&nbsp;4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "טכנולוגיית Thunderbolt™ 4"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "יצירה",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "חברו צג חיצוני ואחסון מהיר כדי לצפות ולערוך תמונות וסרטונים."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "עגינה",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "צרו שולחן עבודה פשוט ונקי מבלגן על ידי חיבור המחשב הנייד לכל האביזרים שלכם עם כבל יחיד."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "משחק",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "הוסיפו עוד כונני SSD ו-HDD עם מהירויות של עד 3,000MB בשנייה לאיחסון ספריות משחקים וקבצי מדיה."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "מקסימום ביצועים<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "מהיר פי 4 מאשר USB* 3.2 <br/>מהיר פי 2 מאשר HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "קישוריות",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "תהנו מאותם הביצועים המדהימים של טכנולוגיית Thunderbolt™ 3 עם יכולות רבות יותר שמאפשרות לכם להתחבר בקלות ולהשתמש בעמדות העגינה, המסכים, האחסון המהיר או כל אביזר *USB עם חיבור Thunderbolt™‎.",
                "tr_title": "טכנולוגיית Thunderbolt™&nbsp;4",
                "tr_pretitle": "קישוריות כבל אוניברסלית באמת",
                "tr_noteSection": "<sup>†</sup>מבוסס על דרישות מפרט מינימליות",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "כבל Thunderbolt™ 4 אוניברסלי אחד יכול להחליף את כל כבלי ה-*USB-C שלכם כדי להפוך את חיבור הכבלים למהיר וקל."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "מעגני טכנולוגיית Thunderbolt™‎ מציעים עד ארבעה שקעים של Thunderbolt™‎ כדי לאפשר גמישות רבה יותר לחיבור כל האביזרים."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "מקסימום ביצועים<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "מהיר פי 4 מאשר USB* 3.2 <br/>מהיר פי 2 מאשר HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "קישוריות",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>שקע וכבל אוניברסלי אחד תומך בכל האביזרים האהובים עליכם</li> <li>הטענה אמינה של מחשב נייד</li> <li>תהליך סרטיפיקציה יציב מאפשר ראש שקט</li></ul>",
                "tr_title": "החוויה המהנה ביותר הודות לאביזרי Thunderbolt™‎",
                "tr_noteSection": "<sup>†</sup>מבוסס על דרישות מפרט מינימליות",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "כיצד אוכל להשתמש בטכנולוגיית THUNDERBOLT™&nbsp;4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "יצירה",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "חברו צג חיצוני ואחסון מהיר כדי לצפות ולערוך תמונות וסרטונים."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "עגינה",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "צרו שולחן עבודה פשוט ונקי מבלגן על ידי חיבור המחשב הנייד לכל האביזרים שלכם עם כבל יחיד."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "משחק",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "הוסיפו עוד כונני SSD ו-HDD עם מהירויות של עד 3,000MB בשנייה לאיחסון ספריות משחקים וקבצי מדיה."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "מקסימום ביצועים<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "מהיר פי 4 מאשר USB* 3.2 <br/>מהיר פי 2 מאשר HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "סוגי ה-Wi‑Fi הנפוצים ביותר הם:",
                "tr_title": "הבנת תקני Wi‑Fi",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 מאפשר מהירויות גבוהות יותר, גם כאשר משתמשים רבים משתמשים באותו האות.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "עם חידושי ה-Wi-Fi האחרונים של Intel, אפשר להגיע למהירויות גבוהות יותר מאשר ב-gigabit Wi-Fi, גם כאשר יש משתמשים רבים על אותו אות.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Wi‑Fi 6E, ההתקדמות הגדולה ביותר בתחום ה-Wi‑Fi מזה 20 שנה, פותח ערוצי מהירות גבוהה חדשים לשיפור הביצועים והאמינות ולהפחתת ההפרעות."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 מאפשר מהירויות גבוהות יותר, גם כאשר משתמשים רבים משתמשים באותו האות.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "כל היתרונות של Wi-Fi 6E בשילוב עם מנוע תעדוף Intel®‎ Killer™‎ שמעביר את רוחב הפס לאפליקציות ולאתרים שזקוקים לו ביותר."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "עם פחות תחרות בערוצים המהירים שלך, ניתן להנות מאמינות ומביצועים משופרים."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 מאפשר מהירויות גבוהות יותר, גם כאשר משתמשים רבים משתמשים באותו האות.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "עם פחות הפרעות מ-Wi-Fi 6 ועם הביצועים החכמים של מנוע התעדוף ה-Intel® Killer™‎, החוויה המקוונת שלך תהיה מהירה יותר ועם תגובות מהירות יותר.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "עם פחות תחרות בערוצים המהירים שלך, ניתן להנות מאמינות ומביצועים משופרים."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "מהירות Wi-Fi של מחשב גבוהה מ-5Gbps עם השהיה עקבית נמוכה במיוחד - שיתוף קבצים תוך שניות לעומת תוך דקות."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) היא הטכנולוגיה העדכנית ביותר המאפשרת אמינות גבוהה במיוחד, זמן המתנה נמוך במיוחד ומהירויות גבוהות במיוחד באמצעות אופטימיזציית רשת מבוססת בינה מלאכותית."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 מאפשר מהירויות גבוהות יותר, גם כאשר משתמשים רבים משתמשים באותו האות.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) מגיע למהירויות גדולות מ- 1Gbps עם אמינות וזמן המתנה נמוך בשימוש בערוץ 6GHz."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "יעילות, קיבולת ומהירות טובות יותר לעומת Wi-Fi 5 ברשתות עמוסות."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Wi‑Fi [XX] מאפשר חיבור רציף יותר, אינטרנט במהירויות גבוהות יותר<sup>†</sup> ואמינות משופרת.",
                "tr_title": "טווח משופר ומהירות גבוהה יותר",
                "tr_noteSection": "<sup>†</sup>קצב העברה ו/או הטווח האלחוטי בפועל משתנים.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "בעידן של ריבוי שחקנים ומשחק מקוון, חשוב יותר מאי פעם להישאר מחובר. בנוסף למהירות, תרצו גם אות אלחוטי שיכול לשמור על המהירויות האלו ממרחק.",
                "tr_title": "[XX]",
                "tr_pretitle": "החיבור הוא המפתח",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "התחברו באמצעות Intel® Wi‑Fi 6 (Gig+) לנתבי Wi‑Fi 6 עם תמיכה בערוץ ‎160 MHz כדי לאפשר חיבורים מהירים ואמינות רבה יותר עבור יותר משתמשים פעילים.",
                "tr_title": "מקבלים מהירויות גבוהות כמעט פי 3 עם Intel® <span class='no-warp'>Wi‑Fi 6‏ </span>(Gig+‎‏)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>מאשר עם AC Wi‑Fi רגיל. תכונות נבחרות זמינות רק עם מספרי SKU מסוימים. לפרטים, מומלץ להתייעץ עם היצרן. מידע נוסף בנוגע ל-Wi‑Fi אפשר למצוא בכתובת intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Wi-Fi 6 ברמה בסיסית עם שני תחומי תדרים תומך בביצועים משופרים לצורכי קישוריות נפוצים, כגון אימייל וגלישה באינטרנט ברשתות עמוסות.<sup>9</sup>",
                "tr_title": "Wi‑Fi לכולם",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "בעידן של ריבוי שחקנים ומשחק מקוון, חשוב יותר מאי פעם להישאר מחובר. בנוסף למהירות, תרצו גם אות אלחוטי שיכול לשמור על המהירויות האלו ממרחק.",
                "tr_title": "[XX]",
                "tr_pretitle": "החיבור הוא המפתח",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "בזכות ערוצי ה-Wi‑Fi החדשים האלה אין צורך לחלוק את רוחב הפס עם ההתקנים הישנים יותר בשכונה, וכך אפשר להזרים באופן חלק, לגלוש במהירות ולעבוד וללמוד מרחוק ללא הפרעות.",
                "tr_title": "חיבור ללא תחרות",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "[XX] מאפשר חיבור רציף יותר, אינטרנט במהירויות גבוהות יותר<sup>†</sup> ואמינות משופרת.",
                "tr_title": "טווח משופר ומהירות גבוהה יותר",
                "tr_noteSection": "<sup>†</sup>קצב העברה ו/או הטווח האלחוטי בפועל משתנים.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "בעידן של ריבוי שחקנים ומשחק מקוון, חשוב יותר מאי פעם להישאר מחובר. בנוסף למהירות, תרצו גם אות אלחוטי שיכול לשמור על המהירויות האלו ממרחק.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "החיבור הוא המפתח",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Wi‑Fi מובנה מהיר ביותר",
                "tr_subtitle": "להשתחרר מהכבלים ולהישאר מחובר לעולם המקוון שלך."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "גיימינג באינטרנט מקבל שדרוג רציני בזכות נתיבים יעודיים למהירות גבוהה עם פחות הפרעות ועם מנוע תעדוף Intel®‎ Killer™‎ שנותן עדיפות לגיימינג.",
                "tr_title": "המתחרים יישארו מאחור",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "גיימינג באינטרנט מקבל שדרוג רציני בזכות נתיבים יעודיים למהירות גבוהה עם פחות הפרעות ועם מנוע תעדוף Intel®‎ Killer™‎ שנותן עדיפות לגיימינג.",
                "tr_title": "[XX]",
                "tr_pretitle": "המתחרים יישארו מאחור",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "הגיימינג באינטרנט זוכה לשדרוג רציני הודות לנתיבים ייעודיים במהירות גבוהה שמפחיתים את ההפרעות.",
                "tr_title": "[XX]",
                "tr_pretitle": "המתחרים יישארו מאחור",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi-Fi מאיץ באופן אוטומטי את תעבורת הרשת עבור היישומים שהכי זקוקים לה. כך הסרטונים בסטרימינג חדים יותר וזקוקים לפחות זמן לאגירת נתונים.",
                "tr_title": "דרך מהירה וחכמה יותר להתחברות.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "בעידן של ריבוי שחקנים ומשחק מקוון, חשוב יותר מאי פעם להישאר מחובר. בנוסף למהירות, תרצו גם אות אלחוטי שיכול לשמור על המהירויות האלו ממרחק.",
                "tr_title": "[XX]",
                "tr_pretitle": "החיבור הוא המפתח",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Wi-Fi 5 מאפשר חיבור רציף יותר, אינטרנט במהירויות גבוהות יותר<sup>†</sup> ואמינות משופרת.",
                "tr_title": "טווח משופר ומהירות גבוהה יותר",
                "tr_noteSection": "<sup>†</sup>קצב העברה ו/או הטווח האלחוטי בפועל משתנים.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "בעידן של ריבוי שחקנים ומשחק מקוון, חשוב יותר מאי פעם להישאר מחובר. בנוסף למהירות, תרצו גם אות אלחוטי שיכול לשמור על המהירויות האלו ממרחק.",
                "tr_title": "[XX]",
                "tr_pretitle": "המתחרים יישארו מאחור",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "התכונות של Wi-Fi 7 יתבססו על דורות קודמים של ה-Wi-Fi וישפרו אותם. המשמעות היא גם מהירויות גבוהות יותר, וגם מהימנות ומהירויות תגובה משופרות באופן משמעותי, ליצירת חוויות סוחפות לצרכנים וטכנולוגיות עתידיות מתוחכמות.",
                "tr_title": "רשת מחשבים אישיים דמויית חיבור קווי",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "התכונות של Wi-Fi 7 יתבססו על דורות קודמים של ה-Wi-Fi וישפרו אותם. המשמעות היא גם מהירויות גבוהות יותר, וגם מהימנות ומהירויות תגובה משופרות באופן משמעותי, ליצירת חוויות סוחפות לצרכנים וטכנולוגיות עתידיות מתוחכמות.",
                "tr_title": "[XX]",
                "tr_pretitle": "יוצרים שינוי בחוויות המשתמש",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "מהירות, זמן המתנה ואמינות טובים יותר באמצעות אופטימיזציית רשת מבוססת בינה מלאכותית",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "מהירות, זמן המתנה ואמינות טובים יותר באמצעות אופטימיזציית רשת מבוססת בינה מלאכותית.",
                "tr_title": "[XX]",
                "tr_pretitle": "תגובתיות דמויית חיבור קווי",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "סוללה",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "כיצד מודדים זאת?",
                    "tr_badgeSubTitle": "רוב היצרנים מציינים את חיי הסוללה בשעות. החישוב שלהם לא מדויק ב-100%, אך הוא יכול לסייע למשתמשים להשוות את חיי הסוללה של מחשבים שונים."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "סוללה",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "למשחקים תוך כדי תנועה",
                "tr_subtitle": "עם עוצמת מחשבי הגיימינג הניידים של היום, אין שום סיבה להישאר כבולים לשולחן. עכשיו אפשר לקחת את המשחקים שלך לכל מקום.",
                "tr_title": "[Dynamic.battery.values.hours] שעות של חיי סוללה"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "סוללה",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "להתנתק מהחשמל ולהישאר מחוברים ליישומים, למשחקים ולמוזיקה למשך שעות.",
                "tr_title": "[XX] שעות של הזרמה לאחר טעינה אחת<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>ההערכה מסתמכת על הצהרת היצרן לגבי משך חיי הסוללה.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] שעות",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "סוללה",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "להתנתק מהחשמל ולהישאר מחוברים ליישומים, למשחקים ולמוזיקה למשך שעות.",
                "tr_title": "[Dynamic.battery.values.hours] שעות של שימוש תוך כדי תנועה, ללא טעינה<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>ההערכה מסתמכת על הצהרת היצרן לגבי משך חיי הסוללה.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] שעות",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "סוללה",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "להתנתק מהחשמל ולהישאר מחוברים ליישומים, למשחקים ולמוזיקה למשך שעות.",
                "tr_title": "[Dynamic.battery.values.hours] שעות של שימוש במשך כל היום לאחר טעינה אחת<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>ההערכה מסתמכת על הצהרת היצרן לגבי משך חיי הסוללה.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] שעות",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "סוללה",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "מחשב זה ממטב את חיי הסוללה כך שתספיק לזמן רב יותר ומספק לפחות 4 שעות של חיי סוללה בטעינה של 30 דקות.<sup>4</sup>",
                "tr_title": "שימוש ממושך יותר ללא חיבור לחשמל עם סוללה שמחזיקה מעמד [Dynamic.battery.values.hours] שעות<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>ההערכה מסתמכת על הצהרת היצרן לגבי משך חיי הסוללה.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] שעות",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "בית",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "מדוע מחשב זה",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "הצגת כל המפרטים",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "מרחוק",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "נייד",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "משפטי",
        "tr_paragraph_array": [
          "ייתכן שתוכנות ועומסי העבודה המשמשים לבדיקות הביצועים עברו מיטוב למיקרו-מעבדים של Intel®‎ בלבד. בדיקות ביצועים כגון SYSmark* ו-MobileMark* נמדדות באמצעות מערכות, פונקציות ורכיבי מחשב ספציפיים. שינוי בכל אחד מהגורמים האלה עשוי להוביל לשינוי בתוצאות. עליך להיוועץ במקורות מידע ומבחני ביצועים נוספים כדי להעריך בצורה מלאה כל רכישה אפשרית, כולל ביצועי המוצר כשהוא עובד בשילוב מוצרים נוספים. לקבלת מידע מקיף יותר על הביצועים ותוצאות מבחני הביצועים, בקר בכתובת www.intel.com/benchmarks‏.",
          "¹מוצרי Intel® Wi-Fi 6 (Gig+)‎ תומכים בערוצי ‎160 MHz אופציונליים, שמאפשרים להשיג באופן עקרוני את המהירויות הגבוהות ביותר האפשריות (‎2402 Mbps) עבור מוצרי 2x2 802.11ax PC Wi-Fi טיפוסיים. מוצרי Premium Intel® Wi-Fi 6 (Gig+)‎ מאפשרים בעיקרון להשיג מהירויות גבוהות פי 2 עד 4 בהשוואה למוצרי ‎2x2‏ (‎1201 Mbps) או ‎1x1‏ (‎600 Mbps) 802.11ax PC Wi-Fi, אשר תומכים רק בדרישת החובה של ערוצי ‎80 MHz‏.",
          "²ההמלצות נועדות למטרות מסירת מידע בלבד. בעת קבלת החלטה לגבי רכישה, התייעצו עם מקורות אחרים.",
          "³התכונות והיתרונות של טכנולוגיות Intel תלויות בתצורת המערכת ועשויות לדרוש הפעלת חומרה, תוכנה או שירות מתאימים. הביצועים משתנים בהתאם לתצורת המערכת. אף מערכת מחשב אינה חסינה באופן מוחלט מפרצות אבטחה. יש לפנות ליצרן המערכת או לספק המערכת, או לעיין במידע נוסף בכתובת intel.com.",
          "⁴למערכות עם מסכי FHD, בעת שימוש לגלישה אלחוטית באינטרנט. בעת כיבוי, מרמת כיבוי ברירת מחדל של OEM.",
          "⁵בהשוואה לטכנולוגיות אחרות של חיבורי PC I/O, לרבות eSATA, ‏USB ו-IEEE 1394* FireWire. הביצועים ישתנו בהתאם לחומרה ולתוכנה הספציפיות שברשותך. יש להשתמש במכשיר שמותאם לטכנולוגיית Thunderbolt™&lrm;.",
          "⁶מהיר כמעט פי 3: 802.11ax 2x2 160 MHz מאפשר קצבי נתונים מקסימליים תיאורטיים של עד ‎2402 Mbps. מהירות גבוהה בערך פי 3 מאשר המהירות של Wi‑Fi רגיל מסוג 802.11ac 2x2 80 MHz ‏(867 Mbps). הנתונים נלקחו מתיעוד המפרט האלחוטי הסטנדרטי של IEEE 802.11* ומחייבים שימוש בנתבי רשת אלחוטית 802.11ax שתצורתם מוגדרת באופן זהה.",
          "⁷הפחתת השהיה של 75%: מבוססת על נתוני הדמיה (79%) של אינטל עם 802.11ax עם ובלי OFDMA באמצעות 9 לקוחות. זמן ההשהיה הממוצע ללא OFDM הוא 36ms, עם OFDMA זמן ההשהיה הממוצע יורד ל-7.6ms. לשיפור השהיה נדרש שנתב 802.11ax (Wi-Fi 6) וכל הלקוחות יתמכו ב-OFDMA.",
          "⁸מבוסס על השוואת מקצבי נתונים מקסימליים תאורטיים במפרט 802.11, 2x2 160 MHz Wi‑Fi 6/6E (802.11ax) יכול לספק 2402 Mbps לעומת 2x2 80 MHz Wi‑Fi 5 (802.11ac) יכול לספק 867 Mbps ששווה למהירות גדולה פי 2.8.",
          "⁹‏Wi‑Fi 6 הטוב מסוגו: מוצרי Intel® Wi‑Fi 6 (Gig+)‎ תומכים בערוצי ‎160MHz אופציונליים, וכך מאפשרים להשיג בעיקרון את המהירויות הגבוהות ביותר האפשריות (‎2402Mbps) עבור מוצרי 2x2 802.11ax PC Wi‑Fi טיפוסיים. מוצרי Premium Intel® Wi‑Fi 6 (Gig+) מאפשרים באופן עקרוני מהירויות גבוהות פי 2-4 לעומת מוצרי ‎2x2 (1201 Mbps) or 1x1 (600 Mbps) 802.11ax PC Wi‑Fi, שתומכים בדרישות החובה של ערוצי ‎80 MHz בלבד.",
          "בחר מספרי SKU בלבד, הפונקציונליות משתנה בהתאם לעיצוב של יצרני הציוד המקוריים. לפרטים, מומלץ להתייעץ עם יצרן הציוד המקורי או עם הקמעונאי. מידע נוסף בנוגע ל-Wi‑Fi אפשר למצוא בכתובת intel.com/wifi6disclaimers.",
          "¹⁰טכנולוגיית Thunderbolt™ 4, במהירות 40Gbps, מעניקה את פתרון הכבל המהיר, הפשוט והאמין ביותר לכל עמדת עגינה, צג או התקן נתונים בהשוואה לטכנולוגיות אחרות של חיבורי PC I/O, לרבות eSATA*, USB* ו-IEEE 1394* FireWire.",
          "¹¹מחייב נתב שמבוסס על 802.11ax אשר תומך ב-OFDMA ולקוחות מרובים ברשת עם תמיכה ב-AX‏. תכונות OFDMA, שנתמכת על ידי לקוחות ונקודות גישה (AP) עם 802.11ax, תורמות לביצועים טובים יותר בסביבות דחוסות. ‎2 Gbps, בהסתמך על הנחות של כ-70% מקצבי הנתונים המקסימליים התיאורטיים של ‎802.11ax 160 MHz 2402 Mbps.המצויינים במפרט של IEEE 802.11*.",
          "¹²המדידה התבצעה על ידי פתיחת מסמך עם פעילות ברקע לצורך השוואה בין מעבד Intel® Core™ i7-8565U דור שמיני (‎512GB TLC SSD) לעומת מעבד Intel® Core™ i7-8565U דור שמיני (זיכרון ‎32GB+‎512GB Intel® Optane™‎ מסוג H10 עם כונן SSD).",
          "¹³‏Intel® Thread Director נוצר להשתלבות במעבדי Intel® Core™‎ דור 12 ועוזר למערכות ההפעלה לנתב בחוכמה את עומסי העבודה לליבה המתאימה. לא נדרשת פעולה מצד המשתמש. פרטים ניתן למצוא בכתובת intel.com.",
          "¹⁴לא זמין במעבדי Intel® Core™‎ דור 12 מסוימים. הארכיטקטורה ההיברידית העוצמתית משלבת שתי מיקרו-ארכיטקטורות ליבה חדשות, Performance&#8209;cores (P&#8209;cores) ו-Efficient&#8209;cores (E&#8209;cores), בפיסת סיליקון אחת של מעבד. חלק ממעבדי Intel® Core™‎ דור 12 (מעבדי Intel® Core™ i5 מסוימים מדור 12 ומטה) לא תומכים בארכיטקטורה ההיברידית העוצמתית, אלא רק ב-P&#8209;cores.",
          "¹⁵כפי שנמדד על ידי Game Launch Workload בהשוואה של מעבד Intel® Core™ i7‑8750H דור שמיני (מודול זיכרון 32GB Intel® Optane™‎‏ + Intel® SSD 256GB PCIe*‏ + 1TB HDD) למעבד Intel® Core™ i7-8750H דור שמיני (Intel® SSD 256GB PCIe*‎“‏ + 1TB HDD). Game&nbsp;Launch&nbsp;Workload&nbsp;&#8209;&nbsp;Workload פותח על ידי Intel למדידת הזמן שחולף לפתיחת Total War*: WARHAMMER* II Build: 5577.0 והגעה לתפריט הראשי כאשר סרטוני ההקדמה מושבתים (הפעלה). תצורות האצת כונן נתונים של זיכרון Intel® Optane™‎: מעבד Intel® Core™ i7‑8750H‏, PL1=45W TDP‏, 6C12T, טורבו עד 4GHz במערכת OEM של קדם ייצור, כרטיס מסך: NVIDIA* GeForce* GTX 1070, זיכרון: ‎2x4GB DDR4, אחסון: Intel® SSD Series 760p 256GB‏ (PCIe*)‏ + 1TB HDD‏ + זיכרון 32GB Intel® Optane™‎ , מערכת הפעלה: Windows* 10 RS3 Build 1709‏, MCU 0x84; מעבד Intel® Core™ i7-8750H‏, PL1=45W TDP‏, 6C12T, טורבו עד 4GHz במערכת OEM של קדם ייצור, כרטיס מסך: NVIDIA* GeForce* GTX 1070, זיכרון: ‎2x4GB DDR4, אחסון: Intel® SSD Series 760p 256GB‏ (PCIe*)‏ + 1TB HDD, מערכת הפעלה: Windows* 10 RS3 Build 1709‏, MCU 0x84.",
          "¹⁶כפי שנמדד על ידי Game Level Load Workload בהשוואה של מעבד Intel® Core™ i7‑8750H דור שמיני (זיכרון 32GB Intel® Optane™‎ למעבד Intel® Core™ i7-8750H דור שמיני (HDD בלבד). Game Level Load Workload‏ - Workload פותח על ידי Intel למדידת הזמן שחולף מהתפריט הראשי ועד להשלמה של טעינת רמה (Level Load) ב-Total War*: WARHAMMER* II Build: 5577.0. תצורות האצת כונן נתונים של זיכרון Intel® Optane™‎: מעבד Intel® Core™ i7‑8750H‏, PL1=45W TDP‏, 6C12T, טורבו עד 4GHz במערכת OEM של קדם ייצור, כרטיס מסך: NVIDIA* GeForce* GTX 1070, זיכרון: ‎2x4GB DDR4, אחסון: Intel® SSD Series 760p 256GB‏ (PCIe*)‏ + 1TB HDD‏ + זיכרון 32GB Intel® Optane™‎ , מערכת הפעלה: Windows* 10 RS3 Build 1709‏, MCU 0x84; מעבד Intel® Core™ i7-8750H‏, PL1=45W TDP‏, 6C12T, טורבו עד 4GHz במערכת OEM של קדם ייצור, כרטיס מסך: NVIDIA* GeForce* GTX 1070, זיכרון: ‎2x4GB DDR4, אחסון: Intel® SSD Series 760p 256GB‏ (PCIe*)‏ + 1TB HDD, מערכת הפעלה: Windows* 10 RS3 Build 1709‏, MCU 0x84.",
          "¹⁷ב-Performance-cores. הביצועים משתנים בהתאם לאופן השימוש, להגדרת התצורה ולגורמים אחרים. מידע נוסף ניתן למצוא בכתובת www.intel.com/PerformanceIndex.",
          "¹⁸שינוי תדירות השעון או המתח עלול לגרום נזק או לקצר את החיים השימושיים של המעבד ורכיבי מערכת אחרים, וכן עלול לפגוע ביציבות ובביצועים של המערכת. הפעלת המעבד מעבר למפרטים המוצהרים עלולה לבטל את האחריות על המוצר. מידע נוסף ניתן למצוא אצל יצרני המערכת והרכיבים.",
          "¹⁹כפי שנמדד על ידי השילוב חסר המתחרים של פלטפורמת Intel vPro®‎ של יכולות אבטחה מעל ומתחת למערכת ההפעלה, הגנות אפליקציות ונתונים והגנות מתקדמות מפני איומים עבור עסק בכל גודל, וכן גישת האבטחה תחילה של Intel לעיצוב, ייצור ותמיכה במוצר. כל המחשבים העסקיים שבנויים על פלטפורמת Intel vPro®‎ אושרו על פי מפרטים קפדניים, כולל תכונות אבטחה ייחודיות מבוססות חומרה. ראו www.intel.com/PerformanceIndex (פלטפורמות) לפרטים נוספים. אף מוצר או רכיב לא יכול להיות בטוח לגמרי.",
          "²⁰‏Intel® Connectivity Performance Suite זמינה במערכות מבוססות Windows*‎ בלבד. בדיקת Wi-Fi בשיטת Intel Over The Air (OTA) ברשת עמוסה בהשוואה לתעדוף תעבורה אלחוטית ביישום תוכנה ללא ICPS ומעבר חכם בין נקודות גישה. ראו www.intel.com/PerformanceIndex (קישוריות) לפרטים נוספים. התוצאות עשויות להיות שונות.",
          "²¹בהשוואה לדור הקודם של מעבדים.",
          "²²גרפיקת Intel® Arc™‎ היא הראשונה בשוק עם תמיכה בקידוד AV1 (נכון לרבעון הראשון של 2022). פרטים נוספים ב-intel.com/performanceindex.",
          "²³כפי שנמדד על ידי תכונות ייחודיות של עיצובי Intel® Evo™‎, שנבדקו לעמידה בתנאי הסף מרכזיים של חוויית משתמש בנייד. בדיקות נכון לפברואר 2022.",
          "²⁴פתרון Intel® Unison™‎ זמין כרגע רק בעיצובים שמתאימים ל-Intel® Evo™‎ במחשבים עם מערכת ההפעלה Windows* וניתן לצמד אותו רק לטלפונים עם מערכות הפעלה Android או iOS. בכל המכשירים חייבת לפעול גרסה נתמכת של מערכת ההפעלה.",
          "²⁵מהירויות זיכרון מקסימליות משויכות לתצורות DIMM 1 לכל ערוץ (1DPC). טעינת DIMM נוספת בערוץ עשויה להשפיע על מהירות הזיכרון המרבית. עד DDR5-5600 MT/s 1DPC UDIMM 1Rx8, ‏1Rx16 ו-DDR5-5200 1Rx8, ‏1Rx16, ‏2Rx8 במק\"טים נבחרים. ניתן להשיג קיבולת זיכרון מרבית באמצעות תצורות 2DPC.",
          "²⁶לעומת Wi-Fi רגיל. פרטים אפשר למצוא בכתובת intel.com/performance-wireless. התוצאות עשויות להיות שונות.",
          "²⁷הארכיטקטורה ההיברידית העוצמתית משלבת שתי מיקרו-ארכיטקטורות ליבה חדשות, ליבות ביצועים (P-cores) וליבות יעילות (E-cores), בפיסת סיליקון אחת של מעבד שהוצגה לראשונה במעבדי Intel® Core™‎ דור 12. לחלק מהמעבדים מדור 12 ומעבדי Intel® Core™‎ חדשים יותר אין ארכיטקטורה היברידית עוצמתית, רק P-cores, ויש להם גודל מטמון זהה לדור הקודם. פרטים בנוגע למק\"ט אפשר למצוא בכתובת ark.intel.com.",
          "²⁸מובנה בחומרה, Intel® Thread Director ניתן רק בתצורות של ארכיטקטורה היברידית עוצמתית של מעבדי Intel® Core™‎ מדור 12 או חדשים יותר. נדרשת הפעלה של מערכת ההפעלה. הפונקציונליות והתכונות הזמינות משתנות בהתאם למערכת ההפעלה.",
          "²⁹מובנה בחומרה, Intel® Thread Director ניתן רק בתצורות של ארכיטקטורה היברידית עוצמתית של מעבדי Intel® Core™‎ מדור 12 ו-13. נדרשת הפעלה של מערכת ההפעלה. הפונקציונליות והתכונות הזמינות משתנות בהתאם למערכת ההפעלה.",
          "³⁰כפי שנמדד על ידי ביצועי פלטפורמת אימות לייחוס של מעבד נייד Intel®‎ Core™‎ Ultra (סדרה 2 — שם קוד: Lunar Lake) לעומת מעבד נייד Intel®‎ Core™‎ Ultra (סדרה 1 — שם קוד: Meteor Lake) ב-3DMark Time Spy. פרטים נוספים ניתן למצוא בכתובת intel.com/performanceindex. התוצאות עשויות להיות שונות.",
          "³¹טשטוש רקע ומעקב אחר פנים זמינים במערכות מבוססות Windows* בלבד.",
          "³²מוצרי Wi-Fi 7 יכולים לגשת לערוצי 320‎ MHz בצירופים של ערוצי 6‎ GHz ושל ערוצי 160‎ MHz חדשים ב-5‎ GHz עם יכולות חדשות של Multi-Resource Unit Puncturing.",
          "³³מבוסס על מפרטים של תקני IEEE לתקשורת אלחוטית ועל מקצבי נתונים מקסימליים תאורטיים למכשירי הזרמה כפולה.",
          "³⁴הדמיות הנדסיות של סביבות רשת עמוסות שנערכו ב-Intel מראות שאפשרית הפחתה משמעותית בזמן ההמתנה עם יכולות ה-Multi-Link Operation החדשות של Wi-Fi 7.",
          "³⁵טעינה מינימלית שהושגה מרמת כיבוי ברירת המחדל של OEM. התוצאות עשויות להיות שונות בין מערכות. פרטים נוספים ניתן למצוא בכתובת intel.com/performance-evo.",
          "³⁶תחום התדרים של Wi-Fi ב-6GHz לא זמין לשימוש בכל המדינות/אזורים.",
          "³⁷‏Windows* בלבד",
          "³⁸הטעינה הושגה מרמת כיבוי ברירת המחדל של OEM. לקבלת מידע מקיף יותר על הביצועים ותוצאות מבחני הביצועים, יש לבקר בכתובת intel.com/Evo.",
          "³⁹מתייחס למעבדי Intel®‎ Core™‎ Ultra (סדרה&nbsp;2) למחשבים ניידים, על סמך תכונות אבטחה ייחודיות מבוססות חומרה, שילוב מעולה של יכולות אבטחה מעל ומתחת למערכת ההפעלה, ביצועים מרשימים, גם בצריכת חשמל נמוכה יותר בהשוואה לדור הקודם (החל מספטמבר 2024). פרטים נוספים ניתן למצוא בכתובת intel.com/performanceindex. התוצאות עשויות להיות שונות.",
          "⁴⁰יישומי התוכנה מוצגים למטרות המחשה בלבד. תכונות AI עשויות לדרוש רכישת תוכנה, מינוי או הפעלה נוספים על ידי ספק תוכנה או פלטפורמה, או שעשויות להיות להן דרישות תצורה או תאימות ספציפיות. פרטים בכתובת www.intel.com/PerformanceIndex. התוצאות עשויות להיות שונות. © Intel Corporation.",
          "⁴¹ייתכן שהתמונות המוצגות שונו או שהן עברו הדמיה. שימוש בתכונות AI עשוי לדרוש רכישת תוכנה, מינוי או הפעלה נוספת באמצעות ספק תוכנה או פלטפורמה, או שעשויות להיות להן דרישות תצורה או תאימויות ספציפיות. פרטים בכתובת www.intel.com/AIPC.",
          "⁴²פריסה בהדרגה בתצוגה מקדימה בעדכון האחרון של Windows* 11 בשווקים גלובליים נבחרים. מועד הזמינות משתנה בהתאם למחשב ולשוק. ניתן למצוא מידע נוסף כאן: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³פתרון Intel®‎ Unison™‎ זמין כרגע רק לעיצובים ספציפיים. פרטים נוספים ניתן למצוא באתר www.intel.com/PerformanceIndex.",
          "⁴⁴קצב פריימים לשנייה (FPS) משופר באמצעות Intel® X<sup>e</sup>SS לעומת FPS מקורי כפי שנמדד על ידי Dying Light 2 Stay Human הפועל במעבד Intel® Core™ Ultra 7 165H. פרטים נוספים ניתן למצוא באתר www.intel.com/PerformanceIndex. התוצאות עשויות להיות שונות.",
          "⁴⁵כפי שנמדד על ידי צריכת חשמל נמוכה יותר של SoC של פלטפורמת אימות לסימוכין של מעבד נייד Intel®‎ Core™‎ Ultra (סדרה 2 — שם קוד: Lunar Lake) לעומת פלטפורמת אימות לסימוכין של מעבד נייד Intel®‎ Core™‎ Ultra (סדרה 1 — שם קוד: Meteor Lake) ב-YouTube* 4K 30FPS AV1. פרטים נוספים ניתן למצוא בכתובת intel.com/performanceindex. התוצאות עשויות להיות שונות.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "אינטל אינה נושאת באחריות כלשהי לפרסום מחירים שגויים.",
          "* שמות ומותגים אחרים עשויים להיות רכוש של אחרים.",
          "זכויות יוצרים 2022 INFLEXION GAMES. NIGHTINGALE, ‏INFLEXION GAMES וסימני עיצוב ורכיבים קשורים הם סימנים מסחריים ששייכים ל-INFLEXION STUDIOS INC. כל הזכויות שמורות.",
          "השימוש בטכנולוגיה של Intel מחייב לעתים הפעלה של חומרה, תוכנה או שירות.",
          "כל התוצאות והנתונים מ-NERO* נועדו למטרות נוחות ומידע בלבד. Intel Corporation לא נושאת באחריות, בין השאר, לדיוק של היישום, תוצאות הביצועים והניקוד של NERO*, או לווריאציות פוטנציאליות מגרסאות אחרות הניתנות להורדה של היישום הזמינות בכתובת www.nero.com או באתרים אחרים של צד שלישי. ניקוד ביצועי המחשב של NERO* נמדד באמצעות מערכות מחשב, תוכנה, פעולות, פונקציות ורכיבים ספציפיים. שינוי בכל אחד מהגורמים האלה עשוי להוביל לשינוי בתוצאות. יש להצליב מידע מול מקורות מידע ומבחני ביצועים נוספים כדי לקבל תמונת מצב מלאה על המוצרים הרצויים, כולל ביצועי המוצר כשהוא עובד לצד מוצרים נוספים.",
          "כל הגרסאות של פלטפורמת Intel vPro®‎ דורשות מעבד Intel® Core™‎ מתאים, מערכת הפעלה נתמכת, מעגלי LAN ו/או WLAN של Intel®‎, שיפורי קושחה וכן חומרה ותוכנה נוספות שנדרשות כדי לספק את יכולת הניהול, תכונות האבטחה, ביצועי המערכת והיציבות המגדירים את הפלטפורמה.",
          "הביצועים משתנים בהתאם לאופן השימוש, להגדרת התצורה ולגורמים אחרים. מידע נוסף ניתן למצוא בכתובת www.intel.com/PerformanceIndex.",
          "חברת Intel לא שולטת בנתונים של צד שלישי ולא בודקת אותם. עליך להתייעץ במקורות אחרים כדי להעריך את הדיוק.",
          "‎© Intel Corporation. ‏Intel, הלוגו של Intel וסימנים אחרים של Intel הם סימנים מסחריים של Intel Corporation או של חברות הבת שלה. *שמות ומותגים אחרים עשויים להיות רכוש של אחרים.",
          "אף מוצר או רכיב לא יכול להיות בטוח לגמרי.",
          "העלויות והתוצאות שלך עשויות להיות שונות.",
          "Intel, הלוגו של Intel, ‏Intel Core,‏ Intel Optane, ‏Intel Iris, ‏Thunderbolt והלוגו של Thunderbolt הם סימנים מסחריים רשומים של Intel Corporation או של חברות הבת שלה. מותגים ושמות אחרים עשויים להיות רכוש של אחרים.",
          "‎© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "בית",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "אפשר לסרוק כדי להשוות",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "פופולרי",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "גלו חוויות AI חדשות עם מעבדי Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "גלו חוויות AI חדשות עם מעבדי Intel® Core™ Ultra",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "גלו חוויות AI חדשות עם מעבדי Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "Stable Diffusion*‎: טקסט לתמונה",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe*‎: עריכת תמונות",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "Intel® X<sup>e</sup>SS : הגדלת רזולוציה של תמונות",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee*: מזהה דיפ-פייק",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "אפקטים של שיתוף פעולה (Teams)",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "IQIYI: שליטה באמצעות מחוות",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "סנכרון המחשב עם הטלפון יאפשר לכם לראות התראות, שיחות והודעות טקסט",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "גרפיקה עשירה ומלאת חיים היא חלק אינטגרלי מהמכשיר",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "Microsoft*: נהלו את החיים שלכם בדרך שלכם",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "Microsoft*: כל הדברים שאתם. הכול במבט אחד, תמיד במרחק החלקה אחת",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "כוונון עדין של המערכת יאפשר לכם להפעיל את המעבד מהר יותר ובתדרים גבוהים יותר",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "שפרו את ביצועי המערכת של המחשב או של הנייד בלי לוותר על מרחב אחסון",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "שפרו את ביצועי המערכת של המחשב או של הנייד בלי לוותר על מרחב אחסון",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "Samsung OLED: תענוג לעיניים",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "מהירות, זמן המתנה ואמינות טובים יותר באמצעות אופטימיזציית רשת מבוססת בינה מלאכותית",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "רשת מחשבים אישיים דמויית חיבור קווי",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "רשת מחשבים אישיים דמויית חיבור קווי",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "עם Intel® Wi-Fi 6 (Gig+)‎ מקבלים מהירויות גבוהות יותר כמעט פי 3",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "חיבור ללא תחרות",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "קישוריות כבל אוניברסלית באמת",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "לשחק כמו המקצוענים: ראו כיצד Intel מעניקה עוצמה לגיימינג ולגיימרים",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "לשחק כמו המקצוענים: Intel יוצרת אלופים",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "ברוכים הבאים",
    "tr_welcomePagetrSubtitle": "הפעילו את המחשב הזה מרחוק באמצעות טלפון חכם",
    "tr_welcomePagembSubtitle": "ברוכים הבאים, צפו במפרט של מחשב זה בטלפון החכם שלכם",
    "tr_welcomePageBtnText": "התחילו עכשיו",
    "tr_welcomePageText": "גלו והשוו את הפרטים של מחשב זה בטלפון&nbsp;החכם&nbsp;שלכם",
    "tr_scrollText": "נווטו במכשיר שמונח לפניכם",
    "tr_toolTipTextc1": "השתמשו בלחצנים אלה כדי לנווט לדפים שונים במכשיר שמונח לפניכם.",
    "tr_toolTipTextc2": "הקשה על הכרטיסים שלהלן תעביר אתכם לדף מפרט מפורט של כל מכשיר.",
    "tr_toolTipTextc3": "השתמשו בלחצנים אלה כדי לנוע מעלה ומטה על המסך שלפניכם",
    "tr_toolTipTextc4": "כבו את המצב המרוחק כדי להציג את מפרט המכשיר בטלפון החכם שלכם.",
    "tr_toolTipTextmb1": "הקישו על כרטיסים כדי להציג דפי תוכן מפורטים.",
    "tr_toolTipTextmb2": "הקישו כדי להציג סיכום של כל מפרטי המכשיר",
    "tr_toolTipTextmb3": "הקישו על הלחצן כדי לסרוק קוד QR ולהשוות עד 4 מכשירים",
    "tr_nextButton": "הבא",
    "tr_prevButton": "קודם",
    "tr_gotItButton": "הבנתי",
    "tr_skipButton": "דלג",
    "tr_placeholderText": "*מציין מיקום",
    "tr_processorHeading": "מעבד",
    "tr_processorSubheading": "מעבד Intel®&nbsp;Core™&nbsp;i7 דור&nbsp;עשירי",
    "tr_memorySubheading": "‎[XX]&nbsp;GB",
    "tr_storageSubheading": "‎[XX] GB של זיכרון Intel® Optane™‎ + ‏‎[YY] GB ‏SSD",
    "tr_coachmarkProcessor": "מעבד XX",
    "tr_coachmarkGraphics": "גרפיקת [XX]",
    "tr_coachmarkMemory": "ערך המפרט",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "‎$ 999.95",
    "tr_compare": "השוואה",
    "tr_viewallspecs": "הצגת כל המפרטים",
    "tr_operatingSystem": "מערכת הפעלה",
    "tr_AmdProcessorNonIntel": "מעבד AMD*‎",
    "tr_QualcommProcessorNonIntel": "מעבד Qualcomm*‎",
    "tr_GBText": "‎GB",
    "tr_remoteText": "מפרטים שמוצגים על מסך המחשב הנייד",
    "tr_scanErrorText": "ניתן לסרוק עד 3 מכשירים בו זמנית בלבד. אנא מחקו מכשיר אחד או יותר.",
    "tr_scanErrorText2": "המכשיר הזה כבר נסרק, נא לסרוק מכשיר אחר.",
    "tr_scanMsg": "סרקו כדי להוסיף מכשיר",
    "tr_backToScan": "חזרה להשוואה",
    "tr_scanToCompare": "סרקו כדי להשוות",
    "tr_compareDevice": "השוואת התקנים",
    "tr_processorTitle": "מעבד",
    "tr_graphicTitle": "גרפיקה",
    "tr_storageTitle": "אחסון",
    "tr_displayTitle": "צג",
    "tr_batteryTitle": "סוללה",
    "tr_memoryTitle": "זיכרון",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "קישוריות",
    "tr_priceTitle": "מחיר",
    "tr_operatingSystemTitle": "מערכת הפעלה",
    "tr_batteryHoursText": "[XX] שעות",
    "tr_hrsText": "שעות",
    "tr_touchscreeenText": "מסך&nbsp;מגע בגודל [screenDiagonal]&nbsp;אינץ'",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, מסך&nbsp;מגע",
    "tr_FHDTouchScreenText": "FHD, מסך&nbsp;מגע",
    "tr_4KTouchscreenText": "4K, מסך&nbsp;מגע",
    "tr_wifi_gigText": "‎(Gig+)",
    "tr_save": "שמירה",
    "tr_device_comparison": "מסמך השוואת מכשירים [mm]-[dd]-[yyyy]",
    "tr_Save_As_JPEG": "שמור כ-JPEG",
    "tr_Save_As_PDF": "שמור כ-PDF",
    "tr_Downloading_PDF": "הורדת PDF מתבצעת",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "פתיחה",
    "tr_logo": "לוגו",
    "tr_laptop_name": "שם המחשב הנייד",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "זיכרון Intel® Optane™‎",
    "tr_inchUnit": "אינץ'",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)‎",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "זיכרון Intel® Optane™‎",
    "tr_Thunderbolt3": "טכנולוגיית Thunderbolt™ 3",
    "tr_Thunderbolt4": "טכנולוגיית Thunderbolt™ 4",
    "tr_processorGraphicsCaps": "גרפיקת מעבד",
    "tr_processorGraphicsSmall": "גרפיקת מעבד",
    "tr_graphicsCardCaps": "גרפיקה ייעודית",
    "tr_graphicsCardSmall": "גרפיקה ייעודית",
    "tr_processorTitleCamel": "מעבד",
    "tr_graphicTitleCamel": "גרפיקה",
    "tr_storageTitleCamel": "אחסון",
    "tr_displayTitleCamel": "צג",
    "tr_batteryTitleCamel": "סוללה",
    "tr_memoryTitleCamel": "זיכרון",
    "tr_connectivityTitleCamel": "קישוריות",
    "tr_priceTitleCamel": "מחיר",
    "tr_operatingSystemTitleCamel": "מערכת הפעלה",
    "tr_viewallspecsCamel": "הצגת כל המפרטים",
    "tr_displayText": "[screenDiagonal] אינץ'",
    "tr_displayTextResolution": "[screenResolutionType] בגודל [screenDiagonal] אינץ'",
    "tr_displayTextResolutionTouchscreen": "מסך&nbsp;מגע [screenResolutionType] בגודל [screenDiagonal]&nbsp;אינץ'",
    "tr_OS": "מערכת הפעלה",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "מפרטי מחשבים",
    "tr_explorePC_text": "עיון בכל הפרטים של המחשב הזה",
    "tr_mtlPC_text": "מה בעצם המשמעות של 'בנויים להתמודד עם כל משימה'",
    "tr_optaneMemory": "זיכרון Intel® Optane™‎",
    "tr_displayText_Display": "מסך בגודל [screenDiagonal]”",
    "tr_displayTextResolution_Display": "מסך בגודל [screenDiagonal]” [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen_Display": "מסך מגע בגודל [screenDiagonal]” [screenResolutionType]",
    "tr_displayTextTouchscreeenText": "מסך מגע בגודל [screenDiagonal]”",
    "tr_learnMoreLabel": "מידע נוסף",
    "tr_batteryHoursTextUpperCase": "[XX] שעות",
    "tr_processorGraphicsIrisXe": "מעבד INTEL® CORE™ [cpu_fam] דור 11 וגרפיקת INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax": "מעבד INTEL® CORE™ [cpu_fam] דור 11 וגרפיקת Intel®‎ Iris®‎ X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_Thunderbolt3UpperCase": "טכנולוגיית THUNDERBOLT™ 3",
    "tr_Thunderbolt4UpperCase": "טכנולוגיית THUNDERBOLT™ 4",
    "tr_processorWithArcGraphics": "מעבד INTEL® CORE™ [cpu_fam] דור 12 וגרפיקת INTEL®‎ ARC™‎",
    "tr_processorGraphicsIrisXe12Gen": "מעבד INTEL® CORE™ [cpu_fam] דור 12 וגרפיקת INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax12Gen": "מעבד INTEL® CORE™ [cpu_fam] דור 12 וגרפיקת INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "מעבד INTEL® CORE™ [cpu_fam] דור 13 וגרפיקת INTEL®‎ ARC™‎",
    "tr_processorGraphicsIrisXe13Gen": "מעבד INTEL® CORE™ [cpu_fam] דור 13 וגרפיקת INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax13Gen": "מעבד INTEL® CORE™ [cpu_fam] דור 13 וגרפיקת INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "גרפיקת INTEL®‎ ARC™ A350M‎",
      "tr_A370M": "גרפיקת INTEL®‎ ARC™ A370M‎",
      "tr_A730M": "גרפיקת INTEL®‎ ARC™ A730M‎",
      "tr_A770M": "גרפיקת INTEL®‎ ARC™ A770M‎",
      "tr_A310": "גרפיקת INTEL®‎ ARC™ A310‎",
      "tr_A380": "גרפיקת INTEL®‎ ARC™ A380‎",
      "tr_A580": "גרפיקת INTEL®‎ ARC™ A580‎",
      "tr_A750": "גרפיקת INTEL®‎ ARC™ A750‎",
      "tr_A770": "גרפיקת INTEL®‎ ARC™ A770‎"
    },
    "tr_memoryValue": "‎[XX] GB של&nbsp;RAM",
    "tr_memoryWithOptaneDesc": "זיכרון&nbsp;GB‏ [Value1] = זיכרון&nbsp;Intel®&nbsp;Optane™‎ ‏GB‏ [Value2]  + זיכרון&nbsp;RAM‏&nbsp;GB‏ [Value3]",
    "tr_textGBGB": "‎[convertedSSD]&nbsp;GB&nbsp;SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textGBTB": "‎[convertedSSD]&nbsp;GB&nbsp;SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textTBGB": "‎[convertedSSD]&nbsp;TB&nbsp;SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textTBTB": "‎[convertedSSD]&nbsp;TB&nbsp;SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textGBSSD": "‎[convertedSSD] GB SSD",
    "tr_textTBSSD": "‎[convertedSSD] TB SSD",
    "tr_textGBHDD": "‎[convertedHDD] GB HDD",
    "tr_textTBHDD": "‎[convertedHDD] TB HDD",
    "tr_textStorageWithOptaneGB": "‎[XX]&nbsp;GB‏ זיכרון של Intel®‎&nbsp;Optane™‎&nbsp;+‏ ‎[convertedSSD]&nbsp;GB&nbsp;SSD‏",
    "tr_textStorageWithOptaneTB": "‎[XX]&nbsp;GB‏ זיכרון של Intel®‎&nbsp;Optane™‎&nbsp;+‏ ‎[convertedSSD]&nbsp;TB&nbsp;SSD‏",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows*‎ 10 Home במצב S",
      "tr_OS21": "Windows*‎ 10 Pro במצב S",
      "tr_OS22": "Windows*‎ 10 Enterprise במצב S",
      "tr_OS23": "Windows*‎ 11 Home במצב S",
      "tr_OS24": "Windows*‎ 11 Pro במצב S",
      "tr_OS25": "Windows*‎ 11 Enterprise במצב S"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "גרפיקת Intel® Arc™ A310",
      "tr_GR387": "גרפיקת Intel® Arc™ A350M",
      "tr_GR388": "גרפיקת Intel® Arc™ A370M",
      "tr_GR389": "גרפיקת Intel® Arc™ A380",
      "tr_GR390": "גרפיקת Intel® Arc™ A530M",
      "tr_GR391": "גרפיקת Intel® Arc™ A550M",
      "tr_GR392": "גרפיקת Intel® Arc™ A570M",
      "tr_GR393": "גרפיקת Intel® Arc™ A580",
      "tr_GR394": "גרפיקת Intel® Arc™ A730M",
      "tr_GR395": "גרפיקת Intel® Arc™ A750",
      "tr_GR396": "גרפיקת Intel® Arc™ A770",
      "tr_GR397": "גרפיקת Intel® Arc™ A770M",
      "tr_GR398": "גרפיקת Intel®‎ Arc™‎",
      "tr_GR399": "גרפיקת Intel® Arc™ Pro A30M",
      "tr_GR400": "גרפיקת Intel® Arc™ Pro A40/A50",
      "tr_GR401": "גרפיקת Intel® Arc™ Pro A60",
      "tr_GR402": "גרפיקת Intel® Arc™ Pro A60M",
      "tr_GR403": "גרפיקת Intel®‎",
      "tr_GR404": "גרפיקת Intel® HD 2000",
      "tr_GR405": "גרפיקת Intel® HD 2500",
      "tr_GR406": "גרפיקת Intel® HD 3000",
      "tr_GR407": "גרפיקת Intel® HD 400",
      "tr_GR408": "גרפיקת Intel® HD 4000",
      "tr_GR409": "גרפיקת Intel® HD 405",
      "tr_GR410": "גרפיקת Intel® HD 4200",
      "tr_GR411": "גרפיקת Intel® HD 4400",
      "tr_GR412": "גרפיקת Intel® HD 4600",
      "tr_GR413": "גרפיקת Intel® HD 500",
      "tr_GR414": "גרפיקת Intel® HD 5000",
      "tr_GR415": "גרפיקת Intel® HD 505",
      "tr_GR416": "גרפיקת Intel® HD 510",
      "tr_GR417": "גרפיקת Intel® HD 515",
      "tr_GR418": "גרפיקת Intel® HD 520",
      "tr_GR419": "גרפיקת Intel® HD 530",
      "tr_GR420": "גרפיקת Intel® HD 5300",
      "tr_GR421": "גרפיקת Intel® HD 5500",
      "tr_GR422": "גרפיקת Intel® HD 5600",
      "tr_GR423": "גרפיקת Intel® HD 6000",
      "tr_GR424": "גרפיקת Intel® HD 610",
      "tr_GR425": "גרפיקת Intel® HD 615",
      "tr_GR426": "גרפיקת Intel® HD 620",
      "tr_GR427": "גרפיקת Intel® HD 630",
      "tr_GR428": "גרפיקת Intel® HD",
      "tr_GR429": "גרפיקת Intel® Iris® 5100",
      "tr_GR430": "גרפיקת Intel® Iris® 540",
      "tr_GR431": "גרפיקת Intel® Iris® 550",
      "tr_GR432": "גרפיקת Intel® Iris® 6100",
      "tr_GR433": "גרפיקת Intel® Iris® Plus 640",
      "tr_GR434": "גרפיקת Intel® Iris® Plus 645",
      "tr_GR435": "גרפיקת Intel® Iris® Plus 650",
      "tr_GR436": "גרפיקת Intel® Iris® Plus 655",
      "tr_GR437": "גרפיקת Intel® Iris® Plus",
      "tr_GR438": "גרפיקת Intel® Iris® Pro 5200",
      "tr_GR439": "גרפיקת Intel® Iris® Pro 580",
      "tr_GR440": "גרפיקת Intel® Iris® Pro 6200",
      "tr_GR441": "גרפיקת Intel® Iris® X<sup>e</sup>",
      "tr_GR442": "גרפיקת Intel® Iris® X<sup>e</sup> MAX A220M",
      "tr_GR443": "גרפיקת Intel® Iris® X<sup>e</sup> MAX",
      "tr_GR444": "גרפיקה משולבת Intel®‎",
      "tr_GR445": "גרפיקת Intel® UHD 600",
      "tr_GR446": "גרפיקת Intel® UHD 605",
      "tr_GR447": "גרפיקת Intel® UHD 610",
      "tr_GR448": "גרפיקת Intel® UHD 615",
      "tr_GR449": "גרפיקת Intel® UHD 617",
      "tr_GR450": "גרפיקת Intel® UHD 620",
      "tr_GR451": "גרפיקת Intel® UHD 630",
      "tr_GR452": "גרפיקת Intel® UHD 710",
      "tr_GR453": "גרפיקת Intel® UHD 730",
      "tr_GR454": "גרפיקת Intel® UHD 770",
      "tr_GR455": "גרפיקת Intel® UHD",
      "tr_GR456": "גרפיקת Intel® UHD",
      "tr_GR457": "גרפיקת Intel® UHD",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 עם עיצוב Max-Q",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti עם עיצוב Max-Q",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 עם עיצוב Max-Q",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 עם עיצוב Max-Q",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 עם עיצוב Max-Q",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 עם עיצוב Max-Q",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti עם עיצוב Max-Q",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti עם עיצוב Max-Q",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX Titan Black",
      "tr_GR651": "NVIDIA* GeForce* GTX Titan X",
      "tr_GR652": "NVIDIA* GeForce* GTX Titan Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 עם עיצוב Max-Q",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 עם עיצוב Max-Q",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super עם עיצוב Max-Q",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 עם עיצוב Max-Q",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super עם עיצוב Max-Q",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 עם עיצוב Max-Q",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 עם עיצוב Max-Q",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 עם עיצוב Max-Q",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 עם עיצוב Max-Q",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 עם עיצוב Max-Q",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 עם עיצוב Max-Q",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 עם עיצוב Max-Q",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 עם עיצוב Max-Q",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 עם עיצוב Max-Q",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 עם עיצוב Max-Q",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "גרפיקת Intel®‎ Arc™‎",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "AMD Radeon* Pro W7900 Dual Slot"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel®‎ Centrino®‎ Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel®‎ Centrino®‎ Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "לרכישה כעת",
    "tr_textTryagain": "נסה שוב",
    "tr_textBuynowErr": "עקב הפרעה בחיבור, האפשרות 'לרכישה עכשיו' אינה זמינה",
    "tr_chatbotPlaceholder_text": "מה ברצונך לדעת על המכשיר?",
    "tr_deviceSpecs_text": "מפרטי המכשיר"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "מעבד Intel®‎&nbsp;Core™&nbsp; i3 עם טכנולוגיית Intel®&nbsp;Hybrid"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "מעבד Intel®‎&nbsp;Core™&nbsp; i5 עם טכנולוגיית Intel®&nbsp;Hybrid"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "הנוקמים של מארוול",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "חוויית גיימינג מרשימה יותר עם רכיבים חדשים ומשופרים של Intel®‎",
                "tr_subtitle": "מפיחים חיים בנוקמים של Marvel* בעזרת שיפורי המשחק של Intel®‎ למחשבים אישיים.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "לחוות את Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "המקום שאליו באים הגיימרים הטובים בעולם להתחרות",
                "tr_subtitle": "Intel® Extreme Masters, סדרת האירועים הוותיקה ביותר בהיסטוריית משחקי הווידאו מגדירה את הסטנדרטים למשחקי הספורט האלקטרוני.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "לשחק כמו המקצוענים",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "עם Intel נהיים אלופים.",
                "tr_subtitle": "לאחר הזכייה באליפות ליגת Rainbow Six Siege* של צפון אמריקה לשנת 2021, חברי ה-Soniqs מדברים על היסטוריית הגיימינג שלהם, על עתיד הספורט האלקטרוני ועל השותפות שלהם עם Intel.",
                "tr_pretitle": "בהשתתפות ה-Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "מוכן למציאות מדומה",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "היכן שמציאות מדומה מתעוררת לחיים",
                "tr_subtitle": "חווית המציאות המדומה המדהימה, המציאותית והסוחפת ביותר במחשב. עם מעבדי Intel® Core™‎ החדשים, תוכלו להיות כל אחד ואחת, ללכת לכל מקום ולחוות משחק בממד הבא.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "עם Xbox*‎ Game Pass Ultimate ועם שלט תואם, אפשר לשחק ביותר מ-100 משחקי קונסולה באיכות גבוהה כמו Sea of Thieves*‎ ו-Psychonauts*‎ 2 במחשב Windows*‎, בטלפון הנייד ובטאבלט.",
                "titleArray": [
                  {
                    "tr_title": "משחקים יחד במכשירים שונים",
                    "tr_subtitle": "הגיע הזמן להצטרף ל-Xbox*‎ ולקהילה שיש בה מיליוני שחקנים שמוכנים ומחכים לשחק ביחד. אפשר להתחבר ולשחק עם אנשים אחרים מתוך ספרייה משותפת של משחקים, וזה לא משנה אם הם נמצאים בצד השני של העולם או יושבים ממש לידך."
                  },
                  {
                    "tr_title": "גיימינג במחשב Windows*",
                    "tr_subtitle": "אפשר לשחק מהענן במשחקי קונסולה שנמצאים בקטלוג באמצעות האפליקציה של Xbox*‎ עבור Windows*‎ ושלט תואם."
                  },
                  {
                    "tr_title": "בוחרים ומשחקים",
                    "tr_subtitle": "נהנים ממשחקים מעולים של Xbox*‎ ביותר מקומות מאשר בעבר. אפשר לשחק בלמעלה מ-100 משחקים במחשב, בטלפון הנייד ובטאבלט, כשנמצאים בבית מול הטלוויזיה, בבית הקפה או בכל מקום אחר שיש בו חיבור אינטרנט מהיר."
                  },
                  {
                    "tr_title": "נהנים גם בלי שלט",
                    "tr_subtitle": "הגיימינג של Xbox*‎ באמצעות מקשי מגע מעניק דרכים חדשות להתחיל לשחק. מקשי המגע מספקים חוויה מוכרת ותמיכה ברמת המשחק שהתרגלת אליה בשימוש בשלט פיזי."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "זרקור",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "מעל 100 חוויות של בינה מלאכותית (AI)‏",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "יותר שליטה, פחות מגע",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "נשארים בתמונה, בכל סביבה",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "יצירתיות",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "מתקנים תאורה כמו מקצוענים",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "מוסיפים בקלות אפקטים גדולים מהחיים",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "רמיקס לשירים עם העוצמה של הבינה המלאכותית",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "פרודוקטיביות",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "משפרים את הפרודוקטיביות והפרטיות",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "מבלים יותר זמן הרחק משקע החשמל",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "העולם שלך. במכשירים שלך.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "בידור",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "סטרימינג חלק, גם ללא חיבור",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "גיימינג מהיר וסוחף",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "זרקור",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "מעל 100 חוויות של בינה מלאכותית (AI)‏",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "השירים האהובים עליך יהפכו להיות שלך באמת",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "רואים את החזון בזמן אמת",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "יצירתיות",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "הדרך המהירה ביותר לגרסה הסופית",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "הופכים כל תמונה למושלמת",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "פרודוקטיביות",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "משפרים את הפרודוקטיביות והפרטיות",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "יותר יעילות. יותר חופש.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "העולם שלך. במכשירים שלך.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "בידור",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "סטרימינג חלק באיכות HD, גם ללא חיבור",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "גיימינג סוחף ביותר מקומות",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "יותר<br/>יצירתיות",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "עריכת תמונות בקלות",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "יצירת תמונות ראויות למסגור בשניות",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "אתם מקלידים. הבינה המלאכותית יוצרת.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "יותר ביצועים",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "הצ'יט קוד לגיימינג חלק יותר",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "מבלים יותר זמן הרחק משקע החשמל",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "אולטרה סוחף",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "יותר פרודוקטיביות",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "מלווה הבינה המלאכותית שלכם ליומיום",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "שיחות וידאו מושלמות",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "העולם שלך. במכשירים שלך.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "יותר אבטחה",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "הגנה מובנית שעוזרת לשמור על האבטחה והפרטיות של הנתונים",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "יותר<br/>יצירתיות",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "עריכת תמונות בקלות",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "בינה מלאכותית. קיצור הדרך לגרסה הסופית.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "תמלול מהיר של מילות השירים האהובים",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "חוסכים שעות של עריכה עם בינה מלאכותית",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "יותר ביצועים",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "הצ'יט קוד לגיימינג חלק יותר",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "מבלים יותר זמן הרחק משקע החשמל",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "אולטרה סוחף",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "יותר פרודוקטיביות",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "מלווה הבינה המלאכותית שלכם ליומיום",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "שיחות וידאו מושלמות",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "העולם שלך. במכשירים שלך.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "יותר אבטחה",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "הגנה מובנית שעוזרת לשמור על האבטחה והפרטיות של הנתונים",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "כל הבידור שלך. מחשב אחד.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "מחשב המשמש גם כמרכז בידור זקוק לאחסון בגודל שיספיק לכל השירים, הסרטים והמשחקים האהובים, ולשילוב המתאים של מעבד ומסך כדי ליצור חוויה ויזואלית סוחפת. למחשב הזה יש את כל השלושה.",
                "titleArray": [
                  {
                    "tr_title": "מוכן למיצגים חזותיים של הדור הבא",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "לבנות את הספרייה דיגיטלית שלך",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "חלון לבידור שלך",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "גיימינג וסטרימינג חלקים",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "לבנות את הספרייה דיגיטלית שלך",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "חלון לבידור שלך",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "להיות פרודוקטיביים יותר. בכל מקום.",
              "tr_tileTitle1": "להספיק יותר. מהר.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "המחשב הזה הוא האיזון המושלם בין עוצמה לניידות. בנוסף למעבד שנועד לעיצובים דקים וקלים, המחשב הזה כולל גם Wi&#8209;Fi מהסוג שישאיר אתכם מחוברים וסוללה שלא תחייב אתכם להתחבר כל הזמן לחשמל.",
                "tr_subtitle1": "למחשב הזה יש את האיזון המושלם בין כוח וביצועים כדי לעזור לך לסיים יותר בפחות זמן. השילוב של מעבד חזק המתוגבר בשפע של זיכרון ו-WiFi מהיר כברק יריצו את המטלות היומיות שלך.",
                "tr_subtitle2": "המחשב הזה הוא האיזון המושלם בין עוצמה לניידות. בנוסף למעבד שנועד לעיצובים דקים וקלים, המחשב הזה כולל גם Wi‑Fi מהסוג שישאיר אתכם מחוברים וגם את הזיכרון המתאים לריבוי-משימות.",
                "titleArray": [
                  {
                    "tr_title": "בנוי לעשות יותר",
                    "tr_title1": "ביצועים חכמים במה שחשוב",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "החיבורים הטובים מסוגם",
                    "tr_title1": "להתחבר לדברים החשובים",
                    "tr_title2": "התקדמות משמעותית בתחום ה-Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "שימוש ממושך יותר ללא טעינה",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "לעבור בין תוכנות כמו מקצוענים",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "החופש ליצור",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "יצירת תוכן היא אחת מהמשימות התובעניות ביותר למחשב שלך. למרבה המזל, לרכיבים האלה יש את העוצמה להזרים את התהליך היצירתי שלך באופן חלק.",
                "titleArray": [
                  {
                    "tr_title": "דרך חכמה יותר ליצירה",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "מפיחים חיים בתמונות",
                    "tr_title1": "זה הזמן ליצירה",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "זיכרון לריבוי משימות",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "מבט אל תוך המחשב",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "כשבוחרים מחשב חדש, צריך להתמקד בשלושה רכיבים עיקריים. כדי להחליט כמה משלושת הרכיבים העיקריים האלה הכרחיים, כדאי להחליט מה יהיה שימושו של המחשב החדש. אלו הם:",
                "titleArray": [
                  {
                    "tr_title": "ביצועים לגיימינג וסטרימינג",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "אחסון",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "זיכרון",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "כל הבידור שלך. מחשב אחד.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "מחשב המשמש גם כמרכז בידור זקוק לאחסון בגודל שיספיק לכל השירים, הסרטים והמשחקים האהובים, ולשילוב המתאים של מעבד ומסך כדי ליצור חוויה ויזואלית סוחפת. למחשב הזה יש את כל השלושה.",
                "titleArray": [
                  {
                    "tr_title": "מוכן למיצגים חזותיים של הדור הבא",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "לבנות את הספרייה דיגיטלית שלך",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "חלון לבידור שלך",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "גיימינג וסטרימינג חלקים",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "לבנות את הספרייה דיגיטלית שלך",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "חלון לבידור שלך",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "זיכרון",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "צג",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "ריבוי משימות במהירות גבוהה",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ריבוי משימות במהירות גבוהה",
                "tr_subtitle": "לעשות יותר מבלי להאט",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לריבוי משימות במהירות:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "מעבד שיכול להפעיל מספר תוכנות בו זמנית."
                  },
                  {
                    "tr_title": "Wi‑Fi מהיר, חזק ואמין."
                  },
                  {
                    "tr_title": "זיכרון שעובר בצורה חלקה בין אפליקציות."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "שיחות וידאו",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "שיחות וידאו",
                "tr_subtitle": "חיבורים ברורים כשמש",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לשיחות וידאו:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "מעבד שיכול לשמור ולשתף תמונות בו זמנית."
                  },
                  {
                    "tr_title": "Wi‑Fi מהיר, חזק ואמין."
                  },
                  {
                    "tr_title": "מצלמת אינטרנט שתעזור לכם להופיע בצורה ברורה."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "מוכן למציאות מדומה",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "היכן שמציאות מדומה מתעוררת לחיים",
                "tr_subtitle": "מה דרוש ליצירת עולם וירטואלי לחלוטין? במחשב יש צורך בתיאום בין המעבד, הכרטיס הגרפי והזיכרון.",
                "titleArray": [
                  {
                    "tr_title": "מספקים תשתית לעולמות וירטואליים",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "המציאות נמצאת בפרטים הקטנים",
                    "tr_title1": "תנו לעצמכם להיסחף",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "עריכת וידאו",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "עריכת וידאו",
                "tr_subtitle": "צרו סרטים קסומים",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לעריכת וידאו:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "מעבד עם יכולת להתמודד עם קבצים גדולים."
                  },
                  {
                    "tr_title": "זיכרון שהופך את תוכנת העריכה למהירה ובעלת יכולת תגובה."
                  },
                  {
                    "tr_title": "גרפיקה שמקצרת את זמני העיבוד."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "ריבוי משימות במהירות גבוהה",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ריבוי משימות במהירות גבוהה",
                "tr_subtitle": "לעשות יותר מבלי להאט",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לריבוי משימות במהירות:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "מעבד שיכול להפעיל מספר תוכנות בו זמנית."
                  },
                  {
                    "tr_title": "Wi‑Fi מהיר, חזק ואמין."
                  },
                  {
                    "tr_title": "זיכרון שעובר בצורה חלקה בין אפליקציות."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "מוכן למציאות מדומה",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "היכן שמציאות מדומה מתעוררת לחיים",
                "tr_subtitle": "מה דרוש ליצירת עולם וירטואלי לחלוטין? במחשב יש צורך בתיאום בין המעבד, הכרטיס הגרפי והזיכרון.",
                "titleArray": [
                  {
                    "tr_title": "מספקים תשתית לעולמות וירטואליים",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "המציאות נמצאת בפרטים הקטנים",
                    "tr_title1": "תנו לעצמכם להיסחף",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "מידול בתלת-ממד",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "מידול בתלת-ממד",
                "tr_subtitle": "יצירות ברמה גבוהה",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר למידול בתלת-ממד:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "מעבד שיכול להתמודד עם תוכנות קריאיטיב מתקדמות."
                  },
                  {
                    "tr_title": "זיכרון עם יכולת להעלות קבצים גדולים במהירות."
                  },
                  {
                    "tr_title": "גרפיקה ליצירה ועריכה של תמונות מפורטות."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "שיתופי פעולה בזמן אמת",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "שיתופי פעולה בזמן אמת",
                "tr_subtitle": "עבודה מכל מקום",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לשיתופי פעולה מקוונים:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "מעבד להפעלת תוכנה משרדית."
                  },
                  {
                    "tr_title": "Wi‑Fi אמין."
                  },
                  {
                    "tr_title": "מצלמת אינטרנט מעולה להקלטות ב-HD."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "מוכן למציאות מדומה",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "היכן שמציאות מדומה מתעוררת לחיים",
                "tr_subtitle": "מה דרוש ליצירת עולם וירטואלי לחלוטין? במחשב יש צורך בתיאום בין המעבד, הכרטיס הגרפי והזיכרון.",
                "titleArray": [
                  {
                    "tr_title": "מספקים תשתית לעולמות וירטואליים",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "המציאות נמצאת בפרטים הקטנים",
                    "tr_title1": "תנו לעצמכם להיסחף",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "מחשבים ניידים Intel® Evo™‎",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "מי היה מאמין שמחשבים ניידים כל כך דקים וקלים יכולים להיות כל כך חזקים?",
                "tr_subtitle": "המחשביים הניידים ממשפחת Intel® Evo™‎ מותאמים עבור ביצועים ניידים וכוללים את המהירות, הגרפיקה וחיי הסוללה שאתם צריכים בעיצוב מדהים, דק וקל משקל.",
                "titleArray": [
                  {
                    "tr_title": "מחשב נייד נחוץ כיום הרבה יותר, ולכן דאגנו שהוא יוכל גם לעשות הרבה יותר.",
                    "tr_subtitle": "ניתן להשתמש במסך המגע כדי לחקור, לסובב, להתנסות ולהגדיל."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "להתעורר ברגע",
                    "tr_subtitle": "עם התעוררות מהירה המחשב האישי שלכם יכול לעבור ממצב שינה למצב עבודה בפחות משנייה."
                  },
                  {
                    "tr_title": "צפייה סוחפת",
                    "tr_subtitle": "תצוגה מרהיבה מכניסה חיים במידע החזותי שלכם ברזולוציה חדה וצבעים ערים."
                  },
                  {
                    "tr_title": "סיימו דברים מהר יותר",
                    "tr_subtitle": "תהנו מביצועים עם תגובה סופר מהירה ממעבדי ™Intel® Core דור 11."
                  },
                  {
                    "tr_title": "היו מנותקים מהחשמל לזמן רב יותר",
                    "tr_subtitle": "הישארו בזרם עם מחשב אישי המבצע מיטוב של חיי סוללה ונטען מחדש במהירות הבזק."
                  },
                  {
                    "tr_title": "קישוריות כבל אוניברסלית",
                    "tr_subtitle": "הופכת את החיבור של מכשירים אחרים למחשב האישי שלך לקל, מהיר ומאובטח בעזרת טכנולוגית Thunderbolt™ 4.‏<sup>10</sup>"
                  },
                  {
                    "tr_title": "חיבורים מהירים ואמינים",
                    "tr_subtitle": "צ'ט בווידאו או שיתוף קבצים עם חיבור שתוכלו לסמוך עליו באמצעות Intel® Wi‑Fi 6 (Gig+)<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "מחשבים ניידים Intel® Evo™‎",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "כל מה שצריך ויותר במחשב נייד דק וקל.",
                "tr_subtitle": "המחשבים הניידים Intel® Evo™‎ מותאמים עבור ביצועים ניידים ונועדו לספק את החוויה הטובה ביותר<sup>2</sup>, לא משנה מה אתם עושים.",
                "titleArray": [
                  {
                    "tr_title": "מחשב נייד נחוץ כיום הרבה יותר, ולכן דאגנו שהוא יוכל גם לעשות הרבה יותר.",
                    "tr_subtitle": "ניתן להשתמש במסך המגע כדי לחקור, לסובב, להתנסות ולהגדיל."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "להתעורר ברגע",
                    "tr_subtitle": "עם התעוררות מהירה המחשב האישי שלכם יכול לעבור ממצב שינה למצב עבודה בפחות משנייה."
                  },
                  {
                    "tr_title": "צפייה סוחפת",
                    "tr_subtitle": "תצוגה מרהיבה מכניסה חיים במידע החזותי שלכם ברזולוציה חדה וצבעים ערים."
                  },
                  {
                    "tr_title": "ביצועים מהפכניים",
                    "tr_subtitle": "הביצועים החכמים של מעבדי Intel® Core™‎ דור 12 נותנים לכם עוצמה נוספת במקומות החשובים ביותר."
                  },
                  {
                    "tr_title": "היו מנותקים מהחשמל לזמן רב יותר",
                    "tr_subtitle": "הישארו בזרם עם מחשב אישי המבצע מיטוב של חיי סוללה ונטען מחדש במהירות הבזק."
                  },
                  {
                    "tr_title": "קישוריות כבל אוניברסלית",
                    "tr_subtitle": "הופכת את החיבור של מכשירים אחרים למחשב האישי שלך לקל, מהיר ומאובטח בעזרת טכנולוגית Thunderbolt™ 4.‏<sup>10</sup>"
                  },
                  {
                    "tr_title": "חיבורים מהירים ואמינים",
                    "tr_subtitle": "צ'ט בווידאו או שיתוף קבצים עם חיבור שתוכלו לסמוך עליו באמצעות&nbsp;Intel® Wi‑Fi 6E (Gig+)‏.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "מחשבים ניידים Intel® Evo™‎",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "מי היה מאמין שמחשבים ניידים כל כך דקים וקלים יכולים להיות כל כך חזקים?",
                "tr_subtitle": "המחשביים הניידים ממשפחת Intel® Evo™‎ מותאמים עבור ביצועים ניידים וכוללים את המהירות, הגרפיקה וחיי הסוללה שאתם צריכים בעיצוב מדהים, דק וקל משקל.",
                "titleArray": [
                  {
                    "tr_title": "מחשב נייד נחוץ כיום הרבה יותר, ולכן דאגנו שהוא יוכל גם לעשות הרבה יותר.",
                    "tr_subtitle": "ניתן להשתמש במסך המגע כדי לחקור, לסובב, להתנסות ולהגדיל."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "להתעורר ברגע",
                    "tr_subtitle": "עם התעוררות מהירה המחשב האישי שלכם יכול לעבור ממצב שינה למצב עבודה בפחות משנייה."
                  },
                  {
                    "tr_title": "צפייה סוחפת",
                    "tr_subtitle": "תצוגה מרהיבה מכניסה חיים במידע החזותי שלכם ברזולוציה חדה וצבעים ערים."
                  },
                  {
                    "tr_title": "סיימו דברים מהר יותר",
                    "tr_subtitle": "תהנו מביצועים עם תגובה סופר מהירה ממעבדי ™Intel® Core דור 11."
                  },
                  {
                    "tr_title": "היו מנותקים מהחשמל לזמן רב יותר",
                    "tr_subtitle": "הישארו בזרם עם מחשב אישי המבצע מיטוב של חיי סוללה ונטען מחדש במהירות הבזק."
                  },
                  {
                    "tr_title": "קישוריות כבל אוניברסלית",
                    "tr_subtitle": "הופכת את החיבור של מכשירים אחרים למחשב האישי שלך לקל, מהיר ומאובטח בעזרת טכנולוגית Thunderbolt™ 4.‏<sup>10</sup>"
                  },
                  {
                    "tr_title": "חיבורים מהירים ואמינים",
                    "tr_subtitle": "צ'ט בווידאו או שיתוף קבצים עם חיבור שתוכלו לסמוך עליו באמצעות Intel® Wi‑Fi 6 (Gig+)<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "מחשבים ניידים Intel® Evo™‎",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "כל מה שצריך ויותר במחשב נייד דק וקל.",
                "tr_subtitle": "המחשבים הניידים Intel® Evo™‎ מותאמים עבור ביצועים ניידים ונועדו לספק את החוויה הטובה ביותר<sup>2</sup>, לא משנה מה אתם עושים.",
                "titleArray": [
                  {
                    "tr_title": "מחשב נייד נחוץ כיום הרבה יותר, ולכן דאגנו שהוא יוכל גם לעשות הרבה יותר.",
                    "tr_subtitle": "ניתן להשתמש במסך המגע כדי לחקור, לסובב, להתנסות ולהגדיל."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "להתעורר ברגע",
                    "tr_subtitle": "עם התעוררות מהירה המחשב האישי שלכם יכול לעבור ממצב שינה למצב עבודה בפחות משנייה."
                  },
                  {
                    "tr_title": "צפייה סוחפת",
                    "tr_subtitle": "תצוגה מרהיבה מכניסה חיים במידע החזותי שלכם ברזולוציה חדה וצבעים ערים."
                  },
                  {
                    "tr_title": "ביצועים מהפכניים",
                    "tr_subtitle": "הביצועים החכמים של מעבדי Intel® Core™‎ דור 12 נותנים לכם עוצמה נוספת במקומות החשובים ביותר."
                  },
                  {
                    "tr_title": "היו מנותקים מהחשמל לזמן רב יותר",
                    "tr_subtitle": "הישארו בזרם עם מחשב אישי המבצע מיטוב של חיי סוללה ונטען מחדש במהירות הבזק."
                  },
                  {
                    "tr_title": "קישוריות כבל אוניברסלית",
                    "tr_subtitle": "הופכת את החיבור של מכשירים אחרים למחשב האישי שלך לקל, מהיר ומאובטח בעזרת טכנולוגית Thunderbolt™ 4.‏<sup>10</sup>"
                  },
                  {
                    "tr_title": "חיבורים מהירים ואמינים",
                    "tr_subtitle": "צ'ט בווידאו או שיתוף קבצים עם חיבור שתוכלו לסמוך עליו באמצעות&nbsp;Intel® Wi‑Fi 6E (Gig+)‏.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "מחשבים ניידים Intel® Evo™‎",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "כל מה שצריך ויותר במחשב נייד דק וקל.",
                "tr_subtitle": "המחשבים הניידים Intel® Evo™‎ מותאמים עבור ביצועים ניידים ונועדו לספק את החוויה הטובה ביותר<sup>2</sup>, לא משנה מה אתם עושים.",
                "titleArray": [
                  {
                    "tr_title": "מחשב נייד נחוץ כיום הרבה יותר, ולכן דאגנו שהוא יוכל גם לעשות הרבה יותר.",
                    "tr_subtitle": "ניתן להשתמש במסך המגע כדי לחקור, לסובב, להתנסות ולהגדיל."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "להתעורר ברגע",
                    "tr_subtitle": "עם התעוררות מהירה המחשב האישי שלכם יכול לעבור ממצב שינה למצב עבודה בפחות משנייה."
                  },
                  {
                    "tr_title": "צפייה סוחפת",
                    "tr_subtitle": "תצוגה מרהיבה מכניסה חיים במידע החזותי שלכם ברזולוציה חדה וצבעים ערים."
                  },
                  {
                    "tr_title": "ביצועים מהפכניים",
                    "tr_subtitle": "הביצועים החכמים של מעבדי Intel® Core™‎ דור 13 נותנים לכם עוצמה נוספת במקומות החשובים ביותר."
                  },
                  {
                    "tr_title": "היו מנותקים מהחשמל לזמן רב יותר",
                    "tr_subtitle": "הישארו בזרם עם מחשב אישי המבצע מיטוב של חיי סוללה ונטען מחדש במהירות הבזק."
                  },
                  {
                    "tr_title": "קישוריות כבל אוניברסלית",
                    "tr_subtitle": "הופכת את החיבור של מכשירים אחרים למחשב האישי שלך לקל, מהיר ומאובטח בעזרת טכנולוגית Thunderbolt™ 4.‏<sup>10</sup>"
                  },
                  {
                    "tr_title": "חיבורים מהירים ואמינים",
                    "tr_subtitle": "צ'ט בווידאו או שיתוף קבצים עם חיבור שתוכלו לסמוך עליו באמצעות&nbsp;Intel® Wi‑Fi 6E (Gig+)‏.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "מחשבים ניידים Intel® Evo™‎",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "כל מה שצריך ויותר במחשב נייד דק וקל.",
                "tr_subtitle": "המחשבים הניידים Intel® Evo™‎ מותאמים עבור ביצועים ניידים ונועדו לספק את החוויה הטובה ביותר<sup>2</sup>, לא משנה מה אתם עושים.",
                "titleArray": [
                  {
                    "tr_title": "מחשב נייד נחוץ כיום הרבה יותר, ולכן דאגנו שהוא יוכל גם לעשות הרבה יותר.",
                    "tr_subtitle": "ניתן להשתמש במסך המגע כדי לחקור, לסובב, להתנסות ולהגדיל."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "להתעורר ברגע",
                    "tr_subtitle": "עם התעוררות מהירה המחשב האישי שלכם יכול לעבור ממצב שינה למצב עבודה בפחות משנייה."
                  },
                  {
                    "tr_title": "צפייה סוחפת",
                    "tr_subtitle": "תצוגה מרהיבה מכניסה חיים במידע החזותי שלכם ברזולוציה חדה וצבעים ערים."
                  },
                  {
                    "tr_title": "ביצועים מהפכניים",
                    "tr_subtitle": "הביצועים החכמים של מעבדי Intel® Core™‎ דור 13 נותנים לכם עוצמה נוספת במקומות החשובים ביותר."
                  },
                  {
                    "tr_title": "היו מנותקים מהחשמל לזמן רב יותר",
                    "tr_subtitle": "הישארו בזרם עם מחשב אישי המבצע מיטוב של חיי סוללה ונטען מחדש במהירות הבזק."
                  },
                  {
                    "tr_title": "קישוריות כבל אוניברסלית",
                    "tr_subtitle": "הופכת את החיבור של מכשירים אחרים למחשב האישי שלך לקל, מהיר ומאובטח בעזרת טכנולוגית Thunderbolt™ 4.‏<sup>10</sup>"
                  },
                  {
                    "tr_title": "חיבורים מהירים ואמינים",
                    "tr_subtitle": "צ'ט בווידאו או שיתוף קבצים עם חיבור שתוכלו לסמוך עליו באמצעות&nbsp;Intel® Wi‑Fi 6E (Gig+)‏.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "זרקור",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "מהי מהדורת Intel® Evo™‎?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "למה לבחור במהדורת Intel® Evo™‎?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "יצירתיות",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "יותר יצירתיות ביותר מקומות",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "עורכים תמונות בשיא המהירות",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "יוצרים אומנות מקורית באופן מיידי",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "פרודוקטיביות",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "מקסימום פרודוקטיביות עם Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "זיהוי של \"זיוף עמוק\" באמצעות בינה מלאכותית לפני שיתוף",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "העולם שלך. במכשירים שלך.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "ניידות",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "נותנים כיף לשליטה במחוות",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "סוללה שלא נגמרת באמצע",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "חיבור אוטומטי שלא מתנתק",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "זרקור",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "מהי מהדורת Intel® Evo™‎?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "למה לבחור במהדורת Intel® Evo™‎?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "יצירתיות",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "הצגת וידאו חלקה ומהירה",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "ליצור להיטים בעזרת העוצמה של בינה מלאכותית",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "הדרך המהירה ביותר לגרסה המוצלחת ביותר",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "פרודוקטיביות",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "מקסימום פרודוקטיביות עם Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "זיהוי של \"זיוף עמוק\" באמצעות בינה מלאכותית לפני שיתוף",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "העולם שלך. במכשירים שלך.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "ניידות",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "שיתוף פעולה כמעט מכל מקום",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "סוללה שלא נגמרת באמצע",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "חיבור אוטומטי שלא מתנתק",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "כל הבידור שלך. מחשב אחד.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "מחשב המשמש גם כמרכז בידור זקוק לאחסון בגודל שיספיק לכל השירים, הסרטים והמשחקים האהובים, ולשילוב המתאים של מעבד ומסך כדי ליצור חוויה ויזואלית סוחפת. למחשב הזה יש את כל השלושה.",
                "titleArray": [
                  {
                    "tr_title": "גיימינג וסטרימינג מרשימים",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "לבנות את הספרייה דיגיטלית שלך",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "חלון לבידור שלך",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "גיימינג וסטרימינג חלקים",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "לבנות את הספרייה דיגיטלית שלך",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "חלון לבידור שלך",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "להיות פרודוקטיביים יותר. בכל מקום.",
              "tr_tileTitle1": "להספיק יותר. מהר.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "המחשב הזה הוא האיזון המושלם בין עוצמה לניידות. בנוסף למעבד שנועד לעיצובים דקים וקלים, המחשב הזה כולל גם Wi&#8209;Fi מהסוג שישאיר אתכם מחוברים וסוללה שלא תחייב אתכם להתחבר כל הזמן לחשמל.",
                "tr_subtitle1": "למחשב הזה יש את האיזון המושלם בין כוח וביצועים כדי לעזור לך לסיים יותר בפחות זמן. השילוב של מעבד חזק המתוגבר בשפע של זיכרון ו-WiFi מהיר כברק יריצו את המטלות היומיות שלך.",
                "tr_subtitle2": "המחשב הזה הוא האיזון המושלם בין עוצמה לניידות. בנוסף למעבד שנועד לעיצובים דקים וקלים, המחשב הזה כולל גם Wi‑Fi מהסוג שישאיר אתכם מחוברים וגם את הזיכרון המתאים לריבוי-משימות.",
                "titleArray": [
                  {
                    "tr_title": "ביצועים חכמים במה שחשוב",
                    "tr_title1": "מהירות גבוהה יותר עם ביצועים חכמים",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "החיבורים הטובים מסוגם",
                    "tr_title1": "להתחבר לדברים החשובים",
                    "tr_title2": "התקדמות משמעותית בתחום ה-Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "שימוש ממושך יותר ללא טעינה",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "לעבור בין תוכנות כמו מקצוענים",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "החופש ליצור",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "יצירת תוכן היא אחת מהמשימות התובעניות ביותר למחשב שלך. למרבה המזל, לרכיבים האלה יש את העוצמה להזרים את התהליך היצירתי שלך באופן חלק.",
                "titleArray": [
                  {
                    "tr_title": "האצת תהליכי יצירה",
                    "tr_title1": "פרויקטי יצירה מקבלים תנופה",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "שיפור תהליכי היצירה",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "זיכרון לריבוי משימות",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "מבט אל תוך המחשב",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "כשבוחרים מחשב חדש, צריך להתמקד בשלושה רכיבים עיקריים. כדי להחליט כמה משלושת הרכיבים העיקריים האלה הכרחיים, כדאי להחליט מה יהיה שימושו של המחשב החדש. אלו הם:",
                "titleArray": [
                  {
                    "tr_title": "מעבד",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "אחסון",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "זיכרון",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "החופש ליצור",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "יצירת תוכן היא אחת מהמשימות התובעניות ביותר למחשב שלך. למרבה המזל, לרכיבים האלה יש את העוצמה להזרים את התהליך היצירתי שלך באופן חלק.",
                "titleArray": [
                  {
                    "tr_title": "דרך חכמה יותר ליצירה",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "מפיחים חיים בתמונות",
                    "tr_title1": "זה הזמן ליצירה",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "זיכרון לריבוי משימות",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "להיות פרודוקטיביים יותר. בכל מקום.",
              "tr_tileTitle1": "להספיק יותר. מהר.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "המחשב הזה הוא האיזון המושלם בין עוצמה לניידות. בנוסף למעבד שנועד לעיצובים דקים וקלים, המחשב הזה כולל גם Wi&#8209;Fi מהסוג שישאיר אתכם מחוברים וסוללה שלא תחייב אתכם להתחבר כל הזמן לחשמל.",
                "tr_subtitle1": "למחשב הזה יש את האיזון המושלם בין כוח וביצועים כדי לעזור לך לסיים יותר בפחות זמן. השילוב של מעבד חזק המתוגבר בשפע של זיכרון ו-WiFi מהיר כברק יריצו את המטלות היומיות שלך.",
                "tr_subtitle2": "המחשב הזה הוא האיזון המושלם בין עוצמה לניידות. בנוסף למעבד שנועד לעיצובים דקים וקלים, המחשב הזה כולל גם Wi‑Fi מהסוג שישאיר אתכם מחוברים וגם את הזיכרון המתאים לריבוי-משימות.",
                "titleArray": [
                  {
                    "tr_title": "בנוי לעשות יותר",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "החיבורים הטובים מסוגם",
                    "tr_title1": "להתחבר לדברים החשובים",
                    "tr_title2": "התקדמות משמעותית בתחום ה-Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "שימוש ממושך יותר ללא טעינה",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "לעבור בין תוכנות כמו מקצוענים",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "מוכן למציאות מדומה",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "היכן שמציאות מדומה מתעוררת לחיים",
                "tr_subtitle": "מה דרוש ליצירת עולם וירטואלי לחלוטין? במחשב יש צורך בתיאום בין המעבד, הכרטיס הגרפי והזיכרון.",
                "titleArray": [
                  {
                    "tr_title": "מספקים תשתית לעולמות וירטואליים",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "המציאות נמצאת בפרטים הקטנים",
                    "tr_title1": "תנו לעצמכם להיסחף",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>תענוג לעיניים",
      "tr_sub_header": "אור כחול עלול לגרום לאי נוחות בעיניים ולשבש את מחזור השינה. צג OLED של סמסונג, בעל אישור של SGS Eye Care, מסייע לא באמצעות שינוי הצבעים, אלא על ידי הפחתת אורך הגל של אור כחול שעשוי להזיק, מעניק נוחות לעיניים ומפחית את התעייפותן.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "אור כחול שמזיק פחות"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "אולטרה בכל דרך",
      "tr_sub_header": "העיצוב הקל והדק במיוחד של OLED ממקסם את איכות התמונה ומקטין את המשקל."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "שחור אמיתי",
      "tr_sub_header": "0.0005nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74%<br/><span class='conventional_text'>רגיל</span>",
      "tr_sub_header": "120%<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "סטרימינג",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "סטרימינג",
                "tr_subtitle": "אינספור תכנים",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לסטרימינג:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "מעבד שיכול להזרים ב-HD."
                  },
                  {
                    "tr_title": "תצוגה של תמונות בהירות ויפות."
                  },
                  {
                    "tr_title": "Wi‑Fi עם פחות זמני אגירה וטעינה."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "גלישה באינטרנט",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "גלישה באינטרנט",
                "tr_subtitle": "לגלוש באינטרנט בקלות",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לגלישה באינטרנט:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "מעבד שיכול לטעון במהירות דפים עם מדיה עשירה."
                  },
                  {
                    "tr_title": "Wi‑Fi מהיר ואמין."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "שיחות וידאו",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "שיחות וידאו",
                "tr_subtitle": "חיבורים ברורים כשמש",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לשיחות וידאו:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "מעבד שיכול לשמור ולשתף תמונות בו זמנית."
                  },
                  {
                    "tr_title": "Wi‑Fi מהיר, חזק ואמין."
                  },
                  {
                    "tr_title": "מצלמת אינטרנט שתעזור לכם להופיע בצורה ברורה."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "ריבוי משימות במהירות גבוהה",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ריבוי משימות במהירות גבוהה",
                "tr_subtitle": "לעשות יותר מבלי להאט",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לריבוי משימות במהירות:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "מעבד שיכול להפעיל מספר תוכנות בו זמנית."
                  },
                  {
                    "tr_title": "Wi‑Fi מהיר, חזק ואמין."
                  },
                  {
                    "tr_title": "זיכרון שעובר בצורה חלקה בין אפליקציות."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "שיחות וידאו",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "שיחות וידאו",
                "tr_subtitle": "חיבורים ברורים כשמש",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לשיחות וידאו:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "מעבד שיכול לשמור ולשתף תמונות בו זמנית."
                  },
                  {
                    "tr_title": "Wi‑Fi מהיר, חזק ואמין."
                  },
                  {
                    "tr_title": "מצלמת אינטרנט שתעזור לכם להופיע בצורה ברורה."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "גיימינג קליל",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "גיימינג קליל",
                "tr_subtitle": "הגיע הזמן לשחק",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לגיימינג קליל:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "מעבד שמפעיל משחקים בצורה חלקה."
                  },
                  {
                    "tr_title": "זיכרון שיכול לתמוך בצ'אט קולי ובסטרימינג בו זמנית."
                  },
                  {
                    "tr_title": "גרפיקה שמציגה תמונות מדהימות."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "עריכת תמונות",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "עריכת תמונות",
                "tr_subtitle": "תמונות עם פיקסלים מושלמים",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לעריכת תמונות:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "מעבד עם יכולת לערוך קבוצות גדולות של תמונות."
                  },
                  {
                    "tr_title": "זיכרון שמאיץ את תוכנת העריכה שלכם."
                  },
                  {
                    "tr_title": "טכנולוגיית Thunderbolt™ 4 מאפשרת לכם להעביר קבצים במהירות."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "ריבוי משימות במהירות גבוהה",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ריבוי משימות במהירות גבוהה",
                "tr_subtitle": "לעשות יותר מבלי להאט",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לריבוי משימות במהירות:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "מעבד שיכול להפעיל מספר תוכנות בו זמנית."
                  },
                  {
                    "tr_title": "Wi‑Fi מהיר, חזק ואמין."
                  },
                  {
                    "tr_title": "זיכרון שעובר בצורה חלקה בין אפליקציות."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "שיתופי פעולה בזמן אמת",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "שיתופי פעולה בזמן אמת",
                "tr_subtitle": "עבודה מכל מקום",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לשיתופי פעולה מקוונים:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "מעבד להפעלת תוכנה משרדית."
                  },
                  {
                    "tr_title": "Wi‑Fi אמין."
                  },
                  {
                    "tr_title": "מצלמת אינטרנט מעולה להקלטות ב-HD."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "עריכת וידאו",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "עריכת וידאו",
                "tr_subtitle": "צרו סרטים קסומים",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לעריכת וידאו:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "מעבד עם יכולת להתמודד עם קבצים גדולים."
                  },
                  {
                    "tr_title": "זיכרון שהופך את תוכנת העריכה למהירה ובעלת יכולת תגובה."
                  },
                  {
                    "tr_title": "גרפיקה שמקצרת את זמני העיבוד."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "עריכת תמונות",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "עריכת תמונות",
                "tr_subtitle": "תמונות עם פיקסלים מושלמים",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לעריכת תמונות:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "מעבד עם יכולת לערוך קבוצות גדולות של תמונות."
                  },
                  {
                    "tr_title": "זיכרון שמאיץ את תוכנת העריכה שלכם."
                  },
                  {
                    "tr_title": "טכנולוגיית Thunderbolt™ 4 מאפשרת לכם להעביר קבצים במהירות."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "עריכת וידאו",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "עריכת וידאו",
                "tr_subtitle": "צרו סרטים קסומים",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לעריכת וידאו:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "מעבד עם יכולת להתמודד עם קבצים גדולים."
                  },
                  {
                    "tr_title": "זיכרון שהופך את תוכנת העריכה למהירה ובעלת יכולת תגובה."
                  },
                  {
                    "tr_title": "גרפיקה שמקצרת את זמני העיבוד."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "מידול בתלת-ממד",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "מידול בתלת-ממד",
                "tr_subtitle": "יצירות ברמה גבוהה",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר למידול בתלת-ממד:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "מעבד שיכול להתמודד עם תוכנות קריאיטיב מתקדמות."
                  },
                  {
                    "tr_title": "זיכרון עם יכולת להעלות קבצים גדולים במהירות."
                  },
                  {
                    "tr_title": "גרפיקה ליצירה ועריכה של תמונות מפורטות."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "שיתופי פעולה בזמן אמת",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "שיתופי פעולה בזמן אמת",
                "tr_subtitle": "עבודה מכל מקום",
                "tr_subtitle1": "הנה מה שמצאנו במחשב זה ונהדר לשיתופי פעולה מקוונים:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "מעבד להפעלת תוכנה משרדית."
                  },
                  {
                    "tr_title": "Wi‑Fi אמין."
                  },
                  {
                    "tr_title": "מצלמת אינטרנט מעולה להקלטות ב-HD."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "יישום Intel® Unison™‎",
    "tr_title": "גלה עולם חדש עם החיבור שלנו",
    "tr_subtitle": "העבר קבצים ותמונות בצורה חלקה תוך ניהול ההתראות, השיחות והודעות הטקסט של הטלפון מהמחשב שלך.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "העברת קבצים ותמונות",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "הוצאה וקבלת שיחות",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "שליחה וקבלה של הודעות טקסט",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "ניהול ההתראות של הטלפון",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "גלה עולם חדש עם החיבור שלנו",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}