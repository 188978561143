const Library_ChromeBook_mobile = {
    "uiText": {
        "tr_endlessProgramsAndApps": "Endless programs and apps",
        "tr_rethinkStorage": "Rethink storage",
        "tr_alwaysOnSecurity": "Built&#8209;in security",
        "tr_extendedBatteryLife": "Extended battery life<sup>1</sup>",
        "tr_viewMore": "View More",
        "tr_processorTitle": "PROCESSOR",
        "tr_processorTitleCamel": "Processor",
        "tr_rethinkStorageTitle": "RETHINK STORAGE",
        "tr_alwaysOnSecurityTitle": "BUILT&#8209;IN SECURITY",
        "tr_extendedBatteryLifeTitle": "EXTENDED BATTERY LIFE<sup>1</sup>",
        "tr_endlessProgramsAndAppsTitle": "ENDLESS PROGRAMS AND APPS",
        "tr_extendedBatteryLifeDescription": "[XX] hrs",
        "tr_rethinkStorageDescriptionGB": "[XX] GB",
        "tr_rethinkStorageDescriptionTB": "[XX] TB",
        "tr_OS": "OS",
        "tr_Chrome_OS": "ChromeOS*",
        "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD] GB HDD",
        "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD] TB HDD",
        "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD] GB HDD",
        "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD] TB HDD",
        "tr_textGBSSD": "[convertedSSD] GB SSD",
        "tr_textTBSSD": "[convertedSSD] TB SSD",
        "tr_textGBHDD": "[convertedHDD] GB HDD",
        "tr_textTBHDD": "[convertedHDD] TB HDD",
        "tr_graphicTitle": "GRAPHICS",
        "tr_storageTitle": "STORAGE",
        "tr_displayTitle": "DISPLAY",
        "tr_batteryTitle": "BATTERY",
        "tr_memoryTitle": "MEMORY",
        "tr_priceTitle": "PRICE",
    },
    "rethinkStorage":{     
        "details": {
            "tr_tileTitle": "Rethink Storage",
            "tr_title": "An innovative approach to storage",
            "tr_subtitle": "Chromebook* combines storage on your PC with the nearly unlimited resource of the Google* Drive in the cloud. Here are the benefits:",
            "badgeIcons": [{
                "badgeUrl": "assets/images/badges/cloud_upload.svg",
                "tr_badgeSubTitle": "All your files are automatically backed up to the cloud and recovering them is as simple as logging into your Google* account.",
                "badgeSubTitle": "cloud-upload"
            },
            {
                "badgeUrl": "assets/images/badges/one_linear.svg",
                "tr_badgeSubTitle": "Work online or offline with [XX] GB of device storage.",
                "badgeSubTitle": "GBALONE"
            },
            {
                "badgeUrl": "assets/images/badges/one_linear.svg",
                "tr_badgeSubTitle": "Work online or offline with [XX] TB of device storage.",
                "badgeSubTitle": "TBALONE"
            }
        ]
        }
    },
    "alwaysOnSecurity":{
        "details": {
            "tr_tileTitle": "Built&#8209;in security",
            "tr_title": "Effortless protection<sup class='sup-text'>2</sup>",
            "tr_subtitle": "Chromebook* automatically updates all of its security features so you can surf the web and click on links with the confidence that your device is better protected.",
        }
    },
    "extendedBatteryLife": {
        "tr_tileTitle": "Extended battery life<sup>1</sup>",
        "iposTypes": [{
            "iposType": "CHROMEBOOK",
            "details": {
                "tr_title": "Do more, charge less<sup class='sup-text'>1</sup>",
                "tr_subtitle": "The streamlined and energy-efficient design of a Chromebook* means you can go hours without having to plug in.",
            }
        },
        {
            "iposType": "CHROMEBOOKEVO",
            "details": {
                "tr_title": "Charge less.<br/>And recharge faster.<sup class='sup-text'>10</sup>",
                "tr_subtitle": "The streamlined and energy-efficient design of Chromebook* meets the demanding battery requirements of the Intel® Evo™ platform to allow you to charge quicker and go longer without recharging."
            }
        },
        {
            "iposType": "CHROMEBOOKEVOGEN12",
            "details": {
                "tr_title": "Battery life that keeps you mobile.<sup class='sup-text'>10</sup>",
                "tr_subtitle": "The fast-charging, long-lasting batteries in thin and light Chromebooks* mean you don’t have to worry about where the nearest outlet is again."
            }
        }]
    },
    "endlessProgramsAndApps":{
        "details": {
            "tr_tileTitle": "Endless programs and apps",
            "tr_title": "More apps every day",
            "tr_subtitle": "Chromebook* users have an enormous selection of apps and programs to choose from with more added all the time.",
            "badgeIcons": [{
                "badgeUrl": "assets/images/badges/download_badge.svg",
                "tr_badgeSubTitle": "Download Chrome* and Android* apps."
            },{
                "badgeUrl": "assets/images/badges/reset_badge.svg",
                "tr_badgeSubTitle": "All your apps and data are synched through your Google* account."
            },{
                "badgeUrl": "assets/images/badges/connect_badge.svg",
                "tr_badgeSubTitle": "Connect, learn, create, and play in endless ways."
            }]
        }
    },
    "processor": [
        {
            "contentType": "INTEL_CORE_I3_8",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Upgrade your expectations",
                        "tr_subtitle": "Effortlessly watch your favorite shows and movies on your Chromebook* with a 10th Gen Intel® Core™ i3 processor.",
                        "tr_gen_core_title":"10th Gen Intel® Core™ i3 processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_I5_8",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "Athena",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Designed specifically for exceptional performance anywhere.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"10th Gen Intel® Core™ i5 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Powered by a 10th Gen Intel® Core™ i5 processor"
                        }, 
                        {
                            "tr_badgeTitle": "Powerful, even when unplugged"
                        },
                        {
                            "tr_badgeTitle": "Quick charge: 4 hours worth of battery in 30 min<sup>x</sup>"
                        },
                        {
                            "tr_badgeTitle": "Best-in-class connectivity with Intel® Wi&#8209;Fi 6 (Gig+)<sup>x</sup>"
                        }]                        
                    }
                },
                {
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Real work. Real play.",
                        "tr_subtitle": "Video chat, email and craft a presentation all while sharpening your productivity on a Chromebook* with a 10th Gen Intel® Core™ i5 processor.",
                        "tr_gen_core_title":"10th Gen Intel® Core™ i5 processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_I7_8",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "Athena",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Designed specifically for exceptional performance anywhere.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"10th Gen Intel® Core™ i7 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Powered by a 10th Gen Intel® Core™ i7 processor"
                        },
                        {
                            "tr_badgeTitle": "Powerful, even when unplugged"
                        },
                        {
                            "tr_badgeTitle": "Quick charge: 4 hours worth of battery in 30 min<sup>x</sup>"
                        },
                        {
                            "tr_badgeTitle": "Best-in-class connectivity with Intel® Wi&#8209;Fi 6 (Gig+)<sup>x</sup>"
                        }]
                    }
                },
                {
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Because performance matters",
                        "tr_subtitle": "Edit your videos with ease and instantly upload and share memories on a Chromebook* with a 10th Gen Intel® Core™ i7 processor.",
                        "tr_gen_core_title":"10th Gen Intel® Core™ i7 processor",
                        
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_I3_6",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Quick responsive performance",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"8th Gen Intel® Core™ i3 processor",
                        
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_I5_6",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Power your passion and productivity",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"8th Gen Intel® Core™ i5 processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_ULTRA_5_1",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Power your passion and productivity",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"Intel® Core™ Ultra 5 processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_ULTRA_7_1",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Power your passion and productivity",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"Intel® Core™ Ultra 7 processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_ULTRA_9_1",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Power your passion and productivity",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"Intel® Core™ Ultra 9 processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_M3_3", // 8th gen m3
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Quick responsive performance",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"8th Gen Intel® Core™ m3 processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_I5_5",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "High performance for what you do today",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"7th Gen Intel® Core™ i5 processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_I7_5",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Premium performance for what you do today",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"7th Gen Intel® Core™ i7 processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_M3_2", // 7th gen m3
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Performance for what you do today",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"7th Gen Intel® Core™ m3 processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_I5_4",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Elevated performance for what you need",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"6th Gen Intel® Core™ i5 processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_I7_4",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Advanced performance for what you need",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"6th Gen Intel® Core™ i7 processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_M3_1", // 6th gen m3
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Performance for what you need",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"6th Gen Intel® Core™ m3 processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_I3_9",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Breaking the boundaries of performance in Chromebooks*",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"11th Gen Intel® Core™ i3 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Up to <br/><span class='big-blue-text'>2.4X </span><span class='subt-text'>better performance<sup>4</sup></span>"
                        }, 
                        {
                            "tr_badgeTitle": "Up to <br/><span class='big-blue-text'>3.2X </span><span class='subt-text'>faster web browsing<sup>5</sup></span>"
                        },
                        {
                            "tr_badgeTitle": "Up to <br/><span class='big-blue-text'>2.1X </span><span class='subt-text'>faster video editing<sup>6</sup></span>"
                        }]                        
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_I5_9",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Imagine what you can accomplish with Chromebook*",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"11th Gen Intel® Core™ i5 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Up to <br/><span class='big-blue-text'>2.7X </span><span class='subt-text'>better performance<sup>4</sup></span>"
                        }, 
                        {
                            "tr_badgeTitle": "Up to <br/><span class='big-blue-text'>3.3X </span><span class='subt-text'>faster web browsing<sup>5</sup></span>"
                        },
                        {
                            "tr_badgeTitle": "Up to <br/><span class='big-blue-text'>2.8X </span><span class='subt-text'>faster video editing<sup>6</sup></span>"
                        }]                        
                    }
                }]
            },
            {
                "iposType": "EVO",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Engineered for mobile performance",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"11th Gen Intel® Core™ i5 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Boundary&#8209;breaking performance with 11th Gen Intel® Core™ processors",
                        },
                        {
                            "tr_badgeTitle": "Wakes in < 1 second<sup>8</sup>",
                        },
                        {
                            "tr_badgeTitle": "Nearly 3x faster internet with Intel® Wi&#8209;Fi 6 (Gig+)<sup>9</sup>",
                        },
                        {
                            "tr_badgeTitle": "Fast&#8209;charging, long&#8209;lasting battery life",
                        }] 
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_I7_9",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "World’s best processor for Chromebooks*<sup class='sup-text'>1</sup>",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"11th Gen Intel® Core™ i7 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Up to <br/><span class='big-blue-text'>2.9X </span><span class='subt-text'>better performance<sup>4</sup></span>"
                        }, 
                        {
                            "tr_badgeTitle": "Up to <br/><span class='big-blue-text'>3.5X </span><span class='subt-text'>faster web browsing<sup>5</sup></span>"
                        },
                        {
                            "tr_badgeTitle": "Up to <br/><span class='big-blue-text'>2.9X </span><span class='subt-text'>faster video editing<sup>6</sup></span>"
                        }]                        
                    }
                }]
            },
            {
                "iposType": "EVO",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/EVO_I7.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Engineered for mobile performance",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"11th Gen Intel® Core™ i7 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Boundary&#8209;breaking performance with 11th Gen Intel® Core™ processors",
                        },
                        {
                            "tr_badgeTitle": "Wakes in < 1 second<sup>8</sup>",
                        },
                        {
                            "tr_badgeTitle": "Nearly 3x faster internet with Intel® Wi&#8209;Fi 6 (Gig+)<sup>9</sup>",
                        },
                        {
                            "tr_badgeTitle": "Fast&#8209;charging, long&#8209;lasting battery life",
                        }] 
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_PENTIUM_GOLD_2",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Fast, everyday performance",
                        "tr_subtitle": "Get excellent performance from all your favorite programs and apps with the power of an Intel® Pentium® processor",
                        "tr_gen_core_title":"Intel® Pentium® Gold processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_PENTIUM_SILVER_2",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Fast, everyday performance",
                        "tr_subtitle": "Get excellent performance from all your favorite programs and apps with the power of an Intel® Pentium® processor",
                        "tr_gen_core_title":"Intel® Pentium® Silver processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CELERON_3",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Boost your daily productivity",
                        "tr_subtitle": "From launching apps to opening projects, an Intel® Celeron® processor will help you accomplish more.",
                        "tr_gen_core_title":"Intel® Celeron® processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_PENTIUM_2",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Performance and value.",
                        "tr_subtitle": "Built for great entertainment, video streaming and productivity. Enjoy movies and web videos in amazing details. Spend more time doing and less time waiting.",
                        "tr_gen_core_title": "Intel® Pentium® processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_PENTIUM_3",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Fast, everyday performance",
                        "tr_subtitle": "Get excellent performance from all your favorite programs and apps with the power of an Intel® Pentium® processor",
                        "tr_gen_core_title": "Intel® Pentium® processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_PENTIUM_GOLD_1",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Performance and value.",   
                        "tr_subtitle": "Enhanced Productivity - Elevated performance to power through your most demanding days. Streamlined Web Browsing - Quickly load media-rich web pages for more exploring and less waiting.",
                        "tr_gen_core_title": "Intel® Pentium® Gold processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_PENTIUM_SILVER_1",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Performance and value.",
                        "tr_subtitle": "Stay on top of your digital world with the performance to access files from the web. Simple multi-task by easily switching between programs and get more done in less time.",
                        "tr_gen_core_title": "Intel® Pentium® Silver processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CELERON_2",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Performance and value.",
                        "tr_subtitle": "Performance to amp up your work and entertainment. Enjoy movies and web videos in amazing detail. Spend more time doing and less time waiting.",
                        "tr_gen_core_title": "Intel® Celeron® processor",
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_I3_12",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Reliable performance where it’s needed most.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"12th Gen Intel® Core™ i3 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Multitask<br/>with ease"
                        }, 
                        {
                            "tr_badgeTitle": "Built-in<br/>background blur"
                        },
                        {
                            "tr_badgeTitle": "Responsive<br/>web browsing"
                        }]                        
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_I5_12",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "A boost of performance where it’s needed most.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"12th Gen Intel® Core™ i5 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Powerful<br/>multitasking"
                        }, 
                        {
                            "tr_badgeTitle": "Auto-noise<br/>suppression"
                        },
                        {
                            "tr_badgeTitle": "HD<br/>streaming"
                        }]                        
                    }
                }]
            },
            {
                "iposType": "EVO",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Mobile performance has evolved.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"12th Gen Intel® Core™ i5 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Superior performance where you need it most, powered by 12th Gen Intel® Core™ processors<sup>11</sup>"
                        },
                        {
                            "tr_badgeTitle": "Wakes in < 1 second<sup>8</sup>"
                        },
                        {
                            "tr_badgeTitle": "Nearly 3x faster internet with Intel® Wi&#8209;Fi 6/6E (Gig+)<sup>9</sup>"
                        },
                        {
                            "tr_badgeTitle": "Fast&#8209;charging, long&#8209;lasting battery life"
                        },
                        {
                            "tr_badgeTitle": "Enhanced collaboration and connectivity"
                        }] 
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_I7_12",
            "iposTypes": [{
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Superior performance where you need it most.<sup>11</sup>",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"12th Gen Intel® Core™ i7 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Extreme<br/>multitasking"
                        }, 
                        {
                            "tr_badgeTitle": "Built-in noise<br/>suppression and<br/>background blur"
                        },
                        {
                            "tr_badgeTitle": "Smooth<br/>8K streaming"
                        }]                        
                    }
                }]
            },{
                "iposType": "EVO",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Mobile performance has evolved.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"12th Gen Intel® Core™ i7 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Superior performance where you need it most, powered by 12th Gen Intel® Core™ processors<sup>11</sup>"
                        },
                        {
                            "tr_badgeTitle": "Wakes in < 1 second<sup>8</sup>"
                        },
                        {
                            "tr_badgeTitle": "Nearly 3x faster internet with Intel® Wi&#8209;Fi 6/6E (Gig+)<sup>9</sup>"
                        },
                        {
                            "tr_badgeTitle": "Fast&#8209;charging, long&#8209;lasting battery life"
                        },
                        {
                            "tr_badgeTitle": "Enhanced collaboration and connectivity"
                        }] 
                    }
                }]
            }
        ]
        },
        {
            "contentType": "INTEL_CORE_I5_13",
            "iposTypes": [
            {
                "iposType": "EVO",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Chromebooks*.<br/>Evolved.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"13th Gen Intel® Core™ i5 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Thin and light design that extends beyond performance<br/><span class='marklist'>and</span> seamlessly connects with your existing phone <sup>13</sup><br/><span class='marklist'>and</span> intelligently selects the best Wi-Fi or 5G connection<br/><span class='marklist'>and</span> charges fast with a battery that lasts"
                        }] 
                    }
                }]
            },
            {
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Do what you do with exceptional performance.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"13th Gen Intel® Core™ i5 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Enhanced<br/>multitasking"
                        }, 
                        {
                            "tr_badgeTitle": "Smooth 4K<br/>streaming"
                        },
                        {
                            "tr_badgeTitle": "Powerful<br/>creating"
                        }]                        
                    }
                }]
            }
        ]
        },
        {
            "contentType": "INTEL_CORE_I7_13",
            "iposTypes": [
            {
                "iposType": "EVO",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Chromebooks*.<br/>Evolved.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"13th Gen Intel® Core™ i7 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Thin and light design that extends beyond performance<br/><span class='marklist'>and</span> seamlessly connects with your existing phone <sup>13</sup><br/><span class='marklist'>and</span> intelligently selects the best Wi-Fi or 5G connection<br/><span class='marklist'>and</span> charges fast with a battery that lasts"
                        }] 
                    }
                }]
            },
            {
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Do what you do with beyond superior performance.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"13th Gen Intel® Core™ i7 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Extreme<br/>multitasking"
                        }, 
                        {
                            "tr_badgeTitle": "Smooth 8K<br/>streaming"
                        },
                        {
                            "tr_badgeTitle": "Advanced<br/>creating"
                        }]                        
                    }
                }]
            }
        ]
        },
        {
            "contentType": "INTEL_INSIDE_13",
            "iposTypes": [
            {
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop",
                        "tr_title": "Intel® Processor",
                        "tr_subtitle": "Performance you need. Affordability you want.",
                        "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
                    }
                }]
            }]
        },
        {
            "contentType": "INTEL_CORE_I3_13",
            "iposTypes": [
            {
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Do what you do with outstanding performance.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"13th Gen Intel® Core™ i3 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Easy<br/>multitasking"
                        }, 
                        {
                            "tr_badgeTitle": "Smooth<br/>streaming"
                        },
                        {
                            "tr_badgeTitle": "Fast<br/>creating"
                        }]                        
                    }
                }]
            }
        ]
        },
        {
            "contentType": "INTEL_CORE_I3_14",
            "iposTypes": [
                {
                    "iposType": "EVO",
                    "deviceTypes": [{
                        "deviceType": "DEFAULT",
                        "details": {
                            "tr_tileTitle": "Processor",
                            "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                            "tr_pretitle": "",
                            "panelType1": "chromebook-header-BadgeIconTop",
                            "tr_title": "Chromebooks*.<br/>Evolved.",
                            "tr_subtitle": "",
                            "tr_gen_core_title":"Intel® Core™ i3 processor",
                            "badgeIcons": [{
                                "tr_badgeTitle": "Thin and light design that extends beyond performance<br/><span class='marklist'>and</span> seamlessly connects with your existing phone <sup>13</sup><br/><span class='marklist'>and</span> intelligently selects the best Wi-Fi or 5G connection<br/><span class='marklist'>and</span> charges fast with a battery that lasts"
                            }] 
                        }
                    }]
                },
            {
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Do what you do with outstanding performance.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"Intel® Core™ i3 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Easy<br/>multitasking"
                        }, 
                        {
                            "tr_badgeTitle": "Smooth<br/>streaming"
                        },
                        {
                            "tr_badgeTitle": "Fast<br/>creating"
                        }]                        
                    }
                }]
            },
        ]
        },
        {
            "contentType": "INTEL_CORE_I5_14",
            "iposTypes": [
                {
                    "iposType": "EVO",
                    "deviceTypes": [{
                        "deviceType": "DEFAULT",
                        "details": {
                            "tr_tileTitle": "Processor",
                            "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                            "tr_pretitle": "",
                            "panelType1": "chromebook-header-BadgeIconTop",
                            "tr_title": "Chromebooks*.<br/>Evolved.",
                            "tr_subtitle": "",
                            "tr_gen_core_title":"Intel® Core™ i5 processor",
                            "badgeIcons": [{
                                "tr_badgeTitle": "Thin and light design that extends beyond performance<br/><span class='marklist'>and</span> seamlessly connects with your existing phone <sup>13</sup><br/><span class='marklist'>and</span> intelligently selects the best Wi-Fi or 5G connection<br/><span class='marklist'>and</span> charges fast with a battery that lasts"
                            }] 
                        }
                    }]
                },
            {
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Do what you do with exceptional performance.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"Intel® Core™ i5 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Enhanced<br/>multitasking"
                        }, 
                        {
                            "tr_badgeTitle": "Smooth 4K<br/>streaming"
                        },
                        {
                            "tr_badgeTitle": "Powerful<br/>creating"
                        }]                        
                    }
                }]
            }
        ]
        },
        {
            "contentType": "INTEL_CORE_I7_14",
            "iposTypes": [
                {
                    "iposType": "EVO",
                    "deviceTypes": [{
                        "deviceType": "DEFAULT",
                        "details": {
                            "tr_tileTitle": "Processor",
                            "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                            "tr_pretitle": "",
                            "panelType1": "chromebook-header-BadgeIconTop",
                            "tr_title": "Chromebooks*.<br/>Evolved.",
                            "tr_subtitle": "",
                            "tr_gen_core_title":"Intel® Core™ i7 processor",
                            "badgeIcons": [{
                                "tr_badgeTitle": "Thin and light design that extends beyond performance<br/><span class='marklist'>and</span> seamlessly connects with your existing phone <sup>13</sup><br/><span class='marklist'>and</span> intelligently selects the best Wi-Fi or 5G connection<br/><span class='marklist'>and</span> charges fast with a battery that lasts"
                            }] 
                        }
                    }]
                },
            {
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Do what you do with beyond superior performance.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"Intel® Core™ i7 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Extreme<br/>multitasking"
                        }, 
                        {
                            "tr_badgeTitle": "Smooth 8K<br/>streaming"
                        },
                        {
                            "tr_badgeTitle": "Advanced<br/>creating"
                        }]                        
                    }
                }]
            }
        ]
        },
        {
            "contentType": "INTEL_CORE_I9_14",
            "iposTypes": [
                {
                    "iposType": "EVO",
                    "deviceTypes": [{
                        "deviceType": "DEFAULT",
                        "details": {
                            "tr_tileTitle": "Processor",
                            "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                            "tr_pretitle": "",
                            "panelType1": "chromebook-header-BadgeIconTop",
                            "tr_title": "Chromebooks*.<br/>Evolved.",
                            "tr_subtitle": "",
                            "tr_gen_core_title":"Intel® Core™ i9 processor",
                            "badgeIcons": [{
                                "tr_badgeTitle": "Thin and light design that extends beyond performance<br/><span class='marklist'>and</span> seamlessly connects with your existing phone <sup>13</sup><br/><span class='marklist'>and</span> intelligently selects the best Wi-Fi or 5G connection<br/><span class='marklist'>and</span> charges fast with a battery that lasts"
                            }] 
                        }
                    }]
                },
            {
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Power your passions with superior performance",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"Intel® Core™ i9 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Fast photo<br/>and video editing"
                        }, 
                        {
                            "tr_badgeTitle": "Improved offline<br/>capabilities"
                        },
                        {
                            "tr_badgeTitle": "Play games<br/>worry-free with<br/>long battery life"
                        }]                        
                    }
                }]
            }
        ]
        },
        {
            "contentType": "INTEL_CORE_3",
            "iposTypes": [
                {
                    "iposType": "EVO",
                    "deviceTypes": [{
                        "deviceType": "DEFAULT",
                        "details": {
                            "tr_tileTitle": "Processor",
                            "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                            "tr_pretitle": "",
                            "panelType1": "chromebook-header-BadgeIconTop",
                            "tr_title": "Chromebooks*.<br/>Evolved.",
                            "tr_subtitle": "",
                            "tr_gen_core_title":"Intel® Core™ 3 processor",
                            "badgeIcons": [{
                                "tr_badgeTitle": "Thin and light design that extends beyond performance<br/><span class='marklist'>and</span> seamlessly connects with your existing phone <sup>13</sup><br/><span class='marklist'>and</span> intelligently selects the best Wi-Fi or 5G connection<br/><span class='marklist'>and</span> charges fast with a battery that lasts"
                            }] 
                        }
                    }]
                },
            {
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Do what you do with outstanding performance.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"Intel® Core™ 3 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Easy<br/>multitasking"
                        }, 
                        {
                            "tr_badgeTitle": "Smooth<br/>streaming"
                        },
                        {
                            "tr_badgeTitle": "Fast<br/>creating"
                        }]                        
                    }
                }]
            }
        ]
        },
        {
            "contentType": "INTEL_CORE_5",
            "iposTypes": [
                {
                    "iposType": "EVO",
                    "deviceTypes": [{
                        "deviceType": "DEFAULT",
                        "details": {
                            "tr_tileTitle": "Processor",
                            "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                            "tr_pretitle": "",
                            "panelType1": "chromebook-header-BadgeIconTop",
                            "tr_title": "Chromebooks*.<br/>Evolved.",
                            "tr_subtitle": "",
                            "tr_gen_core_title":"Intel® Core™ 5 processor",
                            "badgeIcons": [{
                                "tr_badgeTitle": "Thin and light design that extends beyond performance<br/><span class='marklist'>and</span> seamlessly connects with your existing phone <sup>13</sup><br/><span class='marklist'>and</span> intelligently selects the best Wi-Fi or 5G connection<br/><span class='marklist'>and</span> charges fast with a battery that lasts"
                            }] 
                        }
                    }]
                },
            {
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Do what you do with exceptional performance.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"Intel® Core™ 5 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Enhanced<br/>multitasking"
                        }, 
                        {
                            "tr_badgeTitle": "Smooth 4K<br/>streaming"
                        },
                        {
                            "tr_badgeTitle": "Powerful<br/>creating"
                        }]                        
                    }
                }]
            }
        ]
        },
        {
            "contentType": "INTEL_CORE_7",
            "iposTypes": [
                {
                    "iposType": "EVO",
                    "deviceTypes": [{
                        "deviceType": "DEFAULT",
                        "details": {
                            "tr_tileTitle": "Processor",
                            "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                            "tr_pretitle": "",
                            "panelType1": "chromebook-header-BadgeIconTop",
                            "tr_title": "Chromebooks*.<br/>Evolved.",
                            "tr_subtitle": "",
                            "tr_gen_core_title":"Intel® Core™ 7 processor",
                            "badgeIcons": [{
                                "tr_badgeTitle": "Thin and light design that extends beyond performance<br/><span class='marklist'>and</span> seamlessly connects with your existing phone <sup>13</sup><br/><span class='marklist'>and</span> intelligently selects the best Wi-Fi or 5G connection<br/><span class='marklist'>and</span> charges fast with a battery that lasts"
                            }] 
                        }
                    }]
                },
            {
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Do what you do with beyond superior performance.",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"Intel® Core™ 7 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Extreme<br/>multitasking"
                        }, 
                        {
                            "tr_badgeTitle": "Smooth 8K<br/>streaming"
                        },
                        {
                            "tr_badgeTitle": "Advanced<br/>creating"
                        }]                        
                    }
                }]
            }
        ]
        },
        {
            "contentType": "INTEL_CORE_ULTRA_5_MTL",
            "iposTypes": [
                {
                    "iposType": "EVO",
                    "deviceTypes": [{
                        "deviceType": "DEFAULT",
                        "details": {
                            "tr_tileTitle": "Processor",
                            "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                            "tr_pretitle": "",
                            "panelType1": "chromebook-header-BadgeIconTop",
                            "tr_title": "Chromebooks*.<br/>Evolved.",
                            "tr_subtitle": "",
                            "tr_gen_core_title":"Intel® Core™ Ultra 5 processor",
                            "badgeIcons": [{
                                "tr_badgeTitle": "Thin and light design that extends beyond performance<br/><span class='marklist'>and</span> seamlessly connects with your existing phone <sup>13</sup><br/><span class='marklist'>and</span> intelligently selects the best Wi-Fi or 5G connection<br/><span class='marklist'>and</span> charges fast with a battery that lasts"
                            }] 
                        }
                    }]
                },
            {
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Unlock new AI experiences",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"Intel® Core™ Ultra 5 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Up to 65% faster<br/>generative AI"
                        }, 
                        {
                            "tr_badgeTitle": "Up to 2.3X faster<br/>AI video editing"
                        }
                       ]                        
                    }
                }]
            }
        ]
        },
        {
            "contentType": "INTEL_CORE_ULTRA_7_MTL",
            "iposTypes": [
                {
                    "iposType": "EVO",
                    "deviceTypes": [{
                        "deviceType": "DEFAULT",
                        "details": {
                            "tr_tileTitle": "Processor",
                            "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                            "tr_pretitle": "",
                            "panelType1": "chromebook-header-BadgeIconTop",
                            "tr_title": "Chromebooks*.<br/>Evolved.",
                            "tr_subtitle": "",
                            "tr_gen_core_title":"Intel® Core™ Ultra 7 processor",
                            "badgeIcons": [{
                                "tr_badgeTitle": "Thin and light design that extends beyond performance<br/><span class='marklist'>and</span> seamlessly connects with your existing phone <sup>13</sup><br/><span class='marklist'>and</span> intelligently selects the best Wi-Fi or 5G connection<br/><span class='marklist'>and</span> charges fast with a battery that lasts"
                            }] 
                        }
                    }]
                },
            {
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Unlock new AI experiences",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"Intel® Core™ Ultra 7 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Up to 65% faster<br/>generative AI"
                        }, 
                        {
                            "tr_badgeTitle": "Up to 2.3X faster<br/>AI video editing"
                        }
                       ]                        
                    }
                }]
            }
        ]
        },
        {
            "contentType": "INTEL_CORE_ULTRA_9_MTL",
            "iposTypes": [
                {
                    "iposType": "EVO",
                    "deviceTypes": [{
                        "deviceType": "DEFAULT",
                        "details": {
                            "tr_tileTitle": "Processor",
                            "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                            "tr_pretitle": "",
                            "panelType1": "chromebook-header-BadgeIconTop",
                            "tr_title": "Chromebooks*.<br/>Evolved.",
                            "tr_subtitle": "",
                            "tr_gen_core_title":"Intel® Core™ Ultra 9 processor",
                            "badgeIcons": [{
                                "tr_badgeTitle": "Thin and light design that extends beyond performance<br/><span class='marklist'>and</span> seamlessly connects with your existing phone <sup>13</sup><br/><span class='marklist'>and</span> intelligently selects the best Wi-Fi or 5G connection<br/><span class='marklist'>and</span> charges fast with a battery that lasts"
                            }] 
                        }
                    }]
                },
            {
                "iposType": "STANDARD",
                "deviceTypes": [{
                    "deviceType": "DEFAULT",
                    "details": {
                        "tr_tileTitle": "Processor",
                        "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                        "tr_pretitle": "",
                        "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                        "tr_title": "Unlock new AI experiences",
                        "tr_subtitle": "",
                        "tr_gen_core_title":"Intel® Core™ Ultra 9 processor",
                        "badgeIcons": [{
                            "tr_badgeTitle": "Up to 65% faster<br/>generative AI"
                        }, 
                        {
                            "tr_badgeTitle": "Up to 2.3X faster<br/>AI video editing"
                        }
                       ]                        
                    }
                }]
            }
        ]
        }

    ],
    "legalText":         
    {
        "tr_specTitle": "Legal",
        "tr_paragraph_array": [
            "¹Up to 10 hours of battery life based on a full charge according to internal Google* testing.",
            "²No product or component can be absolutely secure. Check with your system manufacturer or retailer or learn more at intel.com.",
            "³As measured by industry benchmarks, Representative Usage Guide testing and unique features of Intel® Core™ i7-1165G7 processor. For workloads and configurations visit www.intel.com/PerformanceIndex. Results may vary.",
            "⁴As measured by Google Sheets Multitasking.",
            "⁵As measured by Speedometer 2.0.",
            "⁶As measured by Kinemaster Export Video.",
            "For footnotes 4-6, all testing completed on Intel® Core™ i3-1115G4 processor vs. Intel® Celeron® N4020 processor. Visit www.intel.com/PerformanceIndex for more workload and configuration details. Performance varies by use, configuration and other factors. Learn more at www.intel.com/PerformanceIndex. Performance results are based on testing as of dates shown in configurations and may not reflect all publicly available updates.",
            "⁷Time taken to drain from 100% to critical battery level while performing typical workflows in a realistic environment. Results may vary. Details at intel.com/evo.",
            "⁸Results may vary. Details at intel.com/evo.",
            "⁹Nearly 3x faster: 802.11ax 2x2 160 MHz enables 2402 Mbps maximum theoretical data rates. ~3x faster than standard 802.11ac 2x2 80 MHz (867 Mbps) Wi&#8209;Fi as documented in IEEE 802.11* wireless standard specifications and requires the use of similarly configured 802.11ax wireless network routers.",
            "¹⁰Charge attained from OEM-default shutdown level. Results may vary. Details at intel.com/evo.",
            "¹¹As compared to previous processor generation.",
            "¹²As measured by unique features of Intel® Evo™ designs such as high performing Intel® Core™ CPUs, premium components, unmatched compatibility, and the latest connectivity solutions in sleek innovative designs. All Intel® Evo™ branded designs must meet demanding thresholds for key mobile user experiences like responsiveness and battery life; individual device performance may vary. Details at www.intel.com/PerformanceIndex (platforms).",
            "¹³Intel® Unison™ solution is currently only available on eligible Intel® Evo™ designs on Windows*-based PCs and only pairs with Android* or iOS* based phones. All devices must run supported OS version. Individual Intel® Evo™ device performance may vary. Details at intel.com/Performance-Evo.",
            "Intel® technologies’ features and benefits depend on system configuration and may require enabled hardware, software and service activation. Performance varies depending on system configuration. No computer system can be absolutely secure. Check with your system manufacturer or retailer or learn more at intel.com.",
            "*Other names and brands may be claimed as the property of others.",
            "Intel accepts no liability for incorrect prices.",
            "© 2022 Intel Corporation. Celeron, Intel, the Intel logo, Intel Core, and Pentium are trademarks of Intel Corporation or its subsidiaries."
        ],
    } 
}