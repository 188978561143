const Library_ChromeBook_mobile_zhTW = {
  "uiText": {
    "tr_endlessProgramsAndApps": "無限的程式與應用程式",
    "tr_rethinkStorage": "重新思考儲存裝置",
    "tr_alwaysOnSecurity": "內建安全措施",
    "tr_extendedBatteryLife": "長效的電池續航力<sup>1</sup>",
    "tr_viewMore": "查看更多",
    "tr_processorTitle": "處理器",
    "tr_processorTitleCamel": "處理器",
    "tr_rethinkStorageTitle": "重新思考儲存裝置",
    "tr_alwaysOnSecurityTitle": "內建安全措施",
    "tr_extendedBatteryLifeTitle": "長效的電池續航力<sup>1</sup>",
    "tr_endlessProgramsAndAppsTitle": "無限的程式與應用程式",
    "tr_extendedBatteryLifeDescription": "[XX] 小時",
    "tr_rethinkStorageDescriptionGB": "[XX] GB",
    "tr_rethinkStorageDescriptionTB": "[XX] TB",
    "tr_OS": "作業系統",
    "tr_Chrome_OS": "ChromeOS*",
    "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD] GB HDD",
    "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD] TB HDD",
    "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textGBSSD": "[convertedSSD] GB SSD",
    "tr_textTBSSD": "[convertedSSD] TB SSD",
    "tr_textGBHDD": "[convertedHDD] GB HDD",
    "tr_textTBHDD": "[convertedHDD] TB HDD",
    "tr_graphicTitle": "顯示晶片",
    "tr_storageTitle": "儲存空間",
    "tr_displayTitle": "顯示器",
    "tr_batteryTitle": "電池",
    "tr_memoryTitle": "記憶體",
    "tr_priceTitle": "價格"
  },
  "rethinkStorage": {
    "details": {
      "tr_tileTitle": "重新思考儲存空間",
      "tr_title": "創新的儲存方式",
      "tr_subtitle": "Chromebook* 結合電腦上的儲存空間與幾乎無限制的 Google* 雲端硬碟資源。優點如下：",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/cloud_upload.svg",
          "tr_badgeSubTitle": "所有檔案都自動備份到雲端，復原檔案就和登入 Google* 帳&#65279;戶一樣簡單。",
          "badgeSubTitle": "cloud-upload"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "透過 [XX] GB 的裝置儲存空間，線上或離線工作皆宜。",
          "badgeSubTitle": "GBALONE"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "透過 [XX] TB 的裝置儲存空間，線上或離線工作皆宜。",
          "badgeSubTitle": "TBALONE"
        }
      ]
    }
  },
  "alwaysOnSecurity": {
    "details": {
      "tr_tileTitle": "內建安全措施",
      "tr_title": "輕鬆防護<sup class='sup-text'>2</sup>",
      "tr_subtitle": "Chromebook* 會自動更新其所有安全功能，讓您在瀏&#65279;覽網&#65279;頁和點擊連結時，可放心知&#65279;道裝&#65279;置已獲&#65279;得更嚴&#65279;密的防&#65279;護。"
    }
  },
  "extendedBatteryLife": {
    "tr_tileTitle": "長效的電池續航力<sup>1</sup>",
    "iposTypes": [
      {
        "iposType": "CHROMEBOOK",
        "details": {
          "tr_title": "表現更佳充&#65279;電&#65279;次&#65279;數&#65279;更&#65279;少<sup class='sup-text'>1</sup>",
          "tr_subtitle": "Chromebook* 的精簡高效節能設計代表您可以持續使用數小時而不需連接電源插座。"
        }
      },
      {
        "iposType": "CHROMEBOOKEVO",
        "details": {
          "tr_title": "充電次數更少<br/>充電速度更快<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Chromebook* 的流線型外觀和節能設計，可滿足 Intel® Evo™ 平台對電池的嚴苛要求，讓您能更快充電並延長續航力。"
        }
      },
      {
        "iposType": "CHROMEBOOKEVOGEN12",
        "details": {
          "tr_title": "讓您保持行動力的電池續航力。<sup class='sup-text'>10</sup>",
          "tr_subtitle": "輕薄型 Chromebooks* 採用可快速充電的長效電池，意味著您不用再為了找最近的插座而憂心。"
        }
      }
    ]
  },
  "endlessProgramsAndApps": {
    "details": {
      "tr_tileTitle": "無限的程式與應用程式",
      "tr_title": "每天都有應用程式新&#65279;上&#65279;架",
      "tr_subtitle": "Chromebook* 使用者擁有無止盡的應用程式任挑選，且數量不斷增加中。",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/download_badge.svg",
          "tr_badgeSubTitle": "下載 Chrome* 和 Android* 應用程式。"
        },
        {
          "badgeUrl": "assets/images/badges/reset_badge.svg",
          "tr_badgeSubTitle": "您所有的應用程式和資料都透過 Google* 帳戶同步。"
        },
        {
          "badgeUrl": "assets/images/badges/connect_badge.svg",
          "tr_badgeSubTitle": "連線、學習、創作和遊戲無止盡。"
        }
      ]
    }
  },
  "processor": [
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "提高您的期望",
                "tr_subtitle": "在搭載第 10 代 Intel® Core™ i3 處理器的 Chromebook* 上觀賞您最喜愛的節目和電影。",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i3 處&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "專為在任何地方實現卓&#65279;越&#65279;效&#65279;能&#65279;而&#65279;設&#65279;計",
                "tr_subtitle": "",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "搭載第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器"
                  },
                  {
                    "tr_badgeTitle": "不插電，效能依然強大"
                  },
                  {
                    "tr_badgeTitle": "快速充電 30 分鐘，電池續航力可達 4 小時<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+) 提供同級最佳連線能力<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "踏實工作<br/>認真玩樂",
                "tr_subtitle": "使用搭載第 10 代 Intel® Core™ i5 處理器的 Chromebook* 視訊聊天、收發電子郵件和製作簡報，同時提高您的生產力。",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "專為在任何地方實現卓&#65279;越&#65279;效&#65279;能&#65279;而&#65279;設&#65279;計",
                "tr_subtitle": "",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "搭載第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器"
                  },
                  {
                    "tr_badgeTitle": "不插電，效能依然強大"
                  },
                  {
                    "tr_badgeTitle": "快速充電 30 分鐘，電池續航力可達 4 小時<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+) 提供同級最佳連線能力<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "因為效能至關重要",
                "tr_subtitle": "在搭載第 10 代 Intel® Core™ i7 處理器的 Chromebook* 上輕鬆編輯影片，並即時上傳、分享回憶。",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "效能快速且靈敏回應效能迅速",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 8 代 Intel® Core™ i3 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "驅動您的熱情與生產力",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 8 代 Intel® Core™ i5 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "驅動您的熱情與生產力",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra&nbsp;5 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "驅動您的熱情與生產力",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra&nbsp;7 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "驅動您的熱情與生產力",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra&nbsp;9 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "效能快速且靈敏回應效能迅速",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 8 代 Intel® Core™ m3 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "滿足您現今工作需&#65279;求的優越效&#65279;能",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 7 代 Intel® Core™ i5 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "滿足您現今工作需&#65279;求的頂&#65279;級卓&#65279;越效&#65279;能",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 7 代 Intel® Core™ i7 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "效能可滿足您現今工作需求",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 7 代 Intel® Core™ m3 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "滿足您所需的更高效能",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 6 代 Intel® Core™ i5 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "滿足您所需的進階效能",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 6 代 Intel® Core™ i7 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "滿足您所需的效能",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 6 代 Intel® Core™ m3 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "突破 Chromebook* 的效能界限",
                "tr_subtitle": "",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i3 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subt-text'>效能提升<sup>4</sup>高達</span><br/><span class='big-blue-text'> 2.4 倍 </span>"
                  },
                  {
                    "tr_badgeTitle": "<span class='subt-text'>網路瀏覽速度<sup>5</sup></span>提升高達 <br/><span class='big-blue-text'>3.2 倍 </span>"
                  },
                  {
                    "tr_badgeTitle": "<span class='subt-text'>影片編輯速度<sup>6</sup></span>提升高達 <br/><span class='big-blue-text'>2.1 倍 </span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "想像一下您可以運用 Chromebook* 達成哪些成就",
                "tr_subtitle": "",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subt-text'>效能提升<sup>4</sup>高達</span><br/><span class='big-blue-text'> 2.7 倍 </span>"
                  },
                  {
                    "tr_badgeTitle": "<span class='subt-text'>網路瀏覽速度<sup>5</sup></span>提升高達 <br/><span class='big-blue-text'>3.3 倍 </span>"
                  },
                  {
                    "tr_badgeTitle": "<span class='subt-text'>影片編輯速度<sup>6</sup></span>提升高達 <br/><span class='big-blue-text'>2.8 倍 </span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "專為行動效能打造",
                "tr_subtitle": "",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "第 11 代 Intel® Core™ 處理器帶來打破框架的精湛效能"
                  },
                  {
                    "tr_badgeTitle": "不到 1 秒瞬間喚醒<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "採用 Intel® Wi-Fi 6 (Gig+)，網路速度快了將近 3 倍<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "快速充電，長效電池續航力"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "全球最佳 Chromebook* 處&#xfeff;理&#xfeff;器<sup class='sup-text'>1</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subt-text'>效能提升<sup>4</sup>高達</span><br/><span class='big-blue-text'> 2.9 倍 </span>"
                  },
                  {
                    "tr_badgeTitle": "<span class='subt-text'>網路瀏覽速度<sup>5</sup></span>提升高達 <br/><span class='big-blue-text'>3.5 倍 </span>"
                  },
                  {
                    "tr_badgeTitle": "<span class='subt-text'>影片編輯速度<sup>6</sup></span>提升高達 <br/><span class='big-blue-text'>2.9 倍 </span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "專為行動效能打造",
                "tr_subtitle": "",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "第 11 代 Intel® Core™ 處理器帶來打破框架的精湛效能"
                  },
                  {
                    "tr_badgeTitle": "不到 1 秒瞬間喚醒<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "採用 Intel® Wi-Fi 6 (Gig+)，網路速度快了將近 3 倍<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "快速充電，長效電池續航力"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "效能強大適&#65279;合&#65279;日&#65279;常&#65279;使&#65279;用",
                "tr_subtitle": "憑藉 Intel® Pentium® 處理器的卓越能力，為您最愛的程式及應用程式取得優異的效能",
                "tr_gen_core_title": "Intel® Pentium® Gold 處&#65279;理&#65279;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "效能強大適&#65279;合&#65279;日&#65279;常&#65279;使&#65279;用",
                "tr_subtitle": "憑藉 Intel® Pentium® 處理器的卓越能力，為您最愛的程式及應用程式取得優異的效能",
                "tr_gen_core_title": "Intel® Pentium® Silver 處&#65279;理&#65279;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "提高您的日常生產力",
                "tr_subtitle": "從啟動應用程式到開啟專案，Intel® Celeron® 處理器都能協助您完成更多工作。",
                "tr_gen_core_title": "Intel® Celeron® 處&#65279;理&#65279;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "效能與價值。",
                "tr_subtitle": "專為提升娛樂體驗、視訊串流和生產力打造。觀賞電影和網路影片時，內容鉅細靡遺地呈現，令人嘆為觀止。減少等候的時間，將更多時間投注在重要的事。",
                "tr_gen_core_title": "Intel® Pentium® 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "效能強大適&#65279;合&#65279;日&#65279;常&#65279;使&#65279;用",
                "tr_subtitle": "憑藉 Intel® Pentium® 處理器的卓越能力，為您最愛的程式及應用程式取得優異的效能",
                "tr_gen_core_title": "Intel® Pentium® 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "效能與價值。",
                "tr_subtitle": "提高生產力 - 提升效能，助您順利挺過工作最艱鉅的日子。簡化網頁瀏覽 - 快速載入多媒體的網頁，以更少的等待時間探索更多內容。",
                "tr_gen_core_title": "Intel® Pentium® Gold 處&#65279;理&#65279;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "效能與價值。",
                "tr_subtitle": "透過從網路存取檔案的優異效能，讓您在數位世界中領先群雄。在不同程式間輕鬆切換，同時進行多項工作，以及在更短的時間內完成更多任務。",
                "tr_gen_core_title": "Intel® Pentium® Silver 處&#65279;理&#65279;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "效能與價值。",
                "tr_subtitle": "優異的效能，進而提升工作生產力和娛樂體驗。享受電影和網路影片內容鉅細靡遺地呈現，令人嘆為觀止。減少等候的時間，將更多時間投注在重要的事。",
                "tr_gen_core_title": "Intel® Celeron® 處&#65279;理&#65279;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "在您最需要的時刻提供可靠效能。",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™ i3 處&#65279;理&#65279;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕鬆<br/>多工作業"
                  },
                  {
                    "tr_badgeTitle": "內建<br/>背景模糊"
                  },
                  {
                    "tr_badgeTitle": "回應快速的<br/>網頁瀏覽"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "在您最需要的時刻提&#xfeff;升&#xfeff;效&#xfeff;能",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "強大的<br/>多工作業"
                  },
                  {
                    "tr_badgeTitle": "自動<br/>降噪"
                  },
                  {
                    "tr_badgeTitle": "HD<br/>串流"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "行動效能再進化。",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "搭載第 12 代 Intel® Core™ 處理器，在您最需要的時刻提供卓越效能<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "不到 1 秒瞬間喚醒<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "採用 Intel® Wi‑Fi 6/6E (Gig+)，網路速度提升將近 3 倍<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "快速充電，長效電池續航力"
                  },
                  {
                    "tr_badgeTitle": "增強的協作與連線能力"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "在您最需要的時刻提供卓越效能<sup>11</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "極致<br/>多工作業"
                  },
                  {
                    "tr_badgeTitle": "內建<br/>降噪與<br/>背景模糊"
                  },
                  {
                    "tr_badgeTitle": "順暢的 <br/>8K 串流"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "行動效能再進化。",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "搭載第 12 代 Intel® Core™ 處理器，在您最需要的時刻提供卓越效能<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "不到 1 秒瞬間喚醒<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "採用 Intel® Wi‑Fi 6/6E (Gig+)，網路速度提升將近 3 倍<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "快速充電，長效電池續航力"
                  },
                  {
                    "tr_badgeTitle": "增強的協作與連線能力"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*。<br/>再進化。",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 13 代 Intel® Core™ i5 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕薄設計不只著重效能，<br/><span class='marklist'>還能</span>與您現有的手機順暢連線，<sup>13</sup><br/><span class='marklist'>並且</span>智慧選取最佳 Wi-Fi 或 5G 連線，<br/><span class='marklist'>以及</span>使用續航力持久的電池快速充電"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "以卓越效能隨心所欲地實現您的嚮往",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 13 代 Intel® Core™ i5 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "增強<br/>多工作業"
                  },
                  {
                    "tr_badgeTitle": "順暢的<br/>4K 串流"
                  },
                  {
                    "tr_badgeTitle": "強大的<br/>創作能力"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*。<br/>再進化。",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 13 代 Intel® Core™ i7 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕薄設計不只著重效能，<br/><span class='marklist'>還能</span>與您現有的手機順暢連線，<sup>13</sup><br/><span class='marklist'>並且</span>智慧選取最佳 Wi-Fi 或 5G 連線，<br/><span class='marklist'>以及</span>使用續航力持久的電池快速充電"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "以更加卓越的效能，實現您的各&#xfeff;種&#xfeff;所&#xfeff;需",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 13 代 Intel® Core™ i7 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "極致<br/>多工作業"
                  },
                  {
                    "tr_badgeTitle": "順暢的<br/>8K 串流"
                  },
                  {
                    "tr_badgeTitle": "進階<br/>創作"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Intel® 處&#xfeff;理&#xfeff;器",
                "tr_subtitle": "您需要的效能<br/>您想要的經濟實惠",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "以突出效能，實現您的各種所需",
                "tr_subtitle": "",
                "tr_gen_core_title": "第 13 代 Intel® Core™ i3 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕鬆<br/>多工作業"
                  },
                  {
                    "tr_badgeTitle": "順暢<br/>串流"
                  },
                  {
                    "tr_badgeTitle": "快速<br/>創作"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*。<br/>再進化。",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 處&#65279;理&#65279;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕薄設計不只著重效能，<br/><span class='marklist'>還能</span>與您現有的手機順暢連線，<sup>13</sup><br/><span class='marklist'>並且</span>智慧選取最佳 Wi-Fi 或 5G 連線，<br/><span class='marklist'>以及</span>使用續航力持久的電池快速充電"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "以突出效能，實現您的各種所需",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 處&#65279;理&#65279;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕鬆<br/>多工作業"
                  },
                  {
                    "tr_badgeTitle": "順暢<br/>串流"
                  },
                  {
                    "tr_badgeTitle": "快速<br/>創作"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*。<br/>再進化。",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕薄設計不只著重效能，<br/><span class='marklist'>還能</span>與您現有的手機順暢連線，<sup>13</sup><br/><span class='marklist'>並且</span>智慧選取最佳 Wi-Fi 或 5G 連線，<br/><span class='marklist'>以及</span>使用續航力持久的電池快速充電"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "以卓越效能隨心所欲地實現您的嚮往",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "增強<br/>多工作業"
                  },
                  {
                    "tr_badgeTitle": "順暢的<br/>4K 串流"
                  },
                  {
                    "tr_badgeTitle": "強大的<br/>創作能力"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*。<br/>再進化。",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕薄設計不只著重效能，<br/><span class='marklist'>還能</span>與您現有的手機順暢連線，<sup>13</sup><br/><span class='marklist'>並且</span>智慧選取最佳 Wi-Fi 或 5G 連線，<br/><span class='marklist'>以及</span>使用續航力持久的電池快速充電"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "以更加卓越的效能，實現您的各&#xfeff;種&#xfeff;所&#xfeff;需",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "極致<br/>多工作業"
                  },
                  {
                    "tr_badgeTitle": "順暢的<br/>8K 串流"
                  },
                  {
                    "tr_badgeTitle": "進階<br/>創作"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*。<br/>再進化。",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i9 處&#65279;理&#65279;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕薄設計不只著重效能，<br/><span class='marklist'>還能</span>與您現有的手機順暢連線，<sup>13</sup><br/><span class='marklist'>並且</span>智慧選取最佳 Wi-Fi 或 5G 連線，<br/><span class='marklist'>以及</span>使用續航力持久的電池快速充電"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "以卓越的效能為您的熱忱注入原動力",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i9 處&#65279;理&#65279;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "快速編輯<br/>相片和影片"
                  },
                  {
                    "tr_badgeTitle": "改善的<br/>離線能力"
                  },
                  {
                    "tr_badgeTitle": "電池續航力長，<br/>可放心<br/>暢玩遊戲"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_3",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*。<br/>再進化。",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 處&#65279;理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕薄設計不只著重效能，<br/><span class='marklist'>還能</span>與您現有的手機順暢連線，<sup>13</sup><br/><span class='marklist'>並且</span>智慧選取最佳 Wi-Fi 或 5G 連線，<br/><span class='marklist'>以及</span>使用續航力持久的電池快速充電"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "以突出效能，實現您的各種所需",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 處&#65279;理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕鬆<br/>多工作業"
                  },
                  {
                    "tr_badgeTitle": "順暢<br/>串流"
                  },
                  {
                    "tr_badgeTitle": "快速<br/>創作"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_5",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*。<br/>再進化。",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 處&#65279;理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕薄設計不只著重效能，<br/><span class='marklist'>還能</span>與您現有的手機順暢連線，<sup>13</sup><br/><span class='marklist'>並且</span>智慧選取最佳 Wi-Fi 或 5G 連線，<br/><span class='marklist'>以及</span>使用續航力持久的電池快速充電"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "以卓越效能隨心所欲地實現您的嚮往",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 處&#65279;理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "增強<br/>多工作業"
                  },
                  {
                    "tr_badgeTitle": "順暢的<br/>4K 串流"
                  },
                  {
                    "tr_badgeTitle": "強大的<br/>創作能力"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_7",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*。<br/>再進化。",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 處&#65279;理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕薄設計不只著重效能，<br/><span class='marklist'>還能</span>與您現有的手機順暢連線，<sup>13</sup><br/><span class='marklist'>並且</span>智慧選取最佳 Wi-Fi 或 5G 連線，<br/><span class='marklist'>以及</span>使用續航力持久的電池快速充電"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "以更加卓越的效能，實現您的各&#xfeff;種&#xfeff;所&#xfeff;需",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 處&#65279;理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "極致<br/>多工作業"
                  },
                  {
                    "tr_badgeTitle": "順暢的<br/>8K 串流"
                  },
                  {
                    "tr_badgeTitle": "進階<br/>創作"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*。<br/>再進化。",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra&nbsp;5 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕薄設計不只著重效能，<br/><span class='marklist'>還能</span>與您現有的手機順暢連線，<sup>13</sup><br/><span class='marklist'>並且</span>智慧選取最佳 Wi-Fi 或 5G 連線，<br/><span class='marklist'>以及</span>使用續航力持久的電池快速充電"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "釋放全新 AI&nbsp;體&#xfeff;驗",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra&nbsp;5 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "生成式 AI 的速度<br/>提升高達 65%"
                  },
                  {
                    "tr_badgeTitle": "AI 影片編輯速度<br/>快達 2.3 倍"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*。<br/>再進化。",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra&nbsp;7 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕薄設計不只著重效能，<br/><span class='marklist'>還能</span>與您現有的手機順暢連線，<sup>13</sup><br/><span class='marklist'>並且</span>智慧選取最佳 Wi-Fi 或 5G 連線，<br/><span class='marklist'>以及</span>使用續航力持久的電池快速充電"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "釋放全新 AI&nbsp;體&#xfeff;驗",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra&nbsp;7 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "生成式 AI 的速度<br/>提升高達 65%"
                  },
                  {
                    "tr_badgeTitle": "AI 影片編輯速度<br/>快達 2.3 倍"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*。<br/>再進化。",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra&nbsp;9 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "輕薄設計不只著重效能，<br/><span class='marklist'>還能</span>與您現有的手機順暢連線，<sup>13</sup><br/><span class='marklist'>並且</span>智慧選取最佳 Wi-Fi 或 5G 連線，<br/><span class='marklist'>以及</span>使用續航力持久的電池快速充電"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "釋放全新 AI&nbsp;體&#xfeff;驗",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra&nbsp;9 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "生成式 AI 的速度<br/>提升高達 65%"
                  },
                  {
                    "tr_badgeTitle": "AI 影片編輯速度<br/>快達 2.3 倍"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "legalText": {
    "tr_specTitle": "法律聲明",
    "tr_paragraph_array": [
      "¹根據 Google* 內部測試，充滿電後電池續航力長達 10 小時。",
      "²沒有產品或元件能提供絕對的安全性。請洽詢您的系統製造商或零售商，或造訪 intel.com 瞭解更多資訊。",
      "³根據業界評測基準、「代表性用途指南」測試以及 Intel® Core™ i7-1165G7 處理器的獨特功能進行評測。關於工作負載與配置，請造訪 www.intel.com/PerformanceIndex。結果可能有所不同。",
      "⁴根據 Google 試算表多工作業進行評測。",
      "⁵根據 Speedometer 2.0 進行評測。",
      "⁶根據 KineMaster 匯出影片進行評測。",
      "關於註腳 4-6，所有測試比較均使用 Intel® Core™ i3-1115G4 處理器與 Intel® Celeron® N4020 處理器完成。如需工作負載與配置詳細資料，請造訪 www.intel.com/PerformanceIndex。效能可能會因使用方式、配置和其他因素而有所不同。請造訪 www.intel.com/PerformanceIndex 瞭解更多資訊。效能結果係依配置中所示日期的測試為準，且可能無法反映所有公開可用的安全性更新。",
      "⁷在現實環境中執行一般工作流程時，將全滿電力耗用至電力不足等級所花費的時間。結果可能會有所差異。intel.com/evo 提供詳細資料。",
      "⁸結果可能會有所差異。intel.com/evo 提供詳細資料。",
      "⁹速度提升近 3 倍：802.11ax 2x2 160 MHz 可提供 2402 Mbps 的最大理論資料速度。比標準 802.11ac 2x2 80 MHz (867 Mbps) 的 Wi‑Fi 快約 3 倍，如 IEEE 802.11* 無線標準規格所述，且需使用類似配置的 802.11ax 無線網路路由器。",
      "¹⁰從 OEM 預設關機等級獲得的電力。結果可能會有所差異。intel.com/evo 提供詳細資料。",
      "¹¹與前一代處理器相比。",
      "¹²量測依據為 Intel® Evo™ 設計的獨特功能，例如高效能 Intel® Core™ CPU、高階元件、無與倫比的相容性，以及時尚創新設計中的最新連線解決方案。所有 Intel® Evo™ 品牌設計，皆必須滿足關鍵行動使用者體驗的嚴苛門檻，例如反應能力與電池續航力；個別裝置效能可能有所差異。詳細資料請造訪 www.intel.com/PerformanceIndex（平台）。",
      "¹³Intel® Unison™ 解決方案目前僅適用於 Windows* 電腦上的合格 Intel® Evo™ 設計，並且只能與 Android* 或 iOS* 手機配對。所有裝置必須執行支援的作業系統版本。個別 Intel® Evo™ 裝置的效能可能會有所不同。如需詳細資料，請造訪 intel.com/Performance-Evo。",
      "Intel® 技術的功能與優勢取決於系統配置，而且可能需要支援的軟硬體和服務啟動才能使用。實際效能會依系統組態而異。沒有電腦系統能提供絕對的安全性。請洽詢您的系統製造商或零售商，或造訪 intel.com 瞭解更多資訊。",
      "*其他名稱與品牌可能業經宣告為他人之財產。",
      "如果價格有誤，Intel 概不負責。",
      "© 2022 Intel 公司。Celeron、Intel、Intel 圖誌、Intel Core 和 Pentium 為 Intel 公司或其子公司的商標。"
    ]
  }
}