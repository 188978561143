const Library_mobile_sk = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Využíva procesor [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Využíva procesor [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Podobne ako mozog, procesor ovláda to, čo váš počítač robí. Teda videá, ktoré sledujete, hry, ktoré hráte, webové stránky, ktoré navštevujete –  všetko začína procesorom.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Procesor je mozog vášho počítača"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Výkon a&nbsp;hodnota.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Pentium® Silver",
                    "tr_subtitle": "Udržiavajte krok s digitálnym svetom vďaka vynikajúcemu výkonu pri prístupe k súborom z internetu. Jednoduchým prepínaním medzi programami môžete hravo vykonávať viacero úloh naraz a zvládať viac práce za menej času. ",
                    "tr_gen_core_title": "Procesor Intel® Pentium® Silver"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Pentium® Gold",
                    "tr_subtitle": "Zvýšená produktivita – zvýšenie výkonu na zvládanie aj tých najnáročnejších úloh. Zjednodušené prehliadanie webu – rýchlo načítajte webové stránky plné médií pre viac objavovania a menej čakania.",
                    "tr_gen_core_title": "Procesor Intel® Pentium® Gold"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Celeron®",
                    "tr_subtitle": "Výkon, ktorý zintenzívni vašu prácu aj zábavu. Vychutnávajte si filmy a videá na webe s úžasnými detailmi. Venujte viac času práci a&nbsp;menej času čakaniu.",
                    "tr_gen_core_title": "Procesor Intel® Celeron®"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Procesor Intel® Pentium®",
                    "tr_subtitle": "Vytvorené pre skvelú zábavu, streamovanie videí a&nbsp;produktivitu. Vychutnávajte si filmy a&nbsp;videá na webe s&nbsp;úžasnými detailmi. Venujte viac času práci a&nbsp;menej času čakaniu.",
                    "tr_gen_core_title": "Procesor Intel® Pentium®"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Výkon, ktorý potrebujete. Cena, ktorú chcete.",
                "tr_title": "Procesor Intel®",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Hodnota vo vlastnej kategórii",
                    "tr_subtitle": "Procesor Intel® zvládne takmer všetky úlohy za cenu, ktorú si môžete dovoliť. Jednoducho prechádzajte z jedného softvéru do druhého. Alebo si vychutnajte špičkovú grafiku so stabilným výkonom.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Vytvárajte silnejšie väzby",
                    "tr_subtitle": "Žiarivejšia kvalita videa, čistejší zvuk a takmer 3x rýchlejšie pripojenie na internet vám umožnia bez obáv komunikovať kdekoľvek to potrebujete.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Ten rozdiel je deň a noc",
                    "tr_subtitle": "Od ranných filmových maratónov až po úpravy na poslednú chvíľu, procesor Intel® predlžuje výdrž batérie vášho zariadenia, aby ste mohli zažiť skutočne pohlcujúce a neprerušované chvíle.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Učiť sa môžete v akomkoľvek prostredí",
                    "tr_subtitle": "Spustite ľahko viacero online vzdelávacích nástrojov z každého kúta vášho domu."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Učte sa, kde chcete",
                    "tr_subtitle": "Dokončite výučbu, kdekoľvek potrebujete, a zároveň používajte rôzne e-learningové nástroje."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Získajte výkon pre zábavu a produktivitu, ktorý vyhovie vášmu rozpočtu aj životnému štýlu.",
                "tr_title": "Výkon procesorov Intel pre každý rozpočet.",
                "tr_gen_core_title": "Procesor Intel® Celeron®",
                "processorCompare": {
                  "tr_title": "NÁJDITE SI TÚ SPRÁVNU ÚROVEŇ VÝKONU A HODNOTY",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Procesor Intel® Celeron®"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rýchle prehliadanie multimediálnych webových stránok",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jednoduché prepínanie medzi programami",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hranie bežných hier",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie 4K videí",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nenáročné úpravy fotografií a videí",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Vysoká produktivita vďaka inteligentnému výkonu",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Používa procesor Intel® Core™ [cpu_fam] 7. generácie"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Používa procesor Intel® Core™ [cpu_fam] 6. generácie"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Používa procesor Intel® Core™ [cpu_fam] 5. generácie"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Používa procesor Intel® Core™ [cpu_fam] 4. generácie"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Používa procesor Intel® Core™ [cpu_fam] 3. generácie"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Používa procesor Intel® Core™ [cpu_fam] 2. generácie"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Používa procesor Intel® Core™ [cpu_fam] 1. generácie"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Odporúčané pre:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Používa procesor Intel® Core™ i3 8.&nbsp;generácie",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 8.&nbsp;generácie"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesory 9. generácie Intel® Core™ boli vytvorené, aby udržali krok s&nbsp;vaším digitálnym svetom. Vďaka kombinácii ich rýchlosti a výkonu budete môcť na počítači vykonávať viac vašich obľúbených činností bez akejkoľvek frustrácie.",
                "tr_title": "Používa procesor Intel® Core™ i3 9. generácie",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 9.&nbsp;generácie"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Univerzálny výkon spolu s dostatočnou výdržou batérie, aby ste sa mohli vydať kamkoľvek.",
                "tr_title": "Výkon na celý váš deň",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 10.&nbsp;generácie",
                "processorCompare": {
                  "tr_title": "OBJAVTE TÚ SPRÁVNU ÚROVEŇ VÝKONU&nbsp;10.&nbsp;GENERÁCIE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streamovanie filmov v rozlíšení 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rýchle a spoľahlivé pripojenie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Úprava fotografií a filtrovanie videí",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hranie v rozlíšení 1080p s plynulou snímkovacou frekvenciou",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepšie na prácu s veľkými súbormi a kreatívnymi programami",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dostatok výkonu vám umožní jednoducho prechádzať od streamovania filmov k vytváraniu prezentácií.",
                "tr_title": "Výkon na celý váš deň",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 10.&nbsp;generácie"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ak patríte medzi tých, ktorí sú neustále na cestách, tento prenosný počítač je určený špeciálne pre Vás.",
                "tr_title": "Spoznajte novú triedu prenosných počítačov",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 10.&nbsp;generácie"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Procesor Intel® Core™ i3 11. generácie navrhnutý osobitne pre prenosné počítače vám umožňuje robiť viac ako kedykoľvek predtým.",
                "tr_title": "Využite svoj nový prenosný počítač naplno",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i3 11. GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Keď celá rodina používa jeden stolný počítač na všetky činnosti, potrebujete výkon a všestrannosť procesora Intel® Core™ i3 11. generácie.",
                "tr_title": "Buďte pripravení na čokoľvek vďaka prelomovému výkonu.",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i3 11. GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Používa procesor Intel® Core™ i5 8.&nbsp;generácie",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 8.&nbsp;generácie"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesory 9. generácie Intel® Core™ boli vytvorené, aby udržali krok s&nbsp;vaším digitálnym svetom. Vďaka kombinácii ich rýchlosti a výkonu budete môcť na počítači vykonávať viac vašich obľúbených činností bez akejkoľvek frustrácie.",
                "tr_title": "Používa procesor Intel® Core™ i5 9. generácie",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 9.&nbsp;generácie"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "PLYNULÉ HRANIE A VIRTUÁLNA REALITA PRE TIE NAJNÁROČNEJŠIE TITULY",
                "tr_title": "Seriózne hranie začína tu",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 9.&nbsp;generácie",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>vyrovnávacia pamäť</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ PRE SVOJ ŠTÝL HRANIA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA hry",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Súťažné hranie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hranie, streamovanie a nahrávanie bez kompromisov",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepší procesor na hry Intel® Core™ 9.&nbsp;generácie",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 4<br/>Vlákna: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "S ľahkosťou prepínajte medzi viacerými spustenými programami, a buďte tak pripravení na všetko, čo vám deň prinesie.",
                "tr_title": "Vykonávanie viacerých úloh na cestách",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 10.&nbsp;generácie",
                "processorCompare": {
                  "tr_title": "OBJAVTE TÚ SPRÁVNU ÚROVEŇ VÝKONU&nbsp;10.&nbsp;GENERÁCIE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streamovanie filmov v rozlíšení 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rýchle a spoľahlivé pripojenie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Úprava fotografií a filtrovanie videí",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hranie v rozlíšení 1080p s plynulou snímkovacou frekvenciou",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepšie na prácu s veľkými súbormi a kreatívnymi programami",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Zábava, produktivita či tvorba obsahu - zlepšiť sa dá všetko, čo na počítači robíte.",
                "tr_title": "Inovujte na výkonnejší počítač",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 10.&nbsp;generácie"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ak patríte medzi tých, ktorí sú neustále na cestách, tento prenosný počítač je určený špeciálne pre Vás.",
                "tr_title": "Spoznajte novú triedu prenosných počítačov",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 10.&nbsp;generácie"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Vytvorený pre bezproblémové streamovanie a hranie špičkových hier",
                "tr_title": "Hrajte podľa vlastných pravidiel",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 10.&nbsp;generácie",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>vyrovnávacia pamäť</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ 10. GENERÁCIE PRE SVOJ ŠTÝL HRANIA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Nerušené hranie AAA hier",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Súťažné hranie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie a nahrávanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pokročilá tvorba obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herný výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odomknutý a pretaktovateľný<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Vytvorený pre bezproblémové streamovanie a hranie špičkových hier",
                "tr_title": "Hrajte podľa vlastných pravidiel",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 10.&nbsp;generácie",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>vyrovnávacia pamäť</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ 10. GENERÁCIE PRE SVOJ ŠTÝL HRANIA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Nerušené hranie AAA hier",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Súťažné hranie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie a nahrávanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pokročilá tvorba obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herný výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odomknutý a pretaktovateľný<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 10<br/>Vlákna: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "S ľahkosťou prepínajte medzi viacerými spustenými programami, a buďte tak pripravení na všetko, čo vám deň prinesie.",
                "tr_title": "Vykonávanie viacerých úloh na cestách",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 10.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 10.&nbsp;GENERÁCIE"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Procesor Intel® Core™ i5 11. generácie prináša úchvatné vizuálne zážitky a vysokú produktivitu aj do najtenších a najľahších počítačov.",
                "tr_title": "Impozantný výkon prenosného počítača",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 11.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Keď celá rodina používa jeden stolný počítač na všetky činnosti, potrebujete výkon a všestrannosť procesora Intel® Core™ i5 11. generácie.",
                "tr_title": "Buďte pripravení na čokoľvek vďaka prelomovému výkonu.",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 11.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesor Intel® Core™ i5 11. generácie prináša úchvatný vizuálny zážitok a vysokú produktivitu aj do tenkých a ľahkých prenosných počítačov.",
                "tr_title": "Impozantný výkon prenosného počítača",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 11.&nbsp;GENERÁCIE"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Používa procesor Intel® Core™ i7 8.&nbsp;generácie",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 8.&nbsp;generácie"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Procesory 9. generácie Intel® Core™ boli vytvorené, aby udržali krok s&nbsp;vaším digitálnym svetom. Vďaka kombinácii ich rýchlosti a výkonu budete môcť na počítači vykonávať viac vašich obľúbených činností bez akejkoľvek frustrácie.",
                "tr_title": "Používa procesor Intel® Core™ i7 9. generácie",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 9.&nbsp;generácie"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "AŽ O&nbsp;32&nbsp;% VIAC SNÍMOK ZA SEKUNDU počas hrania, streamovania a&nbsp;nahrávania v porovnaní s 3-ROČNÝM POČÍTAČOM<sup>3</sup>",
                "tr_title": "Výkon na zdieľanie najúžasnejších herných okamihov",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 9.&nbsp;generácie",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>vyrovnávacia pamäť</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ PRE SVOJ ŠTÝL HRANIA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA hry",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Súťažné hranie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hranie, streamovanie a nahrávanie bez kompromisov",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepší procesor na hry Intel® Core™ 9.&nbsp;generácie",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 4<br/>Vlákna: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Toto elegantné zariadenie bolo navrhnuté pre efektivitu - v značnej miere môže ovplyvniť spôsob, akým vytvárate obsah, pripájate sa a streamujete.",
                "tr_title": "Pokročilý výkon kdekoľvek",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 10.&nbsp;generácie",
                "processorCompare": {
                  "tr_title": "OBJAVTE TÚ SPRÁVNU ÚROVEŇ VÝKONU&nbsp;10.&nbsp;GENERÁCIE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streamovanie filmov v rozlíšení 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Rýchle a spoľahlivé pripojenie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Úprava fotografií a filtrovanie videí",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hranie v rozlíšení 1080p s plynulou snímkovacou frekvenciou",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepšie na prácu s veľkými súbormi a kreatívnymi programami",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Špičkový výkon posunie na vyššiu úroveň všetko, čo robíte.",
                "tr_title": "Dostatok výkonu na všetko",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 10.&nbsp;generácie"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ak patríte medzi tých, ktorí sú neustále na cestách, tento prenosný počítač je určený špeciálne pre Vás.",
                "tr_title": "Spoznajte novú triedu prenosných počítačov",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 10.&nbsp;generácie"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hrajte, streamujte a nahrávajte s kľúčovými výhodami",
                "tr_title": "Výkon poháňajúci váš herný svet",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 10.&nbsp;generácie",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>vyrovnávacia pamäť</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ 10. GENERÁCIE PRE SVOJ ŠTÝL HRANIA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Nerušené hranie AAA hier",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Súťažné hranie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie a nahrávanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pokročilá tvorba obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herný výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odomknutý a pretaktovateľný<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hrajte, streamujte a nahrávajte s kľúčovými výhodami",
                "tr_title": "Výkon poháňajúci váš herný svet",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 10.&nbsp;generácie",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>vyrovnávacia pamäť</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ 10. GENERÁCIE PRE SVOJ ŠTÝL HRANIA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Nerušené hranie AAA hier",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Súťažné hranie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie a nahrávanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pokročilá tvorba obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herný výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odomknutý a pretaktovateľný<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 10<br/>Vlákna: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Vďaka efektívnemu dizajnu môže toto elegantné zariadenie do značnej miery ovplyvniť spôsob, akým sa pripájate, vytvárate a streamujete.",
                "tr_title": "Prémiový výkon kdekoľvek",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 10.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 10.&nbsp;GENERÁCIE"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Procesor Intel® Core™ i7 11. generácie poskytuje ultraprenosným počítačom výkon na pokročilú tvorbu obsahu, nerušené hranie hier a špičkovú zábavu.",
                "tr_title": "Prelomový výkon prenosného počítača",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 11.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Procesor Intel® Core™ i7 11. generácie so svojím prelomovým výkonom zaistí, že váš stolný počítač zvládne všetky potreby členov vašej domácnosti.",
                "tr_title": "Pracujte. Učte sa. Bavte sa. Bez hraníc.",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 11.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesor Intel® Core™ i7 11. generácie poskytuje tenkým a ľahkým počítačom výkon na pokročilú tvorbu obsahu, plynulé hranie hier a špičkovú zábavu.",
                "tr_title": "Prelomový výkon prenosného počítača",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 11.&nbsp;GENERÁCIE"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesor Intel® Core™ i7 11. generácie so svojím prelomovým výkonom zaistí, že váš stolný počítač zvládne všetky potreby členov vašej domácnosti.",
                "tr_title": "Tvorba bez hraníc",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 11.&nbsp;GENERÁCIE"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "S pokročilou tvorbou obsahu, plynulým hraním hier a špičkovou zábavou na tenkých a ľahkých prenosných počítačoch je procesor Intel® Core™ i7 11. generácie ešte lepší.",
                "tr_title": "Prelomový výkon prenosného počítača",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 11.&nbsp;GENERÁCIE"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Používa procesor Intel® Core™ i9 8.&nbsp;generácie",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 8.&nbsp;generácie"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesory 9. generácie Intel® Core™ boli vytvorené, aby udržali krok s&nbsp;vaším digitálnym svetom. Vďaka kombinácii ich rýchlosti a výkonu budete môcť na počítači vykonávať viac vašich obľúbených činností bez akejkoľvek frustrácie.",
                "tr_title": "Používa procesor Intel® Core™ i9 9. generácie",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 9.&nbsp;generácie"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "AŽ O&nbsp;41&nbsp;% VIAC SNÍMOK ZA SEKUNDU počas hrania, streamovania a&nbsp;nahrávania v porovnaní s 3-ROČNÝM POČÍTAČOM<sup>4</sup>",
                "tr_title": "Hráči majú svoje potreby. Intel® Core™ i9 ich plní.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 9.&nbsp;generácie",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>vyrovnávacia pamäť</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ PRE SVOJ ŠTÝL HRANIA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA hry",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Súťažné hranie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hranie, streamovanie a nahrávanie bez kompromisov",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepší procesor na hry Intel® Core™ 9.&nbsp;generácie",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 4<br/>Vlákna: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Využite svoj potenciál a hrajte naozaj naplno",
                "tr_title": "Súťažte na najvyššej úrovni",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 10.&nbsp;generácie",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>vyrovnávacia pamäť</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ 10. GENERÁCIE PRE SVOJ ŠTÝL HRANIA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Nerušené hranie AAA hier",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Súťažné hranie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie a nahrávanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pokročilá tvorba obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herný výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odomknutý a pretaktovateľný<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Využite svoj potenciál a hrajte naozaj naplno",
                "tr_title": "Súťažte na najvyššej úrovni",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 10.&nbsp;generácie",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>vyrovnávacia pamäť</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ 10. GENERÁCIE PRE SVOJ ŠTÝL HRANIA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Nerušené hranie AAA hier",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Súťažné hranie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie a nahrávanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pokročilá tvorba obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herný výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odomknutý a pretaktovateľný<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 10<br/>Vlákna: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Využite svoj potenciál a vytvárajte obsah naozaj naplno ",
                "tr_title": "Tvorte na najvyššej úrovni",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 10.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 10.&nbsp;GENERÁCIE",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Prenosné počítače Intel® Evo™, ktoré používajú procesor Intel® Core™ i5 11. generácie a grafický čip Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>, vám v tenkom a ľahkom dizajne poskytnú neuveriteľnú rýchlosť a výkon.",
                "tr_title": "Ešte lepší výkon pre lepší prenosný počítač",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 11.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Prenosné počítače Intel® Evo™, ktoré používajú procesor Intel® Core™ i7 11. generácie a grafický čip Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>, vám poskytnú vysokú rýchlosť a výkon pre skvelý mobilný zážitok.",
                "tr_title": "Pokročilý výkon pre lepší prenosný počítač",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 11.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Vychutnajte si dokonale vyvážený výkon, multimédiá a dobré pripojenia za skvelú cenu.",
                "tr_title": "Výkon a dobré pripojenia za skvelú cenu.",
                "tr_gen_core_title": "Procesor Intel® Pentium® Silver",
                "processorCompare": {
                  "tr_title": "NÁJDITE SI TÚ SPRÁVNU ÚROVEŇ VÝKONU A HODNOTY",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rýchle prehliadanie multimediálnych webových stránok",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jednoduché prepínanie medzi programami",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hranie bežných hier",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie 4K videí",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nenáročné úpravy fotografií a videí",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Vysoká produktivita vďaka inteligentnému výkonu",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Vychutnávajte si prácu s viacerými programami súčasne, jednoduché úpravy fotografií a videí, 4K videá a živú grafiku za neuveriteľnú cenu.",
                "tr_title": "Pôsobivý výkon pre prácu aj zábavu.",
                "tr_gen_core_title": "Procesor Intel® Pentium® Gold",
                "processorCompare": {
                  "tr_title": "NÁJDITE SI TÚ SPRÁVNU ÚROVEŇ VÝKONU A HODNOTY",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Procesor Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Rýchle prehliadanie multimediálnych webových stránok",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jednoduché prepínanie medzi programami",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hranie bežných hier",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie 4K videí",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Nenáročné úpravy fotografií a videí",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Vysoká produktivita vďaka inteligentnému výkonu",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Hranie náročných hier, a zároveň dlhá výdrž na batériu v ultraprenosnom počítači. Nie je nutné robiť kompromisy pri hraní.",
                "tr_title": "Nie je nutné robiť kompromisy.",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 11.&nbsp;generácie",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ PRE ULTRAPRENOSNÝ HERNÝ POČÍTAČ.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5-11300H 11.&nbsp;generácie"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7-11370H 11.&nbsp;generácie"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7&#8209;11375H Special Edition 11.&nbsp;generácie"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Maximálna taktovacia frekvencia až&nbsp;<span class='style_badge'>4,4&nbsp;GHz</span>",
                      "tr_td2_title": "Maximálna taktovacia frekvencia až&nbsp;<span class='style_badge'>4,8&nbsp;GHz</span>",
                      "tr_td3_title": "Maximálna taktovacia frekvencia až&nbsp;<span class='style_badge'>5,0&nbsp;GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 jadrá/ 8&nbsp;vláken",
                      "tr_td2_title": "4 jadrá/ 8&nbsp;vláken",
                      "tr_td3_title": "4 jadrá/ 8&nbsp;vláken"
                    },
                    {
                      "tr_td1_title": "Technológia Intel® Turbo Boost",
                      "tr_td2_title": "Technológia Intel® Turbo Boost",
                      "tr_td3_title": "Technológia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8&nbsp;MB Inteligentná vyrovnávacia pamäť Intel®",
                      "tr_td2_title": "12&nbsp;MB Inteligentná vyrovnávacia pamäť Intel®",
                      "tr_td3_title": "12&nbsp;MB Inteligentná vyrovnávacia pamäť Intel®"
                    },
                    {
                      "tr_td1_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "AAA hry v rozlíšení 1080p s vysokou snímkovou frekvenciou pri maximálnych nastaveniach a špičkový výkon ultra prenosného počítača pri napájaní z batérie.",
                "tr_title": "Len do toho. Získajte všetko.",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 11.&nbsp;generácie",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ PRE ULTRAPRENOSNÝ HERNÝ POČÍTAČ.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5-11300H 11.&nbsp;generácie"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7-11370H 11.&nbsp;generácie"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7&#8209;11375H Special Edition 11.&nbsp;generácie"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Maximálna taktovacia frekvencia až&nbsp;<span class='style_badge'>4,4&nbsp;GHz</span>",
                      "tr_td2_title": "Maximálna taktovacia frekvencia až&nbsp;<span class='style_badge'>4,8&nbsp;GHz</span>",
                      "tr_td3_title": "Maximálna taktovacia frekvencia až&nbsp;<span class='style_badge'>5,0&nbsp;GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 jadrá/ 8&nbsp;vláken",
                      "tr_td2_title": "4 jadrá/ 8&nbsp;vláken",
                      "tr_td3_title": "4 jadrá/ 8&nbsp;vláken"
                    },
                    {
                      "tr_td1_title": "Technológia Intel® Turbo Boost",
                      "tr_td2_title": "Technológia Intel® Turbo Boost",
                      "tr_td3_title": "Technológia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8&nbsp;MB Inteligentná vyrovnávacia pamäť Intel®",
                      "tr_td2_title": "12&nbsp;MB Inteligentná vyrovnávacia pamäť Intel®",
                      "tr_td3_title": "12&nbsp;MB Inteligentná vyrovnávacia pamäť Intel®"
                    },
                    {
                      "tr_td1_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Maximálna taktovacia frekvencia až 5,0&nbsp;GHz ešte viac posunie herné zážitky, ktoré si vďaka ultraprenosnému počítaču môžete vziať so sebou kamkoľvek.",
                "tr_title": "Vyšší výkon v kompaktnejšom prevedení.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition 11.&nbsp;generácie",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ PRE ULTRAPRENOSNÝ HERNÝ POČÍTAČ.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5-11300H 11.&nbsp;generácie"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7-11370H 11.&nbsp;generácie"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7&#8209;11375H Special Edition 11.&nbsp;generácie"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Maximálna taktovacia frekvencia až&nbsp;<span class='style_badge'>4,4&nbsp;GHz</span>",
                      "tr_td2_title": "Maximálna taktovacia frekvencia až&nbsp;<span class='style_badge'>4,8&nbsp;GHz</span>",
                      "tr_td3_title": "Maximálna taktovacia frekvencia až&nbsp;<span class='style_badge'>5,0&nbsp;GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 jadrá/ 8&nbsp;vláken",
                      "tr_td2_title": "4 jadrá/ 8&nbsp;vláken",
                      "tr_td3_title": "4 jadrá/ 8&nbsp;vláken"
                    },
                    {
                      "tr_td1_title": "Technológia Intel® Turbo Boost",
                      "tr_td2_title": "Technológia Intel® Turbo Boost",
                      "tr_td3_title": "Technológia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8&nbsp;MB Inteligentná vyrovnávacia pamäť Intel®",
                      "tr_td2_title": "12&nbsp;MB Inteligentná vyrovnávacia pamäť Intel®",
                      "tr_td3_title": "12&nbsp;MB Inteligentná vyrovnávacia pamäť Intel®"
                    },
                    {
                      "tr_td1_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Procesor Intel® Core™ i7 Special Edition 11. generácie prináša pokročilú tvorbu obsahu, plynulé hranie a zábavu na vyššej úrovni do ultraprenosných počítačov.",
                "tr_title": "Prelomový výkon prenosného počítača",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 SPECIAL EDITION 11. GENERÁCIE"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Procesor Intel® Core™ i7 Special Edition 11. generácie prináša pokročilú tvorbu obsahu, plynulé hranie a zábavu na vyššej úrovni do ultraprenosných počítačov.",
                "tr_title": "Prelomový výkon prenosného počítača",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 SPECIAL EDITION 11. GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Vysoké taktovacie frekvencie a veľký počet jadier – dokonalá kombinácia pre hranie na súťažnej úrovni a pokročilú tvorbu obsahu.",
                "tr_title": "Obrovský výkon. Dokonalá rovnováha.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 11.&nbsp;generácie",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Vďaka správnej rovnováhe medzi frekvenciou a výkonom jadra procesoru získate zo svojich hier viac, či už potrebujete každý snímok za sekundu pre hranie na súťažnej úrovni, alebo si vychutnávate dychberúcu grafiku najnovších herných titulov.",
                "tr_title": "Výkon, ktorý má svoj účel.",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 11.&nbsp;generácie",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hrajte, nahrávajte a streamujte náročné herné tituly s vysokou snímkovou frekvenciou, a zároveň jednoducho prepínajte medzi ďalšími náročnými programami.",
                "tr_title": "Výkon, ktorý posúva hranice.",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 11.&nbsp;generácie",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké taktovacie frekvencie a veľký počet jadier – dokonalá kombinácia pre hranie na súťažnej úrovni a pokročilú tvorbu obsahu.",
                "tr_title": "Obrovský výkon. Dokonalá rovnováha.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 11.&nbsp;generácie",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dostatok výkonu vám umožní jednoducho prechádzať od streamovania filmov k vytváraniu prezentácií.",
                "tr_title": "Výkon na celý váš deň",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ i3 s&nbsp;technológiou Intel®&nbsp;Hybrid",
                "tr_subTitle2": "Procesor Intel®&nbsp;Core™ i3 s&nbsp;technológiou Intel®&nbsp;Hybrid"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Univerzálny výkon spolu s dostatočnou výdržou batérie, aby ste sa mohli vydať kamkoľvek.",
                "tr_title": "Výkon na celý váš deň",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ i3 s&nbsp;technológiou Intel®&nbsp;Hybrid",
                "tr_subTitle2": "Procesor Intel®&nbsp;Core™ i3 s&nbsp;technológiou Intel®&nbsp;Hybrid"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "S ľahkosťou prepínajte medzi viacerými spustenými programami, a buďte tak pripravení na všetko, čo vám deň prinesie.",
                "tr_title": "Vykonávanie viacerých úloh na cestách",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ i5 s&nbsp;technológiou Intel®&nbsp;Hybrid",
                "tr_subTitle2": "Procesor Intel®&nbsp;Core™ i5 s&nbsp;technológiou Intel®&nbsp;Hybrid"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Zábava, produktivita či tvorba obsahu - zlepšiť sa dá všetko, čo na počítači robíte.",
                "tr_title": "Inovujte na výkonnejší počítač",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ i5 s&nbsp;technológiou Intel®&nbsp;Hybrid",
                "tr_subTitle2": "Procesor Intel®&nbsp;Core™ i5 s&nbsp;technológiou Intel®&nbsp;Hybrid"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Herný výkon na úrovni stolného počítača sa snúbi s mobilitou, či už ide o súťažné hranie s vysokým počtom snímkov za sekundu, alebo o pohlcujúce zážitky z najmodernejších hier.",
                "tr_title": "Výkon, ktorý má svoj účel.",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 11.&nbsp;generácie",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Herný výkon na úrovni stolného počítača sa snúbi s mobilitou, či už ide o súťažné hranie s vysokým počtom snímkov za sekundu, alebo o pohlcujúce zážitky z najmodernejších hier.",
                "tr_title": "Výkon, ktorý má svoj účel.",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 11.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Prenosný počítač, na ktorom dokážete súčasne hrať, nahrávať a streamovať s vysoku snímkovacou frekvenciou, a zároveň s ľahkosťou zvládať náročný multitasking.",
                "tr_title": "Výkon, ktorý posúva hranice.",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 11.&nbsp;generácie",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Prenosný počítač, na ktorom dokážete súčasne hrať, nahrávať a streamovať s vysoku snímkovacou frekvenciou, a zároveň s ľahkosťou zvládať náročný multitasking.",
                "tr_title": "Výkon, ktorý posúva hranice.",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 11.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké taktovacie frekvencie idú ruka v ruke s vysokým počtom jadier, aby ste zo súťažného hrania a tvorby komplexného obsahu vyťažili ešte viac, nech už sa so svojím počítačom vydáte kamkoľvek.",
                "tr_title": "Obrovský výkon. Dokonalá rovnováha.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 11.&nbsp;generácie",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké taktovacie frekvencie idú ruka v ruke s vysokým počtom jadier, aby ste zo súťažného hrania a tvorby komplexného obsahu vyťažili ešte viac, nech už sa so svojím počítačom vydáte kamkoľvek.",
                "tr_title": "Obrovský výkon. Dokonalá rovnováha.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 11.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké taktovacie frekvencie, vysoký počet jadier a výkon taktovateľného procesoru hladko spolupracujú, aby ste zo súťažného hrania a tvorby komplexného obsahu vyťažili ešte viac, nech už sa so svojím počítačom vydáte kamkoľvek.",
                "tr_title": "Odomknutý výkon. V dokonalej rovnováhe.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 11.&nbsp;generácie",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké taktovacie frekvencie, vysoký počet jadier a výkon taktovateľného procesoru hladko spolupracujú, aby ste zo súťažného hrania a tvorby komplexného obsahu vyťažili ešte viac, nech už sa so svojím počítačom vydáte kamkoľvek.",
                "tr_title": "Odomknutý výkon. V dokonalej rovnováhe.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 11.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké taktovacie frekvencie a veľký počet jadier – dokonalá kombinácia pre hranie na súťažnej úrovni a pokročilú tvorbu obsahu.",
                "tr_title": "Obrovský výkon. Dokonalá rovnováha.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 11.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké taktovacie frekvencie a vysoké počty jadier bez problémov spolupracujú, aby ste z tvorby komplexného obsahu vyťažili viac, nech už sa so svojím počítačom vydáte kamkoľvek.",
                "tr_title": "Obrovský výkon. Dokonalá rovnováha.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 11.&nbsp;GENERÁCIE",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké taktovacie frekvencie a vysoký počet jadier spolu bezproblémovo spolupracujú, aby ste z tvorby komplexného obsahu vyťažili viac.",
                "tr_title": "Obrovský výkon. Dokonalá rovnováha.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 11.&nbsp;GENERÁCIE",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Využite svoj potenciál a hrajte naozaj naplno",
                "tr_title": "Súťažte na najvyššej úrovni",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 10.&nbsp;generácie",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>vyrovnávacia pamäť</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ 10. GENERÁCIE PRE SVOJ ŠTÝL HRANIA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Nerušené hranie AAA hier",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Súťažné hranie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie a nahrávanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pokročilá tvorba obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herný výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odomknutý a pretaktovateľný<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Využite svoj potenciál a hrajte naozaj naplno",
                "tr_title": "Súťažte na najvyššej úrovni",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 10.&nbsp;generácie",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>vyrovnávacia pamäť</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ 10. GENERÁCIE PRE SVOJ ŠTÝL HRANIA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Nerušené hranie AAA hier",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Súťažné hranie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamovanie a nahrávanie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Pokročilá tvorba obsahu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Špičkový herný výkon",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Odomknutý a pretaktovateľný<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 10<br/>Vlákna: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ak patríte medzi tých, ktorí sú neustále na cestách, tento prenosný počítač je určený špeciálne pre Vás.",
                "tr_title": "Spoznajte novú triedu prenosných počítačov",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 10.&nbsp;generácie"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké taktovacie frekvencie a veľký počet jadier – dokonalá kombinácia pre hranie na súťažnej úrovni a pokročilú tvorbu obsahu.",
                "tr_title": "Odomknutý výkon. V dokonalej rovnováhe.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 11.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké taktovacie frekvencie, vysoký počet jadier a odomknutý výkon spolu bezproblémovo spolupracujú, aby ste z tvorby komplexného obsahu vyťažili viac, nech už sa so svojím počítačom vydáte kamkoľvek.",
                "tr_title": "Odomknutý výkon. V dokonalej rovnováhe.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 11.&nbsp;GENERÁCIE",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vysoké taktovacie frekvencie a vysoký počet jadier spolu bezproblémovo spolupracujú, aby ste z tvorby komplexného obsahu vyťažili viac.",
                "tr_title": "Odomknutý výkon. V dokonalej rovnováhe.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 11.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 11.&nbsp;GENERÁCIE",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Inteligentná vyrovnávacia pamäť Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Plynulé hranie a virtuálna realita v najnáročnejších tituloch",
                "tr_title": "Seriózne hranie začína tu",
                "tr_gen_core_title": "Procesor Intel® Core™ i5 9.&nbsp;generácie",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>vyrovnávacia pamäť</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ PRE SVOJ ŠTÝL HRANIA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA hry",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Súťažné hranie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hranie, streamovanie a nahrávanie bez kompromisov",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepší procesor na hry Intel® Core™ 9.&nbsp;generácie",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 4<br/>Vlákna: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "AŽ O 32 % VIAC SNÍMOK ZA SEKUNDU<br/><span class='tr_subtitle_font'>pri hraní hier, streamovaní a nahrávaní v porovnaní s 3 roky starým PC<sup>3</sup></span>",
                "tr_title": "Výkon na zdieľanie najúžasnejších herných okamihov",
                "tr_gen_core_title": "Procesor Intel® Core™ i7 9.&nbsp;generácie",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>vyrovnávacia pamäť</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ PRE SVOJ ŠTÝL HRANIA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA hry",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Súťažné hranie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hranie, streamovanie a nahrávanie bez kompromisov",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepší procesor na hry Intel® Core™ 9.&nbsp;generácie",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 4<br/>Vlákna: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "AŽ O 41 % VIAC SNÍMOK ZA SEKUNDU <br/><span class='tr_subtitle_font'>pri hraní hier, streamovaní a nahrávaní v porovnaní s 3 roky starým PC<sup>4</sup></span>",
                "tr_title": "Hráči majú svoje potreby. Intel® Core™ i9 ich plní.",
                "tr_gen_core_title": "Procesor Intel® Core™ i9 9.&nbsp;generácie",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>vlákna</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>jadrá</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Až do <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;MB <br/></span><span class='subtitle_font'>vyrovnávacia pamäť</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Taktovateľný",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "NÁJDITE SI PROCESOR INTEL® CORE™ PRE SVOJ ŠTÝL HRANIA.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Procesor Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA hry",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Súťažné hranie",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hranie, streamovanie a nahrávanie bez kompromisov",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Najlepší procesor na hry Intel® Core™ 9.&nbsp;generácie",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 4<br/>Vlákna: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 6<br/>Vlákna: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Frekvencia<sup>2</sup>: až do 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Jadrá: 8<br/>Vlákna: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Stvorené pre gaming ďalšej generácie",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 12.&nbsp;generácie",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 12. GENERÁCIE"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 12.&nbsp;generácie",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 12.&nbsp;GENERÁCIE"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 12.&nbsp;generácie",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 12. GENERÁCIE"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>jadrá</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>vlákna</span></span>",
                    "tr_badgePreTitle": "[p_thread] na Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] na Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Herná zostava aj pracovná stanica",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 12.&nbsp;generácie",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 12.&nbsp;GENERÁCIE"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 12.&nbsp;generácie",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 12. GENERÁCIE"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>jadrá</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>vlákna</span></span>",
                    "tr_badgePreTitle": "[p_thread] na Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] na Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Spoľahlivý výkon, keď ho najviac potrebujete",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Procesor Intel® Core™ i3 12.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i3 12.&nbsp;GENERÁCIE",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Zvýšenie výkonu tam, kde to potrebujete najviac",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 12.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 12. GENERÁCIE",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Zvýšenie výkonu tam, kde to potrebujete najviac",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 12.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 12. GENERÁCIE",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Vynikajúci výkon tam, kde ho potrebujete najviac",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 12.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 12.&nbsp;GENERÁCIE",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Vynikajúci výkon tam, kde ho potrebujete najviac",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 12.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 12.&nbsp;GENERÁCIE",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "S prenosnými počítačmi Intel® Evo™ získate výkon, vstavanú inteligenciu pre bezproblémové videohovory, rýchlejší internet, dlhú výdrž batérie a omnoho viac.",
                "tr_title": "Zvýšenie výkonu tam, kde ho najviac potrebujete.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 12.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 12. GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "S prenosnými počítačmi Intel® Evo™ získate výkon, vstavanú inteligenciu pre bezproblémové videohovory, rýchlejší internet, dlhú výdrž batérie a omnoho viac.",
                "tr_title": "Vynikajúci výkon tam, kde ho potrebujete najviac",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 12.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 12.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "S prenosnými počítačmi Intel® Evo™ získate výkon, vstavanú inteligenciu pre bezproblémové videohovory, rýchlejší internet, dlhú výdrž batérie a omnoho viac.",
                "tr_title": "Extrémny výkon v prémiovom prenosnom počítači",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 12.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 12. GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "S technológiou Intel® Turbo Boost 2.0 s rýchlosťou až [max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Stvorený pre podnikanie",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 12.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 12. GENERÁCIE",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Potlačenie hluku a rozmazanie pozadia",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Výkon sa automaticky prispôsobuje a uprednostňuje najčastejšie vykonávané úlohy",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Vyskúšajte plynulé a vysokokvalitné webové hovory",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Jedinečné hardvérové zabezpečenie",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Poskytovanie možností správy zariadenia na diaľku",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatická optimalizácia výkonu Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Poskytovanie výkonu aplikáciam na spracovanie dát",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Najvyššia produktivita so zvýšeným výkonom",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "S technológiou Intel® Turbo Boost 2.0 s rýchlosťou až [max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Tvrdo pracuje, aby ste sa mohli sústrediť na svoje podnikanie.",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 12.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 12.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Potlačenie hluku a rozmazanie pozadia",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Výkon sa automaticky prispôsobuje a uprednostňuje najčastejšie vykonávané úlohy",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Vyskúšajte plynulé a vysokokvalitné webové hovory",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Jedinečné hardvérové zabezpečenie",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Poskytovanie možností správy zariadenia na diaľku",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatická optimalizácia výkonu Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Poskytovanie výkonu aplikáciam na spracovanie dát",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Najvyššia produktivita so zvýšeným výkonom",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "S technológiou Intel® Turbo Boost 2.0 s rýchlosťou až [max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Vylepšite vaše podnikanie",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 12.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 12. GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Potlačenie hluku a rozmazanie pozadia",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Výkon sa automaticky prispôsobuje a uprednostňuje najčastejšie vykonávané úlohy",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Vyskúšajte plynulé a vysokokvalitné webové hovory",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Jedinečné hardvérové zabezpečenie",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Poskytovanie možností správy zariadenia na diaľku",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatická optimalizácia výkonu Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Poskytovanie výkonu aplikáciam na spracovanie dát",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Najvyššia produktivita so zvýšeným výkonom",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Spoľahlivý výkon určený k tomu, aby zvládol viac",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i3 13.&nbsp;generácie",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Skutočný výkon, s ktorým dokáže viac",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 13.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 13. GENERÁCIE",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Skutočný výkon, s ktorým dokáže viac",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 13.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 13. GENERÁCIE",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Vysoký výkon, ktorý dokáže viac",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 13.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 13.&nbsp;GENERÁCIE",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Vysoký výkon, ktorý dokáže viac",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 13.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 13.&nbsp;GENERÁCIE",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Nad rámec výkonu",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 13.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 13. GENERÁCIE",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>jadrá</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>vlákna</span></span>",
                    "tr_badgePreTitle": "[p_thread] na Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] na Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Plynulé pripojenie k telefónu<sup>24</sup>, inteligentný výber najsilnejšieho signálu Wi-Fi a rýchle nabíjanie batérie s dlhou výdržou.",
                "tr_title": "Choďte za hranice výkonu a zvládnite toho viac odkiaľkoľvek",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 13.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 13. GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Plynulé pripojenie k telefónu<sup>24</sup>, inteligentný výber najsilnejšieho signálu Wi-Fi a rýchle nabíjanie batérie s dlhou výdržou.",
                "tr_title": "Choďte za hranicu výkonu a umožnite počítaču zvládnuť viac vecí súčasne",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 13.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 13.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Plynulé pripojenie k telefónu<sup>24</sup>, inteligentný výber najsilnejšieho signálu Wi-Fi a rýchle nabíjanie batérie s dlhou výdržou.",
                "tr_title": "Choďte za hranice výkonu a rýchlo stihnete aj tie najnáročnejšie úlohy",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 13.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 13. GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Nad rámec výkonu",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 13.&nbsp;generácie",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 13. GENERÁCIE",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 13.&nbsp;generácie",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 13.&nbsp;GENERÁCIE",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 13.&nbsp;generácie",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 13. GENERÁCIE",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>jadrá</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>vlákna</span></span>",
                    "tr_badgePreTitle": "[p_thread] na Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] na Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Nad rámec výkonu",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ i5",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ i7",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ i9",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>jadrá</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>vlákna</span></span>",
                    "tr_badgePreTitle": "[p_thread] na Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] na Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Spoľahlivý výkon určený k tomu, aby zvládol viac",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Procesor Intel® Core™ i3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Skutočný výkon, s ktorým dokáže viac",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Procesor Intel® Core™ i5",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Vysoký výkon, ktorý dokáže viac",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Procesor Intel® Core™ i7",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Nad rámec výkonu",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ i5",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ i7",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ i9",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>jadrá</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>vlákna</span></span>",
                    "tr_badgePreTitle": "[p_thread] na Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] na Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Spoľahlivý výkon určený k tomu, aby zvládol viac",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Skutočný výkon, s ktorým dokáže viac",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;5",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ 5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Vysoký výkon, ktorý dokáže viac",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;7",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ 7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Procesor Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Procesor",
                "tr_title": "Hraj bez kompromisov",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ Ultra 5",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ ULTRA 5",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ Ultra 7",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ ULTRA 7",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Procesor Intel® Core™ Ultra 9",
                    "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ ULTRA 9",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>jadrá</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core] LPE-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>vlákna</span></span>",
                    "tr_badgePreTitle": "[p_thread] na Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] na Efficient-cores",
                    "tr_badgeSubTitle1": "[l_thread] na LPE-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ Ultra 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ Ultra 7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Procesor Intel® Core™ Ultra 9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Hraj bez kompromisov",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Hraj bez kompromisov",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Hraj bez kompromisov",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Hraj bez kompromisov",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Hraj bez kompromisov",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Hraj bez kompromisov",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Malé firmy si zaslúžia veľké obchodné riešenia",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i5 13.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i5 13. GENERÁCIE",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Potlačenie hluku a rozmazanie pozadia",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Výkon sa automaticky prispôsobuje a uprednostňuje najčastejšie vykonávané úlohy",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Vyskúšajte plynulé a vysokokvalitné webové hovory",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Získate jedinečné hardvérové zabezpečenia",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Pomáha chrániť zariadenia a ich údaje pred fyzickými pokusmi o manipuláciu",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Malé firmy si zaslúžia veľké obchodné riešenia",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i7 13.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i7 13.&nbsp;GENERÁCIE",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Potlačenie hluku a rozmazanie pozadia",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Výkon sa automaticky prispôsobuje a uprednostňuje najčastejšie vykonávané úlohy",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Vyskúšajte plynulé a vysokokvalitné webové hovory",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Získate jedinečné hardvérové zabezpečenia",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Pomáha chrániť zariadenia a ich údaje pred fyzickými pokusmi o manipuláciu",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Malé firmy si zaslúžia veľké obchodné riešenia",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™&nbsp;i9 13.&nbsp;generácie",
                "tr_gen_core_title_uppercase": "PROCESOR INTEL® CORE™ i9 13. GENERÁCIE",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "AKÝ JE TENTO PROCESOR V POROVNANÍ S OSTATNÝMI?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Aktuálne zariadenie",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Potlačenie hluku a rozmazanie pozadia",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Výkon sa automaticky prispôsobuje a uprednostňuje najčastejšie vykonávané úlohy",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Vyskúšajte plynulé a vysokokvalitné webové hovory",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Získate jedinečné hardvérové zabezpečenia",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Pomáha chrániť zariadenia a ich údaje pred fyzickými pokusmi o manipuláciu",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Objavte nové zážitky s umelou inteligenciou pri podnikaní",
                "tr_title": "Vďaka procesorom Intel® Core™ Ultra&nbsp;[XX] a platforme Intel vPro® budete pripravení na to, čo príde",
                "tr_gen_core_title": "Procesor Intel® Core™ Ultra [XX]",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "POSUŇTE PODNIKANIE NA NOVÚ ÚROVEŇ S POČÍTAČMI S UMELOU INTELIGENCIOU OD SPOLOČNOSTI INTEL",
                    "tr_badgeSubTitle": "Výkonné počítače, na ktorých beží všetko rýchlo a spoľahlivo, sú základom podnikateľskej produktivity. Platforma Intel vPro® zvyšuje efektivitu a výkon zamestnancov pri rôznych pracovných úlohách a aplikáciách vďaka novej architektúre, ktorá poskytuje vynikajúci výkon a energetickú efektivitu pri širokej škále komplexných pracovných úloh tak, že posiela správnu úlohu správnemu procesoru v správnom čase.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "VIACVRSTVOVÉ FUNKCIE ZABEZPEČENIA AKTÍVNE HNEĎ PO PRVOM SPUSTENÍ",
                    "tr_badgeSubTitle": "Nové a vznikajúce hrozby si vyžadujú komplexný prístup k zabezpečeniu. Platforma Intel vPro® poskytuje ochranu na úrovni hardvéru v celom systéme, takže vaša firma je lepšie chránená. Ide o jedinečné opatrenia zabezpečenia, vrátane funkcií detekcie hrozieb na báze umelej inteligencie, ktoré pomáhajú chrániť vašich používateľov, vaše údaje a vaše podnikanie.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "ZABUDOVANÉ NÁSTROJE UMOŽŇUJÚ POUŽÍVATEĽOM PRACOVAŤ PRAKTICKY ODKIAĽKOĽVEK",
                    "tr_badgeSubTitle": "Kancelárie a počítače sú dnes všade. Platforma Intel vPro® poskytuje moderné hardvérové možnosti správy, a šetrí tak podnikom prácu spojenú so správou počítačov, zjednodušujú podporu a zlepšujú komfort pre koncových používateľov, a to všetko s dôrazom na udržateľnosť výpočtovej techniky.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Viac než skvelý počítač, počítač s umelou inteligenciou",
                "tr_subtitle1": "POHÁŇANÉ PROCESORMI INTEL®&nbsp;CORE™ ULTRA (SERIES&nbsp;2)",
                "tr_subtitle2": "Aj na vašom počítači Intel AI",
                "tr_subtitle": "Najefektívnejší, najbezpečnejší a najvýkonnejší procesor pre prenosné počítače od spoločnosti Intel.<sup>39</sup>",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;5 (Series&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Kreatívnejší</span> vďaka nástrojom umelej inteligencie pre rýchlejšie tvorenie"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Produktívnejší</span> vďaka podpore umelej inteligencie, ktorá šetrí čas"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Bezpečnejší</span> vďaka vstavanej ochrane údajov"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Viac než skvelý počítač, počítač s umelou inteligenciou",
                "tr_subtitle1": "POHÁŇANÉ PROCESORMI INTEL®&nbsp;CORE™ ULTRA (SERIES&nbsp;2)",
                "tr_subtitle2": "Aj na vašom počítači Intel AI",
                "tr_subtitle": "Najefektívnejší, najbezpečnejší a najvýkonnejší procesor pre prenosné počítače od spoločnosti Intel.<sup>39</sup>",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;7 (Series&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Kreatívnejší</span> vďaka nástrojom umelej inteligencie pre rýchlejšie tvorenie"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Produktívnejší</span> vďaka podpore umelej inteligencie, ktorá šetrí čas"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Bezpečnejší</span> vďaka vstavanej ochrane údajov"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Viac než skvelý počítač, počítač s umelou inteligenciou",
                "tr_subtitle1": "POHÁŇANÉ PROCESORMI INTEL®&nbsp;CORE™ ULTRA (SERIES&nbsp;2)",
                "tr_subtitle2": "Aj na vašom počítači Intel AI",
                "tr_subtitle": "Najefektívnejší, najbezpečnejší a najvýkonnejší procesor pre prenosné počítače od spoločnosti Intel.<sup>39</sup>",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;5 (Series&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Viac než skvelý počítač, počítač s umelou inteligenciou",
                "tr_subtitle1": "POHÁŇANÉ PROCESORMI INTEL®&nbsp;CORE™ ULTRA (SERIES&nbsp;2)",
                "tr_subtitle2": "Aj na vašom počítači Intel AI",
                "tr_subtitle": "Najefektívnejší, najbezpečnejší a najvýkonnejší procesor pre prenosné počítače od spoločnosti Intel.<sup>39</sup>",
                "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ Ultra&nbsp;7 (Series&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_tileTitle1": "Samostatná grafika",
                "tr_title": "ČO JE GRAFICKÁ KARTA?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Grafická karta alebo GPU vytvára obraz, ktorý vidíte na obrazovke.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integrovaný procesor GPU",
                    "tr_badgeSubTitle": "Integrovaná grafika využíva rovnakú pamäť RAM ako zvyšok počítača, vďaka čomu je energeticky úspornejší.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Samostatná grafika",
                    "tr_badgeSubTitle": "Ide o najvýkonnejšiu triedu GPU, pretože má vlastnú pamäť a ostatné zdroje určené výhradne na vykresľovanie špičkovej grafiky.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "Hybridná GPU",
                    "tr_badgeSubTitle": "Ide o najvýkonnejšiu triedu GPU, pretože má vlastnú pamäť a ostatné zdroje určené výhradne na vykresľovanie špičkovej grafiky.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Efektívnejšia úprava fotografií a videí",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Nepretržité sledovanie seriálov v 4K rozlíšení",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Hranie obľúbených hier v ohromujúcich detailoch",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Duálne GPU",
                    "tr_badgeSubTitle": "Niektoré systémy, ako napríklad tie s grafikou Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>, dokážu rozdeliť pracovnú záťaž  medzi integrovanú a samostatnú grafiku.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Integrovaný procesor GPU",
                    "tr_badgeSubTitle": "Integrovaná grafika (GPU) je súčasťou rovnakého čipu ako hlavný procesor (CPU). Tento typ grafickej karty používa rovnakú pamäť RAM a ďalšie zdroje ako zvyšok počítača, čo zvyšuje energetickú účinnosť. Jej výsledkom je tenšie a ľahšie zariadenie.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Dedikovaný procesor GPU",
                    "tr_badgeSubTitle": "Ide o najvýkonnejšiu triedu GPU, pretože má vlastnú pamäť a ostatné zdroje určené výhradne na vykresľovanie špičkovej grafiky.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Štandardný",
                    "tr_badgeSubTitle": "Až 4&nbsp;GB VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Súťažný",
                    "tr_badgeSubTitle": "Až 8 GB VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "Viac než 8&nbsp;GB VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Intenzívny grafický výkon",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Na dosiahnutie čo najvýkonnejšieho spracovania grafiky je potrebná grafická karta, ktorá je zameraná výlučne na vytváranie nádherného ostrého obrazu a plynulých animácií.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Na farby bohatá a živá zabudovaná grafika",
                "tr_pretitle": "Grafika Intel® Iris® Plus",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Grafika Intel® Iris® Plus vám umožňuje ponoriť sa do videa v&nbsp;rozlíšení 4K HDR a&nbsp;hrania v&nbsp;rozlíšení Full HD bez potreby ďalšej grafickej karty.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Ohromujúci obraz, skvelý výkon",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Grafiky Intel® posúvajú možnosti integrovaných grafík na novú úroveň a zaisťujú hladký tvorivý proces a bohaté zážitky pri hraní hier.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Grafika Intel® Iris® Pro",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Grafika Intel® UHD s architektúrou X<sup class='small_text_sup_xe'>e</sup>",
                "tr_pretitle": "Pohlcujúce vizuálne zážitky",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Vyskúšajte si efektívnejšiu úpravu fotografií a videí, nepretržite sledujte videá v špičkovej 4K kvalite alebo si zahrajte obľúbené hry v ohromujúcich detailoch.",
                "tr_photo_text": "Efektívnejšia úprava fotografií a videí",
                "tr_video_text": "Nepretržité sledovanie seriálov v 4K rozlíšení",
                "tr_game_text": "Hranie obľúbených hier v ohromujúcich detailoch",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "OVEĽA VIAC NEŽ LEN PEKNÉ OBRÁZKY",
                "tr_subtitle": "Vďaka úzkej spolupráci s procesorom (CPU) pomáha grafická karta (GPU) určovať chod a vzhľad Vašich hier. Pamäť VRAM (video RAM), ktorá je vyhradená iba na grafické úlohy, vám umožní rýchlo objaviť možnosti vašej grafickej karty.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PRE INTENZÍVNU HERNÚ GRAFIKU",
                "tr_subtitle": "Na dosiahnutie čo najvýkonnejšieho spracovania grafiky je potrebná samostatná grafická karta, ktorá je zameraná výlučne na vytváranie nádherného ostrého obrazu a plynulých animácií.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PRE INTENZÍVNU HERNÚ GRAFIKU",
                "tr_subtitle": "Na dosiahnutie čo najvýkonnejšieho spracovania grafiky je potrebná samostatná grafická karta, ktorá je zameraná výlučne na vytváranie nádherného ostrého obrazu a plynulých animácií.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "OVEĽA VIAC NEŽ LEN PEKNÉ OBRÁZKY",
                "tr_subtitle": "Vďaka úzkej spolupráci s procesorom (CPU) pomáha grafická karta (GPU) určovať chod a vzhľad Vašich hier. Pamäť VRAM (video RAM), ktorá je vyhradená iba na grafické úlohy, vám umožní rýchlo objaviť možnosti vašej grafickej karty.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Intenzívny grafický výkon",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Na dosiahnutie čo najvýkonnejšieho spracovania grafiky je potrebná grafická karta, ktorá je zameraná výlučne na vytváranie nádherného ostrého obrazu a plynulých animácií.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "PRE INTENZÍVNU HERNÚ GRAFIKU",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "Na dosiahnutie čo najvýkonnejšieho spracovania grafiky je potrebná grafická karta, ktorá je zameraná výlučne na vytváranie nádherného ostrého obrazu a plynulých animácií.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Na dosiahnutie čo najvýkonnejšieho spracovania grafiky je potrebná samostatná grafická karta, ktorá je zameraná výlučne na vytváranie nádherného ostrého obrazu a plynulých animácií.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Poskytuje dostatok grafického výkonu na splnenie minimálnych požiadaviek hier.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Pre náročných hráčov, ktorí požadujú hranie na profesionálnej úrovni",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Pre pokročilých hráčov, ktorí používajú svoj počítač aj na intenzívne vytváranie obsahu",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Video plné života a hry, ktoré vás pohltia",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Vyťažte zo svojej obrazovky s vysokým rozlíšením maximum s pomocou grafiky Intel® HD Graphics. Vďaka integrovanej grafike si vychutnáte bohaté multimediálne zážitky.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Sila pre živé videá a fotografie",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Získajte viac detailov a žiarivých farieb zo streamovaného videa a fotografií.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Úžasná tvorba a hranie hier",
                "tr_pretitle": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Od pútavej zábavy a pokročilej tvorby obsahu až po hranie hier, grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> prináša do tenkých a ľahkých počítačov grafickú výkonnosť, ktorá zmení váš pohľad na dostupné možnosti.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_pretitle": "PRE INTENZÍVNU HERNÚ GRAFIKU",
                "tr_title": "Grafika Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Na dosiahnutie čo najvýkonnejšieho spracovania grafiky je potrebná samostatná grafická karta, ktorá je zameraná výlučne na vytváranie nádherného ostrého obrazu a plynulých animácií.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Prebuďte svoju kreativitu",
                "tr_pretitle": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Zvládajte kreatívne projekty s ľahkosťou a ponorte sa do širokej palety hier pomocou kombinovaného výkonu integrovanej grafiky a prvej samostatnej grafiky od spoločnosti Intel®.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "tr_title": "Grafika Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "tr_pretitle": "PRE INTENZÍVNU HERNÚ GRAFIKU",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Na dosiahnutie čo najvýkonnejšieho spracovania grafiky je potrebná samostatná grafická karta, ktorá je zameraná výlučne na vytváranie nádherného ostrého obrazu a plynulých animácií.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Grafika",
                "tr_title": "Nádherná grafika zabudovaná v zariadení",
                "tr_subtitle": "Vychutnávajte si hry, filmy a webové videá s úžasnými detailmi a vynikajúcou ostrosťou.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Grafika",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Zlepšite svoje pracovné postupy"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Zvýšte svoj herný výkon"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Zvýšte svoj herný výkon"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Povýšte hry na novú úroveň"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "PREBUĎTE SVOJU KREATIVITU",
                    "tr_specText": "Zvýšte svoju produktivitu, kdekoľvek idete, vďaka najkomplexnejším multimediálnym možnostiam, ako napríklad <strong>vôbec prvému hardvérovému kódovaniu AV1</strong> a tvorbe obsahu akcelerovanej umelou inteligenciou.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "PREBUĎTE SVOJU KREATIVITU",
                    "tr_specText": "Zvýšte svoju produktivitu vďaka najkomplexnejším multimediálnym možnostiam, ako napríklad <strong>vôbec prvému hardvérovému kódovaniu AV1</strong> a tvorbe obsahu akcelerovanej umelou inteligenciou.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "PREBUĎTE SVOJU KREATIVITU",
                    "tr_specText": "Zažite špičkovú tvorbu obsahu akcelerovanú umelou inteligenciou a s <strong>vôbec prvým hardvérovým kódovaním AV1</strong> vrátane pokročilého <strong>X<sup>e</sup> Media Engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "PREBUĎTE SVOJU KREATIVITU",
                    "tr_specText": "Zažite špičkovú tvorbu obsahu akcelerovanú umelou inteligenciou a s <strong>vôbec prvým hardvérovým kódovaním AV1</strong> vrátane pokročilého <strong>X<sup>e</sup> Media Engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "PREBUĎTE SVOJU KREATIVITU",
                    "tr_specText": "Zažite špičkovú tvorbu obsahu akcelerovanú umelou inteligenciou a s <strong>vôbec prvým hardvérovým kódovaním AV1</strong> vrátane pokročilého <strong>X<sup>e</sup> Media Engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "PRENOSNOSŤ V SPOJENÍ S VÝKONOM",
                    "tr_specText": "Zahrajte si najnovšie hry na cestách, získajte mimoriadny výkon a spoľahlivosť vďaka moderným možnostiam hrania. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling): </strong>Technológia Intel® vylepšená umelou inteligenciou pre vyšší výkon a plynulé hranie. <br/><br/>Podpora <strong>DirectX* 12 Ultimate:  </strong>Povýšte hranie na ešte realistickejšiu úroveň s grafickými technológiami ako sledovanie lúčov (ray tracing) a VRS (variable rate shading)."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "PREVEZMITE KONTROLU NAD SVOJÍM HRANÍM",
                    "tr_specText": "<strong>Ovládacie centrum Intel® Arc™</strong> umožňuje vylepšiť používateľský zážitok vďaka intuitívnemu a modernému rozhraniu. Získajte bezproblémové aktualizácie ovládača, optimalizujte svoje hry a omnoho viac."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "PREVEZMITE KONTROLU NAD SVOJÍM HRANÍM",
                    "tr_specText": "<strong>Ovládacie centrum Intel® Arc™</strong> umožňuje vylepšiť používateľský zážitok vďaka intuitívnemu a modernému rozhraniu. Získajte bezproblémové aktualizácie ovládača, optimalizujte svoje hry a omnoho viac."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "PREVEZMITE KONTROLU NAD SVOJÍM HRANÍM",
                    "tr_specText": "Získajte bezproblémové aktualizácie ovládača a optimalizujte väčšinu svojich obľúbených hier vďaka <strong>Ovládaciemu centru Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "MAXIMALIZUJTE SVOJU PLATFORMU INTEL®",
                    "tr_specText": "Vďaka spojeniu s kompatibilnými procesormi Intel® Core™ môžete využiť <strong>Technológiu Intel® Deep Link</strong> na zlepšenie zážitkov z tvorby, hrania hier a streamovania."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "MAXIMALIZUJTE SVOJ SYSTÉM INTEL®",
                    "tr_specText": "Vďaka spojeniu s kompatibilnými procesormi Intel® Core™ môžete využiť <strong>Technológiu Intel® Deep Link</strong> na zlepšenie zážitkov z tvorby, hrania hier a streamovania."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "MAXIMALIZUJTE SVOJU PLATFORMU INTEL®",
                    "tr_specText": "Vďaka spojeniu s kompatibilnými procesormi Intel® Core™ môžete využiť <strong>Technológiu Intel® Deep Link</strong> na zlepšenie zážitkov z tvorby, hrania hier a streamovania."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAXIMALIZUJTE SVOJU PLATFORMU INTEL®",
                    "tr_specText": "Vďaka spojeniu s kompatibilnými procesormi Intel® Core™ môžete využiť <strong>Technológiu Intel® Deep Link</strong> na zlepšenie zážitkov z tvorby, hrania hier a streamovania."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAXIMALIZUJTE SVOJ SYSTÉM INTEL®",
                    "tr_specText": "Vďaka spojeniu s kompatibilnými procesormi Intel® Core™ môžete využiť <strong>Technológiu Intel® Deep Link</strong> na zlepšenie zážitkov z tvorby, hrania hier a streamovania."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "BUĎTE V CENTRE DIANIA",
                    "tr_specText": "Bezproblémové vysielanie pomocou pokročilých technológií streamovania, ktoré zabezpečuje <strong>Ovládacie centrum Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "BUĎTE V CENTRE DIANIA",
                    "tr_specText": "Bezproblémové vysielanie pomocou pokročilých technológií streamovania, ktoré zabezpečuje <strong>Ovládacie centrum Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "ZAŽITE POHLCUJÚCE HRANIE S VYSOKÝM VÝKONOM",
                    "tr_specText": "Dosiahnite najlepší výkon v najšpičkovejších  herných tituloch, vo vysokom rozlíšení a s vysokou obnovovacou frekvenciou. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling): </strong>Technológia Intel® vylepšená umelou inteligenciou pre vyšší výkon a plynulé hranie. <br/><br/>Podpora <strong>DirectX* 12 Ultimate:  </strong>Povýšte hranie na ešte realistickejšiu úroveň s grafickými technológiami ako sledovanie lúčov (ray tracing) a VRS (variable rate shading)."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "ZAŽITE POHLCUJÚCE HRANIE S VYSOKÝM VÝKONOM",
                    "tr_specText": "Dosiahnite najlepší výkon v najšpičkovejších  herných tituloch, vo vysokom rozlíšení a s vysokou obnovovacou frekvenciou. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling): </strong>Technológia Intel® vylepšená umelou inteligenciou pre vyšší výkon a plynulé hranie. <br/><br/>Podpora <strong>DirectX* 12 Ultimate:  </strong>Povýšte hranie na ešte realistickejšiu úroveň s grafickými technológiami ako sledovanie lúčov (ray tracing) a VRS (variable rate shading)."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "DAJTE HRÁM NOVÝ IMPULZ",
                    "tr_specText": "Zažite hranie na vyššej úrovni s: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Technológia Intel® vylepšená umelou inteligenciou prináša vyšší výkon a plynulé hranie. <br/><br/>Podpora <strong>DirectX* 12 Ultimate:  </strong>Povýšte hranie na ešte realistickejšiu úroveň s grafickými technológiami ako sledovanie lúčov (ray tracing) a VRS (variable rate shading)."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "ZAŽITE POKROČILÉ DYNAMICKÉ HRANIE",
                    "tr_specText": "Vychutnajte si plynulé hranie mnohých populárnych náročných hier a hranie s vysokou obnovovacou frekvenciou pri vysokooktánových e-športových strieľačkách aj rýchlych RTS hrách. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling): </strong>Technológia Intel® vylepšená umelou inteligenciou pre vyšší výkon a plynulé hranie. <br/><br/>Podpora <strong>DirectX* 12 Ultimate:  </strong>Povýšte hranie na ešte realistickejšiu úroveň s grafickými technológiami ako sledovanie lúčov (ray tracing) a VRS (variable rate shading)."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "ZAŽITE POHLCUJÚCE HRANIE S VYSOKÝM VÝKONOM",
                    "tr_specText": "Dosiahnite najlepší výkon v najšpičkovejších  herných tituloch, vo vysokom rozlíšení a s vysokou obnovovacou frekvenciou. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling): </strong>Technológia Intel® vylepšená umelou inteligenciou pre vyšší výkon a plynulé hranie. <br/><br/>Podpora <strong>DirectX* 12 Ultimate:  </strong>Povýšte hranie na ešte realistickejšiu úroveň s grafickými technológiami ako sledovanie lúčov (ray tracing) a VRS (variable rate shading)."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Grafický vesmír, kde je miesto pre každého"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "ZVÝŠTE SNÍMKOVÚ FREKVENCIU A ZRÝCHLITE HRANIE HIER",
                    "tr_specText": "Technológia Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling (X<sup class='small_text_sup_xe'>e</sup>SS) pozdvihne vaše hranie hier na vyššiu úroveň vďaka pokročilému škálovaniu s využitím umelej inteligencie – špičkový výkon s čo najreálnejším obrazom. X<sup class='small_text_sup_xe'>e</sup>SS je optimalizovaný pre produkty s grafikou Intel® Arc™, takže môžete plne využívať zrýchlenie hardvéru Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX)."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "DOKONALÝ OBRAZ SA STÁVA REALITOU",
                    "tr_specText": "Plná podpora DirectX* 12 Ultimate na všetkých grafikách Intel® Arc™ vám prinesie pohlcujúce herné zážitky vďaka najnovším grafickým technológiám, ako ray tracing, VRS, mesh shading a sampler feedback – základy moderného hrania hier."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "JEDNODUCHÁ TVORBA FOTOGRAFIÍ, VIDEÍ A MÉDIÍ",
                    "tr_specText": "Popustite uzdu svojej fantázii a zaujmite divákov s bohatou tvorbou digitálneho obsahu pomocou najmodernejšieho multimediálneho enginu, rozšíreného o podporu umelej inteligencie a akcelerovaného technológiami Intel®. Tvorte pôsobivý obsah vďaka grafike, ktorá podporuje všetky súčasné multimediálne formáty a ponúka najpokročilejšie možnosti kódovania videa vo formáte AV1."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "VYSKÚŠAJTE SI ŠPIČKOVÚ TVORBU A HRANIE HIER S PODPOROU UMELEJ INTELIGENCIE",
                    "tr_specText": "Grafika Intel® Arc™ odomkýna funkcie s umelou inteligenciou, ktoré zvyšujú výkon, od rozšírenej možnosti tvorby až po pohlcujúce hranie hier. Vyťažte čo najviac z moderného softvéru, ktorý poskytuje rozšírenú tvorbu vrátane generovania obrázkov z textu a zrýchlenej úpravy videí s podporou umelej inteligencie. Môžete si takisto vychutnať hranie hier s vysokým rozlíšením, ktoré zabezpečuje škálovanie X<sup class='small_text_sup_xe'>e</sup>SS podporované umelou inteligenciou."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Grafika",
                    "tr_specText": "Vstupenka do sveta profesionálnej grafiky"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "SKVELÉ PRE TVORCOV, DIZAJNÉROV A INŽINIEROV NA CESTÁCH",
                    "tr_specText": "Integrovaná grafika Intel® Arc™ Pro, ktorá je dostupná vo vybraných procesoroch Intel® Core™ Ultra série H, ponúka dvojnásobné zlepšenie grafického výkonu s certifikáciami nezávislých dodávateľov softvéru (ISV) pre zvýšenú spoľahlivosť, a poskytuje efektívne hardvérové zrýchlenie umelej inteligencie a ray tracingu pre ohromujúcu tvorbu obsahu na cestách.<br/><br/><span class ='expandable_heading'>Softvérové certifikácie<br/></span><br/>Spoločnosť Intel úzko spolupracuje so stovkami softvérových spoločností a výsledkom tejto bezkonkurenčnej spolupráce sú grafické karty Intel® Arc™ Pro série A. Certifikácie sú pre nás rovnako dôležité, ako pre vás."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "ABY BOLA UMELÁ INTELIGENCIA DOSTUPNÁ",
                    "tr_specText": "Tento rad grafík zahŕňa širokú škálu skvelých technológií vrátane umelej inteligencie s integrovanou akceleráciou, ktorá v prípade potreby dokáže zrýchliť prácu."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING, ZA KTORÝ SI NEMUSÍTE PRIPLÁCAŤ",
                    "tr_specText": "Od grafickej karty sa očakáva viac, než len vykresľovanie pixelov na obrazovke. Musí tiež vedieť zrýchľovať tvorbu kvalitných obrázkov. Hardvérová technológia ray tracing, ktorá je súčasťou grafických kariet Intel® Arc™ Pro série A, vám v kompatibilnom softvéri umožní objavovať nové profesionálne pracovné postupy. Zatiaľ čo ray tracing je často považovaný za technológiu dostupnú len na drahých grafických kartách, najnovšia séria profesionálnych grafických kariet od Intelu tento predpoklad vyvracia."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "POHLCUJÚCE DISPLEJE, LEPŠIE MOŽNOSTI PRE TVORCOV",
                    "tr_specText": "Plná podpora DirectX* 12 Ultimate na všetkých grafikách Intel® Arc™ vám prinesie pohlcujúce herné zážitky vďaka najnovším grafickým technológiám, ako ray tracing, VRS, mesh shading a sampler feedback – základy moderného hrania hier."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "DYNAMICKY OPTIMALIZOVANÉ OBRÁZKY",
                    "tr_specText": "Vďaka špeciálnemu hardvérovému dekódovaniu na GPU Pro, Dolby Vision* dodáva kompatibilnému displeju väčšiu hĺbku, ostrejší kontrast a viac farieb. Dolby Vision* odomkýna celý potenciál technológie HDR vďaka dynamickej optimalizácii kvality obrazu na základe vášho pripojenia, displeja a platformy, na ktorú posielate ohromujúci obraz."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "ODBORNE OVERENÝ SOFTVÉR",
                    "tr_specText": "Spoločnosť Intel úzko spolupracuje so stovkami softvérových spoločností a výsledkom tejto bezkonkurenčnej spolupráce je profesionálna grafika Intel® Arc™ Pro série A. Certifikácie a oprávnenia softvéru na prácu sú pre nás rovnako dôležité, ako pre vás."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING, ZA KTORÝ SI NEMUSÍTE PRIPLÁCAŤ",
                    "tr_specText": "Od grafickej karty sa očakáva viac, než len vykresľovanie pixelov na obrazovke. Musí tiež vedieť zrýchľovať tvorbu kvalitných obrázkov. Hardvérová technológia ray tracing, ktorá je súčasťou grafických kariet Intel® Arc™ Pro série A, vám v kompatibilnom softvéri umožní objavovať nové profesionálne pracovné postupy. Zatiaľ čo ray tracing je často považovaný za technológiu dostupnú len na drahých grafických kartách, najnovšia séria profesionálnych grafických kariet od Intelu tento predpoklad vyvracia."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "MALÁ GPU PRE NOTEBOOKY, VIACERO VEĽKÝCH DISPLEJOV",
                    "tr_specText": "Grafiky Intel® Arc™ Pro pre notebooky podporujú až štyri veľké displeje, čo vám umožní vykonávať prácu efektívnejšie. Keď k tomu pridáte podporu vysokého dynamického rozsahu (HDR), získate všetko, čo potrebujete pre domáci alebo kancelársky pracovný kútik, ktorý zaujme aj na sociálnych sieťach."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "VYSOKÁ PRIEPUSTNOSŤ PRE PLNÉ VYUŽITIE PAMÄTE",
                    "tr_specText": "Pre grafiku, profesionálne využívanú na cestách, je dôležitým faktorom celková priepustnosť. Ak by bola nízka, spomalilo by to vykonávanú prácu. Práve preto sme sa postarali, aby grafiky Intel® Arc™ Pro série A podporovali moderný štandard PCIe* 4.0, a zároveň nevynechali spätnú kompatibilitu. Vysoká priepustnosť znamená vyššiu prenosovú rýchlosť a umožňuje efektívnejšie využitie pamäte grafickej karty, pretože softvér sa rýchlejšie dostane ku dátam daného projektu."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "VDÝCHNITE ŽIVOT DO KAŽDEJ SNÍMKY",
                    "tr_specText": "<span class='expandable_heading'>Vylepšené pomocou umelej inteligencie. Podporované spoločnosťou Intel.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS je technológia, ktorá podporuje škálovanie pomocou umelej inteligencie, vďaka čomu zvýšite svoj výkon bez obetovania čo i len jednej snímky. Môžete si užiť hranie hier také, aké má byť."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "V PRÍTOMNOSTI.<br/> SKUTOČNE POHLCUJÚCE.<br/> REALISTICKÝ PRÍSTUP V NAJLEPŠEJ PODOBE.",
                    "tr_specText": "Realistickejšie svety na obzore. Vďaka integrovanému ray tracingu ožije všetko, od realistických tieňov a odrazov až po dokonalé nasvietenie. Inými slovami, takto strhujúci vizuálny zážitok z hrania si určite nebudete chcieť nechať ujsť."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "DOKONALÁ HRA, DOKONALÉ ZDIEĽANIE",
                    "tr_specText": "Ponúknite vašim divákom plynulé hranie hier, ktoré si zaslúžia. Vaši diváci budú ohromení každým vaším pohybom vďaka možnostiam kódovania AV1, ktoré poskytuje hardvér grafiky Intel® Arc™. Vyššia kvalita obrazu pri rovnakom dátovom toku znamená, že budete streamovať v najvyššej kvalite s efektívnejším prenosom dát."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Vítame všetkých tvorcov",
                    "tr_specText": "Nové a zdokonalené možnosti umelej inteligencie máte na dosah ruky. Vďaka pokročilým akcelerátorom umelej inteligencie, ktoré sú zabudované v grafike Intel® Arc™ série A, strávite viac času tvorením v aplikáciách a menej času čakaním na ich spustenie.<br/><br/>Vytvárajte videá na úplne novej úrovni a zaraďte sa na špičku s vysokokvalitným, efektívnym AV1 videom, ktoré je poháňané pokročilou hardvérovou podporou AV1, zabudovanou do grafiky Intel® Arc™."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operačný systém",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Všetko, čo potrebujete na vykonanie práce",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Vysoký výkon počítača, ktorý je tenký, ľahký a&nbsp;vyrobený tak, aby vydržal – to všetko môžete mať. Vďaka skvelej výdrži batérie, krásnym dotykovým obrazovkám* a&nbsp;najnovším jednotkám SSD nájdete v&nbsp;počítači so systémom Windows* 10 všetko, čo hľadáte.",
                "tr_noteSection": "*&nbsp;V&nbsp;závislosti od hardvéru",
                "titleArray": [
                  {
                    "tr_title": "Menej je viac",
                    "tr_subtitle": "Dnešné počítače zvyčajne používajú menej energie, takže dosiahnete väčšiu výdrž batérie pri priemerných ôsmich hodinách prehrávania videa.**",
                    "tr_noteSection": "**Výdrž batérie sa výrazne líši v&nbsp;závislosti od nastavení, spôsobu používania a&nbsp;iných faktorov."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operačný systém",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "VŠETKO, ČO POTREBUJETE NA VYKONANIE PRÁCE",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Vysoký výkon počítača, ktorý je tenký, ľahký a&nbsp;vyrobený tak, aby vydržal – to všetko môžete mať. Vďaka skvelej výdrži batérie, krásnym dotykovým obrazovkám* a&nbsp;najnovším jednotkám SSD nájdete v&nbsp;počítači so systémom Windows* 10 všetko, čo hľadáte.",
                "tr_noteSection": "*&nbsp;V&nbsp;závislosti od hardvéru",
                "titleArray": [
                  {
                    "tr_title": "MENEJ JE VIAC",
                    "tr_subtitle": "Dnešné počítače zvyčajne používajú menej energie, takže dosiahnete väčšiu výdrž batérie pri priemerných ôsmich hodinách prehrávania videa.**",
                    "tr_noteSection": "**Výdrž batérie sa výrazne líši v&nbsp;závislosti od nastavení, spôsobu používania a&nbsp;iných faktorov."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operačný systém",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Vytvorený pre bezpečnosť a výkonnosť",
                "tr_pretitle": "Windows* 10 v&nbsp;režime S",
                "tr_subtitle": "Pri používaní Windows* 10 v režime S získate extra vrstvu ochrany, ktorá vám umožní pracovať, hrať a objavovať s istotou, že vaše zariadenie je v bezpečí."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operačný systém",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "VYTVORENÝ PRE BEZPEČNOSŤ A&nbsp;VÝKON",
                "tr_title": "Windows* 10 v&nbsp;režime S",
                "tr_subtitle": "Pri používaní Windows* 10 v režime S získate extra vrstvu ochrany, ktorá vám umožní pracovať, hrať a objavovať s istotou, že vaše zariadenie je v bezpečí."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operačný systém",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Robte skvelé veci",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Výkon, cena a&nbsp;množstvo funkcií na výber. Od inovatívnych zariadení 2&nbsp;v&nbsp;1 až po tenké a ľahké prenosné počítače, dotykové obrazovky a odnímateľné klávesnice – bez ohľadu na svoje finančné možnosti alebo štýl si určite nájdete ten správny počítač so systémom Windows*."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operačný systém",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "ROBTE SKVELÉ VECI",
                "tr_title": "Windows*",
                "tr_subtitle": "Výkon, cena a&nbsp;množstvo funkcií na výber. Od inovatívnych zariadení 2&nbsp;v&nbsp;1 až po tenké a ľahké prenosné počítače, dotykové obrazovky a odnímateľné klávesnice – bez ohľadu na svoje finančné možnosti alebo štýl si určite nájdete ten správny počítač so systémom Windows*."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operačný systém",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Operačný systém",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pamäť",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "KOĽKO PAMÄTE RAM POTREBUJETE?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Každodenný - 2 GB AŽ 8 GB RAM",
                    "tr_badgeSubTitle": "Ideálne pre ľudí, ktorí svoj počítač využívajú prevažne na úpravu textov,&nbsp;surfovanie na internete a&nbsp;zvyčajne nepracujú s&nbsp;viacerými programami súčasne."
                  },
                  {
                    "tr_badgeTitle": "Výkonný - 8 GB AŽ 16 GB RAM",
                    "tr_badgeSubTitle": "Väčšia pamäť RAM zaistí, že vaše aplikácie budú pracovať rýchlejšie a&nbsp;budete môcť používať viacero aplikácií súčasne."
                  },
                  {
                    "tr_badgeTitle": "Pokročilý - VIAC AKO 16&nbsp;GB RAM",
                    "tr_badgeSubTitle": "Ak sa chcete venovať hraniu hier, úprave fotografií alebo videa, či 3D modelovaniu, potrebujete viac pamäte RAM na zvládnutie týchto náročných programov."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMÄŤ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM na každodenné vykonávanie viacerých úloh súčasne",
                "tr_subtitle": "Pamäť vám umožní plynule načítavať a&nbsp;spúšťať základné aplikácie, akými sú textový procesor a&nbsp;prehliadanie internetu.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMÄŤ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM na bezproblémové vykonávanie viacerých úloh súčasne",
                "tr_subtitle": "Ak používate viac programov súčasne, [memory_total]&nbsp;GB pamäte vám umožní jednoducho medzi nimi prechádzať.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMÄŤ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM na pokročilé vykonávanie viacerých úloh súčasne",
                "tr_subtitle": "Máte dostatok pamäte na spustenie viacerých náročných programov súčasne bez spomalenia vášho počítača.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMÄŤ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "Pamäť s kapacitou [Value1] GB = pamäť Intel® Optane™ s kapacitou [Value2] GB + RAM s kapacitou [Value3] GB",
                "tr_subtitle": "Táto kombinácia pamäťových riešení vám poskytne rýchlejší výkon pevného disku vďaka pamäti Intel® Optane™, ako aj lepšiu odozvu vašej pamäte RAM.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>PAMÄTE</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pamäť Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Menej čakania. Viac toho, čo milujete.",
                "tr_subtitle": "Keď pamäť Intel® Optane™ spolupracuje s pamäťou systému, získate rýchlejší prístup k často používaným programom, pretože sa spustia a načítajú práve vtedy, keď ich potrebujete.",
                "tr_pretitle": "[XX] GB pamäte Intel® Optane™",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "AKO SA PAMÄŤ PREJAVÍ V MOJOM POČÍTAČI?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Rýchlejšie vytváranie obsahu",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Hranie s lepšou odozvou",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Jednoduchší multitasking",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Rýchlejšie otváranie programov",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pamäť Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Zrýchlite svoje hranie</span>",
                "tr_pretitle": "[XX] GB pamäte Intel® Optane™",
                "tr_pretitle1": "[XX] GB pamäte Intel® Optane™ zrýchľuje vaše hranie",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Spúšťajte hry až o<br/><span class=\"optane-game\">98&nbsp;% rýchlejšie</span><br/>Z dátovej jednotky s procesorom [optane_legal_processor_name] s pamäťou Intel® Optane™ <sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Úrovne načítania až<br/><span class=\"optane-game\">4,1× rýchlejšie</span><br/>Z dátovej jednotky s procesorom [optane_legal_processor_name] s pamäťou Intel® Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Pamäť Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Zrýchlite svoje hranie</span>",
                "tr_pretitle": "[XX] GB pamäte Intel® Optane™",
                "tr_pretitle1": "[XX] GB pamäte Intel® Optane™ zrýchľuje vaše hranie",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Spúšťajte hry až o<br/><span class=\"optane-game\">98&nbsp;% rýchlejšie</span><br/>Z dátovej jednotky s procesorom [optane_legal_processor_name] s pamäťou Intel® Optane™ <sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Úrovne načítania až<br/><span class=\"optane-game\">4,1× rýchlejšie</span><br/>Z dátovej jednotky s procesorom [optane_legal_processor_name] s pamäťou Intel® Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMÄŤ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Pamäť s kapacitou [Value1] GB = pamäť Intel® Optane™ s kapacitou [Value2] GB + RAM s kapacitou [Value3] GB",
                "tr_subtitle": "Táto kombinácia pamäťových riešení vám poskytne rýchlejší výkon pevného disku vďaka pamäti Intel® Optane™, ako aj lepšiu odozvu vašej pamäte RAM.",
                "titleArray": [
                  {
                    "tr_title": "[XX]&nbsp;GB RAM na multitasking",
                    "tr_subtitle": "Ak používate viac programov súčasne, [memory_total]&nbsp;GB pamäte vám umožní jednoducho medzi nimi prechádzať."
                  },
                  {
                    "tr_title": "Na každodenné úlohy",
                    "tr_subtitle": "Pamäť vám umožní plynule načítavať a&nbsp;spúšťať základné aplikácie, akými sú textový procesor a&nbsp;prehliadanie internetu."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMÄŤ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB RAM",
                "tr_pretitle": "SPÚŠŤAJTE VIAC APLIKÁCIÍ NARAZ",
                "tr_subtitle": "Pamäť RAM poskytuje procesoru jednoduchší prístup k súborom programov, vďaka čomu k nim získa procesor rýchlejší prístup, takže môže pracovať na viacerých úlohách naraz.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Štandardná pamäť",
                    "tr_badgeSubTitle": "Až 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Rozšírená pamäť",
                    "tr_badgeSubTitle": "Pamäť RAM od 8 GB až do 16 GB"
                  },
                  {
                    "tr_badgeTitle": "Elitná pamäť",
                    "tr_badgeSubTitle": "Viac než 16&nbsp;GB RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "PAMÄŤ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB pre multitasking",
                "tr_pretitle": "SPÚŠŤAJTE VIAC APLIKÁCIÍ NARAZ",
                "tr_subtitle": "Pamäť RAM poskytuje procesoru jednoduchší prístup k súborom programov, vďaka čomu k nim získa procesor rýchlejší prístup, takže môže pracovať na viacerých úlohách naraz.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Štandardná pamäť",
                    "tr_badgeSubTitle": "Až 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Rozšírená pamäť",
                    "tr_badgeSubTitle": "Pamäť RAM od 8 GB až do 16 GB"
                  },
                  {
                    "tr_badgeTitle": "Elitná pamäť",
                    "tr_badgeSubTitle": "Viac než 16&nbsp;GB RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "AKÁ JE TÁTO PAMÄŤ V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Štandardný",
                      "tr_badgeSubTitle": "<span class='memory-value'>8&nbsp;GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Rozšírené",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Elita",
                      "tr_badgeSubTitle": "<span class='memory-value'>32&nbsp;GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pamäť",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "AKÁ JE TÁTO PAMÄŤ V POROVNANÍ S OSTATNÝMI?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "AKÁ JE TÁTO PAMÄŤ V POROVNANÍ S OSTATNÝMI?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Aktuálne zariadenie",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Každodenné používanie",
                      "tr_badgeSubTitle": "<span class='memory-value'>8&nbsp;GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Výkonný model",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Rozšírené",
                      "tr_badgeSubTitle": "<span class='memory-value'>32&nbsp;GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Úložný priestor",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "KOĽKO TOHO DOKÁŽE VÁŠ POČÍTAČ UCHOVAŤ?",
                "tr_subtitle": "Nemusíte hádať. Ukážeme vám presne, koľko toho môžete v tomto počítači uložiť.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Vytvorte si knižnicu obsahujúcu</span> <br/><span class=\"storage-standard\">[num_songs] skladieb<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>&nbsp;Pri priemernej veľkosti jednej skladby 5&nbsp;MB",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Vytvorte si knižnicu obsahujúcu </span><br/><span class=\"storage-standard\">[num_games] hier<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>&nbsp;Pri priemernej veľkosti jednej hry 40&nbsp;GB.",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Úložný priestor na </span><br/><span class=\"storage-standard\">[num_videos] videí<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup> Pri priemernej veľkosti jedného videa 5&nbsp;GB",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Nasnímajte a&nbsp;uložte</span><br/><span class=\"storage-standard\">[num_photos]&nbsp;fotografií<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup> Pri priemernej veľkosti jednej fotografie 4&nbsp;MB",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "[capacity]&nbsp;GB HDD"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "[capacity]&nbsp;GB SSD"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "Pamäť Intel® Optane™ [total_optane_memory] GB + [convertedSSD] GB SSD"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ÚLOŽNÝ PRIESTOR",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "Disk SSD s kapacitou [capacity] GB",
                "tr_preTitle1": "Disk SSD s kapacitou [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Tento počítač má SSD disk s&nbsp;kapacitou [capacity] GB. To znamená, že si budete môcť uložiť úctyhodný počet fotografií, videí a&nbsp;programov a&nbsp;dostanete sa k&nbsp;nim oveľa rýchlejšie, ako keby ste ich mali na pevnom disku.",
                    "tr_title": "Miesto pre všetko v počítači"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Tento počítač má SSD disk s&nbsp;kapacitou [capacity] TB. To znamená, že si budete môcť uložiť úctyhodný počet fotografií, videí a&nbsp;programov a&nbsp;dostanete sa k&nbsp;nim oveľa rýchlejšie, ako keby ste ich mali na pevnom disku.",
                    "tr_title": "Toto zariadenie má pôsobivý úložný priestor"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>KAPACITA</span> <span class='value'>[capacity] GB<br/></span>",
                    "tr_badgeTitle1": "<span class='subtitle-value'>KAPACITA</span> <span class='value'>[capacity] TB<br/></span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "Disk SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ÚLOŽNÝ PRIESTOR",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "Disk HDD s kapacitou [capacity] GB",
                "tr_preTitle1": "Disk HDD s kapacitou [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Pevné disky sú dobrou voľbou pre ľudí, ktorí chcú veľké množstvo úložného priestoru priamo vo svojom počítači.",
                    "tr_title": "Miesto pre všetko v počítači"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Pevné disky sú dobrou voľbou pre ľudí, ktorí chcú veľké množstvo úložného priestoru priamo vo svojom počítači.",
                    "tr_title": "Toto zariadenie má pôsobivý úložný priestor"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>ÚLOŽNÉHO PRIESTORU</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "Pevný disk",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ÚLOŽNÝ PRIESTOR",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "KOĽKO TOHO DOKÁŽE VÁŠ POČÍTAČ UCHOVAŤ?",
                "tr_subtitle": "Nemusíte hádať. Ukážeme vám presne, koľko toho môžete v tomto počítači uložiť.",
                "titleArray": [
                  {
                    "tr_title": "[XX]&nbsp;GB HDD"
                  },
                  {
                    "tr_title": "[XX]&nbsp;GB SSD"
                  },
                  {
                    "tr_title": "Disk Intel® SSD s kapacitou [total_capacity]&nbsp;GB akcelerovaný pamäťou Intel® Optane™ s veľkosťou [optane_memory_size]&nbsp;GB"
                  },
                  {
                    "tr_title": "[total_capacity] TB Intel® SSD zrýchlený pamäťou [optane_memory_size]&nbsp;GB Intel® Optane™"
                  },
                  {
                    "tr_title": "[convertedSSD]&nbsp;GB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD"
                  },
                  {
                    "tr_title": "[convertedSSD]&nbsp;TB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>KAPACITA</span> <span class='value'>[capacity] GB<br/></span>",
                        "tr_badgeSubTitle": "Disk SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>KAPACITA</span> <span class='value'>[capacity] GB<br/></span>",
                        "tr_badgeSubTitle": "Pevný disk"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>KAPACITA</span> <span class='value'>[capacity] GB<br/></span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>KAPACITA</span> <span class='value'>[capacity] TB<br/></span>",
                        "tr_badgeSubTitle": "Disk SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>KAPACITA</span> <span class='value'>[capacity] TB<br/></span>",
                        "tr_badgeSubTitle": "Pevný disk"
                      },
                      {
                        "tr_badgeTitle": "<span class='subtitle-value'>KAPACITA</span> <span class='value'>[capacity] TB<br/></span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ÚLOŽNÝ PRIESTOR",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "Disk SSD s kapacitou [convertedSSD] GB + disk HDD [convertedHDD] GB",
                "tr_preTitle1": "Disk SSD s kapacitou [convertedSSD] TB + disk HDD [convertedHDD] GB",
                "tr_preTitle2": "Disk SSD s kapacitou [convertedSSD] GB + disk HDD [convertedHDD] TB",
                "tr_preTitle3": "Disk SSD s kapacitou [convertedSSD] TB + disk HDD [convertedHDD] TB",
                "titleArray": [
                  {
                    "tr_title": "Miesto pre všetko v počítači",
                    "tr_title1": "Toto zariadenie má pôsobivý úložný priestor",
                    "tr_subtitle": "Tento počítač je vybavený diskom SSD s&nbsp;úložnou kapacitou [convertedSSD] GB a&nbsp;pevným diskom s kapacitou [convertedHDD] GB. Vďaka tomu získate obrovskú kapacitu spolu s rýchlym úložným výkonom.",
                    "tr_subtitle1": "Tento počítač je vybavený diskom SSD s&nbsp;úložnou kapacitou [convertedSSD] TB a&nbsp;pevným diskom s kapacitou [convertedHDD] GB. Vďaka tomu získate obrovskú kapacitu spolu s rýchlym úložným výkonom.",
                    "tr_subtitle2": "Tento počítač je vybavený diskom SSD s&nbsp;úložnou kapacitou [convertedSSD] GB a&nbsp;pevným diskom s kapacitou [convertedHDD] TB. Vďaka tomu získate obrovskú kapacitu spolu s rýchlym úložným výkonom.",
                    "tr_subtitle3": "Tento počítač je vybavený diskom SSD s&nbsp;úložnou kapacitou [convertedSSD] TB a&nbsp;pevným diskom s kapacitou [convertedHDD] TB. Vďaka tomu získate obrovskú kapacitu spolu s rýchlym úložným výkonom."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>KAPACITA</span> <span class='value'>[capacity] GB<br/></span>",
                    "tr_badgeTitle1": "<span class='subtitle-value'>KAPACITA</span> <span class='value'>[capacity] TB<br/></span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ÚLOŽNÝ PRIESTOR",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Získajte rýchlejší prístup k vysokokapacitnému úložisku vďaka pamäti Intel® Optane™ s diskom SSD.",
                "tr_title": "Obrovské súbory sú vítané",
                "tr_pretitle": "Disk Intel® SSD s kapacitou [total_capacity]&nbsp;GB akcelerovaný pamäťou Intel® Optane™ s veľkosťou [optane_memory_size]&nbsp;GB",
                "tr_pretitle1": "[total_capacity] TB Intel® SSD zrýchlený pamäťou [optane_memory_size]&nbsp;GB Intel® Optane™",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Disk SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Toto revolučné úložisko ako prvé spája rýchlosť pamäte Intel® Optane™ a vysokokapacitný disk SSD v jednom čipe. Toto všetko pre vás robí:",
                    "tr_title": "ČO JE PAMÄŤ INTEL® OPTANE™ S&nbsp;DISKOM SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Rýchlo otvoríte to, čo potrebujete",
                    "tr_badgeSubTitle": "Vďaka prednačítaniu často používaných súborov a programov môžete stráviť viac času tvorením a menej času čakaním.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Úložisko plus rýchlosť",
                    "tr_badgeSubTitle": "Vďaka rýchlemu výkonu disku SSD budete mať dostatočný požadovaný priestor pre mediálne súbory.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Vyššia odozva v&nbsp;priebehu času",
                    "tr_badgeSubTitle": "Pamäť Intel® Optane™ s&nbsp;diskom SSD sa oboznámi so spôsobom, akým pracujete, a na základe toho zvýši efektivitu vášho počítača.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ÚLOŽNÝ PRIESTOR",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Majte všetky svoje médiá po ruke s&nbsp;množstvom spoľahlivého úložného priestoru.",
                "tr_title": "Priestor pre všetky vaše fotografie, videá a skladby"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Displej",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] mil. pixelov, ktorých sa môžete dotknúť",
                "tr_subtitle": "Komunikujte s počítačom ako so smartfónom vďaka intuitívnemu ovládaniu a ostrejším, živším detailom.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]-palcový<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rozlíšenie: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Pixely: [screenResolution]&nbsp;milióna"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]-palcový displej",
                    "tr_badgeSubTitle": "Výška obrazovky: [screenHeight] palcov",
                    "tr_badgePreTitle": "Šírka obrazovky: [YY]&nbsp;palcov"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Dotykový displej",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Displej",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Ponorte sa do [screenResolution] mil. pixelov",
                "tr_subtitle": "Všetko sa odvíja od pixelov. Čím je ich viac, tým ostrejšie a&nbsp;živšie budú vaše obrázky.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]-palcový<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rozlíšenie: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Pixely: [screenResolution]&nbsp;milióna"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]-palcový displej",
                    "tr_badgeSubTitle": "Výška obrazovky: [screenHeight] palcov",
                    "tr_badgePreTitle": "Šírka obrazovky: [YY]&nbsp;palcov"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Displej",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] palcov <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "OKNO DO VAŠICH HERNÝCH SVETOV",
                "tr_subtitle": "Rovnako ako aj pri výbere televízora, pri výbere herného displeja vás bude najviac zaujímať jeho veľkosť a rozlíšenie.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rozlíšenie: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Pixely: [screenResolution]&nbsp;milióna"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]-palcový displej",
                    "tr_badgeSubTitle": "Výška obrazovky: [screenHeight] palcov",
                    "tr_badgePreTitle": "Šírka obrazovky: [screenWidth] palcov"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Dotykový displej"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Displej",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Toto zariadenie má pôsobivý displej",
                "tr_subtitle": "Osemnásobne viac pixelov v porovnaní s HD posunie vaše videá a hry na novú úroveň ostrosti a farebnosti.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Rozlíšenie: [screenResolutionX]&nbsp;X&nbsp;[screenResolutionY]",
                    "tr_badgePreTitle": "Pixely: [screenResolution]&nbsp;milióna"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal]-palcový displej",
                    "tr_badgeSubTitle": "Výška obrazovky: [screenHeight] palcov",
                    "tr_badgePreTitle": "Šírka obrazovky: [YY]&nbsp;palcov"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Displej",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] milióna pixelov na potiahnutie prstom a dotyk",
                    "tr_subTitle": "Sledujte a používajte obľúbený obsah s ostrejšími a živšími detailmi."
                  },
                  {
                    "tr_title": "[screenResolution] milióna pixelov, ktoré rozšíria váš digitálny svet",
                    "tr_subTitle": "Všetko sa odvíja od pixelov. Čím je ich viac, tým ostrejšie a&nbsp;živšie budú vaše obrázky."
                  },
                  {
                    "tr_title": "Toto zariadenie má pôsobivý displej",
                    "tr_subTitle": "Vďaka takmer štvornásobne vyššiemu počtu pixelov než rozlíšenie HD budú mať vaše videá a hry úplne novú úroveň ostrosti a farieb."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pripojenie",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Technológia Thunderbolt™ 3 poskytuje najrýchlejšie a najuniverzálnejšie pripojenie k dokovacej stanici a zobrazovacím či dátovým zariadeniam.",
                "tr_title": "Jeden port, ktorý zvládne všetko",
                "tr_title1": "JEDEN PORT, KTORÝ ZVLÁDNE VŠETKO",
                "titleArray": [
                  {
                    "tr_title": "AKO MÔŽEM VYUŽÍVAŤ TECHNOLÓGIU THUNDERBOLT™ 3?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Technológia Thunderbolt™ 3"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "Monitory s&nbsp;rozlíšením 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Pripojte displeje s úžasným rozlíšením, kontrastom a farbami"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Externá grafická karta",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Výrazne vylepšite grafiku ľubovoľného počítača"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Rýchle pripojenie k&nbsp;sieti",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Umožňuje rýchle pripojenie medzi rovnocennými zariadeniami (peer-to-peer)"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pripojenie",
                "detailBGUrl": "",
                "tr_subtitle": "Technológia Thunderbolt™&nbsp;3 prenáša dáta vyššou rýchlosťou ako klasické rozhranie USB, čo umožňuje rýchle presúvanie súborov a&nbsp;jednoduchú integráciu periférnych zariadení, ako sú viaceré monitory alebo externé grafické karty.",
                "tr_title": "Technológia Thunderbolt™ 3",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "OSLNIVO RÝCHLY PRENOS",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mb/s",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gb/s",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Technológia Thunderbolt™ 3",
                    "tr_badgeSubTitle": "40 Gb/s",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pripojenie",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Jeden univerzálny port a káblová podpora pre celé vaše obľúbené príslušenstvo</li> <li>Spoľahlivé nabíjanie notebooku</li> <li>Dôsledná certifikácia vám dá pokoj na duši</li></ul>",
                "tr_title": "Skutočne univerzálne káblové pripojenie",
                "tr_title1": "SKUTOČNE UNIVERZÁLNE KÁBLOVÉ PRIPOJENIE",
                "tr_noteSection": "<sup>†</sup>Na základe minimálnych požiadaviek na parametre",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "AKO MÔŽEM VYUŽIŤ TECHNOLÓGIU THUNDERBOLT™ 4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Technológia Thunderbolt™&nbsp;4"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Tvorba",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Pripojte externý monitor a rýchle úložisko, aby ste mohli zobraziť a upravovať fotografie a videá."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dokovanie",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Pripojte svoj prenosný počítač k všetkému príslušenstvu jedným káblom a váš písací stôl bude pôsobiť jednoducho a upratane."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Hra",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Pridajte viac kapacity pomocou SSD a HDD s rýchlosťou až do 3&nbsp;000&nbsp;MB/s, aby ste mohli ukladať knižnice a mediálne súbory hier."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximálna výkonnosť<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x rýchlejšie ako USB* 3.2 <br/>2x rýchlejšie ako HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pripojenie",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Užite si rovnako skvelý výkon technológie Thunderbolt™3 s ešte väčšími možnosťami než doteraz. Vďaka technológii Thunderbolt™ s ľahkosťou pripojíte a môžete používať externé doky, monitory, rýchle úložiská alebo akékoľvek príslušenstvo USB*.",
                "tr_title": "Technológia Thunderbolt™&nbsp;4",
                "tr_pretitle": "SKUTOČNE UNIVERZÁLNE KÁBLOVÉ PRIPOJENIE",
                "tr_noteSection": "<sup>†</sup>Na základe minimálnych požiadaviek na parametre",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Jedným univerzálnym káblom Thunderbolt™&nbsp;4 môžete nahradiť všetky svoje káble USB-C*, čo vám uľahčí pripájanie zariadení."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Dokovacie stanice s&nbsp;technológiou Thunderbolt™&nbsp;4 ponúkajú až štyri porty Thunderbolt™, vďaka čomu máte väčšiu flexibilitu pri pripájaní akéhokoľvek príslušenstva."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximálna výkonnosť<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x rýchlejšie ako USB* 3.2 <br/>2x rýchlejšie ako HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pripojenie",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Jeden univerzálny port a káblová podpora pre celé vaše obľúbené príslušenstvo</li> <li>Spoľahlivé nabíjanie notebooku</li> <li>Dôsledná certifikácia vám dá pokoj na duši</li></ul>",
                "tr_title": "Maximalizujte svoje zážitky s príslušenstvom Thunderbolt™",
                "tr_noteSection": "<sup>†</sup>Na základe minimálnych požiadaviek na parametre",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "AKO MÔŽEM VYUŽIŤ TECHNOLÓGIU THUNDERBOLT™ 4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Tvorba",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Pripojte externý monitor a rýchle úložisko, aby ste mohli zobraziť a upravovať fotografie a videá."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dokovanie",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Pripojte svoj prenosný počítač k všetkému príslušenstvu jedným káblom a váš písací stôl bude pôsobiť jednoducho a upratane."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Hra",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Pridajte viac kapacity pomocou SSD a HDD s rýchlosťou až do 3&nbsp;000&nbsp;MB/s, aby ste mohli ukladať knižnice a mediálne súbory hier."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximálna výkonnosť<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x rýchlejšie ako USB* 3.2 <br/>2x rýchlejšie ako HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "Najčastejšie typy Wi‑Fi sú:",
                "tr_title": "RÔZNE ŠTANDARDY PRIPOJENIA WI‑FI",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 ponúka vyššiu rýchlosť aj vtedy, keď je na rovnakej sieti pripojených viacero používateľov.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Vďaka najnovšej inovácii Wi-Fi od spoločnosti Intel môžete dosiahnuť vyššiu než gigabitovú rýchlosť cez Wi-Fi aj v prípade, že ten istý signál využíva súčasne viac používateľov.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Pripojenie Wi‑Fi 6E, najväčší pokrok vo Wi‑Fi za 20 rokov, prináša nové vysokorýchlostné kanály pre vyšší výkon, väčšiu spoľahlivosť a stabilitu pripojenia."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 ponúka vyššiu rýchlosť aj vtedy, keď je na rovnakej sieti pripojených viacero používateľov.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi-Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "(Všetky výhody Wi-Fi 6E v spojení s mechanizmom stanovenia priorít Intel® Killer™, ktorá prideľuje kapacitu pripojenia webovým stránkam a aplikáciam, ktoré ju najviac potrebujú."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Vďaka menšiemu rušeniu na vysokorýchlostných kanáloch si budete môcť vychutnávať vyšší výkon a spoľahlivosť."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 ponúka vyššiu rýchlosť aj vtedy, keď je na rovnakej sieti pripojených viacero používateľov.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Vďaka menšiemu rušeniu Wi-Fi 6 a inteligentnému výkonu Intel® Killer™ Prioritization Engine bude váš zážitok online rýchlejší a s lepšou reakciou.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Vďaka menšiemu rušeniu na vysokorýchlostných kanáloch si budete môcť vychutnávať vyšší výkon a spoľahlivosť."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Rýchlosť Wi-Fi pre počítač vyššia ako 5 Gb/s so stabilnou veľmi nízkou latenciou. Zdieľajte súbory v sekundách namiesto minút."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) je najnovšia technológia umožňujúca „extrémnu“ spoľahlivosť, nízku latenciu a vysoké rýchlosti vďaka optimalizácií siete založenej na umelej inteligencii."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 ponúka vyššiu rýchlosť aj vtedy, keď je na rovnakej sieti pripojených viacero používateľov.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) s rýchlosťou viac ako 1 Gb/s, spoľahlivosťou a nízkou latenciou pri použití 6 GHz pásma."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Lepšia efektivita, kapacita a rýchlosť oproti Wi-Fi 5 v preťažených sieťach."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Zostaňte v lepšom spojení vďaka pripojeniu Wi‑Fi [XX], ktoré vám umožní využívať internet s vyššou rýchlosťou<sup>†</sup> a väčšou spoľahlivosťou.",
                "tr_title": "Získajte lepší dosah a rýchlosť",
                "tr_noteSection": "<sup>†</sup>Skutočná rýchlosť alebo dosah bezdrôtového pripojenia sa môžu líšiť.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "V ére online hrania a hier pre viacero hráčov je možnosť zostať v spojení dôležitejšia, než kedykoľvek predtým. Okrem rýchlosti potrebujete aj bezdrôtový signál, ktorý dokáže udržať potrebné rýchlosti na diaľku.",
                "tr_title": "[XX]",
                "tr_pretitle": "PRIPOJENIE JE KĽÚČOVÉ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Pripojte sa prostredníctvom rozhrania Intel® Wi‑Fi 6 (Gig+) k smerovačom Wi‑Fi 6 s podporou 160 MHz kanála na rýchlejšie pripojenie a väčšiu spoľahlivosť pre aktívnejších používateľov.",
                "tr_title": "Takmer 3× vyššia rýchlosť vďaka technológii Intel® <span class='no-warp'>Wi-Fi 6 </span> (Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>V porovnaní so štandardným AC Wi‑Fi. Vyberte funkcie dostupné iba s určitými SKU. Podrobnosti vám poskytne výrobca. Viac informácií o Wi‑Fi nájdete na adrese intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dvojpásmová Intel® Wi-Fi 6 základnej úrovne využíva vyšší výkon pri základných potrebách pripojenia, ako je e-mail a prehliadanie internetu v preťažených sieťach.<sup>9</sup>",
                "tr_title": "Potrebná Wi‑Fi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "V ére online hrania a hier pre viacero hráčov je možnosť zostať v spojení dôležitejšia, než kedykoľvek predtým. Okrem rýchlosti potrebujete aj bezdrôtový signál, ktorý dokáže udržať potrebné rýchlosti na diaľku.",
                "tr_title": "[XX]",
                "tr_pretitle": "PRIPOJENIE JE KĽÚČOVÉ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Tieto nové Wi‑Fi kanály znamenajú, že sa o pripojenie nemusíte deliť so staršími zariadeniami na sieti, vďaka čomu získate plynulé streamovanie, rýchle prehliadanie webu či bezproblémovú prácu a výučbu na diaľku.",
                "tr_title": "Bezkonkurenčné pripojenie",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Zostaňte v&nbsp;lepšom spojení vďaka pripojeniu [XX], ktoré vám umožní využívať internet s&nbsp;vyššou rýchlosťou<sup>†</sup> a&nbsp;väčšou spoľahlivosťou.",
                "tr_title": "Získajte lepší dosah a rýchlosť",
                "tr_noteSection": "<sup>†</sup>Skutočná rýchlosť alebo dosah bezdrôtového pripojenia sa môžu líšiť.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "V ére online hrania a hier pre viacero hráčov je možnosť zostať v spojení dôležitejšia, než kedykoľvek predtým. Okrem rýchlosti potrebujete aj bezdrôtový signál, ktorý dokáže udržať potrebné rýchlosti na diaľku.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "PRIPOJENIE JE KĽÚČOVÉ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Extrémne rýchle integrované pripojenie Wi‑Fi",
                "tr_subtitle": "Odpojte sa a zostaňte v&nbsp;kontakte s&nbsp;vašim online svetom."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Vďaka vyhradeným vysokorýchlostným kanálom s vyššou stabilitou a mechanizmom stanovenia priorít Intel® Killer™, ktorý stavia hranie hier na prvé miesto, dostáva online hranie zásadné vylepšenie.",
                "tr_title": "Nechajte konkurenciu za sebou",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Vďaka vyhradeným vysokorýchlostným kanálom s vyššou stabilitou a mechanizmom stanovenia priorít Intel® Killer™, ktorý stavia hranie hier na prvé miesto, dostáva online hranie zásadné vylepšenie.",
                "tr_title": "[XX]",
                "tr_pretitle": "NECHAJTE KONKURENCIU ZA SEBOU",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Vďaka vyhradeným vysokorýchlostným kanálom s menším rušením sa hranie hier dostáva na prvé miesto, online gaming sa zásadné vylepšilo.",
                "tr_title": "[XX]",
                "tr_pretitle": "NECHAJTE KONKURENCIU ZA SEBOU",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi‑Fi automaticky urýchľuje prevádzku siete pre aplikácie, ktoré ju najviac potrebujú, aby mali vaše streamované videá ostré detaily a boli menej rozmazané.",
                "tr_title": "Rýchlejší a inteligentnejší spôsob pripojenia.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "V ére online hrania a hier pre viacero hráčov je možnosť zostať v spojení dôležitejšia, než kedykoľvek predtým. Okrem rýchlosti potrebujete aj bezdrôtový signál, ktorý dokáže udržať potrebné rýchlosti na diaľku.",
                "tr_title": "[XX]",
                "tr_pretitle": "PRIPOJENIE JE KĽÚČOVÉ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Zostaňte v lepšom spojení vďaka pripojeniu Wi-Fi&nbsp;5, ktoré vám umožní využívať internet s&nbsp;vyššou rýchlosťou<sup>†</sup> a&nbsp;väčšou spoľahlivosťou.",
                "tr_title": "Získajte lepší dosah a rýchlosť",
                "tr_noteSection": "<sup>†</sup>Skutočná rýchlosť alebo dosah bezdrôtového pripojenia sa môžu líšiť.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "V ére online hrania a hier pre viacero hráčov je možnosť zostať v spojení dôležitejšia, než kedykoľvek predtým. Okrem rýchlosti potrebujete aj bezdrôtový signál, ktorý dokáže udržať potrebné rýchlosti na diaľku.",
                "tr_title": "[XX]",
                "tr_pretitle": "NECHAJTE KONKURENCIU ZA SEBOU",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Funkcie Wi-Fi 7 vychádzajú z predchádzajúcich Wi-Fi generácií a vylepšujú ich. Nejde iba o vyššiu rýchlosť, ale aj o výrazne rýchlejšiu odozvu a spoľahlivosť pre pohlcujúce zážitky používateľov a sofistikované technológie budúcnosti.",
                "tr_title": "Sieťové pripojenie, ktoré sa vyrovná káblu",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Funkcie Wi-Fi 7 vychádzajú z predchádzajúcich Wi-Fi generácií a vylepšujú ich. Nejde iba o vyššiu rýchlosť, ale aj o výrazne rýchlejšiu odozvu a spoľahlivosť pre pohlcujúce zážitky používateľov a sofistikované technológie budúcnosti.",
                "tr_title": "[XX]",
                "tr_pretitle": "NOVÁ DIMENZIA POUŽÍVATEĽSKÝCH ZÁŽITKOV",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Zvýšená rýchlosť, latencia a spoľahlivosť vďaka optimalizácii siete na báze umelej inteligencie",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Zvýšená rýchlosť, latencia a spoľahlivosť vďaka optimalizácii siete na báze umelej inteligencie.",
                "tr_title": "[XX]",
                "tr_pretitle": "ODOZVA AKO CEZ KÁBEL",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batéria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Ako sa meria?",
                    "tr_badgeSubTitle": "Väčšina výrobcov vyjadruje výdrž batérie v hodinách. Hoci tento výpočet nie je na 100&nbsp;% presný, mal by vám pomôcť porovnať výdrž batérie jednotlivých počítačov."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batéria",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "HRANIE AJ NA CESTÁCH",
                "tr_subtitle": "Vďaka výkonu súčasných prenosných počítačov nie je dôvod, aby ste boli pripútaný k pracovnému stolu. Teraz sa so svojimi hrami môžete vydať kamkoľvek.",
                "tr_title": "Výdrž batérie: [Dynamic.battery.values.hours] hod."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batéria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Odpojte zariadenie od siete a&nbsp;majte svoje aplikácie, hry a&nbsp;hudbu naďalej po ruke celé hodiny.",
                "tr_title": "[XX]&nbsp;hod. streamovania na jedno nabitie<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Na základe výdrže batérie uvádzanej výrobcom počítača.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] hod.",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batéria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Odpojte zariadenie od siete a&nbsp;majte svoje aplikácie, hry a&nbsp;hudbu naďalej po ruke celé hodiny.",
                "tr_title": "[Dynamic.battery.values.hours]&nbsp;hod. nabitia na cestách<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Na základe výdrže batérie uvádzanej výrobcom počítača.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] hod.",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batéria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Odpojte zariadenie od siete a&nbsp;majte svoje aplikácie, hry a&nbsp;hudbu naďalej po ruke celé hodiny.",
                "tr_title": "[Dynamic.battery.values.hours] hod. výkonu na celý deň na jedno nabitie<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Na základe výdrže batérie uvádzanej výrobcom počítača.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] hod.",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batéria",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Tento počítač je optimalizovaný pre chod na batériu, a zároveň sa rýchlo nabije, aby vám poskytol aspoň 4 hodiny výdrže len po krátkom 30-minútovom dobití<sup>4</sup>.",
                "tr_title": "Môžete zostať dlhší čas bez zdroja napájania vďaka životnosti batérie až [Dynamic.battery.values.hours] hod.<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Na základe výdrže batérie uvádzanej výrobcom počítača.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] hod.",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Doma",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Prečo práve tento počítač",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Zobraziť všetky špecifikácie",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Vzdialené",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Mobilné",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Právne informácie",
        "tr_paragraph_array": [
          "Softvér a zaťaženia použité vo výkonnostných testoch mohli byť optimalizované len na výkon mikroprocesorov Intel®. Výkonnostný test, ako napríklad SYSmark* a&nbsp;MobileMark*, sa meria pomocou konkrétnych počítačových systémov, komponentov a&nbsp;funkcií. Akákoľvek zmena ktoréhokoľvek z týchto faktorov môže spôsobiť odlišné výsledky. Ak potrebujete pomoc s&nbsp;úplným vyhodnotením zvažovaného nákupu vrátane výkonu produktu v&nbsp;spojení s&nbsp;ďalšími produktmi, mali by ste sa oboznámiť s&nbsp;ďalšími informáciami a výkonnostnými testami. Podrobnejšie informácie týkajúce sa výkonu a výsledkov porovnávacích testov nájdete na stránke www.intel.com/benchmarks.",
          "¹Produkty s&nbsp;technológiou Intel® Wi-Fi 6 (Gig+) podporujú voliteľné 160&nbsp;MHz kanály, čím umožňujú maximálne teoretické rýchlosti (2402&nbsp;Mb/s) pre bežné počítačové Wi-Fi produkty 2&nbsp;x&nbsp;2 802.11ax. Prémiové produkty Intel® Wi-Fi 6 (GIG+) umožňujú 2&nbsp;–&nbsp;4x vyššie maximálne teoretické rýchlosti v&nbsp;porovnaní so štandardnými počítačovými Wi-Fi produktmi 2&nbsp;x&nbsp;2 (1&nbsp;201&nbsp;Mb/s) alebo 1&nbsp;x&nbsp;1 (600&nbsp;Mb/s) 802.11ax, ktoré podporujú len povinné požiadavky na 80&nbsp;MHz kanály.",
          "²Odporúčané iba pre informačné účely. Pri rozhodovaní o zakúpení sa zoznámte aj s ďalšími zdrojmi informácií.",
          "³Funkcie a výhody technológií spoločnosti Intel závisia od konfigurácie systému a môžu vyžadovať určitý kompatibilný hardvér, softvér alebo aktiváciu služby. Výkon sa líši v závislosti od konfigurácie systému. Žiadny počítačový systém nie je možné dokonale zabezpečiť. Informujte sa u výrobcu vášho systému alebo predajcu, alebo viac sa dozviete na intel.com.",
          "⁴Pre systémy s displejmi FHD, keď sa používajú na bezdrôtové prehliadanie internetu. Pri odpojení od napájania z predvolenej úrovne vypnutia nastavenej výrobcom.",
          "⁵V porovnaní s inými PC externými zariadeniami ako eSATA, USB, a IEEE 1394* FireWire. Výkon bude závisieť od použitého hardvéru a softvéru. Je nutné použiť zariadenie s&nbsp;aktivovanou technológiou Thunderbolt™.",
          "⁶Takmer 3x rýchlejšie: 802.11ax 2 x 2 160 MHz umožňuje maximálne teoretické prenosy údajov 2 402 Mb/s. ~3x rýchlejšie ako štandardné Wi‑Fi 802.11ac 2 x 2 80 MHz (867 Mb/s) v súlade so špecifikáciami bezdrôtového štandardu IEEE 802.11* a vyžaduje použitie podobne konfigurovaných smerovačov bezdrôtovej siete 802.11ax.",
          "⁷75 % skrátenie latencie: Vychádza z údajov spoločnosti Intel zo simulácie v sieti 802.11ax (79 %) s prístupom OFDMA a bez prístupu OFDMA s využitím 9 klientov. Priemerná latencia bez OFDM je 36&nbsp;ms, pričom s prístupom OFDMA sa priemerná latencia  skracuje na 7,6&nbsp;ms. Zlepšenie latencie si vyžaduje, aby smerovač 802.11ax (Wi-Fi 6) a&nbsp;všetci klienti podporovali prístup OFDMA.",
          "⁸Na základe porovnania teoretickej maximálnej rýchlosti prenosu dát v špecifikácii 802.11 je možné v sieti 2x2 160 MHz Wi‑Fi 6/6E (802.11ax) dosiahnuť rýchlosť 2 402 Mb/s a v sieti 2x2 80 MHz 5 (802.11ac) je možné dosiahnuť rýchlosť 867 Mb/s, čo znamená 2,8-násobne vyššiu rýchlosť.",
          "⁹Špičková technológia Wi‑Fi 6: Produkty s technológiou Intel® Wi‑Fi 6 (Gig+) podporujú voliteľné 160 MHz kanály, čím umožňujú maximálne teoretické rýchlosti (2 402 Mb/s) pre bežné počítačové Wi‑Fi produkty 2x2 802.11ax. Prémiové produkty Intel® Wi‑Fi 6 (GIG+) umožňujú 2 – 4x vyššie maximálne teoretické rýchlosti v porovnaní so štandardnými počítačovými Wi‑Fi produktmi 2 x 2 (1 201 Mb/s) alebo 1 x 1 (600 Mb/s) 802.11ax, ktoré podporujú len povinné požiadavky na 80 MHz kanály.",
          "Len vybrané SKU, funkcie sa líšia podľa dizajnu OEM. Viac informácií vám poskytne výrobca originálnych dielov alebo predajca. Viac informácií o Wi‑Fi nájdete na stránke intel.com/wifi6disclaimers.",
          "¹⁰Technológia Thunderbolt™&nbsp;4 poskytuje najrýchlejšie, najjednoduchšie a najspoľahlivejšie rozloženie káblov pre každý dok, displej alebo dátové zariadenie s rýchlosťou 40 Gb/s v porovnaní s inými technológiami pripojenia počítača I/O vrátane eSATA*, USB* a IEEE 1394* FireWire.",
          "¹¹Vyžaduje smerovač založený na štandarde 802.11ax, ktorý podporuje prístup OFDMA a&nbsp;viacerých klientov v&nbsp;sieti s&nbsp;podporou AX. Vyšší výkon v&nbsp;hustých prostrediach vďaka funkciám OFDMA podporovaným klientmi a&nbsp;prístupovými bodmi 802.11ax. Rýchlosť 2&nbsp;Gb/s vychádza z&nbsp;predpokladov približne 70&nbsp;% maximálnej teoretickej rýchlosti prenosu dát podľa špecifikácie IEEE 802.11* pre 802.11ax 160&nbsp;MHz 2&nbsp;402&nbsp;Mb/s.",
          "¹²Merané spustením dokumentov s&nbsp;pracovnou aktivitou v&nbsp;pozadí pri porovnávaní procesora Intel® Core™ i7-8565U 8. generácie (512&nbsp;GB TLC SSD) s&nbsp;procesorom Intel® Core™ i7-8565U 8. generácie (32&nbsp;GB + 512&nbsp;GB pamäť Intel® Optane™ H10 s&nbsp;diskom SSD).",
          "¹³V procesoroch Intel® Core™ 12. generácie je zabudovaná technológia Intel® Thread Director, ktorá pomáha operačným systémom inteligentne presunúť záťaž do správneho jadra. Bez nutnosti zásahu používateľa. Podrobnosti nájdete na stránke intel.com.",
          "¹⁴Nie je k dispozícii v niektorých procesoroch Intel® Core™ 12. generácie. Výkonná hybridná architektúra kombinuje dve nové mikro-architektúry jadier, Performance-cores (P-cores) a Efficient-cores (E-cores) v jednom čipe. Vybrané procesory Intel® Core™ 12. generácie (niektoré procesory Intel® Core™ i5 12. generácie a nižšie) nepodporujú výkonnú hybridnú architektúru, ale iba architektúru P-cores.",
          "¹⁵Merané v pracovnom zaťažení pri spúšťaní hry, porovnanie procesora Intel® Core™ i7-8750H 8. generácie (pamäťový modul 32&nbsp;GB Intel® Optane™ + Intel® SSD 256&nbsp;GB PCIe* + 1&nbsp;TB HDD) vs. procesor Intel® Core™ i7-8750H 8. generácie (Intel® SSD 256&nbsp;GB PCIe* + 1&nbsp;TB HDD). Pracovné zaťaženie pri spúšťaní hry – toto pracovné zaťaženie vyvinuté spoločnosťou Intel meria čas, ktorý trvá spustenie hry Total War*: WARHAMMER* II zostava: 5577.0 a prechod do hlavnej ponuky s deaktivovaným úvodným videom. Konfigurácie zrýchlenia dátového disku s pamäťou Intel® Optane™: procesor Intel® Core™ i7-8750H, PL1=45W TDP, 6C12T, turbo až 4&nbsp;GHz v predprodukčnom OEM systéme, grafická karta: NVIDIA* GeForce* GTX 1070, pamäť: 2 x 4&nbsp;GB DDR4, úložisko: Intel® SSD série 760p 256&nbsp;GB (PCIe*) + 1&nbsp;TB HDD + 32&nbsp;GB pamäť Intel® Optane™, operačný systém: Windows* 10 RS3 zostava 1709, MCU 0x84; procesor Intel® Core™ i7-8750H, PL1=45W TDP, 6C12T, turbo až 4&nbsp;GHz v predprodukčnom OEM systéme, grafická karta: NVIDIA* GeForce* GTX 1070, pamäť: 2 x 4&nbsp;GB DDR4, úložisko: Intel® SSD série 760p 256&nbsp;GB (PCIe*) + 1&nbsp;TB HDD, operačný systém: Windows* 10 RS3 zostava 1709, MCU 0x84.",
          "¹⁶Merané v pracovnom zaťažení pri načítaní úrovne hry, porovnanie procesora Intel® Core™ i7-8750H 8. generácie (32&nbsp;GB pamäťový modul Intel® Optane™) vs. procesor Intel® Core™ i7-8750H 8. generácie (iba HDD). Pracovné zaťaženie pri načítaní úrovne hry – toto pracovné zaťaženie vyvinuté spoločnosťou Intel meria čas, ktorý uplynie od prechodu z hlavnej ponuky po načítanie úrovne v hre Total War*: WARHAMMER* II zostava: 5577.0. Konfigurácie zrýchlenia dátového disku s pamäťou Intel® Optane™: procesor Intel® Core™ i7-8750H, PL1=45W TDP, 6C12T, turbo až 4&nbsp;GHz v predprodukčnom OEM systéme, grafická karta: NVIDIA* GeForce* GTX 1070, pamäť: 2 x 4&nbsp;GB DDR4, úložisko: Intel® SSD série 760p 256&nbsp;GB (PCIe*) + 1&nbsp;TB HDD + 32&nbsp;GB pamäť Intel® Optane™, operačný systém: Windows* 10 RS3 zostava 1709, MCU 0x84; procesor Intel® Core™ i7-8750H, PL1=45W TDP, 6C12T, turbo až 4&nbsp;GHz v predprodukčnom OEM systéme, grafická karta: NVIDIA* GeForce* GTX 1070, pamäť: 2 x 4&nbsp;GB DDR4, úložisko: Intel® SSD série 760p 256&nbsp;GB (PCIe*) + 1&nbsp;TB HDD, operačný systém: Windows* 10 RS3 zostava 1709, MCU 0x84.",
          "¹⁷Na Performance-cores. Výkon sa líši podľa použitia, konfigurácie a iných faktorov. Viac informácií nájdete na webe www.intel.com/PerformanceIndex.",
          "¹⁸Zmena taktovacej frekvencie alebo napätia môže poškodiť alebo skrátiť životnosť procesora a iných systémových komponentov. Taktiež môže znížiť stabilitu a výkon systému. Záruky na produkty sa nemusia vzťahovať na prípady, keď je procesor prevádzkovaný nad rámec svojich špecifikácií. Ďalšie informácie získate od výrobcov systému a komponentov.",
          "¹⁹Podľa bezkonkurenčnej kombinácie možností zabezpečenia platformy Intel vPro® v rámci (a nad rámec) operačného systému, ochrany aplikácií a dát a pokročilej ochrany pred hrozbami a pre podniky akejkoľvek veľkosti, ako aj prístupu spoločnosti Intel k návrhu, výrobe a podpore produktov s ohľadom na bezpečnosť. Všetky pracovné počítače postavené na platforme Intel vPro® boli overené podľa prísnych špecifikácií vrátane jedinečných hardvérových bezpečnostných funkcií. Podrobnosti nájdete na www.intel.com/PerformanceIndex (platformy). Žiadny výrobok ani komponent nie je možné dokonale zabezpečiť.",
          "²⁰ Služba Intel® Connectivity Performance Suite je k dispozícii len na systémoch s Windows*. Testovanie Intel Over The Air (OTA) Wi-Fi v preťaženej sieti v porovnaní so softvérovou aplikáciou ICPS bez prioritizácie bezdrôtovej prevádzky a inteligentného prepínania prístupových bodov. Podrobnosti nájdete na www.intel.com/PerformanceIndex (pripojiteľnosť). Výsledky sa môžu líšiť.",
          "²¹V porovnaní s predchádzajúcou generáciou procesorov.",
          "²²Grafika Intel® Arc™ je prvou na trhu, ktorá podporuje kódovanie AV1 (od 1. kvartála 2022). Podrobnosti na intel.com/performanceindex.",
          "²³Precízne vlastnosti modelov Intel® Evo™ sme testovali tak, aby splnili kľúčové požiadavky mobilných používateľov. Testované od februára 2022.",
          "²⁴Technológia Intel® Unison™ je v súčasnosti dostupná na príslušných modeloch Intel® Evo™, ktoré sú súčasťou počítačov so systémom Windows*, a spáruje sa len s telefónmi so systémom Android alebo iOS. Všetky zariadenia musia mať podporovanú verziu operačného systému.",
          "²⁵Maximálna rýchlosť pamäte závisí od konfigurácie 1 DIMM na kanál (1DPC). Dodatočné zaťaženie DIMM na ktoromkoľvek kanáli môže ovplyvniť maximálnu rýchlosť pamäte. Až do DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 a DDR5-5200 1Rx8, 1Rx16, 2Rx8 na vybraných SKU. Maximálnu kapacitu pamäte získate prostredníctvom konfigurácií 2DPC.",
          "²⁶Oproti štandardnému Wi-Fi pripojeniu. Podrobnosti nájdete na stránke intel.com/performance-wireless. Výsledky sa môžu líšiť.",
          "²⁷Výkonná hybridná architektúra kombinuje dve nové mikro-architektúry jadier, Performance-cores (P-cores) a Efficient-cores (E-cores), na jednej procesorovej doske, ktorá bola prvýkrát predstavená v procesoroch Intel® Core™ 12. generácie. Vybrané procesory Intel® Core™ minimálne 12. generácie nemajú hybridnú architektúru, iba P-cores, a majú rovnakú veľkosť vyrovnávacej pamäte ako predchádzajúca generácia. Podrobnosti o SKU nájdete na ark.intel.com.",
          "²⁸Intel® Thread Director, je zabudovaný v hardvéri len vo výkonných konfiguráciách hybridnej architektúry s procesormi Intel® Core™ 12. generácie alebo novšími. Potrebujete povolenie operačného systému. Dostupné funkcie a funkčnosť sa líšia podľa operačného systému.",
          "²⁹Intel® Thread Director, je zabudovaný v hardvéri len vo výkonných konfiguráciách hybridnej architektúry s procesormi Intel® Core™ 12. a 13. generácie. Potrebujete povolenie operačného systému. Dostupné funkcie a funkčnosť sa líšia podľa operačného systému.",
          "³⁰Merané podľa referenčného validačného výkonu platformy procesoru pre prenosný počítač Intel®&nbsp;Core™ Ultra (Series&nbsp;2 – krycí názov: Lunar Lake) verzus procesor pre prenosný počítač Intel®&nbsp;Core™ Ultra (Series&nbsp;1 – krycí názov: Meteor Lake) na nástroji 3DMark Time Spy. Podrobnosti nájdete na intel.com/performanceindex. Výsledky sa môžu líšiť.",
          "³¹Rozmazanie pozadia a sledovanie tváre fungujú iba na systémoch Windows*.",
          "³²Produkty Wi-Fi 7 môžu pristupovať ku kanálom 320 MHz v 6 GHz a novým kombináciám 160 MHz kanálov v 5 GHz s novými možnosťami Multi-Resource Unit Puncturing.",
          "³³Na základe špecifikácií bezdrôtového štandardu IEEE a maximálnych teoretických dátových rýchlostí pre zariadenia s možnosťou 2 dátových tokov súčasne.",
          "³⁴Technické simulácie spoločnosti Intel preťažených sieťových prostredí naznačujú, že vďaka novým funkciám Wi-Fi 7 Multi-Link Operation je možné výrazne znížiť latenciu.",
          "³⁵Minimálne nabitie od predvolenej úrovne vypnutia OEM. Výsledky jednotlivých zariadení sa môžu líšiť. Podrobnosti nájdete na stránke intel.com/performance-evo.",
          "³⁶Wi-Fi pripojenie 6 GHz nie je k dispozícii vo všetkých krajinách/oblastiach.",
          "³⁷Iba pre Windows*",
          "³⁸Nabitie od predvolenej úrovne vypnutia OEM. Podrobnejšie informácie týkajúce sa výkonu a výsledkov porovnávacích testov nájdete na stránke intel.com/Evo.",
          "³⁹Vzťahuje sa na procesory Intel®&nbsp;Core™ Ultra (Series&nbsp;2) pre prenosný počítač, založené na jedinečných hardvérových bezpečnostných funkciách, bezkonkurenčnej kombinácii zabezpečenia operačného systému vyššej a nižšej úrovne a silnom výkone aj pri slabšom napájaní v porovnaní s predchádzajúcou generáciou (od septembra 2024). Podrobnosti nájdete na intel.com/performanceindex. Výsledky sa môžu líšiť.",
          "⁴⁰Softvérové aplikácie sú zobrazené iba na ilustračné účely. Funkcie umelej inteligencie môžu vyžadovať dodatočné zakúpenie softvéru, predplatné alebo povolenie od poskytovateľa softvéru alebo platformy alebo môžu mať špecifické požiadavky na konfiguráciu alebo kompatibilitu. Podrobnosti nájdete na www.intel.com/PerformanceIndex. Výsledky sa môžu líšiť. © Intel Corporation.",
          "⁴¹Zobrazené obrázky môžu byť zmenené alebo simulované. Funkcie umelej inteligencie môžu vyžadovať dodatočné zakúpenie softvéru, predplatné alebo povolenie od poskytovateľa softvéru alebo platformy alebo môžu mať špecifické požiadavky na konfiguráciu alebo kompatibilitu. Podrobnosti nájdete na www.intel.com/AIPC.",
          "⁴²Postupné zavádzanie náhľadu v rámci najnovšej aktualizácie systému Windows* 11 na vybraných globálnych trhoch. Termín dostupnosti sa líši na základe zariadenia a trhu. Viac informácií tu: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³Riešenie Intel® Unison™ je momentálne dostupné pre vybrané modely. Podrobnosti nájdete na www.intel.com/PerformanceIndex.",
          "⁴⁴Zvýšená snímková frekvencia pomocou Intel® X<sup>e</sup>SS verzus natívna snímková frekvencia meraná počas spustenej hry Dying Light 2 Stay Human na Intel® Core™ Ultra 7 procesore 165H. Podrobnosti nájdete na www.intel.com/PerformanceIndex. Výsledky sa môžu líšiť.",
          "⁴⁵Merané podľa referenčnej validačnej platformy pre nižšiu spotrebu energie systému na čipe (System on Chip, SoC) pri procesore Intel®&nbsp;Core™ Ultra pre prenosný počítač (Series&nbsp;2 – krycí názov: Lunar Lake) verzus referenčná validačná platforma procesoru Intel®&nbsp;Core™ Ultra pre prenosný počítač (Series&nbsp;1 – krycí názov: Meteor Lake) pri prehrávaní videa 4K AV1 s frekvenciou 30 snímok za sekundu na YouTube*. Podrobnosti nájdete na intel.com/performanceindex. Výsledky sa môžu líšiť.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Spoločnosť Intel nezodpovedá za nesprávne uvedené ceny.",
          "*Ostatné názvy a&nbsp;značky môžu byť vlastníctvom iných subjektov.",
          "COPYRIGHT 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES A SÚVISIACE DIZAJNOVÉ A KONŠTRUKČNÉ ZNAČKY SÚ OCHRANNÝMI ZNÁMKAMI SPOLOČNOSTI INFLEXION STUDIOS INC. VŠETKY PRÁVA VYHRADENÉ.",
          "Technológie Intel môžu vyžadovať zapnutie hardvéru či softvéru alebo aktiváciu niektorých služieb.",
          "Všetky výsledky a údaje z NERO* sú poskytované len na informačné účely. Intel Corporation nenesie zodpovednosť za presnosť aplikácie NERO*, jej výkonnosť a skóre alebo potenciálne zmeny z iných verzií aplikácie na stiahnutie, ktoré sú dostupné na internetovej stránke www.nero.com alebo internetových stránkach tretích strán.  Výkon počítačov s technológiou NERO* sa meria pomocou počítačových systémov, komponentov, softvéru, operácií a funkcií. Akákoľvek zmena ktoréhokoľvek z týchto faktorov môže spôsobiť odlišné výsledky. Ak potrebujete pomoc s&nbsp;úplným vyhodnotením zvažovaného nákupu vrátane výkonu produktu v&nbsp;spojení s&nbsp;ďalšími produktmi, mali by ste sa oboznámiť s&nbsp;ďalšími informáciami a výkonnostnými testami.",
          "Všetky verzie Intel vPro® vyžadujú vhodný procesor Intel® Core™, podporovaný operačný systém, systém Intel® LAN a/alebo WLAN silicon, firmvérové vylepšenie a ďalší hardvér a softvér potrebný na zabezpečenie používania v oblasti správy, bezpečnostných funkcií, výkonu systému a stability, ktoré definujú túto platformu.",
          "Výkon sa líši podľa použitia, konfigurácie a iných faktorov. Viac informácií nájdete na webe www.intel.com/PerformanceIndex.",
          "Intel neovláda ani nekontroluje údaje tretích strán.  Presnosť údajov je potrebné skontrolovať v iných zdrojoch.",
          "© Intel Corporation. Intel, logo Intel a iné značky Intel sú ochrannými známkami spoločnosti Intel Corporation alebo jej dcérskych spoločností. *Ostatné názvy a&nbsp;značky môžu byť vlastníctvom iných subjektov.",
          "Žiadny výrobok ani komponent nie je možné dokonale zabezpečiť.",
          "Vaše náklady a výsledky sa môžu líšiť.",
          "Intel, logo Intel, Intel Core, Intel Optane, Intel Iris, Thunderbolt a logo Thunderbolt sú ochrannými známkami spoločnosti Intel Corporation alebo jej dcérskych spoločností. Ďalšie názvy a značky môžu byť vlastníctvom iných spoločností.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Doma",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Skenovať a porovnať",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Súčasné",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "Zažite nové veci s umelou inteligenciou na procesoroch Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "Zažite nové veci s umelou inteligenciou na procesoroch Intel® Core™ Ultra",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "Zažite nové veci s umelou inteligenciou na procesoroch Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "Stable Diffusion*: text na obrázok",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe*: úprava fotografií",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "Intel® X<sup>e</sup>SS: upscaling obrázkov",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee*: Detektor pre deepfake",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "Efekty pre spoluprácu (Teams)",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "IQIYI: ovládanie gestami",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "Zosynchronizujte svoj počítač s telefónom a zobrazte oznámenia, hovory a správy",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "Na farby bohatá a živá zabudovaná grafika",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "Microsoft*: riaďte svoj život podľa seba",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "Microsoft*: všetko o vás. Všetko na dosah, len potiahnite prstom",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "Zrýchlite procesor a zvýšte frekvencie optimalizáciou systému",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "Zvýšte výkon svojho počítača alebo mobilu bez obmedzenia úložiska",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "Zvýšte výkon svojho počítača alebo mobilu bez obmedzenia úložiska",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "Samsung OLED: pohľad pre boľavé oči",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "Zvýšená rýchlosť, latencia a spoľahlivosť vďaka optimalizácii siete na báze umelej inteligencie",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "Sieťové pripojenie, ktoré sa vyrovná káblu",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "Sieťové pripojenie, ktoré sa vyrovná káblu",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "Takmer trojnásobne vyššia rýchlosť s&nbsp;rozhraním Intel® Wi-Fi 6 (Gig+)",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "Bezkonkurenčné pripojenie",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "Skutočne univerzálne káblové pripojenie",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "Hrajte ako profíci: pozrite sa, ako Intel poháňa hranie hier a hráčov",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "Hrajte ako profíci: s Intelom sa stanete šampiónmi",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "VITAJTE",
    "tr_welcomePagetrSubtitle": "Ovládajte tento počítač na diaľku svojím smartfónom",
    "tr_welcomePagembSubtitle": "Vitajte, preskúmajte špecifikácie tohto počítača vo svojom smartfóne",
    "tr_welcomePageBtnText": "Začať",
    "tr_welcomePageText": "Preskúmajte a&nbsp;porovnajte detaily tohto počítača vo svojom smartfóne",
    "tr_scrollText": "Prezerajte si zariadenie pred sebou",
    "tr_toolTipTextc1": "Pomocou týchto tlačidiel môžete prechádzať na rôzne stránky v&nbsp;zariadení pred sebou.",
    "tr_toolTipTextc2": "Po ťuknutí na karty nižšie prejdete na stranu s podrobnosťami jednotlivých špecifikácií zariadenia.",
    "tr_toolTipTextc3": "Pomocou týchto tlačidiel sa môžete pohybovať na obrazovke pred vami smerom nahor a nadol.",
    "tr_toolTipTextc4": "Vypnite diaľkový režim a&nbsp;zobrazte špecifikácie zariadenia vo svojom smartfóne.",
    "tr_toolTipTextmb1": "Ťuknutím na karty môžete zobraziť stránky s&nbsp;podrobnosťami.",
    "tr_toolTipTextmb2": "Ťuknutím zobrazíte súhrn všetkých špecifikácií zariadenia.",
    "tr_toolTipTextmb3": "Ťuknutím na tlačidlo naskenujete QR kód a&nbsp;môžete porovnať až 4 rôzne&nbsp;zariadenia.",
    "tr_nextButton": "Nasledujúce",
    "tr_prevButton": "Predchádzajúce",
    "tr_gotItButton": "CHÁPEM",
    "tr_skipButton": "Preskočiť",
    "tr_placeholderText": "*&nbsp;Zástupný znak",
    "tr_processorHeading": "Procesor",
    "tr_processorSubheading": "Procesor Intel® Core™ i7 10.&nbsp;generácie",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "Pamäť Intel® Optane™ [XX]&nbsp;GB + [YY]&nbsp;GB SSD",
    "tr_coachmarkProcessor": "Procesor XX",
    "tr_coachmarkGraphics": "Grafika [XX]",
    "tr_coachmarkMemory": "Hodnota špecifikácie",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "999,95&nbsp;USD",
    "tr_compare": "Porovnať",
    "tr_viewallspecs": "ZOBRAZIŤ VŠETKY PARAMETRE",
    "tr_operatingSystem": "OS",
    "tr_AmdProcessorNonIntel": "Procesor AMD*",
    "tr_QualcommProcessorNonIntel": "Procesor Qualcomm*",
    "tr_GBText": "GB",
    "tr_remoteText": "Špecifikácie zobrazené na obrazovke prenosného počítača",
    "tr_scanErrorText": "Súčasne môžete naskenovať max. 3 zariadenia. Odstráňte jedno alebo viacero zariadení.",
    "tr_scanErrorText2": "Toto zariadenie ste už naskenovali. Naskenujte iné.",
    "tr_scanMsg": "Naskenovaním pridáte zariadenie",
    "tr_backToScan": "Späť na porovnanie",
    "tr_scanToCompare": "Naskenovať a porovnať",
    "tr_compareDevice": "Porovnajte zariadenia",
    "tr_processorTitle": "PROCESOR",
    "tr_graphicTitle": "GRAFIKA",
    "tr_storageTitle": "ÚLOŽNÝ PRIESTOR",
    "tr_displayTitle": "DISPLEJ",
    "tr_batteryTitle": "BATÉRIA",
    "tr_memoryTitle": "PAMÄŤ",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "PRIPOJENIE",
    "tr_priceTitle": "CENA",
    "tr_operatingSystemTitle": "OPERAČNÝ SYSTÉM",
    "tr_batteryHoursText": "[XX] hodín",
    "tr_hrsText": "hod.",
    "tr_touchscreeenText": "[screenDiagonal]-palcový Dotyková obrazovka",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, dotykový displej",
    "tr_FHDTouchScreenText": "FHD, dotykový displej",
    "tr_4KTouchscreenText": "4K, dotyková obrazovka",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Uložiť",
    "tr_device_comparison": "Dokument z&nbsp;porovnania zariadení [dd]. [mm]. [yyyy]",
    "tr_Save_As_JPEG": "Uložiť ako JPEG",
    "tr_Save_As_PDF": "Uložiť ako PDF",
    "tr_Downloading_PDF": "Sťahovanie PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Otvoriť",
    "tr_logo": "Logo",
    "tr_laptop_name": "Názov prenosného počítača",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Pamäť Intel® Optane™",
    "tr_inchUnit": "palcov",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "PAMÄŤ INTEL® OPTANE™",
    "tr_Thunderbolt3": "Technológia Thunderbolt™ 3",
    "tr_Thunderbolt4": "Technológia Thunderbolt™&nbsp;4",
    "tr_processorGraphicsCaps": "PROCESOROVÁ GRAFIKA",
    "tr_processorGraphicsSmall": "Procesorová grafika",
    "tr_graphicsCardCaps": "SAMOSTATNÁ GRAFIKA",
    "tr_graphicsCardSmall": "Samostatná grafika",
    "tr_processorTitleCamel": "Procesor",
    "tr_graphicTitleCamel": "Grafika",
    "tr_storageTitleCamel": "Úložný priestor",
    "tr_displayTitleCamel": "Displej",
    "tr_batteryTitleCamel": "Batéria",
    "tr_memoryTitleCamel": "Pamäť",
    "tr_connectivityTitleCamel": "Pripojenie",
    "tr_priceTitleCamel": "Cena",
    "tr_operatingSystemTitleCamel": "Operačný systém",
    "tr_viewallspecsCamel": "Zobraziť všetky špecifikácie",
    "tr_displayText": "[screenDiagonal]-palcový",
    "tr_displayTextResolution": "[screenDiagonal]-palcový [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "[screenDiagonal]-palcový [screenResolutionType] Dotykový displej",
    "tr_OS": "OS",
    "tr_Chrome_OS": "OS Chrome*",
    "tr_PCSpecs_text": "Technické parametre počítača",
    "tr_explorePC_text": "Preskúmajte celý počítač",
    "tr_mtlPC_text": "Pozrite sa, čo v skutočnosti znamená „navrhnuté tak, aby zvládli všetko“",
    "tr_optaneMemory": "Pamäť Intel® Optane™",
    "tr_displayText_Display": "[screenDiagonal] -PALCOVÝ DISPLEJ",
    "tr_displayTextResolution_Display": "[screenDiagonal] -PALCOVÝ [screenResolutionType] DISPLEJ",
    "tr_displayTextResolutionTouchscreen_Display": "[screenDiagonal] -PALCOVÝ [screenResolutionType] DOTYKOVÝ DISPLEJ",
    "tr_displayTextTouchscreeenText": "[screenDiagonal]-PALCOVÝ DOTYKOVÝ DISPLEJ",
    "tr_learnMoreLabel": "Viac informácií",
    "tr_batteryHoursTextUpperCase": "[XX] HOD.",
    "tr_processorGraphicsIrisXe": "PROCESOR INTEL® CORE™ [cpu_fam] 11. GENERÁCIE A GRAFIKA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax": "PROCESOR INTEL® CORE™ [cpu_fam] 11.&nbsp;GENERÁCIE  A GRAFIKA INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;MAX",
    "tr_Thunderbolt3UpperCase": "TECHNOLÓGIA THUNDERBOLT™ 3",
    "tr_Thunderbolt4UpperCase": "TECHNOLÓGIA THUNDERBOLT™ 4",
    "tr_processorWithArcGraphics": "PROCESOR INTEL® CORE™ [cpu_fam] 12. GENERÁCIE A GRAFIKA INTEL® ARC™",
    "tr_processorGraphicsIrisXe12Gen": "PROCESOR INTEL® CORE™ [cpu_fam] 12. GENERÁCIE A&nbsp;GRAFIKA&nbsp;INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax12Gen": "PROCESOR INTEL® CORE™ [cpu_fam] 12. GENERÁCIE A&nbsp;GRAFIKA&nbsp;INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;MAX",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "PROCESOR INTEL® CORE™ [cpu_fam] 13. GENERÁCIE A GRAFIKA INTEL® ARC™",
    "tr_processorGraphicsIrisXe13Gen": "PROCESOR INTEL® CORE™ [cpu_fam] 13. GENERÁCIE A&nbsp;GRAFIKA&nbsp;INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax13Gen": "PROCESOR INTEL® CORE™ [cpu_fam] 13. GENERÁCIE A&nbsp;GRAFIKA&nbsp;INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;MAX",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "GRAFIKA INTEL® ARC™ A350M",
      "tr_A370M": "GRAFIKA INTEL® ARC™ A370M",
      "tr_A730M": "GRAFIKA INTEL® ARC™ A730M",
      "tr_A770M": "GRAFIKA INTEL® ARC™ A770M",
      "tr_A310": "GRAFIKA INTEL® ARC™ A310",
      "tr_A380": "GRAFIKA INTEL® ARC™ A380",
      "tr_A580": "GRAFIKA INTEL® ARC™ A580",
      "tr_A750": "GRAFIKA INTEL® ARC™ A750",
      "tr_A770": "GRAFIKA INTEL® ARC™ A770"
    },
    "tr_memoryValue": "[XX] GB RAM",
    "tr_memoryWithOptaneDesc": "Pamäť s kapacitou [Value1] GB = pamäť Intel® Optane™ s kapacitou [Value2] GB + RAM s kapacitou [Value3] GB",
    "tr_textGBGB": "[convertedSSD]&nbsp;GB SSD +&nbsp;[convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textGBTB": "[convertedSSD]&nbsp;GB SSD +&nbsp;[convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textTBGB": "[convertedSSD]&nbsp;TB SSD +&nbsp;[convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textTBTB": "[convertedSSD]&nbsp;TB SSD +&nbsp;[convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textGBSSD": "[convertedSSD]&nbsp;GB SSD",
    "tr_textTBSSD": "[convertedSSD]&nbsp;TB SSD",
    "tr_textGBHDD": "[convertedHDD]&nbsp;GB HDD",
    "tr_textTBHDD": "[convertedHDD]&nbsp;TB HDD",
    "tr_textStorageWithOptaneGB": "Pamäť Intel® Optane™ [XX]&nbsp;GB +&nbsp;[convertedSSD]&nbsp;GB&nbsp;SSD",
    "tr_textStorageWithOptaneTB": "Pamäť Intel® Optane™ [XX]&nbsp;GB +&nbsp;[convertedSSD]&nbsp;TB&nbsp;SSD",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home v režime S",
      "tr_OS21": "Windows* 10 Pro v režime S",
      "tr_OS22": "Windows* 10 Enterprise v režime S",
      "tr_OS23": "Windows* 11 Home v režime S",
      "tr_OS24": "Windows* 11 Pro v režime S",
      "tr_OS25": "Windows* 11 Enterprise v režime S"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Grafika Intel® Arc™ A310",
      "tr_GR387": "Grafika Intel® Arc™ A350M",
      "tr_GR388": "Grafika Intel® Arc™ A370M",
      "tr_GR389": "Grafika Intel® Arc™ A380",
      "tr_GR390": "Grafika Intel® Arc™ A530M",
      "tr_GR391": "Grafika Intel® Arc™ A550M",
      "tr_GR392": "Grafika Intel® Arc™ A570M",
      "tr_GR393": "Grafika Intel® Arc™ A580",
      "tr_GR394": "Grafika Intel® Arc™ A730M",
      "tr_GR395": "Grafika Intel® Arc™ A750",
      "tr_GR396": "Grafika Intel® Arc™ A770",
      "tr_GR397": "Grafika Intel® Arc™ A770M",
      "tr_GR398": "Grafika Intel® Arc™",
      "tr_GR399": "Grafika Intel® Arc™ Pro A30M",
      "tr_GR400": "Grafika Intel® Arc™ Pro A40/A50",
      "tr_GR401": "Grafika Intel® Arc™ Pro A60",
      "tr_GR402": "Grafika Intel® Arc™ Pro A60M",
      "tr_GR403": "Grafika Intel®",
      "tr_GR404": "Grafika Intel® HD 2000",
      "tr_GR405": "Grafika Intel® HD 2500",
      "tr_GR406": "Grafika Intel® HD 3000",
      "tr_GR407": "Grafika Intel® HD 400",
      "tr_GR408": "Grafika Intel® HD 4000",
      "tr_GR409": "Grafika Intel® HD 405",
      "tr_GR410": "Grafika Intel® HD 4200",
      "tr_GR411": "Grafika Intel® HD 4400",
      "tr_GR412": "Grafika Intel® HD 4600",
      "tr_GR413": "Grafika Intel® HD 500",
      "tr_GR414": "Grafika Intel® HD 5000",
      "tr_GR415": "Grafika Intel® HD 505",
      "tr_GR416": "Grafika Intel® HD 510",
      "tr_GR417": "Grafika Intel® HD 515",
      "tr_GR418": "Grafika Intel® HD 520",
      "tr_GR419": "Grafika Intel® HD 530",
      "tr_GR420": "Grafika Intel® HD 5300",
      "tr_GR421": "Grafika Intel® HD 5500",
      "tr_GR422": "Grafika Intel® HD 5600",
      "tr_GR423": "Grafika Intel® HD 6000",
      "tr_GR424": "Grafika Intel® HD 610",
      "tr_GR425": "Grafika Intel® HD 615",
      "tr_GR426": "Grafika Intel® HD 620",
      "tr_GR427": "Grafika Intel® HD 630",
      "tr_GR428": "Grafika Intel® HD",
      "tr_GR429": "Grafika Intel® Iris® 5100",
      "tr_GR430": "Grafika Intel® Iris® 540",
      "tr_GR431": "Grafika Intel® Iris® 550",
      "tr_GR432": "Grafika Intel® Iris® 6100",
      "tr_GR433": "Grafika Intel® Iris® Plus 640",
      "tr_GR434": "Grafika Intel® Iris® Plus 645",
      "tr_GR435": "Grafika Intel® Iris® Plus 650",
      "tr_GR436": "Grafika Intel® Iris® Plus 655",
      "tr_GR437": "Grafika Intel® Iris® Plus",
      "tr_GR438": "Grafika Intel® Iris® Pro 5200",
      "tr_GR439": "Grafika Intel® Iris® Pro 580",
      "tr_GR440": "Grafika Intel® Iris® Pro 6200",
      "tr_GR441": "Grafika Intel® Iris® X<sup>e</sup>",
      "tr_GR442": "Grafika Intel® Iris® X<sup>e</sup> MAX A220M",
      "tr_GR443": "Grafika Intel® Iris® X<sup>e</sup> MAX",
      "tr_GR444": "Integrovaná grafika Intel®",
      "tr_GR445": "Grafika Intel® UHD 600",
      "tr_GR446": "Grafika Intel® UHD 605",
      "tr_GR447": "Grafika Intel® UHD 610",
      "tr_GR448": "Grafika Intel® UHD 615",
      "tr_GR449": "Grafika Intel® UHD 617",
      "tr_GR450": "Grafika Intel® UHD 620",
      "tr_GR451": "Grafika Intel® UHD 630",
      "tr_GR452": "Grafika Intel® UHD 710",
      "tr_GR453": "Grafika Intel® UHD 730",
      "tr_GR454": "Grafika Intel® UHD 770",
      "tr_GR455": "Grafika Intel® UHD",
      "tr_GR456": "Grafika Intel® UHD",
      "tr_GR457": "Grafika Intel® UHD",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 s dizajnom Max-Q",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti s dizajnom Max-Q",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 s dizajnom Max-Q",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 s dizajnom Max-Q",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 s dizajnom Max-Q",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 s dizajnom Max-Q",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti s dizajnom Max-Q",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti s dizajnom Max-Q",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Čierna",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 s dizajnom Max-Q",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 s dizajnom Max-Q",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super s dizajnom Max-Q",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 s dizajnom Max-Q",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super s dizajnom Max-Q",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 s dizajnom Max-Q",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 s dizajnom Max-Q",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 s dizajnom Max-Q",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 s dizajnom Max-Q",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 s dizajnom Max-Q",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 s dizajnom Max-Q",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 s dizajnom Max-Q",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 s dizajnom Max-Q",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 s dizajnom Max-Q",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 s dizajnom Max-Q",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 generácie Ada",
      "tr_GR773": "NVIDIA* RTX 3000 generácie Ada",
      "tr_GR774": "NVIDIA* RTX 3500 generácie Ada",
      "tr_GR775": "NVIDIA* RTX 4000 generácie Ada",
      "tr_GR776": "NVIDIA* RTX 4000 SFF generácie Ada",
      "tr_GR777": "NVIDIA* RTX 4500 generácie Ada",
      "tr_GR778": "NVIDIA* RTX 5000 generácie Ada",
      "tr_GR779": "NVIDIA* RTX 5880 generácie Ada",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 generácie Ada",
      "tr_GR811": "NVIDIA* RTX 1000 generácie Ada",
      "tr_GR812": "Grafika Intel® Arc™",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E generácie Ada",
      "tr_GR815": "AMD Radeon* Pro W7900 Dual Slot"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Kúpiť teraz",
    "tr_textTryagain": "Skúsiť znova",
    "tr_textBuynowErr": "Kvôli prerušeniu pripojenia nie je dostupná možnosť Kúpiť teraz",
    "tr_chatbotPlaceholder_text": "Opýtajte sa ma na čokoľvek o zariadení",
    "tr_deviceSpecs_text": "Špecifikácie zariadenia"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ i3 s&nbsp;technológiou Intel®&nbsp;Hybrid"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Procesor Intel®&nbsp;Core™ i5 s&nbsp;technológiou Intel®&nbsp;Hybrid"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Avengers od Marvel",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Silnejšie herné zážitky vďaka technológii Intel®",
                "tr_subtitle": "Oživte Marvelovych Avengerov* pomocou optimalizácie počítača a technológií Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Zažite Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Miesto, kde súťažia najlepší hráči na svete",
                "tr_subtitle": "Intel® Extreme Masters s najdlhšou sériou podujatí v histórii videohier určuje štandard pre e-športy.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Hrajte ako profíci",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Intel vytvára majstrov.",
                "tr_subtitle": "Po získaní titulu majstrov Severoamerickej ligy Rainbow Six Siege* v roku 2021 sa tím Soniqs zamýšľa nad svojou hernou históriou, nad budúcnosťou e-športov a nad spoluprácou so spoločnosťou Intel.",
                "tr_pretitle": "Predstavujeme tým Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "Pripravené na virtuálnu realitu",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Keď VR ožije",
                "tr_subtitle": "Tá najúžasnejšia, najrealistickejšia a&nbsp;najpohlcujúcejšia virtuálna realita dostupná v počítači. S najnovším procesorom Intel® Core™ sa môžete stať kýmkoľvek, dostať sa kamkoľvek a užívať si hry v ďalšej dimenzii.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Zahrajte si viac ako 100 špičkových konzolových hier, ako napríklad Sea of Thieves* a Psychonauts* 2,  na počítači so systémom Windows*, mobilnom telefóne alebo tablete vďaka predplatnému Xbox* Game Pass Ultimate a kompatibilnému ovládaču.",
                "titleArray": [
                  {
                    "tr_title": "Hrajte s kamarátmi na rôznych zariadeniach",
                    "tr_subtitle": "Preniknite do jadra komunity Xbox*, kde sa milióny hráčov tešia na spoločné hranie a zábavu. Pripojte sa a hrajte s ostatnými hry zo zdieľanej knižnice, či už sú na druhom konci sveta, alebo sedia vedľa vás."
                  },
                  {
                    "tr_title": "Hranie na počítači so systémom Windows*",
                    "tr_subtitle": "Zahrajte si konzolové hry z cloudového katalógu pomocou aplikácie Xbox* pre Windows* a kompatibilného ovládača."
                  },
                  {
                    "tr_title": "Zoberte a hrajte",
                    "tr_subtitle": "Užite si skvelé hry pre Xbox* na viacerých miestach ako kedykoľvek predtým. Zahrajte si vyše 100 hier na počítači, mobilnom telefóne alebo tablete bez ohľadu na to, či ste doma, v kaviarni alebo na hocijakom inom mieste s dobrým internetovým pripojením."
                  },
                  {
                    "tr_title": "Zábava bez ovládača",
                    "tr_subtitle": "Dotykové ovládanie Xbox* sprístupňuje nové spôsoby ako sa zapojiť do hrania, poskytuje podobný herný zážitok a umožňuje hrať tak, ako ste zvyknutí s fyzickým ovládačom."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Zaujímavosti",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Viac než 100 zážitkov s umelou inteligenciou",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Menej dotykov, viacej vecí pod kontrolou",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Zostaňte v zábere, v akomkoľvek prostredí",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativita",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Úprava osvetlenia ako od profesionála",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Jednoducho pridávajte veľkolepé efekty",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Využite umelú inteligenciu k remixovaniu skladieb",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivita",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Lepšie zabezpečenie, vyššia produktivita",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Ide to aj bez adaptéra",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Váš svet. Vo vašich zariadeniach.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Zábava",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Plynulé streamovanie aj v režime na batériu",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Plynulý a pohlcujúci zážitok z hrania hier",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Zaujímavosti",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Viac než 100 zážitkov s umelou inteligenciou",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Vytvorte si vlastnú verziu obľúbenej skladby",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Zhmotnite vaše predstavy v reálnom čase",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativita",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Najrýchlejšia cesta k finálnemu strihu",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Zdokonaľte každú fotku",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivita",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Lepšie zabezpečenie, vyššia produktivita",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Lepšia energetická efektivita. Väčšia sloboda.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Váš svet. Vo vašich zariadeniach.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Zábava",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Plynulé streamovanie v HD aj bez napájacieho adaptéru",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Pohlcujúce hranie hier aj na miestach, kde to predtým nešlo",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Kreatívnejšie",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Jednoduchá úprava fotografií",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Fotky hodné vystavenia už za pár sekúnd",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Vy napíšete. Umelá inteligencia vytvorí.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Výkonnejšie",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Cheat pre plynulejšie hranie hier",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Ide to aj bez adaptéra",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Dokonalo pohlcujúce",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktívnejšie",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Váš každodenný spoločník s podporou umelej inteligencie",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Videohovory bez chýb",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Váš svet. Vo vašich zariadeniach.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Lepšie zabezpečenie",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Integrované ochranné funkcie udržujú vaše súkromné údaje v bezpečí",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Kreatívnejšie",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Jednoduchá úprava fotografií",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Umelá inteligencia. Vaša skratka k finálnemu strihu.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Rýchle prespisy textov vašich obľúbených piesní",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Ušetrite hodiny editovania vďaka umelej inteligencii",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Výkonnejšie",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Cheat pre plynulejšie hranie hier",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Ide to aj bez adaptéra",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Dokonalo pohlcujúce",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktívnejšie",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Váš každodenný spoločník s podporou umelej inteligencie",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Videohovory bez chýb",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Váš svet. Vo vašich zariadeniach.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Lepšie zabezpečenie",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Integrované ochranné funkcie udržujú vaše súkromné údaje v bezpečí",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Všetka zábava. Jeden počítač.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "K počítaču, ktorý funguje aj ako centrum zábavy, potrebujete dostatok úložného priestoru na všetky obľúbené skladby, videá a príležitostné hry, ako aj správnu kombináciu procesora a displeja na vytváranie pútavých vizuálnych materiálov. Tento počítač má všetky tri.",
                "titleArray": [
                  {
                    "tr_title": "Pripravený na grafiku novej generácie",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vytvorte si svoju digitálnu knižnicu",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Okno do vašej zábavy",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Plynulé hranie a streamovanie",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vytvorte si svoju digitálnu knižnicu",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Okno do vašej zábavy",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Buďte produktívnejší. Kdekoľvek.",
              "tr_tileTitle1": "Zvládnite toho viac. A&nbsp;rýchlo.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Tento počítač má dokonale vyvážený výkon a prenosnosť. V&nbsp;prvom rade je vybavený procesorom navrhnutým pre tenké a ľahké zariadenia, ďalej technológiou Wi&#8209;Fi, ktorá vás udrží online, a batériou, vďaka ktorej vydržíte dlhú dobu bez adaptéra.",
                "tr_subtitle1": "Výkon tohoto počítača je dokonale vyvážený, takže stihnete spraviť viac práce za kratší čas. Kombinácia silného procesora, doplneného o dostatočnú pamäť a závratne rýchlu Wi-Fi, zefektívni všetky vaše každodenné úlohy.",
                "tr_subtitle2": "Tento počítač má dokonale vyvážený výkon a prenosnosť. V&nbsp;prvom rade je vybavený procesorom navrhnutým pre tenké a ľahké zariadenia, ďalej technológiou Wi‑Fi, ktorá vás udrží online, a batériou, vďaka ktorej vydržíte dlhú dobu bez adaptéra.",
                "titleArray": [
                  {
                    "tr_title": "Vyvinutý na vyšší výkon",
                    "tr_title1": "Inteligentný výkon, na ktorom záleží",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Najlepšia konektivita vo svojej triede",
                    "tr_title1": "Pripojte sa k tomu, na čom záleží",
                    "tr_title2": "Veľký pokrok v pripojení Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Možnosť dlhšie pracovať bez napájania",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Pracujte s programami ako profesionál",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sloboda tvoriť",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Tvorba obsahu je jednou z najnáročnejších úloh pre váš počítač. Komponenty v tomto počítači vám, našťastie, dokážu kreatívny proces zefektívniť.",
                "titleArray": [
                  {
                    "tr_title": "Inteligentnejší spôsob tvorby obsahu",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vdýchnite svojim obrázkom život",
                    "tr_title1": "Poďme tvoriť",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Pamäť pre multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pozrite sa do tohto počítača",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Toto sú tri hlavné komponenty, na ktoré by ste sa mali zamerať pri výbere nového počítača. Keď viete, akým spôsobom budete nový počítač používať, ste na dobrej ceste k zodpovedaniu otázok, čo všetko budete od týchto troch kľúčových komponentov vyžadovať. Sú to:",
                "titleArray": [
                  {
                    "tr_title": "Výkon na hranie hier a streamovanie",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Úložný priestor",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Pamäť",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Všetka zábava. Jeden počítač.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "K počítaču, ktorý funguje aj ako centrum zábavy, potrebujete dostatok úložného priestoru na všetky obľúbené skladby, videá a príležitostné hry, ako aj správnu kombináciu procesora a displeja na vytváranie pútavých vizuálnych materiálov. Tento počítač má všetky tri.",
                "titleArray": [
                  {
                    "tr_title": "Pripravený na grafiku novej generácie",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vytvorte si svoju digitálnu knižnicu",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Okno do vašej zábavy",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Plynulé hranie a streamovanie",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vytvorte si svoju digitálnu knižnicu",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Okno do vašej zábavy",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pamäť",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Displej",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Vysokorýchlostný multitasking",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VYSOKORÝCHLOSTNÝ MULTITASKING",
                "tr_subtitle": "Zvládnite viac bez spomalenia",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý dokáže spustiť viacero programov súčasne."
                  },
                  {
                    "tr_title": "Rýchla, silná a spoľahlivá Wi‑Fi."
                  },
                  {
                    "tr_title": "Pamäť, ktorá plynulo prepína medzi aplikáciami."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videohovory",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOHOVORY",
                "tr_subtitle": "Krištáľovo čisté pripojenie",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na videohovory:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý dokáže nahrávať aj zdielať obraz zároveň."
                  },
                  {
                    "tr_title": "Rýchla, silná a spoľahlivá Wi‑Fi."
                  },
                  {
                    "tr_title": "Webkamera s krištáľovo čistým obrazom."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pripravené na virtuálnu realitu",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Keď VR ožije",
                "tr_subtitle": "Čo je potrebné na vytvorenie kompletného virtuálneho sveta? V počítači budete potrebovať koordináciu medzi procesorom, grafickou kartou a pamäťou.",
                "titleArray": [
                  {
                    "tr_title": "Poháňa virtuálne svety",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Realita je v detailoch",
                    "tr_title1": "Nechajte sa pohltiť",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Úprava videa",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ÚPRAVA VIDEA",
                "tr_subtitle": "Vytvorte filmársky zázrak",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na úpravy videa:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý si poradí s veľkými súbormi."
                  },
                  {
                    "tr_title": "Pamäť, vďaka ktorej je softvér na strih videa rýchly a svižný."
                  },
                  {
                    "tr_title": "Grafika, ktorá skráti čas vykresľovania."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Vysokorýchlostný multitasking",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VYSOKORÝCHLOSTNÝ MULTITASKING",
                "tr_subtitle": "Zvládnite viac bez spomalenia",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý dokáže spustiť viacero programov súčasne."
                  },
                  {
                    "tr_title": "Rýchla, silná a spoľahlivá Wi‑Fi."
                  },
                  {
                    "tr_title": "Pamäť, ktorá plynulo prepína medzi aplikáciami."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pripravené na virtuálnu realitu",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Keď VR ožije",
                "tr_subtitle": "Čo je potrebné na vytvorenie kompletného virtuálneho sveta? V počítači budete potrebovať koordináciu medzi procesorom, grafickou kartou a pamäťou.",
                "titleArray": [
                  {
                    "tr_title": "Poháňa virtuálne svety",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Realita je v detailoch",
                    "tr_title1": "Nechajte sa pohltiť",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "3D modelovanie",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D MODELOVANIE",
                "tr_subtitle": "Výtvory vyššej úrovne",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na 3D modelovanie:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý zvládne pokročilý kreatívny softvér."
                  },
                  {
                    "tr_title": "Pamäť, ktorá rýchlo načíta veľké súbory."
                  },
                  {
                    "tr_title": "Grafika pre výkonnú tvorbu a úpravy obrázkov."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Spolupráca v reálnom čase",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SPOLUPRÁCA V REÁLNOM ČASE",
                "tr_subtitle": "Pracujte odkiaľkoľvek",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na online spoluprácu:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý umožní používať kancelársky softvér."
                  },
                  {
                    "tr_title": "Wi‑Fi, na ktorú sa môžete spoľahnúť."
                  },
                  {
                    "tr_title": "Webkamera, ktorá nahráva v žiarivom HD rozlíšení."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pripravené na virtuálnu realitu",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Keď VR ožije",
                "tr_subtitle": "Čo je potrebné na vytvorenie kompletného virtuálneho sveta? V počítači budete potrebovať koordináciu medzi procesorom, grafickou kartou a pamäťou.",
                "titleArray": [
                  {
                    "tr_title": "Poháňa virtuálne svety",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Realita je v detailoch",
                    "tr_title1": "Nechajte sa pohltiť",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Prenosné počítače Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tak tenké a ľahké prenosné počítače ešte nikdy neboli také výkonné",
                "tr_subtitle": "Prenosné počítače Intel® Evo™ sú navrhnuté, aby boli ľahko prenosné a výkonné, sú rýchle, batéria má dlhú životnosť, a to všetko v tenkom a ľahkom dizajne.",
                "titleArray": [
                  {
                    "tr_title": "Prenosný počítač sa teší nebývalej obľube, takže sme sa postarali o to, aby dokázal viac ako kedykoľvek predtým.",
                    "tr_subtitle": "Pomocou dotykového displeja skúmajte, otáčajte a približujte obsah."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Zobudenie rýchlosťou blesku",
                    "tr_subtitle": "S okamžitým prebudením počítač dokáže prejsť z režimu spánku&nbsp;do pripraveného&nbsp;stavu za necelú sekundu."
                  },
                  {
                    "tr_title": "Pôsobivý obraz",
                    "tr_subtitle": "Krásny displej prenesie váš obraz do života s&nbsp;ostrým rozlíšením a živými farbami."
                  },
                  {
                    "tr_title": "Robte veci rýchlejšie",
                    "tr_subtitle": "Využívajte ultra responzívny výkon procesorov Intel® Core™ 11.&nbsp;generácie."
                  },
                  {
                    "tr_title": "Dlhšie odpojenie od siete",
                    "tr_subtitle": "Vďaka počítaču, ktorý optimalizuje výdrž batérie a nabíja sa rýchlosťou blesku, nemusíte&nbsp;vôbec prerušiť svoje činnosti."
                  },
                  {
                    "tr_title": "Univerzálne káblové pripojenie",
                    "tr_subtitle": "Vďaka nemu je pripojenie iných zariadení do počítača jednoduché,&nbsp;rýchle a bezpečné, a to vďaka technológii Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Rýchle, spoľahlivé pripojenie",
                    "tr_subtitle": "Četujte s videom alebo zdieľajte súbory s pripojením, na ktoré sa môžete spoľahnúť, s&nbsp;využitím Intel®&nbsp;Wi‑Fi&nbsp;6 (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Prenosné počítače Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Nadštandardné vybavenie v tenkom a ľahkom prenosnom počítači.",
                "tr_subtitle": "Prenosné počítače Intel® Evo™ sú navrhnuté pre mobilný výkon, aby vám poskytli najlepšie možnosti<sup>2</sup> bez ohľadu na to, čo robíte.",
                "titleArray": [
                  {
                    "tr_title": "Prenosný počítač sa teší nebývalej obľube, takže sme sa postarali o to, aby dokázal viac ako kedykoľvek predtým.",
                    "tr_subtitle": "Pomocou dotykového displeja skúmajte, otáčajte a približujte obsah."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Zobudenie rýchlosťou blesku",
                    "tr_subtitle": "S okamžitým prebudením počítač dokáže prejsť z režimu spánku&nbsp;do pripraveného&nbsp;stavu za necelú sekundu."
                  },
                  {
                    "tr_title": "Pôsobivý obraz",
                    "tr_subtitle": "Krásny displej prenesie váš obraz do života s&nbsp;ostrým rozlíšením a živými farbami."
                  },
                  {
                    "tr_title": "Revolučný výkon",
                    "tr_subtitle": "Získajte vyšší výkon tam, kde ho potrebujete najviac, vďaka inteligentnej technológii v procesoroch Intel® Core™ 12. generácie."
                  },
                  {
                    "tr_title": "Dlhšie odpojenie od siete",
                    "tr_subtitle": "Vďaka počítaču, ktorý optimalizuje výdrž batérie a nabíja sa rýchlosťou blesku, nemusíte&nbsp;vôbec prerušiť svoje činnosti."
                  },
                  {
                    "tr_title": "Univerzálne káblové pripojenie",
                    "tr_subtitle": "Vďaka nemu je pripojenie iných zariadení do počítača jednoduché,&nbsp;rýchle a bezpečné, a to vďaka technológii Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Rýchle, spoľahlivé pripojenie",
                    "tr_subtitle": "Uskutočňujte videohovory alebo zdieľajte súbory s pripojením  Intel® Wi-Fi 6E (Gig+), na ktoré sa môžete spoľahnúť.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Prenosné počítače Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tak tenké a ľahké prenosné počítače ešte nikdy neboli také výkonné",
                "tr_subtitle": "Prenosné počítače Intel® Evo™ sú navrhnuté, aby boli ľahko prenosné a výkonné, sú rýchle, batéria má dlhú životnosť, a to všetko v tenkom a ľahkom dizajne.",
                "titleArray": [
                  {
                    "tr_title": "Prenosný počítač sa teší nebývalej obľube, takže sme sa postarali o to, aby dokázal viac ako kedykoľvek predtým.",
                    "tr_subtitle": "Pomocou dotykového displeja skúmajte, otáčajte a približujte obsah."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Zobudenie rýchlosťou blesku",
                    "tr_subtitle": "S okamžitým prebudením počítač dokáže prejsť z režimu spánku&nbsp;do pripraveného&nbsp;stavu za necelú sekundu."
                  },
                  {
                    "tr_title": "Pôsobivý obraz",
                    "tr_subtitle": "Krásny displej prenesie váš obraz do života s&nbsp;ostrým rozlíšením a živými farbami."
                  },
                  {
                    "tr_title": "Robte veci rýchlejšie",
                    "tr_subtitle": "Využívajte ultra responzívny výkon procesorov Intel® Core™ 11.&nbsp;generácie."
                  },
                  {
                    "tr_title": "Dlhšie odpojenie od siete",
                    "tr_subtitle": "Vďaka počítaču, ktorý optimalizuje výdrž batérie a nabíja sa rýchlosťou blesku, nemusíte&nbsp;vôbec prerušiť svoje činnosti."
                  },
                  {
                    "tr_title": "Univerzálne káblové pripojenie",
                    "tr_subtitle": "Vďaka nemu je pripojenie iných zariadení do počítača jednoduché,&nbsp;rýchle a bezpečné, a to vďaka technológii Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Rýchle, spoľahlivé pripojenie",
                    "tr_subtitle": "Četujte s videom alebo zdieľajte súbory s pripojením, na ktoré sa môžete spoľahnúť, s&nbsp;využitím Intel®&nbsp;Wi‑Fi&nbsp;6 (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Prenosné počítače Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Nadštandardné vybavenie v tenkom a ľahkom prenosnom počítači.",
                "tr_subtitle": "Prenosné počítače Intel® Evo™ sú navrhnuté pre mobilný výkon, aby vám poskytli najlepšie možnosti<sup>2</sup> bez ohľadu na to, čo robíte.",
                "titleArray": [
                  {
                    "tr_title": "Prenosný počítač sa teší nebývalej obľube, takže sme sa postarali o to, aby dokázal viac ako kedykoľvek predtým.",
                    "tr_subtitle": "Pomocou dotykového displeja skúmajte, otáčajte a približujte obsah."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Zobudenie rýchlosťou blesku",
                    "tr_subtitle": "S okamžitým prebudením počítač dokáže prejsť z režimu spánku&nbsp;do pripraveného&nbsp;stavu za necelú sekundu."
                  },
                  {
                    "tr_title": "Pôsobivý obraz",
                    "tr_subtitle": "Krásny displej prenesie váš obraz do života s&nbsp;ostrým rozlíšením a živými farbami."
                  },
                  {
                    "tr_title": "Revolučný výkon",
                    "tr_subtitle": "Získajte vyšší výkon tam, kde ho potrebujete najviac, vďaka inteligentnej technológii v procesoroch Intel® Core™ 12. generácie."
                  },
                  {
                    "tr_title": "Dlhšie odpojenie od siete",
                    "tr_subtitle": "Vďaka počítaču, ktorý optimalizuje výdrž batérie a nabíja sa rýchlosťou blesku, nemusíte&nbsp;vôbec prerušiť svoje činnosti."
                  },
                  {
                    "tr_title": "Univerzálne káblové pripojenie",
                    "tr_subtitle": "Vďaka nemu je pripojenie iných zariadení do počítača jednoduché,&nbsp;rýchle a bezpečné, a to vďaka technológii Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Rýchle, spoľahlivé pripojenie",
                    "tr_subtitle": "Uskutočňujte videohovory alebo zdieľajte súbory s pripojením  Intel® Wi-Fi 6E (Gig+), na ktoré sa môžete spoľahnúť.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Prenosné počítače Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Nadštandardné vybavenie v tenkom a ľahkom prenosnom počítači.",
                "tr_subtitle": "Prenosné počítače Intel® Evo™ sú navrhnuté pre mobilný výkon, aby vám poskytli najlepšie možnosti<sup>2</sup> bez ohľadu na to, čo robíte.",
                "titleArray": [
                  {
                    "tr_title": "Prenosný počítač sa teší nebývalej obľube, takže sme sa postarali o to, aby dokázal viac ako kedykoľvek predtým.",
                    "tr_subtitle": "Pomocou dotykového displeja skúmajte, otáčajte a približujte obsah."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Zobudenie rýchlosťou blesku",
                    "tr_subtitle": "S okamžitým prebudením počítač dokáže prejsť z režimu spánku&nbsp;do pripraveného&nbsp;stavu za necelú sekundu."
                  },
                  {
                    "tr_title": "Pôsobivý obraz",
                    "tr_subtitle": "Krásny displej prenesie váš obraz do života s&nbsp;ostrým rozlíšením a živými farbami."
                  },
                  {
                    "tr_title": "Revolučný výkon",
                    "tr_subtitle": "Získajte vyšší výkon tam, kde ho potrebujete najviac, vďaka inteligentnej technológii v procesoroch Intel® Core™ 13. generácie."
                  },
                  {
                    "tr_title": "Dlhšie odpojenie od siete",
                    "tr_subtitle": "Vďaka počítaču, ktorý optimalizuje výdrž batérie a nabíja sa rýchlosťou blesku, nemusíte&nbsp;vôbec prerušiť svoje činnosti."
                  },
                  {
                    "tr_title": "Univerzálne káblové pripojenie",
                    "tr_subtitle": "Vďaka nemu je pripojenie iných zariadení do počítača jednoduché,&nbsp;rýchle a bezpečné, a to vďaka technológii Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Rýchle, spoľahlivé pripojenie",
                    "tr_subtitle": "Uskutočňujte videohovory alebo zdieľajte súbory s pripojením  Intel® Wi-Fi 6E (Gig+), na ktoré sa môžete spoľahnúť.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Prenosné počítače Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Nadštandardné vybavenie v tenkom a ľahkom prenosnom počítači.",
                "tr_subtitle": "Prenosné počítače Intel® Evo™ sú navrhnuté pre mobilný výkon, aby vám poskytli najlepšie možnosti<sup>2</sup> bez ohľadu na to, čo robíte.",
                "titleArray": [
                  {
                    "tr_title": "Prenosný počítač sa teší nebývalej obľube, takže sme sa postarali o to, aby dokázal viac ako kedykoľvek predtým.",
                    "tr_subtitle": "Pomocou dotykového displeja skúmajte, otáčajte a približujte obsah."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Zobudenie rýchlosťou blesku",
                    "tr_subtitle": "S okamžitým prebudením počítač dokáže prejsť z režimu spánku&nbsp;do pripraveného&nbsp;stavu za necelú sekundu."
                  },
                  {
                    "tr_title": "Pôsobivý obraz",
                    "tr_subtitle": "Krásny displej prenesie váš obraz do života s&nbsp;ostrým rozlíšením a živými farbami."
                  },
                  {
                    "tr_title": "Revolučný výkon",
                    "tr_subtitle": "Získajte vyšší výkon tam, kde ho potrebujete najviac, vďaka inteligentnej technológii v procesoroch Intel® Core™ 13. generácie."
                  },
                  {
                    "tr_title": "Dlhšie odpojenie od siete",
                    "tr_subtitle": "Vďaka počítaču, ktorý optimalizuje výdrž batérie a nabíja sa rýchlosťou blesku, nemusíte&nbsp;vôbec prerušiť svoje činnosti."
                  },
                  {
                    "tr_title": "Univerzálne káblové pripojenie",
                    "tr_subtitle": "Vďaka nemu je pripojenie iných zariadení do počítača jednoduché,&nbsp;rýchle a bezpečné, a to vďaka technológii Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Rýchle, spoľahlivé pripojenie",
                    "tr_subtitle": "Uskutočňujte videohovory alebo zdieľajte súbory s pripojením  Intel® Wi-Fi 6E (Gig+), na ktoré sa môžete spoľahnúť.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Zaujímavosti",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Čo je Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Prečo Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativita",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Buďte kreatívnejší na viacerých miestach",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Úprava fotografií v plnej rýchlosti",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Vytvárajte originálne umenie bez čakania",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivita",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximalizujte produktivitu pomocou Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Odhaľte deepfaky pomocou umelej inteligencie skôr, než ich začnete zdieľať",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Váš svet. Vo vašich zariadeniach.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilita",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Zamávajte ovládaniu gestami",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Výdrž batérie, ktorá vás nebude obmedzovať",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Automaticky vás udržiava v spojení",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Zaujímavosti",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Čo je Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Prečo Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Kreativita",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Rýchle a plynulé prehrávanie videí",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Vytvorte hity s pomocou umelej inteligencie",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "Najrýchlejšia cesta k finálnemu strihu",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produktivita",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximalizujte produktivitu pomocou Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Odhaľte deepfaky pomocou umelej inteligencie skôr, než ich začnete zdieľať",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Váš svet. Vo vašich zariadeniach.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilita",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Spolupracujte prakticky odkiaľkoľvek",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Výdrž batérie, ktorá vás nebude obmedzovať",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Automaticky vás udržiava v spojení",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Všetka zábava. Jeden počítač.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "K počítaču, ktorý funguje aj ako centrum zábavy, potrebujete dostatok úložného priestoru na všetky obľúbené skladby, videá a príležitostné hry, ako aj správnu kombináciu procesora a displeja na vytváranie pútavých vizuálnych materiálov. Tento počítač má všetky tri.",
                "titleArray": [
                  {
                    "tr_title": "Impozantné hranie a streamovanie",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vytvorte si svoju digitálnu knižnicu",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Okno do vašej zábavy",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Plynulé hranie a streamovanie",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vytvorte si svoju digitálnu knižnicu",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Okno do vašej zábavy",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Buďte produktívnejší. Kdekoľvek.",
              "tr_tileTitle1": "Zvládnite toho viac. A&nbsp;rýchlo.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Tento počítač má dokonale vyvážený výkon a prenosnosť. V&nbsp;prvom rade je vybavený procesorom navrhnutým pre tenké a ľahké zariadenia, ďalej technológiou Wi&#8209;Fi, ktorá vás udrží online, a batériou, vďaka ktorej vydržíte dlhú dobu bez adaptéra.",
                "tr_subtitle1": "Výkon tohoto počítača je dokonale vyvážený, takže stihnete spraviť viac práce za kratší čas. Kombinácia silného procesora, doplneného o dostatočnú pamäť a závratne rýchlu Wi-Fi, zefektívni všetky vaše každodenné úlohy.",
                "tr_subtitle2": "Tento počítač má dokonale vyvážený výkon a prenosnosť. V&nbsp;prvom rade je vybavený procesorom navrhnutým pre tenké a ľahké zariadenia, ďalej technológiou Wi‑Fi, ktorá vás udrží online, a batériou, vďaka ktorej vydržíte dlhú dobu bez adaptéra.",
                "titleArray": [
                  {
                    "tr_title": "Inteligentný výkon, na ktorom záleží",
                    "tr_title1": "Zrýchlite vďaka inteligentnému výkonu",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Najlepšia konektivita vo svojej triede",
                    "tr_title1": "Pripojte sa k tomu, na čom záleží",
                    "tr_title2": "Veľký pokrok v pripojení Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Možnosť dlhšie pracovať bez napájania",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Pracujte s programami ako profesionál",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sloboda tvoriť",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Tvorba obsahu je jednou z najnáročnejších úloh pre váš počítač. Komponenty v tomto počítači vám, našťastie, dokážu kreatívny proces zefektívniť.",
                "titleArray": [
                  {
                    "tr_title": "Rýchlejšia kreativita",
                    "tr_title1": "Zvládajte kreatívne projekty s ľahkosťou",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Tvorba na vyššej úrovni",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Pamäť pre multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pozrite sa do tohto počítača",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Toto sú tri hlavné komponenty, na ktoré by ste sa mali zamerať pri výbere nového počítača. Keď viete, akým spôsobom budete nový počítač používať, ste na dobrej ceste k zodpovedaniu otázok, čo všetko budete od týchto troch kľúčových komponentov vyžadovať. Sú to:",
                "titleArray": [
                  {
                    "tr_title": "Procesor",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Úložný priestor",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Pamäť",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Sloboda tvoriť",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Tvorba obsahu je jednou z najnáročnejších úloh pre váš počítač. Komponenty v tomto počítači vám, našťastie, dokážu kreatívny proces zefektívniť.",
                "titleArray": [
                  {
                    "tr_title": "Inteligentnejší spôsob tvorby obsahu",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Vdýchnite svojim obrázkom život",
                    "tr_title1": "Poďme tvoriť",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Pamäť pre multitasking",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Buďte produktívnejší. Kdekoľvek.",
              "tr_tileTitle1": "Zvládnite toho viac. A&nbsp;rýchlo.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Tento počítač má dokonale vyvážený výkon a prenosnosť. V&nbsp;prvom rade je vybavený procesorom navrhnutým pre tenké a ľahké zariadenia, ďalej technológiou Wi&#8209;Fi, ktorá vás udrží online, a batériou, vďaka ktorej vydržíte dlhú dobu bez adaptéra.",
                "tr_subtitle1": "Výkon tohoto počítača je dokonale vyvážený, takže stihnete spraviť viac práce za kratší čas. Kombinácia silného procesora, doplneného o dostatočnú pamäť a závratne rýchlu Wi-Fi, zefektívni všetky vaše každodenné úlohy.",
                "tr_subtitle2": "Tento počítač má dokonale vyvážený výkon a prenosnosť. V&nbsp;prvom rade je vybavený procesorom navrhnutým pre tenké a ľahké zariadenia, ďalej technológiou Wi‑Fi, ktorá vás udrží online, a batériou, vďaka ktorej vydržíte dlhú dobu bez adaptéra.",
                "titleArray": [
                  {
                    "tr_title": "Vyvinutý na vyšší výkon",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Najlepšia konektivita vo svojej triede",
                    "tr_title1": "Pripojte sa k tomu, na čom záleží",
                    "tr_title2": "Veľký pokrok v pripojení Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Možnosť dlhšie pracovať bez napájania",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Pracujte s programami ako profesionál",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pripravené na virtuálnu realitu",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Keď VR ožije",
                "tr_subtitle": "Čo je potrebné na vytvorenie kompletného virtuálneho sveta? V počítači budete potrebovať koordináciu medzi procesorom, grafickou kartou a pamäťou.",
                "titleArray": [
                  {
                    "tr_title": "Poháňa virtuálne svety",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Realita je v detailoch",
                    "tr_title1": "Nechajte sa pohltiť",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>Pohľad pre boľavé oči",
      "tr_sub_header": "Modré svetlo môže poškodiť zrak a narušiť váš spánkový cyklus. Displej OLED od spoločnosti Samsung testovaný spoločnosťou SGS Eye Care nemení farby, ale zníži vlnovú dĺžku potenciálne škodlivého modrého svetla, čím poskytuje očné pohodlie a znižuje únavu očí.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Menej škodlivé modré svetlo"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra každým spôsobom",
      "tr_sub_header": "Ultraľahká a ultratenká konštrukcia OLED zvyšuje kvalitu obrazu a zároveň znižuje hmotnosť."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Skutočná čierna",
      "tr_sub_header": "0,005 nitov"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74 %<br/><span class='conventional_text'>bežné</span>",
      "tr_sub_header": "120 %<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Streamovanie",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STREAMOVANIE",
                "tr_subtitle": "Nekonečná zábava",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na streamovanie:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý dokáže streamovať v HD rozlíšení."
                  },
                  {
                    "tr_title": "Displej s krásnym a jasným obrazom."
                  },
                  {
                    "tr_title": "Wi‑Fi, ktorá umožňuje menej čakania a rýchlejšie načítavanie."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Prehliadanie webu",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "PREHLIADANIE WEBU",
                "tr_subtitle": "Internet je hneď jednoduchší",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na prehliadanie webu:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý dokáže rýchlo načítať multimediálne stránky."
                  },
                  {
                    "tr_title": "Rýchla a spoľahlivá Wi‑Fi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videohovory",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOHOVORY",
                "tr_subtitle": "Krištáľovo čisté pripojenie",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na videohovory:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý dokáže nahrávať aj zdielať obraz zároveň."
                  },
                  {
                    "tr_title": "Rýchla, silná a spoľahlivá Wi‑Fi."
                  },
                  {
                    "tr_title": "Webkamera s krištáľovo čistým obrazom."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Vysokorýchlostný multitasking",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VYSOKORÝCHLOSTNÝ MULTITASKING",
                "tr_subtitle": "Zvládnite viac bez spomalenia",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý dokáže spustiť viacero programov súčasne."
                  },
                  {
                    "tr_title": "Rýchla, silná a spoľahlivá Wi‑Fi."
                  },
                  {
                    "tr_title": "Pamäť, ktorá plynulo prepína medzi aplikáciami."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videohovory",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOHOVORY",
                "tr_subtitle": "Krištáľovo čisté pripojenie",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na videohovory:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý dokáže nahrávať aj zdielať obraz zároveň."
                  },
                  {
                    "tr_title": "Rýchla, silná a spoľahlivá Wi‑Fi."
                  },
                  {
                    "tr_title": "Webkamera s krištáľovo čistým obrazom."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hranie bežných hier",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "HRANIE BEŽNÝCH HIER",
                "tr_subtitle": "Čas na hry",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na hranie nenáročných hier:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý umožňuje plynulé hranie."
                  },
                  {
                    "tr_title": "Pamäť, ktorá zvládne hlasový chat a streamovanie súčasne."
                  },
                  {
                    "tr_title": "Grafika, ktorá vykresľuje úžasný obraz."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Úprava fotografií",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ÚPRAVA FOTOGRAFIÍ",
                "tr_subtitle": "Dokonalé fotografie",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na úpravu fotografií:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Procesor s výkonom pre editovanie veľkého množstva snímok."
                  },
                  {
                    "tr_title": "Pamäť, ktorá urýchľuje prácu s grafickým softvérom."
                  },
                  {
                    "tr_title": "Technológia Thunderbolt™ 4 na rýchly prenos súborov."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Vysokorýchlostný multitasking",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VYSOKORÝCHLOSTNÝ MULTITASKING",
                "tr_subtitle": "Zvládnite viac bez spomalenia",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na multitasking:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý dokáže spustiť viacero programov súčasne."
                  },
                  {
                    "tr_title": "Rýchla, silná a spoľahlivá Wi‑Fi."
                  },
                  {
                    "tr_title": "Pamäť, ktorá plynulo prepína medzi aplikáciami."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Spolupráca v reálnom čase",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SPOLUPRÁCA V REÁLNOM ČASE",
                "tr_subtitle": "Pracujte odkiaľkoľvek",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na online spoluprácu:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý umožní používať kancelársky softvér."
                  },
                  {
                    "tr_title": "Wi‑Fi, na ktorú sa môžete spoľahnúť."
                  },
                  {
                    "tr_title": "Webkamera, ktorá nahráva v žiarivom HD rozlíšení."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Úprava videa",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ÚPRAVA VIDEA",
                "tr_subtitle": "Vytvorte filmársky zázrak",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na úpravy videa:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý si poradí s veľkými súbormi."
                  },
                  {
                    "tr_title": "Pamäť, vďaka ktorej je softvér na strih videa rýchly a svižný."
                  },
                  {
                    "tr_title": "Grafika, ktorá skráti čas vykresľovania."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Úprava fotografií",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ÚPRAVA FOTOGRAFIÍ",
                "tr_subtitle": "Dokonalé fotografie",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na úpravu fotografií:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Procesor s výkonom pre editovanie veľkého množstva snímok."
                  },
                  {
                    "tr_title": "Pamäť, ktorá urýchľuje prácu s grafickým softvérom."
                  },
                  {
                    "tr_title": "Technológia Thunderbolt™ 4 na rýchly prenos súborov."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Úprava videa",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ÚPRAVA VIDEA",
                "tr_subtitle": "Vytvorte filmársky zázrak",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na úpravy videa:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý si poradí s veľkými súbormi."
                  },
                  {
                    "tr_title": "Pamäť, vďaka ktorej je softvér na strih videa rýchly a svižný."
                  },
                  {
                    "tr_title": "Grafika, ktorá skráti čas vykresľovania."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "3D modelovanie",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D MODELOVANIE",
                "tr_subtitle": "Výtvory vyššej úrovne",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na 3D modelovanie:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý zvládne pokročilý kreatívny softvér."
                  },
                  {
                    "tr_title": "Pamäť, ktorá rýchlo načíta veľké súbory."
                  },
                  {
                    "tr_title": "Grafika pre výkonnú tvorbu a úpravy obrázkov."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Spolupráca v reálnom čase",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SPOLUPRÁCA V REÁLNOM ČASE",
                "tr_subtitle": "Pracujte odkiaľkoľvek",
                "tr_subtitle1": "Vďaka nasledovnému je tento počítač skvelý na online spoluprácu:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Procesor, ktorý umožní používať kancelársky softvér."
                  },
                  {
                    "tr_title": "Wi‑Fi, na ktorú sa môžete spoľahnúť."
                  },
                  {
                    "tr_title": "Webkamera, ktorá nahráva v žiarivom HD rozlíšení."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Aplikácia Intel® Unison™",
    "tr_title": "Vstúpte do prepojeného sveta",
    "tr_subtitle": "Bezproblémovo prenášajte súbory a fotografie, zatiaľ čo spravujete oznámenia, hovory a správy z telefónu.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "PRENÁŠAJTE SÚBORY A FOTOGRAFIE",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "VOLAJTE A PRIJÍMAJTE HOVORY",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "POSIELAJTE A PRIJÍMAJTE SPRÁVY",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "SPRAVUJTE UPOZORNENIA TELEFÓNU",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "PREJDITE DO PREPOJENÉHO SVETA",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}