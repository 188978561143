const Library_ChromeBook_mobile_is = {
  "uiText": {
    "tr_endlessProgramsAndApps": "Óþrjótandi forrit og öpp",
    "tr_rethinkStorage": "Hugsaðu geymslu upp á nýtt",
    "tr_alwaysOnSecurity": "Innbyggt öryggi",
    "tr_extendedBatteryLife": "Aukin rafhlöðuending<sup>1</sup>",
    "tr_viewMore": "Sjáðu meira",
    "tr_processorTitle": "ÖRGJÖRVI",
    "tr_processorTitleCamel": "Örgjörvi",
    "tr_rethinkStorageTitle": "HUGSAÐU GEYMSLU UPP Á NÝTT",
    "tr_alwaysOnSecurityTitle": "INNBYGGT ÖRYGGI",
    "tr_extendedBatteryLifeTitle": "AUKIN RAFHLÖÐUENDING<sup>1</sup>",
    "tr_endlessProgramsAndAppsTitle": "ÓÞRJÓTANDI FORRIT OG ÖPP",
    "tr_extendedBatteryLifeDescription": "[XX] klst.",
    "tr_rethinkStorageDescriptionGB": "[XX] GB",
    "tr_rethinkStorageDescriptionTB": "[XX] TB",
    "tr_OS": "STÝRIKERFI",
    "tr_Chrome_OS": "ChromeOS*",
    "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD] GB HDD",
    "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD] TB HDD",
    "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD] GB HDD",
    "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD] TB HDD",
    "tr_textGBSSD": "[convertedSSD] GB SSD",
    "tr_textTBSSD": "[convertedSSD] TB SSD",
    "tr_textGBHDD": "[convertedHDD] GB HDD",
    "tr_textTBHDD": "[convertedHDD] TB HDD",
    "tr_graphicTitle": "GRAFÍK",
    "tr_storageTitle": "GEYMSLA",
    "tr_displayTitle": "SKJÁR",
    "tr_batteryTitle": "RAFHLAÐA",
    "tr_memoryTitle": "MINNI",
    "tr_priceTitle": "VERÐ"
  },
  "rethinkStorage": {
    "details": {
      "tr_tileTitle": "Hugsaðu geymslu upp á nýtt",
      "tr_title": "Nýstárleg aðferð við geymslu",
      "tr_subtitle": "Chromebook* tengir geymslupláss í tölvunni þinni við nær takmarkalaust pláss á Google* Drive í skýinu. Kostirnir eru þessir:",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/cloud_upload.svg",
          "tr_badgeSubTitle": "Allar þínar skrár eru afritaðar í skýið og að endurheimta þær er jafn einfalt og að skrá sig inn á Google*.",
          "badgeSubTitle": "cloud-upload"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Þú getur unnið með eða án netsambands með [XX] GB geymslupláss í tækinu.",
          "badgeSubTitle": "GBALONE"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Þú getur unnið með eða án netsambands með [XX] TB geymslupláss í tækinu.",
          "badgeSubTitle": "TBALONE"
        }
      ]
    }
  },
  "alwaysOnSecurity": {
    "details": {
      "tr_tileTitle": "Innbyggt öryggi",
      "tr_title": "Fyrirhafnarlaus vörn<sup class='sup-text'>2</sup>",
      "tr_subtitle": "Chromebook* uppfærir sjálfvirkt alla öryggiseiginleika sína svo þú getir vafrað um netið, smellt á tengla og með fullvissu um að tækið þitt er betur varið."
    }
  },
  "extendedBatteryLife": {
    "tr_tileTitle": "Aukin rafhlöðuending<sup>1</sup>",
    "iposTypes": [
      {
        "iposType": "CHROMEBOOK",
        "details": {
          "tr_title": "Gerðu meira, hladdu minna<sup class='sup-text'>1</sup>",
          "tr_subtitle": "Með straumlínulagaðri og orkusparandi hönnun Chromebook* þarftu ekki að stinga í samband klukkutímum saman."
        }
      },
      {
        "iposType": "CHROMEBOOKEVO",
        "details": {
          "tr_title": "Minni hleðslur.<br/>Hraðari endurhleðsla.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Einfölduð og orkusparneytin hönnun Chromebook* uppfyllir miklar rafhlöðukröfur Intel® Evo™ kerfisins til að þú getir hlaðið tölvuna hraðar og notað hana lengur án þess að endurhlaða hana."
        }
      },
      {
        "iposType": "CHROMEBOOKEVOGEN12",
        "details": {
          "tr_title": "Rafhlöðuending sem heldur þér á ferðinni.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Endingargóðar hraðhleðslurafhlöðurnar í þunnum og léttum Chromebook*-tölvum þýða að þú þarft ekki lengur að hafa áhyggjur af því hvar næsta innstunga er."
        }
      }
    ]
  },
  "endlessProgramsAndApps": {
    "details": {
      "tr_tileTitle": "Óþrjótandi forrit og öpp",
      "tr_title": "Fleiri öpp á hverjum degi",
      "tr_subtitle": "Notendur Chromebook* geta valið öpp og forrit úr geysilegu úrvali og ný bætast sífellt við.",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/download_badge.svg",
          "tr_badgeSubTitle": "Sæktu Chrome* og Android* öpp."
        },
        {
          "badgeUrl": "assets/images/badges/reset_badge.svg",
          "tr_badgeSubTitle": "Öll öppin þín eru samstillt í gegnum Google* reikninginn þinn."
        },
        {
          "badgeUrl": "assets/images/badges/connect_badge.svg",
          "tr_badgeSubTitle": "Vertu í sambandi, lærðu, skapaðu og leiktu þér á óendanlegan máta."
        }
      ]
    }
  },
  "processor": [
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Endurnýjaðu væntingar þínar",
                "tr_subtitle": "Horfðu á þína uppáhalds þætti og kvikmyndir áreynslulaust í Chromebook* með 10. kynslóð Gen Intel® Core™ i3 örgjörva.",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i3 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Sérhönnuð fyrir einstök afköst hvar sem er.",
                "tr_subtitle": "",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Knúin af 10. kynslóðar Intel® Core™ i5 örgjörva"
                  },
                  {
                    "tr_badgeTitle": "Kraftmikil, jafnvel ótengd við rafmagn"
                  },
                  {
                    "tr_badgeTitle": "Hraðhleðsla: 4 klst. rafhlöðuending á 30 mín.<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Besti tengjanleiki í sínum flokki með Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Alvöru vinna. Alvöru leikur.",
                "tr_subtitle": "Notaðu myndspjall, tölvupóst og haltu kynningar á meðan þú skerpir á afkastagetunni með Chromebook* með 10. kynslóðar Intel® Core™ i5 örgjörva.",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i5 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Sérhönnuð fyrir einstök afköst hvar sem er.",
                "tr_subtitle": "",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Knúin af 10. kynslóðar Intel® Core™ i7 örgjörva"
                  },
                  {
                    "tr_badgeTitle": "Kraftmikil, jafnvel ótengd við rafmagn"
                  },
                  {
                    "tr_badgeTitle": "Hraðhleðsla: 4 klst. rafhlöðuending á 30 mín.<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Besti tengjanleiki í sínum flokki með Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Því afköst skipta máli",
                "tr_subtitle": "Klipptu myndböndin þín auðveldlega og deildu minningum með því að hlaða þeim upp með Chromebook* með 10. kynslóð Intel® Core™ i7 örgjörva.",
                "tr_gen_core_title": "10. kynslóðar Intel® Core™ i7 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hraðvirk og viðbragðsfljót vinnsla",
                "tr_subtitle": "",
                "tr_gen_core_title": "8. kynslóðar Intel® Core™ i3 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hafðu ástríðu og afkastagetu þína við völd",
                "tr_subtitle": "",
                "tr_gen_core_title": "8. kynslóðar Intel® Core™ i5 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hafðu ástríðu og afkastagetu þína við völd",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 5 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hafðu ástríðu og afkastagetu þína við völd",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hafðu ástríðu og afkastagetu þína við völd",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 9 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hraðvirk og viðbragðsfljót vinnsla",
                "tr_subtitle": "",
                "tr_gen_core_title": "8. kynslóðar Intel® Core™ m3 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Mikil afkastageta fyrir verkefni þín í dag",
                "tr_subtitle": "",
                "tr_gen_core_title": "7. kynslóðar Intel® Core™ i5 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Fyrsta flokks frammistaða fyrir verkefni þín í dag",
                "tr_subtitle": "",
                "tr_gen_core_title": "7. kynslóðar Intel® Core™ i7 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Afkastageta fyrir verkefni þín í dag",
                "tr_subtitle": "",
                "tr_gen_core_title": "7. kynslóðar Intel® Core™ m3 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Aukin afkastageta fyrir þínar þarfir",
                "tr_subtitle": "",
                "tr_gen_core_title": "6. kynslóðar Intel® Core™ i5 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Þróuð afkastageta fyrir þínar þarfir",
                "tr_subtitle": "",
                "tr_gen_core_title": "6. kynslóðar Intel® Core™ i7 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Frammistaða fyrir þínar þarfir",
                "tr_subtitle": "",
                "tr_gen_core_title": "6. kynslóðar Intel® Core™ m3 örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Mörk afkastagetunnar brotin á bak aftur í Chromebook* vélum",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i3 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Allt að <br/><span class='big-blue-text'>2,4X  </span><span class='subt-text'>meiri afkastageta<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Allt að <br/><span class='big-blue-text'>3,2X  </span><span class='subt-text'>hraðari vefskoðun<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Allt að <br/><span class='big-blue-text'>2,1X  </span><span class='subt-text'>hraðari myndbandsklippingar<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Ímyndaðu þér hvað þú getur innt af hendi með Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Allt að <br/><span class='big-blue-text'>2,7X  </span><span class='subt-text'>meiri afköst<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Allt að <br/><span class='big-blue-text'>3,3X  </span><span class='subt-text'>hraðari vefskoðun<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Allt að <br/><span class='big-blue-text'>2,8X  </span><span class='subt-text'>hraðari myndbandavinnsla<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hannaður fyrir meðfærileg afköst",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Byltingarkennd afköst með 11. kynslóðar Intel® Core™ örgjörvum"
                  },
                  {
                    "tr_badgeTitle": "Úr hvíldarstöðu á < 1 sekúndu<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nærri því 3x hraðara internet með Intel® Wi-Fi 6 (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Hröð hleðsla, mikil rafhlöðuending"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Heimsins besti örgjörvi fyrir Chromebook-tölvur*<sup class='sup-text'>1</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Allt að <br/><span class='big-blue-text'>2,9X  </span><span class='subt-text'>meiri afköst<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Allt að 3<br/><span class='big-blue-text'>3,5X  </span><span class='subt-text'>hraðari vefskoðun<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Allt að <br/><span class='big-blue-text'>2,9X  </span><span class='subt-text'>hraðari myndbandavinnsla<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hannaður fyrir meðfærileg afköst",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. kynslóðar Intel® Core™ i7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Byltingarkennd afköst með 11. kynslóðar Intel® Core™ örgjörvum"
                  },
                  {
                    "tr_badgeTitle": "Úr hvíldarstöðu á < 1 sekúndu<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nærri því 3x hraðara internet með Intel® Wi-Fi 6 (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Hröð hleðsla, mikil rafhlöðuending"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hröð vinnsla fyrir hversdaginn",
                "tr_subtitle": "Fáðu prýðileg afköst í öllum þínum uppáhalds forritum og öppum með aflinu úr Intel® Pentium® örgjörva",
                "tr_gen_core_title": "Intel® Pentium® Gold örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hröð vinnsla fyrir hversdaginn",
                "tr_subtitle": "Fáðu prýðileg afköst í öllum þínum uppáhalds forritum og öppum með aflinu úr Intel® Pentium® örgjörva",
                "tr_gen_core_title": "Intel® Pentium® Silver örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Auktu daglega afkastagetu þína",
                "tr_subtitle": "Hvort sem þú þarft að opna öpp eða verkefni hjálpar Intel® Celeron® örgjörvinn þér að koma meiru í verk.",
                "tr_gen_core_title": "Intel® Celeron® örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Afköst og virði.",
                "tr_subtitle": "Hannaður fyrir frábæra frammistöðu fyrir afþreyingu, myndbandsstreymi og vinnu. Njóttu kvikmynda og vefmyndbanda með ótrúlegum smáatriðum. Meiri tími til að njóta og minni tími í að bíða.",
                "tr_gen_core_title": "Intel® Pentium® örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hröð vinnsla fyrir hversdaginn",
                "tr_subtitle": "Fáðu prýðileg afköst í öllum þínum uppáhalds forritum og öppum með aflinu úr Intel® Pentium® örgjörva",
                "tr_gen_core_title": "Intel® Pentium® örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Afköst og virði.",
                "tr_subtitle": "Aukin framleiðni – enn meiri afköst til að komast í gegnum annasama daga. Fyrirhafnarlaus vefskoðun – efnismiklar vefsíður hlaðast hratt til að þú getir einbeitt þér í stað þess að bíða.",
                "tr_gen_core_title": "Intel® Pentium® Gold örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Afköst og virði.",
                "tr_subtitle": "Haltu utan um stafræna tilveru þína með afköstum sem veita þér aðgang að skrám af vefnum. Skiptu auðveldlega á milli forrita til að gera margt í einu á minni tíma.",
                "tr_gen_core_title": "Intel® Pentium® Silver örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Afköst og virði.",
                "tr_subtitle": "Afköst sem styðja við vinnu og afþreyingu. Njóttu kvikmynda og vefmyndbanda með ótrúlegum smáatriðum. Meiri tími til að njóta og minni tími í að bíða.",
                "tr_gen_core_title": "Intel® Celeron® örgjörvi"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Áreiðaleg afköst þar sem þeirra er mest þörf.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i3 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Auðveld<br/>fjölvinnsla"
                  },
                  {
                    "tr_badgeTitle": "Innbyggður<br/>óskýr bakgrunnur"
                  },
                  {
                    "tr_badgeTitle": "Viðbragðsfljót<br/>vefskoðun"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Aukakraftur þegar þörfin er mest.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Öflug<br/>fjölvinnsla"
                  },
                  {
                    "tr_badgeTitle": "Sjálfvirk deyfing<br/>hljóðs"
                  },
                  {
                    "tr_badgeTitle": "Háskerpu-<br/>streymi"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Afköst fartölva hafa þróast.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Yfirburðaafköst þegar þú þarft á því að halda, knúin af 12. kynslóðar Intel® Core™ örgjörvum<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Úr hvíldarstöðu á < 1 sekúndu<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nærri því 3x hraðara internet með Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Hröð hleðsla, mikil rafhlöðuending"
                  },
                  {
                    "tr_badgeTitle": "Bætt samstarf og tengimöguleikar"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Framúrskarandi afköst þegar þú þarft mest á þeim að halda.<sup>11</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Framúrskarandi<br/>fjölvinnsla"
                  },
                  {
                    "tr_badgeTitle": "Innbyggð<br/>hljóðdeyfing og<br/>óskýr bakgrunnur"
                  },
                  {
                    "tr_badgeTitle": "Hnökralaust<br/>8K streymi"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Afköst fartölva hafa þróast.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12. kynslóðar Intel® Core™ i7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Yfirburðaafköst þegar þú þarft á því að halda, knúin af 12. kynslóðar Intel® Core™ örgjörvum<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Úr hvíldarstöðu á < 1 sekúndu<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nærri því 3x hraðara internet með Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Hröð hleðsla, mikil rafhlöðuending"
                  },
                  {
                    "tr_badgeTitle": "Bætt samstarf og tengimöguleikar"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebook*-tölvur.<br/>Þær hafa þróast.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Þunn og létt hönnun sem nær lengra en afköstin<br/><span class='marklist'>og</span> tengist á hnökralausan hátt við símann þinn <sup>13</sup><br/><span class='marklist'>og</span> velur á hugvitsamlegan hátt bestu Wi-Fi eða 5G-tenginguna<br/><span class='marklist'>og</span> hleður sig hratt með rafhlöðu sem endist lengi"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gerðu það sem þú gerir með einstökum afköstum.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Aukin<br/>fjölþætting verkefna"
                  },
                  {
                    "tr_badgeTitle": "Hnökralaust<br/>4K streymi"
                  },
                  {
                    "tr_badgeTitle": "Kraftmikil<br/>sköpun"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebook*-tölvur.<br/>Þær hafa þróast.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Þunn og létt hönnun sem nær lengra en afköstin<br/><span class='marklist'>og</span> tengist á hnökralausan hátt við símann þinn <sup>13</sup><br/><span class='marklist'>og</span> velur á hugvitsamlegan hátt bestu Wi-Fi eða 5G-tenginguna<br/><span class='marklist'>og</span> hleður sig hratt með rafhlöðu sem endist lengi"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gerðu það sem þú gerir með meira en yfirburðaafköstum.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Framúrskarandi<br/>fjölvinnsla"
                  },
                  {
                    "tr_badgeTitle": "Hnökralaust<br/>8K streymi"
                  },
                  {
                    "tr_badgeTitle": "Háþróuð<br/>sköpun"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Intel® örgjörvi",
                "tr_subtitle": "Afköst sem þú þarft. Sanngjarnt verð sem þú vilt.",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gerðu það sem þú gerir með frábærum afköstum.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. kynslóðar Intel® Core™ i3 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Auðveld<br/>fjölþætting verkefna"
                  },
                  {
                    "tr_badgeTitle": "Hnökralaust<br/>streymi"
                  },
                  {
                    "tr_badgeTitle": "Hröð<br/>sköpun"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebook*-tölvur.<br/>Þær hafa þróast.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Þunn og létt hönnun sem nær lengra en afköstin<br/><span class='marklist'>og</span> tengist á hnökralausan hátt við símann þinn <sup>13</sup><br/><span class='marklist'>og</span> velur á hugvitsamlegan hátt bestu Wi-Fi eða 5G-tenginguna<br/><span class='marklist'>og</span> hleður sig hratt með rafhlöðu sem endist lengi"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gerðu það sem þú gerir með frábærum afköstum.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Auðveld<br/>fjölþætting verkefna"
                  },
                  {
                    "tr_badgeTitle": "Hnökralaust<br/>streymi"
                  },
                  {
                    "tr_badgeTitle": "Hröð<br/>sköpun"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebook*-tölvur.<br/>Þær hafa þróast.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Þunn og létt hönnun sem nær lengra en afköstin<br/><span class='marklist'>og</span> tengist á hnökralausan hátt við símann þinn <sup>13</sup><br/><span class='marklist'>og</span> velur á hugvitsamlegan hátt bestu Wi-Fi eða 5G-tenginguna<br/><span class='marklist'>og</span> hleður sig hratt með rafhlöðu sem endist lengi"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gerðu það sem þú gerir með einstökum afköstum.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Aukin<br/>fjölþætting verkefna"
                  },
                  {
                    "tr_badgeTitle": "Hnökralaust<br/>4K streymi"
                  },
                  {
                    "tr_badgeTitle": "Kraftmikil<br/>sköpun"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebook*-tölvur.<br/>Þær hafa þróast.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Þunn og létt hönnun sem nær lengra en afköstin<br/><span class='marklist'>og</span> tengist á hnökralausan hátt við símann þinn <sup>13</sup><br/><span class='marklist'>og</span> velur á hugvitsamlegan hátt bestu Wi-Fi eða 5G-tenginguna<br/><span class='marklist'>og</span> hleður sig hratt með rafhlöðu sem endist lengi"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gerðu það sem þú gerir með meira en yfirburðaafköstum.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Framúrskarandi<br/>fjölvinnsla"
                  },
                  {
                    "tr_badgeTitle": "Hnökralaust<br/>8K streymi"
                  },
                  {
                    "tr_badgeTitle": "Háþróuð<br/>sköpun"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebook*-tölvur.<br/>Þær hafa þróast.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i9 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Þunn og létt hönnun sem nær lengra en afköstin<br/><span class='marklist'>og</span> tengist á hnökralausan hátt við símann þinn <sup>13</sup><br/><span class='marklist'>og</span> velur á hugvitsamlegan hátt bestu Wi-Fi eða 5G-tenginguna<br/><span class='marklist'>og</span> hleður sig hratt með rafhlöðu sem endist lengi"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Uppfylltu ástríðu þína með betri afköstum",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i9 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Hröð mynd-<br/>og myndbandavinnsla"
                  },
                  {
                    "tr_badgeTitle": "Betri vinnslugeta<br/>án nettengingar"
                  },
                  {
                    "tr_badgeTitle": "Spilaðu leiki<br/>áhyggjulaus með<br/>langri rafhlöðuendingu"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_3",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebook*-tölvur.<br/>Þær hafa þróast.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Þunn og létt hönnun sem nær lengra en afköstin<br/><span class='marklist'>og</span> tengist á hnökralausan hátt við símann þinn <sup>13</sup><br/><span class='marklist'>og</span> velur á hugvitsamlegan hátt bestu Wi-Fi eða 5G-tenginguna<br/><span class='marklist'>og</span> hleður sig hratt með rafhlöðu sem endist lengi"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gerðu það sem þú gerir með frábærum afköstum.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Auðveld<br/>fjölþætting verkefna"
                  },
                  {
                    "tr_badgeTitle": "Hnökralaust<br/>streymi"
                  },
                  {
                    "tr_badgeTitle": "Hröð<br/>sköpun"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_5",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebook*-tölvur.<br/>Þær hafa þróast.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Þunn og létt hönnun sem nær lengra en afköstin<br/><span class='marklist'>og</span> tengist á hnökralausan hátt við símann þinn <sup>13</sup><br/><span class='marklist'>og</span> velur á hugvitsamlegan hátt bestu Wi-Fi eða 5G-tenginguna<br/><span class='marklist'>og</span> hleður sig hratt með rafhlöðu sem endist lengi"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gerðu það sem þú gerir með einstökum afköstum.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Aukin<br/>fjölþætting verkefna"
                  },
                  {
                    "tr_badgeTitle": "Hnökralaust<br/>4K streymi"
                  },
                  {
                    "tr_badgeTitle": "Kraftmikil<br/>sköpun"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_7",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebook*-tölvur.<br/>Þær hafa þróast.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Þunn og létt hönnun sem nær lengra en afköstin<br/><span class='marklist'>og</span> tengist á hnökralausan hátt við símann þinn <sup>13</sup><br/><span class='marklist'>og</span> velur á hugvitsamlegan hátt bestu Wi-Fi eða 5G-tenginguna<br/><span class='marklist'>og</span> hleður sig hratt með rafhlöðu sem endist lengi"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gerðu það sem þú gerir með meira en yfirburðaafköstum.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Framúrskarandi<br/>fjölvinnsla"
                  },
                  {
                    "tr_badgeTitle": "Hnökralaust<br/>8K streymi"
                  },
                  {
                    "tr_badgeTitle": "Háþróuð<br/>sköpun"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebook*-tölvur.<br/>Þær hafa þróast.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Þunn og létt hönnun sem nær lengra en afköstin<br/><span class='marklist'>og</span> tengist á hnökralausan hátt við símann þinn <sup>13</sup><br/><span class='marklist'>og</span> velur á hugvitsamlegan hátt bestu Wi-Fi eða 5G-tenginguna<br/><span class='marklist'>og</span> hleður sig hratt með rafhlöðu sem endist lengi"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Leystu nýja gervigreindarupplifun úr læðingi",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 5 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Allt að 65% hraðari<br/>skapandi gervigreind"
                  },
                  {
                    "tr_badgeTitle": "Allt að 2,3 sinnum hraðari<br/>myndbandavinnsla með gervigreind"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebook*-tölvur.<br/>Þær hafa þróast.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Þunn og létt hönnun sem nær lengra en afköstin<br/><span class='marklist'>og</span> tengist á hnökralausan hátt við símann þinn <sup>13</sup><br/><span class='marklist'>og</span> velur á hugvitsamlegan hátt bestu Wi-Fi eða 5G-tenginguna<br/><span class='marklist'>og</span> hleður sig hratt með rafhlöðu sem endist lengi"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Leystu nýja gervigreindarupplifun úr læðingi",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Allt að 65% hraðari<br/>skapandi gervigreind"
                  },
                  {
                    "tr_badgeTitle": "Allt að 2,3 sinnum hraðari<br/>myndbandavinnsla með gervigreind"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebook*-tölvur.<br/>Þær hafa þróast.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 9 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Þunn og létt hönnun sem nær lengra en afköstin<br/><span class='marklist'>og</span> tengist á hnökralausan hátt við símann þinn <sup>13</sup><br/><span class='marklist'>og</span> velur á hugvitsamlegan hátt bestu Wi-Fi eða 5G-tenginguna<br/><span class='marklist'>og</span> hleður sig hratt með rafhlöðu sem endist lengi"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Örgjörvi",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Leystu nýja gervigreindarupplifun úr læðingi",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 9 örgjörvi",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Allt að 65% hraðari<br/>skapandi gervigreind"
                  },
                  {
                    "tr_badgeTitle": "Allt að 2,3 sinnum hraðari<br/>myndbandavinnsla með gervigreind"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "legalText": {
    "tr_specTitle": "Lagalegar upplýsingar",
    "tr_paragraph_array": [
      "¹Allt að 10 klst. rafhlöðuending byggist á fullri hleðslu samkvæmt innri prófunum Google*.",
      "²Engin vara eða íhlutur getur verið fullkomlega öruggur. Kannaðu hjá kerfisframleiðanda eða söluaðila eða kynntu þér intel.com.",
      "³Samkvæmt mælingu með viðmiðum iðnaðarins, Representative Usage Guide prófunum og einstökum eiginleikum Intel® Core™ i7-1165G7 örgjörvans. Kynntu þér vinnuálag og stillingar á www.intel.com/PerformanceIndex. Niðurstöður geta verið mismunandi.",
      "⁴Samkvæmt mælingu með Google Sheets Multitasking.",
      "⁵Samkvæmt mælingu með Speedometer 2.0.",
      "⁶Samkvæmt mælingu með KineMaster Export Video.",
      "Fyrir neðanmálsgreinar 4-6 voru allar prófanir gerðar á Intel® Core™ i3-1115G4 örgjörva í samanburði við Intel® Celeron® N4020 örgjörva. Farðu á www.intel.com/PerformanceIndex til að fá frekari upplýsingar um vinnuálag og stillingu. Afköst eru breytileg eftir notkun, stillingu og öðrum þáttum. Frekari upplýsingar er að finna á www.intel.com/PerformanceIndex. Afkastaniðurstöður byggja á prófunum á þeim dagsetningum sem sýndar eru í stillingum og endurspegla ekki endilega allar opinberlega tiltækar uppfærslur.",
      "⁷Tími sem það tekur að tæma rafhlöðuna úr 100% niður í krítíska rafhlöðustöðu á meðan þú framkvæmir dæmigerð vinnuferli í raunhæfu umhverfi. Niðurstöður geta verið mismunandi. Nánari upplýsingar á intel.com/evo.",
      "⁸Niðurstöður geta verið mismunandi. Nánari upplýsingar á intel.com/evo.",
      "⁹Nærri því 3x meiri hraði: 802.11ax 2x2 160 MHz býður upp á 2402 Mbps fræðilegan hámarkshraða við gagnaflutning. ~3x meiri hraði en með stöðluðu 802.11ac 2x2 80 MHz (867 Mbps) Wi‑Fi samkvæmt lýsingu IEEE 802.11* staðalsins fyrir þráðlaus netkerfi og krefst notkun þráðlausra netbeina sem eru stilltir samkvæmt 802.11ax.",
      "¹⁰Hleðslu náð úr sjálfgefinni frágangsstöðu frumframleiðanda. Niðurstöður geta verið mismunandi. Nánari upplýsingar á intel.com/evo.",
      "¹¹Í samanburði við fyrri kynslóð örgjörva.",
      "¹²Samkvæmt mælingum einstakra eiginleika Intel® Evo™ hönnunar eins og afkastamikilla Intel® Core™ örgjörva, úrvalsíhluta, óviðjafnanlegrar samhæfni og nýjustu tengimöguleikalausnanna í flottri og framsækinni hönnun. Öll hönnun sem er merkt Intel® Evo™ verður að uppfylla kröfuhörð skilyrði fyrir lykilupplifun fartölvunotenda, eins og varðandi viðbragðsflýti og rafhlöðuendingu; afköst einstakra tækja geta verið mismunandi. Upplýsingar á www.intel.com/PerformanceIndex (platforms).",
      "¹³Intel® Unison™ lausn er eins og stendur aðeins tiltæk á hæfri Intel® Evo™ hönnun í tölvum sem byggðar eru á Windows* og parast aðeins við síma með Android* eða iOS*. Öll tæki verða að keyra studda útgáfu stýrikerfis. Frammistaða einstakra Intel® Evo™ tækja getur verið mismunandi. Nánari upplýsingar á intel.com/Performance-Evo.",
      "Eiginleikar og kostir Intel® tækni eru háðir kerfisstillingum og gætu þurft aukinn vélbúnað, hugbúnað og virkjun þjónustu. Afköst geta verið mismunandi eftir kerfisstillingum. Ekkert tölvukerfi getur verið algjörlega öruggt. Kannaðu hjá kerfisframleiðanda eða söluaðila eða kynntu þér intel.com.",
      "*Aðrir geta gert tilkall til annarra nafna og vörumerkja sem þeirra eign.",
      "Intel er ekki bótaskylt vegna rangs verðs.",
      "© 2022 Intel Corporation. Celeron, Intel, Intel-lógóið, Intel Core og Pentium eru skrásett vörumerki Intel Corporation eða dótturfélaga þess."
    ]
  }
}