const Library_mobile_vi = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Được trang bị bộ xử lý [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Được trang bị bộ xử lý [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Như một bộ não, bộ xử lý của bạn điều khiển các hoạt động của máy tính. Điều đó có nghĩa là video bạn xem, game bạn chơi, trang web bạn truy cập đều bắt đầu từ bộ xử lý của bạn.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Bộ xử lý là não bộ của máy tính"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Hiệu năng và giá trị.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Bộ xử lý Intel®&nbsp;Pentium® Silver",
                    "tr_subtitle": "Luôn dẫn đầu thế giới số của bạn với hiệu năng cho phép truy cập các tệp tin trên web. Đa nhiệm đơn giản bằng cách chuyển đổi giữa các chương trình một cách dễ dàng và hoàn thành nhiều công việc hơn trong thời gian ngắn hơn.",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Pentium® Silver"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Bộ xử lý Intel®&nbsp;Pentium® Gold",
                    "tr_subtitle": "Nâng cao năng suất - Nâng cao hiệu năng giúp bạn thực hiện các công việc đòi hỏi cao nhất. Duyệt web hiệu quả - Tải nhanh các trang web đa phương tiện để khám phá nhiều hơn và chờ đợi ít hơn.",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Pentium® Gold"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Bộ xử lý Intel® Celeron® ",
                    "tr_subtitle": "Hiệu năng để hỗ trợ công việc và hoạt động giải trí của bạn. Xem phim và video trên web với chi tiết đáng kinh ngạc. Thời gian làm việc nhiều hơn và ít phải chờ đợi hơn.",
                    "tr_gen_core_title": "Bộ xử lý Intel® Celeron® "
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Bộ xử lý Intel® Pentium®",
                    "tr_subtitle": "Được thiết kế cho nhu cầu giải trí, truyền phát trực tiếp video và tăng năng suất làm việc. Xem phim và video trên web với chi tiết đáng kinh ngạc. Thời gian làm việc nhiều hơn và ít phải chờ đợi hơn.",
                    "tr_gen_core_title": "Bộ xử lý Intel® Pentium®"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Hiệu năng bạn cần. Giá cả hợp lý mà bạn muốn.",
                "tr_title": "Bộ xử lý Intel®",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Tạo giá trị theo chất riêng",
                    "tr_subtitle": "Bộ xử lý Intel® có thể xử lý bất kỳ nhiệm vụ nào mà bạn cần với mức giá mà bạn có thể mua. Dễ dàng chuyển từ phần mềm này sang phần mềm khác. Hoặc tận hưởng đồ họa chất lượng cao với hiệu năng ổn định.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Tạo kết nối mạnh hơn",
                    "tr_subtitle": "Chất lượng video sống động hơn, âm thanh rõ nét hơn và khả năng kết nối internet nhanh hơn gần gấp 3 lần cho phép bạn tự tin cộng tác ở bất cứ đâu bạn cần.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Sự khác biệt rõ ràng",
                    "tr_subtitle": "Từ xem phim kéo dài cả buổi cho đến chỉnh sửa vào phút cuối, Bộ xử lý Intel® giúp kéo dài thời lượng pin cho thiết bị, để bạn có những trải nghiệm thực sự sống động mà không hề bị gián đoạn.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Mỗi môi trường là một nơi để học tập",
                    "tr_subtitle": "Chạy nhiều công cụ học tập trực tuyến dễ dàng từ mọi góc trong nhà của bạn."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Học ở nơi mà bạn muốn",
                    "tr_subtitle": "Hoàn thành bài học bất cứ nơi nào bạn cần trong khi chạy nhiều công cụ học tập trực tuyến khác nhau cùng một lúc."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Sở hữu sức mạnh đáp ứng nhu cầu giải trí và tăng năng suất làm việc phù hợp với túi tiền và phong cách sống của bạn.",
                "tr_title": "Hiệu năng Intel phù&nbsp;hợp&nbsp;với&nbsp;mọi túi&nbsp;tiền.",
                "tr_gen_core_title": "Bộ xử lý Intel® Celeron® ",
                "processorCompare": {
                  "tr_title": "TÌM KIẾM MỨC HIỆU NĂNG VÀ GIÁ TRỊ PHÙ HỢP",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Bộ xử lý Intel® Celeron® "
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Duyệt web đa phương tiện và nhanh chóng",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Dễ dàng chuyển đổi giữa các chương trình",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thông thường",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Truyền phát trực tiếp video chất lượng 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Biên tập ảnh và video nhẹ",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tăng năng suất một cách nhanh chóng nhờ hiệu năng thông minh",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Được trang bị bộ xử lý Intel® Core™ [cpu_fam] thế hệ thứ 7"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Được trang bị bộ xử lý Intel® Core™ [cpu_fam] thế hệ thứ 6"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Được trang bị bộ xử lý Intel® Core™ [cpu_fam] thế hệ thứ 5"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Được trang bị bộ xử lý Intel® Core™ [cpu_fam] thế hệ thứ 4"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Được trang bị bộ xử lý Intel® Core™ [cpu_fam] thế hệ thứ 3"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Được trang bị bộ xử lý Intel® Core™ [cpu_fam] thế hệ thứ 2"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Được trang bị bộ xử lý Intel® Core™ [cpu_fam] thế hệ thứ 1"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Khuyến nghị cho:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Được trang bị bộ xử lý Intel® Core™ i3 thế hệ thứ 8",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3 thế&nbsp;hệ&nbsp;thứ&nbsp;8"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Bộ xử lý Intel® Core™ thế hệ thứ 9 được tạo ra để bắt kịp thế giới số của bạn. Nhờ tốc độ và hiệu năng của bộ xử lý này, bạn sẽ làm được nhiều việc hơn trên chiếc máy tính của mình mà không gặp chút phiền toái gì.",
                "tr_title": "Được trang bị bộ xử lý Intel® Core™ i3 thế hệ thứ 9",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3 thế&nbsp;hệ&nbsp;thứ&nbsp;9"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hiệu năng linh hoạt kết hợp với thời lượng pin dài để giúp bạn làm việc khi di chuyển, ở bất cứ nơi đâu.",
                "tr_title": "Sức mạnh cho cả ngày hoạt động của bạn",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3 thế&nbsp;hệ&nbsp;thứ&nbsp;10",
                "processorCompare": {
                  "tr_title": "TÌM ĐẲNG CẤP HIỆU NĂNG THẾ HỆ THỨ 10 PHÙ&nbsp;HỢP",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Phát trực tiếp phim với độ phân giải 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kết nối nhanh và tin cậy",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Áp dụng hiệu ứng che hình ảnh và lọc video",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game ở chất lượng 1080p với tốc độ khung hình mượt mà",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Lý tưởng để xử lý các tập tin lớn và&nbsp;các&nbsp;chương&nbsp;trình&nbsp;sáng&nbsp;tạo",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Từ truyền phát trực tiếp phim đến soạn bài thuyết trình, mọi việc đều dễ dàng với hiệu năng vượt trội.",
                "tr_title": "Sức mạnh cho cả ngày hoạt động của bạn",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3 thế&nbsp;hệ&nbsp;thứ&nbsp;10"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Với người dùng thường xuyên di chuyển, đây là chiếc máy tính xách tay được thiết kế đặc biệt dành cho bạn.",
                "tr_title": "Xin giới thiệu một đẳng cấp máy tính xách tay mới",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3 thế&nbsp;hệ&nbsp;thứ&nbsp;10"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Sở hữu thiết kế đặc biệt cho các máy tính xách tay, bộ xử lý Intel® Core™ i3 thế hệ thứ 11 cho phép bạn thực hiện nhiều công việc hơn dù đang ở bất cứ đâu.",
                "tr_title": "Đạt được nhiều lợi ích hơn từ máy tính xách tay mới của bạn",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i3 THẾ HỆ THỨ 11",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Khi cả gia đình sử dụng một máy tính để bàn để làm việc, bạn sẽ cần sức mạnh và tính linh hoạt của bộ xử lý Intel® Core™ i3 thế hệ thứ 11.",
                "tr_title": "Đáp ứng mọi nhu cầu với hiệu năng mang tính đột phá.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i3 THẾ HỆ THỨ 11",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Được trang bị bộ xử lý Intel® Core™ i5 thế hệ thứ 8",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;8"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Bộ xử lý Intel® Core™ thế hệ thứ 9 được tạo ra để bắt kịp thế giới số của bạn. Nhờ tốc độ và hiệu năng của bộ xử lý này, bạn sẽ làm được nhiều việc hơn trên chiếc máy tính của mình mà không gặp chút phiền toái gì.",
                "tr_title": "Được trang bị bộ xử lý Intel® Core™ i5 thế hệ thứ 9",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;9"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "CHƠI GAME MƯỢT MÀ VÀ VR VỚI HẦU HẾT CÁC TỰA GAME CÓ YÊU CẦU CAO",
                "tr_title": "Chơi game thực thụ bắt đầu từ đây",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bộ nhớ đệm</span><span class='badgetitle_font'> <br/>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ INTEL® CORE™ THEO NHU CẦU CHƠI GAME CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Chơi game AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thi đấu",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game, truyền phát trực tiếp nội dung và ghi hình mà không bị ảnh hưởng",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bộ xử lý Intel® Core™ thế hệ thứ 9 tốt nhất dành cho chơi game",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 4<br/>Luồng: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dễ dàng xử lý nhiều chương trình để bạn luôn sẵn sàng làm được bất cứ điều gì, ở bất cứ nơi nào trong ngày.",
                "tr_title": "Đa nhiệm khi đang di chuyển",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;10",
                "processorCompare": {
                  "tr_title": "TÌM ĐẲNG CẤP HIỆU NĂNG THẾ HỆ THỨ 10 PHÙ&nbsp;HỢP",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3"
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Phát trực tiếp phim với độ phân giải 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kết nối nhanh và tin cậy",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Áp dụng hiệu ứng che hình ảnh và lọc video",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game ở chất lượng 1080p với tốc độ khung hình mượt mà",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Lý tưởng để xử lý các tập tin lớn và&nbsp;các&nbsp;chương&nbsp;trình&nbsp;sáng&nbsp;tạo",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Nâng cao chất lượng mọi thứ bạn làm, từ giải trí đến năng suất và sáng tạo nội dung.",
                "tr_title": "Nâng cấp lên chiếc máy tính mạnh hơn",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;10"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Với người dùng thường xuyên di chuyển, đây là chiếc máy tính xách tay được thiết kế đặc biệt dành cho bạn.",
                "tr_title": "Xin giới thiệu một đẳng cấp máy tính xách tay mới",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;10"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Được xây dựng để truyền phát trực tiếp nội dung và chơi game AAA không gián đoạn",
                "tr_title": "Chơi theo luật của&nbsp;bạn",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bộ nhớ đệm</span><span class='badgetitle_font'> <br/>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ INTEL® CORE™ THẾ HỆ THỨ 10 THEO NHU CẦU CHƠI GAME CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Chơi game AAA mượt mà",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thi đấu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp và ghi hình",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Sáng tạo nội dung không giới hạn",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hiệu năng chơi game đỉnh cao",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Mở khóa và có khả năng ép xung<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Được xây dựng để truyền phát trực tiếp nội dung và chơi game AAA không gián đoạn",
                "tr_title": "Chơi theo luật của&nbsp;bạn",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bộ nhớ đệm</span><span class='badgetitle_font'> <br/>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ INTEL® CORE™ THẾ HỆ THỨ 10 THEO NHU CẦU CHƠI GAME CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Chơi game AAA mượt mà",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thi đấu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp và ghi hình",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Sáng tạo nội dung không giới hạn",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hiệu năng chơi game đỉnh cao",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Mở khóa và có khả năng ép xung<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 10<br/>Luồng: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dễ dàng xử lý nhiều chương trình để bạn luôn sẵn sàng làm được bất cứ điều gì, ở bất cứ nơi nào trong ngày.",
                "tr_title": "Đa nhiệm khi đang di chuyển",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;10",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 10"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Bộ xử lý Intel® Core™ i5 thế hệ thứ 11 mang đến trải nghiệm hình ảnh sống động và năng suất trên phạm vi rộng cho những chiếc máy tính mỏng nhất và nhẹ nhất.",
                "tr_title": "Máy tính xách tay sở hữu sức mạnh ấn tượng",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 11",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Khi cả gia đình sử dụng một máy tính để bàn để làm việc, bạn sẽ cần sức mạnh và tính linh hoạt của bộ xử lý Intel® Core™ i5 thế hệ thứ 11.",
                "tr_title": "Đáp ứng mọi nhu cầu với hiệu năng mang tính đột phá.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 11",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Bộ xử lý Intel® Core™ i5 thế hệ thứ 11 mang đến trải nghiệm hình ảnh sống động và năng suất trên phạm vi rộng cho những chiếc máy tính xách tay mỏng và nhẹ.",
                "tr_title": "Máy tính xách tay sở hữu sức mạnh ấn tượng",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 11"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Được trang bị bộ xử lý Intel® Core™ i7 thế hệ thứ 8",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;8"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Bộ xử lý Intel® Core™ thế hệ thứ 9 được tạo ra để bắt kịp thế giới số của bạn. Nhờ tốc độ và hiệu năng của bộ xử lý này, bạn sẽ làm được nhiều việc hơn trên chiếc máy tính của mình mà không gặp chút phiền toái gì.",
                "tr_title": "Được trang bị bộ xử lý Intel® Core™ i7 thế hệ thứ 9",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;9"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FPS NHANH HƠN ĐẾN 32% trong khi chơi game, truyền phát trực tiếp nội dung và ghi hình so với một MÁY TÍNH ĐÃ QUA 3 NĂM SỬ DỤNG<sup>3</sup>",
                "tr_title": "Sức mạnh để chia sẻ những khoảnh khắc chơi game đỉnh cao nhất của bạn",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bộ nhớ đệm</span><span class='badgetitle_font'> <br/>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ INTEL® CORE™ THEO NHU CẦU CHƠI GAME CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Chơi game AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thi đấu",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game, truyền phát trực tiếp nội dung và ghi hình mà không bị ảnh hưởng",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bộ xử lý Intel® Core™ thế hệ thứ 9 tốt nhất dành cho chơi game",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 4<br/>Luồng: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Được thiết kế chú trọng vào hiệu suất, thiết bị có kiểu dáng đẹp này có sức tác động lớn đến cách bạn sáng tạo, kết nối và truyền phát trực tiếp nội dung.",
                "tr_title": "Hiệu năng tiên tiến ở bất cứ đâu",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;10",
                "processorCompare": {
                  "tr_title": "TÌM ĐẲNG CẤP HIỆU NĂNG THẾ HỆ THỨ 10 PHÙ&nbsp;HỢP",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Phát trực tiếp phim với độ phân giải 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Kết nối nhanh và tin cậy",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Áp dụng hiệu ứng che hình ảnh và lọc video",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game ở chất lượng 1080p với tốc độ khung hình mượt mà",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Lý tưởng để xử lý các tập tin lớn và&nbsp;các&nbsp;chương&nbsp;trình&nbsp;sáng&nbsp;tạo",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Hiệu năng nâng cao để đưa mọi hoạt động của bạn lên một đẳng cấp mới.",
                "tr_title": "Sức mạnh giải quyết mọi việc",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;10"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Với người dùng thường xuyên di chuyển, đây là chiếc máy tính xách tay được thiết kế đặc biệt dành cho bạn.",
                "tr_title": "Xin giới thiệu một đẳng cấp máy tính xách tay mới",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;10"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Chơi game, truyền phát trực tiếp nội dung và ghi hình với lợi thế cạnh tranh",
                "tr_title": "Tiếp sức cho thế giới game của bạn",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bộ nhớ đệm</span><span class='badgetitle_font'> <br/>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ INTEL® CORE™ THẾ HỆ THỨ 10 THEO NHU CẦU CHƠI GAME CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Chơi game AAA mượt mà",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thi đấu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp và ghi hình",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Sáng tạo nội dung không giới hạn",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hiệu năng chơi game đỉnh cao",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Mở khóa và có khả năng ép xung<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Chơi game, truyền phát trực tiếp nội dung và ghi hình với lợi thế cạnh tranh",
                "tr_title": "Tiếp sức cho thế giới game của bạn",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bộ nhớ đệm</span><span class='badgetitle_font'> <br/>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ INTEL® CORE™ THẾ HỆ THỨ 10 THEO NHU CẦU CHƠI GAME CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Chơi game AAA mượt mà",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thi đấu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp và ghi hình",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Sáng tạo nội dung không giới hạn",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hiệu năng chơi game đỉnh cao",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Mở khóa và có khả năng ép xung<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 10<br/>Luồng: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Được thiết kế để mang lại hiệu quả cao, thiết bị có kiểu dáng bắt mắt này tác động đáng kể đến cách bạn sáng tạo, kết nối và truyền phát trực tiếp nội dung.",
                "tr_title": "Hiệu năng đẳng cấp ở bất kỳ đâu",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;10",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 10"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Bộ xử lý Intel® Core™ i7 thế hệ thứ 11 mang đến khả năng sáng tạo nội dung không giới hạn, chơi game mượt mà và giải trí ở đẳng cấp mới cho các máy tính siêu di động.",
                "tr_title": "Hiệu năng máy tính xách tay mang tính đột phá",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 11",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Biến chiếc máy tính để bàn của bạn trở thành điểm đến đáp ứng mọi nhu cầu của gia đình bạn nhờ vào hiệu năng mang tính đột phá của bộ xử lý Intel® Core™ i7 thế hệ thứ 11.",
                "tr_title": "Làm việc. Học tập. Giải trí. Không có ranh giới.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 11",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Bộ xử lý Intel® Core™ i7 thế hệ thứ 11 mang đến khả năng sáng tạo nội dung không giới hạn, chơi game mượt mà và giải trí ở đẳng cấp mới cho các máy tính xách tay mỏng & nhẹ.",
                "tr_title": "Hiệu năng máy tính xách tay mang tính đột phá",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 11"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Biến chiếc máy tính để bàn của bạn trở thành điểm đến đáp ứng mọi nhu cầu của gia đình bạn nhờ vào hiệu năng mang tính đột phá của bộ xử lý Intel® Core™ i7 thế hệ thứ 11.",
                "tr_title": "Sáng tạo không giới hạn",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 11"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Với khả năng sáng tạo nội dung không giới hạn, chơi game mượt mà, và giải trí cấp độ mới trên máy tính xách tay mỏng & nhẹ, bộ xử lý Intel® Core™ i7 thế hệ thứ 11 này trở nên tốt hơn bao giờ hết.",
                "tr_title": "Hiệu năng máy tính xách tay mang tính đột phá",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 11"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Được trang bị bộ xử lý Intel® Core™ i9 thế hệ thứ 8",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;8"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Bộ xử lý Intel® Core™ thế hệ thứ 9 được tạo ra để bắt kịp thế giới số của bạn. Nhờ tốc độ và hiệu năng của bộ xử lý này, bạn sẽ làm được nhiều việc hơn trên chiếc máy tính của mình mà không gặp chút phiền toái gì.",
                "tr_title": "Được trang bị bộ xử lý Intel® Core™ i9 thế hệ thứ 9",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;9"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FPS NHANH HƠN TỚI 41% trong khi chơi game, truyền phát trực tiếp nội dung và ghi hình so với một MÁY TÍNH ĐÃ QUA 3 NĂM SỬ DỤNG<sup>4</sup>",
                "tr_title": "Game thủ yêu cầu. Intel® Core™ i9 đáp ứng.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bộ nhớ đệm</span><span class='badgetitle_font'> <br/>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ INTEL® CORE™ THEO NHU CẦU CHƠI GAME CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Chơi game AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thi đấu",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game, truyền phát trực tiếp nội dung và ghi hình mà không bị ảnh hưởng",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bộ xử lý Intel® Core™ thế hệ thứ 9 tốt nhất dành cho chơi game",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 4<br/>Luồng: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Phát huy tiềm năng của bạn với trải nghiệm chơi game đỉnh cao",
                "tr_title": "Cạnh tranh ở mức độ cao nhất",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bộ nhớ đệm</span><span class='badgetitle_font'> <br/>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ INTEL® CORE™ THẾ HỆ THỨ 10 THEO NHU CẦU CHƠI GAME CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Chơi game AAA mượt mà",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thi đấu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp và ghi hình",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Sáng tạo nội dung không giới hạn",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hiệu năng chơi game đỉnh cao",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Mở khóa và có khả năng ép xung<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Phát huy tiềm năng của bạn với trải nghiệm chơi game đỉnh cao",
                "tr_title": "Cạnh tranh ở mức độ cao nhất",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bộ nhớ đệm</span><span class='badgetitle_font'> <br/>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ INTEL® CORE™ THẾ HỆ THỨ 10 THEO NHU CẦU CHƠI GAME CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Chơi game AAA mượt mà",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thi đấu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp và ghi hình",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Sáng tạo nội dung không giới hạn",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hiệu năng chơi game đỉnh cao",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Mở khóa và có khả năng ép xung<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 10<br/>Luồng: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Phát huy tiềm năng của bạn với trải nghiệm sáng tạo tối ưu",
                "tr_title": "Sáng tạo ở mức độ cao nhất",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;10",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Máy tính xách tay Intel® Evo™ được trang bị bộ xử lý Intel® Core™ i5 thế hệ thứ 11 và đồ họa Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> mang đến tốc độ và hiệu năng đáng kinh ngạc, tất cả chỉ trong một thiết bị có thiết kế mỏng và nhẹ.",
                "tr_title": "Bổ sung sức mạnh cho máy tính xách tay đã cải tiến",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 11",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Máy tính xách tay Intel® Evo™ được trang bị bộ xử lý Intel® Core™ i7 thế hệ thứ 11 và đồ họa Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> mang đến tốc độ và hiệu năng nâng cao cho trải nghiệm di động đẳng cấp.",
                "tr_title": "Sức mạnh tăng cường cho máy tính xách tay đã cải tiến",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 11",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Tận hưởng sự cân bằng tuyệt vời giữa trải nghiệm hiệu năng, đa phương tiện và khả năng kết nối với mức giá trị tuyệt vời.",
                "tr_title": "Hiệu năng và khả năng kết nối với mức giá trị tuyệt vời.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Pentium® Silver",
                "processorCompare": {
                  "tr_title": "TÌM KIẾM MỨC HIỆU NĂNG VÀ GIÁ TRỊ PHÙ HỢP",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Duyệt web đa phương tiện và nhanh chóng",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Dễ dàng chuyển đổi giữa các chương trình",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thông thường",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Truyền phát trực tiếp video chất lượng 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Biên tập ảnh và video nhẹ",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tăng năng suất một cách nhanh chóng nhờ hiệu năng thông minh",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Tận hưởng đa nhiệm mượt mà, chỉnh sửa ảnh và video đơn giản, video 4K và đồ họa sống động với giá trị đáng kinh ngạc.",
                "tr_title": "Hiệu năng ấn tượng để&nbsp;làm&nbsp;việc&nbsp;và giải&nbsp;trí.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Pentium® Gold",
                "processorCompare": {
                  "tr_title": "TÌM KIẾM MỨC HIỆU NĂNG VÀ GIÁ TRỊ PHÙ HỢP",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Duyệt web đa phương tiện và nhanh chóng",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Dễ dàng chuyển đổi giữa các chương trình",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thông thường",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Truyền phát trực tiếp video chất lượng 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Biên tập ảnh và video nhẹ",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tăng năng suất một cách nhanh chóng nhờ hiệu năng thông minh",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Chơi game AAA và thời lượng pin dài trong một máy tính siêu di động. Chơi game không phải thỏa hiệp về hiệu năng.",
                "tr_title": "Không phải thỏa hiệp về hiệu năng.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ CHƠI GAME SIÊU DI ĐỘNG INTEL® CORE™ CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™ i5&#8209;11300H thế hệ thứ 11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™ i7&#8209;11370H thế hệ thứ 11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™ i7&#8209;11375H SE thế hệ thứ 11"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Lên đến <span class='style_badge'>4,4&nbsp;GHz</span>&nbsp;tốc độ&nbsp;xung&nbsp;nhịp tối&nbsp;đa",
                      "tr_td2_title": "Lên đến <span class='style_badge'>4,8&nbsp;GHz</span>&nbsp;tốc độ&nbsp;xung&nbsp;nhịp tối đa",
                      "tr_td3_title": "Lên đến <span class='style_badge'>5,0&nbsp;GHz</span>&nbsp;tốc độ&nbsp;xung&nbsp;nhịp tối&nbsp;đa"
                    },
                    {
                      "tr_td1_title": "4 lõi/8 luồng",
                      "tr_td2_title": "4 lõi/8 luồng",
                      "tr_td3_title": "4 lõi/8 luồng"
                    },
                    {
                      "tr_td1_title": "Công nghệ Intel® Turbo Boost",
                      "tr_td2_title": "Công nghệ Intel® Turbo Boost",
                      "tr_td3_title": "Công nghệ Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB bộ&nbsp;nhớ&nbsp;đệm thông&nbsp;minh Intel®",
                      "tr_td2_title": "12 MB bộ&nbsp;nhớ&nbsp;đệm thông&nbsp;minh Intel®",
                      "tr_td3_title": "12 MB bộ&nbsp;nhớ&nbsp;đệm thông&nbsp;minh Intel®"
                    },
                    {
                      "tr_td1_title": "Đồ họa Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Đồ họa Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Đồ họa Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Chơi game AAA 1080p với FPS cao ở cài đặt cao cùng hiệu năng tuyệt vời khi không cắm sạc trong một máy tính siêu di động.",
                "tr_title": "Tiến lên. Giành mọi chiến thắng.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ CHƠI GAME SIÊU DI ĐỘNG INTEL® CORE™ CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™ i5&#8209;11300H thế hệ thứ 11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™ i7&#8209;11370H thế hệ thứ 11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™ i7&#8209;11375H SE thế hệ thứ 11"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Lên đến <span class='style_badge'>4,4&nbsp;GHz</span>&nbsp;tốc độ&nbsp;xung&nbsp;nhịp tối&nbsp;đa",
                      "tr_td2_title": "Lên đến <span class='style_badge'>4,8&nbsp;GHz</span>&nbsp;tốc độ&nbsp;xung&nbsp;nhịp tối đa",
                      "tr_td3_title": "Lên đến <span class='style_badge'>5,0&nbsp;GHz</span>&nbsp;tốc độ&nbsp;xung&nbsp;nhịp tối&nbsp;đa"
                    },
                    {
                      "tr_td1_title": "4 lõi/8 luồng",
                      "tr_td2_title": "4 lõi/8 luồng",
                      "tr_td3_title": "4 lõi/8 luồng"
                    },
                    {
                      "tr_td1_title": "Công nghệ Intel® Turbo Boost",
                      "tr_td2_title": "Công nghệ Intel® Turbo Boost",
                      "tr_td3_title": "Công nghệ Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB bộ&nbsp;nhớ&nbsp;đệm thông&nbsp;minh Intel®",
                      "tr_td2_title": "12 MB bộ&nbsp;nhớ&nbsp;đệm thông&nbsp;minh Intel®",
                      "tr_td3_title": "12 MB bộ&nbsp;nhớ&nbsp;đệm thông&nbsp;minh Intel®"
                    },
                    {
                      "tr_td1_title": "Đồ họa Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Đồ họa Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Đồ họa Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Tốc độ xung nhịp tối đa lên đến 5,0 GHz để đẩy giới hạn các game của bạn cùng một chiếc máy tính siêu di động có thể mang theo mọi lúc mọi nơi.",
                "tr_title": "Mạnh mẽ hơn trên từng gram.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ CHƠI GAME SIÊU DI ĐỘNG INTEL® CORE™ CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™ i5&#8209;11300H thế hệ thứ 11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™ i7&#8209;11370H thế hệ thứ 11"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™ i7&#8209;11375H SE thế hệ thứ 11"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Lên đến <span class='style_badge'>4,4&nbsp;GHz</span>&nbsp;tốc độ&nbsp;xung&nbsp;nhịp tối&nbsp;đa",
                      "tr_td2_title": "Lên đến <span class='style_badge'>4,8&nbsp;GHz</span>&nbsp;tốc độ&nbsp;xung&nbsp;nhịp tối đa",
                      "tr_td3_title": "Lên đến <span class='style_badge'>5,0&nbsp;GHz</span>&nbsp;tốc độ&nbsp;xung&nbsp;nhịp tối&nbsp;đa"
                    },
                    {
                      "tr_td1_title": "4 lõi/8 luồng",
                      "tr_td2_title": "4 lõi/8 luồng",
                      "tr_td3_title": "4 lõi/8 luồng"
                    },
                    {
                      "tr_td1_title": "Công nghệ Intel® Turbo Boost",
                      "tr_td2_title": "Công nghệ Intel® Turbo Boost",
                      "tr_td3_title": "Công nghệ Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB bộ&nbsp;nhớ&nbsp;đệm thông&nbsp;minh Intel®",
                      "tr_td2_title": "12 MB bộ&nbsp;nhớ&nbsp;đệm thông&nbsp;minh Intel®",
                      "tr_td3_title": "12 MB bộ&nbsp;nhớ&nbsp;đệm thông&nbsp;minh Intel®"
                    },
                    {
                      "tr_td1_title": "Đồ họa Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Đồ họa Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Đồ họa Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Bộ xử lý Intel® Core™ i7 Special Edition thế hệ thứ 11 mang đến khả năng sáng tạo nội dung không giới hạn, chơi game mượt mà và giải trí đẳng cấp mới cho những chiếc máy tính siêu di động.",
                "tr_title": "Hiệu năng máy tính xách tay mang tính đột phá",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 PHIÊN BẢN ĐẶC BIỆT THẾ HỆ THỨ 11"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Bộ xử lý Intel® Core™ i7 Special Edition thế hệ thứ 11 mang đến khả năng sáng tạo nội dung không giới hạn, chơi game mượt mà và giải trí đẳng cấp mới cho những chiếc máy tính siêu di động.",
                "tr_title": "Hiệu năng máy tính xách tay mang tính đột phá",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 PHIÊN BẢN ĐẶC BIỆT THẾ HỆ THỨ 11",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Tốc độ xung nhịp nhanh và số lượng lõi cao phối hợp trơn tru với nhau để giúp bạn chơi game thi đấu và sáng tạo nội dung phức tạp tốt hơn.",
                "tr_title": "Sức mạnh vượt trội. Cân bằng tuyệt đối.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Sự cân bằng hợp lý giữa tốc độ xung nhịp và hiệu năng lõi giúp bạn tận hưởng game nhiều hơn, dù là để chơi game thi đấu với FPS cao hay trải nghiệm chơi game AAA sống động.",
                "tr_title": "Sức mạnh để chiến thắng.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Chơi game, ghi hình và truyền phát trực tiếp nội dung trên các tựa game có đòi hỏi khắt khe với FPS cao và dễ dàng chuyển sang khối lượng công việc đa nhiệm nặng.",
                "tr_title": "Sức mạnh bứt phá mọi giới hạn.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Tốc độ xung nhịp nhanh và số lượng lõi cao phối hợp trơn tru với nhau để giúp bạn chơi game thi đấu và sáng tạo nội dung phức tạp tốt hơn.",
                "tr_title": "Sức mạnh vượt trội. Cân bằng tuyệt đối.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Từ truyền phát trực tiếp phim đến soạn bài thuyết trình, mọi việc đều dễ dàng với hiệu năng vượt trội.",
                "tr_title": "Sức mạnh cho cả ngày hoạt động của bạn",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3 tích&nbsp;hợp Công&nbsp;nghệ Intel®&nbsp;Hybrid",
                "tr_subTitle2": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3 tích&nbsp;hợp Công&nbsp;nghệ Intel®&nbsp;Hybrid"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Hiệu năng linh hoạt kết hợp với thời lượng pin dài để giúp bạn làm việc khi di chuyển, ở bất cứ nơi đâu.",
                "tr_title": "Sức mạnh cho cả ngày hoạt động của bạn",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3 tích&nbsp;hợp Công&nbsp;nghệ Intel®&nbsp;Hybrid",
                "tr_subTitle2": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3 tích&nbsp;hợp Công&nbsp;nghệ Intel®&nbsp;Hybrid"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dễ dàng xử lý nhiều chương trình để bạn luôn sẵn sàng làm được bất cứ điều gì, ở bất cứ nơi nào trong ngày.",
                "tr_title": "Đa nhiệm khi đang di chuyển",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 tích&nbsp;hợp Công&nbsp;nghệ Intel®&nbsp;Hybrid",
                "tr_subTitle2": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 tích&nbsp;hợp Công&nbsp;nghệ Intel®&nbsp;Hybrid"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Nâng cao chất lượng mọi thứ bạn làm, từ giải trí đến năng suất và sáng tạo nội dung.",
                "tr_title": "Nâng cấp lên chiếc máy tính mạnh hơn",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 tích&nbsp;hợp Công&nbsp;nghệ Intel®&nbsp;Hybrid",
                "tr_subTitle2": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 tích&nbsp;hợp Công&nbsp;nghệ Intel®&nbsp;Hybrid"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hiệu năng chơi game ngang tầm máy tính để bàn kết hợp với tính di động, dù là chơi game thi đấu với FPS cao hay trải nghiệm các tựa game AAA sống động.",
                "tr_title": "Sức mạnh để chiến thắng.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hiệu năng chơi game ngang tầm máy tính để bàn kết hợp với tính di động, dù là chơi game thi đấu với FPS cao hay trải nghiệm các tựa game AAA sống động.",
                "tr_title": "Sức mạnh để chiến thắng.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Một chiếc máy tính xách tay có thể chơi game, ghi hình và truyền phát trực tiếp nội dung cùng một lúc với FPS cao và dễ dàng chuyển sang khối lượng công việc đa nhiệm nặng.",
                "tr_title": "Sức mạnh bứt phá mọi giới hạn.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Một chiếc máy tính xách tay có thể chơi game, ghi hình và truyền phát trực tiếp nội dung cùng một lúc với FPS cao và dễ dàng chuyển sang khối lượng công việc đa nhiệm nặng.",
                "tr_title": "Sức mạnh bứt phá mọi giới hạn.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Tốc độ xung nhịp cao và số lượng lõi nhiều phối hợp trơn tru với nhau để giúp bạn chơi game thi đấu cũng như thực hiện công việc sáng tạo nội dung phức tạp hiệu quả hơn, dù bạn ở bất kỳ đâu.",
                "tr_title": "Sức mạnh vượt trội. Cân bằng tuyệt đối.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Tốc độ xung nhịp cao và số lượng lõi nhiều phối hợp trơn tru với nhau để giúp bạn chơi game thi đấu cũng như thực hiện công việc sáng tạo nội dung phức tạp hiệu quả hơn, dù bạn ở bất kỳ đâu.",
                "tr_title": "Sức mạnh vượt trội. Cân bằng tuyệt đối.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Tốc độ xung nhịp cao, số lượng lõi nhiều và hiệu năng mở khóa phối hợp trơn tru với nhau để giúp bạn chơi game thi đấu, cũng như thực hiện công việc sáng tạo nội dung phức tạp hiệu quả hơn, dù bạn sử dụng máy tính ở bất kỳ đâu.",
                "tr_title": "Mở khóa sức mạnh. Hiệu quả chiến đấu.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Tốc độ xung nhịp cao, số lượng lõi nhiều và hiệu năng mở khóa phối hợp trơn tru với nhau để giúp bạn chơi game thi đấu, cũng như thực hiện công việc sáng tạo nội dung phức tạp hiệu quả hơn, dù bạn sử dụng máy tính ở bất kỳ đâu.",
                "tr_title": "Mở khóa sức mạnh. Hiệu quả chiến đấu.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 11",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Tốc độ xung nhịp nhanh và số lượng lõi cao phối hợp trơn tru với nhau để giúp bạn chơi game thi đấu và sáng tạo nội dung phức tạp tốt hơn.",
                "tr_title": "Sức mạnh vượt trội. Cân bằng tuyệt đối.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 11",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Tốc độ xung nhịp nhanh và số lượng lõi nhiều phối hợp trơn tru với nhau để giúp bạn thực hiện công việc sáng tạo nội dung phức tạp hiệu quả hơn, dù bạn sử dụng máy tính ở bất kỳ đâu.",
                "tr_title": "Sức mạnh vượt trội. Cân bằng tuyệt đối.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 11",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Tốc độ xung nhịp nhanh và số lượng lõi cao phối hợp trơn tru với nhau để giúp bạn thực hiện công việc sáng tạo nội dung phức tạp hiệu quả hơn.",
                "tr_title": "Sức mạnh vượt trội. Cân bằng tuyệt đối.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 11",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Phát huy tiềm năng của bạn với trải nghiệm chơi game đỉnh cao",
                "tr_title": "Cạnh tranh ở mức độ cao nhất",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bộ nhớ đệm</span><span class='badgetitle_font'> <br/>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ INTEL® CORE™ THẾ HỆ THỨ 10 THEO NHU CẦU CHƠI GAME CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Chơi game AAA mượt mà",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thi đấu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp và ghi hình",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Sáng tạo nội dung không giới hạn",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hiệu năng chơi game đỉnh cao",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Mở khóa và có khả năng ép xung<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Phát huy tiềm năng của bạn với trải nghiệm chơi game đỉnh cao",
                "tr_title": "Cạnh tranh ở mức độ cao nhất",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;10",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bộ nhớ đệm</span><span class='badgetitle_font'> <br/>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ INTEL® CORE™ THẾ HỆ THỨ 10 THEO NHU CẦU CHƠI GAME CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Chơi game AAA mượt mà",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thi đấu",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Phát trực tiếp và ghi hình",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Sáng tạo nội dung không giới hạn",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Hiệu năng chơi game đỉnh cao",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Mở khóa và có khả năng ép xung<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 10<br/>Luồng: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Với người dùng thường xuyên di chuyển, đây là chiếc máy tính xách tay được thiết kế đặc biệt dành cho bạn.",
                "tr_title": "Xin giới thiệu một đẳng cấp máy tính xách tay mới",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;10"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Tốc độ xung nhịp nhanh và số lượng lõi cao phối hợp trơn tru với nhau để giúp bạn chơi game thi đấu và sáng tạo nội dung phức tạp tốt hơn.",
                "tr_title": "Mở khóa sức mạnh. Hiệu quả chiến đấu.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 11",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Tốc độ xung nhịp nhanh, số lượng lõi nhiều và hiệu năng mở khóa phối hợp trơn tru với nhau để giúp bạn thực hiện công việc sáng tạo nội dung phức tạp hiệu quả hơn, dù bạn sử dụng máy tính ở bất kỳ đâu.",
                "tr_title": "Mở khóa sức mạnh. Hiệu quả chiến đấu.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 11",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Tốc độ xung nhịp nhanh và số lượng lõi cao phối hợp trơn tru với nhau để giúp bạn thực hiện công việc sáng tạo nội dung phức tạp hiệu quả hơn.",
                "tr_title": "Mở khóa sức mạnh. Hiệu quả chiến đấu.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;11",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 11",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Bộ nhớ đệm Thông&nbsp;minh Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Trải nghiệm chơi game và VR mượt mà trên các tựa game đòi hỏi khắt khe nhất",
                "tr_title": "Chơi game thực thụ bắt đầu từ đây",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bộ nhớ đệm</span><span class='badgetitle_font'> <br/>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ INTEL® CORE™ THEO NHU CẦU CHƠI GAME CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Chơi game AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thi đấu",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game, truyền phát trực tiếp nội dung và ghi hình mà không bị ảnh hưởng",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bộ xử lý Intel® Core™ thế hệ thứ 9 tốt nhất dành cho chơi game",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 4<br/>Luồng: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FPS CAO HƠN ĐẾN 32% <br/><span class='tr_subtitle_font'>trong khi chơi game, truyền phát trực tiếp nội dung và ghi hình so với một máy tính đã qua 3 năm sử dụng<sup>3</sup></span>",
                "tr_title": "Sức mạnh để chia sẻ những khoảnh khắc chơi game đỉnh cao nhất của bạn",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bộ nhớ đệm</span><span class='badgetitle_font'> <br/>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ INTEL® CORE™ THEO NHU CẦU CHƠI GAME CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Chơi game AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thi đấu",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game, truyền phát trực tiếp nội dung và ghi hình mà không bị ảnh hưởng",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bộ xử lý Intel® Core™ thế hệ thứ 9 tốt nhất dành cho chơi game",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 4<br/>Luồng: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FPS CAO HƠN ĐẾN 41% <br/><span class='tr_subtitle_font'>trong khi chơi game, truyền phát trực tiếp nội dung và ghi hình so với một máy tính đã qua 3 năm sử dụng<sup>4</sup></span>",
                "tr_title": "Game thủ yêu cầu. Intel® Core™ i9 đáp ứng.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Luồng</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Lõi</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Lên đến<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Bộ nhớ đệm</span><span class='badgetitle_font'> <br/>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Mở khóa",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TÌM BỘ XỬ LÝ INTEL® CORE™ THEO NHU CẦU CHƠI GAME CỦA BẠN.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Chơi game AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game thi đấu",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Chơi game, truyền phát trực tiếp nội dung và ghi hình mà không bị ảnh hưởng",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Bộ xử lý Intel® Core™ thế hệ thứ 9 tốt nhất dành cho chơi game",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 4<br/>Luồng: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 6<br/>Luồng: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Tốc độ xung nhịp<sup>2</sup>: Lên đến 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Lõi: 8<br/>Luồng: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Được thiết kế cho thế hệ chơi game mới",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 12"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 12"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 12"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Lõi</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Luồng</span></span>",
                    "tr_badgePreTitle": "[p_thread] trên Performance-core",
                    "tr_badgeSubTitle": "[e_thread] trên Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Làm việc và chơi game trên cùng một thiết bị",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 12"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 12"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Lõi</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Luồng</span></span>",
                    "tr_badgePreTitle": "[p_thread] trên Performance-core",
                    "tr_badgeSubTitle": "[e_thread] trên Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng đáng tin cậy khi bạn cần nhất",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i3 THẾ HỆ THỨ 12",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng tăng cường nơi bạn cần nhất",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 12",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng tăng cường nơi bạn cần nhất",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 12",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng vượt trội nơi bạn cần nhất",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 12",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng vượt trội nơi bạn cần nhất",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 12",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Với máy tính xách tay Intel® Evo™, bạn sẽ nhận được hiệu năng, trí thông minh tích hợp để trò chuyện video tự tin, đường truyền internet nhanh hơn, thời lượng pin lâu hơn và nhiều điều khác nữa.",
                "tr_title": "Hiệu năng tăng cường nơi bạn cần nhất",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 12",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Với máy tính xách tay Intel® Evo™, bạn sẽ nhận được hiệu năng, trí thông minh tích hợp để trò chuyện video tự tin, đường truyền internet nhanh hơn, thời lượng pin lâu hơn và nhiều điều khác nữa.",
                "tr_title": "Hiệu năng vượt trội nơi bạn cần nhất",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 12",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Với máy tính xách tay Intel® Evo™, bạn sẽ nhận được hiệu năng, trí thông minh tích hợp để trò chuyện video tự tin, đường truyền internet nhanh hơn, thời lượng pin lâu hơn và nhiều điều khác nữa.",
                "tr_title": "Hiệu suất cực cao trong một máy tính xách tay cao cấp",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 12",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Với Công nghệ Intel®&nbsp;Turbo&nbsp;Boost&nbsp;2.0 lên&nbsp;đến&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Được thiết kế dành cho doanh nghiệp",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 12",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Giảm tiếng ồn và làm mờ nền của bạn",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Hiệu năng tự động điều chỉnh để ưu tiên những gì bạn làm nhiều nhất",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Trải nghiệm cuộc gọi web chất lượng cao, mượt mà",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Nhận bảo mật dựa trên phần cứng tiện lợi",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Cung cấp khả năng quản lý thiết bị từ xa",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Tự động tối ưu hiệu năng Wi-Fi của bạn<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Cung cấp sức mạnh thông qua ứng dụng hướng&nbsp;dữ&nbsp;liệu",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Năng suất nhất với hiệu suất nâng cao",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Với Công nghệ Intel®&nbsp;Turbo&nbsp;Boost&nbsp;2.0 lên&nbsp;đến&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Làm việc chăm chỉ để bạn có thể tập trung vào công việc.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 12",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Giảm tiếng ồn và làm mờ nền của bạn",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Hiệu năng tự động điều chỉnh để ưu tiên những gì bạn làm nhiều nhất",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Trải nghiệm cuộc gọi web chất lượng cao, mượt mà",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Nhận bảo mật dựa trên phần cứng tiện lợi",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Cung cấp khả năng quản lý thiết bị từ xa",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Tự động tối ưu hiệu năng Wi-Fi của bạn<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Cung cấp sức mạnh thông qua ứng dụng hướng&nbsp;dữ&nbsp;liệu",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Năng suất nhất với hiệu suất nâng cao",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Với Công nghệ Intel®&nbsp;Turbo&nbsp;Boost&nbsp;2.0 lên&nbsp;đến&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Tăng tốc doanh nghiệp của bạn",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;12",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 12",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Giảm tiếng ồn và làm mờ nền của bạn",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Hiệu năng tự động điều chỉnh để ưu tiên những gì bạn làm nhiều nhất",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Trải nghiệm cuộc gọi web chất lượng cao, mượt mà",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Nhận bảo mật dựa trên phần cứng tiện lợi",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Cung cấp khả năng quản lý thiết bị từ xa",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Tự động tối ưu hiệu năng Wi-Fi của bạn<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Cung cấp sức mạnh thông qua ứng dụng hướng&nbsp;dữ&nbsp;liệu",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Năng suất nhất với hiệu suất nâng cao",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng đáng tin cậy được thiết kế để làm được nhiều việc hơn",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3 thế&nbsp;hệ&nbsp;thứ&nbsp;13",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng thực tế được thiết kế để làm được nhiều việc hơn",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;13",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 13",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng thực tế được thiết kế để làm được nhiều việc hơn",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;13",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 13",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng mạnh mẽ được thiết kế để làm được nhiều việc hơn",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;13",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 13",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng mạnh mẽ được thiết kế để làm được nhiều việc hơn",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;13",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 13",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu suất vượt ngoài mong đợi",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;13",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 13",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Lõi</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Luồng</span></span>",
                    "tr_badgePreTitle": "[p_thread] trên Performance-core",
                    "tr_badgeSubTitle": "[e_thread] trên Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Kết nối liền mạch với điện thoại hiện tại của bạn<sup>24</sup>, chọn Wi-Fi tốt nhất một cách thông minh và sạc nhanh với pin thời lượng dài.",
                "tr_title": "Hiệu suất vượt ngoài mong đợi để làm được nhiều việc hơn ở bất cứ đâu",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;13",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 13",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Kết nối liền mạch với điện thoại hiện tại của bạn<sup>24</sup>, chọn Wi-Fi tốt nhất một cách thông minh và sạc nhanh với pin thời lượng dài.",
                "tr_title": "Hiệu suất vượt ngoài mong đợi để máy tính cá nhân của bạn làm được nhiều hơn cùng một lúc",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;13",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 13",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Kết nối liền mạch với điện thoại hiện tại của bạn<sup>24</sup>, chọn Wi-Fi tốt nhất một cách thông minh và sạc nhanh với pin thời lượng dài.",
                "tr_title": "Hiệu suất vượt ngoài mong đợi để tăng tốc trên khắp các tác vụ đòi hỏi khắt khe nhất",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;13",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 13",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu suất vượt ngoài mong đợi",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;13",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 13",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;13",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 13",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;13",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 13",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Lõi</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Luồng</span></span>",
                    "tr_badgePreTitle": "[p_thread] trên Performance-core",
                    "tr_badgeSubTitle": "[e_thread] trên Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu suất vượt ngoài mong đợi",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Lõi</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Luồng</span></span>",
                    "tr_badgePreTitle": "[p_thread] trên Performance-core",
                    "tr_badgeSubTitle": "[e_thread] trên Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng đáng tin cậy được thiết kế để làm được nhiều việc hơn",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng thực tế được thiết kế để làm được nhiều việc hơn",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng mạnh mẽ được thiết kế để làm được nhiều việc hơn",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu suất vượt ngoài mong đợi",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Lõi</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Luồng</span></span>",
                    "tr_badgePreTitle": "[p_thread] trên Performance-core",
                    "tr_badgeSubTitle": "[e_thread] trên Efficient-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng đáng tin cậy được thiết kế để làm được nhiều việc hơn",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng thực tế được thiết kế để làm được nhiều việc hơn",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;5",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ 5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Hiệu năng mạnh mẽ được thiết kế để làm được nhiều việc hơn",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;7",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ 7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "tr_title": "Chơi game không bị ảnh hưởng",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel® Core™ Ultra 5",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ ULTRA 5",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel® Core™ Ultra 7",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ ULTRA 7",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Bộ xử lý Intel® Core™ Ultra 9",
                    "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ ULTRA 9",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Lõi</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-core",
                    "tr_badgeSubTitle": "[e_core] Efficient-core",
                    "tr_badgeSubTitle1": "[l_core] LPE-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Luồng</span></span>",
                    "tr_badgePreTitle": "[p_thread] trên Performance-core",
                    "tr_badgeSubTitle": "[e_thread] trên Efficient-core",
                    "tr_badgeSubTitle1": "[l_thread] trên LPE-core"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel® Core™ Ultra 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel® Core™ Ultra 7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Bộ xử lý Intel® Core™ Ultra 9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Chơi game không bị ảnh hưởng",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Chơi game không bị ảnh hưởng",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Chơi game không bị ảnh hưởng",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Chơi game không bị ảnh hưởng",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Chơi game không bị ảnh hưởng",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Chơi game không bị ảnh hưởng",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Doanh nghiệp nhỏ xứng đáng có giải pháp kinh doanh lớn.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 thế&nbsp;hệ&nbsp;thứ&nbsp;13",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i5 THẾ HỆ THỨ 13",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Giảm tiếng ồn và làm mờ nền của bạn",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Hiệu năng tự động điều chỉnh để ưu tiên những gì bạn làm nhiều nhất",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Trải nghiệm cuộc gọi web chất lượng cao, mượt mà",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Nhận bảo vệ bảo mật dựa trên phần cứng tiện lợi",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Giúp bảo vệ các thiết bị và dữ liệu khỏi các cuộc tấn công giả mạo vật lý",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Doanh nghiệp nhỏ xứng đáng có giải pháp kinh doanh lớn.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;13",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i7 THẾ HỆ THỨ 13",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Giảm tiếng ồn và làm mờ nền của bạn",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Hiệu năng tự động điều chỉnh để ưu tiên những gì bạn làm nhiều nhất",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Trải nghiệm cuộc gọi web chất lượng cao, mượt mà",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Nhận bảo vệ bảo mật dựa trên phần cứng tiện lợi",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Giúp bảo vệ các thiết bị và dữ liệu khỏi các cuộc tấn công giả mạo vật lý",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Doanh nghiệp nhỏ xứng đáng có giải pháp kinh doanh lớn.",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i9 thế&nbsp;hệ&nbsp;thứ&nbsp;13",
                "tr_gen_core_title_uppercase": "BỘ XỬ LÝ INTEL® CORE™ i9 THẾ HỆ THỨ 13",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ XỬ LÝ NÀY?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Giảm tiếng ồn và làm mờ nền của bạn",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Hiệu năng tự động điều chỉnh để ưu tiên những gì bạn làm nhiều nhất",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Trải nghiệm cuộc gọi web chất lượng cao, mượt mà",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Nhận bảo vệ bảo mật dựa trên phần cứng tiện lợi",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Giúp bảo vệ các thiết bị và dữ liệu khỏi các cuộc tấn công giả mạo vật lý",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Khai mở trải nghiệm AI mới cho doanh nghiệp",
                "tr_title": "Sẵn sàng cho tương lai với bộ xử lý Intel® Core™ Ultra&nbsp;[XX] và Intel vPro®",
                "tr_gen_core_title": "Bộ xử lý Intel® Core™ Ultra [XX]",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "THÚC ĐẨY TĂNG TRƯỞNG DOANH NGHIỆP CỦA BẠN VỚI MÁY TÍNH CÁ NHÂN AI TỪ INTEL",
                    "tr_badgeSubTitle": "Máy tính cá nhân mạnh mẽ tạo ra trải nghiệm tuyệt vời là cốt lõi của năng suất kinh doanh. Intel vPro® tăng cường hiệu quả và hiệu năng của nhân viên trên các khối lượng công việc và ứng dụng nhờ cấu trúc mới mang lại hiệu năng vượt trội tiết kiệm năng lượng cho khối lượng công việc kinh doanh phức tạp, đa dạng bằng cách gửi tác vụ phù hợp đến đúng công cụ vào đúng thời điểm.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "CÁC TÍNH NĂNG BẢO MẬT ĐA LỚP ĐƯỢC KÍCH HOẠT NGAY SAU KHI MỞ HỘP",
                    "tr_badgeSubTitle": "Các mối đe dọa mới và mới nổi đòi hỏi cách tiếp cận toàn diện về bảo mật. Intel vPro® cung cấp bảo vệ ở cấp độ phần cứng và trong toàn bộ ngăn xếp để doanh nghiệp của bạn được bảo vệ tốt hơn. Các biện pháp bảo mật có sẵn độc đáo này, bao gồm khả năng phát hiện mối đe dọa do AI hỗ trợ, giúp bảo vệ người dùng, dữ liệu và doanh nghiệp của bạn.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "CÁC CÔNG CỤ TÍCH HỢP ĐỂ GIỮ CHO NGƯỜI DÙNG LÀM VIỆC HIỆU QUẢ TỪ HẦU NHƯ BẤT CỨ ĐÂU",
                    "tr_badgeSubTitle": "Văn phòng ở khắp mọi nơi và máy tính cá nhân cũng vậy. Intel vPro® cung cấp khả năng quản lý dựa trên phần cứng hiện đại để giúp các doanh nghiệp giảm bớt gánh nặng quản lý hệ thống máy tính cá nhân, đơn giản hóa việc hỗ trợ và cải thiện trải nghiệm người dùng cuối, trong khi vẫn tạo điều kiện cho các hoạt động điện toán bền vững.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Hơn cả PC vượt trội, là AI PC",
                "tr_subtitle1": "ĐƯỢC HỖ TRỢ BỞI BỘ XỬ LÝ INTEL®&nbsp;CORE™ ULTRA (CHUỖI&nbsp;2)",
                "tr_subtitle2": "Mọi thứ đều có thể với Intel AI PC",
                "tr_subtitle": "Bộ xử lý di động hiệu quả, an toàn và hiệu năng cao nhất của Intel<sup>39</sup>",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™ Ultra&nbsp;5 (Chuỗi&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Ý tưởng bay xa</span> và nhanh hơn với công cụ AI"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Hiệu suất bứt phá</span> với trợ lý AI tiết kiệm thời gian"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>An toàn hơn</span> với tích hợp bảo mật dữ liệu"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Hơn cả PC vượt trội, là AI PC",
                "tr_subtitle1": "ĐƯỢC HỖ TRỢ BỞI BỘ XỬ LÝ INTEL®&nbsp;CORE™ ULTRA (CHUỖI&nbsp;2)",
                "tr_subtitle2": "Mọi thứ đều có thể với Intel AI PC",
                "tr_subtitle": "Bộ xử lý di động hiệu quả, an toàn và hiệu năng cao nhất của Intel<sup>39</sup>",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™ Ultra&nbsp;7 (Chuỗi&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Ý tưởng bay xa</span> và nhanh hơn với công cụ AI"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Hiệu suất bứt phá</span> với trợ lý AI tiết kiệm thời gian"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>An toàn hơn</span> với tích hợp bảo mật dữ liệu"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Hơn cả PC vượt trội, là AI PC",
                "tr_subtitle1": "ĐƯỢC HỖ TRỢ BỞI BỘ XỬ LÝ INTEL®&nbsp;CORE™ ULTRA (CHUỖI&nbsp;2)",
                "tr_subtitle2": "Mọi thứ đều có thể với Intel AI PC",
                "tr_subtitle": "Bộ xử lý di động hiệu quả, an toàn và hiệu năng cao nhất của Intel<sup>39</sup>",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™ Ultra&nbsp;5 (Chuỗi&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ xử lý",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Hơn cả PC vượt trội, là AI PC",
                "tr_subtitle1": "ĐƯỢC HỖ TRỢ BỞI BỘ XỬ LÝ INTEL®&nbsp;CORE™ ULTRA (CHUỖI&nbsp;2)",
                "tr_subtitle2": "Mọi thứ đều có thể với Intel AI PC",
                "tr_subtitle": "Bộ xử lý di động hiệu quả, an toàn và hiệu năng cao nhất của Intel<sup>39</sup>",
                "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™ Ultra&nbsp;7 (Chuỗi&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_tileTitle1": "Đồ họa Chuyên dụng",
                "tr_title": "ĐƠN VỊ XỬ LÝ ĐỒ HỌA LÀ GÌ?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Đơn vị xử lý đồ họa hoặc GPU tạo ra hình ảnh mà bạn thấy trên màn hình.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU tích hợp",
                    "tr_badgeSubTitle": "GPU tích hợp sử dụng chung RAM với phần còn lại của máy tính, giúp tiết kiệm năng lượng hơn.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "GPU rời",
                    "tr_badgeSubTitle": "Đây là dòng GPU mạnh nhất nhờ có RAM riêng cùng các tài nguyên khác, chuyên về xây dựng đồ họa đỉnh cao.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "GPU lai",
                    "tr_badgeSubTitle": "Đây là dòng GPU mạnh nhất nhờ có RAM riêng cùng các tài nguyên khác, chuyên về xây dựng đồ họa đỉnh cao.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Biên tập ảnh và video được hợp lý hóa",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Thưởng thức nội dung video ở độ phân giải 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Chơi các game phổ biến với chi tiết tuyệt đẹp",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "GPU kép",
                    "tr_badgeSubTitle": "Một số hệ thống, chẳng hạn như những hệ thống có đồ họa Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>, có thể tận dụng cả GPU trên bộ xử lý và GPU rời để phân phối khối lượng công việc đồ họa.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU tích hợp",
                    "tr_badgeSubTitle": "GPU tích hợp được tích hợp trong cùng chip như bộ xử lý chính hoặc CPU. Loại GPU này dùng chung RAM và các tài nguyên khác như bộ phận khác của máy tính, giúp thiết bị đạt hiệu quả năng lượng tốt hơn và cho phép tạo ra thiết bị mỏng hơn, nhẹ hơn.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "GPU chuyên dụng",
                    "tr_badgeSubTitle": "Đây là dòng GPU mạnh nhất nhờ có RAM riêng cùng các tài nguyên khác, chuyên về xây dựng đồ họa đỉnh cao.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Tiêu chuẩn",
                    "tr_badgeSubTitle": "VRAM lên đến 4 GB",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Cạnh tranh",
                    "tr_badgeSubTitle": "VRAM lên đến 8 GB",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Siêu cấp",
                    "tr_badgeSubTitle": "VRAM lớn hơn 8 GB",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_title": "Sức mạnh đồ họa chuyên sâu",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Để xử lý đồ họa tốt nhất, cần có card đồ họa để có thể tập trung chuyên biệt vào việc tạo ra hình ảnh đẹp, rực rỡ và hoạt hình sống động.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_title": "Tích hợp đồ họa chi tiết và sống động",
                "tr_pretitle": "Đồ họa Intel® Iris® Plus",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Đồ họa Intel® Iris® Plus giúp bạn đắm mình trong video HDR 4K và chơi game full HD mà không cần gắn thêm card đồ họa.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_title": "Hình ảnh tuyệt vời, hiệu&nbsp;năng&nbsp;vô đối",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Đồ họa Intel® mang lại hiệu năng đồ họa tích hợp mang tính chuyển đổi để hỗ trợ thiết kế linh hoạt cho người sáng tạo và các trải nghiệm chơi game phong phú.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_title": "Đồ họa Intel® Iris® Pro",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_title": "Đồ họa UHD Intel® có cấu trúc X<sup class='small_text_sup_xe'>e</sup>",
                "tr_pretitle": "Trải nghiệm hình ảnh sống động",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Trải nghiệm biên tập ảnh và video được hợp lý hóa, thưởng thức nội dung video cao cấp ở độ phân giải 4K hoặc chơi các game phổ biến với chi tiết tuyệt đẹp.",
                "tr_photo_text": "Biên tập ảnh và video được hợp lý hóa",
                "tr_video_text": "Thưởng thức nội dung video ở độ phân giải 4K",
                "tr_game_text": "Chơi các game phổ biến với chi tiết tuyệt đẹp",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "KHÔNG CHỈ ẢNH ĐẸP",
                "tr_subtitle": "Hoạt động chặt chẽ với bộ xử lý (hoặc CPU), card đồ họa (hoặc GPU) giúp xác định cách hoạt động của game và diện mạo game. VRAM, hay RAM video, chuyên dụng cho tạo đồ họa, là cách nhanh chóng để biết GPU của bạn ở vị trí nào.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "DÀNH CHO ĐỒ HỌA GAME CHUYÊN NGHIỆP",
                "tr_subtitle": "Tác vụ xử lý đồ họa nặng nhất đòi hỏi phải có card đồ họa rời để có thể tập trung chuyên biệt vào quá trình tạo ra hình ảnh đẹp, rực rỡ và chuyển động mượt mà",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "DÀNH CHO ĐỒ HỌA GAME CHUYÊN NGHIỆP",
                "tr_subtitle": "Tác vụ xử lý đồ họa nặng nhất đòi hỏi phải có card đồ họa rời để có thể tập trung chuyên biệt vào quá trình tạo ra hình ảnh đẹp, rực rỡ và chuyển động mượt mà",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "KHÔNG CHỈ ẢNH ĐẸP",
                "tr_subtitle": "Hoạt động chặt chẽ với bộ xử lý (hoặc CPU), card đồ họa (hoặc GPU) giúp xác định cách hoạt động của game và diện mạo game. VRAM, hay RAM video, chuyên dụng cho tạo đồ họa, là cách nhanh chóng để biết GPU của bạn ở vị trí nào.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_title": "Sức mạnh đồ họa chuyên sâu",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Để xử lý đồ họa tốt nhất, cần có card đồ họa để có thể tập trung chuyên biệt vào việc tạo ra hình ảnh đẹp, rực rỡ và hoạt hình sống động.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "DÀNH CHO ĐỒ HỌA GAME CHUYÊN NGHIỆP",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "Để xử lý đồ họa tốt nhất, cần có card đồ họa để có thể tập trung chuyên biệt vào việc tạo ra hình ảnh đẹp, rực rỡ và hoạt hình sống động.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Tác vụ xử lý đồ họa nặng nhất đòi hỏi phải có card đồ họa rời để có thể tập trung chuyên biệt vào quá trình tạo ra hình ảnh đẹp, rực rỡ và chuyển động mượt mà",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Cung cấp vô số năng lực đồ họa để đáp ứng yêu cầu tối thiểu của game.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Dành cho game thủ cao cấp hơn, cần chơi game cấp độ thi đấu",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Dành cho game thủ cấp cao, còn dùng máy tính để sáng tạo nội dung chuyên sâu",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Video ấn tượng và chơi game sống động",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Sử dụng hiệu quả nhất màn hình độ phân giải cao của bạn với Đồ Họa Intel® HD và thưởng thức trải nghiệm đa phương tiện phong phú và khả năng xử lý đồ họa tích hợp.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Tăng cường sự sống động cho hình ảnh và video",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Xem video và hình ảnh truyền phát trực tiếp với chi tiết rõ nét và màu sắc rực rỡ hơn.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_title": "Chơi game và sáng tạo đáng kinh ngạc",
                "tr_pretitle": "Đồ họa Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Từ trải nghiệm hòa nhập trọn vẹn vào không gian giải trí và sáng tạo nội dung không giới hạn đến chơi game phân khúc đại trà, đồ họa Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> mang đến hiệu năng đồ họa ngoạn mục cho các máy tính mỏng và nhẹ.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_pretitle": "DÀNH CHO ĐỒ HỌA GAME CHUYÊN NGHIỆP",
                "tr_title": "Đồ họa Intel®&nbsp;Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Tác vụ xử lý đồ họa nặng nhất đòi hỏi phải có card đồ họa rời để có thể tập trung chuyên biệt vào quá trình tạo ra hình ảnh đẹp, rực rỡ và chuyển động mượt mà",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_title": "Giải phóng sức sáng tạo",
                "tr_pretitle": "Đồ họa Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Lướt qua các dự án sáng tạo và đắm mình trong nhiều loại game bằng sức mạnh tổng hợp của đồ họa trên bộ xử lý và công cụ đồ họa rời Intel® đầu tiên.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "tr_title": "Đồ họa Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "tr_pretitle": "DÀNH CHO ĐỒ HỌA GAME CHUYÊN NGHIỆP",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Tác vụ xử lý đồ họa nặng nhất đòi hỏi phải có card đồ họa rời để có thể tập trung chuyên biệt vào quá trình tạo ra hình ảnh đẹp, rực rỡ và chuyển động mượt mà",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Đồ họa",
                "tr_title": "Tích hợp đồ họa tuyệt đẹp",
                "tr_subtitle": "Chơi game, xem phim và video trên web với chi tiết và độ nét đáng kinh ngạc.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Đồ họa",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Đồ họa",
                    "tr_specText": "Tăng tốc luồng công việc của bạn"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Đồ họa",
                    "tr_specText": "Trải nghiệm bứt phá, chơi game thật đã"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Đồ họa",
                    "tr_specText": "Trải nghiệm bứt phá, chơi game thật đã"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Đồ họa",
                    "tr_specText": "Nâng tầm cuộc chơi"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "GIẢI PHÓNG SỨC SÁNG TẠO",
                    "tr_specText": "Tăng tốc năng suất của bạn bất cứ nơi đâu với các khả năng đa phương tiện toàn diện nhất, chẳng hạn như <strong>mã hóa phần cứng AV1 đầu tiên trong ngành</strong> và khả năng sáng tạo nội dung được tăng tốc nhờ AI.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "GIẢI PHÓNG SỨC SÁNG TẠO",
                    "tr_specText": "Tăng tốc năng suất của bạn với các khả năng đa phương tiện toàn diện nhất, chẳng hạn như <strong>mã hóa phần cứng AV1 đầu tiên trong ngành</strong> và khả năng sáng tạo nội dung tăng tốc nhờ AI.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "GIẢI PHÓNG SỨC SÁNG TẠO",
                    "tr_specText": "Trải nghiệm sáng tạo nội dung tiên tiến, được tăng tốc nhờ AI có <strong>mã hóa phần cứng AV1 đầu tiên trong ngành</strong> và Công cụ Đa phương tiện <strong>X<sup>e</sup> nâng cao.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "GIẢI PHÓNG SỨC SÁNG TẠO",
                    "tr_specText": "Trải nghiệm sáng tạo nội dung tiên tiến, được tăng tốc nhờ AI có <strong>mã hóa phần cứng AV1 đầu tiên trong ngành</strong> và Công cụ Đa phương tiện <strong>X<sup>e</sup> nâng cao.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "GIẢI PHÓNG SỨC SÁNG TẠO",
                    "tr_specText": "Trải nghiệm sáng tạo nội dung tiên tiến, được tăng tốc nhờ AI có <strong>mã hóa phần cứng AV1 đầu tiên trong ngành</strong> và Công cụ Đa phương tiện <strong>X<sup>e</sup> nâng cao.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "TÍNH DI ĐỘNG GIAO THOA HIỆU NĂNG",
                    "tr_specText": "Chơi các game mới nhất khi di chuyển và có được hiệu năng siêu nạp và độ trung thực với khả năng chơi game hiện đại. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Công nghệ nâng cấp được cải tiến nhờ Intel® AI để có hiệu suất cao hơn và chơi game mượt mà. <br/><br/>Hỗ trợ <strong>DirectX* 12 Ultimate:  </strong>Đưa hoạt động chơi game của bạn lên một cấp độ chân thực mới với các công nghệ đồ họa, chẳng hạn như dò tia và đổ bóng theo tỷ lệ thay đổi."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "KIỂM SOÁT CÁC GAME CỦA BẠN",
                    "tr_specText": "<strong>Trung tâm điều khiển Intel® Arc™</strong> cho phép bạn tinh chỉnh trải nghiệm của mình với bố cục trực quan và hiện đại. Nhận các bản cập nhật trình điều khiển liền mạch, tối ưu hóa game của bạn, và hơn thế."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "KIỂM SOÁT CÁC GAME CỦA BẠN",
                    "tr_specText": "<strong>Trung tâm điều khiển Intel® Arc™</strong> cho phép bạn tinh chỉnh trải nghiệm của mình với bố cục trực quan và hiện đại. Nhận các bản cập nhật trình điều khiển liền mạch, tối ưu hóa game của bạn, và hơn thế."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "KIỂM SOÁT CÁC GAME CỦA BẠN",
                    "tr_specText": "Cập nhật trình điều khiển mượt mà và tối ưu hóa nhiều game yêu thích của bạn với phần mềm <strong>Trung tâm điều khiển Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "TỐI ĐA HÓA NỀN TẢNG INTEL® CỦA BẠN",
                    "tr_specText": "Ghép nối với các bộ xử lý Intel® Core™ tương thích để giải phóng <strong>Công nghệ Intel® Deep Link</strong> nhằm nâng cao trải nghiệm sáng tạo, chơi game, và phát trực tiếp của bạn."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "TỐI ĐA HÓA HỆ THỐNG INTEL® CỦA BẠN",
                    "tr_specText": "Ghép nối với các bộ xử lý Intel® Core™ tương thích để giải phóng <strong>Công nghệ Intel® Deep Link</strong> nhằm nâng cao trải nghiệm sáng tạo, chơi game, và phát trực tiếp của bạn."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "TỐI ĐA HÓA NỀN TẢNG INTEL® CỦA BẠN",
                    "tr_specText": "Ghép nối với các bộ xử lý Intel® Core™ tương thích để giải phóng <strong>Công nghệ Intel® Deep Link</strong> nhằm nâng cao trải nghiệm sáng tạo, chơi game, và phát trực tiếp của bạn."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "TỐI ĐA HÓA NỀN TẢNG INTEL® CỦA BẠN",
                    "tr_specText": "Ghép nối với các bộ xử lý Intel® Core™ tương thích để giải phóng <strong>Công nghệ Intel® Deep Link</strong> nhằm nâng cao trải nghiệm sáng tạo, chơi game, và phát trực tiếp của bạn."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "TỐI ĐA HÓA HỆ THỐNG INTEL® CỦA BẠN",
                    "tr_specText": "Ghép nối với các bộ xử lý Intel® Core™ tương thích để giải phóng <strong>Công nghệ Intel® Deep Link</strong> nhằm nâng cao trải nghiệm sáng tạo, chơi game, và phát trực tiếp của bạn."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "CHIẾM TRỌN TÂM ĐIỂM",
                    "tr_specText": "Truyền phát liền mạch nhờ các công nghệ phát trực tiếp tiên tiến, được kích hoạt bởi phần mềm <strong>Trung tâm Điều khiển Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "CHIẾM TRỌN TÂM ĐIỂM",
                    "tr_specText": "Truyền phát liền mạch nhờ các công nghệ phát trực tiếp tiên tiến, được kích hoạt bởi phần mềm <strong>Trung tâm Điều khiển Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "CHƠI GAME SỐNG ĐỘNG NHƯ THẬT, HIỆU SUẤT CAO",
                    "tr_specText": "Đặt hiệu năng tốt nhất của bạn lên hàng đầu với các game AAA mới nhất ở các độ phân giải cao và tốc độ làm mới cao. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Công nghệ nâng cấp được cải tiến nhờ Intel® AI để có hiệu suất cao hơn và chơi game mượt mà. <br/><br/>Hỗ trợ <strong>DirectX* 12 Ultimate:  </strong>Đưa hoạt động chơi game của bạn lên một cấp độ chân thực mới với các công nghệ đồ họa, chẳng hạn như dò tia và đổ bóng theo tỷ lệ thay đổi."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "CHƠI GAME SỐNG ĐỘNG NHƯ THẬT, HIỆU SUẤT CAO",
                    "tr_specText": "Đặt hiệu năng tốt nhất của bạn lên hàng đầu với các game AAA mới nhất ở các độ phân giải cao và tốc độ làm mới cao. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Công nghệ nâng cấp được cải tiến nhờ Intel® AI để có hiệu suất cao hơn và chơi game mượt mà. <br/><br/>Hỗ trợ <strong>DirectX* 12 Ultimate:  </strong>Đưa hoạt động chơi game của bạn lên một cấp độ chân thực mới với các công nghệ đồ họa, chẳng hạn như dò tia và đổ bóng theo tỷ lệ thay đổi."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "TĂNG TỐC TRÒ CHƠI CỦA BẠN",
                    "tr_specText": "Đưa trải nghiệm chơi game của bạn lên một tầm cao mới với: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Công nghệ nâng cấp được cải tiến nhờ Intel® AI để có hiệu suất cao hơn và chơi game mượt mà. <br/><br/>Hỗ trợ <strong>DirectX* 12 Ultimate:  </strong>Đưa hoạt động chơi game của bạn lên một cấp độ chân thực mới với các công nghệ đồ họa, chẳng hạn như dò tia và đổ bóng theo tỷ lệ thay đổi."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "TRẢI NGHIỆM CHƠI GAME TỐC ĐỘ CAO TIÊN TIẾN",
                    "tr_specText": "Tận hưởng chơi game mượt mà trên nhiều game AAA phổ biến và chơi game với tốc độ làm mới cao từ các game bắn súng thể thao mạnh mẽ đến các game chiến đấu tốc độ cao. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Công nghệ nâng cấp được cải tiến nhờ Intel® AI để có hiệu suất cao hơn và chơi game mượt mà. <br/><br/>Hỗ trợ <strong>DirectX* 12 Ultimate:  </strong>Đưa hoạt động chơi game của bạn lên một cấp độ chân thực mới với các công nghệ đồ họa chẳng hạn như dò tia và đổ bóng theo tỷ lệ thay đổi."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "CHƠI GAME SỐNG ĐỘNG NHƯ THẬT, HIỆU SUẤT CAO",
                    "tr_specText": "Đặt hiệu năng tốt nhất của bạn lên hàng đầu với các game AAA mới nhất ở các độ phân giải cao và tốc độ làm mới cao. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Công nghệ nâng cấp được cải tiến nhờ Intel® AI để có hiệu suất cao hơn và chơi game mượt mà. <br/><br/>Hỗ trợ <strong>DirectX* 12 Ultimate:  </strong>Đưa hoạt động chơi game của bạn lên một cấp độ chân thực mới với các công nghệ đồ họa, chẳng hạn như dò tia và đổ bóng theo tỷ lệ thay đổi."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Đồ họa",
                    "tr_specText": "Vũ trụ đồ họa phù hợp cho mọi người"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "TĂNG TỐC ĐỘ KHUNG HÌNH, TĂNG TỐC ĐỘ CHƠI GAME",
                    "tr_specText": "Công nghệ lntel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling (X<sup class='small_text_sup_xe'>e</sup>SS) đưa hoạt động chơi game của bạn lên cấp độ tiếp theo với khả năng nâng cấp AI – cho hiệu suất cao nhất với độ trung thực hình ảnh cao. X<sup class='small_text_sup_xe'>e</sup>SS được tối ưu hóa cho các sản phẩm đồ họa Intel® Arc™ để bạn có thể tận dụng tối đa khả năng tăng tốc phần cứng Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX)."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "HÌNH ẢNH ĐỈNH CAO Ở ĐÂY",
                    "tr_specText": "Với sự hỗ trợ đầy đủ cho DirectX* 12 Ultimate trên tất cả đồ họa Intel® Arc™, bạn có thể tận hưởng lối chơi tuyệt đẹp, sống động nhờ các công nghệ đồ họa mới nhất như dò tia, đổ bóng theo tỷ lệ thay đổi, đổ bóng lưới và phản hồi của trình lấy mẫu – những yếu tố cơ bản của chơi game thế hệ tiếp theo."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "SÁNG TẠO ẢNH, VIDEO VÀ PHƯƠNG TIỆN TRUYỀN THÔNG LIỀN MẠCH",
                    "tr_specText": "Giải phóng trí tưởng tượng của bạn và thu hút khán giả bằng cách sáng tạo nội dung kỹ thuật số phong phú bằng công cụ truyền thông siêu tiên tiến — được tăng cường bởi AI và tăng tốc với công nghệ Intel®. Tạo nội dung ngoạn mục, được hỗ trợ bởi đồ họa với hỗ trợ cho tất cả các định dạng phương tiện truyền thông hàng đầu hiện nay trong khi vẫn cập nhật khả năng mã hóa video AV1 tiên tiến nhất."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "TRẢI NGHIỆM SÁNG TẠO VÀ CHƠI GAME AI TIÊN TIẾN",
                    "tr_specText": "Đồ họa Intel® Arc™ mở khóa các khả năng AI mạnh mẽ, từ sáng tạo nâng cao đến chơi game sống động. Tận dụng tối đa phần mềm sáng tạo tiên tiến hiện nay, bao gồm chuyển văn bản thành hình ảnh và chỉnh sửa video tăng tốc AI. Thêm vào đó, tận hưởng hoạt động chơi game có độ phân giải cao thông qua việc nâng cấp X<sup class='small_text_sup_xe'>e</sup>SS tăng tốc AI."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Đồ họa",
                    "tr_specText": "Đồ họa chuyên nghiệp bắt đầu tại đây"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "LỰA CHỌN TUYỆT VỜI CHO CÁC NHÀ SÁNG TẠO, NHÀ THIẾT KẾ VÀ KỸ SƯ THƯỜNG DI CHUYỂN",
                    "tr_specText": "Có sẵn trên một số bộ xử lý Intel® Core™ Ultra chuỗi H có đồ họa Intel® Arc™ Pro tích hợp mở khóa cải tiến hiệu năng đồ họa gấp 2 lần với chứng nhận Nhà cung cấp Phần mềm Độc lập (ISV) để tăng độ tin cậy, cung cấp khả năng tăng tốc phần cứng AI và dò tia hiệu quả để sáng tạo nội dung tuyệt vời khi đang di chuyển.<br/><br/><span class='expandable_heading'>Chứng nhận phần mềm<br/></span><br/>Intel đã đồng hành với hàng trăm công ty phần mềm trong nhiều năm và kinh nghiệm vô giá này đã được đưa vào dòng đồ họa Intel® Arc™ Pro chuỗi A chuyên nghiệp của chúng tôi. Chứng chỉ và chứng nhận quy trình làm việc phần mềm quan trọng đối với chúng tôi cũng như đối với bạn."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "LÀM CHO AI KHẢ DỤNG",
                    "tr_specText": "Phạm vi GPU này bao gồm nhiều lựa chọn công nghệ tuyệt vời, bao gồm hỗ trợ các công cụ AI tương thích của bạn với khả năng tăng tốc AI chuyên dụng được tích hợp sẵn khi bạn cần."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "DÒ TIA MÀ KHÔNG PHẢI TRẢ THÊM PHÍ",
                    "tr_specText": "Thẻ đồ họa cần làm nhiều hơn không chỉ đơn giản là đẩy pixel lên màn hình mà còn cần tăng tốc tạo ra hình ảnh đẹp. Công nghệ phần cứng dò tia được tích hợp trong đồ họa Intel® Arc™ Pro chuỗi A cho phép phần mềm tương thích của bạn bắt đầu khám phá các quy trình công việc chuyên nghiệp mới này. Dò tia thường được coi là chỉ có sẵn trên các loại đồ họa đắt tiền, tuy nhiên dòng đồ họa chuyên nghiệp mới nhất của Intel đã phá vỡ kỳ vọng đó."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "TRAO QUYỀN CHO NGƯỜI SÁNG TẠO VỚI MÀN HÌNH HIỂN THỊ SỐNG ĐỘNG",
                    "tr_specText": "Với sự hỗ trợ đầy đủ cho DirectX* 12 Ultimate trên tất cả đồ họa Intel® Arc™, bạn có thể tận hưởng lối chơi tuyệt đẹp, sống động nhờ các công nghệ đồ họa mới nhất như dò tia, đổ bóng theo tỷ lệ thay đổi, đổ bóng lưới và phản hồi của trình lấy mẫu – những yếu tố cơ bản của chơi game thế hệ tiếp theo."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "HÌNH ẢNH ĐƯỢC TỐI ƯU HÓA ĐỘNG",
                    "tr_specText": "Với tính năng giải mã phần cứng chuyên dụng trên GPU Pro, Dolby Vision* giúp tăng thêm độ sâu, độ tương phản sắc nét hơn và nhiều màu sắc hơn cho màn hình hiển thị tương thích của bạn. Dolby Vision* khai thác toàn bộ tiềm năng của công nghệ HDR bằng cách tự động tối ưu hóa chất lượng hình ảnh dựa trên dịch vụ, thiết bị hiển thị và nền tảng của bạn để mang đến hình ảnh tuyệt đẹp mọi lúc."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "PHẦN MỀM ĐÃ ĐƯỢC CHUYÊN GIA XÁC THỰC",
                    "tr_specText": "Intel đã đồng hành với hàng trăm công ty phần mềm trong nhiều năm và kinh nghiệm vô giá này đã được đưa vào dòng đồ họa Intel® Arc™ Pro chuỗi A chuyên nghiệp của chúng tôi. Chứng chỉ và chứng nhận quy trình làm việc phần mềm quan trọng đối với chúng tôi cũng như đối với bạn."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "DÒ TIA MÀ KHÔNG PHẢI TRẢ THÊM PHÍ",
                    "tr_specText": "Thẻ đồ họa cần làm nhiều hơn không chỉ đơn giản là đẩy pixel lên màn hình mà còn cần tăng tốc tạo ra hình ảnh đẹp. Công nghệ phần cứng dò tia được tích hợp trong đồ họa Intel® Arc™ Pro chuỗi A cho phép phần mềm tương thích của bạn bắt đầu khám phá các quy trình công việc chuyên nghiệp mới này. Dò tia thường được coi là chỉ có sẵn trên các loại đồ họa đắt tiền, tuy nhiên dòng đồ họa chuyên nghiệp mới nhất của Intel đã phá vỡ kỳ vọng đó."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "NHIỀU MÀN HÌNH LỚN VỚI MỘT GPU DI ĐỘNG NHỎ",
                    "tr_specText": "Đồ họa Intel® Arc™ Pro dành cho máy tính xách tay có thể hỗ trợ tối đa bốn màn hình cực lớn để nâng cao hiệu quả quy trình làm việc của bạn. Thêm hỗ trợ dải tương phản rộng (HDR), tất cả những gì bạn cần để thiết lập đa màn hình cho việc sáng tạo nội dung truyền thông mạng xã hội thế hệ tiếp theo."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "TIỀM NĂNG BĂNG THÔNG BỘ NHỚ ĐẦY ĐỦ",
                    "tr_specText": "Băng thông thẻ đồ họa của bạn là yếu tố quan trọng cần cân nhắc trong máy trạm. Hiểu sai và bạn có thể làm chậm quy trình làm việc chuyên nghiệp, đó là lý do tại sao chúng tôi đảm bảo GPU Intel® Arc™ Pro chuỗi A có hỗ trợ cho PCIe* 4.0 hiện đại mà không ảnh hưởng đến khả năng tương thích ngược. Bộ nhớ đồ họa được tăng cường hơn nữa nhờ tốc độ băng thông cao, cho phép phần mềm của bạn truy cập dữ liệu dự án nhanh hơn nữa."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "THỔI HỒN VÀO MỌI KHUNG HÌNH",
                    "tr_specText": "<span class='expandable_heading'>Được AI tăng cường. Được hỗ trợ bởi Intel.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS là công nghệ nâng cấp được tăng cường bởi AI giúp tăng hiệu năng của bạn mà không làm mất đi vẻ đẹp của mọi khung hình — vì vậy bạn có thể trải nghiệm hoạt động chơi game của mình theo cách vốn có."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "THỜI GIAN THỰC.<br/> THỰC SỐNG ĐỘNG.<br/> CHÂN THỰC ĐỈNH CAO.",
                    "tr_specText": "Đa thế giới đầy chân thật đang chờ bạn. Nâng tầm trải nghiệm từ bóng đổ, hình ảnh phản xạ và ánh sáng bậc thầy nhờ tính năng dò tia tích hợp. Một trải nghiệm chơi game trực quan mà bạn không muốn bỏ lỡ."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "CHIA SẺ MÀN CHƠI ĐỈNH Ở CHẤT LƯỢNG TOP",
                    "tr_specText": "Trình diễn cho khán giả của bạn lối chơi mượt mà, đỉnh cao họ xứng đáng được xem. Lôi cuốn khán giả qua từng hành động với khả năng mã hóa AV1 được tăng tốc phần cứng của đồ họa Intel® Arc™. Chất lượng hình ảnh cao hơn ở cùng một tốc độ bit đồng nghĩa với việc phát trực tiếp tốt nhất mà vẫn tiết kiệm băng thông."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Chào mừng tất cả những người sáng tạo",
                    "tr_specText": "Trải nghiệm AI mới và nâng cao có sẵn ngay trong tầm tay bạn. Với bộ gia tốc AI tiên tiến được nhúng trên đồ họa Intel® Arc™ chuỗi A, hãy dành nhiều thời gian hơn để sáng tạo và ít thời gian chờ đợi hơn trên nhiều ứng dụng.<br/><br/>Đưa video của bạn lên cấp độ tiếp theo và dẫn đầu với video AV1 chất lượng cao, tiết kiệm dung lượng lưu trữ, được hỗ trợ bởi các khả năng phần cứng AV1 tiên tiến được tích hợp trong đồ họa Intel® Arc™."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Hệ điều hành",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Mọi thứ bạn cần để hoàn thành công việc",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Hiệu suất mạnh mẽ trong thiết bị mỏng, nhẹ và siêu bền - tất cả trong tầm tay của bạn. Với tuổi thọ pin lâu bền, màn hình cảm ứng tuyệt đẹp* và Ổ đĩa bán dẫn mới nhất (SSD), bạn sẽ tìm thấy những thứ mình cần trên PC chạy Windows 10.",
                "tr_noteSection": "*Phụ thuộc phần cứng.",
                "titleArray": [
                  {
                    "tr_title": "Ít hơn có nghĩa là nhiều hơn",
                    "tr_subtitle": "Ngày nay, PC thường dùng ít điện năng hơn, nên bạn có thể dùng pin lâu hơn - trung bình là hơn 8 giờ phát video.**",
                    "tr_noteSection": "**Tuổi thọ pin khác nhau đáng kể tùy theo thiết bị, cài đặt, mức sử dụng và các yếu tố khác."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Hệ điều hành",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "MỌI THỨ BẠN CẦN ĐỂ HOÀN THÀNH CÔNG VIỆC",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Hiệu năng mạnh mẽ với thiết kế mỏng, nhẹ và bền vững — bạn có thể sở hữu tất cả. Với thời lượng pin tuyệt vời, màn hình cảm ứng đẹp* và Ổ đĩa thể rắn (SSD) mới nhất, bạn sẽ tìm thấy những gì mình đang tìm kiếm trong chiếc máy tính Windows* 10.",
                "tr_noteSection": "*Phụ thuộc phần cứng",
                "titleArray": [
                  {
                    "tr_title": "ÍT HƠN CÓ NGHĨA LÀ NHIỀU HƠN",
                    "tr_subtitle": "Ngày nay, PC thường dùng ít điện năng hơn, nên bạn có thể dùng pin lâu hơn - trung bình là hơn 8 giờ phát video.**",
                    "tr_noteSection": "**Tuổi thọ pin khác nhau đáng kể tùy theo thiết bị, cài đặt, mức sử dụng và các yếu tố khác."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Hệ điều hành",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Hợp lý hóa để đảm bảo tính an toàn và hiệu năng",
                "tr_pretitle": "Windows* 10 trong chế độ S",
                "tr_subtitle": "Khi chạy Windows* 10 ở chế độ S mode, bạn sẽ có thêm một lớp bảo vệ, cho phép bạn làm việc, vui chơi và khám phá mà vẫn tự tin rằng thiết bị của bạn được bảo mật."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Hệ điều hành",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "HỢP LÝ HÓA ĐỂ BẢO ĐẢM TÍNH AN TOÀN VÀ HIỆU NĂNG",
                "tr_title": "Windows* 10 trong chế độ S",
                "tr_subtitle": "Khi chạy Windows* 10 ở chế độ S mode, bạn sẽ có thêm một lớp bảo vệ, cho phép bạn làm việc, vui chơi và khám phá mà vẫn tự tin rằng thiết bị của bạn được bảo mật."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Hệ điều hành",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Thực hiện những điều tuyệt vời",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Hiệu năng, giá cả và vô số các lựa chọn. Từ thiết bị 2 trong 1 sáng tạo đến máy tính xách tay mỏng và nhẹ, màn hình cảm ứng, bàn phím rời - dù ngân sách hay phong cách là gì đi nữa, bạn đều có thể tìm được máy tính Windows* phù hợp cho mình."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Hệ điều hành",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "THỰC HIỆN NHỮNG ĐIỀU TUYỆT VỜI",
                "tr_title": "Windows*",
                "tr_subtitle": "Hiệu năng, giá cả và vô số các lựa chọn. Từ thiết bị 2 trong 1 sáng tạo đến máy tính xách tay mỏng và nhẹ, màn hình cảm ứng, bàn phím rời - dù ngân sách hay phong cách là gì đi nữa, bạn đều có thể tìm được máy tính Windows* phù hợp cho mình."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Hệ điều hành",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Hệ điều hành",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ nhớ",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "BẠN CẦN BAO NHIÊU RAM?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Hàng ngày - 2 GB ĐẾN TỐI ĐA 8 GB RAM",
                    "tr_badgeSubTitle": "Lý tưởng cho những người chủ yếu dùng máy tính để xử lý văn bản và lướt web, cũng như có xu hướng tập trung vào một chương trình mỗi lần."
                  },
                  {
                    "tr_badgeTitle": "Mạnh mẽ - 8 GB ĐẾN TỐI ĐA 16 GB RAM",
                    "tr_badgeSubTitle": "Với nhiều RAM hơn, các ứng dụng của bạn sẽ chạy nhanh hơn và bạn cũng có thể chạy đồng thời nhiều ứng dụng hơn."
                  },
                  {
                    "tr_badgeTitle": "Nâng cao - HƠN 16 GB RAM ",
                    "tr_badgeSubTitle": "Nếu bạn quan tâm đến chơi game, biên tập video hoặc ảnh, hay dựng hình 3D, thì bạn sẽ cần thêm RAM để xử lý các chương trình chuyên sâu này."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "BỘ NHỚ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "RAM [XX] GB để đa nhiệm hàng ngày",
                "tr_subtitle": "Bộ nhớ của bạn sẽ cho phép tải và chạy mượt mà các ứng dụng cơ bản như xử lý văn bản và duyệt web.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "BỘ NHỚ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "RAM [XX] GB để đa nhiệm cường độ cao",
                "tr_subtitle": "Nếu bạn sử dụng đồng thời nhiều chương trình, bộ nhớ [memory_total] GB sẽ giúp bạn chuyển đổi qua lại giữa các chương trình dễ dàng hơn.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "BỘ NHỚ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "RAM [XX] GB để đa nhiệm tăng cường",
                "tr_subtitle": "Với dung lượng bộ nhớ rất lớn, bạn có thể chạy đồng thời nhiều chương trình mà không làm chậm máy tính của mình.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "BỘ NHỚ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "Bộ nhớ [Value1] GB = Bộ nhớ Intel® Optane™ [Value2] GB + RAM [Value3] GB",
                "tr_subtitle": "Sự kết hợp của các giải pháp bộ nhớ này giúp bạn đạt được hiệu năng ổ đĩa cứng nhanh hơn từ bộ nhớ Intel® Optane™ cùng với tốc độ phản hồi cao hơn từ RAM.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>BỘ NHỚ</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ nhớ Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Chờ đợi ít hơn. Tận hưởng thêm nội dung yêu thích.",
                "tr_subtitle": "Khi bộ nhớ Intel® Optane™ kết hợp với bộ nhớ hệ thống, bạn có thể truy cập nhanh hơn vào các chương trình thường dùng của mình để khởi chạy và tải ngay lập tức khi bạn cần.",
                "tr_pretitle": "Bộ nhớ Intel® Optane™ [XX] GB",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "BỘ NHỚ LÀM GÌ CHO PC CỦA TÔI?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Sáng tạo nội dung nhanh hơn",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Chơi game với tốc độ phản hồi nhanh hơn",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Xử lý đa nhiệm dễ dàng hơn",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Mở các chương trình nhanh hơn",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ nhớ Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Tăng tốc chơi game</span>",
                "tr_pretitle": "Bộ nhớ Intel® Optane™ [XX] GB",
                "tr_pretitle1": "Bộ nhớ Intel® Optane™ [XX] GB giúp tăng tốc độ chơi game của bạn",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Khởi chạy game nhanh hơn đến <br/><span class=\"optane-game\">98%</span> <br/>Từ ổ dữ liệu với bộ xử lý [optane_legal_processor_name] trang bị bộ nhớ Intel® Optane™<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Khả năng tải nhanh hơn đến <br/><span class=\"optane-game\">4,1 lần</span> <br/>Từ ổ dữ liệu với bộ xử lý [optane_legal_processor_name] trang bị bộ nhớ Intel® Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Bộ nhớ Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Tăng tốc chơi game</span>",
                "tr_pretitle": "Bộ nhớ Intel® Optane™ [XX] GB",
                "tr_pretitle1": "Bộ nhớ Intel® Optane™ [XX] GB giúp tăng tốc độ chơi game của bạn",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Khởi chạy game nhanh hơn đến <br/><span class=\"optane-game\">98%</span> <br/>Từ ổ dữ liệu với bộ xử lý [optane_legal_processor_name] trang bị bộ nhớ Intel® Optane™<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Khả năng tải nhanh hơn đến <br/><span class=\"optane-game\">4,1 lần</span> <br/>Từ ổ dữ liệu với bộ xử lý [optane_legal_processor_name] trang bị bộ nhớ Intel® Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "BỘ NHỚ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Bộ nhớ [Value1] GB = Bộ nhớ Intel® Optane™ [Value2] GB + RAM [Value3] GB",
                "tr_subtitle": "Sự kết hợp của các giải pháp bộ nhớ này giúp bạn đạt được hiệu năng ổ đĩa cứng nhanh hơn từ bộ nhớ Intel® Optane™ cùng với tốc độ phản hồi cao hơn từ RAM.",
                "titleArray": [
                  {
                    "tr_title": "RAM [XX] GB để&nbsp;đa&nbsp;nhiệm",
                    "tr_subtitle": "Nếu bạn sử dụng đồng thời nhiều chương trình, bộ nhớ [memory_total] GB sẽ giúp bạn chuyển đổi qua lại giữa các chương trình dễ dàng hơn."
                  },
                  {
                    "tr_title": "Để xử lý các tác vụ hàng ngày",
                    "tr_subtitle": "Bộ nhớ của bạn sẽ cho phép tải và chạy mượt mà các ứng dụng cơ bản như xử lý văn bản và duyệt web."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "BỘ NHỚ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "RAM [XX] GB",
                "tr_pretitle": "ĐỂ ĐA NHIỆM TỐT HƠN",
                "tr_subtitle": "RAM giúp bộ xử lý của bạn truy cập dễ dàng hơn vào tệp chương trình, cho phép CPU truy cập nhanh hơn và xử lý nhiều tác vụ cùng một lúc.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Bộ nhớ tiêu chuẩn",
                    "tr_badgeSubTitle": "RAM lên đến 8 GB"
                  },
                  {
                    "tr_badgeTitle": "Bộ nhớ nâng cao",
                    "tr_badgeSubTitle": "RAM hơn 8 GB và lên đến 16 GB"
                  },
                  {
                    "tr_badgeTitle": "Bộ nhớ cao cấp",
                    "tr_badgeSubTitle": "RAM lớn hơn&nbsp;16&nbsp;GB"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "BỘ NHỚ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB để thực hiện đa nhiệm nhiều hơn",
                "tr_pretitle": "ĐỂ ĐA NHIỆM TỐT HƠN",
                "tr_subtitle": "RAM giúp bộ xử lý của bạn truy cập dễ dàng hơn vào tệp chương trình, cho phép CPU truy cập nhanh hơn và xử lý nhiều tác vụ cùng một lúc.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Bộ nhớ tiêu chuẩn",
                    "tr_badgeSubTitle": "RAM lên đến 8 GB"
                  },
                  {
                    "tr_badgeTitle": "Bộ nhớ nâng cao",
                    "tr_badgeSubTitle": "RAM hơn 8 GB và lên đến 16 GB"
                  },
                  {
                    "tr_badgeTitle": "Bộ nhớ cao cấp",
                    "tr_badgeSubTitle": "RAM lớn hơn&nbsp;16&nbsp;GB"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ NHỚ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Tiêu chuẩn",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Nâng cao",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Ưu tú",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ nhớ",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "CÁCH SO SÁNH BỘ NHỚ NÀY?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "CÁCH SO SÁNH BỘ NHỚ NÀY?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Thiết bị hiện&nbsp;tại",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Hàng ngày",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Mạnh mẽ",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Nâng cao",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bộ lưu trữ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "MÁY TÍNH CỦA BẠN LƯU TRỮ ĐƯỢC BAO NHIÊU?",
                "tr_subtitle": "Bạn không cần phải đoán đâu. Chúng tôi sẽ cho bạn thấy chính xác những gì bạn có thể lưu trữ trên máy tính này.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Tạo thư viện</span> <br/><span class=\"storage-standard\">[num_songs] bài hát<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>dựa trên dung lượng trung bình 5 MB mỗi bài hát",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Tạo thư viện</span><br/><span class=\"storage-standard\">[num_games] trò chơi<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>dựa trên dung lượng trung bình là 40 GB mỗi game",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Lưu trữ</span><br/><span class=\"storage-standard\">[num_videos] video<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>dựa trên dung lượng trung bình 5 GB mỗi video",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Chụp và lưu trữ</span><br/><span class=\"storage-standard\">[num_photos] ảnh<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>dựa trên dung lượng trung bình 4 MB mỗi ảnh",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "HDD [capacity] GB"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "SSD [capacity] GB"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "Bộ nhớ Intel® Optane™ [total_optane_memory] GB + SSD [convertedSSD] GB"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LƯU TRỮ",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "Bộ lưu trữ SSD [capacity] GB",
                "tr_preTitle1": "Bộ lưu trữ SSD [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Máy tính này có ổ đĩa thể rắn với dung lượng lưu trữ [capacity] GB. Điều đó có nghĩa là bạn sẽ có thể lưu ảnh, video và chương trình với số lượng vô cùng lớn, đồng thời việc truy cập các nội dung này sẽ nhanh hơn nhiều so với ổ đĩa cứng.",
                    "tr_title": "Nơi xử lý mọi tác vụ trên máy tính của bạn"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Máy tính này có ổ đĩa thể rắn với dung lượng lưu trữ [capacity] TB. Điều đó có nghĩa là bạn sẽ có thể lưu ảnh, video và chương trình với số lượng vô cùng lớn, đồng thời việc truy cập các nội dung này sẽ nhanh hơn nhiều so với ổ đĩa cứng.",
                    "tr_title": "Thiết bị này có khả&nbsp;năng&nbsp;lưu&nbsp;trữ ấn&nbsp;tượng"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>BỘ LƯU TRỮ</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>BỘ LƯU TRỮ</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LƯU TRỮ",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "Bộ lưu trữ HDD [capacity] GB",
                "tr_preTitle1": "Bộ lưu trữ HDD [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Ổ đĩa cứng là lựa chọn phù hợp cho những ai muốn có bộ lưu trữ dung lượng lớn trực tiếp trên máy tính.",
                    "tr_title": "Nơi xử lý mọi tác vụ trên máy tính của bạn"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Ổ đĩa cứng là lựa chọn phù hợp cho những ai muốn có bộ lưu trữ dung lượng lớn trực tiếp trên máy tính.",
                    "tr_title": "Thiết bị này có khả&nbsp;năng&nbsp;lưu&nbsp;trữ ấn&nbsp;tượng"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>BỘ LƯU TRỮ</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LƯU TRỮ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "MÁY TÍNH CỦA BẠN LƯU TRỮ ĐƯỢC BAO NHIÊU?",
                "tr_subtitle": "Bạn không cần phải đoán đâu. Chúng tôi sẽ cho bạn thấy chính xác những gì bạn có thể lưu trữ trên máy tính này.",
                "titleArray": [
                  {
                    "tr_title": "HDD [XX] GB"
                  },
                  {
                    "tr_title": "SSD [XX] GB"
                  },
                  {
                    "tr_title": "Ổ đĩa thể rắn Intel® [total_capacity] GB được tăng tốc nhờ bộ nhớ Intel® Optane™ [optane_memory_size] GB"
                  },
                  {
                    "tr_title": "Ổ đĩa thể rắn Intel® SSD [total_capacity] TB được tăng tốc bằng bộ nhớ Intel® Optane™ [optane_memory_size] GB"
                  },
                  {
                    "tr_title": "SSD [convertedSSD] GB + HDD&nbsp;[convertedHDD]&nbsp;GB"
                  },
                  {
                    "tr_title": "SSD [convertedSSD] TB + HDD&nbsp;[convertedHDD]&nbsp;GB"
                  },
                  {
                    "tr_title": "SSD [convertedSSD] GB + HDD&nbsp;[convertedHDD]&nbsp;TB"
                  },
                  {
                    "tr_title": "SSD [convertedSSD] TB + HDD&nbsp;[convertedHDD]&nbsp;TB"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>BỘ LƯU TRỮ</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>BỘ LƯU TRỮ</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>BỘ LƯU TRỮ</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>BỘ LƯU TRỮ</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>BỘ LƯU TRỮ</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>BỘ LƯU TRỮ</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LƯU TRỮ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "Bộ lưu trữ SSD [convertedSSD] GB + HDD [convertedHDD]&nbsp;GB",
                "tr_preTitle1": "Bộ lưu trữ SSD [convertedSSD] TB + HDD [convertedHDD]&nbsp;GB",
                "tr_preTitle2": "Bộ lưu trữ SSD [convertedSSD] GB + HDD [convertedHDD]&nbsp;TB",
                "tr_preTitle3": "Bộ lưu trữ SSD [convertedSSD] TB + HDD [convertedHDD]&nbsp;TB",
                "titleArray": [
                  {
                    "tr_title": "Nơi xử lý mọi tác vụ trên máy tính của bạn",
                    "tr_title1": "Thiết bị này có khả&nbsp;năng&nbsp;lưu&nbsp;trữ ấn&nbsp;tượng",
                    "tr_subtitle": "Chiếc máy tính này trang bị ổ đĩa thể rắn với dung lượng lưu trữ [convertedSSD] GB và một ổ đĩa cứng [convertedHDD] GB. Nhờ đó, bạn sẽ có cả dung lượng khổng lồ cùng với hiệu năng lưu trữ nhanh chóng.",
                    "tr_subtitle1": "Chiếc máy tính này trang bị ổ đĩa thể rắn với dung lượng lưu trữ [convertedSSD] TB và một ổ đĩa cứng [convertedHDD] GB. Nhờ đó, bạn sẽ có cả dung lượng khổng lồ cùng với hiệu năng lưu trữ nhanh chóng.",
                    "tr_subtitle2": "Chiếc máy tính này trang bị ổ đĩa thể rắn với dung lượng lưu trữ [convertedSSD] GB và một ổ đĩa cứng [convertedHDD] TB. Nhờ đó, bạn sẽ có cả dung lượng khổng lồ cùng với hiệu năng lưu trữ nhanh chóng.",
                    "tr_subtitle3": "Chiếc máy tính này trang bị ổ đĩa thể rắn với dung lượng lưu trữ [convertedSSD] TB và một ổ đĩa cứng [convertedHDD] TB. Nhờ đó, bạn sẽ có cả dung lượng khổng lồ cùng với hiệu năng lưu trữ nhanh chóng."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>BỘ LƯU TRỮ</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>BỘ LƯU TRỮ</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LƯU TRỮ",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Đạt khả năng truy cập tốc độ cao đến bộ lưu trữ dung lượng lớn với bộ nhớ Intel® Optane™ sử dụng ổ SSD.",
                "tr_title": "Chấp cả những tệp khổng lồ",
                "tr_pretitle": "Ổ đĩa thể rắn Intel® [total_capacity] GB được tăng tốc nhờ bộ nhớ Intel® Optane™ [optane_memory_size] GB",
                "tr_pretitle1": "Ổ đĩa thể rắn Intel® SSD [total_capacity] TB được tăng tốc bằng bộ nhớ Intel® Optane™ [optane_memory_size] GB",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Giải pháp lưu trữ đột phá này là giải pháp đầu tiên kết hợp bộ nhớ Intel® Optane™ và ổ SSD trên một con chip. Dưới đây là những gì bộ nhớ này có thể làm cho bạn:",
                    "tr_title": "BỘ NHỚ INTEL® OPTANE™ SỬ DỤNG Ổ SSD LÀ GÌ?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Mở nhanh những nội dung bạn cần",
                    "tr_badgeSubTitle": "Bằng cách tải sẵn các tệp tin và chương trình thường xuyên sử dụng, bạn có thể dành nhiều thời gian hơn để sáng tạo nội dung và ít thời gian chờ đợi hơn.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Bộ lưu trữ và tốc độ",
                    "tr_badgeSubTitle": "Bạn sẽ có đủ không gian lưu trữ cần thiết cho các tệp phương tiện truyền thông với hiệu năng nhanh chóng của ổ SSD.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Độ phản hồi tốt hơn theo thời gian",
                    "tr_badgeSubTitle": "Bộ nhớ Intel® Optane™ có ổ cứng SSD học cách làm việc của bạn, khiến máy tính của bạn hoạt động hiệu quả hơn.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "LƯU TRỮ",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Lưu giữ mọi tập tin thật tiện dụng với dung lượng lưu trữ lớn và tin cậy.",
                "tr_title": "Không gian cho mọi hình ảnh, video và bài hát của bạn"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Màn hình",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] triệu điểm ảnh để chạm vuốt, thu phóng và gây ấn tượng",
                "tr_subtitle": "Tương tác trên máy tính giống như trên điện thoại thông minh với các điều khiển trực quan và chi tiết sắc nét hơn, sống động hơn.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Độ phân giải: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Số pixel: [screenResolution] triệu"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Màn hình [screenDiagonal] inch",
                    "tr_badgeSubTitle": "Chiều cao màn hình: [screenHeight] inch",
                    "tr_badgePreTitle": "Chiều rộng màn hình: [YY] inch"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Màn hình cảm ứng",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Màn hình",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Đắm chìm trong [screenResolution]&nbsp;triệu điểm ảnh",
                "tr_subtitle": "Tất cả đều quy về điểm ảnh (pixel). Càng nhiều pixel thì hình ảnh của bạn càng sắc nét và sống động hơn.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Độ phân giải: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Số pixel: [screenResolution] triệu"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Màn hình [screenDiagonal] inch",
                    "tr_badgeSubTitle": "Chiều cao màn hình: [screenHeight] inch",
                    "tr_badgePreTitle": "Chiều rộng màn hình: [YY] inch"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Màn hình",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] inch <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "CÁNH CỬA ĐẾN THẾ GIỚI GAME CỦA BẠN",
                "tr_subtitle": "Cũng như TV, mối quan tâm chính của bạn cho màn hình chơi game sẽ là kích thước và độ phân giải.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Độ phân giải: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Số pixel: [screenResolution] triệu"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Màn hình [screenDiagonal] inch",
                    "tr_badgeSubTitle": "Chiều cao màn hình: [screenHeight] inch",
                    "tr_badgePreTitle": "Chiều rộng màn hình: [screenWidth] inch"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Màn hình cảm ứng"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Màn hình",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Thiết bị này có màn hình hiển thị ấn tượng",
                "tr_subtitle": "Với số điểm ảnh HD nhiều hơn gấp tám lần, các video và game của bạn sẽ có độ sắc nét và màu sắc ở cấp độ hoàn toàn mới.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Độ phân giải: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Số pixel: [screenResolution] triệu"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Màn hình [screenDiagonal] inch",
                    "tr_badgeSubTitle": "Chiều cao màn hình: [screenHeight] inch",
                    "tr_badgePreTitle": "Chiều rộng màn hình: [YY] inch"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Màn hình",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] triệu điểm ảnh để chạm và vuốt",
                    "tr_subTitle": "Xem và tương tác với các nội dung bạn thích với chi tiết sắc nét hơn, sống động hơn."
                  },
                  {
                    "tr_title": "[screenResolution] triệu điểm ảnh để nâng tầm thế giới số của bạn",
                    "tr_subTitle": "Tất cả đều quy về điểm ảnh (pixel). Càng nhiều pixel thì hình ảnh của bạn càng sắc nét và sống động hơn."
                  },
                  {
                    "tr_title": "Thiết bị này có màn hình hiển thị ấn tượng",
                    "tr_subTitle": "Với số lượng pixel gấp gần bốn lần HD, độ sắc nét và màu sắc của video và game sẽ được nâng lên một tầm cao mới."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Khả năng kết&nbsp;nối",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Công nghệ Thunderbolt™ 3 có kết nối nhanh nhất, linh hoạt nhất đối với mọi đế cắm, màn hình hoặc thiết bị dữ liệu.",
                "tr_title": "Một cổng cho tất cả",
                "tr_title1": "MỘT CỔNG CHO TẤT CẢ",
                "titleArray": [
                  {
                    "tr_title": "LÀM THẾ NÀO ĐỂ TÔI CÓ THỂ SỬ DỤNG CÔNG NGHỆ THUNDERBOLT™ 3?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Công nghệ Thunderbolt™ 3"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "Màn hình 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Kết nối với các màn hình hiển thị có độ phân giải, độ tương phản và màu sắc sống động"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Card đồ họa rời",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Nâng cấp ngay khả năng xử lý đồ họa của mọi máy tính"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Nối mạng nhanh",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Giúp kết nối ngang hàng nhanh"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Khả năng kết&nbsp;nối",
                "detailBGUrl": "",
                "tr_subtitle": "Công nghệ Thunderbolt™ 3 truyền dữ liệu ở tốc độ cao hơn các USB thông thường, cho phép bạn di chuyển tệp nhanh và dễ dàng tích hợp với các thiết bị ngoại vi, như nhiều màn hình hoặc card đồ họa rời.",
                "tr_title": "Công nghệ Thunderbolt™ 3",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "DỮ LIỆU TRUYỀN SIÊU NHANH",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Công nghệ Thunderbolt™ 3",
                    "tr_badgeSubTitle": "40 Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Khả năng kết&nbsp;nối",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Một cổng và cáp đa năng hỗ trợ tất cả các phụ kiện yêu thích của bạn</li> <li>Sạc máy tính xách tay đáng tin cậy</li> <li>Chứng nhận mạnh mẽ mang lại sự an tâm</li></ul>",
                "tr_title": "Kết nối cáp thực sự đa năng",
                "tr_title1": "KẾT NỐI CÁP THỰC SỰ ĐA NĂNG",
                "tr_noteSection": "<sup>†</sup>Dựa trên các yêu cầu đặc điểm kỹ thuật tối thiểu",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "TÔI CÓ THỂ SỬ DỤNG CÔNG NGHỆ THUNDERBOLT™ 4 NHƯ THẾ NÀO?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Công nghệ Thunderbolt™ 4"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Sáng tạo",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Kết nối màn hình ngoài và lưu trữ nhanh để xem và chỉnh sửa ảnh và video."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Đế cắm",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Sắp đặt một chiếc bàn làm việc đơn giản, gọn gàng bằng cách kết nối máy tính xách tay với tất cả các phụ kiện của bạn với một cáp duy nhất."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Game",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Bổ sung thêm dung lượng ổ SSD và HDD với tốc độ lên đến 3.000 MB/giây để lưu trữ thư viện game và các tập tin đa phương tiện."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Hiệu năng tối đa<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Nhanh hơn 4 lần so với USB* 3.2 <br/>Nhanh hơn 2 lần so với HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Khả năng kết&nbsp;nối",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Tận hưởng hiệu suất tuyệt vời của Công nghệ tương tự trên Thunderbolt™ 3 với nhiều khả năng hơn nữa, cho phép bạn dễ dàng kết nối và sử dụng đế cắm Thunderbolt™ mới nhất cũng như màn hình, bộ lưu trữ nhanh hoặc bất kỳ phụ kiện USB* nào.",
                "tr_title": "Công nghệ Thunderbolt™ 4",
                "tr_pretitle": "KẾT NỐI CÁP THỰC SỰ ĐA NĂNG",
                "tr_noteSection": "<sup>†</sup>Dựa trên các yêu cầu đặc điểm kỹ thuật tối thiểu",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Một cáp đa năng Thunderbolt™ 4 có thể thay thế tất cả cáp USB-C* của bạn, khiến việc lựa chọn cáp trở nên nhanh chóng và dễ dàng."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Đế cắm Công nghệ Thunderbolt™ 4 cung cấp tối đa bốn cổng Thunderbolt™ để kết nối linh hoạt hơn với bất kỳ phụ kiện nào của bạn."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Hiệu năng tối đa<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Nhanh hơn 4 lần so với USB* 3.2 <br/>Nhanh hơn 2 lần so với HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Khả năng kết&nbsp;nối",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Một cổng và cáp đa năng hỗ trợ tất cả các phụ kiện yêu thích của bạn</li> <li>Sạc máy tính xách tay đáng tin cậy</li> <li>Chứng nhận mạnh mẽ mang lại sự an tâm</li></ul>",
                "tr_title": "Tối đa hóa trải nghiệm của bạn với các cổng kết nối Thunderbolt™",
                "tr_noteSection": "<sup>†</sup>Dựa trên các yêu cầu đặc điểm kỹ thuật tối thiểu",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "TÔI CÓ THỂ SỬ DỤNG CÔNG NGHỆ THUNDERBOLT™ 4 NHƯ THẾ NÀO?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Sáng tạo",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Kết nối màn hình ngoài và lưu trữ nhanh để xem và chỉnh sửa ảnh và video."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Đế cắm",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Sắp đặt một chiếc bàn làm việc đơn giản, gọn gàng bằng cách kết nối máy tính xách tay với tất cả các phụ kiện của bạn với một cáp duy nhất."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Game",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Bổ sung thêm dung lượng ổ SSD và HDD với tốc độ lên đến 3.000 MB/giây để lưu trữ thư viện game và các tập tin đa phương tiện."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Hiệu năng tối đa<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Nhanh hơn 4 lần so với USB* 3.2 <br/>Nhanh hơn 2 lần so với HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "Các loại Wi‑Fi phổ biến nhất là:",
                "tr_title": "TÌM HIỂU CÁC TIÊU CHUẨN WI‑FI",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 cho phép tốc độ cao hơn ngay cả khi nhiều người dùng đang sử dụng cùng một tín hiệu.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Với cải tiến Wi-Fi mới nhất của Intel, bạn có thể đạt được tốc độ nhanh hơn so với Wi-Fi gigabit, kể cả khi có nhiều người dùng sử dụng cùng một tín hiệu.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Là chuyển biến lớn nhất của Wi‑Fi trong vòng 20 năm, Wi‑Fi 6E mở ra các kênh tốc độ cao mới để cải thiện hiệu suất và độ tin cậy cũng như giảm nhiễu."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 cho phép tốc độ cao hơn ngay cả khi nhiều người dùng đang sử dụng cùng một tín hiệu.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Toàn bộ lợi ích của Wi-Fi 6E được kết hợp với Công cụ Ưu tiên Intel® Killer™ nhằm chuyển băng thông đến các trang web và ứng dụng cần nó nhất."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Hiệu năng và độ tin cậy sẽ được cải thiện do ít cạnh tranh hơn trên các kênh tốc độ cao."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 cho phép tốc độ cao hơn ngay cả khi nhiều người dùng đang sử dụng cùng một tín hiệu.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Với độ nhiễu giảm nhờ Wi-Fi 6 và hiệu năng thông minh của Công cụ Ưu tiên Intel® Killer™, trải nghiệm trực tuyến của bạn sẽ trở nên nhanh và nhạy hơn.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Hiệu năng và độ tin cậy sẽ được cải thiện do ít cạnh tranh hơn trên các kênh tốc độ cao."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Tốc độ Wi-Fi máy tính cá nhân lớn hơn 5 Gbps với độ trễ cực thấp nhất quán – chia sẻ tệp trong vài giây thay vì vài phút."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) là công nghệ mới nhất mang lại độ tin cậy \"Cực cấp\", độ trễ thấp và tốc độ thông qua tối ưu hóa mạng dựa trên AI."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 cho phép tốc độ cao hơn ngay cả khi nhiều người dùng đang sử dụng cùng một tín hiệu.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) đạt tới tốc độ lớn hơn 1 Gbps, với độ tin cậy cao hơn và độ trễ thấp hơn, sử dụng kênh 6 GHz."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Hiệu quả, dung lượng và tốc độ tốt hơn so với Wi-Fi 5 trên các mạng bị tắc nghẽn."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Duy trì kết nối ổn định hơn với Wi‑Fi [XX] và tận hưởng tốc độ internet nhanh hơn<sup>†</sup> và đáng tin cậy hơn.",
                "tr_title": "Cải thiện phạm vi và tốc độ kết nối",
                "tr_noteSection": "<sup>†</sup>Thông lượng và/hoặc phạm vi không dây thực tế sẽ khác nhau.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Trong thời đại chơi game trực tuyến và nhiều người cùng chơi thì việc giữ kết nối liên tục trở nên quan trọng hơn xưa. Ngoài tốc độ, bạn còn muốn tín hiệu không dây có thể duy trì tốc độ đó ở khoảng cách xa.",
                "tr_title": "[XX]",
                "tr_pretitle": "KẾT NỐI LÀ CHÌA KHÓA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Kết nối với Intel® Wi‑Fi 6 (Gig+) đến bộ định tuyến Wi‑Fi 6 có hỗ trợ kênh 160 MHz để cho phép kết nối nhanh hơn và tin cậy hơn cho nhiều người dùng đang hoạt động hơn.",
                "tr_title": "Tốc độ nhanh hơn gần gấp 3 lần với Intel® <span class='no-warp'>Wi-Fi 6 </span>(Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Hơn Wi‑Fi AC tiêu chuẩn. Một số tính năng chọn lọc chỉ có sẵn ở một số SKU nhất định. Kiểm tra với nhà sản xuất để biết chi tiết. Để biết thêm thông tin về Wi‑Fi, vui lòng truy cập intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Wi‑Fi 6 băng tần kép cấp cơ bản hỗ trợ cải thiện hiệu năng cho các nhu cầu kết nối cơ bản, như email và duyệt Internet trên các mạng bị tắc nghẽn.<sup>9</sup>",
                "tr_title": "Wi‑Fi thiết yếu",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Trong thời đại chơi game trực tuyến và nhiều người cùng chơi thì việc giữ kết nối liên tục trở nên quan trọng hơn xưa. Ngoài tốc độ, bạn còn muốn tín hiệu không dây có thể duy trì tốc độ đó ở khoảng cách xa.",
                "tr_title": "[XX]",
                "tr_pretitle": "KẾT NỐI LÀ CHÌA KHÓA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Việc có các kênh Wi‑Fi mới này cho phép bạn không phải chia sẻ băng thông với các thiết bị cũ hơn trong khu vực lân cận của mình, giúp bạn phát trực tiếp mượt mà, duyệt web nhanh, làm việc và học tập từ xa liền mạch.",
                "tr_title": "Kết nối không có sự cạnh tranh",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Duy trì kết nối ổn định hơn với [XX] và tận hưởng tốc độ internet nhanh hơn<sup>†</sup> và tin cậy hơn. ",
                "tr_title": "Cải thiện phạm vi và tốc độ kết nối",
                "tr_noteSection": "<sup>†</sup>Thông lượng và/hoặc phạm vi không dây thực tế sẽ khác nhau.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Trong thời đại chơi game trực tuyến và nhiều người cùng chơi thì việc giữ kết nối liên tục trở nên quan trọng hơn xưa. Ngoài tốc độ, bạn còn muốn tín hiệu không dây có thể duy trì tốc độ đó ở khoảng cách xa.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "KẾT NỐI LÀ CHÌA KHÓA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Tích hợp Wi‑Fi siêu nhanh",
                "tr_subtitle": "Ngắt nguồn nhưng vẫn có thể kết nối với thế giới trực tuyến của bạn."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Với đường truyền tốc độ cao dành riêng để giảm nhiễu và Công cụ Ưu tiên Intel® Killer™ đặt việc chơi game lên hàng đầu, chơi game trực tuyến đã thực sự được nâng tầm.",
                "tr_title": "Bỏ lại sự cạnh tranh",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Với đường truyền tốc độ cao dành riêng để giảm nhiễu và Công cụ Ưu tiên Intel® Killer™ đặt việc chơi game lên hàng đầu, chơi game trực tuyến đã thực sự được nâng tầm.",
                "tr_title": "[XX]",
                "tr_pretitle": "BỎ LẠI SỰ CẠNH TRANH",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Chơi game trực tuyến đã được nâng cấp đáng kể với các làn riêng tốc độ cao để giảm nhiễu và ưu tiên trải nghiệm chơi game.",
                "tr_title": "[XX]",
                "tr_pretitle": "BỎ LẠI SỰ CẠNH TRANH",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi-Fi tự động tăng tốc lưu lượng mạng cho các ứng dụng cần ưu tiên nhất, giúp các video truyền phát trực tiếp của bạn có độ sắc nét và hạn chế lưu vào bộ đệm.",
                "tr_title": "Kết nối nhanh hơn và thông minh hơn.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Trong thời đại chơi game trực tuyến và nhiều người cùng chơi thì việc giữ kết nối liên tục trở nên quan trọng hơn xưa. Ngoài tốc độ, bạn còn muốn tín hiệu không dây có thể duy trì tốc độ đó ở khoảng cách xa.",
                "tr_title": "[XX]",
                "tr_pretitle": "KẾT NỐI LÀ CHÌA KHÓA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Duy trì kết nối ổn định hơn với Wi-Fi 5 và tận hưởng tốc độ internet nhanh hơn<sup>†</sup> và đáng tin cậy hơn.",
                "tr_title": "Cải thiện phạm vi và tốc độ kết nối",
                "tr_noteSection": "<sup>†</sup>Thông lượng và/hoặc phạm vi không dây thực tế sẽ khác nhau.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Trong thời đại chơi game trực tuyến và nhiều người cùng chơi thì việc giữ kết nối liên tục trở nên quan trọng hơn xưa. Ngoài tốc độ, bạn còn muốn tín hiệu không dây có thể duy trì tốc độ đó ở khoảng cách xa.",
                "tr_title": "[XX]",
                "tr_pretitle": "BỎ LẠI SỰ CẠNH TRANH",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Các tính năng của Wi-Fi 7 được kế thừa và nâng cấp so với các thế hệ Wi-Fi trước đó. Điều này đồng nghĩa với tốc độ nhanh hơn, khả năng phản hồi và độ tin cậy nâng cao cho trải nghiệm người dùng sống động cùng các công nghệ tương lai tinh vi.",
                "tr_title": "Kết nối mạng không dây mạnh mẽ như có dây",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Các tính năng của Wi-Fi 7 được kế thừa và nâng cấp so với các thế hệ Wi-Fi trước đó. Điều này đồng nghĩa với tốc độ nhanh hơn, khả năng phản hồi và độ tin cậy nâng cao cho trải nghiệm người dùng sống động cùng các công nghệ tương lai tinh vi.",
                "tr_title": "[XX]",
                "tr_pretitle": "CHUYỂN ĐỔI TRẢI NGHIỆM NGƯỜI DÙNG",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Cải thiện tốc độ, độ trễ và độ tin cậy thông qua tối ưu hóa mạng dựa trên AI",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Cải thiện tốc độ, độ trễ và độ tin cậy thông qua tối ưu hóa mạng dựa trên AI.",
                "tr_title": "[XX]",
                "tr_pretitle": "TỐC ĐỘ PHẢN HỒI NHƯ KẾT NỐI CÓ DÂY",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pin",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Đo lường bằng cách nào?",
                    "tr_badgeSubTitle": "Hầu hết các nhà sản xuất dùng đơn vị giờ cho thời lượng pin. Tuy cách tính này không chính xác 100%, nhưng có thể giúp bạn so sánh thời lượng pin của các máy tính."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pin",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "ĐỂ CHƠI GAME MỌI LÚC, MỌI NƠI",
                "tr_subtitle": "Với sức mạnh của máy tính xách tay chơi game ngày nay, không có lý do gì khiến bạn phải cố định ở bàn làm việc. Bây giờ, game theo bạn mọi nơi.",
                "tr_title": "Thời lượng pin [Dynamic.battery.values.hours] giờ"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pin",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Tháo sạc và luôn kết nối với ứng dụng, trò chơi và nhạc trong hàng giờ.",
                "tr_title": "[XX] giờ phát trực tiếp chỉ với một lần sạc<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Dựa trên thời lượng pin do nhà sản xuất máy tính tuyên bố.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] giờ",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pin",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Tháo sạc và luôn kết nối với ứng dụng, trò chơi và nhạc trong hàng giờ.",
                "tr_title": "[Dynamic.battery.values.hours] giờ hoạt động mọi lúc, mọi nơi<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Dựa trên thời lượng pin do nhà sản xuất máy tính tuyên bố.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] giờ",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pin",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Tháo sạc và luôn kết nối với ứng dụng, trò chơi và nhạc trong hàng giờ.",
                "tr_title": "[Dynamic.battery.values.hours] giờ hoạt động cả ngày chỉ sau một lần sạc<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Dựa trên thời lượng pin do nhà sản xuất máy tính tuyên bố.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] giờ",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Pin",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Máy tính này tối ưu hóa thời lượng pin và cho bạn ít nhất 4 giờ sử dụng pin sau mỗi lần sạc 30 phút.<sup>4</sup>",
                "tr_title": "Sử dụng lâu hơn mà không cần cắm sạc với thời lượng pin [Dynamic.battery.values.hours] giờ<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Dựa trên thời lượng pin do nhà sản xuất máy tính tuyên bố.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] giờ",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Trang chủ",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Tại sao chọn chiếc máy tính này",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Xem tất cả các thông số kỹ thuật",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Từ xa",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Di động",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Pháp lý",
        "tr_paragraph_array": [
          "Phần mềm và khối lượng công việc được dùng trong các cuộc kiểm tra hiệu năng có thể đã được tối ưu hóa cho hiệu năng chỉ trên bộ vi xử lý Intel®. Bài kiểm tra hiệu năng như SYSmark* và MobileMark* được đo bằng cách sử dụng các hệ thống, thành phần và chức năng cụ thể của máy tính. Bất kỳ thay đổi nào của các yếu tố này cũng có thể làm thay đổi kết quả. Bạn nên tham khảo thông tin và các cuộc kiểm tra hiệu năng khác để giúp bạn đánh giá đầy đủ việc mua hàng mà bạn đang định tiến hành, bao gồm hiệu năng của sản phẩm đó khi kết hợp với các sản phẩm khác. Để biết thêm thông tin đầy đủ về kết quả hiệu năng và tiêu chuẩn công năng, xin truy cập www.intel.com/benchmarks.",
          "¹Các sản phẩm Intel® Wi-Fi 6 (Gig+) hỗ trợ các kênh 160 MHz tùy chọn, giúp tăng tốc độ tối đa trên lý thuyết lên mức cao nhất có thể (2402 Mbps) cho các sản phẩm Wi-Fi 2x2 802.11ax điển hình dành cho máy tính. Các sản phẩm Intel® Wi-Fi 6 (Gig+) cao cấp giúp tăng tốc độ tối đa theo lý thuyết lên mức nhanh hơn 2 đến 4 lần so với các sản phẩm Wi-Fi 802.11ax 2x2 (1201 Mbps) hoặc 1x1 (600 Mbps) tiêu chuẩn dành cho máy tính, vốn chỉ hỗ trợ yêu cầu bắt buộc là các kênh 80 MHz.",
          "²Đề xuất chỉ mang tính chất cung cấp thông tin. Vui lòng tham khảo các nguồn khác khi đưa ra quyết định mua hàng.",
          "³Các tính năng và lợi ích của công nghệ Intel tùy thuộc vào cấu hình hệ thống và có thể yêu cầu phần cứng, phần mềm có hỗ trợ, và yêu cầu kích hoạt dịch vụ. Hiệu năng thay đổi tùy theo cấu hình hệ thống. Không hệ thống máy tính nào có thể an toàn tuyệt đối. Xin kiểm tra với nhà sản xuất hệ thống hoặc đại lý bán lẻ của bạn hoặc tìm hiểu thêm tại intel.com.",
          "⁴Dành cho các hệ thống có màn hình FHD khi dùng để duyệt web không dây. Khi tắt nguồn, từ mức tắt nguồn mặc định OEM.",
          "⁵Khi so sánh với các công nghệ kết nối I/O khác cho máy tính, bao gồm eSATA, USB và IEEE 1394* FireWire. Hiệu năng sẽ thay đổi tùy thuộc vào phần cứng và phần mềm cụ thể được sử dụng. Phải sử dụng thiết bị hỗ trợ công nghệ Thunderbolt™.",
          "⁶Nhanh hơn gần 3 lần: 802.11ax 2x2 160 MHz giúp đạt được tốc độ dữ liệu tối đa trên lý thuyết là 2402 Mbps. Nhanh hơn ~3 lần so với Wi‑Fi 802.11ac 2x2 80 MHz (867 Mbps) tiêu chuẩn theo ghi chép trong thông số kỹ thuật tiêu chuẩn không dây IEEE 802.11* và yêu cầu sử dụng các bộ định tuyến mạng không dây 802.11ax có cấu hình tương tự.",
          "⁷Giảm 75% độ trễ: Dựa trên dữ liệu mô phỏng của Intel (79%) đối với 802.11ax có và không có OFDMA sử dụng 9 máy khách. Độ trễ trung bình khi không có OFDMA là 36 ms, độ trễ trung bình khi có OFDMA giảm xuống còn 7,6 ms. Để cải thiện độ trễ, bộ định tuyến 802.11ax (Wi&#8209;Fi&nbsp;6) và tất cả các máy khách cần phải hỗ trợ OFDMA.",
          "⁸Dựa trên so sánh tốc độ dữ liệu tối đa theo lý thuyết trong thông số kỹ thuật của 802.11, Wi‑Fi 6/6E 2x2 160 MHz (802.11ax) có thể cung cấp 2402 Mbps so với Wi‑Fi 5 2x2 80 MHz (802.11ac) có thể cung cấp 867 Mbps, nghĩa là tốc độ nhanh hơn 2,8 lần.",
          "⁹Wi‑Fi 6 tốt nhất trong phân khúc: Các sản phẩm Intel® Wi‑Fi 6 (Gig+) hỗ trợ các kênh 160 MHz tùy chọn, giúp tăng tốc độ lý thuyết khả thi lên cao nhất (2402 Mbps) cho các sản phẩm Wi‑Fi 2x2 802.11ax dành cho máy tính để bàn điển hình. Các sản phẩm Intel® Wi‑Fi 6 (Gig+) cao cấp giúp tăng tốc độ tối đa theo lý thuyết lên mức nhanh hơn 2 đến 4 lần so với các sản phẩm Wi‑Fi 802.11ax 2x2 (1201 Mbps) hoặc 1x1 (600 Mbps) tiêu chuẩn dành cho máy tính, vốn chỉ hỗ trợ yêu cầu bắt buộc là các kênh 80 MHz.",
          "Chỉ một số SKU chọn lọc, chức năng thay đổi theo thiết kế OEM. Kiểm tra với OEM hoặc nhà bán lẻ để biết thêm chi tiết. Để biết thêm thông tin về Wi‑Fi vui lòng truy cập intel.com/wifi6disclaimers.",
          "¹⁰Công nghệ Thunderbolt™ 4 với tốc độ 40 Gbps mang đến giải pháp cáp nhanh nhất, đơn giản nhất và đáng tin cậy nhất cho mọi đế cắm, màn hình hoặc thiết bị dữ liệu so với các công nghệ kết nối I/O máy tính khác như eSATA*, USB* và IEEE 1394* FireWire.",
          "¹¹Yêu cầu sử dụng bộ định tuyến dựa trên 802.11ax, hỗ trợ OFDMA và nhiều máy khách trên mạng có hỗ trợ cho AX. Có thể hoạt động tốt hơn trong môi trường có mật độ cao nhờ các tính năng OFDMA được hỗ trợ bởi máy khách 802.11ax và AP. 2 Gbps dựa trên các giả định là xấp xỉ 70% tốc độ dữ liệu tối đa theo lý thuyết thông số kỹ thuật IEEE 802.11* là 802.11ax 160 MHz 2.402 Mbps.",
          "¹²Đo bằng tác vụ Khởi chạy Tài liệu với Khối lượng Công việc Chạy ngầm, so sánh bộ xử lý Intel® Core™ i7-8565U thế hệ thứ 8 (SSD TLC 512 GB) với bộ xử lý Intel® Core™ i7-8565U thế hệ thứ 8 (bộ nhớ Intel® Optane™ H10 32 GB + 512 GB có bộ lưu trữ thể rắn).",
          "¹³Intel® Thread Director được thiết kế vào bộ xử lý Intel® Core™ thế hệ thứ 12 và giúp hỗ trợ các hệ điều hành phân luồng công việc cho lõi phù hợp một cách thông minh hơn. Không cần tác động của người dùng. Vui lòng truy cập intel.com để biết chi tiết.",
          "¹⁴Không khả dụng trên một số bộ xử lý Intel® Core™ thế hệ thứ 12 nhất định. Cấu trúc kết hợp hiệu năng với hai vi cấu trúc lõi mới, Performance-core (P-core) và Efficient-core (E-core), trên một đế cắm bộ xử lý duy nhất. Chọn bộ xử lý Intel® Core™ thế hệ thứ 12 (một số bộ xử lý Intel® Core™ i5 thế hệ thứ 12 nhất định trở xuống) không hỗ trợ cấu trúc kết hợp hiệu năng, chỉ có P-core.",
          "¹⁵Đo theo Tải Lượng Chạy Game, so sánh bộ xử lý Intel® Core™ i7-8750H thế hệ thứ 8 (môđun bộ nhớ Intel® Optane™ 32GB + SSD Intel® PCIe* 256GB + HDD 1TB) với bộ xử lý Intel® Core™ i7-8750H thế hệ thứ 8 (SSD Intel® PCIe* 256GB + HDD 1TB). Khối lượng công việc khởi chạy game - Khối lượng công việc do Intel phát triển, đánh giá thời gian cần thiết để khởi động Total War*: WARHAMMER* II Build: 5577.0 và đến Menu chính khi đã tắt video giới thiệu (Khởi chạy). Cấu hình tăng tốc ổ đĩa dữ liệu bộ nhớ Intel® Optane™: Bộ xử lý Intel® Core™ i7-8750H, PL1=45W TDP, 6C12T, Turbo lên đến 4GHz trên hệ thống OEM tiền sản xuất, Đồ họa: NVIDIA* GeForce* GTX 1070, Bộ nhớ: 2 DDR4 4GB, Bộ lưu trữ: SSD chuỗi Intel® 760p 256GB (PCIe*) + HDD 1TB + bộ nhớ Intel® Optane™ 32GB, HĐH: Windows* 10 RS3 Bản dựng 1709, MCU 0x84; Bộ xử lý Intel® Core™ i7-8750H, PL1=45W TDP, 6C12T, Turbo lên đến 4GHz trên Hệ thống OEM tiền sản xuất, Đồ họa: NVIDIA* GeForce* GTX 1070, Bộ nhớ: 2 DDR4 4GB, Bộ lưu trữ: SSD chuỗi Intel® 760p 256GB (PCIe*) + HDD 1TB, HĐH: Windows* 10 RS3 Bản dựng 1709, MCU 0x84.",
          "¹⁶Đo theo Tải Lượng Nạp Trình Game so sánh bộ xử lý Intel® Core™ i7-8750H thế hệ thứ 8 (môđun bộ nhớ Intel® Optane™ 32GB) với bộ xử lý Intel® Core™ i7-8750H thế hệ thứ 8 (chỉ dùng HDD). Khối lượng công việc tải cấp game - Khối lượng công việc do Intel phát triển để đo thời gian tính từ khi ở menu chính đến khi hoàn thành việc tải cấp (Tải cấp) trong Total War*: WARHAMMER* II Bản dựng: 5577.0. Cấu hình tăng tốc ổ đĩa dữ liệu bộ nhớ Intel® Optane™: Bộ xử lý Intel® Core™ i7-8750H, PL1=45W TDP, 6C12T, Turbo lên đến 4GHz trên Bệ thống OEM tiền sản xuất, Đồ họa: NVIDIA* GeForce* GTX 1070, Bộ nhớ: 2 DDR4 4GB, Bộ lưu trữ: SSD chuỗi Intel® 760p 256GB (PCIe*) + HDD 1TB + bộ nhớ Intel® Optane™ 32GB, HĐH: Windows* 10 RS3 Bản dựng 1709, MCU 0x84; Bộ xử lý Intel® Core™ i7-8750H, PL1=45W TDP, 6C12T, Turbo lên đến 4GHz trên hệ thống OEM tiền sản xuất, Đồ họa: NVIDIA* GeForce* GTX 1070, Bộ nhớ: 2 DDR4 4GB, Bộ lưu trữ: SSD chuỗi Intel® 760p 256GB (PCIe*) + HDD 1TB, HĐH: Windows* 10 RS3 Bản dựng 1709, MCU 0x84.",
          "¹⁷Trên lõi Performance-core. Hiệu năng thay đổi theo cách sử dụng, cấu hình và các yếu tố khác. Tìm hiểu thêm tại www.intel.com/PerformanceIndex.",
          "¹⁸Việc thay đổi tần số hoặc điện áp xung nhịp có thể làm hỏng hoặc giảm tuổi thọ của bộ xử lý và thành phần hệ thống khác, đồng thời có thể làm giảm độ ổn định và hiệu năng của hệ thống. Bảo hành sản phẩm có thể không áp dụng cho trường hợp bộ xử lý được vận hành vượt ngoài phạm vi thông số kỹ thuật của nó. Hãy kiểm tra với các nhà sản xuất hệ thống và linh kiện để biết thêm chi tiết.",
          "¹⁹Được đo lường bằng sự kết hợp vô song của khả năng bảo mật trên và dưới của hệ điều hành, bảo vệ ứng dụng và dữ liệu, và các biện pháp bảo vệ chống lại mối đe dọa nâng cao mà Nền tảng Intel vPro® cung cấp cho doanh nghiệp thuộc mọi quy mô, cũng như cách tiếp cận ưu tiên bảo mật của Intel đối với thiết kế, sản xuất, và hỗ trợ sản phẩm. Tất cả các máy tính cá nhân của doanh nghiệp được xây dựng trên nền tảng Intel vPro® đã được xác minh dựa trên các thông số kỹ thuật nghiêm ngặt, bao gồm các tính năng bảo mật dựa trên phần cứng độc đáo. Vui lòng xem www.intel.com/PerformanceIndex (nền tảng) để biết chi tiết. Không sản phẩm hay thành phần nào có thể bảo mật tuyệt đối.",
          "²⁰Intel® Connectivity Performance Suite chỉ có sẵn trên hệ thống dựa trên Windows*. Thử nghiệm Wi-Fi của Intel Over The Air (OTA) trên một mạng bị tắc nghẽn so với không có ưu tiên lưu lượng không dây ứng dụng phần mềm ICPS và chuyển đổi điểm truy cập thông minh. Vui lòng xem www.intel.com/PerformanceIndex (kết nối) để biết chi tiết. Kết quả có thể khác nhau.",
          "²¹So với thế hệ bộ xử lý trước.",
          "²²Đồ họa Intel® Arc™ lần đầu tiên có mặt trên thị trường với chức năng hỗ trợ mã hóa AV1 (kể từ Q1 năm 2022). Chi tiết tại intel.com/performanceindex.",
          "²³Được đo bằng các tính năng độc đáo của thiết kế Intel® Evo™, các tính năng này cũng được xác minh để đáp ứng ngưỡng trải nghiệm chính của người dùng di động. Kiểm tra kể từ Tháng 2 năm 2022.",
          "²⁴Giải pháp Intel® Unison™ hiện chỉ sẵn có trên các thiết kế Intel® Evo™ trên máy tính cá nhân chạy hệ điều hành Windows* đủ điều kiện và chỉ ghép nối với điện thoại chạy Android hoặc iOS; tất cả các thiết bị phải chạy một phiên bản hệ điều hành được hỗ trợ.",
          "²⁵Tốc độ bộ nhớ tối đa được liên kết với cấu hình 1 DIMM mỗi Kênh (1DPC). Việc tăng tải DIMM lên bất cứ kênh nào cũng có thể tác động đến tốc độ bộ nhớ tối đa. Lên đến DDR5-5600 MT/giây 1DPC UDIMM 1Rx8, 1Rx16 và DDR5-5200 1Rx8, 1Rx16, 2Rx8 trên các SKU chọn lọc. Có thể đạt được dung lượng bộ nhớ tối đa với cấu hình 2DPC.",
          "²⁶So với Wi-Fi tiêu chuẩn. Truy cập intel.com/performance-wireless để biết thêm chi tiết. Kết quả có thể khác nhau.",
          "²⁷Cấu trúc kết hợp hiệu năng kết hợp hai vi cấu trúc lõi, Performance-core (P-core) và Efficient-core (E-core), trên một khuôn bộ xử lý duy nhất được giới thiệu lần đầu tiên trên bộ xử lý Intel® Core™ thế hệ thứ 12. Một số bộ xử lý Intel® Core™ thế hệ thứ 12 và mới hơn chọn lọc không có cấu trúc kết hợp hiệu năng, chỉ có P-core và có cùng kích thước bộ nhớ đệm như thế hệ trước; xem ark.intel.com để biết chi tiết về SKU.",
          "²⁸Được tích hợp vào phần cứng, Intel® Thread Director chỉ được cung cấp trong các cấu hình cấu trúc kết hợp hiệu năng của bộ xử lý Intel® Core™ thế hệ thứ 12 hoặc mới hơn; Bắt buộc phải kích hoạt hệ điều hành. Các tính năng và chức năng có sẵn khác nhau tùy theo hệ điều hành.",
          "²⁹Được tích hợp vào phần cứng, Intel® Thread Director chỉ được cung cấp trong các cấu hình cấu trúc kết hợp hiệu năng của bộ xử lý Intel® Core™ thế hệ thứ 12 và thứ 13; Bắt buộc phải kích hoạt hệ điều hành. Các tính năng và chức năng có sẵn khác nhau tùy theo hệ điều hành.",
          "³⁰Theo hiệu năng nền tảng xác thực tham chiếu của bộ xử lý di động Intel®&nbsp;Core™ Ultra (Chuỗi 2 — Tên mã: Lunar Lake) so với bộ xử lý di động Intel®&nbsp;Core™ Ultra (Chuỗi 1 — Tên mã: Meteor Lake) trên 3DMark Time Spy. Truy cập intel.com/PerformanceIndex để biết thêm chi tiết. Kết quả có thể khác nhau.",
          "³¹Làm mờ nền và theo dõi khuôn mặt chỉ có trên các hệ thống sử dụng Windows*.",
          "³²Các sản phẩm Wi-Fi 7 có thể truy cập các kênh 320 MHz ở tần số 6 GHz và các kết hợp kênh 160 MHz mới ở tần số 5 GHz với khả năng Multi-Resource Unit Puncturing mới.",
          "³³Dựa trên thông số kỹ thuật tiêu chuẩn không dây của IEEE và tốc độ dữ liệu lý thuyết tối đa cho các thiết bị 2 luồng dữ liệu.",
          "³⁴Mô phỏng kỹ thuật Intel về môi trường mạng bị tắc nghẽn cho thấy có thể giảm độ trễ đáng kể nhờ khả năng Hoạt động Đa Liên kết Wi-Fi 7 mới.",
          "³⁵Sạc tối thiểu đạt được từ mức tắt máy mặc định của OEM. Kết quả có thể khác nhau trên từng hệ thống. Truy cập intel.com/performance-evo để biết thêm chi tiết.",
          "³⁶Băng tần Wi-Fi 6 GHz không có sẵn để sử dụng ở tất cả các quốc gia/khu vực.",
          "³⁷Chỉ Windows*",
          "³⁸Sạc đạt được từ mức tắt máy mặc định của OEM. Để biết thêm thông tin đầy đủ về kết quả hiệu năng và tiêu chuẩn công năng, xin truy cập intel.com/Evo.",
          "³⁹Đề cập đến bộ xử lý di động Intel®&nbsp;Core™ Ultra (Chuỗi&nbsp;2), dựa trên các tính năng bảo mật dựa trên phần cứng độc đáo, sự kết hợp vô song giữa khả năng bảo mật trên và dưới hệ điều hành, hiệu năng ấn tượng ngay cả khi sử dụng ít năng lượng hơn so với thế hệ trước (tính đến tháng 9 năm 2024). Truy cập intel.com/PerformanceIndex để biết thêm chi tiết. Kết quả có thể khác nhau.",
          "⁴⁰Các ứng dụng phần mềm được mô tả chỉ nhằm mục đích minh họa. Các tính năng AI có thể yêu cầu mua phần mềm bổ sung, gói đăng ký, hoặc yêu cầu hỗ trợ từ bên cung cấp phần mềm hoặc nền tảng, hoặc có thể có các yêu cầu cụ thể về cấu hình hoặc khả năng tương thích. Chi tiết tại www.intel.com/PerformanceIndex. Kết quả có thể khác nhau. © Intel Corporation.",
          "⁴¹Hình ảnh được hiển thị có thể đã bị thay đổi hoặc mô phỏng. Các tính năng AI có thể yêu cầu mua phần mềm bổ sung, gói đăng ký, hoặc yêu cầu hỗ trợ từ bên cung cấp phần mềm hoặc nền tảng, hoặc có thể có các yêu cầu cụ thể về cấu hình hoặc khả năng tương thích. Xem chi tiết tại www.intel.com/AIPC.",
          "⁴²Triển khai dần trong bản xem trước trong bản cập nhật mới nhất của Windows* 11 tại một số thị trường toàn cầu chọn lọc. Thời gian phát hành thay đổi tùy theo thiết bị và thị trường. Tìm hiểu thêm tại đây: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³Giải pháp Intel® Unison™ hiện có trên các thiết kế đủ điều kiện. Xem www.intel.com/PerformanceIndex để biết chi tiết.",
          "⁴⁴FPS được nâng cấp bởi Intel® X<sup>e</sup>SS so với FPS gốc được đo bằng Dying Light 2 Stay Human chạy trên bộ xử lý Intel® Core™ Ultra 7 165H. Xem www.intel.com/PerformanceIndex để biết chi tiết. Kết quả có thể khác nhau.",
          "⁴⁵Đo bằng mức tiêu thụ năng lượng SoC thấp hơn của nền tảng xác thực tham chiếu của bộ xử lý di động Intel®&nbsp;Core™ Ultra (Chuỗi 2 — Tên mã: Lunar Lake) so với nền tảng xác thực tham chiếu của bộ xử lý di động Intel®&nbsp;Core™ Ultra (Chuỗi 1 — Tên mã: Meteor Lake) trên YouTube* 4K 30FPS AV1. Truy cập intel.com/PerformanceIndex để biết thêm chi tiết. Kết quả có thể khác nhau.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Intel không nhận bất kỳ trách nhiệm pháp lý nào về giá bán không chính xác.",
          "*Các tên và nhãn hiệu khác có thể được công nhận là tài sản của các pháp nhân khác.",
          "BẢN QUYỀN CÁC GAME INFLEXION 2022. CÁC GAME NIGHTINGALE, INFLEXION VÀ THIẾT KẾ LIÊN QUAN VÀ NHÃN HIỆU KẾT HỢP LÀ NHÃN HIỆU CỦA INFLEXION STUDIOS INC. BẢO LƯU MỌI QUYỀN.",
          "Các công nghệ Intel có thể yêu cầu phần cứng, phần mềm có hỗ trợ hoặc yêu cầu kích hoạt dịch vụ.",
          "Mọi kết quả và dữ liệu đều do NERO* cung cấp để tạo thuận tiện và chỉ cho mục đích cung cấp thông tin. Intel Corporation không chịu trách nhiệm về, lấy ví dụ, tính chính xác của ứng dụng NERO*, kết quả và điểm số hiệu năng hoặc các thay đổi tiềm ẩn từ phiên bản tải xuống khác của ứng dụng có trên www.nero.com hoặc trang web khác của bên thứ ba. Tiêu chuẩn công năng máy tính NERO* được đo bằng cách dùng các hệ thống máy tính, bộ phận, phần mềm, hệ điều hành và chức năng cụ thể. Bất kỳ thay đổi nào của các yếu tố này cũng có thể làm thay đổi kết quả. Bạn nên tham khảo thông tin và các kết quả kiểm tra hiệu năng khác để giúp bạn đánh giá đầy đủ việc mua hàng mà bạn đang cân nhắc, bao gồm hiệu năng của sản phẩm đó khi kết hợp với các sản phẩm khác.",
          "Tất cả các phiên bản của nền tảng Intel vPro® yêu cầu phải có bộ xử lý Intel® Core™ đủ điều kiện, hệ điều hành được hỗ trợ, silicon Intel® LAN và/hoặc WLAN, các cải tiến chương trình cơ sở, và các phần cứng và phần mềm cần thiết khác để thực hiện các trường hợp sử dụng khả năng quản lý, các tính năng bảo mật, hiệu năng và độ ổn định hệ thống đặc trưng của nền tảng này.",
          "Hiệu suất thay đổi theo cách sử dụng, cấu hình và các yếu tố khác. Tìm hiểu thêm tại www.intel.com/PerformanceIndex.",
          "Intel không kiểm soát hay kiểm tra dữ liệu bên thứ ba. Bạn nên tham khảo các nguồn khác để đánh giá tính chính xác.",
          "© Intel Corporation. Intel, biểu trưng Intel và các nhãn hiệu Intel khác là thương hiệu của Intel Corporation hoặc các chi nhánh của Intel. *Các tên và thương hiệu khác là tài sản của các chủ sở hữu khác.",
          "Không sản phẩm hay thành phần nào có thể bảo mật tuyệt đối.",
          "Chi phí và kết quả của bạn có thể thay đổi.",
          "Intel, biểu trưng Intel, Intel Core, Intel Optane, Intel Iris, Thunderbolt và biểu trưng Thunderbolt là thương hiệu của Intel Corporation hoặc các chi nhánh của Intel. Các tên và thương hiệu khác là tài sản của các chủ sở hữu khác.",
          "© Intel Corporation"
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Trang chủ",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Quét để So sánh",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Xu hướng",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "Khai mở trải nghiệm AI mới với bộ xử lý Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "Khai mở trải nghiệm AI mới với bộ xử lý Intel® Core™ Ultra",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "Khai mở trải nghiệm AI mới với bộ xử lý Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "Stable Diffusion*: Biến chữ thành hình",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe*: Chỉnh sửa ảnh",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "Intel® X<sup>e</sup>SS : Nâng độ phân giải hình ảnh",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee*: Máy dò Deepfake",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "Hiệu ứng cộng tác (Teams)",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "IQIYI: Điều khiển bằng cử chỉ",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "Đồng bộ hóa PC với điện thoại của bạn và xem thông báo, cuộc gọi và tin nhắn",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "Tích hợp đồ họa chi tiết và sống động",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "Microsoft*: Tận hưởng cuộc sống của bạn, theo cách của bạn",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "Microsoft*: Tất cả mọi thứ đều hướng về bạn. Tất cả trong nháy mắt, chỉ cần một thao tác vuốt",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "Chạy bộ xử lý của bạn nhanh hơn và ở tần số cao hơn bằng cách tinh chỉnh hệ thống",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "Tăng hiệu suất hệ thống máy tính để bàn hoặc di động mà không làm mất dung lượng lưu trữ",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "Tăng hiệu suất hệ thống máy tính để bàn hoặc di động mà không làm mất dung lượng lưu trữ",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "Samsung OLED: Màn hình dễ chịu",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "Cải thiện tốc độ, độ trễ và độ tin cậy thông qua tối ưu hóa mạng dựa trên AI",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "Kết nối mạng không dây mạnh mẽ như có dây",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "Kết nối mạng không dây mạnh mẽ như có dây",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "Tốc độ nhanh hơn gần gấp 3 lần với Intel® Wi-Fi 6 (Gig+)",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "Kết nối không có sự cạnh tranh",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "Kết nối cáp thực sự đa năng",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "Chơi như dân chuyên thực thụ: Xem cách Intel tăng cường sức mạnh cho game thủ và hoạt động chơi game",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "Chơi như dân chuyên thực thụ: Nhà vô địch sinh ra từ Intel",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "CHÀO MỪNG",
    "tr_welcomePagetrSubtitle": "Điều khiển máy tính từ xa bằng chiếc điện thoại thông minh của bạn",
    "tr_welcomePagembSubtitle": "Xin chào, hãy khám phá các thông số kỹ thuật của chiếc máy tính này trên điện thoại thông minh của bạn",
    "tr_welcomePageBtnText": "Bắt Đầu",
    "tr_welcomePageText": "Khám phá và so sánh các thông tin chi tiết của máy tính này trên điện thoại thông minh của bạn",
    "tr_scrollText": "Điều hướng thiết bị trước mặt bạn",
    "tr_toolTipTextc1": "Sử dụng các nút này để điều hướng đến các trang khác nhau trên thiết bị trước mặt bạn.",
    "tr_toolTipTextc2": "Nhấn vào các thẻ bên dưới sẽ điều hướng đến trang chi tiết của từng thông số kỹ thuật thiết bị.",
    "tr_toolTipTextc3": "Sử dụng các nút này để di chuyển lên và xuống trên màn hình phía trước bạn.",
    "tr_toolTipTextc4": "Tắt chế độ từ xa để xem các thông số kỹ thuật của thiết bị trên điện thoại thông minh của bạn.",
    "tr_toolTipTextmb1": "Nhấn vào thẻ để xem các trang nội dung chi tiết.",
    "tr_toolTipTextmb2": "Nhấn để xem tóm tắt tất cả các thông số kỹ thuật của thiết bị",
    "tr_toolTipTextmb3": "Nhấn vào nút để quét mã QR và so sánh tối đa 4 thiết bị",
    "tr_nextButton": "Tiếp theo",
    "tr_prevButton": "Trước",
    "tr_gotItButton": "TÔI ĐÃ HIỂU",
    "tr_skipButton": "Bỏ qua",
    "tr_placeholderText": "*phần giữ chỗ",
    "tr_processorHeading": "Bộ xử lý",
    "tr_processorSubheading": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i7 thế&nbsp;hệ&nbsp;thứ&nbsp;10",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "Bộ nhớ Intel® Optane™ [XX] GB + SSD [YY] GB",
    "tr_coachmarkProcessor": "Bộ xử lý XX",
    "tr_coachmarkGraphics": "Đồ hoạ [XX]",
    "tr_coachmarkMemory": "Giá trị thông số",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "$ 999,95",
    "tr_compare": "So sánh",
    "tr_viewallspecs": "XEM&nbsp;TẤT&nbsp;CẢ THÔNG&nbsp;SỐ KỸ&nbsp;THUẬT",
    "tr_operatingSystem": "HĐH",
    "tr_AmdProcessorNonIntel": "Bộ xử lý AMD*",
    "tr_QualcommProcessorNonIntel": "Bộ xử lý Qualcomm*",
    "tr_GBText": "GB",
    "tr_remoteText": "Thông số kỹ thuật hiển thị trên màn hình máy tính xách tay",
    "tr_scanErrorText": "Bạn chỉ có thể quét tối đa 3 thiết bị cùng một lúc. Vui lòng xóa một hoặc nhiều thiết bị.",
    "tr_scanErrorText2": "Bạn đã quét thiết bị này, vui lòng quét một thiết bị khác.",
    "tr_scanMsg": "Quét để thêm thiết bị",
    "tr_backToScan": "Quay lại so sánh",
    "tr_scanToCompare": "Quét để so sánh",
    "tr_compareDevice": "So sánh thiết bị",
    "tr_processorTitle": "BỘ XỬ LÝ",
    "tr_graphicTitle": "ĐỒ HỌA",
    "tr_storageTitle": "LƯU TRỮ",
    "tr_displayTitle": "MÀN HÌNH",
    "tr_batteryTitle": "PIN",
    "tr_memoryTitle": "BỘ NHỚ",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "KHẢ NĂNG KẾT NỐI",
    "tr_priceTitle": "GIÁ",
    "tr_operatingSystemTitle": "HỆ ĐIỀU HÀNH",
    "tr_batteryHoursText": "[XX] giờ",
    "tr_hrsText": "giờ",
    "tr_touchscreeenText": "Màn hình cảm ứng [screenDiagonal]”",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, Màn hình cảm ứng",
    "tr_FHDTouchScreenText": "FHD, Màn hình cảm ứng",
    "tr_4KTouchscreenText": "4K, Màn hình cảm ứng",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Lưu",
    "tr_device_comparison": "Tài liệu so sánh thiết bị [dd]-[mm]-[yyyy] ",
    "tr_Save_As_JPEG": "Lưu ở dạng JPEG",
    "tr_Save_As_PDF": "Lưu ở dạng PDF",
    "tr_Downloading_PDF": "Tải xuống bản PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Mở",
    "tr_logo": "biểu trưng",
    "tr_laptop_name": "Tên máy tính xách tay",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Bộ nhớ Intel® Optane™",
    "tr_inchUnit": "inch",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "BỘ NHỚ INTEL® OPTANE™",
    "tr_Thunderbolt3": "Công nghệ Thunderbolt™ 3",
    "tr_Thunderbolt4": "Công nghệ Thunderbolt™ 4",
    "tr_processorGraphicsCaps": "ĐỒ HỌA BỘ XỬ LÝ",
    "tr_processorGraphicsSmall": "Đồ họa bộ xử lý",
    "tr_graphicsCardCaps": "ĐỒ HỌA CHUYÊN DỤNG",
    "tr_graphicsCardSmall": "Đồ họa Chuyên dụng",
    "tr_processorTitleCamel": "Bộ xử lý",
    "tr_graphicTitleCamel": "Đồ họa",
    "tr_storageTitleCamel": "Bộ lưu trữ",
    "tr_displayTitleCamel": "Màn hình",
    "tr_batteryTitleCamel": "Pin",
    "tr_memoryTitleCamel": "Bộ nhớ",
    "tr_connectivityTitleCamel": "Khả năng kết&nbsp;nối",
    "tr_priceTitleCamel": "Giá",
    "tr_operatingSystemTitleCamel": "Hệ điều hành",
    "tr_viewallspecsCamel": "Xem tất cả các thông số kỹ thuật",
    "tr_displayText": "[screenDiagonal]”",
    "tr_displayTextResolution": "[screenResolutionType] [screenDiagonal]”",
    "tr_displayTextResolutionTouchscreen": "Màn hình cảm ứng [screenResolutionType] [screenDiagonal]”",
    "tr_OS": "HĐH",
    "tr_Chrome_OS": "HĐH Chrome*",
    "tr_PCSpecs_text": "Thông số kỹ thuật máy tính",
    "tr_explorePC_text": "Khám phá mọi thông tin về chiếc máy tính này",
    "tr_mtlPC_text": "Xem sức mạnh giải quyết mọi việc thực sự có nghĩa là gì",
    "tr_optaneMemory": "Bộ nhớ Intel® Optane™",
    "tr_displayText_Display": "MÀN HÌNH [screenDiagonal]”",
    "tr_displayTextResolution_Display": "MÀN HÌNH [screenDiagonal]” [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen_Display": "MÀN HÌNH CẢM ỨNG [screenDiagonal]” [screenResolutionType]",
    "tr_displayTextTouchscreeenText": "MÀN HÌNH CẢM ỨNG [screenDiagonal]”",
    "tr_learnMoreLabel": "Tìm hiểu thêm",
    "tr_batteryHoursTextUpperCase": "[XX] GIỜ",
    "tr_processorGraphicsIrisXe": "BỘ XỬ LÝ INTEL® CORE™ [cpu_fam] THẾ HỆ THỨ 11 VÀ ĐỒ HỌA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax": "BỘ XỬ LÝ INTEL® CORE™ [cpu_fam] THẾ HỆ THỨ 11 VÀ ĐỒ HỌA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_Thunderbolt3UpperCase": "CÔNG NGHỆ THUNDERBOLT™ 3",
    "tr_Thunderbolt4UpperCase": "CÔNG NGHỆ THUNDERBOLT™ 4",
    "tr_processorWithArcGraphics": "BỘ XỬ LÝ INTEL® CORE™ [cpu_fam] THẾ HỆ THỨ 12 VÀ ĐỒ HỌA INTEL® ARC™",
    "tr_processorGraphicsIrisXe12Gen": "BỘ XỬ LÝ INTEL® CORE™ [cpu_fam] THẾ HỆ THỨ 12 VÀ ĐỒ HỌA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax12Gen": "BỘ XỬ LÝ INTEL® CORE™ [cpu_fam] THẾ HỆ THỨ 12 VÀ ĐỒ HỌA INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "BỘ XỬ LÝ INTEL® CORE™ [cpu_fam] THẾ HỆ THỨ 13 VÀ&nbsp;ĐỒ&nbsp;HỌA&nbsp;INTEL®&nbsp;ARC™",
    "tr_processorGraphicsIrisXe13Gen": "BỘ XỬ LÝ INTEL® CORE™ [cpu_fam] THẾ HỆ THỨ 13 VÀ&nbsp;ĐỒ&nbsp;HỌA&nbsp;INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax13Gen": "BỘ XỬ LÝ INTEL® CORE™ [cpu_fam] THẾ HỆ THỨ 13 VÀ&nbsp;ĐỒ&nbsp;HỌA&nbsp;INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "ĐỒ HỌA INTEL® ARC™ A350M",
      "tr_A370M": "ĐỒ HỌA INTEL® ARC™ A370M",
      "tr_A730M": "ĐỒ HỌA INTEL® ARC™ A730M",
      "tr_A770M": "ĐỒ HỌA INTEL® ARC™ A770M",
      "tr_A310": "ĐỒ HỌA INTEL® ARC™ A310",
      "tr_A380": "ĐỒ HỌA INTEL® ARC™ A380",
      "tr_A580": "ĐỒ HỌA INTEL® ARC™ A580",
      "tr_A750": "ĐỒ HỌA INTEL® ARC™ A750",
      "tr_A770": "ĐỒ HỌA INTEL® ARC™ A770"
    },
    "tr_memoryValue": "RAM [XX] GB",
    "tr_memoryWithOptaneDesc": "Bộ nhớ [Value1] GB = Bộ nhớ Intel® Optane™ [Value2] GB + RAM [Value3] GB",
    "tr_textGBGB": "SSD [convertedSSD] GB + HDD&nbsp;[convertedHDD]&nbsp;GB",
    "tr_textGBTB": "SSD [convertedSSD] GB + HDD&nbsp;[convertedHDD]&nbsp;TB",
    "tr_textTBGB": "SSD [convertedSSD] TB + HDD&nbsp;[convertedHDD]&nbsp;GB",
    "tr_textTBTB": "SSD [convertedSSD] TB + HDD&nbsp;[convertedHDD]&nbsp;TB",
    "tr_textGBSSD": "SSD [convertedSSD] GB",
    "tr_textTBSSD": "SSD [convertedSSD] TB",
    "tr_textGBHDD": "HDD [convertedHDD] GB",
    "tr_textTBHDD": "HDD [convertedHDD] TB",
    "tr_textStorageWithOptaneGB": "Bộ nhớ Intel® Optane™ [XX] GB + SSD [convertedSSD] GB",
    "tr_textStorageWithOptaneTB": "Bộ nhớ Intel® Optane™ [XX] GB + SSD [convertedSSD] TB",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home trong chế độ S",
      "tr_OS21": "Windows* 10 Pro trong chế độ S",
      "tr_OS22": "Windows* 10 Enterprise trong chế độ S",
      "tr_OS23": "Windows* 11 Home trong chế độ S",
      "tr_OS24": "Windows* 11 Pro trong chế độ S",
      "tr_OS25": "Windows* 11 Enterprise trong chế độ S"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Đồ họa Intel® Arc™ A310",
      "tr_GR387": "Đồ họa Intel® Arc™ A350M",
      "tr_GR388": "Đồ họa Intel® Arc™ A370M",
      "tr_GR389": "Đồ họa Intel® Arc™ A380",
      "tr_GR390": "Đồ họa Intel® Arc™ A530M",
      "tr_GR391": "Đồ họa Intel® Arc™ A550M",
      "tr_GR392": "Đồ họa Intel® Arc™ A570M",
      "tr_GR393": "Đồ họa Intel® Arc™ A580",
      "tr_GR394": "Đồ họa Intel® Arc™ A730M",
      "tr_GR395": "Đồ họa Intel® Arc™ A750",
      "tr_GR396": "Đồ họa Intel® Arc™ A770",
      "tr_GR397": "Đồ họa Intel® Arc™ A770M",
      "tr_GR398": "Đồ họa Intel® Arc™",
      "tr_GR399": "Đồ họa Intel® Arc™ Pro A30M",
      "tr_GR400": "Đồ họa Intel® Arc™ Pro A40/A50",
      "tr_GR401": "Đồ họa Intel® Arc™ Pro A60",
      "tr_GR402": "Đồ họa Intel® Arc™ Pro A60M",
      "tr_GR403": "Đồ họa Intel®",
      "tr_GR404": "Đồ họa HD Intel® 2000",
      "tr_GR405": "Đồ họa HD Intel® 2500",
      "tr_GR406": "Đồ họa HD Intel® 3000",
      "tr_GR407": "Đồ họa HD Intel® 400",
      "tr_GR408": "Đồ họa HD Intel® 4000",
      "tr_GR409": "Đồ họa HD Intel® 405",
      "tr_GR410": "Đồ họa HD Intel® 4200",
      "tr_GR411": "Đồ họa HD Intel® 4400",
      "tr_GR412": "Đồ họa HD Intel® 4600",
      "tr_GR413": "Đồ họa HD Intel® 500",
      "tr_GR414": "Đồ họa HD Intel® 5000",
      "tr_GR415": "Đồ họa HD Intel® 505",
      "tr_GR416": "Đồ họa HD Intel® 510",
      "tr_GR417": "Đồ họa HD Intel® 515",
      "tr_GR418": "Đồ họa HD Intel® 520",
      "tr_GR419": "Đồ họa HD Intel® 530",
      "tr_GR420": "Đồ họa HD Intel® 5300",
      "tr_GR421": "Đồ họa HD Intel® 5500",
      "tr_GR422": "Đồ họa HD Intel® 5600",
      "tr_GR423": "Đồ họa HD Intel® 6000",
      "tr_GR424": "Đồ họa HD Intel® 610",
      "tr_GR425": "Đồ họa HD Intel® 615",
      "tr_GR426": "Đồ họa HD Intel® 620",
      "tr_GR427": "Đồ họa HD Intel® 630",
      "tr_GR428": "Đồ họa HD Intel®",
      "tr_GR429": "Đồ họa Intel® Iris® 5100",
      "tr_GR430": "Đồ họa Intel® Iris® 540",
      "tr_GR431": "Đồ họa Intel® Iris® 550",
      "tr_GR432": "Đồ họa Intel® Iris® 6100",
      "tr_GR433": "Đồ họa Intel® Iris® Plus 640",
      "tr_GR434": "Đồ họa Intel® Iris® Plus 645",
      "tr_GR435": "Đồ họa Intel® Iris® Plus 650",
      "tr_GR436": "Đồ họa Intel® Iris® Plus 655",
      "tr_GR437": "Đồ họa Intel® Iris® Plus",
      "tr_GR438": "Đồ họa Intel® Iris® Pro 5200",
      "tr_GR439": "Đồ họa Intel® Iris® Pro 580",
      "tr_GR440": "Đồ họa Intel® Iris® Pro 6200",
      "tr_GR441": "Đồ họa Intel® Iris® X<sup>e</sup>",
      "tr_GR442": "Đồ họa Intel® Iris® X<sup>e</sup> MAX A220M",
      "tr_GR443": "Đồ họa Intel® Iris® X<sup>e</sup> MAX",
      "tr_GR444": "Đồ họa Tích hợp Intel®",
      "tr_GR445": "Đồ họa UHD Intel® 600",
      "tr_GR446": "Đồ họa UHD Intel® 605",
      "tr_GR447": "Đồ họa UHD Intel® 610",
      "tr_GR448": "Đồ họa UHD Intel® 615",
      "tr_GR449": "Đồ họa UHD Intel® 617",
      "tr_GR450": "Đồ họa UHD Intel® 620",
      "tr_GR451": "Đồ họa UHD Intel® 630",
      "tr_GR452": "Đồ họa UHD Intel® 710",
      "tr_GR453": "Đồ họa UHD Intel® 730",
      "tr_GR454": "Đồ họa UHD Intel® 770",
      "tr_GR455": "Đồ họa UHD Intel®",
      "tr_GR456": "Đồ họa UHD Intel®",
      "tr_GR457": "Đồ họa UHD Intel®",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 với Thiết kế Max-Q",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti với Thiết kế Max-Q",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 với Thiết kế Max-Q",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 với Thiết kế Max-Q",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 với Thiết kế Max-Q",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 với Thiết kế Max-Q",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti với Thiết kế Max-Q",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti với Thiết kế Max-Q",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 với Thiết kế Max-Q",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 với Thiết kế Max-Q",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super với Thiết kế Max-Q",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 với Thiết kế Max-Q",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super với Thiết kế Max-Q",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 với Thiết kế Max-Q",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 với Thiết kế Max-Q",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 với Thiết kế Max-Q",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 với Thiết kế Max-Q",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 với Thiết kế Max-Q",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 với Thiết kế Max-Q",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 với Thiết kế Max-Q",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 với Thiết kế Max-Q",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 với Thiết kế Max-Q",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 với Thiết kế Max-Q",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Thế hệ Ada",
      "tr_GR773": "NVIDIA* RTX 3000 Thế hệ Ada",
      "tr_GR774": "NVIDIA* RTX 3500 Thế hệ Ada",
      "tr_GR775": "NVIDIA* RTX 4000 Thế hệ Ada",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Thế hệ Ada",
      "tr_GR777": "NVIDIA* RTX 4500 Thế hệ Ada",
      "tr_GR778": "NVIDIA* RTX 5000 Thế hệ Ada",
      "tr_GR779": "NVIDIA* RTX 5880 Thế hệ Ada",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Thế hệ Ada",
      "tr_GR811": "NVIDIA* RTX 1000 Thế hệ Ada",
      "tr_GR812": "Đồ họa Intel® Arc™",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Thế hệ Ada",
      "tr_GR815": "Khe cắm kép AMD Radeon* Pro W7900"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Mua ngay",
    "tr_textTryagain": "Thử lại",
    "tr_textBuynowErr": "Do gián đoạn kết nối, tùy chọn Mua ngay không khả dụng",
    "tr_chatbotPlaceholder_text": "Hỏi tôi bất cứ điều gì về thiết bị",
    "tr_deviceSpecs_text": "Thông số Kỹ thuật Thiết bị"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i3 tích&nbsp;hợp Công&nbsp;nghệ Intel®&nbsp;Hybrid"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Bộ xử lý Intel®&nbsp;Core™&nbsp;i5 tích&nbsp;hợp Công&nbsp;nghệ Intel®&nbsp;Hybrid"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Trang bị công nghệ Intel® để sở hữu trải nghiệm chơi game đẳng cấp hơn",
                "tr_subtitle": "Tăng cường sự sống động cho Marvel’s Avengers* với các giải pháp tối ưu hóa máy tính chơi game của Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Trải nghiệm Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Nơi những game thủ đỉnh nhất thế giới cạnh tranh",
                "tr_subtitle": "Chuỗi sự kiện diễn ra lâu nhất trong lịch sử video game, Intel® Extreme Masters đặt ra các tiêu chuẩn cho các môn e-sport.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Chơi như dân chuyên thực thụ",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Các nhà vô địch tin dùng Intel",
                "tr_subtitle": "Sau khi giành chức vô địch Giải đấu Bắc Mỹ Rainbow Six Siege* năm 2021, Soniqs suy ngẫm về lịch sử lịch sử chơi game của họ, tương lai của bộ môn thể thao điện tử và quan hệ đối tác của họ với Intel.",
                "tr_pretitle": "Với sự tham gia của Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "Sẵn sàng VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "VR sống động",
                "tr_subtitle": "Trải nghiệm thế giới thực tế ảo sống động và như thật nhất trên máy tính. Với bộ xử lý Intel® Core™ mới nhất, bạn có thể trở thành bất kỳ ai, đi đến bất kỳ đâu và chơi game trong không gian hoàn toàn khác.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Chơi hơn 100 game dùng bảng điều khiển chất lượng cao như Sea of Thieves* và Psychonauts* 2 trên máy tính chạy Windows*, điện thoại di động và máy tính bảng của bạn với Xbox* Game Pass Ultimate và bộ điều khiển tương thích.",
                "titleArray": [
                  {
                    "tr_title": "Chơi cùng nhau trên khắp các thiết bị",
                    "tr_subtitle": "Chạm vào trái tim của Xbox* với cộng đồng hàng triệu người chơi game đã sẵn sàng và chờ đợi để cùng chơi. Kết nối và chơi với những người khác từ thư viện game được chia sẻ dù họ đang ở nửa kia của thế giới hay đang ngồi ngay cạnh bạn."
                  },
                  {
                    "tr_title": "Chơi game trên máy tính Windows*",
                    "tr_subtitle": "Trải nghiệm danh mục game dùng bảng điều khiển từ đám mây bằng ứng dụng Xbox* dành cho Windows* và bộ điều khiển tương thích."
                  },
                  {
                    "tr_title": "Lựa chọn và chơi",
                    "tr_subtitle": "Thưởng thức các game Xbox* tuyệt vời ở nhiều nơi hơn bao giờ hết. Chơi hơn 100 game trên máy tính, điện thoại di động và máy tính bảng dù bạn đang ở nhà, tại quán cà phê hay bất kỳ nơi nào khác với kết nối Internet tốc độ cao."
                  },
                  {
                    "tr_title": "Trải nghiệm không bộ điều khiển thú vị",
                    "tr_subtitle": "Chơi game bằng điều khiển cảm ứng Xbox* mở ra những cách thức mới để chơi game, mang lại trải nghiệm quen thuộc và hỗ trợ mức độ chơi mà bạn đã quen khi sử dụng bộ điều khiển vật lý."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Tiêu điểm",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Hơn 100 trải nghiệm AI",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Điều khiển nhiều hơn, chạm ít hơn",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Luôn trong khung hình, dù ở bất cứ đâu",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sáng tạo",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Hiệu chỉnh ánh sáng như chuyên gia",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Thêm các hiệu ứng ấn tượng một cách dễ dàng",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Phối lại các bài hát bằng sức mạnh của AI",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Năng suất làm việc",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Tăng cường năng suất và quyền riêng tư",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Thêm thời gian sử dụng mà không cần cắm nguồn",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Thế giới của bạn. Trên các thiết bị của bạn.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Giải trí",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Phát trực tiếp mượt mà, ngay cả khi không cắm nguồn",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Chơi game nhanh, sống động",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Tiêu điểm",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Hơn 100 trải nghiệm AI",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Thêm dấu ấn cá nhân vào những bài hát bạn yêu thích",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Nhìn thấy những gì bạn tưởng tượng trong thời gian thực",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sáng tạo",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Cách nhanh nhất để có sản phẩm cuối cùng",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Khiến mọi hình ảnh trở nên hoàn hảo",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Năng suất làm việc",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Tăng cường năng suất và quyền riêng tư",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Hiệu quả hơn. Tự do hơn.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Thế giới của bạn. Trên các thiết bị của bạn.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Giải trí",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Phát trực tiếp HD mượt mà, ngay cả khi không cắm nguồn",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Chơi game sống động, ở nhiều nơi hơn",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Ý tưởng bay&nbsp;xa",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Chỉnh sửa ảnh dễ dàng",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Tạo ảnh trưng bày chỉ trong vài giây",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Bạn gõ. AI tạo.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hiệu năng mở&nbsp;rộng",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Đường tắt để chơi game siêu mượt",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Thêm thời gian sử dụng mà không cần cắm nguồn",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra sống động",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hiệu suất bứt&nbsp;phá",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Trợ thủ AI đắc lực",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Gọi video hoàn hảo",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Thế giới của bạn. Trên các thiết bị của bạn.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bảo mật hơn",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Các biện pháp bảo vệ tích hợp giúp dữ liệu của bạn an toàn và riêng tư hơn",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Ý tưởng bay&nbsp;xa",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Chỉnh sửa ảnh dễ dàng",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "AI. Dựng phim nhanh như chớp.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Tách nhanh lời bài hát yêu thích",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Tiết kiệm hàng giờ chỉnh sửa với AI",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hiệu năng mở&nbsp;rộng",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Đường tắt để chơi game siêu mượt",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Thêm thời gian sử dụng mà không cần cắm nguồn",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra sống động",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hiệu suất bứt&nbsp;phá",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Trợ thủ AI đắc lực",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Gọi video hoàn hảo",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Thế giới của bạn. Trên các thiết bị của bạn.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bảo mật hơn",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Các biện pháp bảo vệ tích hợp giúp dữ liệu của bạn an toàn và riêng tư hơn",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mọi nội dung giải trí của bạn. Trong một máy tính.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Với một chiếc máy tính kiêm vai trò là trung tâm giải trí, bạn cần có đủ dung lượng lưu trữ cho tất cả những bài hát, video và tựa game yêu thích, cũng như kết hợp bộ xử lý và màn hình hiển thị một cách phù hợp để tạo ra hình ảnh sống động. Máy tính này có cả ba yếu tố.",
                "titleArray": [
                  {
                    "tr_title": "Sẵn sàng cho trải nghiệm hình ảnh thế hệ tiếp theo",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Xây dựng thư viện kỹ thuật số của bạn",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Cửa sổ mở ra thế giới giải trí",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Chơi game và truyền phát trực tiếp nội dung mượt mà",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Xây dựng thư viện kỹ thuật số của bạn",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Cửa sổ mở ra thế giới giải trí",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Năng suất cao hơn. Ở&nbsp;bất&nbsp;cứ&nbsp;nơi&nbsp;nào.",
              "tr_tileTitle1": "Làm được nhiều việc hơn. Nhanh&nbsp;chóng.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Máy tính này sở hữu sự cân bằng hoàn hảo giữa sức mạnh và tính cơ động. Bắt đầu từ bộ xử lý dành cho những thiết kế mỏng và nhẹ, chiếc máy tính này cũng được trang bị tính năng giúp bạn duy trì kết nối Wi&#8209;Fi liên tục và loại pin phù hợp để dùng lâu hơn mà không cần cắm sạc trong thời gian dài hơn.",
                "tr_subtitle1": "Máy tính này sở hữu sự cân bằng hoàn hảo giữa sức mạnh và hiệu năng để giúp bạn hoàn thành nhiều việc hơn trong thời gian ngắn hơn. Sự kết hợp giữa bộ xử lý mạnh mẽ được tăng tốc bởi bộ nhớ dồi dào và Wi-Fi nhanh như chớp sẽ sắp xếp các tác vụ hàng ngày của bạn hợp lý hơn.",
                "tr_subtitle2": "Máy tính này sở hữu sự cân bằng hoàn hảo giữa sức mạnh và tính cơ động. Bắt đầu từ bộ xử lý dành cho những thiết kế mỏng và nhẹ, chiếc máy tính này cũng đặc biệt có loại Wi‑Fi giúp bạn duy trì kết nối và bộ nhớ phù hợp để đa nhiệm.",
                "titleArray": [
                  {
                    "tr_title": "Thiết kế để thực hiện nhiều công việc hơn",
                    "tr_title1": "Hiệu năng thông minh tạo nên sự khác biệt",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Kết nối tốt nhất trong phân khúc",
                    "tr_title1": "Kết nối với những điều quan trọng",
                    "tr_title2": "Một cải tiến lớn trong Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Dùng lâu hơn mà không cần sạc",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Chuyển đổi qua lại các chương trình như chuyên gia",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tự do sáng tạo",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Sáng tạo nội dung là một trong những tác vụ đòi hỏi cao nhất đối với máy tính. May mắn là các bộ phận này có sức mạnh để sắp xếp hợp lý quy trình sáng tạo của bạn.",
                "titleArray": [
                  {
                    "tr_title": "Cách sáng tạo thông minh hơn",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Thổi sức sống vào hình ảnh của bạn",
                    "tr_title1": "Tham gia sáng tạo",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Bộ nhớ để đa nhiệm",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hãy tìm hiểu bên trong máy&nbsp;tính&nbsp;này",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Đây là ba yếu tố chính cần chú ý khi chọn máy tính mới. Khi xác định được cách bạn sẽ sử dụng máy tính mới, bạn đã tiến một bước dài đến việc xác định mình sẽ cần ba yếu tố này ở mức độ nào. Các yếu tố này là:",
                "titleArray": [
                  {
                    "tr_title": "Hiệu năng để chơi game và phát trực tiếp nội dung",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bộ lưu trữ",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Bộ nhớ",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Mọi nội dung giải trí của bạn. Trong một máy tính.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Với một chiếc máy tính kiêm vai trò là trung tâm giải trí, bạn cần có đủ dung lượng lưu trữ cho tất cả những bài hát, video và tựa game yêu thích, cũng như kết hợp bộ xử lý và màn hình hiển thị một cách phù hợp để tạo ra hình ảnh sống động. Máy tính này có cả ba yếu tố.",
                "titleArray": [
                  {
                    "tr_title": "Sẵn sàng cho trải nghiệm hình ảnh thế hệ tiếp theo",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Xây dựng thư viện kỹ thuật số của bạn",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Cửa sổ mở ra thế giới giải trí",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Chơi game và truyền phát trực tiếp nội dung mượt mà",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Xây dựng thư viện kỹ thuật số của bạn",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Cửa sổ mở ra thế giới giải trí",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Bộ nhớ",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Màn hình",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Đa nhiệm tốc độ cao",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ĐA NHIỆM TỐC ĐỘ CAO",
                "tr_subtitle": "Làm nhiều hơn mà không bị chậm máy",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để đa nhiệm:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có thể xử lý nhiều chương trình cùng một lúc."
                  },
                  {
                    "tr_title": "Wi‑Fi nhanh, mạnh và đáng tin cậy."
                  },
                  {
                    "tr_title": "Bộ nhớ chuyển đổi liền mạch giữa các ứng dụng."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chat video",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHAT VIDEO",
                "tr_subtitle": "Kết nối rõ nét",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để trò chuyện qua video:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có thể ghi hình và chia sẻ hình ảnh cùng một lúc."
                  },
                  {
                    "tr_title": "Wi‑Fi nhanh, mạnh và đáng tin cậy."
                  },
                  {
                    "tr_title": "Một webcam sẽ giúp bạn kết nối rõ nét."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sẵn sàng VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR sống động",
                "tr_subtitle": "Cần những gì để tạo ra một thế giới ảo hoàn toàn? Trên máy tính, bạn cần kết hợp bộ xử lý, thẻ đồ họa và bộ nhớ.",
                "titleArray": [
                  {
                    "tr_title": "Tăng cường thế giới ảo",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Chân thực đến từng chi tiết",
                    "tr_title1": "Trải nghiệm đắm mình",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Biên tập video",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "BIÊN TẬP VIDEO",
                "tr_subtitle": "Khiến phim ảnh trở nên ảo&nbsp;diệu",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để chỉnh sửa video:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có thể xử lý các tệp lớn."
                  },
                  {
                    "tr_title": "Bộ nhớ giúp phần mềm chỉnh sửa nhanh và nhạy."
                  },
                  {
                    "tr_title": "Đồ họa giúp cắt giảm thời gian kết xuất."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Đa nhiệm tốc độ cao",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ĐA NHIỆM TỐC ĐỘ CAO",
                "tr_subtitle": "Làm nhiều hơn mà không bị chậm máy",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để đa nhiệm:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có thể xử lý nhiều chương trình cùng một lúc."
                  },
                  {
                    "tr_title": "Wi‑Fi nhanh, mạnh và đáng tin cậy."
                  },
                  {
                    "tr_title": "Bộ nhớ chuyển đổi liền mạch giữa các ứng dụng."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sẵn sàng VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR sống động",
                "tr_subtitle": "Cần những gì để tạo ra một thế giới ảo hoàn toàn? Trên máy tính, bạn cần kết hợp bộ xử lý, thẻ đồ họa và bộ nhớ.",
                "titleArray": [
                  {
                    "tr_title": "Tăng cường thế giới ảo",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Chân thực đến từng chi tiết",
                    "tr_title1": "Trải nghiệm đắm mình",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Dựng mô hình 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "DỰNG MÔ HÌNH 3D",
                "tr_subtitle": "Sáng tạo ở cấp độ tiếp theo",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để dựng mô hình 3D:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có thể xử lý phần mềm sáng tạo nâng cao."
                  },
                  {
                    "tr_title": "Bộ nhớ có thể nhanh chóng hiển thị các tệp lớn."
                  },
                  {
                    "tr_title": "Đồ họa để tạo và chỉnh sửa ảnh mạnh mẽ."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Cộng tác trong thời gian thực",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CỘNG TÁC TRONG THỜI GIAN THỰC",
                "tr_subtitle": "Làm việc từ bất cứ đâu",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để cộng tác trực tuyến:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý để chạy phần mềm văn phòng."
                  },
                  {
                    "tr_title": "Wi‑Fi mà bạn có thể tin cậy."
                  },
                  {
                    "tr_title": "Một webcam ghi hình ở chất lượng HD tuyệt vời."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sẵn sàng VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR sống động",
                "tr_subtitle": "Cần những gì để tạo ra một thế giới ảo hoàn toàn? Trên máy tính, bạn cần kết hợp bộ xử lý, thẻ đồ họa và bộ nhớ.",
                "titleArray": [
                  {
                    "tr_title": "Tăng cường thế giới ảo",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Chân thực đến từng chi tiết",
                    "tr_title1": "Trải nghiệm đắm mình",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Máy tính xách tay Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Những máy tính xách tay mỏng và nhẹ như thế này không được cho là mạnh mẽ đến vậy",
                "tr_subtitle": "Được thiết kế cho hiệu năng di động, máy tính xách tay Intel® Evo™ sở hữu mọi thứ từ tốc độ, đồ họa đến thời lượng pin mà bạn cần, tất cả trong một thiết kế mỏng và nhẹ tuyệt vời nhất.",
                "titleArray": [
                  {
                    "tr_title": "Máy tính xách tay có ý nghĩa nhiều hơn bao giờ hết, vì vậy chúng tôi đã thiết kế để đảm bảo thiết bị này có thể làm được nhiều việc hơn so với trước đây.",
                    "tr_subtitle": "Sử dụng màn hình cảm ứng để khám phá, xoay và thu phóng."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Mở lại máy cực nhanh",
                    "tr_subtitle": "Với tính năng khởi động tức thì, máy tính của bạn có thể chuyển từ trạng&nbsp;thái&nbsp;ngủ sang sẵn sàng sử dụng trong vòng chưa đầy một giây."
                  },
                  {
                    "tr_title": "Hình ảnh sống động",
                    "tr_subtitle": "Màn hình đẹp giúp thổi sức sống cho hình ảnh của bạn với độ phân giải rõ nét và màu sắc sống động."
                  },
                  {
                    "tr_title": "Hoàn thành công việc một cách nhanh chóng",
                    "tr_subtitle": "Tận hưởng hiệu năng siêu nhạy từ bộ xử lý Intel® Core™ thế hệ thứ 11."
                  },
                  {
                    "tr_title": "Sử dụng lâu hơn mà không cần cắm sạc",
                    "tr_subtitle": "Duy trì hoạt động với&nbsp;một&nbsp;chiếc&nbsp;máy&nbsp;tính tối&nbsp;ưu&nbsp;hóa&nbsp;thời&nbsp;lượng&nbsp;pin&nbsp;của&nbsp;bạn và&nbsp;sạc&nbsp;lại&nbsp;trong&nbsp;nháy&nbsp;mắt."
                  },
                  {
                    "tr_title": "Kết nối cáp đa năng",
                    "tr_subtitle": "Giúp việc cắm các thiết bị khác vào máy tính của bạn trở nên đơn giản, nhanh chóng và an toàn với công nghệ Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Kết&nbsp;nối&nbsp;nhanh&nbsp;chóng, đáng&nbsp;tin&nbsp;cậy",
                    "tr_subtitle": "Trò chuyện video hoặc chia sẻ tệp với kết nối mà bạn có thể tin cậy khi sử dụng Intel® Wi‑Fi 6 (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Máy tính xách tay Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Hơn cả những gì bạn cần ở một chiếc máy tính xách tay mỏng và nhẹ.",
                "tr_subtitle": "Máy tính xách tay Intel® Evo™ được thiết kế cho hiệu năng di động và được thiết kế để mang đến cho bạn trải nghiệm tốt nhất<sup>2</sup>, bất kể bạn đang làm gì.",
                "titleArray": [
                  {
                    "tr_title": "Máy tính xách tay có ý nghĩa nhiều hơn bao giờ hết, vì vậy chúng tôi đã thiết kế để đảm bảo thiết bị này có thể làm được nhiều việc hơn so với trước đây.",
                    "tr_subtitle": "Sử dụng màn hình cảm ứng để khám phá, xoay và thu phóng."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Mở lại máy cực nhanh",
                    "tr_subtitle": "Với tính năng khởi động tức thì, máy tính của bạn có thể chuyển từ trạng&nbsp;thái&nbsp;ngủ sang sẵn sàng sử dụng trong vòng chưa đầy một giây."
                  },
                  {
                    "tr_title": "Hình ảnh sống động",
                    "tr_subtitle": "Màn hình đẹp giúp thổi sức sống cho hình ảnh của bạn với độ phân giải rõ nét và màu sắc sống động."
                  },
                  {
                    "tr_title": "Hiệu năng mang tính cách mạng",
                    "tr_subtitle": "Tăng thêm sức mạnh ở những nơi bạn cần nhất với hiệu năng thông minh từ bộ xử lý Intel® Core™ thế hệ thứ 12."
                  },
                  {
                    "tr_title": "Sử dụng lâu hơn mà không cần cắm sạc",
                    "tr_subtitle": "Duy trì hoạt động với&nbsp;một&nbsp;chiếc&nbsp;máy&nbsp;tính tối&nbsp;ưu&nbsp;hóa&nbsp;thời&nbsp;lượng&nbsp;pin&nbsp;của&nbsp;bạn và&nbsp;sạc&nbsp;lại&nbsp;trong&nbsp;nháy&nbsp;mắt."
                  },
                  {
                    "tr_title": "Kết nối cáp đa năng",
                    "tr_subtitle": "Giúp việc cắm các thiết bị khác vào máy tính của bạn trở nên đơn giản, nhanh chóng và an toàn với công nghệ Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Kết&nbsp;nối&nbsp;nhanh&nbsp;chóng, đáng&nbsp;tin&nbsp;cậy",
                    "tr_subtitle": "Trò chuyện video hoặc chia sẻ tập tin với kết nối mà bạn có thể tin tưởng khi sử dụng Intel® Wi‑Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Máy tính xách tay Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Những máy tính xách tay mỏng và nhẹ như thế này không được cho là mạnh mẽ đến vậy",
                "tr_subtitle": "Được thiết kế cho hiệu năng di động, máy tính xách tay Intel® Evo™ sở hữu mọi thứ từ tốc độ, đồ họa đến thời lượng pin mà bạn cần, tất cả trong một thiết kế mỏng và nhẹ tuyệt vời nhất.",
                "titleArray": [
                  {
                    "tr_title": "Máy tính xách tay có ý nghĩa nhiều hơn bao giờ hết, vì vậy chúng tôi đã thiết kế để đảm bảo thiết bị này có thể làm được nhiều việc hơn so với trước đây.",
                    "tr_subtitle": "Sử dụng màn hình cảm ứng để khám phá, xoay và thu phóng."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Mở lại máy cực nhanh",
                    "tr_subtitle": "Với tính năng khởi động tức thì, máy tính của bạn có thể chuyển từ trạng&nbsp;thái&nbsp;ngủ sang sẵn sàng sử dụng trong vòng chưa đầy một giây."
                  },
                  {
                    "tr_title": "Hình ảnh sống động",
                    "tr_subtitle": "Màn hình đẹp giúp thổi sức sống cho hình ảnh của bạn với độ phân giải rõ nét và màu sắc sống động."
                  },
                  {
                    "tr_title": "Hoàn thành công việc một cách nhanh chóng",
                    "tr_subtitle": "Tận hưởng hiệu năng siêu nhạy từ bộ xử lý Intel® Core™ thế hệ thứ 11."
                  },
                  {
                    "tr_title": "Sử dụng lâu hơn mà không cần cắm sạc",
                    "tr_subtitle": "Duy trì hoạt động với&nbsp;một&nbsp;chiếc&nbsp;máy&nbsp;tính tối&nbsp;ưu&nbsp;hóa&nbsp;thời&nbsp;lượng&nbsp;pin&nbsp;của&nbsp;bạn và&nbsp;sạc&nbsp;lại&nbsp;trong&nbsp;nháy&nbsp;mắt."
                  },
                  {
                    "tr_title": "Kết nối cáp đa năng",
                    "tr_subtitle": "Giúp việc cắm các thiết bị khác vào máy tính của bạn trở nên đơn giản, nhanh chóng và an toàn với công nghệ Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Kết&nbsp;nối&nbsp;nhanh&nbsp;chóng, đáng&nbsp;tin&nbsp;cậy",
                    "tr_subtitle": "Trò chuyện video hoặc chia sẻ tệp với kết nối mà bạn có thể tin cậy khi sử dụng Intel® Wi‑Fi 6 (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Máy tính xách tay Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Hơn cả những gì bạn cần ở một chiếc máy tính xách tay mỏng và nhẹ.",
                "tr_subtitle": "Máy tính xách tay Intel® Evo™ được thiết kế cho hiệu năng di động và được thiết kế để mang đến cho bạn trải nghiệm tốt nhất<sup>2</sup>, bất kể bạn đang làm gì.",
                "titleArray": [
                  {
                    "tr_title": "Máy tính xách tay có ý nghĩa nhiều hơn bao giờ hết, vì vậy chúng tôi đã thiết kế để đảm bảo thiết bị này có thể làm được nhiều việc hơn so với trước đây.",
                    "tr_subtitle": "Sử dụng màn hình cảm ứng để khám phá, xoay và thu phóng."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Mở lại máy cực nhanh",
                    "tr_subtitle": "Với tính năng khởi động tức thì, máy tính của bạn có thể chuyển từ trạng&nbsp;thái&nbsp;ngủ sang sẵn sàng sử dụng trong vòng chưa đầy một giây."
                  },
                  {
                    "tr_title": "Hình ảnh sống động",
                    "tr_subtitle": "Màn hình đẹp giúp thổi sức sống cho hình ảnh của bạn với độ phân giải rõ nét và màu sắc sống động."
                  },
                  {
                    "tr_title": "Hiệu năng mang tính cách mạng",
                    "tr_subtitle": "Tăng thêm sức mạnh ở những nơi bạn cần nhất với hiệu năng thông minh từ bộ xử lý Intel® Core™ thế hệ thứ 12."
                  },
                  {
                    "tr_title": "Sử dụng lâu hơn mà không cần cắm sạc",
                    "tr_subtitle": "Duy trì hoạt động với&nbsp;một&nbsp;chiếc&nbsp;máy&nbsp;tính tối&nbsp;ưu&nbsp;hóa&nbsp;thời&nbsp;lượng&nbsp;pin&nbsp;của&nbsp;bạn và&nbsp;sạc&nbsp;lại&nbsp;trong&nbsp;nháy&nbsp;mắt."
                  },
                  {
                    "tr_title": "Kết nối cáp đa năng",
                    "tr_subtitle": "Giúp việc cắm các thiết bị khác vào máy tính của bạn trở nên đơn giản, nhanh chóng và an toàn với công nghệ Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Kết&nbsp;nối&nbsp;nhanh&nbsp;chóng, đáng&nbsp;tin&nbsp;cậy",
                    "tr_subtitle": "Trò chuyện video hoặc chia sẻ tập tin với kết nối mà bạn có thể tin tưởng khi sử dụng Intel® Wi‑Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Máy tính xách tay Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Hơn cả những gì bạn cần ở một chiếc máy tính xách tay mỏng và nhẹ.",
                "tr_subtitle": "Máy tính xách tay Intel® Evo™ được thiết kế cho hiệu năng di động và được thiết kế để mang đến cho bạn trải nghiệm tốt nhất<sup>2</sup>, bất kể bạn đang làm gì.",
                "titleArray": [
                  {
                    "tr_title": "Máy tính xách tay có ý nghĩa nhiều hơn bao giờ hết, vì vậy chúng tôi đã thiết kế để đảm bảo thiết bị này có thể làm được nhiều việc hơn so với trước đây.",
                    "tr_subtitle": "Sử dụng màn hình cảm ứng để khám phá, xoay và thu phóng."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Mở lại máy cực nhanh",
                    "tr_subtitle": "Với tính năng khởi động tức thì, máy tính của bạn có thể chuyển từ trạng&nbsp;thái&nbsp;ngủ sang sẵn sàng sử dụng trong vòng chưa đầy một giây."
                  },
                  {
                    "tr_title": "Hình ảnh sống động",
                    "tr_subtitle": "Màn hình đẹp giúp thổi sức sống cho hình ảnh của bạn với độ phân giải rõ nét và màu sắc sống động."
                  },
                  {
                    "tr_title": "Hiệu năng mang tính cách mạng",
                    "tr_subtitle": "Tăng thêm sức mạnh ở những nơi bạn cần nhất với hiệu năng thông minh từ bộ xử lý Intel® Core™ thế hệ thứ 13."
                  },
                  {
                    "tr_title": "Sử dụng lâu hơn mà không cần cắm sạc",
                    "tr_subtitle": "Duy trì hoạt động với&nbsp;một&nbsp;chiếc&nbsp;máy&nbsp;tính tối&nbsp;ưu&nbsp;hóa&nbsp;thời&nbsp;lượng&nbsp;pin&nbsp;của&nbsp;bạn và&nbsp;sạc&nbsp;lại&nbsp;trong&nbsp;nháy&nbsp;mắt."
                  },
                  {
                    "tr_title": "Kết nối cáp đa năng",
                    "tr_subtitle": "Giúp việc cắm các thiết bị khác vào máy tính của bạn trở nên đơn giản, nhanh chóng và an toàn với công nghệ Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Kết&nbsp;nối&nbsp;nhanh&nbsp;chóng, đáng&nbsp;tin&nbsp;cậy",
                    "tr_subtitle": "Trò chuyện video hoặc chia sẻ tập tin với kết nối mà bạn có thể tin tưởng khi sử dụng Intel® Wi‑Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Máy tính xách tay Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Hơn cả những gì bạn cần ở một chiếc máy tính xách tay mỏng và nhẹ.",
                "tr_subtitle": "Máy tính xách tay Intel® Evo™ được thiết kế cho hiệu năng di động và được thiết kế để mang đến cho bạn trải nghiệm tốt nhất<sup>2</sup>, bất kể bạn đang làm gì.",
                "titleArray": [
                  {
                    "tr_title": "Máy tính xách tay có ý nghĩa nhiều hơn bao giờ hết, vì vậy chúng tôi đã thiết kế để đảm bảo thiết bị này có thể làm được nhiều việc hơn so với trước đây.",
                    "tr_subtitle": "Sử dụng màn hình cảm ứng để khám phá, xoay và thu phóng."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Mở lại máy cực nhanh",
                    "tr_subtitle": "Với tính năng khởi động tức thì, máy tính của bạn có thể chuyển từ trạng&nbsp;thái&nbsp;ngủ sang sẵn sàng sử dụng trong vòng chưa đầy một giây."
                  },
                  {
                    "tr_title": "Hình ảnh sống động",
                    "tr_subtitle": "Màn hình đẹp giúp thổi sức sống cho hình ảnh của bạn với độ phân giải rõ nét và màu sắc sống động."
                  },
                  {
                    "tr_title": "Hiệu năng mang tính cách mạng",
                    "tr_subtitle": "Tăng thêm sức mạnh ở những nơi bạn cần nhất với hiệu năng thông minh từ bộ xử lý Intel® Core™ thế hệ thứ 13."
                  },
                  {
                    "tr_title": "Sử dụng lâu hơn mà không cần cắm sạc",
                    "tr_subtitle": "Duy trì hoạt động với&nbsp;một&nbsp;chiếc&nbsp;máy&nbsp;tính tối&nbsp;ưu&nbsp;hóa&nbsp;thời&nbsp;lượng&nbsp;pin&nbsp;của&nbsp;bạn và&nbsp;sạc&nbsp;lại&nbsp;trong&nbsp;nháy&nbsp;mắt."
                  },
                  {
                    "tr_title": "Kết nối cáp đa năng",
                    "tr_subtitle": "Giúp việc cắm các thiết bị khác vào máy tính của bạn trở nên đơn giản, nhanh chóng và an toàn với công nghệ Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Kết&nbsp;nối&nbsp;nhanh&nbsp;chóng, đáng&nbsp;tin&nbsp;cậy",
                    "tr_subtitle": "Trò chuyện video hoặc chia sẻ tập tin với kết nối mà bạn có thể tin tưởng khi sử dụng Intel® Wi‑Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Tiêu điểm",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Intel® Evo™ Edition là gì?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Tại sao nên chọn Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sáng tạo",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Sáng tạo hơn ở nhiều nơi hơn",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Chỉnh sửa ảnh tốc độ tối đa",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Sáng tạo nghệ thuật nguyên bản theo ý muốn",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Năng suất làm việc",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Tối đa hóa năng suất với Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Phát hiện deepfake bằng AI trước khi chia sẻ",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Thế giới của bạn. Trên các thiết bị của bạn.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tính lưu động",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Vẫy chào để điều khiển bằng cử chỉ",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Thời lượng pin không gián đoạn",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Tự động giữ kết nối cho bạn",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Tiêu điểm",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Intel® Evo™ Edition là gì?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Tại sao nên chọn Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sáng tạo",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Phát lại video nhanh, mượt mà",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Tạo những bài hit với sức mạnh của AI",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "Cách nhanh nhất để có sản phẩm cuối cùng",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Năng suất làm việc",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Tối đa hóa năng suất với Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Phát hiện deepfake bằng AI trước khi chia sẻ",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Thế giới của bạn. Trên các thiết bị của bạn.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tính lưu động",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Cộng tác từ hầu hết mọi nơi",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Thời lượng pin không gián đoạn",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Tự động giữ kết nối cho bạn",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mọi nội dung giải trí của bạn. Trong một máy tính.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Với một chiếc máy tính kiêm vai trò là trung tâm giải trí, bạn cần có đủ dung lượng lưu trữ cho tất cả những bài hát, video và tựa game yêu thích, cũng như kết hợp bộ xử lý và màn hình hiển thị một cách phù hợp để tạo ra hình ảnh sống động. Máy tính này có cả ba yếu tố.",
                "titleArray": [
                  {
                    "tr_title": "Chơi game và phát trực tiếp ấn tượng",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Xây dựng thư viện kỹ thuật số của bạn",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Cửa sổ mở ra thế giới giải trí",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Chơi game và truyền phát trực tiếp nội dung mượt mà",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Xây dựng thư viện kỹ thuật số của bạn",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Cửa sổ mở ra thế giới giải trí",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Năng suất cao hơn. Ở&nbsp;bất&nbsp;cứ&nbsp;nơi&nbsp;nào.",
              "tr_tileTitle1": "Làm được nhiều việc hơn. Nhanh&nbsp;chóng.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Máy tính này sở hữu sự cân bằng hoàn hảo giữa sức mạnh và tính cơ động. Bắt đầu từ bộ xử lý dành cho những thiết kế mỏng và nhẹ, chiếc máy tính này cũng được trang bị tính năng giúp bạn duy trì kết nối Wi&#8209;Fi liên tục và loại pin phù hợp để dùng lâu hơn mà không cần cắm sạc trong thời gian dài hơn.",
                "tr_subtitle1": "Máy tính này sở hữu sự cân bằng hoàn hảo giữa sức mạnh và hiệu năng để giúp bạn hoàn thành nhiều việc hơn trong thời gian ngắn hơn. Sự kết hợp giữa bộ xử lý mạnh mẽ được tăng tốc bởi bộ nhớ dồi dào và Wi-Fi nhanh như chớp sẽ sắp xếp các tác vụ hàng ngày của bạn hợp lý hơn.",
                "tr_subtitle2": "Máy tính này sở hữu sự cân bằng hoàn hảo giữa sức mạnh và tính cơ động. Bắt đầu từ bộ xử lý dành cho những thiết kế mỏng và nhẹ, chiếc máy tính này cũng đặc biệt có loại Wi‑Fi giúp bạn duy trì kết nối và bộ nhớ phù hợp để đa nhiệm.",
                "titleArray": [
                  {
                    "tr_title": "Hiệu năng thông minh tạo nên sự khác biệt",
                    "tr_title1": "Tăng tốc với hiệu năng thông minh",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Kết nối tốt nhất trong phân khúc",
                    "tr_title1": "Kết nối với những điều quan trọng",
                    "tr_title2": "Một cải tiến lớn trong Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Dùng lâu hơn mà không cần sạc",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Chuyển đổi qua lại các chương trình như chuyên gia",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Tự do sáng tạo",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Sáng tạo nội dung là một trong những tác vụ đòi hỏi cao nhất đối với máy tính. May mắn là các bộ phận này có sức mạnh để sắp xếp hợp lý quy trình sáng tạo của bạn.",
                "titleArray": [
                  {
                    "tr_title": "Tăng tính sáng tạo",
                    "tr_title1": "Thực hiện xuyên suốt các dự án sáng tạo",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Sáng tạo nâng cao",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Bộ nhớ để đa nhiệm",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hãy tìm hiểu bên trong máy&nbsp;tính&nbsp;này",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Đây là ba yếu tố chính cần chú ý khi chọn máy tính mới. Khi xác định được cách bạn sẽ sử dụng máy tính mới, bạn đã tiến một bước dài đến việc xác định mình sẽ cần ba yếu tố này ở mức độ nào. Các yếu tố này là:",
                "titleArray": [
                  {
                    "tr_title": "Bộ xử lý",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Bộ lưu trữ",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Bộ nhớ",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Tự do sáng tạo",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Sáng tạo nội dung là một trong những tác vụ đòi hỏi cao nhất đối với máy tính. May mắn là các bộ phận này có sức mạnh để sắp xếp hợp lý quy trình sáng tạo của bạn.",
                "titleArray": [
                  {
                    "tr_title": "Cách sáng tạo thông minh hơn",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Thổi sức sống vào hình ảnh của bạn",
                    "tr_title1": "Tham gia sáng tạo",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Bộ nhớ để đa nhiệm",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Năng suất cao hơn. Ở&nbsp;bất&nbsp;cứ&nbsp;nơi&nbsp;nào.",
              "tr_tileTitle1": "Làm được nhiều việc hơn. Nhanh&nbsp;chóng.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Máy tính này sở hữu sự cân bằng hoàn hảo giữa sức mạnh và tính cơ động. Bắt đầu từ bộ xử lý dành cho những thiết kế mỏng và nhẹ, chiếc máy tính này cũng được trang bị tính năng giúp bạn duy trì kết nối Wi&#8209;Fi liên tục và loại pin phù hợp để dùng lâu hơn mà không cần cắm sạc trong thời gian dài hơn.",
                "tr_subtitle1": "Máy tính này sở hữu sự cân bằng hoàn hảo giữa sức mạnh và hiệu năng để giúp bạn hoàn thành nhiều việc hơn trong thời gian ngắn hơn. Sự kết hợp giữa bộ xử lý mạnh mẽ được tăng tốc bởi bộ nhớ dồi dào và Wi-Fi nhanh như chớp sẽ sắp xếp các tác vụ hàng ngày của bạn hợp lý hơn.",
                "tr_subtitle2": "Máy tính này sở hữu sự cân bằng hoàn hảo giữa sức mạnh và tính cơ động. Bắt đầu từ bộ xử lý dành cho những thiết kế mỏng và nhẹ, chiếc máy tính này cũng đặc biệt có loại Wi‑Fi giúp bạn duy trì kết nối và bộ nhớ phù hợp để đa nhiệm.",
                "titleArray": [
                  {
                    "tr_title": "Thiết kế để thực hiện nhiều công việc hơn",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Kết nối tốt nhất trong phân khúc",
                    "tr_title1": "Kết nối với những điều quan trọng",
                    "tr_title2": "Một cải tiến lớn trong Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Dùng lâu hơn mà không cần sạc",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Chuyển đổi qua lại các chương trình như chuyên gia",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sẵn sàng VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR sống động",
                "tr_subtitle": "Cần những gì để tạo ra một thế giới ảo hoàn toàn? Trên máy tính, bạn cần kết hợp bộ xử lý, thẻ đồ họa và bộ nhớ.",
                "titleArray": [
                  {
                    "tr_title": "Tăng cường thế giới ảo",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Chân thực đến từng chi tiết",
                    "tr_title1": "Trải nghiệm đắm mình",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>Màn hình dịu mắt",
      "tr_sub_header": "Ánh sáng xanh có thể khiến mắt bạn khó chịu và gây xáo trộn giấc ngủ của bạn. Màn hình OLED của Samsung đạt tiêu chuẩn SGS Eye Care, mang lại sự dễ chịu cho mắt, hạn chế mỏi mắt bằng cách giảm bước sóng của ánh sáng xanh gây hại thay vì chỉ điều chỉnh màu sắc đơn&nbsp;thuần.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Ánh Sáng Xanh Ít Hại Hơn"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Siêu đủ đường",
      "tr_sub_header": "Thiết kế siêu mỏng, siêu nhẹ của OLED tối đa hóa chất lượng hình ảnh trong khi giảm thiểu trọng lượng."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Màu đen chân thực",
      "tr_sub_header": "0,0005 nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74%<br/><span class='conventional_text'>Thông thường</span>",
      "tr_sub_header": "120%<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Phát trực tiếp",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "PHÁT TRỰC TIẾP",
                "tr_subtitle": "Giải trí bất tận",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để phát trực tiếp:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có thể phát trực tiếp nội dung HD."
                  },
                  {
                    "tr_title": "Một màn hình hiển thị hình ảnh sáng, đẹp."
                  },
                  {
                    "tr_title": "Wi‑Fi mang đến ít thời gian tải và ít bộ đệm hơn."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Duyệt web",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "DUYỆT WEB",
                "tr_subtitle": "Internet trở nên dễ dàng",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để duyệt web:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có thể nhanh chóng tải các trang đa phương tiện."
                  },
                  {
                    "tr_title": "Wi‑Fi nhanh và đáng tin cậy."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chat video",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHAT VIDEO",
                "tr_subtitle": "Kết nối rõ nét",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để trò chuyện qua video:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có thể ghi hình và chia sẻ hình ảnh cùng một lúc."
                  },
                  {
                    "tr_title": "Wi‑Fi nhanh, mạnh và đáng tin cậy."
                  },
                  {
                    "tr_title": "Một webcam sẽ giúp bạn kết nối rõ nét."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Đa nhiệm tốc độ cao",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ĐA NHIỆM TỐC ĐỘ CAO",
                "tr_subtitle": "Làm nhiều hơn mà không bị chậm máy",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để đa nhiệm:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có thể xử lý nhiều chương trình cùng một lúc."
                  },
                  {
                    "tr_title": "Wi‑Fi nhanh, mạnh và đáng tin cậy."
                  },
                  {
                    "tr_title": "Bộ nhớ chuyển đổi liền mạch giữa các ứng dụng."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chat video",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHAT VIDEO",
                "tr_subtitle": "Kết nối rõ nét",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để trò chuyện qua video:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có thể ghi hình và chia sẻ hình ảnh cùng một lúc."
                  },
                  {
                    "tr_title": "Wi‑Fi nhanh, mạnh và đáng tin cậy."
                  },
                  {
                    "tr_title": "Một webcam sẽ giúp bạn kết nối rõ nét."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chơi game thông thường",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHƠI GAME THÔNG THƯỜNG",
                "tr_subtitle": "Đã đến lúc chơi",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để chơi game nhẹ nhàng:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý cung cấp khả năng chơi game mượt mà."
                  },
                  {
                    "tr_title": "Bộ nhớ có thể trò chuyện thoại và phát trực tiếp cùng một lúc."
                  },
                  {
                    "tr_title": "Đồ họa cung cấp hình ảnh tuyệt vời."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sửa ảnh",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SỬA ẢNH",
                "tr_subtitle": "Hình ảnh hoàn hảo đến từng điểm ảnh",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để chỉnh sửa ảnh:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có khả năng chỉnh sửa các nhóm ảnh lớn."
                  },
                  {
                    "tr_title": "Bộ nhớ tăng tốc phần mềm chỉnh sửa của bạn."
                  },
                  {
                    "tr_title": "Công nghệ Thunderbolt™ 4 cho phép bạn nhanh chóng chuyển tệp."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Đa nhiệm tốc độ cao",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ĐA NHIỆM TỐC ĐỘ CAO",
                "tr_subtitle": "Làm nhiều hơn mà không bị chậm máy",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để đa nhiệm:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có thể xử lý nhiều chương trình cùng một lúc."
                  },
                  {
                    "tr_title": "Wi‑Fi nhanh, mạnh và đáng tin cậy."
                  },
                  {
                    "tr_title": "Bộ nhớ chuyển đổi liền mạch giữa các ứng dụng."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Cộng tác trong thời gian thực",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CỘNG TÁC TRONG THỜI GIAN THỰC",
                "tr_subtitle": "Làm việc từ bất cứ đâu",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để cộng tác trực tuyến:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý để chạy phần mềm văn phòng."
                  },
                  {
                    "tr_title": "Wi‑Fi mà bạn có thể tin cậy."
                  },
                  {
                    "tr_title": "Một webcam ghi hình ở chất lượng HD tuyệt vời."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Biên tập video",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "BIÊN TẬP VIDEO",
                "tr_subtitle": "Khiến phim ảnh trở nên ảo&nbsp;diệu",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để chỉnh sửa video:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có thể xử lý các tệp lớn."
                  },
                  {
                    "tr_title": "Bộ nhớ giúp phần mềm chỉnh sửa nhanh và nhạy."
                  },
                  {
                    "tr_title": "Đồ họa giúp cắt giảm thời gian kết xuất."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Sửa ảnh",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "SỬA ẢNH",
                "tr_subtitle": "Hình ảnh hoàn hảo đến từng điểm ảnh",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để chỉnh sửa ảnh:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có khả năng chỉnh sửa các nhóm ảnh lớn."
                  },
                  {
                    "tr_title": "Bộ nhớ tăng tốc phần mềm chỉnh sửa của bạn."
                  },
                  {
                    "tr_title": "Công nghệ Thunderbolt™ 4 cho phép bạn nhanh chóng chuyển tệp."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Biên tập video",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "BIÊN TẬP VIDEO",
                "tr_subtitle": "Khiến phim ảnh trở nên ảo&nbsp;diệu",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để chỉnh sửa video:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có thể xử lý các tệp lớn."
                  },
                  {
                    "tr_title": "Bộ nhớ giúp phần mềm chỉnh sửa nhanh và nhạy."
                  },
                  {
                    "tr_title": "Đồ họa giúp cắt giảm thời gian kết xuất."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Dựng mô hình 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "DỰNG MÔ HÌNH 3D",
                "tr_subtitle": "Sáng tạo ở cấp độ tiếp theo",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để dựng mô hình 3D:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý có thể xử lý phần mềm sáng tạo nâng cao."
                  },
                  {
                    "tr_title": "Bộ nhớ có thể nhanh chóng hiển thị các tệp lớn."
                  },
                  {
                    "tr_title": "Đồ họa để tạo và chỉnh sửa ảnh mạnh mẽ."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Cộng tác trong thời gian thực",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CỘNG TÁC TRONG THỜI GIAN THỰC",
                "tr_subtitle": "Làm việc từ bất cứ đâu",
                "tr_subtitle1": "Đây là những gì chúng tôi tìm thấy trong chiếc máy tính cá nhân này, rất tốt để cộng tác trực tuyến:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Một bộ xử lý để chạy phần mềm văn phòng."
                  },
                  {
                    "tr_title": "Wi‑Fi mà bạn có thể tin cậy."
                  },
                  {
                    "tr_title": "Một webcam ghi hình ở chất lượng HD tuyệt vời."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Ứng dụng Intel® Unison™",
    "tr_title": "Mở khóa thế giới kết nối của bạn",
    "tr_subtitle": "Dễ dàng chia sẻ nội dung và hình ảnh khi nhận thông báo, cuộc gọi và tin nhắn trên điện thoại từ máy tính cá nhân.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "CHUYỂN NỘI DUNG VÀ ẢNH",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "NHẤC MÁY VÀ GỌI ĐI",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "GỬI VÀ NHẬN TIN NHẮN",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "QUẢN LÝ THÔNG BÁO ĐIỆN THOẠI",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "MỞ KHÓA THẾ GIỚI KẾT NỐI CỦA BẠN",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}