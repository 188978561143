const Library_ChromeBook_mobile_de = {
  "uiText": {
    "tr_endlessProgramsAndApps": "Programme und Apps ohne Ende",
    "tr_rethinkStorage": "Die Neudefinition von Speicher",
    "tr_alwaysOnSecurity": "Integrierte Sicherheit",
    "tr_extendedBatteryLife": "Längere Akkulaufzeit<sup>1</sup>",
    "tr_viewMore": "Mehr anzeigen",
    "tr_processorTitle": "PROZESSOR",
    "tr_processorTitleCamel": "Prozessor",
    "tr_rethinkStorageTitle": "DIE NEUDEFINITION VON SPEICHER",
    "tr_alwaysOnSecurityTitle": "INTEGRIERTE SICHERHEIT",
    "tr_extendedBatteryLifeTitle": "LÄNGERE AKKULAUFZEIT<sup>1</sup>",
    "tr_endlessProgramsAndAppsTitle": "PROGRAMME UND APPS OHNE ENDE",
    "tr_extendedBatteryLifeDescription": "[XX] Stunden",
    "tr_rethinkStorageDescriptionGB": "[XX] GB",
    "tr_rethinkStorageDescriptionTB": "[XX] TB",
    "tr_OS": "BETRIEBS&shy;SYSTEM",
    "tr_Chrome_OS": "ChromeOS*",
    "tr_textGBGB": "SSD mit [convertedSSD]&nbsp;GB + HDD mit [convertedHDD]&nbsp;GB",
    "tr_textGBTB": "SSD mit [convertedSSD] GB + HDD mit [convertedHDD] TB",
    "tr_textTBGB": "SSD mit [convertedSSD] TB + HDD mit [convertedHDD] GB",
    "tr_textTBTB": "SSD mit [convertedSSD] TB + HDD mit [convertedHDD] TB",
    "tr_textGBSSD": "SSD mit [convertedSSD] GB",
    "tr_textTBSSD": "SSD mit [convertedSSD] TB",
    "tr_textGBHDD": "HDD mit [convertedHDD] GB",
    "tr_textTBHDD": "HDD mit [convertedHDD] TB",
    "tr_graphicTitle": "GRAFIK",
    "tr_storageTitle": "MASSEN&shy;SPEICHER",
    "tr_displayTitle": "DISPLAY",
    "tr_batteryTitle": "AKKU",
    "tr_memoryTitle": "ARBEITS&shy;SPEICHER",
    "tr_priceTitle": "PREIS"
  },
  "rethinkStorage": {
    "details": {
      "tr_tileTitle": "Speicher neu gedacht",
      "tr_title": "Innovativer Speicheransatz",
      "tr_subtitle": "Ein Chromebook* kombiniert den Speicher deines PCs mit der nahezu unbegrenzten Kapazität von Google* Drive in der Cloud. Was sind die Vorteile?",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/cloud_upload.svg",
          "tr_badgeSubTitle": "Alle Dateien werden automatisch in der Cloud gesichert. Die Wiederherstellung erfolgt ganz einfach über die Anmeldung bei deinem Google*-Konto.",
          "badgeSubTitle": "cloud-upload"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Mit [XX] GB Gerätespeicher kannst du online oder offline arbeiten.",
          "badgeSubTitle": "GBALONE"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Mit [XX] TB Gerätespeicher kannst du online oder offline arbeiten.",
          "badgeSubTitle": "TBALONE"
        }
      ]
    }
  },
  "alwaysOnSecurity": {
    "details": {
      "tr_tileTitle": "Integrierte Sicherheit",
      "tr_title": "Mühelose Sicherheit<sup class='sup-text'>2</sup>",
      "tr_subtitle": "Bei Chromebook* werden alle Sicherheitsfunktionen automatisch aktualisiert – du kannst also jederzeit im Web surfen und Links folgen mit der Gewissheit, dass dein Gerät optimal geschützt ist."
    }
  },
  "extendedBatteryLife": {
    "tr_tileTitle": "Längere Akkulaufzeit<sup>1</sup>",
    "iposTypes": [
      {
        "iposType": "CHROMEBOOK",
        "details": {
          "tr_title": "Mehr erledigen. Weniger&nbsp;laden.<sup class='sup-text'>1</sup>",
          "tr_subtitle": "Dank des schlanken und energieeffizienten Designs kannst du dein Chromebook* stundenlang verwenden, ohne es aufladen zu müssen."
        }
      },
      {
        "iposType": "CHROMEBOOKEVO",
        "details": {
          "tr_title": "Seltener aufladen. <br/>Schneller laden.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Das optimierte und energieeffiziente Design eines Chromebooks* erfüllt die hohen Akkuanforderungen der Intel® Evo™ Plattform, sodass du schneller aufladen und länger ohne erneutes Aufladen arbeiten kannst."
        }
      },
      {
        "iposType": "CHROMEBOOKEVOGEN12",
        "details": {
          "tr_title": "Akkulaufzeit für mehr Mobilität.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Die schnell aufladenden und langlebigen Akkus in flachen und leichten Chromebooks* sorgen dafür, dass man sich keine Gedanken machen muss, wo die nächste Steckdose ist."
        }
      }
    ]
  },
  "endlessProgramsAndApps": {
    "details": {
      "tr_tileTitle": "Programme und Apps ohne Ende",
      "tr_title": "Von Tag zu Tag mehr&nbsp;Apps",
      "tr_subtitle": "Chromebook*-Nutzer können aus einer Vielzahl von Apps und Programmen auswählen, denen stets weitere hinzugefügt werden.",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/download_badge.svg",
          "tr_badgeSubTitle": "Lade Chrome*- und Android*-Apps herunter."
        },
        {
          "badgeUrl": "assets/images/badges/reset_badge.svg",
          "tr_badgeSubTitle": "All deine Apps und Daten werden über dein Google*&#8209;Konto synchronisiert."
        },
        {
          "badgeUrl": "assets/images/badges/connect_badge.svg",
          "tr_badgeSubTitle": "Verbinden, Lernen, Erstellen, Gamen&nbsp;– die Möglichkeiten sind unbegrenzt."
        }
      ]
    }
  },
  "processor": [
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Erwarte mehr",
                "tr_subtitle": "Auf einem Chromebook* mit einem Intel® Core™ i3 Prozessor der 10.&nbsp;Generation kannst du deine Lieblingssendungen und Spielfilme mühelos anschauen.",
                "tr_gen_core_title": "Intel® Core™ i3 Prozessor der 10. Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Speziell für außergewöhnliche Leistung an jedem Ort entwickelt.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 10. Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Mit Intel® Core™ i5 Prozessor der 10. Generation"
                  },
                  {
                    "tr_badgeTitle": "Leistungsstark auch im Akkubetrieb"
                  },
                  {
                    "tr_badgeTitle": "Schnellladefunktion: 4 Stunden Akkulaufzeit nach 30&#8209;minütigem Laden<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Erstklassige Konnektivität mit Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Echtes Arbeiten. Echtes Gaming.",
                "tr_subtitle": "Mit einem Chromebook* mit Intel® Core™ i5 Prozessor der 10. Generation kannst du videochatten, E-Mails schreiben und eine Präsentation erstellen, ohne auf Produktivität zu verzichten.",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 10. Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Speziell für außergewöhnliche Leistung an jedem Ort entwickelt.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 10. Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Mit Intel® Core™ i7 Prozessor der 10. Generation"
                  },
                  {
                    "tr_badgeTitle": "Leistungsstark auch im Akkubetrieb"
                  },
                  {
                    "tr_badgeTitle": "Schnellladefunktion: 4 Stunden Akkulaufzeit nach 30&#8209;minütigem Laden<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Erstklassige Konnektivität mit Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Weil Leistung zählt",
                "tr_subtitle": "Mit einem Chromebook* mit Intel® Core™ i7 Prozessor der 10.&nbsp;Generation kannst du mühelos Videos bearbeiten und deine Erinnerungen sofort ins Internet hochladen und teilen.",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 10. Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hohe Leistung und schnelle Reaktionen",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 Prozessor der 8. Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Leistung für Ihre Leidenschaft und Produktivität",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 8. Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Leistung für Ihre Leidenschaft und Produktivität",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra&nbsp;5 Prozessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Leistung für Ihre Leidenschaft und Produktivität",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 Prozessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Leistung für Ihre Leidenschaft und Produktivität",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra&nbsp;9 Prozessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hohe Leistung und schnelle Reaktionen",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ m3 Prozessor der 8. Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hohe Leistung für moderne Anforderungen",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 7. Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Herausragende Leistung für heutige Anforderungen",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 7. Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Leistung für moderne Anforderungen",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ m3 Prozessor der 7. Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Noch mehr Leistung für Ihre Ideen",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 6. Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Erstklassige Leistung für Ihre Ideen",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 6. Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Leistung für Ihre Ideen",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ m3 Prozessor der 6. Generation"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Unschlagbare Performance für Chromebooks*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 Prozessor der 11. Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Bis zu <br/><span class='big-blue-text'>2,4x </span><span class='subt-text'>bessere Leistung<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Bis zu <br/><span class='big-blue-text'>3,2x </span><span class='subt-text'>schnelleres Web-Browsing<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Bis zu <br/><span class='big-blue-text'>2,1x </span><span class='subt-text'>schnellere Videobearbeitung<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Der ersehnte Boost für deine Kreativität mit Chromebooks*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 11. Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Bis zu <br/><span class='big-blue-text'>2,7x </span><span class='subt-text'>bessere Leistung<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Bis zu <br/><span class='big-blue-text'>3,3x </span><span class='subt-text'>schnelleres Web-Browsing<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Bis zu <br/><span class='big-blue-text'>2,8x </span><span class='subt-text'>schnellere Videobearbeitung<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Designed für mobile Leistung",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 11. Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Bahnbrechende Leistung mit Intel®&nbsp;Core™ Prozessoren der&nbsp;11.&nbsp;Generation"
                  },
                  {
                    "tr_badgeTitle": "Startklar in < 1 Sekunde<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nahezu 3-mal schnelleres Internet mit Intel® Wi-Fi 6 (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Schnell aufladender Akku mit langer Laufzeit"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Ein extrem leistungsstarker Prozessor für Chromebooks*<sup class='sup-text'>1</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 11. Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Bis zu <br/><span class='big-blue-text'>2,9x </span><span class='subt-text'>bessere Leistung<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Bis zu <br/><span class='big-blue-text'>3,5x </span><span class='subt-text'>schnelleres Web-Browsing<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Bis zu <br/><span class='big-blue-text'>2,9x </span><span class='subt-text'>schnellere Videobearbeitung<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Designed für mobile Leistung",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 11. Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Bahnbrechende Leistung mit Intel®&nbsp;Core™ Prozessoren der&nbsp;11.&nbsp;Generation"
                  },
                  {
                    "tr_badgeTitle": "Startklar in < 1 Sekunde<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nahezu 3-mal schnelleres Internet mit Intel® Wi-Fi 6 (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Schnell aufladender Akku mit langer Laufzeit"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Schnelle Leistung. Jeden&nbsp;Tag.",
                "tr_subtitle": "Ein Intel® Pentium® Prozessor bietet&nbsp;dir&nbsp;herausragende Leistung für&nbsp;deine Lieblingsprogramme und&nbsp;&#8209;anwendungen.",
                "tr_gen_core_title": "Intel® Pentium® Gold Prozessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Schnelle Leistung. Jeden&nbsp;Tag.",
                "tr_subtitle": "Ein Intel® Pentium® Prozessor bietet&nbsp;dir&nbsp;herausragende Leistung für&nbsp;deine Lieblingsprogramme und&nbsp;&#8209;anwendungen.",
                "tr_gen_core_title": "Intel® Pentium® Silver Prozessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Täglich produktiver arbeiten",
                "tr_subtitle": "Vom Ausführen von Apps bis zum Öffnen von Projekten&nbsp;– mit einem Intel®&nbsp;Celeron®&nbsp;Prozessor kannst du einfach mehr erreichen.",
                "tr_gen_core_title": "Intel® Celeron® Prozessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Leistung zum attraktiven Preis.",
                "tr_subtitle": "Konzipiert für hervorragende Unterhaltung, Videostreaming und Produktivität. Spielfilme und Web-Videos mit fantastischer Bildschärfe genießen. Mehr tun können, weniger warten müssen.",
                "tr_gen_core_title": "Intel® Pentium® Prozessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Schnelle Leistung. Jeden&nbsp;Tag.",
                "tr_subtitle": "Ein Intel® Pentium® Prozessor bietet&nbsp;dir&nbsp;herausragende Leistung für&nbsp;deine Lieblingsprogramme und&nbsp;&#8209;anwendungen.",
                "tr_gen_core_title": "Intel® Pentium® Prozessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Leistung zum attraktiven Preis.",
                "tr_subtitle": "Gesteigerte Produktivität: Mehr Verarbeitungsleistung für Zeiten mit höchsten Anforderungen. Optimiertes Web-Browsing: Medienintensive Webseiten werden schnell geladen, um mehr erleben zu können und weniger warten zu müssen.",
                "tr_gen_core_title": "Intel® Pentium® Gold Prozessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Leistung zum attraktiven Preis.",
                "tr_subtitle": "Mit ausreichend Leistung, um auf Web-Dateien zuzugreifen, kannst du mühelos mit deiner digitalen Welt Schritt halten. Durch reibungsloses Hin- und Herschalten zwischen Programmen gelingt müheloses Multitasking und in kürzerer Zeit ist mehr erledigt.",
                "tr_gen_core_title": "Intel® Pentium® Silver Prozessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Leistung zum attraktiven Preis.",
                "tr_subtitle": "Verbesserte Leistung für Arbeit und Unterhaltung. Spielfilme und Web-Videos mit fantastischer Bildschärfe genießen. Mehr tun können, weniger warten müssen.",
                "tr_gen_core_title": "Intel® Celeron® Prozessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Zuverlässige Leistung – wo sie am meisten benötigt wird.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™&nbsp;i3 Prozessor der 12.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Müheloses <br/>Multitasking"
                  },
                  {
                    "tr_badgeTitle": "Integrierte <br/>Hintergrundunschärfe"
                  },
                  {
                    "tr_badgeTitle": "Reaktionsschnelles <br/>Browsen"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Eine Leistungssteigerung, wo&nbsp;sie&nbsp;am meisten gebraucht wird.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™&nbsp;i5 Prozessor der 12.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Leistungsstarkes <br/>Multitasking"
                  },
                  {
                    "tr_badgeTitle": "Automatische <br/>Geräuschunterdrückung"
                  },
                  {
                    "tr_badgeTitle": "HD-<br/>Streaming"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Die mobile Leistungsfähigkeit hat sich weiterentwickelt.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™&nbsp;i5 Prozessor der 12.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Überlegene Leistung, wo du sie am meisten brauchst, mit&nbsp;Intel®&nbsp;Core™ Prozessoren der 12. Generation<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Startklar in < 1 Sekunde<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nahezu 3-mal schnelleres Internet mit Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Schnell aufladender Akku mit langer Laufzeit"
                  },
                  {
                    "tr_badgeTitle": "Verbesserte Zusammenarbeit und Konnektivität"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Überlegene Leistung, wo du sie am meisten brauchst.<sup>11</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™&nbsp;i7 Prozessor der 12.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Extremes <br/>Multitasking"
                  },
                  {
                    "tr_badgeTitle": "Integrierte <br/>Geräuschunterdrückung und <br/>Hintergrundunschärfe"
                  },
                  {
                    "tr_badgeTitle": "Reibungsloses <br/>8K-Streaming"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Die mobile Leistungsfähigkeit hat sich weiterentwickelt.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™&nbsp;i7 Prozessor der 12.&nbsp;Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Überlegene Leistung, wo du sie am meisten brauchst, mit&nbsp;Intel®&nbsp;Core™ Prozessoren der 12. Generation<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Startklar in < 1 Sekunde<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nahezu 3-mal schnelleres Internet mit Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Schnell aufladender Akku mit langer Laufzeit"
                  },
                  {
                    "tr_badgeTitle": "Verbesserte Zusammenarbeit und Konnektivität"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Weitergedacht.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 13. Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Flaches, leichtes Design, das über reine Leistung hinausgeht<br/><span class='marklist'>und</span> sich nahtlos mit dem vorhandenen Smartphone verbindet<sup>13</sup><br/><span class='marklist'>und</span> intelligent die beste WLAN- oder 5G-Verbindung aussucht<br/><span class='marklist'>und</span> den ausdauernden Akku schnell auflädt"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Einfach mehr schaffen, mit hervorragender Leistung",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor der 13. Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Verbessertes<br/>Multitasking"
                  },
                  {
                    "tr_badgeTitle": "Flüssiges<br/>4K-Streaming"
                  },
                  {
                    "tr_badgeTitle": "Leistungsstarkes<br/>Erstellen"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Weitergedacht.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 13. Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Flaches, leichtes Design, das über reine Leistung hinausgeht<br/><span class='marklist'>und</span> sich nahtlos mit dem vorhandenen Smartphone verbindet<sup>13</sup><br/><span class='marklist'>und</span> intelligent die beste WLAN- oder 5G-Verbindung aussucht<br/><span class='marklist'>und</span> den ausdauernden Akku schnell auflädt"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Einfach mehr schaffen, mit mehr als überlegener Leistung",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor der 13. Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Extremes <br/>Multitasking"
                  },
                  {
                    "tr_badgeTitle": "Flüssiges<br/>8K-Streaming"
                  },
                  {
                    "tr_badgeTitle": "Erweiterte Funktionalität<br/>für kreative Projekte"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Intel® Prozessor",
                "tr_subtitle": "Die nötige Leistung. Für&nbsp;den&nbsp;richtigen&nbsp;Preis.",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Einfach mehr schaffen, mit außergewöhnlicher Leistung",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 Prozessor der 13. Generation",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Einfaches<br/>Multitasking"
                  },
                  {
                    "tr_badgeTitle": "Reibungsloses<br/>Streaming"
                  },
                  {
                    "tr_badgeTitle": "Schnelles Bearbeiten<br/>kreativer Projekte"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Weitergedacht.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™&nbsp;i3 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Flaches, leichtes Design, das über reine Leistung hinausgeht<br/><span class='marklist'>und</span> sich nahtlos mit dem vorhandenen Smartphone verbindet<sup>13</sup><br/><span class='marklist'>und</span> intelligent die beste WLAN- oder 5G-Verbindung aussucht<br/><span class='marklist'>und</span> den ausdauernden Akku schnell auflädt"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Einfach mehr schaffen, mit außergewöhnlicher Leistung",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™&nbsp;i3 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Einfaches<br/>Multitasking"
                  },
                  {
                    "tr_badgeTitle": "Reibungsloses<br/>Streaming"
                  },
                  {
                    "tr_badgeTitle": "Schnelles Bearbeiten<br/>kreativer Projekte"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Weitergedacht.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Flaches, leichtes Design, das über reine Leistung hinausgeht<br/><span class='marklist'>und</span> sich nahtlos mit dem vorhandenen Smartphone verbindet<sup>13</sup><br/><span class='marklist'>und</span> intelligent die beste WLAN- oder 5G-Verbindung aussucht<br/><span class='marklist'>und</span> den ausdauernden Akku schnell auflädt"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Einfach mehr schaffen, mit hervorragender Leistung",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Verbessertes<br/>Multitasking"
                  },
                  {
                    "tr_badgeTitle": "Flüssiges<br/>4K-Streaming"
                  },
                  {
                    "tr_badgeTitle": "Leistungsstarkes<br/>Erstellen"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Weitergedacht.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Flaches, leichtes Design, das über reine Leistung hinausgeht<br/><span class='marklist'>und</span> sich nahtlos mit dem vorhandenen Smartphone verbindet<sup>13</sup><br/><span class='marklist'>und</span> intelligent die beste WLAN- oder 5G-Verbindung aussucht<br/><span class='marklist'>und</span> den ausdauernden Akku schnell auflädt"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Einfach mehr schaffen, mit mehr als überlegener Leistung",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Extremes <br/>Multitasking"
                  },
                  {
                    "tr_badgeTitle": "Flüssiges<br/>8K-Streaming"
                  },
                  {
                    "tr_badgeTitle": "Erweiterte Funktionalität<br/>für kreative Projekte"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Weitergedacht.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Flaches, leichtes Design, das über reine Leistung hinausgeht<br/><span class='marklist'>und</span> sich nahtlos mit dem vorhandenen Smartphone verbindet<sup>13</sup><br/><span class='marklist'>und</span> intelligent die beste WLAN- oder 5G-Verbindung aussucht<br/><span class='marklist'>und</span> den ausdauernden Akku schnell auflädt"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Spitzenleistung, die sich deinen Leidenschaften widmet",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i9 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Schnelle Foto-<br/>und Videobearbeitung"
                  },
                  {
                    "tr_badgeTitle": "Verbesserte Offline-<br/>Funktionen"
                  },
                  {
                    "tr_badgeTitle": "Sorgenfreies<br/>Gaming dank<br/>langer Akkulaufzeit"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_3",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Weitergedacht.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Flaches, leichtes Design, das über reine Leistung hinausgeht<br/><span class='marklist'>und</span> sich nahtlos mit dem vorhandenen Smartphone verbindet<sup>13</sup><br/><span class='marklist'>und</span> intelligent die beste WLAN- oder 5G-Verbindung aussucht<br/><span class='marklist'>und</span> den ausdauernden Akku schnell auflädt"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Einfach mehr schaffen, mit außergewöhnlicher Leistung",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Einfaches<br/>Multitasking"
                  },
                  {
                    "tr_badgeTitle": "Reibungsloses<br/>Streaming"
                  },
                  {
                    "tr_badgeTitle": "Schnelles Bearbeiten<br/>kreativer Projekte"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_5",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Weitergedacht.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Flaches, leichtes Design, das über reine Leistung hinausgeht<br/><span class='marklist'>und</span> sich nahtlos mit dem vorhandenen Smartphone verbindet<sup>13</sup><br/><span class='marklist'>und</span> intelligent die beste WLAN- oder 5G-Verbindung aussucht<br/><span class='marklist'>und</span> den ausdauernden Akku schnell auflädt"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Einfach mehr schaffen, mit hervorragender Leistung",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Verbessertes<br/>Multitasking"
                  },
                  {
                    "tr_badgeTitle": "Flüssiges<br/>4K-Streaming"
                  },
                  {
                    "tr_badgeTitle": "Leistungsstarkes<br/>Erstellen"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_7",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Weitergedacht.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Flaches, leichtes Design, das über reine Leistung hinausgeht<br/><span class='marklist'>und</span> sich nahtlos mit dem vorhandenen Smartphone verbindet<sup>13</sup><br/><span class='marklist'>und</span> intelligent die beste WLAN- oder 5G-Verbindung aussucht<br/><span class='marklist'>und</span> den ausdauernden Akku schnell auflädt"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Einfach mehr schaffen, mit mehr als überlegener Leistung",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Extremes <br/>Multitasking"
                  },
                  {
                    "tr_badgeTitle": "Flüssiges<br/>8K-Streaming"
                  },
                  {
                    "tr_badgeTitle": "Erweiterte Funktionalität<br/>für kreative Projekte"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Weitergedacht.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra&nbsp;5 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Flaches, leichtes Design, das über reine Leistung hinausgeht<br/><span class='marklist'>und</span> sich nahtlos mit dem vorhandenen Smartphone verbindet<sup>13</sup><br/><span class='marklist'>und</span> intelligent die beste WLAN- oder 5G-Verbindung aussucht<br/><span class='marklist'>und</span> den ausdauernden Akku schnell auflädt"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Entdecke neue KI&#8209;Möglichkeiten",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra&nbsp;5 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Bis zu 65 % schnellere<br/>generative KI"
                  },
                  {
                    "tr_badgeTitle": "Bis zu 2,3-mal schnellere<br/>KI-Videobearbeitung"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Weitergedacht.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Flaches, leichtes Design, das über reine Leistung hinausgeht<br/><span class='marklist'>und</span> sich nahtlos mit dem vorhandenen Smartphone verbindet<sup>13</sup><br/><span class='marklist'>und</span> intelligent die beste WLAN- oder 5G-Verbindung aussucht<br/><span class='marklist'>und</span> den ausdauernden Akku schnell auflädt"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Entdecke neue KI&#8209;Möglichkeiten",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Bis zu 65 % schnellere<br/>generative KI"
                  },
                  {
                    "tr_badgeTitle": "Bis zu 2,3-mal schnellere<br/>KI-Videobearbeitung"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Weitergedacht.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra&nbsp;9 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Flaches, leichtes Design, das über reine Leistung hinausgeht<br/><span class='marklist'>und</span> sich nahtlos mit dem vorhandenen Smartphone verbindet<sup>13</sup><br/><span class='marklist'>und</span> intelligent die beste WLAN- oder 5G-Verbindung aussucht<br/><span class='marklist'>und</span> den ausdauernden Akku schnell auflädt"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prozessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Entdecke neue KI&#8209;Möglichkeiten",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra&nbsp;9 Prozessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Bis zu 65 % schnellere<br/>generative KI"
                  },
                  {
                    "tr_badgeTitle": "Bis zu 2,3-mal schnellere<br/>KI-Videobearbeitung"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "legalText": {
    "tr_specTitle": "Rechtliche Hinweise",
    "tr_paragraph_array": [
      "¹Bis zu 10&nbsp;Stunden Akkulaufzeit bei vollständig aufgeladenem Akku basierend auf internen Tests von Google*.",
      "²Kein Produkt und keine Komponente bietet absolute Sicherheit. Informieren Sie sich beim Systemhersteller, Fachhändler oder auf intel.com.",
      "³Ermittelt anhand von branchenüblichen Benchmarks, Tests nach dem Representative Usage Guide und besonderen Funktionseigenschaften des Intel® Core™ i7-1165G7 Prozessors. Angaben zu Workloads und Konfigurationen finden Sie unter www.intel.com/PerformanceIndex. Die Ergebnisse können von Fall zu Fall abweichen.",
      "⁴Ermittelt mit Google-Sheets-Multitasking.",
      "⁵Ermittelt mit Speedometer 2.0.",
      "⁶Ermittelt mit KineMaster Export Video.",
      "Fußnoten 4-6: Alle Tests wurden mit einem Intel® Core™ i3-1115G4 Prozessor im Vergleich zu einem Intel® Celeron® N4020 Prozessor durchgeführt. Angaben zu Workloads und Konfigurationen unter www.intel.com/PerformanceIndex. Die Leistung variiert je nach Verwendung, Konfiguration und anderen Faktoren. Weitere Informationen unter www.intel.com/PerformanceIndex. Die Leistungswerte basieren auf Tests, die an den in den Konfigurationen angegebenen Daten durchgeführt wurden. Sie spiegeln möglicherweise nicht alle öffentlich verfügbaren Updates wider.",
      "⁷Zeitraum, in welchem der Ladezustand des Akkus bei Arbeitsabläufen in einer für die Nutzung typischen Umgebung ausgehend von 100 % einen kritischen Stand erreicht. Die Ergebnisse können von Fall zu Fall abweichen. Einzelheiten unter intel.com/evo.",
      "⁸Die Ergebnisse können von Fall zu Fall abweichen. Einzelheiten unter intel.com/evo.",
      "⁹Beinahe 3-mal schneller: 802.11ax 2x2 160 MHz ermöglicht theoretische maximale Datenübertragungsraten von 2402 Mbit/s. Dies ist ca. 3-mal schneller als der Standard 802.11ac 2x2 80 MHz (867 Mbit/s). Angaben gemäß der Dokumentation in den IEEE-802.11*-Wireless-Standard-Spezifikationen. Erfordert die Verwendung von ähnlich konfigurierten 802.11ax-Wireless-Netzwerkroutern.",
      "¹⁰Erreichte Akkuladung ausgehend vom OEM-Standardabschaltniveau. Die Ergebnisse können von Fall zu Fall abweichen. Einzelheiten unter intel.com/evo.",
      "¹¹Im Vergleich zur vorherigen Generation von Prozessoren.",
      "¹²Gemessen an einzigartigen Funktionen der Intel® Evo™ Designs wie leistungsstarke Intel® Core™ CPUs, Premium-Komponenten, unerreichte Kompatibilität und den neuesten Konnektivitätslösungen in einem eleganten, innovativen Design. Alle Intel® Evo™ Markendesigns müssen anspruchsvolle Schwellenwerte für wichtige mobile Benutzererlebnisse wie Reaktionsfähigkeit und Akkulaufzeit erfüllen; die spezifische Geräteleistung kann variieren. Einzelheiten unter www.intel.com/PerformanceIndex (Plattformen).",
      "¹³Die Intel® Unison™ Lösung ist derzeit nur für qualifizierte Intel® Evo™ Designs auf Windows*-basierten PCs verfügbar und lässt sich nur mit Android*- oder iOS*-basierten Smartphones koppeln. Alle Geräte müssen eine unterstützte Betriebssystemversion verwenden. Die Leistung einzelner Intel® Evo™ Geräte kann variieren. Einzelheiten siehe intel.com/performance-evo.",
      "Die Funktionsmerkmale und Vorteile von Intel Technologie hängen von der Systemkonfiguration ab und können geeignete Hardware, Software oder die Aktivierung von Diensten erfordern. Die Leistung kann je nach Systemkonfiguration unterschiedlich ausfallen. Kein Computersystem bietet absolute Sicherheit. Informieren Sie sich beim Systemhersteller, Fachhändler oder auf intel.com.",
      "*Andere Marken oder Produktnamen sind Eigentum der jeweiligen Inhaber.",
      "Intel übernimmt keine Garantie für inkorrekte Preisangaben.",
      "© 2022 Intel Corporation. Celeron, Intel, das Intel Logo, Intel Core und Pentium sind Marken der Intel Corporation oder ihrer Tochtergesellschaften."
    ]
  }
}