const Library_ChromeBook_mobile_sv = {
  "uiText": {
    "tr_endlessProgramsAndApps": "Oändligt antal program och appar",
    "tr_rethinkStorage": "Lagring på ett nytt sätt",
    "tr_alwaysOnSecurity": "Inbyggd säkerhet",
    "tr_extendedBatteryLife": "Utökad batteritid<sup>1</sup>",
    "tr_viewMore": "Visa mer",
    "tr_processorTitle": "PROCESSOR",
    "tr_processorTitleCamel": "Processor",
    "tr_rethinkStorageTitle": "LAGRING PÅ ETT NYTT SÄTT",
    "tr_alwaysOnSecurityTitle": "INBYGGD SÄKERHET",
    "tr_extendedBatteryLifeTitle": "UTÖKAD BATTERITID<sup>1</sup>",
    "tr_endlessProgramsAndAppsTitle": "OÄNDLIGT ANTAL PROGRAM OCH APPAR",
    "tr_extendedBatteryLifeDescription": "[XX] timmar",
    "tr_rethinkStorageDescriptionGB": "[XX] GB",
    "tr_rethinkStorageDescriptionTB": "[XX] TB",
    "tr_OS": "OS",
    "tr_Chrome_OS": "ChromeOS*",
    "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textGBSSD": "[convertedSSD] GB SSD",
    "tr_textTBSSD": "[convertedSSD] TB SSD",
    "tr_textGBHDD": "[convertedHDD] GB HDD",
    "tr_textTBHDD": "[convertedHDD] TB HDD",
    "tr_graphicTitle": "GRAFIK",
    "tr_storageTitle": "LAGRING",
    "tr_displayTitle": "BILDSKÄRM",
    "tr_batteryTitle": "BATTERI",
    "tr_memoryTitle": "MINNE",
    "tr_priceTitle": "PRIS"
  },
  "rethinkStorage": {
    "details": {
      "tr_tileTitle": "Lagring på ett nytt sätt",
      "tr_title": "En innovativt sätt att lagra",
      "tr_subtitle": "Chromebook* kombinerar lagring på datorn med nästan obegränsade resurser i Google* Drive i molnet. Här är fördelarna:",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/cloud_upload.svg",
          "tr_badgeSubTitle": "Alla dina filer säkerhetskopieras automatiskt till molnet och det är lika enkelt att återställa dem som att logga in på ditt Google*-konto.",
          "badgeSubTitle": "cloud-upload"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Arbeta online eller offline med [XX] GB lagringsutrymme på enheten.",
          "badgeSubTitle": "GBALONE"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Arbeta online eller offline med [XX] TB lagringsutrymme på enheten.",
          "badgeSubTitle": "TBALONE"
        }
      ]
    }
  },
  "alwaysOnSecurity": {
    "details": {
      "tr_tileTitle": "Inbyggd säkerhet",
      "tr_title": "Skydd utan krångel<sup class='sup-text'>2</sup>",
      "tr_subtitle": "Chromebook* uppdaterar automatiskt alla säkerhetsfunktioner så att du kan surfa på webben och klicka på länkar med vissheten om att enheten är bättre skyddad."
    }
  },
  "extendedBatteryLife": {
    "tr_tileTitle": "Utökad batteritid<sup>1</sup>",
    "iposTypes": [
      {
        "iposType": "CHROMEBOOK",
        "details": {
          "tr_title": "Gör mer, ladda mindre<sup class='sup-text'>1</sup>",
          "tr_subtitle": "Den smidiga och energieffektiva designen hos en Chromebook* innebär att du kan använda den i timmar utan att behöva ansluta laddaren."
        }
      },
      {
        "iposType": "CHROMEBOOKEVO",
        "details": {
          "tr_title": "Ladda mer sällan. <br/>Och ladda snabbare.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Den strömlinjeformade och energieffektiva designen hos en Chromebook* uppfyller de stränga batterikraven för Intel® Evo™ plattformen och gör det möjligt för dig att ladda snabbare och mer sällan."
        }
      },
      {
        "iposType": "CHROMEBOOKEVOGEN12",
        "details": {
          "tr_title": "Batteritid för dig på språng.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "De snabbladdande batterierna i tunna och lätta Chromebooks* håller längre och du behöver aldrig mer behöver oroa dig över var närmaste strömuttag är."
        }
      }
    ]
  },
  "endlessProgramsAndApps": {
    "details": {
      "tr_tileTitle": "Oändligt antal program och appar",
      "tr_title": "Fler appar varje dag",
      "tr_subtitle": "Chromebook*-användare har ett enormt utbud av appar och program att välja bland, och fler läggs till hela tiden.",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/download_badge.svg",
          "tr_badgeSubTitle": "Hämta Chrome*- och Android*-appar."
        },
        {
          "badgeUrl": "assets/images/badges/reset_badge.svg",
          "tr_badgeSubTitle": "Alla appar och data synkroniseras via ditt Google*-konto."
        },
        {
          "badgeUrl": "assets/images/badges/connect_badge.svg",
          "tr_badgeSubTitle": "Anslut, lär dig saker, skapa och spela som du vill."
        }
      ]
    }
  },
  "processor": [
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Uppgradera dina förväntningar",
                "tr_subtitle": "Titta på dina favoritprogram och filmer på din Chromebook* utan krångel med en 10:e generationens Intel® Core™ i3 processor.",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Designad speciellt för exceptionell prestanda var som helst.",
                "tr_subtitle": "",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Drivs av en 10:e generationens Intel® Core™ i5 processor"
                  },
                  {
                    "tr_badgeTitle": "Kraftull, även frånkopplad från elnätet"
                  },
                  {
                    "tr_badgeTitle": "Snabbladdning: 4 timmars batteritid på 30 minuter<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Marknadens bästa anslutningar med Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Arbeta på riktigt. Spela på riktigt.",
                "tr_subtitle": "Videochatta, skicka e-post och skapa en presentation, allt medan du vässar produktiviteten på en Chromebook* med en 10:e generationens Intel® Core™ i5 processor.",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i5 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Designad speciellt för exceptionell prestanda var som helst.",
                "tr_subtitle": "",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Drivs av en 10:e generationens Intel® Core™ i7 processor"
                  },
                  {
                    "tr_badgeTitle": "Kraftull, även frånkopplad från elnätet"
                  },
                  {
                    "tr_badgeTitle": "Snabbladdning: 4 timmars batteritid på 30 minuter<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Marknadens bästa anslutningar med Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "För att prestanda gör skillnad",
                "tr_subtitle": "Redigera dina videor enkelt och ladda upp och dela minnen på ett ögonblick med en Chromebook* som har en 10:e generationens Intel® Core™ i7 processor.",
                "tr_gen_core_title": "10:e gen:s Intel® Core™ i7 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Snabb responsiv prestanda",
                "tr_subtitle": "",
                "tr_gen_core_title": "8:e gen:s Intel® Core™ i3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Stärk din passion och produktivitet",
                "tr_subtitle": "",
                "tr_gen_core_title": "8:e gen:s Intel® Core™ i5 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Stärk din passion och produktivitet",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Stärk din passion och produktivitet",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Stärk din passion och produktivitet",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;9 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Snabb responsiv prestanda",
                "tr_subtitle": "",
                "tr_gen_core_title": "8:e gen:s Intel® Core™ m3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hög prestanda för det du gör idag",
                "tr_subtitle": "",
                "tr_gen_core_title": "7:e gen:s Intel® Core™ i5 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Förstklassig prestanda för det du gör idag",
                "tr_subtitle": "",
                "tr_gen_core_title": "7:e gen:s Intel® Core™ i7 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestanda för det du gör idag",
                "tr_subtitle": "",
                "tr_gen_core_title": "7:e gen:s Intel® Core™ m3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Högre prestanda för det du behöver",
                "tr_subtitle": "",
                "tr_gen_core_title": "6:e gen:s Intel® Core™ i5 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Avancerad prestanda för det du behöver",
                "tr_subtitle": "",
                "tr_gen_core_title": "6:e gen:s Intel® Core™ i7 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestanda för det du behöver",
                "tr_subtitle": "",
                "tr_gen_core_title": "6:e gen:s Intel® Core™ m3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Chromebook-enheter* med en ny nivå av prestanda",
                "tr_subtitle": "",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i3 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Upp till <br/><span class='big-blue-text'>2,4X </span><span class='subt-text'>bättre prestanda<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Upp till <br/><span class='big-blue-text'>3,2X  </span><span class='subt-text'>snabbare webbläsare<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Upp till <br/><span class='big-blue-text'>2,1X  </span><span class='subt-text'>snabbare videoredigering<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Föreställ dig vad du kan åstadkomma med Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Upp till <br/><span class='big-blue-text'>2,7X  </span><span class='subt-text'>bättre prestanda<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Upp till <br/><span class='big-blue-text'>3,3X  </span><span class='subt-text'>snabbare webbläsare<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Upp till <br/><span class='big-blue-text'>2,8X  </span><span class='subt-text'>snabbare videoredigering<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Utvecklad för mobil prestanda",
                "tr_subtitle": "",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "En ny nivå av prestanda med 11:e gen:s Intel® Core™ processorer"
                  },
                  {
                    "tr_badgeTitle": "Vaknar på < 1 sekund<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nästan tre gånger snabbare internet med Intel® Wi-Fi 6 (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Snabbladdande batteri som varar länge"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Världens bästa processor för Chromebook-enheter*<sup class='sup-text'>1</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Upp till <br/><span class='big-blue-text'>2,9X  </span><span class='subt-text'>bättre prestanda<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Upp till <br/><span class='big-blue-text'>3,5X  </span><span class='subt-text'>snabbare webbläsare<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Upp till <br/><span class='big-blue-text'>2,9X  </span><span class='subt-text'>snabbare videoredigering<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Utvecklad för mobil prestanda",
                "tr_subtitle": "",
                "tr_gen_core_title": "11:e gen:s Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "En ny nivå av prestanda med 11:e gen:s Intel® Core™ processorer"
                  },
                  {
                    "tr_badgeTitle": "Vaknar på < 1 sekund<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nästan tre gånger snabbare internet med Intel® Wi-Fi 6 (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Snabbladdande batteri som varar länge"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Snabb vardagsprestanda",
                "tr_subtitle": "Få utmärkt prestanda för alla dina favoritprogram och appar med kraften i en Intel® Pentium® processor",
                "tr_gen_core_title": "Intel® Pentium® Gold processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Snabb vardagsprestanda",
                "tr_subtitle": "Få utmärkt prestanda för alla dina favoritprogram och appar med kraften i en Intel® Pentium® processor",
                "tr_gen_core_title": "Intel® Pentium® Silver processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Öka din dagliga produktivitet",
                "tr_subtitle": "Från att starta appar till att öppna projekt – en Intel® Celeron® processor hjälper dig att få mer gjort.",
                "tr_gen_core_title": "Intel® Celeron® processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestanda och värde.",
                "tr_subtitle": "Byggd för fantastisk underhållning, videoströmning och produktivitet. Njut av filmer och videor online med fantastisk detaljrikedom. Spendera mer tid på att göra och mindre tid på att vänta.",
                "tr_gen_core_title": "Intel® Pentium® processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Snabb vardagsprestanda",
                "tr_subtitle": "Få utmärkt prestanda för alla dina favoritprogram och appar med kraften i en Intel® Pentium® processor",
                "tr_gen_core_title": "Intel® Pentium® processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestanda och värde.",
                "tr_subtitle": "Förbättrad produktivitet – förhöjd prestanda för att klara dina mest krävande dagar. Effektiviserad webbsurfning – ladda snabbt innehållsrika webbsidor för att utforska mer och vänta mindre.",
                "tr_gen_core_title": "Intel® Pentium® Gold processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestanda och värde.",
                "tr_subtitle": "Utforska din digitala värld med prestanda som gör det enkelt att komma åt filer från webben. Enkel multitasking genom att enkelt växla mellan program och få mer gjort på mindre tid.",
                "tr_gen_core_title": "Intel® Pentium® Silver processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestanda och värde.",
                "tr_subtitle": "Prestanda som ger dig mer kraft för jobb och nöje. Njut av filmer och videor online med fantastisk detaljrikedom. Spendera mer tid på att göra och mindre tid på att vänta.",
                "tr_gen_core_title": "Intel® Celeron® processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Tillförlitlig prestanda där du behöver det som mest.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i3 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Enkel<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Inbyggd funktion för<br/>oskarp bakgrund"
                  },
                  {
                    "tr_badgeTitle": "Responsiv<br/>surfning"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "En prestandaökning där du behöver den som mest.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Kraftfull<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Automatisk<br/>brusreducering"
                  },
                  {
                    "tr_badgeTitle": "HD-<br/>strömning"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Mobil prestanda på nästa nivå.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Överlägsen prestanda där du behöver den som mest med 12:e gen:s Intel® Core™ processorer<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Vaknar på < 1 sekund<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nästan tre gånger snabbare internet med Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Snabbladdande batteri som varar länge"
                  },
                  {
                    "tr_badgeTitle": "Bättre samarbete och anslutning"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Överlägsen prestanda där du behöver den mest.<sup>11</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Extrem<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Inbyggd<br/>brusreducering och<br/>oskarp bakgrund"
                  },
                  {
                    "tr_badgeTitle": "Bufferfri<br/>8K-strömning"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Mobil prestanda på nästa nivå.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12:e gen:s Intel®&nbsp;Core™&nbsp;i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Överlägsen prestanda där du behöver den som mest med 12:e gen:s Intel® Core™ processorer<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Vaknar på < 1 sekund<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nästan tre gånger snabbare internet med Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Snabbladdande batteri som varar länge"
                  },
                  {
                    "tr_badgeTitle": "Bättre samarbete och anslutning"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>På nästa nivå.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13:e gen:s Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tunn och lätt design som sträcker sig bortom prestanda<br/><span class='marklist'>och</span> ansluter sömlöst till din befintliga telefon&nbsp;<sup>13</sup><br/><span class='marklist'>och</span> intelligent väljer den bästa Wi-Fi- eller 5G-anslutningen<br/><span class='marklist'>och</span> laddar snabbt med ett batteri som varar"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gör det du ska med exceptionell prestanda.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13:e gen:s Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Förbättrad<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Bufferfri<br/>strömning i 4K"
                  },
                  {
                    "tr_badgeTitle": "Kraftfullt<br/>skapande"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>På nästa nivå.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13:e gen:s Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tunn och lätt design som sträcker sig bortom prestanda<br/><span class='marklist'>och</span> ansluter sömlöst till din befintliga telefon&nbsp;<sup>13</sup><br/><span class='marklist'>och</span> intelligent väljer den bästa Wi-Fi- eller 5G-anslutningen<br/><span class='marklist'>och</span> laddar snabbt med ett batteri som varar"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gör det du ska med högst överlägsen prestanda.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13:e gen:s Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Extrem<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Bufferfri<br/>strömning i 8K"
                  },
                  {
                    "tr_badgeTitle": "Avancerat<br/>skapande"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Intel® Processor",
                "tr_subtitle": "Den prestanda du behöver. Till ett överkomligt pris.",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gör det du ska med enastående prestanda",
                "tr_subtitle": "",
                "tr_gen_core_title": "13:e gen:s Intel® Core™ i3 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Enkel<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Bufferfri<br/>strömning"
                  },
                  {
                    "tr_badgeTitle": "Snabbt<br/>skapande"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>På nästa nivå.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tunn och lätt design som sträcker sig bortom prestanda<br/><span class='marklist'>och</span> ansluter sömlöst till din befintliga telefon&nbsp;<sup>13</sup><br/><span class='marklist'>och</span> intelligent väljer den bästa Wi-Fi- eller 5G-anslutningen<br/><span class='marklist'>och</span> laddar snabbt med ett batteri som varar"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gör det du ska med enastående prestanda",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Enkel<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Bufferfri<br/>strömning"
                  },
                  {
                    "tr_badgeTitle": "Snabbt<br/>skapande"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>På nästa nivå.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tunn och lätt design som sträcker sig bortom prestanda<br/><span class='marklist'>och</span> ansluter sömlöst till din befintliga telefon&nbsp;<sup>13</sup><br/><span class='marklist'>och</span> intelligent väljer den bästa Wi-Fi- eller 5G-anslutningen<br/><span class='marklist'>och</span> laddar snabbt med ett batteri som varar"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gör det du ska med exceptionell prestanda.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Förbättrad<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Bufferfri<br/>strömning i 4K"
                  },
                  {
                    "tr_badgeTitle": "Kraftfullt<br/>skapande"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>På nästa nivå.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tunn och lätt design som sträcker sig bortom prestanda<br/><span class='marklist'>och</span> ansluter sömlöst till din befintliga telefon&nbsp;<sup>13</sup><br/><span class='marklist'>och</span> intelligent väljer den bästa Wi-Fi- eller 5G-anslutningen<br/><span class='marklist'>och</span> laddar snabbt med ett batteri som varar"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gör det du ska med högst överlägsen prestanda.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Extrem<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Bufferfri<br/>strömning i 8K"
                  },
                  {
                    "tr_badgeTitle": "Avancerat<br/>skapande"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>På nästa nivå.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tunn och lätt design som sträcker sig bortom prestanda<br/><span class='marklist'>och</span> ansluter sömlöst till din befintliga telefon&nbsp;<sup>13</sup><br/><span class='marklist'>och</span> intelligent väljer den bästa Wi-Fi- eller 5G-anslutningen<br/><span class='marklist'>och</span> laddar snabbt med ett batteri som varar"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Ge dina passioner tillgång till överlägsen prestanda",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Snabb foto-<br/>och videoredigering"
                  },
                  {
                    "tr_badgeTitle": "Förbättrade offline-<br/>funktioner"
                  },
                  {
                    "tr_badgeTitle": "Spela spel<br/>bekymmersfritt med<br/>lång batteritid"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_3",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>På nästa nivå.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tunn och lätt design som sträcker sig bortom prestanda<br/><span class='marklist'>och</span> ansluter sömlöst till din befintliga telefon&nbsp;<sup>13</sup><br/><span class='marklist'>och</span> intelligent väljer den bästa Wi-Fi- eller 5G-anslutningen<br/><span class='marklist'>och</span> laddar snabbt med ett batteri som varar"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gör det du ska med enastående prestanda",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Enkel<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Bufferfri<br/>strömning"
                  },
                  {
                    "tr_badgeTitle": "Snabbt<br/>skapande"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_5",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>På nästa nivå.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tunn och lätt design som sträcker sig bortom prestanda<br/><span class='marklist'>och</span> ansluter sömlöst till din befintliga telefon&nbsp;<sup>13</sup><br/><span class='marklist'>och</span> intelligent väljer den bästa Wi-Fi- eller 5G-anslutningen<br/><span class='marklist'>och</span> laddar snabbt med ett batteri som varar"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gör det du ska med exceptionell prestanda.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Förbättrad<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Bufferfri<br/>strömning i 4K"
                  },
                  {
                    "tr_badgeTitle": "Kraftfullt<br/>skapande"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_7",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>På nästa nivå.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tunn och lätt design som sträcker sig bortom prestanda<br/><span class='marklist'>och</span> ansluter sömlöst till din befintliga telefon&nbsp;<sup>13</sup><br/><span class='marklist'>och</span> intelligent väljer den bästa Wi-Fi- eller 5G-anslutningen<br/><span class='marklist'>och</span> laddar snabbt med ett batteri som varar"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gör det du ska med högst överlägsen prestanda.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Extrem<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Bufferfri<br/>strömning i 8K"
                  },
                  {
                    "tr_badgeTitle": "Avancerat<br/>skapande"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>På nästa nivå.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tunn och lätt design som sträcker sig bortom prestanda<br/><span class='marklist'>och</span> ansluter sömlöst till din befintliga telefon&nbsp;<sup>13</sup><br/><span class='marklist'>och</span> intelligent väljer den bästa Wi-Fi- eller 5G-anslutningen<br/><span class='marklist'>och</span> laddar snabbt med ett batteri som varar"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Frigör nya AI-upplevelser",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Upp till 65 % snabbare<br/>generativ AI"
                  },
                  {
                    "tr_badgeTitle": "Upp till 2,3 X snabbare<br/>AI-videoredigering"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>På nästa nivå.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tunn och lätt design som sträcker sig bortom prestanda<br/><span class='marklist'>och</span> ansluter sömlöst till din befintliga telefon&nbsp;<sup>13</sup><br/><span class='marklist'>och</span> intelligent väljer den bästa Wi-Fi- eller 5G-anslutningen<br/><span class='marklist'>och</span> laddar snabbt med ett batteri som varar"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Frigör nya AI-upplevelser",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Upp till 65 % snabbare<br/>generativ AI"
                  },
                  {
                    "tr_badgeTitle": "Upp till 2,3 X snabbare<br/>AI-videoredigering"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>På nästa nivå.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tunn och lätt design som sträcker sig bortom prestanda<br/><span class='marklist'>och</span> ansluter sömlöst till din befintliga telefon&nbsp;<sup>13</sup><br/><span class='marklist'>och</span> intelligent väljer den bästa Wi-Fi- eller 5G-anslutningen<br/><span class='marklist'>och</span> laddar snabbt med ett batteri som varar"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Frigör nya AI-upplevelser",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Upp till 65 % snabbare<br/>generativ AI"
                  },
                  {
                    "tr_badgeTitle": "Upp till 2,3 X snabbare<br/>AI-videoredigering"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "legalText": {
    "tr_specTitle": "Juridisk information",
    "tr_paragraph_array": [
      "¹Upp till 10 timmars batteritid baserat på en fullständig laddning enligt interna Google*-tester.",
      "²Ingen produkt eller komponent kan vara helt säker. Fråga tillverkaren eller återförsäljaren, eller läs mer på intel.com.",
      "³Enligt vad som uppmätts med branschens referenspunkter, testning med representativa användarguider och unika funktioner för Intel® Core™ i7-1165G7-processor. Om du vill se arbetsbelastningar och konfigurationer besöker du www.intel.com/PerformanceIndex. Resultat kan variera.",
      "⁴Enligt vad som uppmätts med Google Sheets Multitasking.",
      "⁵Enligt vad som uppmätts med Speedometer 2.0.",
      "⁶Enligt vad som uppmätts med KineMaster Export Video.",
      "Alla tester som nämns i fotnoterna 4–6 har gjorts på Intel® Core™ i3-1115G4 processorn jämfört med Intel® Celeron® N4020 processorn. Besök www.intel.com/PerformanceIndex för mer information om arbetsbelastning och konfiguration. Prestandan varierar beroende på användning, konfiguration och andra faktorer. Läs mer på www.intel.com/PerformanceIndex. Prestandaresultaten baseras på tester på de datum som visas i konfigurationerna och återspeglar kanske inte alla offentligt tillgängliga uppdateringar.",
      "⁷Tiden det tar att köra slut på batteriet från 100 % till en kritisk batterinivå när du utför typiska arbetsflöden i en realistisk miljö. Resultat kan variera. Mer information på intel.com/evo.",
      "⁸Resultat kan variera. Mer information på intel.com/evo.",
      "⁹Nästan 3X snabbare: 802.11ax med 2x2 160 MHz innebär en maximal teoretisk dataöverföringshastighet på 2 402 Mbit/s. Ungefär 3X snabbare än överföringshastigheten för 802.11ac med 2x2 80 MHz (867 Mbit/s) enligt IEEE-specifikationerna* för trådlösa överföringar med 802.11. En nätverksrouter för trådlösa överföringar med rätt 802.11ax-konfiguration krävs.",
      "¹⁰Laddning erhållen från OEM-standardavstängningsnivå. Resultat kan variera. Mer information på intel.com/evo.",
      "¹¹Jämfört med tidigare processorgeneration.",
      "¹²Enligt de unika Intel® Evo™ funktionerna som högpresterande Intel® Core™ CPU:er, premiumkomponenter, oöverträffad kompatibilitet och de senaste anslutningslösningarna i en elegant innovativ design. Alla Intel® Evo™ produkter måste uppfylla stränga krav för viktiga mobila användarupplevelser gällande respons- och batteritid. Prestanda kan variera mellan individuella enheter. Mer information på www.intel.com/PerformanceIndex (plattformar).",
      "¹³Intel® Unison™ lösning är för närvarande endast tillgänglig på kvalificerade Intel® Evo™ designer på Windows*-baserade datorer och parkopplar endast med Android*- eller iOS*-baserade telefoner. Alla enheter måste köra ett operativsystem som stöds. Individuell Intel® Evo™ enhetsprestanda kan variera. Mer information på intel.com/Performance-Evo.",
      "Egenskaperna och fördelarna med Intel® tekniker varierar beroende på systemkonfiguration och kan kräva aktiverad maskinvara, programvara och aktivering av tjänst. Prestandan varierar beroende på systemkonfiguration. Inget datorsystem kan vara helt säkert. Fråga tillverkaren eller återförsäljaren, eller läs mer på intel.com.",
      "*Övriga namn och varumärken tillhör respektive rättighetshavare.",
      "Intel ansvarar inte för felaktiga prisangivelser.",
      "© 2022 Intel Corporation. Celeron, Intel, Intels logotyp, Intel Core och Pentium är varumärken som tillhör Intel Corporation eller dess dotterbolag."
    ]
  }
}