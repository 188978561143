const Library_ChromeBook_mobile_nb = {
  "uiText": {
    "tr_endlessProgramsAndApps": "Uendelige programmer og apper",
    "tr_rethinkStorage": "Tenk nytt om lagring",
    "tr_alwaysOnSecurity": "Innebygd sikkerhet",
    "tr_extendedBatteryLife": "Utvidet batterilevetid<sup>1</sup>",
    "tr_viewMore": "Se mer",
    "tr_processorTitle": "PROSESSOR",
    "tr_processorTitleCamel": "Prosessor",
    "tr_rethinkStorageTitle": "TENK NYTT OM LAGRING",
    "tr_alwaysOnSecurityTitle": "INNEBYGD SIKKERHET",
    "tr_extendedBatteryLifeTitle": "UTVIDET BATTERILEVETID<sup>1</sup>",
    "tr_endlessProgramsAndAppsTitle": "UENDELIGE PROGRAMMER OG APPER",
    "tr_extendedBatteryLifeDescription": "[XX] timer",
    "tr_rethinkStorageDescriptionGB": "[XX]&nbsp;GB",
    "tr_rethinkStorageDescriptionTB": "[XX] TB",
    "tr_OS": "OPERATIV-SYSTEM",
    "tr_Chrome_OS": "ChromeOS*",
    "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD] GB HDD",
    "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD] TB HDD",
    "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD] GB HDD",
    "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD] TB HDD",
    "tr_textGBSSD": "[convertedSSD] GB SDD",
    "tr_textTBSSD": "[convertedSSD] TB SSD",
    "tr_textGBHDD": "[convertedHDD] GB HDD",
    "tr_textTBHDD": "[convertedHDD] TB HDD",
    "tr_graphicTitle": "GRAFIKK",
    "tr_storageTitle": "LAGRING",
    "tr_displayTitle": "SKJERM",
    "tr_batteryTitle": "BATTERI",
    "tr_memoryTitle": "MINNE",
    "tr_priceTitle": "PRIS"
  },
  "rethinkStorage": {
    "details": {
      "tr_tileTitle": "Tenk nytt om lagring",
      "tr_title": "En nyskapende tilnærming til lagring",
      "tr_subtitle": "Chromebook* kombinerer lagring på PC og den nesten ubegrensede ressursen Google* Disk i nettskyen. Dette er fordelene:",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/cloud_upload.svg",
          "tr_badgeSubTitle": "Alle filene dine sikkerhetskopieres automatisk til nettskyen, og du kan enkelt gjenopprette dem ved å logge på Google* -kontoen din.",
          "badgeSubTitle": "cloud-upload"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Jobb tilkoblet eller frakoblet med enhetslagring på [XX] GB.",
          "badgeSubTitle": "GBALONE"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Jobb tilkoblet eller frakoblet med enhetslagring på [XX] TB.",
          "badgeSubTitle": "TBALONE"
        }
      ]
    }
  },
  "alwaysOnSecurity": {
    "details": {
      "tr_tileTitle": "Innebygd sikkerhet",
      "tr_title": "Problemfri beskyttelse<sup class='sup-text'>2</sup>",
      "tr_subtitle": "Chromebook* oppdaterer automatisk alle sikkerhetsfunksjoner, slik at du kan surfe på internett og klikke på lenker med vissheten om at enheten er bedre beskyttet."
    }
  },
  "extendedBatteryLife": {
    "tr_tileTitle": "Utvidet batterilevetid<sup>1</sup>",
    "iposTypes": [
      {
        "iposType": "CHROMEBOOK",
        "details": {
          "tr_title": "Gjør mer, lad mindre<sup class='sup-text'>1</sup>",
          "tr_subtitle": "Den strømlinjeformede og energieffektive utformingen til Chromebook* gjør at du kan bruke den i mange timer uten å lade."
        }
      },
      {
        "iposType": "CHROMEBOOKEVO",
        "details": {
          "tr_title": "Lad mindre <br/>– og raskere.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Den strømlinjeformede og energieffektive utformingen til Chromebook* oppfyller de strenge batterikravene til Intel® Evo™ &#8209;plattformen og sørger for raskere lading og lengre tid mellom hver ladeøkt."
        }
      },
      {
        "iposType": "CHROMEBOOKEVOGEN12",
        "details": {
          "tr_title": "Batterilevetid som holder deg mobil.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "De hurtigladende batteriene med lang levetid i tynne og lette Chromebook*-enheter betyr at du ikke lenger trenger bekymre deg for hvor den nærmeste stikkontakten er."
        }
      }
    ]
  },
  "endlessProgramsAndApps": {
    "details": {
      "tr_tileTitle": "Uendelige programmer og apper",
      "tr_title": "Flere apper hver dag",
      "tr_subtitle": "Chromebook* -brukere har et enormt utvalg av apper og programmer å velge mellom, og det kommer hele tiden flere.",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/download_badge.svg",
          "tr_badgeSubTitle": "Last ned apper for Chrome* og Android*."
        },
        {
          "badgeUrl": "assets/images/badges/reset_badge.svg",
          "tr_badgeSubTitle": "Alle appene og dataene dine synkroniseres gjennom Google* -kontoen din."
        },
        {
          "badgeUrl": "assets/images/badges/connect_badge.svg",
          "tr_badgeSubTitle": "Uendelig mange muligheter for tilkobling, læring, skaping og lek."
        }
      ]
    }
  },
  "processor": [
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Oppgrader forventningene dine",
                "tr_subtitle": "Se serier og filmer på Chromebook* uten problemer med en 10. generasjons Intel® Core™ i3 -prosessor.",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i3 ‑prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Utviklet for eksepsjonell ytelse, uansett hvor du er.",
                "tr_subtitle": "",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i5 ‑prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Med en 10. generasjons Intel® Core™ i5 -prosessor"
                  },
                  {
                    "tr_badgeTitle": "Kraftig, selv når den kobles fra"
                  },
                  {
                    "tr_badgeTitle": "Hurtiglading: fire timer batteritid på kun 30 minutter<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Avanserte tilkoblingsmuligheter med Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ekte arbeid. Ekte fritid.",
                "tr_subtitle": "Videochat, send e-poster og lag presentasjoner enda mer effektivt på en Chromebook* med en 10. generasjons Intel® Core™ i5 -prosessor.",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i5 ‑prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Utviklet for eksepsjonell ytelse, uansett hvor du er.",
                "tr_subtitle": "",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i7 ‑prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Med en 10. generasjons Intel® Core™ i7 -prosessor"
                  },
                  {
                    "tr_badgeTitle": "Kraftig, selv når den kobles fra"
                  },
                  {
                    "tr_badgeTitle": "Hurtiglading: fire timer batteritid på kun 30 minutter<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Avanserte tilkoblingsmuligheter med Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Fordi ytelse er viktig",
                "tr_subtitle": "Rediger videoene dine med letthet, og last opp og del minner på en Chromebook* med en 10. generasjons Intel® Core™ i7 -prosessor.",
                "tr_gen_core_title": "10. generasjons Intel® Core™ i7 ‑prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rask og responsiv ytelse",
                "tr_subtitle": "",
                "tr_gen_core_title": "8. generasjons Intel® Core™ i3 ‑prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Gi kraft til lidenskapen og produktiviteten",
                "tr_subtitle": "",
                "tr_gen_core_title": "8. generasjons Intel® Core™ i5 ‑prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Gi kraft til lidenskapen og produktiviteten",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 5 &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Gi kraft til lidenskapen og produktiviteten",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Gi kraft til lidenskapen og produktiviteten",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 9 &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rask og responsiv ytelse",
                "tr_subtitle": "",
                "tr_gen_core_title": "8. generasjons Intel® Core™ m3 -prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Høy ytelse for det du gjør i dag",
                "tr_subtitle": "",
                "tr_gen_core_title": "7. generasjons Intel® Core™ i5 -prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Førsteklasses ytelse for det du gjør i dag",
                "tr_subtitle": "",
                "tr_gen_core_title": "7. generasjons Intel® Core™ i7 -prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ytelse for det du gjør i dag",
                "tr_subtitle": "",
                "tr_gen_core_title": "7. generasjons Intel® Core™ m3 -prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Forbedret ytelse for det du trenger",
                "tr_subtitle": "",
                "tr_gen_core_title": "6. generasjons Intel® Core™ i5 -prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Avansert ytelse for det du trenger",
                "tr_subtitle": "",
                "tr_gen_core_title": "6. generasjons Intel® Core™ i7 -prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ytelse for det du trenger",
                "tr_subtitle": "",
                "tr_gen_core_title": "6. generasjons Intel® Core™ m3 -prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Banebrytende ytelse for Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i3 ‑prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Opptil <br/><span class='big-blue-text'>2,4 ganger </span> <span class='subt-text'>bedre ytelse<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Opptil <br/><span class='big-blue-text'>3,2 ganger </span> <span class='subt-text'>raskere nettsurfing<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Opptil <br/><span class='big-blue-text'>2,1 ganger </span> <span class='subt-text'>raskere videoredigering<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Forestill deg alt du kan oppnå med en Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i5 ‑prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Opptil <br/><span class='big-blue-text'>2,7 ganger </span> <span class='subt-text'>bedre ytelse<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Opptil <br/><span class='big-blue-text'>3,3 ganger </span> <span class='subt-text'>raskere nettsurfing<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Opptil <br/><span class='big-blue-text'>2,8 ganger </span><span class='subt-text'>raskere videoredigering<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Utviklet for mobil ytelse",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i5 ‑prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Banebrytende ytelse med 11.&nbsp;generasjons Intel®&nbsp;Core™ &#8209;prosessorer"
                  },
                  {
                    "tr_badgeTitle": "Våkner på mindre enn 1 sekund<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nesten tre ganger raskere Internett med Intel® Wi-Fi 6 (Gig+) <sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batteri med lang levetid og hurtiglading"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Verdens beste prosessor for Chromebook-enheter*&nbsp;<sup class='sup-text'>1</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i7 ‑prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Opptil <br/><span class='big-blue-text'>2,9 ganger </span> <span class='subt-text'>bedre ytelse<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Opptil <br/><span class='big-blue-text'>3,5 ganger </span> <span class='subt-text'>raskere nettsurfing<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Opptil <br/><span class='big-blue-text'>2,9 ganger </span> <span class='subt-text'>raskere videoredigering<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Utviklet for mobil ytelse",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. generasjons Intel® Core™ i7 ‑prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Banebrytende ytelse med 11.&nbsp;generasjons Intel®&nbsp;Core™ &#8209;prosessorer"
                  },
                  {
                    "tr_badgeTitle": "Våkner på mindre enn 1 sekund<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nesten tre ganger raskere Internett med Intel® Wi-Fi 6 (Gig+) <sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batteri med lang levetid og hurtiglading"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rask ytelse i hverdagen",
                "tr_subtitle": "Få utmerket ytelse i alle favorittprogrammene og -appene dine med kraften til en Intel® Pentium® -prosessor",
                "tr_gen_core_title": "Intel® Pentium® Gold &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rask ytelse i hverdagen",
                "tr_subtitle": "Få utmerket ytelse i alle favorittprogrammene og -appene dine med kraften til en Intel® Pentium® -prosessor",
                "tr_gen_core_title": "Intel® Pentium® Silver &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Øk den daglige produktiviteten",
                "tr_subtitle": "Fra lasting av apper til åpning av prosjekter – en Intel®&nbsp;Celeron® -prosessor hjelper deg med å få utrettet mer.",
                "tr_gen_core_title": "Intel® Celeron® &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ytelse og verdi.",
                "tr_subtitle": "Laget for fantastisk underholdning, videostreaming og produktivitet. Se filmer og nettvideoer i fantastiske detaljer. Bruk mer tid på å jobbe og mindre tid på å vente.",
                "tr_gen_core_title": "Intel® Pentium® &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rask ytelse i hverdagen",
                "tr_subtitle": "Få utmerket ytelse i alle favorittprogrammene og -appene dine med kraften til en Intel® Pentium® -prosessor",
                "tr_gen_core_title": "Intel® Pentium® &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ytelse og verdi.",
                "tr_subtitle": "Forbedret produktivitet – økt ytelse for raskere utførelse av oppgaver. Effektiv nettsurfing – last raskt inn innholdsrike nettsider for mer surfing og mindre venting.",
                "tr_gen_core_title": "Intel® Pentium® Gold &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ytelse og verdi.",
                "tr_subtitle": "Hold tritt med den digitale verdenen, med ytelse som gir tilgang til filer fra nettet. Bytt enkelt mellom programmer og få mer gjort på mindre tid.",
                "tr_gen_core_title": "Intel® Pentium® Silver &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ytelse og verdi.",
                "tr_subtitle": "Ytelse som gjør arbeidet enklere og underholdningen morsommere. Se på filmer og nettvideoer i fantastiske detaljer. Bruk mer tid på å jobbe og mindre tid på å vente.",
                "tr_gen_core_title": "Intel® Celeron® &#8209;prosessor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Pålitelig ytelse der den trengs mest.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i3 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Enkel<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Innebygd<br/>bakgrunnsuskarphet"
                  },
                  {
                    "tr_badgeTitle": "Responsiv<br/>nettsurfing"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "En økning i ytelse der det trengs mest.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i5 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Kraftig<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Auto-støydemping<br/>"
                  },
                  {
                    "tr_badgeTitle": "HD-streaming<br/>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Mobil ytelse har utviklet seg.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i5 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Overlegen ytelse der du trenger det mest, drevet av 12.&nbsp;generasjons Intel®&nbsp;Core™ &#8209;prosessorer<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Våkner på mindre enn 1 sekund<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nesten tre ganger raskere Internett med Intel® Wi‑Fi 6/6E (Gig+) <sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batteri med lang levetid og hurtiglading"
                  },
                  {
                    "tr_badgeTitle": "Forbedret samarbeid og tilkoblinger"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Overlegen ytelse der du trenger det mest.<sup>11</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i7 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ekstrem<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Innebygd støydemping<br/>og<br/>bakgrunnsuskarphet"
                  },
                  {
                    "tr_badgeTitle": "Jevn<br/>streaming i 8K"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Mobil ytelse har utviklet seg.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12.&nbsp;generasjons Intel®&nbsp;Core™&nbsp;i7 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Overlegen ytelse der du trenger det mest, drevet av 12.&nbsp;generasjons Intel®&nbsp;Core™ &#8209;prosessorer<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Våkner på mindre enn 1 sekund<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Nesten tre ganger raskere Internett med Intel® Wi‑Fi 6/6E (Gig+) <sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batteri med lang levetid og hurtiglading"
                  },
                  {
                    "tr_badgeTitle": "Forbedret samarbeid og tilkoblinger"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "En&nbsp;Chromebook*&#8209;<br/>revolusjon.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. generasjons Intel® Core™ i5 ‑prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tynn og lett design som strekker seg ut over ytelse<br/><span class='marklist'>og</span> kobles sømløst med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> velger intelligent den beste Wi-Fi- eller 5G-tilkoblingen<br/><span class='marklist'>og</span> lader raskt med et batteri som varer"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gjør det du gjør med eksepsjonell ytelse.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. generasjons Intel® Core™ i5 ‑prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Forbedret<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Jevn streaming<br/>i 4K"
                  },
                  {
                    "tr_badgeTitle": "Kraftig<br/>innholdsproduksjon"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "En&nbsp;Chromebook*&#8209;<br/>revolusjon.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. generasjons Intel® Core™ i7 ‑prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tynn og lett design som strekker seg ut over ytelse<br/><span class='marklist'>og</span> kobles sømløst med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> velger intelligent den beste Wi-Fi- eller 5G-tilkoblingen<br/><span class='marklist'>og</span> lader raskt med et batteri som varer"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gjør det du gjør med mer enn overlegen ytelse.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. generasjons Intel® Core™ i7 ‑prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ekstrem<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Jevn streaming<br/>i 8K"
                  },
                  {
                    "tr_badgeTitle": "Avansert<br/>innholdsproduksjon"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Intel® ‑prosessor",
                "tr_subtitle": "Ytelsen du trenger. Kostnadsbesparelsen du ønsker.",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gjør det du gjør med overlegen ytelse.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. generasjons Intel® Core™ i3 ‑prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Enkel<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Jevn<br/>streaming"
                  },
                  {
                    "tr_badgeTitle": "Rask<br/>innholdsproduksjon"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "En&nbsp;Chromebook*&#8209;<br/>revolusjon.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tynn og lett design som strekker seg ut over ytelse<br/><span class='marklist'>og</span> kobles sømløst med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> velger intelligent den beste Wi-Fi- eller 5G-tilkoblingen<br/><span class='marklist'>og</span> lader raskt med et batteri som varer"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gjør det du gjør med overlegen ytelse.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Enkel<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Jevn<br/>streaming"
                  },
                  {
                    "tr_badgeTitle": "Rask<br/>innholdsproduksjon"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "En&nbsp;Chromebook*&#8209;<br/>revolusjon.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tynn og lett design som strekker seg ut over ytelse<br/><span class='marklist'>og</span> kobles sømløst med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> velger intelligent den beste Wi-Fi- eller 5G-tilkoblingen<br/><span class='marklist'>og</span> lader raskt med et batteri som varer"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gjør det du gjør med eksepsjonell ytelse.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Forbedret<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Jevn streaming<br/>i 4K"
                  },
                  {
                    "tr_badgeTitle": "Kraftig<br/>innholdsproduksjon"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "En&nbsp;Chromebook*&#8209;<br/>revolusjon.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tynn og lett design som strekker seg ut over ytelse<br/><span class='marklist'>og</span> kobles sømløst med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> velger intelligent den beste Wi-Fi- eller 5G-tilkoblingen<br/><span class='marklist'>og</span> lader raskt med et batteri som varer"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gjør det du gjør med mer enn overlegen ytelse.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ekstrem<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Jevn streaming<br/>i 8K"
                  },
                  {
                    "tr_badgeTitle": "Avansert<br/>innholdsproduksjon"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "En&nbsp;Chromebook*&#8209;<br/>revolusjon.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i9 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tynn og lett design som strekker seg ut over ytelse<br/><span class='marklist'>og</span> kobles sømløst med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> velger intelligent den beste Wi-Fi- eller 5G-tilkoblingen<br/><span class='marklist'>og</span> lader raskt med et batteri som varer"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Driv lidenskapen din med overlegen ytelse",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i9 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Rask foto-<br/>og videoredigering"
                  },
                  {
                    "tr_badgeTitle": "Forbedret offline-<br/>funksjonalitet"
                  },
                  {
                    "tr_badgeTitle": "Spill<br/>uten bekymringer med<br/>lang batterilevetid"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_3",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "En&nbsp;Chromebook*&#8209;<br/>revolusjon.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tynn og lett design som strekker seg ut over ytelse<br/><span class='marklist'>og</span> kobles sømløst med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> velger intelligent den beste Wi-Fi- eller 5G-tilkoblingen<br/><span class='marklist'>og</span> lader raskt med et batteri som varer"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gjør det du gjør med overlegen ytelse.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Enkel<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Jevn<br/>streaming"
                  },
                  {
                    "tr_badgeTitle": "Rask<br/>innholdsproduksjon"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_5",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "En&nbsp;Chromebook*&#8209;<br/>revolusjon.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tynn og lett design som strekker seg ut over ytelse<br/><span class='marklist'>og</span> kobles sømløst med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> velger intelligent den beste Wi-Fi- eller 5G-tilkoblingen<br/><span class='marklist'>og</span> lader raskt med et batteri som varer"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gjør det du gjør med eksepsjonell ytelse.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Forbedret<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Jevn streaming<br/>i 4K"
                  },
                  {
                    "tr_badgeTitle": "Kraftig<br/>innholdsproduksjon"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_7",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "En&nbsp;Chromebook*&#8209;<br/>revolusjon.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tynn og lett design som strekker seg ut over ytelse<br/><span class='marklist'>og</span> kobles sømløst med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> velger intelligent den beste Wi-Fi- eller 5G-tilkoblingen<br/><span class='marklist'>og</span> lader raskt med et batteri som varer"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gjør det du gjør med mer enn overlegen ytelse.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ekstrem<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Jevn streaming<br/>i 8K"
                  },
                  {
                    "tr_badgeTitle": "Avansert<br/>innholdsproduksjon"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "En&nbsp;Chromebook*&#8209;<br/>revolusjon.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 5 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tynn og lett design som strekker seg ut over ytelse<br/><span class='marklist'>og</span> kobles sømløst med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> velger intelligent den beste Wi-Fi- eller 5G-tilkoblingen<br/><span class='marklist'>og</span> lader raskt med et batteri som varer"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Lås opp nye AI-opplevelser",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 5 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Opptil 65 % raskere<br/>generativ AI"
                  },
                  {
                    "tr_badgeTitle": "Opptil 2,3 ganger raskere<br/>AI-videoredigering"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "En&nbsp;Chromebook*&#8209;<br/>revolusjon.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tynn og lett design som strekker seg ut over ytelse<br/><span class='marklist'>og</span> kobles sømløst med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> velger intelligent den beste Wi-Fi- eller 5G-tilkoblingen<br/><span class='marklist'>og</span> lader raskt med et batteri som varer"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Lås opp nye AI-opplevelser",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Opptil 65 % raskere<br/>generativ AI"
                  },
                  {
                    "tr_badgeTitle": "Opptil 2,3 ganger raskere<br/>AI-videoredigering"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "En&nbsp;Chromebook*&#8209;<br/>revolusjon.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 9 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tynn og lett design som strekker seg ut over ytelse<br/><span class='marklist'>og</span> kobles sømløst med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> velger intelligent den beste Wi-Fi- eller 5G-tilkoblingen<br/><span class='marklist'>og</span> lader raskt med et batteri som varer"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Prosessor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Lås opp nye AI-opplevelser",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 9 &#8209;prosessor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Opptil 65 % raskere<br/>generativ AI"
                  },
                  {
                    "tr_badgeTitle": "Opptil 2,3 ganger raskere<br/>AI-videoredigering"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "legalText": {
    "tr_specTitle": "Juridisk",
    "tr_paragraph_array": [
      "¹Opptil ti timers batterilevetid basert på en full opplading, i henhold til intern Google*-testing.",
      "²Ingen produkter eller komponenter kan være fullstendig sikre. Kontakt systemprodusenten eller forhandleren, eller les mer på intel.com.",
      "³Som målt med ytelsestester etter bransjestandard, RUG-testing (Representative Usage Guide) og unike funksjoner ved Intel® Core™ i7-1165G7 -prosessoren. Du finner informasjon om arbeidsbelastninger og konfigurasjoner på www.intel.com/PerformanceIndex. Resultater kan variere.",
      "⁴Som målt med Google Sheets Multitasking.",
      "⁵Som målt med Speedometer 2.0.",
      "⁶Som målt med Kinemaster Export Video.",
      "For fotnote 4–6: All testing er utført på Intel® Core™ i3-1115G4 ‑prosessor kontra Intel® Celeron® N4020 ‑prosessor. Gå til www.intel.com/PerformanceIndex hvis du vil ha mer informasjon om arbeidsbelastninger og konfigurasjoner. Ytelse varierer avhengig av bruk, konfigurasjon og andre faktorer. Les mer på www.intel.com/PerformanceIndex. Ytelsesresultatene er basert på testing per datoene som vises i konfigurasjonene, og gjenspeiler kanskje ikke alle offentlig tilgjengelige oppdateringer.",
      "⁷Tiden det tar å tømme batteriet fra 100 % til kritisk batterinivå mens du utfører typisk arbeid i et realistisk miljø. Resultater kan variere. Du finner mer informasjon på intel.com/evo.",
      "⁸Resultater kan variere. Du finner mer informasjon på intel.com/evo.",
      "⁹Nesten tre ganger raskere: 802.11ax 2x2 160 MHz muliggjør teoretiske maksimale datahastigheter på 2402 Mbps. Ca. tre ganger raskere enn standard 802.11ac 2x2 80 MHz (867 Mbps) Wi‑Fi, som dokumentert i IEEE 802.11* (standardspesifikasjoner for trådløs teknologi), og krever bruk av trådløse 802.11ax-nettverksrutere med lignende konfigurasjon.",
      "¹⁰Lading oppnådd fra OEM‑standard avstengingsnivå. Resultater kan variere. Du finner mer informasjon på intel.com/evo.",
      "¹¹Sammenlignet med tidligere prosessorgenerasjon.",
      "¹²Som målt med unike egenskaper ved Intel® Evo™ ‑utforminger som for eksempel Intel® Core™ ‑prosessorer med høy ytelse, førsteklasses komponenter, uovertruffen kompatibilitet og de nyeste tilkoblingsløsningene i slanke innovative utforminger. Alle Intel® Evo™ ‑merkede utforminger må tilfredsstille krevende terskler for viktige mobile brukeropplevelser som responstid og batterilevetid. Individuell enhetsytelse kan variere. Gå til www.intel.com/PerformanceIndex (platforms) for detaljer.",
      "¹³Intel® Unison™ ‑løsningen er for øyeblikket bare tilgjengelig på støttede Intel® Evo™ ‑utforminger på Windows*-baserte PC-er og kan kun pares med Android* eller iOS* baserte telefoner. Alle enheter må kjøre en støttet OS-versjon. Ytelsen til individuelle Intel® Evo™ -enheter kan variere. Du finner mer informasjon på intel.com/Performance-Evo.",
      "Intel® -teknologienes funksjoner og fordeler avhenger av systemkonfigurasjonen og kan kreve kompatibel maskinvare, programvare eller aktivering av tjenester. Ytelsen varierer avhengig av systemkonfigurasjonen. Ingen datasystemer kan være fullstendig sikre. Forhør deg med systemprodusenten eller forhandleren, eller les mer på intel.com.",
      "*Andre navn og varemerker tilhører sine respektive eiere.",
      "Intel tar ikke ansvar for feil priser.",
      "© 2022 Intel Corporation. Celeron, Intel, Intel-logoen, Intel Core og Pentium er varemerker for Intel Corporation eller dets datterselskaper."
    ]
  }
}