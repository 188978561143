const Library_mobile_fr = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Équipé d'un processeur [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Équipé d'un processeur [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Votre processeur contrôle les actions de votre PC, de la même manière qu'un cerveau. Cela signifie que les vidéos que vous regardez, les jeux auxquels vous jouez et les sites Web que vous visitez passent tous par votre processeur.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Le processeur est le cerveau de votre PC"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Performances à un prix abordable.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processeur Intel® Pentium® Silver",
                    "tr_subtitle": "Maîtrisez votre monde numérique grâce à des performances vous permettant d'accéder à vos fichiers depuis le Web. Travaillez en multitâche en passant facilement d'un programme à un autre et faisant plus de choses en moins de temps.",
                    "tr_gen_core_title": "Processeur Intel® Pentium® Silver"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processeur Intel® Pentium® Gold",
                    "tr_subtitle": "Productivité accrue – Des performances élevées pour rester productif les jours les plus exigeants. Navigation Web simplifiée – Chargez rapidement les pages Web riches en contenu multimédia pour plus d'exploration et moins d'attente.",
                    "tr_gen_core_title": "Processeur Intel® Pentium® Gold"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processeur Intel® Celeron®",
                    "tr_subtitle": "Les performances pour accélérer votre travail et vos loisirs. Profitez de vos films et vidéos Web avec un niveau de détail remarquable. Faites plus de choses avec un minimum d'attente.",
                    "tr_gen_core_title": "Processeur Intel® Celeron®"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processeur Intel® Pentium®",
                    "tr_subtitle": "Conçu pour les loisirs, le streaming vidéo et la productivité. Profitez de vos films et vidéos Web avec un niveau de détail remarquable. Faites plus de choses avec un minimum d'attente.",
                    "tr_gen_core_title": "Processeur Intel® Pentium®"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Les performances dont vous avez besoin, sans dépasser votre budget.",
                "tr_title": "Processeur Intel®",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Des performances accessibles pour tous",
                    "tr_subtitle": "Un processeur Intel® gère quasiment toutes les tâches, à un prix abordable. Naviguez facilement entre les logiciels ou profitez de graphiques de qualité avec des performances stables.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Établissez des connexions plus puissantes",
                    "tr_subtitle": "Collaborez en toute confiance, partout où vous en avez besoin, grâce aux vidéos plus éclatantes, au son plus riche et à une connectivité Internet près de trois fois plus rapide<sup>26</sup>.",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "La nuit et le jour",
                    "tr_subtitle": "Des marathons de cinéma aux retouches de dernière minute, le processeur Intel® prolonge l'autonomie de votre appareil pour vous offrir des expériences véritablement immersives et ininterrompues.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Chaque environnement favorise l'apprentissage",
                    "tr_subtitle": "Utilisez facilement plusieurs outils d'apprentissage en ligne dans toute la maison."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Apprenez où vous voulez",
                    "tr_subtitle": "Terminez vos leçons où que vous vous trouviez tout en utilisant simultanément plusieurs outils d'apprentissage en ligne."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Pour les loisirs et le travail, profitez de la puissance qui correspond à votre budget et à votre style de vie.",
                "tr_title": "Performances Intel pour tous les budgets.",
                "tr_gen_core_title": "Processeur Intel® Celeron®",
                "processorCompare": {
                  "tr_title": "TROUVEZ LE NIVEAU DE PERFORMANCES ADAPTÉ, À UN PRIX ABORDABLE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Processeur Intel® Celeron®"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Processeur Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Navigation rapide sur des sites Web riches en contenus",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Passage facile d'un programme à un autre",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux occasionnels",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Vidéos&nbsp;4K en streaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tâches légères de retouche de photos et d'édition de vidéos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Productivité accrue grâce aux performances intelligentes",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Équipé d'un processeur Intel® Core™&nbsp;[cpu_fam] de 7<sup>e</sup>&nbsp;génération"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Équipé d'un processeur Intel® Core™&nbsp;[cpu_fam] de 6<sup>e</sup>&nbsp;génération"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Équipé d'un processeur Intel® Core™&nbsp;[cpu_fam] de 5<sup>e</sup>&nbsp;génération"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Équipé d'un processeur Intel® Core™&nbsp;[cpu_fam] de 4<sup>e</sup>&nbsp;génération"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Équipé d'un processeur Intel® Core™&nbsp;[cpu_fam] de 3<sup>e</sup>&nbsp;génération"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Équipé d'un processeur Intel® Core™&nbsp;[cpu_fam] de 2<sup>e</sup>&nbsp;génération"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Équipé d'un processeur Intel® Core™&nbsp;[cpu_fam] de 1<sup>e</sup>&nbsp;génération"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Recommandé pour&nbsp;:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Équipé d'un processeur Intel® Core™ i3 de 8<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 de 8<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Les processeurs Intel® Core™ de 9<sup>e</sup>&nbsp;génération ont été créés pour&nbsp;suivre l'ère du&nbsp;numérique. Réunissant vitesse et performances, ce&nbsp;PC&nbsp;vous permettra de vous adonner à&nbsp;vos activités préférées sans&nbsp;être&nbsp;frustré.",
                "tr_title": "Équipé d'un processeur Intel®&nbsp;Core™&nbsp;i3 de 9<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 de 9<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Profitez de performances polyvalentes et d'une grande autonomie qui vous suivent partout.",
                "tr_title": "Puissance pour toute la journée",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 de 10<sup>e</sup>&nbsp;génération",
                "processorCompare": {
                  "tr_title": "TROUVEZ LA CATÉGORIE DE PROCESSEURS DE 10<sup>e</sup>&nbsp;GÉNÉRATION ADAPTÉE À VOS BESOINS",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming de films en 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Une connectivité rapide et fiable",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Masquage de photos et filtrage de vidéos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux à une résolution de 1080p avec&nbsp;des&nbsp;fréquences&nbsp;d'images&nbsp;fluides",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Utilisation de fichiers volumineux et&nbsp;de&nbsp;programmes&nbsp;de&nbsp;création",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ce processeur ultra-performant vous permet de passer facilement d'un&nbsp;film&nbsp;en&nbsp;streaming à la création de présentations.",
                "tr_title": "Puissance pour toute la journée",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 de 10<sup>e</sup>&nbsp;génération"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Nous avons créé des ordinateurs portables conçus pour ceux qui sont constamment en déplacement.",
                "tr_title": "Découvrez des PC portables nouvelle génération",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 de 10<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Conçu spécifiquement pour les PC portables, le processeur Intel® Core™&nbsp;i3 de 11<sup>e</sup>&nbsp;génération vous permet d'en faire plus que jamais, où que vous soyez.",
                "tr_title": "Obtenez plus de votre nouveau PC portable",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i3 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Lorsque votre famille s'appuie sur un PC de bureau pour tout faire, vous avez besoin de la puissance et de la polyvalence d'un processeur Intel® Core™&nbsp;i3 de 11<sup>e</sup>&nbsp;génération.",
                "tr_title": "Soyez prêt à tout grâce à des performances révolutionnaires.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i3 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Équipé d'un processeur Intel® Core™&nbsp;i5 de 8<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 8<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Les processeurs Intel® Core™ de 9<sup>e</sup>&nbsp;génération ont été créés pour&nbsp;suivre l'ère du&nbsp;numérique. Réunissant vitesse et performances, ce&nbsp;PC&nbsp;vous permettra de vous adonner à&nbsp;vos activités préférées sans&nbsp;être&nbsp;frustré.",
                "tr_title": "Équipé d'un processeur Intel® Core™&nbsp;i5 de 9<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 9<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "JEU ET RV FLUIDES SUR LES TITRES LES PLUS EXIGEANTS",
                "tr_title": "Les jeux vidéo sérieux commencent ici",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 9<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROUVEZ LE PROCESSEUR INTEL® CORE™ ADAPTÉ À VOTRE FAÇON DE JOUER.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jeux AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux vidéo à couper le souffle",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jouez, partagez et enregistrez sans limites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Meilleur processeur Intel® Core™ de 9<sup>e</sup>&nbsp;génération pour les jeux vidéo",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 4<br/>Threads&nbsp;: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Jonglez facilement entre plusieurs programmes pour être toujours prêt, quoi que vous fassiez et où que vous vous trouviez.",
                "tr_title": "Multitâche mobile",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 10<sup>e</sup>&nbsp;génération",
                "processorCompare": {
                  "tr_title": "TROUVEZ LA CATÉGORIE DE PROCESSEURS DE 10<sup>e</sup>&nbsp;GÉNÉRATION ADAPTÉE À VOS BESOINS",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i3"
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming de films en 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Une connectivité rapide et fiable",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Masquage de photos et filtrage de vidéos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux à une résolution de 1080p avec&nbsp;des&nbsp;fréquences&nbsp;d'images&nbsp;fluides",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Utilisation de fichiers volumineux et&nbsp;de&nbsp;programmes&nbsp;de&nbsp;création",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Des loisirs à la productivité en passant par la création de contenus, toutes vos tâches prennent une nouvelle dimension.",
                "tr_title": "Choisissez un PC plus puissant",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 10<sup>e</sup>&nbsp;génération"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Nous avons créé des ordinateurs portables conçus pour ceux qui sont constamment en déplacement.",
                "tr_title": "Découvrez des PC portables nouvelle génération",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 10<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Conçu pour un streaming et des jeux vidéo AAA fluides",
                "tr_title": "Vos propres règles du jeu",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 10<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROUVEZ LE PROCESSEUR INTEL® CORE™ DE 10<sup>e</sup>&nbsp;GÉNÉRATION ADAPTÉ À VOTRE FAÇON DE JOUER.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jeux AAA fluides",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux vidéo à couper le souffle",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming et enregistrement",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Création de contenu avancée",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performances de jeu maximales",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Débloqué avec possibilités d'overclocking<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Conçu pour un streaming et des jeux vidéo AAA fluides",
                "tr_title": "Vos propres règles du jeu",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 10<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROUVEZ LE PROCESSEUR INTEL® CORE™ DE 10<sup>e</sup>&nbsp;GÉNÉRATION ADAPTÉ À VOTRE FAÇON DE JOUER.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jeux AAA fluides",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux vidéo à couper le souffle",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming et enregistrement",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Création de contenu avancée",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performances de jeu maximales",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Débloqué avec possibilités d'overclocking<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 10<br/>Threads&nbsp;: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Jonglez facilement entre plusieurs programmes pour être toujours prêt, quoi que vous fassiez et où que vous vous trouviez.",
                "tr_title": "Multitâche mobile",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 10<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 10<sup>e</sup>&nbsp;GÉNÉRATION"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Le processeur Intel® Core™&nbsp;i5 de 11<sup>e</sup>&nbsp;génération offre des expériences visuelles éblouissantes et une productivité étendue aux PC les plus fins et les plus légers.",
                "tr_title": "Puissance impressionnante sur PC portable",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Lorsque votre famille s'appuie sur un PC de bureau pour tout faire, vous avez besoin de la puissance et de la polyvalence d'un processeur Intel® Core™&nbsp;i5 de 11<sup>e</sup>&nbsp;génération.",
                "tr_title": "Soyez prêt à tout grâce à des performances révolutionnaires.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Avec le processeur Intel® Core™&nbsp;i5 de 11<sup>e</sup>&nbsp;génération, les PC portables fins et légers offrent des expériences visuelles éblouissantes et une productivité étendue.",
                "tr_title": "Puissance impressionnante sur PC portable",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 11<sup>e</sup>&nbsp;GÉNÉRATION"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Équipé d'un processeur Intel® Core™&nbsp;i7 de 8<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 8<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Les processeurs Intel® Core™ de 9<sup>e</sup>&nbsp;génération ont été créés pour&nbsp;suivre l'ère du&nbsp;numérique. Réunissant vitesse et performances, ce&nbsp;PC&nbsp;vous permettra de vous adonner à&nbsp;vos activités préférées sans&nbsp;être&nbsp;frustré.",
                "tr_title": "Équipé d'un processeur Intel® Core™&nbsp;i7 de 9<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 9<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FRÉQUENCE D'IMAGES JUSQU'À 32&nbsp;% PLUS ÉLEVÉE lors du jeu, du streaming et de l'enregistrement par rapport à un PC DE 3&nbsp;ANS<sup>3</sup>",
                "tr_title": "La puissance nécessaire pour partager vos plus grands moments de jeu",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 9<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROUVEZ LE PROCESSEUR INTEL® CORE™ ADAPTÉ À VOTRE FAÇON DE JOUER.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jeux AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux vidéo à couper le souffle",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jouez, partagez et enregistrez sans limites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Meilleur processeur Intel® Core™ de 9<sup>e</sup>&nbsp;génération pour les jeux vidéo",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 4<br/>Threads&nbsp;: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Cet appareil élégant conçu dans une optique d'efficacité peut avoir un énorme impact sur la façon dont vous créez, vous connectez et diffusez des contenus.",
                "tr_title": "Profitez de performances avancées partout",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 10<sup>e</sup>&nbsp;génération",
                "processorCompare": {
                  "tr_title": "TROUVEZ LA CATÉGORIE DE PROCESSEURS DE 10<sup>e</sup>&nbsp;GÉNÉRATION ADAPTÉE À VOS BESOINS",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming de films en 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Une connectivité rapide et fiable",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Masquage de photos et filtrage de vidéos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux à une résolution de 1080p avec&nbsp;des&nbsp;fréquences&nbsp;d'images&nbsp;fluides",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Utilisation de fichiers volumineux et&nbsp;de&nbsp;programmes&nbsp;de&nbsp;création",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Des performances avancées pour porter tout ce que vous faites<br />au niveau supérieur.",
                "tr_title": "Un monstre de puissance",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 10<sup>e</sup>&nbsp;génération"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Nous avons créé des ordinateurs portables conçus pour ceux qui sont constamment en déplacement.",
                "tr_title": "Découvrez des PC portables nouvelle génération",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 10<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Jouez, partagez et enregistrez avec un matériel de pointe",
                "tr_title": "Propulsez vos mondes virtuels",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 10<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROUVEZ LE PROCESSEUR INTEL® CORE™ DE 10<sup>e</sup>&nbsp;GÉNÉRATION ADAPTÉ À VOTRE FAÇON DE JOUER.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jeux AAA fluides",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux vidéo à couper le souffle",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming et enregistrement",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Création de contenu avancée",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performances de jeu maximales",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Débloqué avec possibilités d'overclocking<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Jouez, partagez et enregistrez avec un matériel de pointe",
                "tr_title": "Propulsez vos mondes virtuels",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 10<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROUVEZ LE PROCESSEUR INTEL® CORE™ DE 10<sup>e</sup>&nbsp;GÉNÉRATION ADAPTÉ À VOTRE FAÇON DE JOUER.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jeux AAA fluides",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux vidéo à couper le souffle",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming et enregistrement",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Création de contenu avancée",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performances de jeu maximales",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Débloqué avec possibilités d'overclocking<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 10<br/>Threads&nbsp;: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Cet appareil efficace et élégant peut avoir un impact énorme sur votre façon de créer, de vous connecter et de streamer.",
                "tr_title": "Des performances haut de gamme, où que vous soyez",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 10<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 10<sup>e</sup>&nbsp;GÉNÉRATION"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Le processeur Intel® Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération offre une création de contenu avancée, des jeux vidéo fluides et des divertissements de niveau supérieur aux PC ultra-portables.",
                "tr_title": "Un nouveau niveau de performances sur PC portable",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Faites de votre PC de bureau la destination favorite pour tout ce dont votre maison pourrait avoir besoin, grâce aux performances révolutionnaires d'un processeur Intel® Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération.",
                "tr_title": "Travailler. Apprendre. Jouer. Sans limites.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Avec le processeur Intel® Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération, les PC portables fins et légers conviennent à la création de contenus avancée, aux jeux vidéo fluides et au divertissement de niveau supérieur.",
                "tr_title": "Un nouveau niveau de performances sur PC portable",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 11<sup>e</sup>&nbsp;GÉNÉRATION"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Faites de votre PC de bureau la destination favorite pour tout ce dont votre maison pourrait avoir besoin, grâce aux performances révolutionnaires d'un processeur Intel® Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération.",
                "tr_title": "Créez sans limites",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 11<sup>e</sup>&nbsp;GÉNÉRATION"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ce processeur Intel® Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération est plus puissant que jamais. Il permet la création de contenus avancée, des jeux vidéo fluides et un divertissement de niveau supérieur sur des PC portables fins et légers.",
                "tr_title": "Un nouveau niveau de performances sur PC portable",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 11<sup>e</sup>&nbsp;GÉNÉRATION"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Équipé d'un processeur Intel® Core™&nbsp;i9 de 8<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 8<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Les processeurs Intel® Core™ de 9<sup>e</sup>&nbsp;génération ont été créés pour&nbsp;suivre l'ère du&nbsp;numérique. Réunissant vitesse et performances, ce&nbsp;PC&nbsp;vous permettra de vous adonner à&nbsp;vos activités préférées sans&nbsp;être&nbsp;frustré.",
                "tr_title": "Équipé d'un Processeur Intel®&nbsp;Core™&nbsp;i9 de 9<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 9<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FRÉQUENCE D'IMAGES JUSQU'À 41&nbsp;% PLUS ÉLEVÉE lors du jeu, du streaming et de l'enregistrement par rapport à un PC DE 3&nbsp;ANS<sup>4</sup>",
                "tr_title": "Le processeur Intel® Core™&nbsp;i9 offre ce que les gamers exigent.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 9<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROUVEZ LE PROCESSEUR INTEL® CORE™ ADAPTÉ À VOTRE FAÇON DE JOUER.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jeux AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux vidéo à couper le souffle",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jouez, partagez et enregistrez sans limites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Meilleur processeur Intel® Core™ de 9<sup>e</sup>&nbsp;génération pour les jeux vidéo",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 4<br/>Threads&nbsp;: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Repoussez vos limites et profitez d'une expérience de jeu ultime",
                "tr_title": "Rivalisez au plus haut niveau",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 10<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROUVEZ LE PROCESSEUR INTEL® CORE™ DE 10<sup>e</sup>&nbsp;GÉNÉRATION ADAPTÉ À VOTRE FAÇON DE JOUER.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jeux AAA fluides",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux vidéo à couper le souffle",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming et enregistrement",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Création de contenu avancée",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performances de jeu maximales",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Débloqué avec possibilités d'overclocking<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Repoussez vos limites et profitez d'une expérience de jeu ultime",
                "tr_title": "Rivalisez au plus haut niveau",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 10<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROUVEZ LE PROCESSEUR INTEL® CORE™ DE 10<sup>e</sup>&nbsp;GÉNÉRATION ADAPTÉ À VOTRE FAÇON DE JOUER.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jeux AAA fluides",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux vidéo à couper le souffle",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming et enregistrement",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Création de contenu avancée",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performances de jeu maximales",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Débloqué avec possibilités d'overclocking<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 10<br/>Threads&nbsp;: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Repoussez vos limites et profitez d'une expérience de création ultime",
                "tr_title": "Créez au plus haut niveau",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 10<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 10<sup>e</sup>&nbsp;GÉNÉRATION",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Les PC portables Intel® Evo™ équipés d'un processeur Intel® Core™&nbsp;i5 de 11<sup>e</sup>&nbsp;génération et d'une partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup> vous offrent une vitesse et des performances incroyables, dans un design fin et léger.",
                "tr_title": "Puissance élevée pour un PC portable exceptionnel",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Les PC portables Intel® Evo™ équipés d'un processeur Intel® Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération et d'une partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup> vous offrent une vitesse et des performances avancées pour une expérience mobile sans compromis.",
                "tr_title": "Puissance avancée pour un PC portable exceptionnel",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Profitez d'un excellent équilibre entre les performances et les expériences de connectivité et multimédias à un prix étonnant.",
                "tr_title": "Performances et connectivité à un prix étonnant.",
                "tr_gen_core_title": "Processeur Intel® Pentium® Silver",
                "processorCompare": {
                  "tr_title": "TROUVEZ LE NIVEAU DE PERFORMANCES ADAPTÉ, À UN PRIX ABORDABLE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Processeur Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Processeur Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Navigation rapide sur des sites Web riches en contenus",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Passage facile d'un programme à un autre",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux occasionnels",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Vidéos&nbsp;4K en streaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tâches légères de retouche de photos et d'édition de vidéos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Productivité accrue grâce aux performances intelligentes",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Profitez d'un multitâche fluide, d'une retouche de photos et d'un montage de vidéos simples, de vidéos&nbsp;4K et d'images vives à un prix incroyable.",
                "tr_title": "Des performances impressionnantes pour le travail et les loisirs.",
                "tr_gen_core_title": "Processeur Intel® Pentium® Gold",
                "processorCompare": {
                  "tr_title": "TROUVEZ LE NIVEAU DE PERFORMANCES ADAPTÉ, À UN PRIX ABORDABLE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Processeur Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Processeur Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Navigation rapide sur des sites Web riches en contenus",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Passage facile d'un programme à un autre",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux occasionnels",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Vidéos&nbsp;4K en streaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Tâches légères de retouche de photos et d'édition de vidéos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Productivité accrue grâce aux performances intelligentes",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Jeux AAA et longue autonomie dans un PC ultra-portable. Ne faites pas de compromis sur votre style de jeu.",
                "tr_title": "Ne faites pas de compromis.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 11<sup>e</sup>&nbsp;génération",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "TROUVEZ VOTRE PROCESSEUR INTEL® CORE™ POUR PC DE JEU ULTRAPORTABLES.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Processeur Intel® Core™ i5-11300H de&nbsp;11<sup>e</sup> génération"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Processeur Intel® Core™ i7-11370H de&nbsp;11<sup>e</sup> génération"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Processeur Intel® Core™&nbsp;i7-11375H de&nbsp;11<sup>e</sup> génération Édition spéciale"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Jusqu'à <span class='style_badge'>4,4&nbsp;GHz</span> de fréquence d'horloge maximale",
                      "tr_td2_title": "Jusqu'à <span class='style_badge'>4,8&nbsp;GHz</span> de fréquence d'horloge maximale",
                      "tr_td3_title": "Jusqu'à <span class='style_badge'>5,0&nbsp;GHz</span> de fréquence d'horloge maximale"
                    },
                    {
                      "tr_td1_title": "4 cœurs/ 8&nbsp;threads",
                      "tr_td2_title": "4 cœurs/ 8&nbsp;threads",
                      "tr_td3_title": "4 cœurs/ 8&nbsp;threads"
                    },
                    {
                      "tr_td1_title": "Technologie Intel® Turbo Boost",
                      "tr_td2_title": "Technologie Intel® Turbo Boost",
                      "tr_td3_title": "Technologie Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8 Mo de mémoire Intel® Smart Cache",
                      "tr_td2_title": "12 Mo de mémoire Intel® Smart Cache",
                      "tr_td3_title": "12 Mo de mémoire Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Jeux AAA 1080p avec des fréquences d'images élevées sur des paramètres élevés et excellentes performances sur batterie dans un PC ultraportable.",
                "tr_title": "Tout pour le gaming.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "TROUVEZ VOTRE PROCESSEUR INTEL® CORE™ POUR PC DE JEU ULTRAPORTABLES.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Processeur Intel® Core™ i5-11300H de&nbsp;11<sup>e</sup> génération"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Processeur Intel® Core™ i7-11370H de&nbsp;11<sup>e</sup> génération"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Processeur Intel® Core™&nbsp;i7-11375H de&nbsp;11<sup>e</sup> génération Édition spéciale"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Jusqu'à <span class='style_badge'>4,4&nbsp;GHz</span> de fréquence d'horloge maximale",
                      "tr_td2_title": "Jusqu'à <span class='style_badge'>4,8&nbsp;GHz</span> de fréquence d'horloge maximale",
                      "tr_td3_title": "Jusqu'à <span class='style_badge'>5,0&nbsp;GHz</span> de fréquence d'horloge maximale"
                    },
                    {
                      "tr_td1_title": "4 cœurs/ 8&nbsp;threads",
                      "tr_td2_title": "4 cœurs/ 8&nbsp;threads",
                      "tr_td3_title": "4 cœurs/ 8&nbsp;threads"
                    },
                    {
                      "tr_td1_title": "Technologie Intel® Turbo Boost",
                      "tr_td2_title": "Technologie Intel® Turbo Boost",
                      "tr_td3_title": "Technologie Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8 Mo de mémoire Intel® Smart Cache",
                      "tr_td2_title": "12 Mo de mémoire Intel® Smart Cache",
                      "tr_td3_title": "12 Mo de mémoire Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Une fréquence d'horloge maximale de jusqu'à 5,0&nbsp;GHz pour améliorer vos performances de jeu et un PC ultraportable pour jouer où vous voulez.",
                "tr_title": "Plus de puissance dans un format ultra-compact.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération Édition spéciale",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Processeur Intel® Core™&nbsp;i7 Édition spéciale",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "TROUVEZ VOTRE PROCESSEUR INTEL® CORE™ POUR PC DE JEU ULTRAPORTABLES.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Processeur Intel® Core™ i5-11300H de&nbsp;11<sup>e</sup> génération"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Processeur Intel® Core™ i7-11370H de&nbsp;11<sup>e</sup> génération"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Processeur Intel® Core™&nbsp;i7-11375H de&nbsp;11<sup>e</sup> génération Édition spéciale"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Jusqu'à <span class='style_badge'>4,4&nbsp;GHz</span> de fréquence d'horloge maximale",
                      "tr_td2_title": "Jusqu'à <span class='style_badge'>4,8&nbsp;GHz</span> de fréquence d'horloge maximale",
                      "tr_td3_title": "Jusqu'à <span class='style_badge'>5,0&nbsp;GHz</span> de fréquence d'horloge maximale"
                    },
                    {
                      "tr_td1_title": "4 cœurs/ 8&nbsp;threads",
                      "tr_td2_title": "4 cœurs/ 8&nbsp;threads",
                      "tr_td3_title": "4 cœurs/ 8&nbsp;threads"
                    },
                    {
                      "tr_td1_title": "Technologie Intel® Turbo Boost",
                      "tr_td2_title": "Technologie Intel® Turbo Boost",
                      "tr_td3_title": "Technologie Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "8 Mo de mémoire Intel® Smart Cache",
                      "tr_td2_title": "12 Mo de mémoire Intel® Smart Cache",
                      "tr_td3_title": "12 Mo de mémoire Intel® Smart Cache"
                    },
                    {
                      "tr_td1_title": "Partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Les PC ultra-portables équipés du processeur Intel® Core™&nbsp;i7 Édition spéciale de 11<sup>e</sup>&nbsp;génération offrent des capacités avancées de création de contenus, une expérience de jeu fluide et des divertissements de niveau supérieur.",
                "tr_title": "Un nouveau niveau de performances sur PC portable",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération Édition spéciale",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™ i7 ÉDITION SPÉCIALE DE 11<sup>e</sup>&nbsp;GÉNÉRATION"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Les PC ultra-portables équipés du processeur Intel® Core™&nbsp;i7 Édition spéciale de 11<sup>e</sup>&nbsp;génération offrent des capacités avancées de création de contenus, une expérience de jeu fluide et des divertissements de niveau supérieur.",
                "tr_title": "Un nouveau niveau de performances sur PC portable",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération Édition spéciale",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™ i7 ÉDITION SPÉCIALE DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Processeur Intel® Core™&nbsp;i7 Édition spéciale",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Les fréquences d'horloge rapides et le nombre élevé de cœurs fonctionnent en symbiose pour vous offrir de plus hautes performances lors de vos compétitions de gaming et pour vos projets de création de contenus complexes.",
                "tr_title": "Puissance phénoménale. Équilibre parfait.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 11<sup>e</sup>&nbsp;génération",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "L'équilibre parfait entre la fréquence d'horloge et les performances des cœurs optimise tous vos jeux, que ce soit pour la compétition avec une fréquence d'images élevée ou pour des expériences de gaming immersives avec les jeux AAA.",
                "tr_title": "Puissance pour le gaming.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 11<sup>e</sup>&nbsp;génération",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Jouez, enregistrez et partagez vos parties sur les titres les plus exigeants avec une fréquence d'images élevée et passez sans effort à de lourdes charges de travail en multitâche.",
                "tr_title": "Repoussez toutes les limites.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Les fréquences d'horloge rapides et le nombre élevé de cœurs fonctionnent en symbiose pour vous offrir de plus hautes performances lors de vos compétitions de gaming et pour vos projets de création de contenus complexes.",
                "tr_title": "Puissance phénoménale. Équilibre parfait.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 11<sup>e</sup>&nbsp;génération",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Ce processeur ultra-performant vous permet de passer facilement d'un&nbsp;film&nbsp;en&nbsp;streaming à la création de présentations.",
                "tr_title": "Puissance pour toute la journée",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 avec Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processeur Intel®&nbsp;Core™&nbsp;i3 avec Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Profitez de performances polyvalentes et d'une grande autonomie qui vous suivent partout.",
                "tr_title": "Puissance pour toute la journée",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 avec Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processeur Intel®&nbsp;Core™&nbsp;i3 avec Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Jonglez facilement entre plusieurs programmes pour être toujours prêt, quoi que vous fassiez et où que vous vous trouviez.",
                "tr_title": "Multitâche mobile",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 avec Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processeur Intel®&nbsp;Core™&nbsp;i5 avec Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Des loisirs à la productivité en passant par la création de contenus, toutes vos tâches prennent une nouvelle dimension.",
                "tr_title": "Choisissez un PC plus puissant",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 avec Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processeur Intel®&nbsp;Core™&nbsp;i5 avec Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Les performances de jeu d'un PC de bureau s'allient à la mobilité, que ce soit pour l'eSport avec une fréquence d'images élevée ou des jeux&nbsp;AAA immersifs.",
                "tr_title": "Puissance pour le gaming.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 11<sup>e</sup>&nbsp;génération",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Les performances de jeu d'un PC de bureau s'allient à la mobilité, que ce soit pour l'eSport avec une fréquence d'images élevée ou des jeux&nbsp;AAA immersifs.",
                "tr_title": "Puissance pour le gaming.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Un PC portable qui offre un multitâche intensif fluide pour jouer tout en enregistrant et en partageant avec une fréquence d'images élevée.",
                "tr_title": "Repoussez toutes les limites.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Un PC portable qui offre un multitâche intensif fluide pour jouer tout en enregistrant et en partageant avec une fréquence d'images élevée.",
                "tr_title": "Repoussez toutes les limites.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Les fréquences d'horloge rapides et le nombre élevé de cœurs fonctionnent en symbiose pour vous offrir de plus hautes performances pour l'eSport et pour vos créations de contenus complexes, partout avec votre PC.",
                "tr_title": "Puissance phénoménale. Équilibre parfait.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 11<sup>e</sup>&nbsp;génération",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Les fréquences d'horloge rapides et le nombre élevé de cœurs fonctionnent en symbiose pour vous offrir de plus hautes performances pour l'eSport et pour vos créations de contenus complexes, partout avec votre PC.",
                "tr_title": "Puissance phénoménale. Équilibre parfait.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Les fréquences d'horloge rapides et le nombre élevé de cœurs fonctionnent en symbiose pour vous offrir de plus hautes performances pour l'eSport et pour vos projets de création de contenus complexes, partout où vous emportez votre PC.",
                "tr_title": "Puissance libérée. Équilibre parfait.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 11<sup>e</sup>&nbsp;génération",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Les fréquences d'horloge rapides et le nombre élevé de cœurs fonctionnent en symbiose pour vous offrir de plus hautes performances pour l'eSport et pour vos projets de création de contenus complexes, partout où vous emportez votre PC.",
                "tr_title": "Puissance libérée. Équilibre parfait.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Les fréquences d'horloge rapides et le nombre élevé de cœurs fonctionnent en symbiose pour vous offrir de plus hautes performances lors de vos compétitions de gaming et pour vos projets de création de contenus complexes.",
                "tr_title": "Puissance phénoménale. Équilibre parfait.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Ensemble, les fréquences d'horloge rapides et le nombre élevé de cœurs vous permettent de créer partout des contenus complexes sur votre PC.",
                "tr_title": "Puissance phénoménale. Équilibre parfait.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Ensemble, les fréquences d'horloge rapides et le nombre élevé de cœurs vous permettent de créer des contenus complexes.",
                "tr_title": "Puissance phénoménale. Équilibre parfait.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Repoussez vos limites et profitez d'une expérience de jeu ultime",
                "tr_title": "Rivalisez au plus haut niveau",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 10<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROUVEZ LE PROCESSEUR INTEL® CORE™ DE 10<sup>e</sup>&nbsp;GÉNÉRATION ADAPTÉ À VOTRE FAÇON DE JOUER.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jeux AAA fluides",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux vidéo à couper le souffle",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming et enregistrement",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Création de contenu avancée",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performances de jeu maximales",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Débloqué avec possibilités d'overclocking<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Repoussez vos limites et profitez d'une expérience de jeu ultime",
                "tr_title": "Rivalisez au plus haut niveau",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 10<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROUVEZ LE PROCESSEUR INTEL® CORE™ DE 10<sup>e</sup>&nbsp;GÉNÉRATION ADAPTÉ À VOTRE FAÇON DE JOUER.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jeux AAA fluides",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux vidéo à couper le souffle",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming et enregistrement",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Création de contenu avancée",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Performances de jeu maximales",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Débloqué avec possibilités d'overclocking<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 10<br/>Threads&nbsp;: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Nous avons créé des ordinateurs portables conçus pour ceux qui sont constamment en déplacement.",
                "tr_title": "Découvrez des PC portables nouvelle génération",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 10<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Les fréquences d'horloge rapides et le nombre élevé de cœurs fonctionnent en symbiose pour vous offrir de plus hautes performances lors de vos compétitions de gaming et pour vos projets de création de contenus complexes.",
                "tr_title": "Puissance libérée. Équilibre parfait.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Ensemble, les fréquences d'horloge rapides, le nombre élevé de cœurs et les performances débloquées vous permettent de créer partout des contenus complexes sur votre PC.",
                "tr_title": "Puissance libérée. Équilibre parfait.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Ensemble, les fréquences d'horloge rapides et le nombre élevé de cœurs vous permettent de créer des contenus complexes.",
                "tr_title": "Puissance libérée. Équilibre parfait.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 11<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 11<sup>e</sup>&nbsp;GÉNÉRATION",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de mémoire Intel® Smart Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Jeu et RV fluides sur les titres les plus exigeants",
                "tr_title": "Les jeux vidéo sérieux commencent ici",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 9<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROUVEZ LE PROCESSEUR INTEL® CORE™ ADAPTÉ À VOTRE FAÇON DE JOUER.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jeux AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux vidéo à couper le souffle",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jouez, partagez et enregistrez sans limites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Meilleur processeur Intel® Core™ de 9<sup>e</sup>&nbsp;génération pour les jeux vidéo",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 4<br/>Threads&nbsp;: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FRÉQUENCE D'IMAGES JUSQU'À 32&nbsp;% PLUS ÉLEVÉE <br/><span class='tr_subtitle_font'>lorsque vous jouez tout en partageant et en enregistrant par rapport à un PC de 3&nbsp;ans<sup>3</sup></span>",
                "tr_title": "La puissance nécessaire pour partager vos plus grands moments de jeu",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 9<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROUVEZ LE PROCESSEUR INTEL® CORE™ ADAPTÉ À VOTRE FAÇON DE JOUER.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jeux AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux vidéo à couper le souffle",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jouez, partagez et enregistrez sans limites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Meilleur processeur Intel® Core™ de 9<sup>e</sup>&nbsp;génération pour les jeux vidéo",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 4<br/>Threads&nbsp;: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "FRÉQUENCE D'IMAGES JUSQU'À 41&nbsp;% PLUS ÉLEVÉE <br/><span class='tr_subtitle_font'>lorsque vous jouez tout en partageant et en enregistrant par rapport à un PC de 3&nbsp;ans<sup>4</sup></span>",
                "tr_title": "Le processeur Intel® Core™&nbsp;i9 offre ce que les gamers exigent.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 9<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>cœurs</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Jusqu'à <br/></span><span class='badgetitle_font'>[XX]&nbsp;<br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX]&nbsp;Mo <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Débloqués",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "TROUVEZ LE PROCESSEUR INTEL® CORE™ ADAPTÉ À VOTRE FAÇON DE JOUER.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i7"
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Processeur Intel®&nbsp;Core™&nbsp;i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jeux AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jeux vidéo à couper le souffle",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jouez, partagez et enregistrez sans limites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Meilleur processeur Intel® Core™ de 9<sup>e</sup>&nbsp;génération pour les jeux vidéo",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 4<br/>Threads&nbsp;: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 6<br/>Threads&nbsp;: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Fréquence d'horloge<sup>2</sup>&nbsp;: jusqu'à&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cœurs&nbsp;: 8<br/>Threads&nbsp;: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Conçu pour la nouvelle génération de jeux vidéo",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12<sup>e</sup>&nbsp;génération",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 12<sup>e</sup>&nbsp;GÉNÉRATION"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>e</sup>&nbsp;génération",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 12<sup>e</sup>&nbsp;GÉNÉRATION"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de&nbsp;12<sup>e</sup>&nbsp;génération",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 12<sup>e</sup>&nbsp;GÉNÉRATION"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>cœurs</span></span>",
                    "tr_badgePreTitle": "[p_core]&nbsp;Performance-cores",
                    "tr_badgeSubTitle": "[e_core]&nbsp;Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>threads</span></span>",
                    "tr_badgePreTitle": "[p_thread]&nbsp;sur les Performance-cores",
                    "tr_badgeSubTitle": "[e_thread]&nbsp;sur les Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Un PC pour jouer et créer",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>e</sup>&nbsp;génération",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 12<sup>e</sup>&nbsp;GÉNÉRATION"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de&nbsp;12<sup>e</sup>&nbsp;génération",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 12<sup>e</sup>&nbsp;GÉNÉRATION"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>cœurs</span></span>",
                    "tr_badgePreTitle": "[p_core]&nbsp;Performance-cores",
                    "tr_badgeSubTitle": "[e_core]&nbsp;Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>threads</span></span>",
                    "tr_badgePreTitle": "[p_thread]&nbsp;sur les Performance-cores",
                    "tr_badgeSubTitle": "[e_thread]&nbsp;sur les Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Des performances fiables dans les moments importants",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 de 12<sup>e</sup>&nbsp;génération ",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i3 DE 12<sup>e</sup>&nbsp;GÉNÉRATION",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Booste les performances là où vous en avez le plus besoin",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 12<sup>e</sup>&nbsp;GÉNÉRATION",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Booste les performances là où vous en avez le plus besoin",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 12<sup>e</sup>&nbsp;GÉNÉRATION",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Des performances supérieures, là où vous en avez le plus besoin",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 12<sup>e</sup>&nbsp;GÉNÉRATION",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Des performances supérieures, là où vous en avez le plus besoin",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 12<sup>e</sup>&nbsp;GÉNÉRATION",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Les PC portables Intel® Evo™ vous offrent les performances dont vous avez besoin, une intelligence intégrée pour des chats vidéo en toute confiance, une navigation Internet plus rapide, une longue autonomie et plus encore...",
                "tr_title": "Booste les performances là où vous en avez le plus besoin.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 12<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Les PC portables Intel® Evo™ vous offrent les performances dont vous avez besoin, une intelligence intégrée pour des chats vidéo en toute confiance, une navigation Internet plus rapide, une longue autonomie et plus encore...",
                "tr_title": "Des performances supérieures, là où vous en avez le plus besoin",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 12<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Les PC portables Intel® Evo™ vous offrent les performances dont vous avez besoin, une intelligence intégrée pour des chats vidéo en toute confiance, une navigation Internet plus rapide, une longue autonomie et plus encore...",
                "tr_title": "Des performances extrêmes dans un PC portable haut de gamme",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de&nbsp;12<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 12<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Avec la technologie Intel® Turbo Boost&nbsp;2.0 jusqu'à [max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Conçu pour votre entreprise",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 12<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suppression du bruit et floutage de l'arrière-plan",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Des performances qui s'adaptent automatiquement à&nbsp;vos&nbsp;activités les plus courantes",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Appels Web fluides et de haute qualité",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Sécurité matérielle prête à l'emploi",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Capacités de gestion à distance des appareils",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimisation automatique des performances Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Puissance pour vos applications axées sur les données",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "La productivité la plus élevée avec des performances améliorées",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Avec la technologie Intel® Turbo Boost&nbsp;2.0 jusqu'à [max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Il travaille dur, pour que vous puissiez vous concentrer sur votre activité",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 12<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suppression du bruit et floutage de l'arrière-plan",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Des performances qui s'adaptent automatiquement à&nbsp;vos&nbsp;activités les plus courantes",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Appels Web fluides et de haute qualité",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Sécurité matérielle prête à l'emploi",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Capacités de gestion à distance des appareils",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimisation automatique des performances Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Puissance pour vos applications axées sur les données",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "La productivité la plus élevée avec des performances améliorées",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Avec la technologie Intel® Turbo Boost&nbsp;2.0 jusqu'à [max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Accélérez votre activité",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de&nbsp;12<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 12<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suppression du bruit et floutage de l'arrière-plan",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Des performances qui s'adaptent automatiquement à&nbsp;vos&nbsp;activités les plus courantes",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Appels Web fluides et de haute qualité",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Sécurité matérielle prête à l'emploi",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Capacités de gestion à distance des appareils",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Optimisation automatique des performances Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Puissance pour vos applications axées sur les données",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "La productivité la plus élevée avec des performances améliorées",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Des performances fiables pour en faire plus",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 de 13<sup>e</sup>&nbsp;génération ",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Des performances concrètes pour en faire plus",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 13<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 13<sup>e</sup>&nbsp;GÉNÉRATION",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Des performances concrètes pour en faire plus",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 13<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 13<sup>e</sup>&nbsp;GÉNÉRATION",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Performances et puissance pour en faire plus",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 13<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 13<sup>e</sup>&nbsp;GÉNÉRATION",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Performances et puissance pour en faire plus",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 13<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 13<sup>e</sup>&nbsp;GÉNÉRATION",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Au-delà de la performance",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 13<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 13<sup>e</sup>&nbsp;GÉNÉRATION",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>cœurs</span></span>",
                    "tr_badgePreTitle": "[p_core]&nbsp;Performance-cores",
                    "tr_badgeSubTitle": "[e_core]&nbsp;Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>threads</span></span>",
                    "tr_badgePreTitle": "[p_thread]&nbsp;sur les Performance-cores",
                    "tr_badgeSubTitle": "[e_thread]&nbsp;sur les Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Connectez-vous facilement à votre téléphone<sup>24</sup>, sélectionnez intelligemment le meilleur Wi-Fi et profitez d'une batterie longue durée à charge rapide.",
                "tr_title": "Au-delà de la performance pour en faire plus partout",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 13<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 13<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Connectez-vous facilement à votre téléphone<sup>24</sup>, sélectionnez intelligemment le meilleur Wi-Fi et profitez d'une batterie longue durée à charge rapide.",
                "tr_title": "Au-delà de la performance pour encore plus de multitâche",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 13<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 13<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Connectez-vous facilement à votre téléphone<sup>24</sup>, sélectionnez intelligemment le meilleur Wi-Fi et profitez d'une batterie longue durée à charge rapide.",
                "tr_title": "Au-delà de la performance pour accélérer les tâches les plus exigeantes",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 13<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 13<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Au-delà de la performance",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 13<sup>e</sup>&nbsp;génération",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 13<sup>e</sup>&nbsp;GÉNÉRATION",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 13<sup>e</sup>&nbsp;génération",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 13<sup>e</sup>&nbsp;GÉNÉRATION",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 13<sup>e</sup>&nbsp;génération",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 13<sup>e</sup>&nbsp;GÉNÉRATION",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>cœurs</span></span>",
                    "tr_badgePreTitle": "[p_core]&nbsp;Performance-cores",
                    "tr_badgeSubTitle": "[e_core]&nbsp;Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>threads</span></span>",
                    "tr_badgePreTitle": "[p_thread]&nbsp;sur les Performance-cores",
                    "tr_badgeSubTitle": "[e_thread]&nbsp;sur les Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Au-delà de la performance",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>cœurs</span></span>",
                    "tr_badgePreTitle": "[p_core]&nbsp;Performance-cores",
                    "tr_badgeSubTitle": "[e_core]&nbsp;Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>threads</span></span>",
                    "tr_badgePreTitle": "[p_thread]&nbsp;sur les Performance-cores",
                    "tr_badgeSubTitle": "[e_thread]&nbsp;sur les Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Des performances fiables pour en faire plus",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Des performances concrètes pour en faire plus",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Performances et puissance pour en faire plus",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Au-delà de la performance",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>cœurs</span></span>",
                    "tr_badgePreTitle": "[p_core]&nbsp;Performance-cores",
                    "tr_badgeSubTitle": "[e_core]&nbsp;Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>threads</span></span>",
                    "tr_badgePreTitle": "[p_thread]&nbsp;sur les Performance-cores",
                    "tr_badgeSubTitle": "[e_thread]&nbsp;sur les Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Des performances fiables pour en faire plus",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Des performances concrètes pour en faire plus",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;5",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL®&nbsp;CORE™&nbsp;5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Performances et puissance pour en faire plus",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;7",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL®&nbsp;CORE™&nbsp;7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Processeur Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processeur",
                "tr_title": "Jouez sans limites",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Processeur Intel® Core™ Ultra 5",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™ ULTRA 5",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Processeur Intel® Core™ Ultra 7",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™ ULTRA 7",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Processeur Intel® Core™ Ultra 9",
                    "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™ ULTRA 9",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>cœurs</span></span>",
                    "tr_badgePreTitle": "[p_core]&nbsp;Performance-cores",
                    "tr_badgeSubTitle": "[e_core]&nbsp;Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core] LPE-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>threads</span></span>",
                    "tr_badgePreTitle": "[p_thread]&nbsp;sur les Performance-cores",
                    "tr_badgeSubTitle": "[e_thread]&nbsp;sur les Efficient-cores",
                    "tr_badgeSubTitle1": "[l_thread] sur les LPE-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Processeur Intel® Core™ Ultra 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Processeur Intel® Core™ Ultra 7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Processeur Intel® Core™ Ultra 9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Jouez sans limites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Jouez sans limites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Jouez sans limites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Jouez sans limites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Jouez sans limites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Jouez sans limites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Les PME méritent les mêmes solutions que les grandes entreprises.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 13<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i5 DE 13<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suppression du bruit et floutage de l'arrière-plan",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Des performances qui s'adaptent automatiquement à&nbsp;vos&nbsp;activités les plus courantes",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Appels Web fluides et de haute qualité",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Protections de sécurité matérielle prêtes à l'emploi",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Protéger les appareils et les données contre les attaques de sabotage physique",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Les PME méritent les mêmes solutions que les grandes entreprises.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 13<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i7 DE 13<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suppression du bruit et floutage de l'arrière-plan",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Des performances qui s'adaptent automatiquement à&nbsp;vos&nbsp;activités les plus courantes",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Appels Web fluides et de haute qualité",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Protections de sécurité matérielle prêtes à l'emploi",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Protéger les appareils et les données contre les attaques de sabotage physique",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Les PME méritent les mêmes solutions que les grandes entreprises.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9 de 13<sup>e</sup>&nbsp;génération",
                "tr_gen_core_title_uppercase": "PROCESSEUR INTEL® CORE™&nbsp;i9 DE 13<sup>e</sup>&nbsp;GÉNÉRATION",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "COMMENT CE PROCESSEUR SE COMPARE-T-IL AUX AUTRES&nbsp;?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Appareil actuel",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suppression du bruit et floutage de l'arrière-plan",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Des performances qui s'adaptent automatiquement à&nbsp;vos&nbsp;activités les plus courantes",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Appels Web fluides et de haute qualité",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Protections de sécurité matérielle prêtes à l'emploi",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Protéger les appareils et les données contre les attaques de sabotage physique",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Explorez de nouvelles expériences d'IA en entreprise",
                "tr_title": "Prenez un temps d'avance avec les processeurs Intel®&nbsp;Core™&nbsp;Ultra&nbsp;[XX] et Intel&nbsp;vPro®",
                "tr_gen_core_title": "Processeur Intel® Core™ Ultra [XX]",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "BOOSTEZ VOTRE ACTIVITÉ GRÂCE AUX PC AVEC L'IA D'INTEL",
                    "tr_badgeSubTitle": "Les PC puissants qui créent une expérience exceptionnelle sont au cœur de la productivité des entreprises. Intel vPro® améliore l'efficacité et la performance des collaborateurs sur toutes les charges de travail et des applications grâce à une nouvelle architecture qui offre des performances à faible consommation d'énergie pour diverses charges de travail variées et complexes, en envoyant la bonne tâche, au bon moteur, au bon moment.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "FONCTIONNALITÉS DE SÉCURITÉ MULTICOUCHE PRÊTES À L'EMPLOI",
                    "tr_badgeSubTitle": "Les nouvelles menaces nécessitent une approche globale de la sécurité. Intel vPro® fournit des protections au niveau du matériel et dans toute la pile, afin de mieux protéger votre entreprise. Ces&nbsp;mesures de sécurité, uniques et prêtes à l'emploi, incluent des capacités de détection des menaces renforcées par l'IA, qui protègent vos utilisateurs, vos données et votre entreprise.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "DES OUTILS INTÉGRÉS POUR MAINTENIR LA PRODUCTIVITÉ DES UTILISATEURS, OÙ QU'ILS SE TROUVENT",
                    "tr_badgeSubTitle": "Tout comme les bureaux, les PC sont partout. Intel vPro® offre aux entreprises des capacités de gestion modernes basées sur le matériel pour simplifier la gestion de leur parc de PC, simplifier leur assistance et améliorer leur expérience utilisateur, tout en favorisant des pratiques informatiques durables.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Bien plus qu'un PC performant, un PC avec IA",
                "tr_subtitle1": "PROPULSÉ PAR UN PROCESSEUR INTEL®&nbsp;CORE™ ULTRA (SÉRIE 2)",
                "tr_subtitle2": "Tout devient possible sur un PC Intel avec IA",
                "tr_subtitle": "Le processeur pour PC portables le plus efficace, le plus sûr et le plus performant d'Intel<sup>39</sup>",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™ Ultra&nbsp;5 (Série&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Plus créatifs</span>, avec des outils d'IA pour créer plus rapidement"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Plus productifs</span> grâce à l'assistance de l'IA qui permet de gagner du temps"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Plus sûrs</span> grâce à des outils intégrés de protection des données"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Bien plus qu'un PC performant, un PC avec IA",
                "tr_subtitle1": "PROPULSÉ PAR UN PROCESSEUR INTEL®&nbsp;CORE™ ULTRA (SÉRIE 2)",
                "tr_subtitle2": "Tout devient possible sur un PC Intel avec IA",
                "tr_subtitle": "Le processeur pour PC portables le plus efficace, le plus sûr et le plus performant d'Intel<sup>39</sup>",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™ Ultra&nbsp;7 (Série&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Plus créatifs</span>, avec des outils d'IA pour créer plus rapidement"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Plus productifs</span> grâce à l'assistance de l'IA qui permet de gagner du temps"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Plus sûrs</span> grâce à des outils intégrés de protection des données"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Bien plus qu'un PC performant, un PC avec IA",
                "tr_subtitle1": "PROPULSÉ PAR UN PROCESSEUR INTEL®&nbsp;CORE™ ULTRA (SÉRIE 2)",
                "tr_subtitle2": "Tout devient possible sur un PC Intel avec IA",
                "tr_subtitle": "Le processeur pour PC portables le plus efficace, le plus sûr et le plus performant d'Intel<sup>39</sup>",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™ Ultra&nbsp;5 (Série&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Bien plus qu'un PC performant, un PC avec IA",
                "tr_subtitle1": "PROPULSÉ PAR UN PROCESSEUR INTEL®&nbsp;CORE™ ULTRA (SÉRIE 2)",
                "tr_subtitle2": "Tout devient possible sur un PC Intel avec IA",
                "tr_subtitle": "Le processeur pour PC portables le plus efficace, le plus sûr et le plus performant d'Intel<sup>39</sup>",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™ Ultra&nbsp;7 (Série&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_tileTitle1": "Carte graphique dédiée",
                "tr_title": "QU'EST-CE QU'UN PROCESSEUR GRAPHIQUE&nbsp;?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "L'unité de traitement graphique (ou GPU) crée les images que vous voyez à l'écran.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU intégré",
                    "tr_badgeSubTitle": "Un processeur graphique (GPU) intégré utilise la même RAM que le reste de l'ordinateur, ce qui le rend sa consommation d'énergie plus efficace.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Un processeur graphique dédié",
                    "tr_badgeSubTitle": "Il s'agit du type de GPU le plus puissant, car il possède sa propre RAM et d'autres ressources conçues pour créer des graphismes exceptionnels.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "GPU hybride",
                    "tr_badgeSubTitle": "Il s'agit du type de GPU le plus puissant, car il possède sa propre RAM et d'autres ressources conçues pour créer des graphismes exceptionnels.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Retouche de photos et édition de vidéos simplifiées",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Plongez dans du contenu vidéo&nbsp;4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Jouez à des jeux populaires avec des détails étonnants",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Double GPU",
                    "tr_badgeSubTitle": "Certains systèmes, comme ceux qui possèdent une partie graphique Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>, peuvent tirer parti à la fois du GPU intégré au processeur et du GPU dédié pour distribuer la charge de travail graphique.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU intégré",
                    "tr_badgeSubTitle": "Une unité de traitement graphique (ou GPU) intégrée se trouve sur la même puce que le processeur principal. Comme ce type de GPU utilise la même RAM et les mêmes ressources que le reste de l'ordinateur, celui-ci consomme moins d'énergie et peut bénéficier d'un design plus fin et plus léger.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Unité de traitement graphique (GPU) dédiée",
                    "tr_badgeSubTitle": "Il s'agit du type de GPU le plus puissant, car il possède sa propre RAM et d'autres ressources conçues pour créer des graphismes exceptionnels.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Standard",
                    "tr_badgeSubTitle": "Jusqu'à 4&nbsp;Go de VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Compétition",
                    "tr_badgeSubTitle": "Jusqu'à 8&nbsp;Go de VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "Plus de 8&nbsp;Go de VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_title": "Puissance graphique intense",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Une puissance de traitement graphique optimale exige une carte graphique capable de se consacrer exclusivement à la création d'images nettes et d'animations fluides.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_title": "Des graphiques intégrés intenses et éblouissants",
                "tr_pretitle": "Partie graphique Intel® Iris® Plus",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "La partie graphique Intel® Iris® Plus vous permet de vous immerger dans des vidéos&nbsp;4K&nbsp;HDR et des jeux Full&nbsp;HD sans qu'aucune carte graphique supplémentaire soit nécessaire.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_title": "Une expérience visuelle bluffante, des performances sans effort",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "La technologie graphique Intel® offre des performances graphiques intégrées révolutionnaires pour soutenir la conception fluide des créateurs et les expériences de jeu riches.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_title": "Partie graphique Intel® Iris® Pro",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_title": "Partie graphique UHD Intel® avec architecture X<sup class='small_text_sup_xe'>e</sup>",
                "tr_pretitle": "Expériences visuelles immersives",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Bénéficiez d'une retouche de photos et d'une édition vidéo simplifiées, plongez dans un contenu vidéo haut de gamme en 4K ou jouez à des jeux populaires avec des détails étonnants.",
                "tr_photo_text": "Retouche de photos et édition de vidéos simplifiées",
                "tr_video_text": "Plongez dans du contenu vidéo&nbsp;4K",
                "tr_game_text": "Jouez à des jeux populaires avec des détails étonnants",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "BEAUCOUP PLUS QUE DE BELLES IMAGES",
                "tr_subtitle": "En travaillant en étroite collaboration avec le processeur, la carte graphique (ou GPU) détermine le comportement et l'aspect de vos jeux. La VRAM (ou RAM vidéo) dédiée au rendu des graphismes est un moyen rapide de savoir où se situe votre GPU.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;Go<br/></span><span class='subtitle-value'>de VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "POUR DES JEUX AUX GRAPHISMES INTENSES",
                "tr_subtitle": "Une puissance de traitement graphique optimale exige une carte graphique dédiée capable de se consacrer exclusivement à la création d'images nettes et d'animations fluides",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;Go<br/></span><span class='subtitle-value'>de VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "POUR DES JEUX AUX GRAPHISMES INTENSES",
                "tr_subtitle": "Une puissance de traitement graphique optimale exige une carte graphique dédiée capable de se consacrer exclusivement à la création d'images nettes et d'animations fluides",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;Go<br/></span><span class='subtitle-value'>de VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "BEAUCOUP PLUS QUE DE BELLES IMAGES",
                "tr_subtitle": "En travaillant en étroite collaboration avec le processeur, la carte graphique (ou GPU) détermine le comportement et l'aspect de vos jeux. La VRAM (ou RAM vidéo) dédiée au rendu des graphismes est un moyen rapide de savoir où se situe votre GPU.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;Go<br/></span><span class='subtitle-value'>de VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_title": "Puissance graphique intense",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Une puissance de traitement graphique optimale exige une carte graphique capable de se consacrer exclusivement à la création d'images nettes et d'animations fluides.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "POUR DES JEUX AUX GRAPHISMES INTENSES",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "Une puissance de traitement graphique optimale exige une carte graphique capable de se consacrer exclusivement à la création d'images nettes et d'animations fluides.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;Go<br/></span><span class='subtitle-value'>de VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Une puissance de traitement graphique optimale exige une carte graphique dédiée capable de se consacrer exclusivement à la création d'images nettes et d'animations fluides",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Fournit une importante puissance graphique pour répondre à la configuration minimale requise pour vos jeux.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Pour les joueurs de catégorie supérieure qui exigent des performances de niveau compétition.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Pour les gamers avancés qui utilisent aussi leur PC pour des tâches lourdes de création de contenu.",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Vidéos éclatantes et jeux immersifs",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Obtenez le meilleur de votre écran haute résolution et profitez d'une riche expérience multimédia grâce à la partie graphique HD Intel® intégrée.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Pour des vidéos et des images éclatantes",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Obtenez plus de détails et des couleurs plus vives de votre vidéo et de vos photos.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_title": "Création et jeux vidéo incroyables",
                "tr_pretitle": "Partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Que ce soit pour le divertissement immersif, la création de contenus avancée ou les jeux vidéo grand public, la partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup> offre aux PC fins et légers des performances qui changent la donne.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_pretitle": "POUR DES JEUX AUX GRAPHISMES INTENSES",
                "tr_title": "Partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Une puissance de traitement graphique optimale exige une carte graphique dédiée capable de se consacrer exclusivement à la création d'images nettes et d'animations fluides",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x]&nbsp;Go<br/></span><span class='subtitle-value'>de VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_title": "DONNEZ LIBRE COURS À VOTRE CRÉATIVITÉ",
                "tr_pretitle": "Partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;MAX",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Jonglez entre vos différents projets créatifs et plongez au cœur d'une grande variété de jeux grâce à la puissance combinée des graphiques intégrés au processeur et du premier moteur graphique dédié Intel®.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "tr_title": "Partie graphique Intel® Iris®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;MAX",
                "tr_pretitle": "POUR DES JEUX AUX GRAPHISMES INTENSES",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Une puissance de traitement graphique optimale exige une carte graphique dédiée capable de se consacrer exclusivement à la création d'images nettes et d'animations fluides",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x]&nbsp;Go<br/></span><span class='subtitle-value'>de VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Graphiques",
                "tr_title": "Superbes graphiques intégrés",
                "tr_subtitle": "Profitez de jeux, films et vidéos Web avec un niveau de détail et une précision époustouflants.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphiques",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Graphiques",
                    "tr_specText": "Dynamisez vos workflows"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Graphiques",
                    "tr_specText": "Boostez vos jeux vidéo"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Graphiques",
                    "tr_specText": "Boostez vos jeux vidéo"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Graphiques",
                    "tr_specText": "Faites passer vos jeux au niveau supérieur"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "DONNEZ LIBRE COURS À VOTRE CRÉATIVITÉ",
                    "tr_specText": "Accélérez votre productivité où que vous alliez grâce aux capacités multimédias les plus complètes, comme le <strong>premier encodage matériel AV1 de l'industrie</strong> et la création de contenu accélérée par l'IA<sup>22</sup>."
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "DONNEZ LIBRE COURS À VOTRE CRÉATIVITÉ",
                    "tr_specText": "Accélérez votre productivité grâce aux capacités multimédias les plus complètes, comme le <strong>premier encodage matériel AV1 de l'industrie</strong> et la création de contenu accélérée par l'IA<sup>22</sup>."
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "DONNEZ LIBRE COURS À VOTRE CRÉATIVITÉ",
                    "tr_specText": "Bénéficiez d'une création de contenu de qualité accélérée par l'IA grâce au <strong>premier encodage matériel AV1 de l'industrie</strong> et au <strong>moteur multimédia X<sup>e</sup> avancé</strong><sup>22</sup>."
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "DONNEZ LIBRE COURS À VOTRE CRÉATIVITÉ",
                    "tr_specText": "Bénéficiez d'une création de contenu de qualité accélérée par l'IA grâce au <strong>premier encodage matériel AV1 de l'industrie</strong> et au <strong>moteur multimédia X<sup>e</sup> avancé</strong><sup>22</sup>."
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "DONNEZ LIBRE COURS À VOTRE CRÉATIVITÉ",
                    "tr_specText": "Bénéficiez d'une création de contenu de qualité accélérée par l'IA grâce au <strong>premier encodage matériel AV1 de l'industrie</strong> et au <strong>moteur multimédia X<sup>e</sup> avancé</strong><sup>22</sup>."
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "DES PERFORMANCES GRAPHIQUES OÙ QUE VOUS SOYEZ",
                    "tr_specText": "Jouez aux jeux les plus récents en déplacement et bénéficiez de performances et d'une fidélité surpuissantes grâce aux capacités de jeux vidéo modernes. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling) : </strong>technologie d'upscaling Intel® accélérée par l'IA offrant des performances supérieures et un jeu plus fluide. <br/><br/>Prise en charge de <strong>DirectX* 12 Ultimate : </strong>profitez de jeux plus réalistes grâce à des technologies graphiques comme le ray tracing et le Variable Rate Shading."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "PRENEZ LE CONTRÔLE DE VOS JEUX",
                    "tr_specText": "<strong>Intel® Arc™ Control</strong> vous permet d'ajuster votre expérience grâce à une interface moderne et intuitive. Profitez de mises à jour rapides des pilotes, optimisez vos jeux et bien plus encore."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "PRENEZ LE CONTRÔLE DE VOS JEUX",
                    "tr_specText": "<strong>Intel® Arc™ Control</strong> vous permet d'ajuster votre expérience grâce à une interface moderne et intuitive. Profitez de mises à jour rapides des pilotes, optimisez vos jeux et bien plus encore."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "PRENEZ LE CONTRÔLE DE VOS JEUX",
                    "tr_specText": "Profitez de mises à jour rapides des pilotes et optimisez vos jeux préférés grâce au logiciel <strong>Intel® Arc™ Control</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "AUGMENTEZ LE POTENTIEL DE VOTRE PLATEFORME INTEL®",
                    "tr_specText": "Associez-la aux processeurs Intel® Core™ compatibles pour profiter du meilleur de la <strong>technologie Intel®&nbsp;Deep&nbsp;Link</strong> et améliorer votre expérience de création, de streaming et de jeu."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "MAXIMISEZ VOTRE SYSTÈME INTEL®",
                    "tr_specText": "Associez-la aux processeurs Intel® Core™ compatibles pour profiter du meilleur de la <strong>technologie Intel®&nbsp;Deep&nbsp;Link</strong> et améliorer votre expérience de création, de streaming et de jeu."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "AUGMENTEZ LE POTENTIEL DE VOTRE PLATEFORME INTEL®",
                    "tr_specText": "Associez-la aux processeurs Intel® Core™ compatibles pour profiter du meilleur de la <strong>technologie Intel®&nbsp;Deep&nbsp;Link</strong> et améliorer votre expérience de création, de streaming et de jeu."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "AUGMENTEZ LE POTENTIEL DE VOTRE PLATEFORME INTEL®",
                    "tr_specText": "Associez-la aux processeurs Intel® Core™ compatibles pour profiter du meilleur de la <strong>technologie Intel®&nbsp;Deep&nbsp;Link</strong> et améliorer votre expérience de création, de streaming et de jeu."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAXIMISEZ VOTRE SYSTÈME INTEL®",
                    "tr_specText": "Associez-la aux processeurs Intel® Core™ compatibles pour profiter du meilleur de la <strong>technologie Intel®&nbsp;Deep&nbsp;Link</strong> et améliorer votre expérience de création, de streaming et de jeu."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "OCCUPEZ LE DEVANT DE LA SCÈNE",
                    "tr_specText": "Streaming fluide grâce aux technologies avancées du logiciel <strong>Intel® Arc™ Control</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "OCCUPEZ LE DEVANT DE LA SCÈNE",
                    "tr_specText": "Streaming fluide grâce aux technologies avancées du logiciel <strong>Intel® Arc™ Control</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "VIVEZ UNE EXPÉRIENCE DE JEU HAUTE PERFORMANCE IMMERSIVE",
                    "tr_specText": "Partez à la conquête des derniers jeux AAA à des résolutions et à des taux de rafraîchissement élevés. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling) : </strong>technologie d'upscaling Intel® accélérée par l'IA offrant des performances supérieures et un jeu plus fluide. <br/><br/>Prise en charge de <strong>DirectX* 12 Ultimate : </strong>profitez de jeux plus réalistes grâce à des technologies graphiques comme le ray tracing et le Variable Rate Shading."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "VIVEZ UNE EXPÉRIENCE DE JEU HAUTE PERFORMANCE IMMERSIVE",
                    "tr_specText": "Partez à la conquête des derniers jeux AAA à des résolutions et à des taux de rafraîchissement élevés. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling) : </strong>technologie d'upscaling Intel® accélérée par l'IA offrant des performances supérieures et un jeu plus fluide. <br/><br/>Prise en charge de <strong>DirectX* 12 Ultimate : </strong>profitez de jeux plus réalistes grâce à des technologies graphiques comme le ray tracing et le Variable Rate Shading."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "BOOSTEZ VOS JEUX",
                    "tr_specText": "Faites passer votre expérience de jeu au niveau supérieur grâce à : <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling) : </strong>technologie d'upscaling Intel® accélérée par l'IA offrant des performances supérieures et un jeu plus fluide. <br/><br/>Prise en charge de <strong>DirectX* 12 Ultimate : </strong>profitez de jeux plus réalistes grâce à des technologies graphiques comme le ray tracing et le Variable Rate Shading."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "ACCÉLÉREZ VOTRE EXPÉRIENCE DE GAMING",
                    "tr_specText": "Profitez d'une expérience de jeu fluide sur les titres AAA les plus connus, ainsi que d'une réactivité époustouflante dans les jeux de tir comme dans les jeux de stratégie compétitifs. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling) : </strong>technologie d'upscaling Intel® accélérée par l'IA offrant des performances supérieures et un jeu plus fluide. <br/><br/>Prise en charge de <strong>DirectX* 12 Ultimate : </strong>profitez de jeux plus réalistes grâce à des technologies graphiques comme le ray tracing et le Variable Rate Shading."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "VIVEZ UNE EXPÉRIENCE DE JEU HAUTE PERFORMANCE IMMERSIVE",
                    "tr_specText": "Partez à la conquête des derniers jeux AAA à des résolutions et à des taux de rafraîchissement élevés. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling) : </strong>technologie d'upscaling Intel® accélérée par l'IA offrant des performances supérieures et un jeu plus fluide. <br/><br/>Prise en charge de <strong>DirectX* 12 Ultimate : </strong>profitez de jeux plus réalistes grâce à des technologies graphiques comme le ray tracing et le Variable Rate Shading."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Graphiques",
                    "tr_specText": "Un univers graphique pour tous"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "ACCÉLÉREZ VOS IMAGES, ACCÉLÉREZ VOS JEUX",
                    "tr_specText": "La technologie Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling (X<sup class='small_text_sup_xe'>e</sup>SS) fait passer votre expérience de jeu au niveau supérieur grâce à l'upscaling accéléré par l'IA, afin d'obtenir plus de performances avec une grande fidélité d'image. La technologie X<sup class='small_text_sup_xe'>e</sup>SS est optimisée pour les cartes graphiques Intel® Arc™, ce qui vous permet de profiter pleinement de l'accélération matérielle Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX)."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "DES IMAGES AU SOMMET",
                    "tr_specText": "Avec la prise en charge complète de DirectX* 12 Ultimate sur toutes les cartes graphiques Intel® Arc™, vous allez vivre une expérience de jeu époustouflante et immersive grâce aux dernières avancées dans le domaine des technologies graphiques, comme le ray tracing, le shading à taux variable, le mesh shading et le sampler feedback : les bases pour les jeux vidéo next-gen."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "CRÉATION FACILE DE PHOTOS, VIDÉOS ET CONTENUS MULTIMÉDIAS",
                    "tr_specText": "Donnez libre cours à votre imagination et captivez votre public en créant de riches contenus numériques à l'aide d'un moteur multimédia hyper-avancé, augmenté par l'IA et accéléré par la technologie Intel®. Vos créations deviennent spectaculaires avec ces cartes graphiques qui prennent en charge les principaux formats multimédias actuels, tout en offrant les capacités d'encodage vidéo AV1 les plus avancées."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "VIVEZ DES EXPÉRIENCES DE POINTE POUR LA CRÉATION ET LE GAMING GRÂCE À L'IA",
                    "tr_specText": "Les cartes graphiques Intel® Arc™ libèrent de puissantes capacités d'IA, de la création avancée aux jeux immersifs. Tirez le meilleur parti des logiciels de création avancés d'aujourd'hui, notamment dans le domaine de la génération d'images à partir de texte et du montage vidéo accéléré par l'IA. En outre, profitez de jeux en haute résolution grâce à l'upscaling X<sup class='small_text_sup_xe'>e</sup>SS accéléré par l'IA."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Graphiques",
                    "tr_specText": "Les graphiques professionnels commencent ici"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "PARFAIT POUR LES CRÉATIFS, LES DESIGNERS ET LES INGÉNIEURS EN DÉPLACEMENT",
                    "tr_specText": "Les cartes graphiques Intel® Arc™ Pro intégrées à certains processeurs Intel® Core™ Ultra série H permettent de multiplier par deux les performances graphiques grâce aux certifications des éditeurs de logiciels indépendants, pour une fiabilité accrue. Elles offrent une accélération matérielle efficace grâce à l'IA et au ray tracing pour créer partout des contenus époustouflants.<br/><br/><span class='expandable_heading'>Certifications logicielles<br/></span><br/>Intel a travaillé en étroite collaboration avec des centaines d'éditeurs de logiciels au fil des ans. Cette expérience inégalée est mise à profit dans nos cartes graphiques Intel® Arc™ Pro série A. Les certifications sont aussi importantes pour nous qu'elles le sont pour vous."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "RENDRE L'IA ACCESSIBLE",
                    "tr_specText": "Cette gamme d'unités de traitement graphique (GPU) est dotée d'une multitude de technologies de pointe, notamment la prise en charge de vos outils d'IA compatibles, avec une accélération intégrée de l'IA là où vous en avez besoin."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING ACCESSIBLE",
                    "tr_specText": "Les cartes graphiques ne doivent pas se limiter à afficher des pixels sur les écrans. Elles doivent également accélérer la création de superbes images. La technologie matérielle de ray tracing intégrée aux cartes graphiques Intel®&nbsp;Arc™ Pro série A permet à vos logiciels compatibles d'explorer ces nouveaux flux de travail professionnels. Le ray tracing est souvent perçu comme n'étant disponible que sur les cartes graphiques coûteuses, mais la nouvelle gamme d'unités de traitement graphique (GPU) professionnelles d'Intel change la donne."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "OFFRIR AUX CRÉATEURS DES ÉCRANS IMMERSIFS",
                    "tr_specText": "Avec la prise en charge complète de DirectX* 12 Ultimate sur toutes les cartes graphiques Intel® Arc™, vous allez vivre une expérience de jeu époustouflante et immersive grâce aux dernières avancées dans le domaine des technologies graphiques, comme le ray tracing, le shading à taux variable, le mesh shading et le sampler feedback : les bases pour les jeux vidéo next-gen."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "OPTIMISATION DYNAMIQUE DES IMAGES",
                    "tr_specText": "Grâce au décodage matériel dédié sur les GPU professionnels, Dolby Vision* permet d'ajouter une plus grande profondeur, un contraste plus net et davantage de couleurs à votre écran compatible. Dolby Vision* exploite tout le potentiel de la technologie HDR en optimisant dynamiquement la qualité de l'image en fonction de votre service, de votre dispositif d'affichage et de votre plateforme, afin d'offrir des images époustouflantes à chaque fois."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "LOGICIELS VÉRIFIÉS PAR DES EXPERTS",
                    "tr_specText": "Intel a travaillé en étroite collaboration avec des centaines d'éditeurs de logiciels au fil des ans. Cette expérience inégalée est mise à profit dans la gamme de cartes graphiques professionnelles Intel®&nbsp;Arc™ Pro série A. Les certifications et les qualifications des flux de travail logiciels sont aussi importantes pour nous que pour vous."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING ACCESSIBLE",
                    "tr_specText": "Les cartes graphiques ne doivent pas se limiter à afficher des pixels sur les écrans. Elles doivent également accélérer la création de superbes images. La technologie matérielle de ray tracing intégrée aux cartes graphiques Intel®&nbsp;Arc™ Pro série A permet à vos logiciels compatibles d'explorer ces nouveaux flux de travail professionnels. Le ray tracing est souvent perçu comme n'étant disponible que sur les cartes graphiques coûteuses, mais la nouvelle gamme d'unités de traitement graphique (GPU) professionnelles d'Intel change la donne."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "PLUSIEURS GRANDS ÉCRANS AVEC UNE SEULE CARTE GRAPHIQUE COMPACTE POUR PC PORTABLES",
                    "tr_specText": "Les cartes graphiques Intel® Arc™ Pro pour PC portables peuvent prendre en charge jusqu'à quatre écrans ultra-larges pour améliorer l'efficacité de votre flux de travail. Ajoutez à cela la prise en charge de la technologie HDR, et vous avez tout ce dont vous avez besoin pour la configuration multi-écrans de vos rêves."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "POTENTIEL MAXIMAL DE LA BANDE PASSANTE MÉMOIRE",
                    "tr_specText": "Dans une station de travail, la bande passante de votre carte graphique est un élément important à prendre en compte. Si elle n'est pas adaptée, vos flux de travail professionnels peuvent être ralentis. C'est pourquoi nous avons veillé à ce que les unités de traitement graphique (GPU) Intel® Arc™ Pro série A prennent en charge la connectivité PCIe* 4.0 moderne, sans compromettre la rétrocompatibilité. La mémoire graphique est renforcée par une bande passante élevée, ce qui permet à vos logiciels d'accéder aux données de vos projets beaucoup plus rapidement."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "DONNEZ VIE À CHAQUE IMAGE",
                    "tr_specText": "<span class='expandable_heading'>Boosté par l'IA. Soutenu par Intel.<br/></span><br/>La technologie d'upscaling Intel® X<sup class='small_text_sup_xe'>e</sup>SS accélérée par l'IA booste vos performances sans sacrifier la beauté de chaque image, afin que vous puissiez jouer dans des conditions idéales."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "EN TEMPS RÉEL.<br/> RÉELLEMENT IMMERSIF.<br/> LE RÉALISME À SON APOGÉE.",
                    "tr_specText": "Des mondes plus réalistes vous attendent. Avec le ray tracing intégré, vous découvrirez des ombres et des reflets réalistes, ainsi qu'un éclairage magistral. En d'autres termes, une expérience visuelle à ne pas manquer."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "PARTAGEZ VOS MEILLEURES SESSIONS",
                    "tr_specText": "Offrez à votre public l'expérience fluide qu'il mérite. Grâce aux capacités d'encodage AV1 accélérées par le matériel des cartes graphiques Intel® Arc™, il sera captivé par chacun de vos mouvements. Une meilleure qualité visuelle au même débit vous permet de donner le meilleur de vous-même lorsque vous streamez tout en économisant de la bande passante."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Bienvenue à tous les créateurs",
                    "tr_specText": "De nouvelles expériences d'IA améliorées sont à portée de main. Grâce aux accélérateurs d'IA avancés intégrés aux cartes graphiques Intel® Arc™ série A, passez plus de temps à créer et moins de temps à attendre dans de nombreuses applications.<br/><br/>Faites passer votre création vidéo au niveau supérieur et ouvrez la voie avec une vidéo AV1 de haute qualité et légère, alimentée par des capacités matérielles AV1 avancées intégrées à la carte graphique Intel® Arc™."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Système d'exploitation",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Tout ce dont vous avez besoin pour travailler efficacement",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Associer des performances de pointe avec un appareil ultrafin, léger et fait pour durer ? C’est possible. Une batterie longue durée, des écrans tactiles* hors du commun et les derniers disques SSD : vous trouverez ce qu’il vous faut dans un PC Window 10.",
                "tr_noteSection": "*Dépend du matériel",
                "titleArray": [
                  {
                    "tr_title": "Demandez plus à votre portable",
                    "tr_subtitle": "Les PC actuels consomment généralement moins d'énergie, ce qui permet à votre batterie de durer plus longtemps&nbsp;: en moyenne, plus de huit heures de lecture de vidéos**.",
                    "tr_noteSection": "**L'autonomie varie considérablement en fonction des paramètres, de l’utilisation et d’autres facteurs."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Système d'exploitation",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "TOUT CE DONT VOUS AVEZ BESOIN POUR TRAVAILLER EFFICACEMENT",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Associer des performances de pointe avec un appareil ultrafin, léger et fait pour durer ? C’est possible. Une batterie longue durée, des écrans tactiles* hors du commun et les derniers disques SSD : vous trouverez ce qu’il vous faut dans un PC Window 10.",
                "tr_noteSection": "*Dépend du matériel",
                "titleArray": [
                  {
                    "tr_title": "DEMANDEZ PLUS À VOTRE PORTABLE",
                    "tr_subtitle": "Les PC actuels consomment généralement moins d'énergie, ce qui permet à votre batterie de durer plus longtemps&nbsp;: en moyenne, plus de huit heures de lecture de vidéos**.",
                    "tr_noteSection": "**L'autonomie varie considérablement en fonction des paramètres, de l’utilisation et d’autres facteurs."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Système d'exploitation",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Simplifié pour la sécurité et les performances",
                "tr_pretitle": "Windows*&nbsp;10 en mode&nbsp;S",
                "tr_subtitle": "Lorsque vous utilisez Windows* 10 en mode S, vous bénéficiez d'une protection supplémentaire qui vous permet de travailler, de jouer et d'explorer en toute confiance et en toute sécurité."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Système d'exploitation",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "SIMPLIFIÉ POUR LA SÉCURITÉ ET LES PERFORMANCES",
                "tr_title": "Windows*&nbsp;10 en mode&nbsp;S",
                "tr_subtitle": "Lorsque vous utilisez Windows* 10 en mode S, vous bénéficiez d'une protection supplémentaire qui vous permet de travailler, de jouer et d'explorer en toute confiance et en toute sécurité."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Système d'exploitation",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Faites de grandes choses",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Les performances, le prix et un grand choix. Des appareils 2&nbsp;en&nbsp;1 novateurs aux PC portables fins et légers, et des écrans tactiles aux claviers détachables, quel que soit votre budget ou votre style, vous pouvez trouver un PC Windows* qui vous convient."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Système d'exploitation",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "FAITES DE GRANDES CHOSES",
                "tr_title": "Windows*",
                "tr_subtitle": "Les performances, le prix et un grand choix. Des appareils 2&nbsp;en&nbsp;1 novateurs aux PC portables fins et légers, et des écrans tactiles aux claviers détachables, quel que soit votre budget ou votre style, vous pouvez trouver un PC Windows* qui vous convient."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Système d'exploitation",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Système d'exploitation",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Mémoire",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "DE COMBIEN DE RAM AVEZ-VOUS BESOIN&nbsp;?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Courant&nbsp;: DE 2&nbsp;GO JUSQU'À 8&nbsp;GO DE RAM",
                    "tr_badgeSubTitle": "Idéal pour ceux qui utilisent leur PC principalement pour le traitement de texte et la navigation Web, et qui ont tendance à utiliser un seul programme à la fois."
                  },
                  {
                    "tr_badgeTitle": "Puissant&nbsp;: DE 8&nbsp;GO JUSQU'À 16&nbsp;GO de RAM",
                    "tr_badgeSubTitle": "Avec davantage de RAM, vos applications seront non seulement plus rapides, mais vous pourrez également utiliser davantage d'applications en même temps."
                  },
                  {
                    "tr_badgeTitle": "Avancé : PLUS DE 16&nbsp;GO DE RAM",
                    "tr_badgeSubTitle": "Si vous vous intéressez aux jeux vidéo, à la retouche de photos, au montage de vidéos ou à la modélisation en 3D, vous aurez besoin de plus de RAM pour gérer ces programmes intensifs."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MÉMOIRE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX]&nbsp;Go de RAM pour le multitâche quotidien",
                "tr_subtitle": "La mémoire assure un chargement et une exécution fluides des applications de base comme le traitement de texte et la navigation Web.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;Go<br/></span><span class='subtitle-value'>de RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MÉMOIRE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX]&nbsp;Go de RAM pour le multitâche intensif",
                "tr_subtitle": "Si vous utilisez plusieurs programmes en même temps, [memory_total]&nbsp;Go de mémoire vous permettront de passer facilement de l'un à l'autre.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;Go<br/></span><span class='subtitle-value'>de RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MÉMOIRE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX]&nbsp;Go de RAM pour le multitâche avancé",
                "tr_subtitle": "Vous disposerez de beaucoup de mémoire pour utiliser plusieurs programmes intensifs en même temps sans ralentir votre PC.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;Go<br/></span><span class='subtitle-value'>de RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MÉMOIRE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1]&nbsp;Go de mémoire = [Value2]&nbsp;Go de mémoire Intel®&nbsp;Optane™&nbsp;+ [Value3]&nbsp;Go de RAM",
                "tr_subtitle": "Cette combinaison de solutions de mémoire accélère les performances du disque dur grâce à la mémoire Intel® Optane™ et permet une réactivité accrue de la RAM.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX]&nbsp;Go<br/></span><span class='subtitle-value'>DE MÉMOIRE</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Mémoire Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Moins d'attente. Plus de vos contenus et applications préférés.",
                "tr_subtitle": "Associée à votre mémoire système, la mémoire Intel® Optane™ vous permet d'accéder plus vite aux programmes que vous utilisez fréquemment, car ils se lancent et se chargent dès que vous en avez besoin.",
                "tr_pretitle": "Mémoire Intel® Optane™ de [XX]&nbsp;Go",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "QU'EST-CE QUE LA MÉMOIRE APPORTE À MON PC&nbsp;?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Création de contenu plus rapide",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Jeux plus réactifs",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Multitâche simplifié",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Ouverture plus rapide des programmes",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Mémoire Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Accélérez votre jeu</span>",
                "tr_pretitle": "Mémoire Intel® Optane™ de [XX]&nbsp;Go",
                "tr_pretitle1": "[XX]&nbsp;Go de mémoire Intel® Optane™ pour accélérer votre expérience de jeu",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Lancez les jeux jusqu'à <br/><span class=\"optane-game\">98&nbsp;% plus vite</span><br/> depuis le lecteur de données avec un processeur [optane_legal_processor_name] associé à la mémoire Intel®&nbsp;Optane™<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Chargez les niveaux jusqu'à <br/><span class=\"optane-game\">4,1 fois plus vite</span><br/> depuis le lecteur de données avec un processeur [optane_legal_processor_name] associé à la mémoire Intel®&nbsp;Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Mémoire Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Accélérez votre jeu</span>",
                "tr_pretitle": "Mémoire Intel® Optane™ de [XX]&nbsp;Go",
                "tr_pretitle1": "[XX]&nbsp;Go de mémoire Intel® Optane™ pour accélérer votre expérience de jeu",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Lancez les jeux jusqu'à <br/><span class=\"optane-game\">98&nbsp;% plus vite</span><br/> depuis le lecteur de données avec un processeur [optane_legal_processor_name] associé à la mémoire Intel®&nbsp;Optane™<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Chargez les niveaux jusqu'à <br/><span class=\"optane-game\">4,1 fois plus vite</span><br/> depuis le lecteur de données avec un processeur [optane_legal_processor_name] associé à la mémoire Intel®&nbsp;Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MÉMOIRE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1]&nbsp;Go de mémoire = [Value2]&nbsp;Go de mémoire Intel®&nbsp;Optane™&nbsp;+ [Value3]&nbsp;Go de RAM",
                "tr_subtitle": "Cette combinaison de solutions de mémoire accélère les performances du disque dur grâce à la mémoire Intel® Optane™ et permet une réactivité accrue de la RAM.",
                "titleArray": [
                  {
                    "tr_title": "[XX]&nbsp;Go de RAM pour le multitâche",
                    "tr_subtitle": "Si vous utilisez plusieurs programmes en même temps, [memory_total]&nbsp;Go de mémoire vous permettront de passer facilement de l'un à l'autre."
                  },
                  {
                    "tr_title": "Pour les tâches courantes",
                    "tr_subtitle": "La mémoire assure un chargement et une exécution fluides des applications de base comme le traitement de texte et la navigation Web."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MÉMOIRE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX]&nbsp;Go RAM",
                "tr_pretitle": "POUR PLUS DE MULTITÂCHE",
                "tr_subtitle": "La RAM permet à votre processeur d'accéder rapidement aux fichiers des programmes, ce qui lui permet de travailler sur plusieurs tâches en même temps.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Mémoire standard",
                    "tr_badgeSubTitle": "Jusqu'à 8&nbsp;Go de RAM"
                  },
                  {
                    "tr_badgeTitle": "Mémoire avancée",
                    "tr_badgeSubTitle": "Plus de 8 Go et jusqu'à 16 Go de RAM"
                  },
                  {
                    "tr_badgeTitle": "Mémoire d'élite",
                    "tr_badgeSubTitle": "Plus de 16&nbsp;Go de RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MÉMOIRE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX]&nbsp;Go pour davantage de multitâche",
                "tr_pretitle": "POUR PLUS DE MULTITÂCHE",
                "tr_subtitle": "La RAM permet à votre processeur d'accéder rapidement aux fichiers des programmes, ce qui lui permet de travailler sur plusieurs tâches en même temps.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Mémoire standard",
                    "tr_badgeSubTitle": "Jusqu'à 8&nbsp;Go de RAM"
                  },
                  {
                    "tr_badgeTitle": "Mémoire avancée",
                    "tr_badgeSubTitle": "Plus de 8 Go et jusqu'à 16 Go de RAM"
                  },
                  {
                    "tr_badgeTitle": "Mémoire d'élite",
                    "tr_badgeSubTitle": "Plus de 16&nbsp;Go de RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMMENT CETTE MÉMOIRE SE COMPARE-T-ELLE AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Standard",
                      "tr_badgeSubTitle": "<span class='memory-value'>8&nbsp;Go de <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avancé",
                      "tr_badgeSubTitle": "<span class='memory-value'>16&nbsp;Go de <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Élite",
                      "tr_badgeSubTitle": "<span class='memory-value'>32&nbsp;Go de <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Mémoire",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "COMMENT CETTE MÉMOIRE SE COMPARE-T-ELLE AUX AUTRES&nbsp;?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "COMMENT CETTE MÉMOIRE SE COMPARE-T-ELLE AUX AUTRES&nbsp;?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Appareil actuel",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Activités courantes",
                      "tr_badgeSubTitle": "<span class='memory-value'>8&nbsp;Go de <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Puissant",
                      "tr_badgeSubTitle": "<span class='memory-value'>16&nbsp;Go de <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avancé",
                      "tr_badgeSubTitle": "<span class='memory-value'>32&nbsp;Go de <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Stockage",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "QUELLE QUANTITÉ DE DONNÉES VOTRE PC PEUT-IL CONSERVER&nbsp;?",
                "tr_subtitle": "Pas besoin de deviner. Nous allons vous indiquer exactement ce que vous pouvez stocker sur ce PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Créer une bibliothèque de</span> <br/><span class=\"storage-standard\">[num_songs] morceaux de musique<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>Sur une base moyenne de 5&nbsp;Mo par morceau",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Créer une bibliothèque de</span> <br/><span class=\"storage-standard\">[num_games]&nbsp;jeux<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup> Sur une base moyenne de 40&nbsp;Go par jeu",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Stockage pour</span> <br/><span class=\"storage-standard\">[num_videos] vidéos<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>Sur une base moyenne de 5&nbsp;Go par vidéo",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Prendre et stocker</span> <br/><span class=\"storage-standard\">[num_photos] photos<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>Sur une base moyenne de 4&nbsp;Mo par photo",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "Disque dur de [capacity]&nbsp;Go"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "SSD de [capacity]&nbsp;Go"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "Mémoire Intel® Optane™ de&nbsp;[total_optane_memory]&nbsp;Go + SSD de [convertedSSD]&nbsp;Go"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STOCKAGE",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "Stockage SSD de [capacity]&nbsp;Go",
                "tr_preTitle1": "Stockage SSD de [capacity]&nbsp;To",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Ce PC est équipé d'un lecteur SSD de [capacity]&nbsp;Go de stockage. Vous pouvez donc enregistrer une quantité impressionnante de photos, de vidéos, de programmes et y accéder beaucoup plus rapidement qu'avec un disque dur.",
                    "tr_title": "Un emplacement central pour tout ce qui se trouve sur votre PC"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Ce PC est équipé d'un lecteur SSD de [capacity]&nbsp;To de stockage. Vous pouvez donc enregistrer une quantité impressionnante de photos, de vidéos, de programmes et y accéder beaucoup plus rapidement qu'avec un disque dur.",
                    "tr_title": "Cet appareil dispose d'un stockage impressionnant"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;GO<br/></span><span class='subtitle-value'>DE STOCKAGE</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity]&nbsp;TO<br/></span><span class='subtitle-value'>DE STOCKAGE</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STOCKAGE",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "Stockage sur disque dur de [capacity]&nbsp;Go",
                "tr_preTitle1": "Stockage sur disque dur de [capacity]&nbsp;To",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Les disques durs conviennent aux utilisateurs qui veulent une grande capacité de stockage directement sur leur PC.",
                    "tr_title": "Un emplacement central pour tout ce qui se trouve sur votre PC"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Les disques durs conviennent aux utilisateurs qui veulent une grande capacité de stockage directement sur leur PC.",
                    "tr_title": "Cet appareil dispose d'un stockage impressionnant"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>DE STOCKAGE</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "Sur disque dur",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STOCKAGE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "QUELLE QUANTITÉ DE DONNÉES VOTRE PC PEUT-IL CONSERVER&nbsp;?",
                "tr_subtitle": "Pas besoin de deviner. Nous allons vous indiquer exactement ce que vous pouvez stocker sur ce PC.",
                "titleArray": [
                  {
                    "tr_title": "DISQUE DUR DE [XX]&nbsp;GO"
                  },
                  {
                    "tr_title": "SSD DE [XX]&nbsp;GO"
                  },
                  {
                    "tr_title": "Unité de stockage SSD Intel® de [total_capacity]&nbsp;Go accélérée par [optane_memory_size]&nbsp;Go de mémoire Intel® Optane™"
                  },
                  {
                    "tr_title": "Unité de stockage SSD Intel® de [total_capacity] To accélérée par une mémoire Intel® Optane™ de [optane_memory_size]&nbsp;Go"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD]&nbsp;Go + Disque dur de [convertedHDD]&nbsp;Go"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD]&nbsp;To + Disque dur de [convertedHDD]&nbsp;Go"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD]&nbsp;Go + Disque dur de [convertedHDD]&nbsp;To"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD]&nbsp;To + Disque dur de [convertedHDD]&nbsp;To"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;GO<br/></span><span class='subtitle-value'>DE STOCKAGE</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;GO<br/></span><span class='subtitle-value'>DE STOCKAGE</span>",
                        "tr_badgeSubTitle": "Sur disque dur"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;GO<br/></span><span class='subtitle-value'>DE STOCKAGE</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>Disque dur</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;TO<br/></span><span class='subtitle-value'>DE STOCKAGE</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;TO<br/></span><span class='subtitle-value'>DE STOCKAGE</span>",
                        "tr_badgeSubTitle": "Sur disque dur"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;TO<br/></span><span class='subtitle-value'>DE STOCKAGE</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>Disque dur</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STOCKAGE",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "Stockage SSD de [convertedSSD]&nbsp;Go + Stockage sur disque dur de [convertedHDD]&nbsp;Go",
                "tr_preTitle1": "Stockage SSD de [convertedSSD]&nbsp;To + Stockage sur disque dur de [convertedHDD]&nbsp;Go",
                "tr_preTitle2": "Stockage SSD de [convertedSSD]&nbsp;Go + Stockage sur disque dur de [convertedHDD]&nbsp;To",
                "tr_preTitle3": "Stockage SSD de [convertedSSD]&nbsp;To + Stockage sur disque dur de [convertedHDD]&nbsp;To",
                "titleArray": [
                  {
                    "tr_title": "Un emplacement central pour tout ce qui se trouve sur votre PC",
                    "tr_title1": "Cet appareil dispose d'un stockage impressionnant",
                    "tr_subtitle": "Ce PC est équipé d'un lecteur SSD avec [convertedSSD] Go de stockage et d'un disque dur de [convertedHDD] Go. Vous bénéficiez ainsi d'une énorme capacité et de performances de stockage rapides.",
                    "tr_subtitle1": "Ce PC est équipé d'un lecteur SSD avec [convertedSSD] To de stockage et d'un disque dur de [convertedHDD] Go. Vous bénéficiez ainsi d'une énorme capacité et de performances de stockage rapides.",
                    "tr_subtitle2": "Ce PC est équipé d'un lecteur SSD avec [convertedSSD] Go de stockage et d'un disque dur de [convertedHDD] To. Vous bénéficiez ainsi d'une énorme capacité et de performances de stockage rapides.",
                    "tr_subtitle3": "Ce PC est équipé d'un lecteur SSD avec [convertedSSD] To de stockage et d'un disque dur de [convertedHDD] To. Vous bénéficiez ainsi d'une énorme capacité et de performances de stockage rapides."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]&nbsp;GO<br/></span><span class='subtitle-value'>DE STOCKAGE</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity]&nbsp;TO<br/></span><span class='subtitle-value'>DE STOCKAGE</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>Disque dur</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STOCKAGE",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Obtenez un accès plus rapide à une grande capacité de stockage grâce à la mémoire Intel® Optane™ avec unité de stockage SSD.",
                "tr_title": "Bienvenue aux fichiers volumineux",
                "tr_pretitle": "Unité de stockage SSD Intel® de [total_capacity]&nbsp;Go accélérée par [optane_memory_size]&nbsp;Go de mémoire Intel® Optane™",
                "tr_pretitle1": "Unité de stockage SSD Intel® de [total_capacity] To accélérée par une mémoire Intel® Optane™ de [optane_memory_size]&nbsp;Go",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Cette solution de stockage révolutionnaire est la première à combiner la mémoire Intel® Optane™ et une unité de stockage SSD sur une seule puce. Voici ce qu'elle fait pour vous&nbsp;:",
                    "tr_title": "QU'EST-CE QUE LA MÉMOIRE INTEL®&nbsp;OPTANE™ AVEC UNITÉ DE STOCKAGE SSD&nbsp;?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Ouverture rapide de ce qu'il vous faut",
                    "tr_badgeSubTitle": "En préchargeant les fichiers et programmes fréquemment utilisés, vous pouvez passer plus de temps à créer et moins de temps à attendre.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Stockage et vitesse",
                    "tr_badgeSubTitle": "Vous disposez de l'espace dont vous avez besoin pour les fichiers multimédias avec les performances rapides d'une unité de stockage SSD.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Une plus grande réactivité à long terme",
                    "tr_badgeSubTitle": "La mémoire Intel® Optane™ avec stockage SSD analyse la façon dont vous travaillez, ce qui rend votre PC plus efficace.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "STOCKAGE",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Gardez tous vos contenus multimédia à portée de main, en&nbsp;toute&nbsp;sécurité.",
                "tr_title": "De l'espace pour toutes vos photos, vidéos et chansons"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Écran",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] millions de pixels pour balayer, pincer et étonner",
                "tr_subtitle": "Utilisez votre PC comme un smartphone, avec des commandes intuitives et des détails plus vifs et plus nets.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Résolution&nbsp;: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels&nbsp;: [screenResolution]&nbsp;millions"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Écran de [screenDiagonal]&nbsp;pouces",
                    "tr_badgeSubTitle": "Hauteur de l'écran&nbsp;: [screenHeight]&nbsp;pouces",
                    "tr_badgePreTitle": "Largeur d'écran&nbsp;: [YY]&nbsp;pouces"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Écran tactile",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Écran",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Immergez-vous dans [screenResolution] millions de pixels",
                "tr_subtitle": "Tout repose sur les pixels. Plus leur nombre est élevé, plus les images sont nettes et éclatantes.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Résolution&nbsp;: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels&nbsp;: [screenResolution]&nbsp;millions"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Écran de [screenDiagonal]&nbsp;pouces",
                    "tr_badgeSubTitle": "Hauteur de l'écran&nbsp;: [screenHeight]&nbsp;pouces",
                    "tr_badgePreTitle": "Largeur d'écran&nbsp;: [YY]&nbsp;pouces"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Écran",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal]&nbsp;pouces <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "LA FENÊTRE SUR VOS UNIVERS DE JEU",
                "tr_subtitle": "Comme pour un téléviseur, la taille et la résolution sont les principaux facteurs à prendre en compte pour l'écran d'une plate-forme de jeu.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Résolution&nbsp;: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels&nbsp;: [screenResolution]&nbsp;millions"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Écran de [screenDiagonal]&nbsp;pouces",
                    "tr_badgeSubTitle": "Hauteur de l'écran&nbsp;: [screenHeight]&nbsp;pouces",
                    "tr_badgePreTitle": "Largeur de l'écran&nbsp;: [screenWidth]&nbsp;pouces"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Écran tactile"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Écran",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Cet appareil dispose d'un écran impressionnant",
                "tr_subtitle": "Avec plus de huit fois plus de pixels que la HD, vos vidéos et vos jeux n'ont jamais bénéficié d'images aussi nettes et de couleurs aussi riches.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Résolution&nbsp;: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels&nbsp;: [screenResolution]&nbsp;millions"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Écran de [screenDiagonal]&nbsp;pouces",
                    "tr_badgeSubTitle": "Hauteur de l'écran&nbsp;: [screenHeight]&nbsp;pouces",
                    "tr_badgePreTitle": "Largeur d'écran&nbsp;: [YY]&nbsp;pouces"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Écran",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] millions de pixels pour taper, balayer et toucher",
                    "tr_subTitle": "Affichez et manipulez vos contenus préférés avec un niveau de détail étonnant."
                  },
                  {
                    "tr_title": "[screenResolution]&nbsp;millions de pixels pour enrichir votre mode numérique",
                    "tr_subTitle": "Tout repose sur les pixels. Plus leur nombre est élevé, plus les images sont nettes et éclatantes."
                  },
                  {
                    "tr_title": "Cet appareil dispose d'un écran impressionnant",
                    "tr_subTitle": "Avec quasiment quatre fois le nombre de pixels de la HD, vos vidéos et vos jeux atteindront un niveau de précision et de couleur entièrement nouveau."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Connectivité",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "La technologie Thunderbolt™&nbsp;3 fournit la connexion la plus rapide et la plus polyvalente à tous les écrans, stations d'accueil et périphériques de données.",
                "tr_title": "Un seul port pour tout",
                "tr_title1": "UN SEUL PORT POUR TOUT",
                "titleArray": [
                  {
                    "tr_title": "COMMENT UTILISER LA TECHNOLOGIE THUNDERBOLT™ 3&nbsp;?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Technologie Thunderbolt™&nbsp;3"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "Écrans 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Connectez des écrans qui affichent une résolution, des couleurs et des contrastes éblouissants."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Carte graphique externe ",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Elles offrent instantanément une amélioration graphique majeure à n'importe quel PC."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Connectivité rapide",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Elle fournit des connexions pair à pair (peer-to-peer) rapides."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Connectivité",
                "detailBGUrl": "",
                "tr_subtitle": "La technologie Thunderbolt™&nbsp;3 offre des débits de données plus rapides que les connexions USB conventionnelles, ce qui vous permet de déplacer des fichiers rapidement et d'intégrer facilement des périphériques, comme des écrans ou des cartes graphiques externes.",
                "tr_title": "Technologie Thunderbolt™&nbsp;3",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "DES DONNÉES QUI SE DÉPLACENT À LA VITESSE DE L'ÉCLAIR",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480&nbsp;Mbit/s",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5&nbsp;Gbit/s",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Technologie Thunderbolt™&nbsp;3",
                    "tr_badgeSubTitle": "40&nbsp;Gbit/s",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Connectivité",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Un port et un câble universels compatibles avec tous vos accessoires préférés</li> <li>Recharge fiable des PC portables</li> <li>Sa certification robuste rassure</li></ul>",
                "tr_title": "Connectivité à l'aide d'un câble véritablement universel",
                "tr_title1": "CONNECTIVITÉ À L'AIDE D'UN CÂBLE VÉRITABLEMENT UNIVERSEL",
                "tr_noteSection": "<sup>†</sup> D'après les exigences minimales des spécifications",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "COMMENT UTILISER LA TECHNOLOGIE THUNDERBOLT™&nbsp;4&nbsp;?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Technologie Thunderbolt™&nbsp;4"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Création",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Connectez un moniteur externe et un stockage rapide pour afficher et éditer des photos et des vidéos."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Station d'accueil",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Bénéficiez d'un bureau dégagé en connectant tous vos accessoires à votre PC portable à l'aide d'un seul câble."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Jouer",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Ajoutez des unités SSD et des disques durs de plus grande capacité, grâce à des vitesses pouvant atteindre 3&nbsp;000&nbsp;Mo/s, pour stocker des bibliothèques de jeux et des fichiers multimédias."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Performances maximales<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4&nbsp;fois plus rapide que la norme USB* 3.2 <br/>2x plus rapide que HDMI*&nbsp;2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Connectivité",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Bénéficiez des mêmes performances étonnantes qu'avec la technologie Thunderbolt™&nbsp;3, avec encore plus de capacités vous permettant de connecter facilement et d'utiliser les derniers moniteurs, stations d'accueil, périphériques de stockage rapide Thunderbolt™, ou tout accessoire USB*.",
                "tr_title": "Technologie Thunderbolt™&nbsp;4",
                "tr_pretitle": "CONNECTIVITÉ À L'AIDE D'UN CÂBLE VÉRITABLEMENT UNIVERSEL",
                "tr_noteSection": "<sup>†</sup> D'après les exigences minimales des spécifications",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Un câble Thunderbolt™&nbsp;4 universel peut remplacer tous vos câbles USB-C* pour accélérer et faciliter la sélection des câbles."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Les stations d'accueil de technologie Thunderbolt™&nbsp;4 possèdent jusqu'à quatre ports Thunderbolt™ offrant plus de flexibilité pour connecter vos accessoires."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Performances maximales<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4&nbsp;fois plus rapide que la norme USB* 3.2 <br/>2x plus rapide que HDMI*&nbsp;2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Connectivité",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Un port et un câble universels compatibles avec tous vos accessoires préférés</li> <li>Recharge fiable des PC portables</li> <li>Sa certification robuste rassure</li></ul>",
                "tr_title": "Optimisez votre expérience avec des accessoires Thunderbolt™",
                "tr_noteSection": "<sup>†</sup> D'après les exigences minimales des spécifications",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "COMMENT UTILISER LA TECHNOLOGIE THUNDERBOLT™&nbsp;4&nbsp;?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Création",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Connectez un moniteur externe et un stockage rapide pour afficher et éditer des photos et des vidéos."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Station d'accueil",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Bénéficiez d'un bureau dégagé en connectant tous vos accessoires à votre PC portable à l'aide d'un seul câble."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Jouer",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Ajoutez des unités SSD et des disques durs de plus grande capacité, grâce à des vitesses pouvant atteindre 3&nbsp;000&nbsp;Mo/s, pour stocker des bibliothèques de jeux et des fichiers multimédias."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Performances maximales<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4&nbsp;fois plus rapide que la norme USB* 3.2 <br/>2x plus rapide que HDMI*&nbsp;2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "Les types de Wi‑Fi les plus courants sont&nbsp;les&nbsp;suivants&nbsp;:",
                "tr_title": "COMPRENDRE LES NORMES WI-FI",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Le Wi-Fi 6 permet de meilleurs débits, même lorsque de nombreux utilisateurs utilisent le même signal<sup>9</sup>."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Avec la toute dernière innovation Wi‑Fi d'Intel, vous pouvez atteindre des débits supérieurs au Wi‑Fi Gigabit, même lorsque plusieurs utilisateurs partagent le même signal<sup>9</sup>."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Plus grande avancée du Wi‑Fi en 20 ans, le Wi‑Fi 6E ouvre de nouveaux canaux haut débit qui améliorent les performances et la fiabilité et réduisent les interférences."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Le Wi-Fi 6 permet de meilleurs débits, même lorsque de nombreux utilisateurs utilisent le même signal<sup>9</sup>."
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi&nbsp;6E (Gig+)",
                    "tr_badgeSubTitle": "Tous les avantages du Wi-Fi&nbsp;6E, associés au moteur de priorisation Intel® Killer™ qui attribue de la bande passante aux sites Web et aux applications qui en ont le plus besoin."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Grâce à une moindre concurrence sur vos canaux haut débit, vous bénéficierez de meilleures performances et d'une plus grande fiabilité."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi&nbsp;6",
                    "tr_badgeSubTitle": "Le Wi-Fi 6 permet de meilleurs débits, même lorsque de nombreux utilisateurs utilisent le même signal<sup>9</sup>."
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi&nbsp;6 (Gig+)",
                    "tr_badgeSubTitle": "Les interférences réduites du Wi-Fi&nbsp;6 et les performances intelligentes du moteur de priorisation Intel® Killer™ accélèrent votre expérience en ligne et améliorent sa réactivité.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Grâce à une moindre concurrence sur vos canaux haut débit, vous bénéficierez de meilleures performances et d'une plus grande fiabilité."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Débits Wi-Fi PC supérieurs à 5 Gbit/s avec une latence ultra-faible constante : partagez des fichiers en quelques secondes au lieu de quelques minutes."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) est la dernière technologie offrant une fiabilité « extrême », une faible latence et une vitesse améliorée grâce à l'optimisation du réseau basée sur l'IA."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Le Wi-Fi 6 permet de meilleurs débits, même lorsque de nombreux utilisateurs utilisent le même signal<sup>9</sup>."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) offre des débits supérieurs à 1 Gbit/s, une fiabilité améliorée et une faible latence grâce à l'utilisation des canaux 6 GHz."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Efficacité, capacité et vitesse améliorées par rapport au Wi-Fi 5 sur les réseaux encombrés."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Améliorez votre connexion avec le Wi‑Fi [XX] et profitez d'Internet avec un débit plus élevé<sup>†</sup> et une fiabilité accrue.",
                "tr_title": "Bénéficiez d'une portée et d'une vitesse améliorées",
                "tr_noteSection": "<sup>†</sup>&nbsp;Débit et/ou portée en sans-fil variables.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "À l'ère des jeux multijoueurs et en ligne, il n'a jamais été aussi important de rester connecté. Outre la&nbsp;vitesse, vous avez besoin d'un signal sans fil capable de maintenir cette vitesse à distance.",
                "tr_title": "[XX]",
                "tr_pretitle": "LA CONNEXION EST ESSENTIELLE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Connectez-vous avec des routeurs Intel®Wi‑Fi6(Gig+) à Wi‑Fi6 qui prennent en charge les canaux de 160MHz, ce qui offre des connexions plus rapides, une plus grande fiabilité et permet aux utilisateurs d'être plus actifs.",
                "tr_title": "Débits près de 3&nbsp;fois plus élevés avec <span class='no-warp'>Intel® Wi&#8209;Fi&nbsp;6&nbsp;(Gig+)</span><sup>†</sup>",
                "tr_noteSection": "<sup>†</sup> Que le Wi‑Fi AC standard. Certaines fonctionnalités sont uniquement disponibles sur certains modèles. Consultez votre fabricant pour plus de détails. Pour plus d'informations sur le Wi‑Fi, consultez intel.fr/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Les produits Intel® Wi-Fi 6 double bande d'entrée de gamme améliorent les performances pour les besoins de connectivité basiques comme les e-mails et la navigation Internet sur les réseaux encombrés.<sup>9</sup>",
                "tr_title": "Wi-Fi essentiel",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "À l'ère des jeux multijoueurs et en ligne, il n'a jamais été aussi important de rester connecté. Outre la&nbsp;vitesse, vous avez besoin d'un signal sans fil capable de maintenir cette vitesse à distance.",
                "tr_title": "[XX]",
                "tr_pretitle": "LA CONNEXION EST ESSENTIELLE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Ces nouveaux canaux Wi‑Fi signifient que vous n'avez plus à partager votre bande passante avec les appareils plus anciens de votre voisinage, ce qui vous offre un streaming fluide, une navigation rapide et un travail et un apprentissage à distance sans encombres.",
                "tr_title": "Connectez-vous sans souci de la concurrence",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Améliorez votre connexion avec [XX] et profitez d'Internet avec un débit plus élevé<sup>†</sup> et une fiabilité accrue.",
                "tr_title": "Bénéficiez d'une portée et d'une vitesse améliorées",
                "tr_noteSection": "<sup>†</sup>&nbsp;Débit et/ou portée en sans-fil variables.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "À l'ère des jeux multijoueurs et en ligne, il n'a jamais été aussi important de rester connecté. Outre la&nbsp;vitesse, vous avez besoin d'un signal sans fil capable de maintenir cette vitesse à distance.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "LA CONNEXION EST ESSENTIELLE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Wi‑Fi ultra-rapide intégré",
                "tr_subtitle": "Débranchez votre appareil tout en restant connecté à votre univers en ligne."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Avec des voies haut débit dédiées pour réduire les interférences et le moteur Intel® Killer™ Prioritization Engine qui donne la priorité au jeu, les jeux en ligne viennent de bénéficier d'une cure de jouvence.",
                "tr_title": "Laissez la concurrence dans la poussière",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Avec des voies haut débit dédiées pour réduire les interférences et le moteur Intel® Killer™ Prioritization Engine qui donne la priorité au jeu, les jeux en ligne viennent de bénéficier d'une cure de jouvence.",
                "tr_title": "[XX]",
                "tr_pretitle": "LAISSEZ LA CONCURRENCE DANS LA POUSSIÈRE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Avec des voies haut débit dédiées qui réduisent les interférences pour donner la priorité aux jeux, les jeux vidéo en ligne bénéficient d'un sérieux coup de pouce.",
                "tr_title": "[XX]",
                "tr_pretitle": "LAISSEZ LA CONCURRENCE DANS LA POUSSIÈRE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi-Fi accélère automatiquement le trafic réseau pour les applications qui en ont le plus besoin, afin que vos vidéos possèdent des détails nets et fassent moins appel à la mémoire tampon lors du streaming.",
                "tr_title": "Un moyen plus rapide et plus intelligent de se connecter.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "À l'ère des jeux multijoueurs et en ligne, il n'a jamais été aussi important de rester connecté. Outre la&nbsp;vitesse, vous avez besoin d'un signal sans fil capable de maintenir cette vitesse à distance.",
                "tr_title": "[XX]",
                "tr_pretitle": "LA CONNEXION EST ESSENTIELLE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Améliorez vos connexions grâce au Wi‑Fi&nbsp;5 et profitez d'Internet avec des débits plus élevés<sup>†</sup> et une fiabilité accrue.",
                "tr_title": "Bénéficiez d'une portée et d'une vitesse améliorées",
                "tr_noteSection": "<sup>†</sup>&nbsp;Débit et/ou portée en sans-fil variables.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "À l'ère des jeux multijoueurs et en ligne, il n'a jamais été aussi important de rester connecté. Outre la&nbsp;vitesse, vous avez besoin d'un signal sans fil capable de maintenir cette vitesse à distance.",
                "tr_title": "[XX]",
                "tr_pretitle": "LAISSEZ LA CONCURRENCE DANS LA POUSSIÈRE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Les fonctionnalités du Wi-Fi 7 vont s'appuyer sur, et améliorer, les générations Wi-Fi antérieures. Cela implique non seulement des vitesses plus rapides, mais une réactivité et une fiabilité considérablement améliorées, pour des expériences grand public immersives et des technologies futures sophistiquées.",
                "tr_title": "Mise en réseau de PC digne d'une connexion filaire",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Les fonctionnalités du Wi-Fi 7 vont s'appuyer sur, et améliorer, les générations Wi-Fi antérieures. Cela implique non seulement des vitesses plus rapides, mais une réactivité et une fiabilité considérablement améliorées, pour des expériences grand public immersives et des technologies futures sophistiquées.",
                "tr_title": "[XX]",
                "tr_pretitle": "TRANSFORMATION DE L'EXPÉRIENCE UTILISATEUR",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Vitesse, latence et fiabilité améliorées grâce à l'optimisation du réseau basée sur l'IA.",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Vitesse, latence et fiabilité améliorées grâce à l'optimisation du réseau basée sur l'IA.",
                "tr_title": "[XX]",
                "tr_pretitle": "UNE RÉACTIVITÉ DIGNE D'UNE CONNEXION FILAIRE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batterie",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Comment l'autonomie est-elle mesurée ?",
                    "tr_badgeSubTitle": "La plupart des fabricants indiquent l'autonomie sur batterie en nombre d'heures. Même si ce calcul n'est pas précis à 100&nbsp;%, il vous aide à comparer l'autonomie de plusieurs PC."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batterie",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "POUR JOUER PARTOUT",
                "tr_subtitle": "Avec la puissance des PC portables de jeu actuels, il n'y a aucune raison de rester enchaîné à un bureau. Vos jeux vous suivent désormais partout.",
                "tr_title": "[Dynamic.battery.values.hours]&nbsp;heures d'autonomie sur batterie"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batterie",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Débranchez votre appareil et profitez de vos contenus (applications, jeux et musique) pendant des heures.",
                "tr_title": "[XX]&nbsp;heures de streaming sur une même charge<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>D'après l'autonomie indiquée par le constructeur du PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]&nbsp;heures",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batterie",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Débranchez votre appareil et profitez de vos contenus (applications, jeux et musique) pendant des heures.",
                "tr_title": "[Dynamic.battery.values.hours]&nbsp;heures de fonctionnement sur batterie<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>D'après l'autonomie indiquée par le constructeur du PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]&nbsp;heures",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batterie",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Débranchez votre appareil et profitez de vos contenus (applications, jeux et musique) pendant des heures.",
                "tr_title": "[Dynamic.battery.values.hours] heures de fonctionnement sur une seule charge<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>D'après l'autonomie indiquée par le constructeur du PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]&nbsp;heures",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Batterie",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Ce PC optimise l'autonomie de votre batterie afin qu'elle dure plus longtemps et vous donne au moins quatre&nbsp;heures d'autonomie sur une charge de 30&nbsp;minutes<sup>4</sup>.",
                "tr_title": "Travaillez plus longtemps sur batterie avec [Dynamic.battery.values.hours]&nbsp;heures d'autonomie<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>D'après l'autonomie indiquée par le constructeur du PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]&nbsp;heures",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Accueil",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Pourquoi ce PC",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Voir toutes les caractéristiques",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "À distance",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Mobile",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Mentions légales",
        "tr_paragraph_array": [
          "Les logiciels et charges de travail employés dans les tests de performance peuvent avoir été optimisés pour les microprocesseurs Intel®. Les tests de performance tels que SYSmark* et MobileMark* sont mesurés avec des systèmes informatiques, des composants et des fonctions spécifiques. Les résultats peuvent varier en fonction de ces facteurs. Pour l'évaluation d'un produit, il convient de consulter d'autres tests et d'autres sources d'information, notamment pour connaître le comportement de ce produit avec d'autres composants. Pour en savoir plus sur les performances et les résultats des bancs d'essai, rendez-vous sur www.intel.com/benchmarks.",
          "¹ Les solutions Intel® Wi-Fi 6 (Gig+) prennent en charge les canaux facultatifs de 160 MHz, ce qui permet d'obtenir les plus hautes vitesses théoriques possibles (2 402 Mbit/s) pour les solutions Wi-Fi PC 2x2 802.11ax standard. Les solutions Intel® Wi‑Fi&nbsp;6 (Gig+) haut de gamme permettent d'obtenir des vitesses théoriques maximales deux à quatre fois plus rapides par rapport aux solutions Wi‑Fi PC 2x2 (1&nbsp;201&nbsp;Mbit/s) ou 1x1 (600&nbsp;Mbit/s) 802.11ax standard, qui ne prennent en charge que les canaux obligatoires de 80&nbsp;MHz.",
          "² Recommandations fournies à titre indicatif uniquement. Veuillez consulter d'autres sources avant d'effectuer tout achat.",
          "³Les fonctionnalités et avantages des technologies Intel dépendent de la configuration du système et peuvent nécessiter du matériel et des logiciels compatibles, et l'activation de services. Les résultats varient selon la configuration. Aucun ordinateur ne saurait être totalement sécurisé. Pour plus de détails, contactez le fabricant ou le vendeur de votre ordinateur ou rendez-vous sur intel.fr.",
          "⁴ Pour les systèmes avec écran FHD, lorsqu'ils sont utilisés pour la navigation Web sans fil. Lorsque les systèmes sont éteints à l'aide de la fonction d'arrêt par défaut de l'ordinateur.",
          "⁵ Par rapport à d'autres technologies de connexion des E/S pour PC, notamment eSATA, USB et IEEE&nbsp;1394* FireWire. Les performances peuvent varier en fonction du matériel et des logiciels utilisés. Cette technologie nécessite un appareil compatible avec Thunderbolt™.",
          "⁶ Près de 3 fois plus rapide : la norme 802.11ax 2x2 160 MHz permet d'atteindre des débits de données théoriques maximum de 2 402 Mbit/s. ~3 fois plus rapide que la norme Wi‑Fi 802.11ac 2x2 80 MHz (867 Mbit/s), comme indiqué dans les spécifications de la norme de réseau sans fil IEEE 802.11* et nécessite l'utilisation de routeurs réseau sans fil configurés de manière similaire.",
          "⁷ Réduction de 75&nbsp;% de la latence&nbsp;: d'après les données de simulation Intel (79&nbsp;%) de la norme&nbsp;802.11ax avec et sans OFDMA sur 9&nbsp;clients. La latence moyenne est de 36&nbsp;ms sans OFDMA, 7,6&nbsp;ms avec OFDMA. L'amélioration de latence implique que le routeur 802.11ax (Wi-Fi 6) et tous les clients prennent en charge OFDMA.",
          "⁸ D'après la comparaison du taux de données maximum théorique de la spécification 802.11, 2x2 Wi‑Fi 6/6E 160 MHz (802.11ax) peut offrir 2 402 Mbit/s comparé à 2x2 Wi‑Fi 5 80 MHz (802.11ac) peut offrir 867 Mbit/s, ce qui équivaut à des débits plus rapides.",
          "⁹ Une technologie Wi Fi 6 de pointe : les solutions Intel® Wi Fi 6 (Gig+) prennent en charge les canaux facultatifs de 160 MHz, ce qui permet d'obtenir les plus hauts débits théoriques possibles (2 402 Mbit/s) avec les solutions Wi‑Fi PC 2x2 802.11ax standard. Les produits Intel® Wi Fi 6 (Gig+) haut de gamme permettent d'obtenir des vitesses théoriques maximales deux à quatre fois plus élevées par rapport aux produits Wi Fi PC 2x2 (1 201 Mbit/s) ou 1x1 (600 Mbit/s) 802.11ax standard, qui ne prennent en charge que les canaux obligatoires de 80 MHz.",
          "Disponible sur certains modèles uniquement ; les fonctionnalités varient selon la marque. Pour plus de détails, consultez le fabricant OEM ou le détaillant. Pour plus d'informations sur le Wi‑Fi, consultez intel.fr/wifi6disclaimers.",
          "¹⁰ La technologie Thunderbolt™&nbsp;4, à 40&nbsp;Gbit/s, offre la solution câblée la plus rapide, la plus simple et la plus fiable à n'importe quel écran, station d'accueil ou périphérique de données par rapport aux autres technologies d'E/S de PC, notamment eSATA*, USB* et IEEE&nbsp;1394* FireWire.",
          "¹¹ Nécessite un routeur compatible avec la norme&nbsp;802.11ax prenant en charge OFDMA et plusieurs clients sur le réseau avec prise en charge de la norme&nbsp;AX. Il est possible d'obtenir de meilleurs résultats dans les environnements denses grâce aux fonctionnalités OFDMA prises en charge par les clients et les points d'accès 802.11ax. 2&nbsp;Gbit/s en prenant pour hypothèses environ 70&nbsp;% du débit de données théorique maximal de la spécification IEEE 802.11* par rapport à la norme 802.11ax de 160&nbsp;MHz et 2&nbsp;402&nbsp;Mbit/s.",
          "¹² Tel que mesuré par la charge de travail Lancement d'un document avec activité en arrière-plan, en comparant le processeur Intel® Core™&nbsp;i7‑8565U de 8<sup>e</sup>&nbsp;génération (unité de stockage SSD TLC de 512&nbsp;Go) au processeur Intel® Core™&nbsp;i7‑8565U de 8<sup>e</sup>&nbsp;génération (mémoire Intel® Optane™&nbsp;H10 avec stockage SSD, 32&nbsp;Go + 512&nbsp;Go).",
          "¹³ La technologie Intel® Thread Director intégrée aux processeurs Intel® Core™ de 12<sup>e</sup>&nbsp;génération aide les systèmes d'exploitation à diriger plus intelligemment les charges de travail vers le cœur le plus adapté. Aucune action n'est requise de la part de l'utilisateur. Rendez-vous sur intel.com pour en savoir plus.",
          "¹⁴ Non disponible sur certains processeurs Intel® Core™ de 12<sup>e</sup> génération. L'architecture hybride hautes performances combine deux nouvelles microarchitectures de cœurs sur un seul die de processeur&nbsp;: Performance-cores (P&#8209;cores) et Efficient-cores (E-cores). Certains processeurs Intel® Core™ de 12<sup>e</sup>&nbsp;génération (certains processeurs Intel® Core™&nbsp;i5 de 12<sup>e</sup>&nbsp;génération et inférieure) ne possèdent pas d'architecture hybride hautes performances, mais uniquement des P&#8209;cores.",
          "¹⁵&nbsp;Résultats obtenus à partir d'une charge de travail de lancement de jeu avec le processeur Intel® Core™&nbsp;i7-8750H de 8<sup>e</sup>&nbsp;génération (module de mémoire Intel® Optane™ de 32&nbsp;Go + unité SSD Intel® PCIe* 256&nbsp;Go + disque dur 1&nbsp;To) vs. le processeur Intel® Core™&nbsp;i7-8750H de 8<sup>e</sup>&nbsp;génération (unité SSD Intel® PCIe* 256&nbsp;Go + disque dur 1&nbsp;To). Charge de travail de lancement de jeu - Charge de travail développée par Intel mesurant le temps nécessaire pour lancer Total War*: WARHAMMER* II Build: 5577.0 et atteindre le menu principal avec les vidéos d'intro désactivées (lancement). Configurations utilisées pour tester l'accélération du disque de données avec la mémoire Intel® Optane™&nbsp;: processeur Intel® Core™&nbsp;i7-8750H, PDT PL1&nbsp;=&nbsp;45&nbsp;W, 6&nbsp;cœurs/12&nbsp;threads, jusqu'à 4&nbsp;GHz avec la technologie Turbo sur système OEM de pré-production, carte graphique NVIDIA* GeForce* GTX 1070, 2&nbsp;x&nbsp;4&nbsp;Go de mémoire DDR4, unité SSD Intel® 760p 256&nbsp;Go (PCIe*) + disque dur 1&nbsp;To + mémoire Intel® Optane™ 32&nbsp;Go, système d'exploitation Windows* 10 RS3 Build 1709, MCU 0x84&nbsp;; processeur Intel® Core™&nbsp;i7-8750H, PDT PL1&nbsp;=&nbsp;45&nbsp;W, 6&nbsp;cœurs/12&nbsp;threads, jusqu'à 4&nbsp;GHz avec la technologie Turbo sur système OEM de pré-production, carte graphique NVIDIA* GeForce* GTX 1070, 2&nbsp;x&nbsp;4&nbsp;Go de mémoire DDR4, unité SSD Intel® 760p 256&nbsp;Go (PCIe*) + disque dur 1&nbsp;To, système d'exploitation Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁶&nbsp;Résultats obtenus à partir d'une charge de travail de chargement d'un niveau de jeu avec le processeur Intel® Core™ i7-8750H de 8<sup>e</sup>&nbsp;génération (module de mémoire Intel® Optane™ 32&nbsp;Go) vs. le processeur Intel® Core™ i7-8750H de 8<sup>e</sup>&nbsp;génération (disque dur uniquement). Charge de travail de chargement d'un niveau de jeu - Charge de travail développée par Intel mesurant le temps écoulé entre le menu principal et la fin du chargement du niveau sur Total War*: WARHAMMER* II Build: 5577.0. Configurations utilisées pour tester l'accélération du disque de données avec la mémoire Intel® Optane™&nbsp;: processeur Intel® Core™&nbsp;i7-8750H, PDT PL1&nbsp;=&nbsp;45&nbsp;W, 6&nbsp;cœurs/12&nbsp;threads, jusqu'à 4&nbsp;GHz avec la technologie Turbo sur système OEM de pré-production, carte graphique NVIDIA* GeForce* GTX 1070, 2&nbsp;x&nbsp;4&nbsp;Go de mémoire DDR4, unité SSD Intel® 760p 256&nbsp;Go (PCIe*) + disque dur 1&nbsp;To + mémoire Intel® Optane™ 32&nbsp;Go, système d'exploitation Windows* 10 RS3 Build 1709, MCU 0x84&nbsp;; processeur Intel® Core™&nbsp;i7-8750H, PDT PL1&nbsp;=&nbsp;45&nbsp;W, 6&nbsp;cœurs/12&nbsp;threads, jusqu'à 4&nbsp;GHz avec la technologie Turbo sur système OEM de pré-production, carte graphique NVIDIA* GeForce* GTX 1070, 2&nbsp;x&nbsp;4&nbsp;Go de mémoire DDR4, unité SSD Intel® 760p 256&nbsp;Go (PCIe*) + disque dur 1&nbsp;To, système d'exploitation Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁷Sur les Performance-cores. Les performances varient en fonction de l'utilisation, de la configuration et d'autres facteurs. Plus d'infos sur www.intel.com/PerformanceIndex.",
          "¹⁸Toute modification de la fréquence d'horloge ou de la tension du processeur risque d'endommager ou de réduire la durée de vie du processeur et d'autres composants du système et de nuire à la stabilité et aux performances du système. Les garanties du produit peuvent ne pas s'appliquer en cas de fonctionnement du processeur au-delà de ses caractéristiques techniques nominales. Pour plus de détails, contactez le fabricant de l'ordinateur et des composants.",
          "¹⁹Tel que mesuré par la combinaison inégalée de capacités de sécurité en amont et en aval du système d'exploitation, de protections des applications et des données, et de protections contre les menaces avancées que la plateforme Intel vPro® offre aux entreprises de toutes tailles, ainsi que par l'approche de la sécurité d'Intel dans la conception, la fabrication et la prise en charge des produits. Tous les PC d'entreprise qui s'appuient sur la plateforme Intel vPro® ont été validés en fonction de spécifications rigoureuses, notamment des fonctionnalités de sécurité matérielle uniques. Rendez-vous sur www.intel.com/PerformanceIndex (plateformes) pour plus de détails. Aucun produit ou composant ne saurait être totalement sécurisé.",
          "²⁰Intel® Connectivity Performance Suite est disponible uniquement sur les systèmes Windows*. Tests Wi-Fi Intel Over The Air (OTA) réalisés sur un réseau encombré par rapport à un réseau sans application logicielle ICPS de priorisation du trafic sans fil et de commutation intelligente des points d'accès. Voir www.intel.com/PerformanceIndex (connectivité) pour plus de détails. Les résultats effectifs peuvent varier.",
          "²¹Par rapport à la génération de processeurs précédente.",
          "²² Les cartes graphiques Intel® Arc™ sont les premières du marché à prendre en charge l'encodage AV1 (au premier trimestre 2022). Plus de détails sur intel.com/performanceindex.",
          "²³ Tel que mesuré par les fonctionnalités uniques des designs Intel® Evo™, qui sont également vérifiées pour répondre aux seuils clés de l'expérience utilisateur des PC portables. Test réalisés en février 2022.",
          "²⁴ La solution Intel® Unison™ n'est actuellement disponible que sur les designs Intel® Evo™ éligibles sur les PC sous Windows* et ne s'associe qu'aux téléphones fonctionnant sous Android ou iOS ; tous les appareils doivent exécuter une version prise en charge du système d'exploitation.",
          "²⁵ Les vitesses mémoire maximales sont associées aux configurations 1 DIMM par canal (1DPC). Le chargement d'un module DIMM supplémentaire sur n'importe quel canal peut avoir une incidence sur la vitesse mémoire maximale. Jusqu'à DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 et DDR5-5200 1Rx8, 1Rx16, 2Rx8 sur certains modèles. La capacité de mémoire maximale peut être atteinte avec des configurations 2DPC.",
          "²⁶ Par rapport à une connexion Wi-Fi standard. Voir intel.com/performance-wireless pour plus de détails. Les résultats effectifs peuvent varier.",
          "²⁷L'architecture hybride hautes performances combine deux microarchitectures de cœurs, les Performance-cores (P-cores) et les Efficient-cores (E-cores), sur une seule matrice de processeur. Elle a été introduite pour la première fois sur les processeurs Intel® Core™ de 12<sup>e</sup> génération. Certains processeurs Intel® Core™ de 12<sup>e</sup> génération et plus récents ne possèdent pas d'architecture hybride hautes performances, seulement des P-cores, et offrent la même taille de cache que la génération précédente ; voir ark.intel.com pour plus de détails sur les différents modèles.",
          "²⁸La technologie Intel® Thread Director intégrée au matériel est uniquement fournie dans les configurations d'architecture hybride hautes performances des processeurs Intel® Core™ de 12<sup>e</sup> génération ou plus récents. Le système d'exploitation doit prendre en charge cette fonctionnalité. Les caractéristiques et les fonctionnalités disponibles varient selon le système d'exploitation.",
          "²⁹La technologie Intel® Thread Director intégrée au matériel est uniquement fournie dans les configurations d'architecture hybride hautes performances des processeurs Intel® Core™ de 12<sup>e</sup> et 13<sup>e</sup> génération. Le système d'exploitation doit prendre en charge cette fonctionnalité. Les caractéristiques et les fonctionnalités disponibles varient selon le système d'exploitation.",
          "³⁰ Tel que mesuré par les performances de la plateforme de validation de référence du processeur Intel®&nbsp;Core™ Ultra pour PC portables (Série 2, nom de code : Lunar Lake) par rapport au processeur Intel®&nbsp;Core™ Ultra pour PC portables (Série 1, nom de code : Meteor Lake) sur 3DMark Time Spy. Consultez intel.com/PerformanceIndex pour obtenir plus d'informations. Les résultats effectifs peuvent varier.",
          "³¹Le floutage de l'arrière-plan et le suivi des visages sont disponibles uniquement sur les systèmes Windows*.",
          "³²Les produits Wi-Fi 7 peuvent accéder aux canaux 320 MHz en 6 GHz et aux nouvelles combinaisons de canaux 160 MHz en 5 GHz avec de nouvelles capacités de Puncturing d'unités multi-ressources.",
          "³³Basé sur les spécifications de la norme sans fil IEEE et les débits de données théoriques maximum pour les appareils à 2 flux.",
          "³⁴Les simulations d'environnements de réseaux encombrés des ingénieurs d'Intel indiquent qu'une réduction importante de la latence est possible grâce aux nouvelles capacités Multi-Link Operation (opération multi-liaison) du Wi-Fi 7.",
          "³⁵Charge minimale atteinte à partir du niveau d'arrêt par défaut du fabricant OEM. Les résultats des systèmes individuels peuvent varier. Consultez intel.com/performance-evo pour en savoir plus.",
          "³⁶La bande Wi-Fi de 6 GHz n'est pas disponible dans tous les pays/régions.",
          "³⁷Windows* uniquement",
          "³⁸Charge atteinte à partir du niveau d'arrêt par défaut du fabricant OEM. Pour en savoir plus sur les performances et les résultats des bancs d'essai, rendez-vous sur intel.com/Evo.",
          "³⁹Fait référence aux processeurs Intel®&nbsp;Core™ Ultra (Série 2) pour PC portables, basés sur les fonctionnalités de sécurité matérielle uniques, une combinaison inégalée de capacités de sécurité au-dessus et au-dessous du système d'exploitation, des performances impressionnantes même à une puissance inférieure par rapport à la génération précédente (en septembre 2024). Consultez intel.com/PerformanceIndex pour obtenir plus d'informations. Les&nbsp;résultats effectifs peuvent varier.",
          "⁴⁰ Les applications logicielles sont fournies à titre d'illustration uniquement. Les&nbsp;fonctionnalités d'IA sont susceptibles de nécessiter l'achat, la souscription à un abonnement ou l'activation de logiciels supplémentaires par un fournisseur de plateforme ou de logiciels, ou encore d'avoir des exigences de configuration ou de compatibilité spécifiques. Plus&nbsp;d'informations sur www.intel.com/PerformanceIndex. Les résultats effectifs peuvent varier. ©&nbsp;Intel&nbsp;Corporation.",
          "⁴¹ Les images présentées peuvent avoir été modifiées ou simulées. Les fonctionnalités d'IA sont susceptibles de nécessiter l'achat, la souscription à un abonnement ou l'activation de logiciels supplémentaires par un fournisseur de plateforme ou de logiciels, ou encore d'avoir des exigences de configuration ou de compatibilité spécifiques. Plus de détails sur www.intel.com/AIPC.",
          "⁴² Cette fonctionnalité sera progressivement déployée en avant-première dans le cadre de la dernière mise à jour de Windows* 11 sur certains marchés internationaux. La date de disponibilité dépend de l'appareil et du marché. En savoir plus ici : aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³ La solution Intel® Unison™ est actuellement disponible pour les modèles éligibles. Consultez www.intel.com/PerformanceIndex pour obtenir plus d'informations.",
          "⁴⁴ Fréquence d'images optimisée grâce à Intel® X<sup>e</sup>SS par rapport à la fréquence d'images native mesurée sur Dying Light 2 Stay Human avec le processeur Intel® Core™ Ultra 7 165H. Consultez www.intel.com/PerformanceIndex pour obtenir plus d'informations. Les résultats effectifs peuvent varier.",
          "⁴⁵Tel que mesuré par la consommation énergétique plus faible du SoC du processeur Intel®&nbsp;Core™ Ultra pour PC portables (Série 2, nom de code : Lunar Lake) par rapport à la plateforme de validation de référence du processeur Intel®&nbsp;Core™ Ultra pour PC portables (Série 1, nom de code : Meteor Lake) sur YouTube* 4K 30 images/seconde AV1. Consultez intel.com/PerformanceIndex pour obtenir plus d'informations. Les résultats effectifs peuvent varier.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Intel rejette toute responsabilité en cas de prix incorrect.",
          "*Les autres noms et marques peuvent être revendiqués comme la propriété de tiers.",
          "COPYRIGHT 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES, LES DESIGNS ET MARQUES COMPOSITES CONNEXES SONT DES MARQUES COMMERCIALES D'INFLEXION STUDIOS INC. TOUS DROITS RÉSERVÉS.",
          "Les technologies Intel peuvent nécessiter du matériel, des logiciels ou l'activation de services compatibles.",
          "Les résultats et données de NERO* sont fournis à titre de commodité et d'information uniquement. Intel Corporation décline toute responsabilité en ce qui concerne, par exemple, l'exactitude de l'application NERO*, les résultats et les scores de performances ou les variations potentielles par rapport à d'autres versions téléchargeables de l'application disponibles sur www.nero.com ou sur d'autres sites Web tiers. Les scores du banc d'essai du PC NERO* sont mesurés à l'aide d'ordinateurs, de composants, de logiciels, d'opérations et de fonctions spécifiques. Les résultats peuvent varier en fonction de ces facteurs. Pour l'évaluation d'un produit, il convient de consulter d'autres tests et d'autres sources d'information, notamment pour connaître le comportement de ce produit avec d'autres composants.",
          "Toutes les versions de la plateforme Intel vPro® nécessitent un processeur Intel® Core™ éligible, un système d'exploitation pris en charge, une puce Intel® LAN et/ou RLAN, des améliorations du microprogramme et d'autres composants matériels et logiciels nécessaires pour fournir les cas d'utilisation de manageabilité, les fonctions de sécurité, les performances système et la stabilité qui définissent la plateforme.",
          "Les performances varient en fonction de l'utilisation, de la configuration et d'autres facteurs. Plus d'infos sur www.intel.com/PerformanceIndex.",
          "Intel ne maîtrise et ne vérifie pas les données tierces. Vous devriez consulter d'autres sources pour évaluer leur précision.",
          "© Intel Corporation. Intel, le logo Intel et les autres marques Intel sont des marques commerciales d'Intel Corporation ou de ses filiales. *Les autres noms et marques peuvent être revendiqués comme la propriété de tiers.",
          "Aucun produit ou composant ne saurait être totalement sécurisé en toutes circonstances.",
          "Vos coûts et résultats peuvent varier.",
          "Intel, le logo Intel, Intel Core, Intel Optane, Intel Iris, Thunderbolt et le logo Thunderbolt sont des marques commerciales d'Intel Corporation ou de ses filiales. Les autres noms et marques peuvent être revendiqués comme la propriété de tiers.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Accueil",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Scanner pour comparer",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Tendances",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "Explorez de nouvelles expériences d'IA avec un processeur Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "Explorez de nouvelles expériences d'IA avec un processeur Intel® Core™ Ultra",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "Explorez de nouvelles expériences d'IA avec un processeur Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "Stable Diffusion* : conversion de texte en image",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe* : retouches photo",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "Intel® X<sup>e</sup>SS : upscaling d'image",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee* : Deepfake Detector",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "Effets de collaboration (Teams)",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "Contrôle gestuel dans l'appli iQIYI",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "Synchronisez votre PC avec votre téléphone et recevez vos notifications, vos appels et vos SMS",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "Des graphiques intégrés intenses et éblouissants",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "Microsoft* : menez votre vie comme vous l'entendez",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "Microsoft* : pour toutes vos activités. Tout voir en un clin d'œil, toujours à portée de main",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "Allez plus loin ! Augmentez la vitesse et la fréquence de votre processeur en ajustant les bons paramètres.",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "Améliorez les performances de votre PC de bureau ou PC portable sans sacrifier l'espace de stockage",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "Améliorez les performances de votre PC de bureau ou PC portable sans sacrifier l'espace de stockage",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "OLED Samsung : Un plaisir pour les yeux.",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "Vitesse, latence et fiabilité améliorées grâce à l'optimisation du réseau basée sur l'IA.",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "Mise en réseau de PC digne d'une connexion filaire",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "Mise en réseau de PC digne d'une connexion filaire",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "Débits près de 3&nbsp;fois plus élevés avec Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+)",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "Connectez-vous sans souci de la concurrence",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "Connectivité à l'aide d'un câble véritablement universel",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "Jouez comme un pro : découvrez tout ce qu'Intel fait pour le gaming et les gamers",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "Jouez comme un pro : les champions le deviennent grâce à Intel",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "BIENVENUE",
    "tr_welcomePagetrSubtitle": "Contrôlez ce PC à distance avec votre smartphone",
    "tr_welcomePagembSubtitle": "Bienvenue, découvrez les caractéristiques de ce PC sur votre smartphone",
    "tr_welcomePageBtnText": "Démarrer",
    "tr_welcomePageText": "Découvrez et comparez les détails de ce PC sur votre smartphone",
    "tr_scrollText": "Naviguez sur l'appareil qui est devant vous",
    "tr_toolTipTextc1": "Utilisez ces boutons pour accéder aux différentes pages de l'appareil qui est devant vous.",
    "tr_toolTipTextc2": "Appuyez sur les cartes ci-dessous pour accéder à une page donnant les caractéristiques détaillées de chaque appareil.",
    "tr_toolTipTextc3": "Utilisez ces boutons pour monter et descendre sur l'écran qui est devant vous.",
    "tr_toolTipTextc4": "Désactivez le contrôle à distance pour afficher les caractéristiques de l'appareil sur votre smartphone.",
    "tr_toolTipTextmb1": "Appuyez sur les cartes pour afficher des pages de contenu détaillé.",
    "tr_toolTipTextmb2": "Appuyez pour afficher un résumé de toutes les caractéristiques de l'appareil",
    "tr_toolTipTextmb3": "Appuyez sur le bouton pour scanner le code QR et comparer jusqu'à 4&nbsp;appareils",
    "tr_nextButton": "Suivant",
    "tr_prevButton": "Retour",
    "tr_gotItButton": "COMPRIS",
    "tr_skipButton": "Passer",
    "tr_placeholderText": "*espace réservé",
    "tr_processorHeading": "Processeur",
    "tr_processorSubheading": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 10<sup>e</sup>&nbsp;génération",
    "tr_memorySubheading": "[XX]&nbsp;Go",
    "tr_storageSubheading": "Mémoire Intel® Optane™ de [XX]&nbsp;Go + SSD de [YY]&nbsp;Go",
    "tr_coachmarkProcessor": "Processeur&nbsp;XX",
    "tr_coachmarkGraphics": "Graphiques&nbsp;[XX]",
    "tr_coachmarkMemory": "Valeur spécifiée",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy&nbsp;17T",
    "tr_price": "999,95&nbsp;USD",
    "tr_compare": "Comparer",
    "tr_viewallspecs": "VOIR TOUTES LES CARACTÉRISTIQUES TECHNIQUES",
    "tr_operatingSystem": "SYSTÈME D’EXPLOITATION",
    "tr_AmdProcessorNonIntel": "Processeur AMD*",
    "tr_QualcommProcessorNonIntel": "Processeur Qualcomm*",
    "tr_GBText": "Go",
    "tr_remoteText": "Caractéristiques affichées sur l'écran du PC portable",
    "tr_scanErrorText": "Vous ne pouvez numériser qu'un maximum de 3&nbsp;appareils à la fois. Veuillez&nbsp;supprimer&nbsp;un ou plusieurs appareils.",
    "tr_scanErrorText2": "Vous avez déjà scanné cet appareil, veuillez en scanner un autre.",
    "tr_scanMsg": "Scannez pour ajouter un appareil",
    "tr_backToScan": "Retour à la comparaison",
    "tr_scanToCompare": "Scannez pour comparer",
    "tr_compareDevice": "Comparer des appareils",
    "tr_processorTitle": "PROCESSEUR",
    "tr_graphicTitle": "CARTE GRAPHIQUE",
    "tr_storageTitle": "STOCKAGE",
    "tr_displayTitle": "ÉCRAN",
    "tr_batteryTitle": "BATTERIE",
    "tr_memoryTitle": "MÉMOIRE",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "CONNECTIVITÉ",
    "tr_priceTitle": "PRIX",
    "tr_operatingSystemTitle": "SYSTÈME D'EXPLOITATION",
    "tr_batteryHoursText": "[XX]&nbsp;heures",
    "tr_hrsText": "heures",
    "tr_touchscreeenText": "Écran tactile [screenDiagonal]”",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, écran tactile",
    "tr_FHDTouchScreenText": "FHD, écran tactile",
    "tr_4KTouchscreenText": "4K, écran tactile",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Enregistrer",
    "tr_device_comparison": "Document de comparaison d'appareils [dd]-[mm]-[yyyy]",
    "tr_Save_As_JPEG": "Enregistrer au format JPEG",
    "tr_Save_As_PDF": "Enregistrer au format PDF",
    "tr_Downloading_PDF": "Téléchargement du fichier PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Ouvrir",
    "tr_logo": "logo",
    "tr_laptop_name": "Nom du PC portable",
    "tr_TBText": "To",
    "tr_optaneMemoryTitle": "Mémoire Intel® Optane™",
    "tr_inchUnit": "pouces",
    "tr_wifi1": "Wi‑Fi&nbsp;1",
    "tr_wifi2": "Wi‑Fi&nbsp;2",
    "tr_wifi3": "Wi‑Fi&nbsp;3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "MÉMOIRE INTEL® OPTANE™",
    "tr_Thunderbolt3": "Technologie Thunderbolt™&nbsp;3",
    "tr_Thunderbolt4": "Technologie Thunderbolt™&nbsp;4",
    "tr_processorGraphicsCaps": "PROCESSEUR GRAPHIQUE (GPU)",
    "tr_processorGraphicsSmall": "Processeur graphique (GPU)",
    "tr_graphicsCardCaps": "CARTE GRAPHIQUE DÉDIÉE",
    "tr_graphicsCardSmall": "Carte graphique dédiée",
    "tr_processorTitleCamel": "Processeur",
    "tr_graphicTitleCamel": "Graphiques",
    "tr_storageTitleCamel": "Stockage",
    "tr_displayTitleCamel": "Écran",
    "tr_batteryTitleCamel": "Batterie",
    "tr_memoryTitleCamel": "Mémoire",
    "tr_connectivityTitleCamel": "Connectivité",
    "tr_priceTitleCamel": "Prix",
    "tr_operatingSystemTitleCamel": "Système d'exploitation",
    "tr_viewallspecsCamel": "Voir toutes les caractéristiques",
    "tr_displayText": "[screenDiagonal]”",
    "tr_displayTextResolution": "[screenDiagonal]” [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "Écran tactile [screenDiagonal]” [screenResolutionType]",
    "tr_OS": "SYSTÈME D’EXPLOITATION",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "Caractéristiques techniques du PC",
    "tr_explorePC_text": "Découvrez tout à propos de ce PC",
    "tr_mtlPC_text": "Découvrez ce que signifie vraiment « conçus pour une expérience sans compromis »",
    "tr_optaneMemory": "Mémoire Intel®&nbsp;Optane™",
    "tr_displayText_Display": "ÉCRAN DE [screenDiagonal]”",
    "tr_displayTextResolution_Display": "ÉCRAN [screenResolutionType] DE [screenDiagonal]”",
    "tr_displayTextResolutionTouchscreen_Display": "ÉCRAN TACTILE [screenResolutionType] de [screenDiagonal]”",
    "tr_displayTextTouchscreeenText": "ÉCRAN TACTILE [screenDiagonal]”",
    "tr_learnMoreLabel": "En savoir plus",
    "tr_batteryHoursTextUpperCase": "[XX]&nbsp;HEURES",
    "tr_processorGraphicsIrisXe": "PROCESSEUR INTEL® CORE™ [cpu_fam] DE 11<sup>e</sup>&nbsp;GÉNÉRATION ET PARTIE GRAPHIQUE INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax": "PROCESSEUR INTEL® CORE™ [cpu_fam] DE 11<sup>e</sup>&nbsp;GÉNÉRATION ET PARTIE GRAPHIQUE INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;MAX",
    "tr_Thunderbolt3UpperCase": "TECHNOLOGIE THUNDERBOLT™&nbsp;3",
    "tr_Thunderbolt4UpperCase": "TECHNOLOGIE THUNDERBOLT™&nbsp;4",
    "tr_processorWithArcGraphics": "PROCESSEUR INTEL® CORE™ [cpu_fam] DE 12<sup>e</sup>&nbsp;GÉNÉRATION ET CARTE GRAPHIQUE INTEL® ARC™",
    "tr_processorGraphicsIrisXe12Gen": "PROCESSEUR INTEL® CORE™ [cpu_fam] DE 12<sup>e</sup> GÉNÉRATION ET PARTIE GRAPHIQUE INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax12Gen": "PROCESSEUR INTEL® CORE™ [cpu_fam] DE 12<sup>e</sup>&nbsp;GÉNÉRATION ET PARTIE GRAPHIQUE INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_samsung_oled": "OLED Samsung",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "PROCESSEUR INTEL® CORE™ [cpu_fam] DE 13<sup>e</sup>&nbsp;GÉNÉRATION ET CARTE GRAPHIQUE INTEL® ARC™",
    "tr_processorGraphicsIrisXe13Gen": "PROCESSEUR INTEL® CORE™ [cpu_fam] DE 13<sup>e</sup> GÉNÉRATION ET PARTIE GRAPHIQUE INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax13Gen": "PROCESSEUR INTEL® CORE™ [cpu_fam] DE 13<sup>e</sup>&nbsp;GÉNÉRATION ET PARTIE GRAPHIQUE INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "GRAPHIQUES INTEL® ARC™ A350M",
      "tr_A370M": "GRAPHIQUES INTEL® ARC™ A370M",
      "tr_A730M": "GRAPHIQUES INTEL® ARC™ A730M",
      "tr_A770M": "GRAPHIQUES INTEL® ARC™ A770M",
      "tr_A310": "GRAPHIQUES INTEL® ARC™ A310",
      "tr_A380": "GRAPHIQUES INTEL® ARC™ A380",
      "tr_A580": "GRAPHIQUES INTEL® ARC™ A580",
      "tr_A750": "GRAPHIQUES INTEL® ARC™ A750",
      "tr_A770": "GRAPHIQUES INTEL® ARC™ A770"
    },
    "tr_memoryValue": "[XX]&nbsp;Go RAM",
    "tr_memoryWithOptaneDesc": "[Value1]&nbsp;Go de mémoire = [Value2]&nbsp;Go de mémoire Intel®&nbsp;Optane™&nbsp;+ [Value3]&nbsp;Go de RAM",
    "tr_textGBGB": "SSD&nbsp;de&nbsp;[convertedSSD]&nbsp;Go&nbsp;+ Disque&nbsp;dur de&nbsp;[convertedHDD]&nbsp;Go",
    "tr_textGBTB": "SSD&nbsp;de&nbsp;[convertedSSD]&nbsp;Go&nbsp;+ Disque&nbsp;dur de&nbsp;[convertedHDD]&nbsp;To",
    "tr_textTBGB": "SSD&nbsp;de&nbsp;[convertedSSD]&nbsp;To&nbsp;+ Disque&nbsp;dur de&nbsp;[convertedHDD]&nbsp;Go",
    "tr_textTBTB": "SSD&nbsp;de&nbsp;[convertedSSD]&nbsp;To&nbsp;+ Disque&nbsp;dur de&nbsp;[convertedHDD]&nbsp;To",
    "tr_textGBSSD": "SSD&nbsp;de&nbsp;[convertedSSD]&nbsp;Go",
    "tr_textTBSSD": "SSD&nbsp;de&nbsp;[convertedSSD]&nbsp;To",
    "tr_textGBHDD": "Disque&nbsp;dur de&nbsp;[convertedHDD]&nbsp;Go",
    "tr_textTBHDD": "Disque&nbsp;dur de&nbsp;[convertedHDD]&nbsp;To",
    "tr_textStorageWithOptaneGB": "Mémoire Intel®&nbsp;Optane™ de&nbsp;[XX]&nbsp;Go +&nbsp;SSD&nbsp;de&nbsp;[convertedSSD]&nbsp;Go",
    "tr_textStorageWithOptaneTB": "Mémoire Intel®&nbsp;Optane™ de&nbsp;[XX]&nbsp;Go +&nbsp;SSD&nbsp;de&nbsp;[convertedSSD]&nbsp;To",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Édition Familiale Basique",
      "tr_OS3": "Microsoft* Windows* 7 Édition Familiale Premium",
      "tr_OS4": "Windows* 7 Professionnel",
      "tr_OS5": "Windows* 7 Entreprise",
      "tr_OS6": "Windows* 7 Édition Intégrale",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Entreprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Entreprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Famille",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Entreprise",
      "tr_OS17": "Windows* 11 Famille",
      "tr_OS18": "Windows* 11 Professionnel",
      "tr_OS19": "Windows* 11 Entreprise",
      "tr_OS20": "Windows* 10 Famille en mode S",
      "tr_OS21": "Windows* 10 Professionnel en mode S",
      "tr_OS22": "Windows* 10 Entreprise en mode S",
      "tr_OS23": "Windows* 11 Famille en mode S",
      "tr_OS24": "Windows* 11 Professionnel en mode S",
      "tr_OS25": "Windows* 11 Entreprise en mode S"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Carte graphique Intel® Arc™ A310",
      "tr_GR387": "Carte graphique Intel® Arc™ A350M",
      "tr_GR388": "Carte graphique Intel® Arc™ A370M",
      "tr_GR389": "Carte graphique Intel® Arc™ A380",
      "tr_GR390": "Carte graphique Intel® Arc™ A530M",
      "tr_GR391": "Carte graphique Intel® Arc™ A550M",
      "tr_GR392": "Carte graphique Intel® Arc™ A570M",
      "tr_GR393": "Carte graphique Intel® Arc™ A580",
      "tr_GR394": "Carte graphique Intel® Arc™ A730M",
      "tr_GR395": "Carte graphique Intel® Arc™ A750",
      "tr_GR396": "Carte graphique Intel® Arc™ A770",
      "tr_GR397": "Carte graphique Intel® Arc™ A770M",
      "tr_GR398": "Carte graphique Intel® Arc™",
      "tr_GR399": "Carte graphique Intel® Arc™ Pro A30M",
      "tr_GR400": "Carte graphique Intel® Arc™ Pro A40/A50",
      "tr_GR401": "Carte graphique Intel® Arc™ Pro A60",
      "tr_GR402": "Carte graphique Intel® Arc™ Pro A60M",
      "tr_GR403": "Technologie graphique Intel®",
      "tr_GR404": "Partie graphique HD Intel® 2000",
      "tr_GR405": "Partie graphique HD Intel® 2500",
      "tr_GR406": "Partie graphique HD Intel® 3000",
      "tr_GR407": "Partie graphique HD Intel® 400",
      "tr_GR408": "Partie graphique HD Intel® 4000",
      "tr_GR409": "Partie graphique HD Intel® 405",
      "tr_GR410": "Partie graphique HD Intel® 4200",
      "tr_GR411": "Partie graphique HD Intel® 4400",
      "tr_GR412": "Partie graphique HD Intel® 4600",
      "tr_GR413": "Partie graphique HD Intel® 500",
      "tr_GR414": "Partie graphique HD Intel® 5000",
      "tr_GR415": "Partie graphique HD Intel® 505",
      "tr_GR416": "Partie graphique HD Intel® 510",
      "tr_GR417": "Partie graphique HD Intel® 515",
      "tr_GR418": "Partie graphique HD Intel® 520",
      "tr_GR419": "Partie graphique HD Intel® 530",
      "tr_GR420": "Partie graphique HD Intel® 5300",
      "tr_GR421": "Partie graphique HD Intel® 5500",
      "tr_GR422": "Partie graphique HD Intel® 5600",
      "tr_GR423": "Partie graphique HD Intel® 6000",
      "tr_GR424": "Partie graphique HD Intel® 610",
      "tr_GR425": "Partie graphique HD Intel® 615",
      "tr_GR426": "Partie graphique HD Intel® 620",
      "tr_GR427": "Partie graphique HD Intel® 630",
      "tr_GR428": "Partie graphique HD Intel®",
      "tr_GR429": "Partie graphique Intel® Iris® 5100",
      "tr_GR430": "Partie graphique Intel® Iris® 540",
      "tr_GR431": "Partie graphique Intel® Iris® 550",
      "tr_GR432": "Partie graphique Intel® Iris® 6100",
      "tr_GR433": "Partie graphique Intel® Iris® Plus 640",
      "tr_GR434": "Partie graphique Intel® Iris® Plus 645",
      "tr_GR435": "Partie graphique Intel® Iris® Plus 650",
      "tr_GR436": "Partie graphique Intel® Iris® Plus 655",
      "tr_GR437": "Partie graphique Intel® Iris® Plus",
      "tr_GR438": "Partie graphique Intel® Iris® Pro 5200",
      "tr_GR439": "Partie graphique Intel® Iris® Pro 580",
      "tr_GR440": "Partie graphique Intel® Iris® Pro 6200",
      "tr_GR441": "Partie graphique Intel® Iris® X<sup>e</sup>",
      "tr_GR442": "Partie graphique Intel® Iris® X<sup>e</sup> MAX A220M",
      "tr_GR443": "Partie graphique Intel® Iris® X<sup>e</sup> MAX",
      "tr_GR444": "Partie graphique intégrée Intel®",
      "tr_GR445": "Partie graphique UHD Intel® 600",
      "tr_GR446": "Partie graphique UHD Intel® 605",
      "tr_GR447": "Partie graphique UHD Intel® 610",
      "tr_GR448": "Partie graphique UHD Intel® 615",
      "tr_GR449": "Partie graphique UHD Intel® 617",
      "tr_GR450": "Partie graphique UHD Intel® 620",
      "tr_GR451": "Partie graphique UHD Intel® 630",
      "tr_GR452": "Partie graphique UHD Intel® 710",
      "tr_GR453": "Partie graphique UHD Intel® 730",
      "tr_GR454": "Partie graphique UHD Intel® 770",
      "tr_GR455": "Partie graphique UHD Intel®",
      "tr_GR456": "Partie graphique UHD Intel®",
      "tr_GR457": "Partie graphique UHD Intel®",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 avec design Max-Q",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti avec design Max-Q",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 avec design Max-Q",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 avec design Max-Q",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 avec design Max-Q",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 avec design Max-Q",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti avec design Max-Q",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti avec design Max-Q",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 avec design Max-Q",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 avec design Max-Q",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super avec design Max-Q",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 avec design Max-Q",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super avec design Max-Q",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 avec design Max-Q",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 avec design Max-Q",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 avec design Max-Q",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 avec design Max-Q",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 avec design Max-Q",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 avec design Max-Q",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 avec design Max-Q",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 avec design Max-Q",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 avec design Max-Q",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 avec design Max-Q",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 génération Ada",
      "tr_GR773": "NVIDIA* RTX 3000 génération Ada",
      "tr_GR774": "NVIDIA* RTX 3500 génération Ada",
      "tr_GR775": "NVIDIA* RTX 4000 génération Ada",
      "tr_GR776": "NVIDIA* RTX 4000 SFF génération Ada",
      "tr_GR777": "NVIDIA* RTX 4500 génération Ada",
      "tr_GR778": "NVIDIA* RTX 5000 génération Ada",
      "tr_GR779": "NVIDIA* RTX 5880 génération Ada",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 génération Ada",
      "tr_GR811": "NVIDIA* RTX 1000 génération Ada",
      "tr_GR812": "Carte graphique Intel® Arc™",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "AMD Radeon* Pro W7900 à double emplacement"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Acheter",
    "tr_textTryagain": "Recommencer",
    "tr_textBuynowErr": "L'option Acheter n'est pas disponible actuellement en raison d'une interruption de la connexion",
    "tr_chatbotPlaceholder_text": "Demandez-moi tout ce que vous voulez sur l'appareil",
    "tr_deviceSpecs_text": "Caractéristiques techniques de l'appareil"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 avec Intel®&nbsp;Hybrid Technology"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 avec Intel®&nbsp;Hybrid Technology"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Jouez avec Intel® et profitez d'une expérience de jeu plus puissante",
                "tr_subtitle": "Plongez dans l'univers de Marvel's Avengers* grâce aux optimisations PC Intel dans le jeu.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Découvrez les Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Là où les meilleurs gamers du monde viennent s’affronter",
                "tr_subtitle": "La plus longue série d'événements de l'histoire des jeux vidéo, les Intel® Extreme Masters établit les normes des e-sport.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Jouez comme un pro",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Intel du côté des champions.",
                "tr_subtitle": "Après avoir remporté le championnat nord-américain 2021 de la ligue Rainbow Six Siege*, les Soniqs reviennent sur leur parcours de jeux vidéo, l'avenir des e-sports et leur partenariat avec Intel.",
                "tr_pretitle": "Présentation des Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "Prêt pour la RV",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Là où la RV prend vie",
                "tr_subtitle": "Les expériences de réalité virtuelle les plus incroyables, réalistes et immersives se produisent sur ordinateur. Grâce aux tout nouveaux processeurs Intel®&nbsp;Core™, vous pouvez être qui vous voulez, aller où vous voulez et jouer dans une autre dimension.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Jouez à plus de 100&nbsp;jeux de console de grande qualité comme Sea of Thieves* et Psychonauts*&nbsp;2 sur votre PC Windows*, votre téléphone portable et votre tablette avec un abonnement Xbox* Game Pass Ultimate et une manette compatible.",
                "titleArray": [
                  {
                    "tr_title": "Jouez ensemble sur divers appareils",
                    "tr_subtitle": "Plongez au cœur de la Xbox* avec une communauté de millions de joueurs prêts à jouer ensemble. Connectez-vous et jouez avec d'autres personnes à partir d'une bibliothèque de jeux partagée, qu'elles soient à l'autre bout du monde ou assises juste à côté de vous."
                  },
                  {
                    "tr_title": "Jeux vidéo pour PC Windows*",
                    "tr_subtitle": "Jouez à un catalogue de jeux de console depuis le Cloud en utilisant l'application Xbox* pour Windows* et une manette compatible."
                  },
                  {
                    "tr_title": "Prise en main facile",
                    "tr_subtitle": "Profitez des jeux Xbox* dans plus d'endroits. Jouez à plus de 100&nbsp;titres sur votre PC, votre téléphone portable et votre tablette, que vous soyez à la maison pendant que votre téléviseur est utilisé, dans un café ou n'importe où ailleurs avec une connexion Internet haut débit."
                  },
                  {
                    "tr_title": "Le plaisir sans manette",
                    "tr_subtitle": "Les jeux vidéo à commande tactile de la Xbox* offrent de nouvelles façons de se lancer et de jouer, tout en offrant une expérience familière et un niveau de jeu auquel vous êtes habitué avec une manette physique."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Gros plan",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Plus de 100 expériences d'IA",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Plus de contrôle, moins de toucher",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Restez dans le cadre, quel que soit l'environnement",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Créativité",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Corrigez l'éclairage comme un pro",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Ajoutez facilement des effets plus grands que nature",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Remixez des morceaux grâce à la puissance de l'IA",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productivité",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Boostez votre productivité et renforcez votre confidentialité",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Plus de temps loin de la prise",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Votre monde. Sur tous vos appareils.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Divertissement",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Streaming fluide, même sur batterie",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Une expérience de gaming rapide et immersive",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Gros plan",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Plus de 100 expériences d'IA",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Appropriez-vous vos morceaux préférés",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Vos idées prennent vie en temps réel",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Créativité",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Montage ultra-rapide",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Pour que chaque photo soit parfaite",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productivité",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Boostez votre productivité et renforcez votre confidentialité",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Plus d'efficacité. Plus de liberté.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Votre monde. Sur tous vos appareils.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Divertissement",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Streaming HD fluide, même sur batterie",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Des jeux plus immersifs, où que vous soyez",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Plus créatif",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Retouches photo simplifiées",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Créez des photos dignes d'être encadrées en quelques secondes",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "L'IA donne vie à vos idées en quelques mots.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Plus performant",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Notre ingrédient secret pour des jeux plus fluides",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Plus de temps loin de la prise",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-immersif",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Plus productif",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Votre compagnon IA au quotidien",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Appels vidéo parfaits",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Votre monde. Sur tous vos appareils.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Plus sûr",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Des protections intégrées pour renforcer la sécurité et la confidentialité de vos données",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Plus créatif",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Retouches photo simplifiées",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "IA. Créez votre montage en un clin d'œil.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Retranscrivez rapidement les paroles de vos chansons préférées",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Gagnez du temps sur vos montages grâce à l'IA",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Plus performant",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Notre ingrédient secret pour des jeux plus fluides",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Plus de temps loin de la prise",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-immersif",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Plus productif",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Votre compagnon IA au quotidien",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Appels vidéo parfaits",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Votre monde. Sur tous vos appareils.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Plus sûr",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Des protections intégrées pour renforcer la sécurité et la confidentialité de vos données",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Tous vos loisirs. Dans&nbsp;un&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Pour un PC qui fait aussi office de centre de loisirs, vous aurez besoin d'un espace de stockage suffisant pour vos chansons, vidéos et jeux préférés, ainsi que d'un tandem processeur/écran adapté pour créer des images immersives. Ce PC dispose des trois.",
                "titleArray": [
                  {
                    "tr_title": "Prêt pour la nouvelle génération d'images",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Créez votre bibliothèque numérique",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Une fenêtre sur vos loisirs",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Jeux vidéo et streaming fluides",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Créez votre bibliothèque numérique",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Une fenêtre sur vos loisirs",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Soyez plus productif. Partout.",
              "tr_tileTitle1": "Faites plus de choses. Plus vite.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Ce PC offre un équilibre parfait entre puissance et portabilité. Outre son processeur conçu pour les ordinateurs fins et légers, ce PC présente aussi la connectivité Wi&#8209;Fi nécessaire pour rester connecté et l'autonomie appropriée pour rester sur batterie.",
                "tr_subtitle1": "Ce PC présente un équilibre parfait entre puissance et performances pour vous aider à accomplir plus de tâches en moins de temps. Ensemble, un processeur puissant accéléré par une mémoire de grande capacité et une connexion Wi-Fi ultra-rapide simplifieront vos tâches quotidiennes.",
                "tr_subtitle2": "Ce PC offre un équilibre parfait entre puissance et portabilité. Outre son processeur conçu pour les ordinateurs fins et légers, ce PC dispose d'une connectivité Wi‑Fi nécessaire pour rester connecté ainsi qu'une mémoire adaptée au multitâche.",
                "titleArray": [
                  {
                    "tr_title": "Conçu pour en faire plus",
                    "tr_title1": "Des performances intelligentes qui comptent",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Meilleures connexions de leur catégorie",
                    "tr_title1": "Connectez-vous à ce qui est important",
                    "tr_title2": "Une avancée importante dans le Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Restez plus longtemps sur batterie",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Jonglez entre les programmes comme un pro",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "La liberté de créer",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "La création de contenus représente l'une des tâches les plus exigeantes pour votre PC. Heureusement, ces composants rationalisent votre processus créatif.",
                "titleArray": [
                  {
                    "tr_title": "Création plus intelligente",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Donnez vie à vos images",
                    "tr_title1": "Libérez votre créativité",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Mémoire pour le multitâche",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Explorer l'intérieur de&nbsp;ce&nbsp;PC",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Voici les trois principaux composants à surveiller quand vous choisissez un nouveau PC. En décidant comment vous allez utiliser votre nouveau PC, vous déterminerez plus facilement la place de ces trois composants clés dans vos besoins. Ces trois éléments sont&nbsp;:",
                "titleArray": [
                  {
                    "tr_title": "Performances pour les jeux vidéo et le streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Stockage",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Mémoire",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Tous vos loisirs. Dans&nbsp;un&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Pour un PC qui fait aussi office de centre de loisirs, vous aurez besoin d'un espace de stockage suffisant pour vos chansons, vidéos et jeux préférés, ainsi que d'un tandem processeur/écran adapté pour créer des images immersives. Ce PC dispose des trois.",
                "titleArray": [
                  {
                    "tr_title": "Prêt pour la nouvelle génération d'images",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Créez votre bibliothèque numérique",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Une fenêtre sur vos loisirs",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Jeux vidéo et streaming fluides",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Créez votre bibliothèque numérique",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Une fenêtre sur vos loisirs",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mémoire",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Écran",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Multitâche à grande vitesse",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITÂCHE À GRANDE VITESSE",
                "tr_subtitle": "Faites-en plus sans ralentir",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour le multitâche&nbsp;:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut jongler entre plusieurs programmes à la fois."
                  },
                  {
                    "tr_title": "Un Wi-Fi qui est rapide, puissant et fiable."
                  },
                  {
                    "tr_title": "Une mémoire qui permet d'alterner de manière fluide entre les applications."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chat vidéo",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHAT VIDÉO",
                "tr_subtitle": "Des connexions claires comme de l'eau de roche",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour les discussions vidéo&nbsp;:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut simultanément enregistrer et partager des images."
                  },
                  {
                    "tr_title": "Un Wi-Fi qui est rapide, puissant et fiable."
                  },
                  {
                    "tr_title": "Une webcam qui vous aidera à communiquer très clairement."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Prêt pour la RV",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Là où la RV prend vie",
                "tr_subtitle": "Que faut-il pour créer un monde entièrement virtuel&nbsp;? Sur un PC, le processeur, la carte graphique et la mémoire doivent être coordonnés.",
                "titleArray": [
                  {
                    "tr_title": "La source des mondes virtuels",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "La réalité se trouve dans les détails",
                    "tr_title1": "Plongez au cœur d'expériences visuelles immersives",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Montage vidéo",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MONTAGE VIDÉO",
                "tr_subtitle": "La magie du cinéma",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour le montage vidéo&nbsp;:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut traiter des fichiers volumineux."
                  },
                  {
                    "tr_title": "Une mémoire qui rend les logiciels de montage rapides et réactifs."
                  },
                  {
                    "tr_title": "Des graphiques qui réduisent les délais de rendu."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Multitâche à grande vitesse",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITÂCHE À GRANDE VITESSE",
                "tr_subtitle": "Faites-en plus sans ralentir",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour le multitâche&nbsp;:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut jongler entre plusieurs programmes à la fois."
                  },
                  {
                    "tr_title": "Un Wi-Fi qui est rapide, puissant et fiable."
                  },
                  {
                    "tr_title": "Une mémoire qui permet d'alterner de manière fluide entre les applications."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Prêt pour la RV",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Là où la RV prend vie",
                "tr_subtitle": "Que faut-il pour créer un monde entièrement virtuel&nbsp;? Sur un PC, le processeur, la carte graphique et la mémoire doivent être coordonnés.",
                "titleArray": [
                  {
                    "tr_title": "La source des mondes virtuels",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "La réalité se trouve dans les détails",
                    "tr_title1": "Plongez au cœur d'expériences visuelles immersives",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Modélisation 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MODÉLISATION 3D",
                "tr_subtitle": "Des créations d'un autre niveau",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour la modélisation&nbsp;3D&nbsp;:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut gérer des logiciels de création avancés."
                  },
                  {
                    "tr_title": "Une mémoire qui peut rapidement ouvrir des fichiers volumineux."
                  },
                  {
                    "tr_title": "Des graphiques puissants pour la création et la retouche d'images."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Collaboration en temps réel",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLLABORATION EN TEMPS RÉEL",
                "tr_subtitle": "Travaillez n'importe&nbsp;où",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour la collaboration en ligne&nbsp;:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut exécuter des logiciels de bureautique."
                  },
                  {
                    "tr_title": "Un Wi‑Fi sur lequel vous pouvez compter."
                  },
                  {
                    "tr_title": "Une webcam qui enregistre en HD éclatante."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Prêt pour la RV",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Là où la RV prend vie",
                "tr_subtitle": "Que faut-il pour créer un monde entièrement virtuel&nbsp;? Sur un PC, le processeur, la carte graphique et la mémoire doivent être coordonnés.",
                "titleArray": [
                  {
                    "tr_title": "La source des mondes virtuels",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "La réalité se trouve dans les détails",
                    "tr_title1": "Plongez au cœur d'expériences visuelles immersives",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "PC portables Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Les PC portables aussi fins et légers ne sont pas censés être aussi puissants",
                "tr_subtitle": "Conçus pour des performances mobiles, les PC portables Intel® Evo™ incroyablement fins et légers offrent la vitesse, les graphiques et l'autonomie dont vous avez besoin.",
                "titleArray": [
                  {
                    "tr_title": "Parce que les portables sont au cœur de votre quotidien, ils sont au cœur de nos plus grandes innovations.",
                    "tr_subtitle": "Utilisez votre écran tactile pour explorer, faire&nbsp;pivoter&nbsp;et&nbsp;zoomer."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Sortie de veille instantanée",
                    "tr_subtitle": "Votre PC sort de veille et est prêt&nbsp;à&nbsp;l'emploi en moins d'une seconde."
                  },
                  {
                    "tr_title": "Images immersives",
                    "tr_subtitle": "Un bel écran donne vie à vos images avec&nbsp;une&nbsp;résolution nette et des couleurs vives."
                  },
                  {
                    "tr_title": "Travaillez rapidement",
                    "tr_subtitle": "Profitez des performances ultra-réactives des processeurs Intel® Core™ de 11<sup>e</sup>&nbsp;génération."
                  },
                  {
                    "tr_title": "Travaillez plus longtemps sur batterie",
                    "tr_subtitle": "Gardez le rythme avec un PC qui optimise l'autonomie de votre batterie et se recharge en un instant."
                  },
                  {
                    "tr_title": "Connectivité à l'aide d'un câble universel",
                    "tr_subtitle": "Permet de brancher d'autres appareils à votre PC de manière simple, rapide et sécurisée, grâce à la technologie Thunderbolt™&nbsp;4<sup>10</sup>."
                  },
                  {
                    "tr_title": "Des connexions rapides et fiables",
                    "tr_subtitle": "Participez à une visioconférence ou partagez des fichiers avec une connexion sur laquelle vous pouvez compter, à l'aide de Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+)<sup>1</sup>."
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "PC portables Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Un PC portable fin et léger sans compromis.",
                "tr_subtitle": "Les PC portables Intel® Evo™ sont conçus pour vous offrir des performances mobiles et la meilleure expérience possible<sup>2</sup>, quelle que soit votre activité.",
                "titleArray": [
                  {
                    "tr_title": "Parce que les portables sont au cœur de votre quotidien, ils sont au cœur de nos plus grandes innovations.",
                    "tr_subtitle": "Utilisez votre écran tactile pour explorer, faire&nbsp;pivoter&nbsp;et&nbsp;zoomer."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Sortie de veille instantanée",
                    "tr_subtitle": "Votre PC sort de veille et est prêt&nbsp;à&nbsp;l'emploi en moins d'une seconde."
                  },
                  {
                    "tr_title": "Images immersives",
                    "tr_subtitle": "Un bel écran donne vie à vos images avec&nbsp;une&nbsp;résolution nette et des couleurs vives."
                  },
                  {
                    "tr_title": "Des performances révolutionnaires",
                    "tr_subtitle": "Bénéficiez d'une puissance supplémentaire là où vous en avez le plus besoin grâce aux performances intelligentes des processeurs Intel®&nbsp;Core™ de 12<sup>e</sup> génération."
                  },
                  {
                    "tr_title": "Travaillez plus longtemps sur batterie",
                    "tr_subtitle": "Gardez le rythme avec un PC qui optimise l'autonomie de votre batterie et se recharge en un instant."
                  },
                  {
                    "tr_title": "Connectivité à l'aide d'un câble universel",
                    "tr_subtitle": "Permet de brancher d'autres appareils à votre PC de manière simple, rapide et sécurisée, grâce à la technologie Thunderbolt™&nbsp;4<sup>10</sup>."
                  },
                  {
                    "tr_title": "Des connexions rapides et fiables",
                    "tr_subtitle": "Chattez en vidéo ou partagez des fichiers avec une connexion sur laquelle vous pouvez compter grâce à la technologie Intel® Wi-Fi 6E (Gig+)<sup>1</sup>."
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "PC portables Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Les PC portables aussi fins et légers ne sont pas censés être aussi puissants",
                "tr_subtitle": "Conçus pour des performances mobiles, les PC portables Intel® Evo™ incroyablement fins et légers offrent la vitesse, les graphiques et l'autonomie dont vous avez besoin.",
                "titleArray": [
                  {
                    "tr_title": "Parce que les portables sont au cœur de votre quotidien, ils sont au cœur de nos plus grandes innovations.",
                    "tr_subtitle": "Utilisez votre écran tactile pour explorer, faire&nbsp;pivoter&nbsp;et&nbsp;zoomer."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Sortie de veille instantanée",
                    "tr_subtitle": "Votre PC sort de veille et est prêt&nbsp;à&nbsp;l'emploi en moins d'une seconde."
                  },
                  {
                    "tr_title": "Images immersives",
                    "tr_subtitle": "Un bel écran donne vie à vos images avec&nbsp;une&nbsp;résolution nette et des couleurs vives."
                  },
                  {
                    "tr_title": "Travaillez rapidement",
                    "tr_subtitle": "Profitez des performances ultra-réactives des processeurs Intel® Core™ de 11<sup>e</sup>&nbsp;génération."
                  },
                  {
                    "tr_title": "Travaillez plus longtemps sur batterie",
                    "tr_subtitle": "Gardez le rythme avec un PC qui optimise l'autonomie de votre batterie et se recharge en un instant."
                  },
                  {
                    "tr_title": "Connectivité à l'aide d'un câble universel",
                    "tr_subtitle": "Permet de brancher d'autres appareils à votre PC de manière simple, rapide et sécurisée, grâce à la technologie Thunderbolt™&nbsp;4<sup>10</sup>."
                  },
                  {
                    "tr_title": "Des connexions rapides et fiables",
                    "tr_subtitle": "Participez à une visioconférence ou partagez des fichiers avec une connexion sur laquelle vous pouvez compter, à l'aide de Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+)<sup>1</sup>."
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "PC portables Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Un PC portable fin et léger sans compromis.",
                "tr_subtitle": "Les PC portables Intel® Evo™ sont conçus pour vous offrir des performances mobiles et la meilleure expérience possible<sup>2</sup>, quelle que soit votre activité.",
                "titleArray": [
                  {
                    "tr_title": "Parce que les portables sont au cœur de votre quotidien, ils sont au cœur de nos plus grandes innovations.",
                    "tr_subtitle": "Utilisez votre écran tactile pour explorer, faire&nbsp;pivoter&nbsp;et&nbsp;zoomer."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Sortie de veille instantanée",
                    "tr_subtitle": "Votre PC sort de veille et est prêt&nbsp;à&nbsp;l'emploi en moins d'une seconde."
                  },
                  {
                    "tr_title": "Images immersives",
                    "tr_subtitle": "Un bel écran donne vie à vos images avec&nbsp;une&nbsp;résolution nette et des couleurs vives."
                  },
                  {
                    "tr_title": "Des performances révolutionnaires",
                    "tr_subtitle": "Bénéficiez d'une puissance supplémentaire là où vous en avez le plus besoin grâce aux performances intelligentes des processeurs Intel®&nbsp;Core™ de 12<sup>e</sup> génération."
                  },
                  {
                    "tr_title": "Travaillez plus longtemps sur batterie",
                    "tr_subtitle": "Gardez le rythme avec un PC qui optimise l'autonomie de votre batterie et se recharge en un instant."
                  },
                  {
                    "tr_title": "Connectivité à l'aide d'un câble universel",
                    "tr_subtitle": "Permet de brancher d'autres appareils à votre PC de manière simple, rapide et sécurisée, grâce à la technologie Thunderbolt™&nbsp;4<sup>10</sup>."
                  },
                  {
                    "tr_title": "Des connexions rapides et fiables",
                    "tr_subtitle": "Chattez en vidéo ou partagez des fichiers avec une connexion sur laquelle vous pouvez compter grâce à la technologie Intel® Wi-Fi 6E (Gig+)<sup>1</sup>."
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "PC portables Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Un PC portable fin et léger sans compromis.",
                "tr_subtitle": "Les PC portables Intel® Evo™ sont conçus pour vous offrir des performances mobiles et la meilleure expérience possible<sup>2</sup>, quelle que soit votre activité.",
                "titleArray": [
                  {
                    "tr_title": "Parce que les portables sont au cœur de votre quotidien, ils sont au cœur de nos plus grandes innovations.",
                    "tr_subtitle": "Utilisez votre écran tactile pour explorer, faire&nbsp;pivoter&nbsp;et&nbsp;zoomer."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Sortie de veille instantanée",
                    "tr_subtitle": "Votre PC sort de veille et est prêt&nbsp;à&nbsp;l'emploi en moins d'une seconde."
                  },
                  {
                    "tr_title": "Images immersives",
                    "tr_subtitle": "Un bel écran donne vie à vos images avec&nbsp;une&nbsp;résolution nette et des couleurs vives."
                  },
                  {
                    "tr_title": "Des performances révolutionnaires",
                    "tr_subtitle": "Bénéficiez d'une puissance supplémentaire là où vous en avez le plus besoin grâce aux performances intelligentes des processeurs Intel®&nbsp;Core™ de 13<sup>e</sup> génération."
                  },
                  {
                    "tr_title": "Travaillez plus longtemps sur batterie",
                    "tr_subtitle": "Gardez le rythme avec un PC qui optimise l'autonomie de votre batterie et se recharge en un instant."
                  },
                  {
                    "tr_title": "Connectivité à l'aide d'un câble universel",
                    "tr_subtitle": "Permet de brancher d'autres appareils à votre PC de manière simple, rapide et sécurisée, grâce à la technologie Thunderbolt™&nbsp;4<sup>10</sup>."
                  },
                  {
                    "tr_title": "Des connexions rapides et fiables",
                    "tr_subtitle": "Chattez en vidéo ou partagez des fichiers avec une connexion sur laquelle vous pouvez compter grâce à la technologie Intel® Wi-Fi 6E (Gig+)<sup>1</sup>."
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "PC portables Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Un PC portable fin et léger sans compromis.",
                "tr_subtitle": "Les PC portables Intel® Evo™ sont conçus pour vous offrir des performances mobiles et la meilleure expérience possible<sup>2</sup>, quelle que soit votre activité.",
                "titleArray": [
                  {
                    "tr_title": "Parce que les portables sont au cœur de votre quotidien, ils sont au cœur de nos plus grandes innovations.",
                    "tr_subtitle": "Utilisez votre écran tactile pour explorer, faire&nbsp;pivoter&nbsp;et&nbsp;zoomer."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Sortie de veille instantanée",
                    "tr_subtitle": "Votre PC sort de veille et est prêt&nbsp;à&nbsp;l'emploi en moins d'une seconde."
                  },
                  {
                    "tr_title": "Images immersives",
                    "tr_subtitle": "Un bel écran donne vie à vos images avec&nbsp;une&nbsp;résolution nette et des couleurs vives."
                  },
                  {
                    "tr_title": "Des performances révolutionnaires",
                    "tr_subtitle": "Bénéficiez d'une puissance supplémentaire là où vous en avez le plus besoin grâce aux performances intelligentes des processeurs Intel®&nbsp;Core™ de 13<sup>e</sup> génération."
                  },
                  {
                    "tr_title": "Travaillez plus longtemps sur batterie",
                    "tr_subtitle": "Gardez le rythme avec un PC qui optimise l'autonomie de votre batterie et se recharge en un instant."
                  },
                  {
                    "tr_title": "Connectivité à l'aide d'un câble universel",
                    "tr_subtitle": "Permet de brancher d'autres appareils à votre PC de manière simple, rapide et sécurisée, grâce à la technologie Thunderbolt™&nbsp;4<sup>10</sup>."
                  },
                  {
                    "tr_title": "Des connexions rapides et fiables",
                    "tr_subtitle": "Chattez en vidéo ou partagez des fichiers avec une connexion sur laquelle vous pouvez compter grâce à la technologie Intel® Wi-Fi 6E (Gig+)<sup>1</sup>."
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Gros plan",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Intel® Evo™ Edition, qu'est-ce que c'est ?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Pourquoi Intel®&nbsp;Evo™&nbsp;Edition ?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Créativité",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Plus de créativité, où que vous soyez",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Des retouches photo en un clin d'œil",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Des créations artistiques originales en quelques mots",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productivité",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Optimisation de votre productivité avec Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "L'IA détecte les deepfakes avant de les partager",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Votre monde. Sur tous vos appareils.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilité",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Dites bonjour au contrôle gestuel",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Une autonomie qui ne vous ralentit pas",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Maintien automatique de la connexion",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Gros plan",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Intel® Evo™ Edition, qu'est-ce que c'est ?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Pourquoi Intel®&nbsp;Evo™&nbsp;Edition ?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Créativité",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Lecture vidéo rapide et fluide",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Produisez des tubes grâce à la puissance de l'IA",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "Montage ultra-rapide",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productivité",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Optimisation de votre productivité avec Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "L'IA détecte les deepfakes avant de les partager",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Votre monde. Sur tous vos appareils.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilité",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Collaborez de pratiquement n'importe où",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Une autonomie qui ne vous ralentit pas",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Maintien automatique de la connexion",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Tous vos loisirs. Dans&nbsp;un&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Pour un PC qui fait aussi office de centre de loisirs, vous aurez besoin d'un espace de stockage suffisant pour vos chansons, vidéos et jeux préférés, ainsi que d'un tandem processeur/écran adapté pour créer des images immersives. Ce PC dispose des trois.",
                "titleArray": [
                  {
                    "tr_title": "Jeux vidéo et streaming impressionnants",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Créez votre bibliothèque numérique",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Une fenêtre sur vos loisirs",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Jeux vidéo et streaming fluides",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Créez votre bibliothèque numérique",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Une fenêtre sur vos loisirs",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Soyez plus productif. Partout.",
              "tr_tileTitle1": "Faites plus de choses. Plus vite.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Ce PC offre un équilibre parfait entre puissance et portabilité. Outre son processeur conçu pour les ordinateurs fins et légers, ce PC présente aussi la connectivité Wi&#8209;Fi nécessaire pour rester connecté et l'autonomie appropriée pour rester sur batterie.",
                "tr_subtitle1": "Ce PC présente un équilibre parfait entre puissance et performances pour vous aider à accomplir plus de tâches en moins de temps. Ensemble, un processeur puissant accéléré par une mémoire de grande capacité et une connexion Wi-Fi ultra-rapide simplifieront vos tâches quotidiennes.",
                "tr_subtitle2": "Ce PC offre un équilibre parfait entre puissance et portabilité. Outre son processeur conçu pour les ordinateurs fins et légers, ce PC dispose d'une connectivité Wi‑Fi nécessaire pour rester connecté ainsi qu'une mémoire adaptée au multitâche.",
                "titleArray": [
                  {
                    "tr_title": "Des performances intelligentes qui comptent",
                    "tr_title1": "Allez plus vite avec des performances intelligentes",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Meilleures connexions de leur catégorie",
                    "tr_title1": "Connectez-vous à ce qui est important",
                    "tr_title2": "Une avancée importante dans le Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Restez plus longtemps sur batterie",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Jonglez entre les programmes comme un pro",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "La liberté de créer",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "La création de contenus représente l'une des tâches les plus exigeantes pour votre PC. Heureusement, ces composants rationalisent votre processus créatif.",
                "titleArray": [
                  {
                    "tr_title": "Créativité accélérée",
                    "tr_title1": "Jonglez entre vos différents projets créatifs",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Création élevée",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Mémoire pour le multitâche",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Explorer l'intérieur de&nbsp;ce&nbsp;PC",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Voici les trois principaux composants à surveiller quand vous choisissez un nouveau PC. En décidant comment vous allez utiliser votre nouveau PC, vous déterminerez plus facilement la place de ces trois composants clés dans vos besoins. Ces trois éléments sont&nbsp;:",
                "titleArray": [
                  {
                    "tr_title": "Processeur",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Stockage",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Mémoire",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "La liberté de créer",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "La création de contenus représente l'une des tâches les plus exigeantes pour votre PC. Heureusement, ces composants rationalisent votre processus créatif.",
                "titleArray": [
                  {
                    "tr_title": "Création plus intelligente",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Donnez vie à vos images",
                    "tr_title1": "Libérez votre créativité",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Mémoire pour le multitâche",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Soyez plus productif. Partout.",
              "tr_tileTitle1": "Faites plus de choses. Plus vite.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Ce PC offre un équilibre parfait entre puissance et portabilité. Outre son processeur conçu pour les ordinateurs fins et légers, ce PC présente aussi la connectivité Wi&#8209;Fi nécessaire pour rester connecté et l'autonomie appropriée pour rester sur batterie.",
                "tr_subtitle1": "Ce PC présente un équilibre parfait entre puissance et performances pour vous aider à accomplir plus de tâches en moins de temps. Ensemble, un processeur puissant accéléré par une mémoire de grande capacité et une connexion Wi-Fi ultra-rapide simplifieront vos tâches quotidiennes.",
                "tr_subtitle2": "Ce PC offre un équilibre parfait entre puissance et portabilité. Outre son processeur conçu pour les ordinateurs fins et légers, ce PC dispose d'une connectivité Wi‑Fi nécessaire pour rester connecté ainsi qu'une mémoire adaptée au multitâche.",
                "titleArray": [
                  {
                    "tr_title": "Conçu pour en faire plus",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Meilleures connexions de leur catégorie",
                    "tr_title1": "Connectez-vous à ce qui est important",
                    "tr_title2": "Une avancée importante dans le Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Restez plus longtemps sur batterie",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Jonglez entre les programmes comme un pro",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Prêt pour la RV",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Là où la RV prend vie",
                "tr_subtitle": "Que faut-il pour créer un monde entièrement virtuel&nbsp;? Sur un PC, le processeur, la carte graphique et la mémoire doivent être coordonnés.",
                "titleArray": [
                  {
                    "tr_title": "La source des mondes virtuels",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "La réalité se trouve dans les détails",
                    "tr_title1": "Plongez au cœur d'expériences visuelles immersives",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "OLED Samsung&nbsp;:<br/>Un plaisir pour les yeux.",
      "tr_sub_header": "La lumière bleue peut entraîner de la gêne visuelle et perturber le cycle de votre sommeil. L'Écran OLED testé SGS soin des yeux de Samsung ne soulage pas en changeant les couleurs, mais en réduisant la longueur d'onde de la lumière bleue potentiellement nocive, offrant du confort et réduisant ainsi la fatigue oculaire.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "De la lumière bleue moins nocive"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra dans tous les sens",
      "tr_sub_header": "La conception ultralégère et ultrafine des OLED maximise la qualité de l'image tout en réduisant le poids."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Vrai noir",
      "tr_sub_header": "0,0005&nbsp;nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74&nbsp;%<br/><span class='conventional_text'>classique</span>",
      "tr_sub_header": "120&nbsp;%<br/><span class='samsungoled_text'>OLED Samsung</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Streaming",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STREAMING",
                "tr_subtitle": "Du divertissement à l'infini",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour le streaming&nbsp;:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut faire du streaming en&nbsp;HD."
                  },
                  {
                    "tr_title": "Un écran qui produit des images magnifiques et lumineuses."
                  },
                  {
                    "tr_title": "Un Wi‑Fi qui réduit la mise en mémoire tampon et les durées de chargement."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Navigation Web",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "NAVIGATION WEB",
                "tr_subtitle": "Internet, en toute simplicité",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour la navigation sur le Web&nbsp;:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut charger rapidement des pages riches en multimédia."
                  },
                  {
                    "tr_title": "Un Wi‑Fi rapide et fiable."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chat vidéo",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHAT VIDÉO",
                "tr_subtitle": "Des connexions claires comme de l'eau de roche",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour les discussions vidéo&nbsp;:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut simultanément enregistrer et partager des images."
                  },
                  {
                    "tr_title": "Un Wi-Fi qui est rapide, puissant et fiable."
                  },
                  {
                    "tr_title": "Une webcam qui vous aidera à communiquer très clairement."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitâche à grande vitesse",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITÂCHE À GRANDE VITESSE",
                "tr_subtitle": "Faites-en plus sans ralentir",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour le multitâche&nbsp;:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut jongler entre plusieurs programmes à la fois."
                  },
                  {
                    "tr_title": "Un Wi-Fi qui est rapide, puissant et fiable."
                  },
                  {
                    "tr_title": "Une mémoire qui permet d'alterner de manière fluide entre les applications."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chat vidéo",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHAT VIDÉO",
                "tr_subtitle": "Des connexions claires comme de l'eau de roche",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour les discussions vidéo&nbsp;:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut simultanément enregistrer et partager des images."
                  },
                  {
                    "tr_title": "Un Wi-Fi qui est rapide, puissant et fiable."
                  },
                  {
                    "tr_title": "Une webcam qui vous aidera à communiquer très clairement."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Jeux occasionnels",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "JEUX OCCASIONNELS",
                "tr_subtitle": "À vous de jouer",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour les jeux grand public&nbsp;:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui produit un jeu fluide."
                  },
                  {
                    "tr_title": "Une mémoire qui permet simultanément de discuter et de faire du streaming."
                  },
                  {
                    "tr_title": "Des graphiques qui produisent des images étonnantes."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Retouche photo",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "RETOUCHE PHOTO",
                "tr_subtitle": "Des photos parfaites",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour la retouche photo&nbsp;:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Un processeur suffisamment puissant pour retoucher de grands groupes d'images."
                  },
                  {
                    "tr_title": "Une mémoire qui accélère votre logiciel de retouche."
                  },
                  {
                    "tr_title": "La technologie Thunderbolt™&nbsp;4 vous permet de transférer rapidement des fichiers."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitâche à grande vitesse",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITÂCHE À GRANDE VITESSE",
                "tr_subtitle": "Faites-en plus sans ralentir",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour le multitâche&nbsp;:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut jongler entre plusieurs programmes à la fois."
                  },
                  {
                    "tr_title": "Un Wi-Fi qui est rapide, puissant et fiable."
                  },
                  {
                    "tr_title": "Une mémoire qui permet d'alterner de manière fluide entre les applications."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Collaboration en temps réel",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLLABORATION EN TEMPS RÉEL",
                "tr_subtitle": "Travaillez n'importe&nbsp;où",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour la collaboration en ligne&nbsp;:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut exécuter des logiciels de bureautique."
                  },
                  {
                    "tr_title": "Un Wi‑Fi sur lequel vous pouvez compter."
                  },
                  {
                    "tr_title": "Une webcam qui enregistre en HD éclatante."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Montage vidéo",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MONTAGE VIDÉO",
                "tr_subtitle": "La magie du cinéma",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour le montage vidéo&nbsp;:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut traiter des fichiers volumineux."
                  },
                  {
                    "tr_title": "Une mémoire qui rend les logiciels de montage rapides et réactifs."
                  },
                  {
                    "tr_title": "Des graphiques qui réduisent les délais de rendu."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Retouche photo",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "RETOUCHE PHOTO",
                "tr_subtitle": "Des photos parfaites",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour la retouche photo&nbsp;:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Un processeur suffisamment puissant pour retoucher de grands groupes d'images."
                  },
                  {
                    "tr_title": "Une mémoire qui accélère votre logiciel de retouche."
                  },
                  {
                    "tr_title": "La technologie Thunderbolt™&nbsp;4 vous permet de transférer rapidement des fichiers."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Montage vidéo",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MONTAGE VIDÉO",
                "tr_subtitle": "La magie du cinéma",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour le montage vidéo&nbsp;:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut traiter des fichiers volumineux."
                  },
                  {
                    "tr_title": "Une mémoire qui rend les logiciels de montage rapides et réactifs."
                  },
                  {
                    "tr_title": "Des graphiques qui réduisent les délais de rendu."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Modélisation 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MODÉLISATION 3D",
                "tr_subtitle": "Des créations d'un autre niveau",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour la modélisation&nbsp;3D&nbsp;:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut gérer des logiciels de création avancés."
                  },
                  {
                    "tr_title": "Une mémoire qui peut rapidement ouvrir des fichiers volumineux."
                  },
                  {
                    "tr_title": "Des graphiques puissants pour la création et la retouche d'images."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Collaboration en temps réel",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLLABORATION EN TEMPS RÉEL",
                "tr_subtitle": "Travaillez n'importe&nbsp;où",
                "tr_subtitle1": "Voici ce que nous avons trouvé dans ce PC qui est idéal pour la collaboration en ligne&nbsp;:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Un processeur qui peut exécuter des logiciels de bureautique."
                  },
                  {
                    "tr_title": "Un Wi‑Fi sur lequel vous pouvez compter."
                  },
                  {
                    "tr_title": "Une webcam qui enregistre en HD éclatante."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Application Intel® Unison™",
    "tr_title": "Libérez votre monde connecté",
    "tr_subtitle": "Transférez facilement des fichiers et des photos tout en gérant vos notifications téléphoniques, vos appels et vos SMS depuis votre PC<sup>18</sup>.",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "TRANSFÉREZ DES FICHIERS ET DES PHOTOS",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "PASSEZ ET RECEVEZ DES APPELS",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "ENVOYEZ ET RECEVEZ DES SMS",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "GÉREZ VOS NOTIFICATIONS TÉLÉPHONIQUES",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "LIBÉREZ VOTRE MONDE CONNECTÉ",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}