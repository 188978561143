const Library_mobile_ptBR = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Equipado com um processador [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Equipado com um processador [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Como um cérebro, o processador controla o que seu PC faz. Isso significa que os vídeos que você assiste, os jogos que joga, os sites que visita, tudo começa com seu processador.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "O processador é o cérebro do seu PC"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Desempenho e valor.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processador Intel® Pentium® Silver",
                    "tr_subtitle": "Fique por dentro do seu mundo digital com o desempenho para acessar arquivos na web. Multitarefas simples alternando facilmente entre programas e fazendo mais em menos tempo.",
                    "tr_gen_core_title": "Processador Intel® Pentium® Silver"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processador Intel® Pentium® Gold",
                    "tr_subtitle": "Produtividade aprimorada: desempenho elevado para impulsionar seus dias mais difíceis. Navegação na web simplificada: carregue rapidamente as páginas da web ricas em mídia para mais exploração e menos espera.",
                    "tr_gen_core_title": "Processador Intel® Pentium® Gold"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processador Intel® Celeron®",
                    "tr_subtitle": "Desempenho para melhorar o trabalho e o lazer. Desfrute de filmes e vídeos da web com detalhes incríveis. Trabalhe mais e espere menos.",
                    "tr_gen_core_title": "Processador Intel® Celeron®"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processador Intel® Pentium®",
                    "tr_subtitle": "Desenvolvido para excelente entretenimento, streaming de vídeo e produtividade. Desfrute de filmes e vídeos da web com detalhes incríveis. Trabalhe mais e espere menos.",
                    "tr_gen_core_title": "Processador Intel® Pentium®"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Desempenho que você precisa pelo preço acessível que você deseja.",
                "tr_title": "Processador Intel®",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Valor único",
                    "tr_subtitle": "Um processador Intel® pode lidar com praticamente qualquer tarefa que você precisar, por um preço que você pode pagar. Navegue facilmente de um software para outro. Ou desfrute de gráficos de alta qualidade com desempenho estável.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Faça conexões mais fortes",
                    "tr_subtitle": "Qualidade de vídeo mais vívida, clareza de áudio mais rica e conectividade com a Internet quase 3x mais rápida permitem que você colabore com confiança onde quer que precise.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "A diferença é do dia para a noite",
                    "tr_subtitle": "De maratonas de filmes até retoques de última hora, o processador Intel® aumenta a autonomia da bateria do seu dispositivo para experiências verdadeiramente imersivas e ininterruptas.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Qualquer ambiente é um ambiente de aprendizagem",
                    "tr_subtitle": "Execute várias ferramentas de aprendizagem on-line com facilidade em qualquer espaço de sua casa."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Aprenda onde quiser",
                    "tr_subtitle": "Termine tarefas em qualquer lugar enquanto executa várias ferramentas de e-learning ao mesmo tempo."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Obtenha a potência para entretenimento e produtividade que se ajusta ao seu orçamento e estilo de vida.",
                "tr_title": "Desempenho Intel para qualquer orçamento.",
                "tr_gen_core_title": "Processador Intel® Celeron®",
                "processorCompare": {
                  "tr_title": "ENCONTRE O NÍVEL CERTO DE DESEMPENHO E VALOR",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Processador Intel® Celeron®"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Processador Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Navegação na web rápida e rica em mídia",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Alterne facilmente entre programas",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos casuais",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmitir vídeos 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Edição leve de fotos e vídeo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Produtividade rápida com desempenho inteligente",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Com um processador Intel® Core™ [cpu_fam] da 7ª Geração"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Com um processador Intel® Core™ [cpu_fam] da 6ª Geração"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Com um processador Intel® Core™ [cpu_fam] da 5ª Geração"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Com um processador Intel® Core™ [cpu_fam] da 4ª Geração"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Com um processador Intel® Core™ [cpu_fam] da 3ª Geração"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Com um processador Intel® Core™ [cpu_fam] da 2ª Geração"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Com um processador Intel® Core™ [cpu_fam] da 1ª Geração"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Recomendável para:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Equipado com um processador Intel® Core™ i3 da 8ª Geração",
                "tr_gen_core_title": "Processador Intel® Core™ i3 da 8ª Geração"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Os processadores Intel® Core™ da 9ª Geração foram desenvolvidos para acompanhar seu&nbsp;mundo digital. Com a combinação de&nbsp;velocidade e desempenho desses processadores, você poderá fazer ainda mais daquilo que adora no seu PC sem frustrações.",
                "tr_title": "Equipado com um&nbsp;processador Intel®&nbsp;Core™&nbsp;i3 da&nbsp;9ª&nbsp;Geração",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 da&nbsp;9ª Geração"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Desempenho versátil associa-se à ampla autonomia da bateria para manter você em movimento, em qualquer lugar.",
                "tr_title": "Potência ao longo do seu dia",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 da&nbsp;10ª&nbsp;Geração",
                "processorCompare": {
                  "tr_title": "ENCONTRE O NÍVEL CERTO DE DESEMPENHO DA 10ª GERAÇÃO",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming de filmes em 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Conectividade rápida e confiável",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Mascaramento de fotos e filtragem de vídeos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos em 1080p com taxas de quadros estáveis",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Trabalho com arquivos grandes e programas de criação",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Alterne facilmente entre streaming de filmes e criação de apresentações com desempenho de sobra.",
                "tr_title": "Potência ao longo do seu dia",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 da&nbsp;10ª&nbsp;Geração"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Um notebook projetado especificamente para aquelas pessoas que estão sempre em movimento.",
                "tr_title": "Conheça uma nova era de notebooks",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 da&nbsp;10ª&nbsp;Geração"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Projetado especificamente para PCs portáteis, o processador Intel® Core™ i3 da 11ª Geração permite que você faça mais do que nunca, onde quer que esteja.",
                "tr_title": "Aproveite mais o seu novo notebook",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 da&nbsp;11ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i3 DA 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Quando sua família inteira recorre a um PC desktop para tudo o que faz, você precisa da potência e versatilidade de um processador Intel® Core™ i3 da 11ª Geração.",
                "tr_title": "Esteja pronto para qualquer coisa com desempenho revolucionário.",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 da&nbsp;11ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i3 DA 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Com um processador Intel® Core™ i5 da 8ª Geração",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 8ª Geração"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Os processadores Intel® Core™ da 9ª Geração foram desenvolvidos para acompanhar seu&nbsp;mundo digital. Com a combinação de&nbsp;velocidade e desempenho desses processadores, você poderá fazer ainda mais daquilo que adora no seu PC sem frustrações.",
                "tr_title": "Equipado com um&nbsp;processador Intel®&nbsp;Core™&nbsp;i5 da&nbsp;9ª&nbsp;Geração",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 9ª Geração"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "JOGOS ESTÁVEIS E RV NOS TÍTULOS MAIS EXIGENTES",
                "tr_title": "Jogar a sério começa aqui",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 9ª Geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB de <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ INDICADO PARA O SEU JEITO DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogue, transmita e grave sem limites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "O melhor processador Intel® Core™ da 9ª Geração para jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Manipule vários programas com facilidade para estar sempre preparado para qualquer coisa, não importa aonde o dia o leve.",
                "tr_title": "Multitarefa em qualquer lugar",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 10ª Geração",
                "processorCompare": {
                  "tr_title": "ENCONTRE O NÍVEL CERTO DE DESEMPENHO DA 10ª GERAÇÃO",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming de filmes em 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Conectividade rápida e confiável",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Mascaramento de fotos e filtragem de vídeos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos em 1080p com taxas de quadros estáveis",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Trabalho com arquivos grandes e programas de criação",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Aprimore tudo o que você faz, desde entretenimento até produtividade e&nbsp;criação de conteúdo.",
                "tr_title": "Atualize para um PC mais potente",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 10ª Geração"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Um notebook projetado especificamente para aquelas pessoas que estão sempre em movimento.",
                "tr_title": "Conheça uma nova era de notebooks",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 10ª Geração"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Criado para streaming estável e jogos AAA",
                "tr_title": "Jogue do seu jeito",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 10ª Geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB de <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DA 10ª GERAÇÃO INDICADO PARA O SEU JEITO DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA estáveis",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Máximo desempenho em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com capacidade para overclock<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Criado para streaming estável e jogos AAA",
                "tr_title": "Jogue do seu jeito",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 10ª Geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB de <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DA 10ª GERAÇÃO INDICADO PARA O SEU JEITO DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA estáveis",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Máximo desempenho em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com capacidade para overclock<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Manipule vários programas com facilidade para estar sempre preparado para qualquer coisa, não importa aonde o dia o leve.",
                "tr_title": "Multitarefa em qualquer lugar",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 10ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 10ª GERAÇÃO"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "O processador Intel® Core™ i5 da 11ª Geração oferece experiências visuais vibrantes e produtividade abrangente aos PCs mais finos e leves.",
                "tr_title": "Potência de notebook surpreendente",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Quando sua família inteira recorre a um PC desktop para tudo o que faz, você precisa da potência e versatilidade de um processador Intel® Core™ i5 da 11ª Geração.",
                "tr_title": "Esteja pronto para qualquer coisa com desempenho revolucionário.",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "O processador Intel® Core™ i5 da 11ª Geração oferece experiências visuais vibrantes e produtividade abrangente a notebooks finos e leves.",
                "tr_title": "Potência de notebook surpreendente",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 11ª GERAÇÃO"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Com um processador Intel® Core™ i7 da 8ª Geração",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 8ª Geração"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Os processadores Intel® Core™ da 9ª Geração foram desenvolvidos para acompanhar seu&nbsp;mundo digital. Com a combinação de&nbsp;velocidade e desempenho desses processadores, você poderá fazer ainda mais daquilo que adora no seu PC sem frustrações.",
                "tr_title": "Equipado com um&nbsp;processador Intel®&nbsp;Core™&nbsp;i7 da&nbsp;9ª&nbsp;Geração",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 9ª Geração"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ATÉ 32% MAIS FPS ao jogar, fazer streaming e gravar, comparado a um PC com 3 ANOS DE USO<sup>3</sup>",
                "tr_title": "O poder de compartilhar os melhores momentos dos seus jogos",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 9ª Geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB de <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ INDICADO PARA O SEU JEITO DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogue, transmita e grave sem limites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "O melhor processador Intel® Core™ da 9ª Geração para jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Projetado para eficiência, este elegante dispositivo pode ter grande impacto na sua maneira de criar, conectar-se e fazer transmissões.",
                "tr_title": "Desempenho avançado em qualquer lugar",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 10ª Geração",
                "processorCompare": {
                  "tr_title": "ENCONTRE O NÍVEL CERTO DE DESEMPENHO DA 10ª GERAÇÃO",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming de filmes em 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Conectividade rápida e confiável",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Mascaramento de fotos e filtragem de vídeos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos em 1080p com taxas de quadros estáveis",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Trabalho com arquivos grandes e programas de criação",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Desempenho avançado para elevar tudo o que você faz ao próximo nível.",
                "tr_title": "Potência para fazer de tudo",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 10ª Geração"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Um notebook projetado especificamente para aquelas pessoas que estão sempre em movimento.",
                "tr_title": "Conheça uma nova era de notebooks",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 10ª Geração"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Jogue, transmita e grave com vantagem competitiva",
                "tr_title": "Potencialize o seu mundo de jogos",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 10ª Geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB de <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DA 10ª GERAÇÃO INDICADO PARA O SEU JEITO DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA estáveis",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Máximo desempenho em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com capacidade para overclock<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Jogue, transmita e grave com vantagem competitiva",
                "tr_title": "Potencialize o seu mundo de jogos",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 10ª Geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB de <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DA 10ª GERAÇÃO INDICADO PARA O SEU JEITO DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA estáveis",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Máximo desempenho em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com capacidade para overclock<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Projetado para eficiência, esse elegante dispositivo pode ter um grande impacto na sua maneira de criar, se conectar e fazer streaming.",
                "tr_title": "Desempenho avançado em qualquer lugar",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 10ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 10ª GERAÇÃO"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "O processador Intel® Core™ i7 da 11ª Geração oferece criação de conteúdo avançada, jogos estáveis e entretenimento de nível superior para PCs ultraportáteis.",
                "tr_title": "Desempenho de notebook revolucionário",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Faça do seu PC desktop o destino certo de tudo o que sua casa possa precisar, com o desempenho revolucionário de um processador Intel® Core™ i7 da 11ª Geração.",
                "tr_title": "Trabalhe. Aprenda. Jogue. Sem limites.",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "O processador Intel® Core™ i7 da 11ª Geração oferece criação de conteúdo avançada, jogos estáveis e entretenimento de nível superior para notebooks finos e leves.",
                "tr_title": "Desempenho de notebook revolucionário",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 11ª GERAÇÃO"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Faça do seu PC desktop o destino certo de tudo o que sua casa possa precisar, com o desempenho revolucionário de um processador Intel® Core™ i7 da 11ª Geração.",
                "tr_title": "Crie sem limites",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 11ª GERAÇÃO"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Com criação de conteúdo avançada, jogos estáveis e entretenimento de nível superior em notebooks finos e leves, o processador Intel® Core™ i7 da 11ª Geração ficou ainda melhor.",
                "tr_title": "Desempenho de notebook revolucionário",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 11ª GERAÇÃO"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Com um processador Intel® Core™ i9 da 8ª Geração",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 8ª Geração"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Os processadores Intel® Core™ da 9ª Geração foram desenvolvidos para acompanhar seu&nbsp;mundo digital. Com a combinação de&nbsp;velocidade e desempenho desses processadores, você poderá fazer ainda mais daquilo que adora no seu PC sem frustrações.",
                "tr_title": "Equipado com um&nbsp;processador Intel®&nbsp;Core™&nbsp;i9 da&nbsp;9ª&nbsp;Geração",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 9ª Geração"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ATÉ 41% MAIS FPS ao jogar, fazer streaming e gravar, em comparação com um PC com 3 ANOS DE USO<sup>4</sup>",
                "tr_title": "Os jogadores exigem. O Intel® Core™ i9 entrega.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 9ª Geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB de <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ INDICADO PARA O SEU JEITO DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogue, transmita e grave sem limites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "O melhor processador Intel® Core™ da 9ª Geração para jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Aumente seu potencial com a experiência de jogos definitiva",
                "tr_title": "Participe de competições nos níveis mais elevados",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 10ª Geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB de <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DA 10ª GERAÇÃO INDICADO PARA O SEU JEITO DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA estáveis",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Máximo desempenho em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com capacidade para overclock<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Aumente seu potencial com a experiência de jogos definitiva",
                "tr_title": "Participe de competições nos níveis mais elevados",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 10ª Geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB de <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DA 10ª GERAÇÃO INDICADO PARA O SEU JEITO DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA estáveis",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Máximo desempenho em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com capacidade para overclock<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Aumente seu potencial com a experiência de criação definitiva",
                "tr_title": "Crie nos níveis mais elevados",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 10ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 10ª GERAÇÃO",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Os notebooks Intel® Evo™ com processadores Intel® Core™ i5 da 11ª Geração e gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> proporcionam velocidade e desempenho incríveis, tudo em um design fino e leve.",
                "tr_title": "Potência elevada para um notebook evoluído",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Os notebooks Intel® Evo™ com processadores Intel® Core™ i7 da 11ª Geração e gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> oferecem velocidade e desempenho avançados para uma experiência móvel premium.",
                "tr_title": "Potência avançada para um notebook evoluído",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Aproveite um ótimo equilíbrio de experiências de desempenho, mídia e conectividade a um valor incrível.",
                "tr_title": "Desempenho e conectividade a um valor incrível.",
                "tr_gen_core_title": "Processador Intel® Pentium® Silver",
                "processorCompare": {
                  "tr_title": "ENCONTRE O NÍVEL CERTO DE DESEMPENHO E VALOR",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Processador Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Processador Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Navegação na web rápida e rica em mídia",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Alterne facilmente entre programas",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos casuais",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmitir vídeos 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Edição leve de fotos e vídeo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Produtividade rápida com desempenho inteligente",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Aproveite de multitarefa sem interrupção, edição simples de fotos e vídeo, vídeos 4K e gráficos vívidos a um valor inacreditável.",
                "tr_title": "Desempenho impressionante para o trabalho e lazer.",
                "tr_gen_core_title": "Processador Intel® Pentium® Gold",
                "processorCompare": {
                  "tr_title": "ENCONTRE O NÍVEL CERTO DE DESEMPENHO E VALOR",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Processador Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Processador Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Navegação na web rápida e rica em mídia",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Alterne facilmente entre programas",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos casuais",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Transmitir vídeos 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Edição leve de fotos e vídeo",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Produtividade rápida com desempenho inteligente",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Jogos AAA e autonomia da bateria de longa duração em um PC ultraportátil. Não abra mão de nada para jogar.",
                "tr_title": "Não abra mão de nada para jogar.",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 11ª Geração",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ENCONTRE SEU PROCESSADOR INTEL® CORE™ ULTRAPORTÁTIL PARA JOGOS.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5-11300H da 11ª Geração"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7-11370H da 11ª Geração"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7-11375H Special&nbsp;Edition da&nbsp;11ª&nbsp;Geração"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Até <span class='style_badge'>4,4 GHz</span> de velocidade máxima do clock",
                      "tr_td2_title": "Até <span class='style_badge'>4,8 GHz</span> de velocidade máxima do clock",
                      "tr_td3_title": "Até <span class='style_badge'>5,0 GHz</span> de velocidade máxima do clock"
                    },
                    {
                      "tr_td1_title": "4 núcleos/ 8&nbsp;threads",
                      "tr_td2_title": "4 núcleos/ 8&nbsp;threads",
                      "tr_td3_title": "4 núcleos/ 8&nbsp;threads"
                    },
                    {
                      "tr_td1_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td2_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td3_title": "Tecnologia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Cache inteligente Intel® <br/>de&nbsp;8&nbsp;MB",
                      "tr_td2_title": "Cache inteligente Intel® <br/>de&nbsp;12&nbsp;MB",
                      "tr_td3_title": "Cache inteligente Intel® <br/>de&nbsp;12&nbsp;MB"
                    },
                    {
                      "tr_td1_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Jogos AAA de 1080p com FPS alto em configurações altas e excelente desempenho desconectado da tomada em um PC ultraportátil.",
                "tr_title": "Agora você pode ter tudo",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 11ª Geração",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ENCONTRE SEU PROCESSADOR INTEL® CORE™ ULTRAPORTÁTIL PARA JOGOS.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5-11300H da 11ª Geração"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7-11370H da 11ª Geração"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7-11375H Special&nbsp;Edition da&nbsp;11ª&nbsp;Geração"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Até <span class='style_badge'>4,4 GHz</span> de velocidade máxima do clock",
                      "tr_td2_title": "Até <span class='style_badge'>4,8 GHz</span> de velocidade máxima do clock",
                      "tr_td3_title": "Até <span class='style_badge'>5,0 GHz</span> de velocidade máxima do clock"
                    },
                    {
                      "tr_td1_title": "4 núcleos/ 8&nbsp;threads",
                      "tr_td2_title": "4 núcleos/ 8&nbsp;threads",
                      "tr_td3_title": "4 núcleos/ 8&nbsp;threads"
                    },
                    {
                      "tr_td1_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td2_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td3_title": "Tecnologia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Cache inteligente Intel® <br/>de&nbsp;8&nbsp;MB",
                      "tr_td2_title": "Cache inteligente Intel® <br/>de&nbsp;12&nbsp;MB",
                      "tr_td3_title": "Cache inteligente Intel® <br/>de&nbsp;12&nbsp;MB"
                    },
                    {
                      "tr_td1_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Velocidade máxima de clock de até 5,0 GHz para levar seus jogos mais longe, e um PC ultraportátil para transportá-los aonde quer que você vá.",
                "tr_title": "Mais potência, menos&nbsp;peso.",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition da&nbsp;11ª&nbsp;Geração",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ENCONTRE SEU PROCESSADOR INTEL® CORE™ ULTRAPORTÁTIL PARA JOGOS.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5-11300H da 11ª Geração"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7-11370H da 11ª Geração"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7-11375H Special&nbsp;Edition da&nbsp;11ª&nbsp;Geração"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Até <span class='style_badge'>4,4 GHz</span> de velocidade máxima do clock",
                      "tr_td2_title": "Até <span class='style_badge'>4,8 GHz</span> de velocidade máxima do clock",
                      "tr_td3_title": "Até <span class='style_badge'>5,0 GHz</span> de velocidade máxima do clock"
                    },
                    {
                      "tr_td1_title": "4 núcleos/ 8&nbsp;threads",
                      "tr_td2_title": "4 núcleos/ 8&nbsp;threads",
                      "tr_td3_title": "4 núcleos/ 8&nbsp;threads"
                    },
                    {
                      "tr_td1_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td2_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td3_title": "Tecnologia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Cache inteligente Intel® <br/>de&nbsp;8&nbsp;MB",
                      "tr_td2_title": "Cache inteligente Intel® <br/>de&nbsp;12&nbsp;MB",
                      "tr_td3_title": "Cache inteligente Intel® <br/>de&nbsp;12&nbsp;MB"
                    },
                    {
                      "tr_td1_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "O processador Intel® Core™ i7 Special Edition da 11ª Geração oferece criação de conteúdo avançada, jogos estáveis e entretenimento de nível superior para PCs ultraportáteis.",
                "tr_title": "Desempenho de notebook revolucionário",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition da&nbsp;11ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 SPECIAL EDITION DA 11ª GERAÇÃO"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "O processador Intel® Core™ i7 Special Edition da 11ª Geração oferece criação de conteúdo avançada, jogos estáveis e entretenimento de nível superior para PCs ultraportáteis.",
                "tr_title": "Desempenho de notebook revolucionário",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 Special&nbsp;Edition da&nbsp;11ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 SPECIAL EDITION DA 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "As velocidades de clock rápidas e o alto número de núcleos funcionam perfeitamente juntos, proporcionando alta competitividade em jogos e permitindo criações complexas de conteúdo.",
                "tr_title": "Potência impressionante. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 11ª Geração",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "O equilíbrio perfeito entre a velocidade do clock e o desempenho do núcleo melhora a jogabilidade, tanto em jogos competitivos com FPS alto quanto em experiências AAA imersivas.",
                "tr_title": "Potência para seus jogos.",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 11ª Geração",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Jogue, grave e transmita em títulos exigentes com FPS alto e alterne facilmente para cargas de trabalho multitarefa pesadas.",
                "tr_title": "Potência para ultrapassar limites.",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 11ª Geração",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As velocidades de clock rápidas e o alto número de núcleos funcionam perfeitamente juntos, proporcionando alta competitividade em jogos e permitindo criações complexas de conteúdo.",
                "tr_title": "Potência impressionante. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 11ª Geração",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Alterne facilmente entre streaming de filmes e criação de apresentações com desempenho de sobra.",
                "tr_title": "Potência ao longo do seu dia",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 com Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processador Intel®&nbsp;Core™&nbsp;i3 com Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Desempenho versátil associa-se à ampla autonomia da bateria para manter você em movimento, em qualquer lugar.",
                "tr_title": "Potência ao longo do seu dia",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 com Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processador Intel®&nbsp;Core™&nbsp;i3 com Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Manipule vários programas com facilidade para estar sempre preparado para qualquer coisa, não importa aonde o dia o leve.",
                "tr_title": "Multitarefa em qualquer lugar",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 com Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processador Intel®&nbsp;Core™&nbsp;i5 com Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Aprimore tudo o que você faz, desde entretenimento até produtividade e&nbsp;criação de conteúdo.",
                "tr_title": "Atualize para um PC mais potente",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 com Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processador Intel®&nbsp;Core™&nbsp;i5 com Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "O desempenho de jogos de nível desktop encontra a mobilidade, seja para jogos competitivos com alto FPS ou experiências AAA imersivas.",
                "tr_title": "Potência para seus jogos.",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 11ª Geração",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "O desempenho de jogos de nível desktop encontra a mobilidade, seja para jogos competitivos com alto FPS ou experiências AAA imersivas.",
                "tr_title": "Potência para seus jogos.",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Um notebook que permite jogar, transmitir e gravar simultaneamente com FPS alto e alternar facilmente para multitarefas com cargas de trabalho pesadas.",
                "tr_title": "Potência para ultrapassar limites.",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 11ª Geração",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Um notebook que permite jogar, transmitir e gravar simultaneamente com FPS alto e alternar facilmente para multitarefas com cargas de trabalho pesadas.",
                "tr_title": "Potência para ultrapassar limites.",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As velocidades do clock rápidas e o alto número de núcleos funcionam perfeitamente juntos, proporcionando alta competitividade em jogos e permitindo criações complexas de conteúdo onde quer que você leve seu PC.",
                "tr_title": "Potência impressionante. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 11ª Geração",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As velocidades do clock rápidas e o alto número de núcleos funcionam perfeitamente juntos, proporcionando alta competitividade em jogos e permitindo criações complexas de conteúdo onde quer que você leve seu PC.",
                "tr_title": "Potência impressionante. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As velocidades do clock rápidas, o alto número de núcleos e o desempenho desbloqueado funcionam perfeitamente juntos, proporcionando alta competitividade em jogos e permitindo criação de conteúdo complexo, onde quer que você leve seu PC.",
                "tr_title": "Potência sem limites, equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 11ª Geração",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As velocidades do clock rápidas, o alto número de núcleos e o desempenho desbloqueado funcionam perfeitamente juntos, proporcionando alta competitividade em jogos e permitindo criação de conteúdo complexo, onde quer que você leve seu PC.",
                "tr_title": "Potência sem limites, equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As velocidades de clock rápidas e o alto número de núcleos funcionam perfeitamente juntos, proporcionando alta competitividade em jogos e permitindo criações complexas de conteúdo.",
                "tr_title": "Potência impressionante. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As altas velocidades do clock e a grande quantidade de núcleos funcionam perfeitamente juntas, otimizando a criação de conteúdo complexo, onde quer que você leve seu PC.",
                "tr_title": "Potência impressionante. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 11ª GERAÇÃO",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As altas velocidades do clock e a grande quantidade de núcleos funcionam perfeitamente juntas, otimizando a criação de conteúdo complexo.",
                "tr_title": "Potência impressionante. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 11ª GERAÇÃO",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Aumente seu potencial com a experiência de jogos definitiva",
                "tr_title": "Participe de competições nos níveis mais elevados",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 10ª Geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB de <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DA 10ª GERAÇÃO INDICADO PARA O SEU JEITO DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA estáveis",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Máximo desempenho em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com capacidade para overclock<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Aumente seu potencial com a experiência de jogos definitiva",
                "tr_title": "Participe de competições nos níveis mais elevados",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 10ª Geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB de <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DA 10ª GERAÇÃO INDICADO PARA O SEU JEITO DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA estáveis",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Máximo desempenho em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com capacidade para overclock<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Um notebook projetado especificamente para aquelas pessoas que estão sempre em movimento.",
                "tr_title": "Conheça uma nova era de notebooks",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 10ª Geração"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As velocidades de clock rápidas e o alto número de núcleos funcionam perfeitamente juntos, proporcionando alta competitividade em jogos e permitindo criações complexas de conteúdo.",
                "tr_title": "Potência sem limites, equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As altas velocidades do clock, a grande quantidade de núcleos e o desempenho desbloqueado funcionam perfeitamente juntos, otimizando a criação de conteúdo complexo, onde quer que você leve seu PC.",
                "tr_title": "Potência sem limites, equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 11ª GERAÇÃO",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As altas velocidades do clock e a grande quantidade de núcleos funcionam perfeitamente juntas, otimizando a criação de conteúdo complexo.",
                "tr_title": "Potência sem limites, equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 11ª Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 11ª GERAÇÃO",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache inteligente Intel® de <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Jogabilidade estável e RV nos títulos mais exigentes",
                "tr_title": "Jogar a sério começa aqui",
                "tr_gen_core_title": "Processador Intel® Core™ i5 da 9ª Geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB de <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ INDICADO PARA O SEU JEITO DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogue, transmita e grave sem limites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "O melhor processador Intel® Core™ da 9ª Geração para jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ATÉ 32% MAIS FPS <br/><span class='tr_subtitle_font'>em jogos, streamings e gravações, comparado a um PC com 3 anos de uso<sup>3</sup></span>",
                "tr_title": "O poder de compartilhar os melhores momentos dos seus jogos",
                "tr_gen_core_title": "Processador Intel® Core™ i7 da 9ª Geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB de <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ INDICADO PARA O SEU JEITO DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogue, transmita e grave sem limites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "O melhor processador Intel® Core™ da 9ª Geração para jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ATÉ 41% MAIS FPS <br/><span class='tr_subtitle_font'>em jogos, streamings e gravações, comparado a um PC com 3 anos de uso<sup>4</sup></span>",
                "tr_title": "Os jogadores exigem. O Intel® Core™ i9 entrega.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 da 9ª Geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB de <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ INDICADO PARA O SEU JEITO DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogue, transmita e grave sem limites",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "O melhor processador Intel® Core™ da 9ª Geração para jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do&nbsp;clock<sup>2</sup>: até&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Projetado para os jogos da próxima geração",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 da&nbsp;12ª&nbsp;Geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 12ª GERAÇÃO"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 da&nbsp;12ª&nbsp;Geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 12ª GERAÇÃO"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 da&nbsp;12ª&nbsp;Geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 12ª GERAÇÃO"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] em Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] em Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Onde os mundos dos jogos e da criação se encontram",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 da&nbsp;12ª&nbsp;Geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 12ª GERAÇÃO"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 da&nbsp;12ª&nbsp;Geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 12ª GERAÇÃO"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] em Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] em Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho confiável quando você mais precisa",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 da&nbsp;12ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i3 DA 12ª GERAÇÃO",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Um aumento de desempenho onde você mais precisa",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 da&nbsp;12ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 12ª GERAÇÃO",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Um aumento de desempenho onde você mais precisa",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 da&nbsp;12ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 12ª GERAÇÃO",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho superior onde você mais precisa",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 da&nbsp;12ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 12ª GERAÇÃO",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho superior onde você mais precisa",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 da&nbsp;12ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 12ª GERAÇÃO",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Com os notebooks Intel® Evo™, você obtém desempenho, inteligência integrada para chamadas de vídeo confiáveis, Internet mais rápida, bateria com autonomia prolongada e muito mais.",
                "tr_title": "Um aumento de desempenho onde você mais precisa.",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 da&nbsp;12ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 12ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Com os notebooks Intel® Evo™, você obtém desempenho, inteligência integrada para chamadas de vídeo confiáveis, Internet mais rápida, bateria com autonomia prolongada e muito mais.",
                "tr_title": "Desempenho superior onde você mais precisa",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 da&nbsp;12ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 12ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Com os notebooks Intel® Evo™, você obtém desempenho, inteligência integrada para chamadas de vídeo confiáveis, Internet mais rápida, bateria com autonomia prolongada e muito mais.",
                "tr_title": "Desempenho extremo em um notebook premium",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 da&nbsp;12ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 12ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Com tecnologia Intel® Turbo Boost 2.0 de&nbsp;até&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Feito para os negócios",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 da&nbsp;12ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 12ª GERAÇÃO",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir o ruído e desfocar seu plano de fundo",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Ajuste automático do desempenho para priorizar o que você faz com mais frequência",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Experiência com chamadas estáveis e de alta qualidade via web",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Obter segurança baseada em hardware pronta para uso",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Oferta de recursos de gerenciamento remoto de dispositivos",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Otimizar automaticamente o desempenho do Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Potencializar por meio de aplicativos orientados por dados",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "A máxima produtividade com melhor desempenho",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Com tecnologia Intel® Turbo Boost 2.0 de&nbsp;até&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Trabalha duro, para que você possa se concentrar em seus negócios.",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 da&nbsp;12ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 12ª GERAÇÃO",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir o ruído e desfocar seu plano de fundo",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Ajuste automático do desempenho para priorizar o que você faz com mais frequência",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Experiência com chamadas estáveis e de alta qualidade via web",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Obter segurança baseada em hardware pronta para uso",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Oferta de recursos de gerenciamento remoto de dispositivos",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Otimizar automaticamente o desempenho do Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Potencializar por meio de aplicativos orientados por dados",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "A máxima produtividade com melhor desempenho",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Com tecnologia Intel® Turbo Boost 2.0 de&nbsp;até&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Acelere seus negócios",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 da&nbsp;12ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 12ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir o ruído e desfocar seu plano de fundo",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Ajuste automático do desempenho para priorizar o que você faz com mais frequência",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Experiência com chamadas estáveis e de alta qualidade via web",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Obter segurança baseada em hardware pronta para uso",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Oferta de recursos de gerenciamento remoto de dispositivos",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Otimizar automaticamente o desempenho do Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Potencializar por meio de aplicativos orientados por dados",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "A máxima produtividade com melhor desempenho",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho confiável dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 da&nbsp;13ª&nbsp;Geração",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho real dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 da&nbsp;13ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 13ª GERAÇÃO",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho real dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 da&nbsp;13ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 13ª GERAÇÃO",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho poderoso dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 da&nbsp;13ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 13ª GERAÇÃO",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho poderoso dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 da&nbsp;13ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 13ª GERAÇÃO",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho que te leva mais longe",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 da&nbsp;13ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 13ª GERAÇÃO",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] em Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] em Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Conecte-se com facilidade ao seu telefone<sup>24</sup>, escolha o melhor Wi-Fi e carregue rapidamente com uma bateria que dura.",
                "tr_title": "Desempenho que te leva mais longe para fazer mais em qualquer lugar",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 da&nbsp;13ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 13ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Conecte-se com facilidade ao seu telefone<sup>24</sup>, escolha o melhor Wi-Fi e carregue rapidamente com uma bateria que dura.",
                "tr_title": "Desempenho que te leva mais longe para que seu PC faça ainda mais de uma vez só",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 da&nbsp;13ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 13ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Conecte-se com facilidade ao seu telefone<sup>24</sup>, escolha o melhor Wi-Fi e carregue rapidamente com uma bateria que dura.",
                "tr_title": "Desempenho que te leva mais longe para acelerar as tarefas mais exigentes",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 da&nbsp;13ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 13ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho que te leva mais longe",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 da&nbsp;13ª&nbsp;Geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 13ª GERAÇÃO",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 da&nbsp;13ª&nbsp;Geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 13ª GERAÇÃO",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 da&nbsp;13ª&nbsp;Geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 13ª GERAÇÃO",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] em Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] em Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho que te leva mais longe",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processador Intel® Core™ i5",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processador Intel® Core™ i7",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processador Intel® Core™ i9",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] em Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] em Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho confiável dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Processador Intel® Core™ i3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho real dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processador Intel® Core™ i5",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho poderoso dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processador Intel® Core™ i7",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho que te leva mais longe",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processador Intel® Core™ i5",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processador Intel® Core™ i7",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processador Intel® Core™ i9",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] em Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] em Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho confiável dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho real dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;5",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL®&nbsp;CORE™&nbsp;5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho poderoso dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;7",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL®&nbsp;CORE™&nbsp;7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Jogue sem limites",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™ Ultra&nbsp;5",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ ULTRA 5",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™ Ultra&nbsp;7",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ ULTRA 7",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™ Ultra&nbsp;9",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ ULTRA 9",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core] núcleos LPE"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] em Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] em Efficient-cores",
                    "tr_badgeSubTitle1": "[l_thread] em núcleos LPE"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ Ultra 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ Ultra 7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ Ultra 9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Jogue sem limites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Jogue sem limites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Jogue sem limites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Jogue sem limites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Jogue sem limites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Jogue sem limites",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Pequenas&nbsp;empresas merecem&nbsp;grandes soluções&nbsp;empresariais",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 da&nbsp;13ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DA 13ª GERAÇÃO",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir o ruído e desfocar seu plano de fundo",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Ajuste automático do desempenho para priorizar o que você faz com mais frequência",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Experiência com chamadas estáveis e de alta qualidade via web",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Obtenção de proteções de segurança baseadas em hardware prontas para uso",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Ajudar a proteger dispositivos e dados contra ataques de adulteração física",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Pequenas&nbsp;empresas merecem&nbsp;grandes soluções&nbsp;empresariais",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 da&nbsp;13ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DA 13ª GERAÇÃO",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir o ruído e desfocar seu plano de fundo",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Ajuste automático do desempenho para priorizar o que você faz com mais frequência",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Experiência com chamadas estáveis e de alta qualidade via web",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Obtenção de proteções de segurança baseadas em hardware prontas para uso",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Ajudar a proteger dispositivos e dados contra ataques de adulteração física",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Pequenas&nbsp;empresas merecem&nbsp;grandes soluções&nbsp;empresariais",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 da&nbsp;13ª&nbsp;Geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DA 13ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "COMO ESSE PROCESSADOR SE&nbsp;COMPARA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir o ruído e desfocar seu plano de fundo",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Ajuste automático do desempenho para priorizar o que você faz com mais frequência",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Experiência com chamadas estáveis e de alta qualidade via web",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Obtenção de proteções de segurança baseadas em hardware prontas para uso",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Ajudar a proteger dispositivos e dados contra ataques de adulteração física",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Desbloqueie novas experiências de IA para&nbsp;empresas",
                "tr_title": "Prepare-se para o futuro utilizando os processadores Intel®&nbsp;Core™&nbsp;Ultra&nbsp;[XX] e&nbsp;Intel&nbsp;vPro®",
                "tr_gen_core_title": "Processador Intel® Core™ Ultra [XX]",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "IMPULSIONE SEUS NEGÓCIOS COM OS PCs COM IA DA INTEL",
                    "tr_badgeSubTitle": "PCs poderosos que criam uma ótima experiência estão no centro da produtividade empresarial. Intel vPro® aumenta a eficácia e o desempenho dos funcionários em diversas cargas de trabalho e aplicativos graças a uma nova arquitetura que oferece desempenho com eficiência energética superior para várias cargas de trabalho empresariais complexas, enviando a tarefa certa para o mecanismo certo no momento certo.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "RECURSOS DE SEGURANÇA MULTICAMADA HABILITADOS DE FÁBRICA",
                    "tr_badgeSubTitle": "Ameaças novas e emergentes exigem uma abordagem abrangente para a segurança. Intel vPro® fornece proteções no nível de hardware e em toda a estrutura para que sua empresa fique mais protegida. Essas medidas de segurança exclusivas e prontas para uso, incluindo recursos de detecção de ameaças baseados em IA, ajudam a proteger seus usuários, seus dados e sua empresa.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "FERRAMENTAS INTEGRADAS PARA MANTER OS USUÁRIOS PRODUTIVOS EM PRATICAMENTE QUALQUER LUGAR",
                    "tr_badgeSubTitle": "Escritórios estão em todos os lugares, assim como os PCs. Intel vPro® fornece recursos de gerenciamento modernos baseados em hardware para ajudar as empresas a aliviar o fardo do gerenciamento da base de PCs, simplificar o suporte e melhorar as experiências dos usuários finais, tudo isso enquanto permite práticas de computação sustentáveis.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mais do que um PC incrível, um PC feito para IA",
                "tr_subtitle1": "EQUIPADO COM OS PROCESSADORES INTEL®&nbsp;CORE™ ULTRA (SÉRIE&nbsp;2)",
                "tr_subtitle2": "Já é possível no seu PC Intel feito para IA",
                "tr_subtitle": "O processador para portáteis mais eficiente, seguro e de alto desempenho da Intel<sup>39</sup>",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™ Ultra&nbsp;5 (Série&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Mais criatividade</span> com ferramentas de IA para criar mais rápido"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mais produtividade</span> com assistência de IA que&nbsp;economiza&nbsp;tempo"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mais segurança</span> com proteção de dados integrada"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mais do que um PC incrível, um PC feito para IA",
                "tr_subtitle1": "EQUIPADO COM OS PROCESSADORES INTEL®&nbsp;CORE™ ULTRA (SÉRIE&nbsp;2)",
                "tr_subtitle2": "Já é possível no seu PC Intel feito para IA",
                "tr_subtitle": "O processador para portáteis mais eficiente, seguro e de alto desempenho da Intel<sup>39</sup>",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™ Ultra&nbsp;7 (Série&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Mais criatividade</span> com ferramentas de IA para criar mais rápido"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mais produtividade</span> com assistência de IA que&nbsp;economiza&nbsp;tempo"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mais segurança</span> com proteção de dados integrada"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mais do que um PC incrível, um PC feito para IA",
                "tr_subtitle1": "EQUIPADO COM OS PROCESSADORES INTEL®&nbsp;CORE™ ULTRA (SÉRIE&nbsp;2)",
                "tr_subtitle2": "Já é possível no seu PC Intel feito para IA",
                "tr_subtitle": "O processador para portáteis mais eficiente, seguro e de alto desempenho da Intel<sup>39</sup>",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™ Ultra&nbsp;5 (Série&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mais do que um PC incrível, um PC feito para IA",
                "tr_subtitle1": "EQUIPADO COM OS PROCESSADORES INTEL®&nbsp;CORE™ ULTRA (SÉRIE&nbsp;2)",
                "tr_subtitle2": "Já é possível no seu PC Intel feito para IA",
                "tr_subtitle": "O processador para portáteis mais eficiente, seguro e de alto desempenho da Intel<sup>39</sup>",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™ Ultra&nbsp;7 (Série&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_tileTitle1": "Gráficos dedicados",
                "tr_title": "O QUE É UMA UNIDADE DE PROCESSAMENTO&nbsp;GRÁFICO?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "A unidade de processamento gráfico, ou GPU, cria as imagens exibidas na tela.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU integrada",
                    "tr_badgeSubTitle": "Uma GPU integrada compartilha a RAM com todo o computador, tornando o consumo de energia mais eficiente.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "GPU dedicada",
                    "tr_badgeSubTitle": "Essa é a classe de GPU mais potente porque tem uma RAM própria e outros recursos dedicados à criação de gráficos extraordinários.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "GPU híbrida",
                    "tr_badgeSubTitle": "Essa é a classe de GPU mais potente porque tem uma RAM própria e outros recursos dedicados à criação de gráficos extraordinários.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Edição simplificada de fotos e vídeo",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Devore conteúdo de vídeo 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Jogue jogos populares com detalhes impressionantes",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "GPUs duplas",
                    "tr_badgeSubTitle": "Alguns sistemas, como aqueles com gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>, podem utilizar tanto a GPU integrada ao processador como a dedicada para distribuir a carga de trabalho gráfico.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU integrada",
                    "tr_badgeSubTitle": "Uma GPU integrada é embutida no mesmo chip do processador principal ou CPU. Esse tipo de GPU usa a mesma RAM e os outros recursos que o restante do computador, tornando-o mais eficiente no consumo de energia e contribuindo para um dispositivo mais fino e mais leve.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "GPU dedicada",
                    "tr_badgeSubTitle": "Essa é a classe de GPU mais potente porque tem uma RAM própria e outros recursos dedicados à criação de gráficos extraordinários.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Padrão",
                    "tr_badgeSubTitle": "Até 4 GB de VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Competitivos",
                    "tr_badgeSubTitle": "Até 8 GB de VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "Acima de 8&nbsp;GB de VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Potência de gráficos intensa",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "O processamento gráfico mais poderoso requer uma placa gráfica que possa se dedicar exclusivamente à criação de imagens bonitas e nítidas, e animação suave.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Gráficos ricos e vibrantes integrados",
                "tr_pretitle": "Gráficos Intel® Iris® Plus",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Os gráficos Intel® Iris® Plus proporcionam uma imersão em vídeo 4K HDR e jogos em full HD sem a necessidade de placa gráfica adicional.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Visuais impressionantes, desempenho eficiente",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Os Gráficos Intel® oferecem desempenho gráfico integrado transformador com suporte ao design fluido para criadores e experiências ricas em jogos.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Gráficos Intel® Iris® Pro",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Gráficos UHD Intel® com arquitetura X<sup class='small_text_sup_xe'>e</sup>",
                "tr_pretitle": "Experiências visuais imersivas",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Experimente edição de fotos e vídeo simplificada, devore conteúdo premium de vídeo em 4K ou jogue jogos populares com detalhes impressionantes.",
                "tr_photo_text": "Edição simplificada de fotos e vídeo",
                "tr_video_text": "Devore conteúdo de vídeo 4K",
                "tr_game_text": "Jogue jogos populares com detalhes impressionantes",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MUITO MAIS DO QUE IMAGENS BONITAS",
                "tr_subtitle": "Ao trabalhar em estreita colaboração com o processador (ou CPU), a placa gráfica (ou GPU) ajuda a determinar a execução e a aparência dos seus jogos. A VRAM, ou RAM de vídeo, dedicada exclusivamente à criação de gráficos, é uma maneira rápida de saber a situação da sua GPU.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB DE<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PARA GRÁFICOS DE JOGOS INTENSOS",
                "tr_subtitle": "O processamento gráfico mais potente exige uma placa gráfica separada, dedicada exclusivamente à criação de imagens nítidas e belas e animações fluidas",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB DE<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PARA GRÁFICOS DE JOGOS INTENSOS",
                "tr_subtitle": "O processamento gráfico mais potente exige uma placa gráfica separada, dedicada exclusivamente à criação de imagens nítidas e belas e animações fluidas",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB DE<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MUITO MAIS DO QUE IMAGENS BONITAS",
                "tr_subtitle": "Ao trabalhar em estreita colaboração com o processador (ou CPU), a placa gráfica (ou GPU) ajuda a determinar a execução e a aparência dos seus jogos. A VRAM, ou RAM de vídeo, dedicada exclusivamente à criação de gráficos, é uma maneira rápida de saber a situação da sua GPU.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB DE<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Potência de gráficos intensa",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "O processamento gráfico mais poderoso requer uma placa gráfica que possa se dedicar exclusivamente à criação de imagens bonitas e nítidas, e animação suave.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "PARA GRÁFICOS DE JOGOS INTENSOS",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "O processamento gráfico mais poderoso requer uma placa gráfica que possa se dedicar exclusivamente à criação de imagens bonitas e nítidas, e animação suave.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB DE<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "O processamento gráfico mais potente exige uma placa gráfica separada, dedicada exclusivamente à criação de imagens nítidas e belas e animações fluidas",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Proporciona capacidade gráfica suficiente para atender aos requisitos mínimos do jogo.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Para jogadores avançados que exigem jogos em nível de competição",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Para jogadores avançados que também utilizam o PC para criação intensa de conteúdo",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Vídeos vibrantes e jogos imersivos",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Aproveite ao máximo sua tela de alta resolução com os Gráficos HD Intel® e desfrute de uma excelente experiência multimídia e do processamento gráfico integrado.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Potencializando vídeos e imagens vibrantes",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Obtenha mais detalhes e cores brilhantes em streamings de vídeos e fotos.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Criação e jogos incríveis",
                "tr_pretitle": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "De entretenimento imersivo e criação de conteúdo avançada a jogos convencionais, os gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> oferecem desempenho gráfico revolucionário a PCs finos e leves.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_pretitle": "PARA GRÁFICOS DE JOGOS INTENSOS",
                "tr_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "O processamento gráfico mais potente exige uma placa gráfica separada, dedicada exclusivamente à criação de imagens nítidas e belas e animações fluidas",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB DE<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Dê asas à sua criatividade",
                "tr_pretitle": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Navegue por projetos criativos e mergulhe em uma ampla variedade de jogos com o poder combinado dos gráficos no processador e o primeiro mecanismo de gráficos dedicados Intel®.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "tr_pretitle": "PARA GRÁFICOS DE JOGOS INTENSOS",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "O processamento gráfico mais potente exige uma placa gráfica separada, dedicada exclusivamente à criação de imagens nítidas e belas e animações fluidas",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB DE<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Gráficos",
                "tr_title": "Extraordinários gráficos integrados",
                "tr_subtitle": "Desfrute de jogos, filmes e vídeos da Web com nitidez e detalhes surpreendentes.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Impulsione seus fluxos de trabalho"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Turbine seus jogos"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Turbine seus jogos"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Seu jogo, na&nbsp;próxima&nbsp;fase"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "DÊ ASAS À SUA CRIATIVIDADE",
                    "tr_specText": "Acelere sua produtividade onde estiver com os recursos de mídia mais abrangentes, como um <strong>codificador de hardware AV1 pioneiro do setor</strong> e criação de conteúdo acelerada por IA.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "DÊ ASAS À SUA CRIATIVIDADE",
                    "tr_specText": "Acelere sua produtividade com os recursos de mídia mais abrangentes, como um <strong>codificador de hardware AV1 pioneiro do setor</strong> e criação de conteúdo acelerada por IA.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "DÊ ASAS À SUA CRIATIVIDADE",
                    "tr_specText": "Experimente criação de conteúdo acelerada por IA de ponta com <strong>codificador de hardware AV1 pioneiro do setor</strong> e o avançado <strong>motor de mídia X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "DÊ ASAS À SUA CRIATIVIDADE",
                    "tr_specText": "Experimente criação de conteúdo acelerada por IA de ponta com <strong>codificador de hardware AV1 pioneiro do setor</strong> e o avançado <strong>motor de mídia X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "DÊ ASAS À SUA CRIATIVIDADE",
                    "tr_specText": "Experimente criação de conteúdo acelerada por IA de ponta com <strong>codificador de hardware AV1 pioneiro do setor</strong> e o avançado <strong>motor de mídia X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "PORTABILIDADE E DESEMPENHO JUNTOS",
                    "tr_specText": "Jogue os jogos mais recentes e obtenha desempenho turbinado com recursos de jogos modernos. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tecnologia Intel® de ampliação aprimorada por IA para um maior desempenho e jogos fluidos. <br/><br/>Compatibilidade com <strong>DirectX* 12 Ultimate:  </strong>leve seus jogos para um novo nível de realismo com tecnologias gráficas, como ray tracing e sombreamento de taxa variável."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "ASSUMA O CONTROLE DOS SEUS JOGOS",
                    "tr_specText": "O <strong>Centro de controle Intel® Arc™</strong> permite refinar sua experiência com um layout intuitivo e moderno. Obtenha facilmente as atualizações de driver, otimize seus jogos e muito mais."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "ASSUMA O CONTROLE DOS SEUS JOGOS",
                    "tr_specText": "O <strong>Centro de controle Intel® Arc™</strong> permite refinar sua experiência com um layout intuitivo e moderno. Obtenha facilmente as atualizações de driver, otimize seus jogos e muito mais."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "ASSUMA O CONTROLE DOS SEUS JOGOS",
                    "tr_specText": "Obtenha atualizações de driver estáveis e otimize muitos dos seus jogos favoritos com o software <strong>Centro de controle Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "MAXIMIZE SUA PLATAFORMA&nbsp;INTEL®",
                    "tr_specText": "Emparelhe com processadores Intel® Core™ compatíveis para liberar a <strong>tecnologia Intel® Deep Link</strong> e aprimorar sua experiência de criação, jogos e transmissão."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "MAXIMIZE SEU SISTEMA INTEL®",
                    "tr_specText": "Emparelhe com processadores Intel® Core™ compatíveis para liberar a <strong>tecnologia Intel® Deep Link</strong> e aprimorar sua experiência de criação, jogos e transmissão."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "MAXIMIZE SUA PLATAFORMA&nbsp;INTEL®",
                    "tr_specText": "Emparelhe com processadores Intel® Core™ compatíveis para liberar a <strong>tecnologia Intel® Deep Link</strong> e aprimorar sua experiência de criação, jogos e transmissão."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAXIMIZE SUA PLATAFORMA&nbsp;INTEL®",
                    "tr_specText": "Emparelhe com processadores Intel® Core™ compatíveis para liberar a <strong>tecnologia Intel® Deep Link</strong> e aprimorar sua experiência de criação, jogos e transmissão."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAXIMIZE SEU SISTEMA INTEL®",
                    "tr_specText": "Emparelhe com processadores Intel® Core™ compatíveis para liberar a <strong>tecnologia Intel® Deep Link</strong> e aprimorar sua experiência de criação, jogos e transmissão."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "SEJA O CENTRO DAS ATENÇÕES",
                    "tr_specText": "Transmissões perfeitas com tecnologias avançadas de streaming habilitadas pelo software <strong>Centro de controle Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "SEJA O CENTRO DAS ATENÇÕES",
                    "tr_specText": "Transmissões perfeitas com tecnologias avançadas de streaming habilitadas pelo software <strong>Centro de controle Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "EXPERIMENTE JOGOS IMERSIVOS DE ALTO DESEMPENHO",
                    "tr_specText": "Foque no seu melhor desempenho com os jogos AAA mais recentes em altas resoluções e altas taxas de atualização. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tecnologia Intel® de ampliação aprimorada por IA para um maior desempenho e jogos fluidos. <br/><br/>Compatibilidade com <strong>DirectX* 12 Ultimate:  </strong>leve seus jogos para um novo nível de realismo com tecnologias gráficas, como ray tracing e sombreamento de taxa variável."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "EXPERIMENTE JOGOS IMERSIVOS DE ALTO DESEMPENHO",
                    "tr_specText": "Foque no seu melhor desempenho com os jogos AAA mais recentes em altas resoluções e altas taxas de atualização. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tecnologia Intel® de ampliação aprimorada por IA para um maior desempenho e jogos fluidos. <br/><br/>Compatibilidade com <strong>DirectX* 12 Ultimate:  </strong>leve seus jogos para um novo nível de realismo com tecnologias gráficas, como ray tracing e sombreamento de taxa variável."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "DÊ UM IMPULSO AOS SEUS JOGOS",
                    "tr_specText": "Eleve o nível de sua experiência de jogos com: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tecnologia Intel® de ampliação aprimorada por IA para um maior desempenho e jogos fluidos. <br/><br/>Compatibilidade com <strong>DirectX* 12 Ultimate:  </strong>leve seus jogos para um novo nível de realismo com tecnologias gráficas, como ray tracing e sombreamento de taxa variável."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "EXPERIÊNCIA AVANÇADA EM JOGOS DINÂMICOS",
                    "tr_specText": "Aproveite a jogabilidade estável em muitos jogos AAA populares e alta taxa de atualização, desde atiradores de eSports de alta octanagem a acertos de RTS em ritmo acelerado. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tecnologia Intel® de ampliação aprimorada por IA para um maior desempenho e jogos fluidos. <br/><br/>Compatibilidade com <strong>DirectX* 12 Ultimate:  </strong>leve seus jogos para um novo nível de realismo com tecnologias gráficas, como ray tracing e sombreamento de taxa variável."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "EXPERIMENTE JOGOS IMERSIVOS DE ALTO DESEMPENHO",
                    "tr_specText": "Foque no seu melhor desempenho com os jogos AAA mais recentes em altas resoluções e altas taxas de atualização. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tecnologia Intel® de ampliação aprimorada por IA para um maior desempenho e jogos fluidos. <br/><br/>Compatibilidade com <strong>DirectX* 12 Ultimate:  </strong>leve seus jogos para um novo nível de realismo com tecnologias gráficas, como ray tracing e sombreamento de taxa variável."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Um universo de gráficos que cabe em todos os&nbsp;bolsos"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "ACELERE SEUS QUADROS, ACELERE SEUS JOGOS",
                    "tr_specText": "A tecnologia Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling (X<sup class='small_text_sup_xe'>e</sup>SS) leva seus jogos para o próximo nível com ampliação de imagem aprimorada por IA, para máximo desempenho com alta fidelidade de imagem. O X<sup class='small_text_sup_xe'>e</sup>SS é otimizado para produtos gráficos Intel® Arc™ para que você possa aproveitar ao máximo a aceleração de hardware do Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX)."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "OS MELHORES RECURSOS&nbsp;VISUAIS ESTÃO AQUI",
                    "tr_specText": "Com suporte completo para DirectX* 12 Ultimate em todos os gráficos Intel® Arc™, você desfrutará de uma jogabilidade impressionante e imersiva graças às tecnologias gráficas mais recentes, como traçado de raio, sombreamento de taxa variável, sombreamento de malha e feedback do amostrador, os fundamentos dos jogos de última geração."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "CRIAÇÃO&nbsp;DE&nbsp;FOTOS, VÍDEO E MÍDIA SEM&nbsp;INTERRUPÇÕES",
                    "tr_specText": "Dê asas à sua imaginação e cative o público com criação de conteúdo digital rica em recursos usando um mecanismo de mídia hiperavançado, ampliado por IA e acelerado com a tecnologia Intel®. Crie um conteúdo incrível, com gráficos compatíveis com todos os principais formatos de mídia atuais, e mantenha-se atualizado com os mais avançados recursos de codificação de vídeo AV1."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "EXPERIMENTE CRIAÇÃO E JOGOS COM IA DE PONTA",
                    "tr_specText": "Os gráficos Intel® Arc™ desbloqueiam recursos de IA potentes, desde a criação avançada até jogos imersivos. Aproveite ao máximo o software de criação avançado mais recente, incluindo geração de texto para imagem e edição de vídeo acelerada por IA. Além disso, aproveite a jogabilidade de alta resolução por meio da ampliação de imagem X<sup class='small_text_sup_xe'>e</sup>SS acelerada por IA."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Os gráficos profissionais começam aqui"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "ÓTIMO PARA CRIADORES, DESIGNERS E ENGENHEIROS EM QUALQUER LUGAR",
                    "tr_specText": "Disponíveis em processadores Intel® Core™ Ultra selecionados da série H, os gráficos Intel® Arc™ Pro integrados proporcionam o dobro de melhoria de desempenho gráfico com certificações de fornecedores independentes de software (ISV) para confiabilidade adicional e fornecem aceleração de hardware de IA e traçado de raio eficientes para criação de conteúdo impressionante em qualquer lugar.<br/><br/><span class='expandable_heading'>Certificações de software<br/></span><br/>A Intel vem trabalhando lado a lado com centenas de empresas de software ao longo dos anos, e essa experiência inigualável está presente nas nossas placas gráficas Intel® Arc™ Pro série A. As certificações são tão importantes para nós quanto para você."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "VIABILIZANDO A IA",
                    "tr_specText": "Essa categoria de GPUs inclui uma ampla seleção de excelentes tecnologias, entre elas, suporte para suas ferramentas de IA compatíveis com aceleração de IA dedicada integrada onde você precisar."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "TRAÇADO DE RAIO SEM CUSTO PREMIUM",
                    "tr_specText": "As placas gráficas precisam fazer mais do que simplesmente enviar pixels para os monitores; elas também precisam acelerar a criação de belas imagens. A tecnologia de hardware de traçado de raio integrada aos gráficos Intel® Arc™ Pro série A permite que o software compatível comece a explorar esses novos fluxos de trabalho profissionais. O traçado de raio é normalmente considerado disponível apenas em gráficos caros, mas a mais recente categoria de gráficos profissionais da Intel ultrapassa essa expectativa."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "CAPACITANDO CRIADORES COM TELAS IMERSIVAS",
                    "tr_specText": "Com suporte completo para DirectX* 12 Ultimate em todos os gráficos Intel® Arc™, você desfrutará de uma jogabilidade impressionante e imersiva graças às tecnologias gráficas mais recentes, como traçado de raio, sombreamento de taxa variável, sombreamento de malha e feedback do amostrador, os fundamentos dos jogos de última geração."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "IMAGENS DINAMICAMENTE OTIMIZADAS",
                    "tr_specText": "Com decodificação por hardware dedicada nas GPUs Pro, o Dolby Vision* adiciona maior profundidade, contraste mais nítido e mais cores à sua tela compatível. O Dolby Vision* libera todo o potencial da tecnologia HDR ao otimizar dinamicamente a qualidade da imagem com base em seu serviço, dispositivo de exibição e plataforma para oferecer visuais impressionantes sempre."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "SOFTWARE VERIFICADO POR ESPECIALISTAS",
                    "tr_specText": "A Intel vem trabalhando lado a lado com centenas de empresas de software ao longo dos anos, e essa experiência incomparável está inserida nos nossos gráficos Intel® Arc™ Pro série A. As certificações e qualificações de fluxo de trabalho de software são tão importantes para nós quanto para você."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "TRAÇADO DE RAIO SEM CUSTO PREMIUM",
                    "tr_specText": "As placas gráficas precisam fazer mais do que simplesmente enviar pixels para os monitores; elas também precisam acelerar a criação de belas imagens. A tecnologia de hardware de traçado de raio integrada aos gráficos Intel® Arc™ Pro série A permite que o software compatível comece a explorar esses novos fluxos de trabalho profissionais. O traçado de raio é normalmente considerado disponível apenas em gráficos caros, mas a mais recente categoria de gráficos profissionais da Intel ultrapassa essa expectativa."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "VÁRIOS MONITORES COM UMA PEQUENA GPU PARA PORTÁTEIS",
                    "tr_specText": "Os gráficos Intel® Arc™ Pro para notebooks podem suportar até quatro telas ultragrandes para aprimorar a eficiência do seu fluxo de trabalho. E, com o suporte de alta faixa dinâmica (HDR), você terá tudo o que precisa para a configuração de vários monitores em casa ou no escritório, digna de mídias sociais."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "POTENCIAL DE LARGURA DE BANDA DE MEMÓRIA TOTAL",
                    "tr_specText": "A largura de banda da placa gráfica é um fator importante em uma estação de trabalho. Se escolhida incorretamente, pode desacelerar os fluxos de trabalho profissionais, razão pela qual garantimos que a GPU Intel® Arc™ Pro série A seja compatível com o moderno PCIe* 4.0, sem comprometer a compatibilidade com versões anteriores. A memória gráfica é aprimorada ainda mais por uma alta velocidade de largura de banda, permitindo que os dados do projeto sejam acessados ainda mais rapidamente pelo software."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "VIVA CADA FRAME",
                    "tr_specText": "<span class='expandable_heading'>Potência da IA. Qualidade da Intel.<br/></span><br/>O Intel® X<sup class='small_text_sup_xe'>e</sup>SS é uma tecnologia de ampliação de imagem aprimorada por IA que aumenta seu desempenho sem sacrificar a beleza de cada quadro, para que você possa experimentar o seu jogo como ele deve ser."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "EM TEMPO REAL.<br/> COM IMERSÃO REAL.<br/> É O ÁPICE DO REALISMO.",
                    "tr_specText": "Mundos mais realistas o esperam. Com traçado de raio integrado, você experimentará tudo, desde sombras e reflexos realistas até uma iluminação magistral. Em outras palavras, é uma experiência visual de jogos que você não pode perder."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "MELHOR GAMEPLAY, MELHOR COMPARTIHAMENTO",
                    "tr_specText": "Dê ao seu público a jogabilidade perfeita que ele merece ver. Com os recursos de codificação AV1 acelerados por hardware dos gráficos Intel® Arc™, o público será cativado por todos os seus movimentos. Maior qualidade visual com a mesma taxa de bits equivale a melhor transmissão enquanto economiza largura de banda."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Dando as boas-vindas a todos os criadores",
                    "tr_specText": "Experiências novas e aprimoradas de IA estão disponíveis ao seu alcance. Com aceleradores de IA avançados integrados aos gráficos Intel® Arc™ série A, você passa mais tempo criando e menos tempo esperando em muitos aplicativos.<br/><br/>Leve sua criação de vídeo para o próximo nível e lidere com vídeo AV1 de alta qualidade e eficiente em armazenamento, com recursos avançados de hardware AV1 integrados aos gráficos Intel® Arc™."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema Operacional",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Tudo o que você precisa para ser produtivo",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Desempenho poderoso que é fino, leve e feito para durar — tudo ao seu alcance. Com uma ótima autonomia da bateria, belas telas de toque* e as mais recentes unidades de estado sólido (SSDs), você encontrará o que procura em um PC com Windows* 10.",
                "tr_noteSection": "*Dependente do hardware",
                "titleArray": [
                  {
                    "tr_title": "Menos é mais",
                    "tr_subtitle": "Os PCs atuais geralmente utilizam menos energia, de modo que você obtém mais autonomia da sua bateria, em média, mais de oito horas de reprodução de vídeo.**",
                    "tr_noteSection": "**A autonomia da bateria varia bastante de acordo com as configurações, o uso e outros fatores."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema Operacional",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "TUDO O QUE VOCÊ PRECISA PARA SER PRODUTIVO",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Desempenho poderoso que é fino, leve e feito para durar — tudo ao seu alcance. Com uma ótima autonomia da bateria, belas telas de toque* e as mais recentes unidades de estado sólido (SSDs), você encontrará o que procura em um PC com Windows* 10.",
                "tr_noteSection": "*Dependente do hardware",
                "titleArray": [
                  {
                    "tr_title": "MENOS É MAIS",
                    "tr_subtitle": "Os PCs atuais geralmente utilizam menos energia, de modo que você obtém mais autonomia da sua bateria, em média, mais de oito horas de reprodução de vídeo.**",
                    "tr_noteSection": "**A autonomia da bateria varia bastante de acordo com as configurações, o uso e outros fatores."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema Operacional",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Otimizado para segurança e desempenho",
                "tr_pretitle": "Windows* 10 no modo S",
                "tr_subtitle": "Com o Windows* 10 no modo S, você tem uma camada extra de proteção para trabalhar, jogar e explorar com a confiança de que seu dispositivo está seguro."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema Operacional",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "OTIMIZADO PARA SEGURANÇA E DESEMPENHO",
                "tr_title": "Windows* 10 no modo S",
                "tr_subtitle": "Com o Windows* 10 no modo S, você tem uma camada extra de proteção para trabalhar, jogar e explorar com a confiança de que seu dispositivo está seguro."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema Operacional",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Faça coisas grandiosas",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Desempenho, preço e variedade para escolher. Desde dispositivos 2 em 1 até notebooks finos e leves, telas de toque e teclados destacáveis, não importa o seu orçamento ou estilo, há um PC Windows* ideal para você."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema Operacional",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "FAÇA COISAS GRANDIOSAS",
                "tr_title": "Windows*",
                "tr_subtitle": "Desempenho, preço e variedade para escolher. Desde dispositivos 2 em 1 até notebooks finos e leves, telas de toque e teclados destacáveis, não importa o seu orçamento ou estilo, há um PC Windows* ideal para você."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema Operacional",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema Operacional",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memória",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "QUANTO DE RAM VOCÊ PRECISA?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Dia a dia — 2 GB A 8 GB DE RAM",
                    "tr_badgeSubTitle": "Ideal para quem usa o PC principalmente para processamento de texto e navegação na web, e costuma se concentrar em um único programa de cada vez."
                  },
                  {
                    "tr_badgeTitle": "Potente — 8 GB A 16 GB DE RAM",
                    "tr_badgeSubTitle": "Quando equipados com mais RAM, suas aplicações operarão com mais velocidade e você também conseguirá executar mais aplicações simultaneamente."
                  },
                  {
                    "tr_badgeTitle": "Avançado - MAIS DE 16 GB DE RAM",
                    "tr_badgeSubTitle": "Se você se interessa por games, edição de foto ou vídeo, ou por modelagem 3D, precisará ter RAM adicional para lidar com esses programas intensivos."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB de RAM para multitarefas do dia a dia",
                "tr_subtitle": "A memória permitirá o carregamento e a execução estáveis de aplicações básicas, como processamento de texto e navegação na web.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB DE<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB de RAM para multitarefas potentes",
                "tr_subtitle": "Se você utiliza vários programas simultaneamente, [memory_total] GB de memória permitirão alternar entre eles com facilidade.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB DE<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB de RAM para multitarefas avançadas",
                "tr_subtitle": "Você terá bastante memória para executar vários programas intensivos ao mesmo tempo, sem retardar o computador.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB DE<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "Memória de [Value1] GB = Intel® Optane™ Memory de [Value2] GB + RAM de [Value3] GB",
                "tr_subtitle": "Esta combinação de soluções de memória lhe dará o desempenho acelerado de disco rígido do Intel® Optane™ Memory, além de maior capacidade de resposta da sua RAM.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB DE<br/></span><span class='subtitle-value'>MEMÓRIA</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ Memory",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Menos espera. Mais daquilo que você ama.",
                "tr_subtitle": "Ao trabalhar com a memória do seu sistema, o Intel® Optane™ Memory proporciona acesso mais rápido aos programas utilizados com frequência, para que inicializem e carreguem no instante em que você precisar deles.",
                "tr_pretitle": "Intel® Optane™ Memory de [XX] GB",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "O QUE A MEMÓRIA FAZ PELO MEU PC?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Mais agilidade na criação de conteúdo",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Jogos mais rápidos",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Mais facilidade na multitarefa",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Mais agilidade na abertura dos programas",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ Memory",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Acelere seus jogos</span>",
                "tr_pretitle": "Intel® Optane™ Memory de [XX] GB",
                "tr_pretitle1": "O Intel® Optane™ Memory de [XX] GB acelera seus jogos",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Abra jogos até <br/><span class=\"optane-game\">98% mais rápido</span><br/>A partir da unidade de dados com um processador [optane_legal_processor_name] com Intel® Optane™ Memory<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Carregue níveis até <br/><span class=\"optane-game\">4,1x mais rápido</span><br/>A partir da unidade de dados com um processador [optane_legal_processor_name] com Intel® Optane™ Memory<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Intel® Optane™ Memory",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Acelere seus jogos</span>",
                "tr_pretitle": "Intel® Optane™ Memory de [XX] GB",
                "tr_pretitle1": "O Intel® Optane™ Memory de [XX] GB acelera seus jogos",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Abra jogos até <br/><span class=\"optane-game\">98% mais rápido</span><br/>A partir da unidade de dados com um processador [optane_legal_processor_name] com Intel® Optane™ Memory<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Carregue níveis até <br/><span class=\"optane-game\">4,1x mais rápido</span><br/>A partir da unidade de dados com um processador [optane_legal_processor_name] com Intel® Optane™ Memory<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Memória de [Value1] GB = Intel® Optane™ Memory de [Value2] GB + RAM de [Value3] GB",
                "tr_subtitle": "Esta combinação de soluções de memória lhe dará o desempenho acelerado de disco rígido do Intel® Optane™ Memory, além de maior capacidade de resposta da sua RAM.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB de RAM para multitarefas",
                    "tr_subtitle": "Se você utiliza vários programas simultaneamente, [memory_total] GB de memória permitirão alternar entre eles com facilidade."
                  },
                  {
                    "tr_title": "Para tarefas diárias",
                    "tr_subtitle": "A memória permitirá o carregamento e a execução estáveis de aplicações básicas, como processamento de texto e navegação na web."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB DE&nbsp;RAM",
                "tr_pretitle": "PARA MAIS MULTITAREFAS",
                "tr_subtitle": "A RAM oferece ao processador acesso a arquivos de programas, permitindo à CPU acessá-los mais rapidamente, além de trabalhar em várias tarefas ao mesmo tempo.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Memória padrão",
                    "tr_badgeSubTitle": "Até 8 GB de RAM"
                  },
                  {
                    "tr_badgeTitle": "Memória avançada",
                    "tr_badgeSubTitle": "Mais de 8 GB e até 16 GB de RAM"
                  },
                  {
                    "tr_badgeTitle": "Memória Elite",
                    "tr_badgeSubTitle": "Acima de 16&nbsp;GB de RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB para mais multitarefa",
                "tr_pretitle": "PARA MAIS MULTITAREFAS",
                "tr_subtitle": "A RAM oferece ao processador acesso a arquivos de programas, permitindo à CPU acessá-los mais rapidamente, além de trabalhar em várias tarefas ao mesmo tempo.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Memória padrão",
                    "tr_badgeSubTitle": "Até 8 GB de RAM"
                  },
                  {
                    "tr_badgeTitle": "Memória avançada",
                    "tr_badgeSubTitle": "Mais de 8 GB e até 16 GB de RAM"
                  },
                  {
                    "tr_badgeTitle": "Memória Elite",
                    "tr_badgeSubTitle": "Acima de 16&nbsp;GB de RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESSA MEMÓRIA SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Padrão",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>DE RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avançado",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>DE RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Elite",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>DE RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memória",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "COMO ESSA MEMÓRIA SE COMPARA?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "COMO ESSA MEMÓRIA SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Diariamente",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>DE RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Poderoso",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>DE RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avançado",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>DE RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Armazenamento",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "QUANTO SEU PC PODE AGUENTAR?",
                "tr_subtitle": "Não precisa mais tentar adivinhar. Mostraremos exatamente o que você pode armazenar este&nbsp;computador",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Crie uma biblioteca de</span> <br/><span class=\"storage-standard\">[num_songs] músicas<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>com base em uma média de 5 MB por&nbsp;música",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Crie uma biblioteca de</span><br/><span class=\"storage-standard\">[num_games] jogos<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>com base em uma média de 40 GB por&nbsp;jogo",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Armazenamento para</span><br/><span class=\"storage-standard\">[num_videos] vídeos<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>com base em uma média de 5 GB por&nbsp;vídeo",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Tire e armazene</span><br/><span class=\"storage-standard\">[num_photos] fotos<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>com base em uma média de 4 MB por&nbsp;foto",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "HD DE [capacity] GB"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "SSD DE [capacity] GB"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "Intel® Optane™ Memory de [total_optane_memory] GB + SSD de [convertedSSD] GB"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARMAZE&shy;NAMENTO",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "Armazenamento em SSD de [capacity] GB",
                "tr_preTitle1": "Armazenamento em SSD de [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Este PC dispõe de uma unidade de estado sólido com [capacity] GB de armazenamento. Isso significa que você poderá salvar um número impressionante de fotos, vídeos e programas e acessá-los muito mais rapidamente do que em uma unidade de disco rígido.",
                    "tr_title": "Um lugar para tudo no seu PC"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Este PC dispõe de uma unidade de estado sólido com [capacity] TB de armazenamento. Isso significa que você poderá salvar um número impressionante de fotos, vídeos e programas e acessá-los muito mais rapidamente do que em uma unidade de disco rígido.",
                    "tr_title": "Este dispositivo possui armazenamento extraordinário"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARMAZE&shy;NAMENTO",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "Armazenamento em HD de [capacity] GB",
                "tr_preTitle1": "Armazenamento em HD de [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Unidades de disco rígido são uma boa opção para aqueles que desejam grande volume de armazenamento diretamente no&nbsp;seu&nbsp;PC.",
                    "tr_title": "Um lugar para tudo no seu PC"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Unidades de disco rígido são uma boa opção para aqueles que desejam grande volume de armazenamento diretamente no&nbsp;seu&nbsp;PC.",
                    "tr_title": "Este dispositivo possui armazenamento extraordinário"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>DE ARMAZE&shy;NAMENTO</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARMAZE&shy;NAMENTO",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "QUANTO SEU PC PODE AGUENTAR?",
                "tr_subtitle": "Não precisa mais tentar adivinhar. Mostraremos exatamente o que você pode armazenar este&nbsp;computador",
                "titleArray": [
                  {
                    "tr_title": "HD de [XX] GB"
                  },
                  {
                    "tr_title": "SSD de [XX] GB"
                  },
                  {
                    "tr_title": "Intel® SSD de [total_capacity] GB acelerado pelo Intel® Optane™ Memory de [optane_memory_size] GB"
                  },
                  {
                    "tr_title": "Intel® SSD de [total_capacity] TB acelerada pelo Intel® Optane™ Memory de [optane_memory_size] GB"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD] GB + HD de [convertedHDD] GB"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD] TB + HD de [convertedHDD] GB"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD] GB + HD de [convertedHDD] TB"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD] TB + HD de [convertedHDD] TB"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                        "tr_badgeSubTitle": "HD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                        "tr_badgeSubTitle": "HD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARMAZE&shy;NAMENTO",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "Armazenamento em SSD de [convertedSSD] GB +&nbsp;HD&nbsp;de&nbsp;[convertedHDD]&nbsp;GB",
                "tr_preTitle1": "Armazenamento em SSD de [convertedSSD] TB +&nbsp;HD&nbsp;de&nbsp;[convertedHDD]&nbsp;GB",
                "tr_preTitle2": "Armazenamento em SSD de [convertedSSD] GB +&nbsp;HD&nbsp;de&nbsp;[convertedHDD]&nbsp;TB",
                "tr_preTitle3": "Armazenamento em SSD de [convertedSSD] TB +&nbsp;HD&nbsp;de&nbsp;[convertedHDD]&nbsp;TB",
                "titleArray": [
                  {
                    "tr_title": "Um lugar para tudo no seu PC",
                    "tr_title1": "Este dispositivo possui armazenamento extraordinário",
                    "tr_subtitle": "Este PC dispõe de uma unidade de estado sólido com [convertedSSD] GB de armazenamento e uma unidade de disco rígido de [convertedHDD] GB. Isso fornecerá uma capacidade enorme, além de desempenho de armazenamento rápido.",
                    "tr_subtitle1": "Este PC dispõe de uma unidade de estado sólido com [convertedSSD] TB de armazenamento e uma unidade de disco rígido de [convertedHDD] GB. Isso fornecerá uma capacidade enorme, além de desempenho de armazenamento rápido.",
                    "tr_subtitle2": "Este PC dispõe de uma unidade de estado sólido com [convertedSSD] GB de armazenamento e uma unidade de disco rígido de [convertedHDD] TB. Isso fornecerá uma capacidade enorme, além de desempenho de armazenamento rápido.",
                    "tr_subtitle3": "Este PC dispõe de uma unidade de estado sólido com [convertedSSD] TB de armazenamento e uma unidade de disco rígido de [convertedHDD] TB. Isso fornecerá uma capacidade enorme, além de desempenho de armazenamento rápido."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARMAZE&shy;NAMENTO",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Obtenha acesso acelerado a armazenamento de alta capacidade com Intel® Optane™ Memory com SSD.",
                "tr_title": "Bem-vindos, arquivos gigantes",
                "tr_pretitle": "Intel® SSD de [total_capacity] GB acelerado pelo Intel® Optane™ Memory de [optane_memory_size] GB",
                "tr_pretitle1": "Intel® SSD de [total_capacity] TB acelerada pelo Intel® Optane™ Memory de [optane_memory_size] GB",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Essa solução de armazenamento revolucionária é a primeira a combinar o Intel® Optane™ Memory e uma SSD em um único chip. Veja o que essa solução faz por você:",
                    "tr_title": "O QUE É INTEL® OPTANE™ MEMORY COM SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Abra o que precisar rapidamente",
                    "tr_badgeSubTitle": "Ao pré-carregar arquivos e programas usados com frequência, você poderá passar mais tempo criando e menos tempo esperando.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Mais velocidade de armazenamento",
                    "tr_badgeSubTitle": "Você terá o espaço necessário para os arquivos de mídia com o desempenho ágil de uma SSD.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Mais responsivo com o passar do tempo",
                    "tr_badgeSubTitle": "O Intel® Optane™ Memory com SSD assimila seu jeito de trabalhar, tornando seu PC mais eficiente.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARMAZE&shy;NAMENTO",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Mantenha todas as suas mídias por perto graças a um armazenamento espaçoso e confiável.",
                "tr_title": "Espaço para todas as suas fotos, vídeos e músicas"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tela",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] milhões de pixels para deslizar, pinçar e se surpreender",
                "tr_subtitle": "Interaja com seu PC como com um smartphone, usufruindo de controles intuitivos e detalhes mais nítidos e realistas.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>[screenResolutionType] de<br/></span><span class='value'>[screenDiagonal]″</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolução: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels: [screenResolution] milhões"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Tela de [screenDiagonal]″",
                    "tr_badgeSubTitle": "Altura da tela: [screenHeight] polegadas",
                    "tr_badgePreTitle": "Largura da tela: [YY] polegadas"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Tela sensível ao toque",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tela",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Mergulhe em [screenResolution]&nbsp;milhões de pixels",
                "tr_subtitle": "Tudo é uma questão de pixels. Quanto mais pixels você tiver, mais nítidas e mais vivas serão as imagens.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>[screenResolutionType] de<br/></span><span class='value'>[screenDiagonal]″</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolução: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels: [screenResolution] milhões"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Tela de [screenDiagonal]″",
                    "tr_badgeSubTitle": "Altura da tela: [screenHeight] polegadas",
                    "tr_badgePreTitle": "Largura da tela: [YY] polegadas"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tela",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] polegadas <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "A JANELA PARA OS MUNDOS DOS SEUS JOGOS",
                "tr_subtitle": "Assim como para uma TV, suas maiores preocupações para uma tela voltada para jogar serão tamanho e resolução.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolução: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels: [screenResolution] milhões"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Tela de [screenDiagonal]″",
                    "tr_badgeSubTitle": "Altura da tela: [screenHeight] polegadas",
                    "tr_badgePreTitle": "Largura da tela: [screenWidth] polegadas"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Tela sensível ao toque"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tela",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Este dispositivo possui uma tela excelente",
                "tr_subtitle": "Com mais de oito vezes a quantidade de pixels do HD, seus vídeos e jogos alcançarão um nível totalmente novo de cores e nitidez.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolução: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels: [screenResolution] milhões"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Tela de [screenDiagonal]″",
                    "tr_badgeSubTitle": "Altura da tela: [screenHeight] polegadas",
                    "tr_badgePreTitle": "Largura da tela: [YY] polegadas"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Tela",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] milhões de pixels para tocar e deslizar",
                    "tr_subTitle": "Visualize e interaja com tudo o que você mais gosta, com detalhes mais nítidos e surpreendentes."
                  },
                  {
                    "tr_title": "[screenResolution] milhões de pixels para aprimorar seu mundo digital",
                    "tr_subTitle": "Tudo é uma questão de pixels. Quanto mais pixels você tiver, mais nítidas e mais vivas serão as imagens."
                  },
                  {
                    "tr_title": "Este dispositivo possui uma tela excelente",
                    "tr_subTitle": "Com quase quatro vezes os pixels de HD, seus vídeos e jogos alcançarão um nível totalmente novo de nitidez e cor."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectividade",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "A tecnologia Thunderbolt™ 3 oferece as conexões mais rápidas e versáteis para qualquer encaixe, monitor ou dispositivo de dados.",
                "tr_title": "Uma porta que faz tudo",
                "tr_title1": "UMA PORTA QUE FAZ TUDO",
                "titleArray": [
                  {
                    "tr_title": "COMO POSSO UTILIZAR A TECNOLOGIA THUNDERBOLT™ 3?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tecnologia Thunderbolt™&nbsp;3"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "Monitores 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Conecte-se a monitores com resolução, contraste e cores surpreendentes"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Placa gráfica externa",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Proporcionam instantaneamente uma importante atualização gráfica a&nbsp;qualquer&nbsp;PC"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Agilidade na operação de rede",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Oferece rápidas conexões ponto a ponto"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectividade",
                "detailBGUrl": "",
                "tr_subtitle": "A tecnologia Thunderbolt™ 3 transfere dados a velocidades mais elevadas do que os USBs convencionais, permitindo transferir arquivos rapidamente e integrar periféricos, como vários monitores ou placas gráficas externas&nbsp;facilmente.",
                "tr_title": "Tecnologia Thunderbolt™&nbsp;3",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "DADOS ULTRARRÁPIDOS",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Tecnologia Thunderbolt™&nbsp;3",
                    "tr_badgeSubTitle": "40 Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectividade",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Uma porta e um cabo universais oferecem suporte a todos os seus acessórios favoritos</li> <li>Carregamento de notebook confiável</li> <li>A certificação robusta oferece tranquilidade</li></ul>",
                "tr_title": "Conectividade com&nbsp;cabo verdadeiramente&nbsp;universal",
                "tr_title1": "CONECTIVIDADE COM&nbsp;CABO VERDADEIRAMENTE&nbsp;UNIVERSAL",
                "tr_noteSection": "<sup>†</sup>Com base em requisitos mínimos de especificação",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "COMO POSSO UTILIZAR A TECNOLOGIA THUNDERBOLT™ 4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tecnologia Thunderbolt™ 4"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Crie",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Conecte um monitor externo e armazenamento rápido para visualizar e editar fotos e vídeos."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Acople",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Crie uma configuração de escritório simples e organizada conectando seu notebook a todos os seus acessórios com um único cabo."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Jogue",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Adicione mais capacidade de SSD e HD com velocidades de até 3.000 MB/s para armazenar bibliotecas de jogos e arquivos de mídia."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Desempenho máximo<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x mais rápido do que o USB* 3.2 <br/>2x mais rápido do que o HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectividade",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Aproveite o mesmo desempenho incrível da tecnologia Thunderbolt™ 3 com ainda mais recursos que permitem que você conecte e use facilmente os mais recentes encaixes Thunderbolt™, monitores, armazenamento rápido ou qualquer acessório USB*.",
                "tr_title": "Tecnologia Thunderbolt™ 4",
                "tr_pretitle": "CONECTIVIDADE COM&nbsp;CABO VERDADEIRAMENTE&nbsp;UNIVERSAL",
                "tr_noteSection": "<sup>†</sup>Com base em requisitos mínimos de especificação",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Um cabo universal Thunderbolt™ 4 pode substituir todos os seus cabos USB-C* para tornar a escolha de cabos rápida&nbsp;e&nbsp;fácil."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Os encaixes da tecnologia Thunderbolt™ 4 oferecem até quatro portas Thunderbolt™ para maior flexibilidade ao conectar qualquer um dos seus acessórios."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Desempenho máximo<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x mais rápido do que o USB* 3.2 <br/>2x mais rápido do que o HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectividade",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Uma porta e um cabo universais oferecem suporte a todos os seus acessórios favoritos</li> <li>Carregamento de notebook confiável</li> <li>A certificação robusta oferece tranquilidade</li></ul>",
                "tr_title": "Maximize sua experiência com acessórios Thunderbolt™",
                "tr_noteSection": "<sup>†</sup>Com base em requisitos mínimos de especificação",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "COMO POSSO UTILIZAR A TECNOLOGIA THUNDERBOLT™ 4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Crie",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Conecte um monitor externo e armazenamento rápido para visualizar e editar fotos e vídeos."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Acople",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Crie uma configuração de escritório simples e organizada conectando seu notebook a todos os seus acessórios com um único cabo."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Jogue",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Adicione mais capacidade de SSD e HD com velocidades de até 3.000 MB/s para armazenar bibliotecas de jogos e arquivos de mídia."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Desempenho máximo<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x mais rápido do que o USB* 3.2 <br/>2x mais rápido do que o HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "Os tipos de Wi‑Fi mais comuns são:",
                "tr_title": "COMPREENDENDO OS PADRÕES DE WI‑FI",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "O Wi-Fi 6 possibilita maiores velocidades, mesmo quando muitos usuários estão usando o mesmo sinal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Com a mais recente inovação em Wi-Fi da Intel, você pode atingir velocidades mais rápidas do que com o Wi-Fi Gigabit, mesmo com vários usuários compartilhando o mesmo sinal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Maior avanço em Wi‑Fi em 20 anos, o Wi‑Fi 6E abre novos canais de alta velocidade para mais desempenho e confiabilidade, e menos interferência."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "O Wi-Fi 6 possibilita maiores velocidades, mesmo quando muitos usuários estão usando o mesmo sinal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Todos os benefícios do Wi-Fi 6E combinados com o mecanismo de atribuição de prioridades Intel® Killer™, que transfere a largura de banda para os sites e aplicativos com maior demanda."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Com menos concorrência nos canais de alta velocidade, você desfrutará de desempenho e confiabilidade aprimorados."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6",
                    "tr_badgeSubTitle": "O Wi-Fi 6 possibilita maiores velocidades, mesmo quando muitos usuários estão usando o mesmo sinal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Com o desempenho inteligente do mecanismo de atribuição de prioridades Intel® Killer™ e menos interferência do Wi-Fi 6, sua experiência on-line será mais rápida e ágil.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Com menos concorrência nos canais de alta velocidade, você desfrutará de desempenho e confiabilidade aprimorados."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Velocidades de Wi-Fi para PC superiores a 5 Gbps com latência ultrabaixa consistente: compartilhe arquivos em segundos ao invés de minutos."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "O Intel® Killer™ Wi-Fi 7 (5 Gig) é a mais recente tecnologia que permite \"extrema\" confiabilidade, velocidades e baixa latência por meio da otimização de rede baseada em IA."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "O Wi-Fi 6 possibilita maiores velocidades, mesmo quando muitos usuários estão usando o mesmo sinal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) com velocidades acima de 1 Gbps, confiabilidade e baixa latência com o uso do canal de 6 GHz."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Melhor eficiência, capacidade e velocidade em comparação com o Wi-Fi 5 em redes congestionadas."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Tenha uma conexão melhor com Wi‑Fi [XX] e aproveite a internet com velocidades mais altas<sup>†</sup> e mais confiabilidade.",
                "tr_title": "Obtenha mais alcance e velocidade",
                "tr_noteSection": "<sup>†</sup>A capacidade real de transmissão sem fios e/ou o alcance irão variar.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Na era dos jogos on-line e com vários jogadores, permanecer conectado é mais importante do que nunca. Além da velocidade, você também quer sinal sem fio, que preserve as velocidades mesmo à distância.",
                "tr_title": "[XX]",
                "tr_pretitle": "O SEGREDO ESTÁ NA CONEXÃO",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Conecte-se com Intel® Wi‑Fi 6 (Gig+) a roteadores Wi‑Fi 6 com suporte a canal de 160&nbsp;MHz para proporcionar conexões mais rápidas e maior confiabilidade para mais usuários&nbsp;ativos.",
                "tr_title": "Velocidades quase 3x mais rápidas com Intel®&nbsp;<span class='no-warp'>Wi‑Fi 6  </span>(Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Do que o Wi‑Fi padrão AC. Alguns recursos estão disponíveis apenas em SKUs específicas. Consulte o fabricante para obter detalhes. Para obter mais informações sobre Wi‑Fi, acesse intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "O Intel® Wi-Fi 6 de banda dupla de nível básico oferece suporte ao desempenho aprimorado para as necessidades essenciais de conectividade, como e-mail e navegação na Internet, em redes congestionadas.<sup>9</sup>",
                "tr_title": "Wi‑Fi essencial",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Na era dos jogos on-line e com vários jogadores, permanecer conectado é mais importante do que nunca. Além da velocidade, você também quer sinal sem fio, que preserve as velocidades mesmo à distância.",
                "tr_title": "[XX]",
                "tr_pretitle": "O SEGREDO ESTÁ NA CONEXÃO",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Ter esses novos canais Wi‑Fi significa que você não precisa compartilhar largura de banda com os dispositivos mais antigos do seu bairro, oferecendo streaming estável, navegação rápida, além de trabalho e aprendizagem remotos sem interrupção.",
                "tr_title": "Conecte-se sem concorrência",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Tenha uma conexão melhor com [XX] e desfrute da internet com velocidades mais rápidas<sup>†</sup> e maior confiabilidade.",
                "tr_title": "Obtenha mais alcance e velocidade",
                "tr_noteSection": "<sup>†</sup>A capacidade real de transmissão sem fios e/ou o alcance irão variar.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Na era dos jogos on-line e com vários jogadores, permanecer conectado é mais importante do que nunca. Além da velocidade, você também quer sinal sem fio, que preserve as velocidades mesmo à distância.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "O SEGREDO ESTÁ NA CONEXÃO",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Wi‑Fi ultrarrápido integrado",
                "tr_subtitle": "Tire da tomada, mas permaneça conectado ao seu mundo on&#8209;line."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Os jogos on-line ganharam um excelente aprimoramento com pistas dedicadas de alta velocidade para menos interferência e o mecanismo de atribuição de prioridades Intel® Killer™, que coloca os jogos em primeiro lugar.",
                "tr_title": "Deixe a concorrência para trás",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Os jogos on-line ganharam um excelente aprimoramento com pistas dedicadas de alta velocidade para menos interferência e o mecanismo de atribuição de prioridades Intel® Killer™, que coloca os jogos em primeiro lugar.",
                "tr_title": "[XX]",
                "tr_pretitle": "DEIXE A CONCORRÊNCIA PARA TRÁS",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Com pistas de alta velocidade dedicadas para menos interferências que colocam o jogo em primeiro lugar, jogos online acabam de receber uma grande atualização.",
                "tr_title": "[XX]",
                "tr_pretitle": "DEIXE A CONCORRÊNCIA PARA TRÁS",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "O Intel® Killer™ Wi-Fi acelera automaticamente o tráfego da rede para os aplicativos que mais precisam, para que seus streamings de vídeos apresentem detalhes nítidos e menos buffering.",
                "tr_title": "Uma maneira mais rápida e inteligente de se conectar.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Na era dos jogos on-line e com vários jogadores, permanecer conectado é mais importante do que nunca. Além da velocidade, você também quer sinal sem fio, que preserve as velocidades mesmo à distância.",
                "tr_title": "[XX]",
                "tr_pretitle": "O SEGREDO ESTÁ NA CONEXÃO",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Tenha uma conexão melhor com o Wi-Fi 5 e aproveite a Internet com velocidades mais rápidas<sup>†</sup> e maior confiabilidade.",
                "tr_title": "Obtenha mais alcance e velocidade",
                "tr_noteSection": "<sup>†</sup>A capacidade real de transmissão sem fios e/ou o alcance irão variar.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Na era dos jogos on-line e com vários jogadores, permanecer conectado é mais importante do que nunca. Além da velocidade, você também quer sinal sem fio, que preserve as velocidades mesmo à distância.",
                "tr_title": "[XX]",
                "tr_pretitle": "DEIXE A CONCORRÊNCIA PARA TRÁS",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Os recursos do Wi-Fi 7 serão baseados e elevarão gerações anteriores do Wi-Fi. Isso significará não apenas velocidades mais rápidas, mas responsividade e confiabilidade melhoradas drasticamente para experiências imersivas do consumidor e tecnologias futuras sofisticadas.",
                "tr_title": "Rede de PC semelhante à com fio",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Os recursos do Wi-Fi 7 serão baseados e elevarão gerações anteriores do Wi-Fi. Isso significará não apenas velocidades mais rápidas, mas responsividade e confiabilidade melhoradas drasticamente para experiências imersivas do consumidor e tecnologias futuras sofisticadas.",
                "tr_title": "[XX]",
                "tr_pretitle": "TRANSFORMANDO AS EXPERIÊNCIAS DO USUÁRIO",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Velocidade, latência e confiabilidade aprimoradas por meio da otimização de rede baseada em IA",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Velocidade, latência e confiabilidade aprimoradas por meio da otimização de rede baseada em IA.",
                "tr_title": "[XX]",
                "tr_pretitle": "RESPONSIVIDADE IGUAL À CABEADA",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Como é feita a medição?",
                    "tr_badgeSubTitle": "A maioria dos fabricantes expressa a autonomia da bateria em horas. Embora esse cálculo não seja 100% exato, ele pode ajudar a comparar a autonomia da bateria de um computador com a&nbsp;de&nbsp;outro."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PARA JOGAR ONDE VOCÊ ESTIVER",
                "tr_subtitle": "Com a potência dos notebooks para jogos atuais, já não há motivo para ficar preso a uma mesa. Agora seus jogos podem ir a todo lugar.",
                "tr_title": "[Dynamic.battery.values.hours] horas de autonomia da&nbsp;bateria"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Desconecte do carregador de bateria e continue conectado aos seus aplicativos, jogos e músicas por muitas horas.",
                "tr_title": "[XX] horas de streaming com uma única carga<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Com base na autonomia da bateria indicada pelo fabricante do PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] horas",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Desconecte do carregador de bateria e continue conectado aos seus aplicativos, jogos e músicas por muitas horas.",
                "tr_title": "[Dynamic.battery.values.hours] horas de energia em&nbsp;movimento<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Com base na autonomia da bateria indicada pelo fabricante do PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] horas",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Desconecte do carregador de bateria e continue conectado aos seus aplicativos, jogos e músicas por muitas horas.",
                "tr_title": "[Dynamic.battery.values.hours] horas de energia para o dia todo com uma única carga<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Com base na autonomia da bateria indicada pelo fabricante do PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] horas",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Esse PC otimiza a autonomia da bateria para que ela dure mais e oferece pelo menos 4 horas de autonomia com uma carga de 30 minutos.<sup>4</sup>",
                "tr_title": "Fique desconectado por mais tempo com [Dynamic.battery.values.hours] horas de autonomia da bateria<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Com base na autonomia da bateria indicada pelo fabricante do PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] horas",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Página inicial",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Por que este PC",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Ver todas as especificações",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Remoto",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Dispositivos portáteis",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Informações legais",
        "tr_paragraph_array": [
          "É possível que o software e as cargas de trabalho utilizados nos testes de desempenho tenham sido otimizados apenas para desempenho em microprocessadores Intel®. Testes de desempenho, como SYSmark* e MobileMark*, são medidos utilizando sistemas de computação, componentes e funções específicos. Qualquer modificação nesses fatores pode provocar variação nos resultados. Consulte outras informações e outros testes de desempenho para ajudá-lo a avaliar totalmente as suas compras, incluindo o desempenho desse produto quando combinado com outros produtos. Para informações completas sobre desempenho e resultados de referência, acesse www.intel.com/benchmarks.",
          "¹Os produtos Intel® Wi-Fi 6 (Gig+) são compatíveis com canais opcionais de 160 MHz, possibilitando as velocidades teóricas máximas mais rápidas possíveis (2.402 Mbps) para os produtos Wi-Fi 802.11ax 2x2 comuns para PC. Os produtos Intel® Wi-Fi 6 (Gig+) premium permitem velocidades teóricas máximas 2 a 4 vezes mais rápidas em comparação com os produtos Wi-Fi padrão 802.11ax 2x2 (1.201 Mbps) ou 1x1 (600 Mbps) para PC, que são compatíveis apenas com os requisitos obrigatórios de canais de 80 MHz.",
          "²Recomendação apenas para fins informativos. Consulte outras fontes para tomar sua decisão de compra.",
          "³Os recursos e benefícios das tecnologias Intel dependem da configuração do sistema e podem exigir hardware e software habilitados e ativação de serviços. O desempenho varia de acordo com a configuração do sistema. Nenhum sistema de computador pode ser totalmente seguro. Consulte o fabricante ou revendedor do seu sistema, ou saiba mais em intel.com.",
          "⁴Para sistemas com telas Full HD, quando utilizados para navegação na web sem fio. Quando desligado, a partir do nível de desligamento padrão do OEM.",
          "⁵Em comparação com outras tecnologias de conexão de E/S para PCs, inclusive eSATA, USB e IEEE 1394* FireWire. O desempenho varia de acordo com as configurações específicas de hardware e software utilizadas. É necessário utilizar um dispositivo compatível com a tecnologia Thunderbolt™.",
          "⁶Quase 3x mais rápido: o padrão 802.11ax 2x2 de 160 MHz possibilita taxas de dados teóricas máximas de 2.402 Mbps. Aproximadamente 3x mais rápido do que o Wi‑Fi padrão 802.11ac 2x2 de 80 MHz (867 Mbps), conforme documentado nas especificações do padrão sem fio IEEE 802.11*, e exige o uso de roteadores de rede sem fio 802.11ax configurados de forma semelhante.",
          "⁷Redução de latência de 75%: com base em dados de simulação da Intel (79%) do 802.11ax com e sem OFDMA utilizando 9 clientes. A latência média sem OFDM é de 36 ms; com OFDMA, a latência média é reduzida para 7,6 ms. A melhoria na latência exige que o roteador 802.11ax (Wi&#8209;Fi&nbsp;6) e todos os clientes suportem OFDMA.",
          "⁸Com base na comparação da taxa de dados máxima teórica na especificação 802.11, o Wi‑Fi 6/6E 2x2 de 160 MHz (802.11ax) pode fornecer 2.402 Mbps versus 867 Mbps fornecido pelo Wi‑Fi 5 2x2 de 80 MHz (802.11ac), o que equivale a velocidades 2,8 vezes mais rápidas.",
          "⁹O melhor Wi‑Fi 6 da categoria: os produtos Intel® Wi‑Fi 6 (Gig+) são compatíveis com canais opcionais de 160 MHz, possibilitando as velocidades máximas teóricas mais rápidas possíveis (2.402 Mbps) para os produtos Wi‑Fi 802.11ax 2x2 comuns para PC. Os produtos Intel® Wi‑Fi 6 (Gig+) premium permitem velocidades teóricas máximas 2 a 4 vezes mais rápidas em comparação com os produtos Wi‑Fi 802.11ax 2x2 (1.201 Mbps) ou 1x1 (600 Mbps) padrão para PC, que são compatíveis apenas com os requisitos obrigatórios de canais de 80 MHz.",
          "Disponível apenas em algumas SKUs, a funcionalidade poderá variar de acordo com o design do OEM. Consulte o OEM ou o varejista para obter mais detalhes. Para obter mais informações sobre Wi‑Fi, acesse intel.com/wifi6disclaimers.",
          "¹⁰A tecnologia Thunderbolt™ 4, com 40 Gbps, oferece a solução por cabo mais rápida, simples e confiável para qualquer estação de acoplagem, monitor ou dispositivo de dados em comparação com outras tecnologias de conexão de E/S para PC, incluindo eSATA*, USB* e IEEE 1394* FireWire.",
          "¹¹Exige um roteador baseado no 802.11ax compatível com OFDMA e vários clientes na rede com suporte para AX. A afirmação “Melhor em ambientes densos” é alcançável a partir dos recursos OFDMA suportados por pontos de acesso e clientes 802.11ax. 2 Gbps com base em suposições de aproximadamente 70% das taxas de dados máximas teóricas da especificação IEEE 802.11* para 802.11ax a 160 MHz e 2.402 Mbps.",
          "¹²Conforme avaliado pela carga de trabalho de inicialização de documento com atividade em segundo plano, comparando o processador Intel® Core™ i7-8565U da 8ª Geração (SSD TLC de 512 GB) com o processador Intel® Core™ i7-8565U da 8ª Geração (Intel® Optane™ Memory H10 com armazenamento de estado sólido de 32 GB + 512 GB).",
          "¹³O Intel® Thread Director foi projetado para processadores Intel® Core™ da 12ª Geração e ajuda sistemas operacionais a canalizar cargas de trabalho para o núcleo certo de maneira mais inteligente. Nenhuma ação do usuário é necessária. Consulte intel.com para obter mais detalhes.",
          "¹⁴Não disponível em alguns processadores Intel® Core™ da 12ª Geração. A arquitetura híbrida de desempenho combina duas novas microarquiteturas de núcleo, Performance-cores (P-cores) e Efficient-cores (E-cores), em um único die do processador. Processadores Intel® Core™ da 12ª Geração selecionados (certos processadores Intel® Core™ i5 da 12ª Geração e inferiores) não suportam arquitetura híbrida de desempenho, apenas P-cores.",
          "¹⁵Conforme avaliado pela carga de trabalho de inicialização de jogos, comparando o processador Intel® Core™ i7-8750H da 8ª Geração (módulo do Intel® Optane™ Memory de 32 GB + SSD Intel® PCIe* de 256 GB + HD de 1 TB) ao processador Intel® Core™ i7-8750H da 8ª Geração (SSD Intel® PCIe* de 256 GB + HD de 1 TB). Carga de trabalho de inicialização de jogos - desenvolvida pela Intel, medindo o tempo decorrido para inicializar o Total War: WARHAMMER* II, Compilação 5577.0, e alcançar o menu principal com vídeos de introdução desabilitados (inicialização). Configurações de aceleração da unidade de dados de Intel® Optane™ Memory: processador Intel® Core™ i7-8750H, PL1=45W TDP, 6 núcleos/12 threads, turbo até 4GHz num sistema OEM em pré-produção, Placa gráfica: NVIDIA* GeForce* GTX 1070, Memória: 2 DDR4 de 4GB, Armazenamento: SSD Intel® Série 760p de 256GB (PCIe*) + HD de 1TB + Intel® Optane™ Memory de 32GB, SO: Windows* 10 RS3 compilação 1709, MCU 0x84; processador Intel® Core™ i7-8750H, PL1=45W TDP, 6 núcleos/12 threads, turbo até 4GHz num sistema OEM em pré-produção, Placa gráfica: NVIDIA* GeForce* GTX 1070, Memória: 2 DDR4 de 4GB, Armazenamento: SSD Intel ® Série 760p de 256GB (PCIe*) + HD de 1TB, SO: Windows* 10 RS3 compilação 1709, MCU 0x84.",
          "¹⁶ Conforme avaliado pela carga de trabalho de carregamento de fases de jogos, comparando o processador Intel® Core™ i7-8750H da 8ª Geração (módulo de Intel® Optane™ Memory de 32 GB) com o processador Intel® Core™ i7-8750H da 8ª Geração (HDD apenas). Carga de trabalho de carregamento de fases de jogos desenvolvida pela Intel, medindo o tempo decorrido do menu principal até o término do carregamento de fases no Total War: WARHAMMER* II, Compilação 5577.0. Configurações de aceleração da unidade de dados do Intel® Optane™ Memory: processador Intel® Core™ i7-8750H, PL1=45W TDP, 6 núcleos/12 threads, turbo até 4GHz num sistema OEM em pré-produção, placa gráfica: NVIDIA* GTX 1070, memória: 2 DDR4 de 4GB, armazenamento: SSD Intel® Série 760p de 256GB (PCIe*) + HDD de 1TB + Intel® Optane™ Memory de 32GB, SO: Windows* 10 RS3 versão 1709, MCU 0x84; processador Intel® Core™ i7-8750H, PL1=45W TDP, 6 núcleos/12 threads, turbo até 4GHz num sistema OEM em pré-produção, placa gráfica: NVIDIA* GTX 1070, memória: 2 DDR4 de 4GB, armazenamento: SSD Intel ® Série 760p de 256GB (PCIe*) + HDD de 1TB, SO: Windows* 10 RS3 versão 1709, MCU 0x84.",
          "¹⁷Em Performance-cores. O desempenho varia de acordo com o uso, as configurações e outros fatores. Saiba mais em www.intel.com/PerformanceIndex.",
          "¹⁸Alterar a frequência ou tensão do clock pode danificar ou reduzir a vida útil do processador e de outros componentes do sistema, além de reduzir a estabilidade e o desempenho do sistema. As garantias do produto podem não se aplicar se o processador estiver operando além de suas especificações. Consulte os fabricantes do sistema e dos componentes para obter detalhes adicionais.",
          "¹⁹Conforme medido pela combinação inigualável da plataforma Intel vPro® dos recursos de segurança acima e abaixo do SO, proteções de aplicativos e dados, além de proteções avançadas contra ameaças para empresas de todos os tamanhos, bem como a primeira abordagem de segurança da Intel para design, fabricação e suporte de produtos. Todos os PCs comerciais construídos na plataforma Intel vPro® foram validados usando especificações rigorosas, incluindo recursos de segurança exclusivos baseados em hardware. Acesse www.intel.com/PerformanceIndex (plataformas) para obter mais detalhes. Nenhum produto ou componente pode ser totalmente seguro.",
          "²⁰Intel® Connectivity Performance Suite está disponível apenas em sistemas baseados em Windows*. Testes Wi-Fi Intel sem fios (OTA — Over The Air) em uma rede congestionada versus sem priorização de tráfego sem fio do aplicativo de software ICPS e comutação inteligente de ponto de acesso. Consulte www.Intel.com/PerformanceIndex (conectividade) para obter detalhes. Os resultados podem variar.",
          "²¹Em comparação com a geração anterior de processadores.",
          "²²Os gráficos Intel® Arc™ são os primeiros do mercado compatíveis com a codificação AV1 (no 1º trimestre de 2022). Detalhes em intel.com/PerformanceIndex.",
          "²³Conforme medido pelos recursos exclusivos dos designs Intel® Evo™, que também são verificados para atender aos principais limiares de experiência de usuários móveis. Testes a partir de fevereiro de 2022.",
          "²⁴A solução Intel® Unison™ está atualmente disponível somente em designs Intel® Evo™ elegíveis em PCs baseados em Windows* e apenas emparelha com telefones baseados em Android ou iOS; todos os dispositivos devem executar uma versão do SO compatível.",
          "²⁵As velocidades máximas de memória estão associadas a configurações de 1 DIMM por canal (1DPC). O carregamento adicional de DIMMs em qualquer canal pode afetar a velocidade máxima de memória. Até DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 e DDR5-5200 1Rx8, 1Rx16, 2Rx8 em SKUs selecionadas. A capacidade máxima de memória é alcançável com configurações 2DPC.",
          "²⁶Em comparação com o Wi-Fi padrão. Consulte intel.com/performance-wireless para obter detalhes. Os resultados podem variar.",
          "²⁷A arquitetura híbrida de desempenho combina duas microarquiteturas de núcleo, os Performance-cores (P-cores) e os Efficient-cores (E-cores), em um único die do processador e foi apresentada pela primeira vez nos processadores Intel® Core™ da 12<sup>a</sup> Geração. Certos processadores Intel® Core™ da 12<sup>a</sup> Geração e mais recentes não possuem arquitetura híbrida de desempenho, apenas P-cores, e apresentam o mesmo tamanho de cache que a geração anterior; consulte ark.intel.com para obter detalhes das SKUs.",
          "²⁸Integrado ao hardware, o Intel® Thread Director é fornecido apenas em configurações de arquitetura híbrida de desempenho dos processadores Intel® Core™ da 12<sup>a</sup> Geração ou mais recentes; requer ativação do SO. Os recursos e as funcionalidades disponíveis variam de acordo com o SO.",
          "²⁹Integrado ao hardware, o Intel® Thread Director é fornecido apenas em configurações de arquitetura híbrida de desempenho dos processadores Intel® Core™ da 12<sup>a</sup> e 13<sup>a</sup> Geração; requer ativação do SO. Os recursos e as funcionalidades disponíveis variam de acordo com o SO.",
          "³⁰Conforme medido pelo desempenho da plataforma de validação de referência do processador para portáteis Intel®&nbsp;Core™ Ultra (Série 2 — codinome: Lunar Lake) em comparação com o processador para portáteis Intel®&nbsp;Core™ Ultra (Série 1 — codinome: Meteor Lake) no 3DMark Time Spy. Acesse intel.com/performanceindex para mais detalhes. Os resultados podem variar.",
          "³¹O desfoque de fundo e o rastreamento facial estão disponíveis apenas em sistemas baseados em Windows*.",
          "³²Os produtos Wi-Fi 7 podem acessar canais de 320 MHz em 6 GHz e novas combinações de canais de 160 MHz em 5 GHz com os novos recursos Multi-Resource Unit Puncturing.",
          "³³Com base nas especificações do padrão sem fio IEEE e nas taxas de dados teóricas máximas para dispositivos de 2 fluxos.",
          "³⁴As simulações de engenharia da Intel em ambientes de rede congestionados indicam que é possível reduzir bastante a latência com os novos recursos de operação multilink do Wi-Fi 7.",
          "³⁵Carga mínima obtida a partir do nível de desligamento padrão do OEM. Os resultados individuais de cada sistema podem variar. Consulte intel.com/performance-evo para mais detalhes.",
          "³⁶A banda Wi-Fi de 6 GHz não está disponível para uso em todos os países/regiões.",
          "³⁷Apenas Windows*",
          "³⁸Carga obtida a partir do nível de desligamento padrão do OEM. Para informações completas sobre desempenho e resultados de referência, acesse intel.com/Evo.",
          "³⁹Refere-se aos processadores para portáteis Intel®&nbsp;Core™ Ultra (Série&nbsp;2), com base nos recursos de segurança exclusivos baseados em hardware, combinação inigualável dos recursos de segurança acima e abaixo do SO, e desempenho impressionante, mesmo com menor consumo de energia em comparação com a geração anterior (em setembro de 2024). Acesse intel.com/performanceindex para mais detalhes. Os resultados podem variar.",
          "⁴⁰Aplicativos de software descritos apenas para fins ilustrativos. Os recursos de IA podem exigir a compra, assinatura ou habilitação adicionais de software por um provedor de software ou plataforma, ou ainda, podem ter requisitos específicos de configuração ou compatibilidade. Detalhes em www.intel.com/PerformanceIndex. Os resultados podem variar. ©&nbsp;Intel&nbsp;Corporation.",
          "⁴¹As imagens exibidas podem ter sido alteradas ou simuladas. Os recursos de IA podem exigir a compra, assinatura ou habilitação adicionais de software por um provedor de software ou plataforma, ou ainda, podem ter requisitos específicos de configuração ou compatibilidade. Detalhes em www.intel.com/AIPC.",
          "⁴²Lançamento gradual em pré-visualização na atualização mais recente para o Windows* 11 em mercados globais selecionados. A época da disponibilidade varia de acordo com o dispositivo e o mercado. Saiba mais aqui: aka.ms/WindowsAIFeatures. ©&nbsp;Intel&nbsp;Corporation.",
          "⁴³A solução Intel® Unison™ está atualmente disponível para designs selecionados. Para mais detalhes, consulte www.intel.com/PerformanceIndex.",
          "⁴⁴FPS aprimorado pelo Intel® X<sup>e</sup>SS em comparação com o FPS nativo medido pelo Dying Light 2 Stay Human rodando no processador Intel® Core™ Ultra 7 165H. Para mais detalhes, consulte www.intel.com/PerformanceIndex. Os resultados podem variar.",
          "⁴⁵Conforme medido pelo menor consumo de energia do SoC da plataforma de validação de referência do processador para portáteis Intel®&nbsp;Core™ Ultra (Série 2 — codinome: Lunar Lake) em comparação com o processador para portáteis Intel®&nbsp;Core™ Ultra (Série 1 — codinome: Meteor Lake) no YouTube* 4K 30FPS AV1. Acesse intel.com/performanceindex para mais detalhes. Os resultados podem variar.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "A Intel não pode ser responsabilizada por preços incorretos.",
          "*Outros nomes e marcas podem ser propriedade de outras empresas.",
          "COPYRIGHT 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES E DESIGN RELACIONADO E MARCAS COMPOSTAS SÃO MARCAS REGISTRADAS DA INFLEXION STUDIOS INC. TODOS OS DIREITOS RESERVADOS.",
          "As tecnologias Intel podem exigir a ativação de hardware, software ou serviços.",
          "Quaisquer resultados e dados do NERO* são fornecidos como uma conveniência e apenas para fins informativos. A Intel Corporation não se responsabiliza, por exemplo, pela precisão do aplicativo NERO*, por resultados e pontuações de desempenho ou por potenciais variações de outras versões do aplicativo disponíveis para download em www.nero.com ou outros sites de terceiros. As pontuações de referência do PC NERO* são medidas usando sistemas de computação, componentes, software, operações e funções específicos. Qualquer modificação nesses fatores pode provocar variação nos resultados. Consulte outras informações e outros testes de desempenho para ajudá-lo a avaliar totalmente as suas compras, incluindo o desempenho desse produto quando combinado com outros produtos.",
          "Todas as versões da plataforma Intel vPro® exigem um processador Intel® Core™ elegível, um sistema operacional compatível, LAN Intel® e/ou silício WLAN, aprimoramentos de firmware, além de outros hardware e software necessários para fornecer os casos de uso de capacidade de gerenciamento, recursos de segurança, desempenho do sistema e estabilidade que definem a plataforma.",
          "O desempenho varia de acordo com o uso, as configurações e outros fatores. Saiba mais em www.intel.com/PerformanceIndex.",
          "A Intel não controla nem faz auditoria em dados de terceiros. Você deve consultar outras fontes para avaliar a precisão.",
          "© Intel Corporation. Intel, o logotipo Intel e outras marcas Intel são marcas comerciais da Intel Corporation ou de suas subsidiárias. *Outros nomes e marcas podem ser propriedade de outras empresas.",
          "Nenhum produto ou componente pode ser totalmente seguro.",
          "Seus custos e resultados podem variar.",
          "Intel, o logotipo Intel, Intel Core, Intel Optane, Intel Iris, Thunderbolt e o logotipo Thunderbolt são marcas comerciais da Intel Corporation ou de suas subsidiárias. Outros nomes e marcas podem ser propriedade de outras empresas.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Página inicial",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Escaneie para comparar",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Tendências",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "Desbloqueie novas experiências com a IA utilizando os processadores Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "Desbloqueie novas experiências com a IA utilizando os processadores Intel® Core™ Ultra",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "Desbloqueie novas experiências com a IA utilizando os processadores Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "Stable Diffusion*: texto para imagem",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe*: edição de fotos",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "Intel® X<sup>e</sup>SS: ampliação de imagem",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee*: Detector de Deepfake",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "Efeitos de colaboração (Teams)",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "IQIYI: controle por gestos",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "Sincronize seu PC com seu telefone e visualize notificações, chamadas e mensagens de texto",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "Gráficos ricos e vibrantes integrados",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "Microsoft*: viva sua vida do seu jeito",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "Microsoft*: tudo o que você precisa. Tudo à mão, sempre a um gesto de distância",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "Faça o seu processador trabalhar mais rápido e em frequências mais altas ajustando seu sistema",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "Aumente o desempenho do seu desktop ou sistema portátil sem sacrificar o espaço de armazenamento",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "Aumente o desempenho do seu desktop ou sistema portátil sem sacrificar o espaço de armazenamento",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "Samsung OLED: uma visão para olhos cansados",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "Velocidade, latência e confiabilidade aprimoradas por meio da otimização de rede baseada em IA",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "Rede de PC semelhante à com fio",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "Rede de PC semelhante à com fio",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "Velocidades quase 3x mais rápidas com o Intel® Wi-Fi 6 (Gig+)",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "Conecte-se sem concorrência",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "Conectividade com&nbsp;cabo verdadeiramente&nbsp;universal",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "Jogue como os profissionais: veja como a Intel impulsiona jogos e jogadores",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "Jogue como os profissionais: os campeões surgem com a Intel",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "BEM-VINDO",
    "tr_welcomePagetrSubtitle": "Controle este PC remotamente com o seu smartphone",
    "tr_welcomePagembSubtitle": "Bem-vindo, explore as especificações deste PC no seu smartphone",
    "tr_welcomePageBtnText": "Comece Agora",
    "tr_welcomePageText": "Explore e compare os detalhes deste PC no seu smartphone",
    "tr_scrollText": "Navegue no dispositivo à sua frente",
    "tr_toolTipTextc1": "Utilize esses botões para navegar entre as páginas do dispositivo à sua frente.",
    "tr_toolTipTextc2": "Tocar nos cartões abaixo navegará até uma página detalhada de cada especificação do dispositivo.",
    "tr_toolTipTextc3": "Utilize esses botões para mover para cima e para baixo na tela à sua frente.",
    "tr_toolTipTextc4": "Desative o modo remoto para ver as especificações do dispositivo no seu smartphone.",
    "tr_toolTipTextmb1": "Toque nos cartões para visualizar páginas com conteúdo detalhado.",
    "tr_toolTipTextmb2": "Toque para visualizar um resumo de todas as especificações do dispositivo",
    "tr_toolTipTextmb3": "Toque no botão para ler o código QR e comparar até quatro dispositivos",
    "tr_nextButton": "Avançar",
    "tr_prevButton": "Voltar",
    "tr_gotItButton": "ENTENDI",
    "tr_skipButton": "Pular",
    "tr_placeholderText": "*espaço reservado",
    "tr_processorHeading": "Processador",
    "tr_processorSubheading": "Processador Intel® Core™ i7 da 10ª Geração",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "Intel® Optane™ Memory de [XX] GB + SSD de [YY] GB",
    "tr_coachmarkProcessor": "Processador XX",
    "tr_coachmarkGraphics": "Gráficos [XX]",
    "tr_coachmarkMemory": "Valor da especificação",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "US$ 999,95",
    "tr_compare": "Compare",
    "tr_viewallspecs": "EXIBIR TODAS AS ESPECIFICAÇÕES",
    "tr_operatingSystem": "SISTEMA OPERACIONAL",
    "tr_AmdProcessorNonIntel": "Processador AMD*",
    "tr_QualcommProcessorNonIntel": "Processador Qualcomm*",
    "tr_GBText": "GB",
    "tr_remoteText": "Especificações exibidas na tela do notebook",
    "tr_scanErrorText": "Você pode escanear apenas até três dispositivos por vez. Exclua um ou mais dispositivos.",
    "tr_scanErrorText2": "Você já escaneou este dispositivo, escaneie outro.",
    "tr_scanMsg": "Escaneie para adicionar o dispositivo",
    "tr_backToScan": "Voltar à comparação",
    "tr_scanToCompare": "Escaneie para comparar",
    "tr_compareDevice": "Comparar dispositivos",
    "tr_processorTitle": "PROCESSADOR",
    "tr_graphicTitle": "PLACA GRÁFICA",
    "tr_storageTitle": "ARMAZE&shy;NAMENTO",
    "tr_displayTitle": "TELA",
    "tr_batteryTitle": "BATERIA",
    "tr_memoryTitle": "MEMÓRIA",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "CONECTIVIDADE",
    "tr_priceTitle": "PREÇO",
    "tr_operatingSystemTitle": "SISTEMA OPERACIONAL",
    "tr_batteryHoursText": "[XX] horas",
    "tr_hrsText": "hrs",
    "tr_touchscreeenText": "Tela sensível ao toque de [screenDiagonal]”",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, tela sensível ao toque",
    "tr_FHDTouchScreenText": "FHD, tela sensível ao toque",
    "tr_4KTouchscreenText": "4K, tela sensível ao toque",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Salvar",
    "tr_device_comparison": "Documento de comparação de dispositivos [dd]-[mm]-[yyyy]",
    "tr_Save_As_JPEG": "Salvar como JPEG",
    "tr_Save_As_PDF": "Salvar como PDF",
    "tr_Downloading_PDF": "Baixando PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Abrir",
    "tr_logo": "logotipo",
    "tr_laptop_name": "Nome do notebook",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Intel® Optane™ Memory",
    "tr_inchUnit": "polegada",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "INTEL® OPTANE™ MEMORY",
    "tr_Thunderbolt3": "Tecnologia Thunderbolt™&nbsp;3",
    "tr_Thunderbolt4": "Tecnologia Thunderbolt™ 4",
    "tr_processorGraphicsCaps": "GRÁFICOS DO PROCESSADOR",
    "tr_processorGraphicsSmall": "Gráficos do processador",
    "tr_graphicsCardCaps": "GRÁFICOS DEDICADOS",
    "tr_graphicsCardSmall": "Gráficos dedicados",
    "tr_processorTitleCamel": "Processador",
    "tr_graphicTitleCamel": "Gráficos",
    "tr_storageTitleCamel": "Armazenamento",
    "tr_displayTitleCamel": "Tela",
    "tr_batteryTitleCamel": "Bateria",
    "tr_memoryTitleCamel": "Memória",
    "tr_connectivityTitleCamel": "Conectividade",
    "tr_priceTitleCamel": "Preço",
    "tr_operatingSystemTitleCamel": "Sistema Operacional",
    "tr_viewallspecsCamel": "Ver todas as especificações",
    "tr_displayText": "[screenDiagonal]”",
    "tr_displayTextResolution": "[screenResolutionType]&nbsp;de [screenDiagonal]”",
    "tr_displayTextResolutionTouchscreen": "Tela sensível ao toque de [screenDiagonal]” e [screenResolutionType]",
    "tr_OS": "SISTEMA OPERACIONAL",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "Especificações do PC",
    "tr_explorePC_text": "Explore tudo sobre este PC",
    "tr_mtlPC_text": "Veja o que projetado para fazer de tudo realmente&nbsp;significa",
    "tr_optaneMemory": "Intel® Optane™ Memory",
    "tr_displayText_Display": "TELA DE [screenDiagonal]″",
    "tr_displayTextResolution_Display": "TELA [screenResolutionType] DE [screenDiagonal]″",
    "tr_displayTextResolutionTouchscreen_Display": "TELA [screenResolutionType] SENSÍVEL AO TOQUE DE [screenDiagonal]″",
    "tr_displayTextTouchscreeenText": "TELA SENSÍVEL AO TOQUE DE [screenDiagonal]″",
    "tr_learnMoreLabel": "Saiba Mais",
    "tr_batteryHoursTextUpperCase": "[XX] HORAS",
    "tr_processorGraphicsIrisXe": "PROCESSADOR INTEL® CORE™ [cpu_fam] DA 11ª GERAÇÃO E GRÁFICOS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax": "PROCESSADOR INTEL® CORE™ [cpu_fam] DA 11ª GERAÇÃO E GRÁFICOS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_Thunderbolt3UpperCase": "TECNOLOGIA THUNDERBOLT™ 3",
    "tr_Thunderbolt4UpperCase": "TECNOLOGIA THUNDERBOLT™ 4",
    "tr_processorWithArcGraphics": "PROCESSADOR INTEL® CORE™ [cpu_fam] DA 12ª GERAÇÃO E GRÁFICOS INTEL® ARC™",
    "tr_processorGraphicsIrisXe12Gen": "PROCESSADOR INTEL® CORE™ [cpu_fam] DA 12ª GERAÇÃO E GRÁFICOS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax12Gen": "PROCESSADOR INTEL® CORE™ [cpu_fam] DA 12ª GERAÇÃO E GRÁFICOS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "PROCESSADOR INTEL® CORE™ [cpu_fam] DA 13ª GERAÇÃO E GRÁFICOS&nbsp;INTEL®&nbsp;ARC™",
    "tr_processorGraphicsIrisXe13Gen": "PROCESSADOR INTEL® CORE™ [cpu_fam] DA 13ª GERAÇÃO E GRÁFICOS&nbsp;INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax13Gen": "PROCESSADOR INTEL® CORE™ [cpu_fam] DA 13ª GERAÇÃO E GRÁFICOS&nbsp;INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;MAX",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "GRÁFICOS INTEL® ARC™ A350M",
      "tr_A370M": "GRÁFICOS INTEL® ARC™ A370M",
      "tr_A730M": "GRÁFICOS INTEL® ARC™ A730M",
      "tr_A770M": "GRÁFICOS INTEL® ARC™ A770M",
      "tr_A310": "GRÁFICOS INTEL® ARC™ A310",
      "tr_A380": "GRÁFICOS INTEL® ARC™ A380",
      "tr_A580": "GRÁFICOS INTEL® ARC™ A580",
      "tr_A750": "GRÁFICOS INTEL® ARC™ A750",
      "tr_A770": "GRÁFICOS INTEL® ARC™ A770"
    },
    "tr_memoryValue": "[XX] GB de&nbsp;RAM",
    "tr_memoryWithOptaneDesc": "Memória de [Value1] GB = Intel® Optane™ Memory de [Value2] GB + RAM de [Value3] GB",
    "tr_textGBGB": "SSD de [convertedSSD] GB + HD de [convertedHDD] GB",
    "tr_textGBTB": "SSD de [convertedSSD] GB + HD de [convertedHDD] TB",
    "tr_textTBGB": "SSD de [convertedSSD] TB + HD de [convertedHDD] GB",
    "tr_textTBTB": "SSD de [convertedSSD] TB + HD de [convertedHDD] TB",
    "tr_textGBSSD": "SSD de [convertedSSD] GB",
    "tr_textTBSSD": "SSD de [convertedSSD] TB",
    "tr_textGBHDD": "HD de [convertedHDD] GB",
    "tr_textTBHDD": "HD de [convertedHDD] TB",
    "tr_textStorageWithOptaneGB": "Intel® Optane™ Memory de&nbsp;[XX]&nbsp;GB +&nbsp;SSD&nbsp;de&nbsp;[convertedSSD]&nbsp;GB",
    "tr_textStorageWithOptaneTB": "Intel® Optane™ Memory de&nbsp;[XX]&nbsp;GB +&nbsp;SSD&nbsp;de&nbsp;[convertedSSD]&nbsp;TB",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home no modo S",
      "tr_OS21": "Windows* 10 Pro no modo S",
      "tr_OS22": "Windows* 10 Enterprise no modo S",
      "tr_OS23": "Windows* 11 Home no modo S",
      "tr_OS24": "Windows* 11 Pro no modo S",
      "tr_OS25": "Windows* 11 Enterprise no modo S"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Gráficos Intel®&nbsp;Arc™&nbsp;A310",
      "tr_GR387": "Gráficos Intel®&nbsp;Arc™&nbsp;A350M",
      "tr_GR388": "Gráficos Intel®&nbsp;Arc™&nbsp;A370M",
      "tr_GR389": "Gráficos Intel®&nbsp;Arc™&nbsp;A380",
      "tr_GR390": "Gráficos Intel®&nbsp;Arc™&nbsp;A530M",
      "tr_GR391": "Gráficos Intel®&nbsp;Arc™&nbsp;A550M",
      "tr_GR392": "Gráficos Intel®&nbsp;Arc™&nbsp;A570M",
      "tr_GR393": "Gráficos Intel®&nbsp;Arc™&nbsp;A580",
      "tr_GR394": "Gráficos Intel®&nbsp;Arc™&nbsp;A730M",
      "tr_GR395": "Gráficos Intel®&nbsp;Arc™&nbsp;A750",
      "tr_GR396": "Gráficos Intel®&nbsp;Arc™&nbsp;A770",
      "tr_GR397": "Gráficos Intel®&nbsp;Arc™&nbsp;A770M",
      "tr_GR398": "Gráficos Intel®&nbsp;Arc™",
      "tr_GR399": "Gráficos Intel® Arc™ Pro A30M",
      "tr_GR400": "Gráficos Intel® Arc™ Pro A40/A50",
      "tr_GR401": "Gráficos Intel® Arc™ Pro A60",
      "tr_GR402": "Gráficos Intel® Arc™ Pro A60M",
      "tr_GR403": "Gráficos Intel® ",
      "tr_GR404": "Gráficos HD Intel® 2000",
      "tr_GR405": "Gráficos HD Intel® 2500",
      "tr_GR406": "Gráficos HD Intel® 3000",
      "tr_GR407": "Gráficos HD Intel® 400",
      "tr_GR408": "Gráficos HD Intel® 4000",
      "tr_GR409": "Gráficos HD Intel® 405",
      "tr_GR410": "Gráficos HD Intel® 4200",
      "tr_GR411": "Gráficos HD Intel® 4400",
      "tr_GR412": "Gráficos HD Intel® 4600",
      "tr_GR413": "Gráficos HD Intel® 500",
      "tr_GR414": "Gráficos HD Intel® 5000",
      "tr_GR415": "Gráficos HD Intel® 505",
      "tr_GR416": "Gráficos HD Intel® 510",
      "tr_GR417": "Gráficos HD Intel® 515",
      "tr_GR418": "Gráficos HD Intel® 520",
      "tr_GR419": "Gráficos HD Intel® 530",
      "tr_GR420": "Gráficos HD Intel® 5300",
      "tr_GR421": "Gráficos HD Intel® 5500",
      "tr_GR422": "Gráficos HD Intel® 5600",
      "tr_GR423": "Gráficos HD Intel® 6000",
      "tr_GR424": "Gráficos HD Intel® 610",
      "tr_GR425": "Gráficos HD Intel® 615",
      "tr_GR426": "Gráficos HD Intel® 620",
      "tr_GR427": "Gráficos HD Intel® 630",
      "tr_GR428": "Gráficos HD Intel®",
      "tr_GR429": "Gráficos Intel® Iris® 5100",
      "tr_GR430": "Gráficos Intel® Iris® 540",
      "tr_GR431": "Gráficos Intel® Iris® 550",
      "tr_GR432": "Gráficos Intel® Iris® 6100",
      "tr_GR433": "Gráficos Intel® Iris® Plus 640",
      "tr_GR434": "Gráficos Intel® Iris® Plus 645",
      "tr_GR435": "Gráficos Intel® Iris® Plus 650",
      "tr_GR436": "Gráficos Intel® Iris® Plus 655",
      "tr_GR437": "Gráficos Intel® Iris® Plus",
      "tr_GR438": "Gráficos Intel® Iris® Pro 5200",
      "tr_GR439": "Gráficos Intel® Iris® Pro 580",
      "tr_GR440": "Gráficos Intel® Iris® Pro 6200",
      "tr_GR441": "Gráficos Intel® Iris® X<sup>e</sup>",
      "tr_GR442": "Gráficos Intel® Iris® X<sup>e</sup> MAX A220M",
      "tr_GR443": "Gráficos Intel® Iris® X<sup>e</sup> MAX",
      "tr_GR444": "Gráficos integrados Intel®",
      "tr_GR445": "Gráficos UHD Intel® 600",
      "tr_GR446": "Gráficos UHD Intel® 605",
      "tr_GR447": "Gráficos UHD Intel® 610",
      "tr_GR448": "Gráficos UHD Intel® 615",
      "tr_GR449": "Gráficos UHD Intel® 617",
      "tr_GR450": "Gráficos UHD Intel® 620",
      "tr_GR451": "Gráficos UHD Intel® 630",
      "tr_GR452": "Gráficos UHD Intel® 710",
      "tr_GR453": "Gráficos UHD Intel® 730",
      "tr_GR454": "Gráficos UHD Intel® 770",
      "tr_GR455": "Gráficos UHD Intel®",
      "tr_GR456": "Gráficos UHD Intel®",
      "tr_GR457": "Gráficos UHD Intel®",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 com design Max-Q",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti com design Max-Q",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 com design Max-Q",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 com design Max-Q",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 com design Max-Q",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 com design Max-Q",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti com design Max-Q",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti com design Max-Q",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 com design Max-Q",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 com design Max-Q",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super com design Max-Q",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 com design Max-Q",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super com design Max-Q",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 com design Max-Q",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 com design Max-Q",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 com design Max-Q",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 com design Max-Q",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 com design Max-Q",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 com design Max-Q",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 com design Max-Q",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 com design Max-Q",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 com design Max-Q",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 com design Max-Q",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Geração Ada",
      "tr_GR773": "NVIDIA* RTX 3000 Geração Ada",
      "tr_GR774": "NVIDIA* RTX 3500 Geração Ada",
      "tr_GR775": "NVIDIA* RTX 4000 Geração Ada",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Geração Ada",
      "tr_GR777": "NVIDIA* RTX 4500 Geração Ada",
      "tr_GR778": "NVIDIA* RTX 5000 Geração Ada",
      "tr_GR779": "NVIDIA* RTX 5880 Geração Ada",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Geração Ada",
      "tr_GR811": "NVIDIA* RTX 1000 Geração Ada",
      "tr_GR812": "Gráficos Intel® Arc™",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "Geração NVIDIA RTX* 2000E Ada",
      "tr_GR815": "AMD Radeon* Pro W7900 de slot duplo"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Compre Agora",
    "tr_textTryagain": "Tentar novamente",
    "tr_textBuynowErr": "Devido a uma interrupção na conexão, a opção Comprar agora não está disponível",
    "tr_chatbotPlaceholder_text": "Pergunte-me qualquer coisa sobre o dispositivo",
    "tr_deviceSpecs_text": "Especificações do dispositivo"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 com Intel®&nbsp;Hybrid Technology"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 com Intel®&nbsp;Hybrid Technology"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Prepare-se com a Intel® para uma experiência de jogos mais intensa",
                "tr_subtitle": "Dê vida aos Marvel’s Avengers* otimizando seu PC durante o jogo com as tecnologias Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Experimente o Intel®&nbsp;Extreme&nbsp;Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Onde os melhores gamers do mundo vêm para competir",
                "tr_subtitle": "O Intel® Extreme Masters, a série de eventos de maior duração na história dos videogames, define o padrão para os eSports.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Jogue como os profissionais",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Se tem Intel, tem&nbsp;campeões.",
                "tr_subtitle": "Depois de vencer o campeonato da Liga Norte-Americana de Rainbow Six Siege* de 2021, os Soniqs refletem sobre sua história nos jogos, o futuro dos eSports e sua parceria com a Intel.",
                "tr_pretitle": "Apresentando os Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "Pronto para RV",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Onde a RV ganha vida",
                "tr_subtitle": "As experiências de realidade virtual mais imersivas, realistas e fantásticas executadas em um computador. Com os mais recentes processadores Intel® Core™, você pode ser quem quiser, ir a qualquer lugar e experimentar jogar na próxima dimensão.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Jogue mais de 100 jogos de console de alta qualidade, como Sea of Thieves* e Psychonauts* 2, em seu PC Windows*, celular e tablet com o Xbox* Game Pass Ultimate e um controle compatível.",
                "titleArray": [
                  {
                    "tr_title": "Jogue em conjunto em&nbsp;vários&nbsp;dispositivos",
                    "tr_subtitle": "Explore a essência do Xbox* com uma comunidade de milhões de jogadores prontos para jogar juntos. Conecte-se e jogue com outras pessoas jogos de uma biblioteca compartilhada, quer elas estejam em outro canto do mundo ou sentadas ao seu lado."
                  },
                  {
                    "tr_title": "Jogos para PCs Windows*",
                    "tr_subtitle": "Aproveite um catálogo de jogos de console em nuvem usando o aplicativo Xbox* para Windows* e um controle compatível."
                  },
                  {
                    "tr_title": "Escolha e jogue",
                    "tr_subtitle": "Aproveite jogos excelentes de Xbox* em mais lugares do que nunca. Jogue mais de 100 jogos em seu PC, celular e tablet, seja em casa enquanto a TV estiver ocupada, em uma cafeteria ou em qualquer outro lugar com conexão de Internet de alta velocidade."
                  },
                  {
                    "tr_title": "Diversão sem controle",
                    "tr_subtitle": "Os jogos de controle por toque do Xbox* possibilitam novas maneiras de jogar que oferecem uma experiência familiar e um nível de jogo ao qual você está acostumado com um controle físico."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Em destaque",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Mais de 100 experiências de&nbsp;IA",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Controle mais, toque menos",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Permaneça no enquadramento, em qualquer ambiente",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Criatividade",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Iluminação correta como um&nbsp;profissional",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Adicione efeitos incríveis com&nbsp;facilidade",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Faça remix de músicas com o poder da IA",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produtividade",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Aumente sua produtividade e&nbsp;privacidade",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Passe mais tempo longe da&nbsp;tomada",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Seu mundo. Em&nbsp;seus&nbsp;dispositivos.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Entretenimento",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Transmissão suave, mesmo quando desconectado",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Jogos rápidos e imersivos",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Em destaque",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Mais de 100 experiências de&nbsp;IA",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Torne suas músicas favoritas verdadeiramente suas",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Veja sua ideia se concretizar em tempo real",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Criatividade",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "O caminho mais rápido para a edição final",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Torne cada foto perfeita",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produtividade",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Aumente sua produtividade e&nbsp;privacidade",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Mais eficiência. Mais liberdade.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Seu mundo. Em&nbsp;seus&nbsp;dispositivos.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Entretenimento",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Transmissão suave em HD, mesmo quando desconectado da tomada",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Jogos imersivos, em&nbsp;mais&nbsp;lugares",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mais criatividade",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Edição de fotos simplificada",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Crie fotos dignas de moldura em&nbsp;segundos",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Você digita. A IA cria.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mais desempenho",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "O segredo para uma gameplay mais estável",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Passe mais tempo longe da&nbsp;tomada",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultraimersivo",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mais produtividade",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "A IA que está ao seu lado todos os dias",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Chamada de vídeo perfeita",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Seu mundo. Em&nbsp;seus&nbsp;dispositivos.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mais segurança",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Proteções integradas para manter seus dados mais seguros e privados",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mais criatividade",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Edição de fotos simplificada",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "IA. O atalho para sua edição&nbsp;final.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Transcreva rapidamente suas letras de música favoritas",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Economize horas de edição com a IA",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mais desempenho",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "O segredo para uma gameplay mais estável",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Passe mais tempo longe da&nbsp;tomada",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultraimersivo",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mais produtividade",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "A IA que está ao seu lado todos os dias",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Chamada de vídeo perfeita",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Seu mundo. Em&nbsp;seus&nbsp;dispositivos.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mais segurança",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Proteções integradas para manter seus dados mais seguros e privados",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Todo o seu entretenimento em&nbsp;um&nbsp;PC",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Para um PC que também serve como centro de entretenimento, você precisa de armazenamento suficiente para todas as suas músicas, vídeos e jogos favoritos e da combinação certa de processador e tela para produzir visuais imersivos. Este PC possui todos os três.",
                "titleArray": [
                  {
                    "tr_title": "Pronto para efeitos visuais de última geração",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crie sua biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Uma janela para seu entretenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Jogos e streaming perfeitos",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crie sua biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Uma janela para seu entretenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Seja mais produtivo em qualquer lugar",
              "tr_tileTitle1": "Faça mais. E&nbsp;mais&nbsp;rápido.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Este PC possui o equilíbrio perfeito entre potência e portabilidade. A começar pelo processador fabricado para designs finos e leves, este PC também oferece o tipo de Wi&#8209;Fi capaz de manter você conectado e a bateria certa para você ficar desconectado da tomada.",
                "tr_subtitle1": "Este PC possui o equilíbrio perfeito entre potência e desempenho para&nbsp;ajudar&nbsp;você a fazer mais em menos tempo. A combinação de um processador robusto acelerado por uma memória ampla e Wi-Fi extremamente rápido simplificará suas tarefas do dia a dia.",
                "tr_subtitle2": "Este PC possui o equilíbrio perfeito entre potência e portabilidade. A começar pelo processador fabricado para designs finos e leves, este PC também oferece o tipo de Wi‑Fi capaz de manter você conectado e a memória ideal para multitarefa.",
                "titleArray": [
                  {
                    "tr_title": "Projetado para fazer mais",
                    "tr_title1": "Desempenho inteligente que faz a diferença",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "As melhores conexões da&nbsp;categoria",
                    "tr_title1": "Conecte-se com o que importa",
                    "tr_title2": "Um grande avanço em Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Fique longe da tomada por mais tempo",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Concilie programas como um&nbsp;profissional",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "A liberdade para criar",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "A criação de conteúdo é uma das tarefas que mais exigem de seu PC. Felizmente esses componentes têm o poder de agilizar seu processo de criação.",
                "titleArray": [
                  {
                    "tr_title": "Uma forma mais inteligente de&nbsp;criar",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Dê vida às suas imagens",
                    "tr_title1": "Que comece a criação",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memória para multitarefa",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Examine o que há neste&nbsp;PC",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Estes são os três componentes principais para você prestar atenção quando for escolher um PC novo. Ao decidir como você vai usar seu PC novo, você percorreu um longo caminho para decidir o quanto destes três componentes você vai precisar. Eles são:",
                "titleArray": [
                  {
                    "tr_title": "Desempenho para jogos e&nbsp;streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Armazenamento",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Memória",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Todo o seu entretenimento em&nbsp;um&nbsp;PC",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Para um PC que também serve como centro de entretenimento, você precisa de armazenamento suficiente para todas as suas músicas, vídeos e jogos favoritos e da combinação certa de processador e tela para produzir visuais imersivos. Este PC possui todos os três.",
                "titleArray": [
                  {
                    "tr_title": "Pronto para efeitos visuais de última geração",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crie sua biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Uma janela para seu entretenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Jogos e streaming perfeitos",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crie sua biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Uma janela para seu entretenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Memória",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Tela",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Multitarefa em alta velocidade",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITAREFA EM ALTA VELOCIDADE",
                "tr_subtitle": "Faça mais sem perder a velocidade",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para rapidez em multitarefas:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Um processador que pode conciliar vários programas de uma só vez."
                  },
                  {
                    "tr_title": "Wi‑Fi rápido forte e confiável."
                  },
                  {
                    "tr_title": "Memória que alterna perfeitamente entre aplicativos."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chat por vídeo",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHAT POR VÍDEO",
                "tr_subtitle": "Conexões cristalinas",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para conversas em vídeo:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Um processador que pode gravar e compartilhar imagens ao mesmo tempo."
                  },
                  {
                    "tr_title": "Wi‑Fi rápido forte e confiável."
                  },
                  {
                    "tr_title": "Uma webcam que lhe ajudará a se apresentar de forma nítida."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pronto para RV",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Onde a RV ganha vida",
                "tr_subtitle": "O que é preciso para criar um mundo totalmente virtual? Em um PC, você precisará da coordenação entre o processador, a placa gráfica e a memória.",
                "titleArray": [
                  {
                    "tr_title": "Potencializando mundos virtuais",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "A realidade está nos detalhes",
                    "tr_title1": "Se jogue nessa experiência",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Edição de vídeo",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDIÇÃO DE VÍDEO",
                "tr_subtitle": "Faça a mágica do cinema",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para a edição de vídeo:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Um processador que pode lidar com arquivos grandes."
                  },
                  {
                    "tr_title": "Memória que torna o software de edição rápido e responsivo."
                  },
                  {
                    "tr_title": "Gráficos que reduzirão os tempos de renderização."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Multitarefa em alta velocidade",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITAREFA EM ALTA VELOCIDADE",
                "tr_subtitle": "Faça mais sem perder a velocidade",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para rapidez em multitarefas:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Um processador que pode conciliar vários programas de uma só vez."
                  },
                  {
                    "tr_title": "Wi‑Fi rápido forte e confiável."
                  },
                  {
                    "tr_title": "Memória que alterna perfeitamente entre aplicativos."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pronto para RV",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Onde a RV ganha vida",
                "tr_subtitle": "O que é preciso para criar um mundo totalmente virtual? Em um PC, você precisará da coordenação entre o processador, a placa gráfica e a memória.",
                "titleArray": [
                  {
                    "tr_title": "Potencializando mundos virtuais",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "A realidade está nos detalhes",
                    "tr_title1": "Se jogue nessa experiência",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Modelagem 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MODELAGEM 3D",
                "tr_subtitle": "Criações que te levam ao próximo nível",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para modelagem 3D:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Um processador que pode lidar com software avançado de criação."
                  },
                  {
                    "tr_title": "Memória que pode rapidamente carregar arquivos pesados."
                  },
                  {
                    "tr_title": "Gráficos para criação de imagens e edições poderosas."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Colaboração em tempo real",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLABORAÇÃO EM TEMPO REAL",
                "tr_subtitle": "Trabalhe de qualquer lugar",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para colaborações online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Um processador para potencializar o software de escritório."
                  },
                  {
                    "tr_title": "Wi‑Fi em que você pode confiar."
                  },
                  {
                    "tr_title": "Uma webcam que grava em HD brilhante."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pronto para RV",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Onde a RV ganha vida",
                "tr_subtitle": "O que é preciso para criar um mundo totalmente virtual? Em um PC, você precisará da coordenação entre o processador, a placa gráfica e a memória.",
                "titleArray": [
                  {
                    "tr_title": "Potencializando mundos virtuais",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "A realidade está nos detalhes",
                    "tr_title1": "Se jogue nessa experiência",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Notebooks Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Notebooks tão finos e leves não deveriam ser tão poderosos",
                "tr_subtitle": "Projetados para máxima mobilidade, os notebooks Intel® Evo™ têm toda a velocidade, os recursos gráficos e a autonomia da bateria de que você precisa, nos designs finos e leves mais impressionantes.",
                "titleArray": [
                  {
                    "tr_title": "Notebooks são mais importantes do que nunca, por isso nos certificamos de que eles possam fazer muito mais.",
                    "tr_subtitle": "Utilize a tela sensível ao toque <br/>para explorar, girar e ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Ativação em um piscar de&nbsp;olhos",
                    "tr_subtitle": "Com a ativação instantânea, seu&nbsp;PC&nbsp;pode ir do modo de suspensão a pronto para uso em menos de&nbsp;um&nbsp;segundo."
                  },
                  {
                    "tr_title": "Visualização imersiva",
                    "tr_subtitle": "Uma bela exibição dá vida aos efeitos visuais em resolução nítida e cores vívidas."
                  },
                  {
                    "tr_title": "Conclua suas tarefas rapidamente",
                    "tr_subtitle": "Desfrute do desempenho extremamente responsivo dos&nbsp;processadores Intel®&nbsp;Core™ da&nbsp;11ª&nbsp;Geração."
                  },
                  {
                    "tr_title": "Fique desconectado por&nbsp;mais&nbsp;tempo",
                    "tr_subtitle": "Mantenha a produtividade com um PC que otimiza a autonomia da bateria<br/>e é recarregado em um instante."
                  },
                  {
                    "tr_title": "Conectividade com cabo&nbsp;universal",
                    "tr_subtitle": "Torna a conexão de outros dispositivos ao seu PC simples, rápida e segura com a tecnologia Thunderbolt™&nbsp;4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexões rápidas e&nbsp;confiáveis",
                    "tr_subtitle": "Participe de conversas por vídeo ou&nbsp;compartilhe arquivos em uma conexão&nbsp;confiável usando o&nbsp;Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Notebooks Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tudo o que você precisa e muito mais em um notebook fino e leve.",
                "tr_subtitle": "Os notebooks Intel® Evo™ são projetados para máxima mobilidade e para proporcionar a melhor experiência<sup>2</sup>, independentemente do que você estiver fazendo.",
                "titleArray": [
                  {
                    "tr_title": "Notebooks são mais importantes do que nunca, por isso nos certificamos de que eles possam fazer muito mais.",
                    "tr_subtitle": "Utilize a tela sensível ao toque <br/>para explorar, girar e ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Ativação em um piscar de&nbsp;olhos",
                    "tr_subtitle": "Com a ativação instantânea, seu&nbsp;PC&nbsp;pode ir do modo de suspensão a pronto para uso em menos de&nbsp;um&nbsp;segundo."
                  },
                  {
                    "tr_title": "Visualização imersiva",
                    "tr_subtitle": "Uma bela exibição dá vida aos efeitos visuais em resolução nítida e cores vívidas."
                  },
                  {
                    "tr_title": "Desempenho revolucionário",
                    "tr_subtitle": "Conquiste potência extra onde você mais precisa com o desempenho inteligente dos processadores Intel® Core™ da 12ª Geração."
                  },
                  {
                    "tr_title": "Fique desconectado por&nbsp;mais&nbsp;tempo",
                    "tr_subtitle": "Mantenha a produtividade com um PC que otimiza a autonomia da bateria<br/>e é recarregado em um instante."
                  },
                  {
                    "tr_title": "Conectividade com cabo&nbsp;universal",
                    "tr_subtitle": "Torna a conexão de outros dispositivos ao seu PC simples, rápida e segura com a tecnologia Thunderbolt™&nbsp;4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexões rápidas e&nbsp;confiáveis",
                    "tr_subtitle": "Faça chamadas de vídeo ou compartilhe arquivos em uma conexão com a qual você pode contar usando o Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Notebooks Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Notebooks tão finos e leves não deveriam ser tão poderosos",
                "tr_subtitle": "Projetados para máxima mobilidade, os notebooks Intel® Evo™ têm toda a velocidade, os recursos gráficos e a autonomia da bateria de que você precisa, nos designs finos e leves mais impressionantes.",
                "titleArray": [
                  {
                    "tr_title": "Notebooks são mais importantes do que nunca, por isso nos certificamos de que eles possam fazer muito mais.",
                    "tr_subtitle": "Utilize a tela sensível ao toque <br/>para explorar, girar e ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Ativação em um piscar de&nbsp;olhos",
                    "tr_subtitle": "Com a ativação instantânea, seu&nbsp;PC&nbsp;pode ir do modo de suspensão a pronto para uso em menos de&nbsp;um&nbsp;segundo."
                  },
                  {
                    "tr_title": "Visualização imersiva",
                    "tr_subtitle": "Uma bela exibição dá vida aos efeitos visuais em resolução nítida e cores vívidas."
                  },
                  {
                    "tr_title": "Conclua suas tarefas rapidamente",
                    "tr_subtitle": "Desfrute do desempenho extremamente responsivo dos&nbsp;processadores Intel®&nbsp;Core™ da&nbsp;11ª&nbsp;Geração."
                  },
                  {
                    "tr_title": "Fique desconectado por&nbsp;mais&nbsp;tempo",
                    "tr_subtitle": "Mantenha a produtividade com um PC que otimiza a autonomia da bateria<br/>e é recarregado em um instante."
                  },
                  {
                    "tr_title": "Conectividade com cabo&nbsp;universal",
                    "tr_subtitle": "Torna a conexão de outros dispositivos ao seu PC simples, rápida e segura com a tecnologia Thunderbolt™&nbsp;4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexões rápidas e&nbsp;confiáveis",
                    "tr_subtitle": "Participe de conversas por vídeo ou&nbsp;compartilhe arquivos em uma conexão&nbsp;confiável usando o&nbsp;Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Notebooks Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tudo o que você precisa e muito mais em um notebook fino e leve.",
                "tr_subtitle": "Os notebooks Intel® Evo™ são projetados para máxima mobilidade e para proporcionar a melhor experiência<sup>2</sup>, independentemente do que você estiver fazendo.",
                "titleArray": [
                  {
                    "tr_title": "Notebooks são mais importantes do que nunca, por isso nos certificamos de que eles possam fazer muito mais.",
                    "tr_subtitle": "Utilize a tela sensível ao toque <br/>para explorar, girar e ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Ativação em um piscar de&nbsp;olhos",
                    "tr_subtitle": "Com a ativação instantânea, seu&nbsp;PC&nbsp;pode ir do modo de suspensão a pronto para uso em menos de&nbsp;um&nbsp;segundo."
                  },
                  {
                    "tr_title": "Visualização imersiva",
                    "tr_subtitle": "Uma bela exibição dá vida aos efeitos visuais em resolução nítida e cores vívidas."
                  },
                  {
                    "tr_title": "Desempenho revolucionário",
                    "tr_subtitle": "Conquiste potência extra onde você mais precisa com o desempenho inteligente dos processadores Intel® Core™ da 12ª Geração."
                  },
                  {
                    "tr_title": "Fique desconectado por&nbsp;mais&nbsp;tempo",
                    "tr_subtitle": "Mantenha a produtividade com um PC que otimiza a autonomia da bateria<br/>e é recarregado em um instante."
                  },
                  {
                    "tr_title": "Conectividade com cabo&nbsp;universal",
                    "tr_subtitle": "Torna a conexão de outros dispositivos ao seu PC simples, rápida e segura com a tecnologia Thunderbolt™&nbsp;4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexões rápidas e&nbsp;confiáveis",
                    "tr_subtitle": "Faça chamadas de vídeo ou compartilhe arquivos em uma conexão com a qual você pode contar usando o Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Notebooks Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tudo o que você precisa e muito mais em um notebook fino e leve.",
                "tr_subtitle": "Os notebooks Intel® Evo™ são projetados para máxima mobilidade e para proporcionar a melhor experiência<sup>2</sup>, independentemente do que você estiver fazendo.",
                "titleArray": [
                  {
                    "tr_title": "Notebooks são mais importantes do que nunca, por isso nos certificamos de que eles possam fazer muito mais.",
                    "tr_subtitle": "Utilize a tela sensível ao toque <br/>para explorar, girar e ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Ativação em um piscar de&nbsp;olhos",
                    "tr_subtitle": "Com a ativação instantânea, seu&nbsp;PC&nbsp;pode ir do modo de suspensão a pronto para uso em menos de&nbsp;um&nbsp;segundo."
                  },
                  {
                    "tr_title": "Visualização imersiva",
                    "tr_subtitle": "Uma bela exibição dá vida aos efeitos visuais em resolução nítida e cores vívidas."
                  },
                  {
                    "tr_title": "Desempenho revolucionário",
                    "tr_subtitle": "Conquiste potência extra onde você mais precisa com o desempenho inteligente dos processadores Intel® Core™ da 13ª Geração."
                  },
                  {
                    "tr_title": "Fique desconectado por&nbsp;mais&nbsp;tempo",
                    "tr_subtitle": "Mantenha a produtividade com um PC que otimiza a autonomia da bateria<br/>e é recarregado em um instante."
                  },
                  {
                    "tr_title": "Conectividade com cabo&nbsp;universal",
                    "tr_subtitle": "Torna a conexão de outros dispositivos ao seu PC simples, rápida e segura com a tecnologia Thunderbolt™&nbsp;4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexões rápidas e&nbsp;confiáveis",
                    "tr_subtitle": "Faça chamadas de vídeo ou compartilhe arquivos em uma conexão com a qual você pode contar usando o Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Notebooks Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tudo o que você precisa e muito mais em um notebook fino e leve.",
                "tr_subtitle": "Os notebooks Intel® Evo™ são projetados para máxima mobilidade e para proporcionar a melhor experiência<sup>2</sup>, independentemente do que você estiver fazendo.",
                "titleArray": [
                  {
                    "tr_title": "Notebooks são mais importantes do que nunca, por isso nos certificamos de que eles possam fazer muito mais.",
                    "tr_subtitle": "Utilize a tela sensível ao toque <br/>para explorar, girar e ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Ativação em um piscar de&nbsp;olhos",
                    "tr_subtitle": "Com a ativação instantânea, seu&nbsp;PC&nbsp;pode ir do modo de suspensão a pronto para uso em menos de&nbsp;um&nbsp;segundo."
                  },
                  {
                    "tr_title": "Visualização imersiva",
                    "tr_subtitle": "Uma bela exibição dá vida aos efeitos visuais em resolução nítida e cores vívidas."
                  },
                  {
                    "tr_title": "Desempenho revolucionário",
                    "tr_subtitle": "Conquiste potência extra onde você mais precisa com o desempenho inteligente dos processadores Intel® Core™ da 13ª Geração."
                  },
                  {
                    "tr_title": "Fique desconectado por&nbsp;mais&nbsp;tempo",
                    "tr_subtitle": "Mantenha a produtividade com um PC que otimiza a autonomia da bateria<br/>e é recarregado em um instante."
                  },
                  {
                    "tr_title": "Conectividade com cabo&nbsp;universal",
                    "tr_subtitle": "Torna a conexão de outros dispositivos ao seu PC simples, rápida e segura com a tecnologia Thunderbolt™&nbsp;4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Conexões rápidas e&nbsp;confiáveis",
                    "tr_subtitle": "Faça chamadas de vídeo ou compartilhe arquivos em uma conexão com a qual você pode contar usando o Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Em destaque",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "O que é Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Por que Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Criatividade",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Desperte a criatividade em&nbsp;mais&nbsp;lugares",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Edição de fotos em alta&nbsp;velocidade",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Crie arte original no local",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produtividade",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximize a produtividade com a IA Rewind",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Detecte deepfakes usando a IA antes de compartilhar",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Seu mundo. Em&nbsp;seus&nbsp;dispositivos.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilidade",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Diga oi para o controle de gestos",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Autonomia da bateria que não deixa você na mão",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Mantém você conectado automaticamente",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Em destaque",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "O que é Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Por que Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Criatividade",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Reprodução de vídeo rápida e&nbsp;suave",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Produza hits com o poder da IA",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "O caminho mais rápido para sua edição final",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produtividade",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximize a produtividade com a IA Rewind",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Detecte deepfakes usando a IA antes de compartilhar",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Seu mundo. Em&nbsp;seus&nbsp;dispositivos.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilidade",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Colabore de praticamente qualquer lugar",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Autonomia da bateria que não deixa você na mão",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Mantém você conectado automaticamente",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Todo o seu entretenimento em&nbsp;um&nbsp;PC",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Para um PC que também serve como centro de entretenimento, você precisa de armazenamento suficiente para todas as suas músicas, vídeos e jogos favoritos e da combinação certa de processador e tela para produzir visuais imersivos. Este PC possui todos os três.",
                "titleArray": [
                  {
                    "tr_title": "Jogos e streaming impressionantes",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crie sua biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Uma janela para seu entretenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Jogos e streaming perfeitos",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Crie sua biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Uma janela para seu entretenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Seja mais produtivo em qualquer lugar",
              "tr_tileTitle1": "Faça mais. E&nbsp;mais&nbsp;rápido.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Este PC possui o equilíbrio perfeito entre potência e portabilidade. A começar pelo processador fabricado para designs finos e leves, este PC também oferece o tipo de Wi&#8209;Fi capaz de manter você conectado e a bateria certa para você ficar desconectado da tomada.",
                "tr_subtitle1": "Este PC possui o equilíbrio perfeito entre potência e desempenho para&nbsp;ajudar&nbsp;você a fazer mais em menos tempo. A combinação de um processador robusto acelerado por uma memória ampla e Wi-Fi extremamente rápido simplificará suas tarefas do dia a dia.",
                "tr_subtitle2": "Este PC possui o equilíbrio perfeito entre potência e portabilidade. A começar pelo processador fabricado para designs finos e leves, este PC também oferece o tipo de Wi‑Fi capaz de manter você conectado e a memória ideal para multitarefa.",
                "titleArray": [
                  {
                    "tr_title": "Desempenho inteligente que faz a diferença",
                    "tr_title1": "Acelere com o desempenho inteligente",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "As melhores conexões da&nbsp;categoria",
                    "tr_title1": "Conecte-se com o que importa",
                    "tr_title2": "Um grande avanço em Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Fique longe da tomada por mais tempo",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Concilie programas como um&nbsp;profissional",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "A liberdade para criar",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "A criação de conteúdo é uma das tarefas que mais exigem de seu PC. Felizmente esses componentes têm o poder de agilizar seu processo de criação.",
                "titleArray": [
                  {
                    "tr_title": "Criatividade acelerada",
                    "tr_title1": "Navegue por projetos criativos",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Criação de alto nível",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memória para multitarefa",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Examine o que há neste&nbsp;PC",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Estes são os três componentes principais para você prestar atenção quando for escolher um PC novo. Ao decidir como você vai usar seu PC novo, você percorreu um longo caminho para decidir o quanto destes três componentes você vai precisar. Eles são:",
                "titleArray": [
                  {
                    "tr_title": "Processador",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Armazenamento",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Memória",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "A liberdade para criar",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "A criação de conteúdo é uma das tarefas que mais exigem de seu PC. Felizmente esses componentes têm o poder de agilizar seu processo de criação.",
                "titleArray": [
                  {
                    "tr_title": "Uma forma mais inteligente de&nbsp;criar",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Dê vida às suas imagens",
                    "tr_title1": "Que comece a criação",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memória para multitarefa",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Seja mais produtivo em qualquer lugar",
              "tr_tileTitle1": "Faça mais. E&nbsp;mais&nbsp;rápido.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Este PC possui o equilíbrio perfeito entre potência e portabilidade. A começar pelo processador fabricado para designs finos e leves, este PC também oferece o tipo de Wi&#8209;Fi capaz de manter você conectado e a bateria certa para você ficar desconectado da tomada.",
                "tr_subtitle1": "Este PC possui o equilíbrio perfeito entre potência e desempenho para&nbsp;ajudar&nbsp;você a fazer mais em menos tempo. A combinação de um processador robusto acelerado por uma memória ampla e Wi-Fi extremamente rápido simplificará suas tarefas do dia a dia.",
                "tr_subtitle2": "Este PC possui o equilíbrio perfeito entre potência e portabilidade. A começar pelo processador fabricado para designs finos e leves, este PC também oferece o tipo de Wi‑Fi capaz de manter você conectado e a memória ideal para multitarefa.",
                "titleArray": [
                  {
                    "tr_title": "Projetado para fazer mais",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "As melhores conexões da&nbsp;categoria",
                    "tr_title1": "Conecte-se com o que importa",
                    "tr_title2": "Um grande avanço em Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Fique longe da tomada por mais tempo",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Concilie programas como um&nbsp;profissional",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Pronto para RV",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Onde a RV ganha vida",
                "tr_subtitle": "O que é preciso para criar um mundo totalmente virtual? Em um PC, você precisará da coordenação entre o processador, a placa gráfica e a memória.",
                "titleArray": [
                  {
                    "tr_title": "Potencializando mundos virtuais",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "A realidade está nos detalhes",
                    "tr_title1": "Se jogue nessa experiência",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>uma visão para olhos&nbsp;cansados",
      "tr_sub_header": "A luz azul pode causar desconforto visual e perturbar seu ciclo de sono. A tela de OLED testada, SGS Eye Care, da Samsung ajuda não através da mudança das cores, mas pela redução do comprimento de onda da luz azul potencialmente prejudicial, proporcionando conforto aos olhos e reduzindo a fadiga ocular.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Menos luz azul nociva"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra em todas as&nbsp;maneiras",
      "tr_sub_header": "O design ultraleve e ultrafino do OLED maximiza a qualidade da imagem ao mesmo tempo em que minimiza o peso."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Preto verdadeiro",
      "tr_sub_header": "0,0005 nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "<span class='conventional_text'>Convencional</span><br/>74%",
      "tr_sub_header": "<span class='samsungoled_text'>Samsung OLED</span><br/>120%"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Streaming",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STREAMING",
                "tr_subtitle": "Entretenimento sem fim",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para o streaming de dados:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Um processador que pode transmitir em HD."
                  },
                  {
                    "tr_title": "Uma tela para belas imagens brilhantes."
                  },
                  {
                    "tr_title": "Wi‑Fi que oferece menos tempo de buffer e carregamento."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Navegação na web",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "NAVEGAÇÃO NA WEB",
                "tr_subtitle": "A internet facilitou",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para a navegação na web:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Um processador que pode carregar rapidamente páginas com conteúdo rico em mídia."
                  },
                  {
                    "tr_title": "Wi‑Fi veloz e confiável."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chat por vídeo",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHAT POR VÍDEO",
                "tr_subtitle": "Conexões cristalinas",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para conversas em vídeo:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Um processador que pode gravar e compartilhar imagens ao mesmo tempo."
                  },
                  {
                    "tr_title": "Wi‑Fi rápido forte e confiável."
                  },
                  {
                    "tr_title": "Uma webcam que lhe ajudará a se apresentar de forma nítida."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitarefa em alta velocidade",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITAREFA EM ALTA VELOCIDADE",
                "tr_subtitle": "Faça mais sem perder a velocidade",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para rapidez em multitarefas:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Um processador que pode conciliar vários programas de uma só vez."
                  },
                  {
                    "tr_title": "Wi‑Fi rápido forte e confiável."
                  },
                  {
                    "tr_title": "Memória que alterna perfeitamente entre aplicativos."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chat por vídeo",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHAT POR VÍDEO",
                "tr_subtitle": "Conexões cristalinas",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para conversas em vídeo:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Um processador que pode gravar e compartilhar imagens ao mesmo tempo."
                  },
                  {
                    "tr_title": "Wi‑Fi rápido forte e confiável."
                  },
                  {
                    "tr_title": "Uma webcam que lhe ajudará a se apresentar de forma nítida."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Jogos casuais",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "JOGOS CASUAIS",
                "tr_subtitle": "Hora de jogar",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para leveza em jogos:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Um processador que potencializa a jogabilidade suave."
                  },
                  {
                    "tr_title": "Memória que possibilita conversas por voz e transmissão ao mesmo tempo."
                  },
                  {
                    "tr_title": "Gráficos que potencializam imagens incríveis."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Edição de fotos",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDIÇÃO DE FOTOS",
                "tr_subtitle": "Fotos impecáveis",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para edição de fotos:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Um processador com potência para editar grandes grupos de imagens."
                  },
                  {
                    "tr_title": "Memória que acelera seu software de edição."
                  },
                  {
                    "tr_title": "A tecnologia Thunderbolt™ 4 permite transferir arquivos rapidamente."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Multitarefa em alta velocidade",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MULTITAREFA EM ALTA VELOCIDADE",
                "tr_subtitle": "Faça mais sem perder a velocidade",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para rapidez em multitarefas:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Um processador que pode conciliar vários programas de uma só vez."
                  },
                  {
                    "tr_title": "Wi‑Fi rápido forte e confiável."
                  },
                  {
                    "tr_title": "Memória que alterna perfeitamente entre aplicativos."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Colaboração em tempo real",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLABORAÇÃO EM TEMPO REAL",
                "tr_subtitle": "Trabalhe de qualquer lugar",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para colaborações online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Um processador para potencializar o software de escritório."
                  },
                  {
                    "tr_title": "Wi‑Fi em que você pode confiar."
                  },
                  {
                    "tr_title": "Uma webcam que grava em HD brilhante."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Edição de vídeo",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDIÇÃO DE VÍDEO",
                "tr_subtitle": "Faça a mágica do cinema",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para a edição de vídeo:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Um processador que pode lidar com arquivos grandes."
                  },
                  {
                    "tr_title": "Memória que torna o software de edição rápido e responsivo."
                  },
                  {
                    "tr_title": "Gráficos que reduzirão os tempos de renderização."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Edição de fotos",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDIÇÃO DE FOTOS",
                "tr_subtitle": "Fotos impecáveis",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para edição de fotos:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Um processador com potência para editar grandes grupos de imagens."
                  },
                  {
                    "tr_title": "Memória que acelera seu software de edição."
                  },
                  {
                    "tr_title": "A tecnologia Thunderbolt™ 4 permite transferir arquivos rapidamente."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Edição de vídeo",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDIÇÃO DE VÍDEO",
                "tr_subtitle": "Faça a mágica do cinema",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para a edição de vídeo:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Um processador que pode lidar com arquivos grandes."
                  },
                  {
                    "tr_title": "Memória que torna o software de edição rápido e responsivo."
                  },
                  {
                    "tr_title": "Gráficos que reduzirão os tempos de renderização."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Modelagem 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MODELAGEM 3D",
                "tr_subtitle": "Criações que te levam ao próximo nível",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para modelagem 3D:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Um processador que pode lidar com software avançado de criação."
                  },
                  {
                    "tr_title": "Memória que pode rapidamente carregar arquivos pesados."
                  },
                  {
                    "tr_title": "Gráficos para criação de imagens e edições poderosas."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Colaboração em tempo real",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLABORAÇÃO EM TEMPO REAL",
                "tr_subtitle": "Trabalhe de qualquer lugar",
                "tr_subtitle1": "Aqui está o que encontramos neste PC que é ótimo para colaborações online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Um processador para potencializar o software de escritório."
                  },
                  {
                    "tr_title": "Wi‑Fi em que você pode confiar."
                  },
                  {
                    "tr_title": "Uma webcam que grava em HD brilhante."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Aplicativo Intel® Unison™",
    "tr_title": "Seu mundo conectado",
    "tr_subtitle": "Transfira arquivos e fotos de forma fácil enquanto gerencia notificações, chamadas e mensagens de texto do telefone a partir do&nbsp;seu&nbsp;PC.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "TRANSFIRA ARQUIVOS E&nbsp;FOTOS",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "FAÇA E RECEBA CHAMADAS",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "ENVIE E RECEBA MENSAGENS DE&nbsp;TEXTO",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "GERENCIE AS NOTIFICAÇÕES DO TELEFONE",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "SEU MUNDO CONECTADO",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}