const Library_mobile_ptPT = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Equipado com um processador [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Equipado com um processador [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Como um cérebro, o processador controla o que seu PC faz. Isso significa que os vídeos aos quais assiste, os jogos que joga, os sites que visita, tudo começa com o seu processador.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "O processador é o cérebro do&nbsp;seu&nbsp;PC"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Desempenho e valor.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processador Intel® Pentium® Silver",
                    "tr_subtitle": "Controle o seu mundo digital com o desempenho para aceder a ficheiros a partir da Web. Execute várias tarefas em simultâneo alternando facilmente entre programas e faça mais em menos tempo.",
                    "tr_gen_core_title": "Processador Intel® Pentium® Silver"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processador Intel® Pentium® Gold",
                    "tr_subtitle": "Produtividade melhorada - desempenho elevado para enfrentar os dias mais exigentes. Navegação simplificada - carregamento rápido de páginas web ricas em conteúdo multimédia, para pesquisar mais e esperar menos.",
                    "tr_gen_core_title": "Processador Intel® Pentium® Gold"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processador Intel® Celeron®",
                    "tr_subtitle": "Desempenho que leva o trabalho e a diversão para o próximo nível. Desfrute de filmes e vídeos online com um nível de detalhe fantástico. Trabalhe mais e espere menos.",
                    "tr_gen_core_title": "Processador Intel® Celeron®"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Processador Intel® Pentium®",
                    "tr_subtitle": "Concebido para entretenimento, streaming de vídeo e produtividade ótimos. Desfrute de filmes e vídeos online com um nível de detalhe fantástico. Trabalhe mais e espere menos.",
                    "tr_gen_core_title": "Processador Intel® Pentium®"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "O desempenho que precisa. A um preço acessível.",
                "tr_title": "Processador Intel®",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "O valor numa categoria própria",
                    "tr_subtitle": "Um processador Intel® pode gerir praticamente qualquer tarefa, a um preço acessível. Passe facilmente de um software para outro. Ou desfrute de gráficos de alta qualidade com um desempenho constante.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Crie ligações mais fortes",
                    "tr_subtitle": "Vídeo com mais qualidade, som com uma maior clareza e ligação à Internet quase 3x mais rápida, para colaborar com confiança onde for preciso.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "A diferença é como do dia para a noite",
                    "tr_subtitle": "Desde maratonas cinematográficas a retoques de última hora, o processador Intel® prolonga a autonomia da bateria do seu dispositivo para experiências verdadeiramente imersivas e sem interrupções.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Qualquer ambiente pode ser de aprendizagem",
                    "tr_subtitle": "Execute várias ferramentas de aprendizagem online com facilidade em qualquer canto da casa."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Aprenda onde quiser",
                    "tr_subtitle": "Assista a aulas onde for necessário enquanto executa várias ferramentas de e&#8209;learning ao mesmo tempo."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Obtenha a potência necessária para o entretenimento e a produtividade adequados ao seu estilo de vida e ao seu orçamento.",
                "tr_title": "Desempenho Intel para qualquer orçamento.",
                "tr_gen_core_title": "Processador Intel® Celeron®",
                "processorCompare": {
                  "tr_title": "ENCONTRE O NÍVEL CERTO DE DESEMPENHO E PREÇO",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Processador Intel® Celeron®"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Processador Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Navegação web rápida e rica em multimédia",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Facilidade em alternar entre programas",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos casuais",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming de vídeos em 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Edição ligeira de fotografias e vídeos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Produtividade rápida com desempenho inteligente",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Equipado com um processador Intel® Core™ [cpu_fam] de 7ª geração"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Equipado com um processador Intel® Core™ [cpu_fam] de 6ª geração"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Equipado com um processador Intel® Core™ [cpu_fam] de 5ª geração"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Equipado com um processador Intel® Core™ [cpu_fam] de 4ª geração"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Equipado com um processador Intel® Core™ [cpu_fam] de 3ª geração"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Equipado com um processador Intel® Core™ [cpu_fam] de 2ª geração"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Equipado com um processador Intel® Core™ [cpu_fam] de 1ª geração"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Recomendado para:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Equipado com um processador Intel® Core™ i3 de 8ª geração",
                "tr_gen_core_title": "Processador Intel® Core™ i3 de 8ª geração"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Os processadores Intel® Core™ de 9ª geração foram criados para acompanhar o seu mundo digital. Com a sua combinação de velocidade e desempenho, poderá fazer mais do que gosta com o seu PC sem frustrações.",
                "tr_title": "Equipado com um processador Intel® Core™ i3 de 9ª geração",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;9ª&nbsp;geração"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "A autonomia da bateria acompanha o desempenho versátil, para poder ir&nbsp;para onde quiser.",
                "tr_title": "Potência contínua",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;10ª&nbsp;geração",
                "processorCompare": {
                  "tr_title": "ENCONTRE O NÍVEL IDEAL DE DESEMPENHO DA&nbsp;10ª&nbsp;GERAÇÃO",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming de vídeos em 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ligações rápidas e fiáveis",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Aplicação de filtros de vídeo e máscaras de fotografia",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos a 1080p com velocidades de fotogramas fluídas",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "O melhor para trabalhar com ficheiros grandes e programas criativos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Passe facilmente do streaming de filmes à criação de apresentações com&nbsp;desempenho&nbsp;de&nbsp;sobra.",
                "tr_title": "Potência contínua",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;10ª&nbsp;geração"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Este é um computador portátil concebido especialmente para quem está sempre em viagem.",
                "tr_title": "Conheça uma nova classe de portáteis",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;10ª&nbsp;geração"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Concebido especificamente para PC portáteis, o processador Intel® Core™ i3 de 11ª geração permite-lhe fazer mais do que nunca, onde quer que esteja.",
                "tr_title": "Faça mais com o seu novo portátil",
                "tr_gen_core_title": "Processador Intel® Core™ i3 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i3 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Se toda a família usa um computador de secretária para todas as tarefas, precisa da potência e versatilidade de um processador Intel® Core™ i3 de 11ª geração.",
                "tr_title": "Esteja pronto para tudo com um desempenho que quebra barreiras.",
                "tr_gen_core_title": "Processador Intel® Core™ i3 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i3 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Equipado com um processador Intel® Core™ i5 de 8ª geração",
                "tr_gen_core_title": "Processador Intel® Core™ i5 de 8ª geração"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Os processadores Intel® Core™ de 9ª geração foram criados para acompanhar o seu mundo digital. Com a sua combinação de velocidade e desempenho, poderá fazer mais do que gosta com o seu PC sem frustrações.",
                "tr_title": "Equipado com um processador Intel® Core™ i5 de 9ª geração",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;9ª&nbsp;geração"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "JOGABILIDADE E RV ESTÁVEIS NOS JOGOS MAIS EXIGENTES",
                "tr_title": "Comece a jogar a sério",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;9ª&nbsp;geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ INDICADO PARA A SUA FORMA DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogue, faça streaming e grave sem cedências",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Melhor processador Intel® Core™ de 9ª geração para jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Pode utilizar facilmente vários programas, por isso, está pronto para tudo, onde quer que vá.",
                "tr_title": "Várias tarefas em simultâneo, onde quer que esteja",
                "tr_gen_core_title": "Processador Intel® Core™ i5 de&nbsp;10ª&nbsp;geração",
                "processorCompare": {
                  "tr_title": "ENCONTRE O NÍVEL IDEAL DE DESEMPENHO DA&nbsp;10ª&nbsp;GERAÇÃO",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming de vídeos em 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ligações rápidas e fiáveis",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Aplicação de filtros de vídeo e máscaras de fotografia",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos a 1080p com velocidades de fotogramas fluídas",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "O melhor para trabalhar com ficheiros grandes e programas criativos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Tudo o que faz, a outro nível: desde o entretenimento à produtividade e criação de conteúdos.",
                "tr_title": "Atualize para um PC mais potente",
                "tr_gen_core_title": "Processador Intel® Core™ i5 de&nbsp;10ª&nbsp;geração"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Este é um computador portátil concebido especialmente para quem está sempre em viagem.",
                "tr_title": "Conheça uma nova classe de portáteis",
                "tr_gen_core_title": "Processador Intel® Core™ i5 de 10ª geração"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Construído para streaming e jogos AAA sem interrupções",
                "tr_title": "Jogue à sua medida",
                "tr_gen_core_title": "Processador Intel® Core™ i5 de&nbsp;10ª&nbsp;geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DE 10ª GERAÇÃO INDICADO PARA A SUA FORMA DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA sem interrupções",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Desempenho máximo em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com possibilidade de overclocking<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Construído para streaming e jogos AAA sem interrupções",
                "tr_title": "Jogue à sua medida",
                "tr_gen_core_title": "Processador Intel® Core™ i5 de 10ª geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DE 10ª GERAÇÃO INDICADO PARA A SUA FORMA DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA sem interrupções",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Desempenho máximo em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com possibilidade de overclocking<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Pode utilizar facilmente vários programas, por isso, está pronto para tudo, onde quer que vá.",
                "tr_title": "Várias tarefas em simultâneo, onde quer que esteja",
                "tr_gen_core_title": "Processador Intel® Core™ i5 de 10ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;10ª&nbsp;GERAÇÃO"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "O processador Intel® Core™ i5 de 11ª geração traz experiências visuais vibrantes e produtividade de larga escala aos PC mais finos e leves.",
                "tr_title": "Potência portátil impressionante",
                "tr_gen_core_title": "Processador Intel® Core™ i5 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Se toda a família usa um computador de secretária para todas as tarefas, precisa da potência e versatilidade de um processador Intel® Core™ i5 de 11ª geração.",
                "tr_title": "Esteja pronto para tudo com um desempenho que quebra barreiras.",
                "tr_gen_core_title": "Processador Intel® Core™ i5 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "O processador Intel® Core™ i5 de 11ª geração oferece experiências visuais vibrantes e produtividade de larga escala aos portáteis finos e leves.",
                "tr_title": "Potência portátil impressionante",
                "tr_gen_core_title": "Processador Intel® Core™ i5 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;11ª&nbsp;GERAÇÃO"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Equipado com um processador Intel® Core™ i7 de 8ª geração",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de 8ª geração"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Os processadores Intel® Core™ de 9ª geração foram criados para acompanhar o seu mundo digital. Com a sua combinação de velocidade e desempenho, poderá fazer mais do que gosta com o seu PC sem frustrações.",
                "tr_title": "Equipado com um processador Intel® Core™ i7 de 9ª geração",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;9ª&nbsp;geração"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ATÉ 32% MAIS FPS ao jogar, fazer streaming e gravar, comparado a um PC com 3 ANOS DE USO<sup>3</sup>",
                "tr_title": "O poder de partilhar os seus melhores momentos de jogo",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;9ª&nbsp;geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ INDICADO PARA A SUA FORMA DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogue, faça streaming e grave sem cedências",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Melhor processador Intel® Core™ de 9ª geração para jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Concebido para ser eficiente, este dispositivo elegante pode ter um grande impacto na forma como cria, estabelece ligações e faz streaming.",
                "tr_title": "Desempenho avançado em qualquer lugar",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de&nbsp;10ª&nbsp;geração",
                "processorCompare": {
                  "tr_title": "ENCONTRE O NÍVEL IDEAL DE DESEMPENHO DA&nbsp;10ª&nbsp;GERAÇÃO",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Streaming de vídeos em 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ligações rápidas e fiáveis",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Aplicação de filtros de vídeo e máscaras de fotografia",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos a 1080p com velocidades de fotogramas fluídas",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "O melhor para trabalhar com ficheiros grandes e programas criativos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Desempenho avançado para elevar tudo o que faz a um nível superior.",
                "tr_title": "O poder para fazer tudo",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de&nbsp;10ª&nbsp;geração"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Este é um computador portátil concebido especialmente para quem está sempre em viagem.",
                "tr_title": "Conheça uma nova classe de portáteis",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de 10ª geração"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Jogue, transmita e grave com uma vantagem competitiva",
                "tr_title": "Os seus jogos a outro nível",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de&nbsp;10ª&nbsp;geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DE 10ª GERAÇÃO INDICADO PARA A SUA FORMA DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA sem interrupções",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Desempenho máximo em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com possibilidade de overclocking<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Jogue, transmita e grave com uma vantagem competitiva",
                "tr_title": "Os seus jogos a outro nível",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de 10ª geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DE 10ª GERAÇÃO INDICADO PARA A SUA FORMA DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA sem interrupções",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Desempenho máximo em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com possibilidade de overclocking<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Desenvolvido para ser eficiente, este dispositivo elegante pode ter um grande impacto na forma como cria, estabelece ligações e faz streaming.",
                "tr_title": "Desempenho de excelência em qualquer lugar",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de 10ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;10ª&nbsp;GERAÇÃO"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "O processador Intel® Core™ i7 de 11ª geração proporciona criação de conteúdo avançada, jogabilidade estável e entretenimento de nível avançado a PC ultraportáteis.",
                "tr_title": "Desempenho portátil que quebra barreiras",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de&nbsp;11ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Faça do seu computador de secretária o principal destino para tudo o que for preciso em casa com o desempenho que quebra barreiras de um processador Intel® Core™ i7 de 11ª geração.",
                "tr_title": "Trabalhar. Aprender. Jogar. Sem barreiras.",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "O processador Intel® Core™ i7 de 11ª geração oferece criação de conteúdo avançada, jogabilidade estável e entretenimento de nível avançado aos portáteis finos e leves.",
                "tr_title": "Desempenho portátil que quebra barreiras",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;11ª&nbsp;GERAÇÃO"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Faça do seu computador de secretária o principal destino para tudo o que for preciso em casa com o desempenho que quebra barreiras de um processador Intel® Core™ i7 de 11ª geração.",
                "tr_title": "Criação sem limites",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;11ª&nbsp;GERAÇÃO"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Com criação de conteúdo avançada, jogabilidade estável e entretenimento de nível avançado em portáteis finos e leves, este processador Intel® Core™ i7 de 11ª geração está ainda melhor.",
                "tr_title": "Desempenho portátil que quebra barreiras",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;11ª&nbsp;GERAÇÃO"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Equipado com um processador Intel® Core™ i9 de 8ª geração",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 8ª geração"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Os processadores Intel® Core™ de 9ª geração foram criados para acompanhar o seu mundo digital. Com a sua combinação de velocidade e desempenho, poderá fazer mais do que gosta com o seu PC sem frustrações.",
                "tr_title": "Equipado com um processador Intel® Core™ i9 de 9ª geração",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;9ª&nbsp;geração"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ATÉ 41% MAIS FPS ao jogar, fazer streaming e gravar, comparado a um PC com 3 ANOS DE USO<sup>4</sup>",
                "tr_title": "Os jogadores exigem. Intel® Core™ i9 fornece.",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;9ª&nbsp;geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ INDICADO PARA A SUA FORMA DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogue, faça streaming e grave sem cedências",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Melhor processador Intel® Core™ de 9ª geração para jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Teste o seu potencial com a derradeira experiência de jogos",
                "tr_title": "Competição ao mais alto nível",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 10ª geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DE 10ª GERAÇÃO INDICADO PARA A SUA FORMA DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA sem interrupções",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Desempenho máximo em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com possibilidade de overclocking<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Teste o seu potencial com a derradeira experiência de jogos",
                "tr_title": "Competição ao mais alto nível",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 10ª geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DE 10ª GERAÇÃO INDICADO PARA A SUA FORMA DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA sem interrupções",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Desempenho máximo em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com possibilidade de overclocking<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Teste o seu potencial com a derradeira experiência de criação",
                "tr_title": "Criação ao mais alto nível",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 10ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;10ª&nbsp;GERAÇÃO",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Os portáteis Intel® Evo™ equipados com um processador Intel® Core™ i5 de 11ª geração e gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> proporcionam velocidade e desempenho incríveis num design fino e leve.",
                "tr_title": "Potência superior para um portátil evoluído",
                "tr_gen_core_title": "Processador Intel® Core™ i5 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Os portáteis Intel® Evo™ equipados com um processador Intel® Core™ i7 de 11ª geração e gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> proporcionam velocidade e desempenho avançados para uma experiência móvel de excelência.",
                "tr_title": "Potência avançada para um portátil evoluído",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Desfrute de um ótimo equilíbrio entre desempenho, multimédia e conectividade a um preço impressionante.",
                "tr_title": "Desempenho e conectividade a um preço impressionante.",
                "tr_gen_core_title": "Processador Intel® Pentium® Silver",
                "processorCompare": {
                  "tr_title": "ENCONTRE O NÍVEL CERTO DE DESEMPENHO E PREÇO",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Processador Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Processador Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Navegação web rápida e rica em multimédia",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Facilidade em alternar entre programas",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos casuais",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming de vídeos em 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Edição ligeira de fotografias e vídeos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Produtividade rápida com desempenho inteligente",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Desfrute da execução de várias tarefas em simultâneo sem interrupções, edição simples de fotografias e vídeos, vídeos em 4K e gráficos nítidos por um preço inacreditável.",
                "tr_title": "Desempenho impressionante para trabalho e lazer.",
                "tr_gen_core_title": "Processador Intel® Pentium® Gold",
                "processorCompare": {
                  "tr_title": "ENCONTRE O NÍVEL CERTO DE DESEMPENHO E PREÇO",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Processador Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Processador Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Navegação web rápida e rica em multimédia",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Facilidade em alternar entre programas",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos casuais",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming de vídeos em 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Edição ligeira de fotografias e vídeos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Produtividade rápida com desempenho inteligente",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Jogos AAA e grande autonomia da bateria num PC ultraportátil. Sem cedências na forma como joga.",
                "tr_title": "Sem cedências.",
                "tr_gen_core_title": "Processador Intel® Core™ i5 de 11ª geração",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ENCONTRE O SEU PROCESSADOR INTEL® CORE™ ULTRAPORTÁTIL PARA JOGOS",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5-11300H de 11ª geração"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7-11370H de 11ª geração"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7-11375H Special&nbsp;Edition de&nbsp;11ª&nbsp;geração"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Até <span class='style_badge'>4,4 GHz</span> de velocidade máxima do relógio",
                      "tr_td2_title": "Até <span class='style_badge'>4,8 GHz</span> de velocidade máxima do relógio",
                      "tr_td3_title": "Até <span class='style_badge'>5,0 GHz</span> de velocidade máxima do relógio"
                    },
                    {
                      "tr_td1_title": "4 núcleos/ 8&nbsp;threads",
                      "tr_td2_title": "4 núcleos/ 8&nbsp;threads",
                      "tr_td3_title": "4 núcleos/ 8&nbsp;threads"
                    },
                    {
                      "tr_td1_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td2_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td3_title": "Tecnologia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Cache inteligente Intel® de&nbsp;8&nbsp;MB",
                      "tr_td2_title": "Cache inteligente Intel® de&nbsp;12&nbsp;MB",
                      "tr_td3_title": "Cache inteligente Intel® de&nbsp;12&nbsp;MB"
                    },
                    {
                      "tr_td1_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Jogos AAA de 1080p com taxas de FPS elevadas em altas definições e um desempenho sem fios excelente num PC ultraportátil.",
                "tr_title": "Não comprometa nada.",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de 11ª geração",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ENCONTRE O SEU PROCESSADOR INTEL® CORE™ ULTRAPORTÁTIL PARA JOGOS",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5-11300H de 11ª geração"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7-11370H de 11ª geração"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7-11375H Special&nbsp;Edition de&nbsp;11ª&nbsp;geração"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Até <span class='style_badge'>4,4 GHz</span> de velocidade máxima do relógio",
                      "tr_td2_title": "Até <span class='style_badge'>4,8 GHz</span> de velocidade máxima do relógio",
                      "tr_td3_title": "Até <span class='style_badge'>5,0 GHz</span> de velocidade máxima do relógio"
                    },
                    {
                      "tr_td1_title": "4 núcleos/ 8&nbsp;threads",
                      "tr_td2_title": "4 núcleos/ 8&nbsp;threads",
                      "tr_td3_title": "4 núcleos/ 8&nbsp;threads"
                    },
                    {
                      "tr_td1_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td2_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td3_title": "Tecnologia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Cache inteligente Intel® de&nbsp;8&nbsp;MB",
                      "tr_td2_title": "Cache inteligente Intel® de&nbsp;12&nbsp;MB",
                      "tr_td3_title": "Cache inteligente Intel® de&nbsp;12&nbsp;MB"
                    },
                    {
                      "tr_td1_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Até 5 GHz de velocidade máxima do relógio para impulsionar ainda mais os seus jogos e um PC ultraportátil para os levar consigo para onde quer que vá.",
                "tr_title": "Mais potência, menos&nbsp;peso.",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 Special Edition de&nbsp;11ª&nbsp;geração",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ENCONTRE O SEU PROCESSADOR INTEL® CORE™ ULTRAPORTÁTIL PARA JOGOS",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5-11300H de 11ª geração"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7-11370H de 11ª geração"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7-11375H Special&nbsp;Edition de&nbsp;11ª&nbsp;geração"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Até <span class='style_badge'>4,4 GHz</span> de velocidade máxima do relógio",
                      "tr_td2_title": "Até <span class='style_badge'>4,8 GHz</span> de velocidade máxima do relógio",
                      "tr_td3_title": "Até <span class='style_badge'>5,0 GHz</span> de velocidade máxima do relógio"
                    },
                    {
                      "tr_td1_title": "4 núcleos/ 8&nbsp;threads",
                      "tr_td2_title": "4 núcleos/ 8&nbsp;threads",
                      "tr_td3_title": "4 núcleos/ 8&nbsp;threads"
                    },
                    {
                      "tr_td1_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td2_title": "Tecnologia Intel® Turbo Boost",
                      "tr_td3_title": "Tecnologia Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Cache inteligente Intel® de&nbsp;8&nbsp;MB",
                      "tr_td2_title": "Cache inteligente Intel® de&nbsp;12&nbsp;MB",
                      "tr_td3_title": "Cache inteligente Intel® de&nbsp;12&nbsp;MB"
                    },
                    {
                      "tr_td1_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "O processador Intel® Core™ i7 Special Edition de 11ª geração traz aos PC ultraportáteis criação de conteúdo avançada, jogabilidade suave e entretenimento de alto nível.",
                "tr_title": "Desempenho portátil que quebra barreiras",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 Special Edition de&nbsp;11ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 SPECIAL EDITION DE 11ª GERAÇÃO"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "O processador Intel® Core™ i7 Special Edition de 11ª geração traz aos PC ultraportáteis criação de conteúdo avançada, jogabilidade suave e entretenimento de alto nível.",
                "tr_title": "Desempenho portátil que quebra barreiras",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 Special Edition de&nbsp;11ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 SPECIAL EDITION DE 11ª GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7 Special Edition",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "As velocidades do relógio rápidas e o elevado número de núcleos funcionam perfeitamente em conjunto, para jogos mais competitivos e criação de conteúdo mais complexa.",
                "tr_title": "Potência impressionante. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 11ª geração",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "O equilíbrio perfeito entre a velocidade do relógio e o desempenho do núcleo melhora a jogabilidade, tanto em jogos competitivos com taxas de FPS elevadas como em jogos AAA envolventes.",
                "tr_title": "Potência com finalidade.",
                "tr_gen_core_title": "Processador Intel® Core™ i5 de 11ª geração",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Reproduza, grave e faça streaming de títulos exigentes com taxas de FPS elevadas e passe sem esforço para grandes volumes de trabalho com execução de várias tarefas em simultâneo.",
                "tr_title": "Potência para ultrapassar limites.",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de 11ª geração",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As velocidades do relógio rápidas e o elevado número de núcleos funcionam perfeitamente em conjunto, para jogos mais competitivos e criação de conteúdo mais complexa.",
                "tr_title": "Potência impressionante. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 11ª geração",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Passe facilmente do streaming de filmes à criação de apresentações com&nbsp;desempenho&nbsp;de&nbsp;sobra.",
                "tr_title": "Potência contínua",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 com Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processador Intel®&nbsp;Core™&nbsp;i3 com Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "A autonomia da bateria acompanha o desempenho versátil, para poder ir&nbsp;para onde quiser.",
                "tr_title": "Potência contínua",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 com Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processador Intel®&nbsp;Core™&nbsp;i3 com Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Pode utilizar facilmente vários programas, por isso, está pronto para tudo, onde quer que vá.",
                "tr_title": "Várias tarefas em simultâneo, onde quer que esteja",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 com Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processador Intel®&nbsp;Core™&nbsp;i5 com Intel®&nbsp;Hybrid Technology"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Tudo o que faz, a outro nível: desde o entretenimento à produtividade e criação de conteúdos.",
                "tr_title": "Atualize para um PC mais potente",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 com Intel®&nbsp;Hybrid Technology",
                "tr_subTitle2": "Processador Intel®&nbsp;Core™&nbsp;i5 com Intel®&nbsp;Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "A mobilidade com o desempenho para jogos dos computadores de secretária, seja para jogar competitivamente com FPS elevados, seja para experiências AAA imersivas.",
                "tr_title": "Potência com finalidade.",
                "tr_gen_core_title": "Processador Intel® Core™ i5 de 11ª geração",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "A mobilidade com o desempenho para jogos dos computadores de secretária, seja para jogar competitivamente com FPS elevados, seja para experiências AAA imersivas.",
                "tr_title": "Potência com finalidade.",
                "tr_gen_core_title": "Processador Intel® Core™ i5 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Um portátil que permite jogar, fazer streaming e gravar simultaneamente com FPS elevados e alternar facilmente para trabalhos pesados de várias tarefas.",
                "tr_title": "Potência para ultrapassar limites.",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de 11ª geração",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Um portátil que permite jogar, fazer streaming e gravar simultaneamente com FPS elevados e alternar facilmente para trabalhos pesados de várias tarefas.",
                "tr_title": "Potência para ultrapassar limites.",
                "tr_gen_core_title": "Processador Intel® Core™ i7 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As velocidades rápidas do relógio e o elevado número de núcleos funcionam perfeitamente em conjunto para tirar maior partido dos jogos competitivos e da criação de conteúdos complexos, independentemente de onde utiliza o PC.",
                "tr_title": "Potência impressionante. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 11ª geração",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As velocidades rápidas do relógio e o elevado número de núcleos funcionam perfeitamente em conjunto para tirar maior partido dos jogos competitivos e da criação de conteúdos complexos, independentemente de onde utiliza o PC.",
                "tr_title": "Potência impressionante. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As velocidades rápidas do relógio, o elevado número de núcleos e o desempenho sem limites funcionam perfeitamente em conjunto para tirar maior partido dos jogos competitivos e da criação de conteúdos complexos, independentemente de onde utiliza PC.",
                "tr_title": "Potência sem limites. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 11ª geração",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As velocidades rápidas do relógio, o elevado número de núcleos e o desempenho sem limites funcionam perfeitamente em conjunto para tirar maior partido dos jogos competitivos e da criação de conteúdos complexos, independentemente de onde utiliza PC.",
                "tr_title": "Potência sem limites. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As velocidades do relógio rápidas e o elevado número de núcleos funcionam perfeitamente em conjunto, para jogos mais competitivos e criação de conteúdo mais complexa.",
                "tr_title": "Potência impressionante. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As rápidas velocidades do relógio e o alto número de núcleos trabalham em conjunto de forma contínua para tirar maior partido da criação de conteúdo complexa, para onde quer que leve o seu PC.",
                "tr_title": "Potência impressionante. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As rápidas velocidades do relógio e o alto número de núcleos trabalham em conjunto de forma contínua para tirar maior partido da criação de conteúdo complexa.",
                "tr_title": "Potência impressionante. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Teste o seu potencial com a derradeira experiência de jogos",
                "tr_title": "Competição ao mais alto nível",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 10ª geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DE 10ª GERAÇÃO INDICADO PARA A SUA FORMA DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA sem interrupções",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Desempenho máximo em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com possibilidade de overclocking<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Teste o seu potencial com a derradeira experiência de jogos",
                "tr_title": "Competição ao mais alto nível",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 10ª geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ DE 10ª GERAÇÃO INDICADO PARA A SUA FORMA DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA sem interrupções",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streaming e gravação",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Criação de conteúdo avançada",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Desempenho máximo em jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Desbloqueado e com possibilidade de overclocking<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Este é um computador portátil concebido especialmente para quem está sempre em viagem.",
                "tr_title": "Conheça uma nova classe de portáteis",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 10ª geração"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As velocidades do relógio rápidas e o elevado número de núcleos funcionam perfeitamente em conjunto, para jogos mais competitivos e criação de conteúdo mais complexa.",
                "tr_title": "Potência sem limites. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As rápidas velocidades do relógio, o alto número de núcleos e o desempenho desbloqueado trabalham em conjunto de forma contínua para tirar maior partido da criação de conteúdo complexa, para onde quer que leve o seu PC.",
                "tr_title": "Potência sem limites. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "As rápidas velocidades do relógio e o alto número de núcleos trabalham em conjunto de forma contínua para tirar maior partido da criação de conteúdo complexa.",
                "tr_title": "Potência sem limites. Equilíbrio perfeito.",
                "tr_gen_core_title": "Processador Intel® Core™ i9 de 11ª geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;11ª&nbsp;GERAÇÃO",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache inteligente Intel®</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Jogabilidade e RV estáveis nos jogos mais exigentes",
                "tr_title": "Comece a jogar a sério",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;9ª&nbsp;geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ INDICADO PARA A SUA FORMA DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogue, faça streaming e grave sem cedências",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Melhor processador Intel® Core™ de 9ª geração para jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ATÉ 32% MAIS FPS <br/><span class='tr_subtitle_font'>ao jogar, fazer streaming e gravar, comparado a um PC com 3 anos de uso<sup>3</sup></span>",
                "tr_title": "O poder de partilhar os seus melhores momentos de jogo",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;9ª&nbsp;geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ INDICADO PARA A SUA FORMA DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogue, faça streaming e grave sem cedências",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Melhor processador Intel® Core™ de 9ª geração para jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ATÉ 41% MAIS FPS <br/><span class='tr_subtitle_font'>ao jogar, fazer streaming e gravar, comparado a um PC com 3 anos de uso<sup>4</sup></span>",
                "tr_title": "Os jogadores exigem. Intel® Core™ i9 fornece.",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;9ª&nbsp;geração",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>núcleos</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Até<br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>de cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Desbloqueado",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ENCONTRE O PROCESSADOR INTEL® CORE™ INDICADO PARA A SUA FORMA DE JOGAR.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Processador Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Jogos AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogos competitivos",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Jogue, faça streaming e grave sem cedências",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Melhor processador Intel® Core™ de 9ª geração para jogos",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 6<br/>Threads: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;4,9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Velocidade do relógio<sup>2</sup>: até&nbsp;5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Núcleos: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Projetados para os jogos da próxima geração",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12ª&nbsp;geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;12ª&nbsp;GERAÇÃO"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12ª&nbsp;geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;12ª&nbsp;GERAÇÃO"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;12ª&nbsp;geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;12ª&nbsp;GERAÇÃO"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] em Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] em Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Batalhar em todas as frentes",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12ª&nbsp;geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;12ª&nbsp;GERAÇÃO"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;12ª&nbsp;geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;12ª&nbsp;GERAÇÃO"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] em Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] em Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho fiável quando mais necessita",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;12ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i3 DE&nbsp;12ª&nbsp;GERAÇÃO",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Aumento de desempenho onde mais precisa",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;12ª&nbsp;GERAÇÃO",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Aumento de desempenho onde mais precisa",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;12ª&nbsp;GERAÇÃO",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho superior onde mais precisa",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;12ª&nbsp;GERAÇÃO",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho superior onde mais precisa",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;12ª&nbsp;GERAÇÃO",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Com os portáteis Intel® Evo™ obtém desempenho, inteligência integrada para conversas de vídeo fiáveis, Internet mais rápida, maior autonomia da bateria e muito mais.",
                "tr_title": "Aumento de desempenho onde&nbsp;mais&nbsp;precisa.",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;12ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Com os portáteis Intel® Evo™ obtém desempenho, inteligência integrada para conversas de vídeo fiáveis, Internet mais rápida, maior autonomia da bateria e muito mais.",
                "tr_title": "Desempenho superior onde mais precisa",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;12ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Com os portáteis Intel® Evo™ obtém desempenho, inteligência integrada para conversas de vídeo fiáveis, Internet mais rápida, maior autonomia da bateria e muito mais.",
                "tr_title": "Desempenho extremo num portátil premium",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;12ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;12ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Com tecnologia Intel® Turbo Boost 2.0 até [max_turbo_frequency] GHz",
                "tr_title": "Especial para empresas",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;12ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir o ruído e desfocar o seu plano de fundo",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Personalizar o desempenho para dar prioridade ao que mais faz",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Desfrutar de chamadas web estáveis e de alta qualidade",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Obter segurança por hardware pronta a usar",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Disponibilizar funções de gestão remota de dispositivos",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Otimizar automaticamente o desempenho da rede Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Utilizar aplicações dependentes de dados sem qualquer esforço",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "A maior produtividade com um desempenho melhorado",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Com tecnologia Intel® Turbo Boost 2.0 até [max_turbo_frequency] GHz",
                "tr_title": "Dedique-se à sua empresa sem preocupações.",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;12ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir o ruído e desfocar o seu plano de fundo",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Personalizar o desempenho para dar prioridade ao que mais faz",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Desfrutar de chamadas web estáveis e de alta qualidade",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Obter segurança por hardware pronta a usar",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Disponibilizar funções de gestão remota de dispositivos",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Otimizar automaticamente o desempenho da rede Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Utilizar aplicações dependentes de dados sem qualquer esforço",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "A maior produtividade com um desempenho melhorado",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Com tecnologia Intel® Turbo Boost 2.0 até [max_turbo_frequency] GHz",
                "tr_title": "Acelere o seu negócio",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;12ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;12ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir o ruído e desfocar o seu plano de fundo",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Personalizar o desempenho para dar prioridade ao que mais faz",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Desfrutar de chamadas web estáveis e de alta qualidade",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Obter segurança por hardware pronta a usar",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Disponibilizar funções de gestão remota de dispositivos",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Otimizar automaticamente o desempenho da rede Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Utilizar aplicações dependentes de dados sem qualquer esforço",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "A maior produtividade com um desempenho melhorado",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho fiável dedicado para fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 de&nbsp;13ª&nbsp;geração",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho real dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;13ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;13ª&nbsp;GERAÇÃO",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho real dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;13ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;13ª&nbsp;GERAÇÃO",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho potente dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;13ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;13ª&nbsp;GERAÇÃO",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho potente dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;13ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;13ª&nbsp;GERAÇÃO",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Mais do que desempenho",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;13ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;13ª&nbsp;GERAÇÃO",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] em Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] em Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ligue-se sem problemas ao seu telefone atual<sup>24</sup>, selecione o melhor Wi-Fi de forma inteligente e carregue rapidamente uma bateria que tem uma grande autonomia.",
                "tr_title": "Vá além do desempenho e faça mais em qualquer lugar",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;13ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;13ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ligue-se sem problemas ao seu telefone atual<sup>24</sup>, selecione o melhor Wi-Fi de forma inteligente e carregue rapidamente uma bateria que tem uma grande autonomia.",
                "tr_title": "Vá além do desempenho e deixe que o seu PC faça ainda mais",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;13ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;13ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ligue-se sem problemas ao seu telefone atual<sup>24</sup>, selecione o melhor Wi-Fi de forma inteligente e carregue rapidamente uma bateria que tem uma grande autonomia.",
                "tr_title": "Vá além do desempenho para fazer as tarefas mais&nbsp;exigentes, mais&nbsp;rapidamente",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;13ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;13ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Mais do que desempenho",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;13ª&nbsp;geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;13ª&nbsp;GERAÇÃO",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;13ª&nbsp;geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;13ª&nbsp;GERAÇÃO",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;13ª&nbsp;geração",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;13ª&nbsp;GERAÇÃO",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] em Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] em Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Mais do que desempenho",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processador Intel® Core™ i5",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processador Intel® Core™ i7",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processador Intel® Core™ i9",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] em Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] em Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho fiável dedicado para fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Processador Intel® Core™ i3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho real dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Processador Intel® Core™ i5",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho potente dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Processador Intel® Core™ i7",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Mais do que desempenho",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Processador Intel® Core™ i5",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Processador Intel® Core™ i7",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Processador Intel® Core™ i9",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] em Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] em Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho fiável dedicado para fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho real dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;5",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ 5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Desempenho potente dedicado a fazer mais",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;7",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL®&nbsp;CORE™&nbsp;7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Processador Intel®&nbsp;Core™&nbsp;7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processador",
                "tr_title": "Jogos sem cedências",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™ Ultra&nbsp;5",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ ULTRA 5",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™ Ultra&nbsp;7",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ ULTRA 7",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Processador Intel®&nbsp;Core™ Ultra&nbsp;9",
                    "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ ULTRA 9",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>núcleos</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core] LPE-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] em Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] em Efficient-cores",
                    "tr_badgeSubTitle1": "[l_thread] em LPE-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ Ultra 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ Ultra 7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Processador Intel® Core™ Ultra 9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Jogos sem cedências",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Jogos sem cedências",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Jogos sem cedências",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Jogos sem cedências",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Jogos sem cedências",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Jogos sem cedências",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "As pequenas empresas merecem soluções para as grandes empresas.",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 de&nbsp;13ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i5 DE&nbsp;13ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir o ruído e desfocar o seu plano de fundo",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Personalizar o desempenho para dar prioridade ao que mais faz",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Desfrutar de chamadas web estáveis e de alta qualidade",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Proteções de segurança por hardware prontas a usar",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Ajudar a proteger dispositivos e dados contra ataques de adulteração física",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "As pequenas empresas merecem soluções para as grandes empresas.",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i7 de&nbsp;13ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i7 DE&nbsp;13ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir o ruído e desfocar o seu plano de fundo",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Personalizar o desempenho para dar prioridade ao que mais faz",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Desfrutar de chamadas web estáveis e de alta qualidade",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Proteções de segurança por hardware prontas a usar",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Ajudar a proteger dispositivos e dados contra ataques de adulteração física",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "As pequenas empresas merecem soluções para as grandes empresas.",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i9 de&nbsp;13ª&nbsp;geração",
                "tr_gen_core_title_uppercase": "PROCESSADOR INTEL® CORE™ i9 DE&nbsp;13ª&nbsp;GERAÇÃO",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "COMO ESTE PROCESSADOR SE COMPARA?",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Dispositivo atual",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Suprimir o ruído e desfocar o seu plano de fundo",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Personalizar o desempenho para dar prioridade ao que mais faz",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Desfrutar de chamadas web estáveis e de alta qualidade",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Proteções de segurança por hardware prontas a usar",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Ajudar a proteger dispositivos e dados contra ataques de adulteração física",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Descubra novas experiências de&nbsp;IA para&nbsp;empresas",
                "tr_title": "Prepare-se para o futuro com os processadores Intel®&nbsp;Core™&nbsp;Ultra&nbsp;[XX] e&nbsp;Intel&nbsp;vPro®",
                "tr_gen_core_title": "Processador Intel® Core™ Ultra [XX]",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "DÊ UM IMPULSO À SUA EMPRESA COM OS PC COM IA DA INTEL",
                    "tr_badgeSubTitle": "Computadores poderosos e que criam uma ótima experiência são essenciais para a produtividade das empresas. A tecnologia Intel vPro® aumenta a eficácia e o desempenho dos funcionários em várias cargas de trabalho e aplicações graças a uma nova arquitetura que oferece um desempenho superior com eficiência energética para diferentes cargas de trabalho empresariais complexas através do envio da tarefa certa para o motor certo no momento certo.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "FUNCIONALIDADES DE SEGURANÇA COM VÁRIAS CAMADAS E PRONTAS A USAR",
                    "tr_badgeSubTitle": "É necessária uma abordagem de segurança abrangente para combater ameaças novas e emergentes. A&nbsp;tecnologia Intel&nbsp;vPro® oferece proteção ao nível do hardware e em toda a estrutura para que a sua empresa fique mais protegida. Estas medidas de segurança exclusivas e prontas a usar, que incluem funcionalidades de deteção de ameaças com tecnologia de IA, ajudam a proteger os seus utilizadores, os seus dados e sua&nbsp;empresa.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "FERRAMENTAS INTEGRADAS PARA MANTER OS UTILIZADORES PRODUTIVOS EM PRATICAMENTE QUALQUER LUGAR",
                    "tr_badgeSubTitle": "Hoje, o escritório pode estar em qualquer lugar, e os PC também. A tecnologia Intel vPro® oferece funcionalidades de gestão modernas baseadas em hardware para ajudar as empresas a aliviar a carga da gestão de frotas de PC, simplificar o suporte e melhorar as experiências dos utilizadores finais, ao mesmo tempo que permitem práticas de computação sustentáveis.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mais do que um PC incrível, um PC com IA",
                "tr_subtitle1": "EQUIPADO COM PROCESSADORES INTEL®&nbsp;CORE™ ULTRA (SÉRIE&nbsp;2)",
                "tr_subtitle2": "Já é possível no seu PC Intel com IA",
                "tr_subtitle": "O processador móvel mais eficiente, seguro e de maior desempenho da Intel<sup>39</sup>",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™ Ultra&nbsp;5 (Série&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Mais criatividade</span> com ferramentas de IA para criar mais rápido"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mais produtividade</span> com assistência de IA que economiza tempo"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mais segurança</span> com proteção de dados integrada"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mais do que um PC incrível, um PC com IA",
                "tr_subtitle1": "EQUIPADO COM PROCESSADORES INTEL®&nbsp;CORE™ ULTRA (SÉRIE&nbsp;2)",
                "tr_subtitle2": "Já é possível no seu PC Intel com IA",
                "tr_subtitle": "O processador móvel mais eficiente, seguro e de maior desempenho da Intel<sup>39</sup>",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™ Ultra&nbsp;7 (Série&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Mais criatividade</span> com ferramentas de IA para criar mais rápido"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mais produtividade</span> com assistência de IA que economiza tempo"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Mais segurança</span> com proteção de dados integrada"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mais do que um PC incrível, um PC com IA",
                "tr_subtitle1": "EQUIPADO COM PROCESSADORES INTEL®&nbsp;CORE™ ULTRA (SÉRIE&nbsp;2)",
                "tr_subtitle2": "Já é possível no seu PC Intel com IA",
                "tr_subtitle": "O processador móvel mais eficiente, seguro e de maior desempenho da Intel<sup>39</sup>",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™ Ultra&nbsp;5 (Série&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Mais do que um PC incrível, um PC com IA",
                "tr_subtitle1": "EQUIPADO COM PROCESSADORES INTEL®&nbsp;CORE™ ULTRA (SÉRIE&nbsp;2)",
                "tr_subtitle2": "Já é possível no seu PC Intel com IA",
                "tr_subtitle": "O processador móvel mais eficiente, seguro e de maior desempenho da Intel<sup>39</sup>",
                "tr_gen_core_title": "Processador Intel®&nbsp;Core™ Ultra&nbsp;7 (Série&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_tileTitle1": "Gráficos dedicados",
                "tr_title": "O QUE É UMA UNIDADE DE PROCESSAMENTO GRÁFICO?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "A unidade de processamento gráfico (GPU) cria as imagens que vê no ecrã.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU integrada",
                    "tr_badgeSubTitle": "Uma GPU integrada utiliza a mesma RAM que o resto do computador, tornando-a mais eficiente em termos energéticos.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "GPU dedicada",
                    "tr_badgeSubTitle": "Esta é a classe de GPU mais potente, uma vez que dispõe de memória RAM própria e de outros recursos dedicados para oferecer gráficos excecionais.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "GPU híbrida",
                    "tr_badgeSubTitle": "Esta é a classe de GPU mais potente, uma vez que dispõe de memória RAM própria e de outros recursos dedicados para oferecer gráficos excecionais.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Edição simplificada de fotos e vídeos",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Veja conteúdo de vídeo em 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Desfrute de jogos populares com detalhes impressionantes",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Duas GPUs",
                    "tr_badgeSubTitle": "Alguns sistemas, como os que dispõem de placas gráficas Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>, podem tirar partido da GPU integrada no processador e da GPU dedicada para distribuir a carga de trabalho gráfico.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "GPU integrada",
                    "tr_badgeSubTitle": "Uma GPU integrada encontra-se no mesmo chip do processador principal ou CPU. Este tipo de GPU utiliza a mesma memória RAM e outros recursos utilizados pelo resto do computador, o que o torna energeticamente mais eficiente e possibilita a construção de um dispositivo mais leve e fino.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "GPU dedicada",
                    "tr_badgeSubTitle": "Esta é a classe de GPU mais potente, uma vez que dispõe de memória RAM própria e de outros recursos dedicados para oferecer gráficos excecionais.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Padrão",
                    "tr_badgeSubTitle": "Até 4&nbsp;GB de VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Competição",
                    "tr_badgeSubTitle": "Até 8&nbsp;GB de VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "Mais de 8&nbsp;GB de VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Potência de gráficos intensa",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "O processamento gráfico mais potente exige uma placa gráfica focada exclusivamente na criação de imagens bonitas e nítidas e animações fluidas.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Gráficos ricos e vibrantes integrados",
                "tr_pretitle": "Gráficos Intel® Iris® Plus",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "A gráfica integrada Intel® Iris® Plus permite-lhe mergulhar em vídeo 4K HDR e em jogos Full HD, sem necessidade de uma placa gráfica adicional.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Imagens impressionantes, desempenho sem esforço",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Os produtos Gráficos Intel® oferecem um desempenho gráfico integrado transformador, para suportar tarefas de design fluidas para os criadores e experiências de jogo ricas.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Gráficos Intel® Iris® Pro",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Gráficos UHD Intel® com arquitetura X<sup class='small_text_sup_xe'>e</sup>",
                "tr_pretitle": "Experiências visuais envolventes",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Experimente a edição simplificada de fotos e vídeos, veja conteúdo de vídeo premium em 4K ou desfrute de jogos populares com detalhes impressionantes.",
                "tr_photo_text": "Edição simplificada de fotos e vídeos",
                "tr_video_text": "Veja conteúdo de vídeo em 4K",
                "tr_game_text": "Desfrute de jogos populares com detalhes impressionantes",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MUITO MAIS DO QUE IMAGENS BONITAS",
                "tr_subtitle": "Num trabalho conjunto com o processador (ou a CPU), a placa gráfica (ou a GPU) ajuda a determinar a forma como os jogos são executados e a respetiva aparência. A VRAM, ou RAM de vídeo, dedicada exclusivamente a criar gráficos, é uma forma rápida de avaliar a GPU.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB</span><br/><span class='subtitle-value'>DE VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PARA GRÁFICOS DE JOGOS INTENSOS",
                "tr_subtitle": "O processamento gráfico mais poderoso requer uma placa gráfica independente, focada exclusivamente na criação de animações fluidas e imagens bonitas e nítidas.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB</span><br/><span class='subtitle-value'>DE VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "PARA GRÁFICOS DE JOGOS INTENSOS",
                "tr_subtitle": "O processamento gráfico mais poderoso requer uma placa gráfica independente, focada exclusivamente na criação de animações fluidas e imagens bonitas e nítidas.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB</span><br/><span class='subtitle-value'>DE VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "MUITO MAIS DO QUE IMAGENS BONITAS",
                "tr_subtitle": "Num trabalho conjunto com o processador (ou a CPU), a placa gráfica (ou a GPU) ajuda a determinar a forma como os jogos são executados e a respetiva aparência. A VRAM, ou RAM de vídeo, dedicada exclusivamente a criar gráficos, é uma forma rápida de avaliar a GPU.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB</span><br/><span class='subtitle-value'>DE VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Potência de gráficos intensa",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "O processamento gráfico mais potente exige uma placa gráfica focada exclusivamente na criação de imagens bonitas e nítidas e animações fluidas.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "PARA GRÁFICOS DE JOGOS INTENSOS",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "O processamento gráfico mais potente exige uma placa gráfica focada exclusivamente na criação de imagens bonitas e nítidas e animações fluidas.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB</span><br/><span class='subtitle-value'>DE VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "O processamento gráfico mais poderoso requer uma placa gráfica independente, focada exclusivamente na criação de animações fluidas e imagens bonitas e nítidas.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Capacidade gráfica para responder facilmente às necessidades mínimas dos seus jogos.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Para jogadores avançados que exigem jogos ao mais alto nível",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Para jogadores avançados que também usam o PC para criação intensiva de conteúdo",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Vídeos vibrantes e jogos envolventes",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Tire o máximo partido do ecrã de alta resolução com os gráficos HD Intel® e desfrute de uma rica experiência multimédia e do processamento de um sistema gráfico integrado.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Tecnologia para vídeos e imagens vibrantes",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Obtenha mais detalhes e cores brilhantes no streaming de vídeo e fotografias.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Criação e jogos incríveis",
                "tr_pretitle": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Desde entretenimento envolvente e criação de conteúdo avançada até jogos convencionais, os gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> conferem um desempenho gráfico revolucionário aos PC finos e leves.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_pretitle": "PARA GRÁFICOS DE JOGOS INTENSOS",
                "tr_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "O processamento gráfico mais poderoso requer uma placa gráfica independente, focada exclusivamente na criação de animações fluidas e imagens bonitas e nítidas.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB</span><br/><span class='subtitle-value'>DE VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Dê asas à sua criatividade",
                "tr_pretitle": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dê asas aos seus projetos criativos e mergulhe numa grande variedade de jogos com a potência combinada da gráfica integrada no processador e do primeiro motor gráfico dedicado da Intel®.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "tr_title": "Gráficos Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "tr_pretitle": "PARA GRÁFICOS DE JOGOS INTENSOS",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "O processamento gráfico mais poderoso requer uma placa gráfica independente, focada exclusivamente na criação de animações fluidas e imagens bonitas e nítidas.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB</span><br/><span class='subtitle-value'>DE VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Gráficos",
                "tr_title": "Bonitos gráficos integrados",
                "tr_subtitle": "Desfrute dos seus jogos, filmes e vídeos da Web com níveis de detalhe e nitidez fantásticos.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Gráficos",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Impulsione os seus fluxos de trabalho"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Melhore os seus jogos"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Melhore os seus jogos"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Jogue a outro nível"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "DÊ ASAS À SUA CRIATIVIDADE",
                    "tr_specText": "Acelere a sua produtividade onde quer que esteja com capacidades de média mais abrangentes, como a <strong>codificação por hardware AV1, pioneira no setor</strong>, e a criação de conteúdo acelerada por IA.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "DÊ ASAS À SUA CRIATIVIDADE",
                    "tr_specText": "Acelere a sua produtividade com as capacidades de média mais abrangentes, como a <strong>codificação por hardware AV1, pioneira no setor</strong>, e a criação de conteúdo acelerada por IA.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "DÊ ASAS À SUA CRIATIVIDADE",
                    "tr_specText": "Desfrute de criação de conteúdo inovadora e acelerada por IA com <strong>a codificação por hardware AV1</strong> e o avançado <strong>Motor multimédia X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "DÊ ASAS À SUA CRIATIVIDADE",
                    "tr_specText": "Desfrute de criação de conteúdo inovadora e acelerada por IA com <strong>a codificação por hardware AV1</strong> e o avançado <strong>Motor multimédia X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "DÊ ASAS À SUA CRIATIVIDADE",
                    "tr_specText": "Desfrute de criação de conteúdo inovadora e acelerada por IA com <strong>a codificação por hardware AV1</strong> e o avançado <strong>Motor multimédia X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "PORTABILIDADE E DESEMPENHO JUNTOS",
                    "tr_specText": "Jogue os jogos mais recentes e obtenha desempenho e fidelidade turbinados com recursos de jogos modernos. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tecnologia de upscaling Intel® melhorada por IA para um melhor desempenho e jogos mais estáveis. <br/><br/>Suporte da tecnologia <strong>DirectX* 12 Ultimate:  </strong>jogue com outro nível de realismo graças às tecnologias de gráficos como o ray tracing e o sombreamento de taxa variável."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "ASSUMA O CONTROLO DOS SEUS JOGOS",
                    "tr_specText": "O <strong>Centro de controlo Intel® Arc™</strong> permite-lhe ajustar a sua experiência com um esquema intuitivo e moderno. Atualizações consistentes dos controladores, jogos otimizados e muito mais."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "ASSUMA O CONTROLO DOS SEUS JOGOS",
                    "tr_specText": "O <strong>Centro de controlo Intel® Arc™</strong> permite-lhe ajustar a sua experiência com um esquema intuitivo e moderno. Atualizações consistentes dos controladores, jogos otimizados e muito mais."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "ASSUMA O CONTROLO DOS SEUS JOGOS",
                    "tr_specText": "Atualizações consistentes dos controladores e muitos dos seus jogos favoritos otimizados com o software <strong>Centro de controlo Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "MAXIMIZE A SUA PLATAFORMA INTEL®",
                    "tr_specText": "Emparelhe com processadores Intel® Core™ compatíveis para tirar o máximo partido da tecnologia <strong>Intel® Deep Link</strong> e melhorar a criação, os jogos e a experiência de streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "POTENCIE O SEU SISTEMA INTEL®.",
                    "tr_specText": "Emparelhe com processadores Intel® Core™ compatíveis para tirar o máximo partido da tecnologia <strong>Intel® Deep Link</strong> e melhorar a criação, os jogos e a experiência de streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "MAXIMIZE A SUA PLATAFORMA INTEL®",
                    "tr_specText": "Emparelhe com processadores Intel® Core™ compatíveis para tirar o máximo partido da tecnologia <strong>Intel® Deep Link</strong> e melhorar a criação, os jogos e a experiência de streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "MAXIMIZE A SUA PLATAFORMA INTEL®",
                    "tr_specText": "Emparelhe com processadores Intel® Core™ compatíveis para tirar o máximo partido da tecnologia <strong>Intel® Deep Link</strong> e melhorar a criação, os jogos e a experiência de streaming."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "POTENCIE O SEU SISTEMA INTEL®.",
                    "tr_specText": "Emparelhe com processadores Intel® Core™ compatíveis para tirar o máximo partido da tecnologia <strong>Intel® Deep Link</strong> e melhorar a criação, os jogos e a experiência de streaming."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "SEJA O CENTRO DAS ATENÇÕES.",
                    "tr_specText": "Transmissões perfeitas com tecnologias de streaming avançadas ativadas pelo software <strong>Centro de controlo Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "SEJA O CENTRO DAS ATENÇÕES.",
                    "tr_specText": "Transmissões perfeitas com tecnologias de streaming avançadas ativadas pelo software <strong>Centro de controlo Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "JOGOS IMERSIVOS E DE ALTO DESEMPENHO.",
                    "tr_specText": "Coloque o seu melhor desempenho em primeiro lugar com os jogos AAA mais recentes a altas resoluções e taxas de atualização. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tecnologia de upscaling Intel® melhorada por IA para um melhor desempenho e jogos mais estáveis. <br/><br/>Suporte da tecnologia <strong>DirectX* 12 Ultimate:  </strong>jogue com outro nível de realismo graças às tecnologias de gráficos como o ray tracing e o sombreamento de taxa variável."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "JOGOS IMERSIVOS E DE ALTO DESEMPENHO.",
                    "tr_specText": "Coloque o seu melhor desempenho em primeiro lugar com os jogos AAA mais recentes a altas resoluções e taxas de atualização. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tecnologia de upscaling Intel® melhorada por IA para um melhor desempenho e jogos mais estáveis. <br/><br/>Suporte da tecnologia <strong>DirectX* 12 Ultimate:  </strong>jogue com outro nível de realismo graças às tecnologias de gráficos como o ray tracing e o sombreamento de taxa variável."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "DÊ UM IMPULSO AOS SEUS JOGOS",
                    "tr_specText": "Viva uma nova experiência de jogo com a tecnologia <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>a tecnologia de upscaling Intel® melhorada por IA para um melhor desempenho e jogos mais estáveis. <br/><br/>Suporte da tecnologia <strong>DirectX* 12 Ultimate:  </strong>jogue com outro nível de realismo graças às tecnologias de gráficos como o ray tracing e o sombreamento de taxa variável."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "EXPERIÊNCIA AVANÇADA EM JOGOS DINÂMICOS.",
                    "tr_specText": "Desfrute de estabilidade e uma alta taxa de atualização em muitos jogos AAA populares: dos intensos FPS de e-sports aos RTS entusiasmantes. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tecnologia de upscaling Intel® melhorada por IA para um melhor desempenho e jogos mais estáveis. <br/><br/>Suporte da tecnologia <strong>DirectX* 12 Ultimate:  </strong>jogue com outro nível de realismo graças às tecnologias de gráficos como o ray tracing e o sombreamento de taxa variável."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "JOGOS IMERSIVOS E DE ALTO DESEMPENHO.",
                    "tr_specText": "Coloque o seu melhor desempenho em primeiro lugar com os jogos AAA mais recentes a altas resoluções e taxas de atualização. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>tecnologia de upscaling Intel® melhorada por IA para um melhor desempenho e jogos mais estáveis. <br/><br/>Suporte da tecnologia <strong>DirectX* 12 Ultimate:  </strong>jogue com outro nível de realismo graças às tecnologias de gráficos como o ray tracing e o sombreamento de taxa variável."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "Um universo gráfico com espaço para todos"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "ACELERE OS SEUS FOTOGRAMAS, ACELERE OS SEUS JOGOS",
                    "tr_specText": "A tecnologia Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling (X<sup class='small_text_sup_xe'>e</sup>SS) leva os seus jogos para o próximo nível com o upscaling melhorado por IA, para um desempenho máximo com uma alta fidelidade de imagem. O X<sup class='small_text_sup_xe'>e</sup>SS é otimizado para os produtos de gráficos Intel® Arc™, para que possa tirar o máximo partido da aceleração de hardware com Matrix Extensions da Intel® X<sup class='small_text_sup_xe'>e</sup> (Intel® XMX)."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "AS MELHORES IMAGENS ESTÃO AQUI",
                    "tr_specText": "Com suporte total da tecnologia DirectX* 12 Ultimate em todos os gráficos Intel® Arc™, vai desfrutar de uma jogabilidade impressionante e imersiva graças às tecnologias gráficas mais recentes, como o ray tracing, sombreamento de taxa variável, sombreamento de malha e feedback do sampler, fundamentais para os jogos de última geração."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "CRIAÇÃO DE FOTOGRAFIA, VÍDEO E MULTIMÉDIA SEM INTERRUPÇÕES",
                    "tr_specText": "Liberte a sua imaginação e cative o seu público com a criação de conteúdos digitais ricos utilizando um motor de multimédia hiperavançado, melhorado pela IA e acelerado com a tecnologia Intel®. Crie conteúdos fantásticos, com gráficos que suportam todos os formatos de multimédia atuais, ao mesmo tempo que se mantém sempre a par com as capacidades de codificação de vídeo AV1 mais avançadas."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "DESFRUTE DA CRIAÇÃO E DOS JOGOS COM IA DE PONTA",
                    "tr_specText": "Os gráficos Intel® Arc™ desbloqueiam capacidades de IA poderosas, desde a criação avançada até aos jogos imersivos. Tire o máximo partido do software de criação avançado de hoje, incluindo a transformação de texto em imagem e a edição de vídeo acelerada por IA. Além disso, desfrute de uma jogabilidade de alta resolução através do upscaling X<sup class='small_text_sup_xe'>e</sup>SS acelerado por IA."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Gráficos",
                    "tr_specText": "As placas gráficas profissionais começam aqui"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "EXCELENTES PARA CRIATIVOS, DESIGNERS E ENGENHEIROS, ONDE QUER QUE ESTEJAM",
                    "tr_specText": "Disponíveis em processadores Intel® Core™ Ultra série H, os gráficos Intel® Arc™ Pro integrados desbloqueiam o dobro das melhorias de desempenho gráfico com as certificações de fornecedor independente de software (ISV), para uma fiabilidade adicional, e oferecem uma aceleração de hardware de IA e ray tracing eficientes para a criação de conteúdos fantásticos, seja onde for.<br/><br/><span class='expandable_heading'>Certificações de software<br/></span><br/>A Intel tem trabalhado lado a lado com centenas de empresas de software ao longo dos anos, e esta experiência inigualável está patente nas nossas placas gráficas Intel® Arc™ Pro série A. As certificações são tão importantes para nós como para si."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "IA AO SEU ALCANCE",
                    "tr_specText": "Esta gama de GPU inclui uma vasta seleção de excelentes tecnologias, incluindo suporte para ferramentas de IA compatíveis com aceleração de IA dedicada integrada, onde for necessário."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING SEM O CUSTO PREMIUM",
                    "tr_specText": "As placas gráficas precisam de fazer mais do que simplesmente “empurrar píxeis” para o ecrã: também precisam de acelerar a criação de imagens bonitas. A tecnologia de hardware de ray tracing integrada nas placas gráficas Intel® Arc™ Pro série A permite que o software compatível comece a explorar estes novos fluxos de trabalho profissionais. Normalmente, o ray tracing é visto como disponível apenas em placas gráficas caras, mas a gama de placas gráficas profissionais mais recente da Intel quebra esse mito."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "CRIADORES CAPACITADOS COM ECRÃS IMERSIVOS",
                    "tr_specText": "Com suporte total da tecnologia DirectX* 12 Ultimate em todos os gráficos Intel® Arc™, vai desfrutar de uma jogabilidade impressionante e imersiva graças às tecnologias gráficas mais recentes, como o ray tracing, sombreamento de taxa variável, sombreamento de malha e feedback do sampler, fundamentais para os jogos de última geração."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "IMAGENS OTIMIZADAS DE FORMA DINÂMICA",
                    "tr_specText": "Com a descodificação de hardware dedicada nas GPU Pro, a Dolby Vision* ajuda a adicionar uma maior profundidade, um contraste mais nítido e mais cores aos ecrãs compatíveis. A Dolby Vision* desbloqueia todo o potencial da tecnologia HDR, otimizando dinamicamente a qualidade da imagem com base no seu serviço, dispositivo de ecrã e plataforma, para gerar sempre visuais impressionantes."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "SOFTWARE VERIFICADO POR ESPECIALISTAS",
                    "tr_specText": "A Intel tem trabalhado lado a lado com centenas de empresas de software ao longo dos anos, e esta experiência inigualável está patente na gama profissional de placas gráficas Intel® Arc™ Pro série A. As certificações e as qualificações do fluxo de trabalho de software são tão importantes para nós como para si."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING SEM O CUSTO PREMIUM",
                    "tr_specText": "As placas gráficas precisam de fazer mais do que simplesmente “empurrar píxeis” para o ecrã: também precisam de acelerar a criação de imagens bonitas. A tecnologia de hardware de ray tracing integrada nas placas gráficas Intel® Arc™ Pro série A permite que o software compatível comece a explorar estes novos fluxos de trabalho profissionais. Normalmente, o ray tracing é visto como disponível apenas em placas gráficas caras, mas a gama de placas gráficas profissionais mais recente da Intel quebra esse mito."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "VÁRIOS ECRÃS GRANDES COM UMA PEQUENA GPU MÓVEL",
                    "tr_specText": "Os gráficos Intel® Arc™ Pro para portáteis suportam até quatro ecrãs de grandes dimensões, para melhorar a eficiência do seu fluxo de trabalho. Junte o suporte de HDR (grande alcance dinâmico) e terá tudo de que precisa para criar um ambiente com vários ecrãs, em casa ou no escritório."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "POTENCIAL DE LARGURA DE BANDA DE MEMÓRIA TOTAL",
                    "tr_specText": "É importante considerar a largura de banda da placa gráfica numa estação de trabalho. Se não for a correta, pode atrasar os fluxos de trabalho profissionais, e é por isso que nos certificámos de que a GPU Intel® Arc™ Pro série A tem suporte para PCIe* 4.0 moderno, sem penalizar a compatibilidade com versões mais antigas. A memória gráfica é ainda mais melhorada por uma velocidade de largura de banda elevada, permitindo ao seu software aceder aos dados do projeto ainda mais depressa."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "DÊ VIDA A CADA FOTOGRAMA",
                    "tr_specText": "<span class='expandable_heading'>Potência da IA. Qualidade da Intel.<br/></span><br/>A tecnologia Intel® X<sup class='small_text_sup_xe'>e</sup>SS é uma tecnologia de upscaling melhorada por IA que impulsiona o desempenho sem sacrificar a beleza de cada fotograma, para que possa desfrutar de uma experiência de jogo ideal."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "EM TEMPO REAL.<br/> COM IMERSÃO REAL.<br/> O MELHOR REALISMO.",
                    "tr_specText": "Os mundos mais realistas estão à sua espera. Com ray tracing integrado, poderá desfrutar de tudo, desde sombras e reflexos reais a uma iluminação magistral. Por outras palavras, é uma experiência visual de jogo que não vai querer perder."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "A MELHOR JOGABILIDADE, PARTILHADA DA MELHOR FORMA",
                    "tr_specText": "Dê ao seu público a jogabilidade perfeita que ele merece ver. Com as capacidades de codificação AV1 aceleradas por hardware dos gráficos Intel® Arc™, cada jogada será cativante. Maior qualidade visual com a mesma taxa de bits equivale a melhor streaming com poupança de largura de banda."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Damos as boas-vindas a todos os criadores",
                    "tr_specText": "Descubra experiências de IA novas e melhoradas na ponta dos seus dedos. Com aceleradores de IA avançados integrados nos gráficos Intel® Arc™ série A, vai passar mais tempo a criar e menos tempo à espera em várias aplicações.<br/><br/>Leve a sua criação de vídeo para o próximo nível e esteja na vanguarda com vídeo AV1 de alta qualidade e armazenamento eficiente, com capacidades avançadas de hardware AV1 integradas nos gráficos Intel® Arc™."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Tudo o que precisa para realizar todas as tarefas",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Desempenho poderoso e um design fino, leve e resistente — pode ter tudo isto. Com uma excelente duração da bateria, ecrãs táteis deslumbrantes* e as mais recentes Unidades de Estado Sólido (SSDs), encontrará tudo o que procura num PC Windows 10.",
                "tr_noteSection": "*Dependente do hardware",
                "titleArray": [
                  {
                    "tr_title": "Faça mais com menos",
                    "tr_subtitle": "Os PCs modernos consomem menos energia, para que possa desfrutar de uma longa duração da bateria — em média mais de oito horas de reprodução de vídeo**.",
                    "tr_noteSection": "**A autonomia da bateria varia significativamente consoante as configurações, a utilização e outros fatores."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "TUDO O QUE PRECISA PARA REALIZAR TODAS AS TAREFAS",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Desempenho poderoso e um design fino, leve e resistente — pode ter tudo isto. Com uma excelente duração da bateria, ecrãs táteis deslumbrantes* e as mais recentes Unidades de Estado Sólido (SSDs), encontrará tudo o que procura num PC Windows 10.",
                "tr_noteSection": "*Dependente do hardware",
                "titleArray": [
                  {
                    "tr_title": "FAÇA MAIS COM MENOS",
                    "tr_subtitle": "Os PCs modernos consomem menos energia, para que possa desfrutar de uma longa duração da bateria — em média mais de oito horas de reprodução de vídeo**.",
                    "tr_noteSection": "**A autonomia da bateria varia significativamente consoante as configurações, a utilização e outros fatores."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Simplificado para melhor segurança e desempenho",
                "tr_pretitle": "Windows* 10 no modo S",
                "tr_subtitle": "Ao executar o Windows* 10 no modo S, desfruta de uma camada de proteção extra que lhe permite trabalhar, jogar e explorar sabendo que o seu dispositivo está seguro."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "SIMPLIFICADO PARA MELHOR SEGURANÇA E DESEMPENHO",
                "tr_title": "Windows* 10 no modo S",
                "tr_subtitle": "Ao executar o Windows* 10 no modo S, desfruta de uma camada de proteção extra que lhe permite trabalhar, jogar e explorar sabendo que o seu dispositivo está seguro."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Faça coisas extraordinárias",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Desempenho, preço e muito por onde escolher. Desde dispositivos 2 em 1 inovadores a portáteis finos e leves, ecrãs táteis e teclados destacáveis, qualquer que seja o seu orçamento ou estilo, há um PC Windows* ideal para si."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "FAÇA COISAS EXTRAORDINÁRIAS",
                "tr_title": "Windows*",
                "tr_subtitle": "Desempenho, preço e muito por onde escolher. Desde dispositivos 2 em 1 inovadores a portáteis finos e leves, ecrãs táteis e teclados destacáveis, qualquer que seja o seu orçamento ou estilo, há um PC Windows* ideal para si."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Sistema operativo",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memória",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "DE QUANTA RAM PRECISA?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Quotidiano – 2&nbsp;GB ATÉ 8&nbsp;GB DE RAM",
                    "tr_badgeSubTitle": "Ideal para aqueles que utilizam o PC, principalmente, para processamento de texto e navegação Web e tendem a concentrar-se num programa de cada vez."
                  },
                  {
                    "tr_badgeTitle": "Potente – 8&nbsp;GB ATÉ 16&nbsp;GB DE RAM",
                    "tr_badgeSubTitle": "Com mais RAM, as aplicações são executadas mais rapidamente e poderá executar mais aplicações em simultâneo."
                  },
                  {
                    "tr_badgeTitle": "Avançado – MAIS DE 16 GB DE RAM",
                    "tr_badgeSubTitle": "Se estiver interessado em jogos, fotografia, edição de vídeo ou modelagem 3D, necessita de RAM adicional para trabalhar com estes programas intensivos."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB de RAM para executar várias tarefas do dia a dia em simultâneo",
                "tr_subtitle": "A memória permite o carregamento e funcionamento sem problemas de aplicações básicas, como processamento de texto e navegação Web.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB</span><br/><span class='subtitle-value'>DE RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB de RAM para executar várias tarefas exigentes em simultâneo",
                "tr_subtitle": "Se utiliza vários programas ao mesmo tempo, [memory_total]&nbsp;GB de memória irão permitir&#8209;lhe alternar com facilidade entre eles.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB</span><br/><span class='subtitle-value'>DE RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB de RAM para executar várias tarefas avançadas em simultâneo",
                "tr_subtitle": "Terá memória suficiente para executar vários programas pesados em simultâneo sem perder rapidez.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB</span><br/><span class='subtitle-value'>DE RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "Memória de [Value1]&nbsp;GB = Memória Intel® Optane™ de [Value2]&nbsp;GB + [Value3]&nbsp;GB de RAM",
                "tr_subtitle": "Esta combinação de soluções de memória proporciona um desempenho acelerado do disco rígido com a memória Intel® Optane™ e uma maior capacidade de resposta da memória RAM.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB</span><br/><span class='subtitle-value'>DE MEMÓRIA</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memória Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Menos espera. Mais do que realmente gosta.",
                "tr_subtitle": "Quando a memória Intel® Optane™ trabalha em conjunto com a memória do seu sistema, dá-lhe acesso mais rápido aos programas utilizados frequentemente, para que estes iniciem e sejam carregados no momento em que são necessários.",
                "tr_pretitle": "Memória Intel® Optane™ de [XX]&nbsp;GB",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "O QUE FAZ A MEMÓRIA PELO MEU PC?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Criação mais rápida de conteúdos",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Jogos com maior capacidade de resposta",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Melhor execução de várias tarefas em simultâneo",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Abertura de programas mais rápida",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memória Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Acelere os seus jogos</span>",
                "tr_pretitle": "Memória Intel® Optane™ de [XX]&nbsp;GB",
                "tr_pretitle1": "A memória Intel® Optane™ de [XX] GB acelera a sua jogabilidade",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Início de jogos até<br/><span class=\"optane-game\">98% mais rápido</span><br/>a partir da unidade de dados utilizando um processador [optane_legal_processor_name] com memória Intel®&nbsp;Optane™&#xfeff;<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Carregamento de níveis até<br/><span class=\"optane-game\">4,1x mais rápido</span><br/>a partir da unidade de dados utilizando um processador [optane_legal_processor_name] com memória Intel®&nbsp;Optane™&#xfeff;<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Memória Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Acelere os seus jogos</span>",
                "tr_pretitle": "Memória Intel® Optane™ de [XX]&nbsp;GB",
                "tr_pretitle1": "A memória Intel® Optane™ de [XX] GB acelera a sua jogabilidade",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Início de jogos até<br/><span class=\"optane-game\">98% mais rápido</span><br/>a partir da unidade de dados utilizando um processador [optane_legal_processor_name] com memória Intel®&nbsp;Optane™&#xfeff;<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Carregamento de níveis até<br/><span class=\"optane-game\">4,1x mais rápido</span><br/>a partir da unidade de dados utilizando um processador [optane_legal_processor_name] com memória Intel®&nbsp;Optane™&#xfeff;<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Memória de [Value1]&nbsp;GB = Memória Intel® Optane™ de [Value2]&nbsp;GB + [Value3]&nbsp;GB de RAM",
                "tr_subtitle": "Esta combinação de soluções de memória proporciona um desempenho acelerado do disco rígido com a memória Intel® Optane™ e uma maior capacidade de resposta da memória RAM.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB de RAM para executar várias tarefas em simultâneo",
                    "tr_subtitle": "Se utiliza vários programas ao mesmo tempo, [memory_total]&nbsp;GB de memória irão permitir&#8209;lhe alternar com facilidade entre eles."
                  },
                  {
                    "tr_title": "Para as tarefas diárias",
                    "tr_subtitle": "A memória permite o carregamento e funcionamento sem problemas de aplicações básicas, como processamento de texto e navegação Web."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB de RAM",
                "tr_pretitle": "MELHOR EXECUÇÃO DE VÁRIAS TAREFAS SIMULTANEAMENTE",
                "tr_subtitle": "A memória RAM dá o acesso a ficheiros de programas ao processador, o que permite à CPU aceder a esses ficheiros mais rapidamente e trabalhar em várias tarefas em simultâneo.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Memória padrão",
                    "tr_badgeSubTitle": "Até 8&nbsp;GB de RAM"
                  },
                  {
                    "tr_badgeTitle": "Memória avançada",
                    "tr_badgeSubTitle": "Mais de 8 GB e até 16 GB de RAM"
                  },
                  {
                    "tr_badgeTitle": "Memória de elite",
                    "tr_badgeSubTitle": "Mais de 16&nbsp;GB de RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "MEMÓRIA",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB para executar mais tarefas em simultâneo",
                "tr_pretitle": "MELHOR EXECUÇÃO DE VÁRIAS TAREFAS SIMULTANEAMENTE",
                "tr_subtitle": "A memória RAM dá o acesso a ficheiros de programas ao processador, o que permite à CPU aceder a esses ficheiros mais rapidamente e trabalhar em várias tarefas em simultâneo.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Memória padrão",
                    "tr_badgeSubTitle": "Até 8&nbsp;GB de RAM"
                  },
                  {
                    "tr_badgeTitle": "Memória avançada",
                    "tr_badgeSubTitle": "Mais de 8 GB e até 16 GB de RAM"
                  },
                  {
                    "tr_badgeTitle": "Memória de elite",
                    "tr_badgeSubTitle": "Mais de 16&nbsp;GB de RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "COMO ESTA MEMÓRIA SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Padrão",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>DE RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avançado",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>DE RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Elite",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>DE RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Memória",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "COMO ESTA MEMÓRIA SE COMPARA?",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "COMO ESTA MEMÓRIA SE COMPARA?",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Dispositivo atual",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Dia a dia",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>DE RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Potente",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>DE RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Avançado",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>DE RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Armazenamento",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "QUAL É A CAPACIDADE DO SEU PC?",
                "tr_subtitle": "Não tem de adivinhar. Vamos mostrar&#8209;lhe exatamente aquilo que pode armazenar neste PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Crie uma biblioteca de</span> <br/><span class=\"storage-standard\">[num_songs]&nbsp;músicas<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>com base numa média de 5&nbsp;MB por música",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Crie uma biblioteca de</span> <br/><span class=\"storage-standard\">[num_games]&nbsp;jogos<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>com base numa média de 40 GB por jogo",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Armazenamento para</span><br/><span class=\"storage-standard\">[num_videos]&nbsp;vídeos<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>com base numa média de 5&nbsp;GB por vídeo",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Tire e guarde</span> <br/><span class=\"storage-standard\">[num_photos]&nbsp;fotografias<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>com base numa média de 4&nbsp;MB por fotografia",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "HDD DE [capacity]&nbsp;GB"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "SSD de [capacity]&nbsp;GB"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "Memória Intel® Optane™ de [total_optane_memory] GB + SSD de [convertedSSD] GB"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARMAZE&shy;NAMENTO",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "Armazenamento SSD de [capacity] GB",
                "tr_preTitle1": "Armazenamento SSD de [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Este PC conta com um SSD com [capacity] GB de armazenamento. Isso significa que vai poder guardar um número impressionante de fotografias, vídeos e programas e aceder-lhes muito mais rapidamente do que com uma unidade de disco rígido.",
                    "tr_title": "Um lugar para tudo no seu PC"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Este PC conta com um SSD com [capacity] TB de armazenamento. Isso significa que vai poder guardar um número impressionante de fotografias, vídeos e programas e aceder-lhes muito mais rapidamente do que com uma unidade de disco rígido.",
                    "tr_title": "Este dispositivo tem um armazenamento impressionante"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARMAZE&shy;NAMENTO",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "Armazenamento HDD de [capacity] GB",
                "tr_preTitle1": "Armazenamento HDD de [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Os discos rígidos são uma boa opção para pessoas que necessitam de um grande volume de armazenamento diretamente no PC.",
                    "tr_title": "Um lugar para tudo no seu PC"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Os discos rígidos são uma boa opção para pessoas que necessitam de um grande volume de armazenamento diretamente no PC.",
                    "tr_title": "Este dispositivo tem um armazenamento impressionante"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]</span><br/><span class='subtitle-value'>DE ARMAZE&shy;NAMENTO</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARMAZE&shy;NAMENTO",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "QUAL É A CAPACIDADE DO SEU PC?",
                "tr_subtitle": "Não tem de adivinhar. Vamos mostrar&#8209;lhe exatamente aquilo que pode armazenar neste PC.",
                "titleArray": [
                  {
                    "tr_title": "HDD de [XX] GB"
                  },
                  {
                    "tr_title": "SSD de [XX] GB"
                  },
                  {
                    "tr_title": "SSD Intel® de [total_capacity] GB acelerado pela memória Intel® Optane™ de [optane_memory_size] GB"
                  },
                  {
                    "tr_title": "SSD Intel® de [total_capacity] TB acelerado pela memória Intel® Optane™ de [optane_memory_size] GB"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD] GB + HDD de [convertedHDD] GB"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD] TB + HDD de [convertedHDD] GB"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD] GB + HDD de [convertedHDD] TB"
                  },
                  {
                    "tr_title": "SSD de [convertedSSD] TB + HDD de [convertedHDD] TB"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARMAZE&shy;NAMENTO",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "Armazenamento SSD de [convertedSSD]&nbsp;GB + HDD&nbsp;de&nbsp;[convertedHDD]&nbsp;GB",
                "tr_preTitle1": "Armazenamento SSD de [convertedSSD]&nbsp;TB + HDD&nbsp;de&nbsp;[convertedHDD]&nbsp;GB",
                "tr_preTitle2": "Armazenamento SSD de [convertedSSD]&nbsp;GB + HDD&nbsp;de&nbsp;[convertedHDD]&nbsp;TB",
                "tr_preTitle3": "Armazenamento SSD de [convertedSSD]&nbsp;TB + HDD&nbsp;de&nbsp;[convertedHDD]&nbsp;TB",
                "titleArray": [
                  {
                    "tr_title": "Um lugar para tudo no seu PC",
                    "tr_title1": "Este dispositivo tem um armazenamento impressionante",
                    "tr_subtitle": "Este PC dispõe de um SSD com [convertedSSD] GB de armazenamento e uma unidade de disco rígido de [convertedHDD] GB. Isso significa que tem uma capacidade enorme, juntamente com um desempenho de armazenamento rápido.",
                    "tr_subtitle1": "Este PC dispõe de um SSD com [convertedSSD] TB de armazenamento e uma unidade de disco rígido de [convertedHDD] GB. Isso significa que tem uma capacidade enorme, juntamente com um desempenho de armazenamento rápido.",
                    "tr_subtitle2": "Este PC dispõe de um SSD com [convertedSSD] GB de armazenamento e uma unidade de disco rígido de [convertedHDD] TB. Isso significa que tem uma capacidade enorme, juntamente com um desempenho de armazenamento rápido.",
                    "tr_subtitle3": "Este PC dispõe de um SSD com [convertedSSD] TB de armazenamento e uma unidade de disco rígido de [convertedHDD] TB. Isso significa que tem uma capacidade enorme, juntamente com um desempenho de armazenamento rápido."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>DE ARMAZENA&shy;MENTO</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARMAZE&shy;NAMENTO",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Obtenha acesso acelerado a armazenamento de alta capacidade com memória Intel® Optane™ com SSD.",
                "tr_title": "Os ficheiros gigantes são bem&#8209;vindos",
                "tr_pretitle": "SSD Intel® de [total_capacity] GB acelerado pela memória Intel® Optane™ de [optane_memory_size] GB",
                "tr_pretitle1": "SSD Intel® de [total_capacity] TB acelerado pela memória Intel® Optane™ de [optane_memory_size] GB",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Esta revolucionária solução de armazenamento é a primeira a combinar a memória Intel® Optane™ e um SSD num único chip. Veja o que pode fazer por si:",
                    "tr_title": "O QUE É A MEMÓRIA INTEL® OPTANE™ COM SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Abra o que precisa depressa",
                    "tr_badgeSubTitle": "Ao pré-carregar ficheiros e programas utilizados frequentemente, pode passar mais tempo a criar e menos a esperar.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Armazenamento mais velocidade",
                    "tr_badgeSubTitle": "Terá o espaço necessário para os ficheiros multimédia com o desempenho rápido de um SSD.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Mais agilidade ao longo do tempo",
                    "tr_badgeSubTitle": "A memória Intel® Optane™ com SSD aprende com a sua forma de trabalhar, tornando o PC mais eficiente.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ARMAZE&shy;NAMENTO",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Tenha os seus ficheiros multimédia sempre à mão com muito espaço de armazenamento fiável.",
                "tr_title": "Espaço para todas as suas fotografias, vídeos e músicas"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Ecrã",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] milhões de píxeis para percorrer, aproximar e surpreender.",
                "tr_subtitle": "Interaja com o seu PC tal como num smartphone, com controlos intuitivos e detalhes mais nítidos e vívidos.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>[screenResolutionType]<br/></span><span class='value'>DE [screenDiagonal]”</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolução: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Píxeis: [screenResolution] milhões"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Ecrã de [screenDiagonal] polegadas",
                    "tr_badgeSubTitle": "Altura do ecrã: [screenHeight] polegadas",
                    "tr_badgePreTitle": "Largura do ecrã: [YY] polegadas"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Ecrã tátil",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Ecrã",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Mergulhe em [screenResolution]&nbsp;milhões de&nbsp;píxeis",
                "tr_subtitle": "Está tudo nos píxeis. Quantos mais tiver, mais nítidas e vívidas serão as&nbsp;suas&nbsp;imagens.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>[screenResolutionType]<br/></span><span class='value'>DE [screenDiagonal]”</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolução: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Píxeis: [screenResolution] milhões"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Ecrã de [screenDiagonal] polegadas",
                    "tr_badgeSubTitle": "Altura do ecrã: [screenHeight] polegadas",
                    "tr_badgePreTitle": "Largura do ecrã: [YY] polegadas"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Ecrã",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] polegadas <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "A SUA JANELA PARA O MUNDO DOS JOGOS",
                "tr_subtitle": "Tal como num televisor, a sua principal preocupação num ecrã para jogos será o tamanho e a resolução.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolução: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Píxeis: [screenResolution] milhões"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Ecrã de [screenDiagonal] polegadas",
                    "tr_badgeSubTitle": "Altura do ecrã: [screenHeight] polegadas",
                    "tr_badgePreTitle": "Largura do ecrã: [screenWidth] polegadas"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Ecrã tátil"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Ecrã",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Este equipamento tem um ecrã impressionante.",
                "tr_subtitle": "Com um número de píxeis mais de oito vezes superior ao HD, os seus vídeos e jogos ganharão um novo nível de nitidez e cor.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolução: [screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "Píxeis: [screenResolution] milhões"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Ecrã de [screenDiagonal] polegadas",
                    "tr_badgeSubTitle": "Altura do ecrã: [screenHeight] polegadas",
                    "tr_badgePreTitle": "Largura do ecrã: [YY] polegadas"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Ecrã",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] milhões de píxeis para tocar e percorrer",
                    "tr_subTitle": "Veja e interaja com o que mais gosta com detalhes mais nítidos e vívidos."
                  },
                  {
                    "tr_title": "[screenResolution] milhões de píxeis para melhorar o seu mundo digital",
                    "tr_subTitle": "Está tudo nos píxeis. Quantos mais tiver, mais nítidas e vívidas serão as&nbsp;suas&nbsp;imagens."
                  },
                  {
                    "tr_title": "Este equipamento tem um ecrã impressionante.",
                    "tr_subTitle": "Com quase quatro vezes mais píxeis do que o HD, os vídeos e os jogos atingirão um novo patamar de nitidez e de cor."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectividade",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "A tecnologia Thunderbolt™ 3 oferece a ligação mais rápida e versátil a qualquer estação de ancoragem, ecrã ou dispositivo de dados.",
                "tr_title": "Uma porta que faz tudo",
                "tr_title1": "UMA PORTA QUE FAZ TUDO",
                "titleArray": [
                  {
                    "tr_title": "COMO POSSO UTILIZAR A TECNOLOGIA THUNDERBOLT™&nbsp;3?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tecnologia Thunderbolt™ 3"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "Monitores 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Ligue a ecrãs com resolução, contraste e cor espantosos"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Placa gráfica externa",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Melhore imenso e instantaneamente os gráficos de qualquer&nbsp;PC"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Ligação em rede rápida",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Permite ligações ponto-a-ponto rápidas"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectividade",
                "detailBGUrl": "",
                "tr_subtitle": "A tecnologia Thunderbolt™ 3 transfere dados a velocidades superiores às da tecnologia USB convencional. Assim, pode transferir ficheiros rapidamente e integrar periféricos facilmente, como vários monitores ou placas gráficas externas.",
                "tr_title": "Tecnologia Thunderbolt™ 3",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "DADOS À VELOCIDADE DA LUZ",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Tecnologia Thunderbolt™ 3",
                    "tr_badgeSubTitle": "40 Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectividade",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Apenas uma porta e um cabo universais suportam todos os seus acessórios favoritos</li> <li>Carregamento fiável para o seu portátil</li> <li>Certificação robusta, para que não tenha preocupações</li></ul>",
                "tr_title": "Ligações por cabo verdadeiramente universais",
                "tr_title1": "LIGAÇÕES POR CABO VERDADEIRAMENTE UNIVERSAIS",
                "tr_noteSection": "<sup>†</sup>Baseado nos requisitos de especificações mínimas",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "COMO POSSO UTILIZAR A TECNOLOGIA THUNDERBOLT™ 4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Tecnologia Thunderbolt™ 4"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Criar",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Ligue um monitor externo e um meio de armazenamento rápido para visualizar e editar fotos e vídeos."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Estação de ancoragem",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Ligue o seu portátil a todos os acessórios com um só cabo e crie um ambiente de trabalho simples e organizado."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Jogos",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Adicione mais capacidade SSD e HDD com velocidades até 3000 MB/s para armazenar bibliotecas de jogos e ficheiros multimédia."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Desempenho máximo<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x mais rápido do que o USB* 3.2 <br/>2x mais rápido do que o HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectividade",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Usufrua do mesmo desempenho incrível da tecnologia Thunderbolt™ 3, com ainda mais capacidades, que lhe permitem ligar e usar facilmente estações de ancoragem, monitores, armazenamento rápido ou quaisquer acessórios USB* Thunderbolt™ mais recentes.",
                "tr_title": "Tecnologia Thunderbolt™ 4",
                "tr_pretitle": "LIGAÇÕES POR CABO VERDADEIRAMENTE UNIVERSAIS",
                "tr_noteSection": "<sup>†</sup>Baseado nos requisitos de especificações mínimas",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Um único cabo universal Thunderbolt™ 4 pode substituir todos os seus cabos USB&#8209;C*, para tornar a seleção de cabos rápida e fácil."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "As estações de ancoragem com tecnologia Thunderbolt™&nbsp;4 disponibilizam até quatro portas Thunderbolt™ para maior flexibilidade na ligação de qualquer um dos seus acessórios."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Desempenho máximo<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x mais rápido do que o USB* 3.2 <br/>2x mais rápido do que o HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Conectividade",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Apenas uma porta e um cabo universais suportam todos os seus acessórios favoritos</li> <li>Carregamento fiável para o seu portátil</li> <li>Certificação robusta, para que não tenha preocupações</li></ul>",
                "tr_title": "Maximize a sua experiência com acessórios Thunderbolt™",
                "tr_noteSection": "<sup>†</sup>Baseado nos requisitos de especificações mínimas",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "COMO POSSO UTILIZAR A TECNOLOGIA THUNDERBOLT™ 4?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Criar",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Ligue um monitor externo e um meio de armazenamento rápido para visualizar e editar fotos e vídeos."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Estação de ancoragem",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Ligue o seu portátil a todos os acessórios com um só cabo e crie um ambiente de trabalho simples e organizado."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Jogos",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Adicione mais capacidade SSD e HDD com velocidades até 3000 MB/s para armazenar bibliotecas de jogos e ficheiros multimédia."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Desempenho máximo<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x mais rápido do que o USB* 3.2 <br/>2x mais rápido do que o HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "Os tipos de Wi&#8209;Fi mais comuns são:",
                "tr_title": "COMPREENDER AS NORMAS WI&#8209;FI",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "O Wi-Fi 6 permite velocidades mais elevadas, mesmo quando muitos utilizadores estão a utilizar o mesmo sinal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Com a inovação Wi-Fi Intel® mais recente, pode atingir velocidades mais altas do que com o Wi-Fi gigabit, mesmo quando vários utilizadores estão a usar o mesmo sinal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Sendo o maior avanço relativo a Wi&#8209;Fi em 20 anos, o Wi&#8209;Fi 6E abre novos canais de alta velocidade para um melhor desempenho e fiabilidade e menos interferências."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "O Wi-Fi 6 permite velocidades mais elevadas, mesmo quando muitos utilizadores estão a utilizar o mesmo sinal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi&#8209;Fi&nbsp;6E&nbsp;(Gig+)",
                    "tr_badgeSubTitle": "Todos os benefícios do Wi-Fi&nbsp;6E em conjunto com o motor de atribuição de prioridades Intel® Killer™, que transfere a largura de banda para os sites e aplicações que mais precisam."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E",
                    "tr_badgeSubTitle": "Com menos concorrência nos seus canais de alta velocidade, desfrutará de melhor desempenho e fiabilidade."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "O Wi-Fi 6 permite velocidades mais elevadas, mesmo quando muitos utilizadores estão a utilizar o mesmo sinal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi&#8209;Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Com menos interferência do que Wi&#8209;Fi&nbsp;6 e o desempenho inteligente do Motor de atribuição de prioridades Intel® Killer™, a sua experiência online será mais rápida e mais ágil.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Com menos concorrência nos seus canais de alta velocidade, desfrutará de melhor desempenho e fiabilidade."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Velocidades de Wi-Fi em PC superiores a 5 Gbps com latência consistente extremamente baixa. Partilhe ficheiros em segundos e não em minutos."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "A tecnologia Intel® Killer™ Wi-Fi 7 (5 Gig) é a tecnologia mais recente que oferece fiabilidade, baixa latência e velocidades “extremas” com a otimização de rede baseada em IA."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "O Wi-Fi 6 permite velocidades mais elevadas, mesmo quando muitos utilizadores estão a utilizar o mesmo sinal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi&#8209;Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Velocidades da tecnologia Intel® Wi-Fi 6E (Gig+) superiores a 1 Gbps, fiabilidade e baixa latência com a utilização de canais na banda dos 6 GHz."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi&#8209;Fi 6",
                    "tr_badgeSubTitle": "Melhor eficiência, capacidade e velocidade em comparação com o Wi-Fi 5 em redes congestionadas."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Mantenha uma ligação mais estável com Wi&#8209;Fi [XX] e desfrute da Internet com velocidades mais rápidas<sup>†</sup> e maior fiabilidade.",
                "tr_title": "Alcance e velocidade melhorados",
                "tr_noteSection": "<sup>†</sup> A capacidade de transmissão sem fios e/ou o alcance reais podem variar.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Na era dos jogos online e com vários jogadores, estar ligado é mais importante do que nunca. Além de velocidade, também vai querer um sinal sem fios que possa manter velocidades elevadas à distância.",
                "tr_title": "[XX]",
                "tr_pretitle": "ESTAR LIGADO É FUNDAMENTAL",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ligue-se com o Intel® Wi&#8209;Fi 6 (Gig+) a routers Wi&#8209;Fi 6 com suporte para o canal de 160 MHz para permitir ligações mais rápidas e maior fiabilidade para mais utilizadores ativos.",
                "tr_title": "Velocidades quase 3x mais rápidas com o Intel®&nbsp;<span class='no-warp'>Wi-Fi&nbsp;6 </span>&nbsp;(Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Em comparação com o Wi&#8209;Fi AC padrão. Determinadas funcionalidades estão apenas disponíveis com certas SKU. Consulte o fabricante para obter mais detalhes. Para obter mais informações sobre Wi&#8209;Fi, aceda a intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "A tecnologia Intel® Wi-Fi 6 Dual Band de nível de entrada suporta um desempenho melhorado para necessidades de conectividade básicas, como e-mail e navegação na Internet, em redes congestionadas.<sup>9</sup>",
                "tr_title": "Wi‑Fi essencial",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Na era dos jogos online e com vários jogadores, estar ligado é mais importante do que nunca. Além de velocidade, também vai querer um sinal sem fios que possa manter velocidades elevadas à distância.",
                "tr_title": "[XX]",
                "tr_pretitle": "ESTAR LIGADO É FUNDAMENTAL",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Com estes novos canais de Wi&#8209;Fi, não tem de partilhar a largura de banda com os dispositivos mais antigos da sua vizinhança, proporcionando-lhe fluidez no streaming, navegação rápida, bem como trabalho e aprendizagem remotos sem interrupções.",
                "tr_title": "Ligue-se sem concorrência",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Mantenha uma ligação mais estável com [XX] e desfrute da Internet com velocidades mais rápidas<sup>†</sup> e maior fiabilidade.",
                "tr_title": "Alcance e velocidade melhorados",
                "tr_noteSection": "<sup>†</sup> A capacidade de transmissão sem fios e/ou o alcance reais podem variar.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Na era dos jogos online e com vários jogadores, estar ligado é mais importante do que nunca. Além de velocidade, também vai querer um sinal sem fios que possa manter velocidades elevadas à distância.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "ESTAR LIGADO É FUNDAMENTAL",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Wi&#8209;Fi ultrarrápido integrado",
                "tr_subtitle": "Desligue da tomada e mantenha-se ligado ao seu mundo online."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "As faixas de alta velocidade dedicadas, que reduzem as interferências, e o motor de atribuição de prioridades Intel® Killer™, que dá prioridade aos jogos, permitem melhorar significativamente os jogos online.",
                "tr_title": "Deixe a concorrência para trás",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "As faixas de alta velocidade dedicadas, que reduzem as interferências, e o motor de atribuição de prioridades Intel® Killer™, que dá prioridade aos jogos, permitem melhorar significativamente os jogos online.",
                "tr_title": "[XX]",
                "tr_pretitle": "DEIXE A CONCORRÊNCIA PARA TRÁS",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Com faixas dedicadas de alta velocidade para menos interferência e que colocam os jogos em primeiro lugar, os jogos online ficaram ainda melhores.",
                "tr_title": "[XX]",
                "tr_pretitle": "DEIXE A CONCORRÊNCIA PARA TRÁS",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "O Intel® Killer™ Wi-Fi acelera automaticamente o tráfego de rede para as aplicações que mais necessitam, de modo a que os seus vídeos de streaming tenham detalhes nítidos e sejam colocados na memória intermédia o menos possível.",
                "tr_title": "Uma forma mais rápida e mais inteligente de estabelecer ligação.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Na era dos jogos online e com vários jogadores, estar ligado é mais importante do que nunca. Além de velocidade, também vai querer um sinal sem fios que possa manter velocidades elevadas à distância.",
                "tr_title": "[XX]",
                "tr_pretitle": "ESTAR LIGADO É FUNDAMENTAL",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Mantenha uma ligação mais estável com o Wi-Fi 5 e desfrute da Internet com velocidades mais rápidas<sup>†</sup> e maior fiabilidade.",
                "tr_title": "Alcance e velocidade melhorados",
                "tr_noteSection": "<sup>†</sup> A capacidade de transmissão sem fios e/ou o alcance reais podem variar.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Na era dos jogos online e com vários jogadores, estar ligado é mais importante do que nunca. Além de velocidade, também vai querer um sinal sem fios que possa manter velocidades elevadas à distância.",
                "tr_title": "[XX]",
                "tr_pretitle": "DEIXE A CONCORRÊNCIA PARA TRÁS",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "As características de Wi-Fi 7 baseiam-se e elevam as gerações anteriores de Wi-Fi. Além de velocidades mais rápidas, significa também uma capacidade de resposta e fiabilidade drasticamente melhoradas para criar experiências de consumo imersivas e tecnologias futuras sofisticadas.",
                "tr_title": "Rede de PC semelhante à rede com fios",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "As características de Wi-Fi 7 baseiam-se e elevam as gerações anteriores de Wi-Fi. Além de velocidades mais rápidas, significa também uma capacidade de resposta e fiabilidade drasticamente melhoradas para criar experiências de consumo imersivas e tecnologias futuras sofisticadas.",
                "tr_title": "[XX]",
                "tr_pretitle": "TRANSFORMAR A EXPERIÊNCIA DO UTILIZADOR",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Velocidade, latência e fiabilidade melhoradas com a otimização de rede baseada em IA",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi&#8209;Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Velocidade, latência e fiabilidade melhoradas com a otimização de rede baseada em IA.",
                "tr_title": "[XX]",
                "tr_pretitle": "CAPACIDADE DE RESPOSTA SEMELHANTE À REDE COM FIOS",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi&#8209;Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Como se mede?",
                    "tr_badgeSubTitle": "A maioria dos fabricantes indica a autonomia da bateria em horas. Embora este cálculo não seja 100% preciso, permite-lhe comparar a autonomia da bateria entre vários PC."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "JOGUE ONDE QUER QUE ESTEJA",
                "tr_subtitle": "Com a capacidade dos portáteis de jogos atuais, não há razão para ficar preso a uma secretária. Agora, os seus jogos podem ir para qualquer lugar.",
                "tr_title": "[Dynamic.battery.values.hours] horas de autonomia da bateria"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Desligue os cabos e mantenha-se ligado às suas aplicações, jogos&nbsp;e&nbsp;música durante horas.",
                "tr_title": "[XX] horas de streaming com um único carregamento<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Com base na autonomia da bateria declarada pelo fabricante do PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] horas",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Desligue os cabos e mantenha-se ligado às suas aplicações, jogos&nbsp;e&nbsp;música durante horas.",
                "tr_title": "[Dynamic.battery.values.hours] horas de energia em qualquer lugar<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Com base na autonomia da bateria declarada pelo fabricante do PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] horas",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Desligue os cabos e mantenha-se ligado às suas aplicações, jogos&nbsp;e&nbsp;música durante horas.",
                "tr_title": "[Dynamic.battery.values.hours] horas para todo o dia com um único carregamento<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Com base na autonomia da bateria declarada pelo fabricante do PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] horas",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Bateria",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Este PC otimiza a autonomia da bateria e dá&#8209;lhe, pelo menos, 4 horas de autonomia com uma carga de 30 minutos.<sup>4</sup>",
                "tr_title": "Portabilidade durante mais tempo com [Dynamic.battery.values.hours] horas de autonomia da bateria<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Com base na autonomia da bateria declarada pelo fabricante do PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] horas",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Início",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Porquê este PC",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Ver todas as especificações",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Remoto",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Móvel",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Aviso legal",
        "tr_paragraph_array": [
          "O software e as cargas de trabalho utilizados nos testes de desempenho podem ter sido otimizados apenas para o desempenho nos microprocessadores Intel®. Os testes de desempenho, como o SYSmark* e o MobileMark*, são processados através de sistemas informáticos, componentes e funções específicos. Qualquer alteração a um destes fatores pode resultar numa alteração dos resultados. Consulte mais informações e testes de desempenho para avaliar conscientemente potenciais compras, incluindo informações acerca do produto utilizado em conjunto com outros produtos. Para obter informações mais completas sobre desempenho e resultados dos parâmetros de comparação, aceda a www.intel.com/benchmarks.",
          "¹Os produtos Intel® Wi-Fi 6 (Gig+) são compatíveis com os canais opcionais de 160&nbsp;MHz, possibilitando as velocidades máximas teóricas mais rápidas possíveis (2402&nbsp;Mbps) para os produtos Wi-Fi padrão 802.11ax 2x2 para PC. Os produtos premium Intel® Wi-Fi 6 (Gig+) permitem velocidades máximas teóricas 2-4X mais rápidas em comparação com os produtos Wi-Fi padrão 802.11ax 2x2 (1201&nbsp;Mbps) ou 1x1 (600&nbsp;Mbps) para PC, que suportam apenas os requisitos obrigatórios de canais de 80&nbsp;MHz.",
          "²Recomendação apenas para fins informativos. Consulte outras fontes ao tomar a sua decisão de compra.",
          "³As funcionalidades e os benefícios das tecnologias Intel dependem da configuração do sistema e podem exigir hardware e software compatíveis ou a ativação de serviços. O desempenho varia de acordo com a configuração do sistema. Nenhum sistema informático pode ser totalmente seguro. Consulte o fabricante ou revendedor do sistema ou obtenha mais informações em intel.com.",
          "⁴Para sistemas com ecrãs FHD, quando utilizados para navegação na Web sem fios. Quando desligados, a partir do nível de encerramento predefinido do OEM.",
          "⁵Em comparação com outras tecnologias de ligação de E/S de PC, incluindo eSATA, USB e IEEE 1394* FireWire. O desempenho varia consoante o hardware e o software específicos utilizados. É necessário utilizar um dispositivo compatível com a tecnologia Thunderbolt™.",
          "⁶Praticamente 3X mais rápido: o 802.11ax 2x2 160 MHz possibilita velocidades de dados máximas teóricas de 2402 Mbps. Quase 3X mais rápido do que o Wi&#8209;Fi padrão 802.11ac 2x2 80 MHz (867 Mbps), conforme documentado nas especificações da norma de tecnologia sem fios IEEE 802.11*, e exige a utilização de routers de rede sem fios 802.11ax configurados de forma semelhante.",
          "⁷Redução da latência em 75%: com base em dados de simulação da Intel (79%) da tecnologia 802.11ax com e sem OFDMA utilizando nove clientes. A latência média sem OFDMA é de 36&nbsp;ms. Com OFDMA, a latência média é reduzida para 7,6&nbsp;ms. A melhoria na latência exige que o router 802.11ax (Wi&#8209;Fi&nbsp;6) e todos os clientes suportem OFDMA.",
          "⁸Com base na comparação da taxa máxima teórica de dados na especificação de 802.11, o Wi&#8209;Fi 6/6E de 2x2 160 MHz (802.11ax) consegue fornecer 2402 Mbps face aos 867 Mbps fornecidos pelo Wi&#8209;Fi 5 de 2x2 80 MHz (802.11ac), o que equivale a velocidades 2,8x mais rápidas.",
          "⁹O melhor Wi&#8209;Fi 6 da sua classe: os produtos Intel® Wi&#8209;Fi 6 (Gig+) são compatíveis com os canais opcionais de 160 MHz, possibilitando as velocidades máximas teóricas mais rápidas possíveis (2402 Mbps) para os produtos Wi&#8209;Fi padrão 802.11ax 2x2 para PC. Os produtos premium Intel® Wi&#8209;Fi 6 (Gig+) permitem velocidades máximas teóricas 2-4x mais rápidas em comparação com os produtos Wi&#8209;Fi padrão 802.11ax 2x2 (1201 Mbps) ou 1x1 (600 Mbps) para PC, que suportam apenas os requisitos obrigatórios de canais de 80 MHz.",
          "Disponível apenas em algumas SKUs, a funcionalidade poderá variar de acordo com o design do OEM. Confira com o OEM ou revendedor para obter mais informações. Para mais informações sobre Wi&#8209;Fi, aceda a intel.com/wifi6disclaimers.",
          "¹⁰Tecnologia Thunderbolt™ 4, a 40 Gbps: oferece a solução por cabo mais rápida, simples e fiável para qualquer estação de ancoragem, monitor ou dispositivo de dados quando comparada com outras tecnologias de ligação de E/S para PC, incluindo eSATA*, USB* e FireWire IEEE 1394*.",
          "¹¹Requer um router baseado na tecnologia 802.11ax, compatível com OFDMA e vários clientes na rede com suporte para AX. A reivindicação \"Melhor em ambientes densos\" é alcançável a partir de funcionalidades OFDMA, suportadas por pontos de acesso e clientes 802.11ax. 2&nbsp;Gbps com base em pressupostos de aproximadamente 70% das velocidades de dados máximas teóricas da especificação IEEE 802.11* de 802.11ax a 160&nbsp;MHz e 2402&nbsp;Mbps.",
          "¹²Conforme medido pela abertura de documento com carga de trabalho de atividades em segundo plano, comparando o processador Intel® Core™ i7-8565U de 8ª geração (SSD TLC de 512 GB) e o processador Intel® Core™ i7-8565U de 8ª geração (memória Intel® Optane™ H10 de 32 GB com armazenamento de estado sólido de 512 GB).",
          "¹³O Intel® Thread Director foi concebido para processadores Intel® Core™ de 12ª geração e ajuda os sistemas operativos a canalizarem as cargas de trabalho para o núcleo certo de forma mais inteligente. Não é necessária qualquer ação por parte do utilizador. Consulte intel.com para obter mais detalhes.",
          "¹⁴Não disponível em certos processadores Intel® Core™ de 12ª geração. A arquitetura híbrida de desempenho combina duas novas microarquiteturas de núcleo, Performance-cores (P-cores) e Efficient-cores (E-cores) num único processador. Alguns processadores Intel® Core™ de 12ª geração (certos processadores Intel® Core™ i5 de 12ª geração e inferiores) não suportam arquitetura híbrida de desempenho, apenas P-cores.",
          "¹⁵Conforme medido pela carga de trabalho de início de jogo do processador Intel® Core™ i7-8750H de 8ª geração (Módulo de memória Intel® Optane™ de 32&nbsp;GB + SSD Intel® PCIe* de 256&nbsp;GB + HDD de 1&nbsp;TB) comparativamente ao processador Intel® Core™ i7-8750H de 8ª geração (SSD Intel® PCIe* de 256&nbsp;GB + HDD de 1&nbsp;TB). Carga de trabalho de início de jogo – Carga de trabalho desenvolvida pela Intel com medição do tempo decorrido para iniciar o jogo Total War*: WARHAMMER* II versão 5577.0 e alcançar o Menu principal com os vídeos de introdução desativados (início). Configurações de aceleração da unidade de dados da memória Intel® Optane™: processador Intel® Core™ i7-8750H, PL1=45&nbsp;W de TDP, 6 núcleos/12 threads, turbo até 4&nbsp;GHz num sistema OEM em pré-produção, placa gráfica: NVIDIA* GeForce* GTX 1070, memória: 2 DDR4 de 4&nbsp;GB, armazenamento: SSD Intel® 760p de 256&nbsp;GB (PCIe*) + disco rígido de 1&nbsp;TB + memória Intel® Optane™ de 32&nbsp;GB, SO: Windows* 10 RS3 versão 1709, MCU 0x84; processador Intel® Core™ i7-8750H, PL1=45&nbsp;W de TDP, 6 núcleos/12 threads, turbo até 4&nbsp;GHz num sistema OEM em pré-produção, placa gráfica: NVIDIA* GeForce* GTX 1070, memória: 2 DDR4 de 4&nbsp;GB, armazenamento: SSD Intel® 760p de 256&nbsp;GB (PCIe*) + HDD de 1&nbsp;TB, SO: Windows* 10 RS3 versão 1709, MCU 0x84.",
          "¹⁶Conforme medido pela carga de trabalho de início de níveis de jogo no processador Intel® Core™ i7-8750H de 8ª geração (módulo de memória Intel® Optane™ de 32&nbsp;GB) comparativamente ao processador Intel® Core™ i7-8750H de 8ª geração (apenas HDD). Carga de trabalho de início de níveis de jogo – Carga de trabalho desenvolvida pela Intel com medição do tempo decorrido desde o Menu principal à conclusão do carregamento de níveis no Total War*: WARHAMMER* II versão: 5577.0. Configurações de aceleração da unidade de dados da memória Intel® Optane™: processador Intel® Core™ i7-8750H, PL1=45&nbsp;W de TDP, 6 núcleos/12 threads, turbo até 4&nbsp;GHz num sistema OEM em pré-produção, placa gráfica: NVIDIA* GeForce* GTX 1070, memória: 2 DDR4 de 4&nbsp;GB, armazenamento: SSD Intel® 760p de 256&nbsp;GB (PCIe*) + disco rígido de 1&nbsp;TB + memória Intel® Optane™ de 32&nbsp;GB, SO: Windows* 10 RS3 versão 1709, MCU 0x84; processador Intel® Core™ i7-8750H, PL1=45&nbsp;W de TDP, 6 núcleos/12 threads, turbo até 4&nbsp;GHz num sistema OEM em pré-produção, placa gráfica: NVIDIA* GeForce* GTX 1070, memória: 2 DDR4 de 4&nbsp;GB, armazenamento: SSD Intel® 760p de 256&nbsp;GB (PCIe*) + disco rígido de 1&nbsp;TB, SO: Windows* 10 RS3 versão 1709, MCU 0x84.",
          "¹⁷Em performance-cores. O desempenho varia de acordo com a utilização, a configuração e outros fatores. Saiba mais em www.intel.com/PerformanceIndex.",
          "¹⁸Alterar a frequência ou a tensão do relógio pode danificar ou reduzir a autonomia do processador e de outros componentes do sistema, bem como a estabilidade e o desempenho do sistema. As garantias do produto podem ficar inválidas, caso o processador esteja a funcionar para além das suas especificações. Consulte os fabricantes do sistema e dos componentes para obter detalhes adicionais.",
          "¹⁹Conforme medido pela combinação incomparável da plataforma Intel vPro® de acima e abaixo das capacidades de segurança do SO, das proteções da aplicação e dos dados e das proteções avançadas contra ameaças para uma empresa de qualquer tamanho, bem como a primeira abordagem de segurança da Intel ao design, produção e assistência do produto. Todos os PC empresariais desenvolvidos na plataforma Intel vPro® foram validados com especificações rigorosas, incluindo funcionalidades de segurança exclusivas baseadas no hardware. Consulte www.intel.com/PerformanceIndex (plataformas) para mais detalhes. Nenhum produto ou componente pode ser totalmente seguro.",
          "²⁰O Intel® Connectivity Performance Suite está disponível apenas em sistemas baseados em Windows*. Testes Wi-Fi Intel sem fios (OTA — Over The Air) numa rede congestionada versus sem atribuição de prioridades de tráfego sem fios da aplicação de software ICPS e comutação inteligente de ponto de acesso. Consulte www.intel.com/PerformanceIndex (conectividade) para mais detalhes. Os resultados podem variar.",
          "²¹Em comparação com a geração anterior de processadores.",
          "²²Os gráficos Intel® Arc™ são os primeiros do mercado com suporte para codificação AV1 (no 1º trimestre de 2022). Saiba mais em intel.com/performanceindex.",
          "²³De acordo com as características únicas dos designs Intel® Evo™, que também são verificadas para cumprir os principais limites da experiência de utilização móvel. Testes a partir de fevereiro de 2022.",
          "²⁴De momento, a solução Intel® Unison™ está disponível apenas em designs Intel® Evo™ elegíveis em PC Windows* e apenas faz o emparelhamento com telefones Android ou iOS; todos os dispositivos devem executar uma versão do SO suportada.",
          "²⁵As velocidades máximas de memória estão associadas a configurações de 1 DIMM por canal (1DPC). O carregamento adicional de DIMM em qualquer canal pode afetar a velocidade máxima de memória. Até DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 e DDR5-5200 1Rx8, 1Rx16, 2Rx8 em SKU selecionadas. A capacidade máxima de memória é alcançável com configurações 2DPC.",
          "²⁶Em comparação com o Wi-Fi padrão. Consulte intel.com/performance-wireless para mais informações. Os resultados podem variar.",
          "²⁷A arquitetura híbrida de desempenho combina duas microarquiteturas de núcleo, os Performance-cores (P-cores) e os Efficient-cores (E-cores), numa única matriz de processador, introduzida pela primeira vez nos processadores Intel® Core™ de 12ª geração. Certos processadores Intel® Core™ de 12ª geração e mais recentes não possuem arquitetura híbrida de desempenho, apenas P-cores, e apresentam o mesmo tamanho de cache que a geração anterior; consulte ark.intel.com para obter detalhes da SKU.",
          "²⁸Integrado no hardware, o Intel® Thread Director está apenas disponível em configurações de arquitetura híbrida de desempenho dos processadores Intel® Core™ de 12ª geração ou mais recentes e requer ativação do SO. Os recursos e as funcionalidades disponíveis variam de acordo com o SO.",
          "²⁹Integrado no hardware, o Intel® Thread Director está apenas disponível em configurações de arquitetura híbrida de desempenho dos processadores Intel® Core™ de 12ª e 13ª gerações e requer ativação do SO. Os recursos e as funcionalidades disponíveis variam de acordo com o SO.",
          "³⁰Conforme medido pelo desempenho da plataforma de validação de referência do processador móvel Intel®&nbsp;Core™ Ultra (Série 2 — Nome de código: Lunar Lake) comparativamente ao processador móvel Intel®&nbsp;Core™ Ultra (Série 1 — Nome de código: Meteor Lake) no parâmetro de comparação 3DMark Time Spy. Para obter mais detalhes, consulte intel.com/performanceindex. Os resultados podem variar.",
          "³¹A desfocagem de fundo e o rastreamento facial estão disponíveis apenas em sistemas baseados em Windows*.",
          "³²Os produtos com a tecnologia Wi-Fi 7 conseguem aceder a canais de 320 MHz na banda dos 6 GHz e novas combinações de canais de 160 MHz na banda dos 5 GHz com as mais recentes capacidades de Multi-RU Puncturing.",
          "³³Com base nas especificações IEEE para redes sem fios e nas taxas máximas teóricas de transmissão de dados para dispositivos com 2 streams.",
          "³⁴As simulações Intel de ambientes de rede congestionada indicam que é possível uma grande redução da latência com as novas capacidades de operação Multi-Link da tecnologia Wi-Fi 7.",
          "³⁵Carga mínima obtida a partir do nível de encerramento predefinido do OEM. Os resultados podem variar em cada sistema. Consulte intel.com/performance-evo para mais informações.",
          "³⁶A banda Wi-Fi de 6 GHz não está disponível para utilização em todos os países/regiões.",
          "³⁷Apenas Windows*",
          "³⁸Carga obtida a partir do nível de encerramento predefinido do OEM. Para informações completas sobre desempenho e resultados dos parâmetros de comparação, visite intel.com/Evo.",
          "³⁹Refere-se aos processadores móveis Intel®&nbsp;Core™ Ultra (Série&nbsp;2), que têm por base as funcionalidades de segurança únicas baseadas no hardware, uma combinação incomparável das capacidades de segurança acima e abaixo do sistema operativo e um desempenho impressionante mesmo com uma potência inferior à da geração anterior (à data de setembro de 2024). Para obter mais detalhes, consulte intel.com/performanceindex. Os resultados podem variar.",
          "⁴⁰Aplicações de software apresentadas apenas para fins ilustrativos. As funcionalidades de IA podem exigir a aquisição, assinatura ou ativação de software adicional por um fornecedor de software ou plataforma, ou podem ter requisitos de configuração ou compatibilidade específicos. Mais detalhes em www.intel.com/PerformanceIndex. Os resultados podem variar. ©&nbsp;Intel&nbsp;Corporation.",
          "⁴¹As imagens apresentadas podem ter sido alteradas ou simuladas. As funcionalidades de IA podem exigir a aquisição, assinatura ou ativação de software adicional por um fornecedor de software ou plataforma, ou podem ter requisitos de configuração ou compatibilidade específicos. Saiba mais em www.intel.com/AIPC.",
          "⁴²Antevisão do lançamento gradual no âmbito da atualização mais recente para o Windows* 11 em mercados globais selecionados. A disponibilização varia de acordo com o dispositivo e o mercado. Saiba mais aqui: aka.ms/WindowsAIFeatures. ©&nbsp;Intel&nbsp;Corporation.",
          "⁴³A solução Intel® Unison™ está atualmente disponível para designs elegíveis. Para obter mais detalhes, consulte www.intel.com/PerformanceIndex.",
          "⁴⁴FPS melhorados pela tecnologia Intel® X<sup>e</sup>SS comparativamente aos FPS nativos, conforme medido pelo jogo Dying Light 2 Stay Human em execução no processador Intel® Core™ Ultra 7 165H. Para obter mais detalhes, consulte www.intel.com/PerformanceIndex. Os resultados podem variar.",
          "⁴⁵Conforme medido pela potência do SoC mais baixa da plataforma de validação de referência do processador móvel Intel®&nbsp;Core™ Ultra (Série 2 — Nome de código: Lunar Lake) comparativamente à plataforma de validação de referência do processador móvel Intel®&nbsp;Core™ Ultra (Série 1 — Nome de código: Meteor Lake) no YouTube* 4K 30 FPS AV1. Para obter mais detalhes, consulte intel.com/performanceindex. Os resultados podem variar.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "A Intel não assume qualquer responsabilidade por preços incorretos.",
          "* Outros nomes e marcas poderão ser reivindicados como propriedade de terceiros.",
          "COPYRIGHT 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES E O DESIGN RELACIONADO E MARCAS COMPOSTAS SÃO MARCAS COMERCIAIS DA INFLEXION STUDIOS INC. TODOS OS DIREITOS RESERVADOS.",
          "As tecnologias Intel podem exigir a ativação de hardware, software ou serviços.",
          "Todos os resultados e dados do NERO* são disponibilizados apenas como referência e para fins informativos. A Intel Corporation não se responsabiliza, a título de exemplo, pela exatidão da aplicação NERO*, por resultados e pontuações de desempenho, ou por possíveis variações de outras versões transferíveis da aplicação disponibilizadas em www.nero.com ou noutros websites de terceiros. As pontuações do parâmetro de comparação do NERO* para PC são medidas através de sistemas informáticos, componentes, software, operações e funções específicos. Qualquer alteração num destes fatores pode resultar numa alteração dos resultados. Consulte mais informações e testes de desempenho para avaliar conscientemente potenciais compras, incluindo informações acerca do produto utilizado em conjunto com outros produtos.",
          "Todas as versões da plataforma Intel vPro® requerem um processador Intel® Core™ elegível, um sistema operativo compatível, um circuito LAN e/ou WLAN Intel®, melhorias no firmware e outros hardware e software necessários para fornecer os casos de utilização de capacidade de gestão, as funcionalidades de segurança e o desempenho e a estabilidade do sistema que definem a plataforma.",
          "O desempenho varia de acordo com a utilização, a configuração e outros fatores. Saiba mais em www.intel.com/PerformanceIndex.",
          "A Intel não controla nem audita dados de terceiros. Deve consultar outras fontes para avaliar a precisão.",
          "© Intel Corporation. Intel, o logótipo Intel e outras marcas Intel são marcas comerciais da Intel Corporation ou das suas subsidiárias. *Outros nomes e marcas poderão ser reclamados como propriedade de terceiros.",
          "Nenhum produto ou componente é totalmente seguro.",
          "Os seus custos e resultados podem variar.",
          "Intel, o logótipo Intel, Intel Core, Intel Optane, Intel Iris, Thunderbolt e o logótipo Thunderbolt são marcas comerciais da Intel Corporation ou das suas subsidiárias. Outros nomes e marcas poderão ser reclamados como propriedade de terceiros.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Início",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Leia o código para comparar",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Tendências",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "Descubra novas experiências de IA com os processadores Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "Descubra novas experiências de IA com os processadores Intel® Core™ Ultra",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "Descubra novas experiências de IA com os processadores Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "Difusão estável*: texto para imagem",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe*: edição de fotos",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "Intel® X<sup>e</sup>SS : upscaling de imagens",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee*: Deepfake Detector",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "Efeitos de colaboração (Teams)",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "IQIYI: controlo por gestos",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "Sincronize o seu PC com o seu telemóvel e visualize notificações, chamadas e mensagens de texto",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "Gráficos ricos e vibrantes integrados",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "Microsoft*: viva a vida à sua maneira",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "Microsoft*: tudo aquilo que faz parte de si. Tudo num relance, sempre à distância de um deslizar",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "Faça o seu processador funcionar mais rápido e em frequências mais elevadas ajustando o seu sistema",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "Reforce o desempenho do seu sistema desktop ou móvel sem sacrificar o espaço de armazenamento",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "Reforce o desempenho do seu sistema desktop ou móvel sem sacrificar o espaço de armazenamento",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "Samsung OLED: um espetáculo para os olhos",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "Velocidade, latência e fiabilidade melhoradas com a otimização de rede baseada em IA",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "Rede de PC semelhante à rede com fios",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "Rede de PC semelhante à rede com fios",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "Velocidades quase 3x mais rápidas com o Intel®&nbsp;Wi-Fi&nbsp;6&nbsp;(Gig+)",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "Ligue-se sem concorrência",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "Ligações por cabo verdadeiramente universais",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "Jogue como os profissionais: veja como a Intel potencializa os jogos e os jogadores",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "Jogue como os profissionais: onde há Intel, há campeões",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "BEM-VINDO(A)",
    "tr_welcomePagetrSubtitle": "Controle este PC remotamente com o seu smartphone",
    "tr_welcomePagembSubtitle": "Bem-vindo(a), explore as especificações deste PC no seu smartphone",
    "tr_welcomePageBtnText": "Começar",
    "tr_welcomePageText": "Explore e compare os detalhes deste PC no seu smartphone",
    "tr_scrollText": "Navegue no dispositivo à sua frente",
    "tr_toolTipTextc1": "Utilize estes botões para percorrer as diferentes páginas do dispositivo à sua frente.",
    "tr_toolTipTextc2": "Ao tocar nos cartões abaixo, irá aceder a uma página detalhada de cada especificação do dispositivo.",
    "tr_toolTipTextc3": "Utilize estes botões para se deslocar para cima e para baixo no ecrã à sua frente.",
    "tr_toolTipTextc4": "Desative o modo remoto para visualizar as especificações do dispositivo no seu smartphone.",
    "tr_toolTipTextmb1": "Toque nos cartões para visualizar páginas de conteúdo detalhadas.",
    "tr_toolTipTextmb2": "Toque para visualizar um resumo de todas as especificações do dispositivo",
    "tr_toolTipTextmb3": "Toque no botão para ler o código QR e comparar até 4 dispositivos",
    "tr_nextButton": "Avançar",
    "tr_prevButton": "Voltar",
    "tr_gotItButton": "OK",
    "tr_skipButton": "Ignorar",
    "tr_placeholderText": "*marcador de posição",
    "tr_processorHeading": "Processador",
    "tr_processorSubheading": "Processador Intel® Core™ i7 de&nbsp;10ª&nbsp;geração",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "Memória Intel® Optane™ de [XX] GB + SSD de [YY] GB",
    "tr_coachmarkProcessor": "Processador XX",
    "tr_coachmarkGraphics": "Gráficos [XX]",
    "tr_coachmarkMemory": "Valor da especificação",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "$999,95",
    "tr_compare": "Comparar",
    "tr_viewallspecs": "VER TODAS AS ESPECIFICAÇÕES",
    "tr_operatingSystem": "SO",
    "tr_AmdProcessorNonIntel": "Processador AMD*",
    "tr_QualcommProcessorNonIntel": "Processador Qualcomm*",
    "tr_GBText": "GB",
    "tr_remoteText": "Especificações apresentadas no ecrã do portátil",
    "tr_scanErrorText": "Apenas pode ler o código de até 3 dispositivos em simultâneo. Apague um ou mais dispositivos.",
    "tr_scanErrorText2": "Já leu o código deste dispositivo, pelo que deve ler outro código.",
    "tr_scanMsg": "Leia o código para adicionar o dispositivo",
    "tr_backToScan": "Voltar à comparação",
    "tr_scanToCompare": "Ler código para comparar",
    "tr_compareDevice": "Comparar dispositivos",
    "tr_processorTitle": "PROCESSADOR",
    "tr_graphicTitle": "GRÁFICOS",
    "tr_storageTitle": "ARMAZE&shy;NAMENTO",
    "tr_displayTitle": "ECRÃ",
    "tr_batteryTitle": "BATERIA",
    "tr_memoryTitle": "MEMÓRIA",
    "tr_wifiTitle": "Wi&#8209;Fi",
    "tr_connectivityTitle": "CONECTIVIDADE",
    "tr_priceTitle": "PREÇO",
    "tr_operatingSystemTitle": "SISTEMA OPERATIVO",
    "tr_batteryHoursText": "[XX] horas",
    "tr_hrsText": "h",
    "tr_touchscreeenText": "Ecrã tátil de [screenDiagonal]&nbsp;pol.",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, Ecrã tátil",
    "tr_FHDTouchScreenText": "FHD, Ecrã tátil",
    "tr_4KTouchscreenText": "4K, Ecrã tátil",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Guardar",
    "tr_device_comparison": "Documento de comparação do dispositivo [dd]-[mm]-[yyyy]",
    "tr_Save_As_JPEG": "Guardar como JPEG",
    "tr_Save_As_PDF": "Guardar como PDF",
    "tr_Downloading_PDF": "A transferir o PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Abrir",
    "tr_logo": "logótipo",
    "tr_laptop_name": "Nome do portátil",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Memória Intel® Optane™",
    "tr_inchUnit": "polegadas",
    "tr_wifi1": "Wi&#8209;Fi 1",
    "tr_wifi2": "Wi&#8209;Fi 2",
    "tr_wifi3": "Wi&#8209;Fi 3",
    "tr_wifi4": "Wi&#8209;Fi 4",
    "tr_wifi5": "Wi&#8209;Fi 5",
    "tr_wifi6": "Intel® Wi&#8209;Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI&#8209;FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi&#8209;Fi 6E",
    "tr_wifiXX": "Wi&#8209;Fi [XX]",
    "tr_wifiXXCaps": "WI&#8209;FI [XX]",
    "tr_wifi6E": "Intel® Wi&#8209;Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "MEMÓRIA INTEL® OPTANE™",
    "tr_Thunderbolt3": "Tecnologia Thunderbolt™ 3",
    "tr_Thunderbolt4": "Tecnologia Thunderbolt™ 4",
    "tr_processorGraphicsCaps": "GRÁFICOS DO PROCESSADOR",
    "tr_processorGraphicsSmall": "Gráficos do processador",
    "tr_graphicsCardCaps": "GRÁFICOS DEDICADOS",
    "tr_graphicsCardSmall": "Gráficos dedicados",
    "tr_processorTitleCamel": "Processador",
    "tr_graphicTitleCamel": "Gráficos",
    "tr_storageTitleCamel": "Armazenamento",
    "tr_displayTitleCamel": "Ecrã",
    "tr_batteryTitleCamel": "Bateria",
    "tr_memoryTitleCamel": "Memória",
    "tr_connectivityTitleCamel": "Conectividade",
    "tr_priceTitleCamel": "Preço",
    "tr_operatingSystemTitleCamel": "Sistema operativo",
    "tr_viewallspecsCamel": "Ver todas as especificações",
    "tr_displayText": "[screenDiagonal] pol.",
    "tr_displayTextResolution": "[screenResolutionType] de [screenDiagonal] pol.",
    "tr_displayTextResolutionTouchscreen": "Ecrã tátil [screenResolutionType] de [screenDiagonal] pol.",
    "tr_OS": "SO",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "Especificações",
    "tr_explorePC_text": "Explore tudo sobre este PC",
    "tr_mtlPC_text": "Descubra o verdadeiro significado de “concebidos para fazer tudo”",
    "tr_optaneMemory": "Memória Intel®&nbsp;Optane™",
    "tr_displayText_Display": "MONITOR DE [screenDiagonal] POL.",
    "tr_displayTextResolution_Display": "MONITOR [screenResolutionType] DE [screenDiagonal] POL.",
    "tr_displayTextResolutionTouchscreen_Display": "MONITOR COM ECRÃ TÁTIL [screenResolutionType] de [screenDiagonal] POL.",
    "tr_displayTextTouchscreeenText": "MONITOR COM ECRÃ TÁTIL DE [screenDiagonal] POL.",
    "tr_learnMoreLabel": "Saber mais",
    "tr_batteryHoursTextUpperCase": "[XX] HORAS",
    "tr_processorGraphicsIrisXe": "PROCESSADOR INTEL® CORE™ [cpu_fam] DE 11ª GERAÇÃO E GRÁFICOS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax": "PROCESSADOR INTEL® CORE™ [cpu_fam] DE 11ª GERAÇÃO E GRÁFICOS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_Thunderbolt3UpperCase": "TECNOLOGIA THUNDERBOLT™ 3",
    "tr_Thunderbolt4UpperCase": "TECNOLOGIA THUNDERBOLT™ 4",
    "tr_processorWithArcGraphics": "PROCESSADOR INTEL® CORE™ [cpu_fam] DE 12ª GERAÇÃO E GRÁFICOS INTEL® ARC™",
    "tr_processorGraphicsIrisXe12Gen": "PROCESSADOR INTEL® CORE™ [cpu_fam] DE 12ª GERAÇÃO E GRÁFICOS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax12Gen": "PROCESSADOR INTEL® CORE™ [cpu_fam] DE 12ª GERAÇÃO E GRÁFICOS INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "PROCESSADOR INTEL® CORE™ [cpu_fam] DE 13ª GERAÇÃO E GRÁFICOS&nbsp;INTEL®&nbsp;ARC™",
    "tr_processorGraphicsIrisXe13Gen": "PROCESSADOR INTEL® CORE™ [cpu_fam] DE 13ª GERAÇÃO E GRÁFICOS&nbsp;INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax13Gen": "PROCESSADOR INTEL® CORE™ [cpu_fam] DE 13ª GERAÇÃO E GRÁFICOS&nbsp;INTEL®&nbsp;IRIS®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;MAX",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "GRÁFICOS INTEL® ARC™ A350M",
      "tr_A370M": "GRÁFICOS INTEL® ARC™ A370M",
      "tr_A730M": "GRÁFICOS INTEL® ARC™ A730M",
      "tr_A770M": "GRÁFICOS INTEL® ARC™ A770M",
      "tr_A310": "GRÁFICOS INTEL® ARC™ A310",
      "tr_A380": "GRÁFICOS INTEL® ARC™ A380",
      "tr_A580": "GRÁFICOS INTEL® ARC™ A580",
      "tr_A750": "GRÁFICOS INTEL® ARC™ A750",
      "tr_A770": "GRÁFICOS INTEL® ARC™ A770"
    },
    "tr_memoryValue": "[XX] GB de RAM",
    "tr_memoryWithOptaneDesc": "Memória de [Value1]&nbsp;GB = Memória Intel® Optane™ de [Value2]&nbsp;GB + [Value3]&nbsp;GB de RAM",
    "tr_textGBGB": "SSD de [convertedSSD] GB + HDD de [convertedHDD] GB",
    "tr_textGBTB": "SSD de [convertedSSD] GB + HDD de [convertedHDD] TB",
    "tr_textTBGB": "SSD de [convertedSSD] TB + HDD de [convertedHDD] GB",
    "tr_textTBTB": "SSD de [convertedSSD] TB + HDD de [convertedHDD] TB",
    "tr_textGBSSD": "SSD de [convertedSSD]&nbsp;GB",
    "tr_textTBSSD": "SSD de [convertedSSD]&nbsp;TB",
    "tr_textGBHDD": "HDD DE [convertedHDD]&nbsp;GB",
    "tr_textTBHDD": "HDD DE [convertedHDD]&nbsp;TB",
    "tr_textStorageWithOptaneGB": "Memória Intel® Optane™ de [XX]&nbsp;GB + SSD&nbsp;de&nbsp;[convertedSSD]&nbsp;GB",
    "tr_textStorageWithOptaneTB": "Memória Intel® Optane™ de [XX]&nbsp;GB + SSD&nbsp;de&nbsp;[convertedSSD]&nbsp;TB",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home no modo S",
      "tr_OS21": "Windows* 10 Pro no modo S",
      "tr_OS22": "Windows* 10 Enterprise no modo S",
      "tr_OS23": "Windows* 11 Home no modo S",
      "tr_OS24": "Windows* 11 Pro no modo S",
      "tr_OS25": "Windows* 11 Enterprise no modo S"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Gráficos Intel®&nbsp;Arc™ A310",
      "tr_GR387": "Gráficos Intel®&nbsp;Arc™ A350M",
      "tr_GR388": "Gráficos Intel®&nbsp;Arc™ A370M",
      "tr_GR389": "Gráficos Intel®&nbsp;Arc™ A380",
      "tr_GR390": "Gráficos Intel®&nbsp;Arc™ A530M",
      "tr_GR391": "Gráficos Intel®&nbsp;Arc™ A550M",
      "tr_GR392": "Gráficos Intel®&nbsp;Arc™ A570M",
      "tr_GR393": "Gráficos Intel®&nbsp;Arc™ A580",
      "tr_GR394": "Gráficos Intel®&nbsp;Arc™ A730M",
      "tr_GR395": "Gráficos Intel®&nbsp;Arc™ A750",
      "tr_GR396": "Gráficos Intel®&nbsp;Arc™ A770",
      "tr_GR397": "Gráficos Intel®&nbsp;Arc™ A770M",
      "tr_GR398": "Gráficos Intel® Arc™",
      "tr_GR399": "Gráficos Intel®&nbsp;Arc™ Pro A30M",
      "tr_GR400": "Gráficos Intel®&nbsp;Arc™ Pro A40/A50",
      "tr_GR401": "Gráficos Intel®&nbsp;Arc™ Pro A60",
      "tr_GR402": "Gráficos Intel®&nbsp;Arc™ Pro A60M",
      "tr_GR403": "Gráficos Intel®",
      "tr_GR404": "Gráficos HD Intel®&nbsp;2000",
      "tr_GR405": "Gráficos HD Intel®&nbsp;2500",
      "tr_GR406": "Gráficos HD Intel®&nbsp;3000",
      "tr_GR407": "Gráficos HD Intel®&nbsp;400",
      "tr_GR408": "Gráficos HD Intel®&nbsp;4000",
      "tr_GR409": "Gráficos HD Intel®&nbsp;405",
      "tr_GR410": "Gráficos HD Intel®&nbsp;4200",
      "tr_GR411": "Gráficos HD Intel®&nbsp;4400",
      "tr_GR412": "Gráficos HD Intel®&nbsp;4600",
      "tr_GR413": "Gráficos HD Intel®&nbsp;500",
      "tr_GR414": "Gráficos HD Intel®&nbsp;5000",
      "tr_GR415": "Gráficos HD Intel®&nbsp;505",
      "tr_GR416": "Gráficos HD Intel®&nbsp;510",
      "tr_GR417": "Gráficos HD Intel®&nbsp;515",
      "tr_GR418": "Gráficos HD Intel®&nbsp;520",
      "tr_GR419": "Gráficos HD Intel®&nbsp;530",
      "tr_GR420": "Gráficos HD Intel®&nbsp;5300",
      "tr_GR421": "Gráficos HD Intel®&nbsp;5500",
      "tr_GR422": "Gráficos HD Intel®&nbsp;5600",
      "tr_GR423": "Gráficos HD Intel®&nbsp;6000",
      "tr_GR424": "Gráficos HD Intel®&nbsp;610",
      "tr_GR425": "Gráficos HD Intel®&nbsp;615",
      "tr_GR426": "Gráficos HD Intel®&nbsp;620",
      "tr_GR427": "Gráficos HD Intel®&nbsp;630",
      "tr_GR428": "Gráficos HD Intel®",
      "tr_GR429": "Gráficos Intel® Iris® 5100",
      "tr_GR430": "Gráficos Intel® Iris® 540",
      "tr_GR431": "Gráficos Intel® Iris® 550",
      "tr_GR432": "Gráficos Intel® Iris® 6100",
      "tr_GR433": "Gráficos Intel® Iris® Plus 640",
      "tr_GR434": "Gráficos Intel® Iris® Plus 645",
      "tr_GR435": "Gráficos Intel® Iris® Plus 650",
      "tr_GR436": "Gráficos Intel® Iris® Plus 655",
      "tr_GR437": "Gráficos Intel® Iris® Plus",
      "tr_GR438": "Gráficos Intel® Iris® Pro 5200",
      "tr_GR439": "Gráficos Intel® Iris® Pro 580",
      "tr_GR440": "Gráficos Intel® Iris® Pro 6200",
      "tr_GR441": "Gráficos Intel® Iris® X<sup>e</sup>",
      "tr_GR442": "Gráficos Intel® Iris® X<sup>e</sup> MAX A220M",
      "tr_GR443": "Gráficos Intel® Iris® X<sup>e</sup> MAX",
      "tr_GR444": "Gráficos integrados Intel®",
      "tr_GR445": "Gráficos UHD Intel® 600",
      "tr_GR446": "Gráficos UHD Intel® 605",
      "tr_GR447": "Gráficos UHD Intel® 610",
      "tr_GR448": "Gráficos UHD Intel® 615",
      "tr_GR449": "Gráficos UHD Intel® 617",
      "tr_GR450": "Gráficos UHD Intel® 620",
      "tr_GR451": "Gráficos UHD Intel® 630",
      "tr_GR452": "Gráficos UHD Intel® 710",
      "tr_GR453": "Gráficos UHD Intel® 730",
      "tr_GR454": "Gráficos UHD Intel® 770",
      "tr_GR455": "Gráficos UHD Intel®",
      "tr_GR456": "Gráficos UHD Intel®",
      "tr_GR457": "Gráficos UHD Intel®",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 com design Max-Q",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti com design Max-Q",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 com design Max-Q",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 com design Max-Q",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 com design Max-Q",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 com design Max-Q",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti com design Max-Q",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti com design Max-Q",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN preto",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 com design Max-Q",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 com design Max-Q",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super com design Max-Q",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 com design Max-Q",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super com design Max-Q",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 com design Max-Q",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 com design Max-Q",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 com design Max-Q",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 com design Max-Q",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 com design Max-Q",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 com design Max-Q",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 com design Max-Q",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 com design Max-Q",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 com design Max-Q",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 com design Max-Q",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Geração Ada",
      "tr_GR773": "NVIDIA* RTX 3000 Geração Ada",
      "tr_GR774": "NVIDIA* RTX 3500 Geração Ada",
      "tr_GR775": "NVIDIA* RTX 4000 Geração Ada",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Geração Ada",
      "tr_GR777": "NVIDIA* RTX 4500 Geração Ada",
      "tr_GR778": "NVIDIA* RTX 5000 Geração Ada",
      "tr_GR779": "NVIDIA* RTX 5880 Geração Ada",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Geração Ada",
      "tr_GR811": "NVIDIA* RTX 1000 Geração Ada",
      "tr_GR812": "Gráficos Intel® Arc™",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Geração Ada",
      "tr_GR815": "AMD Radeon* PRO W7900 de slot duplo"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Comprar agora",
    "tr_textTryagain": "Tentar novamente",
    "tr_textBuynowErr": "Devido a uma interrupção na ligação, a opção Comprar agora não está disponível",
    "tr_chatbotPlaceholder_text": "Pergunte-me qualquer coisa sobre o dispositivo",
    "tr_deviceSpecs_text": "Especificações do dispositivo"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i3 com Intel®&nbsp;Hybrid Technology"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Processador Intel®&nbsp;Core™&nbsp;i5 com Intel®&nbsp;Hybrid Technology"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Vingadores da Marvel",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Equipa-te com a Intel® para uma experiência de jogos mais intensa",
                "tr_subtitle": "Dá vida aos Vingadores da Marvel* com otimizações para PC durante o jogo e com tecnologia Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Viva o Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Onde competem os melhores jogadores do mundo",
                "tr_subtitle": "A mais longa série de eventos na história dos videojogos, a Intel® Extreme Masters, define os padrões dos jogos de competição.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Jogue como os profissionais",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Onde há Intel, há&nbsp;campeões",
                "tr_subtitle": "Após vencerem o campeonato de 2021 da North American League Rainbow Six Siege*, os Soniqs refletem acerca do seu histórico de jogos, do futuro dos e-sports e da sua parceria com a Intel.",
                "tr_pretitle": "Apresentamos os Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "Preparado para RV",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Desperte a realidade virtual",
                "tr_subtitle": "As experiências de realidade virtual mais incríveis, realistas e envolventes acontecem num computador. Com os mais recentes processadores Intel® Core™, seja quem quiser, vá onde quiser e viva a experiência de jogo numa nova dimensão.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Jogue mais de 100 jogos de consola de alta qualidade, como Sea of Thieves* e Psychonauts* 2 no seu PC Windows*, telemóvel e tablet com o Xbox* Game Pass Ultimate e um comando compatível.",
                "titleArray": [
                  {
                    "tr_title": "Jogue em conjunto nos vários dispositivos",
                    "tr_subtitle": "Toque no coração da Xbox* com uma comunidade de milhões de jogadores prontos e à espera para jogarem em conjunto. Ligue-se e jogue com outras pessoas numa biblioteca partilhada de jogos, quer estejam do outro lado do mundo ou sentadas ao seu lado."
                  },
                  {
                    "tr_title": "Jogos para PC Windows*",
                    "tr_subtitle": "Aceda a um catálogo de jogos de consola a partir da nuvem com a aplicação Xbox* para Windows* e um comando compatível."
                  },
                  {
                    "tr_title": "Escolha e jogue",
                    "tr_subtitle": "Desfrute de jogos para Xbox* fantásticos em mais sítios do que nunca. Jogue mais de 100 jogos no seu PC, telemóvel e tablet, quer esteja em casa quando a sua TV está a ser utilizada, num café ou noutro sítio qualquer com uma ligação à Internet de alta velocidade."
                  },
                  {
                    "tr_title": "Diversão sem comando",
                    "tr_subtitle": "Com os jogos com controlo tátil da Xbox*, conheça uma nova forma jogar numa experiência familiar e suporte ao nível do jogo idêntico ao que está habituado com um comando físico."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Destaque",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Mais de 100 experiências de IA",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Controle mais, toque menos",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Mantenha-se no enquadramento, em qualquer ambiente",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Criatividade",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ajuste a luminosidade como um profissional",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Adicione efeitos incríveis com facilidade",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Remisture músicas com o poder da IA",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produtividade",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Aumente a sua produtividade e privacidade",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Passe mais tempo longe da tomada",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "O seu mundo. Nos seus dispositivos.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Entretenimento",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Streaming suave, mesmo sem o portátil ligado à corrente",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Jogos rápidos e imersivos",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Destaque",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Mais de 100 experiências de IA",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Torne as suas músicas favoritas verdadeiramente suas",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Concretize a sua ideia em tempo real",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Criatividade",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "O caminho mais rápido para a sua edição final",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Torne cada foto perfeita",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produtividade",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Aumente a sua produtividade e privacidade",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Mais eficiência. Mais&nbsp;liberdade.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "O seu mundo. Nos seus dispositivos.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Entretenimento",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Streaming suave em HD, mesmo sem o portátil ligado à corrente",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Jogos imersivos, em mais lugares",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mais criatividade",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Edição de fotos simplificada",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Crie fotos dignas de moldura em segundos",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Basta escrever. E a IA cria.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mais desempenho",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "O segredo para uma jogabilidade mais estável",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Passe mais tempo longe da tomada",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultraimersivo",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mais produtividade",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "A IA que está ao seu lado todos os dias",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Chamada de vídeo perfeita",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "O seu mundo. Nos seus dispositivos.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mais segurança",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Proteções integradas que ajudam a manter os seus dados mais seguros e privados",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Mais criatividade",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Edição de fotos simplificada",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "IA. O atalho para a sua&nbsp;edição&nbsp;final.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Transcreva rapidamente as letras das suas músicas favoritas",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Poupe horas de edição com a IA",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mais desempenho",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "O segredo para uma jogabilidade mais estável",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Passe mais tempo longe da tomada",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultraimersivo",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mais produtividade",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "A IA que está ao seu lado todos os dias",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Chamada de vídeo perfeita",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "O seu mundo. Nos seus dispositivos.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mais segurança",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Proteções integradas que ajudam a manter os seus dados mais seguros e privados",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Todo o seu entretenimento. Um&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Para um PC que se transforma num centro de entretenimento, precisa de armazenamento suficiente para as suas músicas, vídeos e jogos preferidos e da combinação certa de processador e ecrã para criar imagens envolventes. Este PC inclui os três fatores.",
                "titleArray": [
                  {
                    "tr_title": "Pronto para imagens da próxima geração",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Construa a sua biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Uma janela para o seu entretenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Jogos e streaming sem interrupções",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Construa a sua biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Uma janela para o seu entretenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Seja mais produtivo. Em&nbsp;qualquer&nbsp;lugar.",
              "tr_tileTitle1": "Faça mais. Mais rápido.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Este PC tem o equilíbrio perfeito entre potência e portabilidade. Além de um processador concebido para designs finos e leves, este PC dispõe também do tipo de Wi&#8209;Fi que lhe permite manter&#8209;se ligado, e a bateria certa para permanecer desligado da corrente.",
                "tr_subtitle1": "Este PC tem o equilíbrio perfeito entre potência e desempenho, para fazer mais em menos tempo. A combinação de um processador robusto acelerado por uma ampla memória e um Wi&#8209;Fi ultrarrápido vai agilizar as suas tarefas diárias.",
                "tr_subtitle2": "Este PC tem o equilíbrio perfeito entre potência e portabilidade. Além de um processador concebido para designs finos e leves, este PC dispõe também do tipo de Wi&#8209;Fi que lhe permite manter&#8209;se ligado, e a memória certa para executar várias tarefas em&nbsp;simultâneo.",
                "titleArray": [
                  {
                    "tr_title": "Construído para fazer mais",
                    "tr_title1": "Desempenho inteligente que faz a diferença",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "As melhores ligações da sua classe",
                    "tr_title1": "Ligue-se ao que importa",
                    "tr_title2": "Uma grande evolução no Wi&#8209;Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Portabilidade durante mais tempo",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Trabalhe com vários programas como um profissional",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Liberdade para criar",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "A criação de conteúdo é uma das tarefas mais exigentes para um PC. Felizmente, estes componentes têm a potência necessária para agilizar o&nbsp;seu processo criativo.",
                "titleArray": [
                  {
                    "tr_title": "Uma forma mais inteligente de criar",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Dê vida às suas imagens",
                    "tr_title1": "Vamos criar",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memória para executar várias tarefas em simultâneo",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Veja o interior deste&nbsp;PC",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Estes são os três componentes principais a ter em conta quando escolhe um novo PC. Ao decidir como vai utilizar o seu novo PC, já está próximo de decidir o quanto necessita destes três componentes importantes. São&nbsp;eles:",
                "titleArray": [
                  {
                    "tr_title": "Desempenho para jogos e streaming",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Armazenamento",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Memória",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Todo o seu entretenimento. Um&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Para um PC que se transforma num centro de entretenimento, precisa de armazenamento suficiente para as suas músicas, vídeos e jogos preferidos e da combinação certa de processador e ecrã para criar imagens envolventes. Este PC inclui os três fatores.",
                "titleArray": [
                  {
                    "tr_title": "Pronto para imagens da próxima geração",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Construa a sua biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Uma janela para o seu entretenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Jogos e streaming sem interrupções",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Construa a sua biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Uma janela para o seu entretenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Memória",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Ecrã",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Várias tarefas em simultâneo e a alta velocidade",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VÁRIAS TAREFAS EM SIMULTÂNEO E A ALTA VELOCIDADE",
                "tr_subtitle": "Fazer mais sem abrandar",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para executar várias tarefas em simultâneo:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Um processador que consegue gerir vários programas em simultâneo."
                  },
                  {
                    "tr_title": "Wi‑Fi rápido, sólido e fiável."
                  },
                  {
                    "tr_title": "Memória que alterna entre aplicações sem interrupções."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chat por vídeo",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHAT POR VÍDEO",
                "tr_subtitle": "Ligações cristalinas",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para chat por vídeo:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Um processador que consegue gravar e partilhar imagens ao mesmo tempo."
                  },
                  {
                    "tr_title": "Wi‑Fi rápido, sólido e fiável."
                  },
                  {
                    "tr_title": "Uma webcam que ajuda a emitir uma imagem nítida."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Preparado para RV",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Desperte a realidade virtual",
                "tr_subtitle": "O que é necessário para criar um mundo completamente virtual? Num PC, vai precisar da coordenação entre o processador, a placa gráfica e a memória.",
                "titleArray": [
                  {
                    "tr_title": "A impulsionar mundos virtuais",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "A realidade está nos detalhes",
                    "tr_title1": "Envolva-se",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Edição de vídeo",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDIÇÃO DE VÍDEO",
                "tr_subtitle": "Crie filmes mágicos",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para edição de vídeo:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Um processador que consegue gerir ficheiros grandes."
                  },
                  {
                    "tr_title": "Memória que torna a edição de software fácil e de resposta rápida."
                  },
                  {
                    "tr_title": "Gráficos que reduzem os tempos de renderização."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Várias tarefas em simultâneo e a alta velocidade",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VÁRIAS TAREFAS EM SIMULTÂNEO E A ALTA VELOCIDADE",
                "tr_subtitle": "Fazer mais sem abrandar",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para executar várias tarefas em simultâneo:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Um processador que consegue gerir vários programas em simultâneo."
                  },
                  {
                    "tr_title": "Wi‑Fi rápido, sólido e fiável."
                  },
                  {
                    "tr_title": "Memória que alterna entre aplicações sem interrupções."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Preparado para RV",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Desperte a realidade virtual",
                "tr_subtitle": "O que é necessário para criar um mundo completamente virtual? Num PC, vai precisar da coordenação entre o processador, a placa gráfica e a memória.",
                "titleArray": [
                  {
                    "tr_title": "A impulsionar mundos virtuais",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "A realidade está nos detalhes",
                    "tr_title1": "Envolva-se",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Modelagem 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MODELAGEM 3D",
                "tr_subtitle": "Criações de nível superior",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para modelagem 3D:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Um processador que consegue gerir software criativo avançado."
                  },
                  {
                    "tr_title": "Memória que consegue abrir rapidamente ficheiros grandes."
                  },
                  {
                    "tr_title": "Gráficos para criação e edição de imagens poderosas."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Colaboração em tempo real",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLABORAÇÃO EM TEMPO REAL",
                "tr_subtitle": "Trabalhe em qualquer lugar",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para colaborações online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Um processador para potenciar software de escritório."
                  },
                  {
                    "tr_title": "Wi‑Fi em que pode confiar."
                  },
                  {
                    "tr_title": "Uma webcam que grava em HD brilhante."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Preparado para RV",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Desperte a realidade virtual",
                "tr_subtitle": "O que é necessário para criar um mundo completamente virtual? Num PC, vai precisar da coordenação entre o processador, a placa gráfica e a memória.",
                "titleArray": [
                  {
                    "tr_title": "A impulsionar mundos virtuais",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "A realidade está nos detalhes",
                    "tr_title1": "Envolva-se",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Portáteis Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Não é suposto os portáteis tão finos e leves serem tão poderosos",
                "tr_subtitle": "Concebidos para desempenho móvel, os portáteis Intel® Evo™ têm toda a velocidade, capacidade gráfica e autonomia da bateria de que necessita nos designs finos e leves mais incríveis.",
                "titleArray": [
                  {
                    "tr_title": "Os portáteis são hoje mais importantes do que nunca, pelo que nos certificámos de que podem fazer mais e melhor.",
                    "tr_subtitle": "Utilize o seu ecrã tátil para explorar, rodar e ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Reativação imediata",
                    "tr_subtitle": "O seu PC consegue passar da suspensão&nbsp;à&nbsp;reativação em menos&nbsp;de&nbsp;um&nbsp;segundo."
                  },
                  {
                    "tr_title": "Visualização envolvente",
                    "tr_subtitle": "Um belíssimo ecrã dá vida às suas imagens numa&nbsp;resolução nítida e com cores vivas."
                  },
                  {
                    "tr_title": "Execute tarefas rapidamente",
                    "tr_subtitle": "Desfrute de um desempenho com excelente capacidade de resposta com os processadores Intel® Core™ de 11ª geração."
                  },
                  {
                    "tr_title": "Portabilidade durante mais tempo",
                    "tr_subtitle": "Mantenha o ritmo com um PC que otimiza&nbsp;a&nbsp;autonomia da bateria e&nbsp;recarrega&nbsp;num instante."
                  },
                  {
                    "tr_title": "Ligações por cabo universais",
                    "tr_subtitle": "Torna a ligação de outros dispositivos ao seu PC simples, rápida e segura com a tecnologia Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Ligações rápidas e fiáveis",
                    "tr_subtitle": "Converse por videochamada ou partilhe ficheiros com uma ligação com a qual pode contar ao utilizar o Intel® Wi&#8209;Fi 6 (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Portáteis Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tudo de que precisa e muito mais num portátil fino e leve.",
                "tr_subtitle": "Os portáteis Intel® Evo™ foram concebidos para desempenho móvel e para lhe proporcionar a melhor experiência<sup>2</sup>, independentemente do que estiver a fazer.",
                "titleArray": [
                  {
                    "tr_title": "Os portáteis são hoje mais importantes do que nunca, pelo que nos certificámos de que podem fazer mais e melhor.",
                    "tr_subtitle": "Utilize o seu ecrã tátil para explorar, rodar e ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Reativação imediata",
                    "tr_subtitle": "O seu PC consegue passar da suspensão&nbsp;à&nbsp;reativação em menos&nbsp;de&nbsp;um&nbsp;segundo."
                  },
                  {
                    "tr_title": "Visualização envolvente",
                    "tr_subtitle": "Um belíssimo ecrã dá vida às suas imagens numa&nbsp;resolução nítida e com cores vivas."
                  },
                  {
                    "tr_title": "Desempenho revolucionário",
                    "tr_subtitle": "Consiga a potência extra onde mais precisa com o desempenho inteligente dos processadores Intel® Core™ de 12ª geração."
                  },
                  {
                    "tr_title": "Portabilidade durante mais tempo",
                    "tr_subtitle": "Mantenha o ritmo com um PC que otimiza&nbsp;a&nbsp;autonomia da bateria e&nbsp;recarrega&nbsp;num instante."
                  },
                  {
                    "tr_title": "Ligações por cabo universais",
                    "tr_subtitle": "Torna a ligação de outros dispositivos ao seu PC simples, rápida e segura com a tecnologia Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Ligações rápidas e fiáveis",
                    "tr_subtitle": "Converse por videochamada ou partilhe ficheiros com uma ligação com a qual pode contar ao utilizar o Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Portáteis Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Não é suposto os portáteis tão finos e leves serem tão poderosos",
                "tr_subtitle": "Concebidos para desempenho móvel, os portáteis Intel® Evo™ têm toda a velocidade, capacidade gráfica e autonomia da bateria de que necessita nos designs finos e leves mais incríveis.",
                "titleArray": [
                  {
                    "tr_title": "Os portáteis são hoje mais importantes do que nunca, pelo que nos certificámos de que podem fazer mais e melhor.",
                    "tr_subtitle": "Utilize o seu ecrã tátil para explorar, rodar e ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Reativação imediata",
                    "tr_subtitle": "O seu PC consegue passar da suspensão&nbsp;à&nbsp;reativação em menos&nbsp;de&nbsp;um&nbsp;segundo."
                  },
                  {
                    "tr_title": "Visualização envolvente",
                    "tr_subtitle": "Um belíssimo ecrã dá vida às suas imagens numa&nbsp;resolução nítida e com cores vivas."
                  },
                  {
                    "tr_title": "Execute tarefas rapidamente",
                    "tr_subtitle": "Desfrute de um desempenho com excelente capacidade de resposta com os processadores Intel® Core™ de 11ª geração."
                  },
                  {
                    "tr_title": "Portabilidade durante mais tempo",
                    "tr_subtitle": "Mantenha o ritmo com um PC que otimiza&nbsp;a&nbsp;autonomia da bateria e&nbsp;recarrega&nbsp;num instante."
                  },
                  {
                    "tr_title": "Ligações por cabo universais",
                    "tr_subtitle": "Torna a ligação de outros dispositivos ao seu PC simples, rápida e segura com a tecnologia Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Ligações rápidas e fiáveis",
                    "tr_subtitle": "Converse por videochamada ou partilhe ficheiros com uma ligação com a qual pode contar ao utilizar o Intel® Wi&#8209;Fi 6 (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Portáteis Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tudo de que precisa e muito mais num portátil fino e leve.",
                "tr_subtitle": "Os portáteis Intel® Evo™ foram concebidos para desempenho móvel e para lhe proporcionar a melhor experiência<sup>2</sup>, independentemente do que estiver a fazer.",
                "titleArray": [
                  {
                    "tr_title": "Os portáteis são hoje mais importantes do que nunca, pelo que nos certificámos de que podem fazer mais e melhor.",
                    "tr_subtitle": "Utilize o seu ecrã tátil para explorar, rodar e ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Reativação imediata",
                    "tr_subtitle": "O seu PC consegue passar da suspensão&nbsp;à&nbsp;reativação em menos&nbsp;de&nbsp;um&nbsp;segundo."
                  },
                  {
                    "tr_title": "Visualização envolvente",
                    "tr_subtitle": "Um belíssimo ecrã dá vida às suas imagens numa&nbsp;resolução nítida e com cores vivas."
                  },
                  {
                    "tr_title": "Desempenho revolucionário",
                    "tr_subtitle": "Consiga a potência extra onde mais precisa com o desempenho inteligente dos processadores Intel® Core™ de 12ª geração."
                  },
                  {
                    "tr_title": "Portabilidade durante mais tempo",
                    "tr_subtitle": "Mantenha o ritmo com um PC que otimiza&nbsp;a&nbsp;autonomia da bateria e&nbsp;recarrega&nbsp;num instante."
                  },
                  {
                    "tr_title": "Ligações por cabo universais",
                    "tr_subtitle": "Torna a ligação de outros dispositivos ao seu PC simples, rápida e segura com a tecnologia Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Ligações rápidas e fiáveis",
                    "tr_subtitle": "Converse por videochamada ou partilhe ficheiros com uma ligação com a qual pode contar ao utilizar o Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Portáteis Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tudo de que precisa e muito mais num portátil fino e leve.",
                "tr_subtitle": "Os portáteis Intel® Evo™ foram concebidos para desempenho móvel e para lhe proporcionar a melhor experiência<sup>2</sup>, independentemente do que estiver a fazer.",
                "titleArray": [
                  {
                    "tr_title": "Os portáteis são hoje mais importantes do que nunca, pelo que nos certificámos de que podem fazer mais e melhor.",
                    "tr_subtitle": "Utilize o seu ecrã tátil para explorar, rodar e ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Reativação imediata",
                    "tr_subtitle": "O seu PC consegue passar da suspensão&nbsp;à&nbsp;reativação em menos&nbsp;de&nbsp;um&nbsp;segundo."
                  },
                  {
                    "tr_title": "Visualização envolvente",
                    "tr_subtitle": "Um belíssimo ecrã dá vida às suas imagens numa&nbsp;resolução nítida e com cores vivas."
                  },
                  {
                    "tr_title": "Desempenho revolucionário",
                    "tr_subtitle": "Consiga a potência extra onde mais precisa com o desempenho inteligente dos processadores Intel® Core™ de 13ª geração."
                  },
                  {
                    "tr_title": "Portabilidade durante mais tempo",
                    "tr_subtitle": "Mantenha o ritmo com um PC que otimiza&nbsp;a&nbsp;autonomia da bateria e&nbsp;recarrega&nbsp;num instante."
                  },
                  {
                    "tr_title": "Ligações por cabo universais",
                    "tr_subtitle": "Torna a ligação de outros dispositivos ao seu PC simples, rápida e segura com a tecnologia Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Ligações rápidas e fiáveis",
                    "tr_subtitle": "Converse por videochamada ou partilhe ficheiros com uma ligação com a qual pode contar ao utilizar o Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Portáteis Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Tudo de que precisa e muito mais num portátil fino e leve.",
                "tr_subtitle": "Os portáteis Intel® Evo™ foram concebidos para desempenho móvel e para lhe proporcionar a melhor experiência<sup>2</sup>, independentemente do que estiver a fazer.",
                "titleArray": [
                  {
                    "tr_title": "Os portáteis são hoje mais importantes do que nunca, pelo que nos certificámos de que podem fazer mais e melhor.",
                    "tr_subtitle": "Utilize o seu ecrã tátil para explorar, rodar e ampliar."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Reativação imediata",
                    "tr_subtitle": "O seu PC consegue passar da suspensão&nbsp;à&nbsp;reativação em menos&nbsp;de&nbsp;um&nbsp;segundo."
                  },
                  {
                    "tr_title": "Visualização envolvente",
                    "tr_subtitle": "Um belíssimo ecrã dá vida às suas imagens numa&nbsp;resolução nítida e com cores vivas."
                  },
                  {
                    "tr_title": "Desempenho revolucionário",
                    "tr_subtitle": "Consiga a potência extra onde mais precisa com o desempenho inteligente dos processadores Intel® Core™ de 13ª geração."
                  },
                  {
                    "tr_title": "Portabilidade durante mais tempo",
                    "tr_subtitle": "Mantenha o ritmo com um PC que otimiza&nbsp;a&nbsp;autonomia da bateria e&nbsp;recarrega&nbsp;num instante."
                  },
                  {
                    "tr_title": "Ligações por cabo universais",
                    "tr_subtitle": "Torna a ligação de outros dispositivos ao seu PC simples, rápida e segura com a tecnologia Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Ligações rápidas e fiáveis",
                    "tr_subtitle": "Converse por videochamada ou partilhe ficheiros com uma ligação com a qual pode contar ao utilizar o Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Destaque",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "O que é a Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Porquê a Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Criatividade",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Desperte a criatividade em mais lugares",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Edição de fotografias a alta velocidade",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Crie arte original onde estiver",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produtividade",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximize a produtividade com a ferramenta Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Utilize a IA para detetar conteúdos falsos antes de os partilhar",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "O seu mundo. Nos seus dispositivos.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilidade",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Acene para o controlo por gestos",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Autonomia da bateria à altura das suas tarefas",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Mantém automaticamente a ligação",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Destaque",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "O que é a Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Porquê a Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Criatividade",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Reprodução de vídeo rápida e fluida",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Produza verdadeiros êxitos com o poder da IA",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "O caminho mais rápido para a sua edição final",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Produtividade",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximize a produtividade com a ferramenta Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Utilize a IA para detetar conteúdos falsos antes de os partilhar",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "O seu mundo. Nos seus dispositivos.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobilidade",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Colabore onde quer que esteja",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Autonomia da bateria à altura das suas tarefas",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Mantém automaticamente a ligação",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Todo o seu entretenimento. Um&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Para um PC que se transforma num centro de entretenimento, precisa de armazenamento suficiente para as suas músicas, vídeos e jogos preferidos e da combinação certa de processador e ecrã para criar imagens envolventes. Este PC inclui os três fatores.",
                "titleArray": [
                  {
                    "tr_title": "Jogos e streaming impressionantes",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Construa a sua biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Uma janela para o seu entretenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Jogos e streaming sem interrupções",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Construa a sua biblioteca digital",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Uma janela para o seu entretenimento",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Seja mais produtivo. Em&nbsp;qualquer&nbsp;lugar.",
              "tr_tileTitle1": "Faça mais. Mais rápido.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Este PC tem o equilíbrio perfeito entre potência e portabilidade. Além de um processador concebido para designs finos e leves, este PC dispõe também do tipo de Wi&#8209;Fi que lhe permite manter&#8209;se ligado, e a bateria certa para permanecer desligado da corrente.",
                "tr_subtitle1": "Este PC tem o equilíbrio perfeito entre potência e desempenho, para fazer mais em menos tempo. A combinação de um processador robusto acelerado por uma ampla memória e um Wi&#8209;Fi ultrarrápido vai agilizar as suas tarefas diárias.",
                "tr_subtitle2": "Este PC tem o equilíbrio perfeito entre potência e portabilidade. Além de um processador concebido para designs finos e leves, este PC dispõe também do tipo de Wi&#8209;Fi que lhe permite manter&#8209;se ligado, e a memória certa para executar várias tarefas em&nbsp;simultâneo.",
                "titleArray": [
                  {
                    "tr_title": "Desempenho inteligente que faz a diferença",
                    "tr_title1": "Acelere com o desempenho inteligente",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "As melhores ligações da sua classe",
                    "tr_title1": "Ligue-se ao que importa",
                    "tr_title2": "Uma grande evolução no Wi&#8209;Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Portabilidade durante mais tempo",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Trabalhe com vários programas como um profissional",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Liberdade para criar",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "A criação de conteúdo é uma das tarefas mais exigentes para um PC. Felizmente, estes componentes têm a potência necessária para agilizar o&nbsp;seu processo criativo.",
                "titleArray": [
                  {
                    "tr_title": "Criatividade acelerada",
                    "tr_title1": "Dê asas aos seus projetos&nbsp;criativos",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Criação superior",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memória para executar várias tarefas em simultâneo",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Veja o interior deste&nbsp;PC",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Estes são os três componentes principais a ter em conta quando escolhe um novo PC. Ao decidir como vai utilizar o seu novo PC, já está próximo de decidir o quanto necessita destes três componentes importantes. São&nbsp;eles:",
                "titleArray": [
                  {
                    "tr_title": "Processador",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Armazenamento",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Memória",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Liberdade para criar",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "A criação de conteúdo é uma das tarefas mais exigentes para um PC. Felizmente, estes componentes têm a potência necessária para agilizar o&nbsp;seu processo criativo.",
                "titleArray": [
                  {
                    "tr_title": "Uma forma mais inteligente de criar",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Dê vida às suas imagens",
                    "tr_title1": "Vamos criar",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Memória para executar várias tarefas em simultâneo",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Seja mais produtivo. Em&nbsp;qualquer&nbsp;lugar.",
              "tr_tileTitle1": "Faça mais. Mais rápido.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Este PC tem o equilíbrio perfeito entre potência e portabilidade. Além de um processador concebido para designs finos e leves, este PC dispõe também do tipo de Wi&#8209;Fi que lhe permite manter&#8209;se ligado, e a bateria certa para permanecer desligado da corrente.",
                "tr_subtitle1": "Este PC tem o equilíbrio perfeito entre potência e desempenho, para fazer mais em menos tempo. A combinação de um processador robusto acelerado por uma ampla memória e um Wi&#8209;Fi ultrarrápido vai agilizar as suas tarefas diárias.",
                "tr_subtitle2": "Este PC tem o equilíbrio perfeito entre potência e portabilidade. Além de um processador concebido para designs finos e leves, este PC dispõe também do tipo de Wi&#8209;Fi que lhe permite manter&#8209;se ligado, e a memória certa para executar várias tarefas em&nbsp;simultâneo.",
                "titleArray": [
                  {
                    "tr_title": "Construído para fazer mais",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "As melhores ligações da sua classe",
                    "tr_title1": "Ligue-se ao que importa",
                    "tr_title2": "Uma grande evolução no Wi&#8209;Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Portabilidade durante mais tempo",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Trabalhe com vários programas como um profissional",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Preparado para RV",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Desperte a realidade virtual",
                "tr_subtitle": "O que é necessário para criar um mundo completamente virtual? Num PC, vai precisar da coordenação entre o processador, a placa gráfica e a memória.",
                "titleArray": [
                  {
                    "tr_title": "A impulsionar mundos virtuais",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "A realidade está nos detalhes",
                    "tr_title1": "Envolva-se",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>um regalo para os olhos",
      "tr_sub_header": "A luz azul pode causar desconforto visual e interromper o seu ciclo do sono. Sem alterar as cores, o ecrã OLED da Samsung com proteção ocular (Eye Care), testada pela SGS, ajuda através da redução do comprimento de onda da luz azul potencialmente prejudicial, o que traz um maior conforto aos olhos e reduz a fadiga ocular.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Luz azul menos prejudicial"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra em todos os sentidos",
      "tr_sub_header": "A ultraluz e o ultradesign OLED maximizam a qualidade da imagem e minimizam o peso."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Verdadeiro preto",
      "tr_sub_header": "0,0005&nbsp;nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74%<br/><span class='conventional_text'>convencional</span>",
      "tr_sub_header": "120%<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Streaming",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STREAMING",
                "tr_subtitle": "Entretenimento sem fim",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para fazer streaming:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Um processador que consegue fazer streaming em HD."
                  },
                  {
                    "tr_title": "Um ecrã para imagens vivas e bonitas."
                  },
                  {
                    "tr_title": "Wi‑Fi com menos tempo de colocação na memória intermédia e carregamento."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Navegação na Web",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "NAVEGAÇÃO NA WEB",
                "tr_subtitle": "A Internet facilitada",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para navegar na web:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Um processador que consegue carregar rapidamente páginas com muito conteúdo multimédia."
                  },
                  {
                    "tr_title": "Wi‑Fi rápido e fiável."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chat por vídeo",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHAT POR VÍDEO",
                "tr_subtitle": "Ligações cristalinas",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para chat por vídeo:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Um processador que consegue gravar e partilhar imagens ao mesmo tempo."
                  },
                  {
                    "tr_title": "Wi‑Fi rápido, sólido e fiável."
                  },
                  {
                    "tr_title": "Uma webcam que ajuda a emitir uma imagem nítida."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Várias tarefas em simultâneo e a alta velocidade",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VÁRIAS TAREFAS EM SIMULTÂNEO E A ALTA VELOCIDADE",
                "tr_subtitle": "Fazer mais sem abrandar",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para executar várias tarefas em simultâneo:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Um processador que consegue gerir vários programas em simultâneo."
                  },
                  {
                    "tr_title": "Wi‑Fi rápido, sólido e fiável."
                  },
                  {
                    "tr_title": "Memória que alterna entre aplicações sem interrupções."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Chat por vídeo",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CHAT POR VÍDEO",
                "tr_subtitle": "Ligações cristalinas",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para chat por vídeo:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Um processador que consegue gravar e partilhar imagens ao mesmo tempo."
                  },
                  {
                    "tr_title": "Wi‑Fi rápido, sólido e fiável."
                  },
                  {
                    "tr_title": "Uma webcam que ajuda a emitir uma imagem nítida."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Jogos casuais",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "JOGOS CASUAIS",
                "tr_subtitle": "Hora de jogar",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para jogos leves:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Um processador que potencia uma experiência de jogo tranquila."
                  },
                  {
                    "tr_title": "Memória que consegue lidar com chat por voz e streaming em simultâneo."
                  },
                  {
                    "tr_title": "Gráficos que potenciam imagens incríveis."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Edição de fotos",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDIÇÃO DE FOTOS",
                "tr_subtitle": "Fotos perfeitas e nítidas",
                "tr_subtitle1": "Aqui está o que encontrámos neste PC que é ótimo para edição de fotografia:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Um processador com potência para editar grandes grupos de imagens."
                  },
                  {
                    "tr_title": "Memória que acelera o seu software de edição."
                  },
                  {
                    "tr_title": "A tecnologia Thunderbolt™ 4 permite transferir ficheiros rapidamente."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Várias tarefas em simultâneo e a alta velocidade",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VÁRIAS TAREFAS EM SIMULTÂNEO E A ALTA VELOCIDADE",
                "tr_subtitle": "Fazer mais sem abrandar",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para executar várias tarefas em simultâneo:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Um processador que consegue gerir vários programas em simultâneo."
                  },
                  {
                    "tr_title": "Wi‑Fi rápido, sólido e fiável."
                  },
                  {
                    "tr_title": "Memória que alterna entre aplicações sem interrupções."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Colaboração em tempo real",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLABORAÇÃO EM TEMPO REAL",
                "tr_subtitle": "Trabalhe em qualquer lugar",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para colaborações online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Um processador para potenciar software de escritório."
                  },
                  {
                    "tr_title": "Wi‑Fi em que pode confiar."
                  },
                  {
                    "tr_title": "Uma webcam que grava em HD brilhante."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Edição de vídeo",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDIÇÃO DE VÍDEO",
                "tr_subtitle": "Crie filmes mágicos",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para edição de vídeo:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Um processador que consegue gerir ficheiros grandes."
                  },
                  {
                    "tr_title": "Memória que torna a edição de software fácil e de resposta rápida."
                  },
                  {
                    "tr_title": "Gráficos que reduzem os tempos de renderização."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Edição de fotos",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDIÇÃO DE FOTOS",
                "tr_subtitle": "Fotos perfeitas e nítidas",
                "tr_subtitle1": "Aqui está o que encontrámos neste PC que é ótimo para edição de fotografia:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Um processador com potência para editar grandes grupos de imagens."
                  },
                  {
                    "tr_title": "Memória que acelera o seu software de edição."
                  },
                  {
                    "tr_title": "A tecnologia Thunderbolt™ 4 permite transferir ficheiros rapidamente."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Edição de vídeo",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "EDIÇÃO DE VÍDEO",
                "tr_subtitle": "Crie filmes mágicos",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para edição de vídeo:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Um processador que consegue gerir ficheiros grandes."
                  },
                  {
                    "tr_title": "Memória que torna a edição de software fácil e de resposta rápida."
                  },
                  {
                    "tr_title": "Gráficos que reduzem os tempos de renderização."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Modelagem 3D",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "MODELAGEM 3D",
                "tr_subtitle": "Criações de nível superior",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para modelagem 3D:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Um processador que consegue gerir software criativo avançado."
                  },
                  {
                    "tr_title": "Memória que consegue abrir rapidamente ficheiros grandes."
                  },
                  {
                    "tr_title": "Gráficos para criação e edição de imagens poderosas."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Colaboração em tempo real",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "COLABORAÇÃO EM TEMPO REAL",
                "tr_subtitle": "Trabalhe em qualquer lugar",
                "tr_subtitle1": "Aqui fica o que encontrámos neste PC que é ótimo para colaborações online:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Um processador para potenciar software de escritório."
                  },
                  {
                    "tr_title": "Wi‑Fi em que pode confiar."
                  },
                  {
                    "tr_title": "Uma webcam que grava em HD brilhante."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Aplicação Intel® Unison™",
    "tr_title": "Desbloqueie um mundo de ligações",
    "tr_subtitle": "Transfira ficheiros e fotos com facilidade enquanto gere as notificações, chamadas e&nbsp;mensagens de texto do telefone a&nbsp;partir&nbsp;do&nbsp;PC.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "TRANSFIRA FICHEIROS E FOTOS",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "FAÇA E RECEBA CHAMADAS",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "ENVIE E RECEBA MENSAGENS DE TEXTO",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "FAÇA A GESTÃO DAS NOTIFICAÇÕES DO TELEFONE",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "DESBLOQUEIE UM MUNDO DE LIGAÇÕES",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}