const Library_ChromeBook_mobile_ko = {
  "uiText": {
    "tr_endlessProgramsAndApps": "무한히 많은 프로그램 및 앱",
    "tr_rethinkStorage": "혁신적인 스토리지",
    "tr_alwaysOnSecurity": "보안 기능 내장",
    "tr_extendedBatteryLife": "긴 배터리 수명<sup>1</sup>",
    "tr_viewMore": "더 보기",
    "tr_processorTitle": "프로세서",
    "tr_processorTitleCamel": "프로세서",
    "tr_rethinkStorageTitle": "혁신적인 스토리지",
    "tr_alwaysOnSecurityTitle": "보안 기능 내장",
    "tr_extendedBatteryLifeTitle": "긴 배터리 수명<sup>1</sup>",
    "tr_endlessProgramsAndAppsTitle": "무한히 많은 프로그램 및 앱",
    "tr_extendedBatteryLifeDescription": "[XX]시간",
    "tr_rethinkStorageDescriptionGB": "[XX]GB",
    "tr_rethinkStorageDescriptionTB": "[XX]TB",
    "tr_OS": "OS",
    "tr_Chrome_OS": "ChromeOS*",
    "tr_textGBGB": "[convertedSSD]GB&nbsp;SSD&nbsp;+ [convertedHDD]GB&nbsp;HDD",
    "tr_textGBTB": "[convertedSSD]GB&nbsp;SSD&nbsp;+ [convertedHDD]TB&nbsp;HDD",
    "tr_textTBGB": "[convertedSSD]TB&nbsp;SSD&nbsp;+ [convertedHDD]GB&nbsp;HDD",
    "tr_textTBTB": "[convertedSSD]TB&nbsp;SSD&nbsp;+ [convertedHDD]TB&nbsp;HDD",
    "tr_textGBSSD": "[convertedSSD]GB SSD",
    "tr_textTBSSD": "[convertedSSD]TB SSD",
    "tr_textGBHDD": "[convertedHDD]GB HDD",
    "tr_textTBHDD": "[convertedHDD]TB HDD",
    "tr_graphicTitle": "그래픽",
    "tr_storageTitle": "스토리지",
    "tr_displayTitle": "디스플레이",
    "tr_batteryTitle": "배터리",
    "tr_memoryTitle": "메모리",
    "tr_priceTitle": "가격"
  },
  "rethinkStorage": {
    "details": {
      "tr_tileTitle": "혁신적인 스토리지",
      "tr_title": "혁신적인 저장 방식",
      "tr_subtitle": "Chromebook*은 PC의 스토리지와 클라우드에 있는 Google* 드﻿라﻿이﻿브﻿의 거의 무한대 용량을 결합합니다. 이점은 다음과 같습﻿니﻿다.",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/cloud_upload.svg",
          "tr_badgeSubTitle": "모든 파일이 클라우드로 자동 백업되어 Google* 계정에 로&#65279;그&#65279;인&#65279;하면 &#65279;손쉽게 복구할 수 있습니&#65279;다. ",
          "badgeSubTitle": "cloud-upload"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "[XX]GB의 장치 스토리지로 온라인 및 오프라인 작업 모두 가능.",
          "badgeSubTitle": "GBALONE"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "[XX]TB 장치 스토리지로 온라인 및 오프라인 작업 모두 가능.",
          "badgeSubTitle": "TBALONE"
        }
      ]
    }
  },
  "alwaysOnSecurity": {
    "details": {
      "tr_tileTitle": "보안 기능 내장",
      "tr_title": "간편한 보호<sup class='sup-text'>2</sup>",
      "tr_subtitle": "Chromebook*은 모든 보안 기능을 자동으로 업데이트하므로 더&nbsp;강&#65279;력&#65279;하&#65279;게 보호되는 기기에서 안심하고 웹 서핑을&nbsp;즐&#65279;기&#65279;고 링&#65279;크&#65279;를&nbsp;클릭할 수 있습니다."
    }
  },
  "extendedBatteryLife": {
    "tr_tileTitle": "긴 배터리 수명<sup>1</sup>",
    "iposTypes": [
      {
        "iposType": "CHROMEBOOK",
        "details": {
          "tr_title": "더 많이 사용하고, 더&nbsp;적&#65279;게&nbsp;충&#65279;전<sup class='sup-text'>1</sup>",
          "tr_subtitle": "유연하고 에너지 효율적으로 설계된 Chromebook*은 전&#65279;원 연결 없&#65279;이&#65279;도 더&nbsp;오&#65279;래 사용할 수 있습니다."
        }
      },
      {
        "iposType": "CHROMEBOOKEVO",
        "details": {
          "tr_title": "더 오래가는 배터리. <br/>더 빠른 충전.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Chromebook*의 간소하고 에너지 효율적인 설계와 인텔® Evo™ 플랫폼의 까다로운 배터리 요구 사항이 만나 더 빠른 충전과 오래가는 배터리 수명을 제공합니다."
        }
      },
      {
        "iposType": "CHROMEBOOKEVOGEN12",
        "details": {
          "tr_title": "긴 배터리 수명으로 이동성 유지<sup class='sup-text'>10</sup>",
          "tr_subtitle": "얇고 가벼운 Chromebook*에는 고속 충전이 가능하고 지속 시간이 긴 배터리가 있어 콘센트를 찾아 헤맬 필요가 없습니다."
        }
      }
    ]
  },
  "endlessProgramsAndApps": {
    "details": {
      "tr_tileTitle": "무한히 많은 프로그램 및 앱",
      "tr_title": "매일 더 늘어나는 앱",
      "tr_subtitle": "매일 늘어나는 앱과 프로그램으로 Chromebook* 사용자는 더&nbsp;다&#65279;양&#65279;한 옵션을 즐길 수 있습니다.",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/download_badge.svg",
          "tr_badgeSubTitle": "Chrome* 및 Android* 앱을 다운로드합니다."
        },
        {
          "badgeUrl": "assets/images/badges/reset_badge.svg",
          "tr_badgeSubTitle": "모든 앱과 데이터는 Google* 계정을 통해 동기화됩니다."
        },
        {
          "badgeUrl": "assets/images/badges/connect_badge.svg",
          "tr_badgeSubTitle": "무한한 방법으로 연결하고, 학습하고, 만들고, 플레이합니다."
        }
      ]
    }
  },
  "processor": [
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "기대 이상을 경험해보세요",
                "tr_subtitle": "10세대 인텔® 코어™ i3 프로세서 기반 Chromebook*으로 좋아하는 TV 프로그램과 영화를 손쉽게 시청하세요.",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i3 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "어디서나 뛰어난&nbsp;성능을 제공하도록&nbsp;특별히 설계되었습니다.",
                "tr_subtitle": "",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "10세대 인텔® 코어™ i5 프로세서 기반"
                  },
                  {
                    "tr_badgeTitle": "전원 분리 시에도 강력한 성능 유지"
                  },
                  {
                    "tr_badgeTitle": "빠른 충전: 30분 충전으로 4시간의 배터리 수명 이용 가능<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "인텔® Wi-Fi 6(Gig+)는 동급 최강의 연결성을 제공합니다<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "진정한 업무. 진정한 엔터테인먼트.",
                "tr_subtitle": "10세대 인텔® 코어™ i5 프로세서 기반 Chromebook*에서 화상 채팅과 이메일 및 프레젠테이션 작업을 모두 수행하면서 생산성을 높일 수 있습니다.",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "어디서나 뛰어난&nbsp;성능을 제공하도록&nbsp;특별히 설계되었습니다.",
                "tr_subtitle": "",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "10세대 인텔® 코어™ i7 프로세서 기반"
                  },
                  {
                    "tr_badgeTitle": "전원 분리 시에도 강력한 성능 유지"
                  },
                  {
                    "tr_badgeTitle": "빠른 충전: 30분 충전으로 4시간의 배터리 수명 이용 가능<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "인텔® Wi-Fi 6(Gig+)는 동급 최강의 연결성을 제공합니다<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "성능은 중요합니다",
                "tr_subtitle": "10세대 인텔® 코어™ i7 프로세서 기반 Chromebook*에서 손쉽게 비디오를 편집하고 즉시 업로드하여 추억을 공유하세요.",
                "tr_gen_core_title": "10세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "빠르고 반응성이 뛰어난 성능",
                "tr_subtitle": "",
                "tr_gen_core_title": "8세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i3 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "열정과 생산성 지원",
                "tr_subtitle": "",
                "tr_gen_core_title": "8세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "열정과 생산성 지원",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ Ultra 5 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "열정과 생산성 지원",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ Ultra 7 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "열정과 생산성 지원",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ Ultra 9 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "빠르고 반응성이 뛰어난 성능",
                "tr_subtitle": "",
                "tr_gen_core_title": "8세대 인텔® 코어™ m3 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "현재 작업에 필요한 우수한 성능",
                "tr_subtitle": "",
                "tr_gen_core_title": "7세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "현재 작업에 필요한 프리미엄 성능",
                "tr_subtitle": "",
                "tr_gen_core_title": "7세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "현재 작업에 필요한 성능",
                "tr_subtitle": "",
                "tr_gen_core_title": "7세대 인텔® 코어™ m3 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "작업에 필요한 뛰어난 성능",
                "tr_subtitle": "",
                "tr_gen_core_title": "6세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "작업에 필요한 고급 성능",
                "tr_subtitle": "",
                "tr_gen_core_title": "6세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "나에게 필요한 성능",
                "tr_subtitle": "",
                "tr_gen_core_title": "6세대 인텔® 코어™ m3 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Chromebook* 성능의 한계를 뛰어넘다",
                "tr_subtitle": "",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i3 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "최대 <br/><span class='big-blue-text'>2.4배  </span><span class='subt-text'>더 우수한 성능<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "최대 <br/><span class='big-blue-text'>3.2배  </span><span class='subt-text'>더 빠른 웹 탐색<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "최대 <br/><span class='big-blue-text'>2.1배  </span><span class='subt-text'>더 빠른 비디오 편집<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Chromebook*으로 더 많은 가능성을 상상하세요",
                "tr_subtitle": "",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "최대 <br/><span class='big-blue-text'>2.7배  </span><span class='subt-text'>더 우수한 성능<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "최대 <br/><span class='big-blue-text'>3.3배  </span><span class='subt-text'>더 빠른 웹 탐색<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "최대 <br/><span class='big-blue-text'>2.8배  </span><span class='subt-text'>더 빠른 비디오 편집<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "우수한 모바일 성능",
                "tr_subtitle": "",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "11세대 인텔® 코어™ 프로세서의 한계를 뛰어넘는 성능"
                  },
                  {
                    "tr_badgeTitle": "절전 모드에서 1초 미만 해제<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "인텔® Wi-Fi 6(Gig+)로 약 3배 더 빨라진 인터넷<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "고속 충전, 장시간 지속되는 배터리 수명"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "세계 최고의 Chromebook*용 프로세서<sup class='sup-text'>1</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "최대 <br/><span class='big-blue-text'>2.9배  </span><span class='subt-text'>더 우수한 성능<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "최대 <br/><span class='big-blue-text'>3.5배  </span><span class='subt-text'>더 빠른 웹 탐색<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "최대 <br/><span class='big-blue-text'>2.9배  </span><span class='subt-text'>더 빠른 비디오 편집<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "우수한 모바일 성능",
                "tr_subtitle": "",
                "tr_gen_core_title": "11세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "11세대 인텔® 코어™ 프로세서의 한계를 뛰어넘는 성능"
                  },
                  {
                    "tr_badgeTitle": "절전 모드에서 1초 미만 해제<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "인텔® Wi-Fi 6(Gig+)로 약 3배 더 빨라진 인터넷<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "고속 충전, 장시간 지속되는 배터리 수명"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "빠른 속도, 매일 뛰어난&nbsp;성&#65279;능&nbsp;발&#65279;휘",
                "tr_subtitle": "인텔® 펜티엄® 프로세서의 파워를 통해 자주 사용하는 프로그램과 앱에서 뛰어난 성능을 즐기세요.",
                "tr_gen_core_title": "인텔® 펜티엄® Gold 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "빠른 속도, 매일 뛰어난&nbsp;성&#65279;능&nbsp;발&#65279;휘",
                "tr_subtitle": "인텔® 펜티엄® 프로세서의 파워를 통해 자주 사용하는 프로그램과 앱에서 뛰어난 성능을 즐기세요.",
                "tr_gen_core_title": "인텔® 펜티엄® Silver 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "일일 생산성 향상",
                "tr_subtitle": "앱 시작에서 프로젝트 실행까지 인텔® 셀러론® 프로세서가 더 많은 작업을 완료하도록 도와드립니다. ",
                "tr_gen_core_title": "인&#xfeff;텔&#xfeff;®&nbsp;셀&#xfeff;러&#xfeff;론&#xfeff;® 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "성능과 가치",
                "tr_subtitle": "뛰어난 엔터테인먼트, 비디오 스트리밍 및 생산성을 위해 설계되었습니다. 놀랍도록 선명한 영화와 웹 비디오를 즐기십시오. 더 짧은 시간에 더 많이 처리하십시오.",
                "tr_gen_core_title": "인&#xfeff;텔&#xfeff;®&nbsp;펜&#xfeff;티&#xfeff;엄&#xfeff;® 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "빠른 속도, 매일 뛰어난&nbsp;성&#65279;능&nbsp;발&#65279;휘",
                "tr_subtitle": "인텔® 펜티엄® 프로세서의 파워를 통해 자주 사용하는 프로그램과 앱에서 뛰어난 성능을 즐기세요.",
                "tr_gen_core_title": "인&#xfeff;텔&#xfeff;®&nbsp;펜&#xfeff;티&#xfeff;엄&#xfeff;® 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "성능과 가치",
                "tr_subtitle": "향상된 생산성 - 가장 까다로운 작업을 위해 더 강력한 성능을 제공합니다. 간소화된 웹 탐색 - 미디어가 풍부한 웹 페이지를 빠르게 로드하여 더 짧은 시간에 더 많이 탐색할 수 있습니다.",
                "tr_gen_core_title": "인텔® 펜티엄® Gold 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "성능과 가치",
                "tr_subtitle": "웹에서 파일에 액세스할 수 있는 성능을 통해 디지털 세계에서 앞서가십시오. 프로그램 간에 간편하게 전환하여 간편하게 멀티태스킹을 수행하고 더 짧은 시간에 더 많이 처리할 수 있습니다.",
                "tr_gen_core_title": "인텔® 펜티엄® Silver 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "성능과 가치",
                "tr_subtitle": "업무 및 엔터테인먼트를 모두 강력하게 지원하는 성능 영화와 웹 비디오를 놀라운 디테일로 즐기십시오. 더 짧은 시간에 더 많이 처리하십시오.",
                "tr_gen_core_title": "인&#xfeff;텔&#xfeff;®&nbsp;셀&#xfeff;러&#xfeff;론&#xfeff;® 프로세서"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "가장 필요한 곳에 안정적인 성능 제공",
                "tr_subtitle": "",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i3 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "손쉬운<br/>멀티태스킹"
                  },
                  {
                    "tr_badgeTitle": "내장<br/>배경 초점 흐리기"
                  },
                  {
                    "tr_badgeTitle": "반응형<br/>웹 탐색"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "가장 필요한 곳에 향상된 성능 제공",
                "tr_subtitle": "",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "강력한<br/>멀티태스킹"
                  },
                  {
                    "tr_badgeTitle": "자동 노이즈<br/>억제"
                  },
                  {
                    "tr_badgeTitle": "HD<br/>스트리밍"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "모바일 성능이 진화했습니다.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i5 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "12세대 인텔® 코어™ 프로세서<sup>11</sup>를 기반으로 가장 필요한 곳에 탁월한 성능 제공"
                  },
                  {
                    "tr_badgeTitle": "절전 모드에서 1초 미만 해제<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "인텔® Wi‑Fi 6/6E(Gig+)로 약 3배 더 빨라진 인터넷<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "고속 충전, 장시간 지속되는 배터리 수명"
                  },
                  {
                    "tr_badgeTitle": "향상된 협업 및 연결성"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "가장 필요한 곳에 탁월한 성능&nbsp;제공<sup>11</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "극한의<br/>멀티태스킹"
                  },
                  {
                    "tr_badgeTitle": "내장 노이즈<br/>억제 및<br/>배경 초점 흐리기"
                  },
                  {
                    "tr_badgeTitle": "원활한<br/>8K 스트리밍"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "모바일 성능이 진화했습니다.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12세대 인&#xfeff;텔&#xfeff;®&nbsp;코&#xfeff;어&#xfeff;™&nbsp;i7 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "12세대 인텔® 코어™ 프로세서<sup>11</sup>를 기반으로 가장 필요한 곳에 탁월한 성능 제공"
                  },
                  {
                    "tr_badgeTitle": "절전 모드에서 1초 미만 해제<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "인텔® Wi‑Fi 6/6E(Gig+)로 약 3배 더 빨라진 인터넷<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "고속 충전, 장시간 지속되는 배터리 수명"
                  },
                  {
                    "tr_badgeTitle": "향상된 협업 및 연결성"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13세대 인텔® 코어™ i5 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "차원이 다른 퍼포먼스를 발휘하는 thin & light 디자인<br/><span class='marklist'>+ </span>기존 휴대폰과의 원활한 연결<sup>13</sup><br/><span class='marklist'>+ </span>지능적으로 최상의 Wi-Fi 또는 5G 연결 선택<br/><span class='marklist'>+</span> 빠른 충전으로 오래가는 배터리"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "탁월한 성능으로 원하는 작업을 수행하세요.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13세대 인텔® 코어™ i5 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "향상된<br/>멀티태스킹"
                  },
                  {
                    "tr_badgeTitle": "원활한<br/>4K 스트리밍"
                  },
                  {
                    "tr_badgeTitle": "강력한<br/>창작"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13세대 인텔® 코어™ i7 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "차원이 다른 퍼포먼스를 발휘하는 thin & light 디자인<br/><span class='marklist'>+ </span>기존 휴대폰과의 원활한 연결<sup>13</sup><br/><span class='marklist'>+ </span>지능적으로 최상의 Wi-Fi 또는 5G 연결 선택<br/><span class='marklist'>+</span> 빠른 충전으로 오래가는 배터리"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "차원이 다른 우수한 성능으로 원하는 작업을 수행하세요.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13세대 인텔® 코어™ i7 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "극한의<br/>멀티태스킹"
                  },
                  {
                    "tr_badgeTitle": "원활한<br/>8K 스트리밍"
                  },
                  {
                    "tr_badgeTitle": "고급<br/>제작"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "인텔® 프로세서",
                "tr_subtitle": "필요한 성능. 원하는 경제성.",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "뛰어난 성능으로 원하는 작업을 하세요.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13세대 인텔® 코어™ i3 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "손쉬운<br/>멀티태스킹"
                  },
                  {
                    "tr_badgeTitle": "원활한<br/>스트리밍"
                  },
                  {
                    "tr_badgeTitle": "빠른<br/>제작"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ i3 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "차원이 다른 퍼포먼스를 발휘하는 thin & light 디자인<br/><span class='marklist'>+ </span>기존 휴대폰과의 원활한 연결<sup>13</sup><br/><span class='marklist'>+ </span>지능적으로 최상의 Wi-Fi 또는 5G 연결 선택<br/><span class='marklist'>+</span> 빠른 충전으로 오래가는 배터리"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "뛰어난 성능으로 원하는 작업을 하세요.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ i3 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "손쉬운<br/>멀티태스킹"
                  },
                  {
                    "tr_badgeTitle": "원활한<br/>스트리밍"
                  },
                  {
                    "tr_badgeTitle": "빠른<br/>제작"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "차원이 다른 퍼포먼스를 발휘하는 thin & light 디자인<br/><span class='marklist'>+ </span>기존 휴대폰과의 원활한 연결<sup>13</sup><br/><span class='marklist'>+ </span>지능적으로 최상의 Wi-Fi 또는 5G 연결 선택<br/><span class='marklist'>+</span> 빠른 충전으로 오래가는 배터리"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "탁월한 성능으로 원하는 작업을 수행하세요.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i5 프&#65279;로&#65279;세&#65279;서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "향상된<br/>멀티태스킹"
                  },
                  {
                    "tr_badgeTitle": "원활한<br/>4K 스트리밍"
                  },
                  {
                    "tr_badgeTitle": "강력한<br/>창작"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "차원이 다른 퍼포먼스를 발휘하는 thin & light 디자인<br/><span class='marklist'>+ </span>기존 휴대폰과의 원활한 연결<sup>13</sup><br/><span class='marklist'>+ </span>지능적으로 최상의 Wi-Fi 또는 5G 연결 선택<br/><span class='marklist'>+</span> 빠른 충전으로 오래가는 배터리"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "차원이 다른 우수한 성능으로 원하는 작업을 수행하세요.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i7 프&#65279;로&#65279;세&#65279;서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "극한의<br/>멀티태스킹"
                  },
                  {
                    "tr_badgeTitle": "원활한<br/>8K 스트리밍"
                  },
                  {
                    "tr_badgeTitle": "고급<br/>제작"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i9 프&#65279;로&#65279;세&#65279;서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "차원이 다른 퍼포먼스를 발휘하는 thin & light 디자인<br/><span class='marklist'>+ </span>기존 휴대폰과의 원활한 연결<sup>13</sup><br/><span class='marklist'>+ </span>지능적으로 최상의 Wi-Fi 또는 5G 연결 선택<br/><span class='marklist'>+</span> 빠른 충전으로 오래가는 배터리"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "탁월한 성능으로 열정을 마음껏 펼치십시오",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔&#65279;®&nbsp;코어&#65279;™&nbsp;i9 프&#65279;로&#65279;세&#65279;서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "빠른 사진 <br/>및 비디오 편집"
                  },
                  {
                    "tr_badgeTitle": "오프라인 기능 <br/>향상"
                  },
                  {
                    "tr_badgeTitle": "긴 배터리 수명으로 <br/>끊김 없이<br/> 게임을 플레이하십시오"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_3",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ 3 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "차원이 다른 퍼포먼스를 발휘하는 thin & light 디자인<br/><span class='marklist'>+ </span>기존 휴대폰과의 원활한 연결<sup>13</sup><br/><span class='marklist'>+ </span>지능적으로 최상의 Wi-Fi 또는 5G 연결 선택<br/><span class='marklist'>+</span> 빠른 충전으로 오래가는 배터리"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "뛰어난 성능으로 원하는 작업을 하세요.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ 3 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "손쉬운<br/>멀티태스킹"
                  },
                  {
                    "tr_badgeTitle": "원활한<br/>스트리밍"
                  },
                  {
                    "tr_badgeTitle": "빠른<br/>제작"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_5",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ 5 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "차원이 다른 퍼포먼스를 발휘하는 thin & light 디자인<br/><span class='marklist'>+ </span>기존 휴대폰과의 원활한 연결<sup>13</sup><br/><span class='marklist'>+ </span>지능적으로 최상의 Wi-Fi 또는 5G 연결 선택<br/><span class='marklist'>+</span> 빠른 충전으로 오래가는 배터리"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "탁월한 성능으로 원하는 작업을 수행하세요.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ 5 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "향상된<br/>멀티태스킹"
                  },
                  {
                    "tr_badgeTitle": "원활한<br/>4K 스트리밍"
                  },
                  {
                    "tr_badgeTitle": "강력한<br/>창작"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_7",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ 7 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "차원이 다른 퍼포먼스를 발휘하는 thin & light 디자인<br/><span class='marklist'>+ </span>기존 휴대폰과의 원활한 연결<sup>13</sup><br/><span class='marklist'>+ </span>지능적으로 최상의 Wi-Fi 또는 5G 연결 선택<br/><span class='marklist'>+</span> 빠른 충전으로 오래가는 배터리"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "차원이 다른 우수한 성능으로 원하는 작업을 수행하세요.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ 7 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "극한의<br/>멀티태스킹"
                  },
                  {
                    "tr_badgeTitle": "원활한<br/>8K 스트리밍"
                  },
                  {
                    "tr_badgeTitle": "고급<br/>제작"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ Ultra 5 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "차원이 다른 퍼포먼스를 발휘하는 thin & light 디자인<br/><span class='marklist'>+ </span>기존 휴대폰과의 원활한 연결<sup>13</sup><br/><span class='marklist'>+ </span>지능적으로 최상의 Wi-Fi 또는 5G 연결 선택<br/><span class='marklist'>+</span> 빠른 충전으로 오래가는 배터리"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "새로운 AI&nbsp;경험을 누려보세요",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ Ultra 5 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "최대 65% 더 빠른<br/>생성형 AI"
                  },
                  {
                    "tr_badgeTitle": "최대 2.3배 더 빠른<br/>AI 비디오 편집"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ Ultra 7 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "차원이 다른 퍼포먼스를 발휘하는 thin & light 디자인<br/><span class='marklist'>+ </span>기존 휴대폰과의 원활한 연결<sup>13</sup><br/><span class='marklist'>+ </span>지능적으로 최상의 Wi-Fi 또는 5G 연결 선택<br/><span class='marklist'>+</span> 빠른 충전으로 오래가는 배터리"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "새로운 AI&nbsp;경험을 누려보세요",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ Ultra 7 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "최대 65% 더 빠른<br/>생성형 AI"
                  },
                  {
                    "tr_badgeTitle": "최대 2.3배 더 빠른<br/>AI 비디오 편집"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ Ultra 9 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "차원이 다른 퍼포먼스를 발휘하는 thin & light 디자인<br/><span class='marklist'>+ </span>기존 휴대폰과의 원활한 연결<sup>13</sup><br/><span class='marklist'>+ </span>지능적으로 최상의 Wi-Fi 또는 5G 연결 선택<br/><span class='marklist'>+</span> 빠른 충전으로 오래가는 배터리"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "프로세서",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "새로운 AI&nbsp;경험을 누려보세요",
                "tr_subtitle": "",
                "tr_gen_core_title": "인텔® 코어™ Ultra 9 프로세서",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "최대 65% 더 빠른<br/>생성형 AI"
                  },
                  {
                    "tr_badgeTitle": "최대 2.3배 더 빠른<br/>AI 비디오 편집"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "legalText": {
    "tr_specTitle": "법적 정보",
    "tr_paragraph_array": [
      "¹내부 Google* 테스트에 따라 완전 충전 기준으로 최대 10시간의 배터리 수명",
      "²어떤 제품 또는 구성 요소도 절대적으로 안전할 수는 없습니다. 시스템 제조업체 또는 소매점을 통해 확인하거나 intel.com에서 자세한 내용을 알아보십시오.",
      "³업계 벤치마크, 대표 사용 가이드 테스트 및 인텔® 코어™ i7-1165G7 프로세서의 고유 기능을 기준으로 측정한 결과입니다. 워크로드와 구성을 보려면 www.intel.com/PerformanceIndex를 참조하십시오. 결과는 다를 수 있습니다.",
      "⁴Google Sheets 멀티태스킹으로 측정한 결과입니다.",
      "⁵Speedometer 2.0으로 측정한 결과입니다.",
      "⁶Kinemaster Export Video로 측정한 결과입니다.",
      "각주 4~6는 인텔® 코어™ i3-1115G4 프로세서와 인텔® 셀러론® N4020 프로세서에서 테스트한 결과입니다. 워크로드 및 구성에 대한 자세한 내용은 www.intel.com/PerformanceIndex를 참조하십시오. 성능은 사용, 구성 및 기타 요인에 따라 다릅니다. www.intel.com/PerformanceIndex에서 자세한 정보를 확인하십시오. 성능 결과는 구성에 표시된 날짜의 테스트를 기반으로 하며 공개된 모든 업데이트가 반영되어 있지 않을 수도 있습니다.",
      "⁷현실적인 환경에서 일반적인 워크플로를 수행하면서 100% 충전 상태에서 배터리를 거의 다 소진할 때까지 걸린 시간입니다. 결과는 다를 수 있습니다. 자세한 내용은 intel.com/evo에서 확인하십시오.",
      "⁸결과는 다를 수 있습니다. 자세한 내용은 intel.com/evo에서 확인하십시오.",
      "⁹약 3배 더 빠른 속도: 802.11ax 2x2 160MHz는 2402Mbps의 최대 이론 데이터 속도를 지원합니다. IEEE 802.11* 무선 표준 사양에 문서화된 표준 802.11ac 2x2 80MHz(867Mbps) Wi‑Fi보다 약 3배 더 빠르며 유사하게 구성된 802.11ax 무선 네트워크 라우터가 필요합니다.",
      "¹⁰OEM 기본 종료 상태에서 충전하였습니다. 결과는 다를 수 있습니다. 자세한 내용은 intel.com/evo에서 확인하십시오.",
      "¹¹이전 세대 프로세서 대비",
      "¹²세련되고 혁신적인 디자인에서 제공되는 고성능 인텔® 코어™ CPU, 프리미엄 구성 요소, 압도적인 호환성, 최신 연결 솔루션 등 인텔® Evo™ 디자인의 고유 기능을 측정한 결과입니다. 모든 인텔® Evo™ 브랜드 디자인은 반응성, 배터리 수명과 같은 주요 모바일 사용자 경험에 대한 까다로운 기준치를 충족해야 하며, 개별 장치 성능은 다를 수 있습니다. 자세한 내용은 www.intel.com/PerformanceIndex(플랫폼)에서 확인하십시오.",
      "¹³인텔® Unison™ 솔루션은 현재 Windows* 기반 PC의 적격 인텔® Evo™ 디자인에서만 사용할 수 있으며 Android* 또는 iOS* 기반 휴대폰과만 페어링됩니다. 모든 장치는 지원되는 OS 버전에서 실행해야 합니다. 개별 인텔® Evo™ 장치 성능은 다를 수 있습니다. 자세한 내용은 intel.com/Performance-Evo에서 확인하십시오.",
      "인텔® 기술의 특징과 이점은 시스템 구성에 따라 다르며 지원되는 하드웨어, 소프트웨어 및 서비스 활성화가 필요할 수 있습니다. 성능은 시스템 구성에 따라 달라집니다. 어떠한 컴퓨터 시스템도 절대적으로 안전하지는 않습니다. 시스템 제조업체 또는 소매점을 통해 확인하거나 intel.com에서 자세한 내용을 알아보십시오.",
      "*기타 명칭 및 브랜드는 해당 소유업체의 자산입니다.",
      "잘못된 가격에 대해서는 인텔이 책임지지 않습니다.",
      "© 2022 Intel Corporation. 셀러론, 인텔, 인텔 로고, 인텔 코어 및 펜티엄은 인텔사 또는 그 자회사의 등록 상표입니다."
    ]
  }
}