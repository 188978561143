const Library_mobile_el = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Με επεξεργαστή [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Με επεξεργαστή [cpuDisplay]"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Όπως ένας εγκέφαλος, ο επεξεργαστής ελέγχει το τι κάνει το PC σας. Αυτό σημαίνει ότι τα βίντεο που παρακολουθείτε, τα παιχνίδια που παίζετε, οι ιστοσελίδες που επισκέπτεστε, όλα ξεκινούν με τον επεξεργαστή.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Ο επεξεργαστής είναι ο εγκέφαλος του PC σας"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Επιδόσεις και τιμή.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Επεξεργαστής Intel® Pentium® Silver",
                    "tr_subtitle": "Χαρείτε τον ψηφιακό κόσμο σας με επιδόσεις που σας επιτρέπουν να προσπελάζετε αρχεία από τον Ιστό. Απλή πολυδιεργασία με εύκολη εναλλαγή μεταξύ προγραμμάτων, πετυχαίνοντας έτσι περισσότερα σε λιγότερο χρόνο.",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Pentium® Silver"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Επεξεργαστής Intel® Pentium® Gold",
                    "tr_subtitle": "Βελτιωμένη παραγωγικότητα - Βελτιωμένες επιδόσεις που θα σας υποστηρίξουν και στις πιο απαιτητικές ημέρες. Βελτιωμένη περιήγηση στον Ιστό – Γρήγορη φόρτωση πλούσιων σε πολυμέσα ιστοσελίδων για περισσότερη εξερεύνηση και λιγότερη αναμονή.",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Pentium® Gold"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Επεξεργαστής Intel® Celeron®",
                    "tr_subtitle": "Επιδόσεις για αναβάθμιση της εργασίας και της ψυχαγωγίας σας. Απολαύστε προβολή ταινιών και βίντεο από το διαδίκτυο με απίστευτη λεπτομέρεια. Εργαστείτε περισσότερο και περιμένετε λιγότερο.",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Celeron®"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Επεξεργαστής Intel® Pentium®",
                    "tr_subtitle": "Κατασκευασμένο για καταπληκτική ψυχαγωγία, μετάδοση βίντεο και παραγωγικότητα. Απολαύστε προβολή ταινιών και βίντεο από το διαδίκτυο με απίστευτη λεπτομέρεια. Εργαστείτε περισσότερο και περιμένετε λιγότερο.",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Pentium®"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "Επιδόσεις που χρειάζεστε. Προσιτές τιμές που θέλετε.",
                "tr_title": "Επεξεργαστής Intel®",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Τιμή σε ειδική κατηγορία",
                    "tr_subtitle": "Ένας επεξεργαστής Intel® που μπορεί να χειριστεί σχεδόν οποιαδήποτε εργασία χρειάζεστε ενώ διατίθεται σε προσιτή τιμή. Πλοηγηθείτε εύκολα από το ένα λογισμικό στο άλλο. Ή απολαύστε γραφικά υψηλής ποιότητας με σταθερές επιδόσεις.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Κάντε ισχυρότερες συνδέσεις",
                    "tr_subtitle": "Πιο ζωντανή ποιότητα βίντεο, πλουσιότερη ευκρίνεια ήχου και σχεδόν 3 φορές ταχύτερη σύνδεση στο Διαδίκτυο, σας επιτρέπουν να συνεργάζεστε με αυτοπεποίθηση οπουδήποτε χρειάζεστε.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Η διαφορά είναι μέρα και νύχτα",
                    "tr_subtitle": "Από τους πρωινούς μαραθώνιους ταινιών έως τα ρετουσαρίσματα της τελευταίας στιγμής, ο επεξεργαστής Intel® επεκτείνει τη διάρκεια ζωής της μπαταρίας της συσκευής σας για πραγματικά καθηλωτικές, αδιάκοπες εμπειρίες.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Κάθε περιβάλλον είναι ένα εργαλείο μάθησης",
                    "tr_subtitle": "Εκτελέστε πολλά διαδικτυακά εργαλεία εκμάθησης με ευκολία από κάθε σημείο του σπιτιού σας."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Μάθετε απ΄όπου θέλετε",
                    "tr_subtitle": "Ολοκληρώστε τα μαθήματά σας απ΄όπου χρειάζεστε, ενώ ταυτόχρονα εκτελείτε διάφορα εργαλεία ηλεκτρονικής μάθησης."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Αποκτήστε την ισχύ για ψυχαγωγία και παραγωγικότητα που ταιριάζει στο πορτοφόλι και τον τρόπο ζωής σας.",
                "tr_title": "Επιδόσεις Intel για όλα τα πορτοφόλια.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Celeron®",
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟ ΚΑΤΑΛΛΗΛΟ ΕΠΙΠΕΔΟ ΕΠΙΔΟΣΕΩΝ ΚΑΙ ΤΙΜΗΣ",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Celeron®"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Γρήγορη περιήγηση στον Ιστό με ποικιλία πολυμέσων",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Εύκολη εναλλαγή μεταξύ προγραμμάτων",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Περιστασιακό παιχνίδι",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα βίντεο 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ελαφριά επεξεργασία φωτογραφιών και βίντεο",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Γρήγορη παραγωγικότητα με έξυπνες επιδόσεις",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Με επεξεργαστή Intel® Core™ [cpu_fam] 7ης γενιάς"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Με επεξεργαστή Intel® Core™ [cpu_fam] 6ης γενιάς"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Με επεξεργαστή Intel® Core™ [cpu_fam] 5ης γενιάς"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Με επεξεργαστή Intel® Core™ [cpu_fam] 4ης γενιάς"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Με επεξεργαστή Intel® Core™ [cpu_fam] 3ης γενιάς"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Με επεξεργαστή Intel® Core™ [cpu_fam] 2ης γενιάς"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Με επεξεργαστή Intel® Core™ [cpu_fam] 1ης γενιάς"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Συνιστάται για:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Με επεξεργαστή Intel® Core™ i3 8ης γενιάς",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i3 8ης γενιάς"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Οι επεξεργαστές Intel® Core™ 9ης γενιάς δημιουργήθηκαν για να συμβαδίζουν με τον ψηφιακό κόσμο σας. Με τον συνδυασμό ταχύτητας και επιδόσεων που προσφέρουν, θα μπορείτε να κάνετε περισσότερα από αυτά που αγαπάτε στο PC σας χωρίς απογοητεύσεις.",
                "tr_title": "Με επεξεργαστή Intel® Core™ i3 9ης γενιάς",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i3 9ης γενιάς"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Επιδόσεις για πολλές χρήσεις και μεγάλη αυτονομία μπαταρίας για να προχωράτε όπου κι αν βρίσκεστε.",
                "tr_title": "Ολοήμερη αυτονομία μπαταρίας",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i3 10ης γενιάς",
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟ ΚΑΤΑΛΛΗΛΟ ΕΠΙΠΕΔΟ ΕΠΙΔΟΣΕΩΝ ΤΗΣ 10ΗΣ ΓΕΝΙΑΣ",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Στριμάρισμα ταινιών σε 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Γρήγορη και αξιόπιστη συνδεσιμότητα",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Μασκάρισμα φωτογραφιών και φιλτράρισμα βίντεο",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming σε 1080p με ομαλούς ρυθμούς καρέ",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ιδανικό για εργασία με μεγάλα αρχεία και δημιουργικά προγράμματα",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Πάτε από το στριμάρισμα ταινιών στη δημιουργία παρουσιάσεων με άφθονο απόθεμα επιδόσεων.",
                "tr_title": "Ολοήμερη αυτονομία μπαταρίας",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i3 10ης γενιάς"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Για όσους είστε διαρκώς εκτός σπιτιού, ένα laptop ειδικά σχεδιασμένο για εσάς.",
                "tr_title": "Γνωρίστε μια νέα κατηγορία laptop",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i3 10ης γενιάς"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Σχεδιασμένος ειδικά για φορητούς υπολογιστές, ο επεξεργαστής Intel® Core™ i3 11ης γενιάς σάς επιτρέπει να κάνετε περισσότερα από ποτέ, οπουδήποτε και αν είστε.",
                "tr_title": "Αποκτήστε περισσότερα από το νέο σας laptop",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i3 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i3 11ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Όταν ολόκληρη η οικογένειά σας ψάχνει ένα desktop PC για οτιδήποτε κάνει, τότε χρειάζεστε τη δύναμη και την ευελιξία ενός επεξεργαστή Intel® Core™ i3 11ης γενιάς.",
                "tr_title": "Να είστε έτοιμοι για επιδόσεις-ρεκόρ σε οτιδήποτε.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i3 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i3 11ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Με επεξεργαστή Intel® Core™ i5 8ης γενιάς",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 8ης γενιάς"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Οι επεξεργαστές Intel® Core™ 9ης γενιάς δημιουργήθηκαν για να συμβαδίζουν με τον ψηφιακό κόσμο σας. Με τον συνδυασμό ταχύτητας και επιδόσεων που προσφέρουν, θα μπορείτε να κάνετε περισσότερα από αυτά που αγαπάτε στο PC σας χωρίς απογοητεύσεις.",
                "tr_title": "Με επεξεργαστή Intel® Core™ i5 9ης γενιάς",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 9ης γενιάς"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ΟΜΑΛΟ ΠΑΙΧΝΙΔΙ ΚΑΙ VR ΣΤΟΥΣ ΠΙΟ ΑΠΑΙΤΗΤΙΚΟΥΣ ΤΙΤΛΟΥΣ",
                "tr_title": "Το πραγματικό gaming ξεκινά εδώ",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 9ης γενιάς",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΕΠΕΞΕΡΓΑΣΤΗ INTEL® CORE™ ΠΟΥ ΤΑΙΡΙΑΖΕΙ ΣΤΟΝ ΤΡΟΠΟ ΠΑΙΧΝΙΔΙΟΥ ΣΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ανταγωνιστικό gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Παίξτε, στριμάρετε και εγγράψτε χωρίς συμβιβασμούς",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ο καλύτερος επεξεργαστής Intel® Core™ 9ης γενιάς για gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 4<br/>Νήματα: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Εργαστείτε εύκολα σε πολλά προγράμματα ταυτόχρονα για να αντεπεξέρχεστε ακόμη και στην πιο δύσκολη ημέρα.",
                "tr_title": "Δυνατότητα να κάνετε πολλές εργασίες μαζί εκτός γραφείου",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 10ης γενιάς",
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟ ΚΑΤΑΛΛΗΛΟ ΕΠΙΠΕΔΟ ΕΠΙΔΟΣΕΩΝ ΤΗΣ 10ΗΣ ΓΕΝΙΑΣ",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Στριμάρισμα ταινιών σε 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Γρήγορη και αξιόπιστη συνδεσιμότητα",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Μασκάρισμα φωτογραφιών και φιλτράρισμα βίντεο",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming σε 1080p με ομαλούς ρυθμούς καρέ",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ιδανικό για εργασία με μεγάλα αρχεία και δημιουργικά προγράμματα",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Αναβαθμίστε όλα όσα κάνετε, από την ψυχαγωγία έως την παραγωγικότητα και τη δημιουργία περιεχομένου.",
                "tr_title": "Κάντε αναβάθμιση σε πιο ισχυρό PC",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 10ης γενιάς"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Για όσους είστε διαρκώς εκτός σπιτιού, ένα laptop ειδικά σχεδιασμένο για εσάς.",
                "tr_title": "Γνωρίστε μια νέα κατηγορία laptop",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 10ης γενιάς"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Σχεδίαση για απρόσκοπτο στριμάρισμα και ΑΑΑ gaming",
                "tr_title": "Παίξτε με τους δικούς σας όρους",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 10ης γενιάς",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΕΠΕΞΕΡΓΑΣΤΗ INTEL® CORE™ 10ΗΣ ΓΕΝΙΑΣ ΠΟΥ ΤΑΙΡΙΑΖΕΙ ΣΤΟΝ ΤΡΟΠΟ ΠΑΙΧΝΙΔΙΟΥ ΣΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Απρόσκοπτο gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ανταγωνιστικό gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα και καταγραφή",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Προηγμένη δημιουργία περιεχομένου",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Κορυφαίες επιδόσεις στο gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ξεκλείδωτοι και με δυνατότητα υπερχρονισμού<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Σχεδίαση για απρόσκοπτο στριμάρισμα και ΑΑΑ gaming",
                "tr_title": "Παίξτε με τους δικούς σας όρους",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 10ης γενιάς",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΕΠΕΞΕΡΓΑΣΤΗ INTEL® CORE™ 10ΗΣ ΓΕΝΙΑΣ ΠΟΥ ΤΑΙΡΙΑΖΕΙ ΣΤΟΝ ΤΡΟΠΟ ΠΑΙΧΝΙΔΙΟΥ ΣΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Απρόσκοπτο gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ανταγωνιστικό gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα και καταγραφή",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Προηγμένη δημιουργία περιεχομένου",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Κορυφαίες επιδόσεις στο gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ξεκλείδωτοι και με δυνατότητα υπερχρονισμού<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 10<br/>Νήματα: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Εργαστείτε εύκολα σε πολλά προγράμματα ταυτόχρονα για να αντεπεξέρχεστε ακόμη και στην πιο δύσκολη ημέρα.",
                "tr_title": "Δυνατότητα να κάνετε πολλές εργασίες μαζί εκτός γραφείου",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 10ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ 10ΗΣ ΓΕΝΙΑΣ INTEL® CORE™ i5"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Ο επεξεργαστής Intel® Core™ i5 11ης γενιάς προσφέρει ολοζώντανες οπτικές εμπειρίες και παραγωγικότητα ευρείας εμβέλειας στους πιο λεπτούς κι ελαφριούς υπολογιστές.",
                "tr_title": "Εντυπωσιακή ισχύς laptop",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5 11ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Όταν ολόκληρη η οικογένειά σας ψάχνει ένα desktop PC για οτιδήποτε κάνει, τότε χρειάζεστε τη δύναμη και την ευελιξία ενός επεξεργαστή Intel® Core™ i5 11ης γενιάς.",
                "tr_title": "Να είστε έτοιμοι για επιδόσεις-ρεκόρ σε οτιδήποτε.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5 11ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ο επεξεργαστής Intel® Core™ i5 11ης γενιάς προσφέρει ολοζώντανες οπτικές εμπειρίες και ποικιλότροπη παραγωγικότητα στα λεπτά κι ελαφριά laptop.",
                "tr_title": "Εντυπωσιακή ισχύς laptop",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5 11ΗΣ ΓΕΝΙΑΣ"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Με επεξεργαστή Intel® Core™ i7 8ης γενιάς",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 8ης γενιάς"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Οι επεξεργαστές Intel® Core™ 9ης γενιάς δημιουργήθηκαν για να συμβαδίζουν με τον ψηφιακό κόσμο σας. Με τον συνδυασμό ταχύτητας και επιδόσεων που προσφέρουν, θα μπορείτε να κάνετε περισσότερα από αυτά που αγαπάτε στο PC σας χωρίς απογοητεύσεις.",
                "tr_title": "Με επεξεργαστή Intel® Core™ i7 9ης γενιάς",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 9ης γενιάς"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ΕΩΣ 32% ΠΕΡΙΣΣΟΤΕΡΑ FPS κατά το gaming, το στριμάρισμα και την καταγραφή σε σύγκριση με ένα PC ΤΡΙΕΤΙΑΣ<sup>3</sup>",
                "tr_title": "Ισχύς για να μοιράζεστε τις καλύτερες στιγμές σας στο gaming",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 9ης γενιάς",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΕΠΕΞΕΡΓΑΣΤΗ INTEL® CORE™ ΠΟΥ ΤΑΙΡΙΑΖΕΙ ΣΤΟΝ ΤΡΟΠΟ ΠΑΙΧΝΙΔΙΟΥ ΣΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ανταγωνιστικό gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Παίξτε, στριμάρετε και εγγράψτε χωρίς συμβιβασμούς",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ο καλύτερος επεξεργαστής Intel® Core™ 9ης γενιάς για gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 4<br/>Νήματα: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Κατασκευασμένη για αποδοτικότητα, αυτή η κομψή συσκευή μπορεί να επηρεάσει σημαντικά το πώς δημιουργείτε, συνδέεστε και στριμάρετε.",
                "tr_title": "Προηγμένες επιδόσεις οπουδήποτε",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 10ης γενιάς",
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟ ΚΑΤΑΛΛΗΛΟ ΕΠΙΠΕΔΟ ΕΠΙΔΟΣΕΩΝ ΤΗΣ 10ΗΣ ΓΕΝΙΑΣ",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i3"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Στριμάρισμα ταινιών σε 4K",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Γρήγορη και αξιόπιστη συνδεσιμότητα",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Μασκάρισμα φωτογραφιών και φιλτράρισμα βίντεο",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gaming σε 1080p με ομαλούς ρυθμούς καρέ",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ιδανικό για εργασία με μεγάλα αρχεία και δημιουργικά προγράμματα",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Προηγμένες επιδόσεις για να πάτε όλα όσα κάνετε στο επόμενο επίπεδο.",
                "tr_title": "Ισχύς για να τα κάνετε όλα",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 10ης γενιάς"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Για όσους είστε διαρκώς εκτός σπιτιού, ένα laptop ειδικά σχεδιασμένο για εσάς.",
                "tr_title": "Γνωρίστε μια νέα κατηγορία laptop",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 10ης γενιάς"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Παίξτε παιχνίδια, στριμάρετε και καταγράψτε με ανταγωνιστικό πλεονέκτημα",
                "tr_title": "Ενισχύστε τον κόσμο σας στο gaming",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 10ης γενιάς",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΕΠΕΞΕΡΓΑΣΤΗ INTEL® CORE™ 10ΗΣ ΓΕΝΙΑΣ ΠΟΥ ΤΑΙΡΙΑΖΕΙ ΣΤΟΝ ΤΡΟΠΟ ΠΑΙΧΝΙΔΙΟΥ ΣΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Απρόσκοπτο gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ανταγωνιστικό gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα και καταγραφή",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Προηγμένη δημιουργία περιεχομένου",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Κορυφαίες επιδόσεις στο gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ξεκλείδωτοι και με δυνατότητα υπερχρονισμού<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Παίξτε παιχνίδια, στριμάρετε και καταγράψτε με ανταγωνιστικό πλεονέκτημα",
                "tr_title": "Ενισχύστε τον κόσμο σας στο gaming",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 10ης γενιάς",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΕΠΕΞΕΡΓΑΣΤΗ INTEL® CORE™ 10ΗΣ ΓΕΝΙΑΣ ΠΟΥ ΤΑΙΡΙΑΖΕΙ ΣΤΟΝ ΤΡΟΠΟ ΠΑΙΧΝΙΔΙΟΥ ΣΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Απρόσκοπτο gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ανταγωνιστικό gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα και καταγραφή",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Προηγμένη δημιουργία περιεχομένου",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Κορυφαίες επιδόσεις στο gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ξεκλείδωτοι και με δυνατότητα υπερχρονισμού<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 10<br/>Νήματα: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Σχεδιασμένη για αποδοτικότητα, αυτή η κομψή συσκευή μπορεί να έχει τεράστια επίδραση στον τρόπο με τον οποίο δημιουργείτε, συνδέεστε και στριμάρετε.",
                "tr_title": "Κορυφαίες επιδόσεις οπουδήποτε",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 10ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ 10ΗΣ ΓΕΝΙΑΣ INTEL® CORE™ i7"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Ο επεξεργαστής Intel® Core™ i7 11ης γενιάς προσφέρει προηγμένη δημιουργία περιεχομένου, ομαλό gaming και ανώτερου επιπέδου ψυχαγωγία σε εξαιρετικά φορητούς υπολογιστές.",
                "tr_title": "Πρωτοφανείς επιδόσεις από laptop",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 11ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Κάντε το desktop PC σας τον απαραίτητο προορισμό για οτιδήποτε μπορεί να χρειαστεί η οικογένειά σας, χάρη στις επιδόσεις-ρεκόρ ενός επεξεργαστή Intel® Core™ i7 11ης γενιάς.",
                "tr_title": "Εργασία. Μάθηση. Παιχνίδι. Χωρίς όρια.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 11ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ο επεξεργαστής Intel® Core™ i7 11ης γενιάς προσφέρει προηγμένη δημιουργία περιεχομένου, ομαλό gaming και ανώτερου επιπέδου ψυχαγωγία στα λεπτά κι ελαφριά laptop.",
                "tr_title": "Πρωτοφανείς επιδόσεις από laptop",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 11ΗΣ ΓΕΝΙΑΣ"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Κάντε το desktop PC σας τον απαραίτητο προορισμό για οτιδήποτε μπορεί να χρειαστεί η οικογένειά σας, χάρη στις επιδόσεις-ρεκόρ ενός επεξεργαστή Intel® Core™ i7 11ης γενιάς.",
                "tr_title": "Δημιουργήστε χωρίς όρια",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 11ΗΣ ΓΕΝΙΑΣ"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Με προηγμένη δημιουργία περιεχομένου, ομαλό gaming και ανώτερου επιπέδου ψυχαγωγία σε λεπτά κι ελαφριά laptop, αυτός ο επεξεργαστής Intel® Core™ i7 11ης γενιάς έγινε όντως καλύτερος.",
                "tr_title": "Πρωτοφανείς επιδόσεις από laptop",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 11ΗΣ ΓΕΝΙΑΣ"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Με επεξεργαστή Intel® Core™ i9 8ης γενιάς",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 8ης γενιάς"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Οι επεξεργαστές Intel® Core™ 9ης γενιάς δημιουργήθηκαν για να συμβαδίζουν με τον ψηφιακό κόσμο σας. Με τον συνδυασμό ταχύτητας και επιδόσεων που προσφέρουν, θα μπορείτε να κάνετε περισσότερα από αυτά που αγαπάτε στο PC σας χωρίς απογοητεύσεις.",
                "tr_title": "Με επεξεργαστή Intel® Core™ i9 9ης γενιάς",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 9ης γενιάς"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ΕΩΣ 41% ΠΕΡΙΣΣΟΤΕΡΑ FPS κατά το gaming, το στριμάρισμα και την καταγραφή σε σύγκριση με ένα PC ΤΡΙΕΤΙΑΣ<sup>4</sup>",
                "tr_title": "Οι gamers απαιτούν. Ο Intel® Core™ i9 προσφέρει.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 9ης γενιάς",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΕΠΕΞΕΡΓΑΣΤΗ INTEL® CORE™ ΠΟΥ ΤΑΙΡΙΑΖΕΙ ΣΤΟΝ ΤΡΟΠΟ ΠΑΙΧΝΙΔΙΟΥ ΣΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ανταγωνιστικό gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Παίξτε, στριμάρετε και εγγράψτε χωρίς συμβιβασμούς",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ο καλύτερος επεξεργαστής Intel® Core™ 9ης γενιάς για gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 4<br/>Νήματα: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Ενισχύστε τις δυνατότητές σας με την απόλυτη εμπειρία gaming",
                "tr_title": "Συναγωνιστείτε στα καλύτερα επίπεδα",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 10ης γενιάς",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΕΠΕΞΕΡΓΑΣΤΗ INTEL® CORE™ 10ΗΣ ΓΕΝΙΑΣ ΠΟΥ ΤΑΙΡΙΑΖΕΙ ΣΤΟΝ ΤΡΟΠΟ ΠΑΙΧΝΙΔΙΟΥ ΣΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Απρόσκοπτο gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ανταγωνιστικό gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα και καταγραφή",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Προηγμένη δημιουργία περιεχομένου",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Κορυφαίες επιδόσεις στο gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ξεκλείδωτοι και με δυνατότητα υπερχρονισμού<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Ενισχύστε τις δυνατότητές σας με την απόλυτη εμπειρία gaming",
                "tr_title": "Συναγωνιστείτε στα καλύτερα επίπεδα",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 10ης γενιάς",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΕΠΕΞΕΡΓΑΣΤΗ INTEL® CORE™ 10ΗΣ ΓΕΝΙΑΣ ΠΟΥ ΤΑΙΡΙΑΖΕΙ ΣΤΟΝ ΤΡΟΠΟ ΠΑΙΧΝΙΔΙΟΥ ΣΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Απρόσκοπτο gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ανταγωνιστικό gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα και καταγραφή",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Προηγμένη δημιουργία περιεχομένου",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Κορυφαίες επιδόσεις στο gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ξεκλείδωτοι και με δυνατότητα υπερχρονισμού<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 10<br/>Νήματα: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Ενισχύστε τις δυνατότητές σας με την απόλυτη εμπειρία δημιουργίας",
                "tr_title": "Δημιουργήστε στο υψηλότερο επίπεδο",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 10ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ 10ΗΣ ΓΕΝΙΑΣ INTEL® CORE™ i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Τα laptop Intel® Evo™ με επεξεργαστή Intel® Core™ i5 11ης γενιάς και γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> σας προσφέρουν απίστευτη ταχύτητα και επιδόσεις σε λεπτά κι ελαφριά σχέδια.",
                "tr_title": "Αυξημένη ισχύς για εξελιγμένα laptop",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5 11ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Τα laptop Intel® Evo™ με επεξεργαστή Intel® Core™ i7 11ης γενιάς και γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> σας προσφέρουν προηγμένη ταχύτητα και επιδόσεις για μια κορυφαία εμπειρία χρήσης εν κινήσει.",
                "tr_title": "Προηγμένη ισχύς για εξελιγμένα laptop",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 11ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Απολαύστε εξαιρετική ισορροπία επιδόσεων, εμπειριών πολυμέσων και συνδεσιμότητας σε καταπληκτική τιμή.",
                "tr_title": "Επιδόσεις και συνδεσιμότητα σε καταπληκτική τιμή.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Pentium® Silver",
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟ ΚΑΤΑΛΛΗΛΟ ΕΠΙΠΕΔΟ ΕΠΙΔΟΣΕΩΝ ΚΑΙ ΤΙΜΗΣ",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Γρήγορη περιήγηση στον Ιστό με ποικιλία πολυμέσων",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Εύκολη εναλλαγή μεταξύ προγραμμάτων",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Περιστασιακό παιχνίδι",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα βίντεο 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ελαφριά επεξεργασία φωτογραφιών και βίντεο",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Γρήγορη παραγωγικότητα με έξυπνες επιδόσεις",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Απολαύστε απρόσκοπτη πολυδιεργασία, απλή επεξεργασία φωτογραφιών και βίντεο, βίντεο 4K και ζωντανά γραφικά σε απίστευτη τιμή.",
                "tr_title": "Εντυπωσιακές επιδόσεις για εργασία και διασκέδαση.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Pentium® Gold",
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟ ΚΑΤΑΛΛΗΛΟ ΕΠΙΠΕΔΟ ΕΠΙΔΟΣΕΩΝ ΚΑΙ ΤΙΜΗΣ",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Pentium® Silver"
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Pentium® Gold"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i3"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Γρήγορη περιήγηση στον Ιστό με ποικιλία πολυμέσων",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Εύκολη εναλλαγή μεταξύ προγραμμάτων",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Περιστασιακό παιχνίδι",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα βίντεο 4K",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ελαφριά επεξεργασία φωτογραφιών και βίντεο",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Γρήγορη παραγωγικότητα με έξυπνες επιδόσεις",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Gaming AAA και μεγάλη αυτονομία μπαταρίας σε έναν υπολογιστή εξαιρετικής φορητότητας. Δεν υπάρχει λόγος για συμβιβασμούς στον τρόπο με τον οποίο παίζετε.",
                "tr_title": "Δεν υπάρχει λόγος για συμβιβασμούς.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 11ης γενιάς",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΔΙΚΟ ΣΑΣ ΕΠΕΞΕΡΓΑΣΤΗ  INTEL® CORE™ ΓΙΑ GAMING ΕΞΑΙΡΕΤΙΚΗΣ ΦΟΡΗΤΟΤΗΤΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5-11300H 11ης γενιάς"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7-11370H 11ης γενιάς"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7-11375H Έκδοσης Special 11ης γενιάς"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Μέγιστη ταχύτητα ρολογιού έως και <span class='style_badge'>4,4 GHz</span>",
                      "tr_td2_title": "Μέγιστη ταχύτητα ρολογιού έως και <span class='style_badge'>4,8 GHz</span>",
                      "tr_td3_title": "Μέγιστη ταχύτητα ρολογιού έως και <span class='style_badge'>5,0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 πυρήνες/ 8&nbsp;νήματα",
                      "tr_td2_title": "4 πυρήνες/ 8&nbsp;νήματα",
                      "tr_td3_title": "4 πυρήνες/ 8&nbsp;νήματα"
                    },
                    {
                      "tr_td1_title": "Τεχνολογία Intel® Turbo Boost",
                      "tr_td2_title": "Τεχνολογία Intel® Turbo Boost",
                      "tr_td3_title": "Τεχνολογία Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Έξυπνη κρυφή μνήμη Intel® 8 MB",
                      "tr_td2_title": "Έξυπνη κρυφή μνήμη Intel® 12 MB",
                      "tr_td3_title": "Έξυπνη κρυφή μνήμη Intel® 12 MB"
                    },
                    {
                      "tr_td1_title": "Γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Gaming AAA 1080p με υψηλά FPS σε υψηλές ρυθμίσεις και εξαιρετικές επιδόσεις εκτός πρίζας σε ένα PC εξαιρετικής φορητότητας.",
                "tr_title": "Προχωρήστε μπροστά. Τα έχετε όλα.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 11ης γενιάς",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΔΙΚΟ ΣΑΣ ΕΠΕΞΕΡΓΑΣΤΗ  INTEL® CORE™ ΓΙΑ GAMING ΕΞΑΙΡΕΤΙΚΗΣ ΦΟΡΗΤΟΤΗΤΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5-11300H 11ης γενιάς"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7-11370H 11ης γενιάς"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7-11375H Έκδοσης Special 11ης γενιάς"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Μέγιστη ταχύτητα ρολογιού έως και <span class='style_badge'>4,4 GHz</span>",
                      "tr_td2_title": "Μέγιστη ταχύτητα ρολογιού έως και <span class='style_badge'>4,8 GHz</span>",
                      "tr_td3_title": "Μέγιστη ταχύτητα ρολογιού έως και <span class='style_badge'>5,0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 πυρήνες/ 8&nbsp;νήματα",
                      "tr_td2_title": "4 πυρήνες/ 8&nbsp;νήματα",
                      "tr_td3_title": "4 πυρήνες/ 8&nbsp;νήματα"
                    },
                    {
                      "tr_td1_title": "Τεχνολογία Intel® Turbo Boost",
                      "tr_td2_title": "Τεχνολογία Intel® Turbo Boost",
                      "tr_td3_title": "Τεχνολογία Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Έξυπνη κρυφή μνήμη Intel® 8 MB",
                      "tr_td2_title": "Έξυπνη κρυφή μνήμη Intel® 12 MB",
                      "tr_td3_title": "Έξυπνη κρυφή μνήμη Intel® 12 MB"
                    },
                    {
                      "tr_td1_title": "Γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Μέγιστη ταχύτητα ρολογιού έως 5,0 GHz για να ενισχύσετε τα παιχνίδια σας περισσότερο και ένα PC εξαιρετικής φορητότητας για να τα μεταφέρετε όπου κι αν πάτε.",
                "tr_title": "Περισσότερη ισχύς ανά κιλό.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 Έκδοσης Special 11ης γενιάς",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7 Έκδοσης Special",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΔΙΚΟ ΣΑΣ ΕΠΕΞΕΡΓΑΣΤΗ  INTEL® CORE™ ΓΙΑ GAMING ΕΞΑΙΡΕΤΙΚΗΣ ΦΟΡΗΤΟΤΗΤΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5-11300H 11ης γενιάς"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7-11370H 11ης γενιάς"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7-11375H Έκδοσης Special 11ης γενιάς"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Μέγιστη ταχύτητα ρολογιού έως και <span class='style_badge'>4,4 GHz</span>",
                      "tr_td2_title": "Μέγιστη ταχύτητα ρολογιού έως και <span class='style_badge'>4,8 GHz</span>",
                      "tr_td3_title": "Μέγιστη ταχύτητα ρολογιού έως και <span class='style_badge'>5,0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 πυρήνες/ 8&nbsp;νήματα",
                      "tr_td2_title": "4 πυρήνες/ 8&nbsp;νήματα",
                      "tr_td3_title": "4 πυρήνες/ 8&nbsp;νήματα"
                    },
                    {
                      "tr_td1_title": "Τεχνολογία Intel® Turbo Boost",
                      "tr_td2_title": "Τεχνολογία Intel® Turbo Boost",
                      "tr_td3_title": "Τεχνολογία Intel® Turbo Boost Max 3.0"
                    },
                    {
                      "tr_td1_title": "Έξυπνη κρυφή μνήμη Intel® 8 MB",
                      "tr_td2_title": "Έξυπνη κρυφή μνήμη Intel® 12 MB",
                      "tr_td3_title": "Έξυπνη κρυφή μνήμη Intel® 12 MB"
                    },
                    {
                      "tr_td1_title": "Γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td2_title": "Γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                      "tr_td3_title": "Γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ο επεξεργαστής Intel® Core™ i7 Έκδοσης Special 11ης γενιάς φέρνει προηγμένη δημιουργία περιεχομένου, ομαλή εμπειρία gaming και ψυχαγωγία ανώτερου επιπέδου στους εξαιρετικά φορητούς υπολογιστές.",
                "tr_title": "Πρωτοφανείς επιδόσεις από laptop",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 Έκδοσης Special 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 ΕΚΔΟΣΗΣ SPECIAL 11ΗΣ ΓΕΝΙΑΣ"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Ο επεξεργαστής Intel® Core™ i7 Έκδοσης Special 11ης γενιάς φέρνει προηγμένη δημιουργία περιεχομένου, ομαλή εμπειρία gaming και ψυχαγωγία ανώτερου επιπέδου στους εξαιρετικά φορητούς υπολογιστές.",
                "tr_title": "Πρωτοφανείς επιδόσεις από laptop",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 Έκδοσης Special 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 ΕΚΔΟΣΗΣ SPECIAL 11ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7 Έκδοσης Special",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Οι γρήγορες ταχύτητες ρολογιού και ο υψηλός αριθμός πυρήνων συνεργάζονται άψογα για να αξιοποιήσουν περισσότερο το ανταγωνιστικό gaming και τη δημιουργία σύνθετου περιεχομένου.",
                "tr_title": "Τεράστια ισχύς. Απολύτως ισορροπημένος.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 11ης γενιάς",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i9",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Η σωστή ισορροπία ταχύτητας ρολογιού και επιδόσεων πυρήνα αποδίδει περισσότερα από τα παιχνίδια σας, είτε για ανταγωνιστικό παιχνίδι με υψηλά FPS είτε για καθηλωτικές εμπειρίες AAA.",
                "tr_title": "Ισχύς με σκοπό.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 11ης γενιάς",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Παίξτε, καταγράψτε και στριμάρετε απαιτητικά παιχνίδια με υψηλά FPS και μεταβείτε εύκολα σε μεγάλους φόρτους πολυδιεργασίας.",
                "tr_title": "Ισχύς για να ξεπεράσετε τα όριά σας.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 11ης γενιάς",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Οι γρήγορες ταχύτητες ρολογιού και ο υψηλός αριθμός πυρήνων συνεργάζονται άψογα για να αξιοποιήσουν περισσότερο το ανταγωνιστικό gaming και τη δημιουργία σύνθετου περιεχομένου.",
                "tr_title": "Τεράστια ισχύς. Απολύτως ισορροπημένος.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 11ης γενιάς",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Πάτε από το στριμάρισμα ταινιών στη δημιουργία παρουσιάσεων με άφθονο απόθεμα επιδόσεων.",
                "tr_title": "Ολοήμερη αυτονομία μπαταρίας",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i3 με Intel® Hybrid Technology",
                "tr_subTitle2": "Επεξεργαστής Intel® Core™ i3 με Intel® Hybrid Technology"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Επιδόσεις για πολλές χρήσεις και μεγάλη αυτονομία μπαταρίας για να προχωράτε όπου κι αν βρίσκεστε.",
                "tr_title": "Ολοήμερη αυτονομία μπαταρίας",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i3 με Intel® Hybrid Technology",
                "tr_subTitle2": "Επεξεργαστής Intel® Core™ i3 με Intel® Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Εργαστείτε εύκολα σε πολλά προγράμματα ταυτόχρονα για να αντεπεξέρχεστε ακόμη και στην πιο δύσκολη ημέρα.",
                "tr_title": "Δυνατότητα να κάνετε πολλές εργασίες μαζί εκτός γραφείου",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 με Intel® Hybrid Technology",
                "tr_subTitle2": "Επεξεργαστής Intel® Core™ i5 με Intel® Hybrid Technology"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Αναβαθμίστε όλα όσα κάνετε, από την ψυχαγωγία έως την παραγωγικότητα και τη δημιουργία περιεχομένου.",
                "tr_title": "Κάντε αναβάθμιση σε πιο ισχυρό PC",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 με Intel® Hybrid Technology",
                "tr_subTitle2": "Επεξεργαστής Intel® Core™ i5 με Intel® Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Επιδόσεις επιπέδου desktop στο gaming συνδυασμένες με ευκολία μετακίνησης, είτε για αγωνιστικό παιχνίδι με υψηλά FPS ή καθηλωτικές εμπειρίες AAA.",
                "tr_title": "Ισχύς με σκοπό.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 11ης γενιάς",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Επιδόσεις επιπέδου desktop στο gaming συνδυασμένες με ευκολία μετακίνησης, είτε για αγωνιστικό παιχνίδι με υψηλά FPS ή καθηλωτικές εμπειρίες AAA.",
                "tr_title": "Ισχύς με σκοπό.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5 11ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Ένα laptop που μπορεί να κάνει παιχνίδι με παράλληλη καταγραφή και streaming με υψηλά FPS και να κάνει εύκολα εναλλαγή σε σενάρια με έντονη πολυδιεργασία.",
                "tr_title": "Ισχύς για να ξεπεράσετε τα όριά σας.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 11ης γενιάς",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Ένα laptop που μπορεί να κάνει παιχνίδι με παράλληλη καταγραφή και streaming με υψηλά FPS και να κάνει εύκολα εναλλαγή σε σενάρια με έντονη πολυδιεργασία.",
                "tr_title": "Ισχύς για να ξεπεράσετε τα όριά σας.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 11ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Οι γρήγορες ταχύτητες ρολογιού και ο υψηλός αριθμός πυρήνων συνεργάζονται αρμονικά και προσφέρουν καλύτερο αγωνιστικό gaming και σύνθετη δημιουργία περιεχομένου, όπου και αν παίρνετε το PC σας.",
                "tr_title": "Τεράστια ισχύς. Απολύτως ισορροπημένος.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 11ης γενιάς",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Οι γρήγορες ταχύτητες ρολογιού και ο υψηλός αριθμός πυρήνων συνεργάζονται αρμονικά και προσφέρουν καλύτερο αγωνιστικό gaming και σύνθετη δημιουργία περιεχομένου, όπου και αν παίρνετε το PC σας.",
                "tr_title": "Τεράστια ισχύς. Απολύτως ισορροπημένος.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 11ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Οι γρήγορες ταχύτητες ρολογιού, ο υψηλός αριθμός πυρήνων και οι ξεκλείδωτες επιδόσεις συνεργάζονται αρμονικά και προσφέρουν καλύτερο αγωνιστικό gaming και σύνθετη δημιουργία περιεχομένου, όπου και αν παίρνετε το PC σας.",
                "tr_title": "Ξεκλείδωτη ισχύς. Τέλεια ισορροπία.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 11ης γενιάς",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Οι γρήγορες ταχύτητες ρολογιού, ο υψηλός αριθμός πυρήνων και οι ξεκλείδωτες επιδόσεις συνεργάζονται αρμονικά και προσφέρουν καλύτερο αγωνιστικό gaming και σύνθετη δημιουργία περιεχομένου, όπου και αν παίρνετε το PC σας.",
                "tr_title": "Ξεκλείδωτη ισχύς. Τέλεια ισορροπία.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 11ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Οι γρήγορες ταχύτητες ρολογιού και ο υψηλός αριθμός πυρήνων συνεργάζονται άψογα για να αξιοποιήσουν περισσότερο το ανταγωνιστικό gaming και τη δημιουργία σύνθετου περιεχομένου.",
                "tr_title": "Τεράστια ισχύς. Απολύτως ισορροπημένος.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 11ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Οι γρήγορες ταχύτητες ρολογιού και οι πολλοί πυρήνες συνεργάζονται άψογα για ακόμα καλύτερη δημιουργία σύνθετου περιεχομένου, όπου και αν παίρνετε το PC σας.",
                "tr_title": "Τεράστια ισχύς. Απολύτως ισορροπημένος.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 11ΗΣ ΓΕΝΙΑΣ",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Οι γρήγορες ταχύτητες ρολογιού και οι πολλοί πυρήνες συνεργάζονται άψογα για ακόμα καλύτερη δημιουργία σύνθετου περιεχομένου.",
                "tr_title": "Τεράστια ισχύς. Απολύτως ισορροπημένος.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 11ΗΣ ΓΕΝΙΑΣ",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Ενισχύστε τις δυνατότητές σας με την απόλυτη εμπειρία gaming",
                "tr_title": "Συναγωνιστείτε στα καλύτερα επίπεδα",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 10ης γενιάς",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΕΠΕΞΕΡΓΑΣΤΗ INTEL® CORE™ 10ΗΣ ΓΕΝΙΑΣ ΠΟΥ ΤΑΙΡΙΑΖΕΙ ΣΤΟΝ ΤΡΟΠΟ ΠΑΙΧΝΙΔΙΟΥ ΣΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Απρόσκοπτο gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ανταγωνιστικό gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα και καταγραφή",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Προηγμένη δημιουργία περιεχομένου",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Κορυφαίες επιδόσεις στο gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ξεκλείδωτοι και με δυνατότητα υπερχρονισμού<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Ενισχύστε τις δυνατότητές σας με την απόλυτη εμπειρία gaming",
                "tr_title": "Συναγωνιστείτε στα καλύτερα επίπεδα",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 10ης γενιάς",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΕΠΕΞΕΡΓΑΣΤΗ INTEL® CORE™ 10ΗΣ ΓΕΝΙΑΣ ΠΟΥ ΤΑΙΡΙΑΖΕΙ ΣΤΟΝ ΤΡΟΠΟ ΠΑΙΧΝΙΔΙΟΥ ΣΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Απρόσκοπτο gaming AAA",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ανταγωνιστικό gaming",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Στριμάρισμα και καταγραφή",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Προηγμένη δημιουργία περιεχομένου",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Κορυφαίες επιδόσεις στο gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ξεκλείδωτοι και με δυνατότητα υπερχρονισμού<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 10<br/>Νήματα: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Για όσους είστε διαρκώς εκτός σπιτιού, ένα laptop ειδικά σχεδιασμένο για εσάς.",
                "tr_title": "Γνωρίστε μια νέα κατηγορία laptop",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 10ης γενιάς"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Οι γρήγορες ταχύτητες ρολογιού και ο υψηλός αριθμός πυρήνων συνεργάζονται άψογα για να αξιοποιήσουν περισσότερο το ανταγωνιστικό gaming και τη δημιουργία σύνθετου περιεχομένου.",
                "tr_title": "Ξεκλείδωτη ισχύς. Τέλεια ισορροπία.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 11ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Οι γρήγορες ταχύτητες ρολογιού, οι πολλοί πυρήνες και οι ξεκλείδωτες επιδόσεις αποτελούν τον τέλειο συνδυασμό για ακόμα καλύτερη δημιουργία σύνθετου περιεχομένου, όπου και αν παίρνετε το PC σας.",
                "tr_title": "Ξεκλείδωτη ισχύς. Τέλεια ισορροπία.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 11ΗΣ ΓΕΝΙΑΣ",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Οι γρήγορες ταχύτητες ρολογιού και οι πολλοί πυρήνες συνεργάζονται άψογα για ακόμα καλύτερη δημιουργία σύνθετου περιεχομένου.",
                "tr_title": "Ξεκλείδωτη ισχύς. Τέλεια ισορροπία.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 11ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 11ΗΣ ΓΕΝΙΑΣ",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έξυπνη κρυφή μνήμη Intel® <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Ομαλό gameplay και VR στα πιο απαιτητικά παιχνίδια",
                "tr_title": "Το πραγματικό gaming ξεκινά εδώ",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 9ης γενιάς",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΕΠΕΞΕΡΓΑΣΤΗ INTEL® CORE™ ΠΟΥ ΤΑΙΡΙΑΖΕΙ ΣΤΟΝ ΤΡΟΠΟ ΠΑΙΧΝΙΔΙΟΥ ΣΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ανταγωνιστικό gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Παίξτε, στριμάρετε και εγγράψτε χωρίς συμβιβασμούς",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ο καλύτερος επεξεργαστής Intel® Core™ 9ης γενιάς για gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 4<br/>Νήματα: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ΕΩΣ 32% ΠΕΡΙΣΣΟΤΕΡΑ FPS <br/><span class='tr_subtitle_font'>στο gaming με στριμάρισμα και καταγραφή, σε σύγκριση με ένα PC τριετίας<sup>3</sup></span>",
                "tr_title": "Ισχύς για να μοιράζεστε τις καλύτερες στιγμές σας στο gaming",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 9ης γενιάς",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΕΠΕΞΕΡΓΑΣΤΗ INTEL® CORE™ ΠΟΥ ΤΑΙΡΙΑΖΕΙ ΣΤΟΝ ΤΡΟΠΟ ΠΑΙΧΝΙΔΙΟΥ ΣΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ανταγωνιστικό gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Παίξτε, στριμάρετε και εγγράψτε χωρίς συμβιβασμούς",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ο καλύτερος επεξεργαστής Intel® Core™ 9ης γενιάς για gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 4<br/>Νήματα: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "ΕΩΣ 41% ΠΕΡΙΣΣΟΤΕΡΑ FPS <br/><span class='tr_subtitle_font'>στο gaming με στριμάρισμα και καταγραφή, σε σύγκριση με ένα PC τριετίας<sup>4</sup></span>",
                "tr_title": "Οι gamers απαιτούν. Ο Intel® Core™ i9 προσφέρει.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 9ης γενιάς",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>νήματα</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>πυρήνες</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Έως <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Cache <br/></span><span class='badgetitle_font'>[XX] MB</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ξεκλείδωτος",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ΒΡΕΙΤΕ ΤΟΝ ΕΠΕΞΕΡΓΑΣΤΗ INTEL® CORE™ ΠΟΥ ΤΑΙΡΙΑΖΕΙ ΣΤΟΝ ΤΡΟΠΟ ΠΑΙΧΝΙΔΙΟΥ ΣΑΣ.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i5"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i7"
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Επεξεργαστής Intel® Core™ i9"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA Gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ανταγωνιστικό gaming",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Παίξτε, στριμάρετε και εγγράψτε χωρίς συμβιβασμούς",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Ο καλύτερος επεξεργαστής Intel® Core™ 9ης γενιάς για gaming",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 4<br/>Νήματα: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 6<br/>Νήματα: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Ταχύτητα ρολογιού<sup>2</sup>: έως 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Πυρήνες: 8<br/>Νήματα: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Σχεδιασμένος για την επόμενη γενιά του gaming",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 12ης γενιάς",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5 12ΗΣ ΓΕΝΙΑΣ"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 12ης γενιάς",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 12ΗΣ ΓΕΝΙΑΣ"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 12ης γενιάς",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 12ΗΣ ΓΕΝΙΑΣ"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>πυρήνες</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>νήματα</span></span>",
                    "tr_badgePreTitle": "[p_thread] στους Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] στους Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Παιχνίδι και εργασία, στην ίδια συσκευή",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 12ης γενιάς",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 12ΗΣ ΓΕΝΙΑΣ"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 12ης γενιάς",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 12ΗΣ ΓΕΝΙΑΣ"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>πυρήνες</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>νήματα</span></span>",
                    "tr_badgePreTitle": "[p_thread] στους Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] στους Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Αξιόπιστες επιδόσεις τη στιγμή που τις χρειάζεστε περισσότερο",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i3 12ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i3 12ΗΣ ΓΕΝΙΑΣ",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Ώθηση στις επιδόσεις εκεί όπου απαιτούνται περισσότερο",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 12ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5 12ΗΣ ΓΕΝΙΑΣ",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Ώθηση στις επιδόσεις εκεί όπου απαιτούνται περισσότερο",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 12ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5 12ΗΣ ΓΕΝΙΑΣ",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Ανώτερες επιδόσεις εκεί όπου τις χρειάζεστε περισσότερο",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 12ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 12ΗΣ ΓΕΝΙΑΣ",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Ανώτερες επιδόσεις εκεί όπου τις χρειάζεστε περισσότερο",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 12ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 12ΗΣ ΓΕΝΙΑΣ",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Με τα laptop Intel® Evo™, έχετε επιδόσεις, ενσωματωμένη νοημοσύνη για σιγουριά στις βιντεοκλήσεις, ταχύτερο internet, μεγάλη διάρκεια μπαταρίας και πολλά άλλα.",
                "tr_title": "Ενίσχυση επιδόσεων, εκεί που τις χρειάζεστε περισσότερο.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 12ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5 12ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Με τα laptop Intel® Evo™, έχετε επιδόσεις, ενσωματωμένη νοημοσύνη για σιγουριά στις βιντεοκλήσεις, ταχύτερο internet, μεγάλη διάρκεια μπαταρίας και πολλά άλλα.",
                "tr_title": "Ανώτερες επιδόσεις εκεί όπου τις χρειάζεστε περισσότερο",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 12ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 12ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Με τα laptop Intel® Evo™, έχετε επιδόσεις, ενσωματωμένη νοημοσύνη για σιγουριά στις βιντεοκλήσεις, ταχύτερο internet, μεγάλη διάρκεια μπαταρίας και πολλά άλλα.",
                "tr_title": "Μέγιστες επιδόσεις σε ένα premium laptop",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 12ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 12ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Με τεχνολογία Intel® Turbo Boost 2.0 έως&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Κατασκευασμένος για επιχειρήσεις",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 12ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5 12ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Καταστολή θορύβου και θάμπωμα φόντου",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Αυτόματη προσαρμογή επιδόσεων στις συχνότερες εργασίες σας",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Απροβλημάτιστες διαδικτυακές κλήσεις υψηλής ποιότητας",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Ασφάλεια βάσει υλικού από την πρώτη στιγμή",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Δυνατότητα απομακρυσμένης διαχείρισης συσκευών",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Αυτόματη βελτιστοποίηση των επιδόσεων του Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Άριστη εκτέλεση εφαρμογών που βασίζονται σε δεδομένα",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Μέγιστη δυνατή παραγωγικότητα με βελτιωμένες επιδόσεις",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Με τεχνολογία Intel® Turbo Boost 2.0 έως&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Εργάζεται σκληρά, ώστε να μπορείτε να εστιάσετε στην επιχείρησή σας.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 12ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 12ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Καταστολή θορύβου και θάμπωμα φόντου",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Αυτόματη προσαρμογή επιδόσεων στις συχνότερες εργασίες σας",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Απροβλημάτιστες διαδικτυακές κλήσεις υψηλής ποιότητας",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Ασφάλεια βάσει υλικού από την πρώτη στιγμή",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Δυνατότητα απομακρυσμένης διαχείρισης συσκευών",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Αυτόματη βελτιστοποίηση των επιδόσεων του Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Άριστη εκτέλεση εφαρμογών που βασίζονται σε δεδομένα",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Μέγιστη δυνατή παραγωγικότητα με βελτιωμένες επιδόσεις",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Με τεχνολογία Intel® Turbo Boost 2.0 έως&nbsp;[max_turbo_frequency]&nbsp;GHz",
                "tr_title": "Επιταχύνετε την επιχείρησή σας",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 12ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 12ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Καταστολή θορύβου και θάμπωμα φόντου",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Αυτόματη προσαρμογή επιδόσεων στις συχνότερες εργασίες σας",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Απροβλημάτιστες διαδικτυακές κλήσεις υψηλής ποιότητας",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Ασφάλεια βάσει υλικού από την πρώτη στιγμή",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Δυνατότητα απομακρυσμένης διαχείρισης συσκευών",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Αυτόματη βελτιστοποίηση των επιδόσεων του Wi-Fi<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Άριστη εκτέλεση εφαρμογών που βασίζονται σε δεδομένα",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Μέγιστη δυνατή παραγωγικότητα με βελτιωμένες επιδόσεις",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Αξιόπιστες επιδόσεις για μεγαλύτερη απόδοση.",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i3 13ης γενιάς",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Επιδόσεις σε πραγματικές συνθήκες για να κάνετε περισσότερα",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 13ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5 13ΗΣ ΓΕΝΙΑΣ",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Επιδόσεις σε πραγματικές συνθήκες για να κάνετε περισσότερα",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 13ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5 13ΗΣ ΓΕΝΙΑΣ",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Ισχυρές επιδόσεις για να κάνετε περισσότερα",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 13ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 13ΗΣ ΓΕΝΙΑΣ",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Ισχυρές επιδόσεις για να κάνετε περισσότερα",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 13ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 13ΗΣ ΓΕΝΙΑΣ",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Πέρα από επιδόσεις",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 13ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 13ΗΣ ΓΕΝΙΑΣ",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>πυρήνες</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>νήματα</span></span>",
                    "tr_badgePreTitle": "[p_thread] στους Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] στους Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Συνδεθείτε απρόσκοπτα στο υπάρχον τηλέφωνό σας<sup>24</sup>, επιλέξτε έξυπνα το καλύτερο Wi-Fi και φορτίστε γρήγορα με μπαταρία που διαρκεί.",
                "tr_title": "Πηγαίνετε πέρα από τις επιδόσεις ώστε να κάνετε περισσότερα οπουδήποτε",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 13ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5 13ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Συνδεθείτε απρόσκοπτα στο υπάρχον τηλέφωνό σας<sup>24</sup>, επιλέξτε έξυπνα το καλύτερο Wi-Fi και φορτίστε γρήγορα με μπαταρία που διαρκεί.",
                "tr_title": "Προχωρήστε πέρα από τις επιδόσεις και αφήστε τον υπολογιστή σας να κάνει ακόμα περισσότερα ταυτόχρονα",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 13ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 13ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Συνδεθείτε απρόσκοπτα στο υπάρχον τηλέφωνό σας<sup>24</sup>, επιλέξτε έξυπνα το καλύτερο Wi-Fi και φορτίστε γρήγορα με μπαταρία που διαρκεί.",
                "tr_title": "Προχωρήστε πέρα από τις επιδόσεις και ολοκληρώστε γρήγορα τις πιο απαιτητικές εργασίες",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 13ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 13ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Πέρα από επιδόσεις",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 13ης γενιάς",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5 13ΗΣ ΓΕΝΙΑΣ",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 13ης γενιάς",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 13ΗΣ ΓΕΝΙΑΣ",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 13ης γενιάς",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 13ΗΣ ΓΕΝΙΑΣ",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>πυρήνες</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>νήματα</span></span>",
                    "tr_badgePreTitle": "[p_thread] στους Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] στους Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Πέρα από επιδόσεις",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>πυρήνες</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>νήματα</span></span>",
                    "tr_badgePreTitle": "[p_thread] στους Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] στους Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Αξιόπιστες επιδόσεις για μεγαλύτερη απόδοση.",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Επιδόσεις σε πραγματικές συνθήκες για να κάνετε περισσότερα",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Ισχυρές επιδόσεις για να κάνετε περισσότερα",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Πέρα από επιδόσεις",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>πυρήνες</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>νήματα</span></span>",
                    "tr_badgePreTitle": "[p_thread] στους Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] στους Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ i9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Αξιόπιστες επιδόσεις για μεγαλύτερη απόδοση.",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ 3",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ 3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ 7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Επιδόσεις σε πραγματικές συνθήκες για να κάνετε περισσότερα",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ 5",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ 5",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ 3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ 7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Ισχυρές επιδόσεις για να κάνετε περισσότερα",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ 7",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ 7",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ 3",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ 7",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "tr_title": "Παιχνίδι χωρίς συμβιβασμούς",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel®&nbsp;Core™ Ultra&nbsp;5",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ ULTRA 5",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel®&nbsp;Core™ Ultra&nbsp;7",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ ULTRA 7",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Επεξεργαστής Intel®&nbsp;Core™ Ultra&nbsp;9",
                    "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ ULTRA 9",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>πυρήνες</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core] LPE-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>νήματα</span></span>",
                    "tr_badgePreTitle": "[p_thread] στους Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] στους Efficient-cores",
                    "tr_badgeSubTitle1": "[l_thread] σε LPE-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ Ultra 5",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ Ultra 7",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Επεξεργαστής Intel® Core™ Ultra 9",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Παιχνίδι χωρίς συμβιβασμούς",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Παιχνίδι χωρίς συμβιβασμούς",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Παιχνίδι χωρίς συμβιβασμούς",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Παιχνίδι χωρίς συμβιβασμούς",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Παιχνίδι χωρίς συμβιβασμούς",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Παιχνίδι χωρίς συμβιβασμούς",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Στις μικρές επιχειρήσεις αξίζουν λύσεις που προσφέρονται στις μεγάλες επιχειρήσεις.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 13ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i5 13ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Καταστολή θορύβου και θάμπωμα φόντου",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Αυτόματη προσαρμογή επιδόσεων στις συχνότερες εργασίες σας",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Απροβλημάτιστες διαδικτυακές κλήσεις υψηλής ποιότητας",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Προστασία ασφάλειας βάσει υλικού από την πρώτη στιγμή",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Βοηθήστε στην προστασία των συσκευών και των δεδομένων από φυσικές επιθέσεις αλλοίωσης",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Στις μικρές επιχειρήσεις αξίζουν λύσεις που προσφέρονται στις μεγάλες επιχειρήσεις.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i7 13ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i7 13ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Καταστολή θορύβου και θάμπωμα φόντου",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Αυτόματη προσαρμογή επιδόσεων στις συχνότερες εργασίες σας",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Απροβλημάτιστες διαδικτυακές κλήσεις υψηλής ποιότητας",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Προστασία ασφάλειας βάσει υλικού από την πρώτη στιγμή",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Βοηθήστε στην προστασία των συσκευών και των δεδομένων από φυσικές επιθέσεις αλλοίωσης",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Στις μικρές επιχειρήσεις αξίζουν λύσεις που προσφέρονται στις μεγάλες επιχειρήσεις.",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i9 13ης γενιάς",
                "tr_gen_core_title_uppercase": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ i9 13ΗΣ ΓΕΝΙΑΣ",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΟΣ Ο ΕΠΕΞΕΡΓΑΣΤΗΣ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΟΥΣ;",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Τρέχουσα συσκευή",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Καταστολή θορύβου και θάμπωμα φόντου",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Αυτόματη προσαρμογή επιδόσεων στις συχνότερες εργασίες σας",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Απροβλημάτιστες διαδικτυακές κλήσεις υψηλής ποιότητας",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Προστασία ασφάλειας βάσει υλικού από την πρώτη στιγμή",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Βοηθήστε στην προστασία των συσκευών και των δεδομένων από φυσικές επιθέσεις αλλοίωσης",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Ξεκλειδώστε νέες εμπειρίες ΑΙ για επιχειρήσεις",
                "tr_title": "Ετοιμαστείτε για το μέλλον με τους επεξεργαστές Intel® Core™ Ultra&nbsp;[XX] και το Intel vPro®",
                "tr_gen_core_title": "Επεξεργαστής Intel® Core™ Ultra [XX]",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "ΔΩΣΤΕ ΩΘΗΣΗ ΣΤΗΝ ΕΠΙΧΕΙΡΗΣΗ ΣΑΣ ΧΡΗΣΙΜΟΠΟΙΩΝΤΑΣ PC ΜΕ AI ΑΠΟ ΤΗΝ INTEL",
                    "tr_badgeSubTitle": "Τα ισχυρά PC που δημιουργούν μια εξαιρετική εμπειρία βρίσκονται στον πυρήνα της παραγωγικότητας των επιχειρήσεων. Το Intel vPro® ενισχύει την αποτελεσματικότητα και τις επιδόσεις των εργαζομένων στο σύνολο των φόρτων εργασίας και εφαρμογών, χάρη σε μια νέα αρχιτεκτονική που προσφέρει ανώτερες επιδόσεις με ενεργειακή αποδοτικότητα για διάφορους, σύνθετους φόρτους εργασίας επιχειρήσεων στέλνοντας την κατάλληλη εργασία στην κατάλληλη μηχανή την κατάλληλη στιγμή.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "ΧΑΡΑΚΤΗΡΙΣΤΙΚΑ ΠΟΛΥΕΠΙΠΕΔΗΣ ΑΣΦΑΛΕΙΑΣ ΑΠΟ ΤΗΝ ΠΡΩΤΗ ΣΤΙΓΜΗ",
                    "tr_badgeSubTitle": "Οι νέες και αναδυόμενες απειλές απαιτούν μια ολοκληρωμένη προσέγγιση για την ασφάλεια. Το Intel vPro® παρέχει προστασία στο επίπεδο του υλικού και σε ολόκληρη τη στοίβα, ώστε να προστατεύεται καλύτερα η επιχείρησή σας. Αυτές οι μοναδικές δικλείδες ασφάλειας, συμπεριλαμβανομένων των δυνατοτήτων ανίχνευσης απειλών με τη βοήθεια τεχνητής νοημοσύνης, συμβάλλουν στην προστασία των χρηστών, των δεδομένων και της επιχείρησής σας.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "ΕΝΣΩΜΑΤΩΜΕΝΑ ΕΡΓΑΛΕΙΑ ΓΙΑ ΤΗΝ ΠΑΡΑΓΩΓΙΚΟΤΗΤΑ ΤΩΝ ΧΡΗΣΤΩΝ ΣΧΕΔΟΝ ΑΠΟ ΟΠΟΥΔΗΠΟΤΕ",
                    "tr_badgeSubTitle": "Γραφεία υπάρχουν παντού, το ίδιο και PC. Το Intel vPro® παρέχει σύγχρονες δυνατότητες διαχείρισης βάσει λογισμικού που διευκολύνουν τις επιχειρήσεις στη διαχείριση στόλων PC, απλοποιούν την υποστήριξη και βελτιώνουν την εμπειρία των τελικών χρηστών, ενώ ταυτόχρονα επιτρέπουν την εφαρμογή πρακτικών βιώσιμης χρήσης υπολογιστών.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Περισσότερο από ένα εξαιρετικό PC, ένα PC με τεχνητή νοημοσύνη",
                "tr_subtitle1": "ΜΕ ΕΠΕΞΕΡΓΑΣΤΕΣ INTEL®&nbsp;CORE™ ULTRA (SERIES&nbsp;2)",
                "tr_subtitle2": "Είναι εφικτό στο PC σας Intel AI",
                "tr_subtitle": "Ο πιο αποδοτικός, ασφαλής και υψηλής απόδοσης επεξεργαστής για φορητούς υπολογιστές της Intel<sup>39</sup>",
                "tr_gen_core_title": "Επεξεργαστής Intel®&nbsp;Core™ Ultra&nbsp;5 (Series&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Γίνετε πιο δημιουργικοί</span> με εργαλεία AI για ταχύτερη δημιουργία"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Γίνετε πιο παραγωγικοί</span> εξοικονομώντας χρόνο με τη βοήθεια του AI"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Νιώστε πιο ασφαλείς</span> με την ενσωματωμένη προστασία δεδομένων"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Περισσότερο από ένα εξαιρετικό PC, ένα PC με τεχνητή νοημοσύνη",
                "tr_subtitle1": "ΜΕ ΕΠΕΞΕΡΓΑΣΤΕΣ INTEL®&nbsp;CORE™ ULTRA (SERIES&nbsp;2)",
                "tr_subtitle2": "Είναι εφικτό στο PC σας Intel AI",
                "tr_subtitle": "Ο πιο αποδοτικός, ασφαλής και υψηλής απόδοσης επεξεργαστής για φορητούς υπολογιστές της Intel<sup>39</sup>",
                "tr_gen_core_title": "Επεξεργαστής Intel®&nbsp;Core™ Ultra&nbsp;7 (Series&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Γίνετε πιο δημιουργικοί</span> με εργαλεία AI για ταχύτερη δημιουργία"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Γίνετε πιο παραγωγικοί</span> εξοικονομώντας χρόνο με τη βοήθεια του AI"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Νιώστε πιο ασφαλείς</span> με την ενσωματωμένη προστασία δεδομένων"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Περισσότερο από ένα εξαιρετικό PC, ένα PC με τεχνητή νοημοσύνη",
                "tr_subtitle1": "ΜΕ ΕΠΕΞΕΡΓΑΣΤΕΣ INTEL®&nbsp;CORE™ ULTRA (SERIES&nbsp;2)",
                "tr_subtitle2": "Είναι εφικτό στο PC σας Intel AI",
                "tr_subtitle": "Ο πιο αποδοτικός, ασφαλής και υψηλής απόδοσης επεξεργαστής για φορητούς υπολογιστές της Intel<sup>39</sup>",
                "tr_gen_core_title": "Επεξεργαστής Intel®&nbsp;Core™ Ultra&nbsp;5 (Series&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Επεξεργαστής",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Περισσότερο από ένα εξαιρετικό PC, ένα PC με τεχνητή νοημοσύνη",
                "tr_subtitle1": "ΜΕ ΕΠΕΞΕΡΓΑΣΤΕΣ INTEL®&nbsp;CORE™ ULTRA (SERIES&nbsp;2)",
                "tr_subtitle2": "Είναι εφικτό στο PC σας Intel AI",
                "tr_subtitle": "Ο πιο αποδοτικός, ασφαλής και υψηλής απόδοσης επεξεργαστής για φορητούς υπολογιστές της Intel<sup>39</sup>",
                "tr_gen_core_title": "Επεξεργαστής Intel®&nbsp;Core™ Ultra&nbsp;7 (Series&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_tileTitle1": "Αποκλειστικά γραφικά",
                "tr_title": "ΤΙ ΕΙΝΑΙ Η ΜΟΝΑΔΑ ΕΠΕΞΕΡΓΑΣΙΑΣ ΓΡΑΦΙΚΩΝ;",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Η μονάδα επεξεργασίας γραφικών (GPU) δημιουργεί τις εικόνες που βλέπετε στην οθόνη.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Ενσωματωμένη GPU",
                    "tr_badgeSubTitle": "Μια ενσωματωμένη GPU χρησιμοποιεί την ίδια RAM με τον υπόλοιπο υπολογιστή, εξοικονομώντας ενέργεια.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Αυτόνομη GPU",
                    "tr_badgeSubTitle": "Πρόκειται για την πιο ισχυρή κατηγορία GPU καθώς διαθέτει δική της RAM και δικούς της πόρους που προορίζονται αποκλειστικά για την απόδοση κορυφαίων γραφικών.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "Υβριδική GPU",
                    "tr_badgeSubTitle": "Πρόκειται για την πιο ισχυρή κατηγορία GPU καθώς διαθέτει δική της RAM και δικούς της πόρους που προορίζονται αποκλειστικά για την απόδοση κορυφαίων γραφικών.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Βελτιωμένη επεξεργασία φωτογραφιών και βίντεο",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Απολαύστε με τις ώρες περιεχόμενο βίντεο 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Παίξτε δημοφιλή παιχνίδια με εκπληκτική λεπτομέρεια",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Διπλή GPU",
                    "tr_badgeSubTitle": "Ορισμένα συστήματα, όπως εκείνα με γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>, μπορούν να αξιοποιούν τόσο τα γραφικά επεξεργαστή όσο και την αυτόνομη GPU, ώστε να κατανέμουν τον φόρτο εργασίας γραφικών.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Ενσωματωμένη GPU",
                    "tr_badgeSubTitle": "Η ενσωματωμένη GPU βρίσκεται στο ίδιο chip με τον κύριο επεξεργαστή (CPU). Αυτός ο τύπος GPU χρησιμοποιεί την ίδια RAM και άλλους πόρους με τον υπόλοιπο υπολογιστή, αυξάνοντας την ενεργειακή αποδοτικότητά του και επιτρέποντας την κατασκευή λεπτότερων κι ελαφρύτερων συσκευών.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Αυτόνομη GPU",
                    "tr_badgeSubTitle": "Πρόκειται για την πιο ισχυρή κατηγορία GPU καθώς διαθέτει δική της RAM και δικούς της πόρους που προορίζονται αποκλειστικά για την απόδοση κορυφαίων γραφικών.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Τυπικό",
                    "tr_badgeSubTitle": "VRAM έως 4&nbsp;GB",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Αγωνιστικό",
                    "tr_badgeSubTitle": "VRAM έως 8&nbsp;GB",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Υπέρτατο",
                    "tr_badgeSubTitle": "VRAM άνω των 8&nbsp;GB",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_title": "Υψηλή ισχύς γραφικών",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Για την καλύτερη επεξεργασία γραφικών απαιτείται κάρτα γραφικών που να εστιάζει αποκλειστικά στη δημιουργία όμορφων, καθαρών εικόνων και ομαλών μεταβάσεων.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_title": "Άφθονα και ζωντανά ενσωματωμένα γραφικά",
                "tr_pretitle": "Γραφικά Intel® Iris® Plus",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Η κάρτα γραφικών Intel® Iris® Plus σας επιτρέπει να απολαύσετε βίντεο σε ανάλυση 4K HDR και παιχνίδια σε Full HD χωρίς να απαιτείται επιπλέον κάρτα γραφικών.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_title": "Εκπληκτικά γραφικά, αβίαστες επιδόσεις",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Τα Γραφικά Intel® προσφέρουν εκπληκτικές επιδόσεις ενσωματωμένων γραφικών για την υποστήριξη ρευστού σχεδιασμού για δημιουργούς και πλούσιες εμπειρίες gaming.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_title": "Γραφικά Intel® Iris® Pro",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_title": "Γραφικά UHD Intel® με αρχιτεκτονική X<sup class='small_text_sup_xe'>e</sup>",
                "tr_pretitle": "Καθηλωτικές εμπειρίες θέασης",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Βιώστε βελτιωμένη επεξεργασία φωτογραφιών και βίντεο, απολαύστε με τις ώρες υψηλής ποιότητας περιεχόμενο βίντεο 4K ή παίξτε δημοφιλή παιχνίδια με εκπληκτική λεπτομέρεια.",
                "tr_photo_text": "Βελτιωμένη επεξεργασία φωτογραφιών και βίντεο",
                "tr_video_text": "Απολαύστε με τις ώρες περιεχόμενο βίντεο 4K",
                "tr_game_text": "Παίξτε δημοφιλή παιχνίδια με εκπληκτική λεπτομέρεια",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "ΠΟΛΥ ΠΕΡΙΣΣΟΤΕΡΑ ΑΠΟ ΟΜΟΡΦΕΣ ΕΙΚΟΝΕΣ",
                "tr_subtitle": "Σε συνδυασμό με τον επεξεργαστή (ή CPU), η κάρτα γραφικών (ή GPU) καθορίζει πώς εκτελούνται και πώς εμφανίζονται τα παιχνίδια σας. Η VRAM (Video RAM), αφιερωμένη αποκλειστικά στη δημιουργία γραφικών, είναι ένας γρήγορος τρόπος σύγκρισης της GPU σας.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "ΓΙΑ ΕΝΤΟΝΑ ΓΡΑΦΙΚΑ GAMING",
                "tr_subtitle": "Για την καλύτερη επεξεργασία γραφικών, απαιτείται χωριστή κάρτα γραφικών που να εστιάζει αποκλειστικά στη δημιουργία όμορφων, καθαρών εικόνων και ομαλών μεταβάσεων",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "ΓΙΑ ΕΝΤΟΝΑ ΓΡΑΦΙΚΑ GAMING",
                "tr_subtitle": "Για την καλύτερη επεξεργασία γραφικών, απαιτείται χωριστή κάρτα γραφικών που να εστιάζει αποκλειστικά στη δημιουργία όμορφων, καθαρών εικόνων και ομαλών μεταβάσεων",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "ΠΟΛΥ ΠΕΡΙΣΣΟΤΕΡΑ ΑΠΟ ΟΜΟΡΦΕΣ ΕΙΚΟΝΕΣ",
                "tr_subtitle": "Σε συνδυασμό με τον επεξεργαστή (ή CPU), η κάρτα γραφικών (ή GPU) καθορίζει πώς εκτελούνται και πώς εμφανίζονται τα παιχνίδια σας. Η VRAM (Video RAM), αφιερωμένη αποκλειστικά στη δημιουργία γραφικών, είναι ένας γρήγορος τρόπος σύγκρισης της GPU σας.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_title": "Υψηλή ισχύς γραφικών",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Για την καλύτερη επεξεργασία γραφικών απαιτείται κάρτα γραφικών που να εστιάζει αποκλειστικά στη δημιουργία όμορφων, καθαρών εικόνων και ομαλών μεταβάσεων.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "ΓΙΑ ΕΝΤΟΝΑ ΓΡΑΦΙΚΑ GAMING",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "Για την καλύτερη επεξεργασία γραφικών απαιτείται κάρτα γραφικών που να εστιάζει αποκλειστικά στη δημιουργία όμορφων, καθαρών εικόνων και ομαλών μεταβάσεων.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "Για την καλύτερη επεξεργασία γραφικών, απαιτείται χωριστή κάρτα γραφικών που να εστιάζει αποκλειστικά στη δημιουργία όμορφων, καθαρών εικόνων και ομαλών μεταβάσεων",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Προσφέρει άφθονη ισχύ στα γραφικά για να ικανοποιεί τις ελάχιστες απαιτήσεις του παιχνιδιού.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Για απαιτητικούς παίκτες που ζητούν παιχνίδια ανταγωνιστικού επιπέδου",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Για προχωρημένους gamer που χρησιμοποιούν το PC τους και για απαιτητική δημιουργία περιεχομένου",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Ζωντανό video και καθηλωτικό gaming",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Εκμεταλλευτείτε την οθόνη υψηλής ανάλυσης με τα Γραφικά HD Intel® και απολαύστε μια πλούσια εμπειρία πολυμέσων και μονάδα γραφικών ενσωματωμένη στον επεξεργαστή.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Βίντεο και εικόνες που μοιάζουν αληθινά",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Δείτε περισσότερες λεπτομέρειες και εκπληκτικά χρώματα στα βίντεο που στριμάρετε και στις φωτογραφίες σας.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_title": "Καταπληκτική δημιουργία και gaming",
                "tr_pretitle": "Γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Από την καθηλωτική ψυχαγωγία και την προηγμένη δημιουργία περιεχομένου έως το mainstream gaming, τα γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> προσφέρουν πρωτοποριακές επιδόσεις γραφικών σε λεπτά κι ελαφριά PC.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_pretitle": "ΓΙΑ ΕΝΤΟΝΑ ΓΡΑΦΙΚΑ GAMING",
                "tr_title": "Γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup>",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "Για την καλύτερη επεξεργασία γραφικών, απαιτείται χωριστή κάρτα γραφικών που να εστιάζει αποκλειστικά στη δημιουργία όμορφων, καθαρών εικόνων και ομαλών μεταβάσεων",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM<br/></span><span class='value'>[x] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_title": "Απελευθερώστε τη δημιουργικότητά σας",
                "tr_pretitle": "Γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Ολοκληρώστε δημιουργικά πρότζεκτ με ευκολία και βυθιστείτε σε μεγάλη ποικιλία παιχνιδιών με τη συνδυασμένη ισχύ των γραφικών επεξεργαστή και της πρώτης μηχανής αυτόνομων γραφικών Intel®.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "tr_title": "Γραφικά Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX",
                "tr_pretitle": "ΓΙΑ ΕΝΤΟΝΑ ΓΡΑΦΙΚΑ GAMING",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "Για την καλύτερη επεξεργασία γραφικών, απαιτείται χωριστή κάρτα γραφικών που να εστιάζει αποκλειστικά στη δημιουργία όμορφων, καθαρών εικόνων και ομαλών μεταβάσεων",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='subtitle-value'>VRAM<br/></span><span class='value'>[x] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Γραφικά",
                "tr_title": "Καταπληκτικά γραφικά στον επεξεργαστή",
                "tr_subtitle": "Απολαύστε παιχνίδια, ταινίες και διαδικτυακά video με εκπληκτική λεπτομέρεια και ευκρίνεια.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Γραφικά",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Γραφικά",
                    "tr_specText": "Ενισχύστε τις ροές εργασίας σας"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Γραφικά",
                    "tr_specText": "Αναβαθμίστε το gaming"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Γραφικά",
                    "tr_specText": "Αναβαθμίστε το gaming"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Γραφικά",
                    "tr_specText": "Ενισχύστε το παιχνίδι σας"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "ΑΠΕΛΕΥΘΕΡΩΣΤΕ ΤΗ ΔΗΜΙΟΥΡΓΙΚΟΤΗΤΑ ΣΑΣ",
                    "tr_specText": "Δώστε ώθηση στην παραγωγικότητά σας όπου και αν βρίσκεστε με τις πιο ολοκληρωμένες δυνατότητες πολυμέσων, όπως η <strong>κωδικοποίηση υλικού AV1 για πρώτη φορά στον κλάδο</strong> και η επιταχυνόμενη από τεχνητή νοημοσύνη δημιουργία περιεχομένου.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "ΑΠΕΛΕΥΘΕΡΩΣΤΕ ΤΗ ΔΗΜΙΟΥΡΓΙΚΟΤΗΤΑ ΣΑΣ",
                    "tr_specText": "Δώστε ώθηση στην παραγωγικότητά σας με τις πιο ολοκληρωμένες δυνατότητες πολυμέσων, όπως η <strong>κωδικοποίηση υλικού AV1 για πρώτη φορά στον κλάδο</strong> και η επιταχυνόμενη από τεχνητή νοημοσύνη δημιουργία περιεχομένου.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "ΑΠΕΛΕΥΘΕΡΩΣΤΕ ΤΗ ΔΗΜΙΟΥΡΓΙΚΟΤΗΤΑ ΣΑΣ",
                    "tr_specText": "Απολαύστε την πρωτοποριακή εμπειρία επιταχυνόμενης από τεχνητή νοημοσύνη δημιουργίας περιεχομένου με την <strong>κωδικοποίηση υλικού AV1 για πρώτη φορά στον κλάδο</strong> και την προηγμένη μηχανή πολυμέσων <strong>X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "ΑΠΕΛΕΥΘΕΡΩΣΤΕ ΤΗ ΔΗΜΙΟΥΡΓΙΚΟΤΗΤΑ ΣΑΣ",
                    "tr_specText": "Απολαύστε την πρωτοποριακή εμπειρία επιταχυνόμενης από τεχνητή νοημοσύνη δημιουργίας περιεχομένου με την <strong>κωδικοποίηση υλικού AV1 για πρώτη φορά στον κλάδο</strong> και την προηγμένη μηχανή πολυμέσων <strong>X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "ΑΠΕΛΕΥΘΕΡΩΣΤΕ ΤΗ ΔΗΜΙΟΥΡΓΙΚΟΤΗΤΑ ΣΑΣ",
                    "tr_specText": "Απολαύστε την πρωτοποριακή εμπειρία επιταχυνόμενης από τεχνητή νοημοσύνη δημιουργίας περιεχομένου με την <strong>κωδικοποίηση υλικού AV1 για πρώτη φορά στον κλάδο</strong> και την προηγμένη μηχανή πολυμέσων <strong>X<sup>e</sup>.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "Η ΦΟΡΗΤΟΤΗΤΑ ΣΥΝΑΝΤΑ ΤΙΣ ΥΨΗΛΕΣ ΕΠΙΔΟΣΕΙΣ",
                    "tr_specText": "Παίξτε τα τελευταία παιχνίδια εν κινήσει και απολαύστε υπερενισχυμένες επιδόσεις και πιστότητα με σύγχρονες δυνατότητες gaming. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Αναβαθμισμένη τεχνολογία Intel® βελτιωμένη με AI για υψηλότερες επιδόσεις και ομαλό gaming. <br/><br/>Υποστήριξη <strong>DirectX* 12 Ultimate:  </strong>Αναβαθμίστε το gaming σε ένα νέο επίπεδο ρεαλισμού με τεχνολογίες γραφικών, όπως η ανίχνευση ακτίνων και η σκίαση μεταβλητού ρυθμού."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "ΠΑΡΤΕ ΤΟΝ ΕΛΕΓΧΟ ΤΩΝ ΠΑΙΧΝΙΔΙΩΝ ΣΑΣ",
                    "tr_specText": "Το <strong>Κέντρο ελέγχου Intel® Arc™</strong> σάς επιτρέπει να βελτιώνετε την εμπειρία σας με μια διαισθητική και σύγχρονη διάταξη. Αποκτήστε απρόσκοπτες ενημερώσεις προγραμμάτων οδήγησης, βελτιώστε τα παιχνίδια σας και πολλά άλλα."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "ΠΑΡΤΕ ΤΟΝ ΕΛΕΓΧΟ ΤΩΝ ΠΑΙΧΝΙΔΙΩΝ ΣΑΣ",
                    "tr_specText": "Το <strong>Κέντρο ελέγχου Intel® Arc™</strong> σάς επιτρέπει να βελτιώνετε την εμπειρία σας με μια διαισθητική και σύγχρονη διάταξη. Αποκτήστε απρόσκοπτες ενημερώσεις προγραμμάτων οδήγησης, βελτιώστε τα παιχνίδια σας και πολλά άλλα."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "ΠΑΡΤΕ ΤΟΝ ΕΛΕΓΧΟ ΤΩΝ ΠΑΙΧΝΙΔΙΩΝ ΣΑΣ",
                    "tr_specText": "Αποκτήστε απρόσκοπτες ενημερώσεις προγραμμάτων οδήγησης και βελτιώστε πολλά από τα αγαπημένα σας παιχνίδια με το λογισμικό <strong>Κέντρο ελέγχου Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "ΜΕΓΙΣΤΟΠΟΙΗΣΤΕ ΤΟ ΔΥΝΑΜΙΚΟ ΤΗΣ ΠΛΑΤΦΟΡΜΑΣ INTEL®",
                    "tr_specText": "Συνδυάστε με συμβατούς επεξεργαστές Intel® Core™ για να απελευθερώσετε την <strong>τεχνολογία Intel® Deep Link</strong> και να βελτιώσετε τη δημιουργικότητα, το gaming και την εμπειρία στριμαρίσματος."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "ΜΕΓΙΣΤΟΠΟΙΗΣΤΕ ΤΟ ΔΥΝΑΜΙΚΟ ΤΟΥ ΣΥΣΤΗΜΑΤΟΣ INTEL®",
                    "tr_specText": "Συνδυάστε με συμβατούς επεξεργαστές Intel® Core™ για να απελευθερώσετε την <strong>τεχνολογία Intel® Deep Link</strong> και να βελτιώσετε τη δημιουργικότητα, το gaming και την εμπειρία στριμαρίσματος."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "ΜΕΓΙΣΤΟΠΟΙΗΣΤΕ ΤΟ ΔΥΝΑΜΙΚΟ ΤΗΣ ΠΛΑΤΦΟΡΜΑΣ INTEL®",
                    "tr_specText": "Συνδυάστε με συμβατούς επεξεργαστές Intel® Core™ για να απελευθερώσετε την <strong>τεχνολογία Intel® Deep Link</strong> και να βελτιώσετε τη δημιουργικότητα, το gaming και την εμπειρία στριμαρίσματος."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "ΜΕΓΙΣΤΟΠΟΙΗΣΤΕ ΤΟ ΔΥΝΑΜΙΚΟ ΤΗΣ ΠΛΑΤΦΟΡΜΑΣ INTEL®",
                    "tr_specText": "Συνδυάστε με συμβατούς επεξεργαστές Intel® Core™ για να απελευθερώσετε την <strong>τεχνολογία Intel® Deep Link</strong> και να βελτιώσετε τη δημιουργικότητα, το gaming και την εμπειρία στριμαρίσματος."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "ΜΕΓΙΣΤΟΠΟΙΗΣΤΕ ΤΟ ΔΥΝΑΜΙΚΟ ΤΟΥ ΣΥΣΤΗΜΑΤΟΣ INTEL®",
                    "tr_specText": "Συνδυάστε με συμβατούς επεξεργαστές Intel® Core™ για να απελευθερώσετε την <strong>τεχνολογία Intel® Deep Link</strong> και να βελτιώσετε τη δημιουργικότητα, το gaming και την εμπειρία στριμαρίσματος."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "ΒΡΕΘΕΙΤΕ ΣΤΟ ΠΡΟΣΚΗΝΙΟ",
                    "tr_specText": "Απρόσκοπτη μετάδοση με προηγμένες τεχνολογίες στριμαρίσματος χάρη στο λογισμικό <strong>Κέντρο ελέγχου Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "ΒΡΕΘΕΙΤΕ ΣΤΟ ΠΡΟΣΚΗΝΙΟ",
                    "tr_specText": "Απρόσκοπτη μετάδοση με προηγμένες τεχνολογίες στριμαρίσματος χάρη στο λογισμικό <strong>Κέντρο ελέγχου Intel® Arc™</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "ΑΠΟΛΑΥΣΤΕ ΚΑΘΗΛΩΤΙΚΟ GAMING ΥΨΗΛΩΝ ΕΠΙΔΟΣΕΩΝ",
                    "tr_specText": "Δώστε προτεραιότητα στις καλύτερες επιδόσεις σας με τα τελευταία παιχνίδια AAA σε υψηλές αναλύσεις και υψηλούς ρυθμούς ανανέωσης. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Αναβαθμισμένη τεχνολογία Intel® βελτιωμένη με AI για υψηλότερες επιδόσεις και ομαλό gaming. <br/><br/>Υποστήριξη <strong>DirectX* 12 Ultimate:  </strong>Αναβαθμίστε το gaming σε ένα νέο επίπεδο ρεαλισμού με τεχνολογίες γραφικών, όπως η ανίχνευση ακτίνων και η σκίαση μεταβλητού ρυθμού."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "ΑΠΟΛΑΥΣΤΕ ΚΑΘΗΛΩΤΙΚΟ GAMING ΥΨΗΛΩΝ ΕΠΙΔΟΣΕΩΝ",
                    "tr_specText": "Δώστε προτεραιότητα στις καλύτερες επιδόσεις σας με τα τελευταία παιχνίδια AAA σε υψηλές αναλύσεις και υψηλούς ρυθμούς ανανέωσης. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Αναβαθμισμένη τεχνολογία Intel® βελτιωμένη με AI για υψηλότερες επιδόσεις και ομαλό gaming. <br/><br/>Υποστήριξη <strong>DirectX* 12 Ultimate:  </strong>Αναβαθμίστε το gaming σε ένα νέο επίπεδο ρεαλισμού με τεχνολογίες γραφικών, όπως η ανίχνευση ακτίνων και η σκίαση μεταβλητού ρυθμού."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "ΔΩΣΤΕ ΩΘΗΣΗ ΣΤΑ ΠΑΙΧΝΙΔΙΑ ΣΑΣ",
                    "tr_specText": "Αναβαθμίστε την εμπειρία gaming με το: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Αναβαθμισμένη τεχνολογία Intel® βελτιωμένη με AI για καλύτερες επιδόσεις και ομαλό παιχνίδι. <br/><br/>Υποστήριξη <strong>DirectX* 12 Ultimate:  </strong>Αναβαθμίστε το gaming σε ένα νέο επίπεδο ρεαλισμού με τεχνολογίες γραφικών, όπως η ανίχνευση ακτίνων και η σκίαση μεταβλητού ρυθμού."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "ΑΠΟΛΑΥΣΤΕ ΠΡΟΗΓΜΕΝΟ GAMING ΣΕ ΓΡΗΓΟΡΟ ΡΥΘΜΟ",
                    "tr_specText": "Απολαύστε ομαλό gaming σε πολλά δημοφιλή παιχνίδια AAA και gaming με υψηλό ρυθμό ανανέωσης από esports shooters υψηλών οκτανίων σε γρήγορα χτυπήματα RTS. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Αναβαθμισμένη τεχνολογία Intel® βελτιωμένη με AI για υψηλότερες επιδόσεις και ομαλό gaming. <br/><br/>Υποστήριξη <strong>DirectX* 12 Ultimate:  </strong>Αναβαθμίστε το gaming σε ένα νέο επίπεδο ρεαλισμού με τεχνολογίες γραφικών όπως η ανίχνευση ακτίνων και η σκίαση μεταβλητού ρυθμού."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "ΑΠΟΛΑΥΣΤΕ ΚΑΘΗΛΩΤΙΚΟ GAMING ΥΨΗΛΩΝ ΕΠΙΔΟΣΕΩΝ",
                    "tr_specText": "Δώστε προτεραιότητα στις καλύτερες επιδόσεις σας με τα τελευταία παιχνίδια AAA σε υψηλές αναλύσεις και υψηλούς ρυθμούς ανανέωσης. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Αναβαθμισμένη τεχνολογία Intel® βελτιωμένη με AI για υψηλότερες επιδόσεις και ομαλό gaming. <br/><br/>Υποστήριξη <strong>DirectX* 12 Ultimate:  </strong>Αναβαθμίστε το gaming σε ένα νέο επίπεδο ρεαλισμού με τεχνολογίες γραφικών, όπως η ανίχνευση ακτίνων και η σκίαση μεταβλητού ρυθμού."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Γραφικά",
                    "tr_specText": "Ένα σύμπαν γραφικών με χώρο για όλους"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "ΕΠΙΤΑΧΥΝΕΤΕ ΤΑ ΚΑΡΕ ΣΑΣ, ΕΠΙΤΑΧΥΝΕΤΕ ΤΑ ΠΑΙΧΝΙΔΙΑ ΣΑΣ",
                    "tr_specText": "Η τεχνολογία Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling (X<sup class='small_text_sup_xe'>e</sup>SS) ανεβάζει το gaming στο επόμενο επίπεδο με την τεχνολογία αναβάθμισης ενισχυμένης με τεχνητή νοημοσύνη - για κορυφαίες επιδόσεις με υψηλή πιστότητα εικόνας. Το X<sup class='small_text_sup_xe'>e</sup>SS είναι βελτιστοποιημένο για τα προϊόντα γραφικών Intel® Arc™, ώστε να μπορείτε να επωφεληθείτε πλήρως από την επιτάχυνση υλικού Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX)."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "ΤΑ ΑΠΟΛΥΤΑ ΓΡΑΦΙΚΑ ΕΙΝΑΙ ΕΔΩ",
                    "tr_specText": "Με πλήρη υποστήριξη του DirectX* 12 Ultimate σε όλα τα γραφικά Intel® Arc™, θα απολαμβάνετε εκπληκτικό, καθηλωτικό παιχνίδι χάρη στις τελευταίες τεχνολογίες γραφικών, όπως ray tracing, variable rate shading, mesh shading και sampler feedback - τα βασικά στοιχεία του gaming επόμενης γενιάς."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "ΑΠΡΟΣΚΟΠΤΗ ΔΗΜΙΟΥΡΓΙΑ ΦΩΤΟΓΡΑΦΙΩΝ, ΒΙΝΤΕΟ ΚΑΙ ΠΟΛΥΜΕΣΩΝ",
                    "tr_specText": "Απελευθερώστε τη φαντασία σας και γοητεύστε το κοινό σας με τη δημιουργία πλούσιου ψηφιακού περιεχομένου χρησιμοποιώντας μια υπερ-προηγμένη μηχανή πολυμέσων, ενισχυμένη με τεχνητή νοημοσύνη και επιταχυνόμενη με την τεχνολογία Intel®. Δημιουργήστε θεαματικό περιεχόμενο με γραφικά που υποστηρίζουν όλες τις τρέχουσες κορυφαίες μορφές πολυμέσων, ενώ παράλληλα συμβαδίζετε με τις πιο προηγμένες δυνατότητες κωδικοποίησης βίντεο AV1."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "ΓΝΩΡΙΣΤΕ ΤΗ ΔΗΜΙΟΥΡΓΙΑ ΚΑΙ ΤΟ GAMING ΜΕ ΤΕΧΝΗΤΗ ΝΟΗΜΟΣΥΝΗ ΑΙΧΜΗΣ",
                    "tr_specText": "Τα γραφικά Intel® Arc™ ξεκλειδώνουν ισχυρές δυνατότητες τεχνητής νοημοσύνης, από την προηγμένη δημιουργία έως το καθηλωτικό gaming. Αξιοποιήστε στο έπακρο το σημερινό προηγμένο λογισμικό δημιουργίας, συμπεριλαμβανομένης της δημιουργίας κειμένου σε εικόνα και της επεξεργασίας βίντεο επιταχυνόμενη από τεχνητή νοημοσύνη. Επιπλέον, απολαύστε παιχνίδι υψηλής ανάλυσης μέσω αναβάθμισης X<sup class='small_text_sup_xe'>e</sup>SS επιταχυνόμενης από τεχνητή νοημοσύνη."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Γραφικά",
                    "tr_specText": "Τα επαγγελματικά γραφικά ξεκινούν από εδώ"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "ΙΔΑΝΙΚΟ ΓΙΑ ΔΗΜΙΟΥΡΓΟΥΣ, ΣΧΕΔΙΑΣΤΕΣ ΚΑΙ ΜΗΧΑΝΙΚΟΥΣ ΠΟΥ ΕΡΓΑΖΟΝΤΑΙ ΠΑΝΤΟΥ",
                    "tr_specText": "Διαθέσιμα σε επιλεγμένους επεξεργαστές Intel®&nbsp;Core™&nbsp;Ultra&nbsp;H&#8209;series, τα ενσωματωμένα γραφικά Intel®&nbsp;Arc™&nbsp;Pro προσφέρουν βελτιωμένες επιδόσεις γραφικών κατά 2 φορές με πιστοποιήσεις ανεξάρτητου πωλητή λογισμικού (ISV) για επιπλέον αξιοπιστία και παρέχουν αποτελεσματική επιτάχυνση υλικού τεχνητής νοημοσύνης και ανίχνευσης ακτίνων για εκπληκτική δημιουργία περιεχομένου παντού.<br/><br/><span class='expandable_heading'>Πιστοποιήσεις λογισμικού<br/></span><br/>Η Intel έχει συνεργαστεί στενά με εκατοντάδες εταιρείες λογισμικού όλα αυτά τα χρόνια και αυτή η απαράμιλλη εμπειρία έχει ενσωματωθεί στις κάρτες γραφικών Intel®&nbsp;Arc™&nbsp;Pro&nbsp;A&#8209;series. Οι πιστοποιήσεις είναι τόσο σημαντικές για εμάς όσο και για εσάς."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "ΚΑΝΟΝΤΑΣ ΤΗΝ ΤΕΧΝΗΤΗ ΝΟΗΜΟΣΥΝΗ ΕΦΙΚΤΗ",
                    "tr_specText": "Αυτή η σειρά GPU περιλαμβάνει μια ευρεία επιλογή εξαιρετικών τεχνολογιών, συμπεριλαμβανομένης της υποστήριξης των συμβατών εργαλείων τεχνητής νοημοσύνης με ενσωματωμένη ειδική επιτάχυνση τεχνητής νοημοσύνης όπου τη χρειάζεστε."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING ΧΩΡΙΣ PREMIUM",
                    "tr_specText": "Οι κάρτες γραφικών πρέπει να κάνουν περισσότερα από το να μεταφέρουν απλώς pixel στις οθόνες, πρέπει επίσης να ενισχύουν τη δημιουργία όμορφων εικόνων. Η τεχνολογία υλικού ray tracing που είναι ενσωματωμένη στα γραφικά Intel® Arc™ Pro A-series, επιτρέπει στο συμβατό λογισμικό σας να αρχίσει να εξερευνά αυτές τις νέες επαγγελματικές ροές εργασίας. Το ray tracing θεωρείται συνήθως διαθέσιμο μόνο στα ακριβά γραφικά, αλλά η τελευταία σειρά επαγγελματικών γραφικών της Intel καταρρίπτει αυτήν την αντίληψη."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "ΕΝΙΣΧΥΣΗ ΤΩΝ ΔΗΜΙΟΥΡΓΩΝ ΜΕ ΚΑΘΗΛΩΤΙΚΕΣ ΟΘΟΝΕΣ",
                    "tr_specText": "Με πλήρη υποστήριξη του DirectX* 12 Ultimate σε όλα τα γραφικά Intel® Arc™, θα απολαμβάνετε εκπληκτικό, καθηλωτικό παιχνίδι χάρη στις τελευταίες τεχνολογίες γραφικών, όπως ray tracing, variable rate shading, mesh shading και sampler feedback - τα βασικά στοιχεία του gaming επόμενης γενιάς."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "ΔΥΝΑΜΙΚA ΒΕΛΤΙΣΤΟΠΟΙΗΜEΝΕΣ ΕΙΚOΝΕΣ",
                    "tr_specText": "Με αποκλειστική αποκωδικοποίηση υλικού στις GPU Pro, το Dolby Vision* συμβάλλει στην προσθήκη μεγαλύτερου βάθους, ευκρινέστερης αντίθεσης και περισσότερων χρωμάτων στη συμβατή οθόνη σας. Το Dolby Vision* απελευθερώνει το πλήρες δυναμικό της τεχνολογίας HDR, βελτιστοποιώντας δυναμικά την ποιότητα της εικόνας ανάλογα με την υπηρεσία, τη συσκευή προβολής και την πλατφόρμα σας, ώστε να προσφέρει εκπληκτικές εικόνες κάθε φορά."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "ΕΠΑΓΓΕΛΜΑΤΙΚΑ ΕΠΑΛΗΘΕΥΜΕΝΟ ΛΟΓΙΣΜΙΚΟ",
                    "tr_specText": "Η Intel έχει συνεργαστεί στενά με εκατοντάδες εταιρείες λογισμικού όλα αυτά τα χρόνια και αυτή η απαράμιλλη εμπειρία ενσωματώνεται στην επαγγελματική σειρά γραφικών Intel® Arc™ Pro A-series. Οι πιστοποιήσεις και οι προδιαγραφές ροής λογισμικού είναι εξίσου σημαντικές για εμάς όσο και για εσάς."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING ΧΩΡΙΣ PREMIUM",
                    "tr_specText": "Οι κάρτες γραφικών πρέπει να κάνουν περισσότερα από το να μεταφέρουν απλώς pixel στις οθόνες, πρέπει επίσης να ενισχύουν τη δημιουργία όμορφων εικόνων. Η τεχνολογία υλικού ray tracing που είναι ενσωματωμένη στα γραφικά Intel® Arc™ Pro A-series, επιτρέπει στο συμβατό λογισμικό σας να αρχίσει να εξερευνά αυτές τις νέες επαγγελματικές ροές εργασίας. Το ray tracing θεωρείται συνήθως διαθέσιμο μόνο στα ακριβά γραφικά, αλλά η τελευταία σειρά επαγγελματικών γραφικών της Intel καταρρίπτει αυτήν την αντίληψη."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "ΠΟΛΛΑΠΛΕΣ ΜΕΓΑΛΕΣ ΟΘΟΝΕΣ ΜΕ ΜΙΑ ΜΙΚΡΗ GPU ΓΙΑ ΦΟΡΗΤΕΣ ΣΥΣΚΕΥΕΣ",
                    "tr_specText": "Τα γραφικά Intel® Arc™ Pro για laptop μπορούν να υποστηρίξουν έως και τέσσερις υπερμεγέθεις οθόνες για να ενισχύσουν την αποδοτικότητα της ροής εργασίας σας. Προσθέστε και την υποστήριξη υψηλού δυναμικού εύρους (HDR) και έχετε όλα όσα χρειάζεστε για την επόμενη εγκατάσταση πολλαπλών οθονών στο σπίτι ή το γραφείο που θα ξεχωρίζει για τα μέσα κοινωνικής δικτύωσης."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "ΠΛΗΡΕΣ ΔΥΝΑΜΙΚΟ ΕΥΡΟΥΣ ΖΩΝΗΣ ΜΝΗΜΗΣ",
                    "tr_specText": "Το εύρος ζώνης της κάρτας γραφικών σας είναι ένα σημαντικό στοιχείο σε έναν σταθμό εργασίας. Αν το κάνετε λάθος, μπορεί να επιβραδύνετε τις επαγγελματικές ροές εργασίας, γι' αυτό και φροντίσαμε η GPU Intel® Arc™ Pro A-series να υποστηρίζει το σύγχρονο PCIe* 4.0, χωρίς να υποβαθμίζεται η συμβατότητα με τα προηγούμενα μοντέλα. Η μνήμη γραφικών ενισχύεται περαιτέρω με ταχύτητα υψηλού εύρους ζώνης, επιτρέποντας την ακόμα ταχύτερη πρόσβαση του λογισμικού σας στα δεδομένα του έργου."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "ΔΩΣΤΕ ΖΩΗ ΣΕ ΚΑΘΕ ΚΑΡΕ",
                    "tr_specText": "<span class='expandable_heading'>Ενισχυμένη με AI. Με την υποστήριξη της Intel.<br/></span> <br/>Το Intel® X<sup class='small_text_sup_xe'>e</sup>SS είναι μια τεχνολογία αναβάθμισης ενισχυμένης με τεχνητή νοημοσύνη που ενισχύει τις επιδόσεις σας χωρίς να θυσιάζει την ομορφιά κάθε καρέ, ώστε να μπορείτε να βιώσετε το παιχνίδι σας με τον τρόπο που έχει σχεδιαστεί."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "ΠΡΑΓΜΑΤΙΚΟΣ ΧΡΟΝΟΣ.<br/> ΠΡΑΓΜΑΤΙΚΑ ΚΑΘΗΛΩΤΙΚΟ.<br/> Ο ΡΕΑΛΙΣΜΟΣ ΣΤΑ ΚΑΛΥΤΕΡΑ ΤΟΥ.",
                    "tr_specText": "Σας περιμένουν πιο ρεαλιστικοί κόσμοι. Με την ενσωματωμένη ανίχνευση ακτίνων, θα βιώσετε τα πάντα, από ζωντανές σκιές και αντανακλάσεις μέχρι άψογο φωτισμό. Με άλλα λόγια, πρόκειται για μια οπτική εμπειρία gaming που δεν θα θέλετε να χάσετε."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "Η ΚΑΛΥΤΕΡΗ ΕΜΠΕΙΡΙΑ ΠΑΙΧΝΙΔΙΟΥ, ΜΕ ΤΟΝ ΚΑΛΥΤΕΡΟ ΤΡΟΠΟ",
                    "tr_specText": "Δώστε στο κοινό σας την απρόσκοπτη εμπειρία παιχνιδιού που του αξίζει. Θα καθηλωθούν από την κάθε σας κίνηση, χάρη στις δυνατότητες κωδικοποίησης AV1 με επιτάχυνση υλικού των γραφικών Intel® Arc™. Η υψηλότερη οπτική ποιότητα με τον ίδιο ρυθμό μετάδοσης ισοδυναμεί με το καλύτερό σας στριμάρισμα, εξοικονομώντας παράλληλα εύρος ζώνης."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Καλωσορίστε όλους τους δημιουργούς",
                    "tr_specText": "Νέες και βελτιωμένες εμπειρίες τεχνητής νοημοσύνης είναι διαθέσιμες στα χέρια σας. Με τους προηγμένους επιταχυντές τεχνητής νοημοσύνης που είναι ενσωματωμένοι στα γραφικά του Intel® Arc™ A-series, αφιερώστε περισσότερο χρόνο στη δημιουργία και λιγότερο χρόνο στην αναμονή σε πολλές εφαρμογές.<br/><br/>Απογειώστε τη δημιουργία βίντεο στο επόμενο επίπεδο και πρωτοστατήστε με βίντεο AV1 υψηλής ποιότητας και αποδοτικής αποθήκευσης, το οποίο υποστηρίζεται από τις προηγμένες δυνατότητες υλικού AV1 που είναι ενσωματωμένες στα γραφικά Intel® Arc™."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Λειτουργικό σύστημα",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Όλα όσα χρειάζεστε για να ολοκληρώνετε τις εργασίες σας",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Ισχυρές επιδόσεις με λεπτή, ελαφριά και ανθεκτική σχεδίαση ώστε να τα έχετε όλα. Με εξαιρετική διάρκεια ζωής μπαταρίας, όμορφες οθόνες αφής,* και τις πιο πρόσφατες μονάδες αποθήκευσης σταθερής κατάστασης (SSD), θα βρείτε αυτό που αναζητάτε σε έναν υπολογιστή με Windows 10.",
                "tr_noteSection": "*Εξαρτάται από το υλικό",
                "titleArray": [
                  {
                    "tr_title": "Όσο λιγότερα, τόσο καλύτερα",
                    "tr_subtitle": "Σήμερα, τα PC συνήθως καταναλώνουν λιγότερη ενέργεια, ώστε να απολαμβάνετε μεγαλύτερη αυτονομία μπαταρίας –κατά μέσο όρο, πάνω από οκτώ ώρες αναπαραγωγής βίντεο.**",
                    "tr_noteSection": "**Η αυτονομία μπαταρίας διαφέρει σημαντικά ανάλογα με τις ρυθμίσεις, τη χρήση και άλλους παράγοντες."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Λειτουργικό σύστημα",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "ΟΛΑ ΟΣΑ ΧΡΕΙΑΖΕΣΤΕ ΓΙΑ ΝΑ ΟΛΟΚΛΗΡΩΝΕΤΕ ΤΙΣ ΕΡΓΑΣΙΕΣ ΣΑΣ",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Ισχυρές επιδόσεις με λεπτή, ελαφριά και ανθεκτική σχεδίαση ώστε να τα έχετε όλα. Με εξαιρετική διάρκεια ζωής μπαταρίας, όμορφες οθόνες αφής,* και τις πιο πρόσφατες μονάδες αποθήκευσης σταθερής κατάστασης (SSD), θα βρείτε αυτό που αναζητάτε σε έναν υπολογιστή με Windows 10.",
                "tr_noteSection": "*Εξαρτάται από το υλικό",
                "titleArray": [
                  {
                    "tr_title": "ΟΣΟ ΛΙΓΟΤΕΡΑ, ΤΟΣΟ ΚΑΛΥΤΕΡΑ",
                    "tr_subtitle": "Σήμερα, τα PC συνήθως καταναλώνουν λιγότερη ενέργεια, ώστε να απολαμβάνετε μεγαλύτερη αυτονομία μπαταρίας –κατά μέσο όρο, πάνω από οκτώ ώρες αναπαραγωγής βίντεο.**",
                    "tr_noteSection": "**Η αυτονομία μπαταρίας διαφέρει σημαντικά ανάλογα με τις ρυθμίσεις, τη χρήση και άλλους παράγοντες."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Λειτουργικό σύστημα",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Μεγαλύτερη ασφάλεια, υψηλότερες επιδόσεις",
                "tr_pretitle": "Windows* 10 στη λειτουργία S",
                "tr_subtitle": "Όταν εκτελείτε τα Windows* 10 σε S mode, απολαμβάνετε έναν μεγαλύτερο βαθμό προστασίας χάρη στον οποίο μπορείτε να εργάζεστε, να παίζετε και να εξερευνείτε γνωρίζοντας με βεβαιότητα ότι η συσκευή σας είναι ασφαλής."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Λειτουργικό σύστημα",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "ΜΕΓΑΛΥΤΕΡΗ ΑΣΦΑΛΕΙΑ, ΥΨΗΛΟΤΕΡΕΣ ΕΠΙΔΟΣΕΙΣ",
                "tr_title": "Windows* 10 στη λειτουργία S",
                "tr_subtitle": "Όταν εκτελείτε τα Windows* 10 σε S mode, απολαμβάνετε έναν μεγαλύτερο βαθμό προστασίας χάρη στον οποίο μπορείτε να εργάζεστε, να παίζετε και να εξερευνείτε γνωρίζοντας με βεβαιότητα ότι η συσκευή σας είναι ασφαλής."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Λειτουργικό σύστημα",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Κάντε σπουδαία πράγματα",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Επιδόσεις, τιμή και πολλές επιλογές. Από καινοτόμους υπολογιστές 2 in 1 έως λεπτά κι ελαφριά laptop, οθόνες αφής, αποσπώμενα πληκτρολόγια, όποιο ποσό κι αν διαθέτετε ή όποιο κι αν είναι το στιλ σας, μπορείτε να βρείτε ένα PC με Windows* για σας."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Λειτουργικό σύστημα",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "ΚΑΝΤΕ ΣΠΟΥΔΑΙΑ ΠΡΑΓΜΑΤΑ",
                "tr_title": "Windows*",
                "tr_subtitle": "Επιδόσεις, τιμή και πολλές επιλογές. Από καινοτόμους υπολογιστές 2 in 1 έως λεπτά κι ελαφριά laptop, οθόνες αφής, αποσπώμενα πληκτρολόγια, όποιο ποσό κι αν διαθέτετε ή όποιο κι αν είναι το στιλ σας, μπορείτε να βρείτε ένα PC με Windows* για σας."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Λειτουργικό σύστημα",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Λειτουργικό σύστημα",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Μνήμη",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "ΠΟΣΗ RAM ΧΡΕΙΑΖΕΣΤΕ;",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Καθημερινή – RAM 2 GB ΕΩΣ 8 GB",
                    "tr_badgeSubTitle": "Ιδανική για όσους χρησιμοποιούν το PC τους κυρίως για να επεξεργάζονται κείμενα και να περιηγούνται στον Ιστό, χρησιμοποιώντας συνήθως ένα πρόγραμμα κάθε φορά."
                  },
                  {
                    "tr_badgeTitle": "Ενισχυμένη – RAM 8 GB ΕΩΣ 16 GB",
                    "tr_badgeSubTitle": "Με περισσότερη RAM, οι εφαρμογές σας θα εκτελούνται πιο γρήγορα και θα μπορείτε να εκτελείτε περισσότερες εφαρμογές ταυτόχρονα."
                  },
                  {
                    "tr_badgeTitle": "Προηγμένη – RAM ΑΝΩ ΤΩΝ 16 GB",
                    "tr_badgeSubTitle": "Εάν ενδιαφέρεστε για το gaming, την επεξεργασία φωτογραφιών ή βίντεο ή τη δημιουργία τρισδιάστατων μοντέλων, θα χρειαστείτε περισσότερη RAM ώστε να εκτελείτε ειδικά προγράμματα."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ΜΝΗΜΗ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "RAM [XX] GB για καθημερινή πολυδιεργασία",
                "tr_subtitle": "Η μνήμη θα επιτρέπει την ομαλή φόρτωση και εκτέλεση βασικών εφαρμογών, όπως προγράμματα επεξεργασίας κειμένου ή περιήγησης στον Ιστό.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>RAM<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ΜΝΗΜΗ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "RAM [XX] GB για ισχυρή πολυδιεργασία",
                "tr_subtitle": "Εάν χρησιμοποιείτε πολλά προγράμματα ταυτόχρονα, [memory_total] GB μνήμης θα σας επιτρέπουν να εναλλάσσετε με ευκολία.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>RAM<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ΜΝΗΜΗ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "RAM [XX] GB για προηγμένη πολυδιεργασία",
                "tr_subtitle": "Θα έχετε άφθονη μνήμη για να εκτελείτε πολλά απαιτητικά προγράμματα ταυτόχρονα χωρίς καθυστερήσεις.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>RAM<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ΜΝΗΜΗ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "Μνήμη [Value1] GB = μνήμη Intel® Optane™ [Value2] GB + RAM [Value3] GB",
                "tr_subtitle": "Αυτός ο συνδυασμός λύσεων μνήμης θα σας προσφέρει επιτάχυνση των επιδόσεων της μονάδας σκληρού δίσκου από τη μνήμη Intel® Optane™ συν καλύτερη απόκριση από τη RAM.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>ΜΝΗΜΗ<br/></span><span class='value'>[XX] GB</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Μνήμη Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Λιγότερη αναμονή. Περισσότερα από όσα αγαπάτε.",
                "tr_subtitle": "Όταν η μνήμη Intel® Optane™ συνεργάζεται με τη μνήμη του συστήματός σας, σας προσφέρει γρηγορότερη πρόσβαση στα προγράμματα που χρησιμοποιείτε συχνά, έτσι ώστε να ανοίγουν και να φορτώνουν τη στιγμή που τα χρειάζεστε.",
                "tr_pretitle": "Μνήμη Intel® Optane™ [XX] GB",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "ΤΙ ΚΑΝΕΙ Η ΜΝΗΜΗ ΓΙΑ ΤΟ PC ΜΟΥ;"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Γρηγορότερη δημιουργία περιεχομένου",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Καλύτερη απόκριση στο gaming",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Ευκολότερη πολυδιεργασία",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Πιο γρήγορο άνοιγμα προγραμμάτων",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Μνήμη Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Επιταχύνετε το παιχνίδι σας</span>",
                "tr_pretitle": "Μνήμη Intel® Optane™ [XX] GB",
                "tr_pretitle1": "Η μνήμη Intel® Optane™ [XX] GB επιταχύνει το gaming",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Εκκίνηση παιχνιδιών έως<br/><span class=\"optane-game\">98% γρηγορότερα</span><br/>Από τη μονάδα δεδομένων με επεξεργαστή [optane_legal_processor_name] με μνήμη Intel® Optane™<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Φόρτωση επιπέδων έως<br/><span class=\"optane-game\">4,1 φορές γρηγορότερα</span><br/>Από τη μονάδα δεδομένων με επεξεργαστή [optane_legal_processor_name] με μνήμη Intel® Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Μνήμη Intel® Optane™",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Επιταχύνετε το παιχνίδι σας</span>",
                "tr_pretitle": "Μνήμη Intel® Optane™ [XX] GB",
                "tr_pretitle1": "Η μνήμη Intel® Optane™ [XX] GB επιταχύνει το gaming",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Εκκίνηση παιχνιδιών έως<br/><span class=\"optane-game\">98% γρηγορότερα</span><br/>Από τη μονάδα δεδομένων με επεξεργαστή [optane_legal_processor_name] με μνήμη Intel® Optane™<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Φόρτωση επιπέδων έως<br/><span class=\"optane-game\">4,1 φορές γρηγορότερα</span><br/>Από τη μονάδα δεδομένων με επεξεργαστή [optane_legal_processor_name] με μνήμη Intel® Optane™<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ΜΝΗΜΗ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Μνήμη [Value1] GB = μνήμη Intel® Optane™ [Value2] GB + RAM [Value3] GB",
                "tr_subtitle": "Αυτός ο συνδυασμός λύσεων μνήμης θα σας προσφέρει επιτάχυνση των επιδόσεων της μονάδας σκληρού δίσκου από τη μνήμη Intel® Optane™ συν καλύτερη απόκριση από τη RAM.",
                "titleArray": [
                  {
                    "tr_title": "RAM [XX] GB για πολυδιεργασία",
                    "tr_subtitle": "Εάν χρησιμοποιείτε πολλά προγράμματα ταυτόχρονα, [memory_total] GB μνήμης θα σας επιτρέπουν να εναλλάσσετε με ευκολία."
                  },
                  {
                    "tr_title": "Για καθημερινές εργασίες",
                    "tr_subtitle": "Η μνήμη θα επιτρέπει την ομαλή φόρτωση και εκτέλεση βασικών εφαρμογών, όπως προγράμματα επεξεργασίας κειμένου ή περιήγησης στον Ιστό."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ΜΝΗΜΗ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "RAM [XX] GB",
                "tr_pretitle": "ΓΙΑ ΠΕΡΙΣΣΟΤΕΡΗ ΠΟΛΥΔΙΕΡΓΑΣΙΑ",
                "tr_subtitle": "Η RAM δίνει στον επεξεργαστή σας ευκολότερη πρόσβαση σε αρχεία προγραμμάτων, επιτρέποντας στη CPU να τα προσπελάζει πιο γρήγορα και να εκτελεί περισσότερες εργασίες ταυτόχρονα.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Τυπική μνήμη",
                    "tr_badgeSubTitle": "RAM έως 8 GB"
                  },
                  {
                    "tr_badgeTitle": "Προηγμένη μνήμη",
                    "tr_badgeSubTitle": "Περισσότερα από 8 GB και έως και 16 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Υπέρτατη μνήμη",
                    "tr_badgeSubTitle": "RAM άνω των 16&nbsp;GB"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ΜΝΗΜΗ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB για περισσότερη πολυδιεργασία",
                "tr_pretitle": "ΓΙΑ ΠΕΡΙΣΣΟΤΕΡΗ ΠΟΛΥΔΙΕΡΓΑΣΙΑ",
                "tr_subtitle": "Η RAM δίνει στον επεξεργαστή σας ευκολότερη πρόσβαση σε αρχεία προγραμμάτων, επιτρέποντας στη CPU να τα προσπελάζει πιο γρήγορα και να εκτελεί περισσότερες εργασίες ταυτόχρονα.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Τυπική μνήμη",
                    "tr_badgeSubTitle": "RAM έως 8 GB"
                  },
                  {
                    "tr_badgeTitle": "Προηγμένη μνήμη",
                    "tr_badgeSubTitle": "Περισσότερα από 8 GB και έως και 16 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Υπέρτατη μνήμη",
                    "tr_badgeSubTitle": "RAM άνω των 16&nbsp;GB"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΗ Η ΜΝΗΜΗ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΕΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Τυπική",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Προηγμένη",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Υπέρτατη",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Μνήμη",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΗ Η ΜΝΗΜΗ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΕΣ;",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "ΠΟΥ ΚΙΝΕΙΤΑΙ ΑΥΤΗ Η ΜΝΗΜΗ ΣΥΓΚΡΙΤΙΚΑ ΜΕ ΑΛΛΕΣ;",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Τρέχουσα συσκευή",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Καθημερινή",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Ενισχυμένη",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Προηγμένη",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Μονάδα αποθήκευσης",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "ΤΙ ΧΩΡΑΕΙ ΣΤΟ PC ΣΑΣ;",
                "tr_subtitle": "Δεν χρειάζεται να μαντέψετε. Θα σας δείξουμε ακριβώς τι μπορείτε να αποθηκεύσετε σε αυτό το PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Δημιουργήστε μια βιβλιοθήκη με</span> <br/><span class=\"storage-standard\">[num_songs] τραγούδια<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>με βάση 5 MB ανά τραγούδι κατά μέσο όρο",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Δημιουργήστε μια συλλογή από</span> <br/><span class=\"storage-standard\">[num_games] παιχνίδια<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>με βάση 40 GB ανά παιχνίδι κατά μέσο όρο",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Χώρος αποθήκευσης για</span> <br/><span class=\"storage-standard\">[num_videos] βίντεο<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>με βάση 5 GB ανά βίντεο κατά μέσο όρο",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Τραβήξτε και αποθηκεύστε</span> <br/><span class=\"storage-standard\">[num_photos] φωτογραφίες<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>με βάση 4 MB ανά φωτογραφία κατά μέσο όρο",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "HDD [capacity]&nbsp;GB"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "SSD [capacity]&nbsp;GB"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "Μνήμη Intel® Optane™ [total_optane_memory] GB + [convertedSSD] GB SSD"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ΜΟΝΑΔΑ ΑΠΟΘΗΚΕΥΣΗΣ",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "Μονάδα SSD [capacity] GB",
                "tr_preTitle1": "Μονάδα SSD [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Αυτό το PC διαθέτει SSD με χώρο αποθήκευσης [capacity] GB. Αυτό σημαίνει ότι μπορείτε να αποθηκεύετε εντυπωσιακό αριθμό φωτογραφιών, βίντεο και προγραμμάτων και να έχετε πρόσβαση σε αυτά πολύ πιο γρήγορα από ό,τι σε μονάδα σκληρού δίσκου.",
                    "tr_title": "Χώρος για τα πάντα στο PC σας"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Αυτό το PC διαθέτει SSD με χώρο αποθήκευσης [capacity] TB. Αυτό σημαίνει ότι μπορείτε να αποθηκεύετε εντυπωσιακό αριθμό φωτογραφιών, βίντεο και προγραμμάτων και να έχετε πρόσβαση σε αυτά πολύ πιο γρήγορα από ό,τι σε μονάδα σκληρού δίσκου.",
                    "tr_title": "ΑΥΤΗ Η ΣΥΣΚΕΥΗ ΕΧΕΙ ΕΝΤΥΠΩΣΙΑΚΟ ΧΩΡΟ ΑΠΟΘΗΚΕΥΣΗΣ"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>ΧΩΡΟΣ ΑΠΟΘΗΚΕΥΣΗΣ</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>ΧΩΡΟΣ ΑΠΟΘΗΚΕΥΣΗΣ</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ΜΟΝΑΔΑ ΑΠΟΘΗΚΕΥΣΗΣ",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "Μονάδα HDD [capacity] GB",
                "tr_preTitle1": "Μονάδα HDD [capacity] TB",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Οι μονάδες σκληρού δίσκου είναι μία καλή επιλογή για χρήστες που θέλουν μεγάλο χώρο αποθήκευσης στο PC τους.",
                    "tr_title": "Χώρος για τα πάντα στο PC σας"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Οι μονάδες σκληρού δίσκου είναι μία καλή επιλογή για χρήστες που θέλουν μεγάλο χώρο αποθήκευσης στο PC τους.",
                    "tr_title": "ΑΥΤΗ Η ΣΥΣΚΕΥΗ ΕΧΕΙ ΕΝΤΥΠΩΣΙΑΚΟ ΧΩΡΟ ΑΠΟΘΗΚΕΥΣΗΣ"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>ΧΩΡΟΣ ΑΠΟΘΗΚΕΥΣΗΣ<br/></span><span class='value'>[capacity]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ΜΟΝΑΔΑ ΑΠΟΘΗΚΕΥΣΗΣ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "ΤΙ ΧΩΡΑΕΙ ΣΤΟ PC ΣΑΣ;",
                "tr_subtitle": "Δεν χρειάζεται να μαντέψετε. Θα σας δείξουμε ακριβώς τι μπορείτε να αποθηκεύσετε σε αυτό το PC.",
                "titleArray": [
                  {
                    "tr_title": "HDD [XX] GB"
                  },
                  {
                    "tr_title": "SSD [XX] GB"
                  },
                  {
                    "tr_title": "SSD Intel® [total_capacity] GB με επιτάχυνση από μνήμη Intel® Optane™ [optane_memory_size] GB"
                  },
                  {
                    "tr_title": "Intel® SSD [total_capacity] TB με επιτάχυνση από τη μνήμη Intel® Optane™ [optane_memory_size] GB"
                  },
                  {
                    "tr_title": "SSD [convertedSSD] GB + HDD [convertedHDD] GB"
                  },
                  {
                    "tr_title": "SSD [convertedSSD] TB + HDD [convertedHDD] GB"
                  },
                  {
                    "tr_title": "SSD [convertedSSD] GB + HDD [convertedHDD] TB"
                  },
                  {
                    "tr_title": "SSD [convertedSSD] TB + HDD [convertedHDD] TB"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>ΧΩΡΟΣ ΑΠΟΘΗΚΕΥΣΗΣ</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>ΧΩΡΟΣ ΑΠΟΘΗΚΕΥΣΗΣ</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>ΧΩΡΟΣ ΑΠΟΘΗΚΕΥΣΗΣ</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>ΧΩΡΟΣ ΑΠΟΘΗΚΕΥΣΗΣ</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>ΧΩΡΟΣ ΑΠΟΘΗΚΕΥΣΗΣ</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>ΧΩΡΟΣ ΑΠΟΘΗΚΕΥΣΗΣ</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ΜΟΝΑΔΑ ΑΠΟΘΗΚΕΥΣΗΣ",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "Μονάδα SSD [convertedSSD] GB + μονάδα HDD [convertedHDD] GB",
                "tr_preTitle1": "Μονάδα SSD [convertedSSD] TB + μονάδα HDD [convertedHDD] GB",
                "tr_preTitle2": "Μονάδα SSD [convertedSSD] GB + μονάδα HDD [convertedHDD] TB",
                "tr_preTitle3": "Μονάδα SSD [convertedSSD] TB + μονάδα HDD [convertedHDD] TB",
                "titleArray": [
                  {
                    "tr_title": "Χώρος για τα πάντα στο PC σας",
                    "tr_title1": "ΑΥΤΗ Η ΣΥΣΚΕΥΗ ΕΧΕΙ ΕΝΤΥΠΩΣΙΑΚΟ ΧΩΡΟ ΑΠΟΘΗΚΕΥΣΗΣ",
                    "tr_subtitle": "Αυτό το PC διαθέτει μονάδα σταθερής κατάστασης με χώρο αποθήκευσης [convertedSSD] GB και μονάδα σκληρού δίσκου με χώρο αποθήκευσης [convertedHDD] GB. Έτσι, σας παρέχει και τεράστια χωρητικότητα και μονάδα αποθήκευσης με πιο γρήγορες επιδόσεις.",
                    "tr_subtitle1": "Αυτό το PC διαθέτει μονάδα σταθερής κατάστασης με χώρο αποθήκευσης [convertedSSD] TB και μονάδα σκληρού δίσκου με χώρο αποθήκευσης [convertedHDD] GB. Έτσι, σας παρέχει και τεράστια χωρητικότητα και μονάδα αποθήκευσης με πιο γρήγορες επιδόσεις.",
                    "tr_subtitle2": "Αυτό το PC διαθέτει μονάδα σταθερής κατάστασης με χώρο αποθήκευσης [convertedSSD] GB και μονάδα σκληρού δίσκου με χώρο αποθήκευσης [convertedHDD] TB. Έτσι, σας παρέχει και τεράστια χωρητικότητα και μονάδα αποθήκευσης με πιο γρήγορες επιδόσεις.",
                    "tr_subtitle3": "Αυτό το PC διαθέτει μονάδα σταθερής κατάστασης με χώρο αποθήκευσης [convertedSSD] TB και μονάδα σκληρού δίσκου με χώρο αποθήκευσης [convertedHDD] TB. Έτσι, σας παρέχει και τεράστια χωρητικότητα και μονάδα αποθήκευσης με πιο γρήγορες επιδόσεις."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>ΧΩΡΟΣ ΑΠΟΘΗΚΕΥΣΗΣ</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>ΧΩΡΟΣ ΑΠΟΘΗΚΕΥΣΗΣ</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ΜΟΝΑΔΑ ΑΠΟΘΗΚΕΥΣΗΣ",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Αποκτήστε ταχύτερη πρόσβαση σε μονάδα αποθήκευσης υψηλής χωρητικότητας με τη μνήμη Intel® Optane™ με SSD.",
                "tr_title": "Τα τεράστια αρχεία είναι ευπρόσδεκτα",
                "tr_pretitle": "SSD Intel® [total_capacity] GB με επιτάχυνση από μνήμη Intel® Optane™ [optane_memory_size] GB",
                "tr_pretitle1": "Intel® SSD [total_capacity] TB με επιτάχυνση από τη μνήμη Intel® Optane™ [optane_memory_size] GB",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Αυτή η επαναστατική λύση αποθήκευσης είναι η πρώτη που συνδυάζει μνήμη Intel® Optane™ και SSD σε ένα chip. Ορίστε τι κάνει για εσάς:",
                    "tr_title": "ΤΙ ΕΙΝΑΙ Η ΜΝΗΜΗ INTEL® OPTANE™ ΜΕ SSD;"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "ΑΝΟΙΓΕΙ Ο,ΤΙ ΧΡΕΙΑΖΕΣΤΕ ΓΡΗΓΟΡΑ",
                    "tr_badgeSubTitle": "Φορτώνοντας από πριν όσα αρχεία και προγράμματα χρησιμοποιείτε πιο συχνά, μπορείτε να δημιουργείτε χωρίς να χρονοτριβείτε.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "ΣΥΝΔΥΑΖΕΙ ΧΩΡΗΤΙΚΟΤΗΤΑ ΚΑΙ ΤΑΧΥΤΗΤΑ",
                    "tr_badgeSubTitle": "Θα έχετε τον χώρο που χρειάζεστε για την αποθήκευση αρχείων πολυμέσων με τις αστραπιαίες επιδόσεις μίας SSD.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Αποκρίνεται καλύτερα με τον καιρό",
                    "tr_badgeSubTitle": "Η μνήμη Intel® Optane™ με SSD μαθαίνει τον τρόπο που δουλεύετε, κάνοντας το PC σας πιο αποδοτικό.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "ΜΟΝΑΔΑ ΑΠΟΘΗΚΕΥΣΗΣ",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Άφθονος, αξιόπιστος αποθηκευτικός χώρος για άμεση πρόσβαση σε φωτογραφίες, μουσική και βίντεο.",
                "tr_title": "Χώρος για όλες τις φωτογραφίες, τα βίντεο και τα τραγούδια σας"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Οθόνη",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] εκατομμύρια pixel για σάρωση, τσίμπημα, θαυμασμό",
                "tr_subtitle": "Αλληλεπιδράστε με τον υπολογιστή σας σαν να ήταν smartphone, με πανεύκολο χειρισμό και πιο ευκρινείς, ζωντανές λεπτομέρειες.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]″<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Ανάλυση: [screenResolutionX] × [screenResolutionY]",
                    "tr_badgePreTitle": "Pixel: [screenResolution] εκατομμύρια"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Οθόνη [screenDiagonal] ιντσών",
                    "tr_badgeSubTitle": "Ύψος οθόνης: [screenHeight] ίντσες",
                    "tr_badgePreTitle": "Πλάτος οθόνης: [YY] ίντσες"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Οθόνη αφής",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Οθόνη",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Βυθιστείτε σε [screenResolution] εκατομμύρια pixel",
                "tr_subtitle": "Όλα έχουν να κάνουν με τα pixel. Όσο περισσότερα έχετε, τόσο πιο ευκρινείς και ζωντανές θα είναι οι εικόνες σας.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]″<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Ανάλυση: [screenResolutionX] × [screenResolutionY]",
                    "tr_badgePreTitle": "Pixel: [screenResolution] εκατομμύρια"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Οθόνη [screenDiagonal] ιντσών",
                    "tr_badgeSubTitle": "Ύψος οθόνης: [screenHeight] ίντσες",
                    "tr_badgePreTitle": "Πλάτος οθόνης: [YY] ίντσες"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Οθόνη",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] ίντσες <br/>[screenResolutionX] × [screenResolutionY]",
                "tr_pretitle": "ΤΟ ΠΑΡΑΘΥΡΟ ΣΑΣ ΣΤΟΝ ΚΟΣΜΟ ΤΟΥ GAMING",
                "tr_subtitle": "Όπως και στις τηλεοράσεις, αυτά που κυρίως πρέπει να εξετάζετε σε μια οθόνη gaming είναι το μέγεθος και η ανάλυση.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Ανάλυση: [screenResolutionX] × [screenResolutionY]",
                    "tr_badgePreTitle": "Pixel: [screenResolution] εκατομμύρια"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Οθόνη [screenDiagonal] ιντσών",
                    "tr_badgeSubTitle": "Ύψος οθόνης: [screenHeight] ίντσες",
                    "tr_badgePreTitle": "Πλάτος οθόνης: [screenWidth] ίντσες"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Οθόνη αφής"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Οθόνη",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Αυτή η συσκευή έχει εντυπωσιακή οθόνη",
                "tr_subtitle": "Με πάνω από οκτώ φορές τα pixel της ανάλυσης HD, τα βίντεο και τα παιχνίδια σας θα αποκτήσουν ένα εντελώς νέο επίπεδο ευκρίνειας και χρώματος.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Ανάλυση: [screenResolutionX] × [screenResolutionY]",
                    "tr_badgePreTitle": "Pixel: [screenResolution] εκατομμύρια"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "Οθόνη [screenDiagonal] ιντσών",
                    "tr_badgeSubTitle": "Ύψος οθόνης: [screenHeight] ίντσες",
                    "tr_badgePreTitle": "Πλάτος οθόνης: [YY] ίντσες"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Οθόνη",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] εκατομμύρια pixel για να πατήσετε, να σαρώσετε και να αγγίξετε",
                    "tr_subTitle": "Δείτε και αλληλεπιδράστε με τα πράγματα που σας αρέσουν σε ευκρινέστερη, πιο ζωντανή λεπτομέρεια."
                  },
                  {
                    "tr_title": "[screenResolution] εκατομμύρια pixel για να βελτιώσετε τον ψηφιακό σας κόσμο",
                    "tr_subTitle": "Όλα έχουν να κάνουν με τα pixel. Όσο περισσότερα έχετε, τόσο πιο ευκρινείς και ζωντανές θα είναι οι εικόνες σας."
                  },
                  {
                    "tr_title": "Αυτή η συσκευή έχει εντυπωσιακή οθόνη",
                    "tr_subTitle": "Με σχεδόν τέσσερις φορές τα pixel της ανάλυσης HD, τα βίντεο και τα παιχνίδια σας θα περάσουν σε νέο επίπεδο ευκρίνειας και χρώματος."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Συνδεσιμότητα",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Η τεχνολογία Thunderbolt™ 3 παρέχει την πιο γρήγορη, πολυχρηστική σύνδεση με οποιονδήποτε σταθμό βάσης, οθόνη ή συσκευή αποθήκευσης δεδομένων.",
                "tr_title": "Μία θύρα που κάνει τα πάντα",
                "tr_title1": "ΜΙΑ ΘΥΡΑ ΠΟΥ ΚΑΝΕΙ ΤΑ ΠΑΝΤΑ",
                "titleArray": [
                  {
                    "tr_title": "ΠΩΣ ΜΠΟΡΩ ΝΑ ΧΡΗΣΙΜΟΠΟΙΗΣΩ ΤΗΝ ΤΕΧΝΟΛΟΓΙΑ THUNDERBOLT™ 3;",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Τεχνολογία Thunderbolt™ 3"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "Οθόνες 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Συνδεθείτε με οθόνες με καταπληκτική ανάλυση, αντίθεση και χρώμα"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Αυτόνομη κάρτα γραφικών",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Αναβαθμίστε στη στιγμή τα γραφικά οποιουδήποτε PC"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Γρήγορη δικτύωση",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Προσφέρει γρήγορες συνδέσεις μεταξύ ομοτίμων"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Συνδεσιμότητα",
                "detailBGUrl": "",
                "tr_subtitle": "Η τεχνολογία Thunderbolt™ 3 μεταφέρει δεδομένα με μεγαλύτερη ταχύτητα από τα συμβατικά USB, επιτρέποντάς σας να μεταφέρετε αρχεία γρήγορα και να συνδέετε με ευκολία περιφερειακά, όπως πολλαπλές οθόνες ή εξωτερικές κάρτες γραφικών.",
                "tr_title": "Τεχνολογία Thunderbolt™ 3",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "ΔΕΔΟΜΕΝΑ ΜΕ ΑΣΤΡΑΠΙΑΙΑ ΤΑΧΥΤΗΤΑ",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Τεχνολογία Thunderbolt™ 3",
                    "tr_badgeSubTitle": "40&nbsp;Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Συνδεσιμότητα",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Μία καθολική θύρα και ένα καθολικό καλώδιο υποστηρίζουν όλα τα αγαπημένα σας αξεσουάρ</li> <li>Αξιόπιστη φόρτιση φορητού υπολογιστή</li> <li>Υψηλής ποιότητας πιστοποίηση που προσφέρει σιγουριά</li></ul>",
                "tr_title": "Πραγματικά καθολική συνδεσιμότητα καλωδίων",
                "tr_title1": "ΠΡΑΓΜΑΤΙΚΑ ΚΑΘΟΛΙΚΗ ΣΥΝΔΕΣΙΜΟΤΗΤΑ ΚΑΛΩΔΙΩΝ",
                "tr_noteSection": "<sup>†</sup>Με βάση τις ελάχιστες απαιτήσεις των προδιαγραφών",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "ΠΩΣ ΜΠΟΡΩ ΝΑ ΧΡΗΣΙΜΟΠΟΙΗΣΩ ΤΗΝ ΤΕΧΝΟΛΟΓΙΑ THUNDERBOLT™ 4;",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Τεχνολογία Thunderbolt™ 4"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Να δημιουργούν",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Συνδέστε μια εξωτερική οθόνη και μια συσκευή γρήγορης αποθήκευσης για προβολή και επεξεργασία φωτογραφιών και video."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Σταθμός βάσης",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Απαλλάξτε το γραφείο σας από την ακαταστασία συνδέοντας τον φορητό υπολογιστή σας με όλα τα αξεσουάρ με ένα μόνο καλώδιο."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Παιχνίδι",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Προσθέστε μεγαλύτερη χωρητικότητα SSD και HDD με ταχύτητες έως 3.000 MB/s για αποθήκευση βιβλιοθηκών παιχνιδιών και αρχείων πολυμέσων."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Μέγιστες επιδόσεις<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4 φορές γρηγορότερα από το USB* 3.2 <br/>2 φορές γρηγορότερα από το HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Συνδεσιμότητα",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Απολαύστε τις ίδιες εκπληκτικές επιδόσεις της τεχνολογίας Thunderbolt™ 3 με ακόμα περισσότερες δυνατότητες, που σας επιτρέπουν να συνδέεστε εύκολα και να χρησιμοποιείτε τα πιο πρόσφατα προϊόντα Thunderbolt™ –σταθμούς βάσης, οθόνες, γρήγορες μονάδες αποθήκευσης– ή οποιοδήποτε αξεσουάρ USB*.",
                "tr_title": "Τεχνολογία Thunderbolt™ 4",
                "tr_pretitle": "ΠΡΑΓΜΑΤΙΚΑ ΚΑΘΟΛΙΚΗ ΣΥΝΔΕΣΙΜΟΤΗΤΑ ΚΑΛΩΔΙΩΝ",
                "tr_noteSection": "<sup>†</sup>Με βάση τις ελάχιστες απαιτήσεις των προδιαγραφών",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Ένα καθολικό καλώδιο Thunderbolt™ 4 μπορεί να αντικαταστήσει όλα τα καλώδια USB-C* για να κάνει την επιλογή καλωδίων γρήγορη και εύκολη."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Οι σταθμοί βάσης τεχνολογίας Thunderbolt™ 4 προσφέρουν έως τέσσερις θύρες Thunderbolt™ για περισσότερη ευελιξία στη σύνδεση οποιουδήποτε από τα εξαρτήματά σας."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Μέγιστες επιδόσεις<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4 φορές γρηγορότερα από το USB* 3.2 <br/>2 φορές γρηγορότερα από το HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Συνδεσιμότητα",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Μία καθολική θύρα και ένα καθολικό καλώδιο υποστηρίζουν όλα τα αγαπημένα σας αξεσουάρ</li> <li>Αξιόπιστη φόρτιση φορητού υπολογιστή</li> <li>Υψηλής ποιότητας πιστοποίηση που προσφέρει σιγουριά</li></ul>",
                "tr_title": "Απολαύστε στο έπακρο την εμπειρία σας με αξεσουάρ Thunderbolt™",
                "tr_noteSection": "<sup>†</sup>Με βάση τις ελάχιστες απαιτήσεις των προδιαγραφών",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "ΠΩΣ ΜΠΟΡΩ ΝΑ ΧΡΗΣΙΜΟΠΟΙΗΣΩ ΤΗΝ ΤΕΧΝΟΛΟΓΙΑ THUNDERBOLT™ 4;",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Να δημιουργούν",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Συνδέστε μια εξωτερική οθόνη και μια συσκευή γρήγορης αποθήκευσης για προβολή και επεξεργασία φωτογραφιών και video."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Σταθμός βάσης",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Απαλλάξτε το γραφείο σας από την ακαταστασία συνδέοντας τον φορητό υπολογιστή σας με όλα τα αξεσουάρ με ένα μόνο καλώδιο."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Παιχνίδι",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Προσθέστε μεγαλύτερη χωρητικότητα SSD και HDD με ταχύτητες έως 3.000 MB/s για αποθήκευση βιβλιοθηκών παιχνιδιών και αρχείων πολυμέσων."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Μέγιστες επιδόσεις<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4 φορές γρηγορότερα από το USB* 3.2 <br/>2 φορές γρηγορότερα από το HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "Οι πιο κοινοί τύποι Wi‑Fi είναι:",
                "tr_title": "ΚΑΤΑΝΟΗΣΤΕ ΤΑ ΠΡΟΤΥΠΑ ΤΟΥ WI‑FI",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Το Wi-Fi 6 επιτρέπει μεγαλύτερες ταχύτητες ακόμη και όταν πολλοί χρήστες χρησιμοποιούν το ίδιο σήμα.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Με την τελευταία καινοτομία της Intel στο Wi-Fi μπορείτε να επιτυγχάνετε υψηλότερες ταχύτητες από το Gigabit Wi-Fi, ακόμα και με πολλούς χρήστες στο ίδιο σήμα.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Η μεγαλύτερη εξέλιξη της τελευταίας 20ετίας στο Wi‑Fi, το Wi‑Fi 6E προσφέρει νέα υψηλής ταχύτητας κανάλια για βελτιωμένες επιδόσεις, μεγαλύτερη αξιοπιστία και λιγότερες παρεμβολές."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Το Wi-Fi 6 επιτρέπει μεγαλύτερες ταχύτητες ακόμη και όταν πολλοί χρήστες χρησιμοποιούν το ίδιο σήμα.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Όλα τα οφέλη του Wi-Fi 6E σε συνδυασμό με τη Μηχανή καθορισμού προτεραιότητας Intel® Killer™, η οποία μεταθέτει το εύρος ζώνης στους ιστοτόπους και τις εφαρμογές που το χρειάζονται περισσότερο."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Με λιγότερο ανταγωνισμό στα κανάλια υψηλής ταχύτητας, θα απολαμβάνετε βελτιωμένες επιδόσεις και αξιοπιστία."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi-Fi 6",
                    "tr_badgeSubTitle": "Το Wi-Fi 6 επιτρέπει μεγαλύτερες ταχύτητες ακόμη και όταν πολλοί χρήστες χρησιμοποιούν το ίδιο σήμα.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Με λιγότερη παρεμβολή από το Wi-Fi 6 και τις έξυπνες επιδόσεις της Μηχανής καθορισμού προτεραιότητας Intel® Killer™, η διαδικτυακή σας εμπειρία θα είναι γρηγορότερη και με καλύτερη απόκριση⁶."
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Με λιγότερο ανταγωνισμό στα κανάλια υψηλής ταχύτητας, θα απολαμβάνετε βελτιωμένες επιδόσεις και αξιοπιστία."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Ταχύτητες Wi-Fi PC μεγαλύτερες από 5 Gbps με συνεχώς εξαιρετικά χαμηλό λανθάνοντα χρόνο - μοιραστείτε αρχεία σε δευτερόλεπτα αντί για λεπτά."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Το Intel® Killer™ Wi-Fi 7 (5 Gig) είναι η πιο πρόσφατη τεχνολογία που επιτρέπει «Εξαιρετική» αξιοπιστία, χαμηλό λανθάνοντα χρόνο και ταχύτητες χάρη στη βελτιστοποίηση δικτύωσης μέσω ΑΙ."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Το Wi-Fi 6 επιτρέπει μεγαλύτερες ταχύτητες ακόμη και όταν πολλοί χρήστες χρησιμοποιούν το ίδιο σήμα.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Το Intel® Wi-Fi 6E (Gig+) διαθέτει μεγαλύτερες ταχύτητες του 1 Gbps, αξιοπιστία και χαμηλό λανθάνοντα χρόνο χάρη στη χρήση του καναλιού 6 GHz."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Καλύτερη απόδοση, χωρητικότητα και ταχύτητα σε σύγκριση με το Wi-Fi 5, σε δίκτυα με συμφόρηση."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Μείνετε συνδεδεμένοι καλύτερα με το Wi‑Fi [XX] και απολαύστε το διαδίκτυο με υψηλότερες ταχύτητες<sup>†</sup> και μεγαλύτερη αξιοπιστία.",
                "tr_title": "Αποκτήστε βελτιωμένη εμβέλεια και ταχύτητα",
                "tr_noteSection": "<sup>†</sup>Στην πράξη, η ταχύτητα μετάδοσης και/ή η εμβέλεια του ασύρματου δικτύου θα ποικίλλουν.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Στην εποχή των διαδικτυακών παιχνιδιών πολλών παικτών, είναι πιο σημαντικό από ποτέ να παραμένετε σε σύνδεση. Εκτός από ταχύτητα, θέλετε επίσης ένα ασύρματο σήμα που να μπορεί να διατηρεί αυτές τις ταχύτητες από απόσταση.",
                "tr_title": "[XX]",
                "tr_pretitle": "Η ΣΥΝΔΕΣΗ ΕΙΝΑΙ ΑΠΑΡΑΙΤΗΤΗ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Χάρη στο Intel® Wi‑Fi 6 (Gig+), μπορείτε να συνδεθείτε με δρομολογητές Wi‑Fi 6 που υποστηρίζουν κανάλια 160 MHz, ώστε να επιτύχετε γρηγορότερες συνδέσεις και μεγαλύτερη αξιοπιστία για περισσότερους ενεργούς χρήστες.",
                "tr_title": "Σχεδόν 3 φορές μεγαλύτερες ταχύτητες με το Intel® <span class='no-warp'>Wi-Fi 6  </span>(Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Σε σύγκριση με το τυπικό Wi‑Fi AC. Μερικά χαρακτηριστικά διατίθενται μόνο με ορισμένα SKU. Για λεπτομέρειες, απευθυνθείτε στον κατασκευαστή. Για περισσότερες πληροφορίες για το Wi‑Fi, επισκεφθείτε το intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Το Intel® Wi‑Fi 6 διπλής ζώνης βασικού επιπέδου υποστηρίζει βελτιωμένες επιδόσεις σε βασικές ανάγκες συνδεσιμότητας, όπως η χρήση email και η περιήγηση στο Διαδίκτυο σε δίκτυα με συμφόρηση.<sup>9</sup>",
                "tr_title": "Essential Wi‑Fi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Στην εποχή των διαδικτυακών παιχνιδιών πολλών παικτών, είναι πιο σημαντικό από ποτέ να παραμένετε σε σύνδεση. Εκτός από ταχύτητα, θέλετε επίσης ένα ασύρματο σήμα που να μπορεί να διατηρεί αυτές τις ταχύτητες από απόσταση.",
                "tr_title": "[XX]",
                "tr_pretitle": "Η ΣΥΝΔΕΣΗ ΕΙΝΑΙ ΑΠΑΡΑΙΤΗΤΗ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Με αυτά τα νέα κανάλια Wi‑Fi δεν χρειάζεται να μοιράζεστε το εύρος ζώνης με τις παλαιότερες συσκευές της γειτονιάς σας, προσφέροντας έτσι ομαλό στριμάρισμα, γρήγορη περιήγηση και απρόσκοπτη τηλεργασία και μάθηση.",
                "tr_title": "Σύνδεση χωρίς ανταγωνισμό",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Μείνετε συνδεδεμένοι καλύτερα με το [XX] και απολαύστε το διαδίκτυο με υψηλότερες ταχύτητες<sup>†</sup> και μεγαλύτερη αξιοπιστία.",
                "tr_title": "Αποκτήστε βελτιωμένη εμβέλεια και ταχύτητα",
                "tr_noteSection": "<sup>†</sup>Στην πράξη, η ταχύτητα μετάδοσης και/ή η εμβέλεια του ασύρματου δικτύου θα ποικίλλουν.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Στην εποχή των διαδικτυακών παιχνιδιών πολλών παικτών, είναι πιο σημαντικό από ποτέ να παραμένετε σε σύνδεση. Εκτός από ταχύτητα, θέλετε επίσης ένα ασύρματο σήμα που να μπορεί να διατηρεί αυτές τις ταχύτητες από απόσταση.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "Η ΣΥΝΔΕΣΗ ΕΙΝΑΙ ΑΠΑΡΑΙΤΗΤΗ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Ενσωματωμένο ταχύτατο Wi‑Fi",
                "tr_subtitle": "Αποσυνδέστε τα καλώδια και μείνετε συνδεδεμένοι με τον διαδικτυακό κόσμο σας."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Με ειδικές λωρίδες υψηλής ταχύτητας για λιγότερες παρεμβολές και τη Μηχανή καθορισμού προτεραιότητας Intel® Killer™, που δίνει προτεραιότητα στο gaming, το διαδικτυακό gaming ανεβαίνει σε άλλο επίπεδο.",
                "tr_title": "Προσπεράστε τους ανταγωνιστές σας",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Με ειδικές λωρίδες υψηλής ταχύτητας για λιγότερες παρεμβολές και τη Μηχανή καθορισμού προτεραιότητας Intel® Killer™, που δίνει προτεραιότητα στο gaming, το διαδικτυακό gaming ανεβαίνει σε άλλο επίπεδο.",
                "tr_title": "[XX]",
                "tr_pretitle": "ΠΡΟΣΠΕΡΑΣΤΕ ΤΟΥΣ ΑΝΤΑΓΩΝΙΣΤΕΣ ΣΑΣ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Με ειδικές λωρίδες υψηλής ταχύτητας για λιγότερες παρεμβάσεις που δίνουν προτεραιότητα στο gaming, το διαδικτυακό gaming ανεβαίνει σε πραγματικά άλλο επίπεδο.",
                "tr_title": "[XX]",
                "tr_pretitle": "ΠΡΟΣΠΕΡΑΣΤΕ ΤΟΥΣ ΑΝΤΑΓΩΝΙΣΤΕΣ ΣΑΣ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Το Intel® Killer™ Wi-Fi επιταχύνει αυτόματα την κίνηση δικτύου για τις εφαρμογές που τη χρειάζονται περισσότερο, έτσι ώστε τα βίντεο που στριμάρετε να έχουν ευκρινείς λεπτομέρειες και να κολλάνε λιγότερο.",
                "tr_title": "Ένας ταχύτερος και εξυπνότερος τρόπος σύνδεσης⁶.",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Στην εποχή των διαδικτυακών παιχνιδιών πολλών παικτών, είναι πιο σημαντικό από ποτέ να παραμένετε σε σύνδεση. Εκτός από ταχύτητα, θέλετε επίσης ένα ασύρματο σήμα που να μπορεί να διατηρεί αυτές τις ταχύτητες από απόσταση.",
                "tr_title": "[XX]",
                "tr_pretitle": "Η ΣΥΝΔΕΣΗ ΕΙΝΑΙ ΑΠΑΡΑΙΤΗΤΗ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Εξασφαλίστε καλύτερη σύνδεση με το Wi‑Fi 5 και απολαύστε το Διαδίκτυο με γρηγορότερες ταχύτητες<sup>†</sup> και περισσότερη αξιοπιστία.",
                "tr_title": "Αποκτήστε βελτιωμένη εμβέλεια και ταχύτητα",
                "tr_noteSection": "<sup>†</sup>Στην πράξη, η ταχύτητα μετάδοσης και/ή η εμβέλεια του ασύρματου δικτύου θα ποικίλλουν.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Στην εποχή των διαδικτυακών παιχνιδιών πολλών παικτών, είναι πιο σημαντικό από ποτέ να παραμένετε σε σύνδεση. Εκτός από ταχύτητα, θέλετε επίσης ένα ασύρματο σήμα που να μπορεί να διατηρεί αυτές τις ταχύτητες από απόσταση.",
                "tr_title": "[XX]",
                "tr_pretitle": "ΠΡΟΣΠΕΡΑΣΤΕ ΤΟΥΣ ΑΝΤΑΓΩΝΙΣΤΕΣ ΣΑΣ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Τα χαρακτηριστικά του Wi-Fi 7 θα βασίζονται, αλλά και θα εξελίσσουν, τις προηγούμενες γενιές Wi-Fi. Αυτό θα σημαίνει όχι μόνο μεγαλύτερες ταχύτητες, αλλά και δραματικά βελτιωμένη απόκριση και αξιοπιστία, για εξαιρετικές εμπειρίες καταναλωτών και εξελιγμένες τεχνολογίες του μέλλοντος.",
                "tr_title": "Δικτύωση PC σαν ενσύρματη",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Τα χαρακτηριστικά του Wi-Fi 7 θα βασίζονται, αλλά και θα εξελίσσουν, τις προηγούμενες γενιές Wi-Fi. Αυτό θα σημαίνει όχι μόνο μεγαλύτερες ταχύτητες, αλλά και δραματικά βελτιωμένη απόκριση και αξιοπιστία, για εξαιρετικές εμπειρίες καταναλωτών και εξελιγμένες τεχνολογίες του μέλλοντος.",
                "tr_title": "[XX]",
                "tr_pretitle": "ΜΕΤΑΣΧΗΜΑΤΙΖΟΝΤΑΣ ΤΗΝ ΕΜΠΕΙΡΙΑ ΤΟΥ ΧΡΗΣΤΗ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Βελτιωμένη ταχύτητα, λανθάνοντας χρόνος και αξιοπιστία χάρη στη βελτιστοποίηση δικτύωσης μέσω ΑΙ",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Βελτιωμένη ταχύτητα, λανθάνοντας χρόνος και αξιοπιστία χάρη στη βελτιστοποίηση δικτύωσης μέσω ΑΙ.",
                "tr_title": "[XX]",
                "tr_pretitle": "ΑΠΟΚΡΙΣΗ ΣΑΝ ΕΝΣΥΡΜΑΤΗΣ ΣΥΝΔΕΣΗΣ",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Μπαταρία",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Πώς μετριέται;",
                    "tr_badgeSubTitle": "Οι περισσότεροι κατασκευαστές εκφράζουν την αυτονομία της μπαταρίας σε ώρες. Αν και αυτός ο υπολογισμός δεν είναι 100% ακριβής, θα σας βοηθήσει να συγκρίνετε την αυτονομία της μπαταρίας ενός PC με εκείνη κάποιου άλλου."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Μπαταρία",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "ΓΙΑ GAMING ΕΚΤΟΣ ΣΠΙΤΙΟΥ",
                "tr_subtitle": "Με την ισχύ των σημερινών laptop για παιχνίδια, δεν υπάρχει λόγος να μένετε καθηλωμένοι σε ένα γραφείο. Τώρα τα παιχνίδια σας μπορούν να σας ακολουθούν οπουδήποτε.",
                "tr_title": "[Dynamic.battery.values.hours] ώρες αυτονομίας μπαταρίας"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Μπαταρία",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Βγάλτε την πρίζα κι απολαύστε εφαρμογές, παιχνίδια και μουσική για πολλές ώρες.",
                "tr_title": "[XX] ώρες στριμάρισμα με μία μόνο φόρτιση<sup class='sup-symbol'>†</sup> ",
                "tr_noteSection": "<sup>†</sup>Με βάση την αυτονομία μπαταρίας που δηλώνει ο κατασκευαστής του PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] ώρες",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Μπαταρία",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Βγάλτε την πρίζα κι απολαύστε εφαρμογές, παιχνίδια και μουσική για πολλές ώρες.",
                "tr_title": "[Dynamic.battery.values.hours] ώρες ενέργειας εν κινήσει<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Με βάση την αυτονομία μπαταρίας που δηλώνει ο κατασκευαστής του PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] ώρες",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Μπαταρία",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Βγάλτε την πρίζα κι απολαύστε εφαρμογές, παιχνίδια και μουσική για πολλές ώρες.",
                "tr_title": "[Dynamic.battery.values.hours] ώρες για ενέργεια όλη μέρα με μία μόνο φόρτιση<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Με βάση την αυτονομία μπαταρίας που δηλώνει ο κατασκευαστής του PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] ώρες",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Μπαταρία",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Αυτό το PC βελτιστοποιεί την αυτονομία της μπαταρίας ώστε να διαρκεί περισσότερο και σας προσφέρει τουλάχιστον 4 ώρες αυτονομία μπαταρίας, με μια 30λεπτη φόρτιση.<sup>4</sup>",
                "tr_title": "Μείνετε εκτός πρίζας για περισσότερο, με [Dynamic.battery.values.hours] ώρες αυτονομία μπαταρίας<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Με βάση την αυτονομία μπαταρίας που δηλώνει ο κατασκευαστής του PC.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] ώρες",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Αρχική",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Γιατί αυτό το PC",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Προβολή όλων των προδιαγραφών",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Απομακρυσμένα",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Φορητή",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Νομικό",
        "tr_paragraph_array": [
          "Το λογισμικό και τα σενάρια που χρησιμοποιούνται στις δοκιμές επιδόσεων ενδέχεται να έχουν υποστεί βελτιστοποίηση επιδόσεων μόνο για μικροεπεξεργαστές Intel®. Οι δοκιμές επιδόσεων, όπως SYSmark* και MobileMark*, γίνονται με βάση συγκεκριμένα υπολογιστικά συστήματα, εξαρτήματα και λειτουργίες. Οποιαδήποτε αλλαγή σε οποιονδήποτε από αυτούς τους παράγοντες ενδέχεται να επιφέρει αλλαγή των αποτελεσμάτων. Για να αξιολογήσετε πλήρως τις αγορές που σκοπεύετε να κάνετε, πρέπει να συμβουλευτείτε και άλλες πληροφορίες και δοκιμές επιδόσεων, συμπεριλαμβανομένων των επιδόσεων αυτού του προϊόντος όταν συνδυάζεται με άλλα προϊόντα. Για πιο ολοκληρωμένες πληροφορίες σχετικά με τις επιδόσεις και τα αποτελέσματα των ελέγχων επιδόσεων, επισκεφθείτε το www.intel.com/benchmarks.",
          "¹Τα προϊόντα Intel® Wi-Fi 6 (Gig+) υποστηρίζουν προαιρετικά κανάλια 160 MHz, ώστε να είναι εφικτές οι μεγαλύτερες δυνατές μέγιστες θεωρητικές ταχύτητες (2402 Mbps) με τυπικά προϊόντα Wi-Fi 802.11ax 2x2 για PC. Τα premium προϊόντα Intel® Wi-Fi 6 (Gig+) επιτρέπουν 2 έως 4 φορές μεγαλύτερες μέγιστες θεωρητικές ταχύτητες σε σύγκριση με τα τυπικά προϊόντα Wi-Fi 802.11ax 2x2 (1201 Mbps) ή 1x1 (600 Mbps) για PC, τα οποία υποστηρίζουν μόνο την απαίτηση ύπαρξης καναλιών 80 MHz.",
          "²Μόνο για ενημερωτικούς σκοπούς. Συμβουλευτείτε κι άλλες πηγές πριν αποφασίσετε τι θα αγοράσετε.",
          "³Οι δυνατότητες και τα οφέλη των τεχνολογιών Intel εξαρτώνται από τη διαμόρφωση του συστήματος και ενδέχεται να απαιτούν κατάλληλο υλικό ή λογισμικό ή ενεργοποίηση υπηρεσίας. Οι επιδόσεις διαφέρουν ανάλογα με τη διαμόρφωση του συστήματος. Κανένα σύστημα δεν μπορεί να προσφέρει απόλυτη ασφάλεια. Ρωτήστε τον κατασκευαστή του συστήματος ή τον πωλητή ή μάθετε περισσότερα στο intel.com.",
          "⁴Για συστήματα με οθόνες FHD, όταν χρησιμοποιούνται για ασύρματη περιήγηση στον Ιστό. Όταν απενεργοποιούνται, από προεπιλεγμένο επίπεδο απενεργοποίησης OEM.",
          "⁵Σε σύγκριση με άλλες τεχνολογίες σύνδεσης συσκευών εισόδου-εξόδου για υπολογιστές, συμπεριλαμβανομένων των eSATA, USB και IEEE 1394* FireWire. Οι επιδόσεις θα διαφέρουν ανάλογα με το υλικό και το λογισμικό που χρησιμοποιείτε. Απαραίτητη η χρήση συσκευής με δυνατότητα τεχνολογίας Thunderbolt™.",
          "⁶Σχεδόν 3 φορές πιο γρήγορο: Το 802.11ax 2×2 160 MHz επιτρέπει θεωρητικό μέγιστο ρυθμό μετάδοσης δεδομένων 2402 Mbps. ~3 φορές πιο γρήγορο από το τυπικό Wi‑Fi 802.11ac 2×2 80 MHz (867 Mbps) όπως τεκμηριώνεται στις προδιαγραφές του προτύπου ασύρματης επικοινωνίας IEEE 802.11* και απαιτεί τη χρήση παρόμοια διαμορφωμένων δρομολογητών ασύρματου δικτύου 802.11ax.",
          "⁷Μείωση λανθάνοντα χρόνου κατά 75%: βασίζεται σε δεδομένα προσομοίωσης του 802.11ax από την Intel (79%), με και χωρίς OFDMA, με χρήση 9 πελατών. Ο μέσος λανθάνων χρόνος χωρίς OFDM είναι 36 ms, ενώ με OFDMA μειώνεται στα 7,6 ms. Η βελτίωση του λανθάνοντα χρόνου προϋποθέτει ότι ο δρομολογητής 802.11ax (Wi-Fi 6) και όλοι οι πελάτες υποστηρίζουν OFDMA.",
          "⁸Βάσει της σύγκρισης του θεωρητικά μέγιστου ρυθμού δεδομένων στις προδιαγραφές του 802.11, το Wi‑Fi 6/6E (802.11ax) 2×2 160 MHz μπορεί να προσφέρει 2402 Mbps έναντι του Wi‑Fi 5 (802.11ac) 2×2 80 MHz, που μπορεί να προσφέρει 867 Mbps, το οποίο ισούται με 2,8 φορές υψηλότερες ταχύτητες.",
          "⁹Το καλύτερο Wi‑Fi 6 της κατηγορίας: τα προϊόντα Intel® Wi‑Fi 6 (Gig+) υποστηρίζουν προαιρετικά κανάλια 160 MHz, ώστε να είναι εφικτές οι γρηγορότερες δυνατές μέγιστες θεωρητικές ταχύτητες (2402 Mbps) με τυπικά προϊόντα Wi‑Fi 802.11ax 2×2 για PC. Τα premium προϊόντα Intel® Wi‑Fi 6 (Gig+) επιτρέπουν 2 έως 4 φορές μεγαλύτερες μέγιστες θεωρητικές ταχύτητες σε σύγκριση με τα τυπικά προϊόντα Wi‑Fi 802.11ax 2×2 (1201 Mbps) ή 1×1 (600 Mbps) για PC, τα οποία υποστηρίζουν μόνο την απαίτηση ύπαρξης καναλιών 80 MHz.",
          "Μόνο επιλεγμένα SKU. Η λειτουργικότητα διαφέρει ανάλογα με το σχέδιο του OEM. Για λεπτομέρειες, απευθυνθείτε στον OEM ή το κατάστημα λιανικής πώλησης. Για περισσότερες πληροφορίες για το Wi‑Fi, επισκεφθείτε τη διεύθυνση intel.com/wifi6disclaimers.",
          "¹⁰Η τεχνολογία Thunderbolt™ 4, στα 40 Gbps, προσφέρει την πιο γρήγορη, απλή και αξιόπιστη λύση καλωδίου για οποιαδήποτε βάση σύνδεσης, οθόνη ή συσκευή δεδομένων, σε σύγκριση με άλλες τεχνολογίες σύνδεσης εισόδου/εξόδου υπολογιστών που περιλαμβάνουν τις eSATA*, USB* και IEEE 1394* FireWire.",
          "¹¹Απαιτεί δρομολογητή βασισμένο στο πρότυπο 802.11ax που υποστηρίζει OFDMA και πολλούς πελάτες στο δίκτυο με υποστήριξη για AX. Οι καλύτερες επιδόσεις σε πυκνά περιβάλλοντα είναι εφικτές χάρη στις δυνατότητες OFDMA τις οποίες υποστηρίζουν πελάτες και σημεία πρόσβασης τεχνολογίας 802.11ax. 2 Gbps με βάση σενάριο στο 70% των θεωρητικά μέγιστων ρυθμών μετάδοσης δεδομένων της προδιαγραφής IEEE 802.11* για το 802.11ax 160 MHz 2402 Mbps.",
          "¹²Σύμφωνα με μέτρηση του φόρτου εργασίας «Άνοιγμα εγγράφου με δραστηριότητα στο παρασκήνιο» που σύγκρινε επεξεργαστή Intel® Core™ i7-8565U 8ης γενιάς (512 GB TLC SSD) με επεξεργαστή Intel® Core™ i7-8565U 8ης γενιάς (32 GB + 512 GB μνήμη Intel® Optane™ H10 με μονάδα αποθήκευσης σταθερής κατάστασης).",
          "¹³Το Intel® Thread Director είναι ενσωματωμένο στη σχεδίαση των επεξεργαστών Intel® Core™ 12ης γενιάς και υποστηρίζει τα λειτουργικά συστήματα ώστε να κατευθύνουν πιο έξυπνα τους φόρτους εργασίας στον κατάλληλο πυρήνα. Δεν απαιτείται ενέργεια χρήστη. Για λεπτομέρειες, ανατρέξτε στο intel.com.",
          "¹⁴Δεν διατίθεται σε ορισμένους επεξεργαστές Intel® Core™ 12ης γενιάς. Η υβριδική αρχιτεκτονική επιδόσεων συνδυάζει δύο νέες μικροαρχιτεκτονικές πυρήνων, τους Performance-cores (P-cores) και τους Efficient-cores (E-cores), σε ένα πλακίδιο επεξεργαστή. Επιλεγμένοι επεξεργαστές Intel® Core™ 12ης γενιάς (ορισμένοι επεξεργαστές Intel® Core™ i5 12ης γενιάς και κατώτεροι) δεν υποστηρίζουν υβριδική αρχιτεκτονική επιδόσεων, παρά μόνο P-cores.",
          "¹⁵Σύμφωνα με μέτρηση του σεναρίου «Εκκίνηση παιχνιδιού» που σύγκρινε επεξεργαστή Intel® Core™ i7-8750H 8ης γενιάς (μονάδα μνήμης Intel® Optane™ 32 GB + Intel® SSD 256 GB PCIe* + HDD 1 TB) με επεξεργαστή Intel® Core™ i7-8750H 8ης γενιάς (Intel® SSD 256 GB PCIe* + HDD 1 TB). Σενάριο «Εκκίνηση παιχνιδιού»: σενάριο το οποίο δημιούργησε η Intel και μετράει πόσος χρόνος απαιτείται για την εκκίνηση του «Total War*: WARHAMMER* II» Build: 5577.0 και την πρόσβαση στο κύριο μενού με απενεργοποιημένα τα εισαγωγικά βίντεο (εκκίνηση). Διαμορφώσεις για την επιτάχυνση της μονάδας δεδομένων από τη μνήμη Intel® Optane™: επεξεργαστής Intel® Core™ i7-8750H, PL1 = 45 W TDP, 6C/12T, turbo έως 4 GHz σε σύστημα OEM πριν από το στάδιο της παραγωγής, γραφικά NVIDIA* GeForce* GTX 1070, μνήμη 2 × 4 GB DDR4, συσκευή αποθήκευσης Intel® SSD Series 760p 256 GB (PCIe*) + HDD 1 TB + μνήμη Intel® Optane™ 32 GB, λειτουργικό σύστημα Windows* 10 RS3 Build 1709, MCU 0x84· επεξεργαστής Intel® Core™ i7-8750H, PL1 = 45 W TDP, 6C/12T, turbo έως 4 GHz σε σύστημα OEM πριν από το στάδιο της παραγωγής, γραφικά NVIDIA* GeForce* GTX 1070, μνήμη 2 × 4 GB DDR4, συσκευή αποθήκευσης Intel® SSD Series 760p 256 GB (PCIe*) + HDD 1 TB, λειτουργικό σύστημα Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁶Σύμφωνα με μέτρηση του σεναρίου «Φόρτωση επιπέδου παιχνιδιού» που σύγκρινε επεξεργαστή Intel® Core™ i7-8750H 8ης γενιάς (μονάδα μνήμης Intel® Optane™ 32&nbsp;GB) με επεξεργαστή Intel® Core™ i7-8750H 8ης γενιάς (HDD μόνο). Σενάριο «Φόρτωση επιπέδου παιχνιδιού»: σενάριο το οποίο δημιούργησε η Intel και μετράει πόσος χρόνος απαιτείται από το κύριο μενού μέχρι την ολοκλήρωση της φόρτωσης επιπέδου (φόρτωση επιπέδου) στο «Total War*: WARHAMMER* II» Build: 5577.0. Διαμορφώσεις για την επιτάχυνση της μονάδας δεδομένων από τη μνήμη Intel® Optane™: επεξεργαστής Intel® Core™ i7-8750H, PL1 = 45&nbsp;W TDP, 6C12T, turbo έως 4&nbsp;GHz σε σύστημα OEM πριν από το στάδιο της παραγωγής, γραφικά NVIDIA* GeForce* GTX 1070, μνήμη 2 × 4&nbsp;GB DDR4, συσκευή αποθήκευσης SSD Intel® Series 760p 256 GB (PCIe*) + HDD 1&nbsp;TB + μνήμη Intel® Optane™ 32&nbsp;GB, λειτουργικό σύστημα Windows* 10 RS3 Build 1709, MCU 0x84· επεξεργαστής Intel® Core™ i7-8750H, PL1 = 45&nbsp;W TDP, 6C12T, turbo έως 4&nbsp;GHz σε σύστημα OEM πριν από το στάδιο της παραγωγής, γραφικά NVIDIA* GeForce* GTX 1070, μνήμη 2 × 4&nbsp;GB DDR4, συσκευή αποθήκευσης SSD Intel® Series 760p 256 GB (PCIe*) + HDD 1&nbsp;TB, λειτουργικό σύστημα Windows* 10 RS3 Build 1709, MCU 0x84.",
          "¹⁷Σε υπολογιστές με Performance-cores. Οι επιδόσεις ποικίλλουν ανάλογα με τη χρήση, τη διαμόρφωση και άλλους παράγοντες. Μάθετε περισσότερα στη διεύθυνση www.intel.com/PerformanceIndex.",
          "¹⁸Η αλλαγή της συχνότητας χρονισμού ή της τάσης ενδέχεται να προκαλέσει βλάβη ή να μειώσει την ωφέλιμη ζωή του επεξεργαστή και άλλων στοιχείων του συστήματος, και ενδέχεται να μειώσει τη σταθερότητα και τις επιδόσεις του συστήματος. Η εγγύηση του προϊόντος ενδέχεται να μην ισχύει αν ο επεξεργαστής λειτουργεί εκτός των προδιαγραφών του. Για περισσότερες λεπτομέρειες, απευθυνθείτε στον κατασκευαστή του συστήματος και των εξαρτημάτων του.",
          "¹⁹Βάσει του απαράμιλλου τρόπου με τον οποίο η πλατφόρμα Intel vPro® συνδυάζει χαρακτηριστικά ασφάλεια πάνω και κάτω από το λειτουργικό σύστημα, μέτρα προστασίας εφαρμογών και δεδομένων, μέτρα προστασίας από προηγμένες απειλές, για επιχειρήσεις κάθε μεγέθους, καθώς και βάσει του γεγονότος ότι η Intel θέτει ως προτεραιότητα την ασφάλεια κατά τη σχεδίαση, την κατασκευή και την υποστήριξη προϊόντων. Όσοι υπολογιστές για επιχειρήσεις βασίζονται στην πλατφόρμα Intel vPro® έχει επικυρωθεί ότι πληρούν αυστηρές προδιαγραφές και διαθέτουν μοναδικά χαρακτηριστικά ασφάλειας με βάση το υλικό. Για λεπτομέρειες, βλ. www.intel.com/PerformanceIndex (Platforms). Κανένα προϊόν ή εξάρτημα δεν μπορεί να προσφέρει απόλυτη ασφάλεια.",
          "²⁰Το Intel® Connectivity Performance Suite είναι διαθέσιμο μόνο σε συστήματα με Windows*. Δοκιμή Wi-Fi Over The Air (OTA) της Intel σε συμφορημένο δίκτυο σε σύγκριση με καθορισμό προτεραιότητας κίνησης ασύρματου δικτύου με εφαρμογή λογισμικού χωρίς ICPS και έξυπνη εναλλαγή σημείων πρόσβασης. Για λεπτομέρειες, βλ. www.intel.com/PerformanceIndex (Connectivity). Τα αποτελέσματα ενδέχεται να διαφέρουν.",
          "²¹Σε σύγκριση με την προηγούμενη γενιά επεξεργαστή.",
          "²²Η μονάδα γραφικών Intel® Arc™ είναι η πρώτη στην αγορά με υποστήριξη κωδικοποίησης AV1 (από το πρώτο τρίμηνο του 2022). Λεπτομέρειες μπορείτε να βρείτε στο intel.com/performanceindex.",
          "²³Σύμφωνα με μετρήσεις των μοναδικών χαρακτηριστικών των σχεδίων Intel® Evo™, τα οποία έχουν επίσης επαληθευτεί ώστε να ανταποκρίνονται στα βασικά όρια εμπειρίας χρηστών κινητών συσκευών. Η δοκιμή διενεργήθηκε τον Φεβρουάριο του 2022.",
          "²⁴Η λύση Intel® Unison™ είναι προς το παρόν διαθέσιμη μόνο σε κατάλληλα σχέδια Intel® Evo™ με Windows* και συνδέεται μόνο με τηλέφωνα λειτουργικού Android ή iOS. Όλες οι συσκευές πρέπει να εκτελούν υποστηριζόμενη έκδοση λειτουργικού συστήματος.",
          "²⁵Οι μέγιστες ταχύτητες μνήμης αφορούν διαμορφώσεις με 1 DIMM ανά κανάλι (1DPC). Η επιπλέον φόρτωση DIMM σε οποιοδήποτε κανάλι μπορεί να επηρεάσει τη μέγιστη ταχύτητα της μνήμης. Έως DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 και DDR5-5200 1Rx8, 1Rx16, 2Rx8 σε επιλεγμένα SKU. Η μέγιστη χωρητικότητα μνήμης επιτυγχάνεται με διαμορφώσεις 2DPC.",
          "²⁶Έναντι Wi-Fi τυπικού τύπου. Για λεπτομέρειες, βλ. intel.com/performance-wireless. Τα αποτελέσματα ενδέχεται να διαφέρουν.",
          "²⁷Η υβριδική αρχιτεκτονική επιδόσεων συνδυάζει δύο μικροαρχιτεκτονικές πυρήνων, τους Performance-cores (P-cores) και τους Efficient-cores (E-cores), σε ένα πλακίδιο επεξεργαστή, το οποίο έγινε για πρώτη φορά στους επεξεργαστές Intel® Core™ 12ης γενιάς. Επιλεγμένοι επεξεργαστές Intel® Core™ 12ης και νεότερης γενιάς δεν διαθέτουν υβριδική αρχιτεκτονική επιδόσεων αλλά μόνο P-cores και το ίδιο μέγεθος κρυφής μνήμης με την προηγούμενη γενιά. Δείτε τα SKU λεπτομερώς στο ark.intel.com.",
          "²⁸Το Intel® Thread Director είναι ενσωματωμένο στο υλικό και παρέχεται μόνο σε διαμορφώσεις των επεξεργαστών Intel® Core™ 12ης γενιάς ή Intel® Core™ νεότερης γενιάς με υβριδική αρχιτεκτονική επιδόσεων. Απαιτείται ενεργοποίηση από το λειτουργικό σύστημα. Τα διαθέσιμα χαρακτηριστικά και οι λειτουργίες ποικίλλουν ανάλογα με το λειτουργικό σύστημα.",
          "²⁹Το Intel® Thread Director είναι ενσωματωμένο στο υλικό και παρέχεται μόνο σε διαμορφώσεις των επεξεργαστών Intel® Core™ 12ης και 13ης γενιάς με υβριδική αρχιτεκτονική επιδόσεων. Απαιτείται ενεργοποίηση από το λειτουργικό σύστημα. Τα διαθέσιμα χαρακτηριστικά και οι λειτουργίες ποικίλλουν ανάλογα με το λειτουργικό σύστημα.",
          "³⁰Όπως μετρήθηκε από την πλατφόρμα επικύρωσης αναφοράς, η απόδοση του επεξεργαστή για φορητούς υπολογιστές Intel®&nbsp;Core™ Ultra (Series&nbsp;2 - κωδική ονομασία: Lunar Lake) έναντι του επεξεργαστή για φορητούς υπολογιστές Intel®&nbsp;Core™ Ultra (Series&nbsp;1 - κωδική ονομασία: Meteor Lake) στο 3DMark Time Spy. Για λεπτομέρειες, βλ. intel.com/performanceindex. Τα αποτελέσματα ενδέχεται να διαφέρουν.",
          "³¹Το θάμπωμα φόντου και η παρακολούθηση προσώπου διατίθενται μόνο σε συστήματα με Windows*.",
          "³²Τα προϊόντα Wi-Fi 7 έχουν πρόσβαση σε κανάλια 320 MHz στα 6 GHz και σε νέους συνδυασμούς καναλιών 160 MHz στα 5 GHz με νέες δυνατότητες Διάτρησης Μονάδας Πολλαπλών Πόρων.",
          "³³Βασισμένο στις προδιαγραφές του βασικού ασύρματου δικτύου της ΙΕΕΕ και τις μέγιστες θεωρητικές ταχύτητες δεδομένων για συσκευές 2 ροών.",
          "³⁴Οι μηχανικές προσομοιώσεις της Intel σε περιβάλλοντα δικτύου με συμφόρηση υποδεικνύουν ότι είναι εφικτή η σημαντική μείωση του λανθάνοντα χρόνου χάρη στις νέες δυνατότητες λειτουργίας Wi-Fi 7 Multi-Link.",
          "³⁵Ελάχιστη φόρτιση που επιτυγχάνεται από το προεπιλεγμένο επίπεδο τερματισμού λειτουργίας του OEM. Τα αποτελέσματα κάθε συστήματος ενδέχεται να διαφέρουν. Για λεπτομέρειες, βλ. intel.com/performance-evo.",
          "³⁶Η ζώνη Wi-Fi των 6 GHz δεν είναι διαθέσιμη για χρήση σε όλες τις χώρες/περιοχές.",
          "³⁷Μόνο στα Windows*.",
          "³⁸Φόρτιση που επιτυγχάνεται από το προεπιλεγμένο επίπεδο τερματισμού λειτουργίας του OEM. Για πιο ολοκληρωμένες πληροφορίες σχετικά με τις επιδόσεις και τα αποτελέσματα των ελέγχων επιδόσεων, επισκεφθείτε το intel.com/Evo.",
          "³⁹Αναφέρεται στους επεξεργαστές για φορητούς υπολογιστές Intel®&nbsp;Core™ Ultra (Series&nbsp;2), με βάση τα μοναδικά χαρακτηριστικά ασφαλείας που βασίζονται στο υλικό, τον ασυναγώνιστο συνδυασμό δυνατοτήτων ασφαλείας πάνω και κάτω από το λειτουργικό σύστημα, τις εντυπωσιακές επιδόσεις ακόμη και σε χαμηλότερη ισχύ σε σύγκριση με την προηγούμενη γενιά (από τον Σεπτέμβριο του 2024). Για λεπτομέρειες, βλ. intel.com/performanceindex. Τα αποτελέσματα ενδέχεται να διαφέρουν.",
          "⁴⁰Οι εφαρμογές λογισμικού απεικονίζονται μόνο για ενδεικτικούς σκοπούς. Για ορισμένες δυνατότητες τεχνητής νοημοσύνης ενδέχεται να απαιτείται αγορά πρόσθετου λογισμικού, συνδρομή ή ενεργοποίηση από κάποιον πάροχο λογισμικού ή πλατφόρμας, ή να υπάρχουν συγκεκριμένες απαιτήσεις διαμόρφωσης ή συμβατότητας. Λεπτομέρειες στο www.intel.com/PerformanceIndex. Τα αποτελέσματα ενδέχεται να διαφέρουν. © Intel Corporation.",
          "⁴¹Οι εικόνες που εμφανίζονται ενδέχεται να έχουν τροποποιηθεί ή προσομοιωθεί. Για ορισμένες δυνατότητες τεχνητής νοημοσύνης ενδέχεται να απαιτείται αγορά πρόσθετου λογισμικού, συνδρομή ή ενεργοποίηση από κάποιον πάροχο λογισμικού ή πλατφόρμας, ή να υπάρχουν συγκεκριμένες απαιτήσεις διαμόρφωσης ή συμβατότητας. Λεπτομέρειες στο www.intel.com/AIPC.",
          "⁴²Κυκλοφορεί σταδιακά σε προεπισκόπηση στο πλαίσιο της τελευταίας ενημέρωσης των Windows* 11 σε επιλεγμένες παγκόσμιες αγορές. Ο χρόνος διαθεσιμότητας ποικίλλει ανάλογα με τη συσκευή και την αγορά. Μάθετε περισσότερα εδώ: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³Η λύση Intel® Unison™ είναι επί του παρόντος διαθέσιμη για επιλέξιμους σχεδιασμούς. Για λεπτομέρειες, βλ. www.intel.com/PerformanceIndex.",
          "⁴⁴FPS αναβαθμισμένα από το Intel® X<sup>e</sup>SS σε σύγκριση με τα εγγενή FPS βάσει μετρήσεων στο Dying Light 2 Stay Human που εκτελέστηκε στον επεξεργαστή Intel® Core™ Ultra 7 165H. Για λεπτομέρειες, βλ. www.intel.com/PerformanceIndex. Τα αποτελέσματα ενδέχεται να διαφέρουν.",
          "⁴⁵Όπως μετράται από τη χαμηλότερη ισχύ SoC του επεξεργαστή για φορητούς υπολογιστές Intel®&nbsp;Core™ Ultra (Series&nbsp;2 - κωδική ονομασία: Lunar Lake) πλατφόρμα επικύρωσης αναφοράς έναντι του επεξεργαστή για φορητούς υπολογιστές Intel®&nbsp;Core™ Ultra (Series&nbsp;1 - κωδική ονομασία: Meteor Lake) πλατφόρμα επικύρωσης αναφοράς στο YouTube* 4K 30FPS AV1. Για λεπτομέρειες, βλ. intel.com/performanceindex. Τα αποτελέσματα ενδέχεται να διαφέρουν.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Η Intel δεν φέρει καμία ευθύνη για λανθασμένες τιμές.",
          "*Άλλες επωνυμίες και εμπορικά σήματα ενδέχεται να αποτελούν ιδιοκτησία τρίτων.",
          "COPYRIGHT 2022 INFLEXION GAMES. TA ONOMATA NIGHTINGALE, INFLEXION GAMES, ΚΑΘΩΣ ΚΑΙ ΤΑ ΣΧΕΤΙΚΑ ΣΧΕΔΙΑ KAI ΣΥΝΘΕΤΑ ΣΗΜΑΤΑ ΑΠΟΤΕΛΟΥΝ ΕΜΠΟΡΙΚΑ ΣΗΜΑΤΑ ΤΗΣ INFLEXION STUDIOS INC. ΜΕ ΤΗΝ ΕΠΙΦΥΛΑΞΗ ΠΑΝΤΟΣ ΔΙΚΑΙΩΜΑΤΟΣ.",
          "Για τις τεχνολογίες Intel πιθανόν να απαιτείται κατάλληλο υλικό, λογισμικό ή ενεργοποίηση υπηρεσίας.",
          "Οποιαδήποτε αποτελέσματα και δεδομένα από το NERO* παρέχονται για λόγους ευκολίας και πληροφόρησης. Η Intel Corporation δεν φέρει καμία ευθύνη για την ακρίβεια, παραδείγματος χάριν, της εφαρμογής NERO*, των αποτελεσμάτων επιδόσεων και των βαθμολογιών, ή για τις πιθανές διαφορές από άλλες εκδόσεις της εφαρμογής με δυνατότητα λήψης που διατίθενται στον ιστότοπο www.nero.com ή σε ιστότοπους τρίτων. Οι βαθμολογίες ελέγχου επιδόσεων του NERO* για PC μετριούνται με χρήση συγκεκριμένων υπολογιστικών συστημάτων, στοιχείων, λογισμικών, ενεργειών και λειτουργιών. Οποιαδήποτε αλλαγή σε οποιονδήποτε από αυτούς τους παράγοντες ενδέχεται να επιφέρει αλλαγή των αποτελεσμάτων. Για να αξιολογήσετε πλήρως τις αγορές που σκοπεύετε να κάνετε, πρέπει να συμβουλευτείτε και άλλες πληροφορίες και δοκιμές επιδόσεων, συμπεριλαμβανομένων των επιδόσεων αυτού του προϊόντος όταν συνδυάζεται με άλλα προϊόντα.",
          "Όλες οι εκδόσεις της πλατφόρμας Intel vPro® απαιτούν κατάλληλο επεξεργαστή Intel® Core™, υποστηριζόμενο λειτουργικό σύστημα, chip Intel® LAN και/ή WLAN, βελτιώσεις υλικολογισμικού και όποιο άλλο υλικό και λογισμικό απαιτείται για να επιτευχθούν η δυνατότητα διαχείρισης, τα χαρακτηριστικά ασφάλειας, οι επιδόσεις συστήματος και η σταθερότητα που χαρακτηρίζουν την πλατφόρμα.",
          "Οι επιδόσεις ποικίλουν ανάλογα με τη χρήση, τη διαμόρφωση και άλλους παράγοντες. Μάθετε περισσότερα στη διεύθυνση www.intel.com/PerformanceIndex.",
          "Η Intel δεν ελέγχει τη διάθεση ή την ακρίβεια δεδομένων τρίτων. Πρέπει να συμβουλευτείτε άλλες πηγές για να αξιολογήσετε την ακρίβεια.",
          "© Intel Corporation. Το «Intel», το λογότυπο «Intel» και άλλα σήματα Intel αποτελούν εμπορικά σήματα της Intel Corporation ή των θυγατρικών της. *Άλλες επωνυμίες και εμπορικά σήματα ενδέχεται να αποτελούν ιδιοκτησία τρίτων.",
          "Κανένα προϊόν ή εξάρτημα δεν μπορεί να προσφέρει απόλυτη ασφάλεια.",
          "Οι δικές σας τιμές και τα αποτελέσματα ίσως διαφέρουν.",
          "Το «Intel», το λογότυπο «Intel», το «Intel Core», το «Intel Optane», το «Intel Iris», το «Thunderbolt» και το λογότυπο «Thunderbolt» αποτελούν εμπορικά σήματα της Intel Corporation ή των θυγατρικών της. Άλλες επωνυμίες και εμπορικά σήματα ενδέχεται να αποτελούν ιδιοκτησία τρίτων.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Αρχική",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Σαρώστε για σύγκριση",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Ανερχόμενα",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "Ξεκλειδώστε νέες εμπειρίες AI με τους επεξεργαστές Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "Ξεκλειδώστε νέες εμπειρίες AI με τους επεξεργαστές Intel® Core™ Ultra",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "Ξεκλειδώστε νέες εμπειρίες AI με τους επεξεργαστές Intel® Core™ Ultra",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "Stable Diffusion*: Κείμενο σε εικόνα",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe*: Επεξεργασία φωτογραφίας",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "Intel® X<sup>e</sup>SS : Αύξηση κλιμάκωσης εικόνας (upscaling)",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee*: Ανιχνευτής Deepfake",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "Συνεργατικά εφέ (Teams)",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "IQIYI: Έλεγχος με χειρονομίες",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "Συγχρονίστε το PC σας με το τηλέφωνό σας και λάβετε ειδοποιήσεις, κλήσεις και μηνύματα",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "Άφθονα και ζωντανά ενσωματωμένα γραφικά",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "Microsoft*: Η ζωή σας, στα χέρια σας",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "Microsoft*: Τα πάντα για εσάς. Όλα με μια ματιά: μόνο ένα swipe μακριά",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "Κάντε τον επεξεργαστή σας να λειτουργεί γρηγορότερα και σε υψηλότερες συχνότητες, βελτιώνοντας το σύστημά σας",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "Ενισχύστε την απόδοση του επιτραπέζιου υπολογιστή ή του κινητού σας, χωρίς να πρέπει να θυσιάσετε χώρο αποθήκευσης",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "Ενισχύστε την απόδοση του επιτραπέζιου υπολογιστή ή του κινητού σας, χωρίς να πρέπει να θυσιάσετε χώρο αποθήκευσης",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "Samsung OLED: Χάρμα οφθαλμών",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "Βελτιωμένη ταχύτητα, λανθάνοντας χρόνος και αξιοπιστία χάρη στη βελτιστοποίηση δικτύωσης μέσω ΑΙ",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "Δικτύωση PC σαν ενσύρματη",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "Δικτύωση PC σαν ενσύρματη",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "Σχεδόν 3 φορές μεγαλύτερες ταχύτητες χάρη στο Intel® Wi-Fi 6 (Gig+)",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "Σύνδεση χωρίς ανταγωνισμό",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "Πραγματικά καθολική συνδεσιμότητα καλωδίων",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "Παίξτε όπως οι επαγγελματίες: Δείτε πώς η Intel ενισχύει το gaming και τους gamers",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "Παίξτε όπως οι επαγγελματίες: Η Intel γεννά πρωταθλητές",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "ΚΑΛΩΣ ΗΛΘΑΤΕ",
    "tr_welcomePagetrSubtitle": "Χειριστείτε απομακρυσμένα αυτό το PC με το smartphone σας",
    "tr_welcomePagembSubtitle": "Καλώς ήλθατε, εξερευνήστε τις προδιαγραφές αυτού του PC στο smartphone σας",
    "tr_welcomePageBtnText": "Ξεκινήστε",
    "tr_welcomePageText": "Εξερευνήστε και συγκρίνετε τις λεπτομέρειες αυτού του PC στο smartphone σας",
    "tr_scrollText": "Περιηγηθείτε στη συσκευή που έχετε μπροστά σας",
    "tr_toolTipTextc1": "Χρησιμοποιήστε αυτά τα κουμπιά για να περιηγηθείτε σε διαφορετικές σελίδες στη συσκευή που έχετε μπροστά σας.",
    "tr_toolTipTextc2": "Πατώντας στις παρακάτω κάρτες θα περιηγηθείτε σε μια λεπτομερή σελίδα των προδιαγραφών κάθε συσκευής.",
    "tr_toolTipTextc3": "Χρησιμοποιήστε αυτά τα κουμπιά για να προχωρήσετε πάνω και κάτω στην οθόνη που βλέπετε.",
    "tr_toolTipTextc4": "Απενεργοποιήστε την απομακρυσμένη λειτουργία για να δείτε τις προδιαγραφές της συσκευής στο smartphone σας.",
    "tr_toolTipTextmb1": "Πατήστε στις κάρτες για να δείτε τις λεπτομερείς σελίδες περιεχομένου.",
    "tr_toolTipTextmb2": "Πατήστε για να δείτε την περίληψη όλων των προδιαγραφών της συσκευής",
    "tr_toolTipTextmb3": "Πατήστε το κουμπί για να σαρώσετε τον κωδικό QR και να συγκρίνετε έως τέσσερις συσκευές",
    "tr_nextButton": "Επόμενο",
    "tr_prevButton": "Προηγ.",
    "tr_gotItButton": "ΚΑΤΑΛΑΒΑ",
    "tr_skipButton": "Παράλειψη",
    "tr_placeholderText": "*σύμβολο υποκατάστασης",
    "tr_processorHeading": "Επεξεργαστής",
    "tr_processorSubheading": "Επεξεργαστής Intel® Core™ i7 10ης γενιάς",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "Μνήμη Intel® Optane™ [XX] GB + SSD [YY] GB",
    "tr_coachmarkProcessor": "Επεξεργαστής XX",
    "tr_coachmarkGraphics": "Γραφικά [XX]",
    "tr_coachmarkMemory": "Αξία προδιαγραφής",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "999,95 $",
    "tr_compare": "Σύγκριση",
    "tr_viewallspecs": "ΕΜΦΑΝΙΣΗ ΟΛΩΝ ΤΩΝ ΠΡΟΔΙΑΓΡΑΦΩΝ",
    "tr_operatingSystem": "ΛΕΙΤΟΥΡΓΙΚΟ ΣΥΣΤΗΜΑ",
    "tr_AmdProcessorNonIntel": "Επεξεργαστής AMD*",
    "tr_QualcommProcessorNonIntel": "Επεξεργαστής Qualcomm*",
    "tr_GBText": "GB",
    "tr_remoteText": "Οι προδιαγραφές εμφανίζονται στην οθόνη του laptop",
    "tr_scanErrorText": "Μπορείτε να σαρώσετε μόνο έως τρεις συσκευές ταυτόχρονα. Διαγράψτε μία ή περισσότερες συσκευές.",
    "tr_scanErrorText2": "Έχετε ήδη σαρώσει αυτήν τη συσκευή, σαρώστε μια άλλη συσκευή.",
    "tr_scanMsg": "Σάρωση για προσθήκη συσκευής",
    "tr_backToScan": "Επιστροφή στη Σύγκριση",
    "tr_scanToCompare": "Σάρωση για Σύγκριση",
    "tr_compareDevice": "Σύγκριση συσκευών",
    "tr_processorTitle": "ΕΠΕΞΕΡΓΑΣΤΗΣ",
    "tr_graphicTitle": "ΣΥΣΚΕΥΗ ΓΡΑΦΙΚΩΝ",
    "tr_storageTitle": "ΜΟΝΑΔΑ ΑΠΟΘΗΚΕΥΣΗΣ",
    "tr_displayTitle": "ΟΘΟΝΗ",
    "tr_batteryTitle": "ΜΠΑΤΑΡΙΑ",
    "tr_memoryTitle": "ΜΝΗΜΗ",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "ΣΥΝΔΕΣΙΜΟΤΗΤΑ",
    "tr_priceTitle": "ΤΙΜΗ",
    "tr_operatingSystemTitle": "ΛΕΙΤΟΥΡΓΙΚΟ ΣΥΣΤΗΜΑ",
    "tr_batteryHoursText": "[XX] ώρες",
    "tr_hrsText": "ώρες",
    "tr_touchscreeenText": "Οθόνη αφής [screenDiagonal]″",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, Οθόνη αφής",
    "tr_FHDTouchScreenText": "FHD, Οθόνη αφής",
    "tr_4KTouchscreenText": "4K, Οθόνη αφής",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Αποθήκευση",
    "tr_device_comparison": "Έγγραφο σύγκρισης συσκευών [dd]-[mm]-[yyyy]",
    "tr_Save_As_JPEG": "Αποθήκευση ως JPEG",
    "tr_Save_As_PDF": "Αποθήκευση ως PDF",
    "tr_Downloading_PDF": "Λήψη PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Άνοιγμα",
    "tr_logo": "λογότυπο",
    "tr_laptop_name": "Όνομα laptop",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Μνήμη Intel® Optane™",
    "tr_inchUnit": "ίντσα",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "ΜΝΗΜΗ INTEL® OPTANE™",
    "tr_Thunderbolt3": "Τεχνολογία Thunderbolt™ 3",
    "tr_Thunderbolt4": "Τεχνολογία Thunderbolt™ 4",
    "tr_processorGraphicsCaps": "ΓΡΑΦΙΚΑ ΕΠΕΞΕΡΓΑΣΤΗ",
    "tr_processorGraphicsSmall": "Γραφικά επεξεργαστή",
    "tr_graphicsCardCaps": "ΑΠΟΚΛΕΙΣΤΙΚΑ ΓΡΑΦΙΚΑ",
    "tr_graphicsCardSmall": "Αποκλειστικά γραφικά",
    "tr_processorTitleCamel": "Επεξεργαστής",
    "tr_graphicTitleCamel": "Γραφικά",
    "tr_storageTitleCamel": "Μονάδα αποθήκευσης",
    "tr_displayTitleCamel": "Οθόνη",
    "tr_batteryTitleCamel": "Μπαταρία",
    "tr_memoryTitleCamel": "Μνήμη",
    "tr_connectivityTitleCamel": "Συνδεσιμότητα",
    "tr_priceTitleCamel": "Τιμή",
    "tr_operatingSystemTitleCamel": "Λειτουργικό σύστημα",
    "tr_viewallspecsCamel": "Προβολή όλων των προδιαγραφών",
    "tr_displayText": "[screenDiagonal]″",
    "tr_displayTextResolution": "[screenDiagonal]″ [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "Οθόνη αφής [screenDiagonal]″ [screenResolutionType]",
    "tr_OS": "ΛΕΙΤΟΥΡΓΙΚΟ ΣΥΣΤΗΜΑ",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "Προδιαγραφές PC",
    "tr_explorePC_text": "Εξερευνήστε τα πάντα για αυτό το PC",
    "tr_mtlPC_text": "Δείτε τι πραγματικά σημαίνει σχεδιασμένα για να τα κάνουν όλα",
    "tr_optaneMemory": "Μνήμη Intel®&nbsp;Optane™",
    "tr_displayText_Display": "ΟΘΟΝΗ [screenDiagonal]\"",
    "tr_displayTextResolution_Display": "ΟΘΟΝΗ [screenResolutionType] [screenDiagonal]\"",
    "tr_displayTextResolutionTouchscreen_Display": "ΟΘΟΝΗ ΑΦΗΣ [screenResolutionType] [screenDiagonal]\"",
    "tr_displayTextTouchscreeenText": "ΟΘΟΝΗ ΑΦΗΣ [screenDiagonal]\"",
    "tr_learnMoreLabel": "Μάθετε περισσότερα",
    "tr_batteryHoursTextUpperCase": "[XX] ΩΡΕΣ",
    "tr_processorGraphicsIrisXe": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ [cpu_fam] 11ΗΣ ΓΕΝΙΑΣ ΚΑΙ ΓΡΑΦΙΚΑ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ [cpu_fam] 11ΗΣ ΓΕΝΙΑΣ ΚΑΙ ΓΡΑΦΙΚΑ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>MAX",
    "tr_Thunderbolt3UpperCase": "ΤΕΧΝΟΛΟΓΙΑ THUNDERBOLT™ 3",
    "tr_Thunderbolt4UpperCase": "ΤΕΧΝΟΛΟΓΙΑ THUNDERBOLT™ 4",
    "tr_processorWithArcGraphics": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ [cpu_fam] 12ΗΣ ΓΕΝΙΑΣ ΚΑΙ ΓΡΑΦΙΚΑ INTEL® ARC™",
    "tr_processorGraphicsIrisXe12Gen": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ [cpu_fam] 12ΗΣ ΓΕΝΙΑΣ ΚΑΙ ΓΡΑΦΙΚΑ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax12Gen": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ [cpu_fam] 12ΗΣ ΓΕΝΙΑΣ ΚΑΙ ΓΡΑΦΙΚΑ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ [cpu_fam] 13ΗΣ ΓΕΝΙΑΣ ΚΑΙ ΓΡΑΦΙΚΑ INTEL® ARC™",
    "tr_processorGraphicsIrisXe13Gen": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ [cpu_fam] 13ΗΣ ΓΕΝΙΑΣ ΚΑΙ ΓΡΑΦΙΚΑ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup>",
    "tr_processorGraphicsIrisXeMax13Gen": "ΕΠΕΞΕΡΓΑΣΤΗΣ INTEL® CORE™ [cpu_fam] 13ΗΣ ΓΕΝΙΑΣ ΚΑΙ ΓΡΑΦΙΚΑ INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "ΓΡΑΦΙΚΑ INTEL® ARC™ A350M",
      "tr_A370M": "ΓΡΑΦΙΚΑ INTEL® ARC™ A370M",
      "tr_A730M": "ΓΡΑΦΙΚΑ INTEL® ARC™ A730M",
      "tr_A770M": "ΓΡΑΦΙΚΑ INTEL® ARC™ A770M",
      "tr_A310": "ΓΡΑΦΙΚΑ INTEL® ARC™ A310",
      "tr_A380": "ΓΡΑΦΙΚΑ INTEL® ARC™ A380",
      "tr_A580": "ΓΡΑΦΙΚΑ INTEL® ARC™ A580",
      "tr_A750": "ΓΡΑΦΙΚΑ INTEL® ARC™ A750",
      "tr_A770": "ΓΡΑΦΙΚΑ INTEL® ARC™ A770"
    },
    "tr_memoryValue": "RAM [XX] GB",
    "tr_memoryWithOptaneDesc": "Μνήμη [Value1] GB = μνήμη Intel® Optane™ [Value2] GB + RAM [Value3] GB",
    "tr_textGBGB": "SSD [convertedSSD] GB + HDD [convertedHDD] GB",
    "tr_textGBTB": "SSD [convertedSSD] GB + HDD [convertedHDD] TB",
    "tr_textTBGB": "SSD [convertedSSD] TB + HDD [convertedHDD] GB",
    "tr_textTBTB": "SSD [convertedSSD] TB + HDD [convertedHDD] TB",
    "tr_textGBSSD": "SSD [convertedSSD]&nbsp;GB",
    "tr_textTBSSD": "SSD [convertedSSD]&nbsp;TB",
    "tr_textGBHDD": "HDD [convertedHDD]&nbsp;GB",
    "tr_textTBHDD": "HDD [convertedHDD]&nbsp;TB",
    "tr_textStorageWithOptaneGB": "Μνήμη Intel®&nbsp;Optane™ [XX]&nbsp;GB + [convertedSSD]&nbsp;GB&nbsp;SSD",
    "tr_textStorageWithOptaneTB": "Μνήμη Intel®&nbsp;Optane™ [XX]&nbsp;GB + [convertedSSD]&nbsp;TB&nbsp;SSD",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home στη λειτουργία S",
      "tr_OS21": "Windows* 10 Pro στη λειτουργία S",
      "tr_OS22": "Windows* 10 Enterprise στη λειτουργία S",
      "tr_OS23": "Windows* 11 Home στη λειτουργία S",
      "tr_OS24": "Windows* 11 Pro στη λειτουργία S",
      "tr_OS25": "Windows* 11 Enterprise στη λειτουργία S"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Γραφικά Intel®&nbsp;Arc™&nbsp;A310",
      "tr_GR387": "Γραφικά Intel®&nbsp;Arc™&nbsp;A350M",
      "tr_GR388": "Γραφικά Intel®&nbsp;Arc™&nbsp;A370M",
      "tr_GR389": "Γραφικά Intel®&nbsp;Arc™&nbsp;A380",
      "tr_GR390": "Γραφικά Intel®&nbsp;Arc™&nbsp;A530M",
      "tr_GR391": "Γραφικά Intel®&nbsp;Arc™&nbsp;A550M",
      "tr_GR392": "Γραφικά Intel®&nbsp;Arc™&nbsp;A570M",
      "tr_GR393": "Γραφικά Intel®&nbsp;Arc™&nbsp;A580",
      "tr_GR394": "Γραφικά Intel®&nbsp;Arc™&nbsp;A730M",
      "tr_GR395": "Γραφικά Intel®&nbsp;Arc™&nbsp;A750",
      "tr_GR396": "Γραφικά Intel®&nbsp;Arc™&nbsp;A770",
      "tr_GR397": "Γραφικά Intel®&nbsp;Arc™&nbsp;A770M",
      "tr_GR398": "Γραφικά Intel® Arc™",
      "tr_GR399": "Γραφικά Intel®&nbsp;Arc™&nbsp;Pro A30M",
      "tr_GR400": "Γραφικά Intel®&nbsp;Arc™&nbsp;Pro A40/A50",
      "tr_GR401": "Γραφικά Intel®&nbsp;Arc™&nbsp;Pro A60",
      "tr_GR402": "Γραφικά Intel®&nbsp;Arc™&nbsp;Pro A60M",
      "tr_GR403": "Γραφικά Intel®",
      "tr_GR404": "Γραφικά Intel®&nbsp;HD&nbsp;2000",
      "tr_GR405": "Γραφικά Intel®&nbsp;HD&nbsp;2500",
      "tr_GR406": "Γραφικά Intel®&nbsp;HD&nbsp;3000",
      "tr_GR407": "Γραφικά Intel®&nbsp;HD&nbsp;400",
      "tr_GR408": "Γραφικά Intel®&nbsp;HD&nbsp;4000",
      "tr_GR409": "Γραφικά Intel®&nbsp;HD&nbsp;405",
      "tr_GR410": "Γραφικά Intel®&nbsp;HD&nbsp;4200",
      "tr_GR411": "Γραφικά Intel®&nbsp;HD&nbsp;4400",
      "tr_GR412": "Γραφικά Intel®&nbsp;HD&nbsp;4600",
      "tr_GR413": "Γραφικά Intel®&nbsp;HD&nbsp;500",
      "tr_GR414": "Γραφικά Intel®&nbsp;HD&nbsp;5000",
      "tr_GR415": "Γραφικά Intel®&nbsp;HD&nbsp;505",
      "tr_GR416": "Γραφικά Intel®&nbsp;HD&nbsp;510",
      "tr_GR417": "Γραφικά Intel®&nbsp;HD&nbsp;515",
      "tr_GR418": "Γραφικά Intel®&nbsp;HD&nbsp;520",
      "tr_GR419": "Γραφικά Intel®&nbsp;HD&nbsp;530",
      "tr_GR420": "Γραφικά Intel®&nbsp;HD&nbsp;5300",
      "tr_GR421": "Γραφικά Intel®&nbsp;HD&nbsp;5500",
      "tr_GR422": "Γραφικά Intel®&nbsp;HD&nbsp;5600",
      "tr_GR423": "Γραφικά Intel®&nbsp;HD&nbsp;6000",
      "tr_GR424": "Γραφικά Intel®&nbsp;HD&nbsp;610",
      "tr_GR425": "Γραφικά Intel®&nbsp;HD&nbsp;615",
      "tr_GR426": "Γραφικά Intel®&nbsp;HD&nbsp;620",
      "tr_GR427": "Γραφικά Intel®&nbsp;HD&nbsp;630",
      "tr_GR428": "Γραφικά HD Intel®",
      "tr_GR429": "Γραφικά Intel® Iris® 5100",
      "tr_GR430": "Γραφικά Intel® Iris® 540",
      "tr_GR431": "Γραφικά Intel® Iris® 550",
      "tr_GR432": "Γραφικά Intel® Iris® 6100",
      "tr_GR433": "Γραφικά Intel® Iris® Plus 640",
      "tr_GR434": "Γραφικά Intel® Iris® Plus 645",
      "tr_GR435": "Γραφικά Intel® Iris® Plus 650",
      "tr_GR436": "Γραφικά Intel® Iris® Plus 655",
      "tr_GR437": "Γραφικά Intel® Iris® Plus",
      "tr_GR438": "Γραφικά Intel® Iris® Pro 5200",
      "tr_GR439": "Γραφικά Intel® Iris® Pro 580",
      "tr_GR440": "Γραφικά Intel® Iris® Pro 6200",
      "tr_GR441": "Γραφικά Intel® Iris® X<sup>e</sup>",
      "tr_GR442": "Γραφικά Intel® Iris® X<sup>e</sup> MAX A220M",
      "tr_GR443": "Γραφικά Intel® Iris® X<sup>e</sup> MAX",
      "tr_GR444": "Ενσωματωμένα γραφικά Intel®",
      "tr_GR445": "Γραφικά UHD Intel® 600",
      "tr_GR446": "Γραφικά UHD Intel® 605",
      "tr_GR447": "Γραφικά UHD Intel® 610",
      "tr_GR448": "Γραφικά UHD Intel® 615",
      "tr_GR449": "Γραφικά UHD Intel® 617",
      "tr_GR450": "Γραφικά UHD Intel® 620",
      "tr_GR451": "Γραφικά UHD Intel® 630",
      "tr_GR452": "Γραφικά UHD Intel® 710",
      "tr_GR453": "Γραφικά UHD Intel® 730",
      "tr_GR454": "Γραφικά UHD Intel® 770",
      "tr_GR455": "Γραφικά UHD Intel®",
      "tr_GR456": "Γραφικά UHD Intel®",
      "tr_GR457": "Γραφικά UHD Intel®",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 με σχεδίαση Max-Q",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti με σχεδίαση Max-Q",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 με σχεδίαση Max-Q",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 με σχεδίαση Max-Q",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 με σχεδίαση Max-Q",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 με σχεδίαση Max-Q",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti με σχεδίαση Max-Q",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti με σχεδίαση Max-Q",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 με σχεδίαση Max-Q",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 με σχεδίαση Max-Q",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super με σχεδίαση Max-Q",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 με σχεδίαση Max-Q",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super με σχεδίαση Max-Q",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 με σχεδίαση Max-Q",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 με σχεδίαση Max-Q",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 με σχεδίαση Max-Q",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 με σχεδίαση Max-Q",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 με σχεδίαση Max-Q",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 με σχεδίαση Max-Q",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 με σχεδίαση Max-Q",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 με σχεδίαση Max-Q",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 με σχεδίαση Max-Q",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 με σχεδίαση Max-Q",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "Γραφικά Intel® Arc™",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "AMD Radeon* Pro W7900 Dual Slot"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Αγορά τώρα",
    "tr_textTryagain": "Δοκιμάστε ξανά!",
    "tr_textBuynowErr": "Εξαιτίας μιας διακοπής σύνδεσης, η επιλογή «Αγορά τώρα» δεν είναι διαθέσιμη",
    "tr_chatbotPlaceholder_text": "Ρωτήστε με οτιδήποτε σχετικά με τη συσκευή",
    "tr_deviceSpecs_text": "Χαρακτηριστικά συσκευής"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i3 με Intel® Hybrid Technology"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Επεξεργαστής Intel® Core™ i5 με Intel® Hybrid Technology"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Εξοπλιστείτε με Intel® για δυνατότερη εμπειρία στο gaming",
                "tr_subtitle": "Ζωντανέψτε τους Marvel’s Avengers* με τις βελτιστοποιήσεις του PC εντός παιχνιδιού και επεξεργαστή Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Ζήστε το Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Εκεί όπου αγωνίζονται οι καλύτεροι παίκτες του κόσμου",
                "tr_subtitle": "Το Intel® Extreme Masters, η μακροβιότερη σειρά εκδηλώσεων στην ιστορία των βιντεοπαιχνιδιών, θέτει τα πρότυπα για τα ηλεκτρονικά αθλήματα.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Παίξτε όπως οι επαγγελματίες",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Η Intel φτιάχνει πρωταθλητές.",
                "tr_subtitle": "Αφού κέρδισαν το πρωτάθλημα Rainbow Six Siege* της Βόρειας Αμερικής το 2021, οι Soniqs μιλούν για τη διαδρομή τους στο gaming, το μέλλον των e-sport και τη συνεργασία τους με την Intel.",
                "tr_pretitle": "Αφιέρωμα στους Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "Έτοιμο για VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Εκεί όπου το εικονικό γίνεται πραγματικό",
                "tr_subtitle": "Οι πιο εκπληκτικές, ρεαλιστικές και καθηλωτικές εμπειρίες εικονικής πραγματικότητας εκτελούνται σε έναν υπολογιστή. Με τους πιο καινούργιους επεξεργαστές Intel® Core™ μπορείτε να γίνετε οποιοσδήποτε, να πηγαίνετε οπουδήποτε και να απολαμβάνετε εμπειρίες gaming στην επόμενη διάσταση.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Παίξτε πάνω από 100 παιχνίδια κονσόλας υψηλής ποιότητας όπως το Sea of Thieves* και το Psychonauts* 2 σε PC Windows*, κινητό τηλέφωνο και tablet με το Xbox* Game Pass Ultimate και συμβατό χειριστήριο.",
                "titleArray": [
                  {
                    "tr_title": "Ομαδικό παιχνίδι σε διαφορετικές συσκευές",
                    "tr_subtitle": "Γίνετε μέλος στην κοινότητα του Xbox* και εκατομμύρια παίκτες σας περιμένουν να παίξετε μαζί. Συνδεθείτε και παίξτε με άλλους παίκτες, είτε βρίσκονται στην άλλη άκρη του κόσμου είτε δίπλα σας, επιλέγοντας παιχνίδια από μια κοινή βιβλιοθήκη παιχνιδιών."
                  },
                  {
                    "tr_title": "Gaming για PC Windows*",
                    "tr_subtitle": "Επιλέξτε παιχνίδια κονσόλας από έναν κατάλογο στο cloud και παίξτε χρησιμοποιώντας την εφαρμογή Xbox* για Windows* και συμβατό χειριστήριο."
                  },
                  {
                    "tr_title": "Παιχνίδι όπου κι αν είστε",
                    "tr_subtitle": "Απολαύστε εξαιρετικά παιχνίδια Xbox* σε ακόμη περισσότερα μέρη. Παίξτε πάνω από 100 παιχνίδια σε PC, κινητό τηλέφωνο και tablet, είτε βρίσκεστε στο σπίτι και δεν μπορείτε να χρησιμοποιήσετε την τηλεόραση, είτε σε καφέ ή σε οποιοδήποτε μέρος με σύνδεση ίντερνετ υψηλής ταχύτητας."
                  },
                  {
                    "tr_title": "Διασκέδαση χωρίς χειριστήριο",
                    "tr_subtitle": "Το gaming με έλεγχο αφής του Xbox* ξεκλειδώνει νέους τρόπους για να μπείτε στο παιχνίδι, ώστε να έχετε μια γνώριμη εμπειρία και να απολαμβάνετε το επίπεδο παιχνιδιού που έχετε συνηθίσει με ένα κανονικό χειριστήριο."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Ανάδειξη",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "100+ εμπειρίες AI",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Ελέγξτε περισσότερο, αγγίξτε λιγότερο",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Μείνετε μέσα στο κάδρο, σε οποιοδήποτε περιβάλλον",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Δημιουργικότητα",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Διορθώστε τον φωτισμό σαν επαγγελματίας",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Προσθέστε απίστευτα εφέ με ευκολία",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Κάνετε ρεμίξ τραγουδιών με δύναμη AI",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Παραγωγικότητα",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Αυξήστε την παραγωγικότητα, ενώ προστατεύετε το απόρρητό σας",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Περάστε περισσότερο χρόνο μακριά από την πρίζα",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Ο κόσμος σας. Στις&nbsp;συσκευές&nbsp;σας.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Ψυχαγωγία",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ομαλό στριμάρισμα, ακόμα και εκτός πρίζας",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Γρήγορο και καθηλωτικό παιχνίδι",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Ανάδειξη",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "100+ εμπειρίες AI",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Βάλτε τη δική σας πινελιά στα αγαπημένα σας τραγούδια",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Δείτε το όραμά σας σε πραγματικό χρόνο",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Δημιουργικότητα",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ο γρηγορότερος τρόπος για να ολοκληρώσετε τα βίντεό σας",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Κάνετε κάθε φωτογραφία να φαίνεται τέλεια",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Παραγωγικότητα",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Αυξήστε την παραγωγικότητα, ενώ προστατεύετε το απόρρητό σας",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Περισσότερη αποτελεσματικότητα. Περισσότερη ελευθερία.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Ο κόσμος σας. Στις&nbsp;συσκευές&nbsp;σας.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Ψυχαγωγία",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ομαλό στριμάρισμα HD, ακόμα και εκτός πρίζας",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Καθηλωτικό παιχνίδι σε περισσότερα μέρη",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Πιο Δημιουργικό",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Η επεξεργασία φωτογραφιών γίνεται εύκολη",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Δημιουργήστε φωτογραφίες ιδανικές για κορνίζα σε δευτερόλεπτα",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Το πληκτρολογείτε. Η τεχνητή νοημοσύνη το κάνει πραγματικότητα.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Καλύτερες επιδόσεις",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Το κόλπο για πιο ομαλό gaming",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Περάστε περισσότερο χρόνο μακριά από την πρίζα",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Εξαιρετικά καθηλωτικό",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Μεγαλύτερη παραγωγικότητα",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ο καθημερινός σας σύντροφος AI",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Τέλεια βιντεοκλήση",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Ο κόσμος σας. Στις&nbsp;συσκευές&nbsp;σας.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Πιο ασφαλές",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ενσωματωμένη προστασία για να διατηρήσετε τα δεδομένα σας πιο ασφαλή και ιδιωτικά",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Πιο Δημιουργικό",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Η επεξεργασία φωτογραφιών γίνεται εύκολη",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Tεχνητή νοημοσύνη. Ο σύντομος δρόμος για την επιτυχία.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Μεταγράψτε γρήγορα τους στίχους των αγαπημένων σας τραγουδιών",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Εξοικονομήστε ώρες επεξεργασίας με την τεχνητή νοημοσύνη",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Καλύτερες επιδόσεις",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Το κόλπο για πιο ομαλό gaming",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Περάστε περισσότερο χρόνο μακριά από την πρίζα",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Εξαιρετικά καθηλωτικό",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Μεγαλύτερη παραγωγικότητα",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ο καθημερινός σας σύντροφος AI",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Τέλεια βιντεοκλήση",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Ο κόσμος σας. Στις&nbsp;συσκευές&nbsp;σας.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Πιο ασφαλές",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ενσωματωμένη προστασία για να διατηρήσετε τα δεδομένα σας πιο ασφαλή και ιδιωτικά",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Όλη η ψυχαγωγία σας. Ένα&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Για ένα PC που θα χρησιμοποιείται και ως κέντρο ψυχαγωγίας, χρειάζεστε αρκετό χώρο αποθήκευσης για όλα τα αγαπημένα σας τραγούδια, βίντεο και παιχνίδια και τον κατάλληλο συνδυασμό επεξεργαστή και οθόνης που θα δημιουργεί καθηλωτικές εικόνες. Αυτό το PC έχει και τα τρία.",
                "titleArray": [
                  {
                    "tr_title": "Έτοιμος για γραφικά επόμενης γενιάς",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Δημιουργήστε την ψηφιακή βιβλιοθήκη σας",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ένα παράθυρο στην ψυχαγωγία",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Απρόσκοπτο gaming και στριμάρισμα",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Δημιουργήστε την ψηφιακή βιβλιοθήκη σας",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ένα παράθυρο στην ψυχαγωγία",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Γίνετε πιο παραγωγικοί. Οπουδήποτε.",
              "tr_tileTitle1": "Κάντε περισσότερα. Γρήγορα.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Αυτό το PC προσφέρει την τέλεια ισορροπία ισχύος και φορητότητας. Αυτό το PC έχει επεξεργαστή κατασκευασμένο για λεπτές κι ελαφριές συσκευές, Wi&#8209;Fi που θα σας κρατάει σε σύνδεση και μπαταρία για πολύωρη χρήση εκτός πρίζας.",
                "tr_subtitle1": "Αυτός ο υπολογιστής έχει την τέλεια ισορροπία ισχύος και επιδόσεων, ώστε να σας βοηθά να επιτυγχάνετε περισσότερα σε λιγότερο χρόνο. Ο συνδυασμός ενός δυνατού επεξεργαστή με μεγάλη μνήμη και αστραπιαίο Wi-Fi θα απλοποιήσει τις καθημερινές εργασίες σας.",
                "tr_subtitle2": "Αυτό το PC προσφέρει την τέλεια ισορροπία ισχύος και φορητότητας. Αυτό το PC έχει επεξεργαστή κατασκευασμένο για λεπτές κι ελαφριές συσκευές, Wi‑Fi που θα σας κρατάει σε σύνδεση και μνήμη για πολυδιεργασία.",
                "titleArray": [
                  {
                    "tr_title": "Σχεδιασμένος να κάνει περισσότερα",
                    "tr_title1": "Έξυπνες επιδόσεις που έχουν σημασία",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Κορυφαίες στην κατηγορία συνδέσεις",
                    "tr_title1": "Συνδεθείτε με όσα έχουν σημασία",
                    "tr_title2": "Μια σημαντική εξέλιξη για το Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Μείνετε χωρίς φόρτιση περισσότερο",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Εναλλάξτε προγράμματα σαν επαγγελματίας",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Η ελευθερία να δημιουργείτε",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Η δημιουργία περιεχομένου είναι μία από τις πιο απαιτητικές εργασίες για το PC σας. Ευτυχώς, αυτά τα εξαρτήματα διαθέτουν την ισχύ που χρειάζεται για να βελτιώνετε τη δημιουργική διαδικασία.",
                "titleArray": [
                  {
                    "tr_title": "Ένας πιο έξυπνος τρόπος δημιουργίας",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Δώστε ζωντάνια στις εικόνες σας",
                    "tr_title1": "Ας δημιουργήσουμε",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Μνήμη για πολυδιεργασία",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Δείτε μέσα σε αυτό το PC",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Αυτά είναι τα τρία βασικά στοιχεία που πρέπει να εξετάζετε όταν επιλέγετε ένα νέο PC. Αποφασίζοντας πώς θα χρησιμοποιείτε το νέο PC σας, έχετε ήδη αποφασίσει σε μεγάλο βαθμό σε τι αναλογία χρειάζεστε αυτά τα τρία στοιχεία. Είναι τα εξής:",
                "titleArray": [
                  {
                    "tr_title": "Επιδόσεις για gaming και στριμάρισμα",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Μονάδα αποθήκευσης",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Μνήμη",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Όλη η ψυχαγωγία σας. Ένα&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Για ένα PC που θα χρησιμοποιείται και ως κέντρο ψυχαγωγίας, χρειάζεστε αρκετό χώρο αποθήκευσης για όλα τα αγαπημένα σας τραγούδια, βίντεο και παιχνίδια και τον κατάλληλο συνδυασμό επεξεργαστή και οθόνης που θα δημιουργεί καθηλωτικές εικόνες. Αυτό το PC έχει και τα τρία.",
                "titleArray": [
                  {
                    "tr_title": "Έτοιμος για γραφικά επόμενης γενιάς",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Δημιουργήστε την ψηφιακή βιβλιοθήκη σας",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ένα παράθυρο στην ψυχαγωγία",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Απρόσκοπτο gaming και στριμάρισμα",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Δημιουργήστε την ψηφιακή βιβλιοθήκη σας",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ένα παράθυρο στην ψυχαγωγία",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Μνήμη",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Οθόνη",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Πολυδιεργασία υψηλής ταχύτητας",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΠΟΛΥΔΙΕΡΓΑΣΙΑ ΥΨΗΛΗΣ ΤΑΧΥΤΗΤΑΣ",
                "tr_subtitle": "Κάντε περισσότερα χωρίς καθυστέρηση",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για πολυδιεργασία.",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή που μπορεί να τα βγάζει πέρα με πολλά προγράμματα ταυτόχρονα."
                  },
                  {
                    "tr_title": "Wi‑Fi που είναι γρήγορο, ισχυρό και αξιόπιστο."
                  },
                  {
                    "tr_title": "Μνήμη η οποία κάνει εναλλαγή μεταξύ εφαρμογών χωρίς προβλήματα."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Συνομιλία μέσω βιντεοκλήσης",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΣΥΝΟΜΙΛΙΑ ΜΕΣΩ ΒΙΝΤΕΟΚΛΗΣΗΣ",
                "tr_subtitle": "Συνδέσεις με κρυστάλλινο ήχο",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για συνομιλία μέσω βιντεοκλήσης.",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή που μπορεί να καταγράφει και να κοινοποιεί εικόνες ταυτόχρονα."
                  },
                  {
                    "tr_title": "Wi‑Fi που είναι γρήγορο, ισχυρό και αξιόπιστο."
                  },
                  {
                    "tr_title": "Μια webcam που θα σας βοηθήσει να φαίνεστε πεντακάθαρα."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Έτοιμο για VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Εκεί όπου το εικονικό γίνεται πραγματικό",
                "tr_subtitle": "Τι χρειάζεται για τη δημιουργία ενός ολοκληρωτικά εικονικού κόσμου; Σε ένα PC χρειάζεστε τον συντονισμό μεταξύ του επεξεργαστή, της κάρτας γραφικών και της μνήμης.",
                "titleArray": [
                  {
                    "tr_title": "Ζωντανεύει εικονικούς κόσμους",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Η πραγματικότητα βρίσκεται στις λεπτομέρειες",
                    "tr_title1": "Ζήστε μια καθηλωτική εμπειρία",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Επεξεργασία βίντεο",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΕΠΕΞΕΡΓΑΣΙΑ ΒΙΝΤΕΟ",
                "tr_subtitle": "Δημιουργήστε τη μαγεία του κινηματογράφου",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για επεξεργασία βίντεο.",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή ο οποίος μπορεί να διαχειρίζεται μεγάλα αρχεία."
                  },
                  {
                    "tr_title": "Μνήμη η οποία αυξάνει την ταχύτητα και την απόκριση του λογισμικού επεξεργασίας."
                  },
                  {
                    "tr_title": "Μονάδα γραφικών που μειώνει τους χρόνους απόδοσης."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Πολυδιεργασία υψηλής ταχύτητας",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΠΟΛΥΔΙΕΡΓΑΣΙΑ ΥΨΗΛΗΣ ΤΑΧΥΤΗΤΑΣ",
                "tr_subtitle": "Κάντε περισσότερα χωρίς καθυστέρηση",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για πολυδιεργασία.",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή που μπορεί να τα βγάζει πέρα με πολλά προγράμματα ταυτόχρονα."
                  },
                  {
                    "tr_title": "Wi‑Fi που είναι γρήγορο, ισχυρό και αξιόπιστο."
                  },
                  {
                    "tr_title": "Μνήμη η οποία κάνει εναλλαγή μεταξύ εφαρμογών χωρίς προβλήματα."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Έτοιμο για VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Εκεί όπου το εικονικό γίνεται πραγματικό",
                "tr_subtitle": "Τι χρειάζεται για τη δημιουργία ενός ολοκληρωτικά εικονικού κόσμου; Σε ένα PC χρειάζεστε τον συντονισμό μεταξύ του επεξεργαστή, της κάρτας γραφικών και της μνήμης.",
                "titleArray": [
                  {
                    "tr_title": "Ζωντανεύει εικονικούς κόσμους",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Η πραγματικότητα βρίσκεται στις λεπτομέρειες",
                    "tr_title1": "Ζήστε μια καθηλωτική εμπειρία",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Δημιουργία τρισδιάστατων μοντέλων",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΔΗΜΙΟΥΡΓΙΑ ΤΡΙΣΔΙΑΣΤΑΤΩΝ ΜΟΝΤΕΛΩΝ",
                "tr_subtitle": "Δημιουργίες ανώτερου επιπέδου",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για δημιουργία τρισδιάστατων μοντέλων.",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή που μπορεί να διαχειρίζεται προηγμένο λογισμικό δημιουργίας."
                  },
                  {
                    "tr_title": "Μνήμη η οποία μπορεί να ανασύρει γρήγορα μεγάλα αρχεία."
                  },
                  {
                    "tr_title": "Μονάδα γραφικών για δυναμική δημιουργία και επεξεργασία εικόνας."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Συνεργασία σε πραγματικό χρόνο",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΣΥΝΕΡΓΑΣΙΑ ΣΕ ΠΡΑΓΜΑΤΙΚΟ ΧΡΟΝΟ",
                "tr_subtitle": "Εργαστείτε από οπουδήποτε",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για συνεργασίες μέσω Διαδικτύου.",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή που υποστηρίζει λογισμικό γραφείου."
                  },
                  {
                    "tr_title": "Wi‑Fi στο οποίο μπορείτε να βασίζεστε."
                  },
                  {
                    "tr_title": "Μια webcam που καταγράφει σε έξοχο HD."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Έτοιμο για VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Εκεί όπου το εικονικό γίνεται πραγματικό",
                "tr_subtitle": "Τι χρειάζεται για τη δημιουργία ενός ολοκληρωτικά εικονικού κόσμου; Σε ένα PC χρειάζεστε τον συντονισμό μεταξύ του επεξεργαστή, της κάρτας γραφικών και της μνήμης.",
                "titleArray": [
                  {
                    "tr_title": "Ζωντανεύει εικονικούς κόσμους",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Η πραγματικότητα βρίσκεται στις λεπτομέρειες",
                    "tr_title1": "Ζήστε μια καθηλωτική εμπειρία",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Laptop Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Συνήθως, τα τόσο λεπτά κι ελαφριά laptop δεν φημίζονται για την ισχύ τους",
                "tr_subtitle": "Σχεδιασμένα για επιδόσεις σε φορητό υπολογιστή, τα laptop Intel® Evo™ έχουν την ταχύτητα, τα γραφικά και την αυτονομία μπαταρίας που χρειάζεστε στα πιο εντυπωσιακά λεπτά κι ελαφριά σχέδια.",
                "titleArray": [
                  {
                    "tr_title": "Το laptop σημαίνει περισσότερα από ποτέ, γιʼ αυτό φροντίσαμε να μπορεί να κάνει περισσότερα από ποτέ.",
                    "tr_subtitle": "Χρησιμοποιήστε την οθόνη αφής για να εξερευνήσετε, να περιστρέψετε και να κάνετε μεγέθυνση."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Αστραπιαία ενεργοποίηση συστήματος",
                    "tr_subtitle": "Με τη στιγμιαία ενεργοποίηση, ο υπολογιστής σας μπορεί να περάσει από&nbsp;αναστολή λειτουργίας σε λειτουργία σε λιγότερο από ένα δευτερόλεπτο."
                  },
                  {
                    "tr_title": "Καθηλωτική θέαση",
                    "tr_subtitle": "Μια όμορφη οθόνη ζωντανεύει το οπτικό υλικό σας με ευκρινή ανάλυση και ζωντανά χρώματα."
                  },
                  {
                    "tr_title": "Γρήγορη ολοκλήρωση εργασιών",
                    "tr_subtitle": "Απολαύστε εξαιρετική απόκριση από&nbsp;τους επεξεργαστές Intel® Core™ 11ης γενιάς."
                  },
                  {
                    "tr_title": "Μεγαλύτερη διάρκεια μπαταρίας",
                    "tr_subtitle": "Εργαστείτε απερίσπαστοι με ένα PC που βελτιστοποιεί τη ζωή της μπαταρίας σας και επαναφορτίζεται αστραπιαία."
                  },
                  {
                    "tr_title": "Καθολική συνδεσιμότητα καλωδίων",
                    "tr_subtitle": "Κάνει τη σύνδεση άλλων συσκευών στον υπολογιστή σας απλή, γρήγορη&nbsp;και ασφαλή με την τεχνολογία Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Γρήγορες και αξιόπιστες συνδέσεις",
                    "tr_subtitle": "Συνομιλία μέσω video ή κοινή χρήση αρχείων με μια σύνδεση στην οποία μπορείτε να βασιστείτε με&nbsp;χρήση Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Laptop Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Όλα όσα χρειάζεστε και περισσότερα, σε ένα λεπτό και ελαφρύ laptop.",
                "tr_subtitle": "Τα laptop Intel® Evo™ είναι κατασκευασμένα για επιδόσεις σε φορητό υπολογιστή και σχεδιασμένα για να έχετε την καλύτερη εμπειρία<sup>2</sup>, ό, τι κι αν κάνετε.",
                "titleArray": [
                  {
                    "tr_title": "Το laptop σημαίνει περισσότερα από ποτέ, γιʼ αυτό φροντίσαμε να μπορεί να κάνει περισσότερα από ποτέ.",
                    "tr_subtitle": "Χρησιμοποιήστε την οθόνη αφής για να εξερευνήσετε, να περιστρέψετε και να κάνετε μεγέθυνση."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Αστραπιαία ενεργοποίηση συστήματος",
                    "tr_subtitle": "Με τη στιγμιαία ενεργοποίηση, ο υπολογιστής σας μπορεί να περάσει από&nbsp;αναστολή λειτουργίας σε λειτουργία σε λιγότερο από ένα δευτερόλεπτο."
                  },
                  {
                    "tr_title": "Καθηλωτική θέαση",
                    "tr_subtitle": "Μια όμορφη οθόνη ζωντανεύει το οπτικό υλικό σας με ευκρινή ανάλυση και ζωντανά χρώματα."
                  },
                  {
                    "tr_title": "Επαναστατικές επιδόσεις",
                    "tr_subtitle": "Αποκτήστε επιπλέον ισχύ εκεί που τη χρειάζεστε περισσότερο με τις έξυπνες επιδόσεις των επεξεργαστών Intel® Core™ 12ης γενιάς."
                  },
                  {
                    "tr_title": "Μεγαλύτερη διάρκεια μπαταρίας",
                    "tr_subtitle": "Εργαστείτε απερίσπαστοι με ένα PC που βελτιστοποιεί τη ζωή της μπαταρίας σας και επαναφορτίζεται αστραπιαία."
                  },
                  {
                    "tr_title": "Καθολική συνδεσιμότητα καλωδίων",
                    "tr_subtitle": "Κάνει τη σύνδεση άλλων συσκευών στον υπολογιστή σας απλή, γρήγορη&nbsp;και ασφαλή με την τεχνολογία Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Γρήγορες και αξιόπιστες συνδέσεις",
                    "tr_subtitle": "Κάντε βιντεοκλήσεις ή κοινή χρήση αρχείων με σύνδεση που μπορείτε να βασιστείτε, χρησιμοποιώντας Intel®&nbsp;Wi&#8209;Fi&nbsp;6E&nbsp;(Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Laptop Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Συνήθως, τα τόσο λεπτά κι ελαφριά laptop δεν φημίζονται για την ισχύ τους",
                "tr_subtitle": "Σχεδιασμένα για επιδόσεις σε φορητό υπολογιστή, τα laptop Intel® Evo™ έχουν την ταχύτητα, τα γραφικά και την αυτονομία μπαταρίας που χρειάζεστε στα πιο εντυπωσιακά λεπτά κι ελαφριά σχέδια.",
                "titleArray": [
                  {
                    "tr_title": "Το laptop σημαίνει περισσότερα από ποτέ, γιʼ αυτό φροντίσαμε να μπορεί να κάνει περισσότερα από ποτέ.",
                    "tr_subtitle": "Χρησιμοποιήστε την οθόνη αφής για να εξερευνήσετε, να περιστρέψετε και να κάνετε μεγέθυνση."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Αστραπιαία ενεργοποίηση συστήματος",
                    "tr_subtitle": "Με τη στιγμιαία ενεργοποίηση, ο υπολογιστής σας μπορεί να περάσει από&nbsp;αναστολή λειτουργίας σε λειτουργία σε λιγότερο από ένα δευτερόλεπτο."
                  },
                  {
                    "tr_title": "Καθηλωτική θέαση",
                    "tr_subtitle": "Μια όμορφη οθόνη ζωντανεύει το οπτικό υλικό σας με ευκρινή ανάλυση και ζωντανά χρώματα."
                  },
                  {
                    "tr_title": "Γρήγορη ολοκλήρωση εργασιών",
                    "tr_subtitle": "Απολαύστε εξαιρετική απόκριση από&nbsp;τους επεξεργαστές Intel® Core™ 11ης γενιάς."
                  },
                  {
                    "tr_title": "Μεγαλύτερη διάρκεια μπαταρίας",
                    "tr_subtitle": "Εργαστείτε απερίσπαστοι με ένα PC που βελτιστοποιεί τη ζωή της μπαταρίας σας και επαναφορτίζεται αστραπιαία."
                  },
                  {
                    "tr_title": "Καθολική συνδεσιμότητα καλωδίων",
                    "tr_subtitle": "Κάνει τη σύνδεση άλλων συσκευών στον υπολογιστή σας απλή, γρήγορη&nbsp;και ασφαλή με την τεχνολογία Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Γρήγορες και αξιόπιστες συνδέσεις",
                    "tr_subtitle": "Συνομιλία μέσω video ή κοινή χρήση αρχείων με μια σύνδεση στην οποία μπορείτε να βασιστείτε με&nbsp;χρήση Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Laptop Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Όλα όσα χρειάζεστε και περισσότερα, σε ένα λεπτό και ελαφρύ laptop.",
                "tr_subtitle": "Τα laptop Intel® Evo™ είναι κατασκευασμένα για επιδόσεις σε φορητό υπολογιστή και σχεδιασμένα για να έχετε την καλύτερη εμπειρία<sup>2</sup>, ό, τι κι αν κάνετε.",
                "titleArray": [
                  {
                    "tr_title": "Το laptop σημαίνει περισσότερα από ποτέ, γιʼ αυτό φροντίσαμε να μπορεί να κάνει περισσότερα από ποτέ.",
                    "tr_subtitle": "Χρησιμοποιήστε την οθόνη αφής για να εξερευνήσετε, να περιστρέψετε και να κάνετε μεγέθυνση."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Αστραπιαία ενεργοποίηση συστήματος",
                    "tr_subtitle": "Με τη στιγμιαία ενεργοποίηση, ο υπολογιστής σας μπορεί να περάσει από&nbsp;αναστολή λειτουργίας σε λειτουργία σε λιγότερο από ένα δευτερόλεπτο."
                  },
                  {
                    "tr_title": "Καθηλωτική θέαση",
                    "tr_subtitle": "Μια όμορφη οθόνη ζωντανεύει το οπτικό υλικό σας με ευκρινή ανάλυση και ζωντανά χρώματα."
                  },
                  {
                    "tr_title": "Επαναστατικές επιδόσεις",
                    "tr_subtitle": "Αποκτήστε επιπλέον ισχύ εκεί που τη χρειάζεστε περισσότερο με τις έξυπνες επιδόσεις των επεξεργαστών Intel® Core™ 12ης γενιάς."
                  },
                  {
                    "tr_title": "Μεγαλύτερη διάρκεια μπαταρίας",
                    "tr_subtitle": "Εργαστείτε απερίσπαστοι με ένα PC που βελτιστοποιεί τη ζωή της μπαταρίας σας και επαναφορτίζεται αστραπιαία."
                  },
                  {
                    "tr_title": "Καθολική συνδεσιμότητα καλωδίων",
                    "tr_subtitle": "Κάνει τη σύνδεση άλλων συσκευών στον υπολογιστή σας απλή, γρήγορη&nbsp;και ασφαλή με την τεχνολογία Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Γρήγορες και αξιόπιστες συνδέσεις",
                    "tr_subtitle": "Κάντε βιντεοκλήσεις ή κοινή χρήση αρχείων με σύνδεση που μπορείτε να βασιστείτε, χρησιμοποιώντας Intel®&nbsp;Wi&#8209;Fi&nbsp;6E&nbsp;(Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Laptop Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Όλα όσα χρειάζεστε και περισσότερα, σε ένα λεπτό και ελαφρύ laptop.",
                "tr_subtitle": "Τα laptop Intel® Evo™ είναι κατασκευασμένα για επιδόσεις σε φορητό υπολογιστή και σχεδιασμένα για να έχετε την καλύτερη εμπειρία<sup>2</sup>, ό, τι κι αν κάνετε.",
                "titleArray": [
                  {
                    "tr_title": "Το laptop σημαίνει περισσότερα από ποτέ, γιʼ αυτό φροντίσαμε να μπορεί να κάνει περισσότερα από ποτέ.",
                    "tr_subtitle": "Χρησιμοποιήστε την οθόνη αφής για να εξερευνήσετε, να περιστρέψετε και να κάνετε μεγέθυνση."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Αστραπιαία ενεργοποίηση συστήματος",
                    "tr_subtitle": "Με τη στιγμιαία ενεργοποίηση, ο υπολογιστής σας μπορεί να περάσει από&nbsp;αναστολή λειτουργίας σε λειτουργία σε λιγότερο από ένα δευτερόλεπτο."
                  },
                  {
                    "tr_title": "Καθηλωτική θέαση",
                    "tr_subtitle": "Μια όμορφη οθόνη ζωντανεύει το οπτικό υλικό σας με ευκρινή ανάλυση και ζωντανά χρώματα."
                  },
                  {
                    "tr_title": "Επαναστατικές επιδόσεις",
                    "tr_subtitle": "Αποκτήστε επιπλέον ισχύ εκεί που τη χρειάζεστε περισσότερο με τις έξυπνες επιδόσεις των επεξεργαστών Intel® Core™ 13ης γενιάς."
                  },
                  {
                    "tr_title": "Μεγαλύτερη διάρκεια μπαταρίας",
                    "tr_subtitle": "Εργαστείτε απερίσπαστοι με ένα PC που βελτιστοποιεί τη ζωή της μπαταρίας σας και επαναφορτίζεται αστραπιαία."
                  },
                  {
                    "tr_title": "Καθολική συνδεσιμότητα καλωδίων",
                    "tr_subtitle": "Κάνει τη σύνδεση άλλων συσκευών στον υπολογιστή σας απλή, γρήγορη&nbsp;και ασφαλή με την τεχνολογία Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Γρήγορες και αξιόπιστες συνδέσεις",
                    "tr_subtitle": "Κάντε βιντεοκλήσεις ή κοινή χρήση αρχείων με σύνδεση που μπορείτε να βασιστείτε, χρησιμοποιώντας Intel®&nbsp;Wi&#8209;Fi&nbsp;6E&nbsp;(Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Laptop Intel® Evo™",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Όλα όσα χρειάζεστε και περισσότερα, σε ένα λεπτό και ελαφρύ laptop.",
                "tr_subtitle": "Τα laptop Intel® Evo™ είναι κατασκευασμένα για επιδόσεις σε φορητό υπολογιστή και σχεδιασμένα για να έχετε την καλύτερη εμπειρία<sup>2</sup>, ό, τι κι αν κάνετε.",
                "titleArray": [
                  {
                    "tr_title": "Το laptop σημαίνει περισσότερα από ποτέ, γιʼ αυτό φροντίσαμε να μπορεί να κάνει περισσότερα από ποτέ.",
                    "tr_subtitle": "Χρησιμοποιήστε την οθόνη αφής για να εξερευνήσετε, να περιστρέψετε και να κάνετε μεγέθυνση."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Αστραπιαία ενεργοποίηση συστήματος",
                    "tr_subtitle": "Με τη στιγμιαία ενεργοποίηση, ο υπολογιστής σας μπορεί να περάσει από&nbsp;αναστολή λειτουργίας σε λειτουργία σε λιγότερο από ένα δευτερόλεπτο."
                  },
                  {
                    "tr_title": "Καθηλωτική θέαση",
                    "tr_subtitle": "Μια όμορφη οθόνη ζωντανεύει το οπτικό υλικό σας με ευκρινή ανάλυση και ζωντανά χρώματα."
                  },
                  {
                    "tr_title": "Επαναστατικές επιδόσεις",
                    "tr_subtitle": "Αποκτήστε επιπλέον ισχύ εκεί που τη χρειάζεστε περισσότερο με τις έξυπνες επιδόσεις των επεξεργαστών Intel® Core™ 13ης γενιάς."
                  },
                  {
                    "tr_title": "Μεγαλύτερη διάρκεια μπαταρίας",
                    "tr_subtitle": "Εργαστείτε απερίσπαστοι με ένα PC που βελτιστοποιεί τη ζωή της μπαταρίας σας και επαναφορτίζεται αστραπιαία."
                  },
                  {
                    "tr_title": "Καθολική συνδεσιμότητα καλωδίων",
                    "tr_subtitle": "Κάνει τη σύνδεση άλλων συσκευών στον υπολογιστή σας απλή, γρήγορη&nbsp;και ασφαλή με την τεχνολογία Thunderbolt™ 4.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Γρήγορες και αξιόπιστες συνδέσεις",
                    "tr_subtitle": "Κάντε βιντεοκλήσεις ή κοινή χρήση αρχείων με σύνδεση που μπορείτε να βασιστείτε, χρησιμοποιώντας Intel®&nbsp;Wi&#8209;Fi&nbsp;6E&nbsp;(Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Ανάδειξη",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Τι είναι η έκδοση Intel® Evo™;",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Γιατί να προτιμήσετε την έκδοση Intel® Evo™;",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Δημιουργικότητα",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Γίνετε πιο δημιουργικοί σε περισσότερα μέρη",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Εξαιρετικά γρήγορη επεξεργασία φωτογραφίας",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Δημιουργήστε πρωτότυπη τέχνη επί τόπου",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Παραγωγικότητα",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Αυξήστε την παραγωγικότητα με το Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Εντοπίστε deepfakes με AI, πριν τα μοιραστείτε",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Ο κόσμος σας. Στις&nbsp;συσκευές&nbsp;σας.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Φορητότητα",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Κάνετε γεια με το χέρι σας για τον έλεγχο με χειρονομίες",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Αυτονομία μπαταρίας που θα σας απελευθερώσει",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Σας κρατά συνδεδεμένους αυτόματα",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Ανάδειξη",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Τι είναι η έκδοση Intel® Evo™;",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Γιατί να προτιμήσετε την έκδοση Intel® Evo™;",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Δημιουργικότητα",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Γρήγορη και ομαλή αναπαραγωγή βίντεο",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Δημιουργήστε την επόμενη μεγάλη επιτυχία, με τη δύναμη του AI",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "Ο γρηγορότερος τρόπος για να ολοκληρώσετε τα βίντεό σας",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Παραγωγικότητα",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Αυξήστε την παραγωγικότητα με το Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Εντοπίστε deepfakes με AI, πριν τα μοιραστείτε",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Ο κόσμος σας. Στις&nbsp;συσκευές&nbsp;σας.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Φορητότητα",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Μπορείτε να συνεργάζεστε πρακτικά από οπουδήποτε",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Αυτονομία μπαταρίας που θα σας απελευθερώσει",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Σας κρατά συνδεδεμένους αυτόματα",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Όλη η ψυχαγωγία σας. Ένα&nbsp;PC.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Για ένα PC που θα χρησιμοποιείται και ως κέντρο ψυχαγωγίας, χρειάζεστε αρκετό χώρο αποθήκευσης για όλα τα αγαπημένα σας τραγούδια, βίντεο και παιχνίδια και τον κατάλληλο συνδυασμό επεξεργαστή και οθόνης που θα δημιουργεί καθηλωτικές εικόνες. Αυτό το PC έχει και τα τρία.",
                "titleArray": [
                  {
                    "tr_title": "Εντυπωσιακό gaming και στριμάρισμα",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Δημιουργήστε την ψηφιακή βιβλιοθήκη σας",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ένα παράθυρο στην ψυχαγωγία",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Απρόσκοπτο gaming και στριμάρισμα",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Δημιουργήστε την ψηφιακή βιβλιοθήκη σας",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Ένα παράθυρο στην ψυχαγωγία",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Γίνετε πιο παραγωγικοί. Οπουδήποτε.",
              "tr_tileTitle1": "Κάντε περισσότερα. Γρήγορα.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Αυτό το PC προσφέρει την τέλεια ισορροπία ισχύος και φορητότητας. Αυτό το PC έχει επεξεργαστή κατασκευασμένο για λεπτές κι ελαφριές συσκευές, Wi&#8209;Fi που θα σας κρατάει σε σύνδεση και μπαταρία για πολύωρη χρήση εκτός πρίζας.",
                "tr_subtitle1": "Αυτός ο υπολογιστής έχει την τέλεια ισορροπία ισχύος και επιδόσεων, ώστε να σας βοηθά να επιτυγχάνετε περισσότερα σε λιγότερο χρόνο. Ο συνδυασμός ενός δυνατού επεξεργαστή με μεγάλη μνήμη και αστραπιαίο Wi-Fi θα απλοποιήσει τις καθημερινές εργασίες σας.",
                "tr_subtitle2": "Αυτό το PC προσφέρει την τέλεια ισορροπία ισχύος και φορητότητας. Αυτό το PC έχει επεξεργαστή κατασκευασμένο για λεπτές κι ελαφριές συσκευές, Wi‑Fi που θα σας κρατάει σε σύνδεση και μνήμη για πολυδιεργασία.",
                "titleArray": [
                  {
                    "tr_title": "Έξυπνες επιδόσεις που έχουν σημασία",
                    "tr_title1": "Επιταχύνετε με έξυπνες επιδόσεις",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Κορυφαίες στην κατηγορία συνδέσεις",
                    "tr_title1": "Συνδεθείτε με όσα έχουν σημασία",
                    "tr_title2": "Μια σημαντική εξέλιξη για το Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Μείνετε χωρίς φόρτιση περισσότερο",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Εναλλάξτε προγράμματα σαν επαγγελματίας",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Η ελευθερία να δημιουργείτε",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Η δημιουργία περιεχομένου είναι μία από τις πιο απαιτητικές εργασίες για το PC σας. Ευτυχώς, αυτά τα εξαρτήματα διαθέτουν την ισχύ που χρειάζεται για να βελτιώνετε τη δημιουργική διαδικασία.",
                "titleArray": [
                  {
                    "tr_title": "Επιταχυνόμενη δημιουργικότητα",
                    "tr_title1": "Ολοκληρώστε δημιουργικά πρότζεκτ με ευκολία",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Αυξανόμενη δημιουργικότητα",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Μνήμη για πολυδιεργασία",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Δείτε μέσα σε αυτό το PC",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Αυτά είναι τα τρία βασικά στοιχεία που πρέπει να εξετάζετε όταν επιλέγετε ένα νέο PC. Αποφασίζοντας πώς θα χρησιμοποιείτε το νέο PC σας, έχετε ήδη αποφασίσει σε μεγάλο βαθμό σε τι αναλογία χρειάζεστε αυτά τα τρία στοιχεία. Είναι τα εξής:",
                "titleArray": [
                  {
                    "tr_title": "Επεξεργαστής",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Μονάδα αποθήκευσης",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Μνήμη",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Η ελευθερία να δημιουργείτε",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Η δημιουργία περιεχομένου είναι μία από τις πιο απαιτητικές εργασίες για το PC σας. Ευτυχώς, αυτά τα εξαρτήματα διαθέτουν την ισχύ που χρειάζεται για να βελτιώνετε τη δημιουργική διαδικασία.",
                "titleArray": [
                  {
                    "tr_title": "Ένας πιο έξυπνος τρόπος δημιουργίας",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Δώστε ζωντάνια στις εικόνες σας",
                    "tr_title1": "Ας δημιουργήσουμε",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Μνήμη για πολυδιεργασία",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Γίνετε πιο παραγωγικοί. Οπουδήποτε.",
              "tr_tileTitle1": "Κάντε περισσότερα. Γρήγορα.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Αυτό το PC προσφέρει την τέλεια ισορροπία ισχύος και φορητότητας. Αυτό το PC έχει επεξεργαστή κατασκευασμένο για λεπτές κι ελαφριές συσκευές, Wi&#8209;Fi που θα σας κρατάει σε σύνδεση και μπαταρία για πολύωρη χρήση εκτός πρίζας.",
                "tr_subtitle1": "Αυτός ο υπολογιστής έχει την τέλεια ισορροπία ισχύος και επιδόσεων, ώστε να σας βοηθά να επιτυγχάνετε περισσότερα σε λιγότερο χρόνο. Ο συνδυασμός ενός δυνατού επεξεργαστή με μεγάλη μνήμη και αστραπιαίο Wi-Fi θα απλοποιήσει τις καθημερινές εργασίες σας.",
                "tr_subtitle2": "Αυτό το PC προσφέρει την τέλεια ισορροπία ισχύος και φορητότητας. Αυτό το PC έχει επεξεργαστή κατασκευασμένο για λεπτές κι ελαφριές συσκευές, Wi‑Fi που θα σας κρατάει σε σύνδεση και μνήμη για πολυδιεργασία.",
                "titleArray": [
                  {
                    "tr_title": "Σχεδιασμένος να κάνει περισσότερα",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Κορυφαίες στην κατηγορία συνδέσεις",
                    "tr_title1": "Συνδεθείτε με όσα έχουν σημασία",
                    "tr_title2": "Μια σημαντική εξέλιξη για το Wi‑Fi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Μείνετε χωρίς φόρτιση περισσότερο",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Εναλλάξτε προγράμματα σαν επαγγελματίας",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Έτοιμο για VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "Εκεί όπου το εικονικό γίνεται πραγματικό",
                "tr_subtitle": "Τι χρειάζεται για τη δημιουργία ενός ολοκληρωτικά εικονικού κόσμου; Σε ένα PC χρειάζεστε τον συντονισμό μεταξύ του επεξεργαστή, της κάρτας γραφικών και της μνήμης.",
                "titleArray": [
                  {
                    "tr_title": "Ζωντανεύει εικονικούς κόσμους",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Η πραγματικότητα βρίσκεται στις λεπτομέρειες",
                    "tr_title1": "Ζήστε μια καθηλωτική εμπειρία",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>χάρμα οφθαλμών",
      "tr_sub_header": "Το μπλε φως μπορεί να προκαλέσει ενοχλήσεις στην όραση και να διαταράξει τον κύκλο του ύπνου. Η OLED της Samsung, ελεγμένη από την SGS ως οθόνη Eye Care, βοηθά όχι αλλάζοντας τα χρώματα, αλλά, αντίθετα, μειώνοντας το μήκος κύματος του εν δυνάμει επιβλαβούς μπλε φωτός, ανακουφίζοντας τα μάτια και μειώνοντας την οφθαλμική κόπωση.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Λιγότερο επιβλαβές μπλε φως"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Τα πάντα στον υπερθετικό βαθμό",
      "tr_sub_header": "Ο εξαιρετικά ελαφρύς και λεπτός σχεδιασμός της OLED μεγιστοποιεί την ποιότητα εικόνας, ελαχιστοποιώντας παράλληλα το βάρος."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Τέλειο μαύρο",
      "tr_sub_header": "0,0005&nbsp;nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74%<br/><span class='conventional_text'>Συμβατική</span>",
      "tr_sub_header": "120%<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Στριμάρισμα",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΣΤΡΙΜΑΡΙΣΜΑ",
                "tr_subtitle": "Απεριόριστη ψυχαγωγία",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για στριμάρισμα.",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή που μπορεί να στριμάρει σε HD."
                  },
                  {
                    "tr_title": "Μια οθόνη για ζωηρές, όμορφες εικόνες."
                  },
                  {
                    "tr_title": "Wi‑Fi που προσφέρει μικρότερους χρόνους αποθήκευσης σε buffer και φόρτωσης."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Περιήγηση στον Ιστό",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΠΕΡΙΗΓΗΣΗ ΣΤΟΝ ΙΣΤΟ",
                "tr_subtitle": "Το Διαδίκτυο έγινε εύκολο",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για περιήγηση στον Ιστό.",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή που μπορεί να φορτώνει γρήγορα σελίδες με πολλά πολυμέσα."
                  },
                  {
                    "tr_title": "Wi‑Fi που είναι γρήγορο και αξιόπιστο."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Συνομιλία μέσω βιντεοκλήσης",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΣΥΝΟΜΙΛΙΑ ΜΕΣΩ ΒΙΝΤΕΟΚΛΗΣΗΣ",
                "tr_subtitle": "Συνδέσεις με κρυστάλλινο ήχο",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για συνομιλία μέσω βιντεοκλήσης.",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή που μπορεί να καταγράφει και να κοινοποιεί εικόνες ταυτόχρονα."
                  },
                  {
                    "tr_title": "Wi‑Fi που είναι γρήγορο, ισχυρό και αξιόπιστο."
                  },
                  {
                    "tr_title": "Μια webcam που θα σας βοηθήσει να φαίνεστε πεντακάθαρα."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Πολυδιεργασία υψηλής ταχύτητας",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΠΟΛΥΔΙΕΡΓΑΣΙΑ ΥΨΗΛΗΣ ΤΑΧΥΤΗΤΑΣ",
                "tr_subtitle": "Κάντε περισσότερα χωρίς καθυστέρηση",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για πολυδιεργασία.",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή που μπορεί να τα βγάζει πέρα με πολλά προγράμματα ταυτόχρονα."
                  },
                  {
                    "tr_title": "Wi‑Fi που είναι γρήγορο, ισχυρό και αξιόπιστο."
                  },
                  {
                    "tr_title": "Μνήμη η οποία κάνει εναλλαγή μεταξύ εφαρμογών χωρίς προβλήματα."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Συνομιλία μέσω βιντεοκλήσης",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΣΥΝΟΜΙΛΙΑ ΜΕΣΩ ΒΙΝΤΕΟΚΛΗΣΗΣ",
                "tr_subtitle": "Συνδέσεις με κρυστάλλινο ήχο",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για συνομιλία μέσω βιντεοκλήσης.",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή που μπορεί να καταγράφει και να κοινοποιεί εικόνες ταυτόχρονα."
                  },
                  {
                    "tr_title": "Wi‑Fi που είναι γρήγορο, ισχυρό και αξιόπιστο."
                  },
                  {
                    "tr_title": "Μια webcam που θα σας βοηθήσει να φαίνεστε πεντακάθαρα."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Περιστασιακό παιχνίδι",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΠΕΡΙΣΤΑΣΙΑΚΟ ΠΑΙΧΝΙΔΙ",
                "tr_subtitle": "Ώρα για παιχνίδι",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για ελαφρύ gaming.",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή που υποστηρίζει ομαλό παίξιμο παιχνιδιών."
                  },
                  {
                    "tr_title": "Μνήμη ικανή για φωνητική συνομιλία και στριμάρισμα ταυτόχρονα."
                  },
                  {
                    "tr_title": "Μονάδα γραφικών που υποστηρίζει εκπληκτικές εικόνες."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Επεξεργασία φωτογραφιών",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΕΠΕΞΕΡΓΑΣΙΑ ΦΩΤΟΓΡΑΦΙΩΝ",
                "tr_subtitle": "Φωτογραφίες τέλειες σε κάθε λεπτομέρεια",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για επεξεργασία φωτογραφιών.",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή με την ισχύ να επεξεργάζεται μεγάλα σύνολα εικόνων."
                  },
                  {
                    "tr_title": "Μνήμη η οποία επιταχύνει το λογισμικό επεξεργασίας."
                  },
                  {
                    "tr_title": "Τεχνολογία Thunderbolt™ 4, η οποία σας επιτρέπει να μεταφέρετε αρχεία γρήγορα."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Πολυδιεργασία υψηλής ταχύτητας",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΠΟΛΥΔΙΕΡΓΑΣΙΑ ΥΨΗΛΗΣ ΤΑΧΥΤΗΤΑΣ",
                "tr_subtitle": "Κάντε περισσότερα χωρίς καθυστέρηση",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για πολυδιεργασία.",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή που μπορεί να τα βγάζει πέρα με πολλά προγράμματα ταυτόχρονα."
                  },
                  {
                    "tr_title": "Wi‑Fi που είναι γρήγορο, ισχυρό και αξιόπιστο."
                  },
                  {
                    "tr_title": "Μνήμη η οποία κάνει εναλλαγή μεταξύ εφαρμογών χωρίς προβλήματα."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Συνεργασία σε πραγματικό χρόνο",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΣΥΝΕΡΓΑΣΙΑ ΣΕ ΠΡΑΓΜΑΤΙΚΟ ΧΡΟΝΟ",
                "tr_subtitle": "Εργαστείτε από οπουδήποτε",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για συνεργασίες μέσω Διαδικτύου.",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή που υποστηρίζει λογισμικό γραφείου."
                  },
                  {
                    "tr_title": "Wi‑Fi στο οποίο μπορείτε να βασίζεστε."
                  },
                  {
                    "tr_title": "Μια webcam που καταγράφει σε έξοχο HD."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Επεξεργασία βίντεο",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΕΠΕΞΕΡΓΑΣΙΑ ΒΙΝΤΕΟ",
                "tr_subtitle": "Δημιουργήστε τη μαγεία του κινηματογράφου",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για επεξεργασία βίντεο.",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή ο οποίος μπορεί να διαχειρίζεται μεγάλα αρχεία."
                  },
                  {
                    "tr_title": "Μνήμη η οποία αυξάνει την ταχύτητα και την απόκριση του λογισμικού επεξεργασίας."
                  },
                  {
                    "tr_title": "Μονάδα γραφικών που μειώνει τους χρόνους απόδοσης."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Επεξεργασία φωτογραφιών",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΕΠΕΞΕΡΓΑΣΙΑ ΦΩΤΟΓΡΑΦΙΩΝ",
                "tr_subtitle": "Φωτογραφίες τέλειες σε κάθε λεπτομέρεια",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για επεξεργασία φωτογραφιών.",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή με την ισχύ να επεξεργάζεται μεγάλα σύνολα εικόνων."
                  },
                  {
                    "tr_title": "Μνήμη η οποία επιταχύνει το λογισμικό επεξεργασίας."
                  },
                  {
                    "tr_title": "Τεχνολογία Thunderbolt™ 4, η οποία σας επιτρέπει να μεταφέρετε αρχεία γρήγορα."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Επεξεργασία βίντεο",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΕΠΕΞΕΡΓΑΣΙΑ ΒΙΝΤΕΟ",
                "tr_subtitle": "Δημιουργήστε τη μαγεία του κινηματογράφου",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για επεξεργασία βίντεο.",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή ο οποίος μπορεί να διαχειρίζεται μεγάλα αρχεία."
                  },
                  {
                    "tr_title": "Μνήμη η οποία αυξάνει την ταχύτητα και την απόκριση του λογισμικού επεξεργασίας."
                  },
                  {
                    "tr_title": "Μονάδα γραφικών που μειώνει τους χρόνους απόδοσης."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Δημιουργία τρισδιάστατων μοντέλων",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΔΗΜΙΟΥΡΓΙΑ ΤΡΙΣΔΙΑΣΤΑΤΩΝ ΜΟΝΤΕΛΩΝ",
                "tr_subtitle": "Δημιουργίες ανώτερου επιπέδου",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για δημιουργία τρισδιάστατων μοντέλων.",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή που μπορεί να διαχειρίζεται προηγμένο λογισμικό δημιουργίας."
                  },
                  {
                    "tr_title": "Μνήμη η οποία μπορεί να ανασύρει γρήγορα μεγάλα αρχεία."
                  },
                  {
                    "tr_title": "Μονάδα γραφικών για δυναμική δημιουργία και επεξεργασία εικόνας."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Συνεργασία σε πραγματικό χρόνο",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "ΣΥΝΕΡΓΑΣΙΑ ΣΕ ΠΡΑΓΜΑΤΙΚΟ ΧΡΟΝΟ",
                "tr_subtitle": "Εργαστείτε από οπουδήποτε",
                "tr_subtitle1": "Ορίστε τι βρήκαμε σε αυτό το PC, το οποίο είναι εξαιρετικό για συνεργασίες μέσω Διαδικτύου.",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Έναν επεξεργαστή που υποστηρίζει λογισμικό γραφείου."
                  },
                  {
                    "tr_title": "Wi‑Fi στο οποίο μπορείτε να βασίζεστε."
                  },
                  {
                    "tr_title": "Μια webcam που καταγράφει σε έξοχο HD."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Εφαρμογή Intel® Unison™",
    "tr_title": "Αξιοποιήστε στο έπακρο τον συνδεδεμένο κόσμο",
    "tr_subtitle": "Μεταφέρετε απρόσκοπτα αρχεία και φωτογραφίες, ενώ διαχειρίζεστε τηλεφωνικές ειδοποιήσεις, κλήσεις και κείμενα από το PC σας.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "ΜΕΤΑΦΟΡΑ ΑΡΧΕΙΩΝ ΚΑΙ ΦΩΤΟΓΡΑΦΙΩΝ",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "ΠΡΑΓΜΑΤΟΠΟΙΗΣΗ ΚΑΙ ΛΗΨΗ ΚΛΗΣΕΩΝ",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "ΑΠΟΣΤΟΛΗ ΚΑΙ ΛΗΨΗ ΜΗΝΥΜΑΤΩΝ ΚΕΙΜΕΝΟΥ",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "ΔΙΑΧΕΙΡΙΣΗ ΤΗΛΕΦΩΝΙΚΩΝ ΕΙΔΟΠΟΙΗΣΕΩΝ",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "ΑΞΙΟΠΟΙΗΣΤΕ ΣΤΟ ΕΠΑΚΡΟ ΤΟΝ ΣΥΝΔΕΔΕΜΕΝΟ ΚΟΣΜΟ",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}