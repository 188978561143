const Library_ChromeBook_mobile_gls = {
  "uiText": {
    "tr_endlessProgramsAndApps": "uiText.tr_endlessProgramsAndApps",
    "tr_rethinkStorage": "uiText.tr_rethinkStorage",
    "tr_alwaysOnSecurity": "uiText.tr_alwaysOnSecurity",
    "tr_extendedBatteryLife": "uiText.tr_extendedBatteryLife",
    "tr_viewMore": "uiText.tr_viewMore",
    "tr_processorTitle": "uiText.tr_processorTitle",
    "tr_processorTitleCamel": "uiText.tr_processorTitleCamel",
    "tr_rethinkStorageTitle": "uiText.tr_rethinkStorageTitle",
    "tr_alwaysOnSecurityTitle": "uiText.tr_alwaysOnSecurityTitle",
    "tr_extendedBatteryLifeTitle": "uiText.tr_extendedBatteryLifeTitle",
    "tr_endlessProgramsAndAppsTitle": "uiText.tr_endlessProgramsAndAppsTitle",
    "tr_extendedBatteryLifeDescription": "uiText.tr_extendedBatteryLifeDescription",
    "tr_rethinkStorageDescriptionGB": "uiText.tr_rethinkStorageDescriptionGB",
    "tr_rethinkStorageDescriptionTB": "uiText.tr_rethinkStorageDescriptionTB",
    "tr_OS": "uiText.tr_OS",
    "tr_Chrome_OS": "uiText.tr_Chrome_OS",
    "tr_textGBGB": "uiText.tr_textGBGB",
    "tr_textGBTB": "uiText.tr_textGBTB",
    "tr_textTBGB": "uiText.tr_textTBGB",
    "tr_textTBTB": "uiText.tr_textTBTB",
    "tr_textGBSSD": "uiText.tr_textGBSSD",
    "tr_textTBSSD": "uiText.tr_textTBSSD",
    "tr_textGBHDD": "uiText.tr_textGBHDD",
    "tr_textTBHDD": "uiText.tr_textTBHDD",
    "tr_graphicTitle": "uiText.tr_graphicTitle",
    "tr_storageTitle": "uiText.tr_storageTitle",
    "tr_displayTitle": "uiText.tr_displayTitle",
    "tr_batteryTitle": "uiText.tr_batteryTitle",
    "tr_memoryTitle": "uiText.tr_memoryTitle",
    "tr_priceTitle": "uiText.tr_priceTitle"
  },
  "rethinkStorage": {
    "details": {
      "tr_tileTitle": "rethinkStorage.details.tr_tileTitle",
      "tr_title": "rethinkStorage.details.tr_title",
      "tr_subtitle": "rethinkStorage.details.tr_subtitle",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/cloud_upload.svg",
          "tr_badgeSubTitle": "rethinkStorage.details.badgeIcons[0].tr_badgeSubTitle",
          "badgeSubTitle": "cloud-upload"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "rethinkStorage.details.badgeIcons[1].tr_badgeSubTitle",
          "badgeSubTitle": "GBALONE"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "rethinkStorage.details.badgeIcons[2].tr_badgeSubTitle",
          "badgeSubTitle": "TBALONE"
        }
      ]
    }
  },
  "alwaysOnSecurity": {
    "details": {
      "tr_tileTitle": "alwaysOnSecurity.details.tr_tileTitle",
      "tr_title": "alwaysOnSecurity.details.tr_title",
      "tr_subtitle": "alwaysOnSecurity.details.tr_subtitle"
    }
  },
  "extendedBatteryLife": {
    "tr_tileTitle": "extendedBatteryLife.tr_tileTitle",
    "iposTypes": [
      {
        "iposType": "CHROMEBOOK",
        "details": {
          "tr_title": "extendedBatteryLife.iposTypes[0].details.tr_title",
          "tr_subtitle": "extendedBatteryLife.iposTypes[0].details.tr_subtitle"
        }
      },
      {
        "iposType": "CHROMEBOOKEVO",
        "details": {
          "tr_title": "extendedBatteryLife.iposTypes[1].details.tr_title",
          "tr_subtitle": "extendedBatteryLife.iposTypes[1].details.tr_subtitle"
        }
      },
      {
        "iposType": "CHROMEBOOKEVOGEN12",
        "details": {
          "tr_title": "extendedBatteryLife.iposTypes[2].details.tr_title",
          "tr_subtitle": "extendedBatteryLife.iposTypes[2].details.tr_subtitle"
        }
      }
    ]
  },
  "endlessProgramsAndApps": {
    "details": {
      "tr_tileTitle": "endlessProgramsAndApps.details.tr_tileTitle",
      "tr_title": "endlessProgramsAndApps.details.tr_title",
      "tr_subtitle": "endlessProgramsAndApps.details.tr_subtitle",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/download_badge.svg",
          "tr_badgeSubTitle": "endlessProgramsAndApps.details.badgeIcons[0].tr_badgeSubTitle"
        },
        {
          "badgeUrl": "assets/images/badges/reset_badge.svg",
          "tr_badgeSubTitle": "endlessProgramsAndApps.details.badgeIcons[1].tr_badgeSubTitle"
        },
        {
          "badgeUrl": "assets/images/badges/connect_badge.svg",
          "tr_badgeSubTitle": "endlessProgramsAndApps.details.badgeIcons[2].tr_badgeSubTitle"
        }
      ]
    }
  },
  "processor": [
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[0].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[0].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "processor[0].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_gen_core_title": "processor[0].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "processor[1].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[1].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[1].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[1].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[1].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[1].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[1].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[1].iposTypes[0].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[1].iposTypes[0].deviceTypes[1].details.tr_title",
                "tr_subtitle": "processor[1].iposTypes[0].deviceTypes[1].details.tr_subtitle",
                "tr_gen_core_title": "processor[1].iposTypes[0].deviceTypes[1].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "processor[2].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[2].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[2].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[2].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[2].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[2].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[2].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[2].iposTypes[0].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[2].iposTypes[0].deviceTypes[1].details.tr_title",
                "tr_subtitle": "processor[2].iposTypes[0].deviceTypes[1].details.tr_subtitle",
                "tr_gen_core_title": "processor[2].iposTypes[0].deviceTypes[1].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[3].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[3].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[3].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[4].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[4].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[4].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[5].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[5].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[5].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[6].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[6].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[6].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[7].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[7].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[7].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[8].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[8].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[8].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[9].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[9].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[9].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[10].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[10].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[10].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[11].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[11].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[11].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[12].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[12].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[12].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[13].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[13].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[13].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[14].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[14].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[14].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[15].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[15].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[15].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[15].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[15].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[15].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[16].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[16].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[16].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[16].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[16].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[16].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[16].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[16].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[16].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[16].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[16].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[16].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[16].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[17].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[17].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[17].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[17].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[17].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[17].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[17].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[17].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[17].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[17].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[17].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[17].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[17].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[18].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[18].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "processor[18].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_gen_core_title": "processor[18].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[19].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[19].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "processor[19].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_gen_core_title": "processor[19].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[20].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[20].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "processor[20].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_gen_core_title": "processor[20].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[21].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[21].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "processor[21].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_gen_core_title": "processor[21].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[22].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[22].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "processor[22].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_gen_core_title": "processor[22].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[23].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[23].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "processor[23].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_gen_core_title": "processor[23].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[24].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[24].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "processor[24].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_gen_core_title": "processor[24].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[25].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[25].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "processor[25].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_gen_core_title": "processor[25].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[26].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[26].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[26].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[26].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[26].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[26].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[27].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[27].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[27].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[27].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[27].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[27].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[27].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[27].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[27].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[27].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[27].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[27].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[27].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[27].iposTypes[1].deviceTypes[0].details.badgeIcons[4].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[28].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[28].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[28].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[28].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[28].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[28].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[28].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[28].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[28].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[28].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[28].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[28].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[28].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[28].iposTypes[1].deviceTypes[0].details.badgeIcons[4].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[29].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[29].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[29].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[29].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[29].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[29].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[29].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[29].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[29].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[29].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[30].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[30].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[30].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[30].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[30].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[30].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[30].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[30].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[30].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[30].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[31].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[31].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "processor[31].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[32].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[32].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[32].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[32].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[32].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[32].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[33].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[33].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[33].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[33].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[33].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[33].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[33].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[33].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[33].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[33].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[34].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[34].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[34].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[34].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[34].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[34].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[34].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[34].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[34].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[34].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[35].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[35].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[35].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[35].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[35].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[35].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[35].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[35].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[35].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[35].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[36].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[36].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[36].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[36].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[36].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[36].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[36].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[36].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[36].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[36].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_3",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[37].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[37].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[37].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[37].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[37].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[37].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[37].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[37].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[37].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[37].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_5",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[38].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[38].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[38].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[38].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[38].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[38].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[38].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[38].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[38].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[38].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_7",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[39].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[39].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[39].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[39].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[39].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[39].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[39].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[39].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[39].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[39].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[40].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[40].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[40].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[40].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[40].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[40].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[40].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[40].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[40].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[41].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[41].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[41].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[41].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[41].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[41].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[41].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[41].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[41].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[42].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "processor[42].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[42].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[42].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[42].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "processor[42].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "tr_gen_core_title": "processor[42].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[42].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[42].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "legalText": {
    "tr_specTitle": "legalText.tr_specTitle",
    "tr_paragraph_array": [
      "legalText.tr_paragraph_array[0]",
      "legalText.tr_paragraph_array[1]",
      "legalText.tr_paragraph_array[2]",
      "legalText.tr_paragraph_array[3]",
      "legalText.tr_paragraph_array[4]",
      "legalText.tr_paragraph_array[5]",
      "legalText.tr_paragraph_array[6]",
      "legalText.tr_paragraph_array[7]",
      "legalText.tr_paragraph_array[8]",
      "legalText.tr_paragraph_array[9]",
      "legalText.tr_paragraph_array[10]",
      "legalText.tr_paragraph_array[11]",
      "legalText.tr_paragraph_array[12]",
      "legalText.tr_paragraph_array[13]",
      "legalText.tr_paragraph_array[14]",
      "legalText.tr_paragraph_array[15]",
      "legalText.tr_paragraph_array[16]",
      "legalText.tr_paragraph_array[17]"
    ]
  }
}