const Library_ChromeBook_mobile_fi = {
  "uiText": {
    "tr_endlessProgramsAndApps": "Loputtomasti ohjelmia ja sovelluksia",
    "tr_rethinkStorage": "Uudenlainen tallennustila",
    "tr_alwaysOnSecurity": "Sisäänrakennettu turvallisuus",
    "tr_extendedBatteryLife": "Erinomainen akunkesto<sup>1</sup>",
    "tr_viewMore": "Näytä lisää",
    "tr_processorTitle": "SUORITIN",
    "tr_processorTitleCamel": "Suoritin",
    "tr_rethinkStorageTitle": "UUDENLAINEN TALLENNUSTILA",
    "tr_alwaysOnSecurityTitle": "SISÄÄNRAKENNETTU TURVALLISUUS",
    "tr_extendedBatteryLifeTitle": "ERINOMAINEN AKUNKESTO<sup>1</sup>",
    "tr_endlessProgramsAndAppsTitle": "LOPUTTOMASTI OHJELMIA JA SOVELLUKSIA",
    "tr_extendedBatteryLifeDescription": "[XX] tuntia",
    "tr_rethinkStorageDescriptionGB": "[XX] Gt",
    "tr_rethinkStorageDescriptionTB": "[XX] Tt",
    "tr_OS": "KÄYTTÖJÄR&shy;JESTELMÄ",
    "tr_Chrome_OS": "ChromeOS*",
    "tr_textGBGB": "[convertedSSD] Gt:n SSD-asema + [convertedHDD] Gt:n kiintolevy",
    "tr_textGBTB": "[convertedSSD] Gt:n SSD-asema + [convertedHDD] Tt:n kiintolevy",
    "tr_textTBGB": "[convertedSSD] Tt:n SSD-asema + [convertedHDD] Gt:n kiintolevy",
    "tr_textTBTB": "[convertedSSD] Tt:n SSD-asema + [convertedHDD] Tt:n kiintolevy",
    "tr_textGBSSD": "[convertedSSD] Gt:n SSD-asema",
    "tr_textTBSSD": "[convertedSSD] Tt:n SSD-asema",
    "tr_textGBHDD": "[convertedHDD] Gt:n kiintolevy",
    "tr_textTBHDD": "[convertedHDD] Tt:n kiintolevy",
    "tr_graphicTitle": "NÄYTÖNOHJAIN",
    "tr_storageTitle": "TALLENNUSTILA",
    "tr_displayTitle": "NÄYTTÖ",
    "tr_batteryTitle": "AKKU",
    "tr_memoryTitle": "MUISTI",
    "tr_priceTitle": "HINTA"
  },
  "rethinkStorage": {
    "details": {
      "tr_tileTitle": "Uudenlainen tallennustila",
      "tr_title": "Innovatiivinen lähestymistapa tallennukseen",
      "tr_subtitle": "Chromebook* yhdistää tietokoneesi tallennustilan Google* Driven lähes rajattomaan tallennustilaan pilvipalvelussa. Siitä on seuraavanlaisia etuja:",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/cloud_upload.svg",
          "tr_badgeSubTitle": "Kaikki tiedostosi varmuuskopioidaan automaattisesti pilvipalveluun, mistä niiden palauttaminen on yhtä helppoa kuin Google*-tilillesi kirjautuminen.",
          "badgeSubTitle": "cloud-upload"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Työskentele online- tai offline-tilassa – laitteessa on [XX] Gt tallennustilaa.",
          "badgeSubTitle": "GBALONE"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Työskentele online- tai offline-tilassa – laitteessa on [XX] Tt tallennustilaa.",
          "badgeSubTitle": "TBALONE"
        }
      ]
    }
  },
  "alwaysOnSecurity": {
    "details": {
      "tr_tileTitle": "Sisäänrakennettu turvallisuus",
      "tr_title": "Vaivaton suojaus<sup class='sup-text'>2</sup>",
      "tr_subtitle": "Chromebook* päivittää automaattisesti kaikki tietoturvaominaisuutensa, jotta voit surffailla verkossa ja napsautella linkkejä tietäen, että laitteesi on suojassa."
    }
  },
  "extendedBatteryLife": {
    "tr_tileTitle": "Erinomainen akunkesto<sup>1</sup>",
    "iposTypes": [
      {
        "iposType": "CHROMEBOOK",
        "details": {
          "tr_title": "Tee enemmän, lataa vähemmän<sup class='sup-text'>1</sup>",
          "tr_subtitle": "Chromebookin* virtaviivaisuuden ja energiatehokkuuden ansiosta voit käyttää sitä monta tuntia ilman virtajohtoa."
        }
      },
      {
        "iposType": "CHROMEBOOKEVO",
        "details": {
          "tr_title": "Lataa vähemmän. <br/>Ja lataa nopeammin.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Chromebookien* virtaviivainen ja energiatehokas suunnittelu vastaa Intel® Evo™ -alustan vaativia akkuedellytyksiä ja sallii nopeamman latauksen ja pidemmän käyttöajan ilman uudelleen latausta."
        }
      },
      {
        "iposType": "CHROMEBOOKEVOGEN12",
        "details": {
          "tr_title": "Akunkesto, joka pitää sinut liikkeessä<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Nopeasti latautuvat, pitkäkestoiset akut ohuissa ja kevyissä Chromebookeissa* tarkoittavat sitä, ettei sinun tarvitse enää miettiä lähimmän pistorasian sijaintia."
        }
      }
    ]
  },
  "endlessProgramsAndApps": {
    "details": {
      "tr_tileTitle": "Loputtomasti ohjelmia ja sovelluksia",
      "tr_title": "Lisää sovelluksia joka päivä",
      "tr_subtitle": "Chromebookin* käyttäjillä on valittavanaan valtava määrä sovelluksia ja ohjelmia, ja lisää tulee koko ajan.",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/download_badge.svg",
          "tr_badgeSubTitle": "Lataa Chrome-* ja Android*-sovelluksia."
        },
        {
          "badgeUrl": "assets/images/badges/reset_badge.svg",
          "tr_badgeSubTitle": "Kaikki sovelluksesi ja tietosi synkronoidaan Google*-tilisi kautta."
        },
        {
          "badgeUrl": "assets/images/badges/connect_badge.svg",
          "tr_badgeSubTitle": "Pidä yhteyttä, opi, luo ja pelaa rajoittamattomasti."
        }
      ]
    }
  },
  "processor": [
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Päivitä odotuksesi",
                "tr_subtitle": "Katso vaivatta tuttuja ohjelmia ja elokuvia Chromebookin* ja 10. sukupolven Intel® Core™ i3 -suorittimen avulla.",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i3 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Suunniteltu tarjoamaan ylivertaista suorituskykyä kaikkialla",
                "tr_subtitle": "",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i5 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Laitteessa on 10. sukupolven Intel® Core™ i5 -suoritin"
                  },
                  {
                    "tr_badgeTitle": "Tehokas, vaikka verkkovirtaa ei olisi"
                  },
                  {
                    "tr_badgeTitle": "Pikalataus: 4 tunnin akunkesto 30 minuutissa<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Luokkansa parhaan yhteyden takaa Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Työhön ja vapaa-aikaan",
                "tr_subtitle": "Videochattaaminen, sähköpostien lähettäminen ja esitysten luominen sujuu terävästi ja tehokkaasti Chromebookin* 10. sukupolven Intel® Core™ i5 -suorittimen avulla.",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i5 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Suunniteltu tarjoamaan ylivertaista suorituskykyä kaikkialla",
                "tr_subtitle": "",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i7 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Laitteessa on 10. sukupolven Intel® Core™ i7 -suoritin"
                  },
                  {
                    "tr_badgeTitle": "Tehokas, vaikka verkkovirtaa ei olisi"
                  },
                  {
                    "tr_badgeTitle": "Pikalataus: 4 tunnin akunkesto 30 minuutissa<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Luokkansa parhaan yhteyden takaa Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Koska suorituskyvyllä on väliä",
                "tr_subtitle": "Muokkaa videoita helposti ja lataa ja jaa muistoja hetkessä Chromebookin* ja 10. sukupolven Intel® Core™ i7 -suorittimen avulla.",
                "tr_gen_core_title": "10. sukupolven Intel® Core™ i7 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Responsiivisuus ja tehokkuus",
                "tr_subtitle": "",
                "tr_gen_core_title": "8. sukupolven Intel® Core™ i3 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Tehoa tunteisiin ja tuottavuuteen",
                "tr_subtitle": "",
                "tr_gen_core_title": "8. sukupolven Intel® Core™ i5 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Tehoa tunteisiin ja tuottavuuteen",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 5 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Tehoa tunteisiin ja tuottavuuteen",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Tehoa tunteisiin ja tuottavuuteen",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 9 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Responsiivisuus ja tehokkuus",
                "tr_subtitle": "",
                "tr_gen_core_title": "8. sukupolven Intel® Core™ m3 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Erinomaista suorituskykyä tämän päivän tehtäviin",
                "tr_subtitle": "",
                "tr_gen_core_title": "7. sukupolven Intel® Core™ i5 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ensiluokkaista suorituskykyä tämän päivän tehtäviin",
                "tr_subtitle": "",
                "tr_gen_core_title": "7. sukupolven Intel® Core™ i7 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Suorituskykyä tämän päivän tehtäviin",
                "tr_subtitle": "",
                "tr_gen_core_title": "7. sukupolven Intel® Core™ m3 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Entistä parempaa suorituskykyä tämän päivän tehtäviin",
                "tr_subtitle": "",
                "tr_gen_core_title": "6. sukupolven Intel® Core™ i5 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Edistynyttä suorituskykyä tämän päivän tehtäviin",
                "tr_subtitle": "",
                "tr_gen_core_title": "6. sukupolven Intel® Core™ i7 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Tehoa antamiisi tehtäviin",
                "tr_subtitle": "",
                "tr_gen_core_title": "6. sukupolven Intel® Core™ m3 -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Mullistavaa tehoa Chromebookeille*",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i3 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Jopa <br/><span class='big-blue-text'>2,4 kertaa  </span><span class='subt-text'>parempi suorituskyky<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Jopa <br/><span class='big-blue-text'>3,2 kertaa  </span><span class='subt-text'>nopeampi verkkoselaus<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Jopa <br/><span class='big-blue-text'>2,1 kertaa  </span><span class='subt-text'>nopeampi videoeditointi<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Kuvittele, mitä kaikkea voit saavuttaa Chromebookin* avulla",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i5 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Jopa <br/><span class='big-blue-text'>2,7 kertaa  </span><span class='subt-text'>parempi suorituskyky<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Jopa <br/><span class='big-blue-text'>3,3 kertaa  </span><span class='subt-text'>nopeampi verkkoselaus<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Jopa <br/><span class='big-blue-text'>2,8 kertaa  </span><span class='subt-text'>nopeampi videoeditointi<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Suunniteltu mobiilikäyttöön",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i5 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Mullistava teho 11. sukupolven Intel® Core™ -suorittimilla"
                  },
                  {
                    "tr_badgeTitle": "Herää <1 sekunnissa<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Lähes 3 kertaa nopeampi internet Intel® Wi-Fi 6 (Gig+)<sup>9</sup> -yhteydellä"
                  },
                  {
                    "tr_badgeTitle": "Nopea lataus, pitkäkestoinen akku"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Maailman paras suoritin Chromebookeille*<sup class='sup-text'>1</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i7 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Jopa <br/><span class='big-blue-text'>2,9 kertaa  </span><span class='subt-text'>parempi suorituskyky<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Jopa <br/><span class='big-blue-text'>3,5 kertaa  </span><span class='subt-text'>nopeampi verkkoselaus<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Jopa <br/><span class='big-blue-text'>2,9 kertaa  </span><span class='subt-text'>nopeampi videoeditointi<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Suunniteltu mobiilikäyttöön",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. sukupolven Intel® Core™ i7 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Mullistava teho 11. sukupolven Intel® Core™ -suorittimilla"
                  },
                  {
                    "tr_badgeTitle": "Herää <1 sekunnissa<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Lähes 3 kertaa nopeampi internet Intel® Wi-Fi 6 (Gig+)<sup>9</sup> -yhteydellä"
                  },
                  {
                    "tr_badgeTitle": "Nopea lataus, pitkäkestoinen akku"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Nopeutta ja tehoa joka&nbsp;päivään",
                "tr_subtitle": "Kaikki tutut ohjelmasi ja sovelluksesi toimivat tehokkaasti Intel® Pentium® -suorittimen ansiosta.",
                "tr_gen_core_title": "Intel® Pentium® Gold -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Nopeutta ja tehoa joka&nbsp;päivään",
                "tr_subtitle": "Kaikki tutut ohjelmasi ja sovelluksesi toimivat tehokkaasti Intel® Pentium® -suorittimen ansiosta.",
                "tr_gen_core_title": "Intel® Pentium® Silver -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Paranna päivittäistä tuottavuuttasi",
                "tr_subtitle": "Sovellusten käynnistämisestä projektien avaamiseen Intel® Celeron® -suoritin auttaa tekemään enemmän.",
                "tr_gen_core_title": "Intel® Celeron® -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Suorituskykyä edullisesti.",
                "tr_subtitle": "Suunniteltu viihdettä, videoiden suoratoistoa ja tuottavuutta varten. Katsele elokuvia ja verkkovideoita huipputarkkuudella. Tee enemmän, odota vähemmän.",
                "tr_gen_core_title": "Intel® Pentium® -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Nopeutta ja tehoa joka&nbsp;päivään",
                "tr_subtitle": "Kaikki tutut ohjelmasi ja sovelluksesi toimivat tehokkaasti Intel® Pentium® -suorittimen ansiosta.",
                "tr_gen_core_title": "Intel® Pentium® -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Suorituskykyä edullisesti.",
                "tr_subtitle": "Parannettu tuottavuus – Parannettu suorituskyky antaa tehoa vaativimpiin päiviin.  Virtaviivaistettua verkkoselailua – Lataa nopeasti paljon mediaa sisältävät verkkosivut, jotta voit selata enemmän ja odotella vähemmän.",
                "tr_gen_core_title": "Intel® Pentium® Gold -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Suorituskykyä edullisesti.",
                "tr_subtitle": "Voit hallita digitaalista maailmaasi missä oletkin, sillä pääset käsiksi tiedostoihin myös verkosta. Vaivattoman moniajon ansiosta voit vaihtaa helposti ohjelmien välillä ja tehdä enemmän nopeammin.",
                "tr_gen_core_title": "Intel® Pentium® Silver -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Suorituskykyä edullisesti.",
                "tr_subtitle": "Suorituskykyä työhön ja viihteeseen. Katsele elokuvia ja verkkovideoita huipputarkkuudella. Tee enemmän, odota vähemmän.",
                "tr_gen_core_title": "Intel® Celeron® -suoritin"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Luotettavaa suorituskykyä siellä, missä sitä tarvitaan eniten",
                "tr_subtitle": "",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i3 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Helpompaa<br/>moniajoa"
                  },
                  {
                    "tr_badgeTitle": "Sisäänrakennettu<br/>taustan sumennus"
                  },
                  {
                    "tr_badgeTitle": "Responsiivinen<br/>verkkoselaus"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Tehostaa suorituskykyä siellä, missä sitä tarvitaan eniten",
                "tr_subtitle": "",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i5 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tehokas<br/>moniajo"
                  },
                  {
                    "tr_badgeTitle": "Automaattinen<br/>melunvaimennus"
                  },
                  {
                    "tr_badgeTitle": "HD-suoratoisto<br/>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Mobiilisuorituskyky on kehittynyt",
                "tr_subtitle": "",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i5 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "12. sukupolven Intel® Core™ -suorittimet<sup>11</sup> tarjoavat ylivoimaista suorituskykyä siellä, missä sitä eniten tarvitset"
                  },
                  {
                    "tr_badgeTitle": "Herää <1 sekunnissa<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Lähes 3 kertaa nopeampi internet Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup> -yhteydellä"
                  },
                  {
                    "tr_badgeTitle": "Nopea lataus, pitkäkestoinen akku"
                  },
                  {
                    "tr_badgeTitle": "Tehostettu yhteistyö ja yhteydet"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Huipputason suorituskykyä siellä, missä tarvitset sitä eniten<sup>11</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i7 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Äärimmäistä<br/>moniajoa"
                  },
                  {
                    "tr_badgeTitle": "Sisäänrakennettu<br/>melunvaimennus ja<br/>taustan sumennus"
                  },
                  {
                    "tr_badgeTitle": "Sulavaa<br/>8K-suoratoistoa"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Mobiilisuorituskyky on kehittynyt",
                "tr_subtitle": "",
                "tr_gen_core_title": "12. sukupolven Intel®&nbsp;Core™&nbsp;i7 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "12. sukupolven Intel® Core™ -suorittimet<sup>11</sup> tarjoavat ylivoimaista suorituskykyä siellä, missä sitä eniten tarvitset"
                  },
                  {
                    "tr_badgeTitle": "Herää <1 sekunnissa<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Lähes 3 kertaa nopeampi internet Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup> -yhteydellä"
                  },
                  {
                    "tr_badgeTitle": "Nopea lataus, pitkäkestoinen akku"
                  },
                  {
                    "tr_badgeTitle": "Tehostettu yhteistyö ja yhteydet"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebookien*<br/>uusi kehitysaskel",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. sukupolven Intel® Core™ i5 ‑suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ohut ja kevyt muotoilu ylittää suorituskyvyn,<br/><span class='marklist'>ja</span> yhdistyy saumattomasti nykyiseen puhelimeesi<sup>13</sup><br/><span class='marklist'>ja</span> valitsee älykkäästi parhaan Wi-Fi- tai 5G-yhteyden<br/><span class='marklist'>ja</span> lataa nopeasti pitkäkestoisen akun."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Tee tärkeitä asioita huipputason suorituskyvyllä.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. sukupolven Intel® Core™ i5 ‑suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Parannettua<br/>moniajoa"
                  },
                  {
                    "tr_badgeTitle": "Sulavaa<br/>4K-suoratoistoa"
                  },
                  {
                    "tr_badgeTitle": "Tehokasta<br/>luomista"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebookien*<br/>uusi kehitysaskel",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. sukupolven Intel® Core™ i7 ‑suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ohut ja kevyt muotoilu ylittää suorituskyvyn,<br/><span class='marklist'>ja</span> yhdistyy saumattomasti nykyiseen puhelimeesi<sup>13</sup><br/><span class='marklist'>ja</span> valitsee älykkäästi parhaan Wi-Fi- tai 5G-yhteyden<br/><span class='marklist'>ja</span> lataa nopeasti pitkäkestoisen akun."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Tee tärkeitä asioita ylivoimaisella suorituskyvyllä",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. sukupolven Intel® Core™ i7 ‑suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Äärimmäistä<br/>moniajoa"
                  },
                  {
                    "tr_badgeTitle": "Sulavaa<br/>8K-suoratoistoa"
                  },
                  {
                    "tr_badgeTitle": "Kehittynyttä<br/>luomista"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Intel® -Suoritin",
                "tr_subtitle": "Tarvittava suorituskyky riittävän edulliseen hintaan",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Tee tärkeitä asioita erinomaisella suorituskyvyllä",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. sukupolven Intel® Core™ i3 ‑suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Helppoa<br/>moniajoa"
                  },
                  {
                    "tr_badgeTitle": "Sujuvaa<br/>suoratoistoa"
                  },
                  {
                    "tr_badgeTitle": "Nopeaa<br/>luomista"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebookien*<br/>uusi kehitysaskel",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ohut ja kevyt muotoilu ylittää suorituskyvyn,<br/><span class='marklist'>ja</span> yhdistyy saumattomasti nykyiseen puhelimeesi<sup>13</sup><br/><span class='marklist'>ja</span> valitsee älykkäästi parhaan Wi-Fi- tai 5G-yhteyden<br/><span class='marklist'>ja</span> lataa nopeasti pitkäkestoisen akun."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Tee tärkeitä asioita erinomaisella suorituskyvyllä",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Helppoa<br/>moniajoa"
                  },
                  {
                    "tr_badgeTitle": "Sujuvaa<br/>suoratoistoa"
                  },
                  {
                    "tr_badgeTitle": "Nopeaa<br/>luomista"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebookien*<br/>uusi kehitysaskel",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ohut ja kevyt muotoilu ylittää suorituskyvyn,<br/><span class='marklist'>ja</span> yhdistyy saumattomasti nykyiseen puhelimeesi<sup>13</sup><br/><span class='marklist'>ja</span> valitsee älykkäästi parhaan Wi-Fi- tai 5G-yhteyden<br/><span class='marklist'>ja</span> lataa nopeasti pitkäkestoisen akun."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Tee tärkeitä asioita huipputason suorituskyvyllä.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Parannettua<br/>moniajoa"
                  },
                  {
                    "tr_badgeTitle": "Sulavaa<br/>4K-suoratoistoa"
                  },
                  {
                    "tr_badgeTitle": "Tehokasta<br/>luomista"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebookien*<br/>uusi kehitysaskel",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ohut ja kevyt muotoilu ylittää suorituskyvyn,<br/><span class='marklist'>ja</span> yhdistyy saumattomasti nykyiseen puhelimeesi<sup>13</sup><br/><span class='marklist'>ja</span> valitsee älykkäästi parhaan Wi-Fi- tai 5G-yhteyden<br/><span class='marklist'>ja</span> lataa nopeasti pitkäkestoisen akun."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Tee tärkeitä asioita ylivoimaisella suorituskyvyllä",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Äärimmäistä<br/>moniajoa"
                  },
                  {
                    "tr_badgeTitle": "Sulavaa<br/>8K-suoratoistoa"
                  },
                  {
                    "tr_badgeTitle": "Kehittynyttä<br/>luomista"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebookien*<br/>uusi kehitysaskel",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i9 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ohut ja kevyt muotoilu ylittää suorituskyvyn,<br/><span class='marklist'>ja</span> yhdistyy saumattomasti nykyiseen puhelimeesi<sup>13</sup><br/><span class='marklist'>ja</span> valitsee älykkäästi parhaan Wi-Fi- tai 5G-yhteyden<br/><span class='marklist'>ja</span> lataa nopeasti pitkäkestoisen akun."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Tehosta ideasi ylivoimaisen suorituskyvyn avulla",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i9 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Nopea kuvien<br/>ja videoiden muokkaus"
                  },
                  {
                    "tr_badgeTitle": "Parannellut<br/>offline-ominaisuudet"
                  },
                  {
                    "tr_badgeTitle": "Pelaa huoletta<br/>pitkän akunkeston ansiosta"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_3",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebookien*<br/>uusi kehitysaskel",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ohut ja kevyt muotoilu ylittää suorituskyvyn,<br/><span class='marklist'>ja</span> yhdistyy saumattomasti nykyiseen puhelimeesi<sup>13</sup><br/><span class='marklist'>ja</span> valitsee älykkäästi parhaan Wi-Fi- tai 5G-yhteyden<br/><span class='marklist'>ja</span> lataa nopeasti pitkäkestoisen akun."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Tee tärkeitä asioita erinomaisella suorituskyvyllä",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Helppoa<br/>moniajoa"
                  },
                  {
                    "tr_badgeTitle": "Sujuvaa<br/>suoratoistoa"
                  },
                  {
                    "tr_badgeTitle": "Nopeaa<br/>luomista"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_5",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebookien*<br/>uusi kehitysaskel",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ohut ja kevyt muotoilu ylittää suorituskyvyn,<br/><span class='marklist'>ja</span> yhdistyy saumattomasti nykyiseen puhelimeesi<sup>13</sup><br/><span class='marklist'>ja</span> valitsee älykkäästi parhaan Wi-Fi- tai 5G-yhteyden<br/><span class='marklist'>ja</span> lataa nopeasti pitkäkestoisen akun."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Tee tärkeitä asioita huipputason suorituskyvyllä.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Parannettua<br/>moniajoa"
                  },
                  {
                    "tr_badgeTitle": "Sulavaa<br/>4K-suoratoistoa"
                  },
                  {
                    "tr_badgeTitle": "Tehokasta<br/>luomista"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_7",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebookien*<br/>uusi kehitysaskel",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ohut ja kevyt muotoilu ylittää suorituskyvyn,<br/><span class='marklist'>ja</span> yhdistyy saumattomasti nykyiseen puhelimeesi<sup>13</sup><br/><span class='marklist'>ja</span> valitsee älykkäästi parhaan Wi-Fi- tai 5G-yhteyden<br/><span class='marklist'>ja</span> lataa nopeasti pitkäkestoisen akun."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Tee tärkeitä asioita ylivoimaisella suorituskyvyllä",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 &#8209;suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Äärimmäistä<br/>moniajoa"
                  },
                  {
                    "tr_badgeTitle": "Sulavaa<br/>8K-suoratoistoa"
                  },
                  {
                    "tr_badgeTitle": "Kehittynyttä<br/>luomista"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebookien*<br/>uusi kehitysaskel",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 5 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ohut ja kevyt muotoilu ylittää suorituskyvyn,<br/><span class='marklist'>ja</span> yhdistyy saumattomasti nykyiseen puhelimeesi<sup>13</sup><br/><span class='marklist'>ja</span> valitsee älykkäästi parhaan Wi-Fi- tai 5G-yhteyden<br/><span class='marklist'>ja</span> lataa nopeasti pitkäkestoisen akun."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Vapauta uudet AI-elämykset",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 5 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Jopa 65 % nopeampi<br/>generatiivinen tekoäly"
                  },
                  {
                    "tr_badgeTitle": "Jopa 2,3 kertaa nopeampi<br/>tekoälypohjainen videoeditointi"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebookien*<br/>uusi kehitysaskel",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ohut ja kevyt muotoilu ylittää suorituskyvyn,<br/><span class='marklist'>ja</span> yhdistyy saumattomasti nykyiseen puhelimeesi<sup>13</sup><br/><span class='marklist'>ja</span> valitsee älykkäästi parhaan Wi-Fi- tai 5G-yhteyden<br/><span class='marklist'>ja</span> lataa nopeasti pitkäkestoisen akun."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Vapauta uudet AI-elämykset",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Jopa 65 % nopeampi<br/>generatiivinen tekoäly"
                  },
                  {
                    "tr_badgeTitle": "Jopa 2,3 kertaa nopeampi<br/>tekoälypohjainen videoeditointi"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebookien*<br/>uusi kehitysaskel",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 9 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ohut ja kevyt muotoilu ylittää suorituskyvyn,<br/><span class='marklist'>ja</span> yhdistyy saumattomasti nykyiseen puhelimeesi<sup>13</sup><br/><span class='marklist'>ja</span> valitsee älykkäästi parhaan Wi-Fi- tai 5G-yhteyden<br/><span class='marklist'>ja</span> lataa nopeasti pitkäkestoisen akun."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Suoritin",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Vapauta uudet AI-elämykset",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 9 -suoritin",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Jopa 65 % nopeampi<br/>generatiivinen tekoäly"
                  },
                  {
                    "tr_badgeTitle": "Jopa 2,3 kertaa nopeampi<br/>tekoälypohjainen videoeditointi"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "legalText": {
    "tr_specTitle": "Laillisuustiedot",
    "tr_paragraph_array": [
      "¹&nbsp;Jopa 10 tunnin akunkesto perustuu täyteen lataukseen Googlen* sisäisten testien perusteella.",
      "²&nbsp;Mitään tuotetta tai komponenttia ei voi suojata täydellisesti. Lisätietoja saat järjestelmän valmistajalta tai myyjältä tai osoitteesta intel.com.",
      "³ Mitattu käyttämällä alan vertailutestejä, Intelin RUG-testausta ja Intel® Core™ i7-1165G7 -suorittimen ainutlaatuisia ominaisuuksia. Tietoa kuormituksesta ja kokoonpanoista löydät osoitteessa www.intel.com/PerformanceIndex. Tulokset voivat vaihdella.",
      "⁴ Mitattu käyttämällä moniajoa Google Sheetsissä.",
      "⁵ Mitattu käyttämällä Speedometer 2.0 -vertailutestiä.",
      "⁶ Mitattu käyttämällä KineMaster Export Video -sovellusta.",
      "Alaviitteiden 4–6 osalta kaikki testaus suoritettu Intel® Core™ i3-1115G4 -suorittimella vs. Intel® Celeron® N4020 -suorittimella. Saat lisää kuormitus- ja kokoonpanotietoja siirtymällä osoitteeseen www.intel.com/PerformanceIndex. Suorituskyky vaihtelee käytön, kokoonpanon ja muiden tekijöiden mukaan. Lue lisää osoitteesta www.intel.com/PerformanceIndex. Suorituskykytiedot perustuvat kokoonpanoissa näkyvien päivämäärien mukaisiin testeihin eivätkä ne välttämättä heijasta kaikkia julkisesti saatavilla olevia päivityksiä.",
      "⁷ Aika, joka kuluu akun varaustason laskemiseen täydestä varauksesta kriittiselle tasolle tehtäessä tyypillisiä työnkulkuja realistisessa ympäristössä. Tulokset voivat vaihdella. Lisätietoja on osoitteessa intel.com/evo.",
      "⁸ Tulokset voivat vaihdella. Lisätietoja on osoitteessa intel.com/evo.",
      "⁹ Lähes 3 kertaa nopeampi: 802.11ax 2x2 160 MHz mahdollistaa enimmillään 2402 Mb/s:n teoreettisen datansiirtonopeuden. Noin 3 kertaa nopeampi kuin 802.11ac 2x2 80 MHz (867 Mb/s) Wi‑Fi -standardi, kuten käy ilmi IEEE 802.11* Wi‑Fi -standardin vaatimuksista, ja edellyttää samoin määritetyn 802.11ax Wi‑Fi -reitittimen käyttöä.",
      "¹⁰ Saavutettu varaustaso OEM:n oletussammutustasosta. Tulokset voivat vaihdella. Lisätietoja on osoitteessa intel.com/evo.",
      "¹¹ Verrattuna edelliseen suoritinsukupolveen.",
      "¹² Mitattuna Intel® Evo™ -mallien ainutlaatuisilla ominaisuuksilla, joita ovat esimerkiksi korkean suorituskyvyn Intel® Core™ -suorittimet, tasokkaat komponentit, verraton yhteensopivuus ja uusimmat yhteysratkaisut tyylikkäissä, innovatiivisissa malleissa. Kaikkien Intel® Evo™ -tuotemerkin mallien on täytettävä keskeisten mobiilikäyttökokemusten, kuten responsiivisuuden ja akunkeston, vaativat kynnysarvot. Yksittäisten laitteiden suorituskyky voi vaihdella. Lisätietoja on osoitteessa www.intel.com/PerformanceIndex (alustat).",
      "¹³Intel® Unison™ -ratkaisu on tällä hetkellä käytettävissä vain tietyissä Windows* &#8209;pohjaisten tietokoneiden Intel® Evo™ &#8209;malleissa, ja pariliitoksen voi muodostaa vain Android*- tai iOS* -pohjaisten puhelimien kanssa. Kaikissa laitteissa on oltava tuettu käyttöjärjestelmäversio. Yksittäisen Intel® Evo™ -laitteen suorituskyky voi vaihdella. Lisätietoja saat osoitteesta intel.com/Performance-Evo.",
      "Intel® -teknologioiden ominaisuudet ja edut vaihtelevat järjestelmäkokoonpanon mukaan, ja ne saattavat edellyttää laitteiden, ohjelmien ja palvelujen aktivointia. Suorituskyky vaihtelee järjestelmäkokoonpanon mukaan. Mikään tietokonejärjestelmä ei ole täysin turvallinen. Lisätietoja saat järjestelmän valmistajalta tai myyjältä tai osoitteesta intel.com.",
      "* Muut nimet ja merkit voivat olla muiden omaisuutta.",
      "Intel® ei ole vastuussa virheellisestä hinnoittelusta.",
      "© 2022 Intel Corporation. Celeron, Intel, Intel-logo, Intel Core ja Pentium ovat Intel Corporationin tai sen tytäryhtiöiden tavaramerkkejä."
    ]
  }
}