const Library_ChromeBook_mobile_da = {
  "uiText": {
    "tr_endlessProgramsAndApps": "Uendelige programmer og apps",
    "tr_rethinkStorage": "Nytænk lagring",
    "tr_alwaysOnSecurity": "Indbygget sikkerhed",
    "tr_extendedBatteryLife": "Forlænget batterilevetid<sup>1</sup>",
    "tr_viewMore": "Vis mere",
    "tr_processorTitle": "PROCESSOR",
    "tr_processorTitleCamel": "Processor",
    "tr_rethinkStorageTitle": "NYTÆNK LAGRING",
    "tr_alwaysOnSecurityTitle": "INDBYGGET SIKKERHED",
    "tr_extendedBatteryLifeTitle": "FORLÆNGET BATTERILEVETID<sup>1</sup>",
    "tr_endlessProgramsAndAppsTitle": "UENDELIGE PROGRAMMER OG APPS",
    "tr_extendedBatteryLifeDescription": "[XX] timer",
    "tr_rethinkStorageDescriptionGB": "[XX] GB",
    "tr_rethinkStorageDescriptionTB": "[XX] TB",
    "tr_OS": "OS",
    "tr_Chrome_OS": "ChromeOS*",
    "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD] GB HDD",
    "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD] TB HDD",
    "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD] GB HDD",
    "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD] TB HDD",
    "tr_textGBSSD": "[convertedSSD] GB SSD",
    "tr_textTBSSD": "[convertedSSD] TB SSD",
    "tr_textGBHDD": "[convertedHDD] GB HDD",
    "tr_textTBHDD": "[convertedHDD] TB HDD",
    "tr_graphicTitle": "GRAFIK",
    "tr_storageTitle": "LAGER",
    "tr_displayTitle": "SKÆRM",
    "tr_batteryTitle": "BATTERI",
    "tr_memoryTitle": "HUKOMMELSE",
    "tr_priceTitle": "PRIS"
  },
  "rethinkStorage": {
    "details": {
      "tr_tileTitle": "Nytænk lagring",
      "tr_title": "En innovativ tilgang til lagring",
      "tr_subtitle": "Chromebook* kombinerer lagring på din pc med den næsten ubegrænsede ressource fra Google* Drive i skyen. Her er fordelene:",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/cloud_upload.svg",
          "tr_badgeSubTitle": "Alle dine filer sikkerhedskopieres automatisk til skyen og gendannes ved blot at logge på din Google*-konto.",
          "badgeSubTitle": "cloud-upload"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Arbejd online eller offline med [XX] GB enhedslager.",
          "badgeSubTitle": "GBALONE"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Arbejd online eller offline med [XX] TB af enhedslager.",
          "badgeSubTitle": "TBALONE"
        }
      ]
    }
  },
  "alwaysOnSecurity": {
    "details": {
      "tr_tileTitle": "Indbygget sikkerhed",
      "tr_title": "Problemfri beskyttelse<sup class='sup-text'>2</sup>",
      "tr_subtitle": "Chromebook* opdaterer automatisk alle sikkerhedsfunktioner, så du kan surfe på internettet og klikke på links med tillid til, at din enhed er bedre beskyttet."
    }
  },
  "extendedBatteryLife": {
    "tr_tileTitle": "Forlænget batterilevetid<sup>1</sup>",
    "iposTypes": [
      {
        "iposType": "CHROMEBOOK",
        "details": {
          "tr_title": "Gør mere, oplad mindre<sup class='sup-text'>1</sup>",
          "tr_subtitle": "Chromebook* har et strømlinet og energibesparende design, så du kan fortsætte i timevis uden at skulle oplade."
        }
      },
      {
        "iposType": "CHROMEBOOKEVO",
        "details": {
          "tr_title": "Oplad mindre.<br/>Og genoplad hurtigere.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Det strømlinede og energieffektive design af en Chromebook* opfylder de krævende batterikrav fra Intel® Evo™ -platformen, så du kan oplade hurtigere og bruge længere tid uden opladning."
        }
      },
      {
        "iposType": "CHROMEBOOKEVOGEN12",
        "details": {
          "tr_title": "Batterilevetid, der holder dig mobil.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "De hurtigt opladelige batterier med lang levetid i tynde og lette Chromebooks* betyder, at du ikke behøver at bekymre dig om, hvor den nærmeste stikkontakt er igen."
        }
      }
    ]
  },
  "endlessProgramsAndApps": {
    "details": {
      "tr_tileTitle": "Uendelige programmer og apps",
      "tr_title": "Flere apps hver dag",
      "tr_subtitle": "Chromebook*-brugere har et enormt udvalg af apps og programmer at vælge imellem, og der kommer hele tiden flere.",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/download_badge.svg",
          "tr_badgeSubTitle": "Download Chrome*- og Android*-apps."
        },
        {
          "badgeUrl": "assets/images/badges/reset_badge.svg",
          "tr_badgeSubTitle": "Alle dine apps og data synkroniseres via din Google*-konto."
        },
        {
          "badgeUrl": "assets/images/badges/connect_badge.svg",
          "tr_badgeSubTitle": "Tilslut, lær, skab og spil på uendelig mange måder."
        }
      ]
    }
  },
  "processor": [
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Opgrader dine forventninger",
                "tr_subtitle": "Se nemt dine yndlingsshows og film på din Chromebook* med en 10. generations Intel® Core™ i3 &#8209;processor.",
                "tr_gen_core_title": "10. generation Intel® Core™ i3 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Designet specifikt til enestående ydeevne overalt.",
                "tr_subtitle": "",
                "tr_gen_core_title": "10. generation Intel® Core™ i5 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Drevet af en 10. generations Intel® Core™ i5 &#8209;processor"
                  },
                  {
                    "tr_badgeTitle": "Kraftfuld, selv når den ikke er tilsluttet støm"
                  },
                  {
                    "tr_badgeTitle": "Hurtig opladning: 4 timers batterilevetid på 30 minutter<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Den bedste forbindelse i klassen med Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Rigtigt arbejde. Rigtigt spil.",
                "tr_subtitle": "Videochat, e-mail og skab en præsentation alt sammen mens du øger din produktivitet på en Chromebook* med en 10. generations Intel® Core™ i5 &#8209;processor.",
                "tr_gen_core_title": "10. generation Intel® Core™ i5 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Designet specifikt til enestående ydeevne overalt.",
                "tr_subtitle": "",
                "tr_gen_core_title": "10. generation Intel® Core™ i7 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Drevet af en 10. generations Intel® Core™ i7 &#8209;processor"
                  },
                  {
                    "tr_badgeTitle": "Kraftfuld, selv når den ikke er tilsluttet støm"
                  },
                  {
                    "tr_badgeTitle": "Hurtig opladning: 4 timers batterilevetid på 30 minutter<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Den bedste forbindelse i klassen med Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Fordi ydeevne betyder noget",
                "tr_subtitle": "Rediger dine videoer med lethed, og upload og del minder øjeblikkeligt på en Chromebook* med en 10. generations Intel® Core™ i7 &#8209;processor.",
                "tr_gen_core_title": "10. generation Intel® Core™ i7 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hurtig reaktionsevne",
                "tr_subtitle": "",
                "tr_gen_core_title": "8. generation Intel® Core™ i3 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Styrk din effektivitet og produktivitet",
                "tr_subtitle": "",
                "tr_gen_core_title": "8. generation Intel® Core™ i5 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Styrk din effektivitet og produktivitet",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 5 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Styrk din effektivitet og produktivitet",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Styrk din effektivitet og produktivitet",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 9 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hurtig reaktionsevne",
                "tr_subtitle": "",
                "tr_gen_core_title": "8. generation Intel® Core™ m3 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Høj ydeevne til det, du laver i dag",
                "tr_subtitle": "",
                "tr_gen_core_title": "7. generation Intel® Core™ i5 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Førsteklasses ydeevne til det, du laver i dag",
                "tr_subtitle": "",
                "tr_gen_core_title": "7. generation Intel® Core™ i7 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ydeevne til det, du gør i dag",
                "tr_subtitle": "",
                "tr_gen_core_title": "7. generation Intel® Core™ m3 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Forbedret ydeevne til det, du har brug for",
                "tr_subtitle": "",
                "tr_gen_core_title": "6. generation Intel® Core™ i5 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Avanceret ydeevne til det, du har brug for",
                "tr_subtitle": "",
                "tr_gen_core_title": "6. generation Intel® Core™ i7 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ydeevne til det, du har brug for",
                "tr_subtitle": "",
                "tr_gen_core_title": "6. generation Intel® Core™ m3 -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Bryder grænserne for ydeevne i Chromebooks*",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. generation Intel® Core™ i3 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Op til <br/><span class='big-blue-text'>2,4X </span><span class='subt-text'> bedre ydeevne<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Op til <br/><span class='big-blue-text'>3,2X </span><span class='subt-text'> hurtigere webbrowsing<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Op til <br/><span class='big-blue-text'>2,1X </span><span class='subt-text'> hurtigere videoredigering<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Forestil dig, hvad du kan opnå med Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. generation Intel® Core™ i5 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Op til <br/><span class='big-blue-text'>2,7X </span><span class='subt-text'> bedre ydeevne<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Op til <br/><span class='big-blue-text'>3,3X </span><span class='subt-text'>hurtigere webbrowsing<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Op til <br/><span class='big-blue-text'>2,8X </span><span class='subt-text'> hurtigere videoredigering<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Udviklet til mobil ydeevne",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. generation Intel® Core™ i5 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Banebrydende ydeevne med 11. generation Intel® Core™ -processorer"
                  },
                  {
                    "tr_badgeTitle": "Vågner på < 1 sekund<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Næsten 3 gange hurtigere internet med Intel® Wi-Fi 6 (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Hurtig opladning, langvarig batterilevetid"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Verdens bedste processor til Chromebooks*<sup class='sup-text'>1</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. generation Intel® Core™ i7 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Op til <br/><span class='big-blue-text'>2,9X </span><span class='subt-text'>bedre ydeevne<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Op til <br/><span class='big-blue-text'>3,5X </span><span class='subt-text'>hurtigere webbrowsing<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Op til <br/><span class='big-blue-text'>2,9X </span><span class='subt-text'> gange hurtigere videoredigering<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Udviklet til mobil ydeevne",
                "tr_subtitle": "",
                "tr_gen_core_title": "11. generation Intel® Core™ i7 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Banebrydende ydeevne med 11. generation Intel® Core™ -processorer"
                  },
                  {
                    "tr_badgeTitle": "Vågner på < 1 sekund<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Næsten 3 gange hurtigere internet med Intel® Wi-Fi 6 (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Hurtig opladning, langvarig batterilevetid"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hurtig ydeevne i hverdagen",
                "tr_subtitle": "Få fremragende ydeevne fra alle dine foretrukne programmer og apps med kraften fra en Intel® Pentium® &#8209;processor",
                "tr_gen_core_title": "Intel® Pentium® Gold -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hurtig ydeevne i hverdagen",
                "tr_subtitle": "Få fremragende ydeevne fra alle dine foretrukne programmer og apps med kraften fra en Intel® Pentium® &#8209;processor",
                "tr_gen_core_title": "Intel® Pentium® Silver -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Sæt skub i din daglige produktivitet",
                "tr_subtitle": "Fra start af apps til åbning af projekter – en Intel® Celeron® &#8209;processor hjælper dig med at udrette mere.",
                "tr_gen_core_title": "Intel® Celeron® -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ydeevne og værdi.",
                "tr_subtitle": "Bygget til fantastisk underholdning, videostreaming og produktivitet. Nyd film og videoer på nettet i utrolige detaljer. Brug mere tid på at gøre og mindre på at vente.",
                "tr_gen_core_title": "Intel® Pentium® -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hurtig ydeevne i hverdagen",
                "tr_subtitle": "Få fremragende ydeevne fra alle dine foretrukne programmer og apps med kraften fra en Intel® Pentium® &#8209;processor",
                "tr_gen_core_title": "Intel® Pentium® -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ydeevne og værdi.",
                "tr_subtitle": "Forbedret produktivitet - Øget ydeevne til at støtte dig igennem dine mest krævende dage. Strømlinet webbrowsing - Indlæs hurtigt media-rich websider for mere udforskning og mindre ventetid.",
                "tr_gen_core_title": "Intel® Pentium® Gold -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ydeevne og værdi.",
                "tr_subtitle": "Hold styr på din digitale verden med en ydeevne, som kan få adgang til filer fra internettet. Multi-task ved simpelt og nemt at skifte mellem programmer og få mere gjort. Hurtigt.",
                "tr_gen_core_title": "Intel® Pentium® Silver -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ydeevne og værdi.",
                "tr_subtitle": "Ydeevne til at øge arbejde og underholdning. Nyd film og videoer på nettet i utrolige detaljer. Brug mere tid på at gøre og mindre på at vente.",
                "tr_gen_core_title": "Intel® Celeron® -processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Pålidelig ydeevne, hvor der er mest brug for den.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12. generation Intel® Core™ i3 &#8209;processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitasking<br/>med lethed"
                  },
                  {
                    "tr_badgeTitle": "Indbygget<br/>baggrundssløring"
                  },
                  {
                    "tr_badgeTitle": "Responsiv<br/>webbrowsing"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Et løft af ydeevne, hvor det er nødvendigt mest.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12. generation Intel® Core™ i5 &#8209;processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Kraftfuld<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Automatisk<br/>støjundertrykkelse"
                  },
                  {
                    "tr_badgeTitle": "HD<br/>streaming"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Mobil ydeevne har udviklet sig.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12. generation Intel® Core™ i5 &#8209;processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Overlegen ydeevne, hvor du har mest brug for den, drevet af 12. generation Intel® Core™ -processorer<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Vågner på < 1 sekund<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Næsten 3 gange hurtigere internet med Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Hurtig opladning, langvarig batterilevetid"
                  },
                  {
                    "tr_badgeTitle": "Forbedret samarbejde og tilslutningsmuligheder"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Overlegen ydeevne, hvor du har mest brug for det.<sup>11</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "12. generation Intel® Core™ i7 &#8209;processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ekstrem<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Indbygget<br/>støjundertrykkelse og<br/>baggrundssløring"
                  },
                  {
                    "tr_badgeTitle": "Smidig<br/>8K-streaming"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Mobil ydeevne har udviklet sig.",
                "tr_subtitle": "",
                "tr_gen_core_title": "12. generation Intel® Core™ i7 &#8209;processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Overlegen ydeevne, hvor du har mest brug for den, drevet af 12. generation Intel® Core™ -processorer<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Vågner på < 1 sekund<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Næsten 3 gange hurtigere internet med Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Hurtig opladning, langvarig batterilevetid"
                  },
                  {
                    "tr_badgeTitle": "Forbedret samarbejde og tilslutningsmuligheder"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. generation Intel® Core™ i5 ‑processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tyndt og let design, der går ud over ydeevne <br/><span class='marklist'>og</span> problemfrit forbinder med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> intelligent vælger den bedste Wi-Fi- eller 5G-forbindelse<br/><span class='marklist'>og</span> oplader hurtigt med et batteri, der holder"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gør det, du gør, med enestående ydeevne.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. generation Intel® Core™ i5 ‑processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Forbedret<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Smidig<br/>4K-streaming"
                  },
                  {
                    "tr_badgeTitle": "Kraftfuld produktion af <br/>indhold"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. generation Intel® Core™ i7 ‑processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tyndt og let design, der går ud over ydeevne <br/><span class='marklist'>og</span> problemfrit forbinder med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> intelligent vælger den bedste Wi-Fi- eller 5G-forbindelse<br/><span class='marklist'>og</span> oplader hurtigt med et batteri, der holder"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gør det, du gør, med mere end overlegen ydeevne.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. generation Intel® Core™ i7 ‑processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ekstrem<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Smidig<br/>8K-streaming"
                  },
                  {
                    "tr_badgeTitle": "Avanceret<br/>oprettelse"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Intel® -processor",
                "tr_subtitle": "Ydelse, du har brug for. Prisen, du ønsker.",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gør det, du gør, med fremragende ydeevne.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13. generation Intel® Core™ i3 ‑processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Nem<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Problemfri<br/>streaming"
                  },
                  {
                    "tr_badgeTitle": "Hurtig<br/>oprettelse"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tyndt og let design, der går ud over ydeevne <br/><span class='marklist'>og</span> problemfrit forbinder med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> intelligent vælger den bedste Wi-Fi- eller 5G-forbindelse<br/><span class='marklist'>og</span> oplader hurtigt med et batteri, der holder"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gør det, du gør, med fremragende ydeevne.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Nem<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Problemfri<br/>streaming"
                  },
                  {
                    "tr_badgeTitle": "Hurtig<br/>oprettelse"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tyndt og let design, der går ud over ydeevne <br/><span class='marklist'>og</span> problemfrit forbinder med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> intelligent vælger den bedste Wi-Fi- eller 5G-forbindelse<br/><span class='marklist'>og</span> oplader hurtigt med et batteri, der holder"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gør det, du gør, med enestående ydeevne.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Forbedret<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Smidig<br/>4K-streaming"
                  },
                  {
                    "tr_badgeTitle": "Kraftfuld produktion af <br/>indhold"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tyndt og let design, der går ud over ydeevne <br/><span class='marklist'>og</span> problemfrit forbinder med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> intelligent vælger den bedste Wi-Fi- eller 5G-forbindelse<br/><span class='marklist'>og</span> oplader hurtigt med et batteri, der holder"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gør det, du gør, med mere end overlegen ydeevne.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ekstrem<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Smidig<br/>8K-streaming"
                  },
                  {
                    "tr_badgeTitle": "Avanceret<br/>oprettelse"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i9 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tyndt og let design, der går ud over ydeevne <br/><span class='marklist'>og</span> problemfrit forbinder med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> intelligent vælger den bedste Wi-Fi- eller 5G-forbindelse<br/><span class='marklist'>og</span> oplader hurtigt med et batteri, der holder"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Sæt gang i dine passioner med overlegen ydeevne",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i9 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Hurtig redigering af billeder<br/>og video"
                  },
                  {
                    "tr_badgeTitle": "Forbedrede offline<br/>-egenskaber"
                  },
                  {
                    "tr_badgeTitle": "Spil<br/>bekymringsfrit med <br/>lang batterilevetid"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_3",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tyndt og let design, der går ud over ydeevne <br/><span class='marklist'>og</span> problemfrit forbinder med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> intelligent vælger den bedste Wi-Fi- eller 5G-forbindelse<br/><span class='marklist'>og</span> oplader hurtigt med et batteri, der holder"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gør det, du gør, med fremragende ydeevne.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Nem<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Problemfri<br/>streaming"
                  },
                  {
                    "tr_badgeTitle": "Hurtig<br/>oprettelse"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_5",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tyndt og let design, der går ud over ydeevne <br/><span class='marklist'>og</span> problemfrit forbinder med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> intelligent vælger den bedste Wi-Fi- eller 5G-forbindelse<br/><span class='marklist'>og</span> oplader hurtigt med et batteri, der holder"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gør det, du gør, med enestående ydeevne.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Forbedret<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Smidig<br/>4K-streaming"
                  },
                  {
                    "tr_badgeTitle": "Kraftfuld produktion af <br/>indhold"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_7",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tyndt og let design, der går ud over ydeevne <br/><span class='marklist'>og</span> problemfrit forbinder med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> intelligent vælger den bedste Wi-Fi- eller 5G-forbindelse<br/><span class='marklist'>og</span> oplader hurtigt med et batteri, der holder"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Gør det, du gør, med mere end overlegen ydeevne.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Ekstrem<br/>multitasking"
                  },
                  {
                    "tr_badgeTitle": "Smidig<br/>8K-streaming"
                  },
                  {
                    "tr_badgeTitle": "Avanceret<br/>oprettelse"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 5 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tyndt og let design, der går ud over ydeevne <br/><span class='marklist'>og</span> problemfrit forbinder med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> intelligent vælger den bedste Wi-Fi- eller 5G-forbindelse<br/><span class='marklist'>og</span> oplader hurtigt med et batteri, der holder"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Lås op for de nye AI-oplevelser",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 5 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Op til 65 % hurtigere <br/>generativ AI"
                  },
                  {
                    "tr_badgeTitle": "Op til 2,3 gange hurtigere <br/>AI-videoredigering"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tyndt og let design, der går ud over ydeevne <br/><span class='marklist'>og</span> problemfrit forbinder med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> intelligent vælger den bedste Wi-Fi- eller 5G-forbindelse<br/><span class='marklist'>og</span> oplader hurtigt med et batteri, der holder"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Lås op for de nye AI-oplevelser",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Op til 65 % hurtigere <br/>generativ AI"
                  },
                  {
                    "tr_badgeTitle": "Op til 2,3 gange hurtigere <br/>AI-videoredigering"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolved.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 9 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tyndt og let design, der går ud over ydeevne <br/><span class='marklist'>og</span> problemfrit forbinder med din eksisterende telefon <sup>13</sup><br/><span class='marklist'>og</span> intelligent vælger den bedste Wi-Fi- eller 5G-forbindelse<br/><span class='marklist'>og</span> oplader hurtigt med et batteri, der holder"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Lås op for de nye AI-oplevelser",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 9 -processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Op til 65 % hurtigere <br/>generativ AI"
                  },
                  {
                    "tr_badgeTitle": "Op til 2,3 gange hurtigere <br/>AI-videoredigering"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "legalText": {
    "tr_specTitle": "Juridisk",
    "tr_paragraph_array": [
      "¹Op til 10 timers batterilevetid baseret på en fuld opladning i henhold til interne Google*-test.",
      "²Intet produkt og ingen komponent kan være helt sikker. Kontakt systemproducenten eller forhandleren, eller se mere på intel.com.",
      "³Som målt af branchens benchmarks, Representative Usage Guide-test og unikke funktioner i Intel® Core™ i7-1165G7 &#8209;processor. For arbejdsbelastninger og konfigurationer besøg www.intel.com/PerformanceIndex. Resultaterne kan variere.",
      "⁴Som målt af Google Sheets Multitasking.",
      "⁵Som målt af Speedometer 2.0.",
      "⁶Som målt af Kinemaster Export Video.",
      "I fodnoterne 4-6 er alle test udført på Intel® Core™ i3-1115G4 -processor vs. Intel® Celeron® N4020 -processor. Besøg www.intel.com/PerformanceIndex for at få flere oplysninger om arbejdsbyrde og konfiguration. Ydeevne varierer efter brug, konfiguration og andre faktorer. Få mere at vide på www.intel.com/PerformanceIndex. Ydeevne er baseret på test på de datoer, der vises i konfigurationer og afspejler muligvis ikke alle offentligt tilgængelige opdateringer.",
      "⁷Tid det tager at dræne fra 100 % til kritisk batteriniveau, mens du udfører typiske arbejdsgange i et realistisk miljø. Resultater kan variere. Detaljer på intel.com/evo.",
      "⁸Resultater kan variere. Detaljer på intel.com/evo.",
      "⁹Næsten 3x hurtigere: 802.11ax 2x2 160 MHz giver mulighed for 2402 Mbps maksimum teoretiske datarater. ~3x hurtigere end standard 802.11ac 2x2 80 MHz (867 Mbps) Wi‑Fi som dokumenteret i IEEE 802.11* trådløse standardspecifikationer, og brug af tilsvarende konfigurerede 802.11ax trådløse netværks routere.",
      "¹⁰Opladning opnået fra OEM-standard nedlukningsniveau. Resultater kan variere. Detaljer på intel.com/evo.",
      "¹¹I forhold til den foregående processorgeneration.",
      "¹²Som målt på de unikke funktioner i Intel® Evo™ -designs, f.eks. højtydende Intel® Core™ CPU'er, førsteklasses komponenter, uovertruffen kompatibilitet og de nyeste tilslutningsløsninger i slanke innovative designs. Alle Intel® Evo™ -designs skal opfylde krævende tærskler for vigtige mobile brugeroplevelser som f.eks. reaktionsevne og batterilevetid; den enkelte enheds ydeevne kan variere. Nærmere oplysninger på www.intel.com/PerformanceIndex (platforme).",
      "¹³Intel® Unison™ -løsningen er i øjeblikket kun tilgængelig på kvalificerede Intel® Evo™ -design på Windows*-baserede PC'er og passer kun sammen med Android*- eller iOS*-baserede telefoner. Alle enheder skal køre en understøttet OS-version. Den enkelte Intel® Evo™ -enheds ydeevne kan variere. Detaljer på intel.com/Performance-Evo.",
      "Funktionerne og fordelene ved Intel®s teknologier afhænger af systemkonfigurationen og kan kræve aktiveret hardware, software eller aktivering af tjenester. Ydeevnen afhænger af systemkonfiguration. Ingen computer kan være hundrede procent sikker. Kontakt systemproducenten eller forhandleren, eller se mere på intel.com.",
      "*Andre navne og varemærker kan være ejet af andre.",
      "Intel påtager sig intet ansvar for ukorrekte prisoplysninger.",
      "© 2022 Intel Corporation. Celeron, Intel, Intel-logoet, Intel Core og Pentium er varemærker tilhørende Intel Corporation eller vores datterselskaber."
    ]
  }
}