const Library_ChromeBook_mobile_fr = {
  "uiText": {
    "tr_endlessProgramsAndApps": "Des programmes et applications à l'infini",
    "tr_rethinkStorage": "Repenser le stockage",
    "tr_alwaysOnSecurity": "Sécurité intégrée",
    "tr_extendedBatteryLife": "Autonomie prolongée<sup>1</sup>",
    "tr_viewMore": "Plus de contenus",
    "tr_processorTitle": "PROCESSEUR",
    "tr_processorTitleCamel": "Processeur",
    "tr_rethinkStorageTitle": "REPENSER LE STOCKAGE",
    "tr_alwaysOnSecurityTitle": "SÉCURITÉ INTÉGRÉE",
    "tr_extendedBatteryLifeTitle": "AUTONOMIE PROLONGÉE<sup>1</sup>",
    "tr_endlessProgramsAndAppsTitle": "DES PROGRAMMES ET APPLICATIONS À L'INFINI",
    "tr_extendedBatteryLifeDescription": "[XX]&nbsp;heures",
    "tr_rethinkStorageDescriptionGB": "[XX]&nbsp;Go",
    "tr_rethinkStorageDescriptionTB": "[XX]&nbsp;To",
    "tr_OS": "SYSTÈME D’EXPLOITATION",
    "tr_Chrome_OS": "ChromeOS*",
    "tr_textGBGB": "SSD de [convertedSSD]&nbsp;Go + Disque dur de [convertedHDD]&nbsp;Go",
    "tr_textGBTB": "SSD de [convertedSSD]&nbsp;Go + Disque dur de [convertedHDD]&nbsp;To",
    "tr_textTBGB": "SSD de [convertedSSD]&nbsp;To + Disque dur de [convertedHDD]&nbsp;Go",
    "tr_textTBTB": "SSD de [convertedSSD]&nbsp;To + Disque dur de [convertedHDD]&nbsp;To",
    "tr_textGBSSD": "SSD de [convertedSSD]&nbsp;Go",
    "tr_textTBSSD": "SSD de [convertedSSD]&nbsp;To",
    "tr_textGBHDD": "Disque dur de [convertedHDD]&nbsp;Go",
    "tr_textTBHDD": "Disque dur de [convertedHDD]&nbsp;To",
    "tr_graphicTitle": "CARTE GRAPHIQUE",
    "tr_storageTitle": "STOCKAGE",
    "tr_displayTitle": "ÉCRAN",
    "tr_batteryTitle": "BATTERIE",
    "tr_memoryTitle": "MÉMOIRE",
    "tr_priceTitle": "PRIX"
  },
  "rethinkStorage": {
    "details": {
      "tr_tileTitle": "Repenser le stockage",
      "tr_title": "Une approche innovante du stockage",
      "tr_subtitle": "Chromebook* combine le stockage de votre PC aux&nbsp;ressources presque illimitées de Google* Drive dans&nbsp;le&nbsp;Cloud. Voici les avantages&nbsp;:",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/cloud_upload.svg",
          "tr_badgeSubTitle": "Tous vos fichiers sont automatiquement sauvegardés dans le Cloud. Pour les récupérer, il vous suffit de vous connecter à votre compte Google*.",
          "badgeSubTitle": "cloud-upload"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Travaillez en ligne ou hors ligne avec [XX]&nbsp;Go de stockage sur l'appareil",
          "badgeSubTitle": "GBALONE"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Travaillez en ligne ou hors ligne avec [XX]&nbsp;To de stockage sur l'appareil",
          "badgeSubTitle": "TBALONE"
        }
      ]
    }
  },
  "alwaysOnSecurity": {
    "details": {
      "tr_tileTitle": "Sécurité intégrée",
      "tr_title": "Protection sans effort<sup class='sup-text'>2</sup>",
      "tr_subtitle": "Votre Chromebook* met automatiquement à jour toutes ses&nbsp;fonctions de sécurité afin que vous puissiez naviguer sur&nbsp;le&nbsp;Web et cliquer sur les liens avec la certitude que votre&nbsp;appareil est protégé de façon optimale."
    }
  },
  "extendedBatteryLife": {
    "tr_tileTitle": "Autonomie prolongée<sup>1</sup>",
    "iposTypes": [
      {
        "iposType": "CHROMEBOOK",
        "details": {
          "tr_title": "Soyez plus productif, rechargez moins souvent<sup class='sup-text'>1</sup>",
          "tr_subtitle": "Le design épuré et économe en énergie d'un Chromebook* vous permet de passer des heures sans avoir à le brancher."
        }
      },
      {
        "iposType": "CHROMEBOOKEVO",
        "details": {
          "tr_title": "Rechargez moins souvent.<br/>Et rechargez plus vite.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "La conception épurée et économe en énergie d'un Chromebook* répond aux exigences strictes d'autonomie de la plateforme Intel® Evo™ pour recharger votre appareil plus rapidement et rester plus longtemps sur batterie."
        }
      },
      {
        "iposType": "CHROMEBOOKEVOGEN12",
        "details": {
          "tr_title": "Une autonomie qui vous permet de vous déplacer comme vous le souhaitez<sup class='sup-text'>10</sup>.",
          "tr_subtitle": "Grâce au chargement rapide et à la longue autonomie des Chromebooks* fins et légers, plus besoin de vous demander où se trouve la prise la plus proche."
        }
      }
    ]
  },
  "endlessProgramsAndApps": {
    "details": {
      "tr_tileTitle": "Des programmes et applications à l'infini",
      "tr_title": "De nouvelles applications chaque jour",
      "tr_subtitle": "Les utilisateurs du Chromebook* disposent d'un choix vaste et croissant d'applications et de programmes.",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/download_badge.svg",
          "tr_badgeSubTitle": "Téléchargez les applications Chrome* et Android*."
        },
        {
          "badgeUrl": "assets/images/badges/reset_badge.svg",
          "tr_badgeSubTitle": "Toutes vos applications et données sont synchronisées via votre compte Google*."
        },
        {
          "badgeUrl": "assets/images/badges/connect_badge.svg",
          "tr_badgeSubTitle": "Connectez-vous, apprenez, créez et jouez d'innombrables façons."
        }
      ]
    }
  },
  "processor": [
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Élevez vos attentes",
                "tr_subtitle": "Vos émissions et films préférés sont fluides sur un Chromebook* équipé d'un processeur Intel® Core™&nbsp;i3 de 10<sup>e</sup>&nbsp;génération.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 de 10<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Conçu spécifiquement pour offrir une expérience exceptionnelle, partout.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 10<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Équipé d'un processeur Intel® Core™&nbsp;i5 de 10<sup>e</sup>&nbsp;génération"
                  },
                  {
                    "tr_badgeTitle": "Puissant, même sur batterie"
                  },
                  {
                    "tr_badgeTitle": "Recharge rapide&nbsp;: 4&nbsp;heures d'autonomie en 30&nbsp;minutes<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Connectivité de pointe avec la technologie Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Pour travailler et jouer.",
                "tr_subtitle": "Discutez par vidéo, envoyez des e-mails et créez une présentation tout en améliorant votre productivité sur un Chromebook* équipé d'un processeur Intel® Core™&nbsp;i5 de 10<sup>e</sup>&nbsp;génération.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 10<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Conçu spécifiquement pour offrir une expérience exceptionnelle, partout.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 10<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Équipé d'un processeur Intel® Core™&nbsp;i7 de 10<sup>e</sup>&nbsp;génération"
                  },
                  {
                    "tr_badgeTitle": "Puissant, même sur batterie"
                  },
                  {
                    "tr_badgeTitle": "Recharge rapide&nbsp;: 4&nbsp;heures d'autonomie en 30&nbsp;minutes<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Connectivité de pointe avec la technologie Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Parce que les performances comptent",
                "tr_subtitle": "Montez vos vidéos facilement, téléchargez et partagez instantanément vos souvenirs sur un Chromebook* équipé d'un processeur Intel® Core™&nbsp;i7 de 10<sup>e</sup>&nbsp;génération.",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 10<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des performances plus réactives",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 de 8<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des performances au&nbsp;service de votre passion et&nbsp;de votre productivité",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 8<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des performances au&nbsp;service de votre passion et&nbsp;de votre productivité",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel® Core™ Ultra 5"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des performances au&nbsp;service de votre passion et&nbsp;de votre productivité",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel® Core™ Ultra&nbsp;7"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des performances au&nbsp;service de votre passion et&nbsp;de votre productivité",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel® Core™ Ultra 9"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des performances plus réactives",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;m3 de 8<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des performances élevées pour vos activités quotidiennes",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 7<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des performances de grande qualité pour vos activités quotidiennes",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 7<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Les performances pour vos activités quotidiennes",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;m3 de 7<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des performances élevées pour vos besoins",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 6<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des performances avancées pour vos besoins",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 6<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Les performances dont vous avez besoin",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;m3 de 6<sup>e</sup>&nbsp;génération"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Repousser les limites de performances des Chromebooks*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 de 11<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Performances jusqu'à <br/><span class='big-blue-text'>2,4X  </span><span class='subt-text'>supérieures<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Navigation Web jusqu'à <br/><span class='big-blue-text'>3,2X  </span><span class='subt-text'>plus rapide<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Montage vidéo jusqu'à <br/><span class='big-blue-text'>2,1X  </span><span class='subt-text'>plus rapide<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Imaginez ce que vous pouvez accomplir avec un Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 11<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Performances jusqu'à <br/><span class='big-blue-text'>2,7X  </span><span class='subt-text'>supérieures<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Navigation Web jusqu'à <br/><span class='big-blue-text'>3,3X  </span><span class='subt-text'>plus rapide<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Montage vidéo jusqu'à <br/><span class='big-blue-text'>2,8X  </span><span class='subt-text'>plus rapide<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Conçus pour des performances mobiles",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de 11<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Des performances révolutionnaires grâce aux processeurs Intel® Core™ de 11<sup>e</sup>&nbsp;génération"
                  },
                  {
                    "tr_badgeTitle": "Sort de veille en moins d'une seconde<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet presque 3&nbsp;fois plus rapide grâce à la technologie Intel® Wi-Fi 6 (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Un chargement rapide et une longue autonomie"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Le meilleur processeur au monde pour les Chromebooks*<sup class='sup-text'>1</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Performances jusqu'à <br/><span class='big-blue-text'>2,9X  </span><span class='subt-text'>supérieures<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Navigation Web jusqu'à <br/><span class='big-blue-text'>3,5X  </span><span class='subt-text'>plus rapide<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Montage vidéo jusqu'à <br/><span class='big-blue-text'>2,9X  </span><span class='subt-text'>plus rapide<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Conçus pour des performances mobiles",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de 11<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Des performances révolutionnaires grâce aux processeurs Intel® Core™ de 11<sup>e</sup>&nbsp;génération"
                  },
                  {
                    "tr_badgeTitle": "Sort de veille en moins d'une seconde<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet presque 3&nbsp;fois plus rapide grâce à la technologie Intel® Wi-Fi 6 (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Un chargement rapide et une longue autonomie"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des performances rapides au quotidien",
                "tr_subtitle": "Bénéficiez d'excellentes performances pour tous vos programmes et applications préférés grâce à la puissance d'un processeur Intel® Pentium®",
                "tr_gen_core_title": "Processeur Intel® Pentium® Gold"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des performances rapides au quotidien",
                "tr_subtitle": "Bénéficiez d'excellentes performances pour tous vos programmes et applications préférés grâce à la puissance d'un processeur Intel® Pentium®",
                "tr_gen_core_title": "Processeur Intel® Pentium® Silver"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Améliorez votre productivité quotidienne",
                "tr_subtitle": "Pour lancer vos applications et ouvrir vos projets, un&nbsp;processeur Intel® Celeron® vous aidera à être plus&nbsp;productif.",
                "tr_gen_core_title": "Processeur Intel® Celeron®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Performances à un prix abordable.",
                "tr_subtitle": "Conçu pour les loisirs, le streaming vidéo et la productivité. Profitez de vos films et vidéos Web avec un niveau de détail remarquable. Faites plus de choses avec un minimum d'attente.",
                "tr_gen_core_title": "Processeur Intel® Pentium®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des performances rapides au quotidien",
                "tr_subtitle": "Bénéficiez d'excellentes performances pour tous vos programmes et applications préférés grâce à la puissance d'un processeur Intel® Pentium®",
                "tr_gen_core_title": "Processeur Intel® Pentium®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Performances à un prix abordable.",
                "tr_subtitle": "Productivité accrue – Des performances élevées pour rester productif les jours les plus exigeants. Navigation Web simplifiée – Chargez rapidement les pages Web riches en contenu multimédia pour plus d'exploration et moins d'attente.",
                "tr_gen_core_title": "Processeur Intel® Pentium® Gold"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Performances à un prix abordable.",
                "tr_subtitle": "Maîtrisez votre monde numérique grâce à des performances vous permettant d'accéder à vos fichiers depuis le Web. Travaillez en multitâche en passant facilement d'un programme à un autre et faisant plus de choses en moins de temps.",
                "tr_gen_core_title": "Processeur Intel® Pentium® Silver"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Performances à un prix abordable.",
                "tr_subtitle": "Les performances pour accélérer votre travail et vos loisirs. Profitez de vos films et vidéos Web avec un niveau de détail remarquable. Faites plus de choses avec un minimum d'attente.",
                "tr_gen_core_title": "Processeur Intel® Celeron®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Des performances fiables, là où vous en avez le plus besoin.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3 de&nbsp;12<sup>e</sup>&nbsp;génération ",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitâche<br/>facile"
                  },
                  {
                    "tr_badgeTitle": "Floutage d'arrière-<br/>plan intégré"
                  },
                  {
                    "tr_badgeTitle": "Navigation<br/>Web réactive"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Booste les performances là où vous en avez le plus besoin.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitâche<br/>puissant"
                  },
                  {
                    "tr_badgeTitle": "Suppression<br/>automatique du bruit"
                  },
                  {
                    "tr_badgeTitle": "Streaming<br/>en HD"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Les performances sur appareils mobiles ont évolué.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5 de&nbsp;12<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Des performances supérieures, là où vous en avez le plus besoin, grâce aux processeurs Intel® Core™ de 12<sup>e</sup>&nbsp;génération<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Sort de veille en moins d'une seconde<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet presque 3&nbsp;fois plus rapide grâce à la technologie Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Un chargement rapide et une longue autonomie"
                  },
                  {
                    "tr_badgeTitle": "Collaboration et connectivité améliorées"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Des performances supérieures, là où vous en avez le plus besoin.<sup>11</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitâche<br/>extrême"
                  },
                  {
                    "tr_badgeTitle": "Suppression du bruit<br/>et floutage de l'arrière-<br/>plan intégrés"
                  },
                  {
                    "tr_badgeTitle": "Streaming<br/>8K fluide"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Les performances sur appareils mobiles ont évolué.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7 de&nbsp;12<sup>e</sup>&nbsp;génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Des performances supérieures, là où vous en avez le plus besoin, grâce aux processeurs Intel® Core™ de 12<sup>e</sup>&nbsp;génération<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Sort de veille en moins d'une seconde<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet presque 3&nbsp;fois plus rapide grâce à la technologie Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Un chargement rapide et une longue autonomie"
                  },
                  {
                    "tr_badgeTitle": "Collaboration et connectivité améliorées"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des Chromebooks*<br/>exceptionnels.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel® Core™ i5 de 13<sup>e</sup> génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Un design fin et léger qui va bien au-delà des performances<br/><span class='marklist'>et</span> se connecte facilement à votre téléphone<sup>13</sup><br/><span class='marklist'>et</span> sélectionne intelligemment la meilleure connexion Wi-Fi ou 5G <br/><span class='marklist'>et</span> se recharge rapidement et offre une longue autonomie"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Des performances exceptionnelles pour donner vie à toutes vos passions.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel® Core™ i5 de 13<sup>e</sup> génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitâche<br/>amélioré"
                  },
                  {
                    "tr_badgeTitle": "Streaming 4K<br/>fluide"
                  },
                  {
                    "tr_badgeTitle": "Création<br/>puissante"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des Chromebooks*<br/>exceptionnels.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel® Core™ i7 de 13<sup>e</sup> génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Un design fin et léger qui va bien au-delà des performances<br/><span class='marklist'>et</span> se connecte facilement à votre téléphone<sup>13</sup><br/><span class='marklist'>et</span> sélectionne intelligemment la meilleure connexion Wi-Fi ou 5G <br/><span class='marklist'>et</span> se recharge rapidement et offre une longue autonomie"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Des performances de pointe pour donner vie à toutes vos passions.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel® Core™ i7 de 13<sup>e</sup> génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitâche<br/>extrême"
                  },
                  {
                    "tr_badgeTitle": "Streaming 8K<br/>fluide"
                  },
                  {
                    "tr_badgeTitle": "Création<br/>avancée"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Processeur Intel®",
                "tr_subtitle": "Les performances dont vous avez besoin, sans dépasser votre budget.",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Des performances remarquables pour donner vie à toutes vos passions",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel® Core™ i3 de 13<sup>e</sup> génération",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitâche<br/>facile"
                  },
                  {
                    "tr_badgeTitle": "Streaming<br/>fluide"
                  },
                  {
                    "tr_badgeTitle": "Création<br/>rapide"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des Chromebooks*<br/>exceptionnels.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Un design fin et léger qui va bien au-delà des performances<br/><span class='marklist'>et</span> se connecte facilement à votre téléphone<sup>13</sup><br/><span class='marklist'>et</span> sélectionne intelligemment la meilleure connexion Wi-Fi ou 5G <br/><span class='marklist'>et</span> se recharge rapidement et offre une longue autonomie"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Des performances remarquables pour donner vie à toutes vos passions",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitâche<br/>facile"
                  },
                  {
                    "tr_badgeTitle": "Streaming<br/>fluide"
                  },
                  {
                    "tr_badgeTitle": "Création<br/>rapide"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des Chromebooks*<br/>exceptionnels.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Un design fin et léger qui va bien au-delà des performances<br/><span class='marklist'>et</span> se connecte facilement à votre téléphone<sup>13</sup><br/><span class='marklist'>et</span> sélectionne intelligemment la meilleure connexion Wi-Fi ou 5G <br/><span class='marklist'>et</span> se recharge rapidement et offre une longue autonomie"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Des performances exceptionnelles pour donner vie à toutes vos passions.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitâche<br/>amélioré"
                  },
                  {
                    "tr_badgeTitle": "Streaming 4K<br/>fluide"
                  },
                  {
                    "tr_badgeTitle": "Création<br/>puissante"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des Chromebooks*<br/>exceptionnels.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Un design fin et léger qui va bien au-delà des performances<br/><span class='marklist'>et</span> se connecte facilement à votre téléphone<sup>13</sup><br/><span class='marklist'>et</span> sélectionne intelligemment la meilleure connexion Wi-Fi ou 5G <br/><span class='marklist'>et</span> se recharge rapidement et offre une longue autonomie"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Des performances de pointe pour donner vie à toutes vos passions.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitâche<br/>extrême"
                  },
                  {
                    "tr_badgeTitle": "Streaming 8K<br/>fluide"
                  },
                  {
                    "tr_badgeTitle": "Création<br/>avancée"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des Chromebooks*<br/>exceptionnels.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Un design fin et léger qui va bien au-delà des performances<br/><span class='marklist'>et</span> se connecte facilement à votre téléphone<sup>13</sup><br/><span class='marklist'>et</span> sélectionne intelligemment la meilleure connexion Wi-Fi ou 5G <br/><span class='marklist'>et</span> se recharge rapidement et offre une longue autonomie"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Des performances supérieures au service de vos passions",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Retouches photo<br/>et montage vidéo rapides"
                  },
                  {
                    "tr_badgeTitle": "Capacités hors ligne<br/>améliorées"
                  },
                  {
                    "tr_badgeTitle": "Une longue autonomie<br/>pour jouer<br/>plus longtemps"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_3",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des Chromebooks*<br/>exceptionnels.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Un design fin et léger qui va bien au-delà des performances<br/><span class='marklist'>et</span> se connecte facilement à votre téléphone<sup>13</sup><br/><span class='marklist'>et</span> sélectionne intelligemment la meilleure connexion Wi-Fi ou 5G <br/><span class='marklist'>et</span> se recharge rapidement et offre une longue autonomie"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Des performances remarquables pour donner vie à toutes vos passions",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitâche<br/>facile"
                  },
                  {
                    "tr_badgeTitle": "Streaming<br/>fluide"
                  },
                  {
                    "tr_badgeTitle": "Création<br/>rapide"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_5",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des Chromebooks*<br/>exceptionnels.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Un design fin et léger qui va bien au-delà des performances<br/><span class='marklist'>et</span> se connecte facilement à votre téléphone<sup>13</sup><br/><span class='marklist'>et</span> sélectionne intelligemment la meilleure connexion Wi-Fi ou 5G <br/><span class='marklist'>et</span> se recharge rapidement et offre une longue autonomie"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Des performances exceptionnelles pour donner vie à toutes vos passions.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitâche<br/>amélioré"
                  },
                  {
                    "tr_badgeTitle": "Streaming 4K<br/>fluide"
                  },
                  {
                    "tr_badgeTitle": "Création<br/>puissante"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_7",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des Chromebooks*<br/>exceptionnels.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Un design fin et léger qui va bien au-delà des performances<br/><span class='marklist'>et</span> se connecte facilement à votre téléphone<sup>13</sup><br/><span class='marklist'>et</span> sélectionne intelligemment la meilleure connexion Wi-Fi ou 5G <br/><span class='marklist'>et</span> se recharge rapidement et offre une longue autonomie"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Des performances de pointe pour donner vie à toutes vos passions.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel®&nbsp;Core™&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitâche<br/>extrême"
                  },
                  {
                    "tr_badgeTitle": "Streaming 8K<br/>fluide"
                  },
                  {
                    "tr_badgeTitle": "Création<br/>avancée"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des Chromebooks*<br/>exceptionnels.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel® Core™ Ultra 5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Un design fin et léger qui va bien au-delà des performances<br/><span class='marklist'>et</span> se connecte facilement à votre téléphone<sup>13</sup><br/><span class='marklist'>et</span> sélectionne intelligemment la meilleure connexion Wi-Fi ou 5G <br/><span class='marklist'>et</span> se recharge rapidement et offre une longue autonomie"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Explorez de nouvelles expériences d'IA",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel® Core™ Ultra 5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "IA générative<br/>jusqu'à 65 % plus rapide"
                  },
                  {
                    "tr_badgeTitle": "Montage vidéo assisté par l'IA<br/>jusqu'à 2,3 fois plus rapide"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des Chromebooks*<br/>exceptionnels.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel® Core™ Ultra&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Un design fin et léger qui va bien au-delà des performances<br/><span class='marklist'>et</span> se connecte facilement à votre téléphone<sup>13</sup><br/><span class='marklist'>et</span> sélectionne intelligemment la meilleure connexion Wi-Fi ou 5G <br/><span class='marklist'>et</span> se recharge rapidement et offre une longue autonomie"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Explorez de nouvelles expériences d'IA",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel® Core™ Ultra&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "IA générative<br/>jusqu'à 65 % plus rapide"
                  },
                  {
                    "tr_badgeTitle": "Montage vidéo assisté par l'IA<br/>jusqu'à 2,3 fois plus rapide"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Des Chromebooks*<br/>exceptionnels.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel® Core™ Ultra 9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Un design fin et léger qui va bien au-delà des performances<br/><span class='marklist'>et</span> se connecte facilement à votre téléphone<sup>13</sup><br/><span class='marklist'>et</span> sélectionne intelligemment la meilleure connexion Wi-Fi ou 5G <br/><span class='marklist'>et</span> se recharge rapidement et offre une longue autonomie"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processeur",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Explorez de nouvelles expériences d'IA",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processeur Intel® Core™ Ultra 9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "IA générative<br/>jusqu'à 65 % plus rapide"
                  },
                  {
                    "tr_badgeTitle": "Montage vidéo assisté par l'IA<br/>jusqu'à 2,3 fois plus rapide"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "legalText": {
    "tr_specTitle": "Mentions légales",
    "tr_paragraph_array": [
      "¹&nbsp;Jusqu'à 10&nbsp;heures d'autonomie avec une charge complète selon les tests internes de Google*.",
      "²&nbsp;Aucun produit ou composant ne saurait être totalement sécurisé. Pour plus de détails, contactez le fabricant ou le vendeur de votre ordinateur ou rendez-vous sur intel.com.",
      "³ Mesures effectuées avec les bancs d'essai de l'industrie, les tests du guide des utilisations représentatives et les fonctionnalités uniques du processeur Intel® Core™ i7-1165G7. Pour connaître les charges de travail et les configurations, rendez-vous sur www.intel.com/PerformanceIndex. Les résultats effectifs peuvent varier.",
      "⁴ Mesures effectuées avec Google Sheets en mode multitâche.",
      "⁵ Mesures effectuées avec Speedometer 2.0.",
      "⁶ Mesures effectuées avec Kinemaster pour l'exportation de vidéos.",
      "Pour les notes de pied de page 4 à 6, tous les tests ont été effectués en comparant le processeur Intel® Core™ i3-1115G4 au processeur Intel® Celeron® N4020. Pour plus d'informations sur les charges de travail et les configurations, rendez-vous sur www.intel.com/PerformanceIndex. Les performances varient en fonction de l'utilisation, de la configuration et d'autres facteurs. Plus d'infos sur www.intel.com/PerformanceIndex. Les résultats de performance s'appuient sur les tests réalisés aux dates indiquées dans les configurations et peuvent ne pas refléter toutes les mises à jour de sécurité disponibles.",
      "⁷ Temps écoulé pour passer d'un niveau de batterie de 100&nbsp;% à un niveau critique lors de l'exécution de flux de travail habituels dans un environnement réaliste. Les résultats effectifs peuvent varier. Vous trouverez plus de détails sur la page intel.com/evo.",
      "⁸ Vos résultats peuvent varier. Vous trouverez plus de détails sur la page intel.com/evo.",
      "⁹ Près de 3 fois plus rapide : la norme 802.11ax 2x2 160 MHz permet d'atteindre des débits de données théoriques maximum de 2 402 Mbit/s. ~3 fois plus rapide que la norme Wi‑Fi 802.11ac 2x2 80 MHz (867 Mbit/s), comme indiqué dans les spécifications de la norme de réseau sans fil IEEE 802.11* et nécessite l'utilisation de routeurs réseau sans fil configurés de manière similaire.",
      "¹⁰ Charge atteinte à partir de la fonction d'arrêt par défaut de l'ordinateur. Les résultats effectifs peuvent varier. Vous trouverez plus de détails sur la page intel.com/evo.",
      "¹¹Par rapport à la génération de processeur précédente.",
      "¹²Tel que mesuré par les fonctionnalités uniques des designs Intel® Evo™, telles que des processeurs Intel® Core™ haute performance, des composants haut de gamme, une compatibilité inégalée et les dernières solutions de connectivité dans des designs élégants et novateurs. Tous les designs qui portent la marque Intel® Evo™ doivent répondre à des exigences clés concernant l'expérience des utilisateurs mobiles, comme la réactivité et l'autonomie. Les performances des appareils individuels peuvent varier. Plus d'informations sur www.intel.com/PerformanceIndex (plateformes).",
      "¹³La solution Intel® Unison™ est actuellement uniquement disponible sur les designs Intel® Evo™ éligibles fonctionnant sous Windows* et ne se couple qu'avec des téléphones Android* ou iOS*. Tous les appareils doivent fonctionner avec la version du système d'exploitation prise en charge. Les performances de chaque appareil Intel® Evo™ peuvent varier. Vous trouverez plus d'informations sur intel.com/Performance-Evo.",
      "Les fonctionnalités et avantages des technologies Intel® dépendent de la configuration du système et peuvent nécessiter du matériel et des logiciels compatibles, et l'activation de services. Les résultats varient selon la configuration. Aucun ordinateur ne saurait être totalement sécurisé. Pour plus de détails, contactez le fabricant ou le vendeur de votre ordinateur ou rendez-vous sur intel.fr.",
      "*Les autres noms et marques peuvent être revendiqués comme la propriété de tiers.",
      "Intel rejette toute responsabilité en cas de prix incorrect.",
      "© 2022 Intel Corporation. Celeron, Intel, le logo Intel, Intel Core et Pentium sont des marques commerciales d'Intel Corporation ou de ses filiales."
    ]
  }
}