const Library_ChromeBook_mobile_nl = {
  "uiText": {
    "tr_endlessProgramsAndApps": "Oneindig veel programma's en apps",
    "tr_rethinkStorage": "Een nieuwe manier van opslag",
    "tr_alwaysOnSecurity": "Ingebouwde beveiliging",
    "tr_extendedBatteryLife": "Verlengde accuduur<sup>1</sup>",
    "tr_viewMore": "Meer lezen",
    "tr_processorTitle": "PROCESSOR",
    "tr_processorTitleCamel": "Processor",
    "tr_rethinkStorageTitle": "EEN NIEUWE MANIER VAN OPSLAG",
    "tr_alwaysOnSecurityTitle": "INGEBOUWDE BEVEILIGING",
    "tr_extendedBatteryLifeTitle": "VERLENGDE ACCUDUUR<sup>1</sup>",
    "tr_endlessProgramsAndAppsTitle": "ONEINDIG VEEL PROGRAMMA'S EN APPS",
    "tr_extendedBatteryLifeDescription": "[XX] uur",
    "tr_rethinkStorageDescriptionGB": "[XX] GB",
    "tr_rethinkStorageDescriptionTB": "[XX] TB",
    "tr_OS": "OS",
    "tr_Chrome_OS": "ChromeOS*",
    "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD] GB HDD",
    "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD] TB HDD",
    "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD] GB HDD",
    "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD] TB HDD",
    "tr_textGBSSD": "[convertedSSD] GB SSD",
    "tr_textTBSSD": "[convertedSSD] TB SSD",
    "tr_textGBHDD": "[convertedHDD] GB HDD",
    "tr_textTBHDD": "[convertedHDD] TB HDD",
    "tr_graphicTitle": "GRAPHICS",
    "tr_storageTitle": "OPSLAG",
    "tr_displayTitle": "SCHERM",
    "tr_batteryTitle": "ACCU",
    "tr_memoryTitle": "GEHEUGEN",
    "tr_priceTitle": "PRIJS"
  },
  "rethinkStorage": {
    "details": {
      "tr_tileTitle": "Een nieuwe manier van opslag",
      "tr_title": "Een innovatieve opslagoplossing",
      "tr_subtitle": "Chromebook* combineert opslag op je pc met de vrijwel onbeperkte bronnen van Google Drive* in de cloud. Dit zijn de voordelen:",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/cloud_upload.svg",
          "tr_badgeSubTitle": "Van al je bestanden wordt automatisch een back-up gemaakt in de cloud. Je kunt dus al je bestanden eenvoudig terugvinden als je je aanmeldt bij je Google-account.",
          "badgeSubTitle": "cloud-upload"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Werk on- en offline met [XX] GB opslagruimte op je device.",
          "badgeSubTitle": "GBALONE"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Werk on- en offline met [XX] TB opslagruimte op je device.",
          "badgeSubTitle": "TBALONE"
        }
      ]
    }
  },
  "alwaysOnSecurity": {
    "details": {
      "tr_tileTitle": "Ingebouwde beveiliging",
      "tr_title": "Moeiteloze bescherming<sup class='sup-text'>2</sup>",
      "tr_subtitle": "Chromebook* werkt automatisch alle beveiligingsfuncties bij, zodat je kunt internetten en op links kunt klikken met de zekerheid dat je device beter is beveiligd."
    }
  },
  "extendedBatteryLife": {
    "tr_tileTitle": "Verlengde accuduur<sup>1</sup>",
    "iposTypes": [
      {
        "iposType": "CHROMEBOOK",
        "details": {
          "tr_title": "Doe meer, laad minder op<sup class='sup-text'>1</sup>",
          "tr_subtitle": "Dankzij het gestroomlijnde en energiezuinige ontwerp van een Chromebook* kun je urenlang werken zonder dat je de Chromebook hoeft op te laden."
        }
      },
      {
        "iposType": "CHROMEBOOKEVO",
        "details": {
          "tr_title": "Laad minder vaak en sneller.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Het gestroomlijnde en energie-efficiënte ontwerp van een Chromebook* voldoet aan de veeleisende accuvereisten van het Intel® Evo™ platform om sneller en minder vaak te laden."
        }
      },
      {
        "iposType": "CHROMEBOOKEVOGEN12",
        "details": {
          "tr_title": "Accuduur die je mobiel houdt.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Met snel oplaadbare accu's en een lange gebruiksduur in dunne en lichte Chromebooks* hoef je niet meer op zoek te gaan naar het dichtstbijzijnde stopcontact."
        }
      }
    ]
  },
  "endlessProgramsAndApps": {
    "details": {
      "tr_tileTitle": "Oneindig veel programma's en apps",
      "tr_title": "Elke dag meer apps",
      "tr_subtitle": "Chromebook*-gebruikers kunnen kiezen uit een enorme selectie apps en programma's, en de keuze wordt steeds groter.",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/download_badge.svg",
          "tr_badgeSubTitle": "Download apps voor Chrome* en Android*."
        },
        {
          "badgeUrl": "assets/images/badges/reset_badge.svg",
          "tr_badgeSubTitle": "Al je apps en gegevens worden gesynchroniseerd via je Google-account."
        },
        {
          "badgeUrl": "assets/images/badges/connect_badge.svg",
          "tr_badgeSubTitle": "Leg contact, leer, creëer en geniet op oneindig veel manieren."
        }
      ]
    }
  },
  "processor": [
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Upgrade je verwachtingen",
                "tr_subtitle": "Bekijk moeiteloos je favoriete tv-series en films op je Chromebook* met een 10<sup>e</sup> generatie Intel® Core™ i3 processor.",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Speciaal ontworpen voor uitzonderlijke prestaties, overal.",
                "tr_subtitle": "",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Met een 10<sup>e</sup> generatie Intel® Core™ i5 processor"
                  },
                  {
                    "tr_badgeTitle": "Krachtig, zelfs zonder stekker in het stopcontact"
                  },
                  {
                    "tr_badgeTitle": "Snel oplaadbaar: 4 uur accuduur in 30 minuten<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Best-in-class connectiviteit met Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Echt werk. Echt gamen.",
                "tr_subtitle": "Videochat, verstuur e-mails en maak een presentatie, terwijl je je productiviteit verhoogt op een Chromebook* met een 10<sup>e</sup> generatie Intel® Core™ i5 processor.",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i5 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Speciaal ontworpen voor uitzonderlijke prestaties, overal.",
                "tr_subtitle": "",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Met een 10<sup>e</sup> generatie Intel® Core™ i7 processor"
                  },
                  {
                    "tr_badgeTitle": "Krachtig, zelfs zonder stekker in het stopcontact"
                  },
                  {
                    "tr_badgeTitle": "Snel oplaadbaar: 4 uur accuduur in 30 minuten<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Best-in-class connectiviteit met Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Omdat prestaties tellen",
                "tr_subtitle": "Bewerk eenvoudig je video's en upload en deel meteen je herinneringen op een Chromebook* met een 10<sup>e</sup> generatie Intel® Core™ i7 processor.",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i7 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Snelle, responsieve prestaties",
                "tr_subtitle": "",
                "tr_gen_core_title": "8<sup>e</sup> generatie Intel® Core™ i3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Geef je passie en productiviteit kracht",
                "tr_subtitle": "",
                "tr_gen_core_title": "8<sup>e</sup> generatie Intel® Core™ i5 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Geef je passie en productiviteit kracht",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 5 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Geef je passie en productiviteit kracht",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Geef je passie en productiviteit kracht",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 9 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Snelle, responsieve prestaties",
                "tr_subtitle": "",
                "tr_gen_core_title": "8<sup>e</sup> generatie Intel® Core™ m3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Hoge prestaties voor je dagelijkse bezigheden",
                "tr_subtitle": "",
                "tr_gen_core_title": "7<sup>e</sup> generatie Intel® Core™ i5 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Premium prestaties voor je dagelijkse bezigheden",
                "tr_subtitle": "",
                "tr_gen_core_title": "7<sup>e</sup> generatie Intel® Core™ i7 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestaties voor je dagelijkse bezigheden",
                "tr_subtitle": "",
                "tr_gen_core_title": "7<sup>e</sup> generatie Intel® Core™ m3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Verbeterde prestaties voor jouw wensen",
                "tr_subtitle": "",
                "tr_gen_core_title": "6<sup>e</sup> generatie Intel® Core™ i5 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Geavanceerde prestaties voor jouw wensen",
                "tr_subtitle": "",
                "tr_gen_core_title": "6<sup>e</sup> generatie Intel® Core™ i7 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestaties voor jouw wensen",
                "tr_subtitle": "",
                "tr_gen_core_title": "6<sup>e</sup> generatie Intel® Core™ m3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Grensverleggende prestaties voor Chromebooks*",
                "tr_subtitle": "",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i3 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tot <br/><span class='big-blue-text'>2,4x  </span><span class='subt-text'>betere prestaties<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Tot <br/><span class='big-blue-text'>3,2x  </span><span class='subt-text'>sneller internetten<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Tot <br/><span class='big-blue-text'>2,1x  </span><span class='subt-text'>sneller video's bewerken<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Voed je verbeelding met Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tot <br/><span class='big-blue-text'>2,7x  </span><span class='subt-text'>betere prestaties<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Tot <br/><span class='big-blue-text'>3,3x  </span><span class='subt-text'>sneller internetten<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Tot <br/><span class='big-blue-text'>2,8x  </span><span class='subt-text'>sneller video's bewerken<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ontwikkeld voor mobiele prestaties",
                "tr_subtitle": "",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Grensverleggende prestaties met 11<sup>e</sup> generatie Intel® Core™ processors"
                  },
                  {
                    "tr_badgeTitle": "Binnen 1 seconde gebruiksklaar<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Bijna 3x sneller internet met Intel® Wi-Fi 6 (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Snel oplaadbare accu met lange levensduur"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "'s Werelds beste processor voor Chromebooks*<sup class='sup-text'>1</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tot <br/><span class='big-blue-text'>2,9x  </span><span class='subt-text'>betere prestaties<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Tot <br/><span class='big-blue-text'>3,5x  </span><span class='subt-text'>sneller internetten<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Tot <br/><span class='big-blue-text'>2,9x  </span><span class='subt-text'>sneller video's bewerken<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Ontwikkeld voor mobiele prestaties",
                "tr_subtitle": "",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Grensverleggende prestaties met 11<sup>e</sup> generatie Intel® Core™ processors"
                  },
                  {
                    "tr_badgeTitle": "Binnen 1 seconde gebruiksklaar<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Bijna 3x sneller internet met Intel® Wi-Fi 6 (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Snel oplaadbare accu met lange levensduur"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Snelle, dagelijkse prestaties",
                "tr_subtitle": "Profiteer van uitstekende prestaties van al je favoriete programma's en apps met de kracht van een Intel® Pentium® processor",
                "tr_gen_core_title": "Intel® Pentium® Gold processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Snelle, dagelijkse prestaties",
                "tr_subtitle": "Profiteer van uitstekende prestaties van al je favoriete programma's en apps met de kracht van een Intel® Pentium® processor",
                "tr_gen_core_title": "Intel® Pentium® Silver processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Verhoog je dagelijkse productiviteit",
                "tr_subtitle": "Van het starten van apps tot het openen van projecten, met een Intel® Celeron® processor bereik je meer.",
                "tr_gen_core_title": "Intel® Celeron® processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestaties en prijs-kwaliteitverhouding.",
                "tr_subtitle": "Ontworpen voor geweldig entertainment, videostreaming en productiviteit. Geniet van films en online video's tot in de kleinste details. Doe meer en wacht minder.",
                "tr_gen_core_title": "Intel® Pentium® processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Snelle, dagelijkse prestaties",
                "tr_subtitle": "Profiteer van uitstekende prestaties van al je favoriete programma's en apps met de kracht van een Intel® Pentium® processor",
                "tr_gen_core_title": "Intel® Pentium® processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestaties en prijs-kwaliteitverhouding.",
                "tr_subtitle": "Verbeterde productiviteit: hogere prestaties zodat je zelfs de drukste dagen aankunt. Gestroomlijnd internetten: laad snel uitgebreide webpagina's om meer te ontdekken en minder te wachten.",
                "tr_gen_core_title": "Intel® Pentium® Gold processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestaties en prijs-kwaliteitverhouding.",
                "tr_subtitle": "Blijf op de hoogte van je digitale wereld omdat je toegang hebt tot bestanden op het web. Schakel heel makkelijk tussen programma's om te multitasken en meer te doen in minder tijd.",
                "tr_gen_core_title": "Intel® Pentium® Silver processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestaties en prijs-kwaliteitverhouding.",
                "tr_subtitle": "Krachtige prestaties voor werk en entertainment. Geniet van films en online video's tot in de kleinste details. Doe meer en wacht minder.",
                "tr_gen_core_title": "Intel® Celeron® processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Betrouwbare prestaties, wanneer je ze het meest nodig hebt",
                "tr_subtitle": "",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i3 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Eenvoudig<br/>multitasken"
                  },
                  {
                    "tr_badgeTitle": "Ingebouwde<br/>achtergrondvervaging"
                  },
                  {
                    "tr_badgeTitle": "Responsief<br/>internetten"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Betere prestaties op de momenten dat het nodig is",
                "tr_subtitle": "",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Krachtig<br/>multitasken"
                  },
                  {
                    "tr_badgeTitle": "Automatische<br/>ruisonderdrukking"
                  },
                  {
                    "tr_badgeTitle": "Streamen<br/>in HD"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Mobiele prestaties geëvolueerd",
                "tr_subtitle": "",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Superieure prestaties op de momenten dat het nodig is met 12<sup>e</sup> generatie Intel® Core™ processors<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Binnen 1 seconde gebruiksklaar<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Bijna 3x sneller internet met Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Snel oplaadbare accu met lange levensduur"
                  },
                  {
                    "tr_badgeTitle": "Verbeterde samenwerking en connectiviteit"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Superieure prestaties op de momenten dat het nodig is<sup>11</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Extreem<br/>multitasken"
                  },
                  {
                    "tr_badgeTitle": "Ingebouwde<br/>ruisonderdrukking en<br/>achtergrondvervaging"
                  },
                  {
                    "tr_badgeTitle": "Vloeiend<br/>streamen in 8K"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Mobiele prestaties geëvolueerd",
                "tr_subtitle": "",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Superieure prestaties op de momenten dat het nodig is met 12<sup>e</sup> generatie Intel® Core™ processors<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Binnen 1 seconde gebruiksklaar<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Bijna 3x sneller internet met Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Snel oplaadbare accu met lange levensduur"
                  },
                  {
                    "tr_badgeTitle": "Verbeterde samenwerking en connectiviteit"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Geëvolueerd.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Dun en licht ontwerp dat verder gaat dan prestaties<br/><span class='marklist'>en</span> naadloos aansluit op je bestaande telefoon <sup>13</sup><br/><span class='marklist'>en</span> op intelligente manier de beste wifi- of 5G-verbinding selecteert<br/><span class='marklist'>en</span> snel oplaadt met een accu die meegaat"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Doe wat je doet met uitzonderlijke prestaties.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Beter<br/>multitasken"
                  },
                  {
                    "tr_badgeTitle": "Vloeiend<br/>streamen in 4K"
                  },
                  {
                    "tr_badgeTitle": "Krachtige<br/>creaties"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Geëvolueerd.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Dun en licht ontwerp dat verder gaat dan prestaties<br/><span class='marklist'>en</span> naadloos aansluit op je bestaande telefoon <sup>13</sup><br/><span class='marklist'>en</span> op intelligente manier de beste wifi- of 5G-verbinding selecteert<br/><span class='marklist'>en</span> snel oplaadt met een accu die meegaat"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Doe wat je doet met superieure prestaties.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Extreem<br/>multitasken"
                  },
                  {
                    "tr_badgeTitle": "Vloeiend<br/>streamen in 8K"
                  },
                  {
                    "tr_badgeTitle": "Geavanceerd<br/>creëren"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Intel® processor",
                "tr_subtitle": "De prestaties die je nodig hebt. De&nbsp;prijs die je wilt.",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Doe wat je doet met uitstekende prestaties.",
                "tr_subtitle": "",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i3 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Eenvoudig<br/>multitasken"
                  },
                  {
                    "tr_badgeTitle": "Vloeiend<br/>streamen"
                  },
                  {
                    "tr_badgeTitle": "Snel<br/>creëren"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Geëvolueerd.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Dun en licht ontwerp dat verder gaat dan prestaties<br/><span class='marklist'>en</span> naadloos aansluit op je bestaande telefoon <sup>13</sup><br/><span class='marklist'>en</span> op intelligente manier de beste wifi- of 5G-verbinding selecteert<br/><span class='marklist'>en</span> snel oplaadt met een accu die meegaat"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Doe wat je doet met uitstekende prestaties.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i3 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Eenvoudig<br/>multitasken"
                  },
                  {
                    "tr_badgeTitle": "Vloeiend<br/>streamen"
                  },
                  {
                    "tr_badgeTitle": "Snel<br/>creëren"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Geëvolueerd.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Dun en licht ontwerp dat verder gaat dan prestaties<br/><span class='marklist'>en</span> naadloos aansluit op je bestaande telefoon <sup>13</sup><br/><span class='marklist'>en</span> op intelligente manier de beste wifi- of 5G-verbinding selecteert<br/><span class='marklist'>en</span> snel oplaadt met een accu die meegaat"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Doe wat je doet met uitzonderlijke prestaties.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Beter<br/>multitasken"
                  },
                  {
                    "tr_badgeTitle": "Vloeiend<br/>streamen in 4K"
                  },
                  {
                    "tr_badgeTitle": "Krachtige<br/>creaties"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Geëvolueerd.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Dun en licht ontwerp dat verder gaat dan prestaties<br/><span class='marklist'>en</span> naadloos aansluit op je bestaande telefoon <sup>13</sup><br/><span class='marklist'>en</span> op intelligente manier de beste wifi- of 5G-verbinding selecteert<br/><span class='marklist'>en</span> snel oplaadt met een accu die meegaat"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Doe wat je doet met superieure prestaties.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Extreem<br/>multitasken"
                  },
                  {
                    "tr_badgeTitle": "Vloeiend<br/>streamen in 8K"
                  },
                  {
                    "tr_badgeTitle": "Geavanceerd<br/>creëren"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Geëvolueerd.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Dun en licht ontwerp dat verder gaat dan prestaties<br/><span class='marklist'>en</span> naadloos aansluit op je bestaande telefoon <sup>13</sup><br/><span class='marklist'>en</span> op intelligente manier de beste wifi- of 5G-verbinding selecteert<br/><span class='marklist'>en</span> snel oplaadt met een accu die meegaat"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Geef je passies kracht met superieure prestaties",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Snelle foto-<br/>en videobewerking"
                  },
                  {
                    "tr_badgeTitle": "Verbeterde offline<br/>mogelijkheden"
                  },
                  {
                    "tr_badgeTitle": "Zorgeloos gamen<br/>dankzij een<br/>lange accuduur"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_3",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Geëvolueerd.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Dun en licht ontwerp dat verder gaat dan prestaties<br/><span class='marklist'>en</span> naadloos aansluit op je bestaande telefoon <sup>13</sup><br/><span class='marklist'>en</span> op intelligente manier de beste wifi- of 5G-verbinding selecteert<br/><span class='marklist'>en</span> snel oplaadt met een accu die meegaat"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Doe wat je doet met uitstekende prestaties.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 3 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Eenvoudig<br/>multitasken"
                  },
                  {
                    "tr_badgeTitle": "Vloeiend<br/>streamen"
                  },
                  {
                    "tr_badgeTitle": "Snel<br/>creëren"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_5",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Geëvolueerd.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Dun en licht ontwerp dat verder gaat dan prestaties<br/><span class='marklist'>en</span> naadloos aansluit op je bestaande telefoon <sup>13</sup><br/><span class='marklist'>en</span> op intelligente manier de beste wifi- of 5G-verbinding selecteert<br/><span class='marklist'>en</span> snel oplaadt met een accu die meegaat"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Doe wat je doet met uitzonderlijke prestaties.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Beter<br/>multitasken"
                  },
                  {
                    "tr_badgeTitle": "Vloeiend<br/>streamen in 4K"
                  },
                  {
                    "tr_badgeTitle": "Krachtige<br/>creaties"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_7",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Geëvolueerd.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Dun en licht ontwerp dat verder gaat dan prestaties<br/><span class='marklist'>en</span> naadloos aansluit op je bestaande telefoon <sup>13</sup><br/><span class='marklist'>en</span> op intelligente manier de beste wifi- of 5G-verbinding selecteert<br/><span class='marklist'>en</span> snel oplaadt met een accu die meegaat"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Doe wat je doet met superieure prestaties.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ 7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Extreem<br/>multitasken"
                  },
                  {
                    "tr_badgeTitle": "Vloeiend<br/>streamen in 8K"
                  },
                  {
                    "tr_badgeTitle": "Geavanceerd<br/>creëren"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Geëvolueerd.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Dun en licht ontwerp dat verder gaat dan prestaties<br/><span class='marklist'>en</span> naadloos aansluit op je bestaande telefoon <sup>13</sup><br/><span class='marklist'>en</span> op intelligente manier de beste wifi- of 5G-verbinding selecteert<br/><span class='marklist'>en</span> snel oplaadt met een accu die meegaat"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Maak nieuwe AI-ervaringen mogelijk",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tot 65% snellere<br/>generatieve AI"
                  },
                  {
                    "tr_badgeTitle": "Tot 2,3x snellere<br/>AI-videobewerking"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Geëvolueerd.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Dun en licht ontwerp dat verder gaat dan prestaties<br/><span class='marklist'>en</span> naadloos aansluit op je bestaande telefoon <sup>13</sup><br/><span class='marklist'>en</span> op intelligente manier de beste wifi- of 5G-verbinding selecteert<br/><span class='marklist'>en</span> snel oplaadt met een accu die meegaat"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Maak nieuwe AI-ervaringen mogelijk",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tot 65% snellere<br/>generatieve AI"
                  },
                  {
                    "tr_badgeTitle": "Tot 2,3x snellere<br/>AI-videobewerking"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Geëvolueerd.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Dun en licht ontwerp dat verder gaat dan prestaties<br/><span class='marklist'>en</span> naadloos aansluit op je bestaande telefoon <sup>13</sup><br/><span class='marklist'>en</span> op intelligente manier de beste wifi- of 5G-verbinding selecteert<br/><span class='marklist'>en</span> snel oplaadt met een accu die meegaat"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Maak nieuwe AI-ervaringen mogelijk",
                "tr_subtitle": "",
                "tr_gen_core_title": "Intel® Core™ Ultra 9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Tot 65% snellere<br/>generatieve AI"
                  },
                  {
                    "tr_badgeTitle": "Tot 2,3x snellere<br/>AI-videobewerking"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "legalText": {
    "tr_specTitle": "Juridisch",
    "tr_paragraph_array": [
      "¹Tot 10 uur accuduur bij volledig opgeladen accu, volgens interne tests van Google*.",
      "²Geen enkel product of component kan absoluut veilig zijn. Neem contact op met de fabrikant of leverancier of lees meer op intel.com.",
      "³Zoals gemeten met sectorbenchmarks, Representative Usage Guide tests en unieke kenmerken van de Intel® Core™ i7-1165G7 processor. Ga voor werkbelastingen en configuraties naar www.intel.com/PerformanceIndex. Resultaten kunnen afwijken.",
      "⁴Zoals gemeten via multitasken met Google Spreadsheets.",
      "⁵Zoals gemeten via Speedometer 2.0.",
      "⁶Zoals gemeten via video exporteren met KineMaster.",
      "Voor voetnoten 4 t/m 6 geldt dat alle tests zijn uitgevoerd op een Intel® Core™ i3-1115G4 processor versus een Intel® Celeron® N4020 processor. Ga naar www.intel.com/PerformanceIndex voor meer informatie over werkbelasting en configuratie. De prestaties variëren per gebruik, configuratie en andere factoren. Meer informatie op www.intel.com/PerformanceIndex. De prestatieresultaten zijn gebaseerd op tests op de datums die bij de configuraties vermeld worden en komen mogelijk niet overeen met alle openbaar beschikbare updates.",
      "⁷Tijd die nodig is om van 100% naar een kritiek accuniveau te gaan tijdens het uitvoeren van typische workflows in een realistische omgeving. Resultaten kunnen variëren. Meer informatie op intel.com/evo.",
      "⁸Resultaten kunnen variëren. Meer informatie op intel.com/evo.",
      "⁹Bijna 3x sneller: 802.11ax 2x2 160 MHz maakt 2402 Mbps maximale theoretische gegevenssnelheden mogelijk. ~3x sneller dan standaard 802.11ac 2x2 80 MHz (867 Mbps) wifi zoals gedocumenteerd in IEEE 802.11* draadloze standaardspecificaties en vereist het gebruik van vergelijkbare geconfigureerde 802.11ax draadloze netwerkrouters.",
      "¹⁰Lading bereikt vanaf OEM-standaard uitschakelniveau. Resultaten kunnen variëren. Meer informatie op intel.com/evo.",
      "¹¹In vergelijking met de vorige processorgeneratie.",
      "¹²Zoals gemeten door unieke kenmerken van Intel® Evo™ ontwerpen zoals hoog presterende Intel® Core™ CPU's, hoogwaardige componenten, ongeëvenaarde compatibiliteit en de nieuwste connectiviteitsoplossingen in slanke innovatieve ontwerpen. Alle ontwerpen van het merk Intel® Evo™ moeten voldoen aan veeleisende drempels voor belangrijke mobiele gebruikerservaringen zoals reactievermogen en accuduur. Prestaties van individuele devices kunnen variëren. Meer informatie op www.intel.com/PerformanceIndex (platforms).",
      "¹³De Intel® Unison™ oplossing is momenteel alleen beschikbaar op geschikte Intel® Evo™ modellen op Windows*-pc's en kan alleen worden gekoppeld aan telefoons met Android* of iOS*. Alle devices moeten een ondersteunde OS-versie uitvoeren. De prestaties van individuele Intel® Evo™ devices kunnen variëren. Meer informatie op intel.com/Performance-Evo.",
      "De eigenschappen en voordelen van Intel® technologieën zijn afhankelijk van de systeemconfiguratie en vereisen mogelijk ingeschakelde hardware, software en serviceactivering. De prestaties zijn afhankelijk van de systeemconfiguratie. Geen enkel computersysteem kan absoluut veilig zijn. Neem contact op met de fabrikant of leverancier of lees meer op intel.com.",
      "*Andere namen en merknamen kunnen worden geclaimd als het eigendom van anderen. ",
      "Intel aanvaardt geen aansprakelijkheid voor onjuiste prijzen.",
      "© 2022 Intel Corporation. Celeron, Intel, het Intel logo, Intel Core en Pentium zijn handelsmerken van Intel Corporation of zijn dochterondernemingen."
    ]
  }
}