const Library_mobile_zhTW = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "搭載 [cpuDisplay] 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "搭載 [cpuDisplay] 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "就跟大腦一樣，您的處理器控制了電腦的一切動作。這表示您觀看的影片、您玩的遊戲、您造訪的網站，全都始於處理器。",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "如果處理器是 電腦的大腦"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "效能與價值。",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Silver 處&#65279;理&#65279;器",
                    "tr_subtitle": "透過從網路存取檔案的優異效能，讓您在數位世界中領先群雄。在不同程式間輕鬆切換，同時進行多項工作，以及在更短的時間內完成更多任務。",
                    "tr_gen_core_title": "Intel® Pentium® Silver 處&#65279;理&#65279;器"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Gold 處&#65279;理&#65279;器",
                    "tr_subtitle": "提高生產力 - 提升效能，助您順利挺過工作最艱鉅的日子。簡化網頁瀏覽 - 快速載入多媒體的網頁，以更少的等待時間探索更多內容。",
                    "tr_gen_core_title": "Intel® Pentium® Gold 處&#65279;理&#65279;器"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Celeron® 處&#65279;理&#65279;器",
                    "tr_subtitle": "優異的效能，進而提升工作生產力和娛樂體驗。享受電影和網路影片內容鉅細靡遺地呈現，令人嘆為觀止。減少等候的時間，將更多時間投注在重要的事。",
                    "tr_gen_core_title": "Intel® Celeron® 處&#65279;理&#65279;器"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® 處理器",
                    "tr_subtitle": "專為提升娛樂體驗、視訊串流和生產力打造。觀賞電影和網路影片時，內容鉅細靡遺地呈現，令人嘆為觀止。減少等候的時間，將更多時間投注在重要的事。",
                    "tr_gen_core_title": "Intel® Pentium® 處理器"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "您需要的效能<br/>您想要的經濟實惠",
                "tr_title": "Intel® 處理器",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "超群出眾的效能價值",
                    "tr_subtitle": "Intel® 處理器能以您負擔得起的價格，處理您所需的任何工作。在不同軟體之間穿梭自如。或以穩定的效能享受高品質圖形。",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "建立更穩固的連線",
                    "tr_subtitle": "更生動的影片品質、更豐富的音訊清晰度和速度快上近 3 倍的網際網路連線能力，讓您無論在哪裡都能安心協作。<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "從早到晚不間斷",
                    "tr_subtitle": "從早上的電影馬拉松到最後一刻的潤飾，Intel® 處理器延長了您裝置的電池續航力，讓您享受真正身歷其境、不間斷的體驗。",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "哪裡都能學習",
                    "tr_subtitle": "在家裡的各個角落，輕鬆執行多個線上學習工具。"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "隨處學習",
                    "tr_subtitle": "在您需要的任何地方完成課程，同時執行各種線上學習工具。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "取得符合預算又適合您生活方式的娛樂與生產力。",
                "tr_title": "任何預算都能享有 Intel&nbsp;效&#65279;能",
                "tr_gen_core_title": "Intel® Celeron® 處&#65279;理&#65279;器",
                "processorCompare": {
                  "tr_title": "尋找合適的效能與價值等級",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Intel® Celeron® 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "快速瀏覽多媒體的網頁內容",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "在不同程式間輕鬆切換",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "一般電腦遊戲",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "串流 4K 影片",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "簡易的相片和影片編輯",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "具有智慧型效能的快速生產力",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "搭載第 7 代 Intel® Core™ [cpu_fam] 處&#65279;理&#65279;器"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "搭載第 6 代 Intel® Core™ [cpu_fam] 處&#65279;理&#65279;器"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "搭載第 5 代 Intel® Core™ [cpu_fam] 處&#65279;理&#65279;器"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "搭載第 4 代 Intel® Core™ [cpu_fam] 處&#65279;理&#65279;器"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "搭載第 3 代 Intel® Core™ [cpu_fam] 處&#65279;理&#65279;器"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "搭載第 2 代 Intel® Core™ [cpu_fam] 處&#65279;理&#65279;器"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "搭載第 1 代 Intel® Core™ [cpu_fam] 處&#65279;理&#65279;器"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "推薦用途：",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "搭載第 8 代 Intel® Core™ i3 處理器",
                "tr_gen_core_title": "第&nbsp;8&nbsp;代 Intel®&nbsp;Core™&nbsp;i3 處&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 9 代 Intel® Core™ 處&#65279;理&#65279;器的設計目標是要與您的數位世界並&#65279;駕&#65279;齊&#65279;驅。兼&#65279;具速&#65279;度和效&#65279;能，讓&#65279;您能用&#65279;電&#65279;腦順利完成更多您所喜愛的活動。",
                "tr_title": "搭載第 9 代 Intel® Core™ i3 處&#65279;理&#65279;器",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代 Intel®&nbsp;Core™&nbsp;i3 處&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "多樣化的效能加上強大的電池續航力，讓您無論到何處都能繼續工作。",
                "tr_title": "強大效能伴您度過每&#65279;一&#65279;天",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i3 處&#xfeff;理&#xfeff;器",
                "processorCompare": {
                  "tr_title": "尋找最適合您的第 10 代處&#65279;理&#65279;器效&#65279;能&#65279;等&#65279;級",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "串流 4K 影片",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "迅速、穩固的連線能力",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "影像遮罩和影片濾鏡",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "以 1080p 高解析度和流暢畫面播放速率玩遊戲",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最適合處理大型檔案和創意程式",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "輕鬆在電影串流和簡報製作等工作間切&#65279;換，還有足夠的效能餘裕做其他的事。",
                "tr_title": "強大效能伴您度過每&#65279;一&#65279;天",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i3 處&#xfeff;理&#xfeff;器"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "專為四處奔波的您設計的筆記型電腦。",
                "tr_title": "全新等級筆電",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i3 處&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "第 11 代 Intel® Core™ i3 處&#65279;理&#65279;器專為可攜式電腦設計，無論您身在何處，都能讓您成就更多。",
                "tr_title": "更充分地發揮新&#65279;筆&#65279;記&#65279;型&#65279;電&#65279;腦的威力",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i3 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i3 處&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "當全家想要找一台能符合所有人需求的桌上型電腦，您需要的是第 11 代 Intel® Core™ i3 處理器的威力與多重功能。",
                "tr_title": "打破框架的精湛效能讓您隨時都能挑戰不&#65279;可&#65279;能",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i3 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i3 處&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "搭載第 8 代 Intel® Core™ i5 處&#65279;理&#65279;器",
                "tr_gen_core_title": "第&nbsp;8&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 9 代 Intel® Core™ 處&#65279;理&#65279;器的設計目標是要與您的數位世界並&#65279;駕&#65279;齊&#65279;驅。兼&#65279;具速&#65279;度和效&#65279;能，讓&#65279;您能用&#65279;電&#65279;腦順利完成更多您所喜愛的活動。",
                "tr_title": "搭載第 9 代 Intel® Core™ i5 處&#65279;理&#65279;器",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "為需求最嚴苛的遊戲提供流暢的遊戲體驗和 VR",
                "tr_title": "專業級遊戲體驗 從&#65279;這&#65279;開&#65279;始",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "根據您的遊戲風格，找出最合適的 INTEL® CORE™ 處理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "3A遊戲",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "電玩競技",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "開戰、直播、錄製，效能不須妥協",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "適合遊戲的最佳第 9 代 Intel® Core™ 處理器",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：4 <br/>執行緒：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "輕鬆處理多個程式，讓您隨時隨地做好準備。",
                "tr_title": "隨時隨地多工處理",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "processorCompare": {
                  "tr_title": "尋找最適合您的第 10 代處&#65279;理&#65279;器效&#65279;能&#65279;等&#65279;級",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "串流 4K 影片",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "迅速、穩固的連線能力",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "影像遮罩和影片濾鏡",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "以 1080p 高解析度和流暢畫面播放速率玩遊戲",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最適合處理大型檔案和創意程式",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "不管是娛樂、生產力還是內容創作，您的各項工作都能更上一層樓",
                "tr_title": "升級至更強大的電腦",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "專為四處奔波的您設計的筆記型電腦。",
                "tr_title": "全新等級筆電",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "專為順暢的直播和 3A 遊戲打造",
                "tr_title": "照您的規則玩遊戲",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "根據您的遊戲風格，找出最合適的第&nbsp;10&nbsp;代&nbsp;INTEL®&nbsp;CORE™&nbsp;處&#65279;理&#65279;器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "順暢遊玩 3A遊戲",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "串流",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "電玩競技",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播與錄製",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "進階內容創作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "頂尖遊戲的效能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "不鎖頻且可超頻<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "專為順暢的直播和 3A 遊戲打造",
                "tr_title": "照您的規則玩遊戲",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "根據您的遊戲風格，找出最合適的第&nbsp;10&nbsp;代&nbsp;INTEL®&nbsp;CORE™&nbsp;處&#65279;理&#65279;器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "順暢遊玩 3A遊戲",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "串流",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "電玩競技",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播與錄製",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "進階內容創作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "頂尖遊戲的效能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "不鎖頻且可超頻<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：10 <br/>執行緒：20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "輕鬆處理多個程式，讓您隨時隨地做好準備。",
                "tr_title": "隨時隨地多工處理",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 10 代 INTEL® CORE™ i5 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "第 11 代 Intel® Core™ i5 處&#65279;理&#65279;器賦予極致輕薄的筆記型電腦生動的視&#65279;覺體驗和廣泛的生產力。",
                "tr_title": "令人震撼的筆&#65279;記&#65279;型&#65279;電&#65279;腦威力",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i5 處理器",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "當全家想要找一台能符合所有人需求的桌上型電腦，您需要的是第 11 代 Intel® Core™ i5 處理器的威力與多重功能。",
                "tr_title": "打破框架的精湛效能讓您隨時都能挑戰不&#65279;可&#65279;能",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i5 處理器",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 11 代 Intel® Core™ i5 處理器為纖薄輕巧的筆記型電腦賦予生動的視覺體驗和廣泛的生產力。",
                "tr_title": "令人震撼的筆&#65279;記&#65279;型&#65279;電&#65279;腦威力",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i5 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "搭載第 8 代 Intel® Core™ i7 處&#65279;理&#65279;器",
                "tr_gen_core_title": "第&nbsp;8&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "第 9 代 Intel® Core™ 處&#65279;理&#65279;器的設計目標是要與您的數位世界並&#65279;駕&#65279;齊&#65279;驅。兼&#65279;具速&#65279;度和效&#65279;能，讓&#65279;您能用&#65279;電&#65279;腦順利完成更多您所喜愛的活動。",
                "tr_title": "搭載第 9 代 Intel® Core™ i7 處&#65279;理&#65279;器",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "相較於機齡 3 年的電腦在遊戲、串流及錄製時，FPS 提升高達 32%<sup>3</sup>",
                "tr_title": "分享遊戲最精彩瞬間的效能",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "根據您的遊戲風格，找出最合適的 INTEL® CORE™ 處理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Intel® Core™ i9 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "3A遊戲",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "電玩競技",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "開戰、直播、錄製，效能不須妥協",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "適合遊戲的最佳第 9 代 Intel® Core™ 處理器",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：4 <br/>執行緒：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "為效率而生，這個順暢的裝置可以大大改變您創作、連接和串流的方式。",
                "tr_title": "隨時隨地都能享受卓&#65279;越&#65279;效&#65279;能",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "processorCompare": {
                  "tr_title": "尋找最適合您的第 10 代處&#65279;理&#65279;器效&#65279;能&#65279;等&#65279;級",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "串流 4K 影片",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "迅速、穩固的連線能力",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "影像遮罩和影片濾鏡",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "以 1080p 高解析度和流暢畫面播放速率玩遊戲",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最適合處理大型檔案和創意程式",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "進階效能讓您的各項工作都更上一層樓。",
                "tr_title": "無所不能的威力",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "專為四處奔波的您設計的筆記型電腦。",
                "tr_title": "全新等級筆電",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "開戰、直播、錄製各方面都給您競爭優勢",
                "tr_title": "驅動您的遊戲世界",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "根據您的遊戲風格，找出最合適的第&nbsp;10&nbsp;代&nbsp;INTEL®&nbsp;CORE™&nbsp;處&#65279;理&#65279;器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "順暢遊玩 3A遊戲",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "串流",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "電玩競技",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播與錄製",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "進階內容創作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "頂尖遊戲的效能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "不鎖頻且可超頻<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "開戰、直播、錄製各方面都給您競爭優勢",
                "tr_title": "驅動您的遊戲世界",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "根據您的遊戲風格，找出最合適的第&nbsp;10&nbsp;代&nbsp;INTEL®&nbsp;CORE™&nbsp;處&#65279;理&#65279;器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "順暢遊玩 3A遊戲",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "串流",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "電玩競技",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播與錄製",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "進階內容創作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "頂尖遊戲的效能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "不鎖頻且可超頻<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：10 <br/>執行緒：20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "這款時尚的裝置專為效率而設計，可以大大改變您創作、連線和直播的方式。",
                "tr_title": "隨處發揮優異效能",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 10 代 INTEL® CORE™ i7 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "第 11 代&nbsp;Intel® Core™ i7 處&#65279;理&#65279;器讓您可以在超輕便可攜的電腦上體驗進階內容創作、順暢的遊戲以及嶄新境界的娛樂饗宴。",
                "tr_title": "打破框架的筆&#65279;記&#65279;型&#65279;電&#65279;腦效能",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i7 處理器",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "藉助第 11 代 Intel® Core™ i7 處理器打破框架的精湛效能，讓您的桌上型電腦成為家用所需的一切解答。",
                "tr_title": "工作、學習、娛樂 無&#65279;所&#65279;限&#65279;制",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i7 處理器",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 11 代 Intel® Core™ i7 處理器讓您可以在纖薄輕巧的筆記型電腦上體驗進階內容創作、順暢的遊戲以及嶄新境界的娛樂饗宴。",
                "tr_title": "打破框架的筆&#65279;記&#65279;型&#65279;電&#65279;腦效能",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i7 處理器"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "藉助第 11 代 Intel® Core™ i7 處理器打破框架的精湛效能，讓您的桌上型電腦成為家用所需的一切解答。",
                "tr_title": "創作無所限制",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i7 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "這款第 11 代 Intel® Core™ i7 處理器透過在輕薄筆記型電腦上進行進階內容創作、順暢的遊戲與全新境界的娛樂體驗，變得更加出色。",
                "tr_title": "打破框架的筆&#65279;記&#65279;型&#65279;電&#65279;腦效能",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i7 處理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "搭載第 8 代 Intel® Core™ i9 處&#65279;理&#65279;器",
                "tr_gen_core_title": "第&nbsp;8&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 9 代 Intel® Core™ 處&#65279;理&#65279;器的設計目標是要與您的數位世界並&#65279;駕&#65279;齊&#65279;驅。兼&#65279;具速&#65279;度和效&#65279;能，讓&#65279;您能用&#65279;電&#65279;腦順利完成更多您所喜愛的活動。",
                "tr_title": "搭載第 9 代 Intel® Core™ i9 處&#65279;理&#65279;器",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "相較於機齡 3 年的電腦在遊戲、串流及錄製時 FPS 提升高達 41%<sup>4</sup>",
                "tr_title": "遊戲玩家的需求 Intel®&nbsp;Core™&nbsp;i9&nbsp;處&#65279;理&#65279;器來實現",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "根據您的遊戲風格，找出最合適的 INTEL® CORE™ 處理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "3A遊戲",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "電玩競技",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "開戰、直播、錄製，效能不須妥協",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "適合遊戲的最佳第 9 代 Intel® Core™ 處理器",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：4 <br/>執行緒：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "享受極致遊戲體驗發揮您的終極潛能",
                "tr_title": "迎戰最高級別的競爭",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "根據您的遊戲風格，找出最合適的第&nbsp;10&nbsp;代&nbsp;INTEL®&nbsp;CORE™&nbsp;處&#65279;理&#65279;器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "順暢遊玩 3A遊戲",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "串流",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "電玩競技",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播與錄製",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "進階內容創作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "頂尖遊戲的效能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "不鎖頻且可超頻<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "享受極致遊戲體驗發揮您的終極潛能",
                "tr_title": "迎戰最高級別的競爭",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "根據您的遊戲風格，找出最合適的第&nbsp;10&nbsp;代&nbsp;INTEL®&nbsp;CORE™&nbsp;處&#65279;理&#65279;器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "順暢遊玩 3A遊戲",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "串流",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "電玩競技",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播與錄製",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "進階內容創作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "頂尖遊戲的效能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "不鎖頻且可超頻<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：10 <br/>執行緒：20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "透過極致創作體驗激發您的潛力",
                "tr_title": "以最高水準創作",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 10 代 INTEL® CORE™ i9 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ 筆記型電腦搭載第 11 代 Intel® Core™ i5 處理器與 Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片，帶給您不可思議的速度與效能，一切盡在纖薄輕巧的設計中。",
                "tr_title": "進化筆電的提升威力",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i5 處理器",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ 筆記型電腦搭載第 11 代 Intel® Core™ i7 處理器與 Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片，帶給您先進的速度與效能，暢享頂級的行動體驗。",
                "tr_title": "進化筆電的先進威力",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i7 處理器",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "以令人驚豔的價值，享受效能、媒體與連線體驗的絕佳平衡。",
                "tr_title": "效能與連線能力物超所值",
                "tr_gen_core_title": "Intel® Pentium® Silver 處&#65279;理&#65279;器",
                "processorCompare": {
                  "tr_title": "尋找合適的效能與價值等級",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "快速瀏覽多媒體的網頁內容",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "在不同程式間輕鬆切換",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "一般電腦遊戲",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "串流 4K 影片",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "簡易的相片和影片編輯",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "具有智慧型效能的快速生產力",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "享受流暢的多工作業、簡易的相片和影片編輯、4K 高畫質影片與生動的繪圖，物超所值、令人驚豔。",
                "tr_title": "令人驚艷的效能<br/>工作玩樂皆適宜",
                "tr_gen_core_title": "Intel® Pentium® Gold 處&#65279;理&#65279;器",
                "processorCompare": {
                  "tr_title": "尋找合適的效能與價值等級",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "快速瀏覽多媒體的網頁內容",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "在不同程式間輕鬆切換",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "一般電腦遊戲",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "串流 4K 影片",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "簡易的相片和影片編輯",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "具有智慧型效能的快速生產力",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "在超便攜式電腦中體驗 3A遊戲和長&#65279;效&#65279;電&#65279;池&#65279;續&#65279;航&#65279;力&#65279;。不須妥協您的遊戲方式。",
                "tr_title": "不必妥協",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "尋找您的 INTEL® CORE™ 超便攜電競處理器。",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "第 11 代 Intel®&nbsp;Core™ i5&#8209;11300H 處&#65279;理&#65279;器"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "第 11 代 Intel®&nbsp;Core™ i7&#8209;11370H 處&#65279;理&#65279;器"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "第 11 代 Intel®&nbsp;Core™ i7&#8209;11375H 特&#65279;別&#65279;版&#65279;處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "最大時脈速度高&#65279;達 <span class='style_badge'>4.4 GHz</span>",
                      "tr_td2_title": "最大時脈速度高&#65279;達 <span class='style_badge'>4.8 GHz</span>",
                      "tr_td3_title": "最大時脈速度高&#65279;達 <span class='style_badge'>5.0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 核心/ 8&nbsp;執&#65279;行&#65279;緒",
                      "tr_td2_title": "4 核心/ 8&nbsp;執&#65279;行&#65279;緒",
                      "tr_td3_title": "4 核心/ 8&nbsp;執&#65279;行&#65279;緒"
                    },
                    {
                      "tr_td1_title": "Intel® 渦輪加速技術",
                      "tr_td2_title": "Intel® 渦輪加速技術",
                      "tr_td3_title": "Intel® 渦輪加速 Max 技術 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® 智慧型快&#65279;取&#65279;記&#65279;憶&#65279;體",
                      "tr_td2_title": "12 MB Intel® 智慧型快&#65279;取&#65279;記&#65279;憶&#65279;體",
                      "tr_td3_title": "12 MB Intel® 智慧型快&#65279;取&#65279;記&#65279;憶&#65279;體"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "這款超便攜式電腦讓您在高設定下以高 FPS 暢玩 1080p 3A遊戲，並且具備優異的不插電效能。",
                "tr_title": "放膽擁有一切",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "尋找您的 INTEL® CORE™ 超便攜電競處理器。",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "第 11 代 Intel®&nbsp;Core™ i5&#8209;11300H 處&#65279;理&#65279;器"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "第 11 代 Intel®&nbsp;Core™ i7&#8209;11370H 處&#65279;理&#65279;器"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "第 11 代 Intel®&nbsp;Core™ i7&#8209;11375H 特&#65279;別&#65279;版&#65279;處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "最大時脈速度高&#65279;達 <span class='style_badge'>4.4 GHz</span>",
                      "tr_td2_title": "最大時脈速度高&#65279;達 <span class='style_badge'>4.8 GHz</span>",
                      "tr_td3_title": "最大時脈速度高&#65279;達 <span class='style_badge'>5.0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 核心/ 8&nbsp;執&#65279;行&#65279;緒",
                      "tr_td2_title": "4 核心/ 8&nbsp;執&#65279;行&#65279;緒",
                      "tr_td3_title": "4 核心/ 8&nbsp;執&#65279;行&#65279;緒"
                    },
                    {
                      "tr_td1_title": "Intel® 渦輪加速技術",
                      "tr_td2_title": "Intel® 渦輪加速技術",
                      "tr_td3_title": "Intel® 渦輪加速 Max 技術 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® 智慧型快&#65279;取&#65279;記&#65279;憶&#65279;體",
                      "tr_td2_title": "12 MB Intel® 智慧型快&#65279;取&#65279;記&#65279;憶&#65279;體",
                      "tr_td3_title": "12 MB Intel® 智慧型快&#65279;取&#65279;記&#65279;憶&#65279;體"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "最大時脈速度高達 5.0 GHz，讓您的遊戲體驗更上一層樓，超便攜式電腦讓您走到哪玩到哪。",
                "tr_title": "輕薄且強悍",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 特&#xfeff;別&#xfeff;版&#xfeff;處&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 特殊版處理器",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "尋找您的 INTEL® CORE™ 超便攜電競處理器。",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "第 11 代 Intel®&nbsp;Core™ i5&#8209;11300H 處&#65279;理&#65279;器"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "第 11 代 Intel®&nbsp;Core™ i7&#8209;11370H 處&#65279;理&#65279;器"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "第 11 代 Intel®&nbsp;Core™ i7&#8209;11375H 特&#65279;別&#65279;版&#65279;處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "最大時脈速度高&#65279;達 <span class='style_badge'>4.4 GHz</span>",
                      "tr_td2_title": "最大時脈速度高&#65279;達 <span class='style_badge'>4.8 GHz</span>",
                      "tr_td3_title": "最大時脈速度高&#65279;達 <span class='style_badge'>5.0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 核心/ 8&nbsp;執&#65279;行&#65279;緒",
                      "tr_td2_title": "4 核心/ 8&nbsp;執&#65279;行&#65279;緒",
                      "tr_td3_title": "4 核心/ 8&nbsp;執&#65279;行&#65279;緒"
                    },
                    {
                      "tr_td1_title": "Intel® 渦輪加速技術",
                      "tr_td2_title": "Intel® 渦輪加速技術",
                      "tr_td3_title": "Intel® 渦輪加速 Max 技術 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® 智慧型快&#65279;取&#65279;記&#65279;憶&#65279;體",
                      "tr_td2_title": "12 MB Intel® 智慧型快&#65279;取&#65279;記&#65279;憶&#65279;體",
                      "tr_td3_title": "12 MB Intel® 智慧型快&#65279;取&#65279;記&#65279;憶&#65279;體"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 11 代 Intel® Core™ i7 特別版處理器讓您可以在超輕便可攜的 PC 上體驗進階內容創作、順暢的遊戲以及嶄新境界的娛樂饗宴。",
                "tr_title": "打破框架的筆&#65279;記&#65279;型&#65279;電&#65279;腦效能",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 特&#xfeff;別&#xfeff;版&#xfeff;處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 Intel® Core™ i7 特別版處理器"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "第 11 代 Intel® Core™ i7 特別版處理器讓您可以在超輕便可攜的 PC 上體驗進階內容創作、順暢的遊戲以及嶄新境界的娛樂饗宴。",
                "tr_title": "打破框架的筆&#65279;記&#65279;型&#65279;電&#65279;腦效能",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 特&#xfeff;別&#xfeff;版&#xfeff;處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 Intel® Core™ i7 特別版處理器",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 特殊版處理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "快速時脈速度與高核心數天衣無縫地合作，讓您在競爭激烈的電競世界與複雜的內容創作中搶占優勢。",
                "tr_title": "強大性能<br/>完美平衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "無論是具有高 FPS 的競爭性遊戲，還是身歷其境的 3A 體驗，您都能從遊戲中獲得時脈速度與核心效能的完美平衡。",
                "tr_title": "強大性能<br/>為遊戲而生",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "利用高 FPS 在要求嚴苛的遊戲上開戰、錄製及直播，不費吹灰之力切換至繁重的多工作業工作負載。",
                "tr_title": "突破限制的強大性能",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "快速時脈速度與高核心數天衣無縫地合作，讓您在競爭激烈的電競世界與複雜的內容創作中搶占優勢。",
                "tr_title": "強大性能<br/>完美平衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "輕鬆在電影串流和簡報製作等工作間切&#65279;換，還有足夠的效能餘裕做其他的事。",
                "tr_title": "強大效能伴您度過每&#65279;一&#65279;天",
                "tr_gen_core_title": "搭載 Intel® Hybrid Technology 的 Intel® Core™ i3 處&#65279;理&#65279;器",
                "tr_subTitle2": "搭載 Intel® Hybrid Technology 的 Intel® Core™ i3 處&#65279;理&#65279;器"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "多樣化的效能加上強大的電池續航力，讓您無論到何處都能繼續工作。",
                "tr_title": "強大效能伴您度過每&#65279;一&#65279;天",
                "tr_gen_core_title": "搭載 Intel® Hybrid Technology 的 Intel® Core™ i3 處&#65279;理&#65279;器",
                "tr_subTitle2": "搭載 Intel® Hybrid Technology 的 Intel® Core™ i3 處&#65279;理&#65279;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "輕鬆處理多個程式，讓您隨時隨地做好準備。",
                "tr_title": "隨時隨地多工處理",
                "tr_gen_core_title": "搭載 Intel® Hybrid Technology 的 Intel® Core™ i5 處&#65279;理&#65279;器",
                "tr_subTitle2": "搭載 Intel® Hybrid Technology 的 Intel® Core™ i5 處&#65279;理&#65279;器"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "不管是娛樂、生產力還是內容創作，您的各項工作都能更上一層樓",
                "tr_title": "升級至更強大的電腦",
                "tr_gen_core_title": "搭載 Intel® Hybrid Technology 的 Intel® Core™ i5 處&#65279;理&#65279;器",
                "tr_subTitle2": "搭載 Intel® Hybrid Technology 的 Intel® Core™ i5 處&#65279;理&#65279;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "桌上型電腦級的遊戲效能加上行動力，無論是需要高 FPS 的競技性遊戲或沉浸式的 3A 遊戲體驗皆非常合適。",
                "tr_title": "強大性能<br/>為遊戲而生",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "桌上型電腦級的遊戲效能加上行動力，無論是需要高 FPS 的競技性遊戲或沉浸式的 3A 遊戲體驗皆非常合適。",
                "tr_title": "強大性能<br/>為遊戲而生",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i5 處理器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "這台筆記型電腦能以高 FPS 同時播放、錄製與直播，並可輕鬆切換至繁重的多工作業負載。",
                "tr_title": "突破限制的強大性能",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "這台筆記型電腦能以高 FPS 同時播放、錄製與直播，並可輕鬆切換至繁重的多工作業負載。",
                "tr_title": "突破限制的強大性能",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i7 處理器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "無論您帶著電腦到任何地方，高時脈速度與高核心數都能緊密合作，讓您在競爭激烈的遊戲世界與複雜的內容創作中搶占優勢。",
                "tr_title": "強大性能<br/>完美平衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "無論您帶著電腦到任何地方，高時脈速度與高核心數都能緊密合作，讓您在競爭激烈的遊戲世界與複雜的內容創作中搶占優勢。",
                "tr_title": "強大性能<br/>完美平衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i9 處理器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "無論您帶著電腦到任何地方，快速時脈速度、高核心數與徹底釋放的效能，都能緊密合作，讓您在競爭激烈的遊戲世界與複雜的內容創作中搶占優勢。",
                "tr_title": "解鎖力量<br>完美平衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "無論您帶著電腦到任何地方，快速時脈速度、高核心數與徹底釋放的效能，都能緊密合作，讓您在競爭激烈的遊戲世界與複雜的內容創作中搶占優勢。",
                "tr_title": "解鎖力量<br>完美平衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i9 處理器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "快速時脈速度與高核心數天衣無縫地合作，讓您在競爭激烈的電競世界與複雜的內容創作中搶占優勢。",
                "tr_title": "強大性能<br/>完美平衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i9 處理器",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "無論您帶著電腦到哪裡，快速時脈速度與高核心數都能天衣無縫地合作，讓您在複雜的內容創作中搶占優勢。",
                "tr_title": "強大性能<br/>完美平衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i9 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "快速時脈速度與高核心數天衣無縫地合作，讓您在複雜的內容創作中搶占優勢。",
                "tr_title": "強大性能<br/>完美平衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i9 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "享受極致遊戲體驗發揮您的終極潛能",
                "tr_title": "迎戰最高級別的競爭",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "根據您的遊戲風格，找出最合適的第&nbsp;10&nbsp;代&nbsp;INTEL®&nbsp;CORE™&nbsp;處&#65279;理&#65279;器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "順暢遊玩 3A遊戲",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "串流",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "電玩競技",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播與錄製",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "進階內容創作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "頂尖遊戲的效能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "不鎖頻且可超頻<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "享受極致遊戲體驗發揮您的終極潛能",
                "tr_title": "迎戰最高級別的競爭",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "根據您的遊戲風格，找出最合適的第&nbsp;10&nbsp;代&nbsp;INTEL®&nbsp;CORE™&nbsp;處&#65279;理&#65279;器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "順暢遊玩 3A遊戲",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "串流",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "電玩競技",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播與錄製",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "進階內容創作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "頂尖遊戲的效能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "不鎖頻且可超頻<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：10 <br/>執行緒：20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "專為四處奔波的您設計的筆記型電腦。",
                "tr_title": "全新等級筆電",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "快速時脈速度與高核心數天衣無縫地合作，讓您在競爭激烈的電競世界與複雜的內容創作中搶占優勢。",
                "tr_title": "解鎖力量<br>完美平衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i9 處理器",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "無論您帶著電腦到哪裡，快速時脈速度、高核心數與不鎖頻效能，都能天衣無縫地合作，讓您在複雜的內容創作中搶占優勢。",
                "tr_title": "解鎖力量<br>完美平衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i9 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "快速時脈速度與高核心數天衣無縫地合作，讓您在複雜的內容創作中搶占優勢。",
                "tr_title": "解鎖力量<br>完美平衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第 11 代 INTEL® CORE™ i9 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® 智慧型快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "在需求最嚴苛的遊戲提供流暢的遊戲體驗和 VR",
                "tr_title": "專業級遊戲體驗 從&#65279;這&#65279;開&#65279;始",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代 Intel®&nbsp;Core™&nbsp;i5 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "根據您的遊戲風格，找出最合適的 INTEL® CORE™ 處理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "3A遊戲",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "電玩競技",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "開戰、直播、錄製，效能不須妥協",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "適合遊戲的最佳第 9 代 Intel® Core™ 處理器",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：4 <br/>執行緒：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "相較於機齡 3 年的電腦<br/><span class='tr_subtitle_font'>在遊戲、串流及錄製時，FPS 提升高達 32%<sup>3</sup></span>",
                "tr_title": "分享遊戲最精彩瞬間的效能",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "根據您的遊戲風格，找出最合適的 INTEL® CORE™ 處理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "3A遊戲",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "電玩競技",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "開戰、直播、錄製，效能不須妥協",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "適合遊戲的最佳第 9 代 Intel® Core™ 處理器",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：4 <br/>執行緒：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "相較於機齡 3 年的電腦<br/><span class='tr_subtitle_font'>在遊戲、串流及錄製時，FPS&nbsp;提&#65279;升&#65279;高&#65279;達 41%<sup>4</sup></span>",
                "tr_title": "遊戲玩家的需求 Intel®&nbsp;Core™&nbsp;i9&nbsp;處&#65279;理&#65279;器來實現",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代 Intel®&nbsp;Core™&nbsp;i9 處&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個執行緒</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>個核心</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>高達<br/></span> <span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>快取記憶體</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "不鎖頻",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "根據您的遊戲風格，找出最合適的 INTEL® CORE™ 處理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器"
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 處&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "3A遊戲",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "電玩競技",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "開戰、直播、錄製，效能不須妥協",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "適合遊戲的最佳第 9 代 Intel® Core™ 處理器",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：4 <br/>執行緒：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：6<br/> 執行緒：6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>4.9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>時脈速度<sup>2</sup>：最&#65279;高&#65279;可&#65279;達 <br/>5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>核心：8 <br/>執行緒：16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "專為新一代遊戲體驗打&#65279;造",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "第 12 代 INTEL® CORE™ i5 處理器"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "第 12 代 INTEL® CORE™ i7 處理器"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i9 處&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "第 12 代 INTEL® CORE™ i9 處理器"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>個核心</span></span>",
                    "tr_badgePreTitle": "[p_core] 個 Performance-core",
                    "tr_badgeSubTitle": "[e_core] 個 Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>個執行緒</span></span>",
                    "tr_badgePreTitle": "P-core 具備 [p_thread] 條執行緒",
                    "tr_badgeSubTitle": "E-core 具備 [e_thread] 條執行緒"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "遊戲戰場與工作站一機搞定",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "第 12 代 INTEL® CORE™ i7 處理器"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i9 處&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "第 12 代 INTEL® CORE™ i9 處理器"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>個核心</span></span>",
                    "tr_badgePreTitle": "[p_core] 個 Performance-core",
                    "tr_badgeSubTitle": "[e_core] 個 Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>個執行緒</span></span>",
                    "tr_badgePreTitle": "P-core 具備 [p_thread] 條執行緒",
                    "tr_badgeSubTitle": "E-core 具備 [e_thread] 條執行緒"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "在您最需要時提供可靠效能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™ i3 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 12 代 INTEL®&nbsp;CORE™ i3 處&#65279;理&#65279;器",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "在您最需要的時刻提&#xfeff;升&#xfeff;效&#xfeff;能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 12 代 INTEL® CORE™ i5 處理器",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "在您最需要的時刻提&#xfeff;升&#xfeff;效&#xfeff;能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 12 代 INTEL® CORE™ i5 處理器",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "在您最需要的時刻提供卓越效能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 12 代 INTEL® CORE™ i7 處理器",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "在您最需要的時刻提供卓越效能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 12 代 INTEL® CORE™ i7 處理器",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "有了 Intel® Evo™ 筆記型電腦，您可獲得效能、內建智慧，安心進行視訊會談，並可提升網路速度以及延長電池續航力等。",
                "tr_title": "在您最需要的時刻提&#xfeff;升&#xfeff;效&#xfeff;能",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 12 代 INTEL® CORE™ i5 處理器",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "有了 Intel® Evo™ 筆記型電腦，您可獲得效能、內建智慧，安心進行視訊會談，並可提升網路速度以及延長電池續航力等。",
                "tr_title": "在您最需要的時刻提供卓越效能",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 12 代 INTEL® CORE™ i7 處理器",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "有了 Intel® Evo™ 筆記型電腦，您可獲得效能、內建智慧，安心進行視訊會談，並可提升網路速度以及延長電池續航力等。",
                "tr_title": "頂級筆記型電腦中的極致效能",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i9 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 12 代 INTEL® CORE™ i9 處理器",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "採用 Intel® 渦輪加速技術 2.0 最&#65279;高&#65279;可&#65279;達 [max_turbo_frequency] GHz",
                "tr_title": "專為商務打造",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 12 代 INTEL® CORE™ i5 處理器",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "比較這些處理器",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "抑制噪音，並模糊您的背景",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "自動調整效能，優先處理您最常做的事",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "體驗流暢的高品質網路通話",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "隨拆即用基於硬體的安全防護",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "提供遠端管理裝置功能",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "自動將您的 Wi-Fi 效能最佳化<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "透過資料驅動的應用程式讓您如虎添翼",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "生產力最高，效能增強",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "採用 Intel® 渦輪加速技術 2.0 最&#65279;高&#65279;可&#65279;達 [max_turbo_frequency] GHz",
                "tr_title": "賣力運作，讓您集中精神處理業務。",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 12 代 INTEL® CORE™ i7 處理器",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "比較這些處理器",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "抑制噪音，並模糊您的背景",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "自動調整效能，優先處理您最常做的事",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "體驗流暢的高品質網路通話",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "隨拆即用基於硬體的安全防護",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "提供遠端管理裝置功能",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "自動將您的 Wi-Fi 效能最佳化<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "透過資料驅動的應用程式讓您如虎添翼",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "生產力最高，效能增強",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "採用 Intel® 渦輪加速技術 2.0 最&#65279;高&#65279;可&#65279;達 [max_turbo_frequency] GHz",
                "tr_title": "加速業務進展",
                "tr_gen_core_title": "第 12 代 Intel®&nbsp;Core™&nbsp;i9 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 12 代 INTEL® CORE™ i9 處理器",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "比較這些處理器",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "抑制噪音，並模糊您的背景",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "自動調整效能，優先處理您最常做的事",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "體驗流暢的高品質網路通話",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "隨拆即用基於硬體的安全防護",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "提供遠端管理裝置功能",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "自動將您的 Wi-Fi 效能最佳化<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "透過資料驅動的應用程式讓您如虎添翼",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "生產力最高，效能增強",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "專門用來執行更多任務的可靠效能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "第 13 代 Intel®&nbsp;Core™ i3 處&#65279;理&#65279;器",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "致力完成更多任務的實&#xfeff;際&#xfeff;效&#xfeff;能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "第 13 代 Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 13 代 INTEL® CORE™ i5 處理器",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "致力完成更多任務的實&#xfeff;際&#xfeff;效&#xfeff;能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "第 13 代 Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 13 代 INTEL® CORE™ i5 處理器",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "致力完成更多任務的強大效能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "第 13 代 Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 13 代 INTEL® CORE™ i7 處理器",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "致力完成更多任務的強大效能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "第 13 代 Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 13 代 INTEL® CORE™ i7 處理器",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "巔峰效能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "第 13 代 Intel®&nbsp;Core™&nbsp;i9 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 13 代 INTEL® CORE™ i9 處理器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>個核心</span></span>",
                    "tr_badgePreTitle": "[p_core] 個 Performance-core",
                    "tr_badgeSubTitle": "[e_core] 個 Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>個執行緒</span></span>",
                    "tr_badgePreTitle": "P-core 具備 [p_thread] 條執行緒",
                    "tr_badgeSubTitle": "E-core 具備 [e_thread] 條執行緒"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "無縫連接您現有的手機<sup>24</sup>，智慧選取最佳 Wi-Fi，以長效型電池迅速充電。",
                "tr_title": "超越巔峰效能隨&#xfeff;處&#xfeff;提&#xfeff;升&#xfeff;產&#xfeff;能",
                "tr_gen_core_title": "第 13 代 Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 13 代 INTEL® CORE™ i5 處理器",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "無縫連接您現有的手機<sup>24</sup>，智慧選取最佳 Wi-Fi，以長效型電池迅速充電。",
                "tr_title": "超越巔峰效能，同時讓電腦成就更多",
                "tr_gen_core_title": "第 13 代 Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 13 代 INTEL® CORE™ i7 處理器",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "無縫連接您現有的手機<sup>24</sup>，智慧選取最佳 Wi-Fi，以長效型電池迅速充電。",
                "tr_title": "超越巔峰效能，即使是要求最嚴苛的任務也能快速完成",
                "tr_gen_core_title": "第 13 代 Intel®&nbsp;Core™&nbsp;i9 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 13 代 INTEL® CORE™ i9 處理器",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "巔峰效能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "第 13 代 Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "第 13 代 INTEL® CORE™ i5 處理器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "第 13 代 Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "第 13 代 INTEL® CORE™ i7 處理器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "第 13 代 Intel®&nbsp;Core™&nbsp;i9 處&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "第 13 代 INTEL® CORE™ i9 處理器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>個核心</span></span>",
                    "tr_badgePreTitle": "[p_core] 個 Performance-core",
                    "tr_badgeSubTitle": "[e_core] 個 Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>個執行緒</span></span>",
                    "tr_badgePreTitle": "P-core 具備 [p_thread] 條執行緒",
                    "tr_badgeSubTitle": "E-core 具備 [e_thread] 條執行緒"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "巔峰效能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 處理器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 處理器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 處&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 處理器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>個核心</span></span>",
                    "tr_badgePreTitle": "[p_core] 個 Performance-core",
                    "tr_badgeSubTitle": "[e_core] 個 Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>個執行緒</span></span>",
                    "tr_badgePreTitle": "P-core 具備 [p_thread] 條執行緒",
                    "tr_badgeSubTitle": "E-core 具備 [e_thread] 條執行緒"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "專門用來執行更多任務的可靠效能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Intel® Core™ i3 處&#65279;理&#65279;器",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "致力完成更多任務的實&#xfeff;際&#xfeff;效&#xfeff;能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 處理器",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "致力完成更多任務的強大效能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 處理器",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "巔峰效能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 處理器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 處理器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 處&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 處理器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>個核心</span></span>",
                    "tr_badgePreTitle": "[p_core] 個 Performance-core",
                    "tr_badgeSubTitle": "[e_core] 個 Efficient-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>個執行緒</span></span>",
                    "tr_badgePreTitle": "P-core 具備 [p_thread] 條執行緒",
                    "tr_badgeSubTitle": "E-core 具備 [e_thread] 條執行緒"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 處&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "專門用來執行更多任務的可靠效能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 3 處&#65279;理器",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 處&#65279;理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 處&#65279;理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 處&#65279;理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "致力完成更多任務的實&#xfeff;際&#xfeff;效&#xfeff;能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 5 處&#65279;理器",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 5 處&#65279;理器",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 處&#65279;理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 處&#65279;理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 處&#65279;理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "致力完成更多任務的強大效能",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 7 處&#65279;理器",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 7 處理器",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 處&#65279;理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 處&#65279;理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 處&#65279;理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "處理器",
                "tr_title": "遊戲效能不須妥協",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 5 處理器",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 5 處理器",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 7 處理器",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 7 處理器",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 9 處理器",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 9 處理器",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>個核心</span></span>",
                    "tr_badgePreTitle": "[p_core] 個 Performance-core",
                    "tr_badgeSubTitle": "[e_core] 個 Efficient-core",
                    "tr_badgeSubTitle1": "[l_core] LPE-core"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>個執行緒</span></span>",
                    "tr_badgePreTitle": "P-core 具備 [p_thread] 條執行緒",
                    "tr_badgeSubTitle": "E-core 具備 [e_thread] 條執行緒",
                    "tr_badgeSubTitle1": "LPE-core 具備 [l_thread] 條執行緒"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "比較這些處理器",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 5 處理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 7 處理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 9 處理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "遊戲效能不須妥協",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "遊戲效能不須妥協",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "遊戲效能不須妥協",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "遊戲效能不須妥協",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "遊戲效能不須妥協",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "遊戲效能不須妥協",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "小型企業也值得擁有大型的商業解決方案",
                "tr_gen_core_title": "第 13 代 Intel®&nbsp;Core™&nbsp;i5 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 13 代 INTEL® CORE™ i5 處理器",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "比較這些處理器",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "抑制噪音，並模糊您的背景",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "自動調整效能，優先處理您最常做的事",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "體驗流暢的高品質網路通話",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "獲得隨裝即用的硬體型安全防護措施",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "協助保護裝置與資料遭到實體竄改攻擊",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "小型企業也值得擁有大型的商業解決方案",
                "tr_gen_core_title": "第 13 代 Intel®&nbsp;Core™&nbsp;i7 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 13 代 INTEL® CORE™ i7 處理器",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "比較這些處理器",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "抑制噪音，並模糊您的背景",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "自動調整效能，優先處理您最常做的事",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "體驗流暢的高品質網路通話",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "獲得隨裝即用的硬體型安全防護措施",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "協助保護裝置與資料遭到實體竄改攻擊",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "小型企業也值得擁有大型的商業解決方案",
                "tr_gen_core_title": "第 13 代 Intel®&nbsp;Core™&nbsp;i9 處&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第 13 代 INTEL® CORE™ i9 處理器",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "比較這些處理器",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "目前裝置",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "抑制噪音，並模糊您的背景",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "自動調整效能，優先處理您最常做的事",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "體驗流暢的高品質網路通話",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "獲得隨裝即用的硬體型安全防護措施",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "協助保護裝置與資料遭到實體竄改攻擊",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "釋放全新商務 AI 體驗",
                "tr_title": "使用 Intel® Core™ Ultra&nbsp;[XX] 處理器和 Intel&nbsp;vPro® 平台，掌握未來",
                "tr_gen_core_title": "Intel® Core™ Ultra [XX] 處理器",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "使用 Intel 的 AI 電腦推動您的商務成長",
                    "tr_badgeSubTitle": "強大的電腦是商務生產力的核心，為您帶來卓越體驗。Intel&nbsp;vPro® 平台採用全新架構，適時將適合的工作交給合適的引擎，締造優異的節能表現，大幅提升員工執行各種工作負載與應用程式的效率和效能。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "立即擁有多層安全防護功能",
                    "tr_badgeSubTitle": "新興威脅需要採用全方位的安全防護措施。Intel vPro® 平台提供硬體層級的保護和整體堆疊保護，讓您的企業受到更安全的防護。這些可立即用的獨特安全防護措施包括 AI 支援的威脅偵測功能，有助於保護使用者、資料與企業數位安&#xfeff;全&#xfeff;。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "內建工具讓使用者幾乎在任何地方都能維持生產力",
                    "tr_badgeSubTitle": "辦公室無所不在，您的電腦也是 Intel vPro® 平台提供新式硬體型管理功能，協助企業卸下管理電腦設備的重擔、簡化支援流程、提升終端使用者體驗，並且實現兼顧永續發展的運算實務。",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "超越卓越，AI PC 開啟新時代",
                "tr_subtitle1": "搭載 INTEL®&nbsp;CORE™ ULTRA 處理器（系&#xfeff;列&nbsp;2）",
                "tr_subtitle2": "Intel AI PC，讓你創造無限可能",
                "tr_subtitle": "Intel 最高效、安全且高效能的筆記型處理器<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 處理器（系&#xfeff;列&nbsp;2）",
                "titleArray": [
                  {
                    "tr_title": "AI 工具加速創作，讓您<span class='spec-heading'>更能發揮創意</span>"
                  },
                  {
                    "tr_title": "AI 讓您節省工作時間，讓您<span class='spec-heading'>更有生產力</span>"
                  },
                  {
                    "tr_title": "內建資料保護措施，讓您使用<span class='spec-heading'>更安全</span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "超越卓越，AI PC 開啟新時代",
                "tr_subtitle1": "搭載 INTEL®&nbsp;CORE™ ULTRA 處理器（系&#xfeff;列&nbsp;2）",
                "tr_subtitle2": "Intel AI PC，讓你創造無限可能",
                "tr_subtitle": "Intel 最高效、安全且高效能的筆記型處理器<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 處理器（系&#xfeff;列&nbsp;2）",
                "titleArray": [
                  {
                    "tr_title": "AI 工具加速創作，讓您<span class='spec-heading'>更能發揮創意</span>"
                  },
                  {
                    "tr_title": "AI 讓您節省工作時間，讓您<span class='spec-heading'>更有生產力</span>"
                  },
                  {
                    "tr_title": "內建資料保護措施，讓您使用<span class='spec-heading'>更安全</span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "超越卓越，AI PC 開啟新時代",
                "tr_subtitle1": "搭載 INTEL®&nbsp;CORE™ ULTRA 處理器（系&#xfeff;列&nbsp;2）",
                "tr_subtitle2": "Intel AI PC，讓你創造無限可能",
                "tr_subtitle": "Intel 最高效、安全且高效能的筆記型處理器<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 處理器（系&#xfeff;列&nbsp;2）"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "處理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "超越卓越，AI PC 開啟新時代",
                "tr_subtitle1": "搭載 INTEL®&nbsp;CORE™ ULTRA 處理器（系&#xfeff;列&nbsp;2）",
                "tr_subtitle2": "Intel AI PC，讓你創造無限可能",
                "tr_subtitle": "Intel 最高效、安全且高效能的筆記型處理器<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 處理器（系&#xfeff;列&nbsp;2）"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_tileTitle1": "獨立顯示卡",
                "tr_title": "什麼是圖形處理器？",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "圖形處理器或 GPU 負責在螢幕上顯示您所看到的影像。",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "整合式 GPU",
                    "tr_badgeSubTitle": "整合式 GPU 與電腦其他元件使用相同的 RAM，因而更節能。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "獨立 GPU",
                    "tr_badgeSubTitle": "這是最強大的 GPU 等級，因為擁有自己的 RAM，以及專用於建立優異繪圖的其他資源。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "混合式 GPU",
                    "tr_badgeSubTitle": "這是最強大的 GPU 等級，因為擁有自己的 RAM，以及專用於建立優異繪圖的其他資源。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "簡化的相片和影片編輯作業",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "以 4K 超高畫質追劇",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "在暢玩熱門遊戲時享受其中令人驚豔的細節",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "雙重 GPU",
                    "tr_badgeSubTitle": "諸如搭載 Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片的系統，可以利用內建和獨立 GPU 來分配顯示晶片工作負載。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "整合式 GPU",
                    "tr_badgeSubTitle": "整合式 GPU 內建在主處&#65279;理&#65279;器或 CPU 所在的相同晶片內。<br/>這種 GPU 和電腦的其餘組件使用相同的 RAM 與其他資&#65279;源，所以能更有效的運用能源，因此能打造出更輕&#65279;薄&#65279;的裝&#65279;置。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "獨立 GPU",
                    "tr_badgeSubTitle": "這是最強大的 GPU 等級，因為擁有自己的 RAM，以及專用於建立優異繪圖的其他資源。",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "標準",
                    "tr_badgeSubTitle": "最高 4 GB VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "具備競爭力",
                    "tr_badgeSubTitle": "最高 8 GB VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "超強",
                    "tr_badgeSubTitle": "超過 8 GB VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_title": "強大的繪圖效能",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "最強大的圖形處理需要專用於創造鮮豔燦爛、清&#65279;晰銳&#65279;利&#65279;的影&#65279;像與流&#65279;暢動&#65279;畫&#65279;的顯&#65279;示&#65279;卡&#65279;。",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_title": "內建色彩艷麗鮮明的顯示晶片",
                "tr_pretitle": "Intel® Iris® Plus 顯示晶片",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Iris® Plus 顯示晶片讓您沉浸於 4K HDR 影片與 Full HD 遊戲畫面，且無需使用額外顯示卡。",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_title": "令人驚豔的視覺效果，不費吹灰之力的效能",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Graphics 提供革命性的內建顯示晶片效能，支援創作者流暢進行設計和豐富的遊戲體驗。",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_title": "Intel® Iris® Pro 顯示晶片",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_title": "採用 X<sup class='small_text_sup_xe'>e</sup> 系統架構的 Intel® UHD 顯示晶片",
                "tr_pretitle": "身歷其境的視覺體驗",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "體驗簡化的相片和影片編輯、 以 4K 超高畫質追劇，或在暢玩熱門遊戲時享受其中令人驚豔的細節。",
                "tr_photo_text": "簡化的相片和影片編輯作業",
                "tr_video_text": "以 4K 超高畫質追劇",
                "tr_game_text": "在暢玩熱門遊戲時享受其中令人驚豔的細節",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "不僅是畫面漂亮",
                "tr_subtitle": "與處理器（即 CPU）和顯示卡（即 GPU）密切配合，有助於判斷遊戲執行效能與顯示效果。VRAM 亦即視訊記憶體，是專為締造影像而生，讓您迅速掌握 GPU 的現況。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "強大的遊戲顯示晶片",
                "tr_subtitle": "最強大的繪圖處理需要一個獨立的顯示卡，以專注於創造美麗、清晰的影像與流暢動畫",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "強大的遊戲顯示晶片",
                "tr_subtitle": "最強大的繪圖處理需要一個獨立的顯示卡，以專注於創造美麗、清晰的影像與流暢動畫",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "不僅是畫面漂亮",
                "tr_subtitle": "與處理器（即 CPU）和顯示卡（即 GPU）密切配合，有助於判斷遊戲執行效能與顯示效果。VRAM 亦即視訊記憶體，是專為締造影像而生，讓您迅速掌握 GPU 的現況。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_title": "強大的繪圖效能",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "最強大的圖形處理需要專用於創造鮮豔燦爛、清&#65279;晰銳&#65279;利&#65279;的影&#65279;像與流&#65279;暢動&#65279;畫&#65279;的顯&#65279;示&#65279;卡&#65279;。",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "強大的遊戲顯示晶片",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "最強大的圖形處理需要專用於創造鮮豔燦爛、清&#65279;晰銳&#65279;利&#65279;的影&#65279;像與流&#65279;暢動&#65279;畫&#65279;的顯&#65279;示&#65279;卡&#65279;。",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "最強大的繪圖處理需要一個獨立的顯示卡，以專注於創造美麗、清晰的影像與流暢動畫",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "提供充足的顯示功能，符合遊戲的最低需求。",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "適合追求電競級遊戲的高階遊戲玩家",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "適合同時利用電腦從事大量內容創作的進階遊戲玩家",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "活靈活現的影片與身歷其境的遊戲",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "透過 Intel® HD 顯示晶片 讓您的高解析度螢幕發揮最佳效能，<br/>並享受豐富的多媒體體驗和內建影像處理。",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "呈現活靈活現的影片和影像",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "為串流影片及相片帶來豐富細節與明豔色彩。",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_title": "令人驚豔的創作與遊&#65279;戲",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "從身歷其境的娛樂體驗和進階內容創作，一直到主流遊戲，Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片賦予輕薄型電腦劃時代的顯示晶片效能。",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_pretitle": "強大的遊戲顯示晶片",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "最強大的繪圖處理需要一個獨立的顯示卡，以專注於創造美麗、清晰的影像與流暢動畫",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_title": "盡情揮灑創意",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX 顯示晶片",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "利用處理器內建顯示卡與 Intel® 首款獨立顯示卡引擎組合的威力，順暢完成創作專案，並在琳瑯滿目的遊戲中身歷其境。",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX 顯示晶片",
                "tr_pretitle": "強大的遊戲顯示晶片",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "最強大的繪圖處理需要一個獨立的顯示卡，以專注於創造美麗、清晰的影像與流暢動畫",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "顯示晶片",
                "tr_title": "內建華麗的影&#65279;像&#65279;效&#65279;果",
                "tr_subtitle": "享受具有令人驚豔的細節與清晰畫面的遊戲、電&#65279;影和網&#65279;路&#65279;影&#65279;片&#65279;。",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示晶片",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "顯示晶片",
                    "tr_specText": "加速您的工作流程"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "顯示晶片",
                    "tr_specText": "遊戲體驗全面強化"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "顯示晶片",
                    "tr_specText": "遊戲體驗全面強化"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "顯示晶片",
                    "tr_specText": "遊戲體驗全面升級"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "盡情揮灑創意",
                    "tr_specText": "利用最全方位的媒體功能，例如<strong>業界首創的 AV1 硬體編碼</strong>，以及 AI 加速的內容創作，無論身在何處皆可加速生產力。<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "盡情揮灑創意",
                    "tr_specText": "利用最全方位的媒體功能，例如<strong>業界首創的 AV1 硬體編碼</strong>，以及 AI 加速的內容創作，加速您的生產力。<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "盡情揮灑創意",
                    "tr_specText": "利用<strong>業界首創的 AV1 硬體編碼</strong>，以及先進的 <strong>X<sup>e</sup> 媒體引擎，體驗尖端的 AI 加速內容創作。</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "盡情揮灑創意",
                    "tr_specText": "利用<strong>業界首創的 AV1 硬體編碼</strong>，以及先進的 <strong>X<sup>e</sup> 媒體引擎，體驗尖端的 AI 加速內容創作。</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "盡情揮灑創意",
                    "tr_specText": "利用<strong>業界首創的 AV1 硬體編碼</strong>，以及先進的 <strong>X<sup>e</sup> 媒體引擎，體驗尖端的 AI 加速內容創作。</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "可攜性與效能同時搞定",
                    "tr_specText": "隨時隨地暢玩最新遊戲，借助新世代遊戲功能獲得增強的效能與逼真度。<br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling)： </strong>Intel® AI 加強的升級技術，可締造更高的效能，以及順暢的遊戲體驗。<br/><br/>支援 <strong>DirectX* 12 Ultimate： </strong>利用各種繪圖技術，例如光線追蹤與可變速率著色，讓遊戲的真實感更上層樓。"
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "掌控您的遊戲",
                    "tr_specText": "<strong>Intel® Arc™ 控制台</strong>採用現代化的直覺式配置，可讓您的體驗更臻完善。獲得順暢的驅動程式更新、將遊戲體驗最佳化，以及其他內&#xfeff;容。"
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "掌控您的遊戲",
                    "tr_specText": "<strong>Intel® Arc™ 控制台</strong>採用現代化的直覺式配置，可讓您的體驗更臻完善。獲得順暢的驅動程式更新、將遊戲體驗最佳化，以及其他內&#xfeff;容。"
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "掌控您的遊戲",
                    "tr_specText": "利用 <strong>Intel® Arc™ 控制台</strong>軟體，獲得順暢的驅動程式更新，並且將許多您喜愛的遊戲最佳化。"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "極致優化您的 INTEL® 平台",
                    "tr_specText": "搭配相容的 Intel® Core™ 處理器，讓 <strong>Intel® Deep Link 技術</strong>發揮得淋漓盡致，為您加強創作、遊戲與直播體驗。"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "極致優化您的 INTEL® 系&#xfeff;統",
                    "tr_specText": "搭配相容的 Intel® Core™ 處理器，讓 <strong>Intel® Deep Link 技術</strong>發揮得淋漓盡致，為您加強創作、遊戲與直播體驗。"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "極致優化您的 INTEL® 平台",
                    "tr_specText": "搭配相容的 Intel® Core™ 處理器，讓 <strong>Intel® Deep Link 技術</strong>發揮得淋漓盡致，為您加強創作、遊戲與直播體驗。"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "極致優化您的 INTEL® 平台",
                    "tr_specText": "搭配相容的 Intel® Core™ 處理器，讓 <strong>Intel® Deep Link 技術</strong>發揮得淋漓盡致，為您加強創作、遊戲與直播體驗。"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "極致優化您的 INTEL® 系&#xfeff;統",
                    "tr_specText": "搭配相容的 Intel® Core™ 處理器，讓 <strong>Intel® Deep Link 技術</strong>發揮得淋漓盡致，為您加強創作、遊戲與直播體驗。"
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "成為矚目焦點",
                    "tr_specText": "透過 <strong>Intel® Arc™ 控制台</strong>提供的高級直播技術進行傳播。"
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "成為矚目焦點",
                    "tr_specText": "透過 <strong>Intel® Arc™ 控制台</strong>提供的高級直播技術進行傳播。"
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "體驗身歷其境的高效能遊戲",
                    "tr_specText": "以高解析度與高重新整理速率遊玩最新的 3A 遊戲，優先提供最佳效&#xfeff;能。<br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling)： </strong>Intel® AI 加強的升級技術，可締造更高的效能，以及順暢的遊戲體驗。<br/><br/>支援 <strong>DirectX* 12 Ultimate： </strong>利用各種繪圖技術，例如光線追蹤與可變速率著色，讓遊戲的真實感更上層樓。"
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "體驗身歷其境的高效能遊戲",
                    "tr_specText": "以高解析度與高重新整理速率遊玩最新的 3A 遊戲，優先提供最佳效&#xfeff;能。<br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling)： </strong>Intel® AI 加強的升級技術，可締造更高的效能，以及順暢的遊戲體驗。<br/><br/>支援 <strong>DirectX* 12 Ultimate： </strong>利用各種繪圖技術，例如光線追蹤與可變速率著色，讓遊戲的真實感更上層樓。"
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "升級您的遊戲體驗",
                    "tr_specText": "利用以下技術，讓遊戲體驗更上層樓：<br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling)： </strong>Intel® AI 加強的升級技術，可締造更高的效能，以及順暢的遊戲體驗。<br/><br/>支援 <strong>DirectX* 12 Ultimate： </strong>利用各種繪圖技術，例如光線追蹤與可變速率著色，讓遊戲的真實感更上層樓。"
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "體驗進階的快節奏遊戲",
                    "tr_specText": "順暢遊玩多款熱門 3A 遊戲，從高強度電競射擊遊戲到快節奏的 RTS 熱門遊戲都能享受高重新整理率。<br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling)： </strong>Intel® AI 加強的升級技術，可締造更高的效能，以及順暢的遊戲體驗。<br/><br/>支援 <strong>DirectX* 12 Ultimate： </strong>利用各種繪圖技術，例如光線追蹤與可變速率著色，讓遊戲的真實感更上層樓。"
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "體驗身歷其境的高效能遊戲",
                    "tr_specText": "以高解析度與高重新整理速率遊玩最新的 3A 遊戲，優先提供最佳效&#xfeff;能。<br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling)： </strong>Intel® AI 加強的升級技術，可締造更高的效能，以及順暢的遊戲體驗。<br/><br/>支援 <strong>DirectX* 12 Ultimate： </strong>利用各種繪圖技術，例如光線追蹤與可變速率著色，讓遊戲的真實感更上層樓。"
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "顯示晶片",
                    "tr_specText": "全系列顯示晶片，滿足多種需求"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "加速幀數、加速遊戲",
                    "tr_specText": "Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling 技術（X<sup class='small_text_sup_xe'>e</sup>SS）利用 AI 加強的畫質提升功能，讓遊戲進入全新境界，實現最高效能與高影像逼真度。X<sup class='small_text_sup_xe'>e</sup>SS 針對 Intel® Arc™ 顯示晶片產品最佳化，可讓您充分利用 Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions（Intel® XMX）硬體加速功能。"
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "終極視覺效果問世",
                    "tr_specText": "所有的 Intel® Arc™ 顯示晶片均全面支援 DirectX* 12 Ultimate，透過新一代遊戲的基礎，即光線追蹤、可變速率著色、網格著色和取樣工具意見回饋等最新圖形技術，您將享受令人驚豔、身歷其境的遊戲體驗。"
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "流暢創作相片、影片和媒體",
                    "tr_specText": "超先進的媒體引擎經過 AI 增強，採用 Intel® 技術加速，實現豐富的數位內容創作，讓您盡情揮灑想像力，令觀眾目眩神迷。利用支援目前所有主流媒體格式的顯示晶片技術，創作精彩的內容，並且以最先進的 AV1 影片編碼功能維持最新狀態。"
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "體驗尖端的 AI 創作與遊戲",
                    "tr_specText": "Intel® Arc™ 顯示晶片釋放強大的 AI 功能，從進階創作到身歷其境的遊戲無所不包。充分發揮現今進階創作軟體的效益，包括文字生成影像與 AI 加速影片編輯。此外，透過 AI 加速的 X<sup class='small_text_sup_xe'>e</sup>SS 畫質提升功能，享受高解析度遊戲體驗。"
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "顯示晶片",
                    "tr_specText": "專業顯示晶片從&#xfeff;這&#xfeff;裡&#xfeff;開&#xfeff;始"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "非常適合出門在外的創意人士、設計師和工程師",
                    "tr_specText": "適用於特定的 Intel® Core™ Ultra 處理器 H 系列，內建 Intel® Arc™ Pro 顯示晶片，利用獨立軟體廠商（ISV）認證提升 2 倍的顯示晶片效能，提高可靠性，並提供高效的 AI 和光線追蹤硬體加速，可在外出時創作令人驚豔的內容。<br/><br/><span class='expandable_heading'>軟體認證<br/></span><br/>多年來，Intel 與數百家軟體公司密切合作，這種無與倫比的體驗融入了我們的 Intel® Arc™ Pro A 系列顯示卡。認證對您而言和對我們一樣重要。"
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "讓 AI 得以實現",
                    "tr_specText": "這個 GPU 系列包含各式各樣優異的技術，包括在您需要時支援內建專屬 AI 加速的相容 AI 工具。"
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "無須高價的光線追蹤",
                    "tr_specText": "顯示卡除了在顯示器顯示像素，還必須能夠加速創作美麗的影像。Intel® Arc™ Pro A 系列顯示晶片內建的光線追蹤硬體技術，可讓相容的軟體開始探索這些全新的專業工作流程。一般認為光線追蹤只有昂貴的顯示晶片才能提供，但 Intel 最新的專業顯示晶片系列破除了這個想法。"
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "為創作者提供身歷其境的顯示畫面",
                    "tr_specText": "所有的 Intel® Arc™ 顯示晶片均全面支援 DirectX* 12 Ultimate，透過新一代遊戲的基礎，即光線追蹤、可變速率著色、網格著色和取樣工具意見回饋等最新圖形技術，您將享受令人驚豔、身歷其境的遊戲體驗。"
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "動態最佳化影像",
                    "tr_specText": "透過 Pro GPU 專用的硬體解碼，Dolby Vision* 有助於為相容的顯示器增加深度、更銳利的對比，以及更豐富的色彩。Dolby Vision* 根據您的服務、顯示裝置與平台，以動態方式將影像品質最佳化，時時刻刻締造令人驚豔的視覺效果，釋放 HDR 技術的完整潛力。"
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "經專業驗證的軟體",
                    "tr_specText": "Intel 多年來與數百家軟體公司密切合作，並且在專業的 Intel® Arc™ Pro A 系列顯示晶片系列融入這項無與倫比的經驗。認證和軟體工作流程資格判斷對您而言和對我們一樣重要。"
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "無須高價的光線追蹤",
                    "tr_specText": "顯示卡除了在顯示器顯示像素，還必須能夠加速創作美麗的影像。Intel® Arc™ Pro A 系列顯示晶片內建的光線追蹤硬體技術，可讓相容的軟體開始探索這些全新的專業工作流程。一般認為光線追蹤只有昂貴的顯示晶片才能提供，但 Intel 最新的專業顯示晶片系列破除了這個想法。"
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "單一小巧的筆記型 GPU 能支援多台大型顯示器",
                    "tr_specText": "筆記型電腦適用的 Intel® Arc™ Pro 顯示晶片最多支援四部超大顯示器，可增強工作流程效率。另外還新增高動態範圍（HDR）支援，下一套適合在社群媒體上展示的居家或辦公室多顯示器裝備便一應俱全。"
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "完整記憶體頻寬潛力",
                    "tr_specText": "顯示卡的頻寬是工作站的考量重點。頻寬不對就可能拖慢專業工作流程，因此我們確保 Intel® Arc™ Pro A 系列 GPU 無須犧牲回溯相容性即可支援新式 PCIe* 4.0。顯示晶片記憶體獲得高頻寬速度進一步強化，因此軟體存取專案資料的速度甚至更快。"
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "每幅畫面栩栩如生",
                    "tr_specText": "<span class='expandable_heading'>Intel 提升 AI 效能<br/></span><br/> Intel® X<sup class='small_text_sup_xe'>e</sup>SS 是一種 AI 增強的擴充技術，在不犧牲每幅畫面的美感之餘提升您的效能，讓您以應有的方式體驗遊戲。"
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "反應迅速、<br/>引人入勝、<br/>細緻逼真",
                    "tr_specText": "更逼真的天地等您探訪。透過內建光線追蹤，您可體驗一切，包括逼真的陰影和反射乃至於精湛的照明。換句話説，這是您不容錯過的視覺遊戲體驗。"
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "最精彩的遊戲體驗，完整分享不漏拍",
                    "tr_specText": "為您的觀眾提供他們應享的流暢遊戲體驗。透過 Intel® Arc™ 顯示晶片的硬體加速 AV1 編碼功能，您的一舉一動都會令他們著迷。位元速率相同，視覺品質更優異，意味著您可以用更少的頻寬實現最佳直播體驗。"
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "歡迎所有創作者",
                    "tr_specText": "全新增強的 AI 體驗俯拾即是。透過 Intel® Arc™ A 系列顯示晶片內嵌的先進 AI 加速器，能在許多應用程式中將更多時間用於創作，減少等待的時間。<br/><br/>將影片創作提升至全新境界，並利用 Intel® Arc™ 顯示晶片內建的先進 AV1 硬體功能，以高品質、高儲存效率的 AV1 影片引領潮流。"
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "作業系統",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "完成工作應具備的生產力工具",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "纖薄、輕巧也耐用，並擁有絕佳效能，一應俱全。有了絕佳的電池續航力、高解析度的觸控螢幕*，以及最新的固態硬碟 (SSD)，您將能在 Windows 10 電腦找到所需的功能。",
                "tr_noteSection": "*需硬體支援。",
                "titleArray": [
                  {
                    "tr_title": "化繁為簡",
                    "tr_subtitle": "Windows 10 新世代裝置提供您更持久的電池續航力，播放影片平均可達 8 小時以上。**",
                    "tr_noteSection": "**電池續航力會依裝置、設定、使用方式和其他因素不同而有明顯差異。"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "作業系統",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "完成工作應具備的生產力工具",
                "tr_title": "Windows* 10",
                "tr_subtitle": "纖薄、輕巧也耐用，並擁有絕佳效能，一應俱全。有了絕佳的電池續航力、高解析度的觸控螢幕*，以及最新的固態硬碟 (SSD)，您將能在 Windows 10 電腦找到所需的功能。",
                "tr_noteSection": "*需硬體支援。",
                "titleArray": [
                  {
                    "tr_title": "化繁為簡",
                    "tr_subtitle": "Windows 10 新世代裝置提供您更持久的電池續航力，播放影片平均可達 8 小時以上。**",
                    "tr_noteSection": "**電池續航力會依裝置、設定、使用方式和其他因素不同而有明顯差異。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "作業系統",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "一切從簡，提升安&#65279;全&#65279;性與效能",
                "tr_pretitle": "Windows* 10 S 模式",
                "tr_subtitle": "在 Windows* 10 中以 S 模式運行時，您將獲得額外的保護層，使您可以因裝置安全而放心地工作、玩耍和探索。"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "作業系統",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "一切從簡，提升安&#65279;全&#65279;性與效能",
                "tr_title": "Windows* 10 S 模式",
                "tr_subtitle": "在 Windows* 10 中以 S 模式運行時，您將獲得額外的保護層，使您可以因裝置安全而放心地工作、玩耍和探索。"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "作業系統",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "成就非凡",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "效能、價格、選項眾多。從創新的二合一筆記型電腦到輕薄筆記型電腦、觸控螢幕、可拆鍵盤，無論您的預算或風格為何，都能找到一款合適的 Windows* PC 來搭配。"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "作業系統",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "成就非凡",
                "tr_title": "Windows*",
                "tr_subtitle": "效能、價格、選項眾多。從創新的二合一筆記型電腦到輕薄筆記型電腦、觸控螢幕、可拆鍵盤，無論您的預算或風格為何，都能找到一款合適的 Windows* PC 來搭配。"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "作業系統",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "作業系統",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "記憶體",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "您需要多少 RAM？",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "日常：2 GB 到 8 GB RAM",
                    "tr_badgeSubTitle": "適合主要使用文書處理與網頁瀏覽，一次只執行一個程式的使用者。"
                  },
                  {
                    "tr_badgeTitle": "強大：8 GB 到 16 GB RAM",
                    "tr_badgeSubTitle": "更多 RAM 能使應用程式加快執行速度，並且能同時執行更多應用程式。"
                  },
                  {
                    "tr_badgeTitle": "進階：超過 16 GB RAM",
                    "tr_badgeSubTitle": "如果您對遊戲、照片或影片編輯或 3D 模型製作感興趣，您將需要額外的 RAM 來處理這些耗費記憶體的程式。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "記憶體",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM 以進行日&#65279;常多工作業",
                "tr_subtitle": "您的記憶體將能順暢載入並執行基本應用程式，例如文字處理與網頁瀏覽。",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "記憶體",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM 以進行強&#65279;大&#65279;的多工作業",
                "tr_subtitle": "如果您同時使用多個程式，[memory_total] GB 記憶體可讓您輕鬆在程式間進行切換。",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "記憶體",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM 以進行進階多工作業",
                "tr_subtitle": "您將擁有足夠的記憶體以同時執行數個耗費記憶體的程式，且不會拖慢電腦速度。",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "記憶體",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1] GB 記憶體 = [Value2]&nbsp;GB Intel® Optane™ Memory +&nbsp;[Value3]&nbsp;GB&nbsp;RAM",
                "tr_subtitle": "這項記憶體組合的解決方案提供您 Intel® Optane™ memory 的加速硬碟效能，加上反應更敏捷的 RAM。",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>記憶體</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ Memory",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "更短的等待時間<br/>更多您喜愛的功能",
                "tr_subtitle": "Intel® Optane™ memory 搭配系統記憶體運作時，存取各項常用程式的速度更快，需要時，程式可以隨即啟動載入。",
                "tr_pretitle": "[XX] GB Intel® Optane™ Memory",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "記憶體對電腦有何用處？"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "內容創作更快速",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "遊戲反應能力更敏捷",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "多工作業更輕鬆",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "開啟程式更迅速",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ Memory",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>加速您的遊戲</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ Memory",
                "tr_pretitle1": "[XX] GB Intel® Optane™ memory 能&#65279;加&#65279;速您&#65279;的遊&#65279;戲&#65279;體&#65279;驗",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "搭載配備 Intel® Optane™ memory 的 [optane_legal_processor_name] 處理器，從資料磁碟機<br/>上啟動遊戲的速度最高提升 <br/><span class=\"optane-game\">98%</span><sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "搭載配備 Intel® Optane™ memory 的 [optane_legal_processor_name] 處理器，從資料磁碟機<br/>載入升級的速度最高提升 <br/><span class=\"optane-game\">4.1 倍</span><sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Intel® Optane™ Memory",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>加速您的遊戲</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ Memory",
                "tr_pretitle1": "[XX] GB Intel® Optane™ memory 能&#65279;加&#65279;速您&#65279;的遊&#65279;戲&#65279;體&#65279;驗",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "搭載配備 Intel® Optane™ memory 的 [optane_legal_processor_name] 處理器，從資料磁碟機<br/>上啟動遊戲的速度最高提升 <br/><span class=\"optane-game\">98%</span><sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "搭載配備 Intel® Optane™ memory 的 [optane_legal_processor_name] 處理器，從資料磁碟機<br/>載入升級的速度最高提升 <br/><span class=\"optane-game\">4.1 倍</span><sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "記憶體",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1] GB 記憶體 = [Value2]&nbsp;GB Intel® Optane™ Memory +&nbsp;[Value3]&nbsp;GB&nbsp;RAM",
                "tr_subtitle": "這項記憶體組合的解決方案提供您 Intel® Optane™ memory 的加速硬碟效能，加上反應更敏捷的 RAM。",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB 的 RAM 進行多工作業",
                    "tr_subtitle": "如果您同時使用多個程式，[memory_total] GB 記憶體可讓您輕鬆在程式間進行切換。"
                  },
                  {
                    "tr_title": "適合日常工作",
                    "tr_subtitle": "您的記憶體將能順暢載入並執行基本應用程式，例如文字處理與網頁瀏覽。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "記憶體",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB RAM",
                "tr_pretitle": "支援更多多工處理",
                "tr_subtitle": "RAM 讓處&#65279;理&#65279;器能存取程式檔案、讓您的 CPU 能更快存取這些內容，並同時進行多個任務。",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "標準記憶體",
                    "tr_badgeSubTitle": "高達 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "進階記憶體",
                    "tr_badgeSubTitle": "超過 8 GB，最高 16 GB 的 RAM"
                  },
                  {
                    "tr_badgeTitle": "菁英級記憶體",
                    "tr_badgeSubTitle": "超過 16 GB RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "記憶體",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB 可支援更&#65279;多多&#65279;工&#65279;作&#65279;業",
                "tr_pretitle": "支援更多多工處理",
                "tr_subtitle": "RAM 讓處&#65279;理&#65279;器能存取程式檔案、讓您的 CPU 能更快存取這些內容，並同時進行多個任務。",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "標準記憶體",
                    "tr_badgeSubTitle": "高達 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "進階記憶體",
                    "tr_badgeSubTitle": "超過 8 GB，最高 16 GB 的 RAM"
                  },
                  {
                    "tr_badgeTitle": "菁英級記憶體",
                    "tr_badgeSubTitle": "超過 16 GB RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "比較這些記憶體",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "標準",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "進階",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "精英",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "記憶體",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "比較這些記憶體",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "比較這些記憶體",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "目前裝置",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "每天",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "強大",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "進階",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "儲存裝置",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "您的電腦能儲存多少？",
                "tr_subtitle": "這不必猜測。我們會告訴您這部電腦實際可儲存的內容。",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>建立一個檔案庫包含</span><br/><span class=\"storage-standard\">[num_songs] 首歌曲<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>以每首歌曲平均 5 MB 為計算基準",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>建立一個檔案庫包含</span><br/><span class=\"storage-standard\">[num_games] 款遊戲<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>以每款遊戲平均 40 GB 為計算基準",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>儲存總計</span><br/><span class=\"storage-standard\">[num_videos] 支影片<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>以每部影片平均 5 GB 為計算基準",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>拍攝並儲存</span><br/><span class=\"storage-standard\">[num_photos] 張相片<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>以每張相片平均 4 MB 為計算基準",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "[capacity] GB HDD"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "[capacity] GB 固態硬碟"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "[total_optane_memory] GB Intel® Optane™ Memory + [convertedSSD] GB SSD"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "儲存空間",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "[capacity] GB SSD 儲存裝置",
                "tr_preTitle1": "[capacity] TB SSD 儲存裝置",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "此款個人電腦配備 [capacity] GB 儲存空間的 SSD。這代表您可以儲存大量的相片、影片與程式，且存取它們的速度會比使用傳統硬碟機還來得快。",
                    "tr_title": "任何資料都能在您的電腦上適得其所"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "此款個人電腦配備 [capacity] TB 儲存空間的 SSD。這代表您可以儲存大量的相片、影片與程式，且存取它們的速度會比使用傳統硬碟機還來得快。",
                    "tr_title": "擁有令人驚豔的儲存空間"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>儲存空間</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>儲存空間</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "儲存空間",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "[capacity] GB HDD 儲存裝置",
                "tr_preTitle1": "[capacity] TB HDD 儲存裝置",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "傳統硬碟是想在個人電腦上擁有大量儲存空間者的好選擇。",
                    "tr_title": "任何資料都能在您的電腦上適得其所"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "傳統硬碟是想在個人電腦上擁有大量儲存空間者的好選擇。",
                    "tr_title": "擁有令人驚豔的儲存空間"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>儲存空間</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "儲存空間",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "您的電腦能儲存多少？",
                "tr_subtitle": "這不必猜測。我們會告訴您這部電腦實際可儲存的內容。",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB HDD"
                  },
                  {
                    "tr_title": "[XX] GB SSD"
                  },
                  {
                    "tr_title": "透過 [optane_memory_size] GB Intel® Optane™ memory 加&#65279;速&nbsp;[total_capacity]&nbsp;GB&nbsp;Intel®&nbsp;SSD"
                  },
                  {
                    "tr_title": "[optane_memory_size] GB Intel® Optane™ memory 加&#65279;速&#65279;了&nbsp;[total_capacity]&nbsp;TB&nbsp;Intel®&nbsp;SSD"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD] GB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD] TB HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>儲存空間</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>儲存空間</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>儲存空間</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>儲存空間</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>儲存空間</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>儲存空間</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "儲存空間",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "[convertedSSD] GB SSD 儲存裝置 + [convertedHDD] GB HDD",
                "tr_preTitle1": "[convertedSSD] TB SSD 儲存裝置 + [convertedHDD] GB HDD",
                "tr_preTitle2": "[convertedSSD] GB SSD 儲存裝置 + [convertedHDD] TB HDD",
                "tr_preTitle3": "[convertedSSD] TB SSD 儲存裝置 + [convertedHDD] TB HDD",
                "titleArray": [
                  {
                    "tr_title": "任何資料都能在您的電腦上適得其所",
                    "tr_title1": "擁有令人驚豔的儲存空間",
                    "tr_subtitle": "此款個人電腦搭載 [convertedSSD] GB 儲存空間的 SSD，以及 [convertedHDD] GB 傳統硬碟。這能讓您不只擁有超大容量，也能獲得快速儲存效能。",
                    "tr_subtitle1": "此款個人電腦搭載 [convertedSSD] TB 儲存空間的 SSD，以及 [convertedHDD] GB 傳統硬碟。這能讓您不只擁有超大容量，也能獲得快速儲存效能。",
                    "tr_subtitle2": "此款個人電腦搭載 [convertedSSD] GB 儲存空間的 SSD，以及 [convertedHDD] TB 傳統硬碟。這能讓您不只擁有超大容量，也能獲得快速儲存效能。",
                    "tr_subtitle3": "此款個人電腦搭載 [convertedSSD] TB 儲存空間的 SSD，以及 [convertedHDD] TB 傳統硬碟。這能讓您不只擁有超大容量，也能獲得快速儲存效能。"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>儲存空間</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>儲存空間</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "儲存空間",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "透過 Intel® Optane™ memory 搭配固態硬碟，加速存取高容量儲存裝置。",
                "tr_title": "歡迎大型檔案",
                "tr_pretitle": "透過 [optane_memory_size] GB Intel® Optane™ memory 加&#65279;速&nbsp;[total_capacity]&nbsp;GB&nbsp;Intel®&nbsp;SSD",
                "tr_pretitle1": "[optane_memory_size] GB Intel® Optane™ memory 加&#65279;速&#65279;了&nbsp;[total_capacity]&nbsp;TB&nbsp;Intel®&nbsp;SSD",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "這是一套率先結合 Intel® Optane™ memory 和 SSD 於單一晶片的革命性儲存解決方案。以下是它能為您帶來的效益：",
                    "tr_title": "什麼是 INTEL® OPTANE™ MEMORY 搭配 SSD？"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "快速開啟您需要的一切",
                    "tr_badgeSubTitle": "透過預先載入常用檔案和程式，您可縮短等候時間，將更多時間用來揮灑創意。",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "大容量加上高速度",
                    "tr_badgeSubTitle": "您可同時擁有儲存媒體檔案所需的空間，以及 SSD 的快速效能。",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "隨著使用時間能提供更快反應速度",
                    "tr_badgeSubTitle": "搭載 SSD 的 Intel® Optane™ Memory 能學習您的工作方式，提升您電腦的運作效率。",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "儲存空間",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "讓您的所有媒體觸手可及，且擁有大量可靠的儲存空間。",
                "tr_title": "能儲存您所有照片、影&#65279;片&#65279;與歌曲的空間"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示器",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] 萬像素，任您隨意滑動、隨意縮放、驚豔四座",
                "tr_subtitle": "透過直覺式的掌控與更清晰生動的細節，像使用智慧型手機那樣與電腦互動。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal] 吋<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "解析度：[screenResolutionX]x[screenResolutionY]",
                    "tr_badgePreTitle": "像素：[screenResolution] 萬"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] 吋顯示器",
                    "tr_badgeSubTitle": "螢幕高度：[screenHeight] 吋",
                    "tr_badgePreTitle": "螢幕寬度：[YY] 吋"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "觸控式螢幕顯示器",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示器",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "沉醉在 [screenResolution] 萬像素的大千世界中無法自拔",
                "tr_subtitle": "像素就是一切。像素越高，影像越清晰生動。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal] 吋<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "解析度：[screenResolutionX]x[screenResolutionY]",
                    "tr_badgePreTitle": "像素：[screenResolution] 萬"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] 吋顯示器",
                    "tr_badgeSubTitle": "螢幕高度：[screenHeight] 吋",
                    "tr_badgePreTitle": "螢幕寬度：[YY] 吋"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示器",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] 吋 <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "電玩遊戲的靈魂之&#xfeff;窗",
                "tr_subtitle": "就像電視一樣，遊戲顯示器最主要的考量是尺寸和解析度。",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "解析度：[screenResolutionX]x[screenResolutionY]",
                    "tr_badgePreTitle": "像素：[screenResolution] 萬"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] 吋顯示器",
                    "tr_badgeSubTitle": "螢幕高度：[screenHeight] 吋",
                    "tr_badgePreTitle": "螢幕寬度：[screenWidth] 吋"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "觸控式螢幕顯示器"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示器",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "這部裝置有<br/>令人驚豔的顯示器",
                "tr_subtitle": "透過 8 倍以上的高畫質像素，您的影片和遊戲無論在清晰度和色彩上都將進入全新境界。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "解析度：[screenResolutionX]x[screenResolutionY]",
                    "tr_badgePreTitle": "像素：[screenResolution] 萬"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] 吋顯示器",
                    "tr_badgeSubTitle": "螢幕高度：[screenHeight] 吋",
                    "tr_badgePreTitle": "螢幕寬度：[YY] 吋"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "顯示器",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] 萬像素，可輕點、滑動與觸控",
                    "tr_subTitle": "以更清晰生動的細節欣賞您最愛的內容並進行互動。"
                  },
                  {
                    "tr_title": "[screenResolution] 萬像素以&#65279;提&#65279;升您數位世界體驗",
                    "tr_subTitle": "像素就是一切。像素越高，影像越清晰生動。"
                  },
                  {
                    "tr_title": "這部裝置有<br/>令人驚豔的顯示器",
                    "tr_subTitle": "比 HD 高出將近四倍的像素，您的影片與遊戲將呈現出全新等級的清&#65279;晰&#65279;度&#65279;與&#65279;色&#65279;彩&#65279;。"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "連線能力",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Thunderbolt™ 3 技術可連接任何基座、顯示器或資料裝置，提供最快速與最廣泛的使用。",
                "tr_title": "一個連接埠包辦所有功能",
                "tr_title1": "一個連接埠包辦所有功能",
                "titleArray": [
                  {
                    "tr_title": "我可以如何使用 THUNDERBOLT™ 3 技術？",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™&nbsp;3 技&#65279;術"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "4K 螢幕",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "連接顯示器，享受優美的解析度、對比與色彩"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "外接式顯示卡",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "即刻為任何個人電腦大幅升級繪圖效果"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "快速網路",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "提供快速的端對端連線"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "連線能力",
                "detailBGUrl": "",
                "tr_subtitle": "Thunderbolt™ 3 技術傳輸資料的速度比傳統 USB 快，能讓您輕鬆快速整合各種週邊設備，如多螢幕或外接顯示卡。",
                "tr_title": "Thunderbolt™&nbsp;3 技&#65279;術",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "飆速存取資料",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Thunderbolt™&nbsp;3 技&#65279;術",
                    "tr_badgeSubTitle": "40 Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "連線能力",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>一個通用的連接埠和一條通用纜線，支援您所有喜愛的配件</li> <li>可靠的筆記型電腦充電</li> <li>健全的認證制度，讓您安心</li></ul>",
                "tr_title": "通用的萬能連接埠",
                "tr_title1": "通用的萬能連接埠",
                "tr_noteSection": "<sup>†</sup>根據最低規格要求",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "如何使用 THUNDERBOLT™ 4 技術？",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™&nbsp;4 技&#65279;術"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "創作",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "連接外接螢幕和快速儲存裝置來檢視和編輯相片與影片。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "擴充座",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "用一條纜線將所有的配件連接至您的筆記型電腦，打造簡單不雜亂的桌面。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "開戰",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "增加 SSD 與 HDD 容量，速度最高達 3,000 MB/s，供您儲存遊戲資料庫和媒體檔案。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "最大效能<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "比 USB* 3.2 快 4 倍 <br/>比 HDMI* 2 速度加快 2 倍"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "連線能力",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "享受與 Thunderbolt™ 3 技術同等級的驚人效能，並獲得更多功能。這些新功能讓您可以輕鬆連接採用最新 Thunderbolt™ 規格的擴充座、螢幕、快速儲存裝置或 USB* 配件。",
                "tr_title": "Thunderbolt™&nbsp;4 技&#65279;術",
                "tr_pretitle": "通用的萬能連接埠",
                "tr_noteSection": "<sup>†</sup>根據最低規格要求",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "一條 Thunderbolt™ 4 通用纜線可以取代您所有的 USB-C* 纜線，讓您可以輕鬆快速地選擇所需的纜線。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Thunderbolt™ 4 技術擴充座最多可提供 4 個 Thunderbolt™ 連接埠，靈活連接您的各種配件。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "最大效能<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "比 USB* 3.2 快 4 倍 <br/>比 HDMI* 2 速度加快 2 倍"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "連線能力",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>一個通用的連接埠和一條通用纜線，支援您所有喜愛的配件</li> <li>可靠的筆記型電腦充電</li> <li>健全的認證制度，讓您安心</li></ul>",
                "tr_title": "使用 Thunderbolt™ 配件得到最豐富的體&#xfeff;驗",
                "tr_noteSection": "<sup>†</sup>根據最低規格要求",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "如何使用 THUNDERBOLT™ 4 技術？",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "創作",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "連接外接螢幕和快速儲存裝置來檢視和編輯相片與影片。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "擴充座",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "用一條纜線將所有的配件連接至您的筆記型電腦，打造簡單不雜亂的桌面。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "開戰",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "增加 SSD 與 HDD 容量，速度最高達 3,000 MB/s，供您儲存遊戲資料庫和媒體檔案。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "最大效能<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "比 USB* 3.2 快 4 倍 <br/>比 HDMI* 2 速度加快 2 倍"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "最常見的 Wi‑Fi 類型是：",
                "tr_title": "瞭解 Wi‑Fi 標準",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "即使許多使用者正在使用相同的訊號，Wi-Fi 6 也能實現更高的速度。<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6（Gig+）",
                    "tr_badgeSubTitle": "藉助於 Intel 的最新 Wi-Fi 創新技術，即使有許多使用者使用相同的訊號，也能提供優於 Gigabit Wi-Fi 的速度。<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Wi‑Fi 6E 是 20 年來 Wi‑Fi 的最大進展，開啟全新的高速通道，不只提升效能與穩定性，更減少干擾。"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "即使許多使用者正在使用相同的訊號，Wi-Fi 6 也能實現更高的速度。<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Wi-Fi 6E 的所有優勢，搭配 Intel® Killer™ 優化引擎，將頻寬轉換至最需要它的網站與應用程式。"
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "在競爭較少的高速通道上，您可享受提升的效能與可靠性。"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6",
                    "tr_badgeSubTitle": "即使許多使用者正在使用相同的訊號，Wi-Fi 6 也能實現更高的速度。<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "憑藉 Wi-Fi 6 更少的干擾和 Intel® Killer™ Prioritization Engine 的智慧效能，您的線上體驗不僅速度更快，回應也會更加靈敏。⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "在競爭較少的高速通道上，您可享受提升的效能與可靠性。"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "超過 5 Gbps 的電腦 Wi-Fi 速度以及一致的超低延遲：無需耗時數分鐘，只需幾秒的時間即可分享檔案。"
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "最新 Intel® Killer™ Wi-Fi 7 (5 Gig) 技術透過 AI 網路最佳化，為您帶來「極致」的高可靠性、低延遲與速度。"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "即使許多使用者正在使用相同的訊號，Wi-Fi 6 也能實現更高的速度。<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "利用 6 GHz 頻道，Intel® Wi-Fi 6E (Gig+) 實現超過 1 Gbps 的速度、高可靠度和低延遲。"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "與 Wi-Fi 5 相比，在壅塞網路上的效率、容量及速度都更高。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Wi‑Fi [XX] 讓您享受更佳連線品質與更﻿快﻿速﻿<sup>†</sup>、更穩定可靠的連線體驗。",
                "tr_title": "取得更良好的網路覆&#65279;蓋範&#65279;圍與速度",
                "tr_noteSection": "<sup>†</sup>實際無線傳輸量及/或範圍將因狀況而異。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "在多人線上遊戲的時代，連線再也無法妥協。除了速度，您也會需要能夠在較遠距離維持相同速度的無線訊號。",
                "tr_title": "[XX]",
                "tr_pretitle": "連線是關鍵",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "透過 Intel® Wi‑Fi 6 (Gig+) 連接至 Wi‑Fi 6 路由器，並支援 160 MHz 通道，讓更多活躍用戶享受更快速、更穩定可靠的連線體驗。",
                "tr_title": "使用 Intel® <span class='no-warp'>Wi-Fi 6 </span>（Gig+）時，速度提升將近 3 倍<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>相較於標準 AC Wi‑Fi。部分精選功能僅限於特定 SKU。詳情請洽製造商。如需更多 Wi‑Fi 資訊，請造訪 intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "入門級雙頻 Intel® Wi-Fi 6 為基本連線需求帶來改善的效能，如在壅塞的網路上使用電子郵件和瀏覽網際網路。<sup>9</sup>",
                "tr_title": "基礎 Wi‑Fi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "在多人線上遊戲的時代，連線再也無法妥協。除了速度，您也會需要能夠在較遠距離維持相同速度的無線訊號。",
                "tr_title": "[XX]",
                "tr_pretitle": "連線是關鍵",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "擁有全新的 Wi‑Fi 通道，表示您不需要與鄰近的老舊裝置分享頻寬，讓您順暢串流、快速瀏覽並能流暢地進行遠端工作與學習。",
                "tr_title": "無與倫比的連線體驗",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "[XX] 讓您享受更好的連線品質與更快速&#65279;<sup>†</sup>又可靠的連線體驗。",
                "tr_title": "取得更良好的網路覆&#65279;蓋範&#65279;圍與速度",
                "tr_noteSection": "<sup>†</sup>實際無線傳輸量及/或範圍將因狀況而異。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "在多人線上遊戲的時代，連線再也無法妥協。除了速度，您也會需要能夠在較遠距離維持相同速度的無線訊號。",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "連線是關鍵",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "內建超高速 Wi‑Fi",
                "tr_subtitle": "不需連接網路線，就能與您的線上世界保持連線。"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "利用獨立高速通道減少干擾，並透過 Intel® Killer™ 優化引擎將動力優先提供給遊戲，讓線上遊戲體驗大幅升級。",
                "tr_title": "讓競爭對手望塵莫及",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "利用獨立高速通道減少干擾，並透過 Intel® Killer™ 優化引擎將動力優先提供給遊戲，讓線上遊戲體驗大幅升級。",
                "tr_title": "[XX]",
                "tr_pretitle": "讓競爭對手望塵莫及",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "具有專屬的高速通道可減少干擾，將遊戲視為第一要務，大幅提升線上遊戲體驗。",
                "tr_title": "[XX]",
                "tr_pretitle": "讓競爭對手望塵莫及",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi-Fi 可為最需要的應用程式自動加速網路流量，讓您的串流影片畫面更加鉅細靡遺，並減少緩衝情況。",
                "tr_title": "以更快更&#65279;聰&#65279;明&#65279;的方&#65279;式&#65279;連&#65279;線&#65279;⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "在多人線上遊戲的時代，連線再也無法妥協。除了速度，您也會需要能夠在較遠距離維持相同速度的無線訊號。",
                "tr_title": "[XX]",
                "tr_pretitle": "連線是關鍵",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Wi-Fi 5 讓您享受更好的連線品質與更快速<sup>†</sup> 又可靠的連線體驗。",
                "tr_title": "取得更良好的網路覆&#65279;蓋範&#65279;圍與速度",
                "tr_noteSection": "<sup>†</sup>實際無線傳輸量及/或範圍將因狀況而異。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "在多人線上遊戲的時代，連線再也無法妥協。除了速度，您也會需要能夠在較遠距離維持相同速度的無線訊號。",
                "tr_title": "[XX]",
                "tr_pretitle": "讓競爭對手望塵莫及",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Wi-Fi 7 的功能奠基於前幾代的 Wi-Fi 產品，精益求精、發揚光大。這表示不只速度更快，回應能力和可靠度也大幅改善，帶來沉浸式的消費者體驗和精密的未來技術。",
                "tr_title": "宛如有線的電腦網路",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Wi-Fi 7 的功能奠基於前幾代的 Wi-Fi 產品，精益求精、發揚光大。這表示不只速度更快，回應能力和可靠度也大幅改善，帶來沉浸式的消費者體驗和精密的未來技術。",
                "tr_title": "[XX]",
                "tr_pretitle": "大幅提升使用者體驗",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "通過 AI 網路最佳化提升速度和可靠性，並降低延&#xfeff;遲",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "通過 AI 網路最佳化提升速度和可靠性，並降低延&#xfeff;遲。",
                "tr_title": "[XX]",
                "tr_pretitle": "媲美有線連線的回應能力",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "電池",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "測量方式？",
                    "tr_badgeSubTitle": "多數製造商以小時方式表達電池續航力。儘&#65279;管&#65279;這&#65279;項&#65279;計&#65279;算方&#65279;式並非 100% 準確，您仍可利用此資訊比較不同的個&#65279;人&#65279;電&#65279;腦&#65279;電&#65279;池&#65279;續&#65279;航&#65279;力。"
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "電池",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "隨時隨地玩遊戲",
                "tr_subtitle": "當今的電競筆記型電腦威力如此強大，何必獨鐘於桌上型電腦？現在您可以隨時隨地玩遊戲。",
                "tr_title": "[Dynamic.battery.values.hours] 個小時的電池續&#65279;航&#65279;力"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "電池",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "拔下電源線以後，仍可繼續使用應用程式、玩遊戲或聽音樂好&#65279;幾&#65279;個&#65279;小&#65279;時&#65279;。",
                "tr_title": "以單次充電進行 [XX]&nbsp;小&#65279;時的串流<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>根據電腦製造商聲明的電池續航力。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] 小時",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "電池",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "拔下電源線以後，仍可繼續使用應用程式、玩遊戲或聽音樂好&#65279;幾&#65279;個&#65279;小&#65279;時&#65279;。",
                "tr_title": "隨時隨地使用 [Dynamic.battery.values.hours]&nbsp;個&#65279;小&#65279;時的電力<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>根據電腦製造商聲明的電池續航力。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] 小時",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "電池",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "拔下電源線以後，仍可繼續使用應用程式、玩遊戲或聽音樂好&#65279;幾&#65279;個&#65279;小&#65279;時&#65279;。",
                "tr_title": "單次充電 [Dynamic.battery.values.hours] 個小&#65279;時&#65279;，即可擁有全&#65279;天&#65279;候&#65279;電&#65279;力<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>根據電腦製造商聲明的電池續航力。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] 小時",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "電池",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "這款電腦將電池續航力最佳化，充電 30&nbsp;分&#65279;鐘即可為您提供至少 4&nbsp;小&#65279;時的電池續航力。<sup>4</sup>",
                "tr_title": "不插電使用可提供 [Dynamic.battery.values.hours]&nbsp;個&#65279;小&#65279;時的電池續&#65279;航&#65279;力&#65279;<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>根據電腦製造商聲明的電池續航力。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] 小時",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "首頁",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "為何選擇這款電腦",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "查看所有規格",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "遠端",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "行動自如",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "法律聲明",
        "tr_paragraph_array": [
          "效能測試中使用的軟體與工作負載，可能只有針對 Intel® 微處理器的效能最佳化。效能測試（例如 SYSmark* 與 MobileMark*）使用特定的電腦系統、元件及功能進行評量。這些因素若有任何變更，可能會導致不同的結果。考慮購買時，為了協助您充分評估，您應該參考其他資訊及效能測試，包括該產品結合其它產品使用時的效能表現。如需更完整的效能與效能標竿評測結果資訊，請造訪 www.intel.com/benchmarks。",
          "¹Intel® Wi-Fi 6 (Gig+) 產品支援選用 160 MHz 通道，為典型的 2x2 802.11ax 個人電腦 Wi-Fi 產品儘可能提供最快速的理論最高速度 (2402 Mbps)。相較於僅支援 80 MHz 通道強制要求的標準 2x2 (1201 Mbps) 或 1x1 (600 Mbps) 802.11ax PC Wi-Fi 產品，頂級 Intel® Wi-Fi 6 (Gig+) 產品可提供快達 2 至 4 倍的理論最高速度。",
          "²建議僅供參考。請參考其它資料來源以進行採購決策。",
          "³Intel® 技術的功能與優勢取決於系統配置，而且可能需要支援的軟硬體和服務啟動才能使用。實際效能會依系統組態而異。沒有電腦系統能提供絕對的安全性。請洽詢您的系統製造商或零售商，或造訪 intel.com 瞭解更多資訊。",
          "⁴用於無線網頁瀏覽時，針對配備 FHD 顯示器的系統。關閉電源時，根據 OEM 預設關機等級。",
          "⁵相較於其他的個人電腦 I/O 連接技術，包括 eSATA、USB 與 IEEE 1394* FireWire。效能表現會因使用的特定硬體與軟體而異。必須使用具備 Thunderbolt™ 技術的裝置。",
          "⁶速度提升近 3 倍：802.11ax 2x2 160 MHz 可提供 2402 Mbps 的最大理論資料速度。比標準 802.11ac 2x2 80 MHz (867 Mbps) 的 Wi‑Fi 快約 3 倍，如 IEEE 802.11* 無線標準規格所述，且需使用類似配置的 802.11ax 無線網路路由器。",
          "⁷75% 延遲限制：根據使用 9 個用戶端，測試採用和不採用 OFDMA 之 802.11ax 的 Intel 模擬資料 (79%)。未採用 OFDMA 的平均延遲為 36 毫秒，採用 OFDMA 的平均延遲則降低為 7.6 毫秒。802.11ax (Wi-Fi 6) 路由器與所有用戶端都必須支援 OFDMA 才能縮短延遲時間。",
          "⁸根據 802.11 規格的理論最大資料速率比較，2x2 160 MHz Wi‑Fi 6/6E (802.11ax) 可提供 2402 Mbps，而 2x2 80 MHz Wi‑Fi 5 (802.11ac) 可提供 867 Mbps，等於速度快上 2.8 倍。",
          "⁹同級最佳 Wi‑Fi 6：Intel® Wi‑Fi 6 (Gig+) 產品支援選用 160 MHz 通道，為典型的 2x2 802.11ax PC Wi‑Fi 產品提供最快速的理論最高速度 (2402 Mbps)。相較於僅支援 80 MHz 通道強制要求的標準 2x2 (1201 Mbps) 或 1x1 (600 Mbps) 802.11ax PC Wi‑Fi 產品，頂級 Intel® Wi‑Fi 6 (Gig+) 產品可提供快達 2 至 4 倍的理論最高速度。",
          "僅限特定的 SKU，功能依據 OEM 設計而異。詳細資料請洽 OEM 或零售商。如需更多 Wi‑Fi 資訊，請造訪 intel.com/wifi6disclaimers。",
          "¹⁰與其他 PC I/O 連線技術（包括 eSATA*、USB* 和 IEEE 1394* FireWire）相比，Thunderbolt™ 4 技術能以 40 Gbps 的速度，為任何基座、顯示器或資料裝置提供最快、最簡單和最可靠的纜線解決方案。",
          "¹¹需要支援 OFDMA 的 802.11ax 路由器，以及網路上支援 AX 的多個用戶端。受 802.11ax 用戶端與 AP 支援的 OFDMA 功能在密集環境中可達更佳表現。2 Gbps 是基於 IEEE 802.11* 規格約 70% 的假設，最大理論數據速度 802.11ax 160 MHz 2402 Mbps。",
          "¹²根據文件發行（Document Launch）使用背景活動工作負載（Background Activity Workload），將第 8 代 Intel® Core™ i7-8565U 處理器（512 GB TLC SSD）與第 8 代 Intel® Core™ i7-8565U 處理器（32 GB + 512 GB Intel® Optane™ memory H10 搭配固態儲存裝置）相較。",
          "¹³Intel® Thread Director 設計成為第 12 代 Intel® Core™ 處理器的一環，可協助支援的作業系統，以更智慧的方式將工作負載傳送至正確的核心。無須任何使用者動作。如需詳細資料，請參閱 intel.com。",
          "¹⁴部份第 12 代 Intel® Core™ 處理器不提供。效能混合式架構在單一處理器晶粒上，結合了 Performance-core（P-core）與 Efficient-core（E-core）這兩種全新的核心微架構。特定第 12 代 Intel® Core™ 處理器（某些第 12 代 Intel® Core™ i5 處理器和更低版本）不支援效能混合式架構，只有 P-core。",
          "¹⁵依據遊戲啟動工作負載進行測量，比較第 8 代 Intel® Core™ i7-8750H 處理器 (32GB Intel® Optane™ memory module + Intel® SSD 256GB PCIe* + 1TB HDD)，以及第 8 代 Intel® Core™ i7-8750H 處理器 (Intel® SSD 256GB PCIe* + 1TB HDD)。遊戲啟動工作負載 - 由 Intel 開發的工作負載測量在停用簡介影片的情況下，啟動 Total War*: WARHAMMER* II《全軍破敵：戰鎚 2》組建：5577.0 至到達主功能表（啟動）所經過的時間。Intel® Optane™ memory 資料磁碟加速配置：Intel® Core™ i7-8750H 處理器，PL1=45W TDP，6 核 12 緒，在未量產的 OEM 系統上渦輪加速最高 4GHz，顯示卡：NVIDIA* GTX 1070，記憶體：2x4GB DDR4，儲存裝置：Intel® SSD 760p 256GB (PCIe*) + 1TB HDD + 32GB Intel® Optane™ memory，作業系統：Windows* 10 RS3 Build 1709，MCU 0x84；Intel® Core™ i7-8750H 處理器，PL1=45W TDP，6 核 12 緒，在未量產的 OEM 系統上渦輪加速最高 4GHz，顯示卡：NVIDIA* GeForce* GTX 1070，記憶體：2x4GB DDR4，儲存裝置：Intel® SSD 760p 256GB (PCIe*) + 1TB HDD，作業系統：Windows* 10 RS3 Build 1709，MCU 0x84。",
          "¹⁶依據遊戲關卡載入工作負載進行測量，比較第 8 代 Intel® Core™ i7-8750H 處理器 (32GB Intel® Optane™ memory module) 和第 8 代 Intel® Core™ i7-8750H 處理器（僅具備 HDD）。遊戲關卡載入工作負載 - 由 Intel 開發的工作負載測量從 Total War*: WARHAMMER* II《全軍破敵：戰鎚 2》組建：5577.0 主功能表到關卡載入完成（關卡載入）所經過的時間。Intel® Optane™ memory 資料磁碟加速配置：Intel® Core™ i7-8750H 處理器，PL1=45W TDP，6 核 12 緒，在未量產的 OEM 系統上渦輪加速最高 4GHz，顯示卡：NVIDIA* GeForce* GTX 1070，記憶體：2x4GB DDR4，儲存裝置：Intel® SSD 760p 256GB (PCIe*) + 1TB HDD + 32GB Intel® Optane™ memory，作業系統：Windows* 10 RS3 Build 1709，MCU 0x84；Intel® Core™ i7-8750H 處理器，PL1=45W TDP，6 核 12 緒，在未量產的 OEM 系統上渦輪加速最高 4GHz，顯示卡：NVIDIA* GeForce* GTX 1070，記憶體：2x4GB DDR4，儲存裝置：Intel® SSD 760p 256GB (PCIe*) + 1TB HDD，作業系統：Windows* 10 RS3 Build 1709，MCU 0x84。",
          "¹⁷於 Performance-core。效能可能會因使用方式、配置和其他因素而有所不同。請造訪 www.intel.com/PerformanceIndex 瞭解更多資訊。",
          "¹⁸變更時脈頻率或電壓有可能傷害或縮短處理器及其他系統元件的使用壽命，亦可能降低系統穩定度與效能。若以超越規格的條件運作處理器，則可能不適用產品保固。有關詳情，請洽詢各系統與元件的製造商。",
          "¹⁹量測依據為 Intel vPro® 平台無與倫比的上下作業系統安全功能、應用程式和資料保護，以及適用於各種規模企業的進階威脅防護，還有 Intel 在產品設計、製造與支援方面安全第一的策略。所有搭載 Intel vPro® 平台的商務電腦，均已依據嚴格的規格驗證，包括獨特的硬體型安全功能。如需詳細資料，請造訪 www.intel.com/PerformanceIndex（平台）。沒有產品或元件能提供絕對的安全性。",
          "²⁰Intel® Connectivity Performance Suite 僅限在 Windows* 系統提供。在壅塞網路上以及沒有 ICPS 軟體應用程式無線流量優化和智慧存取點切換的 Intel Over Air（OTA）Wi-Fi 測試。如需詳細資料，請參閱 www.intel.com/PerformanceIndex（連線能力）。結果可能會有所差異。",
          "²¹相較於前一代處理器。",
          "²²Intel® Arc™ 顯示晶片是市面上首款支援 AV1 編碼的產品（截至 2022 年第 1 季）。請造訪 intel.com/performanceindex 瞭解詳細資&#xfeff;訊。",
          "²³量測時使用 Intel® Evo™ 設計的獨特功能，而且這些功能經過驗證，符合主要行動使用者體驗的門檻。測試截至 2022 年 2 月。",
          "²⁴Intel® Unison™ 解決方案目前僅可用於 Windows* 電腦上合格的 Intel® Evo™ 設計，而且僅限與 Android 或 iOS 手機搭配使用；所有裝置皆必須執行支援的作業系統版本。",
          "²⁵記憶體速度上限與每通道 1 個 DIMM（1DPC）的配置相關。任何通道上的額外 DIMM 負荷，都可能影響記憶體速度上限。部分 SKU 高達 DDR5-5600 MT/s 1DPC UDIMM 1Rx8、1Rx16 以及 DDR5-5200 1Rx8、1Rx16、2Rx8。2DPC 配置可達記憶體容量上&#xfeff;限。",
          "²⁶相較於與標準 Wi-Fi。如需詳細資料，請參閱 intel.com/performance-wireless。結果可能會有所差異。",
          "²⁷效能混合式架構在第 12 代 Intel® Core™ 處理器率先引進的單一處理器晶粒，結合 Performance-core (P-core) 與 Efficient-core (E-core) 這兩種核心微架構。特定第 12 代與更新的 Intel® Core™ 處理器沒有效能混合式架構，只有 P-core，且快取記憶體大小與前一代相同；如需 SKU 詳細資料，請參閱 ark.intel.com。",
          "²⁸Intel® Thread Director 內建於硬體，僅限在第 12 代或更新 Intel® Core™ 處理器的效能混合式架構配置提供；需要作業系統支援。適用特色與功能隨作業系統而異。",
          "²⁹Intel® Thread Director 內建於硬體，只有搭載第 12 代和第 13 代 Intel® Core™ 處理器的效能混合式架構配置才提供；需要作業系統支援。適用特色與功能隨作業系統而異。",
          "³⁰測量依據是透過參考驗證平台在 3DMark Time Spy 上使用 Intel®&nbsp;Core™ Ultra 筆記型處理器（系列 2 - 代號名稱：Lunar Lake）相較於 Intel®&nbsp;Core™ Ultra 筆記型處理器（系列 1 - 代號名稱：Meteor Lake）的效能。配置詳細資訊請參閱 intel.com/performanceindex。結果可能會有所差異。",
          "³¹僅在 Windows* 系統提供背景模糊與人臉追蹤功能。",
          "³²透過全新 Multi-Resource Unit Puncturing 功能，Wi-Fi 7 產品能以 6 GHz 存取 320 MHz 頻道，以及以 5 GHz 存取新的 160 MHz 頻道組合。",
          "³³根據 IEEE 無線標準規格和雙資料流裝置的最大理論資料速率。",
          "³⁴Intel 對壅塞網路環境的工程模擬顯示，全新的 Wi-Fi 7 Multi-Link Operation 功能可大幅減少延遲。",
          "³⁵從 OEM 預設關機等級獲得的最低電量。個別系統結果可能會有所差異。如需詳細資料，請造訪 intel.com/performance-evo。",
          "³⁶ 6 GHz Wi-Fi 頻段並未在所有國家/地區提供。",
          "³⁷僅限 Windows*",
          "³⁸從 OEM 預設關機等級獲得的電量。如需更完整的效能與評測基準結果資訊，請造訪 intel.com/Evo。",
          "³⁹係指 Intel®&nbsp;Core™ Ultra 筆記型處理器（系&#xfeff;列&nbsp;2），採用獨特的硬體型安全功能，以無與倫比的方式整合作業系統上層和下層安全機制，即使功率低於前一代（截至 2024 年 9 月），效能也令人驚豔。配置詳細資訊請參閱 intel.com/performanceindex。結果可能會有所差異。",
          "⁴⁰所描述的軟體應用程式僅用於說明目的。AI 功能可能需要另外購買、訂閱或由軟體或平台供應商支援軟體，或是可能有特定的組態或相容性要求。請造訪 www.Intel.com/PerformanceIndex 瞭解詳細資料。結果可能會有所差異。© Intel 公司。",
          "⁴¹顯示的影像可能經過修改或模擬。AI 功能可能需要另外購買、訂閱或由軟體或平台供應商支援軟體，或是可能有特定的組態或相容性要求。詳細資料請參閱 www.intel.com/AIPC。",
          "⁴²在全球特定市場中，將在 Windows* 11 的最新更新中逐步推出預覽版。可用時間因裝置和市場而異。在這裡進一步瞭解：aka.ms/WindowsAIFeatures。© Intel 公司。",
          "⁴³Intel® Unison™ 解決方案目前針對合格的設計提供。詳情請見 www.intel.com/PerformanceIndex。",
          "⁴⁴在 Intel® Core™ Ultra 7 處理器 165H 上執行《消逝的光芒 2：人與仁之戰》，由 Intel® X<sup>e</sup>SS 提升的 FPS 相較於本機 FPS。詳情請見 www.intel.com/PerformanceIndex。結果可能會有所差異。",
          "⁴⁵根據在 YouTube* 4K 30FPS AV1 上測量的結果，和 Intel®&nbsp;Core™ Ultra 筆記型處理器（系列 1 - 代號名稱：Meteor Lake）參考驗證平台相比，Intel®&nbsp;Core™ Ultra 筆記型處理器（系列 2 - 代號名稱：Lunar Lake）參考驗證平台的 SoC 功率較低。配置詳細資訊請參閱 intel.com/performanceindex。結果可能會有所差異。",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "如果價格有誤，Intel 概不負責。",
          "*其他名稱與品牌可能業經宣告為他人之財產。",
          "版權所有 2022 INFLEXION GAMES。NIGHTINGALE、INFLEXION GAMES 及相關設計和綜合標誌是 INFLEXION STUDIOS INC. 的商標。保留一切權利。",
          "Intel 技術可能需要搭配支援的硬體、軟體或服務啟動才能使用。",
          "NERO* 提供的任何結果和資料，僅供參考方便之用。Intel 公司對下列項目概不負責，舉例如下：NERO* 應用程式的準確性、效能結果和評分、或從 www.nero.com 或其它第三方網站可下載之其它應用程式版本的潛在變化。NERO* 電腦評測基準分數，使用特定電腦系統、元件、軟體、作業和功能進行測量。這些因素若有任何變更，可能會導致不同的結果。考慮購買時，為了協助您充分評估，您應該參考其他資訊及效能測試，包括該產品結合其它產品使用時的效能表現。",
          "所有版本的 Intel vPro® 平台都需要合格的 Intel® Core™ 處理器、支援的作業系統、Intel® LAN 和/或 WLAN 晶片、韌體增強功能及其他軟硬體，才能提供定義平台的管理功能使用案例、安全功能、系統效能和穩定性。",
          "效能可能會因使用方式、配置和其他因素而有所不同。請造訪 www.intel.com/PerformanceIndex 瞭解更多資訊。",
          "Intel 不控制或審查第三方資料。您應該參考其他來源以評估準確性。",
          "© Intel 公司。Intel、Intel 圖誌和其它 Intel 標誌是 Intel 公司或其子公司的商標。*其他名稱與品牌可能業經宣告為他人之財產。",
          "沒有產品或元件能提供絕對的安全性。",
          "您的成本和成果可能有所落差。",
          "Intel、Intel 圖誌、Intel Core、Intel Optane、Intel Iris、Thunderbolt，和 Thunderbolt 圖誌是 Intel 公司或其子公司的商標。其他名稱與品牌可能業經宣告為他人之財產。",
          "© Intel 公司。"
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "首頁",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "掃描以比較",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "熱門",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "使用 Intel® Core™ Ultra 處理器釋放全新 AI 體驗",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "使用 Intel® Core™ Ultra 處理器釋放全新 AI 體驗",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "使用 Intel® Core™ Ultra 處理器釋放全新 AI 體驗",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "Stable Diffusion*：文字生成影像",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe*：相片編輯",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "Intel® X<sup>e</sup>SS : 圖像升級",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee*: Deepfake Detector",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "協作效果 （Teams）",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "IQIYI：手勢控制",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "將電腦與手機同步，並查看通知、通話和文字",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "內建色彩艷麗鮮明的顯示晶片",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "Microsoft*：依您的方式生活",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "Microsoft*：全都一覽無餘，一刷即是",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "微調系統，讓處理器以更高的頻率更快運作",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "在不犧牲儲存空間的情況下，提升桌上型或筆記型電腦的系統效能",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "在不犧牲儲存空間的情況下，提升桌上型或筆記型電腦的系統效能",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "Samsung OLED：令人心曠神怡的景象",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "通過 AI 網路最佳化提升速度和可靠性，並降低延&#xfeff;遲",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "宛如有線的電腦網路",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "宛如有線的電腦網路",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "使用 Intel® Wi-Fi 6 (Gig+) 時，速度提升將近 3 倍",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "無與倫比的連線體驗",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "通用的萬能連接埠",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "打出高手風采：瞭解 Intel 如何為遊戲和遊戲玩家提供動力",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "打出高手風采：與 Intel 攜手奪冠稱王",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "歡迎",
    "tr_welcomePagetrSubtitle": "用智慧型手機遠端控制這部電腦",
    "tr_welcomePagembSubtitle": "歡迎在您的智慧型手機上探索這部電腦的規格",
    "tr_welcomePageBtnText": "立即開始",
    "tr_welcomePageText": "在您的智慧型手機上探索並比較這部電腦的詳細資料",
    "tr_scrollText": "導覽您前方的裝置",
    "tr_toolTipTextc1": "使用這些按鈕，導覽至您前方裝置的不同頁面。",
    "tr_toolTipTextc2": "輕點下方的卡片，將導覽至每個裝置規格的詳細頁面。",
    "tr_toolTipTextc3": "使用這些按鈕，以在您前方的螢幕上下移動。",
    "tr_toolTipTextc4": "關閉遠端模式，以在您的智慧型手機上檢視裝置規格。",
    "tr_toolTipTextmb1": "輕點卡片以檢視詳細的內容頁面。",
    "tr_toolTipTextmb2": "輕點以檢視所有裝置規格的摘要",
    "tr_toolTipTextmb3": "輕點按鈕以掃描 QR 代碼，並比較最多 4 個裝置",
    "tr_nextButton": "下一頁",
    "tr_prevButton": "上一頁",
    "tr_gotItButton": "瞭解",
    "tr_skipButton": "略過",
    "tr_placeholderText": "* 預留位置",
    "tr_processorHeading": "處理器",
    "tr_processorSubheading": "第&nbsp;10&nbsp;代 Intel®&nbsp;Core™&nbsp;i7 處&#xfeff;理&#xfeff;器",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "[XX] GB Intel® Optane™ Memory + [YY] GB SSD",
    "tr_coachmarkProcessor": "XX 處理器",
    "tr_coachmarkGraphics": "[XX] 顯示晶片",
    "tr_coachmarkMemory": "規格值",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "999.95 美元",
    "tr_compare": "比較",
    "tr_viewallspecs": "檢視所有規格",
    "tr_operatingSystem": "作業系統",
    "tr_AmdProcessorNonIntel": "AMD* 處理器",
    "tr_QualcommProcessorNonIntel": "Qualcomm* 處理器",
    "tr_GBText": "GB",
    "tr_remoteText": "筆記型電腦螢幕所顯示的規格",
    "tr_scanErrorText": "您最多一次只能掃描 3 個裝置。請刪除一個或多個裝置。",
    "tr_scanErrorText2": "您已經掃描過此裝置，請掃描另一個裝置。",
    "tr_scanMsg": "掃描以新增裝&#65279;置",
    "tr_backToScan": "返回比較",
    "tr_scanToCompare": "掃描以比較",
    "tr_compareDevice": "比較裝置",
    "tr_processorTitle": "處理器",
    "tr_graphicTitle": "顯示晶片",
    "tr_storageTitle": "儲存空間",
    "tr_displayTitle": "顯示器",
    "tr_batteryTitle": "電池",
    "tr_memoryTitle": "記憶體",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "連線傳輸",
    "tr_priceTitle": "價格",
    "tr_operatingSystemTitle": "作業系統",
    "tr_batteryHoursText": "[XX] 小時",
    "tr_hrsText": "小時",
    "tr_touchscreeenText": "[screenDiagonal] 吋觸控螢幕",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD 觸控螢幕",
    "tr_FHDTouchScreenText": "FHD 觸控螢幕",
    "tr_4KTouchscreenText": "4K 觸控螢幕",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "儲存",
    "tr_device_comparison": "裝置比較文件 [mm]-[dd]-[yyyy]",
    "tr_Save_As_JPEG": "儲存為 JPEG",
    "tr_Save_As_PDF": "儲存為 PDF",
    "tr_Downloading_PDF": "正在下載 PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "開放",
    "tr_logo": "圖誌",
    "tr_laptop_name": "筆記型電腦名稱",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Intel® Optane™ Memory",
    "tr_inchUnit": "吋",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6（Gig+）",
    "tr_wifi6Caps": "INTEL® Wi‑Fi 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "INTEL® OPTANE™ MEMORY",
    "tr_Thunderbolt3": "Thunderbolt™&nbsp;3 技&#65279;術",
    "tr_Thunderbolt4": "Thunderbolt™&nbsp;4 技&#65279;術",
    "tr_processorGraphicsCaps": "處理器顯示晶片",
    "tr_processorGraphicsSmall": "處理器顯示晶片",
    "tr_graphicsCardCaps": "獨立顯示卡",
    "tr_graphicsCardSmall": "獨立顯示卡",
    "tr_processorTitleCamel": "處理器",
    "tr_graphicTitleCamel": "顯示晶片",
    "tr_storageTitleCamel": "儲存裝置",
    "tr_displayTitleCamel": "顯示器",
    "tr_batteryTitleCamel": "電池",
    "tr_memoryTitleCamel": "記憶體",
    "tr_connectivityTitleCamel": "連線能力",
    "tr_priceTitleCamel": "價格",
    "tr_operatingSystemTitleCamel": "作業系統",
    "tr_viewallspecsCamel": "查看所有規格",
    "tr_displayText": "[screenDiagonal] 吋",
    "tr_displayTextResolution": "[screenDiagonal] 吋的 [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "[screenDiagonal] 吋的 [screenResolutionType] 觸控螢幕",
    "tr_OS": "作業系統",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "PC 規格",
    "tr_explorePC_text": "深入探索這部 PC 的一切",
    "tr_mtlPC_text": "瞭解專為全方位打造的真諦",
    "tr_optaneMemory": "Intel® Optane™ Memory",
    "tr_displayText_Display": "[screenDiagonal] 吋顯示器",
    "tr_displayTextResolution_Display": "[screenDiagonal] 吋[screenResolutionType] 顯示器",
    "tr_displayTextResolutionTouchscreen_Display": "[screenDiagonal] 吋 [screenResolutionType] 觸控式螢幕顯示器",
    "tr_displayTextTouchscreeenText": "[screenDiagonal] 吋觸控式螢幕顯示器",
    "tr_learnMoreLabel": "進一步瞭解",
    "tr_batteryHoursTextUpperCase": "[XX] 小時",
    "tr_processorGraphicsIrisXe": "第 11 代 Intel® Core™ [cpu_fam] 處理器與 Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片",
    "tr_processorGraphicsIrisXeMax": "第 11 代 Intel® Core™ [cpu_fam] 處理器與 Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX 顯示晶片",
    "tr_Thunderbolt3UpperCase": "THUNDERBOLT™ 3 技術",
    "tr_Thunderbolt4UpperCase": "THUNDERBOLT™ 4 技術",
    "tr_processorWithArcGraphics": "第 12 代 INTEL® CORE™ [cpu_fam] 處理器與 INTEL® ARC™ 顯示晶片",
    "tr_processorGraphicsIrisXe12Gen": "第 12 代 INTEL® CORE™ [cpu_fam] 處理器與 INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> 顯示晶片",
    "tr_processorGraphicsIrisXeMax12Gen": "第 12 代 INTEL® CORE™ [cpu_fam] 處理器與 INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX 顯示晶片",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "第 13 代 INTEL® CORE™ [cpu_fam] 處理器與 INTEL® ARC™ 顯示晶片",
    "tr_processorGraphicsIrisXe13Gen": "第 13 代 INTEL® CORE™ [cpu_fam] 處理器與 INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> 顯&#xfeff;示&#xfeff;晶&#xfeff;片",
    "tr_processorGraphicsIrisXeMax13Gen": "第 13 代 INTEL® CORE™ [cpu_fam] 處理器與 INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX 顯示晶片",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "INTEL® ARC™ A350M 顯示晶片",
      "tr_A370M": "INTEL® ARC™ A370M 顯示晶片",
      "tr_A730M": "INTEL® ARC™ A730M 顯示晶片",
      "tr_A770M": "INTEL® ARC™ A770M 顯示晶片",
      "tr_A310": "INTEL® ARC™ A310 顯示晶片",
      "tr_A380": "INTEL® ARC™ A380 顯示晶片",
      "tr_A580": "INTEL® ARC™ A580 顯示晶片",
      "tr_A750": "INTEL® ARC™ A750 顯示晶片",
      "tr_A770": "INTEL® ARC™ A770 顯示晶片"
    },
    "tr_memoryValue": "[XX] GB RAM",
    "tr_memoryWithOptaneDesc": "[Value1] GB 記憶體 = [Value2]&nbsp;GB Intel® Optane™ Memory +&nbsp;[Value3]&nbsp;GB&nbsp;RAM",
    "tr_textGBGB": "[convertedSSD]&nbsp;GB&nbsp;SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textGBTB": "[convertedSSD]&nbsp;GB&nbsp;SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textTBGB": "[convertedSSD]&nbsp;TB&nbsp;SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textTBTB": "[convertedSSD]&nbsp;TB&nbsp;SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textGBSSD": "[convertedSSD]&nbsp;GB 固&#xfeff;態&#xfeff;硬&#xfeff;碟",
    "tr_textTBSSD": "[convertedSSD]&nbsp;TB 固&#xfeff;態&#xfeff;硬&#xfeff;碟",
    "tr_textGBHDD": "[convertedHDD] GB HDD",
    "tr_textTBHDD": "[convertedHDD] TB HDD",
    "tr_textStorageWithOptaneGB": "[XX] GB Intel® Optane™ Memory + [convertedSSD] GB SSD",
    "tr_textStorageWithOptaneTB": "[XX] GB Intel® Optane™ Memory + [convertedSSD] TB SSD",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 家用入門版",
      "tr_OS3": "Windows* 7 家用進階版",
      "tr_OS4": "Windows* 7 專業版",
      "tr_OS5": "Windows* 7 企業版",
      "tr_OS6": "Windows* 7 旗艦版",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 專業版",
      "tr_OS9": "Windows* 8 企業版",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 專業版",
      "tr_OS12": "Windows* 8.1 企業版",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 家用版",
      "tr_OS15": "Windows* 10 專業版",
      "tr_OS16": "Windows* 10 企&#xfeff;業&#xfeff;版",
      "tr_OS17": "Windows* 11 家用版",
      "tr_OS18": "Windows* 11 專業版",
      "tr_OS19": "Windows* 11 企業版",
      "tr_OS20": "Windows* 10 Home S 模式",
      "tr_OS21": "Windows* 10 Pro S 模式",
      "tr_OS22": "Windows* 10 Enterprise S 模式",
      "tr_OS23": "Windows* 11 Home S 模式",
      "tr_OS24": "Windows* 11 Pro S 模式",
      "tr_OS25": "Windows* 11 Enterprise S 模式"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Intel® Arc™ A310 Graphics",
      "tr_GR387": "Intel® Arc™ A350M Graphics",
      "tr_GR388": "Intel® Arc™ A370M Graphics",
      "tr_GR389": "Intel® Arc™ A380 Graphics",
      "tr_GR390": "Intel® Arc™ A530M Graphics",
      "tr_GR391": "Intel® Arc™ A550M Graphics",
      "tr_GR392": "Intel® Arc™ A570M Graphics",
      "tr_GR393": "Intel® Arc™ A580 Graphics",
      "tr_GR394": "Intel® Arc™ A730M Graphics",
      "tr_GR395": "Intel® Arc™ A750 Graphics",
      "tr_GR396": "Intel® Arc™ A770 Graphics",
      "tr_GR397": "Intel® Arc™ A770M Graphics",
      "tr_GR398": "Intel® Arc™ 顯示晶片",
      "tr_GR399": "Intel® Arc™ Pro A30M Graphics",
      "tr_GR400": "Intel® Arc™ Pro A40/A50 Graphics",
      "tr_GR401": "Intel® Arc™ Pro A60 Graphics",
      "tr_GR402": "Intel® Arc™ Pro A60M Graphics",
      "tr_GR403": "Intel® Graphics",
      "tr_GR404": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 2000",
      "tr_GR405": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 2500",
      "tr_GR406": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 3000",
      "tr_GR407": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 400",
      "tr_GR408": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 4000",
      "tr_GR409": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 405",
      "tr_GR410": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 4200",
      "tr_GR411": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 4400",
      "tr_GR412": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 4600",
      "tr_GR413": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 500",
      "tr_GR414": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 5000",
      "tr_GR415": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 505",
      "tr_GR416": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 510",
      "tr_GR417": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 515",
      "tr_GR418": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 520",
      "tr_GR419": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 530",
      "tr_GR420": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 5300",
      "tr_GR421": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 5500",
      "tr_GR422": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 5600",
      "tr_GR423": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 6000",
      "tr_GR424": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 610",
      "tr_GR425": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 615",
      "tr_GR426": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 620",
      "tr_GR427": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片 630",
      "tr_GR428": "Intel® HD 顯&#xfeff;示&#xfeff;晶&#xfeff;片",
      "tr_GR429": "Intel® Iris® 顯示晶片 5100",
      "tr_GR430": "Intel® Iris® 顯示晶片 540",
      "tr_GR431": "Intel® Iris® 顯示晶片 550",
      "tr_GR432": "Intel® Iris® 顯示晶片 6100",
      "tr_GR433": "Intel® Iris® Plus 顯示晶片 640",
      "tr_GR434": "Intel® Iris® Plus 顯示晶片 645",
      "tr_GR435": "Intel® Iris® Plus 顯示晶片 650",
      "tr_GR436": "Intel® Iris® Plus 顯示晶片 655",
      "tr_GR437": "Intel® Iris® Plus 顯示晶片",
      "tr_GR438": "Intel® Iris® Pro 顯示晶片 5200",
      "tr_GR439": "Intel® Iris® Pro 顯示晶片 580",
      "tr_GR440": "Intel® Iris® Pro 顯示晶片 6200",
      "tr_GR441": "Intel® Iris® X<sup>e</sup> 顯示晶片",
      "tr_GR442": "Intel® Iris® X<sup>e</sup> MAX 顯示晶片 A220M",
      "tr_GR443": "Intel® Iris® X<sup>e</sup> MAX 顯示晶片",
      "tr_GR444": "Intel® 整合式顯示晶片",
      "tr_GR445": "Intel® UHD Graphics 600",
      "tr_GR446": "Intel® UHD Graphics 605",
      "tr_GR447": "Intel® UHD Graphics 610",
      "tr_GR448": "Intel® UHD Graphics 615",
      "tr_GR449": "Intel® UHD Graphics 617",
      "tr_GR450": "Intel® UHD Graphics 620",
      "tr_GR451": "Intel® UHD Graphics 630",
      "tr_GR452": "Intel® UHD Graphics 710",
      "tr_GR453": "Intel® UHD Graphics 730",
      "tr_GR454": "Intel® UHD Graphics 770",
      "tr_GR455": "Intel® UHD 顯示晶片",
      "tr_GR456": "Intel® UHD 顯示晶片",
      "tr_GR457": "Intel® UHD 顯示晶片",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 with Max-Q 設計",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti with Max-Q 設計",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 with Max-Q 設計",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 with Max-Q 設計",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 with Max-Q 設計",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 with Max-Q 設計",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti with Max-Q 設計",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti with Max-Q 設計",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 with Max-Q 設計",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 with Max-Q 設計",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super with Max-Q 設計",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 with Max-Q 設計",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super with Max-Q 設計",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 with Max-Q 設計",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 with Max-Q 設計",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 with Max-Q 設計",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 with Max-Q 設計",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 with Max-Q 設計",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 with Max-Q 設計",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 with Max-Q 設計",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 with Max-Q 設計",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 with Max-Q 設計",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 with Max-Q 設計",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "Intel® Arc™ 顯示晶片",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "AMD Radeon* Pro W7900 雙插槽"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "立即購買",
    "tr_textTryagain": "請再試一次",
    "tr_textBuynowErr": "由於連線中斷，無法使用「立即購買」選項",
    "tr_chatbotPlaceholder_text": "問我任何關於裝置的問題",
    "tr_deviceSpecs_text": "裝置規格"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "搭載 Intel® Hybrid Technology 的 Intel® Core™ i3 處&#65279;理&#65279;器"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "搭載 Intel® Hybrid Technology 的 Intel® Core™ i5 處&#65279;理&#65279;器"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "《漫威復仇者聯盟》",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Intel® 重磅助攻， 打&#65279;造&#65279;更&#65279;強遊&#65279;戲&#65279;體&#65279;驗",
                "tr_subtitle": "透過 Intel® 技術將電腦遊戲最佳化，重現《漫威復仇者聯盟*》最真實的臨場感。",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "體驗 Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "全球頂尖玩家的終極戰場",
                "tr_subtitle": "Intel® 極限高手盃大賽是電動遊戲史上歷史最悠久的活動系列，為電競界設下了標準。",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "打出高手風采",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "與 Intel 攜&#xfeff;手&#xfeff;奪&#xfeff;冠&#xfeff;稱&#xfeff;王",
                "tr_subtitle": "Soniqs 勇奪 2021 年《虹彩六號：圍攻行動》* 北美聯賽冠軍之後，回顧了他們的遊戲史、電競的未來，以及與 Intel 的夥伴關係。",
                "tr_pretitle": "特別介紹 Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "支援 VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "生動逼真的虛擬實境",
                "tr_subtitle": "在電腦上感受最令人驚豔的擬真感與身歷其境的虛擬體驗。使用最新的 Intel® Core™ 處&#65279;理&#65279;器，您就能成為任何角色、自由暢行並體驗新次元的遊戲。",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "使用 Xbox* Game Pass Ultimate 和相容的控制器，在您的 Windows* 電腦、手機和平板電腦上遊玩 100 多款高品質主機遊戲，例如《盜賊之海*》和《Psychonauts* 2》。",
                "titleArray": [
                  {
                    "tr_title": "跨裝置一起遊玩",
                    "tr_subtitle": "進入 Xbox* 的核心，社群中有數百萬玩家隨時準備一起遊玩。與共用遊戲庫中的其他玩家連線並一起遊玩，無論他們是在世界的另一端，或是坐在你身旁。"
                  },
                  {
                    "tr_title": "Windows* 電腦遊戲",
                    "tr_subtitle": "使用適用於 Windows* 的 Xbox* 應用程式與相容的控制器，從雲端遊玩一系列主機遊戲。"
                  },
                  {
                    "tr_title": "領取並遊玩",
                    "tr_subtitle": "在更多地方暢玩精彩的 Xbox* 遊戲。在您的電腦、手機和平板電腦上遊玩 100 多款遊戲，無論是在家中使用電視、在咖啡廳，或是其他任何有高速網際網路連線的地方。"
                  },
                  {
                    "tr_title": "無需控制器即可暢玩",
                    "tr_subtitle": "Xbox* 觸控遊戲解鎖了全新的遊戲方式，提供熟悉的體驗並支援您習慣的實體控制器遊戲方式。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "焦點",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "超過 100 種 AI 體驗",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "觸碰頻率減少，操控力增加",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "在任何環境都能維持在畫面範圍內",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "創意",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "以媲美專業人士的技巧校正光影",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "加上生動逼真的影像效果毫不費力",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "利用 AI 強大功能將歌曲重新混音",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "生產力",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "增強生產力與隱私保護",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "享受更多使用時間，不受插座束縛",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "您的專屬天地，盡在裝&#xfeff;置&#xfeff;之&#xfeff;間。",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "娛樂",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "不插電也能順暢直播",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "迅速且身歷其境的遊戲體驗",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "焦點",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "超過 100 種 AI 體驗",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "打造真正屬於自己的最佳音樂",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "即時觀賞您的創意成果",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "創意",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "最快完成影片剪輯的捷徑",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "讓每張照片完美無瑕",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "生產力",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "增強生產力與隱私保護",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "更高效， 更自由。",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "您的專屬天地，盡在裝&#xfeff;置&#xfeff;之&#xfeff;間。",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "娛樂",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "即使未插電也能以 HD 畫質順暢直播",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "在更多地方享受身歷其境的遊戲體驗",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "更富有創意",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "相片編輯易如反掌",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "瞬間創作值得裱框的相片",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "您只要輸入文字 AI 就能為您實&#xfeff;現。",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "效能更強大",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "享受順暢遊戲的絕佳秘訣",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "享受更多使用時間，不受插座束縛",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "超 – 沉浸",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "提高生產力",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "您的專屬 AI 助理",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "完美的視訊通話",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "您的專屬天地，盡在裝&#xfeff;置&#xfeff;之&#xfeff;間。",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "更安全",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "內建防護措施，協助讓資料更安全、更私密",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "更富有創意",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "相片編輯易如反掌",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "AI，最快完成影&#xfeff;片&#xfeff;編&#xfeff;輯&#xfeff;的&#xfeff;捷&#xfeff;徑",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "快速錄製您最喜愛的歌曲歌詞",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "利用 AI 節省數小時的編輯",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "效能更強大",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "享受順暢遊戲的絕佳秘訣",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "享受更多使用時間，不受插座束縛",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "超 – 沉浸",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "提高生產力",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "您的專屬 AI 助理",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "完美的視訊通話",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "您的專屬天地，盡在裝&#xfeff;置&#xfeff;之&#xfeff;間。",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "更安全",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "內建防護措施，協助讓資料更安全、更私密",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "所有娛樂。一部個人電腦",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "同時做為娛樂中心的個人電腦，需要足夠儲存空間容納所有您最愛的歌曲、影片與遊戲，以及正確的處&#65279;理&#65279;器與顯示器組合，以創造沉浸式視覺饗宴。這部個人電腦三者皆具。",
                "titleArray": [
                  {
                    "tr_title": "為新一代視覺效果做好準備",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "打造您的數位館藏",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "通向個人娛樂之窗",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "順暢的遊戲與直播體驗",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "打造您的數位館藏",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "通向個人娛樂之窗",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "提高生產力<br/>任何地方",
              "tr_tileTitle1": "提升產能 迅速完成",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "此個人電腦取得了電力與可攜性的絕佳平衡。從適合輕薄設計的處&#65279;理&#65279;器開始，這部個人電腦還配備了讓您保持連線的 Wi&#8209;Fi 技術，以及讓您維持不插電的電池。",
                "tr_subtitle1": "這台電腦完美結合威力和效能，助您事半功倍。配備強大的處&#65279;理&#65279;器，加上充足的記憶體和光速般的 Wi-Fi 連線，讓您的日常任務變得更&#65279;簡&#65279;單。",
                "tr_subtitle2": "此個人電腦取得了電力與可攜性的絕佳平衡。從適合輕薄設計的處理器開始，這部個人電腦還配備了讓您保持連線的 Wi‑Fi 技術，以及讓您執行多項工作的記憶體。",
                "titleArray": [
                  {
                    "tr_title": "專為成就更多而打造",
                    "tr_title1": "關鍵的智慧型效能",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "頂級的連線能力",
                    "tr_title1": "連接重要的人事物",
                    "tr_title2": "WI‑FI 的重大進步",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "延長不須插電的時間",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "像專家一樣同時應付不同程式",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "創作的自由",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "內容創作是對您的個人電腦要求最高的一項工作。幸運的是，這些元件可以讓您的創作過程更加流暢。",
                "titleArray": [
                  {
                    "tr_title": "更聰明的創作方式",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "為您的影像賦予生命",
                    "tr_title1": "一起創作吧",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "處理多工作業的記憶體",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "瞧瞧此個人電腦內部",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "選擇一部新的個人電腦時，務必專注於這三項主要元件。當您決定好新個人電腦的用途，您也決定了您會多需要這三項重要元件。它們是：",
                "titleArray": [
                  {
                    "tr_title": "遊戲與直播效能",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "儲存裝置",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "記憶體",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "所有娛樂。一部個人電腦",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "同時做為娛樂中心的個人電腦，需要足夠儲存空間容納所有您最愛的歌曲、影片與遊戲，以及正確的處&#65279;理&#65279;器與顯示器組合，以創造沉浸式視覺饗宴。這部個人電腦三者皆具。",
                "titleArray": [
                  {
                    "tr_title": "為新一代視覺效果做好準備",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "打造您的數位館藏",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "通向個人娛樂之窗",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "順暢的遊戲與直播體驗",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "打造您的數位館藏",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "通向個人娛樂之窗",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "記憶體",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "顯示器",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "高速多工作業",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "高速多工作業",
                "tr_subtitle": "無須放慢速度，成就更多",
                "tr_subtitle1": "這部適合多工作業的電腦包括下列優勢：",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "可同時應付多個程式的處理器。"
                  },
                  {
                    "tr_title": "迅速、強大且可靠的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "順暢切換應用程式的記憶體。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "視訊通話",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "視訊通話",
                "tr_subtitle": "清晰的連線",
                "tr_subtitle1": "這部適合視訊聊天的電腦包括下列優勢：",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "可同時錄製及分享影像的處理器。"
                  },
                  {
                    "tr_title": "迅速、強大且可靠的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "可協助您展現清晰影像的網路攝影機。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "支援 VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "生動逼真的虛擬實境",
                "tr_subtitle": "創造完全虛擬的世界需要什麼？在個人電腦上，您需要處理器、顯示卡與記憶體相互協調合作。",
                "titleArray": [
                  {
                    "tr_title": "為虛擬世界注入動&#xfeff;能",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "細節之中看見真實",
                    "tr_title1": "身歷其境",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "影片編輯",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "影片編輯",
                "tr_subtitle": "打造迷人的電&#65279;影&#65279;",
                "tr_subtitle1": "這部適合影片編輯的電腦包括下列優勢：",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "可處理大型檔案的處理器。"
                  },
                  {
                    "tr_title": "讓軟體快速且快速回應的記憶體。"
                  },
                  {
                    "tr_title": "縮短彩現時間的顯示晶片。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "高速多工作業",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "高速多工作業",
                "tr_subtitle": "無須放慢速度，成就更多",
                "tr_subtitle1": "這部適合多工作業的電腦包括下列優勢：",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "可同時應付多個程式的處理器。"
                  },
                  {
                    "tr_title": "迅速、強大且可靠的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "順暢切換應用程式的記憶體。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "支援 VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "生動逼真的虛擬實境",
                "tr_subtitle": "創造完全虛擬的世界需要什麼？在個人電腦上，您需要處理器、顯示卡與記憶體相互協調合作。",
                "titleArray": [
                  {
                    "tr_title": "為虛擬世界注入動&#xfeff;能",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "細節之中看見真實",
                    "tr_title1": "身歷其境",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "3D 建模",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D 建模",
                "tr_subtitle": "全新境界的創&#65279;作",
                "tr_subtitle1": "這部適合 3D 建模的電腦包括下列優勢：",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "可處理進階創作軟體的處理器。"
                  },
                  {
                    "tr_title": "可快速顯示大型檔案的記憶體。"
                  },
                  {
                    "tr_title": "適合強大影像創作與編輯的顯示晶片。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "即時協作",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "即時協作",
                "tr_subtitle": "從任何地方工作",
                "tr_subtitle1": "這部適合線上協作的電腦包括下列優勢：",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "支援辦公室軟體的處理器。"
                  },
                  {
                    "tr_title": "您可信賴的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "以出色 HD 錄製的網路攝影機。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "支援 VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "生動逼真的虛擬實境",
                "tr_subtitle": "創造完全虛擬的世界需要什麼？在個人電腦上，您需要處理器、顯示卡與記憶體相互協調合作。",
                "titleArray": [
                  {
                    "tr_title": "為虛擬世界注入動&#xfeff;能",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "細節之中看見真實",
                    "tr_title1": "身歷其境",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ 筆記型電腦",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "這款筆電如此纖薄輕巧效&#xfeff;能&#xfeff;卻&#xfeff;超&#xfeff;乎&#xfeff;預&#xfeff;期&#xfeff;強&#xfeff;大",
                "tr_subtitle": "Intel® Evo™ 筆記型電腦專為行動效能打造，擁有您需要的一切速度、顯示晶片和電池續航力，盡在最令人驚豔的纖薄輕巧設計中。",
                "titleArray": [
                  {
                    "tr_title": "筆記型電腦的重要性更甚以往，我們確保這款筆記型電腦能讓您成就更多。",
                    "tr_subtitle": "使用觸控螢幕來探索、旋轉和縮放。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬間喚醒",
                    "tr_subtitle": "有了即刻喚醒功能，您的電腦從睡眠模式到準備就緒只需要不到 1 秒的時間。"
                  },
                  {
                    "tr_title": "身歷其境的視覺饗宴",
                    "tr_subtitle": "美麗的顯示器提供清晰的解析度與生動色彩，讓視覺效果躍然幕上。"
                  },
                  {
                    "tr_title": "迅速完成各項工作",
                    "tr_subtitle": "享受第 11 代 Intel® Core™ 處理器反應性超群的效能。"
                  },
                  {
                    "tr_title": "不插電使&#65279;用&#65279;時&#65279;間&#65279;更&#65279;長",
                    "tr_subtitle": "全新電腦可最佳化電池續航力、<br/>快速充電，讓您持續進行工作。"
                  },
                  {
                    "tr_title": "通用纜線連接",
                    "tr_subtitle": "Thunderbolt™ 4 技術讓您可以簡單、快速且安全地將其他裝置連接至您的電腦。<sup>10</sup>"
                  },
                  {
                    "tr_title": "迅速、穩固的連線能力",
                    "tr_subtitle": "利用 Intel® Wi‑Fi 6 (Gig+) ，以可靠連線進行視訊與分享檔案。<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ 筆記型電腦",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "您所需要的一切輕&#xfeff;薄&#xfeff;筆&#xfeff;記&#xfeff;型&#xfeff;電&#xfeff;腦應&#xfeff;有&#xfeff;盡&#xfeff;有",
                "tr_subtitle": "Intel® Evo™ 筆記型電腦是專為行動效能打造，無論您做什麼，設計皆可為您締造最佳體驗<sup>2</sup>。",
                "titleArray": [
                  {
                    "tr_title": "筆記型電腦的重要性更甚以往，我們確保這款筆記型電腦能讓您成就更多。",
                    "tr_subtitle": "使用觸控螢幕來探索、旋轉和縮放。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬間喚醒",
                    "tr_subtitle": "有了即刻喚醒功能，您的電腦從睡眠模式到準備就緒只需要不到 1 秒的時間。"
                  },
                  {
                    "tr_title": "身歷其境的視覺饗宴",
                    "tr_subtitle": "美麗的顯示器提供清晰的解析度與生動色彩，讓視覺效果躍然幕上。"
                  },
                  {
                    "tr_title": "劃時代的效能",
                    "tr_subtitle": "利用第 12 代 Intel® Core™ 處理器的智慧效能，在您最需要的時刻獲得額外效能。"
                  },
                  {
                    "tr_title": "不插電使&#65279;用&#65279;時&#65279;間&#65279;更&#65279;長",
                    "tr_subtitle": "全新電腦可最佳化電池續航力、<br/>快速充電，讓您持續進行工作。"
                  },
                  {
                    "tr_title": "通用纜線連接",
                    "tr_subtitle": "Thunderbolt™ 4 技術讓您可以簡單、快速且安全地將其他裝置連接至您的電腦。<sup>10</sup>"
                  },
                  {
                    "tr_title": "迅速、穩固的連線能力",
                    "tr_subtitle": "利用 Intel® Wi‑Fi 6E (Gig+)，透過值得您信賴的連線進行視訊會談或分享檔案。<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ 筆記型電腦",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "這款筆電如此纖薄輕巧效&#xfeff;能&#xfeff;卻&#xfeff;超&#xfeff;乎&#xfeff;預&#xfeff;期&#xfeff;強&#xfeff;大",
                "tr_subtitle": "Intel® Evo™ 筆記型電腦專為行動效能打造，擁有您需要的一切速度、顯示晶片和電池續航力，盡在最令人驚豔的纖薄輕巧設計中。",
                "titleArray": [
                  {
                    "tr_title": "筆記型電腦的重要性更甚以往，我們確保這款筆記型電腦能讓您成就更多。",
                    "tr_subtitle": "使用觸控螢幕來探索、旋轉和縮放。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬間喚醒",
                    "tr_subtitle": "有了即刻喚醒功能，您的電腦從睡眠模式到準備就緒只需要不到 1 秒的時間。"
                  },
                  {
                    "tr_title": "身歷其境的視覺饗宴",
                    "tr_subtitle": "美麗的顯示器提供清晰的解析度與生動色彩，讓視覺效果躍然幕上。"
                  },
                  {
                    "tr_title": "迅速完成各項工作",
                    "tr_subtitle": "享受第 11 代 Intel® Core™ 處理器反應性超群的效能。"
                  },
                  {
                    "tr_title": "不插電使&#65279;用&#65279;時&#65279;間&#65279;更&#65279;長",
                    "tr_subtitle": "全新電腦可最佳化電池續航力、<br/>快速充電，讓您持續進行工作。"
                  },
                  {
                    "tr_title": "通用纜線連接",
                    "tr_subtitle": "Thunderbolt™ 4 技術讓您可以簡單、快速且安全地將其他裝置連接至您的電腦。<sup>10</sup>"
                  },
                  {
                    "tr_title": "迅速、穩固的連線能力",
                    "tr_subtitle": "利用 Intel® Wi‑Fi 6 (Gig+) ，以可靠連線進行視訊與分享檔案。<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ 筆記型電腦",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "您所需要的一切輕&#xfeff;薄&#xfeff;筆&#xfeff;記&#xfeff;型&#xfeff;電&#xfeff;腦應&#xfeff;有&#xfeff;盡&#xfeff;有",
                "tr_subtitle": "Intel® Evo™ 筆記型電腦是專為行動效能打造，無論您做什麼，設計皆可為您締造最佳體驗<sup>2</sup>。",
                "titleArray": [
                  {
                    "tr_title": "筆記型電腦的重要性更甚以往，我們確保這款筆記型電腦能讓您成就更多。",
                    "tr_subtitle": "使用觸控螢幕來探索、旋轉和縮放。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬間喚醒",
                    "tr_subtitle": "有了即刻喚醒功能，您的電腦從睡眠模式到準備就緒只需要不到 1 秒的時間。"
                  },
                  {
                    "tr_title": "身歷其境的視覺饗宴",
                    "tr_subtitle": "美麗的顯示器提供清晰的解析度與生動色彩，讓視覺效果躍然幕上。"
                  },
                  {
                    "tr_title": "劃時代的效能",
                    "tr_subtitle": "利用第 12 代 Intel® Core™ 處理器的智慧效能，在您最需要的時刻獲得額外效能。"
                  },
                  {
                    "tr_title": "不插電使&#65279;用&#65279;時&#65279;間&#65279;更&#65279;長",
                    "tr_subtitle": "全新電腦可最佳化電池續航力、<br/>快速充電，讓您持續進行工作。"
                  },
                  {
                    "tr_title": "通用纜線連接",
                    "tr_subtitle": "Thunderbolt™ 4 技術讓您可以簡單、快速且安全地將其他裝置連接至您的電腦。<sup>10</sup>"
                  },
                  {
                    "tr_title": "迅速、穩固的連線能力",
                    "tr_subtitle": "利用 Intel® Wi‑Fi 6E (Gig+)，透過值得您信賴的連線進行視訊會談或分享檔案。<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ 筆記型電腦",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "您所需要的一切輕&#xfeff;薄&#xfeff;筆&#xfeff;記&#xfeff;型&#xfeff;電&#xfeff;腦應&#xfeff;有&#xfeff;盡&#xfeff;有",
                "tr_subtitle": "Intel® Evo™ 筆記型電腦是專為行動效能打造，無論您做什麼，設計皆可為您締造最佳體驗<sup>2</sup>。",
                "titleArray": [
                  {
                    "tr_title": "筆記型電腦的重要性更甚以往，我們確保這款筆記型電腦能讓您成就更多。",
                    "tr_subtitle": "使用觸控螢幕來探索、旋轉和縮放。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬間喚醒",
                    "tr_subtitle": "有了即刻喚醒功能，您的電腦從睡眠模式到準備就緒只需要不到 1 秒的時間。"
                  },
                  {
                    "tr_title": "身歷其境的視覺饗宴",
                    "tr_subtitle": "美麗的顯示器提供清晰的解析度與生動色彩，讓視覺效果躍然幕上。"
                  },
                  {
                    "tr_title": "劃時代的效能",
                    "tr_subtitle": "利用第 13 代 Intel® Core™ 處理器的智慧效能，在您最需要的時刻獲得額外效能。"
                  },
                  {
                    "tr_title": "不插電使&#65279;用&#65279;時&#65279;間&#65279;更&#65279;長",
                    "tr_subtitle": "全新電腦可最佳化電池續航力、<br/>快速充電，讓您持續進行工作。"
                  },
                  {
                    "tr_title": "通用纜線連接",
                    "tr_subtitle": "Thunderbolt™ 4 技術讓您可以簡單、快速且安全地將其他裝置連接至您的電腦。<sup>10</sup>"
                  },
                  {
                    "tr_title": "迅速、穩固的連線能力",
                    "tr_subtitle": "利用 Intel® Wi‑Fi 6E (Gig+)，透過值得您信賴的連線進行視訊會談或分享檔案。<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ 筆記型電腦",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "您所需要的一切輕&#xfeff;薄&#xfeff;筆&#xfeff;記&#xfeff;型&#xfeff;電&#xfeff;腦應&#xfeff;有&#xfeff;盡&#xfeff;有",
                "tr_subtitle": "Intel® Evo™ 筆記型電腦是專為行動效能打造，無論您做什麼，設計皆可為您締造最佳體驗<sup>2</sup>。",
                "titleArray": [
                  {
                    "tr_title": "筆記型電腦的重要性更甚以往，我們確保這款筆記型電腦能讓您成就更多。",
                    "tr_subtitle": "使用觸控螢幕來探索、旋轉和縮放。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬間喚醒",
                    "tr_subtitle": "有了即刻喚醒功能，您的電腦從睡眠模式到準備就緒只需要不到 1 秒的時間。"
                  },
                  {
                    "tr_title": "身歷其境的視覺饗宴",
                    "tr_subtitle": "美麗的顯示器提供清晰的解析度與生動色彩，讓視覺效果躍然幕上。"
                  },
                  {
                    "tr_title": "劃時代的效能",
                    "tr_subtitle": "利用第 13 代 Intel® Core™ 處理器的智慧效能，在您最需要的時刻獲得額外效能。"
                  },
                  {
                    "tr_title": "不插電使&#65279;用&#65279;時&#65279;間&#65279;更&#65279;長",
                    "tr_subtitle": "全新電腦可最佳化電池續航力、<br/>快速充電，讓您持續進行工作。"
                  },
                  {
                    "tr_title": "通用纜線連接",
                    "tr_subtitle": "Thunderbolt™ 4 技術讓您可以簡單、快速且安全地將其他裝置連接至您的電腦。<sup>10</sup>"
                  },
                  {
                    "tr_title": "迅速、穩固的連線能力",
                    "tr_subtitle": "利用 Intel® Wi‑Fi 6E (Gig+)，透過值得您信賴的連線進行視訊會談或分享檔案。<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "焦點",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Intel® Evo™ 版是什麼？",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "為什麼選擇 Intel® Evo™ 版？",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "創意",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "在更多地方發揮創意",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "全速照片編輯",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "即興創作原創藝術",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "生產力",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "用 Rewind AI 將生產力發揮到極致",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "分享前先用 AI 偵測 Deepfake",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "您的專屬天地，盡在裝&#xfeff;置&#xfeff;之&#xfeff;間。",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "行動力",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "揮手問候，即可啟用手勢控制",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "持久的電池續航力，不再讓您裹足不前",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "自動保持連線",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "焦點",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Intel® Evo™ 版是什麼？",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "為什麼選擇 Intel® Evo™ 版？",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "創意",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "快速順暢的影片播放",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "利用 AI 的強大力量製作勁歌金曲",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "最快完成剪輯的捷徑",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "生產力",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "用 Rewind AI 將生產力發揮到極致",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "分享前先用 AI 偵測 Deepfake",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "您的專屬天地，盡在裝&#xfeff;置&#xfeff;之&#xfeff;間。",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "行動力",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "幾乎在任何地方都能協作",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "持久的電池續航力，不再讓您裹足不前",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "自動保持連線",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "所有娛樂。一部個人電腦",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "同時做為娛樂中心的個人電腦，需要足夠儲存空間容納所有您最愛的歌曲、影片與遊戲，以及正確的處&#65279;理&#65279;器與顯示器組合，以創造沉浸式視覺饗宴。這部個人電腦三者皆具。",
                "titleArray": [
                  {
                    "tr_title": "令人驚豔的遊戲與直播",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "打造您的數位館藏",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "通向個人娛樂之窗",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "順暢的遊戲與直播體驗",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "打造您的數位館藏",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "通向個人娛樂之窗",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "提高生產力<br/>任何地方",
              "tr_tileTitle1": "提升產能 迅速完成",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "此個人電腦取得了電力與可攜性的絕佳平衡。從適合輕薄設計的處&#65279;理&#65279;器開始，這部個人電腦還配備了讓您保持連線的 Wi&#8209;Fi 技術，以及讓您維持不插電的電池。",
                "tr_subtitle1": "這台電腦完美結合威力和效能，助您事半功倍。配備強大的處&#65279;理&#65279;器，加上充足的記憶體和光速般的 Wi-Fi 連線，讓您的日常任務變得更&#65279;簡&#65279;單。",
                "tr_subtitle2": "此個人電腦取得了電力與可攜性的絕佳平衡。從適合輕薄設計的處理器開始，這部個人電腦還配備了讓您保持連線的 Wi‑Fi 技術，以及讓您執行多項工作的記憶體。",
                "titleArray": [
                  {
                    "tr_title": "關鍵的智慧型效能",
                    "tr_title1": "智慧型效能加持，速度突飛猛進",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "頂級的連線能力",
                    "tr_title1": "連接重要的人事物",
                    "tr_title2": "WI‑FI 的重大進步",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "延長不須插電的時間",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "像專家一樣同時應付不同程式",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "創作的自由",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "內容創作是對您的個人電腦要求最高的一項工作。幸運的是，這些元件可以讓您的創作過程更加流暢。",
                "titleArray": [
                  {
                    "tr_title": "加快創作速度",
                    "tr_title1": "創意專案順暢無礙",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "創作升級",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "處理多工作業的記憶體",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "瞧瞧此個人電腦內部",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "選擇一部新的個人電腦時，務必專注於這三項主要元件。當您決定好新個人電腦的用途，您也決定了您會多需要這三項重要元件。它們是：",
                "titleArray": [
                  {
                    "tr_title": "處理器",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "儲存裝置",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "記憶體",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "創作的自由",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "內容創作是對您的個人電腦要求最高的一項工作。幸運的是，這些元件可以讓您的創作過程更加流暢。",
                "titleArray": [
                  {
                    "tr_title": "更聰明的創作方式",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "為您的影像賦予生命",
                    "tr_title1": "一起創作吧",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "處理多工作業的記憶體",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "提高生產力<br/>任何地方",
              "tr_tileTitle1": "提升產能 迅速完成",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "此個人電腦取得了電力與可攜性的絕佳平衡。從適合輕薄設計的處&#65279;理&#65279;器開始，這部個人電腦還配備了讓您保持連線的 Wi&#8209;Fi 技術，以及讓您維持不插電的電池。",
                "tr_subtitle1": "這台電腦完美結合威力和效能，助您事半功倍。配備強大的處&#65279;理&#65279;器，加上充足的記憶體和光速般的 Wi-Fi 連線，讓您的日常任務變得更&#65279;簡&#65279;單。",
                "tr_subtitle2": "此個人電腦取得了電力與可攜性的絕佳平衡。從適合輕薄設計的處理器開始，這部個人電腦還配備了讓您保持連線的 Wi‑Fi 技術，以及讓您執行多項工作的記憶體。",
                "titleArray": [
                  {
                    "tr_title": "專為成就更多而打造",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "頂級的連線能力",
                    "tr_title1": "連接重要的人事物",
                    "tr_title2": "WI‑FI 的重大進步",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "延長不須插電的時間",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "像專家一樣同時應付不同程式",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "支援 VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "生動逼真的虛擬實境",
                "tr_subtitle": "創造完全虛擬的世界需要什麼？在個人電腦上，您需要處理器、顯示卡與記憶體相互協調合作。",
                "titleArray": [
                  {
                    "tr_title": "為虛擬世界注入動&#xfeff;能",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "細節之中看見真實",
                    "tr_title1": "身歷其境",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED：<br/>景象怡人，呵護雙眼",
      "tr_sub_header": "藍光可能導致視覺不適，並且干擾睡眠週期。Samsung 的 SGS Eye Care OLED 經過顯示器測試，並非利用變更顏色的方式提供協助，而是透過減少潛在有害藍光的波長，讓雙眼舒適並減少眼睛疲勞。",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "減少有害的藍光"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "全方位的極致表現",
      "tr_sub_header": "OLED 超輕薄的設計，儘可能提高影像品質，同時儘可能輕量化。"
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "真黑",
      "tr_sub_header": "0.0005 尼特"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74%<br/><span class='conventional_text'>傳統</span>",
      "tr_sub_header": "120%<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "串流",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "串流",
                "tr_subtitle": "無窮無盡的娛樂",
                "tr_subtitle1": "這部適合串流的電腦包括下列優勢：",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "可以 HD 串流的處理器。"
                  },
                  {
                    "tr_title": "可提供鮮艷美觀影像的顯示器。"
                  },
                  {
                    "tr_title": "減少緩衝與載入時間的 Wi‑Fi。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "網頁瀏覽",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "網頁瀏覽",
                "tr_subtitle": "網路易如反掌",
                "tr_subtitle1": "這部適合網頁瀏覽的電腦包括下列優勢：",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "可快速載入豐富媒體頁面的處理器。"
                  },
                  {
                    "tr_title": "快速可靠的 Wi‑Fi。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "視訊通話",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "視訊通話",
                "tr_subtitle": "清晰的連線",
                "tr_subtitle1": "這部適合視訊聊天的電腦包括下列優勢：",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "可同時錄製及分享影像的處理器。"
                  },
                  {
                    "tr_title": "迅速、強大且可靠的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "可協助您展現清晰影像的網路攝影機。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "高速多工作業",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "高速多工作業",
                "tr_subtitle": "無須放慢速度，成就更多",
                "tr_subtitle1": "這部適合多工作業的電腦包括下列優勢：",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "可同時應付多個程式的處理器。"
                  },
                  {
                    "tr_title": "迅速、強大且可靠的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "順暢切換應用程式的記憶體。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "視訊通話",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "視訊通話",
                "tr_subtitle": "清晰的連線",
                "tr_subtitle1": "這部適合視訊聊天的電腦包括下列優勢：",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "可同時錄製及分享影像的處理器。"
                  },
                  {
                    "tr_title": "迅速、強大且可靠的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "可協助您展現清晰影像的網路攝影機。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "一般電腦遊戲",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "一般電腦遊戲",
                "tr_subtitle": "遊戲時間",
                "tr_subtitle1": "這部適合休閒遊戲的電腦包括下列優勢：",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "支援順暢遊戲的處理器。"
                  },
                  {
                    "tr_title": "可同時語音交談與直播的記憶體。"
                  },
                  {
                    "tr_title": "支援出色影像的顯示晶片。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "相片編輯",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "相片編輯",
                "tr_subtitle": "像素完美無缺的相片",
                "tr_subtitle1": "這部適合相片編輯的電腦包括下&#65279;列&#65279;優&#65279;勢&#65279;：",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "能夠編輯大批影像的處理器。"
                  },
                  {
                    "tr_title": "加速編輯軟體的記憶體。"
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 技術可讓您快速傳輸檔案。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "高速多工作業",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "高速多工作業",
                "tr_subtitle": "無須放慢速度，成就更多",
                "tr_subtitle1": "這部適合多工作業的電腦包括下列優勢：",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "可同時應付多個程式的處理器。"
                  },
                  {
                    "tr_title": "迅速、強大且可靠的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "順暢切換應用程式的記憶體。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "即時協作",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "即時協作",
                "tr_subtitle": "從任何地方工作",
                "tr_subtitle1": "這部適合線上協作的電腦包括下列優勢：",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "支援辦公室軟體的處理器。"
                  },
                  {
                    "tr_title": "您可信賴的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "以出色 HD 錄製的網路攝影機。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "影片編輯",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "影片編輯",
                "tr_subtitle": "打造迷人的電&#65279;影&#65279;",
                "tr_subtitle1": "這部適合影片編輯的電腦包括下列優勢：",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "可處理大型檔案的處理器。"
                  },
                  {
                    "tr_title": "讓軟體快速且快速回應的記憶體。"
                  },
                  {
                    "tr_title": "縮短彩現時間的顯示晶片。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "相片編輯",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "相片編輯",
                "tr_subtitle": "像素完美無缺的相片",
                "tr_subtitle1": "這部適合相片編輯的電腦包括下&#65279;列&#65279;優&#65279;勢&#65279;：",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "能夠編輯大批影像的處理器。"
                  },
                  {
                    "tr_title": "加速編輯軟體的記憶體。"
                  },
                  {
                    "tr_title": "Thunderbolt™ 4 技術可讓您快速傳輸檔案。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "影片編輯",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "影片編輯",
                "tr_subtitle": "打造迷人的電&#65279;影&#65279;",
                "tr_subtitle1": "這部適合影片編輯的電腦包括下列優勢：",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "可處理大型檔案的處理器。"
                  },
                  {
                    "tr_title": "讓軟體快速且快速回應的記憶體。"
                  },
                  {
                    "tr_title": "縮短彩現時間的顯示晶片。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "3D 建模",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D 建模",
                "tr_subtitle": "全新境界的創&#65279;作",
                "tr_subtitle1": "這部適合 3D 建模的電腦包括下列優勢：",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "可處理進階創作軟體的處理器。"
                  },
                  {
                    "tr_title": "可快速顯示大型檔案的記憶體。"
                  },
                  {
                    "tr_title": "適合強大影像創作與編輯的顯示晶片。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "即時協作",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "即時協作",
                "tr_subtitle": "從任何地方工作",
                "tr_subtitle1": "這部適合線上協作的電腦包括下列優勢：",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "支援辦公室軟體的處理器。"
                  },
                  {
                    "tr_title": "您可信賴的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "以出色 HD 錄製的網路攝影機。"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Intel® Unison™ app",
    "tr_title": "開啟您的互聯世界",
    "tr_subtitle": "從電腦管理手機通知、來電和簡訊，同時順暢無礙傳輸檔案與照片。<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "傳輸檔案和相片",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "撥打及接聽電話",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "傳送及接收文字簡訊",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "管理手機通知",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "開啟您的互聯世&#xfeff;界",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}