const Library_mobile_nl = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Met een [cpuDisplay] processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Met een [cpuDisplay] processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "Net als een brein regelt de processor wat je pc doet. Je processor is dus het startpunt van de video's die je bekijkt, de games die je speelt en de websites die je bezoekt.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "De processor is het brein van je pc"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "Prestaties en prijs-kwaliteitverhouding.",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Silver processor",
                    "tr_subtitle": "Blijf op de hoogte van je digitale wereld omdat je toegang hebt tot bestanden op het web. Schakel heel makkelijk tussen programma's om te multitasken en meer te doen in minder tijd.",
                    "tr_gen_core_title": "Intel® Pentium® Silver processor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® Gold processor",
                    "tr_subtitle": "Verbeterde productiviteit: hogere prestaties zodat je zelfs de drukste dagen aankunt. Gestroomlijnd internetten: laad snel uitgebreide webpagina's om meer te ontdekken en minder te wachten.",
                    "tr_gen_core_title": "Intel® Pentium® Gold processor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Celeron® processor",
                    "tr_subtitle": "Krachtige prestaties voor werk en entertainment. Geniet van films en online video's tot in de kleinste details. Doe meer en wacht minder.",
                    "tr_gen_core_title": "Intel® Celeron® processor"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Intel® Pentium® processor",
                    "tr_subtitle": "Ontworpen voor geweldig entertainment, videostreaming en productiviteit. Geniet van films en online video's tot in de kleinste details. Doe meer en wacht minder.",
                    "tr_gen_core_title": "Intel® Pentium® processor"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "De&nbsp;prijs die je wilt.",
                "tr_title": "Intel® processor",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "Waarde is een&nbsp;klasse&nbsp;apart",
                    "tr_subtitle": "Een Intel® processor kan zowat elke taak aan voor een betaalbare prijs. Schakel eenvoudig tussen software. Of beleef hoogwaardige graphics met stabiele prestaties.",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Maak sterkere verbindingen",
                    "tr_subtitle": "Met de levendigere videokwaliteit, helderdere audio en bijna 3x snellere internetconnectiviteit kun je overal vol vertrouwen samenwerken.<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "Het verschil is dag en nacht",
                    "tr_subtitle": "Van filmmarathons 's morgens tot op het laatste moment wijzigingen aanbrengen: de Intel® processor verlengt de accuduur van je device voor echt meeslepende, ononderbroken ervaringen.",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "In elke omgeving kun je leren",
                    "tr_subtitle": "Voer overal in je huis eenvoudig meerdere online leermiddelen uit."
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "Leer waar je wilt",
                    "tr_subtitle": "Volg overal je lessen, terwijl je diverse e-leermiddelen gebruikt."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Kies de kracht voor entertainment en productiviteit die past bij jouw budget en wensen.",
                "tr_title": "Intel prestaties voor elk budget",
                "tr_gen_core_title": "Intel® Celeron® processor",
                "processorCompare": {
                  "tr_title": "VIND HET JUISTE NIVEAU VAN PRESTATIES EN WAARDE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "Intel® Celeron® processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Snel, uitgebreid internetten",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Eenvoudig schakelen tussen programma's",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Casual gamen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K-video's streamen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Lichte foto- en videobewerking",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Snelle productiviteit met intelligente prestaties",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "Met een 7<sup>e</sup> generatie Intel® Core™ [cpu_fam] processor"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "Met een 6<sup>e</sup> generatie Intel® Core™ [cpu_fam] processor"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "Met een 5<sup>e</sup> generatie Intel® Core™ [cpu_fam] processor"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "Met een 4<sup>e</sup> generatie Intel® Core™ [cpu_fam] processor"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "Met een 3<sup>e</sup> generatie Intel® Core™ [cpu_fam] processor"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "Met een 2<sup>e</sup> generatie Intel® Core™ [cpu_fam] processor"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "Met een 1<sup>e</sup> generatie Intel® Core™ [cpu_fam] processor"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "Aanbevolen voor:",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Met een 8<sup>e</sup> generatie Intel® Core™ i3 processor",
                "tr_gen_core_title": "8<sup>e</sup> generatie Intel® Core™ i3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "De 9<sup>e</sup> generatie Intel® Core™ processors zijn ontworpen om jouw digitale wereld te versnellen. Door de combinatie van snelheid en goede prestaties kun je zonder frustraties doen wat je leuk vindt op je pc.",
                "tr_title": "Met een 9<sup>e</sup> generatie Intel® Core™ i3 processor",
                "tr_gen_core_title": "9<sup>e</sup> generatie Intel® Core™ i3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Veelzijdige prestaties en een lange accuduur zodat je overal door kunt blijven gaan.",
                "tr_title": "Energie voor de hele dag",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i3 processor",
                "processorCompare": {
                  "tr_title": "KIES DE PRESTATIES VAN DE 10<sup>e</sup> GENERATIE DIE BIJ JE PASSEN",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Films in 4K streamen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Snelle, betrouwbare connectiviteit",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fotomaskering en videofiltering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen in 1080p met vloeiende framerates",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "De beste keuze voor grote bestanden en creatieve programma's",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Stream films en maak presentaties zonder dat de accu snel leeg is.",
                "tr_title": "Energie voor de hele dag",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i3 processor"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Als je altijd onderweg bent, is er een laptop die speciaal voor jou is ontworpen.",
                "tr_title": "Maak kennis met een nieuwe laptopklasse",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i3 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "De 11<sup>e</sup> generatie Intel® Core™ i3 processor is speciaal ontwikkeld voor laptops en zorgt dat je meer kunt doen dan ooit, waar je ook bent.",
                "tr_title": "Haal meer uit je nieuwe laptop",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i3 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i3 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Als je met het hele gezin één desktop-pc gebruikt, heb je de kracht en veelzijdigheid nodig van een 11<sup>e</sup> generatie Intel® Core™ i3 processor.",
                "tr_title": "Wees overal op voorbereid met grensverleggende prestaties",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i3 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i3 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Met een 8<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title": "8<sup>e</sup> generatie Intel® Core™ i5 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "De 9<sup>e</sup> generatie Intel® Core™ processors zijn ontworpen om jouw digitale wereld te versnellen. Door de combinatie van snelheid en goede prestaties kun je zonder frustraties doen wat je leuk vindt op je pc.",
                "tr_title": "Met een 9<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title": "9<sup>e</sup> generatie Intel® Core™ i5 processor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "SOEPELE GAMEPLAY EN VR VOOR DE MEEST VEELEISENDE TITELS",
                "tr_title": "Gamen op hoog niveau begint hier",
                "tr_gen_core_title": "9<sup>e</sup> generatie Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ONTDEK DE INTEL® CORE™ PROCESSOR VOOR JOUW GAME.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-games",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen op wedstrijdniveau",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen, streamen en opnemen zonder compromissen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "De beste 9<sup>e</sup> generatie Intel® Core™ processor voor games",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Je kunt eenvoudig meerdere programma's tegelijk gebruiken, zodat je overal op voorbereid bent, waar de dag je ook brengt.",
                "tr_title": "Onderweg multitasken",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i5 processor",
                "processorCompare": {
                  "tr_title": "KIES DE PRESTATIES VAN DE 10<sup>e</sup> GENERATIE DIE BIJ JE PASSEN",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processor"
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Films in 4K streamen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Snelle, betrouwbare connectiviteit",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fotomaskering en videofiltering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen in 1080p met vloeiende framerates",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "De beste keuze voor grote bestanden en creatieve programma's",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Til alles naar een hoger niveau, van entertainment tot productiviteit en contentcreatie.",
                "tr_title": "Upgrade naar een krachtigere pc",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i5 processor"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Als je altijd onderweg bent, is er een laptop die speciaal voor jou is ontworpen.",
                "tr_title": "Maak kennis met een nieuwe laptopklasse",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i5 processor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Gebouwd voor naadloos streamen en AAA-gamen",
                "tr_title": "Speel op jouw voorwaarden",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ONTDEK DE 10<sup>e</sup> GENERATIE INTEL® CORE™ PROCESSOR VOOR JOUW GAME.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Naadloos AAA-games spelen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen op wedstrijdniveau",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen en opnemen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Geavanceerd contentcreatie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Topprestaties voor gamen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ontgrendeld en overklokbaar<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Gebouwd voor naadloos streamen en AAA-gamen",
                "tr_title": "Speel op jouw voorwaarden",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ONTDEK DE 10<sup>e</sup> GENERATIE INTEL® CORE™ PROCESSOR VOOR JOUW GAME.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Naadloos AAA-games spelen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen op wedstrijdniveau",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen en opnemen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Geavanceerd contentcreatie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Topprestaties voor gamen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ontgrendeld en overklokbaar<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Je kunt eenvoudig meerdere programma's tegelijk gebruiken, zodat je overal op voorbereid bent, waar de dag je ook brengt.",
                "tr_title": "Onderweg multitasken",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "10<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "De 11<sup>e</sup> generatie Intel® Core™ i5 processor brengt levendige visuele ervaringen en zeer diverse productiviteit naar de dunste en lichtste laptops.",
                "tr_title": "Indrukwekkend laptopvermogen",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Als je met het hele gezin één desktop-pc gebruikt, heb je de kracht en veelzijdigheid nodig van een 11<sup>e</sup> generatie Intel® Core™ i5 processor.",
                "tr_title": "Wees overal op voorbereid met grensverleggende prestaties",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "De 11<sup>e</sup> generatie Intel® Core™ i5 processor brengt levendige beelden en uitgebreide productiviteit naar dunne en lichte laptops.",
                "tr_title": "Indrukwekkend laptopvermogen",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "Met een 8<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title": "8<sup>e</sup> generatie Intel® Core™ i7 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "De 9<sup>e</sup> generatie Intel® Core™ processors zijn ontworpen om jouw digitale wereld te versnellen. Door de combinatie van snelheid en goede prestaties kun je zonder frustraties doen wat je leuk vindt op je pc.",
                "tr_title": "Met een 9<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title": "9<sup>e</sup> generatie Intel® Core™ i7 processor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "TOT 32% MEER FPS tijdens het gamen, streamen en opnemen in vergelijking met een 3 JAAR OUDE PC<sup>3</sup>",
                "tr_title": "De kracht om je mooiste gamingmomenten te delen",
                "tr_gen_core_title": "9<sup>e</sup> generatie Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ONTDEK DE INTEL® CORE™ PROCESSOR VOOR JOUW GAME.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-games",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen op wedstrijdniveau",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen, streamen en opnemen zonder compromissen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "De beste 9<sup>e</sup> generatie Intel® Core™ processor voor games",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Dit slanke device is gebouwd voor efficiëntie en kan een enorme invloed hebben op de manier waarop je creëert, verbinding maakt en streamt.",
                "tr_title": "Overal geavanceerde prestaties",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i7 processor",
                "processorCompare": {
                  "tr_title": "KIES DE PRESTATIES VAN DE 10<sup>e</sup> GENERATIE DIE BIJ JE PASSEN",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Films in 4K streamen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Snelle, betrouwbare connectiviteit",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Fotomaskering en videofiltering",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen in 1080p met vloeiende framerates",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "De beste keuze voor grote bestanden en creatieve programma's",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Geavanceerde prestaties die alles wat je doet naar een hoger niveau tillen.",
                "tr_title": "Kracht om alles te doen",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i7 processor"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Als je altijd onderweg bent, is er een laptop die speciaal voor jou is ontworpen.",
                "tr_title": "Maak kennis met een nieuwe laptopklasse",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i7 processor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Game, stream en neem op met een concurrentievoordeel",
                "tr_title": "Geef je gamewereld kracht",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ONTDEK DE 10<sup>e</sup> GENERATIE INTEL® CORE™ PROCESSOR VOOR JOUW GAME.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Naadloos AAA-games spelen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen op wedstrijdniveau",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen en opnemen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Geavanceerd contentcreatie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Topprestaties voor gamen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ontgrendeld en overklokbaar<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Game, stream en neem op met een concurrentievoordeel",
                "tr_title": "Geef je gamewereld kracht",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ONTDEK DE 10<sup>e</sup> GENERATIE INTEL® CORE™ PROCESSOR VOOR JOUW GAME.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Naadloos AAA-games spelen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen op wedstrijdniveau",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen en opnemen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Geavanceerd contentcreatie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Topprestaties voor gamen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ontgrendeld en overklokbaar<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dit slanke device is gebouwd voor efficiëntie en kan enorm veel betekenen voor de manier waarop je creëert, verbinding maakt en streamt.",
                "tr_title": "Topprestaties, waar je ook bent",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "10<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "De 11<sup>e</sup> generatie Intel® Core™ i7 processor brengt geavanceerde contentcreatie, soepel gamen en het beste entertainment naar ultra-draagbare laptops.",
                "tr_title": "Grensverleggende laptopprestaties",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Maak van je desktop-pc dé plek voor alles wat je thuis maar nodig hebt met de grensverleggende prestaties van de 11<sup>e</sup> generatie Intel® Core™ i7 processor.",
                "tr_title": "Werk. Leer. Geniet. Zonder grenzen.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "De 11<sup>e</sup> generatie Intel® Core™ i7 processor brengt geavanceerde contentcreatie, vloeiend gamen en het beste entertainment naar dunne en lichte laptops.",
                "tr_title": "Grensverleggende laptopprestaties",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Maak van je desktop-pc dé plek voor alles wat je thuis maar nodig hebt met de grensverleggende prestaties van de 11<sup>e</sup> generatie Intel® Core™ i7 processor.",
                "tr_title": "Grenzeloos creëren",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "De 11<sup>e</sup> generatie Intel® Core™ i7 processor is nu nog beter met geavanceerde contentcreatie, vloeiend gamen en het beste entertainment op dunne en lichte laptops.",
                "tr_title": "Grensverleggende laptopprestaties",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Met een 8<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title": "8<sup>e</sup> generatie Intel® Core™ i9 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "De 9<sup>e</sup> generatie Intel® Core™ processors zijn ontworpen om jouw digitale wereld te versnellen. Door de combinatie van snelheid en goede prestaties kun je zonder frustraties doen wat je leuk vindt op je pc.",
                "tr_title": "Met een 9<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title": "9<sup>e</sup> generatie Intel® Core™ i9 processor"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "TOT 41% MEER FPS tijdens het gamen, streamen en opnemen in vergelijking met een 3 JAAR OUDE PC<sup>4</sup>",
                "tr_title": "Gamers vragen. Intel® Core™ i9 maakt het waar.",
                "tr_gen_core_title": "9<sup>e</sup> generatie Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ONTDEK DE INTEL® CORE™ PROCESSOR VOOR JOUW GAME.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-games",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen op wedstrijdniveau",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen, streamen en opnemen zonder compromissen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "De beste 9<sup>e</sup> generatie Intel® Core™ processor voor games",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Vergroot je potentieel met de ultieme game-ervaring",
                "tr_title": "Strijd op het hoogste niveau",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ONTDEK DE 10<sup>e</sup> GENERATIE INTEL® CORE™ PROCESSOR VOOR JOUW GAME.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Naadloos AAA-games spelen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen op wedstrijdniveau",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen en opnemen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Geavanceerd contentcreatie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Topprestaties voor gamen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ontgrendeld en overklokbaar<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Vergroot je potentieel met de ultieme game-ervaring",
                "tr_title": "Strijd op het hoogste niveau",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ONTDEK DE 10<sup>e</sup> GENERATIE INTEL® CORE™ PROCESSOR VOOR JOUW GAME.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Naadloos AAA-games spelen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen op wedstrijdniveau",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen en opnemen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Geavanceerd contentcreatie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Topprestaties voor gamen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ontgrendeld en overklokbaar<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Vergroot je potentieel met de ultieme laptop voor contentmakers",
                "tr_title": "Creëer op het hoogste niveau",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "10<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ laptops met een 11<sup>e</sup> generatie Intel® Core™ i5 processor en Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics bieden je ongelooflijke snelheid en prestaties in een dun en licht design.",
                "tr_title": "Meer power voor een superlaptop",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ laptops met een 11<sup>e</sup> generatie Intel® Core™ i7 processor en Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics bieden je geavanceerde snelheden en prestaties voor een hoogwaardige mobiele ervaring.",
                "tr_title": "Geavanceerde power voor een superlaptop",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Geniet van een geweldige balans tussen prestaties, media en connectiviteit voor een geweldige prijs.",
                "tr_title": "Prestaties en connectiviteit voor een geweldige prijs.",
                "tr_gen_core_title": "Intel® Pentium® Silver processor",
                "processorCompare": {
                  "tr_title": "VIND HET JUISTE NIVEAU VAN PRESTATIES EN WAARDE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Snel, uitgebreid internetten",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Eenvoudig schakelen tussen programma's",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Casual gamen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K-video's streamen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Lichte foto- en videobewerking",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Snelle productiviteit met intelligente prestaties",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Geniet van naadloos multitasken, eenvoudige foto- en videobewerking, video's in 4K en levendige graphics voor een ongelooflijke prijs.",
                "tr_title": "Indrukwekkende prestaties voor werk en ontspanning",
                "tr_gen_core_title": "Intel® Pentium® Gold processor",
                "processorCompare": {
                  "tr_title": "VIND HET JUISTE NIVEAU VAN PRESTATIES EN WAARDE",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Silver processor"
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "Intel® Pentium® Gold processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "Intel® Core™ i3 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Snel, uitgebreid internetten",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Eenvoudig schakelen tussen programma's",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Casual gamen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "4K-video's streamen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Lichte foto- en videobewerking",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Snelle productiviteit met intelligente prestaties",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "AAA gaming en een lange accuduur in een ultradraagbare pc. Zonder compromissen voor de manier waarop je speelt.",
                "tr_title": "Geen compromissen nodig.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i5 processor",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ONTDEK JOUW INTEL® CORE™ ULTRADRAAGBARE GAMEPROCESSOR.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11<sup>e</sup> generatie Intel® Core™ i5-11300H processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11<sup>e</sup> generatie Intel® Core™ i7-11370H processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11<sup>e</sup> generatie Intel® Core™ i7-11375H Special Edition processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Tot <span class='style_badge'>4,4 GHz</span> max. kloksnelheid",
                      "tr_td2_title": "Tot <span class='style_badge'>4,8 GHz</span> max. kloksnelheid",
                      "tr_td3_title": "Tot <span class='style_badge'>5,0 GHz</span> max. kloksnelheid"
                    },
                    {
                      "tr_td1_title": "4 cores /8&nbsp;threads",
                      "tr_td2_title": "4 cores /8&nbsp;threads",
                      "tr_td3_title": "4 cores /8&nbsp;threads"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost Technologie",
                      "tr_td2_title": "Intel® Turbo Boost Technologie",
                      "tr_td3_title": "Intel® Turbo Boost Max Technologie 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® Cachebeheer",
                      "tr_td2_title": "12 MB Intel® Cachebeheer",
                      "tr_td3_title": "12 MB Intel® Cachebeheer"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "1080p AAA-gamen met hoge FPS en hoge instellingen en uitstekende losgekoppelde prestaties in een ultradraagbare pc.",
                "tr_title": "Nu hoef je nooit meer te kiezen.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i7 processor",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ONTDEK JOUW INTEL® CORE™ ULTRADRAAGBARE GAMEPROCESSOR.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11<sup>e</sup> generatie Intel® Core™ i5-11300H processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11<sup>e</sup> generatie Intel® Core™ i7-11370H processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11<sup>e</sup> generatie Intel® Core™ i7-11375H Special Edition processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Tot <span class='style_badge'>4,4 GHz</span> max. kloksnelheid",
                      "tr_td2_title": "Tot <span class='style_badge'>4,8 GHz</span> max. kloksnelheid",
                      "tr_td3_title": "Tot <span class='style_badge'>5,0 GHz</span> max. kloksnelheid"
                    },
                    {
                      "tr_td1_title": "4 cores /8&nbsp;threads",
                      "tr_td2_title": "4 cores /8&nbsp;threads",
                      "tr_td3_title": "4 cores /8&nbsp;threads"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost Technologie",
                      "tr_td2_title": "Intel® Turbo Boost Technologie",
                      "tr_td3_title": "Intel® Turbo Boost Max Technologie 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® Cachebeheer",
                      "tr_td2_title": "12 MB Intel® Cachebeheer",
                      "tr_td3_title": "12 MB Intel® Cachebeheer"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Tot 5,0 GHz maximale kloksnelheid om de grenzen van je games te verleggen en een ultradraagbare pc om ze overal mee naartoe te nemen.",
                "tr_title": "Klein maar zeer krachtig.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i7 Special&nbsp;Edition processor",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition processor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "ONTDEK JOUW INTEL® CORE™ ULTRADRAAGBARE GAMEPROCESSOR.",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "11<sup>e</sup> generatie Intel® Core™ i5-11300H processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "11<sup>e</sup> generatie Intel® Core™ i7-11370H processor"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "11<sup>e</sup> generatie Intel® Core™ i7-11375H Special Edition processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "Tot <span class='style_badge'>4,4 GHz</span> max. kloksnelheid",
                      "tr_td2_title": "Tot <span class='style_badge'>4,8 GHz</span> max. kloksnelheid",
                      "tr_td3_title": "Tot <span class='style_badge'>5,0 GHz</span> max. kloksnelheid"
                    },
                    {
                      "tr_td1_title": "4 cores /8&nbsp;threads",
                      "tr_td2_title": "4 cores /8&nbsp;threads",
                      "tr_td3_title": "4 cores /8&nbsp;threads"
                    },
                    {
                      "tr_td1_title": "Intel® Turbo Boost Technologie",
                      "tr_td2_title": "Intel® Turbo Boost Technologie",
                      "tr_td3_title": "Intel® Turbo Boost Max Technologie 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB Intel® Cachebeheer",
                      "tr_td2_title": "12 MB Intel® Cachebeheer",
                      "tr_td3_title": "12 MB Intel® Cachebeheer"
                    },
                    {
                      "tr_td1_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
                      "tr_td2_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
                      "tr_td3_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "De 11<sup>e</sup> generatie Intel® Core™ i7 Special Edition processor brengt geavanceerde contentcreatie, soepel gamen en het beste entertainment naar ultradraagbare pc's.",
                "tr_title": "Grensverleggende laptopprestaties",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i7 Special&nbsp;Edition processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i7 SPECIAL EDITION PROCESSOR"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "De 11<sup>e</sup> generatie Intel® Core™ i7 Special Edition processor brengt geavanceerde contentcreatie, soepel gamen en het beste entertainment naar ultradraagbare pc's.",
                "tr_title": "Grensverleggende laptopprestaties",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i7 Special&nbsp;Edition processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i7 SPECIAL EDITION PROCESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 Special Edition processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "Hoge kloksnelheden en veel cores werken naadloos samen om meer uit competitief gamen en complexe contentcreatie te halen.",
                "tr_title": "Enorme kracht. Perfect gebalanceerd.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i9 processor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Haal meer uit je games met de juiste balans van kloksnelheid en coreprestaties, zowel voor competitief gamen met hoge FPS als voor meeslepende AAA-ervaringen.",
                "tr_title": "Kracht met een doel",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i5 processor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Veeleisende games spelen, opnemen en streamen met hoge FPS en moeiteloos overschakelen naar zware multitaskwerkbelastingen.",
                "tr_title": "Kracht om tot het uiterste te gaan",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i7 processor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hoge kloksnelheden en veel cores werken naadloos samen om meer uit competitief gamen en complexe contentcreatie te halen.",
                "tr_title": "Enorme kracht. Perfect gebalanceerd.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i9 processor",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Stream films en maak presentaties zonder dat de accu snel leeg is.",
                "tr_title": "Energie voor de hele dag",
                "tr_gen_core_title": "Intel® Core™ i3 processor met Intel® Hybrid Technology",
                "tr_subTitle2": "Intel® Core™ i3 processor met Intel® Hybrid Technology"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Veelzijdige prestaties en een lange accuduur zodat je overal door kunt blijven gaan.",
                "tr_title": "Energie voor de hele dag",
                "tr_gen_core_title": "Intel® Core™ i3 processor met Intel® Hybrid Technology",
                "tr_subTitle2": "Intel® Core™ i3 processor met Intel® Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Je kunt eenvoudig meerdere programma's tegelijk gebruiken, zodat je overal op voorbereid bent, waar de dag je ook brengt.",
                "tr_title": "Onderweg multitasken",
                "tr_gen_core_title": "Intel® Core™ i5 processor met Intel® Hybrid Technology",
                "tr_subTitle2": "Intel® Core™ i5 processor met Intel® Hybrid Technology"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "Til alles naar een hoger niveau, van entertainment tot productiviteit en contentcreatie.",
                "tr_title": "Upgrade naar een krachtigere pc",
                "tr_gen_core_title": "Intel® Core™ i5 processor met Intel® Hybrid Technology",
                "tr_subTitle2": "Intel® Core™ i5 processor met Intel® Hybrid Technology"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Gamingprestaties van desktopkwaliteit gecombineerd met mobiliteit, of het nu gaat om competitieve games met hoge FPS of meeslepende AAA-ervaringen.",
                "tr_title": "Kracht met een doel",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i5 processor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Gamingprestaties van desktopkwaliteit gecombineerd met mobiliteit, of het nu gaat om competitieve games met hoge FPS of meeslepende AAA-ervaringen.",
                "tr_title": "Kracht met een doel",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Een laptop die tegelijkertijd kan gamen, opnemen en streamen met een hoge FPS en moeiteloos overschakelt op veeleisend multitasken.",
                "tr_title": "Kracht om tot het uiterste te gaan",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i7 processor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Een laptop die tegelijkertijd kan gamen, opnemen en streamen met een hoge FPS en moeiteloos overschakelt op veeleisend multitasken.",
                "tr_title": "Kracht om tot het uiterste te gaan",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hoge kloksnelheden en veel cores werken naadloos samen om meer uit competitief gamen en complexe contentcreatie te halen, waar je ook bent.",
                "tr_title": "Enorme kracht. Perfect gebalanceerd.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i9 processor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hoge kloksnelheden en veel cores werken naadloos samen om meer uit competitief gamen en complexe contentcreatie te halen, waar je ook bent.",
                "tr_title": "Enorme kracht. Perfect gebalanceerd.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hoge kloksnelheden, veel cores en ontgrendelde capaciteit werken naadloos samen om meer uit competitief gamen en complexe contentcreatie te halen, waar je ook bent.",
                "tr_title": "Ontgrendelde kracht. Perfect gebalanceerd.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i9 processor",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hoge kloksnelheden, veel cores en ontgrendelde capaciteit werken naadloos samen om meer uit competitief gamen en complexe contentcreatie te halen, waar je ook bent.",
                "tr_title": "Ontgrendelde kracht. Perfect gebalanceerd.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hoge kloksnelheden en veel cores werken naadloos samen om meer uit competitief gamen en complexe contentcreatie te halen.",
                "tr_title": "Enorme kracht. Perfect gebalanceerd.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hoge kloksnelheden en veel cores werken naadloos samen om meer uit complexe contentcreatie te halen, waar je ook bent.",
                "tr_title": "Enorme kracht. Perfect gebalanceerd.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hoge kloksnelheden en veel cores werken naadloos samen om meer uit complexe contentcreatie te halen.",
                "tr_title": "Enorme kracht. Perfect gebalanceerd.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Vergroot je potentieel met de ultieme game-ervaring",
                "tr_title": "Strijd op het hoogste niveau",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ONTDEK DE 10<sup>e</sup> GENERATIE INTEL® CORE™ PROCESSOR VOOR JOUW GAME.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Naadloos AAA-games spelen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen op wedstrijdniveau",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen en opnemen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Geavanceerd contentcreatie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Topprestaties voor gamen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ontgrendeld en overklokbaar<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Vergroot je potentieel met de ultieme game-ervaring",
                "tr_title": "Strijd op het hoogste niveau",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ONTDEK DE 10<sup>e</sup> GENERATIE INTEL® CORE™ PROCESSOR VOOR JOUW GAME.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Naadloos AAA-games spelen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen op wedstrijdniveau",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Streamen en opnemen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Geavanceerd contentcreatie",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Topprestaties voor gamen",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "Ontgrendeld en overklokbaar<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,8 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,1 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 10<br/>Threads: 20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Als je altijd onderweg bent, is er een laptop die speciaal voor jou is ontworpen.",
                "tr_title": "Maak kennis met een nieuwe laptopklasse",
                "tr_gen_core_title": "10<sup>e</sup> generatie Intel® Core™ i9 processor"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hoge kloksnelheden en veel cores werken naadloos samen om meer uit competitief gamen en complexe contentcreatie te halen.",
                "tr_title": "Ontgrendelde kracht. Perfect gebalanceerd.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hoge kloksnelheden, veel cores en ontgrendelde prestaties werken naadloos samen om meer uit complexe contentcreatie te halen, waar je ook bent.",
                "tr_title": "Ontgrendelde kracht. Perfect gebalanceerd.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "Hoge kloksnelheden en veel cores werken naadloos samen om meer uit complexe contentcreatie te halen.",
                "tr_title": "Ontgrendelde kracht. Perfect gebalanceerd.",
                "tr_gen_core_title": "11<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "11<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Intel® Cachebeheer</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "Soepele gameplay en VR bij de meest veeleisende titels",
                "tr_title": "Gamen op hoog niveau begint hier",
                "tr_gen_core_title": "9<sup>e</sup> generatie Intel® Core™ i5 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ONTDEK DE INTEL® CORE™ PROCESSOR VOOR JOUW GAME.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-games",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen op wedstrijdniveau",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen, streamen en opnemen zonder compromissen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "De beste 9<sup>e</sup> generatie Intel® Core™ processor voor games",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "TOT 32% MEER FPS <br/><span class='tr_subtitle_font'>bij het gamen, streamen en opnemen, in vergelijking met een 3 jaar oude pc<sup>3</sup></span>",
                "tr_title": "De kracht om je mooiste gamingmomenten te delen",
                "tr_gen_core_title": "9<sup>e</sup> generatie Intel® Core™ i7 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ONTDEK DE INTEL® CORE™ PROCESSOR VOOR JOUW GAME.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-games",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen op wedstrijdniveau",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen, streamen en opnemen zonder compromissen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "De beste 9<sup>e</sup> generatie Intel® Core™ processor voor games",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "TOT 41% MEER FPS <br/><span class='tr_subtitle_font'>bij het gamen, streamen en opnemen, in vergelijking met een 3 jaar oude pc<sup>4</sup></span>",
                "tr_title": "Gamers vragen. Intel® Core™ i9 maakt het waar.",
                "tr_gen_core_title": "9<sup>e</sup> generatie Intel® Core™ i9 processor",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Threads</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>Cores</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>Tot <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>Cache</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "Ontgrendeld",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "ONTDEK DE INTEL® CORE™ PROCESSOR VOOR JOUW GAME.",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i5 processor"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i7 processor"
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "Intel® Core™ i9 processor"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA-games",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen op wedstrijdniveau",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "Gamen, streamen en opnemen zonder compromissen",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "De beste 9<sup>e</sup> generatie Intel® Core™ processor voor games",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,3 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 4<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,6 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 6<br/>Threads: 6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 4,9 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>Kloksnelheid<sup>2</sup>: tot 5 GHz</span>",
                      "tr_subTitle": "<span class='small_font'>Cores: 8<br/>Threads: 16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Gemaakt voor de volgende generatie games",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i5 processor",
                    "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i7 processor",
                    "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i9 processor",
                    "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Cores</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] op Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] op Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Battlestation en werkstation",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i7 processor",
                    "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i9 processor",
                    "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Cores</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] op Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] op Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Betrouwbare prestaties wanneer je die het meest nodig hebt",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i3 processor",
                "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i3 PROCESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Betere prestaties op de momenten dat het nodig is",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Betere prestaties op de momenten dat het nodig is",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Superieure prestaties op de momenten dat het nodig is",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Superieure prestaties op de momenten dat het nodig is",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ laptops bieden je prestaties, ingebouwde intelligentie voor vlotte videogesprekken, sneller internet, lange accuduur en meer.",
                "tr_title": "Betere prestaties op de momenten dat het nodig is.",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ laptops bieden je prestaties, ingebouwde intelligentie voor vlotte videogesprekken, sneller internet, lange accuduur en meer.",
                "tr_title": "Superieure prestaties op de momenten dat het nodig is",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Evo™ laptops bieden je prestaties, ingebouwde intelligentie voor vlotte videogesprekken, sneller internet, lange accuduur en meer.",
                "tr_title": "Extreme prestaties in een hoogwaardige laptop",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Met Intel® Turbo Boost Technologie 2.0 tot wel [max_turbo_frequency] GHz",
                "tr_title": "Built for Business",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Ruisonderdrukking en achtergrondvervaging",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatisch afgestemde prestaties geven prioriteit aan wat je het meest doet",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Vloeiende online calls van hoge kwaliteit",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Out-of-the-box hardwarebeveiliging",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Mogelijkheden voor devicebeheer op afstand",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatische optimalisatie van je wifiprestaties<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Krachtig voor data-driven apps",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "De meeste productiviteit met verbeterde prestaties",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Met Intel® Turbo Boost Technologie 2.0 tot wel [max_turbo_frequency] GHz",
                "tr_title": "Werkt hard, zodat jij je kunt richten op je bedrijf",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Ruisonderdrukking en achtergrondvervaging",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatisch afgestemde prestaties geven prioriteit aan wat je het meest doet",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Vloeiende online calls van hoge kwaliteit",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Out-of-the-box hardwarebeveiliging",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Mogelijkheden voor devicebeheer op afstand",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatische optimalisatie van je wifiprestaties<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Krachtig voor data-driven apps",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "De meeste productiviteit met verbeterde prestaties",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "Met Intel® Turbo Boost Technologie 2.0 tot wel [max_turbo_frequency] GHz",
                "tr_title": "Versnel je onderneming",
                "tr_gen_core_title": "12<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "12<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Ruisonderdrukking en achtergrondvervaging",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatisch afgestemde prestaties geven prioriteit aan wat je het meest doet",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Vloeiende online calls van hoge kwaliteit",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Out-of-the-box hardwarebeveiliging",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Mogelijkheden voor devicebeheer op afstand",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Automatische optimalisatie van je wifiprestaties<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "Krachtig voor data-driven apps",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "De meeste productiviteit met verbeterde prestaties",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Betrouwbare prestaties om meer gedaan te krijgen",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i3 processor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Echte prestaties om meer gedaan te krijgen",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "13<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Echte prestaties om meer gedaan te krijgen",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "13<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Krachtige prestaties om meer gedaan te krijgen",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "13<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Krachtige prestaties om meer gedaan te krijgen",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "13<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Meer dan prestaties",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "13<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Cores</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] op Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] op Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Maak naadloos verbinding met je telefoon<sup>24</sup>, kies op slimme wijze de beste wifi en laad snel met een accu die lang meegaat.",
                "tr_title": "Meer dan prestaties om overal meer gedaan te krijgen",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "13<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Maak naadloos verbinding met je telefoon<sup>24</sup>, kies op slimme wijze de beste wifi en laad snel met een accu die lang meegaat.",
                "tr_title": "Meer dan prestaties waarbij je pc nog meer tegelijkertijd kan",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "13<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Maak naadloos verbinding met je telefoon<sup>24</sup>, kies op slimme wijze de beste wifi en laad snel met een accu die lang meegaat.",
                "tr_title": "Meer dan prestaties om door de meest veeleisende taken te vliegen",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "13<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Meer dan prestaties",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i5 processor",
                    "tr_gen_core_title_uppercase": "13<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i7 processor",
                    "tr_gen_core_title_uppercase": "13<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i9 processor",
                    "tr_gen_core_title_uppercase": "13<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Cores</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] op Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] op Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Meer dan prestaties",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Cores</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] op Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] op Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Betrouwbare prestaties om meer gedaan te krijgen",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "Intel® Core™ i3 processor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Echte prestaties om meer gedaan te krijgen",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Krachtige prestaties om meer gedaan te krijgen",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Meer dan prestaties",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i5 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i5 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i7 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i7 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ i9 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ i9 PROCESSOR",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Cores</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] op Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] op Efficient-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ i9 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Betrouwbare prestaties om meer gedaan te krijgen",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 3 processor",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Echte prestaties om meer gedaan te krijgen",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 5 processor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 5 PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Krachtige prestaties om meer gedaan te krijgen",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "Intel® Core™ 7 processor",
                "tr_gen_core_title_uppercase": "INTEL® CORE™ 7 PROCESSOR",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 3 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "Intel® Core™ 7 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "Processor",
                "tr_title": "Gamen zonder compromissen",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 5 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 5 PROCESSOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 7 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 7 PROCESSOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "Intel® Core™ Ultra 9 processor",
                    "tr_gen_core_title_uppercase": "INTEL® CORE™ ULTRA 9 PROCESSOR",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Cores</span></span>",
                    "tr_badgePreTitle": "[p_core] Performance-cores",
                    "tr_badgeSubTitle": "[e_core] Efficient-cores",
                    "tr_badgeSubTitle1": "[l_core] LPE-cores"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>Threads</span></span>",
                    "tr_badgePreTitle": "[p_thread] op Performance-cores",
                    "tr_badgeSubTitle": "[e_thread] op Efficient-cores",
                    "tr_badgeSubTitle1": "[l_thread] op LPE-cores"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 5 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 7 processor",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "Intel® Core™ Ultra 9 processor",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Gamen zonder compromissen",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Gamen zonder compromissen",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Gamen zonder compromissen",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "Gamen zonder compromissen",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "Gamen zonder compromissen",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "Gamen zonder compromissen",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Kleine bedrijven verdienen de oplossingen van grote bedrijven",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i5 processor",
                "tr_gen_core_title_uppercase": "13<sup>e</sup> GENERATIE INTEL® CORE™ i5 PROCESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Ruisonderdrukking en achtergrondvervaging",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatisch afgestemde prestaties geven prioriteit aan wat je het meest doet",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Vloeiende online calls van hoge kwaliteit",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Out-of-the-box hardwarebescherming",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Beveilig apparaten en gegevens tegen aanvallen door fysieke manipulatie",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Kleine bedrijven verdienen de oplossingen van grote bedrijven",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i7 processor",
                "tr_gen_core_title_uppercase": "13<sup>e</sup> GENERATIE INTEL® CORE™ i7 PROCESSOR",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Ruisonderdrukking en achtergrondvervaging",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatisch afgestemde prestaties geven prioriteit aan wat je het meest doet",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Vloeiende online calls van hoge kwaliteit",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Out-of-the-box hardwarebescherming",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Beveilig apparaten en gegevens tegen aanvallen door fysieke manipulatie",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "Kleine bedrijven verdienen de oplossingen van grote bedrijven",
                "tr_gen_core_title": "13<sup>e</sup> generatie Intel® Core™ i9 processor",
                "tr_gen_core_title_uppercase": "13<sup>e</sup> GENERATIE INTEL® CORE™ i9 PROCESSOR",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "VERGELIJK DE PROCESSOR",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "Huidig device",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "Ruisonderdrukking en achtergrondvervaging",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Automatisch afgestemde prestaties geven prioriteit aan wat je het meest doet",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Vloeiende online calls van hoge kwaliteit",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "Out-of-the-box hardwarebescherming",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "Beveilig apparaten en gegevens tegen aanvallen door fysieke manipulatie",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "Maak nieuwe AI-ervaringen voor bedrijven mogelijk",
                "tr_title": "Wees voorbereid op de toekomst met Intel® Core™ Ultra&nbsp;[XX] processors en Intel vPro®",
                "tr_gen_core_title": "Intel® Core™ Ultra [XX] processor",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "GEEF JE BEDRIJF EEN BOOST MET DE AI-PC'S VAN INTEL",
                    "tr_badgeSubTitle": "Krachtige pc's die een geweldige ervaring creëren, vormen de kern van de zakelijke productiviteit. Intel vPro® verhoogt de effectiviteit en prestaties van medewerkers voor elke werkbelasting en applicatie dankzij een nieuwe architectuur die superieure energiezuinige prestaties levert voor verschillende, complexe zakelijke werkbelastingen door de juiste taak op het juiste moment naar de juiste engine te sturen.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "MEERLAAGSE BEVEILIGINGSFUNCTIES DIE ONMIDDELLIJK GEBRUIKSKLAAR ZIJN",
                    "tr_badgeSubTitle": "Nieuwe en opkomende bedreigingen vereisen een alomvattende veiligheidsaanpak. Intel vPro® biedt bescherming op hardwareniveau en in de hele stack, zodat je bedrijf beter beschermd is. Deze unieke beveiligingsmaatregelen die onmiddellijk gebruiksklaar zijn, waaronder door AI aangedreven detectie van bedreigingen, helpen je gebruikers, je gegevens en je bedrijf te beschermen.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "INGEBOUWDE TOOLS HOUDEN GEBRUIKERS VANAF VRIJWEL ELKE LOCATIE PRODUCTIEF",
                    "tr_badgeSubTitle": "Een bedrijf heeft niet alleen veel werkruimtes, maar ook veel pc's. Intel vPro® biedt moderne, op hardware gebaseerde beheermogelijkheden om bedrijven te helpen de last van het pc-parkbeheer te verlichten, de ondersteuning te vereenvoudigen en de ervaringen van eindgebruikers te verbeteren, en dit alles terwijl duurzame computerpraktijken mogelijk worden gemaakt.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Meer dan een geweldige pc, een AI-pc",
                "tr_subtitle1": "MET EEN INTEL®&nbsp;CORE™ ULTRA PROCESSOR (SERIES&nbsp;2)",
                "tr_subtitle2": "Nu kan het op je Intel AI-pc",
                "tr_subtitle": "Intel's meest efficiënte, veilige en hoog presterende mobiele processor<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 processor (Series&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Creatiever</span> met AI-tools die je sneller doen werken"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Productiever</span> met tijdbesparende AI-assistentie"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Veiliger</span> met ingebouwde gegevensbeveiliging"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Meer dan een geweldige pc, een AI-pc",
                "tr_subtitle1": "MET EEN INTEL®&nbsp;CORE™ ULTRA PROCESSOR (SERIES&nbsp;2)",
                "tr_subtitle2": "Nu kan het op je Intel AI-pc",
                "tr_subtitle": "Intel's meest efficiënte, veilige en hoog presterende mobiele processor<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 processor (Series&nbsp;2)",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>Creatiever</span> met AI-tools die je sneller doen werken"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Productiever</span> met tijdbesparende AI-assistentie"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>Veiliger</span> met ingebouwde gegevensbeveiliging"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Meer dan een geweldige pc, een AI-pc",
                "tr_subtitle1": "MET EEN INTEL®&nbsp;CORE™ ULTRA PROCESSOR (SERIES&nbsp;2)",
                "tr_subtitle2": "Nu kan het op je Intel AI-pc",
                "tr_subtitle": "Intel's meest efficiënte, veilige en hoog presterende mobiele processor<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;5 processor (Series&nbsp;2)"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processor",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "Meer dan een geweldige pc, een AI-pc",
                "tr_subtitle1": "MET EEN INTEL®&nbsp;CORE™ ULTRA PROCESSOR (SERIES&nbsp;2)",
                "tr_subtitle2": "Nu kan het op je Intel AI-pc",
                "tr_subtitle": "Intel's meest efficiënte, veilige en hoog presterende mobiele processor<sup>39</sup>",
                "tr_gen_core_title": "Intel®&nbsp;Core™ Ultra&nbsp;7 processor (Series&nbsp;2)"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_tileTitle1": "Dedicated graphics",
                "tr_title": "WAT IS EEN GRAFISCHE PROCESSOR?",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "De graphics processing unit (GPU) produceert de beelden die je ziet op je scherm.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Geïntegreerde GPU",
                    "tr_badgeSubTitle": "Een geïntegreerde GPU die hetzelfde RAM gebruikt als de rest van de computer, waardoor deze energiezuiniger is.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Losse GPU",
                    "tr_badgeSubTitle": "Dit is het krachtigste type GPU omdat dit type over eigen RAM en andere resources beschikt die zijn gereserveerd voor het produceren van uitzonderlijke graphics.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "Hybride GPU",
                    "tr_badgeSubTitle": "Dit is het krachtigste type GPU omdat dit type over eigen RAM en andere resources beschikt die zijn gereserveerd voor het produceren van uitzonderlijke graphics.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "Gestroomlijnde foto- en videobewerking",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "Binge video's in 4K",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "Speel populaire games met verbluffende details",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "Dual GPU's",
                    "tr_badgeSubTitle": "Bepaalde systemen, zoals pc's met Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics, hebben zowel een in de processor geïntegreerde GPU als een losse GPU om de grafische werkbelasting te verdelen.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "Geïntegreerde GPU",
                    "tr_badgeSubTitle": "Een geïntegreerde GPU is ingebouwd in dezelfde chip als de centrale processor of CPU. Dit type GPU maakt gebruik van dezelfde RAM en andere resources als de rest van de computer, waardoor deze energiezuiniger is en in een dunner en lichter device past.",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "Losse GPU",
                    "tr_badgeSubTitle": "Dit is het krachtigste type GPU omdat dit type over eigen RAM en andere resources beschikt die zijn gereserveerd voor het produceren van uitzonderlijke graphics.",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Standaard",
                    "tr_badgeSubTitle": "Tot 4 GB VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wedstrijdniveau",
                    "tr_badgeSubTitle": "Tot 8 GB VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Ultra",
                    "tr_badgeSubTitle": "Meer dan 8&nbsp;GB VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_title": "Intense graphics",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "De krachtigste grafische verwerking vereist een grafische kaart die zich uitsluitend richt op het maken van mooie, heldere beelden en vloeiende animaties.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_title": "Rijke en levendige ingebouwde graphics",
                "tr_pretitle": "Intel® Iris® Plus graphics",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Met Intel® Iris® Plus graphics kun je volledig opgaan in 4K HDR-video en gamen in Full HD zonder dat je een extra grafische kaart nodig hebt.",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_title": "Prachtige beelden, moeiteloze prestaties",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Intel® Graphics levert transformationele prestaties van geïntegreerde graphics om vloeiend ontwerpen voor makers en rijke game-ervaringen te ondersteunen.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_title": "Intel® Iris® Pro graphics",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_title": "Intel® UHD graphics met X<sup class='small_text_sup_xe'>e</sup> architectuur",
                "tr_pretitle": "Overweldigende kijkervaringen",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "Ervaar gestroomlijnde foto- en videobewerking, binge hoogwaardige video's in 4K en speel populaire games met verbluffende details.",
                "tr_photo_text": "Gestroomlijnde foto- en videobewerking",
                "tr_video_text": "Binge video's in 4K",
                "tr_game_text": "Speel populaire games met verbluffende details",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "VEEL MEER DAN MOOIE PLAATJES",
                "tr_subtitle": "De grafische kaart (of GPU) werkt nauw samen met de processor (of CPU) en helpt bij het bepalen hoe je games werken en hoe ze eruitzien. Het VRAM of video-RAM wordt alleen gebruikt voor het maken van beelden en is een snelle manier om te zien wat een GPU kan:",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "VOOR INTENSE GAME-GRAPHICS",
                "tr_subtitle": "De krachtigste grafische verwerking vereist een aparte grafische kaart die volledig ingezet kan worden voor het maken van mooie, heldere beelden en vloeiende animaties",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "VOOR INTENSE GAME-GRAPHICS",
                "tr_subtitle": "De krachtigste grafische verwerking vereist een aparte grafische kaart die volledig ingezet kan worden voor het maken van mooie, heldere beelden en vloeiende animaties",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "VEEL MEER DAN MOOIE PLAATJES",
                "tr_subtitle": "De grafische kaart (of GPU) werkt nauw samen met de processor (of CPU) en helpt bij het bepalen hoe je games werken en hoe ze eruitzien. Het VRAM of video-RAM wordt alleen gebruikt voor het maken van beelden en is een snelle manier om te zien wat een GPU kan:",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_title": "Intense graphics",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "De krachtigste grafische verwerking vereist een grafische kaart die zich uitsluitend richt op het maken van mooie, heldere beelden en vloeiende animaties.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "VOOR INTENSE GAME-GRAPHICS",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "De krachtigste grafische verwerking vereist een grafische kaart die zich uitsluitend richt op het maken van mooie, heldere beelden en vloeiende animaties.",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "De krachtigste grafische verwerking vereist een aparte grafische kaart die volledig ingezet kan worden voor het maken van mooie, heldere beelden en vloeiende animaties",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "Levert voldoende grafische kracht om aan de minimumvereisten van je game te voldoen.",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "Voor topspelers die willen gamen op professioneel niveau",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "Voor ervaren gamers die hun pc ook gebruiken voor het maken van zware content",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Levendige video en overweldigend gamen",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Haal het beste uit je HD-scherm met Intel® HD Graphics en geniet van een rijke multimedia-ervaring en geïntegreerde grafische verwerking.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Voor levendige video en foto's",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "Geniet van meer details en schitterende kleuren in je videostreams en foto's.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_title": "Creëer en game als nooit tevoren",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Van overweldigend entertainment en geavanceerde contentcreatie tot mainstream games, Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics zorgen voor baanbrekende grafische prestaties op dunne en lichte pc's.",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_pretitle": "VOOR INTENSE GAME-GRAPHICS",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> graphics",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "De krachtigste grafische verwerking vereist een aparte grafische kaart die volledig ingezet kan worden voor het maken van mooie, heldere beelden en vloeiende animaties",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_title": "Unleash your creativity",
                "tr_pretitle": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX graphics",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Met de gecombineerde kracht van geïntegreerde graphics en de eerste losse graphics engine van Intel®, verlopen je creatieve processen soepel en kun je helemaal opgaan in je games.",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "tr_title": "Intel® Iris® X<sup class='small_text_sup_xe'>e</sup> MAX graphics",
                "tr_pretitle": "VOOR INTENSE GAME-GRAPHICS",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "De krachtigste grafische verwerking vereist een aparte grafische kaart die volledig ingezet kan worden voor het maken van mooie, heldere beelden en vloeiende animaties",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "Graphics",
                "tr_title": "Prachtige geïntegreerde graphics",
                "tr_subtitle": "Geniet van games, films en online video's met indrukwekkende details en helderheid.",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Graphics",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Graphics",
                    "tr_specText": "Boost your workflows"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Graphics",
                    "tr_specText": "Supercharge your gaming"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Graphics",
                    "tr_specText": "Supercharge your gaming"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Graphics",
                    "tr_specText": "Level up your game"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "UNLEASH YOUR CREATIVITY",
                    "tr_specText": "Versnel je productiviteit waar je ook bent met de meest uitgebreide mediafuncties, zoals de <strong>eerste AV1-hardwarecodering in de branche</strong> en AI-versnelde contentcreatie.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "UNLEASH YOUR CREATIVITY",
                    "tr_specText": "Versnel je productiviteit met de meest uitgebreide mediamogelijkheden, zoals de <strong>eerste AV1-hardwarecodering in de branche</strong> en AI-versnelde contentcreatie.<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "UNLEASH YOUR CREATIVITY",
                    "tr_specText": "Ervaar geavanceerde AI-versnelde contentcreatie met <strong>de eerste AV1-hardwarecodering in de branche</strong> en de geavanceerde <strong>X<sup>e</sup> media-engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "UNLEASH YOUR CREATIVITY",
                    "tr_specText": "Ervaar geavanceerde AI-versnelde contentcreatie met <strong>de eerste AV1-hardwarecodering in de branche</strong> en de geavanceerde <strong>X<sup>e</sup> media-engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "UNLEASH YOUR CREATIVITY",
                    "tr_specText": "Ervaar geavanceerde AI-versnelde contentcreatie met <strong>de eerste AV1-hardwarecodering in de branche</strong> en de geavanceerde <strong>X<sup>e</sup> media-engine.</strong><sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "MOBILITEIT GECOMBINEERD MET PRESTATIES",
                    "tr_specText": "Speel de nieuwste games onderweg en ervaar supercharged prestaties met moderne gamefuncties. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-verbeterde upscaling-technologie voor betere prestaties en vloeiend gamen. <br/><br/>Ondersteuning van <strong>DirectX* 12 Ultimate:  </strong>Ervaar je games op een nieuw niveau van realisme met grafische technologieën, zoals ray tracing en shading met variabele snelheid."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "DE CONTROLE NEMEN OVER JE GAMES",
                    "tr_specText": "<strong>Intel®&nbsp;Arc™ controlecentrum</strong> biedt een betere ervaring met een intuïtieve en moderne lay-out. Krijg zonder vertraging driver-updates, optimaliseer je games en meer."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "DE CONTROLE NEMEN OVER JE GAMES",
                    "tr_specText": "<strong>Intel®&nbsp;Arc™ controlecentrum</strong> biedt een betere ervaring met een intuïtieve en moderne lay-out. Krijg zonder vertraging driver-updates, optimaliseer je games en meer."
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "DE CONTROLE NEMEN OVER JE GAMES",
                    "tr_specText": "Krijg zonder vertraging driver-updates en optimaliseer veel van je favoriete games met de software van <strong>Intel®&nbsp;Arc™ controlecentrum</strong>."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "HAAL HET MEESTE UIT JE INTEL® PLATFORM",
                    "tr_specText": "Koppel met compatibele Intel® Core™ processors om de <strong>Intel® Deep Link technologie</strong> te ontketenen om je ervaring met creëren, gamen en streamen te verbeteren."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "HAAL HET MEESTE UIT JE INTEL® SYSTEEM",
                    "tr_specText": "Koppel met compatibele Intel® Core™ processors om de <strong>Intel® Deep Link technologie</strong> te ontketenen om je ervaring met creëren, gamen en streamen te verbeteren."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "HAAL HET MEESTE UIT JE INTEL® PLATFORM",
                    "tr_specText": "Koppel met compatibele Intel® Core™ processors om de <strong>Intel® Deep Link technologie</strong> te ontketenen om je ervaring met creëren, gamen en streamen te verbeteren."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "HAAL HET MEESTE UIT JE INTEL® PLATFORM",
                    "tr_specText": "Koppel met compatibele Intel® Core™ processors om de <strong>Intel® Deep Link technologie</strong> te ontketenen om je ervaring met creëren, gamen en streamen te verbeteren."
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "HAAL HET MEESTE UIT JE INTEL® SYSTEEM",
                    "tr_specText": "Koppel met compatibele Intel® Core™ processors om de <strong>Intel® Deep Link technologie</strong> te ontketenen om je ervaring met creëren, gamen en streamen te verbeteren."
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "TAKE CENTER STAGE",
                    "tr_specText": "Naadloos streamen met geavanceerde technologieën die mogelijk worden gemaakt door de software van <strong>Intel®&nbsp;Arc™ controlecentrum</strong>."
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "TAKE CENTER STAGE",
                    "tr_specText": "Naadloos streamen met geavanceerde technologieën die mogelijk worden gemaakt door de software van <strong>Intel®&nbsp;Arc™ controlecentrum</strong>."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "EXPERIENCE IMMERSIVE HIGH-PERFORMANCE GAMING",
                    "tr_specText": "Zet je beste prestaties voorop met de nieuwste AAA-games bij hoge resoluties en hoge verversingsfrequenties. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-verbeterde upscaling-technologie voor betere prestaties en vloeiend gamen. <br/><br/>Ondersteuning van <strong>DirectX* 12 Ultimate:  </strong>Ervaar je games op een nieuw niveau van realisme met grafische technologieën, zoals ray tracing en shading met variabele snelheid."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "EXPERIENCE IMMERSIVE HIGH-PERFORMANCE GAMING",
                    "tr_specText": "Zet je beste prestaties voorop met de nieuwste AAA-games bij hoge resoluties en hoge verversingsfrequenties. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-verbeterde upscaling-technologie voor betere prestaties en vloeiend gamen. <br/><br/>Ondersteuning van <strong>DirectX* 12 Ultimate:  </strong>Ervaar je games op een nieuw niveau van realisme met grafische technologieën, zoals ray tracing en shading met variabele snelheid."
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "JE GAMES EEN BOOST GEVEN",
                    "tr_specText": "Ervaar je games op het hoogste niveau met: <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-verbeterde upscaling-technologie voor betere prestaties en vloeiend gamen. <br/><br/>Ondersteuning van <strong>DirectX* 12 Ultimate:  </strong>Ervaar je games op een nieuw niveau van realisme met grafische technologieën, zoals ray tracing en shading met variabele snelheid."
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "EXPERIENCE ADVANCED FAST-PACED GAMING",
                    "tr_specText": "Geniet van vloeiend gamen op veel populaire AAA-games, en razendsnel gamen van high-octane esports shooters tot snelle RTS-hits. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-verbeterde upscaling-technologie voor betere prestaties en vloeiend gamen. <br/><br/>Ondersteuning van <strong>DirectX* 12 Ultimate:  </strong>Ervaar je games op een nieuw niveau van realisme met grafische technologieën, zoals ray tracing en shading met variabele snelheid."
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "EXPERIENCE IMMERSIVE HIGH-PERFORMANCE GAMING",
                    "tr_specText": "Zet je beste prestaties voorop met de nieuwste AAA-games bij hoge resoluties en hoge verversingsfrequenties. <br/><br/><strong>X<sup>e</sup>SS (X<sup>e</sup> Super Sampling):  </strong>Intel® AI-verbeterde upscaling-technologie voor betere prestaties en vloeiend gamen. <br/><br/>Ondersteuning van <strong>DirectX* 12 Ultimate:  </strong>Ervaar je games op een nieuw niveau van realisme met grafische technologieën, zoals ray tracing en shading met variabele snelheid."
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "Graphics",
                    "tr_specText": "Een grafisch universum met ruimte voor iedereen"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "VERSNEL JE FRAMES, VERSNEL JE GAMES",
                    "tr_specText": "Intel® X<sup class='small_text_sup_xe'>e</sup> Super Sampling-technologie (X<sup class='small_text_sup_xe'>e</sup>SS) tilt je gamen naar een hoger niveau met AI-verbeterde opschaling — voor topprestaties met hoge beeldgetrouwheid. X<sup class='small_text_sup_xe'>e</sup>SS is geoptimaliseerd voor Intel® Arc™ graphics producten, zodat je volledig kunt profiteren van de Intel® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions (Intel® XMX) hardwareversnelling."
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "DE ULTIEME BEELDKWALITEIT IS GEARRIVEERD",
                    "tr_specText": "Met volledige ondersteuning voor DirectX* 12 Ultimate op alle Intel® Arc™ graphics geniet je van prachtige, immersieve gameplay dankzij de nieuwste grafische technologieën zoals ray tracing, variable rate shading, mesh-shading en sampler-feedback, de fundamenten van de volgende generatie games."
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "NAADLOOS FOTO'S, VIDEO'S EN MEDIA MAKEN",
                    "tr_specText": "Geef je verbeelding de vrije loop en boei je publiek met uitgebreide digitale contentcreatie, met behulp van een hyper-geavanceerde media-engine – aangevuld met AI en versneld met Intel® technologie. Creëer spectaculaire content, aangestuurd door graphics met ondersteuning voor alle huidige toonaangevende mediaformaten, terwijl je up-to-date blijft met de meest geavanceerde AV1-videocoderingsmogelijkheden."
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "ERVAAR GEAVANCEERDE AI-CREATIE EN GAMEN",
                    "tr_specText": "Intel® Arc™ graphics ontgrendelen krachtige AI-mogelijkheden, van geavanceerde creatie tot immersief gamen. Haal het meeste uit de geavanceerde creatiesoftware van vandaag, inclusief het genereren van tekst-naar-beeld en AI-versnelde videobewerking. Plus, geniet van gameplay met hoge resolutie via AI-versneld X<sup class='small_text_sup_xe'>e</sup>SS-opschalen."
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "Graphics",
                    "tr_specText": "Professionele graphics beginnen hier"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "GEWELDIG VOOR CREATIEVELINGEN, ONTWERPERS EN INGENIEURS DIE VEEL ONDERWEG ZIJN",
                    "tr_specText": "Ingebouwde Intel® Arc™ Pro graphics zijn beschikbaar op bepaalde Intel® Core™ Ultra processors H-series verdubbelen, de grafische prestaties met Independent Software Vendor (ISV) certificeringen voor extra betrouwbaarheid en bieden efficiënte AI- en ray tracing-hardwareversnelling voor verbluffende contentcreatie onderweg.<br/><br/><span class='expandable_heading'>Softwarecertificeringen<br/></span><br/>Intel heeft door de jaren heen nauw samengewerkt met honderden softwarebedrijven, en deze ongeëvenaarde ervaring is terug te vinden in onze Intel® Arc™ Pro A-series grafische kaarten. Certificeringen zijn net zo belangrijk voor ons als voor jou."
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "AI HAALBAAR MAKEN",
                    "tr_specText": "Deze GPU-reeks bevat allerlei geweldige technologie, waaronder ondersteuning voor je compatibele AI-tools met ingebouwde speciale AI-versnelling waar je deze nodig hebt."
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING ZONDER DE MEERPRIJS",
                    "tr_specText": "Grafische kaarten doen meer dan pixels naar schermen sturen, ze moeten ook de creatie van prachtige beelden versnellen. Ray tracing-hardwaretechnologie ingebouwd in Intel® Arc™ Pro A-series graphics zorgt ervoor dat je compatibele software deze nieuwe professionele taken kan verkennen. Normaal gesproken wordt gedacht dat ray tracing alleen beschikbaar is op dure grafische kaarten, maar de nieuwste professionele reeks grafische kaarten van Intel overtreft die verwachting."
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "MAKERS MEER KRACHT GEVEN MET IMMERSIEVE SCHERMEN",
                    "tr_specText": "Met volledige ondersteuning voor DirectX* 12 Ultimate op alle Intel® Arc™ graphics geniet je van prachtige, immersieve gameplay dankzij de nieuwste grafische technologieën zoals ray tracing, variable rate shading, mesh-shading en sampler-feedback, de fundamenten van de volgende generatie games."
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "DYNAMISCH GEOPTIMALISEERDE BEELDEN",
                    "tr_specText": "Met speciale hardware-decodering op de Pro GPU's helpt Dolby Vision* om meer diepte, scherper contrast en meer kleuren toe te voegen aan je compatibele scherm. Dolby Vision* ontgrendelt het volledige potentieel van HDR-technologie door de beeldkwaliteit dynamisch te optimaliseren op basis van je service, weergavedevice en platform om elke keer prachtige beelden te leveren."
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "DESKUNDIG GEVERIFIEERDE SOFTWARE",
                    "tr_specText": "Intel heeft door de jaren heen nauw samengewerkt met honderden softwarebedrijven, en deze ongeëvenaarde ervaring is terug te vinden in het professionele assortiment van Intel® Arc™ Pro A-series grafische kaarten. Certificeringen en kwalificaties voor de softwareworkflow zijn net zo belangrijk voor ons als voor jou."
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "RAY TRACING ZONDER DE MEERPRIJS",
                    "tr_specText": "Grafische kaarten doen meer dan pixels naar schermen sturen, ze moeten ook de creatie van prachtige beelden versnellen. Ray tracing-hardwaretechnologie ingebouwd in Intel® Arc™ Pro A-series graphics zorgt ervoor dat je compatibele software deze nieuwe professionele taken kan verkennen. Normaal gesproken wordt gedacht dat ray tracing alleen beschikbaar is op dure grafische kaarten, maar de nieuwste professionele reeks grafische kaarten van Intel overtreft die verwachting."
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "MEERDERE GROTE SCHERMEN MET ÉÉN KLEINE MOBIELE GPU",
                    "tr_specText": "Intel® Arc™ Pro graphics voor laptops ondersteunen tot vier ultra-grote schermen om je workflow-efficiëntie te verbeteren en bieden ondersteuning voor hoog dynamisch bereik (HDR), zodat jij klaar bent voor die volgende sociale-media-waardige opstelling met meerdere schermen thuis of op kantoor."
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "VOLLEDIG GEHEUGENBANDBREEDTE-POTENTIEEL",
                    "tr_specText": "De bandbreedte van je grafische kaart is een belangrijke overweging voor een werkstation. Een verkeerde keuze kan je taken vertragen. Daarom biedt de Intel® Arc™ Pro A-series GPU ondersteuning voor moderne PCIe* 4.0, zonder afbreuk te doen aan achterwaartse compatibiliteit. De hoge bandbreedtesnelheid verbetert het grafisch geheugen verder, waardoor projectgegevens nog sneller toegankelijk zijn voor je software."
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "BLAAS LEVEN IN ELK FRAME",
                    "tr_specText": "<span class='expandable_heading'>Versterkt met AI. Ondersteund door Intel.<br/></span><br/>Intel® X<sup class='small_text_sup_xe'>e</sup>SS is een AI-verbeterde opschalingstechnologie die je prestaties verbetert zonder de schoonheid van elk frame op te offeren — zodat je je gameplay kunt ervaren zoals het bedoeld is."
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "IN REALTIME.<br/> ECHT IMMERSIEF.<br/> REALISME OP ZIJN BEST.",
                    "tr_specText": "Realistischere werelden wachten op jou. Met ingebouwde ray tracing ervaar je alles, van levensechte schaduwen en reflecties tot indrukwekkende belichting. Het is een visuele game-ervaring die je niet wilt missen."
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "JE BESTE GAMEPLAY, GEDEELD OP ZIJN BEST",
                    "tr_specText": "Geef je publiek de naadloze gameplay die ze verdienen. Met de hardware-versnelde AV1-coderingsmogelijkheden van Intel® Arc™ graphics fascineer je ze met elke beweging. Hogere visuele kwaliteit bij dezelfde bitrate levert de beste streaming terwijl je bandbreedte bespaart."
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "Welkom aan alle makers",
                    "tr_specText": "Nieuwe en verbeterde AI-ervaringen zijn beschikbaar binnen handbereik. Met geavanceerde AI-versnellers ingebed in Intel® Arc™ A-series graphics, besteed je meer tijd aan het creëren en minder tijd aan wachten voor veel toepassingen.<br/><br/>Til je videocreatie naar een hoger niveau en loop voorop met opslagefficiënte AV1-video van hoge kwaliteit, aangedreven door geavanceerde AV1-hardwaremogelijkheden die zijn ingebouwd in Intel® Arc™ graphics."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Besturingssysteem",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "Alles wat je nodig hebt voor je werk",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "Zowel krachtige prestaties als een dun, licht en duurzaam ontwerp; alles zit erop en eraan. Met een geweldige batterijduur, prachtige touchscreens* en de nieuwste Solid-State Drives (SSD's) vind je altijd wat je zoekt met een Windows 10-pc.",
                "tr_noteSection": "*Afhankelijk van hardware.",
                "titleArray": [
                  {
                    "tr_title": "Meer met minder",
                    "tr_subtitle": "De huidige pc's verbruiken doorgaans minder stroom, zodat je langer kunt werken op één batterijlading en gemiddeld zo'n acht uur aan video kunt afspelen.**",
                    "tr_noteSection": "**De batterijduur is sterk afhankelijk van het apparaat, de instellingen, het gebruik en andere factoren."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Besturingssysteem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "ALLES WAT JE NODIG HEBT VOOR JE WERK",
                "tr_title": "Windows* 10",
                "tr_subtitle": "Zowel krachtige prestaties als een dun, licht en duurzaam ontwerp; alles zit erop en eraan. Met een geweldige batterijduur, prachtige touchscreens* en de nieuwste Solid-State Drives (SSD's) vind je altijd wat je zoekt met een Windows 10-pc.",
                "tr_noteSection": "*Afhankelijk van hardware.",
                "titleArray": [
                  {
                    "tr_title": "MEER MET MINDER",
                    "tr_subtitle": "De huidige pc's verbruiken doorgaans minder stroom, zodat je langer kunt werken op één batterijlading en gemiddeld zo'n acht uur aan video kunt afspelen.**",
                    "tr_noteSection": "**De batterijduur is sterk afhankelijk van het apparaat, de instellingen, het gebruik en andere factoren."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Besturingssysteem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Ontworpen voor veiligheid en prestaties",
                "tr_pretitle": "Windows* 10 in S-modus",
                "tr_subtitle": "Windows* 10 in S-modus geeft je een extra beschermingslaag waardoor je kunt werken, ontspannen en browsen met de zekerheid dat je device beveiligd is."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Besturingssysteem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "ONTWORPEN VOOR VEILIGHEID EN PRESTATIES",
                "tr_title": "Windows* 10 in S-modus",
                "tr_subtitle": "Windows* 10 in S-modus geeft je een extra beschermingslaag waardoor je kunt werken, ontspannen en browsen met de zekerheid dat je device beveiligd is."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Besturingssysteem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Doe geweldige dingen",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "Prestaties, prijzen en genoeg om uit te kiezen. Van innovatieve 2 in 1's tot dunne en lichte laptops, touchscreens, afneembare toetsenborden: je vindt altijd een Windows*-pc die bij je past, ongeacht je budget of stijl."
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Besturingssysteem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "DOE GEWELDIGE DINGEN",
                "tr_title": "Windows*",
                "tr_subtitle": "Prestaties, prijzen en genoeg om uit te kiezen. Van innovatieve 2 in 1's tot dunne en lichte laptops, touchscreens, afneembare toetsenborden: je vindt altijd een Windows*-pc die bij je past, ongeacht je budget of stijl."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Besturingssysteem",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Besturingssysteem",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Geheugen",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "HOEVEEL RAM HEB JE NODIG?",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Dagelijks gebruik - 2 TOT 8 GB RAM",
                    "tr_badgeSubTitle": "Ideaal voor iedereen die de pc voornamelijk als tekstverwerker en webbrowser gebruikt, en zich graag op één programma tegelijkertijd richt."
                  },
                  {
                    "tr_badgeTitle": "Krachtig - 8 TOT 16 GB RAM",
                    "tr_badgeSubTitle": "Als je meer RAM hebt, worden je toepassingen sneller uitgevoerd en kun je meerdere toepassingen tegelijkertijd uitvoeren."
                  },
                  {
                    "tr_badgeTitle": "Geavanceerd - MEER DAN 16 GB RAM",
                    "tr_badgeSubTitle": "Voor intensieve programma's, zoals games en programma's voor foto- en videobewerking en 3D-modellering, heb je extra RAM nodig."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "GEHEUGEN",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM voor dagelijks multitasken",
                "tr_subtitle": "Dankzij je geheugen kun je basistoepassingen, zoals tekstverwerkers en webbrowsers, moeiteloos laden en uitvoeren.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "GEHEUGEN",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM voor krachtig multitasken",
                "tr_subtitle": "Als je meerdere programma's tegelijkertijd gebruikt, kun je dankzij [memory_total] GB aan geheugen eenvoudig tussen deze programma's wisselen.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "GEHEUGEN",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM voor geavanceerd multitasken",
                "tr_subtitle": "Je beschikt over meer dan genoeg geheugen om diverse intensieve programma's tegelijkertijd uit te voeren zonder dat je pc trager wordt.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "GEHEUGEN",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1] GB geheugen = [Value2] GB Intel® Optane™ geheugen + [Value3] GB RAM",
                "tr_subtitle": "Deze combinatie van geheugenoplossingen biedt je een snellere harde schijf dankzij het Intel® Optane™ geheugen en de korte reactietijd van je RAM.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>GEHEUGEN</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ geheugen",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "Minder wachten. Meer van je favorieten.",
                "tr_subtitle": "Wanneer het Intel® Optane™ geheugen met je systeemgeheugen werkt, heb je sneller toegang tot veelgebruikte programma's, zodat ze meteen worden gestart en geladen wanneer je ze nodig hebt.",
                "tr_pretitle": "[XX] GB Intel® Optane™ geheugen",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "WAT DOET HET GEHEUGEN VOOR MIJN PC?"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "Sneller content creëren",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "Sneller gamen",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "Eenvoudiger multitasken",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "Programma's sneller openen",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Intel® Optane™ geheugen",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Sneller gamen</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ geheugen",
                "tr_pretitle1": "[XX] GB Intel® Optane™ geheugen laat je sneller gamen",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Start games tot <br/><span class=\"optane-game\">98% sneller</span><br/>vanaf de harde schijf met een [optane_legal_processor_name] processor met Intel® Optane™ geheugen<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Laad levels tot<br/><span class=\"optane-game\">4,1x sneller</span><br/>vanaf de harde schijf met een [optane_legal_processor_name] processor met Intel® Optane™ geheugen<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "Intel® Optane™ geheugen",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>Sneller gamen</span>",
                "tr_pretitle": "[XX] GB Intel® Optane™ geheugen",
                "tr_pretitle1": "[XX] GB Intel® Optane™ geheugen laat je sneller gamen",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Start games tot <br/><span class=\"optane-game\">98% sneller</span><br/>vanaf de harde schijf met een [optane_legal_processor_name] processor met Intel® Optane™ geheugen<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "Laad levels tot<br/><span class=\"optane-game\">4,1x sneller</span><br/>vanaf de harde schijf met een [optane_legal_processor_name] processor met Intel® Optane™ geheugen<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "GEHEUGEN",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1] GB geheugen = [Value2] GB Intel® Optane™ geheugen + [Value3] GB RAM",
                "tr_subtitle": "Deze combinatie van geheugenoplossingen biedt je een snellere harde schijf dankzij het Intel® Optane™ geheugen en de korte reactietijd van je RAM.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB RAM voor multitasken",
                    "tr_subtitle": "Als je meerdere programma's tegelijkertijd gebruikt, kun je dankzij [memory_total] GB aan geheugen eenvoudig tussen deze programma's wisselen."
                  },
                  {
                    "tr_title": "Voor dagelijkse taken",
                    "tr_subtitle": "Dankzij je geheugen kun je basistoepassingen, zoals tekstverwerkers en webbrowsers, moeiteloos laden en uitvoeren."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "GEHEUGEN",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB RAM",
                "tr_pretitle": "VOOR MEER MULTITASKING",
                "tr_subtitle": "RAM geeft je processor toegang tot programmabestanden, waardoor je CPU er sneller toegang tot heeft en aan meerdere taken tegelijk kan werken.",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standaardgeheugen",
                    "tr_badgeSubTitle": "Tot 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Geavanceerd geheugen",
                    "tr_badgeSubTitle": "Meer dan 8 GB en tot 16 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Topgeheugen",
                    "tr_badgeSubTitle": "Meer dan 16 GB RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "GEHEUGEN",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB om meer te multitasken",
                "tr_pretitle": "VOOR MEER MULTITASKING",
                "tr_subtitle": "RAM geeft je processor toegang tot programmabestanden, waardoor je CPU er sneller toegang tot heeft en aan meerdere taken tegelijk kan werken.",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Standaardgeheugen",
                    "tr_badgeSubTitle": "Tot 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Geavanceerd geheugen",
                    "tr_badgeSubTitle": "Meer dan 8 GB en tot 16 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "Topgeheugen",
                    "tr_badgeSubTitle": "Meer dan 16 GB RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "VERGELIJK HET GEHEUGEN",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Standaard",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Geavanceerd",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Elite",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Geheugen",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "VERGELIJK HET GEHEUGEN",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "VERGELIJK HET GEHEUGEN",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "Huidig device",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Dagelijks",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Krachtig",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "Geavanceerd",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Opslag",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "HOEVEEL PAST ER OP JE PC?",
                "tr_subtitle": "Je hoeft niet te raden. We laten je precies zien wat je op deze pc kunt opslaan.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Maak een bibliotheek van</span> <br/><span class=\"storage-standard\">[num_songs] nummers<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>gebaseerd op gemiddeld 5 MB per nummer",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Maak een bibliotheek van</span><br/><span class=\"storage-standard\">[num_games] games<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>gebaseerd op een gemiddelde van 40 GB per game",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Opslag voor</span><br/><span class=\"storage-standard\">[num_videos] video's<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>gebaseerd op gemiddeld 5 GB per video",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>Maak en bewaar</span><br/><span class=\"storage-standard\">[num_photos] foto's<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>gebaseerd op gemiddeld 4 MB per foto",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "[capacity] GB HDD"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "[capacity] GB SSD"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "[total_optane_memory] GB Intel® Optane™ geheugen + [convertedSSD] GB SSD"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "OPSLAG",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "[capacity] GB SSD-opslag",
                "tr_preTitle1": "[capacity] TB SSD-opslag",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "Deze pc heeft een SSD met een opslagruimte van [capacity] GB. Dit betekent dat je een enorme hoeveelheid foto's, video's en programma's kunt opslaan, en deze veel sneller kunt openen dan met een harde schijf.",
                    "tr_title": "Een plek voor alles op je pc"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "Deze pc heeft een SSD met een opslagruimte van [capacity] TB. Dit betekent dat je een enorme hoeveelheid foto's, video's en programma's kunt opslaan, en deze veel sneller kunt openen dan met een harde schijf.",
                    "tr_title": "Dit device heeft een indrukwekkende opslagcapaciteit"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>OPSLAG</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>OPSLAG</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "OPSLAG",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "[capacity] GB HDD-opslag",
                "tr_preTitle1": "[capacity] TB HDD-opslag",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "HDD-schijven zijn een goede keuze voor mensen die een pc met veel opslagruimte willen.",
                    "tr_title": "Een plek voor alles op je pc"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "HDD-schijven zijn een goede keuze voor mensen die een pc met veel opslagruimte willen.",
                    "tr_title": "Dit device heeft een indrukwekkende opslagcapaciteit"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>OPSLAG</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "HDD",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "OPSLAG",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "HOEVEEL PAST ER OP JE PC?",
                "tr_subtitle": "Je hoeft niet te raden. We laten je precies zien wat je op deze pc kunt opslaan.",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB HDD"
                  },
                  {
                    "tr_title": "[XX] GB SSD"
                  },
                  {
                    "tr_title": "[total_capacity] GB Intel® SSD versneld met [optane_memory_size] GB Intel® Optane™ geheugen"
                  },
                  {
                    "tr_title": "[total_capacity] TB Intel® SSD versneld met [optane_memory_size] GB Intel® Optane™ geheugen"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD"
                  },
                  {
                    "tr_title": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>OPSLAG</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>OPSLAG</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>OPSLAG</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>OPSLAG</span>",
                        "tr_badgeSubTitle": "SSD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>OPSLAG</span>",
                        "tr_badgeSubTitle": "HDD"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>OPSLAG</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "OPSLAG",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "[convertedSSD] GB SSD + [convertedHDD] GB HDD",
                "tr_preTitle1": "[convertedSSD] TB SSD + [convertedHDD] GB HDD",
                "tr_preTitle2": "[convertedSSD] GB SSD + [convertedHDD] TB HDD",
                "tr_preTitle3": "[convertedSSD] TB SSD + [convertedHDD] TB HDD",
                "titleArray": [
                  {
                    "tr_title": "Een plek voor alles op je pc",
                    "tr_title1": "Dit device heeft een indrukwekkende opslagcapaciteit",
                    "tr_subtitle": "Deze pc heeft een SSD met een opslagruimte van [convertedSSD] GB en een harde schijf van [convertedHDD] GB. Hiermee beschik je over een enorme capaciteit en wordt alles sneller opgeslagen.",
                    "tr_subtitle1": "Deze pc heeft een SSD met een opslagruimte van [convertedSSD] TB en een harde schijf van [convertedHDD] GB. Hiermee beschik je over een enorme capaciteit en wordt alles sneller opgeslagen.",
                    "tr_subtitle2": "Deze pc heeft een SSD met een opslagruimte van [convertedSSD] GB en een harde schijf van [convertedHDD] TB. Hiermee beschik je over een enorme capaciteit en wordt alles sneller opgeslagen.",
                    "tr_subtitle3": "Deze pc heeft een SSD met een opslagruimte van [convertedSSD] TB en een harde schijf van [convertedHDD] TB. Hiermee beschik je over een enorme capaciteit en wordt alles sneller opgeslagen."
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>OPSLAG</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>OPSLAG</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>SSD<br/><span class='storage-seperator'> +  </span><br/>HDD</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "OPSLAG",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Profiteer van snellere toegang tot opslag met hoge capaciteit met Intel® Optane™ geheugen met SSD.",
                "tr_title": "Grote bestanden zijn welkom",
                "tr_pretitle": "[total_capacity] GB Intel® SSD versneld met [optane_memory_size] GB Intel® Optane™ geheugen",
                "tr_pretitle1": "[total_capacity] TB Intel® SSD versneld met [optane_memory_size] GB Intel® Optane™ geheugen",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "SSD",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "Deze revolutionaire opslagoplossing is de eerste die Intel® Optane™ geheugen combineert met een SSD op één chip. Lees hier meer over de voordelen:",
                    "tr_title": "WAT IS INTEL® OPTANE™ GEHEUGEN MET SSD?"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "Open snel wat je nodig hebt",
                    "tr_badgeSubTitle": "Bestanden en programma's die je regelmatig gebruikt, worden vooraf geladen. Daardoor hoef je minder te wachten en heb je meer tijd om te creëren.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "Opslag en snelheid",
                    "tr_badgeSubTitle": "Je beschikt over de opslagruimte die je nodig hebt voor grote mediabestanden en de snelle prestaties van een SSD.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "Toenemende responsiviteit",
                    "tr_badgeSubTitle": "Het Intel® Optane™ geheugen met SSD leert hoe je werkt, waardoor je pc steeds efficiënter wordt.",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "OPSLAG",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "Hou al je media in de buurt met meer dan genoeg betrouwbare opslag.",
                "tr_title": "Ruimte voor al je foto's, video's en nummers"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Scherm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] miljoen pixels om te swipen, in te zoomen en van te genieten",
                "tr_subtitle": "Je bedient je pc net als een smartphone, met een intuïtieve interface en scherpe, levendige details.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolutie: [screenResolutionX] x [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels: [screenResolution] miljoen"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] inch scherm",
                    "tr_badgeSubTitle": "Schermhoogte: [screenHeight] inch",
                    "tr_badgePreTitle": "Schermbreedte: [YY] inch"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Touchscreen",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Scherm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "Ga helemaal op in [screenResolution] miljoen pixels",
                "tr_subtitle": "Het draait allemaal om de pixels. Hoe meer je er hebt, des te scherper en levendiger je beeld.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolutie: [screenResolutionX] x [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels: [screenResolution] miljoen"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] inch scherm",
                    "tr_badgeSubTitle": "Schermhoogte: [screenHeight] inch",
                    "tr_badgePreTitle": "Schermbreedte: [YY] inch"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Scherm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] inch <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "HET SCHERM VOOR JE GAMING-WERELD",
                "tr_subtitle": "Net als bij een tv zijn de afmetingen en resolutie van een gamescherm het belangrijkst.",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolutie: [screenResolutionX] x [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels: [screenResolution] miljoen"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] inch scherm",
                    "tr_badgeSubTitle": "Schermhoogte: [screenHeight] inch",
                    "tr_badgePreTitle": "Schermbreedte: [screenWidth] inch"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "Touchscreen"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Scherm",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "Dit device heeft een indrukwekkend scherm",
                "tr_subtitle": "Met meer dan acht keer zoveel pixels als HD krijgen je video's en games een geheel nieuw niveau van scherpte en kleur.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "Resolutie: [screenResolutionX] x [screenResolutionY]",
                    "tr_badgePreTitle": "Pixels: [screenResolution] miljoen"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] inch scherm",
                    "tr_badgeSubTitle": "Schermhoogte: [screenHeight] inch",
                    "tr_badgePreTitle": "Schermbreedte: [YY] inch"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Scherm",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] miljoen pixels om te tikken, vegen en aan te raken",
                    "tr_subTitle": "Bekijk en gebruik de dingen die je leuk vindt met scherpe en levendige details."
                  },
                  {
                    "tr_title": "[screenResolution] miljoen pixels om je digitale wereld te verbeteren",
                    "tr_subTitle": "Het draait allemaal om de pixels. Hoe meer je er hebt, des te scherper en levendiger je beeld."
                  },
                  {
                    "tr_title": "Dit device heeft een indrukwekkend scherm",
                    "tr_subTitle": "Met bijna vier keer zoveel pixels als HD krijgen je video's en games een geheel nieuw niveau van scherpte en kleur."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Connectiviteit",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Thunderbolt™ 3 technologie biedt de snelste, veelzijdigste verbindingen met docks, schermen en gegevensdevices.",
                "tr_title": "Eén poort voor alles",
                "tr_title1": "EÉN POORT VOOR ALLES",
                "titleArray": [
                  {
                    "tr_title": "HOE GEBRUIK IK THUNDERBOLT™ 3 TECHNOLOGIE?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 3 technologie"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "4K-schermen",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Sluit je pc aan op schermen met een verbluffende resolutie, meer contrast en mooiere kleuren"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "Externe grafische kaart",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Geef je pc onmiddellijk een grafische boost"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "Snelle netwerkverbindingen",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Voor snelle peer-to-peerverbindingen"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Connectiviteit",
                "detailBGUrl": "",
                "tr_subtitle": "Thunderbolt™ 3 technologie brengt gegevens sneller over dan conventionele USB's, zodat je bestanden snel en eenvoudig kunt overzetten en randapparatuur zoals meerdere beeldschermen of externe grafische kaarten kunt integreren.",
                "tr_title": "Thunderbolt™ 3 technologie",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "RAZENDSNELLE GEGEVENS",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Thunderbolt™ 3 technologie",
                    "tr_badgeSubTitle": "40 Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Connectiviteit",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Eén universele poort en kabel voor al je favoriete accessoires</li> <li>Betrouwbaar laptops laden</li> <li>Solide certificering geeft gemoedsrust</li></ul>",
                "tr_title": "Universele kabelconnectiviteit",
                "tr_title1": "UNIVERSELE KABELCONNECTIVITEIT",
                "tr_noteSection": "<sup>†</sup> Op basis van minimum specificatievereisten",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "HOE GEBRUIK IK THUNDERBOLT™ 4 TECHNOLOGIE?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Thunderbolt™ 4 technologie"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Creëren",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Sluit een externe monitor en snelle opslag aan om foto's en video's te bekijken en bewerken."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dock",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Zorg voor een eenvoudig, opgeruimd bureau door met één kabel je laptop aan te sluiten op al je accessoires."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Gamen",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Voeg meer SSD- en HDD-capaciteit toe met snelheden tot 3000 MB/s om gamebibliotheken en mediabestanden op te slaan."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximale prestaties<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x sneller dan USB* 3.2 <br/>2x sneller dan HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Connectiviteit",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "Geniet van de geweldige prestaties van de Thunderbolt™ 3 technologie met nog meer mogelijkheden, waarmee je eenvoudig de nieuwste Thunderbolt™ docks, schermen, snelle opslag en USB*-accessoires kunt aansluiten en gebruiken.",
                "tr_title": "Thunderbolt™ 4 technologie",
                "tr_pretitle": "UNIVERSELE KABELCONNECTIVITEIT",
                "tr_noteSection": "<sup>†</sup> Op basis van minimum specificatievereisten",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Eén universele Thunderbolt™ 4 kabel kan al je USB-C*-kabels vervangen voor snelle en eenvoudige kabelselectie."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "Docks met Thunderbolt™ 4 technologie bieden tot vier Thunderbolt™ poorten voor meer flexibiliteit voor het aansluiten van je accessoires."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximale prestaties<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x sneller dan USB* 3.2 <br/>2x sneller dan HDMI* 2"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Connectiviteit",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>Eén universele poort en kabel voor al je favoriete accessoires</li> <li>Betrouwbaar laptops laden</li> <li>Solide certificering geeft gemoedsrust</li></ul>",
                "tr_title": "Haal alles uit je ervaring met Thunderbolt™ accessoires",
                "tr_noteSection": "<sup>†</sup> Op basis van minimum specificatievereisten",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "HOE GEBRUIK IK THUNDERBOLT™ 4 TECHNOLOGIE?",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "Creëren",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Sluit een externe monitor en snelle opslag aan om foto's en video's te bekijken en bewerken."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "Dock",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Zorg voor een eenvoudig, opgeruimd bureau door met één kabel je laptop aan te sluiten op al je accessoires."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "Gamen",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "Voeg meer SSD- en HDD-capaciteit toe met snelheden tot 3000 MB/s om gamebibliotheken en mediabestanden op te slaan."
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "Maximale prestaties<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "4x sneller dan USB* 3.2 <br/>2x sneller dan HDMI* 2"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "De meest voorkomende soorten wifi zijn:",
                "tr_title": "WIFISTANDAARDEN BEGRIJPEN",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 zorgt voor hogere snelheden, zelfs wanneer veel gebruikers hetzelfde signaal gebruiken.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Met de nieuwste wifi-innovatie van Intel bereik je hogere snelheden dan Gigabit Wi-Fi, zelfs met meerdere gebruikers op hetzelfde signaal.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Met de grootste wifiverbetering in twintig jaar biedt Wi‑Fi 6E nieuwe razendsnelle kanalen voor betere prestaties en betrouwbaarheid en minder interferentie."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 zorgt voor hogere snelheden, zelfs wanneer veel gebruikers hetzelfde signaal gebruiken.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Alle voordelen van Wi-Fi 6E, gecombineerd met de Intel® Killer™ Prioritization Engine die bandbreedte toewijst aan websites en applicaties die dat het meest nodig hebben."
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "Minder concurrentie op je high-speed kanalen zorgt voor betere prestaties en betrouwbaarheid van je laptop."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 zorgt voor hogere snelheden, zelfs wanneer veel gebruikers hetzelfde signaal gebruiken.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "Met minder interferentie van Wi-Fi 6 en de intelligente prestaties van de Intel® Killer™ Prioritization Engine krijg je een snellere en responsievere online ervaring.⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Minder concurrentie op je high-speed kanalen zorgt voor betere prestaties en betrouwbaarheid van je laptop."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "Pc-wifi-snelheden van meer dan 5 Gbps met consistente ultralage latentie. Deel bestanden in seconden in plaats van in minuten."
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "Intel® Killer™ Wi‑Fi 7",
                    "tr_badgeSubTitle": "Intel® Killer™ Wi-Fi 7 (5 Gig) is de nieuwste technologie voor extreme betrouwbaarheid, ultralage latentie en hoge snelheden door AI-gebaseerde netwerkoptimalisatie."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Wi-Fi 6 zorgt voor hogere snelheden, zelfs wanneer veel gebruikers hetzelfde signaal gebruiken.<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "Intel® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Intel® Wi-Fi 6E (Gig+) detecteert snelheden van hoger dan 1 Gbps, is betrouwbaar en heeft lage latentie door gebruik van het 6 GHz-kanaal."
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "Betere efficiëntie, meer capaciteit en hogere snelheden vergeleken met Wi-Fi 5 op overbelaste netwerken."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Met Wi‑Fi [XX] blijf je beter verbonden en geniet je van sneller<sup>†</sup> en betrouwbaarder internet.",
                "tr_title": "Geniet van een beter bereik en hogere snelheden",
                "tr_noteSection": "<sup>†</sup>De werkelijke draadloze doorvoersnelheid en/of het bereik variëren.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "In het tijdperk van multiplayer en online games is een goede snelle verbinding belangrijker dan ooit. En natuurlijk moet het draadloze signaal ook op afstand goed blijven werken.",
                "tr_title": "[XX]",
                "tr_pretitle": "DE VERBINDING IS HET BELANGRIJKST",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Maak met Intel® Wi‑Fi 6 (Gig+) verbinding met Wi‑Fi 6 routers die 160 MHz-kanalen ondersteunen voor snellere verbindingen en een grotere betrouwbaarheid voor meer actieve gebruikers.",
                "tr_title": "Bijna 3x sneller met Intel® <span class='no-warp'>Wi‑Fi 6  </span>(Gig+)<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>Dan standaard AC wifi. Bepaalde functies zijn alleen beschikbaar op bepaalde SKU's. Informeer bij de fabrikant voor meer informatie. Ga voor meer informatie over wifi naar intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Dual band Intel® Wi-Fi 6 op instapniveau ondersteunt verbeterde prestaties voor basis connectiviteitsbehoeften zoals e-mailen en browsen op internet op drukke netwerken.<sup>9</sup>",
                "tr_title": "Essentiële wifi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "In het tijdperk van multiplayer en online games is een goede snelle verbinding belangrijker dan ooit. En natuurlijk moet het draadloze signaal ook op afstand goed blijven werken.",
                "tr_title": "[XX]",
                "tr_pretitle": "DE VERBINDING IS HET BELANGRIJKST",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Met deze nieuwe wifikanalen hoef je geen bandbreedte te delen met oudere devices in je buurt, waardoor je zonder haperingen kunt streamen, snel kunt internetten en naadloos kunt werken en leren op afstand.",
                "tr_title": "Maak verbinding zonder concurrentie",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "Met [XX] blijf je beter verbonden en geniet je van sneller<sup>†</sup> en betrouwbaarder internet.",
                "tr_title": "Geniet van een beter bereik en hogere snelheden",
                "tr_noteSection": "<sup>†</sup>De werkelijke draadloze doorvoersnelheid en/of het bereik variëren.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "In het tijdperk van multiplayer en online games is een goede snelle verbinding belangrijker dan ooit. En natuurlijk moet het draadloze signaal ook op afstand goed blijven werken.",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "DE VERBINDING IS HET BELANGRIJKST",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "Ingebouwde ultrasnelle wifi",
                "tr_subtitle": "Blijf verbonden met je online wereld, ook zonder stekker."
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "Met specifieke razendsnelle kanalen voor minder interferentie en de Intel® Killer™ Prioritization Engine die gamen op de eerste plaats zet, is online gaming nu sterk verbeterd.",
                "tr_title": "Laat de concurrentie ver achter je",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Met specifieke razendsnelle kanalen voor minder interferentie en de Intel® Killer™ Prioritization Engine die gamen op de eerste plaats zet, is online gaming nu sterk verbeterd.",
                "tr_title": "[XX]",
                "tr_pretitle": "LAAT DE CONCURRENTIE VER ACHTER JE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Online gamen is nu sterk verbeterd met specifieke razendsnelle lanes voor minder interferentie die gamen op de eerste plaats zetten.",
                "tr_title": "[XX]",
                "tr_pretitle": "LAAT DE CONCURRENTIE VER ACHTER JE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "Intel® Killer™ Wi-Fi versnelt automatisch netwerkverkeer voor de toepassingen die dit het meest nodig hebben, zodat je gestreamde video's een scherpe beeldkwaliteit hebben en sneller laden.",
                "tr_title": "Een snellere, slimmere manier van verbinden.⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "In het tijdperk van multiplayer en online games is een goede snelle verbinding belangrijker dan ooit. En natuurlijk moet het draadloze signaal ook op afstand goed blijven werken.",
                "tr_title": "[XX]",
                "tr_pretitle": "DE VERBINDING IS HET BELANGRIJKST",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "Met Wi-Fi 5 blijf je beter verbonden en geniet je van sneller<sup>†</sup> en betrouwbaarder internet.",
                "tr_title": "Geniet van een beter bereik en hogere snelheden",
                "tr_noteSection": "<sup>†</sup>De werkelijke draadloze doorvoersnelheid en/of het bereik variëren.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "In het tijdperk van multiplayer en online games is een goede snelle verbinding belangrijker dan ooit. En natuurlijk moet het draadloze signaal ook op afstand goed blijven werken.",
                "tr_title": "[XX]",
                "tr_pretitle": "LAAT DE CONCURRENTIE VER ACHTER JE",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Wi-Fi 7-functies zullen voortbouwen op eerdere wifi-generaties en deze naar een hoger niveau tillen. Dit betekent niet alleen hogere snelheden, maar ook een aanzienlijk betere responsiviteit en betrouwbaarheid voor meeslepende consumentenervaringen en geavanceerde toekomstige technologieën.",
                "tr_title": "Ultrasnelle pc-netwerken",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Wi-Fi 7-functies zullen voortbouwen op eerdere wifi-generaties en deze naar een hoger niveau tillen. Dit betekent niet alleen hogere snelheden, maar ook een aanzienlijk betere responsiviteit en betrouwbaarheid voor meeslepende consumentenervaringen en geavanceerde toekomstige technologieën.",
                "tr_title": "[XX]",
                "tr_pretitle": "GETRANSFORMEERDE GEBRUIKERSERVARINGEN",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "Hogere snelheden, lagere latentie en meer betrouwbaarheid door AI-gebaseerde netwerkoptimalisatie",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wifi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Hogere snelheden, lagere latentie en meer betrouwbaarheid door AI-gebaseerde netwerkoptimalisatie.",
                "tr_title": "[XX]",
                "tr_pretitle": "ULTRASNELLE RESPONSIVITEIT",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Accu",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "Hoe wordt het gemeten?",
                    "tr_badgeSubTitle": "De meeste fabrikanten drukken de accuduur uit in uren. Hoewel deze berekening niet 100% nauwkeurig is, kun je hiermee de accuduur van verschillende pc's vergelijken."
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Accu",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "VOOR GAMING ONDERWEG",
                "tr_subtitle": "Dankzij de kracht van de moderne gaming-laptops hoef je niet aan je bureau te blijven zitten. Je kunt je games nu overal mee naartoe nemen.",
                "tr_title": "[Dynamic.battery.values.hours] uur accuduur"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Accu",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Haal de stekker uit het stopcontact en geniet urenlang van je apps, games en muziek.",
                "tr_title": "[XX] uur streamen per acculading<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Gebaseerd op de door de pc-fabrikant vermelde accuduur.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] uur",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Accu",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Haal de stekker uit het stopcontact en geniet urenlang van je apps, games en muziek.",
                "tr_title": "[Dynamic.battery.values.hours] uur accu onderweg<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Gebaseerd op de door de pc-fabrikant vermelde accuduur.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] uur",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Accu",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Haal de stekker uit het stopcontact en geniet urenlang van je apps, games en muziek.",
                "tr_title": "[Dynamic.battery.values.hours] uur voor de hele dag met één acculading<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Gebaseerd op de door de pc-fabrikant vermelde accuduur.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] uur",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Accu",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "Deze pc optimaliseert je accuduur zodat deze langer is. Je krijgt een accuduur van ten minste 4 uur na 30 minuten laden.<sup>4</sup>",
                "tr_title": "Langer zonder stopcontact met [Dynamic.battery.values.hours] uur accuduur<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>Gebaseerd op de door de pc-fabrikant vermelde accuduur.",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] uur",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "Home",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "Waarom deze pc",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "Alle specificaties bekijken",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "Op afstand",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "Mobiel",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "Juridisch",
        "tr_paragraph_array": [
          "De software en de werkbelasting die zijn gebruikt in de prestatietests, zijn mogelijk alleen geoptimaliseerd voor gebruik met Intel® microprocessors. Prestatietests, zoals SYSmark* en MobileMark*, zijn uitgevoerd met specifieke computersystemen, -componenten en -functies. Wijzigingen in een van deze factoren kan leiden tot een afwijkend resultaat. Het is raadzaam om andere informatie en prestatietests te raadplegen, waaronder de prestaties van het product in combinatie met andere producten, zodat een goed geïnformeerde en weloverwogen aankoopbeslissing kan worden genomen. Ga voor volledige informatie over prestaties en benchmarkresultaten naar www.intel.com/benchmarks.",
          "¹Intel® Wi-Fi 6 (Gig+) producten ondersteunen optionele 160 MHz-kanalen, waardoor de theoretisch hoogst mogelijke maximumsnelheid (2402 Mbps) mogelijk is voor standaard 2x2 802.11ax pc wifiproducten. Premium Intel® Wi-Fi 6 (Gig+) producten maken theoretisch 2-4x hogere snelheden mogelijk vergeleken met standaard 2x2 (1201 Mbps) of 1x1 (600 Mbps) 802.11ax wifiproducten voor pc's, die alleen de verplichte vereisten van 80 MHz-kanalen ondersteunen.",
          "²Advies uitsluitend voor informatieve doeleinden. Raadpleeg andere bronnen bij het nemen van je aankoopbeslissing.",
          "³De eigenschappen en voordelen van Intel technologieën zijn afhankelijk van de systeemconfiguratie en vereisen mogelijk ingeschakelde hardware, software en serviceactivering. De prestaties zijn afhankelijk van de systeemconfiguratie. Geen enkel computersysteem kan absoluut veilig zijn. Neem contact op met de fabrikant of leverancier of lees meer op intel.com.",
          "⁴Voor systemen met FHD-schermen, wanneer gebruikt voor draadloos internetten. Indien uitgeschakeld vanuit OEM-standaard uitschakelniveau.",
          "⁵Vergeleken met andere I/O-verbindingstechnologieën voor pc's, waaronder eSATA, USB en IEEE 1394* FireWire. De prestaties zijn afhankelijk van de gebruikte hardware- en softwareconfiguratie. Gebruik van een device met Thunderbolt™ technologie is noodzakelijk.",
          "⁶Bijna 3x sneller: 802.11ax 2x2 160 MHz maakt 2402 Mbps maximale theoretische gegevenssnelheden mogelijk. ~3x sneller dan standaard 802.11ac 2x2 80 MHz (867 Mbps) wifi zoals gedocumenteerd in IEEE 802.11* draadloze standaardspecificaties en vereist het gebruik van vergelijkbare geconfigureerde 802.11ax draadloze netwerkrouters.",
          "⁷75% minder latentie: is gebaseerd op simulatiegegevens van Intel (79%) van 802.11ax met en zonder OFDMA met 9 clients. Gemiddelde latentie zonder OFDM is 36 ms, gemiddelde latentie met OFDMA is 7,6 ms. Om de latentie te verbeteren moeten de 802.11ax (Wi-Fi 6) router en alle clients OFDMA ondersteunen.",
          "⁸Gebaseerd op de vergelijking van de theoretische maximale gegevenssnelheid in de 802.11-specificatie, kan 2x2 160 MHz Wi‑Fi 6/6E (802.11ax) 2402 Mbps leveren vergeleken met 2x2 80 MHz Wi‑Fi 5 (802.11ac) die 867 Mbps levert, wat overeenkomt met 2,8x hogere snelheden.",
          "⁹De beste Wi‑Fi 6 in zijn klasse: Intel® Wi‑Fi 6 (Gig+) producten ondersteunen optionele 160 MHz-kanalen, waardoor de theoretisch hoogst mogelijke maximumsnelheid (2402 Mbps) mogelijk is voor standaard 2x2 802.11ax wifiproducten voor pc. Premium Intel® Wi‑Fi 6 (Gig+) producten maken theoretisch 2-4x hogere snelheden mogelijk vergeleken met standaard 2x2 (1201 Mbps) of 1x1 (600 Mbps) 802.11ax wifiproducten voor pc's, die alleen de verplichte vereisten van 80 MHz-kanalen ondersteunen.",
          "Alleen bepaalde SKU's, functionaliteit verschilt per OEM-ontwerp. Informeer bij de OEM of de verkoper voor meer informatie. Ga voor meer informatie over wifi naar intel.com/wifi6disclaimers.",
          "¹⁰Thunderbolt™ 4 technologie, met 40 Gbps, biedt de snelste, eenvoudigste en meest betrouwbare kabeloplossing voor docks, schermen en gegevensdevices vergeleken met andere I/O-verbindingstechnologieën voor pc's, waaronder eSATA*, USB* en IEEE 1394* FireWire.",
          "¹¹Vereist een 802.11ax router die OFDMA en meerdere clients ondersteunt op het netwerk met ondersteuning voor AX. Een betere werking in omgevingen met veel devices dankzij de OFDMA-kenmerken die worden ondersteund door 802.11ax clients en toegangspunten. 2 Gbps gebaseerd op aannames van ongeveer 70% van de theoretische maximale gegevenssnelheden van 802.11ax 160 MHz 2402 Mbps volgens de specificatie van IEEE 802.11*.",
          "¹²Zoals gemeten door openen van een document met een achtergrondwerkbelasting, waarbij de 8<sup>e</sup> generatie Intel® Core™ i7-8565U processor (512 GB TLC SSD) vergeleken wordt met de 8<sup>e</sup> generatie Intel® Core™ i7-8565U processor (32 GB + 512 GB Intel® Optane™ geheugen H10 met solid-stateopslag).",
          "¹³Intel® Thread Director is ontworpen in de 12<sup>e</sup> generatie Intel® Core™ processors en ondersteunt besturingssystemen om slimmer werkbelastingen naar de juiste core te sturen. Er is geen actie van de gebruiker vereist. Zie intel.com voor meer informatie.",
          "¹⁴Niet beschikbaar op bepaalde 12<sup>e</sup> generatie Intel® Core™ processors. De hybride prestatiearchitectuur combineert twee nieuwe core-microarchitecturen, Performance-cores (P-cores) en Efficient-cores (E-cores) op één processor. Bepaalde 12<sup>e</sup> generatie Intel® Core™ processors (sommige 12<sup>e</sup> generatie Intel® Core™ i5 processors en lager) ondersteunen niet de hybride prestatiearchitectuur, alleen P-cores.",
          "¹⁵Zoals gemeten via werkbelasting van het laden van een gamelevel waarbij een 8<sup>e</sup> generatie Intel® Core™ i7-8750H processor (32 GB Intel® Optane™ geheugenmodule + Intel® SSD 256 GB PCIe* + 1 TB HDD) wordt vergeleken met een 8<sup>e</sup> generatie Intel® Core™ i7-8750H processor (Intel® SSD 256 GB PCIe* + 1 TB HDD). Werkbelasting van het laden van een gamelevel - Werkbelasting ontwikkeld door Intel meet de benodigde tijd voor het openen van Total War*: WARHAMMER* II build: 5577.0 en het bereiken van hoofdmenu met introvideo's uitgeschakeld (Starten). Intel® Optane™ geheugen - Snellere gegevensschijfconfiguratie: Intel® Core™ i7+ 8750H processor, PL1=45 W TDP, 6C12T, turbo tot 4 GHz op pre-productie OEM-systeem, grafische kaart: NVIDIA* GeForce* GTX 1070, geheugen: 2x4 GB DDR4, opslag: Intel® SSD Series 760p 256 GB (PCIe*) + 1 TB HDD + 32 GB Intel® Optane™ geheugen, besturingssysteem: Windows* 10 RS3 build 1709, MCU 0x84; Intel® Core™ i7-8750H processor, PL1=45 W TDP, 6C12T, turbo tot 4 GHz op pre-productie OEM-systeem, grafische kaart: NVIDIA* GeForce* GTX 1070, geheugen: 2x4 GB DDR4, opslag: Intel® SSD Series 760p 256 GB (PCIe*) + 1TB HDD, besturingssysteem: Windows* 10 RS3 build 1709, MCU 0x84.",
          "¹⁶ Zoals gemeten via werkbelasting van het laden van een gamelevel waarbij een 8<sup>e</sup> generatie Intel® Core™ i7-8750H processor (32 GB Intel® Optane™ geheugenmodule) wordt vergeleken met een 8<sup>e</sup> generatie Intel® Core™ i7-8750H processor (alleen HDD). Werkbelasting van het laden van een gamelevel - Werkbelasting ontwikkeld door Intel meet de benodigde tijd om vanaf het hoofdmenu een level van Total War*: WARHAMMER* II build: 5577.0 te laden (level load). Intel® Optane™ geheugen - Snellere gegevensschijfconfiguratie: Intel® Core™ i7+ 8750H processor, PL1=45 W TDP, 6C12T, Turbo tot 4 GHz op pre-productie OEM-systeem, grafische kaart: NVIDIA* GeForce* GTX 1070, geheugen: 2x4 GB DDR4, opslag: Intel® SSD Series 760p 256 GB (PCIe*) + 1 TB HDD + 32 GB Intel® Optane™ geheugen, besturingssysteem: Windows* 10 RS3 build 1709, MCU 0x84; Intel® Core™ i7-8750H processor, PL1=45 W TDP, 6C12T, Turbo tot 4 GHz op pre-productie OEM-systeem, grafische kaart: NVIDIA* GeForce* GTX 1070, geheugen: 2x4 GB DDR4, opslag: Intel® SSD Series 760p 256 GB (PCIe*) + 1 TB HDD, besturingssysteem: Windows* 10 RS3 build 1709, MCU 0x84.",
          "¹⁷Op Performance-cores. De prestaties variëren per gebruik, configuratie en andere factoren. Meer informatie op www.intel.com/PerformanceIndex.",
          "¹⁸Het wijzigen van de kloksnelheid of het voltage kan leiden tot beschadiging of een kortere levensduur van de processor en andere systeemcomponenten, en kan de stabiliteit en prestaties van het systeem verminderen. Productgaranties zijn mogelijk niet geldig als de processor wordt gebruikt bij waarden die buiten de specificaties vallen. Informeer bij de fabrikanten van het systeem en de onderdelen voor meer informatie.",
          "¹⁹Zoals gemeten door de Intel vPro® platforms ongeëvenaarde combinatie van de boven- en onderstaande beveiligingsmogelijkheden van het besturingssysteem, app- en gegevensbeveiliging en geavanceerde beveiliging tegen bedreigingen voor bedrijven van elke omvang, alsook Intels aanpak waarbij beveiliging voorop staat voor productontwerp, productie en ondersteuning. Alle zakelijke pc's die zijn gebouwd op het Intel vPro® platform zijn getoetst aan strenge specificaties, waaronder unieke op hardware gebaseerde beveiligingskenmerken. Zie www.intel.com/PerformanceIndex (platforms) voor informatie. Geen enkel product of component kan absoluut veilig zijn.",
          "²⁰Intel® Connectivity Performance Suite is alleen beschikbaar op systemen met Windows*. Intel Over The Air (OTA) wifi testen op een overbelast netwerk versus zonder ICPS softwaretoepassingen draadloze Traffic Prioritization en Smart Access Point Switching. Zie www.intel.com/PerformanceIndex (connectiviteit) voor informatie. Resultaten kunnen variëren.",
          "²¹In vergelijking met de vorige processorgeneratie.",
          "²²Intel® Arc™ graphics zijn de eerste op de markt met AV1-coderingondersteuning (vanaf het 1<sup>e</sup> kwartaal van 2022). Meer informatie op intel.com/performanceindex.",
          "²³Zoals gemeten aan de hand van unieke kenmerken van Intel® Evo™ modellen, die ook zijn geverifieerd om te voldoen aan de belangrijkste drempels voor mobiele gebruikerservaringen. Testen vanaf februari 2022.",
          "²⁴De Intel® Unison™ oplossing is momenteel alleen beschikbaar op in aanmerking komende Intel® Evo™ modellen op pc's met Windows* en is alleen compatibel met smartphones met Android of iOS; alle devices moeten worden ondersteund door een besturingssysteem.",
          "²⁵Maximale geheugensnelheden zijn gekoppeld aan configuraties van 1 DIMM per kanaal (1DPC). Het extra laden van DIMM's op elk kanaal kan de maximale geheugensnelheid beïnvloeden. Tot DDR5-5600 MT/s 1DPC UDIMM 1Rx8, 1Rx16 en DDR5-5200 1Rx8, 1Rx16, 2Rx8 op bepaalde SKU's. Maximale geheugencapaciteit is haalbaar met 2DPC-configuraties.",
          "²⁶Vergeleken met standaard wifi. Zie intel.com/performance-wireless voor meer informatie. Resultaten kunnen variëren.",
          "²⁷De prestatiegerichte hybride architectuur combineert twee core-microarchitecturen, Performance-cores (P-cores) en Efficient-cores (E-cores) in één processor en is voor het eerst geïntroduceerd in 12<sup>e</sup> generatie Intel® Core™ processors. Bepaalde 12<sup>e</sup> generatie en nieuwere Intel® Core™ processors hebben geen hybride prestatiearchitectuur, maar alleen P-cores, en hebben dezelfde cachegrootte als de vorige generatie; zie ark.intel.com voor SKU-details.",
          "²⁸Intel® Thread Director is ingebouwd in de hardware en wordt alleen geleverd in configuraties met hybride prestatiearchitectuur van de 12<sup>e</sup> generatie of nieuwere Intel® Core™ processors; activering via het besturingssysteem is vereist. Beschikbare kenmerken en functionaliteit verschillen per OS.",
          "²⁹Intel® Thread Director is in de hardware ingebouwd en zit alleen in configuraties met de hybride prestatiearchitectuur van de 12<sup>e</sup> en 13<sup>e</sup> generatie Intel® Core™ processors; activering via het besturingssysteem is vereist. Beschikbare kenmerken en functionaliteit verschillen per OS.",
          "³⁰Zoals gemeten door de prestaties van het referentievalidatieplatform van Intel®&nbsp;Core™ Ultra mobiele processor (Series&nbsp;2 — codenaam: Lunar Lake) versus Intel®&nbsp;Core™ Ultra mobiele processor (Series&nbsp;1 — codenaam: Meteor Lake) op 3DMark Time Spy. Zie www.intel.com/performanceindex voor meer informatie. Resultaten kunnen variëren.",
          "³¹Achtergrondvervaging en gezichtstracking zijn alleen beschikbaar op systemen met Windows*.",
          "³²Wi-Fi 7-producten kunnen toegang krijgen tot 320 MHz kanalen in 6 GHz en nieuwe combinaties van 160 MHz kanalen in 5 GHz met nieuwe Multi-Resource Unit Puncturing-mogelijkheden.",
          "³³Gebaseerd op de IEEE-specificaties voor draadloze standaarden en de maximale theoretische gegevenssnelheden voor 2-stream apparaten.",
          "³⁴Intel Engineering-simulaties van overbelaste netwerkomgevingen laten zien dat significant lagere latentie mogelijk is met nieuwe Wi-Fi 7 Multi-Link Operation-mogelijkheden.",
          "³⁵Minimumlading bereikt vanaf standaard OEM-niveau voor uitschakelen. Resultaten van individuele systemen kunnen variëren. Zie intel.com/performance-evo voor meer informatie.",
          "³⁶De 6 GHz Wi-Fi-band is niet beschikbaar voor gebruik in alle landen/regio's.",
          "³⁷Alleen Windows*",
          "³⁸Laden werd gedaan vanaf standaard OEM-niveau voor uitschakelen. Ga voor volledige informatie over prestaties en benchmarkresultaten naar intel.com/Evo.",
          "³⁹Verwijst naar Intel®&nbsp;Core™ Ultra mobiele processors (Series&nbsp;2), gebaseerd op de unieke hardware-gebaseerde beveiligingsfuncties, ongeëvenaarde combinatie van beveiligingsmogelijkheden boven en onder het besturingssysteem, indrukwekkende prestaties, zelfs bij een lager energieverbruik in vergelijking met de vorige generatie (per september 2024). Zie www.intel.com/performanceindex voor meer informatie. Resultaten kunnen variëren.",
          "⁴⁰De weergegeven softwaretoepassingen zijn alleen bedoeld voor illustratieve doeleinden. AI-functies kunnen de aankoop van aanvullende software, een abonnement of inschakeling door een software- of platformprovider vereisen of hebben mogelijk specifieke configuratie- of compatibiliteitsvereisten. Meer informatie op www.intel.com/PerformanceIndex. Resultaten kunnen variëren. © Intel Corporation.",
          "⁴¹De getoonde afbeeldingen zijn mogelijk gewijzigd of gesimuleerd. AI-functies kunnen de aankoop van aanvullende software, een abonnement of inschakeling door een software- of platformprovider vereisen of hebben mogelijk specifieke configuratie- of compatibiliteitsvereisten. Meer informatie op www.intel.com/AIPC.",
          "⁴²De nieuwste update van Windows* 11 wordt wereldwijd geleidelijk in een voorvertoning uitgerold op bepaalde markten. De beschikbaarheid verschilt per device en markt. Meer informatie hier: aka.ms/WindowsAIFeatures. © Intel Corporation.",
          "⁴³De Intel® Unison™ oplossing is momenteel beschikbaar voor in aanmerking komende modellen. Zie www.intel.com/PerformanceIndex voor meer informatie.",
          "⁴⁴FPS opgeschaald door Intel® X<sup>e</sup>SS versus inheemse FPS, zoals gemeten door Dying Light 2 Stay Human uitgevoerd op Intel® Core™ Ultra 7 processor 165H. Zie www.intel.com/PerformanceIndex voor meer informatie. Resultaten kunnen variëren.",
          "⁴⁵Zoals gemeten door het lagere SoC-vermogen van Intel®&nbsp;Core™ Ultra mobiele processor (Series&nbsp;2 — codenaam: Lunar Lake) referentievalidatieplatform versus Intel®&nbsp;Core™ Ultra mobiele processor (Series&nbsp;1 — codenaam: Meteor Lake) referentievalidatieplatform op YouTube* 4K 30FPS AV1. Zie www.intel.com/performanceindex voor meer informatie. Resultaten kunnen variëren.",
          "[ZhCn Only Legal Text General]",
          "[ZhCn Only Legal Text Point12]",
          "[ZhCn Only Legal Text Point13]",
          "Intel aanvaardt geen aansprakelijkheid voor onjuiste prijzen.",
          "*Andere namen en merknamen kunnen worden geclaimd als het eigendom van anderen. ",
          "COPYRIGHT 2022 INFLEXION GAMES. NIGHTINGALE, INFLEXION GAMES EN GERELATEERDE ONTWERPMERKEN EN SAMENGESTELDE MERKEN ZIJN HANDELSMERKEN VAN INFLEXION STUDIOS INC. ALLE RECHTEN VOORBEHOUDEN.",
          "Intel technologieën vereisen mogelijk ingeschakelde hardware, software of serviceactivering.",
          "Alle resultaten en gegevens van NERO* worden uitsluitend als service en voor informatieve doeleinden verstrekt. Intel Corporation draagt geen verantwoordelijkheid voor de nauwkeurigheid van de NERO* app, prestatieresultaten en scores, of mogelijke afwijkingen van andere downloadbare versies van de app die beschikbaar zijn op www.nero.com of andere websites van derden. NERO* PC benchmarkscores worden gemeten aan de hand van specifieke computersystemen, componenten, software, bewerkingen en functies. Wijzigingen in een van deze factoren kan leiden tot een afwijkend resultaat. Het is raadzaam om andere informatie en prestatietests te raadplegen, waaronder de prestaties van het product in combinatie met andere producten, zodat een goed geïnformeerde en weloverwogen aankoopbeslissing kan worden genomen.",
          "Alle versies van het Intel vPro® platform vereisen een in aanmerking komende Intel® Core™ processor, een ondersteund besturingssysteem, Intel® LAN en/of WLAN silicium, verbeterde firmware en andere hardware en software die nodig zijn om de gebruiksscenario's voor het beheer, de beveiligingskenmerken, de systeemprestaties en de stabiliteit te leveren die het platform definiëren.",
          "De prestaties variëren per gebruik, configuratie en andere factoren. Meer informatie op www.intel.com/PerformanceIndex.",
          "Intel beheert of controleert geen gegevens van derden. Raadpleeg meerdere bronnen om de juistheid te beoordelen.",
          "© Intel Corporation. Intel, het Intel logo en andere Intel merken zijn handelsmerken van Intel Corporation of haar dochterondernemingen. *Andere namen en merknamen kunnen worden geclaimd als het eigendom van anderen.",
          "Geen enkel product of component kan absolute beveiliging bieden.",
          "Kosten en resultaten kunnen variëren.",
          "Intel, het Intel logo, Intel Core, Intel Optane, Intel Iris, Thunderbolt en het Thunderbolt logo zijn handelsmerken van Intel Corporation of haar dochterondernemingen. Andere namen en merknamen kunnen worden geclaimd als het eigendom van anderen.",
          "© Intel Corporation."
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "Home",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "Scannen om te vergelijken",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "Trending",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "Maak nieuwe AI-ervaringen mogelijk met Intel® Core™ Ultra processors",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "Maak nieuwe AI-ervaringen mogelijk met Intel® Core™ Ultra processors",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "Maak nieuwe AI-ervaringen mogelijk met Intel® Core™ Ultra processors",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "Stable Diffusion*: tekst naar afbeelding",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe*: fotobewerking",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "Intel® X<sup>e</sup>SS: opschalen van afbeeldingen",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee*: Deepfake Detector",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "Samenwerkingseffecten (Teams)",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "IQIYI: controle van gebaren",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "Synchroniseer je pc met je telefoon en bekijk meldingen, oproepen en sms-berichten",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "Rijke en levendige ingebouwde graphics",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "Microsoft*: leef je leven op jouw manier",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "Microsoft*: alles wat jij wilt. Alles in één oogopslag, slechts met één veeg",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "Voer je processor sneller en op hogere frequenties uit door je systeem te fine-tunen",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "Verbeter de prestaties van je desktop of mobiele systeem zonder in te boeten op opslagruimte",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "Verbeter de prestaties van je desktop of mobiele systeem zonder in te boeten op opslagruimte",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "Samsung OLED: een streling voor vermoeide ogen",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "Hogere snelheden, lagere latentie en meer betrouwbaarheid door AI-gebaseerde netwerkoptimalisatie",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "Ultrasnelle pc-netwerken",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "Ultrasnelle pc-netwerken",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "Bijna 3x sneller met Intel® Wi-Fi 6 (Gig+)",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "Maak verbinding zonder concurrentie",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "Universele kabelconnectiviteit",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "Speel als de professionals: ontdek hoe Intel gamen en gamers ondersteunt",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "Speel als de professionals: winnaars worden gemaakt met Intel",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "WELKOM",
    "tr_welcomePagetrSubtitle": "Bestuur deze pc op afstand met je smartphone",
    "tr_welcomePagembSubtitle": "Welkom, ontdek de specificaties van deze pc op je smartphone",
    "tr_welcomePageBtnText": "Aan de slag",
    "tr_welcomePageText": "Ontdek en vergelijk de details van deze pc op je smartphone",
    "tr_scrollText": "Ga voor het device staan",
    "tr_toolTipTextc1": "Gebruik deze knoppen om naar verschillende pagina's op het device voor je te gaan.",
    "tr_toolTipTextc2": "Tik op onderstaande kaarten om naar de pagina's met uitgebreide specificaties van het device te gaan.",
    "tr_toolTipTextc3": "Gebruik deze knoppen om naar boven en beneden te scrollen op het scherm voor je.",
    "tr_toolTipTextc4": "Schakel de afstandsmodus uit om de devicespecificaties op je smartphone te bekijken.",
    "tr_toolTipTextmb1": "Tik op de kaarten om pagina's met uitgebreide informatie te bekijken.",
    "tr_toolTipTextmb2": "Tik om een overzicht van alle devicespecificaties te bekijken",
    "tr_toolTipTextmb3": "Tik op de knop om de QR-code te scannen en tot vier devices te vergelijken",
    "tr_nextButton": "Volgende",
    "tr_prevButton": "Vorige",
    "tr_gotItButton": "IK SNAP HET",
    "tr_skipButton": "Overslaan",
    "tr_placeholderText": "*placeholder",
    "tr_processorHeading": "Processor",
    "tr_processorSubheading": "10<sup>e</sup> generatie Intel® Core™ i7 processor",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "[XX] GB Intel® Optane™ geheugen + [YY] GB SSD",
    "tr_coachmarkProcessor": "XX processor",
    "tr_coachmarkGraphics": "[XX] graphics",
    "tr_coachmarkMemory": "Specificatiewaarde",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "$ 999,95",
    "tr_compare": "Vergelijken",
    "tr_viewallspecs": "ALLE SPECIFICATIES",
    "tr_operatingSystem": "OS",
    "tr_AmdProcessorNonIntel": "AMD* processor",
    "tr_QualcommProcessorNonIntel": "Qualcomm* processor",
    "tr_GBText": "GB",
    "tr_remoteText": "Specificaties weergegeven op het laptopscherm",
    "tr_scanErrorText": "Je kunt maximaal drie devices tegelijk scannen. Verwijder een of meer devices.",
    "tr_scanErrorText2": "Je hebt dit device al gescand. Scan een ander device.",
    "tr_scanMsg": "Scan om een device toe te voegen",
    "tr_backToScan": "Terug naar vergelijken",
    "tr_scanToCompare": "Scannen om te vergelijken",
    "tr_compareDevice": "Devices vergelijken",
    "tr_processorTitle": "PROCESSOR",
    "tr_graphicTitle": "GRAPHICS",
    "tr_storageTitle": "OPSLAG",
    "tr_displayTitle": "SCHERM",
    "tr_batteryTitle": "ACCU",
    "tr_memoryTitle": "GEHEUGEN",
    "tr_wifiTitle": "Wifi",
    "tr_connectivityTitle": "CONNECTIVITEIT",
    "tr_priceTitle": "PRIJS",
    "tr_operatingSystemTitle": "BESTURINGSSYSTEEM",
    "tr_batteryHoursText": "[XX] uur",
    "tr_hrsText": "uur",
    "tr_touchscreeenText": "[screenDiagonal]” Touchscreen",
    "tr_HDText": "HD",
    "tr_FHDText": "FHD",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "HD, touchscreen",
    "tr_FHDTouchScreenText": "FHD, touchscreen",
    "tr_4KTouchscreenText": "4K, touchscreen",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "Opslaan",
    "tr_device_comparison": "Document met devicevergelijkingen [dd]-[mm]-[yyyy]",
    "tr_Save_As_JPEG": "Opslaan als jpeg",
    "tr_Save_As_PDF": "Opslaan als pdf",
    "tr_Downloading_PDF": "Pdf downloaden",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "Open",
    "tr_logo": "logo",
    "tr_laptop_name": "Laptopnaam",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "Intel® Optane™ geheugen",
    "tr_inchUnit": "inch",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "Intel® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "INTEL® WI‑FI 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "Intel® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "INTEL® OPTANE™ GEHEUGEN",
    "tr_Thunderbolt3": "Thunderbolt™ 3 technologie",
    "tr_Thunderbolt4": "Thunderbolt™ 4 technologie",
    "tr_processorGraphicsCaps": "GEÏNTEGREERDE GRAPHICS",
    "tr_processorGraphicsSmall": "Geïntegreerde graphics",
    "tr_graphicsCardCaps": "DEDICATED GRAPHICS",
    "tr_graphicsCardSmall": "Dedicated graphics",
    "tr_processorTitleCamel": "Processor",
    "tr_graphicTitleCamel": "Graphics",
    "tr_storageTitleCamel": "Opslag",
    "tr_displayTitleCamel": "Scherm",
    "tr_batteryTitleCamel": "Accu",
    "tr_memoryTitleCamel": "Geheugen",
    "tr_connectivityTitleCamel": "Connectiviteit",
    "tr_priceTitleCamel": "Prijs",
    "tr_operatingSystemTitleCamel": "Besturingssysteem",
    "tr_viewallspecsCamel": "Alle specificaties bekijken",
    "tr_displayText": "[screenDiagonal]”",
    "tr_displayTextResolution": "[screenDiagonal]” [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "[screenDiagonal]” [screenResolutionType] Touchscreen",
    "tr_OS": "OS",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "Pc-specificaties",
    "tr_explorePC_text": "Ontdek alles over deze pc",
    "tr_mtlPC_text": "Ontdek wat gemaakt om alles te kunnen doen echt betekent",
    "tr_optaneMemory": "Intel® Optane™ geheugen",
    "tr_displayText_Display": "[screenDiagonal]\" SCHERM",
    "tr_displayTextResolution_Display": "[screenDiagonal]\" [screenResolutionType] SCHERM",
    "tr_displayTextResolutionTouchscreen_Display": "[screenDiagonal]\" [screenResolutionType] TOUCHSCREEN",
    "tr_displayTextTouchscreeenText": "[screenDiagonal]” TOUCHSCREEN",
    "tr_learnMoreLabel": "Meer informatie",
    "tr_batteryHoursTextUpperCase": "[XX] UUR",
    "tr_processorGraphicsIrisXe": "11<sup>e</sup> GENERATIE INTEL® CORE™ [cpu_fam] PROCESSOR EN INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAPHICS",
    "tr_processorGraphicsIrisXeMax": "11<sup>e</sup> GENERATIE INTEL® CORE™ [cpu_fam] PROCESSOR EN INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAPHICS",
    "tr_Thunderbolt3UpperCase": "THUNDERBOLT™ 3 TECHNOLOGIE",
    "tr_Thunderbolt4UpperCase": "THUNDERBOLT™ 4 TECHNOLOGIE",
    "tr_processorWithArcGraphics": "12<sup>e</sup> GENERATIE INTEL® CORE™ [cpu_fam] PROCESSOR EN INTEL® ARC™ GRAPHICS",
    "tr_processorGraphicsIrisXe12Gen": "12<sup>e</sup> GENERATIE INTEL® CORE™ [cpu_fam] PROCESSOR EN INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAPHICS",
    "tr_processorGraphicsIrisXeMax12Gen": "12<sup>e</sup> GENERATIE INTEL® CORE™ [cpu_fam] PROCESSOR EN INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAPHICS",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "13<sup>e</sup> GENERATIE INTEL® CORE™ [cpu_fam] PROCESSOR EN INTEL® ARC™ GRAPHICS",
    "tr_processorGraphicsIrisXe13Gen": "13<sup>e</sup> GENERATIE INTEL® CORE™ [cpu_fam] PROCESSOR EN INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> GRAPHICS",
    "tr_processorGraphicsIrisXeMax13Gen": "13<sup>e</sup> GENERATIE INTEL® CORE™ [cpu_fam] PROCESSOR EN INTEL® IRIS® X<sup class='small_text_sup_xe'>e</sup> MAX GRAPHICS",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "INTEL® ARC™ A350M GRAPHICS",
      "tr_A370M": "INTEL® ARC™ A370M GRAPHICS",
      "tr_A730M": "INTEL® ARC™ A730M GRAPHICS",
      "tr_A770M": "INTEL® ARC™ A770M GRAPHICS",
      "tr_A310": "INTEL® ARC™ A310 GRAPHICS",
      "tr_A380": "INTEL® ARC™ A380 GRAPHICS",
      "tr_A580": "INTEL® ARC™ A580 GRAPHICS",
      "tr_A750": "INTEL® ARC™ A750 GRAPHICS",
      "tr_A770": "INTEL® ARC™ A770 GRAPHICS"
    },
    "tr_memoryValue": "[XX] GB RAM",
    "tr_memoryWithOptaneDesc": "[Value1] GB geheugen = [Value2] GB Intel® Optane™ geheugen + [Value3] GB RAM",
    "tr_textGBGB": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textGBTB": "[convertedSSD] GB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textTBGB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;GB&nbsp;HDD",
    "tr_textTBTB": "[convertedSSD] TB SSD + [convertedHDD]&nbsp;TB&nbsp;HDD",
    "tr_textGBSSD": "[convertedSSD] GB SSD",
    "tr_textTBSSD": "[convertedSSD] TB SSD",
    "tr_textGBHDD": "[convertedHDD] GB HDD",
    "tr_textTBHDD": "[convertedHDD] TB HDD",
    "tr_textStorageWithOptaneGB": "[XX] GB Intel®&nbsp;Optane™ geheugen +&nbsp;[convertedSSD]&nbsp;GB&nbsp;SSD",
    "tr_textStorageWithOptaneTB": "[XX] GB Intel®&nbsp;Optane™ geheugen +&nbsp;[convertedSSD]&nbsp;TB&nbsp;SSD",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 Home Basic",
      "tr_OS3": "Windows* 7 Home Premium",
      "tr_OS4": "Windows* 7 Professional",
      "tr_OS5": "Windows* 7 Enterprise",
      "tr_OS6": "Windows* 7 Ultimate",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 Pro",
      "tr_OS9": "Windows* 8 Enterprise",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 Pro",
      "tr_OS12": "Windows* 8.1 Enterprise",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 Home",
      "tr_OS15": "Windows* 10 Pro",
      "tr_OS16": "Windows* 10 Enterprise",
      "tr_OS17": "Windows* 11 Home",
      "tr_OS18": "Windows* 11 Pro",
      "tr_OS19": "Windows* 11 Enterprise",
      "tr_OS20": "Windows* 10 Home in S-modus",
      "tr_OS21": "Windows* 10 Pro in S-modus",
      "tr_OS22": "Windows* 10 Enterprise in S-modus",
      "tr_OS23": "Windows* 11 Home in S-modus",
      "tr_OS24": "Windows* 11 Pro in S-modus",
      "tr_OS25": "Windows* 11 Enterprise in S-modus"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "Intel® Arc™ A310 graphics",
      "tr_GR387": "Intel® Arc™ A350M graphics",
      "tr_GR388": "Intel® Arc™ A370M graphics",
      "tr_GR389": "Intel® Arc™ A380 graphics",
      "tr_GR390": "Intel® Arc™ A530M graphics",
      "tr_GR391": "Intel® Arc™ A550M graphics",
      "tr_GR392": "Intel® Arc™ A570M graphics",
      "tr_GR393": "Intel® Arc™ A580 graphics",
      "tr_GR394": "Intel® Arc™ A730M graphics",
      "tr_GR395": "Intel® Arc™ A750 graphics",
      "tr_GR396": "Intel® Arc™ A770 graphics",
      "tr_GR397": "Intel® Arc™ A770M graphics",
      "tr_GR398": "Intel® Arc™ graphics",
      "tr_GR399": "Intel® Arc™ Pro A30M graphics",
      "tr_GR400": "Intel® Arc™ Pro A40/A50 graphics",
      "tr_GR401": "Intel® Arc™ Pro A60 graphics",
      "tr_GR402": "Intel® Arc™ Pro A60M graphics",
      "tr_GR403": "Intel® Graphics",
      "tr_GR404": "Intel® HD Graphics 2000",
      "tr_GR405": "Intel® HD Graphics 2500",
      "tr_GR406": "Intel® HD Graphics 3000",
      "tr_GR407": "Intel® HD Graphics 400",
      "tr_GR408": "Intel® HD Graphics 4000",
      "tr_GR409": "Intel® HD Graphics 405",
      "tr_GR410": "Intel® HD Graphics 4200",
      "tr_GR411": "Intel® HD Graphics 4400",
      "tr_GR412": "Intel® HD Graphics 4600",
      "tr_GR413": "Intel® HD Graphics 500",
      "tr_GR414": "Intel® HD Graphics 5000",
      "tr_GR415": "Intel® HD Graphics 505",
      "tr_GR416": "Intel® HD Graphics 510",
      "tr_GR417": "Intel® HD Graphics 515",
      "tr_GR418": "Intel® HD Graphics 520",
      "tr_GR419": "Intel® HD Graphics 530",
      "tr_GR420": "Intel® HD Graphics 5300",
      "tr_GR421": "Intel® HD Graphics 5500",
      "tr_GR422": "Intel® HD Graphics 5600",
      "tr_GR423": "Intel® HD Graphics 6000",
      "tr_GR424": "Intel® HD Graphics 610",
      "tr_GR425": "Intel® HD Graphics 615",
      "tr_GR426": "Intel® HD Graphics 620",
      "tr_GR427": "Intel® HD Graphics 630",
      "tr_GR428": "Intel® HD Graphics",
      "tr_GR429": "Intel® Iris® Graphics 5100",
      "tr_GR430": "Intel® Iris® Graphics 540",
      "tr_GR431": "Intel® Iris® Graphics 550",
      "tr_GR432": "Intel® Iris® Graphics 6100",
      "tr_GR433": "Intel® Iris® Plus Graphics 640",
      "tr_GR434": "Intel® Iris® Plus Graphics 645",
      "tr_GR435": "Intel® Iris® Plus Graphics 650",
      "tr_GR436": "Intel® Iris® Plus Graphics 655",
      "tr_GR437": "Intel® Iris® Plus graphics",
      "tr_GR438": "Intel® Iris® Pro Graphics 5200",
      "tr_GR439": "Intel® Iris® Pro Graphics 580",
      "tr_GR440": "Intel® Iris® Pro Graphics 6200",
      "tr_GR441": "Intel® Iris® X<sup>e</sup> graphics",
      "tr_GR442": "Intel® Iris® X<sup>e</sup> MAX graphics A220M",
      "tr_GR443": "Intel® Iris® X<sup>e</sup> MAX graphics",
      "tr_GR444": "Intel® Integrated Graphics",
      "tr_GR445": "Intel® UHD Graphics 600",
      "tr_GR446": "Intel® UHD Graphics 605",
      "tr_GR447": "Intel® UHD Graphics 610",
      "tr_GR448": "Intel® UHD Graphics 615",
      "tr_GR449": "Intel® UHD Graphics 617",
      "tr_GR450": "Intel® UHD Graphics 620",
      "tr_GR451": "Intel® UHD Graphics 630",
      "tr_GR452": "Intel® UHD Graphics 710",
      "tr_GR453": "Intel® UHD Graphics 730",
      "tr_GR454": "Intel® UHD Graphics 770",
      "tr_GR455": "Intel® UHD graphics",
      "tr_GR456": "Intel® UHD graphics",
      "tr_GR457": "Intel® UHD graphics",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "NVIDIA* GeForce* GTX 1050 met Max-Q Design",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "NVIDIA* GeForce* GTX 1050 Ti met Max-Q Design",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "NVIDIA* GeForce* GTX 1060 met Max-Q Design",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "NVIDIA* GeForce* GTX 1070 met Max-Q Design",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "NVIDIA* GeForce* GTX 1080 met Max-Q Design",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "NVIDIA* GeForce* GTX 1650 met Max-Q Design",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "NVIDIA* GeForce* GTX 1650 Ti met Max-Q Design",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "NVIDIA* GeForce* GTX 1660 Ti met Max-Q Design",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "NVIDIA* GeForce* RTX 2060 met Max-Q Design",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "NVIDIA* GeForce* RTX 2070 met Max-Q Design",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "NVIDIA* GeForce* RTX 2070 Super met Max-Q Design",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "NVIDIA* GeForce* RTX 2080 met Max-Q Design",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "NVIDIA* GeForce* RTX 2080 Super met Max-Q Design",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "NVIDIA* Quadro* P2000 met Max-Q Design",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "NVIDIA* Quadro* P3200 met Max-Q Design",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "NVIDIA* Quadro* P4000 met Max-Q Design",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "NVIDIA* Quadro* P4200 met Max-Q Design",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "NVIDIA* Quadro* P5200 met Max-Q Design",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "NVIDIA* Quadro* RTX 3000 met Max-Q Design",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "NVIDIA* Quadro* RTX 4000 met Max-Q Design",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "NVIDIA* Quadro* RTX 5000 met Max-Q Design",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "NVIDIA* Quadro* T1000 met Max-Q Design",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "NVIDIA* Quadro* T2000 met Max-Q Design",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "Intel® Arc™ graphics",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "AMD Radeon* Pro W7900 Dual Slot"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "Intel® Centrino® Advanced-N 6200 ABG",
      "tr_Wifi2": "Intel® Centrino® Advanced-N 6200 AGN",
      "tr_Wifi3": "Intel® Centrino® Advanced-N 6200 BG",
      "tr_Wifi4": "Intel® Centrino® Advanced-N 6205",
      "tr_Wifi5": "Intel® Centrino® Advanced-N 6230",
      "tr_Wifi6": "Intel® Centrino® Advanced-N 6235",
      "tr_Wifi7": "Intel® Centrino® Advanced-N 6250 ABG",
      "tr_Wifi8": "Intel® Centrino® Advanced-N 6250 AGN",
      "tr_Wifi9": "Intel® Centrino® Ultimate-N 6300 AGN",
      "tr_Wifi10": "Intel® Centrino® Wireless-N 100",
      "tr_Wifi11": "Intel® Centrino® Wireless-N 1000",
      "tr_Wifi12": "Intel® Centrino® Wireless-N 1030",
      "tr_Wifi13": "Intel® Centrino® Wireless-N 105",
      "tr_Wifi14": "Intel® Centrino® Wireless-N 135",
      "tr_Wifi15": "Intel® Centrino® Wireless-N 2200",
      "tr_Wifi16": "Intel® Centrino® Wireless-N 2230",
      "tr_Wifi17": "Intel® Centrino® Wireless-N WiMAX 6150",
      "tr_Wifi18": "Intel® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "Intel® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "Intel® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "Intel® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "Intel® Dual Band Wireless-N 3160",
      "tr_Wifi23": "Intel® Dual Band Wireless-N 7260",
      "tr_Wifi24": "Intel® Dual Band Wireless-N 7265",
      "tr_Wifi25": "Intel® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "Intel® Wi-Fi 6 AX101",
      "tr_Wifi27": "Intel® Wi-Fi 6 AX200",
      "tr_Wifi28": "Intel® Wi-Fi 6 AX201",
      "tr_Wifi29": "Intel® Wi-Fi 6 AX203",
      "tr_Wifi30": "Intel® Wi-Fi 6E AX210",
      "tr_Wifi31": "Intel® Wi-Fi 6E AX211",
      "tr_Wifi32": "Intel® Wi-Fi 6E AX411",
      "tr_Wifi33": "Intel® Wi-Fi 7 BE200",
      "tr_Wifi34": "Intel® Wi-Fi 7 BE202",
      "tr_Wifi35": "Intel® Wireless-AC 3165",
      "tr_Wifi36": "Intel® Wireless-AC 3168",
      "tr_Wifi37": "Intel® Wireless-AC 8265",
      "tr_Wifi38": "Intel® Wireless-AC 9260",
      "tr_Wifi39": "Intel® Wireless-AC 9461",
      "tr_Wifi40": "Intel® Wireless-AC 9462",
      "tr_Wifi41": "Intel® Wireless-AC 9560",
      "tr_Wifi42": "Intel® Wireless-N 3160",
      "tr_Wifi43": "Intel® Wireless-N 7260",
      "tr_Wifi44": "Intel® Wireless-N 7265",
      "tr_Wifi45": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "Intel® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "Intel® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "Intel® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "Intel® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "Intel® Wi-Fi 7 BE201",
      "tr_Wifi59": "Intel® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "Intel® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "Koop nu",
    "tr_textTryagain": "Probeer het opnieuw",
    "tr_textBuynowErr": "De optie Nu kopen is niet beschikbaar vanwege een verbindingsonderbreking",
    "tr_chatbotPlaceholder_text": "Vraag me alles over het device",
    "tr_deviceSpecs_text": "Devicespecificaties"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "Intel® Core™ i3 processor met Intel® Hybrid Technology"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "Intel® Core™ i5 processor met Intel® Hybrid Technology"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Marvel's Avengers",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Beleef een machtige game-ervaring met Intel®",
                "tr_subtitle": "Breng Marvel’s Avengers* tot leven met in-game optimalisering voor pc's door Intel®.",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Ervaar de Intel® Extreme Masters",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Waar 's werelds beste gamers samenkomen om te strijden",
                "tr_subtitle": "Intel® Extreme Masters is de langstlopende evenementenreeks in de geschiedenis van videogames en stelt de norm voor e-sport.",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Speel als de professionals",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "Kampioen word je met Intel",
                "tr_subtitle": "Na het winnen van de Rainbow Six Siege* North American League competitie in 2021 blikken de Soniqs terug op hun gamegeschiedenis, de&nbsp;toekomst van e-sport en hun samenwerking met Intel.",
                "tr_pretitle": "Met de Soniqs",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "Gereed voor VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "VR komt tot leven",
                "tr_subtitle": "De meest geweldige, levensechte en meeslepende virtual reality ervaar je op een computer. Met de nieuwste Intel® Core™ processors kun je iedereen zijn, overal naartoe gaan en gamen in de volgende dimensie.",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "Speel meer dan 100 hoogwaardige consolegames zoals Sea of Thieves* en Psychonauts* 2 op je Windows* pc, smartphone en tablet met Xbox* Game Pass Ultimate en een compatibele controller.",
                "titleArray": [
                  {
                    "tr_title": "Speel samen op verschillende devices",
                    "tr_subtitle": "Geniet van alles wat Xbox* te bieden heeft met een community van miljoenen spelers die klaar staan om samen te spelen. Maak contact en speel met anderen via een gedeelde gamebibliotheek, of ze zich nu aan de andere kant van de wereld bevinden of vlak naast je zitten."
                  },
                  {
                    "tr_title": "Gamen op Windows* pc's",
                    "tr_subtitle": "Speel een scala aan consolegames vanuit de cloud met de Xbox* app voor Windows* en een compatibele controller."
                  },
                  {
                    "tr_title": "Meteen aan de slag",
                    "tr_subtitle": "Geniet overal van geweldige Xbox* games. Speel meer dan 100 games op je pc, smartphone en tablet, of je nu thuis bent en je tv niet kunt gebruiken, of in een café, of waar dan ook met een snelle internetverbinding."
                  },
                  {
                    "tr_title": "Plezier zonder controller",
                    "tr_subtitle": "Xbox* games spelen met touch-bediening is een nieuwe manier van gamen die vertrouwd aanvoelt, op het spelniveau dat je gewend bent van een fysieke controller."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Meer dan 100 AI-ervaringen",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "Meer controle, minder aanraken",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "Blijf altijd in beeld, in elke omgeving",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creativiteit",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Licht corrigeren als een professional",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "Voeg eenvoudig indrukwekkende effecten toe",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "Nummers remixen met de kracht van AI",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productiviteit",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Verhoog je productiviteit en privacy",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Langer aan de slag zonder stopcontact",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "Jouw wereld. Op jouw devices.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Entertainment",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Vloeiend streamen, zelfs op accuvoeding",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "Snel, meeslepend gamen",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Meer dan 100 AI-ervaringen",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "Maak je favoriete nummers echt de jouwe",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "Zie je visie in realtime",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creativiteit",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "De snelste weg naar het eindresultaat",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "Maak elke foto perfect",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productiviteit",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Verhoog je productiviteit en privacy",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "Efficiënter. Meer vrijheid.",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "Jouw wereld. Op jouw devices.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Entertainment",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Vloeiend streamen in HD, zelfs op accuvoeding",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "Realistisch gamen, op meer plaatsen",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Creatiever",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Eenvoudig foto's bewerken",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "Creëer inlijstwaardige foto's in seconden",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "Jij typt. AI maakt.",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Meer prestatievermogen",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "De cheatcode voor soepeler gamen",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Langer aan de slag zonder stopcontact",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-meeslepend",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productiever",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Jouw dagelijkse AI-assistent",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Perfecte videogesprekken",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Jouw wereld. Op jouw devices.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Veiliger",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ingebouwde bescherming om je gegevens veiliger en privé te houden",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Creatiever",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Eenvoudig foto's bewerken",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "AI. The shortcut to your final cut.",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "Transcribeer snel je favoriete songteksten",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "Bespaar uren aan bewerking met behulp van AI",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Meer prestatievermogen",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "De cheatcode voor soepeler gamen",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "Langer aan de slag zonder stopcontact",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "Ultra-meeslepend",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productiever",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Jouw dagelijkse AI-assistent",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "Perfecte videogesprekken",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "Jouw wereld. Op jouw devices.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Veiliger",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Ingebouwde bescherming om je gegevens veiliger en privé te houden",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Al je entertainment. Eén&nbsp;pc.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Als je pc ook dienst doet als entertainmentcentrum, heb je voldoende opslagruimte nodig voor al je favoriete nummers, video's en games, en de juiste combinatie van processor en scherm om meeslepende beelden te creëren. Deze pc heeft alle drie de onderdelen.",
                "titleArray": [
                  {
                    "tr_title": "Klaar voor de volgende generatie visuals",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Ontwikkel je digitale bibliotheek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Visie op jouw entertainment",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Moeiteloos gamen en streamen",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Ontwikkel je digitale bibliotheek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Visie op jouw entertainment",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hogere productiviteit. Overal.",
              "tr_tileTitle1": "Krijg meer gedaan. Snel.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Deze pc biedt de perfecte balans tussen kracht en draagbaarheid. De pc heeft een processor die speciaal is ontwikkeld voor dunne en lichte laptops, sterke wifi om overal verbonden te blijven en de juiste accu om zonder stopcontact te blijven werken.",
                "tr_subtitle1": "Deze pc is de perfecte balans tussen kracht en prestaties, zodat je meer gedaan krijgt in minder tijd. De combinatie van een robuuste processor, versneld door voldoende geheugen, en supersnel wifi stroomlijnt je dagelijkse taken.",
                "tr_subtitle2": "Deze pc biedt de perfecte balans tussen kracht en draagbaarheid. De pc heeft een processor die speciaal is ontwikkeld voor dunne en lichte laptops, sterke wifi om overal verbonden te blijven en het juiste geheugen om te multitasken.",
                "titleArray": [
                  {
                    "tr_title": "Gebouwd om meer te doen",
                    "tr_title1": "Intelligente prestaties die ertoe doen",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Best-in-class verbindingen",
                    "tr_title1": "Maak verbinding met wat belangrijk is",
                    "tr_title2": "Een grote verbetering van wifi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Blijf langer zonder stopcontact",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Snel schakelen tussen programma's",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "De vrijheid om te creëren",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Het maken van content is één van de zwaarste taken voor een pc. Gelukkig zijn deze onderdelen krachtig genoeg om je creatieve proces te stroomlijnen.",
                "titleArray": [
                  {
                    "tr_title": "Een slimmere manier om te creëren",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Breng je afbeeldingen tot leven",
                    "tr_title1": "Let’s Create",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Geheugen voor multitasken",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Een kijkje in deze pc",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Dit zijn de drie belangrijkste onderdelen om bij het kiezen van een nieuwe pc in de gaten te houden. Nu je hebt bepaald hoe je je nieuwe pc gaat gebruiken, ben je al aardig op weg om te beslissen hoeveel je van deze drie belangrijke onderdelen nodig hebt. Hier zijn ze:",
                "titleArray": [
                  {
                    "tr_title": "Prestaties voor gamen en streamen",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Opslag",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Geheugen",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Al je entertainment. Eén&nbsp;pc.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Als je pc ook dienst doet als entertainmentcentrum, heb je voldoende opslagruimte nodig voor al je favoriete nummers, video's en games, en de juiste combinatie van processor en scherm om meeslepende beelden te creëren. Deze pc heeft alle drie de onderdelen.",
                "titleArray": [
                  {
                    "tr_title": "Klaar voor de volgende generatie visuals",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Ontwikkel je digitale bibliotheek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Visie op jouw entertainment",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Moeiteloos gamen en streamen",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Ontwikkel je digitale bibliotheek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Visie op jouw entertainment",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Geheugen",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "Scherm",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Razendsnel multitasken",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "RAZENDSNEL MULTITASKEN",
                "tr_subtitle": "Krijg meer gedaan zonder vertraging",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor multitasken:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Een processor die kan schakelen tussen meerdere programma's tegelijk."
                  },
                  {
                    "tr_title": "Snelle, sterke en betrouwbare wifi."
                  },
                  {
                    "tr_title": "Geheugen dat naadloos schakelt tussen toepassingen."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videochat",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCHAT",
                "tr_subtitle": "Kristalheldere verbindingen",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor videochatten:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Een processor die tegelijk kan opnemen en afbeeldingen kan delen."
                  },
                  {
                    "tr_title": "Snelle, sterke en betrouwbare wifi."
                  },
                  {
                    "tr_title": "Een webcam waarmee je kristalhelder in beeld komt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Gereed voor VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR komt tot leven",
                "tr_subtitle": "Wat is er nodig om een volledig virtuele wereld te creëren? Op een pc is een goede coördinatie tussen de processor, de grafische kaart en het geheugen noodzakelijk.",
                "titleArray": [
                  {
                    "tr_title": "Virtuele werelden komen tot leven",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "De realiteit zit hem in de details",
                    "tr_title1": "Dompel je onder",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "Video's bewerken",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEO'S BEWERKEN",
                "tr_subtitle": "Maak magische films",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor videobewerking:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Een processor die grote bestanden aankan."
                  },
                  {
                    "tr_title": "Geheugen dat bewerkingssoftware snel en responsief maakt."
                  },
                  {
                    "tr_title": "Graphics die de rendertijden verminderen."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Razendsnel multitasken",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "RAZENDSNEL MULTITASKEN",
                "tr_subtitle": "Krijg meer gedaan zonder vertraging",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor multitasken:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Een processor die kan schakelen tussen meerdere programma's tegelijk."
                  },
                  {
                    "tr_title": "Snelle, sterke en betrouwbare wifi."
                  },
                  {
                    "tr_title": "Geheugen dat naadloos schakelt tussen toepassingen."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Gereed voor VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR komt tot leven",
                "tr_subtitle": "Wat is er nodig om een volledig virtuele wereld te creëren? Op een pc is een goede coördinatie tussen de processor, de grafische kaart en het geheugen noodzakelijk.",
                "titleArray": [
                  {
                    "tr_title": "Virtuele werelden komen tot leven",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "De realiteit zit hem in de details",
                    "tr_title1": "Dompel je onder",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "3D-modellering",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D-MODELLERING",
                "tr_subtitle": "Creëren op een hoger niveau",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor 3D-modellering:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Een processor die geavanceerde creatieve software aankan."
                  },
                  {
                    "tr_title": "Geheugen dat snel grote bestanden kan laden."
                  },
                  {
                    "tr_title": "Krachtige graphics voor het maken en bewerken van beelden."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Realtime samenwerking",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "REALTIME SAMENWERKING",
                "tr_subtitle": "Werk overal",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor online samenwerking:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Een krachtige processor voor kantoorsoftware."
                  },
                  {
                    "tr_title": "Betrouwbare wifi."
                  },
                  {
                    "tr_title": "Een webcam die in schitterende HD opneemt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Gereed voor VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR komt tot leven",
                "tr_subtitle": "Wat is er nodig om een volledig virtuele wereld te creëren? Op een pc is een goede coördinatie tussen de processor, de grafische kaart en het geheugen noodzakelijk.",
                "titleArray": [
                  {
                    "tr_title": "Virtuele werelden komen tot leven",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "De realiteit zit hem in de details",
                    "tr_title1": "Dompel je onder",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ laptops",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Ongekend krachtige dunne en lichte laptops",
                "tr_subtitle": "De Intel® Evo™ laptops zijn ontwikkeld voor mobiele prestaties en beschikken over de snelheid, graphics en accuduur die je nodig hebt, en dat alles in de meest verbluffende dunne en lichte designs.",
                "titleArray": [
                  {
                    "tr_title": "De rol van laptops is groter dan ooit, dus wij zorgen ervoor dat laptops meer kunnen dan ooit.",
                    "tr_subtitle": "Gebruik je touchscreen om te verkennen, draaien en zoomen."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Razendsnel weer aan",
                    "tr_subtitle": "Met Instant Wake ontwaakt je pc in minder dan een seconde uit de slaapstand."
                  },
                  {
                    "tr_title": "Overweldigende kijkervaring",
                    "tr_subtitle": "Een prachtig scherm brengt alles tot leven in een heldere resolutie en levendige kleuren."
                  },
                  {
                    "tr_title": "Snel je ding doen",
                    "tr_subtitle": "Geniet van de ultraresponsieve prestaties van de 11<sup>e</sup> generatie Intel® Core™ processors."
                  },
                  {
                    "tr_title": "Langer zonder stopcontact",
                    "tr_subtitle": "Blijf lekker bezig met een pc die je accuduur optimaliseert en snel is opgeladen."
                  },
                  {
                    "tr_title": "Universele kabelconnectiviteit",
                    "tr_subtitle": "Dankzij Thunderbolt™ 4 technologie is het aansluiten van andere apparaten op je pc eenvoudig, snel en veilig.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Snelle, betrouwbare verbindingen",
                    "tr_subtitle": "Intel® Wi‑Fi 6 (Gig+) zorgt voor een betrouwbare verbinding voor videochats en het snel delen van bestanden.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ laptops",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alles wat je nodig hebt en meer in een dunne en lichte laptop",
                "tr_subtitle": "Intel® Evo™ laptops zijn ontwikkeld voor mobiele prestaties en ontworpen om je de beste ervaring te bieden<sup>2</sup>, wat je ook doet.",
                "titleArray": [
                  {
                    "tr_title": "De rol van laptops is groter dan ooit, dus wij zorgen ervoor dat laptops meer kunnen dan ooit.",
                    "tr_subtitle": "Gebruik je touchscreen om te verkennen, draaien en zoomen."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Razendsnel weer aan",
                    "tr_subtitle": "Met Instant Wake ontwaakt je pc in minder dan een seconde uit de slaapstand."
                  },
                  {
                    "tr_title": "Overweldigende kijkervaring",
                    "tr_subtitle": "Een prachtig scherm brengt alles tot leven in een heldere resolutie en levendige kleuren."
                  },
                  {
                    "tr_title": "Revolutionaire prestaties",
                    "tr_subtitle": "Krijg een boost op de momenten dat het nodig is met intelligente prestaties van 12<sup>e</sup> generatie Intel® Core™ processors."
                  },
                  {
                    "tr_title": "Langer zonder stopcontact",
                    "tr_subtitle": "Blijf lekker bezig met een pc die je accuduur optimaliseert en snel is opgeladen."
                  },
                  {
                    "tr_title": "Universele kabelconnectiviteit",
                    "tr_subtitle": "Dankzij Thunderbolt™ 4 technologie is het aansluiten van andere apparaten op je pc eenvoudig, snel en veilig.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Snelle, betrouwbare verbindingen",
                    "tr_subtitle": "Videochatten of bestanden delen met een verbinding waar je op kunt vertrouwen met Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ laptops",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Ongekend krachtige dunne en lichte laptops",
                "tr_subtitle": "De Intel® Evo™ laptops zijn ontwikkeld voor mobiele prestaties en beschikken over de snelheid, graphics en accuduur die je nodig hebt, en dat alles in de meest verbluffende dunne en lichte designs.",
                "titleArray": [
                  {
                    "tr_title": "De rol van laptops is groter dan ooit, dus wij zorgen ervoor dat laptops meer kunnen dan ooit.",
                    "tr_subtitle": "Gebruik je touchscreen om te verkennen, draaien en zoomen."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Razendsnel weer aan",
                    "tr_subtitle": "Met Instant Wake ontwaakt je pc in minder dan een seconde uit de slaapstand."
                  },
                  {
                    "tr_title": "Overweldigende kijkervaring",
                    "tr_subtitle": "Een prachtig scherm brengt alles tot leven in een heldere resolutie en levendige kleuren."
                  },
                  {
                    "tr_title": "Snel je ding doen",
                    "tr_subtitle": "Geniet van de ultraresponsieve prestaties van de 11<sup>e</sup> generatie Intel® Core™ processors."
                  },
                  {
                    "tr_title": "Langer zonder stopcontact",
                    "tr_subtitle": "Blijf lekker bezig met een pc die je accuduur optimaliseert en snel is opgeladen."
                  },
                  {
                    "tr_title": "Universele kabelconnectiviteit",
                    "tr_subtitle": "Dankzij Thunderbolt™ 4 technologie is het aansluiten van andere apparaten op je pc eenvoudig, snel en veilig.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Snelle, betrouwbare verbindingen",
                    "tr_subtitle": "Intel® Wi‑Fi 6 (Gig+) zorgt voor een betrouwbare verbinding voor videochats en het snel delen van bestanden.<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ laptops",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alles wat je nodig hebt en meer in een dunne en lichte laptop",
                "tr_subtitle": "Intel® Evo™ laptops zijn ontwikkeld voor mobiele prestaties en ontworpen om je de beste ervaring te bieden<sup>2</sup>, wat je ook doet.",
                "titleArray": [
                  {
                    "tr_title": "De rol van laptops is groter dan ooit, dus wij zorgen ervoor dat laptops meer kunnen dan ooit.",
                    "tr_subtitle": "Gebruik je touchscreen om te verkennen, draaien en zoomen."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Razendsnel weer aan",
                    "tr_subtitle": "Met Instant Wake ontwaakt je pc in minder dan een seconde uit de slaapstand."
                  },
                  {
                    "tr_title": "Overweldigende kijkervaring",
                    "tr_subtitle": "Een prachtig scherm brengt alles tot leven in een heldere resolutie en levendige kleuren."
                  },
                  {
                    "tr_title": "Revolutionaire prestaties",
                    "tr_subtitle": "Krijg een boost op de momenten dat het nodig is met intelligente prestaties van 12<sup>e</sup> generatie Intel® Core™ processors."
                  },
                  {
                    "tr_title": "Langer zonder stopcontact",
                    "tr_subtitle": "Blijf lekker bezig met een pc die je accuduur optimaliseert en snel is opgeladen."
                  },
                  {
                    "tr_title": "Universele kabelconnectiviteit",
                    "tr_subtitle": "Dankzij Thunderbolt™ 4 technologie is het aansluiten van andere apparaten op je pc eenvoudig, snel en veilig.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Snelle, betrouwbare verbindingen",
                    "tr_subtitle": "Videochatten of bestanden delen met een verbinding waar je op kunt vertrouwen met Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "Intel® Evo™ laptops",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alles wat je nodig hebt en meer in een dunne en lichte laptop",
                "tr_subtitle": "Intel® Evo™ laptops zijn ontwikkeld voor mobiele prestaties en ontworpen om je de beste ervaring te bieden<sup>2</sup>, wat je ook doet.",
                "titleArray": [
                  {
                    "tr_title": "De rol van laptops is groter dan ooit, dus wij zorgen ervoor dat laptops meer kunnen dan ooit.",
                    "tr_subtitle": "Gebruik je touchscreen om te verkennen, draaien en zoomen."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Razendsnel weer aan",
                    "tr_subtitle": "Met Instant Wake ontwaakt je pc in minder dan een seconde uit de slaapstand."
                  },
                  {
                    "tr_title": "Overweldigende kijkervaring",
                    "tr_subtitle": "Een prachtig scherm brengt alles tot leven in een heldere resolutie en levendige kleuren."
                  },
                  {
                    "tr_title": "Revolutionaire prestaties",
                    "tr_subtitle": "Krijg een boost op de momenten dat het nodig is met intelligente prestaties van 13<sup>e</sup> generatie Intel® Core™ processors."
                  },
                  {
                    "tr_title": "Langer zonder stopcontact",
                    "tr_subtitle": "Blijf lekker bezig met een pc die je accuduur optimaliseert en snel is opgeladen."
                  },
                  {
                    "tr_title": "Universele kabelconnectiviteit",
                    "tr_subtitle": "Dankzij Thunderbolt™ 4 technologie is het aansluiten van andere apparaten op je pc eenvoudig, snel en veilig.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Snelle, betrouwbare verbindingen",
                    "tr_subtitle": "Videochatten of bestanden delen met een verbinding waar je op kunt vertrouwen met Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Intel® Evo™ laptops",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "Alles wat je nodig hebt en meer in een dunne en lichte laptop",
                "tr_subtitle": "Intel® Evo™ laptops zijn ontwikkeld voor mobiele prestaties en ontworpen om je de beste ervaring te bieden<sup>2</sup>, wat je ook doet.",
                "titleArray": [
                  {
                    "tr_title": "De rol van laptops is groter dan ooit, dus wij zorgen ervoor dat laptops meer kunnen dan ooit.",
                    "tr_subtitle": "Gebruik je touchscreen om te verkennen, draaien en zoomen."
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Razendsnel weer aan",
                    "tr_subtitle": "Met Instant Wake ontwaakt je pc in minder dan een seconde uit de slaapstand."
                  },
                  {
                    "tr_title": "Overweldigende kijkervaring",
                    "tr_subtitle": "Een prachtig scherm brengt alles tot leven in een heldere resolutie en levendige kleuren."
                  },
                  {
                    "tr_title": "Revolutionaire prestaties",
                    "tr_subtitle": "Krijg een boost op de momenten dat het nodig is met intelligente prestaties van 13<sup>e</sup> generatie Intel® Core™ processors."
                  },
                  {
                    "tr_title": "Langer zonder stopcontact",
                    "tr_subtitle": "Blijf lekker bezig met een pc die je accuduur optimaliseert en snel is opgeladen."
                  },
                  {
                    "tr_title": "Universele kabelconnectiviteit",
                    "tr_subtitle": "Dankzij Thunderbolt™ 4 technologie is het aansluiten van andere apparaten op je pc eenvoudig, snel en veilig.<sup>10</sup>"
                  },
                  {
                    "tr_title": "Snelle, betrouwbare verbindingen",
                    "tr_subtitle": "Videochatten of bestanden delen met een verbinding waar je op kunt vertrouwen met Intel® Wi-Fi 6E (Gig+).<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Wat is Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Waarom Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creativiteit",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Wees nog creatiever op nog meer plaatsen",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "Fotobewerking op volle snelheid",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "Creëer originele kunst waar je ook bent",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productiviteit",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximaliseer de productiviteit met Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Detecteer deepfakes voordat je ze deelt met AI",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Jouw wereld. Op jouw devices.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobiliteit",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Zwaai gedag naar gebarenherkenning",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "Accuduur die je niet zal tegenhouden",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Houdt je automatisch verbonden",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "Spotlight",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Wat is Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "Waarom Intel® Evo™ Edition?",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Creativiteit",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Snel en vloeiend video's afspelen",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "Produceer hits met de kracht van AI",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "De snelste weg naar je eindproduct",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Productiviteit",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Maximaliseer de productiviteit met Rewind AI",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "Detecteer deepfakes voordat je ze deelt met AI",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "Jouw wereld. Op jouw devices.",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Mobiliteit",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "Samenwerken vanaf vrijwel elke locatie",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "Accuduur die je niet zal tegenhouden",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "Houdt je automatisch verbonden",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Al je entertainment. Eén&nbsp;pc.",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Als je pc ook dienst doet als entertainmentcentrum, heb je voldoende opslagruimte nodig voor al je favoriete nummers, video's en games, en de juiste combinatie van processor en scherm om meeslepende beelden te creëren. Deze pc heeft alle drie de onderdelen.",
                "titleArray": [
                  {
                    "tr_title": "Indrukwekkend gamen en streamen",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Ontwikkel je digitale bibliotheek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Visie op jouw entertainment",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "Moeiteloos gamen en streamen",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Ontwikkel je digitale bibliotheek",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Visie op jouw entertainment",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hogere productiviteit. Overal.",
              "tr_tileTitle1": "Krijg meer gedaan. Snel.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Deze pc biedt de perfecte balans tussen kracht en draagbaarheid. De pc heeft een processor die speciaal is ontwikkeld voor dunne en lichte laptops, sterke wifi om overal verbonden te blijven en de juiste accu om zonder stopcontact te blijven werken.",
                "tr_subtitle1": "Deze pc is de perfecte balans tussen kracht en prestaties, zodat je meer gedaan krijgt in minder tijd. De combinatie van een robuuste processor, versneld door voldoende geheugen, en supersnel wifi stroomlijnt je dagelijkse taken.",
                "tr_subtitle2": "Deze pc biedt de perfecte balans tussen kracht en draagbaarheid. De pc heeft een processor die speciaal is ontwikkeld voor dunne en lichte laptops, sterke wifi om overal verbonden te blijven en het juiste geheugen om te multitasken.",
                "titleArray": [
                  {
                    "tr_title": "Intelligente prestaties die ertoe doen",
                    "tr_title1": "Alles gaat sneller met intelligente prestaties",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Best-in-class verbindingen",
                    "tr_title1": "Maak verbinding met wat belangrijk is",
                    "tr_title2": "Een grote verbetering van wifi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Blijf langer zonder stopcontact",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Snel schakelen tussen programma's",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "De vrijheid om te creëren",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Het maken van content is één van de zwaarste taken voor een pc. Gelukkig zijn deze onderdelen krachtig genoeg om je creatieve proces te stroomlijnen.",
                "titleArray": [
                  {
                    "tr_title": "Versnelde creativiteit",
                    "tr_title1": "Creatieve projecten verlopen soepel",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Creëren op hoog niveau",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Geheugen voor multitasken",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Een kijkje in deze pc",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Dit zijn de drie belangrijkste onderdelen om bij het kiezen van een nieuwe pc in de gaten te houden. Nu je hebt bepaald hoe je je nieuwe pc gaat gebruiken, ben je al aardig op weg om te beslissen hoeveel je van deze drie belangrijke onderdelen nodig hebt. Hier zijn ze:",
                "titleArray": [
                  {
                    "tr_title": "Processor",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Opslag",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "Geheugen",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "De vrijheid om te creëren",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Het maken van content is één van de zwaarste taken voor een pc. Gelukkig zijn deze onderdelen krachtig genoeg om je creatieve proces te stroomlijnen.",
                "titleArray": [
                  {
                    "tr_title": "Een slimmere manier om te creëren",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Breng je afbeeldingen tot leven",
                    "tr_title1": "Let’s Create",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "Geheugen voor multitasken",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Hogere productiviteit. Overal.",
              "tr_tileTitle1": "Krijg meer gedaan. Snel.",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "Deze pc biedt de perfecte balans tussen kracht en draagbaarheid. De pc heeft een processor die speciaal is ontwikkeld voor dunne en lichte laptops, sterke wifi om overal verbonden te blijven en de juiste accu om zonder stopcontact te blijven werken.",
                "tr_subtitle1": "Deze pc is de perfecte balans tussen kracht en prestaties, zodat je meer gedaan krijgt in minder tijd. De combinatie van een robuuste processor, versneld door voldoende geheugen, en supersnel wifi stroomlijnt je dagelijkse taken.",
                "tr_subtitle2": "Deze pc biedt de perfecte balans tussen kracht en draagbaarheid. De pc heeft een processor die speciaal is ontwikkeld voor dunne en lichte laptops, sterke wifi om overal verbonden te blijven en het juiste geheugen om te multitasken.",
                "titleArray": [
                  {
                    "tr_title": "Gebouwd om meer te doen",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "Best-in-class verbindingen",
                    "tr_title1": "Maak verbinding met wat belangrijk is",
                    "tr_title2": "Een grote verbetering van wifi",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "Blijf langer zonder stopcontact",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "Snel schakelen tussen programma's",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Gereed voor VR",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "VR komt tot leven",
                "tr_subtitle": "Wat is er nodig om een volledig virtuele wereld te creëren? Op een pc is een goede coördinatie tussen de processor, de grafische kaart en het geheugen noodzakelijk.",
                "titleArray": [
                  {
                    "tr_title": "Virtuele werelden komen tot leven",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "De realiteit zit hem in de details",
                    "tr_title1": "Dompel je onder",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED:<br/>een streling voor vermoeide ogen",
      "tr_sub_header": "Blauw licht kan zorgen voor vermoeide ogen en je slaapcyclus verstoren. De geteste SGS Eye Care OLED-schermen van Samsung helpen door de golflengte van mogelijk schadelijk blauw licht te verminderen in plaats van de kleuren te veranderen. Dit leidt tot meer kijkcomfort en minder vermoeide ogen.",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "Vermindert schadelijk blauw licht"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "Ultra op elke manier",
      "tr_sub_header": "OLED's ultralichte en ultradunne ontwerp optimaliseert de beeldkwaliteit en beperkt het gewicht tot een minimum."
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "Puur zwart",
      "tr_sub_header": "0,0005 nit"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74%<br/><span class='conventional_text'>Conventioneel</span>",
      "tr_sub_header": "120%<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Streamen",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "STREAMEN",
                "tr_subtitle": "Eindeloos entertainment",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor streamen:",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "Een processor die streamt in HD."
                  },
                  {
                    "tr_title": "Een speciaal scherm voor heldere, mooie beelden."
                  },
                  {
                    "tr_title": "Wifi met kortere buffer- en laadtijden."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Internetten",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "INTERNETTEN",
                "tr_subtitle": "Eenvoudig browsen op het web",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is om te browsen op het web:",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "Een processor die snel mediarijke pagina's kan laden."
                  },
                  {
                    "tr_title": "Snelle en betrouwbare wifi."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videochat",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCHAT",
                "tr_subtitle": "Kristalheldere verbindingen",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor videochatten:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Een processor die tegelijk kan opnemen en afbeeldingen kan delen."
                  },
                  {
                    "tr_title": "Snelle, sterke en betrouwbare wifi."
                  },
                  {
                    "tr_title": "Een webcam waarmee je kristalhelder in beeld komt."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Razendsnel multitasken",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "RAZENDSNEL MULTITASKEN",
                "tr_subtitle": "Krijg meer gedaan zonder vertraging",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor multitasken:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Een processor die kan schakelen tussen meerdere programma's tegelijk."
                  },
                  {
                    "tr_title": "Snelle, sterke en betrouwbare wifi."
                  },
                  {
                    "tr_title": "Geheugen dat naadloos schakelt tussen toepassingen."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Videochat",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEOCHAT",
                "tr_subtitle": "Kristalheldere verbindingen",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor videochatten:",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "Een processor die tegelijk kan opnemen en afbeeldingen kan delen."
                  },
                  {
                    "tr_title": "Snelle, sterke en betrouwbare wifi."
                  },
                  {
                    "tr_title": "Een webcam waarmee je kristalhelder in beeld komt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Casual gamen",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "CASUAL GAMEN",
                "tr_subtitle": "Tijd om te gamen",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor light gaming:",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "Een processor die vloeiende gameplay levert."
                  },
                  {
                    "tr_title": "Voldoende geheugen om tegelijk te streamen en te communiceren via spraakchat."
                  },
                  {
                    "tr_title": "Graphics met geweldige beelden."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Foto's bewerken",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "FOTO'S BEWERKEN",
                "tr_subtitle": "Perfecte foto's tot op de pixel",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor fotobewerking:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Een processor die een grote collectie afbeeldingen kan bewerken."
                  },
                  {
                    "tr_title": "Geheugen dat je bewerkingssoftware versnelt."
                  },
                  {
                    "tr_title": "Met Thunderbolt™ 4 technologie kun je bestanden snel overdragen."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "Razendsnel multitasken",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "RAZENDSNEL MULTITASKEN",
                "tr_subtitle": "Krijg meer gedaan zonder vertraging",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor multitasken:",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "Een processor die kan schakelen tussen meerdere programma's tegelijk."
                  },
                  {
                    "tr_title": "Snelle, sterke en betrouwbare wifi."
                  },
                  {
                    "tr_title": "Geheugen dat naadloos schakelt tussen toepassingen."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Realtime samenwerking",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "REALTIME SAMENWERKING",
                "tr_subtitle": "Werk overal",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor online samenwerking:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Een krachtige processor voor kantoorsoftware."
                  },
                  {
                    "tr_title": "Betrouwbare wifi."
                  },
                  {
                    "tr_title": "Een webcam die in schitterende HD opneemt."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Video's bewerken",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEO'S BEWERKEN",
                "tr_subtitle": "Maak magische films",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor videobewerking:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Een processor die grote bestanden aankan."
                  },
                  {
                    "tr_title": "Geheugen dat bewerkingssoftware snel en responsief maakt."
                  },
                  {
                    "tr_title": "Graphics die de rendertijden verminderen."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Foto's bewerken",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "FOTO'S BEWERKEN",
                "tr_subtitle": "Perfecte foto's tot op de pixel",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor fotobewerking:",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "Een processor die een grote collectie afbeeldingen kan bewerken."
                  },
                  {
                    "tr_title": "Geheugen dat je bewerkingssoftware versnelt."
                  },
                  {
                    "tr_title": "Met Thunderbolt™ 4 technologie kun je bestanden snel overdragen."
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "Video's bewerken",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "VIDEO'S BEWERKEN",
                "tr_subtitle": "Maak magische films",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor videobewerking:",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "Een processor die grote bestanden aankan."
                  },
                  {
                    "tr_title": "Geheugen dat bewerkingssoftware snel en responsief maakt."
                  },
                  {
                    "tr_title": "Graphics die de rendertijden verminderen."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "3D-modellering",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D-MODELLERING",
                "tr_subtitle": "Creëren op een hoger niveau",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor 3D-modellering:",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "Een processor die geavanceerde creatieve software aankan."
                  },
                  {
                    "tr_title": "Geheugen dat snel grote bestanden kan laden."
                  },
                  {
                    "tr_title": "Krachtige graphics voor het maken en bewerken van beelden."
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "Realtime samenwerking",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "REALTIME SAMENWERKING",
                "tr_subtitle": "Werk overal",
                "tr_subtitle1": "Dit is waarom deze pc geweldig is voor online samenwerking:",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "Een krachtige processor voor kantoorsoftware."
                  },
                  {
                    "tr_title": "Betrouwbare wifi."
                  },
                  {
                    "tr_title": "Een webcam die in schitterende HD opneemt."
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "Intel® Unison™ app",
    "tr_title": "Verbind je wereld",
    "tr_subtitle": "Zet naadloos bestanden en foto's over, terwijl je notificaties, oproepen en sms-berichten vanaf je laptop beheert.<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "BESTANDEN EN FOTO'S OVERZETTEN",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "BELLEN EN GEBELD WORDEN",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "BERICHTEN STUREN EN ONTVANGEN",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "TELEFOONMELDINGEN BEHEREN",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "VERBIND JE WERELD",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}