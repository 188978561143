const Library_ChromeBook_mobile_esMX = {
  "uiText": {
    "tr_endlessProgramsAndApps": "Enorme cantidad de programas y aplicaciones",
    "tr_rethinkStorage": "Reimagina el almacenamiento",
    "tr_alwaysOnSecurity": "Seguridad integrada",
    "tr_extendedBatteryLife": "Duración extendida de la batería<sup>1</sup>",
    "tr_viewMore": "Ver más",
    "tr_processorTitle": "PROCESADOR",
    "tr_processorTitleCamel": "Procesador",
    "tr_rethinkStorageTitle": "REIMAGINA EL ALMACENAMIENTO",
    "tr_alwaysOnSecurityTitle": "SEGURIDAD INTEGRADA",
    "tr_extendedBatteryLifeTitle": "DURACIÓN EXTENDIDA DE LA BATERÍA<sup>1</sup>",
    "tr_endlessProgramsAndAppsTitle": "ENORME CANTIDAD DE PROGRAMAS Y APLICACIONES",
    "tr_extendedBatteryLifeDescription": "[XX] h",
    "tr_rethinkStorageDescriptionGB": "[XX] GB",
    "tr_rethinkStorageDescriptionTB": "[XX] TB",
    "tr_OS": "SO",
    "tr_Chrome_OS": "ChromeOS*",
    "tr_textGBGB": "SSD de [convertedSSD] GB + HDD&nbsp;de&nbsp;[convertedHDD] GB",
    "tr_textGBTB": "SSD de [convertedSSD] GB + HDD&nbsp;de&nbsp;[convertedHDD] TB",
    "tr_textTBGB": "SSD de [convertedSSD] TB + HDD&nbsp;de&nbsp;[convertedHDD] GB",
    "tr_textTBTB": "SSD de [convertedSSD] TB + HDD&nbsp;de&nbsp;[convertedHDD] TB",
    "tr_textGBSSD": "SSD de [convertedSSD] GB",
    "tr_textTBSSD": "SSD de [convertedSSD] TB",
    "tr_textGBHDD": "HHD de [convertedHDD] GB",
    "tr_textTBHDD": "HHD de [convertedHDD] TB",
    "tr_graphicTitle": "GRÁFICOS",
    "tr_storageTitle": "ALMACENA&shy;MIENTO",
    "tr_displayTitle": "PANTALLA",
    "tr_batteryTitle": "BATERÍA",
    "tr_memoryTitle": "MEMORIA",
    "tr_priceTitle": "PRECIO"
  },
  "rethinkStorage": {
    "details": {
      "tr_tileTitle": "Reimagina el almacenamiento",
      "tr_title": "Un innovador enfoque respecto del almacenamiento",
      "tr_subtitle": "Chromebook* combina el almacenamiento en la PC con el recurso casi ilimitado de Google* Drive en la nube. Estos son los beneficios:",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/cloud_upload.svg",
          "tr_badgeSubTitle": "Todos tus archivos se respaldan automáticamente en la nube y recuperarlos es tan sencillo como iniciar sesión en tu cuenta de Google*.",
          "badgeSubTitle": "cloud-upload"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Trabaja con o sin conexión con [XX]&nbsp;GB de almacenamiento en el dispositivo.",
          "badgeSubTitle": "GBALONE"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Trabaja con o sin conexión con [XX]&nbsp;TB de almacenamiento en el dispositivo.",
          "badgeSubTitle": "TBALONE"
        }
      ]
    }
  },
  "alwaysOnSecurity": {
    "details": {
      "tr_tileTitle": "Seguridad integrada",
      "tr_title": "Protección sin&nbsp;esfuerzo<sup class='sup-text'>2</sup>",
      "tr_subtitle": "Chromebook* actualiza automáticamente todas sus características de seguridad para que puedas navegar por Internet y hacer clic en enlaces con la confianza de que tu dispositivo estará mejor protegido."
    }
  },
  "extendedBatteryLife": {
    "tr_tileTitle": "Duración extendida de la batería<sup>1</sup>",
    "iposTypes": [
      {
        "iposType": "CHROMEBOOK",
        "details": {
          "tr_title": "Haz más, con&nbsp;menos&nbsp;cargas<sup class='sup-text'>1</sup>",
          "tr_subtitle": "El diseño simplificado y con eficiencia energética de una Chromebook* significa que puedes usarla durante horas sin tener que enchufarla."
        }
      },
      {
        "iposType": "CHROMEBOOKEVO",
        "details": {
          "tr_title": "Menos cargas. <br/>Y cargas más rápidas.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "El diseño optimizado y de bajo consumo de las Chromebook* cumple con los exigentes requisitos de batería de la plataforma Intel®&nbsp;Evo™ para permitirte cargar tu dispositivo más rápido y pasar más tiempo sin necesidad de enchufarlo."
        }
      },
      {
        "iposType": "CHROMEBOOKEVOGEN12",
        "details": {
          "tr_title": "La autonomía de la batería te mantiene en movimiento.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Las baterías de carga rápida y larga duración en Chromebooks* delgadas y livianas significan que no tienes que preocuparte por dónde está la toma de corriente más cercana."
        }
      }
    ]
  },
  "endlessProgramsAndApps": {
    "details": {
      "tr_tileTitle": "Enorme cantidad de programas y aplicaciones",
      "tr_title": "Más aplicaciones todos los días",
      "tr_subtitle": "Los usuarios de Chromebook* cuentan con una enorme selección de aplicaciones y programas para elegir, y se agregan más todo el tiempo.",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/download_badge.svg",
          "tr_badgeSubTitle": "Descarga aplicaciones de Chrome* y Android*."
        },
        {
          "badgeUrl": "assets/images/badges/reset_badge.svg",
          "tr_badgeSubTitle": "Todas tus aplicaciones y tus datos se sincronizan a través de tu cuenta de Google*."
        },
        {
          "badgeUrl": "assets/images/badges/connect_badge.svg",
          "tr_badgeSubTitle": "Conéctate, aprende, crea y juega sin límites."
        }
      ]
    }
  },
  "processor": [
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Amplía tus expectativas",
                "tr_subtitle": "Mira tus películas y programas favoritos en tu Chromebook* con un procesador Intel® Core™ i3 de 10<sup>a</sup> Generación.",
                "tr_gen_core_title": "Procesador Intel® Core™ i3 de 10<sup>a</sup> Generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Diseñado específicamente para ofrecer un desempeño excepcional en cualquier lugar",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i5 de 10<sup>a</sup> Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Equipado con un procesador Intel® Core™ i5 de 10<sup>a</sup> Generación"
                  },
                  {
                    "tr_badgeTitle": "Potente, aún desenchufada"
                  },
                  {
                    "tr_badgeTitle": "Carga rápida: cuatro horas de batería en 30&nbsp;minutos<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Excelente conectividad gracias a Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Trabajo real. Juego real.",
                "tr_subtitle": "Realiza una videollamada, envía un correo electrónico y crea una presentación al mismo tiempo que mejoras tu productividad en una Chromebook* con un procesador Intel® Core™ i5 de 10<sup>a</sup> Generación.",
                "tr_gen_core_title": "Procesador Intel® Core™ i5 de 10<sup>a</sup> Generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Diseñado específicamente para ofrecer un desempeño excepcional en cualquier lugar",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i7 de 10<sup>a</sup> Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Equipado con un procesador Intel® Core™ i7 de 10<sup>a</sup> Generación"
                  },
                  {
                    "tr_badgeTitle": "Potente, aún desenchufada"
                  },
                  {
                    "tr_badgeTitle": "Carga rápida: cuatro horas de batería en 30&nbsp;minutos<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "Excelente conectividad gracias a Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Porque el desempeño importa",
                "tr_subtitle": "Edita tus videos con facilidad, y carga y comparte recuerdos al instante en una Chromebook* con un procesador Intel® Core™ i7 de 10<sup>a</sup> Generación.",
                "tr_gen_core_title": "Procesador Intel® Core™ i7 de 10<sup>a</sup> Generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Desempeño rápido y con capacidad de respuesta",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i3 de 8<sup>a</sup> Generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Potencia tu pasión y tu&nbsp;productividad",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i5 de 8<sup>a</sup> Generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Potencia tu pasión y tu&nbsp;productividad",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;5"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Potencia tu pasión y tu&nbsp;productividad",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;7"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Potencia tu pasión y tu&nbsp;productividad",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;9"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Desempeño rápido y con capacidad de respuesta",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ m3 de 8<sup>a</sup> Generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Alto desempeño para lo que haces&nbsp;hoy",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i5 de 7<sup>a</sup> Generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Desempeño de primer nivel para lo que haces hoy",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i7 de 7<sup>a</sup> Generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Desempeño para lo que haces hoy",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ m3 de 7<sup>a</sup> Generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Desempeño elevado para lo que&nbsp;necesites",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i5 de 6<sup>a</sup> Generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Desempeño avanzado para lo que&nbsp;necesites",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i7 de 6<sup>a</sup> Generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Desempeño para lo que&nbsp;necesites",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ m3 de 6<sup>a</sup> Generación"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Rompiendo los límites del desempeño en las Chromebooks*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i3 de 11<sup>a</sup> Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Desempeño hasta <br/><span class='big-blue-text'>2,4 veces  </span><span class='subt-text'>mejor<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Navegación web hasta <br/><span class='big-blue-text'>3,2 veces </span> <span class='subt-text'>más rápida<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Edición de video hasta <br/><span class='big-blue-text'>2,1 veces  </span><span class='subt-text'>más rápida<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Imagina lo que puedes lograr con una Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i5 de&nbsp;11<sup>a</sup>&nbsp;Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Desempeño hasta <br/><span class='big-blue-text'>2,7 veces  </span><span class='subt-text'>mejor<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Navegación web hasta <br/><span class='big-blue-text'>3,3 veces </span> <span class='subt-text'>más rápida<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Edición de video hasta <br/><span class='big-blue-text'>2,8 veces  </span><span class='subt-text'>más rápida<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Diseñado para la máxima experiencia móvil",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i5 de&nbsp;11<sup>a</sup>&nbsp;Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Desempeño revolucionario con los procesadores Intel® Core™ de 11<sup>a</sup> Generación"
                  },
                  {
                    "tr_badgeTitle": "Se enciende en menos de un segundo<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet casi tres veces más rápido con Intel® Wi-Fi 6 (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batería de carga rápida y larga duración"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "El mejor procesador del mundo para Chromebooks*<sup class='sup-text'>1</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i7 de&nbsp;11<sup>a</sup>&nbsp;Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Desempeño hasta <br/><span class='big-blue-text'>2,9 veces </span><span class='subt-text'> mejor<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Navegación web hasta <br/><span class='big-blue-text'>3,5 veces </span> <span class='subt-text'>más rápida<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Edición de video hasta <br/><span class='big-blue-text'>2,9 veces  </span><span class='subt-text'>más rápida<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Diseñado para la máxima experiencia móvil",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i7 de&nbsp;11<sup>a</sup>&nbsp;Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Desempeño revolucionario con los procesadores Intel® Core™ de 11<sup>a</sup> Generación"
                  },
                  {
                    "tr_badgeTitle": "Se enciende en menos de un segundo<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet casi tres veces más rápido con Intel® Wi-Fi 6 (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batería de carga rápida y larga duración"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Desempeño cotidiano&nbsp;rápido",
                "tr_subtitle": "Obtén un excelente desempeño para todas tus aplicaciones y programas favoritos con la potencia de un procesador Intel®&nbsp;Pentium®.",
                "tr_gen_core_title": "Procesador Intel® Pentium® Gold"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Desempeño cotidiano&nbsp;rápido",
                "tr_subtitle": "Obtén un excelente desempeño para todas tus aplicaciones y programas favoritos con la potencia de un procesador Intel®&nbsp;Pentium®.",
                "tr_gen_core_title": "Procesador Intel® Pentium® Silver"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Aumenta tu productividad&nbsp;diaria",
                "tr_subtitle": "Desde iniciar aplicaciones hasta abrir proyectos, un procesador Intel® Celeron® te ayuda a lograr más.",
                "tr_gen_core_title": "Procesador Intel® Celeron®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Desempeño y valor.",
                "tr_subtitle": "Su diseño permite disfrutar de un gran entretenimiento, streaming de videos y productividad. Disfruta películas y videos en la Web con detalles sorprendentes. Pasa más tiempo realizando tareas y menos tiempo esperando.",
                "tr_gen_core_title": "Procesador Intel® Pentium®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Desempeño cotidiano&nbsp;rápido",
                "tr_subtitle": "Obtén un excelente desempeño para todas tus aplicaciones y programas favoritos con la potencia de un procesador Intel®&nbsp;Pentium®.",
                "tr_gen_core_title": "Procesador Intel® Pentium®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Desempeño y valor.",
                "tr_subtitle": "Productividad mejorada: Ofrece un potente desempeño para tus días más exigentes. Navegación web optimizada: Carga rápidamente páginas web ricas en medios para explorar más en en menos tiempo.",
                "tr_gen_core_title": "Procesador Intel® Pentium® Gold"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Desempeño y valor.",
                "tr_subtitle": "Mantén el control de tu mundo digital con el desempeño necesario para acceder a archivos desde la web. Muévete entre programas con facilidad para realizar múltiples tareas sin problemas y hacer más en menos tiempo.",
                "tr_gen_core_title": "Procesador Intel® Pentium® Silver"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Desempeño y valor.",
                "tr_subtitle": "Desempeño para mejorar tu trabajo y entretenimiento. Disfruta películas y videos en la Web con detalles sorprendentes. Pasa más tiempo realizando tareas y menos tiempo esperando.",
                "tr_gen_core_title": "Procesador Intel® Celeron®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Desempeño confiable cuando más se necesita",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3 de 12<sup>a</sup>&nbsp;Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "La facilidad<br/>de multitareas"
                  },
                  {
                    "tr_badgeTitle": "Desenfoque de<br/>fondo incorporado"
                  },
                  {
                    "tr_badgeTitle": "Navegación<br/>web con capacidad de respuesta"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Un impulso para el desempeño donde más lo necesitas",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de 12<sup>a</sup>&nbsp;Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitarea<br/>potente"
                  },
                  {
                    "tr_badgeTitle": "Supresión<br/>automática de ruidos"
                  },
                  {
                    "tr_badgeTitle": "Streaming<br/>en HD"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "El desempeño móvil ha evolucionado.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5 de 12<sup>a</sup>&nbsp;Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Desempeño superior donde más lo necesitas con la tecnología de los procesadores Intel® Core™ de 12<sup>a</sup> Generación<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Se enciende en menos de un segundo<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet casi tres veces más rápido con Intel® Wi‑Fi&nbsp;6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batería de carga rápida y larga duración"
                  },
                  {
                    "tr_badgeTitle": "Una mejor colaboración y conectividad"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Desempeño superior donde más lo necesitas<sup>11</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de 12<sup>a</sup>&nbsp;Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitareas<br/>extremas"
                  },
                  {
                    "tr_badgeTitle": "Supresión de ruido<br/>y desenfoque de<br/>fondo integrados"
                  },
                  {
                    "tr_badgeTitle": "Streaming fluido<br/>en 8K"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "El desempeño móvil ha evolucionado.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7 de 12<sup>a</sup>&nbsp;Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Desempeño superior donde más lo necesitas con la tecnología de los procesadores Intel® Core™ de 12<sup>a</sup> Generación<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Se enciende en menos de un segundo<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet casi tres veces más rápido con Intel® Wi‑Fi&nbsp;6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batería de carga rápida y larga duración"
                  },
                  {
                    "tr_badgeTitle": "Una mejor colaboración y conectividad"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolucionadas.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i5 de 13<sup>a</sup> Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño delgado y liviano que va más allá de un desempeño superior<br/><span class='marklist'>y</span> se conecta a la perfección con tu teléfono actual <sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápido con una batería duradera."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que amas con un desempeño excepcional.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i5 de 13<sup>a</sup> Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitareas<br/>mejoradas"
                  },
                  {
                    "tr_badgeTitle": "Streaming fluido<br/>en 4K"
                  },
                  {
                    "tr_badgeTitle": "Creación <br/>potente"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolucionadas.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i7 de 13<sup>a</sup> Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño delgado y liviano que va más allá de un desempeño superior<br/><span class='marklist'>y</span> se conecta a la perfección con tu teléfono actual <sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápido con una batería duradera."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que amas con un desempeño extraordinario.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i7 de 13<sup>a</sup> Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitareas<br/>extremas"
                  },
                  {
                    "tr_badgeTitle": "Streaming fluido<br/>en 8K"
                  },
                  {
                    "tr_badgeTitle": "Creación<br/>avanzada"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Procesador Intel®",
                "tr_subtitle": "El desempeño que necesitas. La asequibilidad que deseas.",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que amas con un desempeño increíble",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel® Core™ i3 de 13<sup>a</sup> Generación",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitareas<br/>fáciles"
                  },
                  {
                    "tr_badgeTitle": "Streaming<br/>fluido"
                  },
                  {
                    "tr_badgeTitle": "Creación<br/>rápida"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolucionadas.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño delgado y liviano que va más allá de un desempeño superior<br/><span class='marklist'>y</span> se conecta a la perfección con tu teléfono actual <sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápido con una batería duradera."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que amas con un desempeño increíble",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitareas<br/>fáciles"
                  },
                  {
                    "tr_badgeTitle": "Streaming<br/>fluido"
                  },
                  {
                    "tr_badgeTitle": "Creación<br/>rápida"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolucionadas.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño delgado y liviano que va más allá de un desempeño superior<br/><span class='marklist'>y</span> se conecta a la perfección con tu teléfono actual <sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápido con una batería duradera."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que amas con un desempeño excepcional.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitareas<br/>mejoradas"
                  },
                  {
                    "tr_badgeTitle": "Streaming fluido<br/>en 4K"
                  },
                  {
                    "tr_badgeTitle": "Creación <br/>potente"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolucionadas.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño delgado y liviano que va más allá de un desempeño superior<br/><span class='marklist'>y</span> se conecta a la perfección con tu teléfono actual <sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápido con una batería duradera."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que amas con un desempeño extraordinario.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitareas<br/>extremas"
                  },
                  {
                    "tr_badgeTitle": "Streaming fluido<br/>en 8K"
                  },
                  {
                    "tr_badgeTitle": "Creación<br/>avanzada"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolucionadas.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño delgado y liviano que va más allá de un desempeño superior<br/><span class='marklist'>y</span> se conecta a la perfección con tu teléfono actual <sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápido con una batería duradera."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Potencia tus pasiones con un desempeño superior",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Edición rápida de fotos<br/>y videos"
                  },
                  {
                    "tr_badgeTitle": "Mejores capacidades<br/>sin conexión"
                  },
                  {
                    "tr_badgeTitle": "Diviértete con juegos<br/>sin preocupaciones con <br/>una gran autonomía"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_3",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolucionadas.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño delgado y liviano que va más allá de un desempeño superior<br/><span class='marklist'>y</span> se conecta a la perfección con tu teléfono actual <sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápido con una batería duradera."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que amas con un desempeño increíble",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitareas<br/>fáciles"
                  },
                  {
                    "tr_badgeTitle": "Streaming<br/>fluido"
                  },
                  {
                    "tr_badgeTitle": "Creación<br/>rápida"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_5",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolucionadas.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño delgado y liviano que va más allá de un desempeño superior<br/><span class='marklist'>y</span> se conecta a la perfección con tu teléfono actual <sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápido con una batería duradera."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que amas con un desempeño excepcional.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitareas<br/>mejoradas"
                  },
                  {
                    "tr_badgeTitle": "Streaming fluido<br/>en 4K"
                  },
                  {
                    "tr_badgeTitle": "Creación <br/>potente"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_7",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolucionadas.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño delgado y liviano que va más allá de un desempeño superior<br/><span class='marklist'>y</span> se conecta a la perfección con tu teléfono actual <sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápido con una batería duradera."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Haz lo que amas con un desempeño extraordinario.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitareas<br/>extremas"
                  },
                  {
                    "tr_badgeTitle": "Streaming fluido<br/>en 8K"
                  },
                  {
                    "tr_badgeTitle": "Creación<br/>avanzada"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolucionadas.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño delgado y liviano que va más allá de un desempeño superior<br/><span class='marklist'>y</span> se conecta a la perfección con tu teléfono actual <sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápido con una batería duradera."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Descubre nuevas experiencias de IA",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "IA generativa <br/>hasta un 65 % más rápida"
                  },
                  {
                    "tr_badgeTitle": "Edición de video impulsada por IA <br/>hasta 2,3 veces más rápida"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolucionadas.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño delgado y liviano que va más allá de un desempeño superior<br/><span class='marklist'>y</span> se conecta a la perfección con tu teléfono actual <sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápido con una batería duradera."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Descubre nuevas experiencias de IA",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "IA generativa <br/>hasta un 65 % más rápida"
                  },
                  {
                    "tr_badgeTitle": "Edición de video impulsada por IA <br/>hasta 2,3 veces más rápida"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Chromebooks*.<br/>Evolucionadas.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Diseño delgado y liviano que va más allá de un desempeño superior<br/><span class='marklist'>y</span> se conecta a la perfección con tu teléfono actual <sup>13</sup><br/><span class='marklist'>y</span> selecciona de forma inteligente la mejor conexión Wi-Fi o 5G<br/><span class='marklist'>y</span> se carga rápido con una batería duradera."
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Procesador",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Descubre nuevas experiencias de IA",
                "tr_subtitle": "",
                "tr_gen_core_title": "Procesador Intel®&nbsp;Core™ Ultra&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "IA generativa <br/>hasta un 65 % más rápida"
                  },
                  {
                    "tr_badgeTitle": "Edición de video impulsada por IA <br/>hasta 2,3 veces más rápida"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "legalText": {
    "tr_specTitle": "Información legal",
    "tr_paragraph_array": [
      "¹Hasta 10&nbsp;horas de autonomía con una carga completa según pruebas internas de Google*.",
      "²Ningún producto o componente puede ser absolutamente seguro. Consulte al fabricante del sistema o al distribuidor minorista, o bien obtenga más información en intel.com.",
      "³Según mediciones de análisis de referencia de la industria, pruebas de la Guía de uso representativo y características únicas del procesador Intel® Core™ i7-1165G7. Para conocer las cargas de trabajo y las configuraciones, visite www.intel.com/PerformanceIndex. Los resultados pueden variar.",
      "⁴Según mediciones de multitareas de Google Sheets.",
      "⁵Según mediciones de Speedometer 2.0.",
      "⁶Según mediciones de Kinemaster Export Video.",
      "Para las notas a pie de página 4-6, se han completado todas las pruebas que comparaban un procesador Intel® Core™ i3-1115G4 con un procesador Intel® Celeron® N4020. Visite www.Intel.com/PerformanceIndex para conocer más detalles sobre la carga de trabajo y la configuración. El desempeño varía según el uso, la configuración y otros factores. Conozca más en www.intel.com/PerformanceIndex. Los resultados de desempeño se basan en pruebas realizadas a las fechas que se indican en las configuraciones y es posible que no reflejen todas las actualizaciones que están a disposición del público.",
      "⁷El tiempo que toma gastar desde el 100 % al nivel de batería crítico mientras se realizan flujos de trabajo típicos en un entorno realista. Los resultados pueden variar. Detalles en intel.com/evo.",
      "⁸Los resultados pueden variar. Detalles en intel.com/evo.",
      "⁹Casi 3 veces más rápido: un dispositivo 802.11ax 2 x 2 de 160 MHz permite alcanzar la tasa de transferencia de datos máxima teórica de 2402 Mbps. Alrededor de 3 veces más rápido que un dispositivo con Wi‑Fi 802.11ac 2 x 2 de 80 MHz (867 Mbps) convencional, como se detalla en las especificaciones de la normativa de conexiones inalámbricas IEEE 802.11*, y requiere del uso de enrutadores de red inalámbricos 802.11ax con similar configuración.",
      "¹⁰Carga alcanzada desde el nivel de apagado predeterminado por el OEM. Los resultados pueden variar. Detalles en intel.com/evo.",
      "¹¹En comparación con la generación de procesadores anterior.",
      "¹²Según las características únicas de los diseños Intel® Evo™, como las CPUs Intel® Core™ de alto rendimiento, los componentes prémium, la compatibilidad inigualable y las soluciones de conectividad más recientes en diseños innovadores y elegantes. Todos los diseños de la marca Intel® Evo™ deben cumplir con normas exigentes en lo que respecta a las experiencias clave de los usuarios móviles, como la capacidad de respuesta y la autonomía. El desempeño particular de los dispositivos puede variar. Más información en www.intel.com/PerformanceIndex (plataformas).",
      "¹³La solución Intel® Unison™ actualmente solo está disponible en diseños Intel® Evo™ seleccionados en PCs equipadas con Windows* y solo se conecta con teléfonos Android* o iOS*. Todos los dispositivos deben contar con un SO compatible. El desempeño particular de los dispositivos Intel® Evo™ puede variar. Obtenga más información en intel.com/Performance-Evo.",
      "Las características y los beneficios de las tecnologías Intel® dependen de la configuración del sistema y podrían requerir hardware y software habilitados y la activación de servicios. El desempeño varía según la configuración del sistema. Ningún sistema informático puede ser absolutamente seguro. Consulte al fabricante del sistema o al distribuidor minorista, o bien puede encontrar más información en intel.com.",
      "*Otros nombres y marcas podrían ser reclamados como propiedad de terceros.",
      "Intel no asume ninguna responsabilidad por precios incorrectos.",
      "© 2022 Intel Corporation. Celeron, Intel, el logotipo de Intel, Intel Core y Pentium son marcas comerciales de Intel Corporation o de sus filiales."
    ]
  }
}