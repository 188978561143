const Library_mobile_zhCN = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "采用 [cpuDisplay] 处理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "采用 [cpuDisplay] 处理器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "就像大脑指挥人的一切行动一样，处理器控制着电脑的一切操作。包括您观看的视频、运行的游戏、访问的网站，一切都从处理器开始。",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "处理器是计算机的大脑"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "性能出众，物超所值。",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "英特尔® 奔腾® Silver 处理器",
                    "tr_subtitle": "借助从 Web 访问文件的强大性能，掌控自己的数字世界。在程序之间轻松切换，简化多任务处理，在更短时间内完成更多工作。",
                    "tr_gen_core_title": "英特尔® 奔腾® Silver 处理器"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "英特尔® 奔腾® Gold 处理器",
                    "tr_subtitle": "提高工作效率 - 增强性能，让您在繁忙的工作日动力十足。简化网页浏览 - 快速加载媒体丰富的网页，让您探索更多内容，缩短等待时间。",
                    "tr_gen_core_title": "英特尔® 奔腾® Gold 处理器"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "英特尔® 赛扬® 处理器",
                    "tr_subtitle": "出色的性能让工作如虎添翼、为娱乐锦上添花。畅享电影和网络视频令人惊叹的细节。提高工作效率，缩短等待时间。",
                    "tr_gen_core_title": "英特尔® 赛扬® 处理器"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "英特尔® 奔腾® 处理器",
                    "tr_subtitle": "专为轻松娱乐、视频直播和高效工作而设计。畅享电影和网络视频令人惊叹的细节。提高工作效率，缩短等待时间。",
                    "tr_gen_core_title": "英特尔® 奔腾® 处理器"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "所需的性能<br/>经济实惠的价格",
                "tr_title": "英特尔® 处理器",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "各有所值",
                    "tr_subtitle": "英特尔® 处理器以您负担得起的价格，处理您需要的几乎任何任务。轻松地从一个软件导航到下一个软件，或畅享性能稳定的高质量图案。",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "建立性能更强大的连接",
                    "tr_subtitle": "还原度更高的视频质量、更丰富的音频清晰度和近 3 倍的互联网连接提速，让您可以随时随地自信地进行协作。<sup>26</sup>",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "天壤之别",
                    "tr_subtitle": "从早上的长时间连续观影到最后一刻的修片，英特尔® 处理器可延长您设备的电池续航时间，以提供真正身临其境、不间断的体验。",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "所有环境下都可以进行学习",
                    "tr_subtitle": "在家中的各个角落，都可以轻松运行多种在线学习工具。"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "随时随地学习",
                    "tr_subtitle": "运行各种电子学习工具，随时随地完成课程。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "享受与您的预算和生活方式相匹配的娱乐和生产力。",
                "tr_title": "任何预算，都能获得合适的英特尔性能。",
                "tr_gen_core_title": "英特尔® 赛扬® 处理器",
                "processorCompare": {
                  "tr_title": "找到合适的性价平衡点",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "英特尔® 赛扬® 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "英特尔® 奔腾® Gold 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i3 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "多媒体网页快速浏览",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "轻松在程序之间切换",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "休闲游戏",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播 4K 视频",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "轻量级照片和视频编辑",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "借助智能性能提高工作效率",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "采用第 7 代英特尔® 酷睿™ [cpu_fam] 处理器"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "采用第 6 代英特尔® 酷睿™ [cpu_fam] 处理器"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "采用第 5 代英特尔® 酷睿™ [cpu_fam] 处理器"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "采用第 4 代英特尔® 酷睿™ [cpu_fam] 处理器"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "采用第 3 代英特尔® 酷睿™ [cpu_fam] 处理器"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "采用第 2 代英特尔® 酷睿™ [cpu_fam] 处理器"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "采用第 1 代英特尔® 酷睿™ [cpu_fam] 处理器"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "推荐用途：",
                    "<span><img src=></span>",
                    "<span><img src=></span>"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "采用第 8 代英特尔® 酷睿™ i3 处理器",
                "tr_gen_core_title": "第&nbsp;8&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i3&nbsp;处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 9 代英特尔® 酷睿™ 处理器的开发宗旨是紧跟数字世界的发展。兼&#65279;具速度与性能，能&#65279;够轻松完成更多过去用电脑完成的赏心乐事。",
                "tr_title": "采用第 9 代英特尔® 酷睿™ i3 处理器",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i3&nbsp;处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "出色的性能与充足的电池续航时间相结合，让您随时随地动力十足。",
                "tr_title": "让您的一天动力十足",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i3 处&#xfeff;理&#xfeff;器",
                "processorCompare": {
                  "tr_title": "选择合适的第&nbsp;10&nbsp;代&#65279;处&#65279;理&#65279;器&#65279;性&#65279;能&#65279;水&#65279;平&#65279;：",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i3 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "在线观看 4K 电影",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "快速可靠的连接",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "照片 P 图与视频滤镜",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "1080P 游戏体验，全高清模式畅玩",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最适合处理大型文件和创意程序",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "从在线观看电影轻松切换至创作演示，无需占用很多性能空间。",
                "tr_title": "让您的一天动力十足",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i3 处&#xfeff;理&#xfeff;器"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "专为不停奔波的用户设计的笔记本电脑。",
                "tr_title": "了解新型笔记本电脑",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i3 处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "第 11 代英特尔® 酷睿™ i3 处理器专为便携式电脑量身设计，帮助您随时随地提高工作效率。",
                "tr_title": "充&#65279;分挖&#65279;掘全&#65279;新笔&#65279;记&#65279;本&#65279;电&#65279;脑潜&#65279;能",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i3 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i3 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "当您的整个家庭都希望在一部台式机上完成所有工作时，您需要第 11 代英特尔® 酷睿™ i3 处理器的强大能力和多功能性。",
                "tr_title": "突破传统性能边界<br/>一切皆有可能",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i3 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i3 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "由第 8 代英特尔® 酷睿™ i5 处理器提供支持",
                "tr_gen_core_title": "第&nbsp;8&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5&nbsp;处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 9 代英特尔® 酷睿™ 处理器的开发宗旨是紧跟数字世界的发展。兼&#65279;具速度与性能，能&#65279;够轻松完成更多过去用电脑完成的赏心乐事。",
                "tr_title": "采用第 9 代英特尔® 酷睿™ i5 处理器",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5&nbsp;处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "畅玩要求最苛刻的游戏，实现顺畅的虚拟现实体验",
                "tr_title": "正版游戏，从此开始",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5&nbsp;处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "找到一款能让您尽情畅玩游戏的英特尔® 酷睿™ 处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "英特尔® 酷睿™ i9 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA 级游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "竞技游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "游戏、直播、录制，统统酣畅淋漓，毫不妥协",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最适合游戏的第 9 代英特尔® 酷睿™ 处理器",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：4<br/>线程：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "轻松地在多个程序间切换，让您每天都准备好做任何事情。",
                "tr_title": "随时进行多任务处理",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "processorCompare": {
                  "tr_title": "选择合适的第&nbsp;10&nbsp;代&#65279;处&#65279;理&#65279;器&#65279;性&#65279;能&#65279;水&#65279;平&#65279;：",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i3 处理器"
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "在线观看 4K 电影",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "快速可靠的连接",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "照片 P 图与视频滤镜",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "1080P 游戏体验，全高清模式畅玩",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最适合处理大型文件和创意程序",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "从娱乐到工作效率以及内容创建，提升您的所有操作。",
                "tr_title": "升级至更加强大的电&#xfeff;脑",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "专为不停奔波的用户设计的笔记本电脑。",
                "tr_title": "了解新型笔记本电脑",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "为无缝直播和 AAA 级游戏而搭建",
                "tr_title": "各种任务随您心意",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "为游戏玩家推荐一款适合你的第 10 代英特尔® 酷睿™ 处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i9 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "流畅的 AAA 级游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "竞技游戏",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播和录制",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高阶内容创作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "游戏峰值性能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "未锁频且可超频<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "为无缝直播和 AAA 级游戏而搭建",
                "tr_title": "各种任务随您心意",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "为游戏玩家推荐一款适合你的第 10 代英特尔® 酷睿™ 处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i9 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "流畅的 AAA 级游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "竞技游戏",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播和录制",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高阶内容创作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "游戏峰值性能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "未锁频且可超频<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：10<br/>线程：20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "轻松地在多个程序间切换，让您每天都准备好做任何事情。",
                "tr_title": "随时进行多任务处理",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "第 11 代英特尔® 酷睿™ i5 处理器赋予最轻薄的电脑栩栩如生的视觉体验和高效的工作体验。",
                "tr_title": "笔记本电脑性能令人惊叹",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "当您的整个家庭都希望在一部台式机上完成所有工作时，您需要第 11 代英特尔® 酷睿™ i5 处理器的强大能力和多功能性。",
                "tr_title": "突破传统性能边界<br/>一切皆有可能",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 11 代英特尔® 酷睿™ i5 处理器赋予轻薄型笔记本电脑逼真的视觉感受和高效的工作体验。",
                "tr_title": "笔记本电脑性能令人惊叹",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "由第 8 代英特尔® 酷睿™ i7 处理器提供支持",
                "tr_gen_core_title": "第&nbsp;8&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7&nbsp;处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "第 9 代英特尔® 酷睿™ 处理器的开发宗旨是紧跟数字世界的发展。兼&#65279;具速度与性能，能&#65279;够轻松完成更多过去用电脑完成的赏心乐事。",
                "tr_title": "采用第 9 代英特尔® 酷睿™ i7 处理器",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7&nbsp;处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "与 3 年机龄的电脑相比，游戏、直播、录制时的 FPS 可提升高达 32%<sup>3</sup>",
                "tr_title": "性能强大，顺畅分享最辉煌的游戏瞬间",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7&nbsp;处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "找到一款能让您尽情畅玩游戏的英特尔® 酷睿™ 处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i9 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA 级游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "竞技游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "游戏、直播、录制，统统酣畅淋漓，毫不妥协",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最适合游戏的第 9 代英特尔® 酷睿™ 处理器",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：4<br/>线程：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "专为高效率而设计，这款线条流畅的设备对您的创意、连接和流媒体使用方式都有重大的影响。",
                "tr_title": "性能优异，随时随地",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "processorCompare": {
                  "tr_title": "选择合适的第&nbsp;10&nbsp;代&#65279;处&#65279;理&#65279;器&#65279;性&#65279;能&#65279;水&#65279;平&#65279;：",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i3 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "在线观看 4K 电影",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "快速可靠的连接",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "照片 P 图与视频滤镜",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "1080P 游戏体验，全高清模式畅玩",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最适合处理大型文件和创意程序",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "优异的性能，让您所有的操作更上一层楼。",
                "tr_title": "功能强劲，尽展所能",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "专为不停奔波的用户设计的笔记本电脑。",
                "tr_title": "了解新型笔记本电脑",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "具有游戏、直播和录制的竞争优势",
                "tr_title": "为游戏提供强劲动力",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "为游戏玩家推荐一款适合你的第 10 代英特尔® 酷睿™ 处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i9 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "流畅的 AAA 级游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "竞技游戏",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播和录制",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高阶内容创作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "游戏峰值性能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "未锁频且可超频<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "具有游戏、直播和录制的竞争优势",
                "tr_title": "为游戏提供强劲动力",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "为游戏玩家推荐一款适合你的第 10 代英特尔® 酷睿™ 处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i9 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "流畅的 AAA 级游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "竞技游戏",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播和录制",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高阶内容创作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "游戏峰值性能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "未锁频且可超频<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：10<br/>线程：20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "专为提升效率而设计，这款轻薄时尚的设备将深刻影响您创作、连接和直播的方式。",
                "tr_title": "随时随地享&#65279;受&#65279;卓&#65279;越&#65279;性&#65279;能",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "有了第 11 代英特尔® 酷睿™ i7 处理器，超级便携式电脑能&#65279;够实&#65279;现高端内容创作、流畅游戏和全新娱乐体验。",
                "tr_title": "笔记本电脑性能打破极限",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "借助第 11 代英特尔® 酷睿™ i7 处理器的突破性能，让您的台式机成为完成各项家庭任务的首选工具。",
                "tr_title": "工作、学习、娱乐 无障碍驾驭",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "有了第 11 代英特尔® 酷睿™ i7 处理器，轻薄型笔记本电脑能够实现高阶内容创作、流畅游戏和全新娱乐体验。",
                "tr_title": "笔记本电脑性能打破极限",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "借助第 11 代英特尔® 酷睿™ i7 处理器的突破性能，让您的台式机成为完成各项家庭任务的首选工具。",
                "tr_title": "突破创意边界",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "让轻薄型笔记本电脑实现高阶内容创作、流畅游戏和全新娱乐体验，这款第 11 代英特尔® 酷睿™ i7 处理器性能更出色。",
                "tr_title": "笔记本电脑性能打破极限",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "由第 8 代英特尔® 酷睿™ i9 处理器提供支持",
                "tr_gen_core_title": "第&nbsp;8&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9&nbsp;处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "第 9 代英特尔® 酷睿™ 处理器的开发宗旨是紧跟数字世界的发展。兼&#65279;具速度与性能，能&#65279;够轻松完成更多过去用电脑完成的赏心乐事。",
                "tr_title": "采用第 9 代英特尔® 酷睿™ i9 处理器",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9&nbsp;处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "与 3 年机龄的电脑相比，游戏、直播、录制时的 FPS 可提升高达 41%<sup>4</sup>",
                "tr_title": "游戏玩家的需求英特尔® 酷睿™ i9 尽可满足",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9&nbsp;处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "找到一款能让您尽情畅玩游戏的英特尔® 酷睿™ 处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "",
                      "tr_imgCaption": "英特尔® 酷睿™ i9 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA 级游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "竞技游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "游戏、直播、录制，统统酣畅淋漓，毫不妥协",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最适合游戏的第 9 代英特尔® 酷睿™ 处理器",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：4<br/>线程：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "极致游戏体验激发无限潜能",
                "tr_title": "进行最高水平的竞争",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "为游戏玩家推荐一款适合你的第 10 代英特尔® 酷睿™ 处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i9 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "流畅的 AAA 级游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "竞技游戏",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播和录制",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高阶内容创作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "游戏峰值性能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "未锁频且可超频<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "极致游戏体验激发无限潜能",
                "tr_title": "进行最高水平的竞争",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "为游戏玩家推荐一款适合你的第 10 代英特尔® 酷睿™ 处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i9 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "流畅的 AAA 级游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "竞技游戏",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播和录制",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高阶内容创作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "游戏峰值性能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "未锁频且可超频<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：10<br/>线程：20</span>"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "激发创作灵感助您释放无穷潜力",
                "tr_title": "尽情创作",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "英特尔 Evo™ 笔记本电脑采用第 11 代英特尔® 酷睿™ i5 处理器和英特尔锐炬® X<sup class='small_text_sup_xe'>e</sup> 显卡，用轻薄的设计带来超乎想象的速度和性能。",
                "tr_title": "性能增强的笔记本电脑实&#xfeff;现&#xfeff;动&#xfeff;力&#xfeff;升&#xfeff;级",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "英特尔 Evo™ 笔记本电脑采用第 11 代英特尔® 酷睿™ i7 处理器和英特尔锐炬® X<sup class='small_text_sup_xe'>e</sup> 显卡，用先进的速度与出色性能为您带来更卓越的移动体验。",
                "tr_title": "动力升级的笔记本电脑，性能强大",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "性价比高，性能、媒体和连接体验实现完美平衡。",
                "tr_title": "性价比高性能强连接快",
                "tr_gen_core_title": "英特尔® 奔腾® Silver 处理器",
                "processorCompare": {
                  "tr_title": "找到合适的性价平衡点",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "英特尔® 奔腾® Silver 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "英特尔® 奔腾® Gold 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i3 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "多媒体网页快速浏览",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "轻松在程序之间切换",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "休闲游戏",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播 4K 视频",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "轻量级照片和视频编辑",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "借助智能性能提高工作效率",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "以超乎想象的价格体验无缝多任务处理、简单的照片和视频编辑、4K 视频和生动的图形。",
                "tr_title": "面向工作和娱乐的出色性能。",
                "tr_gen_core_title": "英特尔® 奔腾® Gold 处理器",
                "processorCompare": {
                  "tr_title": "找到合适的性价平衡点",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "英特尔® 奔腾® Silver 处理器"
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "英特尔® 奔腾® Gold 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i3 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "多媒体网页快速浏览",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "轻松在程序之间切换",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "休闲游戏",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播 4K 视频",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "轻量级照片和视频编辑",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "借助智能性能提高工作效率",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "选择电池续航时间持久的超便携电脑，畅玩 AAA 级游戏。游戏体验不妥协。",
                "tr_title": "性能不妥协",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷&#65279;睿&#65279;™ i5 处&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷&#65279;睿&#65279;™ i7 处&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷&#65279;睿&#65279;™ i9 处&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "选购您的英特尔® 酷睿™ 超便携游戏处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "第 11 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i5&#8209;11300H 处&#65279;理&#65279;器"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "第 11 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i7&#8209;11370H 处&#65279;理&#65279;器"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "第 11 代英&#65279;特&#65279;尔&#65279;® 酷&#65279;睿&#65279;™ i7&#8209;11375H 特&#65279;别&#65279;版&#65279;处&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "睿频频率至高可达 <span class='style_badge'>4.4 GHz</span>",
                      "tr_td2_title": "睿频频率至高可达<span class='style_badge'> 4.8 GHz</span>",
                      "tr_td3_title": "睿频频率至高可达 <span class='style_badge'>5.0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 核/8 线程",
                      "tr_td2_title": "4 核/8 线程",
                      "tr_td3_title": "4 核/8 线程"
                    },
                    {
                      "tr_td1_title": "英特尔® 睿频加速技术",
                      "tr_td2_title": "英特尔® 睿频加速技术",
                      "tr_td3_title": "英特尔® 睿频加速 Max 技&#65279;术 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB 英&#65279;特&#65279;尔&#65279;® 智&#65279;能&#65279;高&#65279;速&#65279;缓&#65279;存",
                      "tr_td2_title": "12 MB 英&#65279;特&#65279;尔&#65279;® 智&#65279;能&#65279;高&#65279;速&#65279;缓&#65279;存",
                      "tr_td3_title": "12 MB 英&#65279;特&#65279;尔&#65279;® 智&#65279;能&#65279;高&#65279;速&#65279;缓&#65279;存"
                    },
                    {
                      "tr_td1_title": "英特尔锐&#65279;炬&#65279;®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;显&#65279;卡",
                      "tr_td2_title": "英特尔锐&#65279;炬&#65279;®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;显&#65279;卡",
                      "tr_td3_title": "英特尔锐&#65279;炬&#65279;®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;显&#65279;卡"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "在高设置下具有高帧速率，畅享 1080p 的 AAA 级游戏，以超便携电脑的机身提供优异的不插电性能。",
                "tr_title": "芯怀猛虎，肆意轻盈",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷&#65279;睿&#65279;™ i5 处&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷&#65279;睿&#65279;™ i7 处&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i9 处理器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "选购您的英特尔® 酷睿™ 超便携游戏处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "第 11 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i5&#8209;11300H 处&#65279;理&#65279;器"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "第 11 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i7&#8209;11370H 处&#65279;理&#65279;器"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "第 11 代英&#65279;特&#65279;尔&#65279;® 酷&#65279;睿&#65279;™ i7&#8209;11375H 特&#65279;别&#65279;版&#65279;处&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "睿频频率至高可达 <span class='style_badge'>4.4 GHz</span>",
                      "tr_td2_title": "睿频频率至高可达<span class='style_badge'> 4.8 GHz</span>",
                      "tr_td3_title": "睿频频率至高可达 <span class='style_badge'>5.0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 核/8 线程",
                      "tr_td2_title": "4 核/8 线程",
                      "tr_td3_title": "4 核/8 线程"
                    },
                    {
                      "tr_td1_title": "英特尔® 睿频加速技术",
                      "tr_td2_title": "英特尔® 睿频加速技术",
                      "tr_td3_title": "英特尔® 睿频加速 Max 技&#65279;术 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB 英&#65279;特&#65279;尔&#65279;® 智&#65279;能&#65279;高&#65279;速&#65279;缓&#65279;存",
                      "tr_td2_title": "12 MB 英&#65279;特&#65279;尔&#65279;® 智&#65279;能&#65279;高&#65279;速&#65279;缓&#65279;存",
                      "tr_td3_title": "12 MB 英&#65279;特&#65279;尔&#65279;® 智&#65279;能&#65279;高&#65279;速&#65279;缓&#65279;存"
                    },
                    {
                      "tr_td1_title": "英特尔锐&#65279;炬&#65279;®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;显&#65279;卡",
                      "tr_td2_title": "英特尔锐&#65279;炬&#65279;®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;显&#65279;卡",
                      "tr_td3_title": "英特尔锐&#65279;炬&#65279;®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;显&#65279;卡"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "至高可达 5.0 GHz 的睿频频率让游戏体验更进一步，超便携电脑让您肆意纵横驰骋。",
                "tr_title": "身如翼，芯似虎",
                "tr_gen_core_title": "第 11 代英特尔® 酷睿™ i7 特别版处理器",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷&#65279;睿&#65279;™ i5 处&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷&#65279;睿&#65279;™ i7 处&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "英特尔® 酷&#65279;睿&#65279;™ i7 特&#65279;别&#65279;版&#65279;处&#65279;理&#65279;器",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "选购您的英特尔® 酷睿™ 超便携游戏处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "第 11 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i5&#8209;11300H 处&#65279;理&#65279;器"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "第 11 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i7&#8209;11370H 处&#65279;理&#65279;器"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "第 11 代英&#65279;特&#65279;尔&#65279;® 酷&#65279;睿&#65279;™ i7&#8209;11375H 特&#65279;别&#65279;版&#65279;处&#65279;理&#65279;器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "睿频频率至高可达 <span class='style_badge'>4.4 GHz</span>",
                      "tr_td2_title": "睿频频率至高可达<span class='style_badge'> 4.8 GHz</span>",
                      "tr_td3_title": "睿频频率至高可达 <span class='style_badge'>5.0 GHz</span>"
                    },
                    {
                      "tr_td1_title": "4 核/8 线程",
                      "tr_td2_title": "4 核/8 线程",
                      "tr_td3_title": "4 核/8 线程"
                    },
                    {
                      "tr_td1_title": "英特尔® 睿频加速技术",
                      "tr_td2_title": "英特尔® 睿频加速技术",
                      "tr_td3_title": "英特尔® 睿频加速 Max 技&#65279;术 3.0"
                    },
                    {
                      "tr_td1_title": "8 MB 英&#65279;特&#65279;尔&#65279;® 智&#65279;能&#65279;高&#65279;速&#65279;缓&#65279;存",
                      "tr_td2_title": "12 MB 英&#65279;特&#65279;尔&#65279;® 智&#65279;能&#65279;高&#65279;速&#65279;缓&#65279;存",
                      "tr_td3_title": "12 MB 英&#65279;特&#65279;尔&#65279;® 智&#65279;能&#65279;高&#65279;速&#65279;缓&#65279;存"
                    },
                    {
                      "tr_td1_title": "英特尔锐&#65279;炬&#65279;®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;显&#65279;卡",
                      "tr_td2_title": "英特尔锐&#65279;炬&#65279;®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;显&#65279;卡",
                      "tr_td3_title": "英特尔锐&#65279;炬&#65279;®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;显&#65279;卡"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "借助第 11 代英特尔® 酷睿™ i7 特别版处理器，超便携式笔记本电脑能够实现高阶内容创作、流畅的游戏体验和全新的娱乐体验。",
                "tr_title": "笔记本电脑性能打破极限",
                "tr_gen_core_title": "第 11 代英特尔® 酷睿™ i7 特别版处理器",
                "tr_gen_core_title_uppercase": "第 11 代英特尔® 酷睿™ i7 特别版处理器"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "借助第 11 代英特尔® 酷睿™ i7 特别版处理器，超便携式笔记本电脑能够实现高阶内容创作、流畅的游戏体验和全新的娱乐体验。",
                "tr_title": "笔记本电脑性能打破极限",
                "tr_gen_core_title": "第 11 代英特尔® 酷睿™ i7 特别版处理器",
                "tr_gen_core_title_uppercase": "第 11 代英特尔® 酷睿™ i7 特别版处理器",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "英特尔® 酷&#65279;睿&#65279;™ i7 特&#65279;别&#65279;版&#65279;处&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "快速时钟速度和高内核计数可以无缝地协同工作，以改进竞技游戏和复杂内容创建体验。",
                "tr_title": "强大之余<br/>更显均衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i9 处理器",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "无论是具有高帧速率的竞技游戏还是沉浸式 AAA 级体验，时钟速度和内核性能之间合适的平衡都让您畅享游戏。",
                "tr_title": "强大性能<br/>为游戏而生",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "借助高帧速率同时玩游戏、录像和直播，并轻松切换到繁重的多任务处理工作负载。",
                "tr_title": "性能强大，超乎想象",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "快速时钟速度和高内核计数可以无缝地协同工作，以改进竞技游戏和复杂内容创建体验。",
                "tr_title": "强大之余<br/>更显均衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "从在线观看电影轻松切换至创作演示，无需占用很多性能空间。",
                "tr_title": "让您的一天动力十足",
                "tr_gen_core_title": "采用英特尔® Hybrid Technology&nbsp;的英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i3&nbsp;处&#65279;理&#65279;器",
                "tr_subTitle2": "采用英特尔® Hybrid Technology&nbsp;的英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i3&nbsp;处&#65279;理&#65279;器"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "出色的性能与充足的电池续航时间相结合，让您随时随地动力十足。",
                "tr_title": "让您的一天动力十足",
                "tr_gen_core_title": "采用英特尔® Hybrid Technology&nbsp;的英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i3&nbsp;处&#65279;理&#65279;器",
                "tr_subTitle2": "采用英特尔® Hybrid Technology&nbsp;的英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i3&nbsp;处&#65279;理&#65279;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "轻松地在多个程序间切换，让您每天都准备好做任何事情。",
                "tr_title": "随时进行多任务处理",
                "tr_gen_core_title": "采用英特尔® Hybrid Technology&nbsp;的英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i5&nbsp;处&#65279;理&#65279;器",
                "tr_subTitle2": "采用英特尔® Hybrid Technology&nbsp;的英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i5&nbsp;处&#65279;理&#65279;器"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "从娱乐到工作效率以及内容创建，提升您的所有操作。",
                "tr_title": "升级至更加强大的电&#xfeff;脑",
                "tr_gen_core_title": "采用英特尔® Hybrid Technology&nbsp;的英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i5&nbsp;处&#65279;理&#65279;器",
                "tr_subTitle2": "采用英特尔® Hybrid Technology&nbsp;的英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i5&nbsp;处&#65279;理&#65279;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "无论以高帧速率玩对战游戏，还是体验沉浸式 AAA 级游戏，都将畅享台式机的游戏性能与移动性相结合所带来的乐趣。",
                "tr_title": "强大性能<br/>为游戏而生",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "无论以高帧速率玩对战游戏，还是体验沉浸式 AAA 级游戏，都将畅享台式机的游戏性能与移动性相结合所带来的乐趣。",
                "tr_title": "强大性能<br/>为游戏而生",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "用笔记本电脑以高帧速率同时玩&#xfeff;游戏、录制和直播，还能轻松&#xfeff;切&#xfeff;换到繁重的多任务处理工作&#xfeff;负&#xfeff;载。",
                "tr_title": "性能强大，超乎想象",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "用笔记本电脑以高帧速率同时玩&#xfeff;游戏、录制和直播，还能轻松&#xfeff;切&#xfeff;换到繁重的多任务处理工作&#xfeff;负&#xfeff;载。",
                "tr_title": "性能强大，超乎想象",
                "tr_gen_core_title": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "无论您将电脑带到何处，高时钟速度与多个内核的无缝配合，都能助您尽情享受激烈的游戏对战、完成复杂的内容创作。",
                "tr_title": "强大之余<br/>更显均衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "无论您将电脑带到何处，高时钟速度与多个内核的无缝配合，都能助您尽情享受激烈的游戏对战、完成复杂的内容创作。",
                "tr_title": "强大之余<br/>更显均衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "无论您将电脑带到何处，高时钟速度、多个内核和未锁频性能的无缝配合，都能助您尽情享受激烈的游戏对战、完成复杂的内容创作。",
                "tr_title": "超频之余<br/> 更显均衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "无论您将电脑带到何处，高时钟速度、多个内核和未锁频性能的无缝配合，都能助您尽情享受激烈的游戏对战、完成复杂的内容创作。",
                "tr_title": "超频之余<br/> 更显均衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "快速时钟速度和高内核计数可以无缝地协同工作，以改进竞技游戏和复杂内容创建体验。",
                "tr_title": "强大之余<br/>更显均衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "高时钟速度与多个内核的无缝配合，让您无论身处何地都能完成复杂的内容创作。",
                "tr_title": "强大之余<br/>更显均衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "高时钟速度和多个内核无缝协同工作，助您完成复杂的内容创作。",
                "tr_title": "强大之余<br/>更显均衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "极致游戏体验激发无限潜能",
                "tr_title": "进行最高水平的竞争",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "为游戏玩家推荐一款适合你的第 10 代英特尔® 酷睿™ 处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i9 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "流畅的 AAA 级游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "竞技游戏",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播和录制",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高阶内容创作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "游戏峰值性能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "未锁频且可超频<sup>2</sup>",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "极致游戏体验激发无限潜能",
                "tr_title": "进行最高水平的竞争",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "为游戏玩家推荐一款适合你的第 10 代英特尔® 酷睿™ 处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i9 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "流畅的 AAA 级游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "竞技游戏",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "直播和录制",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "高阶内容创作",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "游戏峰值性能",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "未锁频且可超频<sup>2</sup>",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.8&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.1&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：10<br/>线程：20</span>"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "专为不停奔波的用户设计的笔记本电脑。",
                "tr_title": "了解新型笔记本电脑",
                "tr_gen_core_title": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "快速时钟速度和高内核计数可以无缝地协同工作，以改进竞技游戏和复杂内容创建体验。",
                "tr_title": "超频之余<br/> 更显均衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "无论您将电脑带到何处，高时钟速度、多个内核和未锁频性能的无缝配合，都能助您完成复杂的内容创作。",
                "tr_title": "超频之余<br/> 更显均衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "高时钟速度和多个内核无缝协同工作，助您完成复杂的内容创作。",
                "tr_title": "超频之余<br/> 更显均衡",
                "tr_gen_core_title": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "tr_gen_core_title_uppercase": "第&nbsp;11&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>英特尔® 智能高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "在要求苛刻的游戏中享受流畅的游戏和虚拟现实体验",
                "tr_title": "正版游戏，从此开始",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5&nbsp;处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "找到一款能让您尽情畅玩游戏的英特尔® 酷睿™ 处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i9 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA 级游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "竞技游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "游戏、直播、录制，统统酣畅淋漓，毫不妥协",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最适合游戏的第 9 代英特尔® 酷睿™ 处理器",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：4<br/>线程：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "<span class='tr_subtitle_font'>与 3 年前的电脑相比，游戏、直播、录制时，<br/></span>帧速率提升多达 32%<sup>3</sup>",
                "tr_title": "性能强大，顺畅分享最辉煌的游戏瞬间",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7&nbsp;处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "找到一款能让您尽情畅玩游戏的英特尔® 酷睿™ 处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i9 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA 级游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "竞技游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "游戏、直播、录制，统统酣畅淋漓，毫不妥协",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最适合游戏的第 9 代英特尔® 酷睿™ 处理器",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：4<br/>线程：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "<span class='tr_subtitle_font'>与 3 年前的电脑相比，游戏、直播、录制时，<br/></span>帧速率提升多达 41%<sup>4</sup>",
                "tr_title": "游戏玩家的需求英特尔® 酷睿™ i9 尽可满足",
                "tr_gen_core_title": "第&nbsp;9&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9&nbsp;处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>线程</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>核</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle_font'>睿频至<br/>高可达 <br/></span><span class='badgetitle_font'>[XX] <br/></span><span class='subtitle_font'>GHz</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='badgetitle_font'>[XX] MB <br/></span><span class='subtitle_font'>高速缓存</span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "未锁频",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "找到一款能让您尽情畅玩游戏的英特尔® 酷睿™ 处理器。",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i5 处理器"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i7 处理器"
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "英特尔® 酷睿™ i9 处理器"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "AAA 级游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "竞技游戏",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "游戏、直播、录制，统统酣畅淋漓，毫不妥协",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "最适合游戏的第 9 代英特尔® 酷睿™ 处理器",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.3&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：4<br/>线程：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：12</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.6&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：6<br/>线程：6</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 4.9&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：8</span>"
                    },
                    {
                      "tr_title": "<span class='small_font'>时钟速度 <sup>2</sup>：睿&#65279;频&#65279;至&#65279;高&#65279;可&#65279;达 5&nbsp;GHz</span>",
                      "tr_subTitle": "<span class='small_font'>内核：8<br/>线程：16</span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "异构<br/>一战封神",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i5 处&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i7 处&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "第&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i9 处&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "第&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>核</span></span>",
                    "tr_badgePreTitle": "[p_core] 个 Performance-core (性能核)",
                    "tr_badgeSubTitle": "[e_core] 个 Efficient-core (能效核)"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>线程</span></span>",
                    "tr_badgePreTitle": "[p_thread] 线程在 Performance-core (性能核) 上",
                    "tr_badgeSubTitle": "[e_thread] 线程在 Efficient-core (能效核) 上"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i9 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "畅玩游戏，高效工作",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i7 处&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "第&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i9 处&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "第&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>核</span></span>",
                    "tr_badgePreTitle": "[p_core] 个 Performance-core (性能核)",
                    "tr_badgeSubTitle": "[e_core] 个 Efficient-core (能效核)"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>线程</span></span>",
                    "tr_badgePreTitle": "[p_thread] 线程在 Performance-core (性能核) 上",
                    "tr_badgeSubTitle": "[e_thread] 线程在 Efficient-core (能效核) 上"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i9 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "性能可靠<br/>随时待命",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i3 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i3 处&#xfeff;理&#xfeff;器",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷&#65279;睿&#65279;™ i3 处&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷&#65279;睿&#65279;™ i5 处&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷&#65279;睿&#65279;™ i7 处&#65279;理&#65279;器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "发挥卓越性能<br/>满足核心需求",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i5 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "发挥卓越性能<br/>满足核心需求",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i5 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "发挥卓越性能<br/>满足核心需求",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i7 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "发挥卓越性能<br/>满足核心需求",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i7 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "使用英特尔 Evo™ 笔记本电脑，您可以获得超强性能、让您自信地进行视频聊天的内置智能、更快的互联网连接和持久的电池续航时间等。",
                "tr_title": "发挥卓越性能满&#xfeff;足&#xfeff;核&#xfeff;心&#xfeff;需&#xfeff;求",
                "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i5 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "使用英特尔 Evo™ 笔记本电脑，您可以获得超强性能、让您自信地进行视频聊天的内置智能、更快的互联网连接和持久的电池续航时间等。",
                "tr_title": "发挥卓越性能<br/>满足核心需求",
                "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i7 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "使用英特尔 Evo™ 笔记本电脑，您可以获得超强性能、让您自信地进行视频聊天的内置智能、更快的互联网连接和持久的电池续航时间等。",
                "tr_title": "优质笔记本电脑，极致性能",
                "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i9 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "采用英特尔® 睿频加速技术 2.0，睿频至高可达 [max_turbo_frequency] GHz ",
                "tr_title": "为卓越商务而生",
                "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i5 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "抑制噪音，虚化背景",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "自动调节性能，优先处理最常见任务",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "体验顺畅、高质量的网络通话",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "获得开箱即用的硬件级安全保障",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "提供远程设备管理功能",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "自动优化 Wi-Fi 性能<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "顺畅使用数据驱动型应用",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "增强型性能，激发顶级效能",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "采用英特尔® 睿频加速技术 2.0，睿频至高可达 [max_turbo_frequency] GHz ",
                "tr_title": "工作效率出众，<br/>让您专注于业务",
                "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i7 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "抑制噪音，虚化背景",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "自动调节性能，优先处理最常见任务",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "体验顺畅、高质量的网络通话",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "获得开箱即用的硬件级安全保障",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "提供远程设备管理功能",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "自动优化 Wi-Fi 性能<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "顺畅使用数据驱动型应用",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "增强型性能，激发顶级效能",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "采用英特尔® 睿频加速技术 2.0，睿频至高可达 [max_turbo_frequency] GHz ",
                "tr_title": "加速您的业务",
                "tr_gen_core_title": "第 12 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i9 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;12&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "抑制噪音，虚化背景",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "自动调节性能，优先处理最常见任务",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "体验顺畅、高质量的网络通话",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "获得开箱即用的硬件级安全保障",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "提供远程设备管理功能",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "自动优化 Wi-Fi 性能<sup>20</sup>",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "顺畅使用数据驱动型应用",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "增强型性能，激发顶级效能",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "提供可靠性能，专注处理更多任务",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "第 13 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i3 处&#65279;理&#65279;器",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "提供真实世界性能专&#xfeff;注&#xfeff;处&#xfeff;理&#xfeff;更&#xfeff;多&#xfeff;任&#xfeff;务",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "第 13 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i5 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;13&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "提供真实世界性能专&#xfeff;注&#xfeff;处&#xfeff;理&#xfeff;更&#xfeff;多&#xfeff;任&#xfeff;务",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "第 13 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i5 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;13&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "提供真实世界性能专&#xfeff;注&#xfeff;处&#xfeff;理&#xfeff;更&#xfeff;多&#xfeff;任&#xfeff;务",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "第 13 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i7 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;13&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "提供真实世界性能专&#xfeff;注&#xfeff;处&#xfeff;理&#xfeff;更&#xfeff;多&#xfeff;任&#xfeff;务",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "第 13 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i7 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;13&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "超强芯生",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "第 13 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i9 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;13&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>核</span></span>",
                    "tr_badgePreTitle": "[p_core] 个 Performance-core (性能核)",
                    "tr_badgeSubTitle": "[e_core] 个 Efficient-core (能效核)"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>线程</span></span>",
                    "tr_badgePreTitle": "[p_thread] 线程在 Performance-core (性能核) 上",
                    "tr_badgeSubTitle": "[e_thread] 线程在 Efficient-core (能效核) 上"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i9 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "无缝连接您当前的手机<sup>24</sup>，智能选择最佳 Wi&#8209;Fi，而且电池持久耐用，充电快速。",
                "tr_title": "性能超乎想象，随时随地提高工作效率",
                "tr_gen_core_title": "第 13 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i5 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;13&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "无缝连接您当前的手机<sup>24</sup>，智能选择最佳 Wi&#8209;Fi，而且电池持久耐用，充电快速。",
                "tr_title": "性能超乎想象，助力电脑同时处理更多任务",
                "tr_gen_core_title": "第 13 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i7 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;13&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "无缝连接您当前的手机<sup>24</sup>，智能选择最佳 Wi&#8209;Fi，而且电池持久耐用，充电快速。",
                "tr_title": "性能超乎想象，加速完成要求最苛刻的任务",
                "tr_gen_core_title": "第 13 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i9 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;13&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "超强芯生",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "第 13 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i5 处&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "第&nbsp;13&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "第 13 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i7 处&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "第&nbsp;13&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "第 13 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i9 处&#65279;理&#65279;器",
                    "tr_gen_core_title_uppercase": "第&nbsp;13&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>核</span></span>",
                    "tr_badgePreTitle": "[p_core] 个 Performance-core (性能核)",
                    "tr_badgeSubTitle": "[e_core] 个 Efficient-core (能效核)"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>线程</span></span>",
                    "tr_badgePreTitle": "[p_thread] 线程在 Performance-core (性能核) 上",
                    "tr_badgeSubTitle": "[e_thread] 线程在 Efficient-core (能效核) 上"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i9 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "超强芯生",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "英特尔® 酷睿™ i5 处理器",
                    "tr_gen_core_title_uppercase": "英特尔® 酷睿™ i5 处理器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "英特尔® 酷睿™ i7 处理器",
                    "tr_gen_core_title_uppercase": "英特尔® 酷睿™ i7 处理器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "英特尔® 酷睿™ i9 处理器",
                    "tr_gen_core_title_uppercase": "英特尔® 酷睿™ i9 处理器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>核</span></span>",
                    "tr_badgePreTitle": "[p_core] 个 Performance-core (性能核)",
                    "tr_badgeSubTitle": "[e_core] 个 Efficient-core (能效核)"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>线程</span></span>",
                    "tr_badgePreTitle": "[p_thread] 线程在 Performance-core (性能核) 上",
                    "tr_badgeSubTitle": "[e_thread] 线程在 Efficient-core (能效核) 上"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i9 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "提供可靠性能，专注处理更多任务",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "英特尔® 酷睿&#65279;™&nbsp;i3 处&#65279;理&#65279;器",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "提供真实世界性能专&#xfeff;注&#xfeff;处&#xfeff;理&#xfeff;更&#xfeff;多&#xfeff;任&#xfeff;务",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "英特尔® 酷睿&#65279;™&nbsp;i5 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "英特尔® 酷睿™ i5 处理器",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "提供真实世界性能专&#xfeff;注&#xfeff;处&#xfeff;理&#xfeff;更&#xfeff;多&#xfeff;任&#xfeff;务",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "英特尔® 酷睿&#65279;™&nbsp;i7 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "英特尔® 酷睿™ i7 处理器",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "超强芯生",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "英特尔® 酷睿™ i5 处理器",
                    "tr_gen_core_title_uppercase": "英特尔® 酷睿™ i5 处理器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "英特尔® 酷睿™ i7 处理器",
                    "tr_gen_core_title_uppercase": "英特尔® 酷睿™ i7 处理器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "英特尔® 酷睿™ i9 处理器",
                    "tr_gen_core_title_uppercase": "英特尔® 酷睿™ i9 处理器",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>核</span></span>",
                    "tr_badgePreTitle": "[p_core] 个 Performance-core (性能核)",
                    "tr_badgeSubTitle": "[e_core] 个 Efficient-core (能效核)"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>线程</span></span>",
                    "tr_badgePreTitle": "[p_thread] 线程在 Performance-core (性能核) 上",
                    "tr_badgeSubTitle": "[e_thread] 线程在 Efficient-core (能效核) 上"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i7 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ i9 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "提供可靠性能，专注处理更多任务",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "英特尔® 酷睿&#65279;™&nbsp;3 处&#65279;理&#65279;器",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;5 处&#65279;理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "提供真实世界性能专&#xfeff;注&#xfeff;处&#xfeff;理&#xfeff;更&#xfeff;多&#xfeff;任&#xfeff;务",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "英特尔® 酷睿&#65279;™&nbsp;5 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "英特尔® 酷睿™ 5 处理器",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™&nbsp;3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;5 处&#65279;理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "提供真实世界性能专&#xfeff;注&#xfeff;处&#xfeff;理&#xfeff;更&#xfeff;多&#xfeff;任&#xfeff;务",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "英特尔® 酷睿&#65279;™&nbsp;7 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "英特尔® 酷睿™ 7 处理器",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;3 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;5 处&#65279;理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;7 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "处理器",
                "tr_title": "无可挑剔的游戏体验",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "英特尔® 酷睿™ Ultra 5 处理器",
                    "tr_gen_core_title_uppercase": "英特尔® 酷睿™ ULTRA 5 处理器",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "英特尔® 酷睿™ Ultra 7 处理器",
                    "tr_gen_core_title_uppercase": "英特尔® 酷睿™ ULTRA 7 处理器",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "英特尔® 酷睿™ Ultra 9 处理器",
                    "tr_gen_core_title_uppercase": "英特尔® 酷睿™ ULTRA 9 处理器",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>核</span></span>",
                    "tr_badgePreTitle": "[p_core] 个 Performance-core (性能核)",
                    "tr_badgeSubTitle": "[e_core] 个 Efficient-core (能效核)",
                    "tr_badgeSubTitle1": "[l_core] 个 LPE 核心"
                  },
                  {
                    "tr_badgeTitle": "<span class='core-value'>[XX] <span class='core-text'>线程</span></span>",
                    "tr_badgePreTitle": "[p_thread] 线程在 Performance-core (性能核) 上",
                    "tr_badgeSubTitle": "[e_thread] 线程在 Efficient-core (能效核) 上",
                    "tr_badgeSubTitle1": "[l_thread] 条线程（在 LPE 核心上）"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ Ultra 5 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ Ultra 7 处理器",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "英特尔® 酷睿™ Ultra 9 处理器",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "无可挑剔的游戏体验",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "无可挑剔的游戏体验",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "无可挑剔的游戏体验",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "无可挑剔的游戏体验",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "无可挑剔的游戏体验",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "无可挑剔的游戏体验",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "小型企业应该具备大型业务解决方案",
                "tr_gen_core_title": "第 13 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i5 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;13&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i5 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "抑制噪音，虚化背景",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "自动调节性能，优先处理最常见任务",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "体验顺畅、高质量的网络通话",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "获得开箱即用的硬件级安全保障",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "帮助保护设备和数据免受物理篡改攻击",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "小型企业应该具备大型业务解决方案",
                "tr_gen_core_title": "第 13 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i7 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;13&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "抑制噪音，虚化背景",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "自动调节性能，优先处理最常见任务",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "体验顺畅、高质量的网络通话",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "获得开箱即用的硬件级安全保障",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "帮助保护设备和数据免受物理篡改攻击",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "小型企业应该具备大型业务解决方案",
                "tr_gen_core_title": "第 13 代英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™&nbsp;i9 处&#65279;理&#65279;器",
                "tr_gen_core_title_uppercase": "第&nbsp;13&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i9 处&#xfeff;理&#xfeff;器",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "此处理器的比较结果如何？",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "当前设备",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "抑制噪音，虚化背景",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "自动调节性能，优先处理最常见任务",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "体验顺畅、高质量的网络通话",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "获得开箱即用的硬件级安全保障",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "帮助保护设备和数据免受物理篡改攻击",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "英特尔® 酷睿™ Ultra，给你一颗 AI 的芯，开启全新商务体验",
                "tr_title": "蓄势待发，进击未来，尽在英&#xfeff;特&#xfeff;尔&#xfeff;® 酷&#xfeff;睿&#xfeff;™ Ultra&nbsp;[XX] 处理器和英&#xfeff;特&#xfeff;尔&#xfeff;® vPro®",
                "tr_gen_core_title": "英特尔® 酷睿™ Ultra [XX] 处理器",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "利用英特尔的 AI PC，提升您的企业效率",
                    "tr_badgeSubTitle": "能够提供卓越体验的强大电脑是企业生产力的核心所在。英特尔® vPro® 采用新架构，在正确的时间将正确的任务发送到正确的引擎，为各种复杂的业务工作负载提供卓越的节能性能，从而提高员工在工作负载和应用程序中的效率和绩效。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "开箱即用的多层安全功能",
                    "tr_badgeSubTitle": "需要全面的安全方法应对新型威胁。英特尔® vPro® 在硬件层面和整个堆栈中提供保护，从而更好地保护您的企业。这些独特的开箱即用安全措施，包括 AI 驱动威胁检测功能，有助于保护用户、数据和企业。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "内置工具，让用户几乎可以随时随地工作",
                    "tr_badgeSubTitle": "办公室无处不在，电脑也是如此。英特尔® vPro® 提供基于硬件的现代管理功能，帮助企业减轻电脑机群管理负担，简化支持，改善最终用户体验，同时实现可持续计算实践。",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "不仅仅是一台出色的电脑，更是一台 AI PC",
                "tr_subtitle1": "搭载英特尔®&nbsp;酷睿™ Ultra 处理器（系&#xfeff;列&nbsp;2）",
                "tr_subtitle2": "在英特尔 AI PC 上一切皆有可能",
                "tr_subtitle": "英特尔最高效、安全、高性能的移动式处理器<sup>39</sup>",
                "tr_gen_core_title": "英特尔®&nbsp;酷睿™ Ultra&nbsp;5 处理器（系&#xfeff;列&nbsp;2）",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>更具创造力</span> AI 工具为创作提速"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>更强生产力</span> AI 助理大幅提升效率"
                  },
                  {
                    "tr_title": "内置保护措施，<span class='spec-heading'>让数据更安全</span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "不仅仅是一台出色的电脑，更是一台 AI PC",
                "tr_subtitle1": "搭载英特尔®&nbsp;酷睿™ Ultra 处理器（系&#xfeff;列&nbsp;2）",
                "tr_subtitle2": "在英特尔 AI PC 上一切皆有可能",
                "tr_subtitle": "英特尔最高效、安全、高性能的移动式处理器<sup>39</sup>",
                "tr_gen_core_title": "英特尔®&nbsp;酷睿™ Ultra&nbsp;7 处理器（系&#xfeff;列&nbsp;2）",
                "titleArray": [
                  {
                    "tr_title": "<span class='spec-heading'>更具创造力</span> AI 工具为创作提速"
                  },
                  {
                    "tr_title": "<span class='spec-heading'>更强生产力</span> AI 助理大幅提升效率"
                  },
                  {
                    "tr_title": "内置保护措施，<span class='spec-heading'>让数据更安全</span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "不仅仅是一台出色的电脑，更是一台 AI PC",
                "tr_subtitle1": "搭载英特尔®&nbsp;酷睿™ Ultra 处理器（系&#xfeff;列&nbsp;2）",
                "tr_subtitle2": "在英特尔 AI PC 上一切皆有可能",
                "tr_subtitle": "英特尔最高效、安全、高性能的移动式处理器<sup>39</sup>",
                "tr_gen_core_title": "英特尔®&nbsp;酷睿™ Ultra&nbsp;5 处理器（系&#xfeff;列&nbsp;2）"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "处理器",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "不仅仅是一台出色的电脑，更是一台 AI PC",
                "tr_subtitle1": "搭载英特尔®&nbsp;酷睿™ Ultra 处理器（系&#xfeff;列&nbsp;2）",
                "tr_subtitle2": "在英特尔 AI PC 上一切皆有可能",
                "tr_subtitle": "英特尔最高效、安全、高性能的移动式处理器<sup>39</sup>",
                "tr_gen_core_title": "英特尔®&nbsp;酷睿™ Ultra&nbsp;7 处理器（系&#xfeff;列&nbsp;2）"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_tileTitle1": "独立显卡",
                "tr_title": "什么是图形处理单元？",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "图形处理单元 (GPU) 在屏幕上生成您所看到的画面。",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "集成 GPU",
                    "tr_badgeSubTitle": "集成 GPU 与电脑的其他部件共用同一块 RAM，能源效率更高。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "独立 GPU",
                    "tr_badgeSubTitle": "十分强大的 GPU 类型，自带内存及其他资源，专门用来实现非凡的图形显示性能。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "混合 GPU",
                    "tr_badgeSubTitle": "十分强大的 GPU 类型，自带内存及其他资源，专门用来实现非凡的图形显示性能。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "简化的照片和视频编辑",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "尽享 4K 视频内容",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "畅玩细节惊艳的流行游戏",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "双 GPU",
                    "tr_badgeSubTitle": "部分系统（如采用英特尔锐炬® X<sup class='small_text_sup_xe'>e</sup> 显卡的系统）可以同时利用处理器内置 GPU 和独立 GPU 来分配图形工作负载。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "集成 GPU",
                    "tr_badgeSubTitle": "集成 GPU 搭载在主处理器 (CPU) 所在的统一芯片上。集成 GPU 与计算机其他部件共用内存和其他资源，因此能效更高，可以让设备更薄更轻。",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "专用 GPU",
                    "tr_badgeSubTitle": "十分强大的 GPU 类型，自带内存及其他资源，专门用来实现非凡的图形显示性能。",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "标准",
                    "tr_badgeSubTitle": "高达 4 GB VRAM",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "高级",
                    "tr_badgeSubTitle": "高达 8 GB VRAM",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "超级",
                    "tr_badgeSubTitle": "大于 8 GB VRAM",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_title": "图形处理能力强大",
                "tr_pretitle": "[graphic_display]",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "要实现最强大的图形处理能力，需要使用显卡，专门用来生成清晰亮丽的图像和栩栩如生的动画。",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_title": "内置丰富生动的图形功能",
                "tr_pretitle": "英特尔锐炬® Plus 显卡",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "使用英特尔锐炬® Plus 显卡，无需使用额外显卡，即可打造身临其境的 4K HDR 视频和全高清游戏体验。",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_title": "超凡的视觉效果，轻松自如的性能表现",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "英特尔® 显卡提供变革性的集成显卡性能，可为创作者提供流畅的设计体验，为游戏玩家提供丰富的游戏体验。",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_title": "英特尔锐炬® Pro 显卡",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_title": "采用 X<sup class='small_text_sup_xe'>e</sup> 架构的英特尔® 超核芯显卡",
                "tr_pretitle": "沉浸式的视觉体验",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "体验简化的照片和视频编辑、尽享 4K 优质视频内容，或畅玩细节惊艳的流行游戏。",
                "tr_photo_text": "简化的照片和视频编辑",
                "tr_video_text": "尽享 4K 视频内容",
                "tr_game_text": "畅玩细节惊艳的流行游戏",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "远远超越美图概念",
                "tr_subtitle": "显卡（或 GPU）与处理器（或 CPU）密切协作，可以一同决定游戏性能和视效。VRAM 又称显卡内存，是显卡专用内存，也是了解 GPU 性能的捷径。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "游戏显卡性能强劲",
                "tr_subtitle": "要实现最强大的图形处理能力，需要使用独立显卡，专门用来生成清晰亮丽的图像和栩栩如生的动画",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "游戏显卡性能强劲",
                "tr_subtitle": "要实现最强大的图形处理能力，需要使用独立显卡，专门用来生成清晰亮丽的图像和栩栩如生的动画",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_title": "[graphic_display]",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "远远超越美图概念",
                "tr_subtitle": "显卡（或 GPU）与处理器（或 CPU）密切协作，可以一同决定游戏性能和视效。VRAM 又称显卡内存，是显卡专用内存，也是了解 GPU 性能的捷径。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_title": "图形处理能力强大",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "要实现最强大的图形处理能力，需要使用显卡，专门用来生成清晰亮丽的图像和栩栩如生的动画。",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "游戏显卡性能强劲",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "要实现最强大的图形处理能力，需要使用显卡，专门用来生成清晰亮丽的图像和栩栩如生的动画。",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "要实现最强大的图形处理能力，需要使用独立显卡，专门用来生成清晰亮丽的图像和栩栩如生的动画",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "显卡性能较强，可以满足基本的游戏要求。",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "适合希望享受竞技类游戏体验的高端游戏玩家",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "适合同时使用电脑完成繁重内容创作任务的高级游戏玩家",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "视频画面灵动，游戏体验如同身临其境",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "借助英特尔® 核芯显卡充分发挥高分辨率屏幕的绚丽效果，畅享丰富的多媒体体验和强大的内置显卡处理功能。",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "带来亮丽鲜活的视频和图像",
                "tr_pretitle": "[graphic_display]",
                "tr_subtitle": "直播视频和照片细节更逼真、色彩更亮丽。",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_title": "创作和游戏体验令人惊叹",
                "tr_pretitle": "英特尔锐&#65279;炬&#65279;®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;显&#65279;卡",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "从身临其境的娱乐体验和高阶内容创作到主流游戏，英特尔锐炬® X<sup class='small_text_sup_xe'>e</sup> 显卡赋予了轻薄型电脑颠覆性的图形性能。",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_pretitle": "游戏显卡性能强劲",
                "tr_title": "英特尔锐&#65279;炬&#65279;®&nbsp;X<sup class='small_text_sup_xe'>e</sup>&nbsp;显&#65279;卡",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "要实现最强大的图形处理能力，需要使用独立显卡，专门用来生成清晰亮丽的图像和栩栩如生的动画",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_title": "无限创意，尽情释放",
                "tr_pretitle": "英特尔锐炬® X<sup class='small_text_sup_xe'>e</sup> MAX 显卡",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "借助处理器内置显卡和英特尔® 独立显卡引擎的强大功能，如行云流水般完成创意项目，尽情畅玩多款游戏。",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "tr_title": "英特尔锐炬® X<sup class='small_text_sup_xe'>e</sup> MAX 显卡",
                "tr_pretitle": "游戏显卡性能强劲",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "要实现最强大的图形处理能力，需要使用独立显卡，专门用来生成清晰亮丽的图像和栩栩如生的动画",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[x] GB<br/></span><span class='subtitle-value'>VRAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "显卡",
                "tr_title": "内置显卡<br/>超炫视效",
                "tr_subtitle": "畅享细节极其丰富、画面极其清晰的游&#65279;戏&#65279;、&#65279;电&#65279;影&#65279;和&#65279;网&#65279;络&#65279;视&#65279;频&#65279;。",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显卡",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "显卡",
                    "tr_specText": "提升您的工作流程"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "显卡",
                    "tr_specText": "解锁超性能游戏体&#xfeff;验"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "显卡",
                    "tr_specText": "解锁超性能游戏体&#xfeff;验"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "显卡",
                    "tr_specText": "提升游戏表现"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "无限创意，尽情释放",
                    "tr_specText": "利用<strong>业界首创的 AV1 硬件编码</strong>和 AI 加速型内容创作等全方位的媒体功能，随时随地提升工作效率。<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "无限创意，尽情释放",
                    "tr_specText": "利用<strong>业界首创的 AV1 硬件编码</strong>和 AI 加速型内容创作等全方位的媒体功能，提升工作效率。<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "无限创意，尽情释放",
                    "tr_specText": "利用<strong>业界首创的 AV1 硬件编码</strong>和先进的 <strong>X<sup>e</sup> 媒体引擎</strong>，体验前沿 AI 加速型内容创作。<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "无限创意，尽情释放",
                    "tr_specText": "利用<strong>业界首创的 AV1 硬件编码</strong>和先进的 <strong>X<sup>e</sup> 媒体引擎</strong>，体验前沿 AI 加速型内容创作。<sup>22</sup>"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "无限创意，尽情释放",
                    "tr_specText": "利用<strong>业界首创的 AV1 硬件编码</strong>和先进的 <strong>X<sup>e</sup> 媒体引擎</strong>，体验前沿 AI 加速型内容创作。<sup>22</sup>"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "兼顾便携性与性能",
                    "tr_specText": "借助现代游戏功能，随时随地畅玩最新款游戏并实现超性能和保真度。<br/><br/><strong>X<sup>e</sup>SS（X<sup>e</sup> 超级采样）：</strong>采用英特尔® AI 增强型提升技术，带来性能更强劲和更流畅的游戏体验。<br/><br/><strong>DirectX* 12 Ultimate </strong> 支持 ：利用光线追踪和可变速率着色等显卡技术，让游戏逼真度更上一层楼。"
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "做游戏的主人",
                    "tr_specText": "<strong>英特尔锐炫™ 控制面板</strong>直观而又现代的布局，给您的体验带来提升。流畅无缝地获得驱动程序更新，优化游戏和其他程序。"
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "做游戏的主人",
                    "tr_specText": "<strong>英特尔锐炫™ 控制面板</strong>直观而又现代的布局，给您的体验带来提升。流畅无缝地获得驱动程序更新，优化游戏和其他程序。"
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "做游戏的主人",
                    "tr_specText": "利用<strong>英特尔锐炫™ 控制面板</strong>获取无缝驱动程序更新，优化您最爱的各种游戏。"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "强化英特尔® 平台优势",
                    "tr_specText": "与兼容的英特尔® 酷睿™ 处理器搭配，释放<strong>英特尔® Deep Link 技术</strong>，提升创作、游戏和直播体验。"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "最大限度地利用英&#xfeff;特&#xfeff;尔® 系统",
                    "tr_specText": "与兼容的英特尔® 酷睿™ 处理器搭配，释放<strong>英特尔® Deep Link 技术</strong>，提升创作、游戏和直播体验。"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "强化英特尔® 平台优势",
                    "tr_specText": "与兼容的英特尔® 酷睿™ 处理器搭配，释放<strong>英特尔® Deep Link 技术</strong>，提升创作、游戏和直播体验。"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "强化英特尔® 平台优势",
                    "tr_specText": "与兼容的英特尔® 酷睿™ 处理器搭配，释放<strong>英特尔® Deep Link 技术</strong>，提升创作、游戏和直播体验。"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "最大限度地利用英&#xfeff;特&#xfeff;尔® 系统",
                    "tr_specText": "与兼容的英特尔® 酷睿™ 处理器搭配，释放<strong>英特尔® Deep Link 技术</strong>，提升创作、游戏和直播体验。"
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "卓尔不群",
                    "tr_specText": "利用<strong>英特尔锐炫™ 控制面板</strong>软件支持的高级直播技术实现无缝广播。"
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "卓尔不群",
                    "tr_specText": "利用<strong>英特尔锐炫™ 控制面板</strong>软件支持的高级直播技术实现无缝广播。"
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "体验沉浸式高性能游戏",
                    "tr_specText": "以高分辨率和高刷新率运行最新 AAA 级游戏，以超强性能为先。<br/><br/><strong>X<sup>e</sup>SS（X<sup>e</sup> 超级采样）：</strong>采用英特尔® AI 增强型提升技术，带来性能更强劲和更流畅的游戏体验。<br/><br/><strong>DirectX* 12 Ultimate </strong> 支持 ：利用光线追踪和可变速率着色等显卡技术，让游戏逼真度更上一层楼。"
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "体验沉浸式高性能游戏",
                    "tr_specText": "以高分辨率和高刷新率运行最新 AAA 级游戏，以超强性能为先。<br/><br/><strong>X<sup>e</sup>SS（X<sup>e</sup> 超级采样）：</strong>采用英特尔® AI 增强型提升技术，带来性能更强劲和更流畅的游戏体验。<br/><br/><strong>DirectX* 12 Ultimate </strong> 支持 ：利用光线追踪和可变速率着色等显卡技术，让游戏逼真度更上一层楼。"
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "让游戏更精彩",
                    "tr_specText": "让游戏体验更上一层楼：<br/><br/><strong>X<sup>e</sup>SS（X<sup>e</sup> 超级采样）：</strong>采用英特尔® AI 增强型提升技术，带来性能更强劲和更流畅的游戏体验。<br/><br/><strong>DirectX* 12 Ultimate </strong> 支持 ：利用光线追踪和可变速率着色等显卡技术，让游戏逼真度更上一层楼。"
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "令人惊艳的游戏体验",
                    "tr_specText": "尽享流畅体验，畅玩众多热门 AAA 级游戏和高刷新率游戏，从高负载电竞射击游戏到快节奏的即时战略名作。<br/><br/><strong>X<sup>e</sup>SS（X<sup>e</sup> 超级采样）：</strong>采用英特尔® AI 增强型提升技术，带来性能更强劲和更流畅的游戏体验。<br/><br/><strong>DirectX* 12 Ultimate </strong> 支持 ：利用光线追踪和可变速率着色等显卡技术，让游戏逼真度更上一层楼。"
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "体验沉浸式高性能游戏",
                    "tr_specText": "以高分辨率和高刷新率运行最新 AAA 级游戏，以超强性能为先。<br/><br/><strong>X<sup>e</sup>SS（X<sup>e</sup> 超级采样）：</strong>采用英特尔® AI 增强型提升技术，带来性能更强劲和更流畅的游戏体验。<br/><br/><strong>DirectX* 12 Ultimate </strong> 支持 ：利用光线追踪和可变速率着色等显卡技术，让游戏逼真度更上一层楼。"
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "显卡",
                    "tr_specText": "显卡多元宇宙，满足多重需求"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "提升帧率，从而实现游戏加速",
                    "tr_specText": "利用 AI 增强的画质提升，英特尔® X<sup class='small_text_sup_xe'>e</sup> Super Sampling 技术 (X<sup class='small_text_sup_xe'>e</sup>SS) 可将您的游戏体验提升至全新水平，实现颠覆性能和高图像保真度。X<sup class='small_text_sup_xe'>e</sup>SS 已针对英特尔锐炫™ 显卡产品进行了优化，使您可以充分利用英特尔® X<sup class='small_text_sup_xe'>e</sup> Matrix Extensions（英特尔® XMX）硬件加速。"
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "畅享极致的视觉效果",
                    "tr_specText": "所有英特尔锐炫™ 显卡全面支持 DirectX* 12 Ultimate，同时利用光线追踪、可变速率着色、网状着色和采样器反馈等作为新一代游戏基本要素的最新显卡技术，让您畅享超凡的沉浸式游戏体验。"
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "体验无缝衔接的照片、视频和媒体创作",
                    "tr_specText": "选择由 AI 增强和英特尔® 技术加速的超先进媒体引擎开展丰富的数字内容创作，从而释放您的想象力，吸引受众。选择支持当前所有主流媒体格式的显卡，创作精彩内容，同时保持最先进的 AV1 视频编码功能。"
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "体验尖端的 AI 创作和游戏",
                    "tr_specText": "从高级创作到沉浸式游戏，英特尔锐炫™ 显卡可提供强大的 AI 功能。充分利用当今先进的创作软件，包括文本到图像生成和 AI 加速的视频编辑。此外，通过 AI 加速的 X<sup class='small_text_sup_xe'>e</sup>SS 画质提升，畅享高分辨率游戏体验。"
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "显卡",
                    "tr_specText": "专业显卡由此开始"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "非常适合移动办公的创意人员、设计师和工程师",
                    "tr_specText": "内置英特尔锐炫™ 专业版显卡在部分英特尔® 酷睿™ Ultra 处理器 H 系列上可用，且通过独立软件开发商 (ISV) 的认证，实现 2 倍的显卡性能提升，增加了可靠性，并提供高效的 AI 和光线追踪硬件加速，从而提供超凡的移动内容创作体验。<br/><br/><span class='expandable_heading'>软件认证<br/></span><br/>多年来，英特尔与数百家软件公司携手合作，并将此积累的非凡经验融入到我们的英特尔锐炫™ 专业版 A 系列显卡中。认证对我们和您一样重要。"
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "让 AI 触手可及",
                    "tr_specText": "这款 GPU 系列采用多项出色技术，包括为兼容 AI 工具提供支持，并根据需要让内置专用 AI 加速。"
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "以亲民价格实现光线追踪",
                    "tr_specText": "显卡需要做的不仅仅是将像素推送到显示屏，它们还需要加快精美图像的创作速度。英特尔锐炫™ 专业版 A 系列显卡中内置的光线追踪硬件技术，让您能够开始将兼容软件用于这些新的专业工作流程。人们通常认为只有昂贵的显卡才能提供光线追踪功能，但全新的英特尔专业显卡系列打破了这一观念。"
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "以提供沉浸式显示为创作者赋能",
                    "tr_specText": "所有英特尔锐炫™ 显卡全面支持 DirectX* 12 Ultimate，同时利用光线追踪、可变速率着色、网状着色和采样器反馈等作为新一代游戏基本要素的最新显卡技术，让您畅享超凡的沉浸式游戏体验。"
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "动态优化的图像",
                    "tr_specText": "借助专业 GPU 上的专用硬件解码，杜比视界* 有助于带来更深邃的层次、更鲜明的对比度和更丰富的色彩。杜比视界* 可以根据您的服务、显示设备和平台对图像质量进行动态优化，从而充分发挥 HDR 技术的潜力，每次都能产生惊艳的视觉效果。"
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "经专家验证的软件",
                    "tr_specText": "多年来，英特尔携手数百家软件公司开展密切合作，并将由此积累的非凡经验融入到专业英特尔锐炫™ 专业版 A 系列显卡中。各种认证和软件工作流资格对您很重要，对我们也同样重要。"
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "以亲民价格实现光线追踪",
                    "tr_specText": "显卡需要做的不仅仅是将像素推送到显示屏，它们还需要加快精美图像的创作速度。英特尔锐炫™ 专业版 A 系列显卡中内置的光线追踪硬件技术，让您能够开始将兼容软件用于这些新的专业工作流程。人们通常认为只有昂贵的显卡才能提供光线追踪功能，但全新的英特尔专业显卡系列打破了这一观念。"
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "一个小巧的移动端 GPU，可提供多个大尺寸显示屏的支持",
                    "tr_specText": "面向笔记本电脑的英特尔锐炫™ 专业版显卡可支持多达四个超大显示屏，从而提高您的工作流程效率。该 GPU 还添加了高动态范围 (HDR) 支持，无论您要设置玩转社交媒体的家用电脑，还是要设置办公室多屏显示环境，都能满足您的所有需求。"
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "全内存带宽潜力",
                    "tr_specText": "在工作站中，显卡带宽是一个重要考虑因素。如果在这方面失策，可能会降低专业工作的效率，正因为如此，我们确保英特尔锐炫™ 专业版 A 系列 GPU 能够在不影响向后兼容性的情况下支持现代 PCIe* 4.0。高带宽速度进一步增强了显卡内存，从而让软件访问项目数据的速度进一步加快。"
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "让每一帧都栩栩如生",
                    "tr_specText": "<span class='expandable_heading'>AI 加速。英特尔助力。<br/></span><br/>英特尔® X<sup class='small_text_sup_xe'>e</sup>SS 是一种 AI 增强的画质提升技术，可在不影响每一帧美感的情况下提升性能，让您获得本该有的游戏体验。"
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "实时<br/>沉浸<br/>逼真",
                    "tr_specText": "更逼真的世界等待着您。利用内置光线追踪技术，从更真实的阴影和反射到全局照明，您将获得丰富的体验。换句话说，这是您不容错过的游戏视觉体验。"
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "将您的精彩操作展现得淋漓尽致",
                    "tr_specText": "为您的受众带来值得的畅爽游戏体验。借助英特尔锐炫™ 显卡的硬件加速 AV1 编码功能，他们将被您的一举一动所吸引。相同比特率下的更高视觉质量，意味着在节省带宽的同时以最佳状态进行传输。"
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "欢迎所有创作者",
                    "tr_specText": "全新增强的 AI 体验触手可及。通过在英特尔锐炫™ A 系列显卡中内置先进的 AI 加速器，您可以在众多应用中花更多时间进行创作，花更少时间等待<br/><br/>借助英特尔锐炫™ 显卡内置的高级 AV1 硬件功能，将您的视频创作提升到新的水平，并以高质量、高存储效率的 AV1 视频引领潮流。"
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "操作系统",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "完成工作所需的一切",
                "tr_pretitle": "Windows* 10",
                "tr_subtitle": "超薄、轻巧且经久耐用的强大性能—您都可以拥有。凭借出色的电池续航，精美的触摸屏，*和最新的固态硬盘 (SSDs)，Windows 10 电脑可以满足您的期待。",
                "tr_noteSection": "*硬件支持",
                "titleArray": [
                  {
                    "tr_title": "电池续航",
                    "tr_subtitle": "您可以在设备上获得更长的使用寿命-平均八小时以上的视频播放时间。**",
                    "tr_noteSection": "**电池续航时间因设备、设置、使用情况和其他因素而存在较大差异。"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "操作系统",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "完成工作所需的一切",
                "tr_title": "Windows* 10",
                "tr_subtitle": "超薄、轻巧且经久耐用的强大性能—您都可以拥有。凭借出色的电池续航，精美的触摸屏，*和最新的固态硬盘 (SSDs)，Windows 10 电脑可以满足您的期待。",
                "tr_noteSection": "*硬件支持",
                "titleArray": [
                  {
                    "tr_title": "电池续航",
                    "tr_subtitle": "您可以在设备上获得更长的使用寿命-平均八小时以上的视频播放时间。**",
                    "tr_noteSection": "**电池续航时间因设备、设置、使用情况和其他因素而存在较大差异。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "操作系统",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "针对安全和性能进行了简化",
                "tr_pretitle": "WINDOWS* 10 S 模式",
                "tr_subtitle": "在处于 S 模式的 Windows* 10 平台上运行时，为用户增加了一层保护机制。用户可以放心使用设备工作、娱乐、探索，不用担心设备安全。"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "操作系统",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "针对安全和性能进行了改善",
                "tr_title": "WINDOWS* 10 S 模式",
                "tr_subtitle": "在处于 S 模式的 Windows* 10 平台上运行时，为用户增加了一层保护机制。用户可以放心使用设备工作、娱乐、探索，不用担心设备安全。"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "操作系统",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "成就大事",
                "tr_pretitle": "Windows*",
                "tr_subtitle": "性能卓越、价格优惠、选择丰富。从创新性的 2 合 1 电脑到轻薄型笔记本电脑、触摸屏、可拆卸键盘，无论您的预算或风格如何，总有一款 Windows* 电脑适合您。"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "操作系统",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "成就大事",
                "tr_title": "Windows*",
                "tr_subtitle": "性能卓越、价格优惠、选择丰富。从创新性的 2 合 1 电脑到轻薄型笔记本电脑、触摸屏、可拆卸键盘，无论您的预算或风格如何，总有一款 Windows* 电脑适合您。"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "操作系统",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "LINUX*",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "操作系统",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "内存",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "您需要多少 RAM？",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "日常 - 2 GB 到 8 GB RAM",
                    "tr_badgeSubTitle": "适合此选择的用户主要使用电脑进行文字处理和网上冲浪，往往一次主要使用一个程序。"
                  },
                  {
                    "tr_badgeTitle": "强劲 - 8 GB 至 16 GB RAM",
                    "tr_badgeSubTitle": "借助更多内存，应用可以加速运行，您也能够同时运行更多应用。"
                  },
                  {
                    "tr_badgeTitle": "高级 - 16 GB RAM 以上",
                    "tr_badgeSubTitle": "如果您对游戏、照片或视频编辑、3D 建模感兴趣，则需要更多 RAM 来处理这些高强度程序。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "内存",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM 用于日常多任务处理",
                "tr_subtitle": "选用您的内存后，基本应用程序（如文字处理和 Web 浏览）可以流畅地加载并运行。",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "内存",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM 用于强劲的多任务处理",
                "tr_subtitle": "如果您同时使用多个程序，使用 [memory_total] GB 内存可以让您轻松地来回切换。",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "内存",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[XX] GB RAM 用于更为复杂的多任务处理",
                "tr_subtitle": "内存充足，可同时运行多个工作负载较高的程序，而不会减慢电脑速度。",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>RAM</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "内存",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "[Value1] GB 内存 = [Value2] GB 英特尔® 傲腾™ 内存 + [Value3] GB RAM",
                "tr_subtitle": "此内存解决方案组合中的英特尔® 傲腾™ 内存可加速硬盘性能，RAM 可以提供更快的响应速度。",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[XX] GB<br/></span><span class='subtitle-value'>内存</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "英特尔® 傲腾™ 内存",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "等待时间更短。更多极致体验",
                "tr_subtitle": "当英特尔® 傲腾™ 内存与系统内存相结合时，会加快您访问常用程序的速度，以便它们根据您的需要即时启动和加载。",
                "tr_pretitle": "[XX] GB 英特尔® 傲腾™ 内存",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "电脑内存的功能是什么？"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "提高内容创作速度",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "提高游戏响应速度",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "更轻松地实现多任务处理",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "提高程序启动速度",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "英特尔® 傲腾™ 内存",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>加快游戏运行速度</span>",
                "tr_pretitle": "[XX] GB 英特尔® 傲腾™ 内存",
                "tr_pretitle1": "[XX] GB 英特尔® 傲腾™ 内存提升游戏体验",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "游戏启动速度<br/><span class=\"optane-game\">提升高达 98%</span><br/>使用数据硬盘，采用[optane_legal_processor_name] 处理器，配备英特尔® 傲腾™ 内&#65279;存&#65279;<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "关卡加载速度<br/><span class=\"optane-game\">提升高达 4.1 倍</span><br/>使用数据硬盘，采用[optane_legal_processor_name] 处理器，配备英特尔® 傲腾™ 内&#65279;存&#65279;<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "英特尔® 傲腾™ 内存",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "<span class='intelOptanMemoryTitle'>加快游戏运行速度</span>",
                "tr_pretitle": "[XX] GB 英特尔® 傲腾™ 内存",
                "tr_pretitle1": "[XX] GB 英特尔® 傲腾™ 内存提升游戏体验",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "游戏启动速度<br/><span class=\"optane-game\">提升高达 98%</span><br/>使用数据硬盘，采用[optane_legal_processor_name] 处理器，配备英特尔® 傲腾™ 内&#65279;存&#65279;<sup>15</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "关卡加载速度<br/><span class=\"optane-game\">提升高达 4.1 倍</span><br/>使用数据硬盘，采用[optane_legal_processor_name] 处理器，配备英特尔® 傲腾™ 内&#65279;存&#65279;<sup>16</sup>",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "内存",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "[Value1] GB 内存 = [Value2] GB 英特尔® 傲腾™ 内存 + [Value3] GB RAM",
                "tr_subtitle": "此内存解决方案组合中的英特尔® 傲腾™ 内存可加速硬盘性能，RAM 可以提供更快的响应速度。",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB RAM 用于多任务处理",
                    "tr_subtitle": "如果您同时使用多个程序，使用 [memory_total] GB 内存可以让您轻松地来回切换。"
                  },
                  {
                    "tr_title": "适用于日常任务",
                    "tr_subtitle": "选用您的内存后，基本应用程序（如文字处理和 Web 浏览）可以流畅地加载并运行。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "内存",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB RAM",
                "tr_pretitle": "提高多任务处理效率",
                "tr_subtitle": "RAM 更便于处理器访问程序文件，让 CPU 加快程序访问速度，一次处理多项任务。",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "标准内存",
                    "tr_badgeSubTitle": "高达 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "高级内存",
                    "tr_badgeSubTitle": "8~16 GB 的 RAM"
                  },
                  {
                    "tr_badgeTitle": "超级内存",
                    "tr_badgeSubTitle": "大于 16 GB RAM"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "内存",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "[XX] GB，可同时处理更多任务",
                "tr_pretitle": "提高多任务处理效率",
                "tr_subtitle": "RAM 更便于处理器访问程序文件，让 CPU 加快程序访问速度，一次处理多项任务。",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "标准内存",
                    "tr_badgeSubTitle": "高达 8 GB RAM"
                  },
                  {
                    "tr_badgeTitle": "高级内存",
                    "tr_badgeSubTitle": "8~16 GB 的 RAM"
                  },
                  {
                    "tr_badgeTitle": "超级内存",
                    "tr_badgeSubTitle": "大于 16 GB RAM"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "此内存的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "标准",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "高级",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "超级",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "内存",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "此内存的比较结果如何？",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "此内存的比较结果如何？",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "当前设备",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "日常",
                      "tr_badgeSubTitle": "<span class='memory-value'>8 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "强劲",
                      "tr_badgeSubTitle": "<span class='memory-value'>16 GB <span class='ram_value'>RAM</span></span>"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "高级",
                      "tr_badgeSubTitle": "<span class='memory-value'>32 GB <span class='ram_value'>RAM</span></span>"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "存储",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "电脑容量有多大？",
                "tr_subtitle": "无需猜测。这款电脑的存储量可以准确显示出来。",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>创建包含 </span><br/><span class=\"storage-standard\">[num_songs] 首歌曲的媒体库<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>基于每首歌平均 5 MB",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>创建包含</span> <br/><span class=\"storage-standard\">[num_games] 款游戏的游戏库<sup>†</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>†</sup>基于每款游戏平均 40 GB",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>包含</span> <br/><span class=\"storage-standard\">[num_videos] 个视频的存储<sup>§</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>§</sup>基于每个视频平均 5 GB",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "<span class='storage-preTile'>拍摄和存储</span> <br/><span class=\"storage-standard\">[num_photos] 张照片<sup>^</sup></span>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "<sup>^</sup>基于每张照片平均 4 MB",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "[capacity] GB&nbsp;普通硬盘"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "[capacity] GB&nbsp;固态硬盘"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "[total_optane_memory] GB 英特尔® 傲腾™ 内存 + [convertedSSD] GB&nbsp;固态硬盘"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "存储",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "[capacity] GB 固态硬盘存储",
                "tr_preTitle1": "[capacity] TB 固态硬盘存储",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "此电脑采用存储容量为 [capacity] GB 的固态硬盘。也就是说，您可以保存海量照片、视频和程序，而且访问速度比普通硬盘要快得多。",
                    "tr_title": "电脑上的所有内容都存储在同一个地方"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "此电脑采用存储容量为 [capacity] TB 的固态硬盘。也就是说，您可以保存海量照片、视频和程序，而且访问速度比普通硬盘要快得多。",
                    "tr_title": "这台设备拥有傲&#65279;人&#65279;的&#65279;存&#65279;储&#65279;空&#65279;间"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>存储</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>存储</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "固态硬盘",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "存储",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "[capacity] GB 普通硬盘存储",
                "tr_preTitle1": "[capacity] TB 普通硬盘存储",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "对于希望电脑上直接提供大量存储空间的人，普通硬盘是个不错的选择。",
                    "tr_title": "电脑上的所有内容都存储在同一个地方"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "对于希望电脑上直接提供大量存储空间的人，普通硬盘是个不错的选择。",
                    "tr_title": "这台设备拥有傲&#65279;人&#65279;的&#65279;存&#65279;储&#65279;空&#65279;间"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity]<br/></span><span class='subtitle-value'>存储</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "普通硬盘",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "存储",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "电脑容量有多大？",
                "tr_subtitle": "无需猜测。这款电脑的存储量可以准确显示出来。",
                "titleArray": [
                  {
                    "tr_title": "[XX] GB 普通硬盘"
                  },
                  {
                    "tr_title": "[XX] GB 固态硬盘"
                  },
                  {
                    "tr_title": "由 [optane_memory_size] GB 英特尔® 傲腾™ 内存实现加速的 [total_capacity] GB 英特尔® 固态硬盘"
                  },
                  {
                    "tr_title": "由 [optane_memory_size] GB 英特尔® 傲腾™ 内存加速的 [total_capacity] TB 英特尔® 固态硬盘"
                  },
                  {
                    "tr_title": "[convertedSSD] GB 固态硬盘 + [convertedHDD]&nbsp;GB&nbsp;普&#xfeff;通&#xfeff;硬&#xfeff;盘"
                  },
                  {
                    "tr_title": "[convertedSSD] TB 固态硬盘 + [convertedHDD]&nbsp;GB&nbsp;普&#xfeff;通&#xfeff;硬&#xfeff;盘"
                  },
                  {
                    "tr_title": "[convertedSSD] GB 固态硬盘 + [convertedHDD]&nbsp;TB&nbsp;普&#xfeff;通&#xfeff;硬&#xfeff;盘"
                  },
                  {
                    "tr_title": "[convertedSSD] TB 固态硬盘 + [convertedHDD]&nbsp;TB&nbsp;普&#xfeff;通&#xfeff;硬&#xfeff;盘"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>存储</span>",
                        "tr_badgeSubTitle": "固态硬盘"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>存储</span>",
                        "tr_badgeSubTitle": "普通硬盘"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>存储</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>固态硬盘<br/><span class='storage-seperator'> +  </span><br/>普通硬盘</span>"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>存储</span>",
                        "tr_badgeSubTitle": "固态硬盘"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>存储</span>",
                        "tr_badgeSubTitle": "普通硬盘"
                      },
                      {
                        "tr_badgeTitle": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>存储</span>",
                        "tr_badgeSubTitle": "<span class='subtitle-value'>固态硬盘<br/><span class='storage-seperator'> +  </span><br/>普通硬盘</span>"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "存储",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "[convertedSSD] GB 固态硬盘 + [convertedHDD] GB 普通硬盘存储",
                "tr_preTitle1": "[convertedSSD] TB 固态硬盘 + [convertedHDD] GB 普通硬盘存储",
                "tr_preTitle2": "[convertedSSD] GB 固态硬盘 + [convertedHDD] TB 普通硬盘存储",
                "tr_preTitle3": "[convertedSSD] TB 固态硬盘 + [convertedHDD] TB 普通硬盘存储",
                "titleArray": [
                  {
                    "tr_title": "电脑上的所有内容都存储在同一个地方",
                    "tr_title1": "这台设备拥有傲&#65279;人&#65279;的&#65279;存&#65279;储&#65279;空&#65279;间",
                    "tr_subtitle": "这款电脑采用存储容量为 [convertedSSD] GB 的固态硬盘和 [convertedHDD] GB 的普通硬盘。因此，兼具巨大的容量和高速存储性能。",
                    "tr_subtitle1": "这款电脑采用存储容量为 [convertedSSD] TB 的固态硬盘和 [convertedHDD] GB 的普通硬盘。因此，兼具巨大的容量和高速存储性能。",
                    "tr_subtitle2": "这款电脑采用存储容量为 [convertedSSD] GB 的固态硬盘和 [convertedHDD] TB 的普通硬盘。因此，兼具巨大的容量和高速存储性能。",
                    "tr_subtitle3": "这款电脑采用存储容量为 [convertedSSD] TB 的固态硬盘和 [convertedHDD] TB 的普通硬盘。因此，兼具巨大的容量和高速存储性能。"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "<span class='value'>[capacity] GB<br/></span><span class='subtitle-value'>存储</span>",
                    "tr_badgeTitle1": "<span class='value'>[capacity] TB<br/></span><span class='subtitle-value'>存储</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "<span class='subtitle-value'>固态硬盘<br/><span class='storage-seperator'> +  </span><br/>普通硬盘</span>",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "存储",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "借助英特尔® 傲腾™ 内存与固态硬盘，可以加速访问大容量存储设备。",
                "tr_title": "大型文件不受限制",
                "tr_pretitle": "由 [optane_memory_size] GB 英特尔® 傲腾™ 内存实现加速的 [total_capacity] GB 英特尔® 固态硬盘",
                "tr_pretitle1": "由 [optane_memory_size] GB 英特尔® 傲腾™ 内存加速的 [total_capacity] TB 英特尔® 固态硬盘",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "固态硬盘",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "它是一个革命性的存储解决方案，率先将英&#65279;特&#65279;尔&#65279;® 傲&#65279;腾&#65279;™ 内存与固态硬盘集成在一块芯片上。功能如下：",
                    "tr_title": "什么是英特尔® 傲腾™ 内存与固态硬盘？"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "快速打开所需内容",
                    "tr_badgeSubTitle": "通过预加载常用的文件和程序，您可以将更多的时间用于创作，等待时间也会减少。",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "兼顾容量与速度",
                    "tr_badgeSubTitle": "借助固态硬盘的快速性能，您可以存储媒体文件。",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "长期来看响应速度更快",
                    "tr_badgeSubTitle": "英特尔® 傲腾™ 内存搭配固态硬盘会学习用户的工作方式，提高电脑效率。",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "存储",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "有了大量可靠的存储空间，所有媒体内容触手可及。",
                "tr_title": "存储所有照片、视频和歌曲的空间"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显示器",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "[screenResolution] 万像素级，滑动、缩放自如，带来无限惊喜",
                "tr_subtitle": "像使用智能手机一样，通过直观的控件与电脑交互，享受更加锐丽、更为鲜活的细节。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal]”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "分辨率：[screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "像素：[screenResolution] 万"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] 英寸显示器",
                    "tr_badgeSubTitle": "屏幕高度：[screenHeight] 英寸",
                    "tr_badgePreTitle": "屏幕宽度：[YY] 英寸"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "触摸屏",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显示器",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "尽情享受 [screenResolution] 万像素带来的沉浸体验",
                "tr_subtitle": "一切都源于像素。像素越高，图像便越清晰、越生动。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "<span class='value'>[screenDiagonal] ”<br/></span><span class='subtitle-value'>[screenResolutionType]</span>",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "分辨率：[screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "像素：[screenResolution] 万"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] 英寸显示器",
                    "tr_badgeSubTitle": "屏幕高度：[screenHeight] 英寸",
                    "tr_badgePreTitle": "屏幕宽度：[YY] 英寸"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显示器",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "[screenDiagonal] 英寸 <br/>[screenResolutionX] x [screenResolutionY]",
                "tr_pretitle": "通往游戏世界的窗口",
                "tr_subtitle": "就像电视一样，游戏显示器主要看尺寸和分辨率。",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "分辨率：[screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "像素：[screenResolution] 万"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] 英寸显示器",
                    "tr_badgeSubTitle": "屏幕高度：[screenHeight] 英寸",
                    "tr_badgePreTitle": "屏幕宽度：[screenWidth] 英寸"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "触摸屏"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显示器",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "4K",
                "tr_title": "这台设备拥有傲&#65279;人&#65279;的&#65279;显&#65279;示&#65279;器",
                "tr_subtitle": "像素数量多达高清标准的 8 倍以上，以全新的清晰度和色彩水平呈现视频和游戏。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "4K",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "[screenResolutionType]",
                    "tr_badgeSubTitle": "分辨率：[screenResolutionX] X [screenResolutionY]",
                    "tr_badgePreTitle": "像素：[screenResolution] 万"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "[screenDiagonal] 英寸显示器",
                    "tr_badgeSubTitle": "屏幕高度：[screenHeight] 英寸",
                    "tr_badgePreTitle": "屏幕宽度：[YY] 英寸"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "显示器",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "[screenResolution] 万像素，方便轻触、滑动和触摸",
                    "tr_subTitle": "以更清晰、更生动的方式查看您喜欢的内容并与之进行交互。"
                  },
                  {
                    "tr_title": "[screenResolution] 万像素让您拥有精彩的数字世界",
                    "tr_subTitle": "一切都源于像素。像素越高，图像便越清晰、越生动。"
                  },
                  {
                    "tr_title": "这台设备拥有傲&#65279;人&#65279;的&#65279;显&#65279;示&#65279;器",
                    "tr_subTitle": "凭借接近 4 倍于高清显示的像素数，视频和游戏将呈现出<br/>全新水平的锐度和色彩。"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "连接",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "雷电技术 3 为任何扩展坞、显示器或数据设备提供快速、通用的连接。",
                "tr_title": "一个端口实现全部功能",
                "tr_title1": "一个端口实现全部功能",
                "titleArray": [
                  {
                    "tr_title": "我应该如何使用雷电技术 3？",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "雷电技术 3"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "4K 显示器",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "连接到分辨率、对比度和色彩令人惊叹的显示器"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "外部显卡",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "立即大大提升电脑的图形处理能力"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "高速网络",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "提供快速对等连接"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "连接",
                "detailBGUrl": "",
                "tr_subtitle": "雷电技术 3 的数据传输速度比传统 USB 快，允许您快速移动文件并轻松集成外设（例如，多个显示器或外接显卡）。",
                "tr_title": "雷电技术 3",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "快如闪电的数据",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 2.0",
                    "tr_badgeSubTitle": "480 Mbps",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "USB* 3.0",
                    "tr_badgeSubTitle": "5 Gbps",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "雷电技术 3",
                    "tr_badgeSubTitle": "40 Gbps",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "连接",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>一个通用端口、一根电缆即可连接所有常用配件</li> <li>笔记本电脑充电可靠</li> <li>强大认证令人安心</li></ul>",
                "tr_title": "真正通用的电缆连接",
                "tr_title1": "真正通用的电缆连接",
                "tr_noteSection": "<sup>†</sup> 按照基本规格要求",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "我如何使用雷电技术 4？",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "雷电技术 4"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "创作",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "外接显示器和连接高速存储设备，查看和编辑照片和视频。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "扩展坞",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "用一条电缆即可将笔记本电脑与所有配件连接起来，十分简便，而且桌面毫不凌乱。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "游戏",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "增加固态硬盘和普通硬盘容量，用于存储游戏库和媒体文件，速度最高可达 3,000 MB/s。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "巅峰性能<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "比 USB* 3.2 速度快 4 倍 <br/>比 HDMI* 2 快 2 倍"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "连接",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "实现与雷电技术 3 同样出色的性能，甚至还有更多其他功能，方便轻松连接和使用最新的雷电技术扩展坞、显示器、快速存储或任何 USB* 配件。",
                "tr_title": "雷电技术 4",
                "tr_pretitle": "真正通用的电缆连接",
                "tr_noteSection": "<sup>†</sup> 按照基本规格要求",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "只需一根雷电技术 4 通用电缆，即可代替所有 USB-C* 型电缆，电缆选择非常便捷。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "雷电技术 4 扩展坞提供多达 4 个雷电技术端口，更加灵活，可连接任何配件。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "巅峰性能<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "比 USB* 3.2 速度快 4 倍 <br/>比 HDMI* 2 快 2 倍"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "连接",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "<ul><li>一个通用端口、一根电缆即可连接所有常用配件</li> <li>笔记本电脑充电可靠</li> <li>强大认证令人安心</li></ul>",
                "tr_title": "借助雷电技术配件最&#65279;大&#65279;程&#65279;度&#65279;地&#65279;增&#65279;强您&#65279;的&#65279;体&#65279;验",
                "tr_noteSection": "<sup>†</sup> 按照基本规格要求",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "我如何使用雷电技术 4？",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "创作",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "外接显示器和连接高速存储设备，查看和编辑照片和视频。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "扩展坞",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "用一条电缆即可将笔记本电脑与所有配件连接起来，十分简便，而且桌面毫不凌乱。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "游戏",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "增加固态硬盘和普通硬盘容量，用于存储游戏库和媒体文件，速度最高可达 3,000 MB/s。"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "巅峰性能<sup>†</sup>",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "比 USB* 3.2 速度快 4 倍 <br/>比 HDMI* 2 快 2 倍"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "最常见的 Wi‑Fi 类型是：",
                "tr_title": "了解 WI‑FI 标准",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "即使在许多用户使用同一信号的情况下，Wi-Fi 6 也能实现更快的速度。<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "英特尔® Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "采用全新的英特尔 Wi-Fi 创新技术，即使多个用户使用同一信号，其连接速度也比千兆 Wi-Fi 快。<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "英特尔® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "Wi‑Fi 6E 是 Wi‑Fi 近 20 年来最大的进步，带来了全新高速信道，可提高性能和可靠性并减少干扰。"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "即使在许多用户使用同一信号的情况下，Wi-Fi 6 也能实现更快的速度。<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "英特尔® Killer™ Wi-Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "尽享 Wi-Fi 6E 与英特尔® Killer™ 优先引擎组合带来的诸多优势，将带宽优先分配给最需要它的那些网站和应用程序。"
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "Wi‑Fi 6E",
                    "tr_badgeSubTitle": "高速通道不再拥挤，性能和可靠性更上一层楼。"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "英特尔® Wi-Fi 6",
                    "tr_badgeSubTitle": "即使在许多用户使用同一信号的情况下，Wi-Fi 6 也能实现更快的速度。<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "英特尔® Killer™ Wi‑Fi 6 (Gig+)",
                    "tr_badgeSubTitle": "由于 Wi-Fi 6 的干扰更少，加上英特尔® Killer™ 优先引擎的智能性能，您的在线体验会变得更快、响应更灵敏⁶"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "高速通道不再拥挤，性能和可靠性更上一层楼。"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "英特尔® Wi-Fi 7 (5 Gig)",
                    "tr_badgeSubTitle": "5 Gbps 以上的电脑端 Wi-Fi 速度和一致的超低延迟——只需几秒即可共享文件，而无需几分钟。"
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "英特尔® Killer™ Wi-Fi 7",
                    "tr_badgeSubTitle": "英特尔® Killer™ Wi-Fi 7 (5 Gig) 是一项新技术，通过基于 AI 的网络优化，实现了“极致的”可靠性、低延迟和高速度。"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "即使在许多用户使用同一信号的情况下，Wi-Fi 6 也能实现更快的速度。<sup>9</sup>"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "英特尔® Wi‑Fi 6E (Gig+)",
                    "tr_badgeSubTitle": "英特尔® Wi-Fi 6E (Gig+) 速度超过 1 Gbps，可靠性高，且使用 6 GHz 信道以实现低延迟。"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "Wi‑Fi 6",
                    "tr_badgeSubTitle": "在拥挤的网络中，与 Wi-Fi 5 相比效率更高、容量更大、速度更快。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "利用 Wi‑Fi [XX] 改进了连接性能，提高了互联网连接速度<sup>†</sup> 和可靠性。",
                "tr_title": "提升范围和速度",
                "tr_noteSection": "<sup>†</sup> 实际的无线吞吐量和/或覆盖范围会有所不同。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi [XX]"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "在多人游戏和在线游戏时代，联网比以往任何时候都更重要。除了网速外，还需要无线信号，方便在距离较远时仍能保持同等速度。",
                "tr_title": "[XX]",
                "tr_pretitle": "联网至关重要",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "使用支持 160 MHz 信道的英特尔® Wi‑Fi 6 (Gig+) 连接至 Wi‑Fi 6 路由器，为更多的活跃用户提供更快的连接速度和更高的可靠性。",
                "tr_title": "使用英特尔® <span class='no-warp'>Wi&#8209;Fi&nbsp;6&nbsp;(Gig+) </span>速&#xfeff;度&#xfeff;提&#xfeff;升&#xfeff;近&nbsp;3&nbsp;倍&#xfeff;<sup>†</sup>",
                "tr_noteSection": "<sup>†</sup>与标准 AC Wi‑Fi 相比。部分功能仅在特定 SKU 中提供。请与制造商联系以了解详细信息。如欲了解更多有关 Wi‑Fi 的信息，请访问 intel.com/wifi6disclaimers"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "入门级双频英特尔® Wi-Fi 6 支持经改进的性能，可满足基本连接需求，例如在拥挤的网络上使用电子邮件和浏览互联网。<sup>9</sup>",
                "tr_title": "基础 Wi‑Fi",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "在多人游戏和在线游戏时代，联网比以往任何时候都更重要。除了网速外，还需要无线信号，方便在距离较远时仍能保持同等速度。",
                "tr_title": "[XX]",
                "tr_pretitle": "联网至关重要",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "借助这些全新的 Wi‑Fi 信道，您无需与周&#65279;围&#65279;的旧设备共享带宽，从而可实现流畅&#xfeff;直&#xfeff;播、快速浏览、无缝远程工作和学习&#xfeff;体&#xfeff;验&#xfeff;。",
                "tr_title": "独享连接",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "通过 [XX] 享受更优质的连接性能、更高速的互联网连接<sup>†</sup> 和更高的可靠性。",
                "tr_title": "提升范围和速度",
                "tr_noteSection": "<sup>†</sup> 实际的无线吞吐量和/或覆盖范围会有所不同。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "在多人游戏和在线游戏时代，联网比以往任何时候都更重要。除了网速外，还需要无线信号，方便在距离较远时仍能保持同等速度。",
                "tr_title": "[graphic_display]",
                "tr_pretitle": "联网至关重要",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "内置超快 WI‑FI",
                "tr_subtitle": "拔掉网线，仍然与您的在线世界保持连接。"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "借助可降低干扰的专用高速通道以及将游戏性能放在首位的英特尔® Killer™ 优先引擎，在线游戏性能获得了大幅提升。",
                "tr_title": "将竞争对手甩在身后",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "借助可降低干扰的专用高速通道以及将游戏性能放在首位的英特尔® Killer™ 优先引擎，在线游戏性能获得了大幅提升。",
                "tr_title": "[XX]",
                "tr_pretitle": "将竞争对手甩在身后",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "采用优先处理游戏任务的专用高速通道，降低干扰，真正提升网络游戏体验。",
                "tr_title": "[XX]",
                "tr_pretitle": "将竞争对手甩在身后",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "英特尔® Killer™ Wi-Fi 可自动为最需要加速网络流量的应用程序加速网络流量，让直播视频细节清晰、缓冲时间更短。",
                "tr_title": "更快、更智能的&#65279;连&#65279;接&#65279;方&#65279;式&#65279;。&#65279;⁶",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "在多人游戏和在线游戏时代，联网比以往任何时候都更重要。除了网速外，还需要无线信号，方便在距离较远时仍能保持同等速度。",
                "tr_title": "[XX]",
                "tr_pretitle": "联网至关重要",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "利用 Wi-Fi 5 改进了连接性能，提高了互联网连接速度<sup>†</sup> 和可靠性。",
                "tr_title": "提升范围和速度",
                "tr_noteSection": "<sup>†</sup> 实际的无线吞吐量和/或覆盖范围会有所不同。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "在多人游戏和在线游戏时代，联网比以往任何时候都更重要。除了网速外，还需要无线信号，方便在距离较远时仍能保持同等速度。",
                "tr_title": "[XX]",
                "tr_pretitle": "将竞争对手甩在身后",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "Wi-Fi 7 的功能将以前几代 Wi-Fi 产品为基础，并更上一层楼。其不仅将实现速度方面的提升，还将显着提高响应能力和可靠性，以支持沉浸式消费者体验和复杂的未来技术。",
                "tr_title": "媲美有线电脑网络",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "Wi-Fi 7 的功能将以前几代 Wi-Fi 产品为基础，并更上一层楼。其不仅将实现速度方面的提升，还将显着提高响应能力和可靠性，以支持沉浸式消费者体验和复杂的未来技术。",
                "tr_title": "[XX]",
                "tr_pretitle": "颠覆用户体验",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "通过基于 AI 的网络优化，提升速度、降低延迟并提高可靠性",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Wi‑Fi",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "通过基于 AI 的网络优化，提升速度、降低延迟并提高可靠性。",
                "tr_title": "[XX]",
                "tr_pretitle": "媲美有线的响应能力",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "Wi‑Fi 6E"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX]"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "电池",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "测量方式",
                    "tr_badgeSubTitle": "大部分制造商以小时来表示电池续航时间。尽管此计算不是 100% 准确，但它有助于比较不同电脑的电池续航时间。"
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "电池",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "随时随地畅玩游戏",
                "tr_subtitle": "如今，有了功能强大的游戏笔记本电脑，玩家无需被束缚在电脑桌旁。现在，随时随地都可以玩游戏。",
                "tr_title": "[Dynamic.battery.values.hours] 小时电池续航时间"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "电池",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "拔下电源插头后，仍可在数小时内与应用、游戏和音乐保持连接。",
                "tr_title": "一次充电可直播 [XX] 小时<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>基于电脑制造商规定的电池续航时间。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] 小时",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "电池",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "拔下电源插头后，仍可在数小时内与应用、游戏和音乐保持连接。",
                "tr_title": "旅途中可使用 [Dynamic.battery.values.hours]&nbsp;小&#65279;时&#65279;<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>基于电脑制造商规定的电池续航时间。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] 小时",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "电池",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "拔下电源插头后，仍可在数小时内与应用、游戏和音乐保持连接。",
                "tr_title": "一次充电可提供 [Dynamic.battery.values.hours] 小时的全天动力<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>基于电脑制造商规定的电池续航时间。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] 小时",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "电池",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "这款电脑优化了电池续航时间，更加持久耐用，充电 30 分钟至&#65279;少&#65279;续&#65279;航 4&#65279; 小&#65279;时&#65279;。<sup>4</sup>",
                "tr_title": "有了 [Dynamic.battery.values.hours] 小时的电池续航时间，您不插电使用的时间更长<sup class='sup-symbol'>†</sup>",
                "tr_noteSection": "<sup>†</sup>基于电脑制造商规定的电池续航时间。",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "[XX] 小时",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "首页",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "选择这款电脑的理由",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "查看所有规格",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "远程",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "移动",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "法律信息",
        "tr_paragraph_array": [
          "性能测试中使用的软件和工作负载可能仅针对英特尔® 微处理器进行了性能优化。SYSmark* 和 MobileMark* 等性能测试采用特定的计算机系统、组件和功能进行测试。上述任何要素的变动都有可能导致测试结果的变化。您应该参考其他信息和性能测试以帮助您全面评估您正在考虑的采购，包括产品在与其他产品结合使用时的性能。有关性能和基准测试结果的更完整信息，请访问 www.intel.cn/content/www/cn/zh/<wbr>benchmarks/benchmark.html。",
          "¹英特尔® Wi-Fi 6 (Gig+) 产品支持可选的 160 MHz 通道，从而使典型的 2x2 802.11ax PC Wi-Fi 产品可达到最快的理论最大速度 (2402 Mbps)。与标准 2x2 (1201 Mbps) 或 1x1 (600 Mbps) 802.11ax PC Wi-Fi 产品（仅支持 80 MHz 通道的强制要求）相比，高级英特尔® Wi-Fi 6 (Gig+) 产品可使最大理论速度加快 2 - 4 倍。",
          "²建议仅供参考。请在做出采购决策时参考其他信息来源。",
          "³英特尔技术的特性和优势取决于系统配置，可能需要支持的硬件、软件和服务激活。实际性能可能因系统配置的不同而有所差异。没有任何计算机系统能保证绝对安全。请咨询您的系统制造商或零售商，也可登录 intel.com 获取更多信息。",
          "⁴适用于配备 FHD 显示屏的系统进行无线网页浏览。关机时，从 OEM 默认关闭级别。",
          "⁵与其他 PC I/O 连接技术比较所得，包括 eSATA、USB 和 IEEE 1394* FireWire。系统运行性能将取决于具体的硬件与软件环境。必须使用支持雷电技术的设备。",
          "⁶提升近 3 倍：802.11ax 2x2 160 MHz 可实现 2402 Mbps 的最大理论数据速率。如 IEEE 802.11* 无线标准规范中所述，比标准 802.11ac 2x2 80 MHz (867 Mbps) Wi‑Fi 快约 3 倍，并且需要使用类似配置的 802.11ax 无线网络路由器。",
          "⁷ 延迟降低 75%：基于英特尔 802.11ax 模拟数据（79%，针对包含 OFDMA 和不包含 OFDMA 的情况，使用 9 个客户端）。不支持 OFDMA 的平均延迟为 36 毫秒，支持 OFDMA 的平均延迟缩短至 7.6 毫秒。延迟改进要求 802.11ax (Wi-Fi 6) 路由器和所有客户端都支持 OFDMA。",
          "⁸根据 802.11 规范中的理论最大数据速率比较，2x2 160 MHz Wi‑Fi 6/6E (802.11ax) 能达到 2402 Mbps，而 2x2 80 MHz Wi‑Fi 5 (802.11ac) 则只能达到 867 Mbps，相当于速度提高了 2.8 倍。",
          "⁹同类最佳的 Wi‑Fi 6：英特尔® Wi‑Fi 6 (Gig+) 产品支持可选的 160 MHz 通道，从而使典型 2x2 802.11ax PC Wi‑Fi 产品得以达到最快的理论最大速度 (2402 Mbps)。与标准 2x2 (1201 Mbps) 或 1x1 (600 Mbps) 802.11ax PC Wi‑Fi 产品（仅支持 80 MHz 通道的强制要求）相比，高级英特尔® Wi‑Fi 6 (Gig+) 产品可使最大理论速度加快 2 - 4 倍。",
          "仅适用于指定型号产品，功能根据 OEM 设计而有所不同。请向原始设备制造商或零售商确认详情。如欲了解更多 Wi‑Fi 信息，请访问 intel.com/wifi6disclaimers。",
          "¹⁰ 雷电技术 4 带宽高达 40 Gbps，与其它电脑 I/O 连接技术（包括 eSATA*、USB* 和 IEEE 1394* FireWire）相比，可为任何扩展坞、显示屏或数据设备提供非常快速、简单而又可靠的电缆解决方案。",
          "¹¹ 要求使用基于 802.11ax 并支持 OFDMA 的路由器，且网络上需要有多个支持 AX 的客户端。在密集的环境中，通过 802.11ax 客户端和接入点支持的 OFDMA 功能，可以实现更好的性能。基于 IEEE 802.11* 规范最大理论数据速率 (802.11ax 160 MHz 2402 Mbps) 的大约 70% 假设得出 2 Gbps。",
          "¹² 评测标准：在有后台活动工作负载的情况下启动文档，比较第 8 代英特尔® 酷睿™ i7-8565U 处理器（512 GB TLC 固态硬盘）与第 8 代英特尔® 酷睿™ i7-8565U 处理器（32 GB + 512 GB 英特尔® 傲腾™ 增强型 H10 SSD）。",
          "¹³ 英特尔® 硬件线程调度器内置于第 12 代英特尔® 酷睿™ 处理器中，有助于操作系统更智能地将工作负载引导至适当的内核。无需用户操作。访问 intel.com 了解详情。",
          "¹⁴ 某些第 12 代英特尔® 酷睿™ 处理器不提供。性能混合架构在单个处理器芯片上采用两个全新核心微架构：Performance-core (性能核) 和 Efficient-core (能效核)。部分第 12 代英特尔® 酷睿™ 处理器（部分第 12 代英特尔® 酷睿™ i5 处理器及更低端的型号）不支持性能混合架构，只支持 P-core (性能核)。",
          "¹⁵ 采用游戏启动工作负载为评测标准的测试结果，比较了第 8 代英&#xfeff;特&#xfeff;尔&#xfeff;® 酷&#xfeff;睿&#xfeff;™ i7-8750H 处理器（32 GB 英&#xfeff;特&#xfeff;尔&#xfeff;® 傲&#xfeff;腾&#xfeff;™ 内存组件 + 英&#xfeff;特&#xfeff;尔&#xfeff;® 固态硬盘 256 GB PCIe* + 1 TB 普通硬盘）与第 8 代英&#xfeff;特&#xfeff;尔&#xfeff;® 酷&#xfeff;睿&#xfeff;™ i7-8750H 处理器（英&#xfeff;特&#xfeff;尔&#xfeff;® 固态硬盘 256 GB PCIe* + 1 TB 普通硬盘）。游戏启动工作负载 - 工作负载由英特尔开发，测量启动《全面战争*：战锤* II》Build：5577.0 和进入主菜单（禁用启动时播放介绍视频）的时间。英&#xfeff;特&#xfeff;尔&#xfeff;® 傲&#xfeff;腾&#xfeff;™ 内存数据硬盘加速配置：英&#xfeff;特&#xfeff;尔&#xfeff;® 酷&#xfeff;睿&#xfeff;™ i7-8750H 处理器，PL1=45W TDP，6C12T，在未发布的 OEM 系统上睿频高达 4 GHz，显卡：NVIDIA* GeForce* GTX 1070，内存：2x4 GB DDR4，存储：英&#xfeff;特&#xfeff;尔&#xfeff;® 固态硬盘系列 760p 256 GB (PCIe*) + 1 TB 普通硬盘+ 32 GB 英&#xfeff;特&#xfeff;尔&#xfeff;® 傲&#xfeff;腾&#xfeff;™ 内存，操作系统：Windows* 10 RS3 Build 1709，MCU 0x84；英&#xfeff;特&#xfeff;尔&#xfeff;® 酷&#xfeff;睿&#xfeff;™ i7-8750H 处理器，PL1=45W TDP，6C12T，在未发布的 OEM 系统上睿频高达 4 GHz，显卡：NVIDIA* GeForce* GTX 1070，内存：2x4 GB DDR4，存储：英&#xfeff;特&#xfeff;尔&#xfeff;® 固态硬盘系列 760p 256 GB (PCIe*) + 1 TB 普通硬盘，操作系统：Windows* 10 RS3 Build 1709，MCU 0x84。",
          "¹⁶ 采用游戏关卡加载工作负载为评测标准的测试结果，比较了第 8 代英特尔® 酷睿™ i7-8750H 处理器（32 GB 英特尔® 傲腾™ 内存组件）与第 8 代英特尔® 酷睿™ i7-8750H 处理器（仅普通硬盘）。游戏关卡加载工作负载 — 工作负载由英特尔开发，测量《全面战争*：战锤* II》Build：5577.0 从主菜单显示到关卡加载完毕（关卡加载）的时间。英特尔® 傲腾™ 内存数据硬盘加速配置：英特尔® 酷睿™ i7-8750H 处理器，PL1=45W TDP，6C12T，在未发布的 OEM 系统上睿频高达 4 GHz，显卡：NVIDIA* GeForce* GTX 1070，内存：2x4 GB DDR4，存储：英特尔® 固态硬盘系列 760p 256 GB (PCIe*) + 1 TB 普通硬盘 + 32 GB 英特尔® 傲腾™ 内存，操作系统：Windows* 10 RS3 Build 1709，MCU 0x84；英特尔® 酷睿™ i7-8750H 处理器，PL1=45W TDP，6C12T，在未发布的 OEM 系统上睿频高达 4 GHz，显卡：NVIDIA* GeForce* GTX 1070，内存：2x4 GB DDR4，存储：英特尔® 固态硬盘系列 760p 256 GB (PCIe*) + 1 TB 普通硬盘，操作系统：Windows* 10 RS3 Build 1709，MCU 0x84。",
          "¹⁷在 P-core（性能核）上。性能因用途、配置和其他因素而异。请访问 www.intel.com/PerformanceIndex 了解详情。",
          "¹⁸更改时钟频率或电压可能会损坏处理器和其他系统组件，或者缩短它们的使用寿命，也可能会降低系统的稳定性和性能。如果处理器不在既定规格范围内操作，则不适用产品保修。请咨询您的系统和组件制造商，以了解更多详细信息。",
          "¹⁹基于以下测试标准：英特尔® vPro® 平台为各种规模的企业提供无与伦比的操作系统顶层和底层安全功能、应用程序和数据保护以及高级威胁防护，以及英特尔安全优先的产品设计、制造和支持方法。基于英特尔® vPro® 平台的所有商用电脑均已按照严格的规范进行验证，包括独特的硬件级安全功能。请参阅 www.intel.com/PerformanceIndex （平台）了解详情。没有任何产品或组件能够做到绝对安全。",
          "²⁰英特尔® Connectivity Performance Suite 仅在基于 Windows* 的系统上提供。英特尔 Over The Air (OTA) Wi-Fi 测试在拥塞网络上进行，对比未进行 ICPS 软件应用程序无线流量优先级排序和智能接入点交换的情景。请参阅 www.intel.com/PerformanceIndex（连接性）了解详细信息。结果可能会有所不同。",
          "²¹与上代处理器相比。",
          "²²英特尔锐炫™ 显卡是市面上首款支持 AV1 编码的产品（截至 2022 年第 1 季度）。详情请访问 intel.com/performanceindex。",
          "²³评测标准基于英特尔 Evo™ 设计的独特功能。这些设计也已经过认证，达到关键移动用户体验要求。测试结果截至 2022 年 2 月。",
          "²⁴英特尔® Unison™ 解决方案当前仅对在 Windows* 电脑上符合条件的英特尔 Evo™ 设计提供，只能与 Android 或 iOS 手机配对；所有设备必须运行支持的操作系统版本。",
          "²⁵最大内存速度与每个通道 1 个 DIMM (1DPC) 的配置相关。任何通道加装 DIMM 都可能会影响最大内存速度。特定 SKU 最高可支持 DDR5-5600 MT/s 1DPC UDIMM 1Rx8，1Rx16 和 DDR5-5200 1Rx8、1Rx16、2Rx8。使用 2DPC 配置，可实现最大内存容量。",
          "²⁶与标准 Wi-Fi 对比。详情请参阅 intel.com/performance-wireless。结果可能会有所不同。",
          "²⁷性能混合架构在第 12 代英特尔® 酷睿™ 处理器首次引入的单个处理器芯片上组合采用了两个核心微架构：Performance-core（性能核）和 Efficient-core（能效核）。部分第 12 代英特尔® 酷睿™ 处理器以及更新版本没有性能混合架构，只具有 P-core（性能核），并且高速缓存大小与上一代相同；有关 SKU 详细信息，请访问 ark.intel.com。",
          "²⁸英特尔® 硬件线程调度器内置于硬件中，仅在第 12 代英特尔® 酷睿™ 处理器或更新版本的性能混合架构配置中提供；需要操作系统支持。可用的特性和功能因操作系统而异。",
          "²⁹英特尔® 硬件线程调度器内置于硬件中，仅在第 12 代和第 13 代英特尔® 酷睿™ 处理器的性能混合架构配置中提供；需要操作系统支持。可用的特性和功能因操作系统而异。",
          "³⁰测量结果基于英特尔®&nbsp;酷睿™ Ultra 移动式处理器（系列 2 — 代号：Lunar Lake）与英特尔®&nbsp;酷睿™ Ultra 移动式处理器（系列 1 — 代号：Meteor Lake）参考验证平台在 3DMark Time Spy 上的性能对比。请参阅 intel.com/PerformanceIndex 了解详细信息。结果可能会有所不同。",
          "³¹背景模糊和人脸跟踪功能仅适用于基于 Windows* 的系统。",
          "³²Wi-Fi 7 产品可访问 6 GHz 的 320 MHz 信道和 5 GHz 的新 160 MHz 信道组合，并具有新的多资源单元穿孔功能。",
          "³³基于 IEEE 无线标准规范和双流设备的最大理论数据传输速率。",
          "³⁴英特尔对拥挤的网络环境所进行的工程模拟显示，新的 Wi-Fi 7 多链接操作功能可以显著降低延迟。",
          "³⁵根据原始设备制造商默认关机电量计算的最短充电时间。个别系统的结果可能会有所不同。请访问 intel.com/performance-evo 了解详情。",
          "³⁶6 GHz Wi-Fi 频段并非适用于所有国家/地区。",
          "³⁷仅限 Windows*",
          "³⁸根据原始设备制造商默认关机电量计算的充电时间。有关性能和基准测试结果的更完整信息，请访问 intel.com/Evo。",
          "³⁹指英特尔®&nbsp;酷睿™ Ultra 移动式处理器（系&#xfeff;列&nbsp;2），基于独特的硬件安全特性，具有出色的操作系统下层和上层安全功能组合，与上一代产品相比，即使在更低功耗下也能提供令人印象深刻的性能（截至 2024 年 9 月）。请参阅 intel.com/PerformanceIndex 了解详细信息。结果可能会有所不同。",
          "⁴⁰所述软件应用程序仅供说明用途。AI 功能可能需要购买或订阅额外软件，或由软件或平台提供商提供支持，或可能有特定配置或兼容性要求。详情请访问 www.intel.com/PerformanceIndex。结果可能会有所不同。© 英特尔公司。",
          "⁴¹所显示的图像可能已被更改或模拟。AI 功能可能需要购买或订阅额外软件，或由软件或平台提供商提供支持，或可能有特定配置或兼容性要求。详细信息请访问：www.intel.com/AIPC。",
          "⁴²在特定全球市场中，将逐步推出 Windows* 11 的最新更新预览版。推出时间因设备和市场而异。了解详情：aka.ms/WindowsAIFeatures。© 英特尔公司。",
          "⁴³英特尔® Unison™ 解决方案目前可用于符合条件的设计。请参阅 www.intel.com/PerformanceIndex 了解详细信息。",
          "⁴⁴经过英特尔® X<sup>e</sup>SS 改进的帧速率与原生帧速率，在英特尔® 酷睿™ Ultra 7 处理器 165H 上运行的 Dying Light 2 Stay Human 上的对比结果。请参阅 www.intel.com/PerformanceIndex 了解详细信息。结果可能会有所不同。",
          "⁴⁵基于英特尔®&nbsp;酷睿™ Ultra 移动式处理器较低 SoC 功耗的测量结果（系列 2 — 代号：Lunar Lake）参考验证平台与英特尔®&nbsp;酷睿™ Ultra 移动式处理器（系列 1 — 代号：Meteor Lake）YouTube 上的参考验证平台* 4K 30FPS AV1。请参阅 intel.com/PerformanceIndex 了解详细信息。结果可能会有所不同。",
          "本页性能数据基于截至 2020 年 8 月的测试结果，未必反映了可以公开获得的所有更新，测试细节请参考配置声明。英特尔技术需要相关的硬件，软件或者服务的激活。没有任何产品或者部件可以绝对安全。成本和结果可能有所差异。关于性能和基准测试程序结果的更多信息，请访问 intel.cn/Evo。",
          "<sup>△</sup>英特尔雅典娜创新笔记本项目的核心是对高端配置规格进行测试，针对核心体验指标进行严苛认证。所有测试是基于截止 2020 年 8 月的结果。英特尔所认证的是特定的产品机型，产品实际性能会基于使用情况，系统配置，电脑设置而有所变化，故英特尔无法保障因个体化而产生变化的电脑性能。关于性能和基准测试程序结果的更多信息，请访问 intel.cn/Evo。",
          "<sup>△△</sup>在 OEM 默认关机级别下关闭电源后充电 30 分钟可获得的最长续航时间。性能因使用、配置和其他因素而异。截至 2020 年 8 月的测试。",
          "对于价格方面的错误，英特尔概不负责。",
          "* 文中涉及的其它名称及商标属于各自所有者资产。",
          "版权所有 2022 Inflexion Games。《夜莺》、Inflexion Games 以及相关设计和综合标记是 Inflexion Studios Inc. 的商标。保留所有权利。",
          "英特尔技术可能需要支持的硬件、软件或服务激活。",
          "NERO* 提供的任何结果和数据均为方便起见，仅供参考。例如，英特尔公司对 NERO* 应用程序的准确性、性能结果和分数，或与 www.nero.com 或其他第三方网站上提供的该应用程序的其他可下载版本的潜在差异不承担任何责任。NERO* PC 基准测试分数采用特定计算机系统、组件、软件、操作和功能进行测试。上述任何要素的变动均有可能导致测试结果的变化。您应该参考其他信息和性能测试以帮助您全面评估您正在考虑的采购，包括产品在与其他产品结合使用时的性能。",
          "英特尔® vPro® 平台的所有版本均要求配备符合条件的英特尔® 酷睿™ 处理器、受支持操作系统、英特尔 LAN 和/或 WLAN 芯片、固件增强和其他必要的硬件和软件，以便实现平台特有的可管理性用例、安全功能、系统性能和稳定性。",
          "性能因用途、配置和其他因素而异。请访问 www.intel.com/PerformanceIndex 了解更多信息。",
          "英特尔不对第三方数据承担任何控制或审计的责任。您应参考其他信息来源以评估准确性。",
          "© 英特尔公司。英特尔、英特尔标志和其他英特尔标识是英特尔公司或其子公司的商标。* 文中涉及的其它名称及商标属于各自所有者资产。",
          "没有任何产品或组件能够保证绝对安全。",
          "您的成本和结果可能会有所不同。",
          "英特尔、英特尔标志、英特尔酷睿、英特尔傲腾、英特尔锐炬、雷电技术，以及雷电技术标志是英特尔公司或其子公司的商标。文中涉及的其它名称及商标属于各自所有者资产。",
          "© 英特尔公司"
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "首页",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "扫码比较",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "趋势分析",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "利用英特尔® 酷睿™ Ultra 处理器，解锁全新 AI 体验",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "利用英特尔® 酷睿™ Ultra 处理器，解锁全新 AI 体验",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "利用英特尔® 酷睿™ Ultra 处理器，解锁全新 AI 体验",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "Stable Diffusion*：文本转图像",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "Adobe*：照片编辑",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "英特尔® X<sup>e</sup>SS：画质提升",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "McAfee*: Deepfake 检测器",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "Microsoft* Copilot",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "协作效果 (Teams)",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "爱奇艺：手势控制",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "将电脑与手机同步，查看通知、通话和短信",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "内置丰富生动的图形功能",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "微软*：掌控生活，随心而行",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "微软*：唯你所属。一目了然，一划即达",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "Xbox* Game Pass Ultimate",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "通过微调系统，让处理器以更快的速度和更高的频率运行",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "提升桌面或移动系统性能，而无需牺牲存储空间",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "提升桌面或移动系统性能，而无需牺牲存储空间",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "三星 OLED：缓解视觉疲劳的福音",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "通过基于 AI 的网络优化，提升速度、降低延迟并提高可靠性",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "媲美有线电脑网络",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "媲美有线电脑网络",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "使用英特尔® Wi-Fi 6 (Gig+)，速度提升近 3 倍",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "独享连接",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "真正通用的电缆连接",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "像高手一样畅玩：了解英特尔如何为游戏和游戏玩家提供支持",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "像高手一样畅玩：英特尔成就冠军梦想",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "欢迎",
    "tr_welcomePagetrSubtitle": "使用您的智能手机来远程控制这台电脑",
    "tr_welcomePagembSubtitle": "欢迎使用您的智能手机查看此电脑的规格",
    "tr_welcomePageBtnText": "立即开始",
    "tr_welcomePageText": "在您的智能手机上了解和比较这台电脑的详细信息",
    "tr_scrollText": "在您前面的设备上导航",
    "tr_toolTipTextc1": "使用这些按钮可以导航至您面前这台设备上的不同页面。",
    "tr_toolTipTextc2": "点按以下卡片将导航到每一种设备规格的详细页面。",
    "tr_toolTipTextc3": "使用这些按钮可以在您面前的屏幕上下移动。",
    "tr_toolTipTextc4": "关闭远程模式以查看智能手机上的设备规格。",
    "tr_toolTipTextmb1": "点按卡片以查看详细内容页面。",
    "tr_toolTipTextmb2": "点按以查看所有设备规格的摘要",
    "tr_toolTipTextmb3": "点按按钮扫描二维码并比较最多 4 台设备",
    "tr_nextButton": "下一页",
    "tr_prevButton": "上一页",
    "tr_gotItButton": "知道了",
    "tr_skipButton": "跳过",
    "tr_placeholderText": "*占位符",
    "tr_processorHeading": "处理器",
    "tr_processorSubheading": "第&nbsp;10&nbsp;代英&#xfeff;特&#xfeff;尔&#xfeff;®&nbsp;酷&#xfeff;睿&#xfeff;™&nbsp;i7 处&#xfeff;理&#xfeff;器",
    "tr_memorySubheading": "[XX] GB",
    "tr_storageSubheading": "[XX] GB 英特尔® 傲腾™ 内存 + [YY] GB&nbsp;固态硬盘",
    "tr_coachmarkProcessor": "XX 处理器",
    "tr_coachmarkGraphics": "[XX] 显卡",
    "tr_coachmarkMemory": "规格价值",
    "tr_coachmarkOS": "[XX]",
    "tr_deviceName": "HP Envy 17T",
    "tr_price": "$ 999.95",
    "tr_compare": "比较",
    "tr_viewallspecs": "查看所有规格",
    "tr_operatingSystem": "操作系统",
    "tr_AmdProcessorNonIntel": "AMD* 处理器",
    "tr_QualcommProcessorNonIntel": "高通* 处理器",
    "tr_GBText": "GB",
    "tr_remoteText": "笔记本电脑屏幕上显示的 规格",
    "tr_scanErrorText": "您一次只能扫描最多 3 部设备。请删除至少一部设备。",
    "tr_scanErrorText2": "您已经扫描过这部设备，请扫描另一部。",
    "tr_scanMsg": "扫描以添加设备",
    "tr_backToScan": "返回以比较",
    "tr_scanToCompare": "扫描以比较",
    "tr_compareDevice": "比较设备",
    "tr_processorTitle": "处理器",
    "tr_graphicTitle": "显卡",
    "tr_storageTitle": "存储",
    "tr_displayTitle": "显示器",
    "tr_batteryTitle": "电池",
    "tr_memoryTitle": "内存",
    "tr_wifiTitle": "Wi‑Fi",
    "tr_connectivityTitle": "连接能力",
    "tr_priceTitle": "价格",
    "tr_operatingSystemTitle": "操作系统",
    "tr_batteryHoursText": "[XX] 小时",
    "tr_hrsText": "小时",
    "tr_touchscreeenText": "[screenDiagonal]” 触摸屏",
    "tr_HDText": "高清",
    "tr_FHDText": "全高清",
    "tr_4KText": "4K",
    "tr_HDTochScreenText": "高清，触摸屏",
    "tr_FHDTouchScreenText": "全高清，触摸屏",
    "tr_4KTouchscreenText": "4K，触摸屏",
    "tr_wifi_gigText": "(Gig+)",
    "tr_save": "保存",
    "tr_device_comparison": "设备比较文档 [mm]-[dd]-[yyyy]",
    "tr_Save_As_JPEG": "保存为 JPEG",
    "tr_Save_As_PDF": "保存为 PDF",
    "tr_Downloading_PDF": "下载 PDF",
    "tr_pdf_file": "[XXX].pdf",
    "tr_open": "打开",
    "tr_logo": "标志",
    "tr_laptop_name": "笔记本电脑名称",
    "tr_TBText": "TB",
    "tr_optaneMemoryTitle": "英特尔® 傲腾™ 内存",
    "tr_inchUnit": "英寸",
    "tr_wifi1": "Wi‑Fi 1",
    "tr_wifi2": "Wi‑Fi 2",
    "tr_wifi3": "Wi‑Fi 3",
    "tr_wifi4": "Wi‑Fi 4",
    "tr_wifi5": "Wi‑Fi 5",
    "tr_wifi6": "英特尔® Wi‑Fi 6 (Gig+)",
    "tr_wifi6Caps": "英特尔® Wi‑Fi 6 (GIG+)",
    "tr_wifi6e": "6E",
    "tr_wifi6eValue": "Wi‑Fi 6E",
    "tr_wifiXX": "Wi‑Fi [XX]",
    "tr_wifiXXCaps": "WI‑FI [XX]",
    "tr_wifi6E": "英特尔® Wi‑Fi 6E (Gig+)",
    "tr_IntelOptaneMemory": "英特尔® 傲腾™ 内存",
    "tr_Thunderbolt3": "雷电技术 3",
    "tr_Thunderbolt4": "雷电技术 4",
    "tr_processorGraphicsCaps": "处理器显卡",
    "tr_processorGraphicsSmall": "处理器显卡",
    "tr_graphicsCardCaps": "独立显卡",
    "tr_graphicsCardSmall": "独立显卡",
    "tr_processorTitleCamel": "处理器",
    "tr_graphicTitleCamel": "显卡",
    "tr_storageTitleCamel": "存储",
    "tr_displayTitleCamel": "显示器",
    "tr_batteryTitleCamel": "电池",
    "tr_memoryTitleCamel": "内存",
    "tr_connectivityTitleCamel": "连接",
    "tr_priceTitleCamel": "价格",
    "tr_operatingSystemTitleCamel": "操作系统",
    "tr_viewallspecsCamel": "查看所有规格",
    "tr_displayText": "[screenDiagonal]”",
    "tr_displayTextResolution": "[screenDiagonal]” [screenResolutionType]",
    "tr_displayTextResolutionTouchscreen": "[screenDiagonal]” [screenResolutionType] 触摸屏",
    "tr_OS": "操作系统",
    "tr_Chrome_OS": "Chrome* OS",
    "tr_PCSpecs_text": "电脑规格",
    "tr_explorePC_text": "了解有关此电脑的所有信息",
    "tr_mtlPC_text": "了解全能设计的真正含义",
    "tr_optaneMemory": "英特尔® 傲腾™ 内存",
    "tr_displayText_Display": "[screenDiagonal]” 显示器",
    "tr_displayTextResolution_Display": "[screenDiagonal]” [screenResolutionType] 显示器",
    "tr_displayTextResolutionTouchscreen_Display": "[screenDiagonal]” [screenResolutionType] 触屏显示器",
    "tr_displayTextTouchscreeenText": "[screenDiagonal]” 触屏显示器",
    "tr_learnMoreLabel": "了解详情",
    "tr_batteryHoursTextUpperCase": "[XX] 小时",
    "tr_processorGraphicsIrisXe": "第 11 代英特尔® 酷睿™ [cpu_fam] 处理器和英特尔锐炬® X<sup class='small_text_sup_xe'>e</sup> 显卡",
    "tr_processorGraphicsIrisXeMax": "第 11 代英特尔® 酷睿™ [cpu_fam] 处理器和英特尔锐炬® X<sup class='small_text_sup_xe'>e</sup> MAX 显卡",
    "tr_Thunderbolt3UpperCase": "雷电技术 3",
    "tr_Thunderbolt4UpperCase": "雷电技术 4",
    "tr_processorWithArcGraphics": "第 12 代英特尔® 酷睿™ [cpu_fam] 处理器和英特尔锐炫™ 显卡",
    "tr_processorGraphicsIrisXe12Gen": "第 12 代英特尔® 酷睿™ [cpu_fam] 处理器和英特尔锐炬® X<sup class='small_text_sup_xe'>e</sup> 显卡",
    "tr_processorGraphicsIrisXeMax12Gen": "第 12 代英特尔® 酷睿™ [cpu_fam] 处理器和英特尔锐炬® X<sup class='small_text_sup_xe'>e</sup> MAX 显卡",
    "tr_samsung_oled": "Samsung OLED",
    "tr_oled": "OLED",
    "tr_processorWithArcGraphics13Gen": "第 13 代英特尔® 酷睿™ [cpu_fam] 处理器和英特尔锐炫™ 显卡",
    "tr_processorGraphicsIrisXe13Gen": "第 13 代英特尔® 酷睿™ [cpu_fam] 处理器和英特尔锐炬® X<sup class='small_text_sup_xe'>e</sup> 显卡",
    "tr_processorGraphicsIrisXeMax13Gen": "第 13 代英特尔® 酷睿™ [cpu_fam] 处理器和英特尔锐炬® X<sup class='small_text_sup_xe'>e</sup> MAX 显卡",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "英特尔锐炫™ A350M 显卡",
      "tr_A370M": "英特尔锐炫™ A370M 显卡",
      "tr_A730M": "英特尔锐炫™ A730M 显卡",
      "tr_A770M": "英特尔锐炫™ A770M 显卡",
      "tr_A310": "英特尔锐炫™ A310 显卡",
      "tr_A380": "英特尔锐炫™ A380 显卡",
      "tr_A580": "英特尔锐炫™ A580 显卡",
      "tr_A750": "英特尔锐炫™ A750 显卡",
      "tr_A770": "英特尔锐炫™ A770 显卡"
    },
    "tr_memoryValue": "[XX] GB RAM",
    "tr_memoryWithOptaneDesc": "[Value1] GB 内存 = [Value2] GB 英特尔® 傲腾™ 内存 + [Value3] GB RAM",
    "tr_textGBGB": "[convertedSSD] GB 固态硬盘 + [convertedHDD]&nbsp;GB&nbsp;普&#xfeff;通&#xfeff;硬&#xfeff;盘",
    "tr_textGBTB": "[convertedSSD] GB 固态硬盘 + [convertedHDD]&nbsp;TB&nbsp;普&#xfeff;通&#xfeff;硬&#xfeff;盘",
    "tr_textTBGB": "[convertedSSD] TB 固态硬盘 + [convertedHDD]&nbsp;GB&nbsp;普&#xfeff;通&#xfeff;硬&#xfeff;盘",
    "tr_textTBTB": "[convertedSSD] TB 固态硬盘 + [convertedHDD]&nbsp;TB&nbsp;普&#xfeff;通&#xfeff;硬&#xfeff;盘",
    "tr_textGBSSD": "[convertedSSD] GB 固态硬盘",
    "tr_textTBSSD": "[convertedSSD] TB 固态硬盘",
    "tr_textGBHDD": "[convertedHDD] GB 普通硬盘",
    "tr_textTBHDD": "[convertedHDD] TB 普通硬盘",
    "tr_textStorageWithOptaneGB": "[XX] GB 英&#xfeff;特&#xfeff;尔&#xfeff;® 傲&#xfeff;腾&#xfeff;™ 内存 + [convertedSSD]&nbsp;GB&nbsp;固&#xfeff;态&#xfeff;硬&#xfeff;盘",
    "tr_textStorageWithOptaneTB": "[XX] GB 英&#xfeff;特&#xfeff;尔&#xfeff;® 傲&#xfeff;腾&#xfeff;™ 内存 + [convertedSSD]&nbsp;TB&nbsp;固&#xfeff;态&#xfeff;硬&#xfeff;盘",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "Windows* 7",
      "tr_OS2": "Windows* 7 家&#xfeff;庭&#xfeff;普&#xfeff;通&#xfeff;版",
      "tr_OS3": "Windows* 7 家&#xfeff;庭&#xfeff;高&#xfeff;级&#xfeff;版",
      "tr_OS4": "Windows* 7 专&#xfeff;业&#xfeff;版",
      "tr_OS5": "Windows* 7 企&#xfeff;业&#xfeff;版",
      "tr_OS6": "Windows* 7 旗&#xfeff;舰&#xfeff;版",
      "tr_OS7": "Windows* 8",
      "tr_OS8": "Windows* 8 专&#xfeff;业&#xfeff;版",
      "tr_OS9": "Windows* 8 企&#xfeff;业&#xfeff;版",
      "tr_OS10": "Windows* 8.1",
      "tr_OS11": "Windows* 8.1 专&#xfeff;业&#xfeff;版",
      "tr_OS12": "Windows* 8.1 企&#xfeff;业&#xfeff;版",
      "tr_OS13": "Windows* 8.1",
      "tr_OS14": "Windows* 10 家&#xfeff;庭&#xfeff;版",
      "tr_OS15": "Windows* 10 专&#xfeff;业&#xfeff;版",
      "tr_OS16": "Windows* 10 企&#xfeff;业&#xfeff;版",
      "tr_OS17": "Windows* 11 家&#xfeff;庭&#xfeff;版",
      "tr_OS18": "Windows* 11 专&#xfeff;业&#xfeff;版",
      "tr_OS19": "Windows* 11 企&#xfeff;业&#xfeff;版",
      "tr_OS20": "Windows* 10 家&#xfeff;庭&#xfeff;版 S 模式",
      "tr_OS21": "Windows* 10 专&#xfeff;业&#xfeff;版 S 模式",
      "tr_OS22": "Windows* 10 企&#xfeff;业&#xfeff;版 S 模式",
      "tr_OS23": "Windows* 11 家&#xfeff;庭&#xfeff;版 S 模式",
      "tr_OS24": "Windows* 11 专&#xfeff;业&#xfeff;版 S 模式",
      "tr_OS25": "Windows* 11 企&#xfeff;业&#xfeff;版 S 模&#xfeff;式"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "AMD* Mobility Radeon* 4100",
      "tr_GR2": "AMD* Mobility Radeon* HD 2400",
      "tr_GR3": "AMD* Mobility Radeon* HD 2400 XT",
      "tr_GR4": "AMD* Mobility Radeon* HD 2600",
      "tr_GR5": "AMD* Mobility Radeon* HD 2600 XT",
      "tr_GR6": "AMD* Mobility Radeon* HD 3400",
      "tr_GR7": "AMD* Mobility Radeon* HD 3430",
      "tr_GR8": "AMD* Mobility Radeon* HD 3650",
      "tr_GR9": "AMD* Mobility Radeon* HD 3670",
      "tr_GR10": "AMD* Mobility Radeon* HD 3850",
      "tr_GR11": "AMD* Mobility Radeon* HD 3850 X2",
      "tr_GR12": "AMD* Mobility Radeon* HD 3870",
      "tr_GR13": "AMD* Mobility Radeon* HD 3870 X2",
      "tr_GR14": "AMD* Mobility Radeon* HD 4200",
      "tr_GR15": "AMD* Mobility Radeon* HD 4300",
      "tr_GR16": "AMD* Mobility Radeon* HD 4330",
      "tr_GR17": "AMD* Mobility Radeon* HD 4500",
      "tr_GR18": "AMD* Mobility Radeon* HD 4650",
      "tr_GR19": "AMD* Mobility Radeon* HD 4670",
      "tr_GR20": "AMD* Mobility Radeon* HD 4830",
      "tr_GR21": "AMD* Mobility Radeon* HD 4850",
      "tr_GR22": "AMD* Mobility Radeon* HD 4860",
      "tr_GR23": "AMD* Mobility Radeon* HD 4870",
      "tr_GR24": "AMD* Mobility Radeon* HD 5000",
      "tr_GR25": "AMD* Mobility Radeon* HD 5570",
      "tr_GR26": "AMD* Mobility Radeon* HD 5800",
      "tr_GR27": "AMD* Mobility Radeon* HD 6000",
      "tr_GR28": "AMD* Radeon* HD 2350",
      "tr_GR29": "AMD* Radeon* HD 2400",
      "tr_GR30": "AMD* Radeon* HD 2400 Pro",
      "tr_GR31": "AMD* Radeon* HD 2400 XT",
      "tr_GR32": "AMD* Radeon* HD 2600",
      "tr_GR33": "AMD* Radeon* HD 2600 Pro",
      "tr_GR34": "AMD* Radeon* HD 2600 XT",
      "tr_GR35": "AMD* Radeon* HD 2900 GT",
      "tr_GR36": "AMD* Radeon* HD 2900 Pro",
      "tr_GR37": "AMD* Radeon* HD 2900 XT",
      "tr_GR38": "AMD* Radeon* HD 3200",
      "tr_GR39": "AMD* Radeon* HD 3300",
      "tr_GR40": "AMD* Radeon* HD 3450",
      "tr_GR41": "AMD* Radeon* HD 3470",
      "tr_GR42": "AMD* Radeon* HD 3600",
      "tr_GR43": "AMD* Radeon* HD 3650",
      "tr_GR44": "AMD* Radeon* HD 3830",
      "tr_GR45": "AMD* Radeon* HD 3850",
      "tr_GR46": "AMD* Radeon* HD 3850 X2",
      "tr_GR47": "AMD* Radeon* HD 3870",
      "tr_GR48": "AMD* Radeon* HD 3870 X2",
      "tr_GR49": "AMD* Radeon* HD 4200",
      "tr_GR50": "AMD* Radeon* HD 4250",
      "tr_GR51": "AMD* Radeon* HD 4290",
      "tr_GR52": "AMD* Radeon* HD 4300",
      "tr_GR53": "AMD* Radeon* HD 4550",
      "tr_GR54": "AMD* Radeon* HD 4600",
      "tr_GR55": "AMD* Radeon* HD 4700",
      "tr_GR56": "AMD* Radeon* HD 4770",
      "tr_GR57": "AMD* Radeon* HD 4800",
      "tr_GR58": "AMD* Radeon* HD 4850 X2",
      "tr_GR59": "AMD* Radeon* HD 4870 X2",
      "tr_GR60": "AMD* Radeon* HD 5000",
      "tr_GR61": "AMD* Radeon* HD 5400",
      "tr_GR62": "AMD* Radeon* HD 5450",
      "tr_GR63": "AMD* Radeon* HD 5470",
      "tr_GR64": "AMD* Radeon* HD 5490",
      "tr_GR65": "AMD* Radeon* HD 5500",
      "tr_GR66": "AMD* Radeon* HD 5530",
      "tr_GR67": "AMD* Radeon* HD 5570",
      "tr_GR68": "AMD* Radeon* HD 5600",
      "tr_GR69": "AMD* Radeon* HD 5630",
      "tr_GR70": "AMD* Mobility Radeon* 4100",
      "tr_GR71": "AMD* Radeon* HD 5690",
      "tr_GR72": "AMD* Radeon* HD 5700",
      "tr_GR73": "AMD* Radeon* HD 5730",
      "tr_GR74": "AMD* Radeon* HD 5800",
      "tr_GR75": "AMD* Radeon* HD 5850X2",
      "tr_GR76": "AMD* Radeon* HD 5900",
      "tr_GR77": "AMD* Radeon* HD 6230",
      "tr_GR78": "AMD* Radeon* HD 6250",
      "tr_GR79": "AMD* Radeon* HD 6290",
      "tr_GR80": "AMD* Radeon* HD 6300M",
      "tr_GR81": "AMD* Radeon* HD 6350",
      "tr_GR82": "AMD* Radeon* HD 6350A",
      "tr_GR83": "AMD* Radeon* HD 6390",
      "tr_GR84": "AMD* Radeon* HD 6400",
      "tr_GR85": "AMD* Radeon* HD 6430M",
      "tr_GR86": "AMD* Radeon* HD 6450",
      "tr_GR87": "AMD* Radeon* HD 6490",
      "tr_GR88": "AMD* Radeon* HD 6500",
      "tr_GR89": "AMD* Radeon* HD 6510",
      "tr_GR90": "AMD* Radeon* HD 6530",
      "tr_GR91": "AMD* Radeon* HD 6570",
      "tr_GR92": "AMD* Radeon* HD 6600A",
      "tr_GR93": "AMD* Radeon* HD 6610",
      "tr_GR94": "AMD* Radeon* HD 6670",
      "tr_GR95": "AMD* Radeon* HD 6700",
      "tr_GR96": "AMD* Radeon* HD 6700M",
      "tr_GR97": "AMD* Radeon* HD 6750",
      "tr_GR98": "AMD* Radeon* HD 6800",
      "tr_GR99": "AMD* Radeon* HD 6800M",
      "tr_GR100": "AMD* Radeon* HD 6850",
      "tr_GR101": "AMD* Radeon* HD 6850 X2",
      "tr_GR102": "AMD* Radeon* HD 6870",
      "tr_GR103": "AMD* Radeon* HD 6870 X2",
      "tr_GR104": "AMD* Radeon* HD 6900",
      "tr_GR105": "AMD* Radeon* HD 6900M",
      "tr_GR106": "AMD* Radeon* HD 7000",
      "tr_GR107": "AMD* Radeon* HD 7000M",
      "tr_GR108": "AMD* Radeon* HD 7300",
      "tr_GR109": "AMD* Radeon* HD 7350",
      "tr_GR110": "AMD* Radeon* HD 7400",
      "tr_GR111": "AMD* Radeon* HD 7400A",
      "tr_GR112": "AMD* Radeon* HD 7400G",
      "tr_GR113": "AMD* Radeon* HD 7400M",
      "tr_GR114": "AMD* Radeon* HD 7420G",
      "tr_GR115": "AMD* Radeon* HD 7450",
      "tr_GR116": "AMD* Radeon* HD 7450A",
      "tr_GR117": "AMD* Radeon* HD 7470",
      "tr_GR118": "AMD* Radeon* HD 7480D",
      "tr_GR119": "AMD* Radeon* HD 7500",
      "tr_GR120": "AMD* Radeon* HD 7500G",
      "tr_GR121": "AMD* Radeon* HD 7500M",
      "tr_GR122": "AMD* Radeon* HD 7510",
      "tr_GR123": "AMD* Radeon* HD 7520G",
      "tr_GR124": "AMD* Radeon* HD 7540D",
      "tr_GR125": "AMD* Radeon* HD 7560D",
      "tr_GR126": "AMD* Radeon* HD 7570",
      "tr_GR127": "AMD* Radeon* HD 7600",
      "tr_GR128": "AMD* Radeon* HD 7600A",
      "tr_GR129": "AMD* Radeon* HD 7600G",
      "tr_GR130": "AMD* Radeon* HD 7600M",
      "tr_GR131": "AMD* Radeon* HD 7620G",
      "tr_GR132": "AMD* Radeon* HD 7640G",
      "tr_GR133": "AMD* Radeon* HD 7660D",
      "tr_GR134": "AMD* Radeon* HD 7660G",
      "tr_GR135": "AMD* Radeon* HD 7670",
      "tr_GR136": "AMD* Radeon* HD 7670M",
      "tr_GR137": "AMD* Radeon* HD 7700",
      "tr_GR138": "AMD* Radeon* HD 7700M",
      "tr_GR139": "AMD* Radeon* HD 7720",
      "tr_GR140": "AMD* Radeon* HD 7800",
      "tr_GR141": "AMD* Radeon* HD 7800M",
      "tr_GR142": "AMD* Radeon* HD 7900",
      "tr_GR143": "AMD* Radeon* HD 7970M",
      "tr_GR144": "AMD* Radeon* HD 8180",
      "tr_GR145": "AMD* Radeon* HD 8200",
      "tr_GR146": "AMD* Radeon* HD 8210",
      "tr_GR147": "AMD* Radeon* HD 8210E",
      "tr_GR148": "AMD* Radeon* HD 8250",
      "tr_GR149": "AMD* Radeon* HD 8280E",
      "tr_GR150": "AMD* Radeon* HD 8310G",
      "tr_GR151": "AMD* Radeon* HD 8330",
      "tr_GR152": "AMD* Radeon* HD 8330E",
      "tr_GR153": "AMD* Radeon* HD 8350",
      "tr_GR154": "AMD* Radeon* HD 8350G",
      "tr_GR155": "AMD* Radeon* HD 8370D",
      "tr_GR156": "AMD* Radeon* HD 8400",
      "tr_GR157": "AMD* Radeon* HD 8400E",
      "tr_GR158": "AMD* Radeon* HD 8410G",
      "tr_GR159": "AMD* Radeon* HD 8450",
      "tr_GR160": "AMD* Radeon* HD 8450G",
      "tr_GR161": "AMD* Radeon* HD 8470",
      "tr_GR162": "AMD* Radeon* HD 8470D",
      "tr_GR163": "AMD* Radeon* HD 8490",
      "tr_GR164": "AMD* Radeon* HD 8500M",
      "tr_GR165": "AMD* Radeon* HD 8510",
      "tr_GR166": "AMD* Radeon* HD 8510G",
      "tr_GR167": "AMD* Radeon* HD 8550",
      "tr_GR168": "AMD* Radeon* HD 8550D",
      "tr_GR169": "AMD* Radeon* HD 8550G",
      "tr_GR170": "AMD* Radeon* HD 8570",
      "tr_GR171": "AMD* Radeon* HD 8570D",
      "tr_GR172": "AMD* Radeon* HD 8600",
      "tr_GR173": "AMD* Radeon* HD 8600M",
      "tr_GR174": "AMD* Radeon* HD 8610G",
      "tr_GR175": "AMD* Radeon* HD 8650D",
      "tr_GR176": "AMD* Radeon* HD 8650G",
      "tr_GR177": "AMD* Radeon* HD 8670D",
      "tr_GR178": "AMD* Radeon* HD 8730",
      "tr_GR179": "AMD* Radeon* HD 8760",
      "tr_GR180": "AMD* Radeon* HD 8770",
      "tr_GR181": "AMD* Radeon* HD 8790M",
      "tr_GR182": "AMD* Radeon* HD 8800M",
      "tr_GR183": "AMD* Radeon* HD 8870",
      "tr_GR184": "AMD* Radeon* HD 8950",
      "tr_GR185": "AMD* Radeon* HD 8970",
      "tr_GR186": "AMD* Radeon* HD 8970M",
      "tr_GR187": "AMD* Radeon* HD 8990",
      "tr_GR188": "AMD* Radeon* HD 9000",
      "tr_GR189": "AMD* Radeon* Pro 450",
      "tr_GR190": "AMD* Radeon* Pro 455",
      "tr_GR191": "AMD* Radeon* Pro 460",
      "tr_GR192": "AMD* Radeon* Pro 465",
      "tr_GR193": "AMD* Radeon* Pro Duo",
      "tr_GR194": "AMD* Radeon* Pro SSG",
      "tr_GR195": "AMD* Radeon* Pro V340",
      "tr_GR196": "AMD* Radeon* Pro V5300X",
      "tr_GR197": "AMD* Radeon* Pro V7300X",
      "tr_GR198": "AMD* Radeon* Pro V7350x2",
      "tr_GR199": "AMD* Radeon* Pro Vega 56",
      "tr_GR200": "AMD* Radeon* Pro VII",
      "tr_GR201": "AMD* Radeon* Pro W5500",
      "tr_GR202": "AMD* Radeon* Pro W5500M",
      "tr_GR203": "AMD* Radeon* Pro W5700",
      "tr_GR204": "AMD* Radeon* Pro W6300",
      "tr_GR205": "AMD* Radeon* Pro W6300M",
      "tr_GR206": "AMD* Radeon* Pro W6400",
      "tr_GR207": "AMD* Radeon* Pro W6500M",
      "tr_GR208": "AMD* Radeon* Pro W6600",
      "tr_GR209": "AMD* Radeon* Pro W6600M",
      "tr_GR210": "AMD* Radeon* Pro W6800",
      "tr_GR211": "AMD* Radeon* Pro W7500",
      "tr_GR212": "AMD* Radeon* Pro W7600",
      "tr_GR213": "AMD* Radeon* Pro W7700",
      "tr_GR214": "AMD* Radeon* Pro W7800",
      "tr_GR215": "AMD* Radeon* Pro W7900",
      "tr_GR216": "AMD* Radeon* Pro WX 2100",
      "tr_GR217": "AMD* Radeon* Pro WX 3100",
      "tr_GR218": "AMD* Radeon* Pro WX 3200",
      "tr_GR219": "AMD* Radeon* Pro WX 4100",
      "tr_GR220": "AMD* Radeon* Pro WX 4150",
      "tr_GR221": "AMD* Radeon* Pro WX 4170",
      "tr_GR222": "AMD* Radeon* Pro WX 5100",
      "tr_GR223": "AMD* Radeon* Pro WX 7100",
      "tr_GR224": "AMD* Radeon* Pro WX 8200",
      "tr_GR225": "AMD* Radeon* Pro WX 9100",
      "tr_GR226": "AMD* Radeon* R5 200",
      "tr_GR227": "AMD* Radeon* R5 220",
      "tr_GR228": "AMD* Radeon* R5 230",
      "tr_GR229": "AMD* Radeon* R5 235",
      "tr_GR230": "AMD* Radeon* R5 240",
      "tr_GR231": "AMD* Radeon* R5 340",
      "tr_GR232": "AMD* Radeon* R5 340X",
      "tr_GR233": "AMD* Radeon* R5 420",
      "tr_GR234": "AMD* Radeon* R5 430",
      "tr_GR235": "AMD* Radeon* R5 430M",
      "tr_GR236": "AMD* Radeon* R5 M200",
      "tr_GR237": "AMD* Radeon* R5 M230",
      "tr_GR238": "AMD* Radeon* R5 M240",
      "tr_GR239": "AMD* Radeon* R5 M255",
      "tr_GR240": "AMD* Radeon* R5 M315",
      "tr_GR241": "AMD* Radeon* R5 M320",
      "tr_GR242": "AMD* Radeon* R5 M330",
      "tr_GR243": "AMD* Radeon* R5 M335",
      "tr_GR244": "AMD* Radeon* R5 M430",
      "tr_GR245": "AMD* Radeon* R5 M435",
      "tr_GR246": "AMD* Radeon* R6 M255DX",
      "tr_GR247": "AMD* Radeon* R6 M335DX",
      "tr_GR248": "AMD* Radeon* R6 M340DX",
      "tr_GR249": "AMD* Radeon* R6 M435DX",
      "tr_GR250": "AMD* Radeon* R7 200",
      "tr_GR251": "AMD* Radeon* R7 240",
      "tr_GR252": "AMD* Radeon* R7 250",
      "tr_GR253": "AMD* Radeon* R7 250X",
      "tr_GR254": "AMD* Radeon* R7 265",
      "tr_GR255": "AMD* Radeon* R7 300",
      "tr_GR256": "AMD* Radeon* R7 340",
      "tr_GR257": "AMD* Radeon* R7 350",
      "tr_GR258": "AMD* Radeon* R7 350X",
      "tr_GR259": "AMD* Radeon* R7 360",
      "tr_GR260": "AMD* Radeon* R7 370",
      "tr_GR261": "AMD* Radeon* R7 430",
      "tr_GR262": "AMD* Radeon* R7 450",
      "tr_GR263": "AMD* Radeon* R7 M260",
      "tr_GR264": "AMD* Radeon* R7 M260DX",
      "tr_GR265": "AMD* Radeon* R7 M265",
      "tr_GR266": "AMD* Radeon* R7 M265DX",
      "tr_GR267": "AMD* Radeon* R7 M340",
      "tr_GR268": "AMD* Radeon* R7 M350",
      "tr_GR269": "AMD* Radeon* R7 M360",
      "tr_GR270": "AMD* Radeon* R7 M370",
      "tr_GR271": "AMD* Radeon* R7 M380",
      "tr_GR272": "AMD* Radeon* R7 M440",
      "tr_GR273": "AMD* Radeon* R7 M460",
      "tr_GR274": "AMD* Radeon* R7 M465",
      "tr_GR275": "AMD* Radeon* R7 M465X",
      "tr_GR276": "AMD* Radeon* R8 M350DX",
      "tr_GR277": "AMD* Radeon* R8 M435DX",
      "tr_GR278": "AMD* Radeon* R8 M535DX",
      "tr_GR279": "AMD* Radeon* R9 200",
      "tr_GR280": "AMD* Radeon* R9 255",
      "tr_GR281": "AMD* Radeon* R9 260",
      "tr_GR282": "AMD* Radeon* R9 270",
      "tr_GR283": "AMD* Radeon* R9 280",
      "tr_GR284": "AMD* Radeon* R9 290",
      "tr_GR285": "AMD* Radeon* R9 290X",
      "tr_GR286": "AMD* Radeon* R9 295X2",
      "tr_GR287": "AMD* Radeon* R9 360",
      "tr_GR288": "AMD* Radeon* R9 370",
      "tr_GR289": "AMD* Radeon* R9 370X",
      "tr_GR290": "AMD* Radeon* R9 380",
      "tr_GR291": "AMD* Radeon* R9 390",
      "tr_GR292": "AMD* Radeon* R9 390X",
      "tr_GR293": "AMD* Radeon* R9 Fury",
      "tr_GR294": "AMD* Radeon* R9 Fury X",
      "tr_GR295": "AMD* Radeon* R9 M200X",
      "tr_GR296": "AMD* Radeon* R9 M275X",
      "tr_GR297": "AMD* Radeon* R9 M280X",
      "tr_GR298": "AMD* Radeon* R9 M360",
      "tr_GR299": "AMD* Radeon* R9 M365X",
      "tr_GR300": "AMD* Radeon* R9 M370X",
      "tr_GR301": "AMD* Radeon* R9 M375",
      "tr_GR302": "AMD* Radeon* R9 M375X",
      "tr_GR303": "AMD* Radeon* R9 M380",
      "tr_GR304": "AMD* Radeon* R9 M385",
      "tr_GR305": "AMD* Radeon* R9 M385X",
      "tr_GR306": "AMD* Radeon* R9 M390X",
      "tr_GR307": "AMD* Radeon* R9 M395X",
      "tr_GR308": "AMD* Radeon* R9 M470X",
      "tr_GR309": "AMD* Radeon* RX 455",
      "tr_GR310": "AMD* Radeon* RX 460",
      "tr_GR311": "AMD* Radeon* RX 470",
      "tr_GR312": "AMD* Radeon* RX 480",
      "tr_GR313": "AMD* Radeon* RX 5300",
      "tr_GR314": "AMD* Radeon* RX 5300M",
      "tr_GR315": "AMD* Radeon* RX 540",
      "tr_GR316": "AMD* Radeon* RX 540X",
      "tr_GR317": "AMD* Radeon* RX 550",
      "tr_GR318": "AMD* Radeon* RX 5500",
      "tr_GR319": "AMD* Radeon* RX 5500 XT",
      "tr_GR320": "AMD* Radeon* RX 5500M",
      "tr_GR321": "AMD* Radeon* RX 550X",
      "tr_GR322": "AMD* Radeon* RX 560",
      "tr_GR323": "AMD* Radeon* RX 560 XT",
      "tr_GR324": "AMD* Radeon* RX 5600",
      "tr_GR325": "AMD* Radeon* RX 5600 OEM",
      "tr_GR326": "AMD* Radeon* RX 5600 XT",
      "tr_GR327": "AMD* Radeon* RX 5600M",
      "tr_GR328": "AMD* Radeon* RX 560X",
      "tr_GR329": "AMD* Radeon* RX 570",
      "tr_GR330": "AMD* Radeon* RX 5700",
      "tr_GR331": "AMD* Radeon* RX 5700 XT",
      "tr_GR332": "AMD* Radeon* RX 5700M",
      "tr_GR333": "AMD* Radeon* RX 570X",
      "tr_GR334": "AMD* Radeon* RX 580",
      "tr_GR335": "AMD* Radeon* RX 580X",
      "tr_GR336": "AMD* Radeon* RX 590",
      "tr_GR337": "AMD* Radeon* RX 590 GME",
      "tr_GR338": "AMD* Radeon* RX 6300",
      "tr_GR339": "AMD* Radeon* RX 6300M",
      "tr_GR340": "AMD* Radeon* RX 640",
      "tr_GR341": "AMD* Radeon* RX 6400",
      "tr_GR342": "AMD* Radeon* RX 6450M",
      "tr_GR343": "AMD* Radeon* RX 6500",
      "tr_GR344": "AMD* Radeon* RX 6500 XT",
      "tr_GR345": "AMD* Radeon* RX 6500M",
      "tr_GR346": "AMD* Radeon* RX 6550M",
      "tr_GR347": "AMD* Radeon* RX 6600",
      "tr_GR348": "AMD* Radeon* RX 6600 LE",
      "tr_GR349": "AMD* Radeon* RX 6600 XT",
      "tr_GR350": "AMD* Radeon* RX 6600M",
      "tr_GR351": "AMD* Radeon* RX 6600S",
      "tr_GR352": "AMD* Radeon* RX 6650 XT",
      "tr_GR353": "AMD* Radeon* RX 6650M",
      "tr_GR354": "AMD* Radeon* RX 6650M XT",
      "tr_GR355": "AMD* Radeon* RX 6700",
      "tr_GR356": "AMD* Radeon* RX 6700 XT",
      "tr_GR357": "AMD* Radeon* RX 6700M",
      "tr_GR358": "AMD* Radeon* RX 6700S",
      "tr_GR359": "AMD* Radeon* RX 6750 GRE",
      "tr_GR360": "AMD* Radeon* RX 6750 XT",
      "tr_GR361": "AMD* Radeon* RX 6800",
      "tr_GR362": "AMD* Radeon* RX 6800 XT",
      "tr_GR363": "AMD* Radeon* RX 6800M",
      "tr_GR364": "AMD* Radeon* RX 6800S",
      "tr_GR365": "AMD* Radeon* RX 6850M XT",
      "tr_GR366": "AMD* Radeon* RX 6900 XT",
      "tr_GR367": "AMD* Radeon* RX 6950 XT",
      "tr_GR368": "AMD* Radeon* RX 7600",
      "tr_GR369": "AMD* Radeon* RX 7600 XT",
      "tr_GR370": "AMD* Radeon* RX 7600M",
      "tr_GR371": "AMD* Radeon* RX 7600M XT",
      "tr_GR372": "AMD* Radeon* RX 7600S",
      "tr_GR373": "AMD* Radeon* RX 7700 XT",
      "tr_GR374": "AMD* Radeon* RX 7700S",
      "tr_GR375": "AMD* Radeon* RX 7800 XT",
      "tr_GR376": "AMD* Radeon* RX 7900 GRE",
      "tr_GR377": "AMD* Radeon* RX 7900 XT",
      "tr_GR378": "AMD* Radeon* RX 7900 XTX",
      "tr_GR379": "AMD* Radeon* RX 7900M",
      "tr_GR380": "AMD* Radeon* RX Vega",
      "tr_GR381": "AMD* Radeon* RX Vega 10",
      "tr_GR382": "AMD* Radeon* RX Vega 11",
      "tr_GR383": "AMD* Radeon* RX Vega 64",
      "tr_GR384": "AMD* Radeon* RX Vega M GH",
      "tr_GR385": "AMD* Radeon* RX Vega M GL",
      "tr_GR386": "英特尔锐炫™ A310 显卡",
      "tr_GR387": "英特尔锐炫™ A350M 显卡",
      "tr_GR388": "英特尔锐炫™ A370M 显卡",
      "tr_GR389": "英特尔锐炫™ A380 显卡",
      "tr_GR390": "英特尔锐炫™ A530M 显卡",
      "tr_GR391": "英特尔锐炫™ A550M 显卡",
      "tr_GR392": "英特尔锐炫™ A570M 显卡",
      "tr_GR393": "英特尔锐炫™ A580 显卡",
      "tr_GR394": "英特尔锐炫™ A730M 显卡",
      "tr_GR395": "英特尔锐炫™ A750 显卡",
      "tr_GR396": "英特尔锐炫™ A770 显卡",
      "tr_GR397": "英特尔锐炫™ A770M 显卡",
      "tr_GR398": "英特尔锐炫™ 显卡",
      "tr_GR399": "英特尔锐炫™ 专业版 A30M 显卡",
      "tr_GR400": "英特尔锐炫™ 专业版 A40/A50 显卡",
      "tr_GR401": "英特尔锐炫™ 专业版 A60 显卡",
      "tr_GR402": "英特尔锐炫™ 专业版 A60M 显卡",
      "tr_GR403": "英特尔® 显卡",
      "tr_GR404": "英特尔® 核芯显卡 2000",
      "tr_GR405": "英特尔® 核芯显卡 2500",
      "tr_GR406": "英特尔® 核芯显卡 3000",
      "tr_GR407": "英特尔® 核芯显卡 400",
      "tr_GR408": "英特尔® 核芯显卡 4000",
      "tr_GR409": "英特尔® 核芯显卡 405",
      "tr_GR410": "英特尔® 核芯显卡 4200",
      "tr_GR411": "英特尔® 核芯显卡 4400",
      "tr_GR412": "英特尔® 核芯显卡 4600",
      "tr_GR413": "英特尔® 核芯显卡 500",
      "tr_GR414": "英特尔® 核芯显卡 5000",
      "tr_GR415": "英特尔® 核芯显卡 505",
      "tr_GR416": "英特尔® 核芯显卡 510",
      "tr_GR417": "英特尔® 核芯显卡 515",
      "tr_GR418": "英特尔® 核芯显卡 520",
      "tr_GR419": "英特尔® 核芯显卡 530",
      "tr_GR420": "英特尔® 核芯显卡 5300",
      "tr_GR421": "英特尔® 核芯显卡 5500",
      "tr_GR422": "英特尔® 核芯显卡 5600",
      "tr_GR423": "英特尔® 核芯显卡 6000",
      "tr_GR424": "英特尔® 核芯显卡 610",
      "tr_GR425": "英特尔® 核芯显卡 615",
      "tr_GR426": "英特尔® 核芯显卡 620",
      "tr_GR427": "英特尔® 核芯显卡 630",
      "tr_GR428": "英特尔® 核芯显卡",
      "tr_GR429": "英特尔锐炬® 显卡 5100",
      "tr_GR430": "英特尔锐炬® 显卡 540",
      "tr_GR431": "英特尔锐炬® 显卡 550",
      "tr_GR432": "英特尔锐炬® 显卡 6100",
      "tr_GR433": "英特尔锐炬® Plus 显卡 640",
      "tr_GR434": "英特尔锐炬® Plus 显卡 645",
      "tr_GR435": "英特尔锐炬® Plus 显卡 650",
      "tr_GR436": "英特尔锐炬® Plus 显卡 655",
      "tr_GR437": "英特尔锐炬® Plus 显卡",
      "tr_GR438": "英特尔锐炬® Pro 显卡 5200",
      "tr_GR439": "英特尔锐炬® Pro 显卡 580",
      "tr_GR440": "英特尔锐炬® Pro 显卡 6200",
      "tr_GR441": "英特尔锐炬® X<sup>e</sup> 显卡",
      "tr_GR442": "英特尔锐炬® X<sup>e</sup> MAX 显卡 A220M",
      "tr_GR443": "英特尔锐炬® X<sup>e</sup> MAX 显卡",
      "tr_GR444": "英特尔® 集成显卡",
      "tr_GR445": "英特尔® 超核芯显卡 600",
      "tr_GR446": "英特尔® 超核芯显卡 605",
      "tr_GR447": "英特尔® 超核芯显卡 610",
      "tr_GR448": "英特尔® 超核芯显卡 615",
      "tr_GR449": "英特尔® 超核芯显卡 617",
      "tr_GR450": "英特尔® 超核芯显卡 620",
      "tr_GR451": "英特尔® 超核芯显卡 630",
      "tr_GR452": "英特尔® 超核芯显卡 710",
      "tr_GR453": "英特尔® 超核芯显卡 730",
      "tr_GR454": "英特尔® 超核芯显卡 770",
      "tr_GR455": "英特尔® 超核芯显卡",
      "tr_GR456": "英特尔® 超核芯显卡",
      "tr_GR457": "英特尔® 超核芯显卡",
      "tr_GR458": "NVIDIA* GeForce* MX110",
      "tr_GR459": "NVIDIA* GeForce* MX130",
      "tr_GR460": "NVIDIA* GeForce* MX150",
      "tr_GR461": "NVIDIA* GeForce* MX230",
      "tr_GR462": "NVIDIA* GeForce* MX250",
      "tr_GR463": "NVIDIA* GeForce* MX330",
      "tr_GR464": "NVIDIA* GeForce* MX350",
      "tr_GR465": "NVIDIA* GeForce* 410M",
      "tr_GR466": "NVIDIA* GeForce* MX450",
      "tr_GR467": "NVIDIA* GeForce* 510",
      "tr_GR468": "NVIDIA* GeForce* MX550",
      "tr_GR469": "NVIDIA* GeForce* MX570",
      "tr_GR470": "NVIDIA* GeForce* MX570 A",
      "tr_GR471": "NVIDIA* GeForce* 605",
      "tr_GR472": "NVIDIA* GeForce* 610",
      "tr_GR473": "NVIDIA* GeForce* 610M",
      "tr_GR474": "NVIDIA* GeForce* 615",
      "tr_GR475": "NVIDIA* GeForce* 620M",
      "tr_GR476": "NVIDIA* GeForce* 705A",
      "tr_GR477": "NVIDIA* GeForce* 705M",
      "tr_GR478": "NVIDIA* GeForce* 710A",
      "tr_GR479": "NVIDIA* GeForce* 710M",
      "tr_GR480": "NVIDIA* GeForce* 720A",
      "tr_GR481": "NVIDIA* GeForce* 720M",
      "tr_GR482": "NVIDIA* GeForce* 730A",
      "tr_GR483": "NVIDIA* GeForce* 800A",
      "tr_GR484": "NVIDIA* GeForce* 800M",
      "tr_GR485": "NVIDIA* GeForce* 805A",
      "tr_GR486": "NVIDIA* GeForce* 810A",
      "tr_GR487": "NVIDIA* GeForce* 810M",
      "tr_GR488": "NVIDIA* GeForce* 820A",
      "tr_GR489": "NVIDIA* GeForce* 820M",
      "tr_GR490": "NVIDIA* GeForce* 825M",
      "tr_GR491": "NVIDIA* GeForce* 830A",
      "tr_GR492": "NVIDIA* GeForce* 830M",
      "tr_GR493": "NVIDIA* GeForce* 840A",
      "tr_GR494": "NVIDIA* GeForce* 840M",
      "tr_GR495": "NVIDIA* GeForce* 845M",
      "tr_GR496": "NVIDIA* GeForce* 910M",
      "tr_GR497": "NVIDIA* GeForce* 920A",
      "tr_GR498": "NVIDIA* GeForce* 920M",
      "tr_GR499": "NVIDIA* GeForce* 920MX",
      "tr_GR500": "NVIDIA* GeForce* 930A",
      "tr_GR501": "NVIDIA* GeForce* 930M",
      "tr_GR502": "NVIDIA* GeForce* 930MX",
      "tr_GR503": "NVIDIA* GeForce* 940A",
      "tr_GR504": "NVIDIA* GeForce* 940M",
      "tr_GR505": "NVIDIA* GeForce* 940MX",
      "tr_GR506": "NVIDIA* GeForce* 945A",
      "tr_GR507": "NVIDIA* GeForce* 945M",
      "tr_GR508": "NVIDIA* GeForce* GT 1010",
      "tr_GR509": "NVIDIA* GeForce* GT 1030",
      "tr_GR510": "NVIDIA* GeForce* GT 415M",
      "tr_GR511": "NVIDIA* GeForce* GT 420",
      "tr_GR512": "NVIDIA* GeForce* GT 420M",
      "tr_GR513": "NVIDIA* GeForce* GT 425M",
      "tr_GR514": "NVIDIA* GeForce* GT 430",
      "tr_GR515": "NVIDIA* GeForce* GT 435M",
      "tr_GR516": "NVIDIA* GeForce* GT 440",
      "tr_GR517": "NVIDIA* GeForce* GT 445M",
      "tr_GR518": "NVIDIA* GeForce* GT 520",
      "tr_GR519": "NVIDIA* GeForce* GT 520M",
      "tr_GR520": "NVIDIA* GeForce* GT 520MX",
      "tr_GR521": "NVIDIA* GeForce* GT 525M",
      "tr_GR522": "NVIDIA* GeForce* GT 530",
      "tr_GR523": "NVIDIA* GeForce* GT 540M",
      "tr_GR524": "NVIDIA* GeForce* GT 545",
      "tr_GR525": "NVIDIA* GeForce* GT 550M",
      "tr_GR526": "NVIDIA* GeForce* GT 555M",
      "tr_GR527": "NVIDIA* GeForce* GT 610",
      "tr_GR528": "NVIDIA* GeForce* GT 620",
      "tr_GR529": "NVIDIA* GeForce* GT 620M",
      "tr_GR530": "NVIDIA* GeForce* GT 625",
      "tr_GR531": "NVIDIA* GeForce* GT 625M",
      "tr_GR532": "NVIDIA* GeForce* GT 630",
      "tr_GR533": "NVIDIA* GeForce* GT 630M",
      "tr_GR534": "NVIDIA* GeForce* GT 635",
      "tr_GR535": "NVIDIA* GeForce* GT 635M",
      "tr_GR536": "NVIDIA* GeForce* GT 640",
      "tr_GR537": "NVIDIA* GeForce* GT 640M",
      "tr_GR538": "NVIDIA* GeForce* GT 640M LE",
      "tr_GR539": "NVIDIA* GeForce* GT 645",
      "tr_GR540": "NVIDIA* GeForce* GT 645M",
      "tr_GR541": "NVIDIA* GeForce* GT 650M",
      "tr_GR542": "NVIDIA* GeForce* GT 705",
      "tr_GR543": "NVIDIA* GeForce* GT 710",
      "tr_GR544": "NVIDIA* GeForce* GT 710M",
      "tr_GR545": "NVIDIA* GeForce* GT 720",
      "tr_GR546": "NVIDIA* GeForce* GT 720A",
      "tr_GR547": "NVIDIA* GeForce* GT 720M",
      "tr_GR548": "NVIDIA* GeForce* GT 730",
      "tr_GR549": "NVIDIA* GeForce* GT 730M",
      "tr_GR550": "NVIDIA* GeForce* GT 735M",
      "tr_GR551": "NVIDIA* GeForce* GT 740",
      "tr_GR552": "NVIDIA* GeForce* GT 740A",
      "tr_GR553": "NVIDIA* GeForce* GT 740M",
      "tr_GR554": "NVIDIA* GeForce* GT 745A",
      "tr_GR555": "NVIDIA* GeForce* GT 745M",
      "tr_GR556": "NVIDIA* GeForce* GT 750M",
      "tr_GR557": "NVIDIA* GeForce* GT 755M",
      "tr_GR558": "NVIDIA* GeForce* GT 820M",
      "tr_GR559": "NVIDIA* GeForce* GTS 450",
      "tr_GR560": "NVIDIA* GeForce* GTX 1050",
      "tr_GR561": "采用 Max-Q 设计的 NVIDIA* GeForce* GTX 1050",
      "tr_GR562": "NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR563": "采用 Max-Q 设计的 NVIDIA* GeForce* GTX 1050 Ti",
      "tr_GR564": "NVIDIA* GeForce* GTX 1060",
      "tr_GR565": "采用 Max-Q 设计的 NVIDIA* GeForce* GTX 1060",
      "tr_GR566": "NVIDIA* GeForce* GTX 1070",
      "tr_GR567": "采用 Max-Q 设计的 NVIDIA* GeForce* GTX 1070",
      "tr_GR568": "NVIDIA* GeForce* GTX 1070 Ti",
      "tr_GR569": "NVIDIA* GeForce* GTX 1080",
      "tr_GR570": "采用 Max-Q 设计的 NVIDIA* GeForce* GTX 1080",
      "tr_GR571": "NVIDIA* GeForce* GTX 1080 Ti",
      "tr_GR572": "NVIDIA* GeForce* GTX 1630",
      "tr_GR573": "NVIDIA* GeForce* GTX 1650",
      "tr_GR574": "采用 Max-Q 设计的 NVIDIA* GeForce* GTX 1650",
      "tr_GR575": "NVIDIA* GeForce* GTX 1650 SUPER",
      "tr_GR576": "NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR577": "采用 Max-Q 设计的 NVIDIA* GeForce* GTX 1650 Ti",
      "tr_GR578": "NVIDIA* GeForce* GTX 1660",
      "tr_GR579": "NVIDIA* GeForce* GTX 1660 SUPER",
      "tr_GR580": "NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR581": "采用 Max-Q 设计的 NVIDIA* GeForce* GTX 1660 Ti",
      "tr_GR582": "NVIDIA* GeForce* GTX 460",
      "tr_GR583": "NVIDIA* GeForce* GTX 460 SE",
      "tr_GR584": "NVIDIA* GeForce* GTX 460 v2",
      "tr_GR585": "NVIDIA* GeForce* GTX 460M",
      "tr_GR586": "NVIDIA* GeForce* GTX 465",
      "tr_GR587": "NVIDIA* GeForce* GTX 470",
      "tr_GR588": "NVIDIA* GeForce* GTX 470M",
      "tr_GR589": "NVIDIA* GeForce* GTX 480",
      "tr_GR590": "NVIDIA* GeForce* GTX 480M",
      "tr_GR591": "NVIDIA* GeForce* GTX 485M",
      "tr_GR592": "NVIDIA* GeForce* GTX 550 Ti",
      "tr_GR593": "NVIDIA* GeForce* GTX 555",
      "tr_GR594": "NVIDIA* GeForce* GTX 560",
      "tr_GR595": "NVIDIA* GeForce* GTX 560 SE",
      "tr_GR596": "NVIDIA* GeForce* GTX 560 Ti",
      "tr_GR597": "NVIDIA* GeForce* GTX 560M",
      "tr_GR598": "NVIDIA* GeForce* GTX 570",
      "tr_GR599": "NVIDIA* GeForce* GTX 570M",
      "tr_GR600": "NVIDIA* GeForce* GTX 580",
      "tr_GR601": "NVIDIA* GeForce* GTX 580M",
      "tr_GR602": "NVIDIA* GeForce* GTX 590",
      "tr_GR603": "NVIDIA* GeForce* GTX 645",
      "tr_GR604": "NVIDIA* GeForce* GTX 650",
      "tr_GR605": "NVIDIA* GeForce* GTX 650 Ti",
      "tr_GR606": "NVIDIA* GeForce* GTX 660",
      "tr_GR607": "NVIDIA* GeForce* GTX 660 Ti",
      "tr_GR608": "NVIDIA* GeForce* GTX 660M",
      "tr_GR609": "NVIDIA* GeForce* GTX 670",
      "tr_GR610": "NVIDIA* GeForce* GTX 670M",
      "tr_GR611": "NVIDIA* GeForce* GTX 670MX",
      "tr_GR612": "NVIDIA* GeForce* GTX 675M",
      "tr_GR613": "NVIDIA* GeForce* GTX 675MX",
      "tr_GR614": "NVIDIA* GeForce* GTX 680",
      "tr_GR615": "NVIDIA* GeForce* GTX 680M",
      "tr_GR616": "NVIDIA* GeForce* GTX 680MX",
      "tr_GR617": "NVIDIA* GeForce* GTX 690",
      "tr_GR618": "NVIDIA* GeForce* GTX 745",
      "tr_GR619": "NVIDIA* GeForce* GTX 750",
      "tr_GR620": "NVIDIA* GeForce* GTX 750 Ti",
      "tr_GR621": "NVIDIA* GeForce* GTX 760",
      "tr_GR622": "NVIDIA* GeForce* GTX 760 Ti",
      "tr_GR623": "NVIDIA* GeForce* GTX 760A",
      "tr_GR624": "NVIDIA* GeForce* GTX 760M",
      "tr_GR625": "NVIDIA* GeForce* GTX 765M",
      "tr_GR626": "NVIDIA* GeForce* GTX 770",
      "tr_GR627": "NVIDIA* GeForce* GTX 770M",
      "tr_GR628": "NVIDIA* GeForce* GTX 775M",
      "tr_GR629": "NVIDIA* GeForce* GTX 780",
      "tr_GR630": "NVIDIA* GeForce* GTX 780 Ti",
      "tr_GR631": "NVIDIA* GeForce* GTX 780M",
      "tr_GR632": "NVIDIA* GeForce* GTX 850A",
      "tr_GR633": "NVIDIA* GeForce* GTX 850M",
      "tr_GR634": "NVIDIA* GeForce* GTX 860M",
      "tr_GR635": "NVIDIA* GeForce* GTX 870M",
      "tr_GR636": "NVIDIA* GeForce* GTX 880M",
      "tr_GR637": "NVIDIA* GeForce* GTX 950",
      "tr_GR638": "NVIDIA* GeForce* GTX 950A",
      "tr_GR639": "NVIDIA* GeForce* GTX 950M",
      "tr_GR640": "NVIDIA* GeForce* GTX 960",
      "tr_GR641": "NVIDIA* GeForce* GTX 960A",
      "tr_GR642": "NVIDIA* GeForce* GTX 960M",
      "tr_GR643": "NVIDIA* GeForce* GTX 965M",
      "tr_GR644": "NVIDIA* GeForce* GTX 970",
      "tr_GR645": "NVIDIA* GeForce* GTX 970M",
      "tr_GR646": "NVIDIA* GeForce* GTX 980",
      "tr_GR647": "NVIDIA* GeForce* GTX 980 Ti",
      "tr_GR648": "NVIDIA* GeForce* GTX 980M",
      "tr_GR649": "NVIDIA* GeForce* GTX TITAN",
      "tr_GR650": "NVIDIA* GeForce* GTX TITAN Black",
      "tr_GR651": "NVIDIA* GeForce* GTX TITAN X",
      "tr_GR652": "NVIDIA* GeForce* GTX TITAN Z",
      "tr_GR653": "NVIDIA* GeForce* RTX 2050",
      "tr_GR654": "NVIDIA* GeForce* RTX 2060",
      "tr_GR655": "采用 Max-Q 设计的 NVIDIA* GeForce* RTX 2060",
      "tr_GR656": "NVIDIA* GeForce* RTX 2060 SUPER",
      "tr_GR657": "NVIDIA* GeForce* RTX 2070",
      "tr_GR658": "采用 Max-Q 设计的 NVIDIA* GeForce* RTX 2070",
      "tr_GR659": "NVIDIA* GeForce* RTX 2070 SUPER",
      "tr_GR660": "采用 Max-Q 设计的 NVIDIA* GeForce* RTX 2070 Super",
      "tr_GR661": "NVIDIA* GeForce* RTX 2080",
      "tr_GR662": "采用 Max-Q 设计的 NVIDIA* GeForce* RTX 2080",
      "tr_GR663": "NVIDIA* GeForce* RTX 2080 SUPER",
      "tr_GR664": "采用 Max-Q 设计的 NVIDIA* GeForce* RTX 2080 Super",
      "tr_GR665": "NVIDIA* GeForce* RTX 2080 Ti",
      "tr_GR666": "NVIDIA* GeForce* RTX 3050",
      "tr_GR667": "NVIDIA* GeForce* RTX 3050 Ti",
      "tr_GR668": "NVIDIA* GeForce* RTX 3060",
      "tr_GR669": "NVIDIA* GeForce* RTX 3060 Ti",
      "tr_GR670": "NVIDIA* GeForce* RTX 3070",
      "tr_GR671": "NVIDIA* GeForce* RTX 3070 Ti",
      "tr_GR672": "NVIDIA* GeForce* RTX 3080",
      "tr_GR673": "NVIDIA* GeForce* RTX 3080 Ti",
      "tr_GR674": "NVIDIA* GeForce* RTX 3090",
      "tr_GR675": "NVIDIA* GeForce* RTX 3090 Ti",
      "tr_GR676": "NVIDIA* GeForce* RTX 4050",
      "tr_GR677": "NVIDIA* GeForce* RTX 4060",
      "tr_GR678": "NVIDIA* GeForce* RTX 4060 Ti",
      "tr_GR679": "NVIDIA* GeForce* RTX 4070",
      "tr_GR680": "NVIDIA* GeForce* RTX 4070 SUPER",
      "tr_GR681": "NVIDIA* GeForce* RTX 4070 Ti",
      "tr_GR682": "NVIDIA* GeForce* RTX 4070 Ti SUPER",
      "tr_GR683": "NVIDIA* GeForce* RTX 4080",
      "tr_GR684": "NVIDIA* GeForce* RTX 4090",
      "tr_GR685": "NVIDIA* GeForce* RTX 4090 D",
      "tr_GR686": "NVIDIA* NVS* 310",
      "tr_GR687": "NVIDIA* NVS* 315",
      "tr_GR688": "NVIDIA* NVS* 4200M",
      "tr_GR689": "NVIDIA* NVS* 510",
      "tr_GR690": "NVIDIA* NVS* 5200M",
      "tr_GR691": "NVIDIA* NVS* 5400M",
      "tr_GR692": "NVIDIA* NVS* 810",
      "tr_GR693": "NVIDIA* Quadro* 410",
      "tr_GR694": "NVIDIA* Quadro* GP100",
      "tr_GR695": "NVIDIA* Quadro* GV100",
      "tr_GR696": "NVIDIA* Quadro* K1000M",
      "tr_GR697": "NVIDIA* Quadro* K1100M",
      "tr_GR698": "NVIDIA* Quadro* K1200",
      "tr_GR699": "NVIDIA* Quadro* K2000",
      "tr_GR700": "NVIDIA* Quadro* K2000D",
      "tr_GR701": "NVIDIA* Quadro* K2000M",
      "tr_GR702": "NVIDIA* Quadro* K2100M",
      "tr_GR703": "NVIDIA* Quadro* K2200",
      "tr_GR704": "NVIDIA* Quadro* K2200M",
      "tr_GR705": "NVIDIA* Quadro* K3000M",
      "tr_GR706": "NVIDIA* Quadro* K3100M",
      "tr_GR707": "NVIDIA* Quadro* K4000",
      "tr_GR708": "NVIDIA* Quadro* K4000M",
      "tr_GR709": "NVIDIA* Quadro* K4100M",
      "tr_GR710": "NVIDIA* Quadro* K420",
      "tr_GR711": "NVIDIA* Quadro* K4200",
      "tr_GR712": "NVIDIA* Quadro* K5000",
      "tr_GR713": "NVIDIA* Quadro* K5000M",
      "tr_GR714": "NVIDIA* Quadro* K500M",
      "tr_GR715": "NVIDIA* Quadro* K5100M",
      "tr_GR716": "NVIDIA* Quadro* K510M",
      "tr_GR717": "NVIDIA* Quadro* K5200",
      "tr_GR718": "NVIDIA* Quadro* K600",
      "tr_GR719": "NVIDIA* Quadro* K6000",
      "tr_GR720": "NVIDIA* Quadro* K610M",
      "tr_GR721": "NVIDIA* Quadro* K620",
      "tr_GR722": "NVIDIA* Quadro* K620M",
      "tr_GR723": "NVIDIA* Quadro* M1000M",
      "tr_GR724": "NVIDIA* Quadro* M1200",
      "tr_GR725": "NVIDIA* Quadro* M2000",
      "tr_GR726": "NVIDIA* Quadro* M2000M",
      "tr_GR727": "NVIDIA* Quadro* M2200",
      "tr_GR728": "NVIDIA* Quadro* M3000M",
      "tr_GR729": "NVIDIA* Quadro* M4000",
      "tr_GR730": "NVIDIA* Quadro* M4000M",
      "tr_GR731": "NVIDIA* Quadro* M5000",
      "tr_GR732": "NVIDIA* Quadro* M5000M",
      "tr_GR733": "NVIDIA* Quadro* M500M",
      "tr_GR734": "NVIDIA* Quadro* M520",
      "tr_GR735": "NVIDIA* Quadro* M5500",
      "tr_GR736": "NVIDIA* Quadro* M6000",
      "tr_GR737": "NVIDIA* Quadro* M600M",
      "tr_GR738": "NVIDIA* Quadro* M620",
      "tr_GR739": "NVIDIA* Quadro* P1000",
      "tr_GR740": "NVIDIA* Quadro* P2000",
      "tr_GR741": "采用 Max-Q 设计的 NVIDIA* Quadro* P2000",
      "tr_GR742": "NVIDIA* Quadro* P2200",
      "tr_GR743": "NVIDIA* Quadro* P3000",
      "tr_GR744": "NVIDIA* Quadro* P3200",
      "tr_GR745": "采用 Max-Q 设计的 NVIDIA* Quadro* P3200",
      "tr_GR746": "NVIDIA* Quadro* P400",
      "tr_GR747": "NVIDIA* Quadro* P4000",
      "tr_GR748": "采用 Max-Q 设计的 NVIDIA* Quadro* P4000",
      "tr_GR749": "NVIDIA* Quadro* P4200",
      "tr_GR750": "采用 Max-Q 设计的 NVIDIA* Quadro* P4200",
      "tr_GR751": "NVIDIA* Quadro* P500",
      "tr_GR752": "NVIDIA* Quadro* P5000",
      "tr_GR753": "NVIDIA* Quadro* P520",
      "tr_GR754": "NVIDIA* Quadro* P5200",
      "tr_GR755": "采用 Max-Q 设计的 NVIDIA* Quadro* P5200",
      "tr_GR756": "NVIDIA* Quadro* P600",
      "tr_GR757": "NVIDIA* Quadro* P6000",
      "tr_GR758": "NVIDIA* Quadro* P620",
      "tr_GR759": "NVIDIA* Quadro* RTX 3000",
      "tr_GR760": "采用 Max-Q 设计的 NVIDIA* Quadro* RTX 3000",
      "tr_GR761": "NVIDIA* Quadro* RTX 4000",
      "tr_GR762": "采用 Max-Q 设计的 NVIDIA* Quadro* RTX 4000",
      "tr_GR763": "NVIDIA* Quadro* RTX 5000",
      "tr_GR764": "采用 Max-Q 设计的 NVIDIA* Quadro* RTX 5000",
      "tr_GR765": "NVIDIA* Quadro* RTX 6000",
      "tr_GR766": "NVIDIA* Quadro* RTX 8000",
      "tr_GR767": "NVIDIA* Quadro* T1000",
      "tr_GR768": "采用 Max-Q 设计的 NVIDIA* Quadro* T1000",
      "tr_GR769": "NVIDIA* Quadro* T2000",
      "tr_GR770": "采用 Max-Q 设计的 NVIDIA* Quadro* T2000",
      "tr_GR771": "NVIDIA* T500",
      "tr_GR772": "NVIDIA* RTX 2000 Ada Generation",
      "tr_GR773": "NVIDIA* RTX 3000 Ada Generation",
      "tr_GR774": "NVIDIA* RTX 3500 Ada Generation",
      "tr_GR775": "NVIDIA* RTX 4000 Ada Generation",
      "tr_GR776": "NVIDIA* RTX 4000 SFF Ada Generation",
      "tr_GR777": "NVIDIA* RTX 4500 Ada Generation",
      "tr_GR778": "NVIDIA* RTX 5000 Ada Generation",
      "tr_GR779": "NVIDIA* RTX 5880 Ada Generation",
      "tr_GR780": "NVIDIA* Tesla* C2050 / C2070",
      "tr_GR781": "NVIDIA* Tesla* C2070",
      "tr_GR782": "NVIDIA* Tesla* C2075",
      "tr_GR783": "NVIDIA* Tesla* K10",
      "tr_GR784": "NVIDIA* Tesla* K20c",
      "tr_GR785": "NVIDIA* Tesla* K20m",
      "tr_GR786": "NVIDIA* Tesla* K20s",
      "tr_GR787": "NVIDIA* Tesla* K20Xm",
      "tr_GR788": "NVIDIA* Tesla* K40c",
      "tr_GR789": "NVIDIA* Tesla* K40m",
      "tr_GR790": "NVIDIA* Tesla* K40s",
      "tr_GR791": "NVIDIA* Tesla* K40st",
      "tr_GR792": "NVIDIA* Tesla* K40t",
      "tr_GR793": "NVIDIA* Tesla* K8",
      "tr_GR794": "NVIDIA* Tesla* K80",
      "tr_GR795": "NVIDIA* Tesla* M2050",
      "tr_GR796": "NVIDIA* Tesla* M2070",
      "tr_GR797": "NVIDIA* Tesla* M2070-Q",
      "tr_GR798": "NVIDIA* Tesla* M2075",
      "tr_GR799": "NVIDIA* Tesla* M2090",
      "tr_GR800": "NVIDIA* Tesla* S2050",
      "tr_GR801": "NVIDIA* Tesla* T20",
      "tr_GR802": "NVIDIA* Tesla* T4",
      "tr_GR803": "NVIDIA* Tesla* X2070",
      "tr_GR804": "NVIDIA* Tesla* X2090",
      "tr_GR805": "NVIDIA* TITAN RTX",
      "tr_GR806": "NVIDIA* TITAN V",
      "tr_GR807": "NVIDIA* TITAN X",
      "tr_GR808": "NVIDIA* TITAN Xp",
      "tr_GR809": "NVIDIA* GeForce* RTX 4080 SUPER",
      "tr_GR810": "NVIDIA* RTX 500 Ada Generation",
      "tr_GR811": "NVIDIA* RTX 1000 Ada Generation",
      "tr_GR812": "英特尔锐炫™ 显卡",
      "tr_GR813": "NVIDIA* GeForce* RTX 3050 A",
      "tr_GR814": "NVIDIA RTX* 2000E Ada Generation",
      "tr_GR815": "AMD Radeon* Pro W7900 双插槽"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "英特尔® 迅驰® Advanced-N 6200 ABG",
      "tr_Wifi2": "英特尔® 迅驰® Advanced-N 6200 AGN",
      "tr_Wifi3": "英特尔® 迅驰® Advanced-N 6200 BG",
      "tr_Wifi4": "英特尔® 迅驰® Advanced-N 6205",
      "tr_Wifi5": "英特尔® 迅驰® Advanced-N 6230",
      "tr_Wifi6": "英特尔® 迅驰® Advanced-N 6235",
      "tr_Wifi7": "英特尔® 迅驰® Advanced-N 6250 ABG",
      "tr_Wifi8": "英特尔® 迅驰® Advanced-N 6250 AGN",
      "tr_Wifi9": "英特尔® 迅驰® Ultimate-N 6300 AGN",
      "tr_Wifi10": "英特尔® 迅驰® Wireless-N 100",
      "tr_Wifi11": "英特尔® 迅驰® Wireless-N 1000",
      "tr_Wifi12": "英特尔® 迅驰® Wireless-N 1030",
      "tr_Wifi13": "英特尔® 迅驰® Wireless-N 105",
      "tr_Wifi14": "英特尔® 迅驰® Wireless-N 135",
      "tr_Wifi15": "英特尔® 迅驰® Wireless-N 2200",
      "tr_Wifi16": "英特尔® 迅驰® Wireless-N 2230",
      "tr_Wifi17": "英特尔® 迅驰® Wireless-N WiMAX 6150",
      "tr_Wifi18": "英特尔® Dual Band Wireless-AC 3160",
      "tr_Wifi19": "英特尔® Dual Band Wireless-AC 3165",
      "tr_Wifi20": "英特尔® Dual Band Wireless-AC 7260",
      "tr_Wifi21": "英特尔® Dual Band Wireless-AC 7265",
      "tr_Wifi22": "英特尔® Dual Band Wireless-N 3160",
      "tr_Wifi23": "英特尔® Dual Band Wireless-N 7260",
      "tr_Wifi24": "英特尔® Dual Band Wireless-N 7265",
      "tr_Wifi25": "英特尔® Tri-Band Wireless-AC 17261",
      "tr_Wifi26": "英特尔® Wi-Fi 6 AX101",
      "tr_Wifi27": "英特尔® Wi-Fi 6 AX200",
      "tr_Wifi28": "英特尔® Wi-Fi 6 AX201",
      "tr_Wifi29": "英特尔® Wi-Fi 6 AX203",
      "tr_Wifi30": "英特尔® Wi-Fi 6E AX210",
      "tr_Wifi31": "英特尔® Wi-Fi 6E AX211",
      "tr_Wifi32": "英特尔® Wi-Fi 6E AX411",
      "tr_Wifi33": "英特尔® Wi-Fi 7 BE200",
      "tr_Wifi34": "英特尔® Wi-Fi 7 BE202",
      "tr_Wifi35": "英特尔® Wireless-AC 3165",
      "tr_Wifi36": "英特尔® Wireless-AC 3168",
      "tr_Wifi37": "英特尔® Wireless-AC 8265",
      "tr_Wifi38": "英特尔® Wireless-AC 9260",
      "tr_Wifi39": "英特尔® Wireless-AC 9461",
      "tr_Wifi40": "英特尔® Wireless-AC 9462",
      "tr_Wifi41": "英特尔® Wireless-AC 9560",
      "tr_Wifi42": "英特尔® Wireless-N 3160",
      "tr_Wifi43": "英特尔® Wireless-N 7260",
      "tr_Wifi44": "英特尔® Wireless-N 7265",
      "tr_Wifi45": "英特尔® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi46": "英特尔® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi47": "英特尔® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi48": "英特尔® Killer™ Wi-Fi 6 AX1650",
      "tr_Wifi49": "英特尔® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi50": "英特尔® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi51": "英特尔® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi52": "英特尔® Killer™ Wi-Fi 6E AX1675",
      "tr_Wifi53": "英特尔® Killer™ Wi-Fi 6E AX1690",
      "tr_Wifi54": "英特尔® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi55": "英特尔® Killer™ Wireless-AC 1550",
      "tr_Wifi56": "英特尔® Killer™ Wireless-AC 1550",
      "tr_Wifi57": "英特尔® Killer™ Wireless-AC 1550",
      "tr_Wifi58": "英特尔® Wi-Fi 7 BE201",
      "tr_Wifi59": "英特尔® Killer™ Wi-Fi 7 BE1750",
      "tr_Wifi60": "英特尔® Killer™ Wi-Fi 7 BE1750"
    },
    "tr_textBuyNow": "立即购买",
    "tr_textTryagain": "再试一次",
    "tr_textBuynowErr": "由于连接中断，“立即购买”选项无法使用",
    "tr_chatbotPlaceholder_text": "向我询问关于设备的任何问题",
    "tr_deviceSpecs_text": "设备规格"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "采用英特尔® Hybrid Technology&nbsp;的英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i3&nbsp;处&#65279;理&#65279;器"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "采用英特尔® Hybrid Technology&nbsp;的英&#65279;特&#65279;尔&#65279;®&nbsp;酷&#65279;睿&#65279;™ i5&nbsp;处&#65279;理&#65279;器"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "《漫威复仇者》",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "与英特尔® 联手，打造更强大的游戏体验",
                "tr_subtitle": "用英特尔技术优化游戏电脑，栩栩如生再现《漫威复仇者》*。",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "体验英特尔® 极限大师杯赛",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "出色的游戏玩家共聚一堂，一争高下",
                "tr_subtitle": "英特尔® 极限大师杯赛是视频游戏历史上运行时间最长的赛事系列，为电子竞技树立了标准。",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "像职业玩家一样玩游戏",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "英特尔助您夺冠",
                "tr_subtitle": "在获得 2021 年《彩虹六号：围攻》* 北美联赛冠军后，Soniqs 认真思考了他们的游戏历程、电子竞技的未来以及他们与英特尔的合作伙伴关系。",
                "tr_pretitle": "Soniqs 专题",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "虚拟现实就绪",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "虚拟现实震撼登场",
                "tr_subtitle": "在电脑上畅享令人惊叹、栩栩如生的沉浸式虚拟现实体验。借助最新英特尔® 酷睿™ 处理器，您将无往不利，无所不能，并且能够在未知新世界中获得卓越的游戏体验。",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "Xbox* Game Pass Ultimate",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "Xbox* Game Pass Ultimate",
                "tr_subtitle": "使用 Xbox* Game Pass Ultimate 和兼容的控制器，在您的 Windows* 电脑、手机和平板电脑上畅玩 100 多种高品质游戏，例如《盗贼之海*》 和《脑航员* 2》。",
                "titleArray": [
                  {
                    "tr_title": "跨设备玩游戏",
                    "tr_subtitle": "进入 Xbox* 的核心，加入玩家社区，与数百万玩家一起玩游戏。与共享游戏库中的其他人建立联系并一起玩游戏，他们或许身处世界另一端，又或是就坐在您旁边。"
                  },
                  {
                    "tr_title": "Windows* 电脑游戏",
                    "tr_subtitle": "使用适用于 Windows* 的 Xbox* 应用程序和兼容的控制器，在云端畅玩各式主机游戏。"
                  },
                  {
                    "tr_title": "即选即玩",
                    "tr_subtitle": "在多种设备上畅玩精彩 Xbox* 游戏。无论您是在家中看电视、在咖啡馆还是在其他任何有高速互联网连接的地方，均可在电脑、手机和平板电脑上玩 100 多种游戏。"
                  },
                  {
                    "tr_title": "免控制器的娱乐",
                    "tr_subtitle": "Xbox* 触控游戏带来全新游戏方式，提供熟悉的体验，延续您惯用实物控制器的游戏水平。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "新闻热点",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "超过 100 种 AI 体验",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "控制更强，触摸更少",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "在任何环境都能保持在画面中",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "创意",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "如同专业设备一样校正光感效果",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "轻松实现超越现实的效果",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "利用 AI 的强大功能对歌曲进行混音",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "工作效率",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "提升工作效率和隐私性",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "持久续航，无需插座",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "使用各种设备， 在&#65279;您&#65279;的&#65279;世&#65279;界&#65279;里&#65279;畅&#65279;游",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "娱乐",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "即使不接入电源，也能流畅观看直播",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "高速沉浸式游戏体验",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "新闻热点",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "超过 100 种 AI 体验",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "让您最喜爱的歌曲真正成为您的歌曲",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "实时查看视觉效果",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "创意",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "完成最终剪辑的最快途径",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "让每张照片完美呈现",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "工作效率",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "提升工作效率和隐私性",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "更高效率。更多自由",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "使用各种设备， 在&#65279;您&#65279;的&#65279;世&#65279;界&#65279;里&#65279;畅&#65279;游",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "娱乐",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "即使不接入电源，也能流畅观看高清直播",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "随时随地获得沉浸式游戏体验",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "创作更高效",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "图片处理 高效便捷",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "智能修图 秒出片",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "用您的文字 实现 AI 创作",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "性能更强劲",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "超多游戏 畅爽体验",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "持久续航，无需插座",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "超沉浸式",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "效率更出众",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "您的日常 AI 助手",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "视频通话 清晰通畅",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "使用各种设备， 在&#65279;您&#65279;的&#65279;世&#65279;界&#65279;里&#65279;畅&#65279;游",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "安全性更高",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "内置保护措施，帮助您的数据更安全、更私密",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "创作更高效",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "图片处理 高效便捷",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "AI。化繁为简 即刻出片",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "音频歌词 智能提取",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "利用 AI 节省数小时的编辑时间",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "性能更强劲",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "超多游戏 畅爽体验",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "持久续航，无需插座",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "超沉浸式",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "效率更出众",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "您的日常 AI 助手",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "视频通话 清晰通畅",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "使用各种设备， 在&#65279;您&#65279;的&#65279;世&#65279;界&#65279;里&#65279;畅&#65279;游",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "安全性更高",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "内置保护措施，帮助您的数据更安全、更私密",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "所有娱乐尽在一台电脑",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "对于一台可用作娱乐中心的电脑，需要为所有喜爱的歌曲、视频和游戏提供足够的存储空间，还需要合适的处理器和显示器组合，以打造沉浸式视觉体验。这款电脑三个方面齐备。",
                "titleArray": [
                  {
                    "tr_title": "提供新一代视觉体验",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "打造数字内容库",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "通往娱乐世界的窗口",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "无缝游戏和直播体验",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "打造数字内容库",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "通往娱乐世界的窗口",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "提高工作效率，随时随地",
              "tr_tileTitle1": "提高效率，快速实现",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "这款电脑在性能与便携性之间实现了完美的平衡。除了专为轻薄型设计而打造的处理器，这款电脑还拥有让您保持连接的 Wi&#8209;Fi 和让您不插电也能工作的大容量电池。",
                "tr_subtitle1": "此款电脑使功率与性能达到完美平衡，可以帮助您在更短的时间内完成更多工&#65279;作。由容量充足的内存加速的强大处理器，再加上快如闪电的 Wi-Fi，这些都&#65279;将简化您的日常任务。",
                "tr_subtitle2": "这款电脑在性能与便携性之间实现了完美的平衡。除了专为轻薄型设计而打造的处理器，这款电脑还拥有让您保持连接的 Wi‑Fi 和实现多任务处理所需的合适内存。",
                "titleArray": [
                  {
                    "tr_title": "处理能力更强",
                    "tr_title1": "智能至关重要",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "一流的连接性能",
                    "tr_title1": "连接重要目标",
                    "tr_title2": "Wi‑Fi 的重大进步",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "电池续航更长",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "像专业人士一样玩转程序",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "自由创作",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "内容创作是一项对电脑要求最严苛的任务。不过，这些组件能够简化创作过程。",
                "titleArray": [
                  {
                    "tr_title": "更智能的创作方式",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "为影像赋予生命力",
                    "tr_title1": "携手创造",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "内存助力实现多任务处理",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "深入了解这款电脑",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "这是选择新电脑时需要关注的三个要点。通过确定用户使用新电脑的方式，就能很好地判断用户需要这三个关键点的程度。具体包括：",
                "titleArray": [
                  {
                    "tr_title": "性能强劲，满足游戏和直播需求",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "存储",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "内存",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "所有娱乐尽在一台电脑",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "对于一台可用作娱乐中心的电脑，需要为所有喜爱的歌曲、视频和游戏提供足够的存储空间，还需要合适的处理器和显示器组合，以打造沉浸式视觉体验。这款电脑三个方面齐备。",
                "titleArray": [
                  {
                    "tr_title": "提供新一代视觉体验",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "打造数字内容库",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "通往娱乐世界的窗口",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "无缝游戏和直播体验",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "打造数字内容库",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "通往娱乐世界的窗口",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "内存",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "显示器",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "高速多任务处理",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "高速多任务处理",
                "tr_subtitle": "事半功倍，毫不减速",
                "tr_subtitle1": "我们发现这款电脑的以下特性有利于多任务处理：",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "搭载可同时搞定多个程序的处理器。"
                  },
                  {
                    "tr_title": "配备高速、强大、可靠的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "配备可无缝切换应用程序的内存。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "视频聊天",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "视频聊天",
                "tr_subtitle": "清晰通透的连接",
                "tr_subtitle1": "我们发现这部电脑的以下特性有利于视频聊天：",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "采用可同时录制和分享图像的处理器。"
                  },
                  {
                    "tr_title": "配备高速、强大、可靠的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "网络摄像头可清晰呈现您的影像。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "虚拟现实就绪",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "虚拟现实震撼登场",
                "tr_subtitle": "它如何营造一个完全虚拟的世界？在电脑上，您需要处理器、显卡和内存之间的协同合作。",
                "titleArray": [
                  {
                    "tr_title": "助力虚拟世界",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "细节成就真实",
                    "tr_title1": "沉浸其中",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "视频编辑",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "视频编辑",
                "tr_subtitle": "创造光影奇迹",
                "tr_subtitle1": "我们发现这部电脑的以下特性有利于视频剪辑：",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "搭载可处理大型文件的处理器。"
                  },
                  {
                    "tr_title": "采用的内存可令剪辑软件变得快速而响应敏捷。"
                  },
                  {
                    "tr_title": "配备可缩短渲染时间的显卡。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "高速多任务处理",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "高速多任务处理",
                "tr_subtitle": "事半功倍，毫不减速",
                "tr_subtitle1": "我们发现这款电脑的以下特性有利于多任务处理：",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "搭载可同时搞定多个程序的处理器。"
                  },
                  {
                    "tr_title": "配备高速、强大、可靠的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "配备可无缝切换应用程序的内存。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "虚拟现实就绪",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "虚拟现实震撼登场",
                "tr_subtitle": "它如何营造一个完全虚拟的世界？在电脑上，您需要处理器、显卡和内存之间的协同合作。",
                "titleArray": [
                  {
                    "tr_title": "助力虚拟世界",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "细节成就真实",
                    "tr_title1": "沉浸其中",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "3D 建模",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D 建模",
                "tr_subtitle": "让创作再上新高",
                "tr_subtitle1": "我们发现这部电脑的以下特性有利于 3D 建模：",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "搭载可处理高级创意软件的处理器。"
                  },
                  {
                    "tr_title": "配备可快速加载大型文件的内存。"
                  },
                  {
                    "tr_title": "采用面向图像创建和剪辑的强大显卡。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "实时协作",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "实时协作",
                "tr_subtitle": "随时随地投入工作",
                "tr_subtitle1": "我们发现这款电脑的以下特性有利于展开在线协作：",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "搭载可玩转办公室软件的处理器。"
                  },
                  {
                    "tr_title": "配备值得信赖的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "采用可完美录制高清视频的网络摄像头。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "虚拟现实就绪",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "虚拟现实震撼登场",
                "tr_subtitle": "它如何营造一个完全虚拟的世界？在电脑上，您需要处理器、显卡和内存之间的协同合作。",
                "titleArray": [
                  {
                    "tr_title": "助力虚拟世界",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "细节成就真实",
                    "tr_title1": "沉浸其中",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "英特尔 Evo™ 笔记本电脑",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "如此轻薄的笔&#xfeff;记&#xfeff;本&#xfeff;电&#xfeff;脑带来的强大动力超&#xfeff;乎&#xfeff;想&#xfeff;象",
                "tr_subtitle": "英特尔 Evo™ 笔记本电脑专为实现移动超能而打造，速度、显卡、电池续航一应俱全，尽在令人惊叹的轻薄设计之中。",
                "titleArray": [
                  {
                    "tr_title": "笔记本电脑的用途已非比寻常，因此我们要保证它能堪当大任。",
                    "tr_subtitle": "使用触控屏来探索、旋转和缩放。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬间唤醒",
                    "tr_subtitle": "有了即时唤醒功能，电脑可以在不到一秒钟时间内被唤醒，准备开始工作。"
                  },
                  {
                    "tr_title": "沉浸式观看体验",
                    "tr_subtitle": "显示屏造型美观，画面清晰、色彩艳丽、栩栩如生。"
                  },
                  {
                    "tr_title": "快速完成任务",
                    "tr_subtitle": "享受第 11 代英特尔® 酷睿™ 处理器带来的超敏捷的响应性能。"
                  },
                  {
                    "tr_title": "不插电使用时间更长",
                    "tr_subtitle": "如果能延长电池续航时间、充电速度迅如闪电，工作就不会间断。"
                  },
                  {
                    "tr_title": "通用电缆连接",
                    "tr_subtitle": "借助雷电技术 4 将其他设备接&#65279;入&#65279;电&#65279;脑&#65279;，简单便捷，安全可靠。<sup>10</sup>"
                  },
                  {
                    "tr_title": "快速可靠的连接",
                    "tr_subtitle": "借助英特尔® Wi‑Fi 6 (Gig+) 提供的可靠连接进行视频聊天或文件分享。<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "英特尔 Evo™ 笔记本电脑",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "只&#xFEFF;需一&#xFEFF;台超&#xFEFF;能&#xFEFF;轻&#xFEFF;薄&#xFEFF;型<br>笔&#xFEFF;记&#xFEFF;本电&#xFEFF;脑<br>即&#xFEFF;可满&#xFEFF;足您&#xFEFF;的<br>各&#xFEFF;种需&#xFEFF;求",
                "tr_subtitle": "英特尔 Evo™ 笔记本电脑具备移动超能，无论进行什么操作，都能为您提供超能轻薄体验&#xfeff;<sup>2</sup>。",
                "titleArray": [
                  {
                    "tr_title": "笔记本电脑的用途已非比寻常，因此我们要保证它能堪当大任。",
                    "tr_subtitle": "使用触控屏来探索、旋转和缩放。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬间唤醒",
                    "tr_subtitle": "有了即时唤醒功能，电脑可以在不到一秒钟时间内被唤醒，准备开始工作。"
                  },
                  {
                    "tr_title": "沉浸式观看体验",
                    "tr_subtitle": "显示屏造型美观，画面清晰、色彩艳丽、栩栩如生。"
                  },
                  {
                    "tr_title": "颠覆性的性能",
                    "tr_subtitle": "借助第 12 代英特尔® 酷睿™ 处理器的智能性能，获得额外动力，满足核心需求。"
                  },
                  {
                    "tr_title": "不插电使用时间更长",
                    "tr_subtitle": "如果能延长电池续航时间、充电速度迅如闪电，工作就不会间断。"
                  },
                  {
                    "tr_title": "通用电缆连接",
                    "tr_subtitle": "借助雷电技术 4 将其他设备接&#65279;入&#65279;电&#65279;脑&#65279;，简单便捷，安全可靠。<sup>10</sup>"
                  },
                  {
                    "tr_title": "快速可靠的连接",
                    "tr_subtitle": "借助英特尔® Wi-Fi 6E (Gig+) 提供的可靠连接进行视频聊天或文件共享<sup>1</sup>。"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "英特尔 Evo™ 笔记本电脑",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "如此轻薄的笔&#xfeff;记&#xfeff;本&#xfeff;电&#xfeff;脑带来的强大动力超&#xfeff;乎&#xfeff;想&#xfeff;象",
                "tr_subtitle": "英特尔 Evo™ 笔记本电脑专为实现移动超能而打造，速度、显卡、电池续航一应俱全，尽在令人惊叹的轻薄设计之中。",
                "titleArray": [
                  {
                    "tr_title": "笔记本电脑的用途已非比寻常，因此我们要保证它能堪当大任。",
                    "tr_subtitle": "使用触控屏来探索、旋转和缩放。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬间唤醒",
                    "tr_subtitle": "有了即时唤醒功能，电脑可以在不到一秒钟时间内被唤醒，准备开始工作。"
                  },
                  {
                    "tr_title": "沉浸式观看体验",
                    "tr_subtitle": "显示屏造型美观，画面清晰、色彩艳丽、栩栩如生。"
                  },
                  {
                    "tr_title": "快速完成任务",
                    "tr_subtitle": "享受第 11 代英特尔® 酷睿™ 处理器带来的超敏捷的响应性能。"
                  },
                  {
                    "tr_title": "不插电使用时间更长",
                    "tr_subtitle": "如果能延长电池续航时间、充电速度迅如闪电，工作就不会间断。"
                  },
                  {
                    "tr_title": "通用电缆连接",
                    "tr_subtitle": "借助雷电技术 4 将其他设备接&#65279;入&#65279;电&#65279;脑&#65279;，简单便捷，安全可靠。<sup>10</sup>"
                  },
                  {
                    "tr_title": "快速可靠的连接",
                    "tr_subtitle": "借助英特尔® Wi‑Fi 6 (Gig+) 提供的可靠连接进行视频聊天或文件分享。<sup>1</sup>"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "英特尔 Evo™ 笔记本电脑",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "只&#xFEFF;需一&#xFEFF;台超&#xFEFF;能&#xFEFF;轻&#xFEFF;薄&#xFEFF;型<br>笔&#xFEFF;记&#xFEFF;本电&#xFEFF;脑<br>即&#xFEFF;可满&#xFEFF;足您&#xFEFF;的<br>各&#xFEFF;种需&#xFEFF;求",
                "tr_subtitle": "英特尔 Evo™ 笔记本电脑具备移动超能，无论进行什么操作，都能为您提供超能轻薄体验&#xfeff;<sup>2</sup>。",
                "titleArray": [
                  {
                    "tr_title": "笔记本电脑的用途已非比寻常，因此我们要保证它能堪当大任。",
                    "tr_subtitle": "使用触控屏来探索、旋转和缩放。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬间唤醒",
                    "tr_subtitle": "有了即时唤醒功能，电脑可以在不到一秒钟时间内被唤醒，准备开始工作。"
                  },
                  {
                    "tr_title": "沉浸式观看体验",
                    "tr_subtitle": "显示屏造型美观，画面清晰、色彩艳丽、栩栩如生。"
                  },
                  {
                    "tr_title": "颠覆性的性能",
                    "tr_subtitle": "借助第 12 代英特尔® 酷睿™ 处理器的智能性能，获得额外动力，满足核心需求。"
                  },
                  {
                    "tr_title": "不插电使用时间更长",
                    "tr_subtitle": "如果能延长电池续航时间、充电速度迅如闪电，工作就不会间断。"
                  },
                  {
                    "tr_title": "通用电缆连接",
                    "tr_subtitle": "借助雷电技术 4 将其他设备接&#65279;入&#65279;电&#65279;脑&#65279;，简单便捷，安全可靠。<sup>10</sup>"
                  },
                  {
                    "tr_title": "快速可靠的连接",
                    "tr_subtitle": "借助英特尔® Wi-Fi 6E (Gig+) 提供的可靠连接进行视频聊天或文件共享<sup>1</sup>。"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "英特尔 Evo™ 笔记本电脑",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "只&#xFEFF;需一&#xFEFF;台超&#xFEFF;能&#xFEFF;轻&#xFEFF;薄&#xFEFF;型<br>笔&#xFEFF;记&#xFEFF;本电&#xFEFF;脑<br>即&#xFEFF;可满&#xFEFF;足您&#xFEFF;的<br>各&#xFEFF;种需&#xFEFF;求",
                "tr_subtitle": "英特尔 Evo™ 笔记本电脑具备移动超能，无论进行什么操作，都能为您提供超能轻薄体验&#xfeff;<sup>2</sup>。",
                "titleArray": [
                  {
                    "tr_title": "笔记本电脑的用途已非比寻常，因此我们要保证它能堪当大任。",
                    "tr_subtitle": "使用触控屏来探索、旋转和缩放。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬间唤醒",
                    "tr_subtitle": "有了即时唤醒功能，电脑可以在不到一秒钟时间内被唤醒，准备开始工作。"
                  },
                  {
                    "tr_title": "沉浸式观看体验",
                    "tr_subtitle": "显示屏造型美观，画面清晰、色彩艳丽、栩栩如生。"
                  },
                  {
                    "tr_title": "颠覆性的性能",
                    "tr_subtitle": "借助第 13 代英特尔® 酷睿™ 处理器的智能性能，获得额外动力，满足核心需求。"
                  },
                  {
                    "tr_title": "不插电使用时间更长",
                    "tr_subtitle": "如果能延长电池续航时间、充电速度迅如闪电，工作就不会间断。"
                  },
                  {
                    "tr_title": "通用电缆连接",
                    "tr_subtitle": "借助雷电技术 4 将其他设备接&#65279;入&#65279;电&#65279;脑&#65279;，简单便捷，安全可靠。<sup>10</sup>"
                  },
                  {
                    "tr_title": "快速可靠的连接",
                    "tr_subtitle": "借助英特尔® Wi-Fi 6E (Gig+) 提供的可靠连接进行视频聊天或文件共享<sup>1</sup>。"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "英特尔 Evo™ 笔记本电脑",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "只&#xFEFF;需一&#xFEFF;台超&#xFEFF;能&#xFEFF;轻&#xFEFF;薄&#xFEFF;型<br>笔&#xFEFF;记&#xFEFF;本电&#xFEFF;脑<br>即&#xFEFF;可满&#xFEFF;足您&#xFEFF;的<br>各&#xFEFF;种需&#xFEFF;求",
                "tr_subtitle": "英特尔 Evo™ 笔记本电脑具备移动超能，无论进行什么操作，都能为您提供超能轻薄体验&#xfeff;<sup>2</sup>。",
                "titleArray": [
                  {
                    "tr_title": "笔记本电脑的用途已非比寻常，因此我们要保证它能堪当大任。",
                    "tr_subtitle": "使用触控屏来探索、旋转和缩放。"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "瞬间唤醒",
                    "tr_subtitle": "有了即时唤醒功能，电脑可以在不到一秒钟时间内被唤醒，准备开始工作。"
                  },
                  {
                    "tr_title": "沉浸式观看体验",
                    "tr_subtitle": "显示屏造型美观，画面清晰、色彩艳丽、栩栩如生。"
                  },
                  {
                    "tr_title": "颠覆性的性能",
                    "tr_subtitle": "借助第 13 代英特尔® 酷睿™ 处理器的智能性能，获得额外动力，满足核心需求。"
                  },
                  {
                    "tr_title": "不插电使用时间更长",
                    "tr_subtitle": "如果能延长电池续航时间、充电速度迅如闪电，工作就不会间断。"
                  },
                  {
                    "tr_title": "通用电缆连接",
                    "tr_subtitle": "借助雷电技术 4 将其他设备接&#65279;入&#65279;电&#65279;脑&#65279;，简单便捷，安全可靠。<sup>10</sup>"
                  },
                  {
                    "tr_title": "快速可靠的连接",
                    "tr_subtitle": "借助英特尔® Wi-Fi 6E (Gig+) 提供的可靠连接进行视频聊天或文件共享<sup>1</sup>。"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "新闻热点",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "什么是英特尔 Evo™ 版？",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "为什么选择英特尔 Evo™ 版？",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "创意",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "在更多地方发挥创意",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "全速照片编辑",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "即兴创作原创艺术",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "工作效率",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "利用 Rewind AI 最大限度地提高工作效率",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "利用 AI 在分享之前检测深度伪造",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "使用各种设备， 在&#65279;您&#65279;的&#65279;世&#65279;界&#65279;里&#65279;畅&#65279;游",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "移动性",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "体验手势控制",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "电池续航时间不会拖您的后腿",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "自动保持连接",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "新闻热点",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "什么是英特尔 Evo™ 版？",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "为什么选择英特尔 Evo™ 版？",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "创意",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "快速、流畅的视频播放",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "利用 AI 的强大功能制作热门歌曲",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "完成最终剪辑的最快途径",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "工作效率",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "利用 Rewind AI 最大限度地提高工作效率",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "利用 AI 在分享之前检测深度伪造",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "使用各种设备， 在&#65279;您&#65279;的&#65279;世&#65279;界&#65279;里&#65279;畅&#65279;游",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "移动性",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "几乎随时随地进行协作",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "电池续航时间不会拖您的后腿",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "自动保持连接",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "所有娱乐尽在一台电脑",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "对于一台可用作娱乐中心的电脑，需要为所有喜爱的歌曲、视频和游戏提供足够的存储空间，还需要合适的处理器和显示器组合，以打造沉浸式视觉体验。这款电脑三个方面齐备。",
                "titleArray": [
                  {
                    "tr_title": "游戏和直播效果令人惊叹",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "打造数字内容库",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "通往娱乐世界的窗口",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "无缝游戏和直播体验",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "打造数字内容库",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "通往娱乐世界的窗口",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "提高工作效率，随时随地",
              "tr_tileTitle1": "提高效率，快速实现",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "这款电脑在性能与便携性之间实现了完美的平衡。除了专为轻薄型设计而打造的处理器，这款电脑还拥有让您保持连接的 Wi&#8209;Fi 和让您不插电也能工作的大容量电池。",
                "tr_subtitle1": "此款电脑使功率与性能达到完美平衡，可以帮助您在更短的时间内完成更多工&#65279;作。由容量充足的内存加速的强大处理器，再加上快如闪电的 Wi-Fi，这些都&#65279;将简化您的日常任务。",
                "tr_subtitle2": "这款电脑在性能与便携性之间实现了完美的平衡。除了专为轻薄型设计而打造的处理器，这款电脑还拥有让您保持连接的 Wi‑Fi 和实现多任务处理所需的合适内存。",
                "titleArray": [
                  {
                    "tr_title": "智能至关重要",
                    "tr_title1": "智能性能实现的加速",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "一流的连接性能",
                    "tr_title1": "连接重要目标",
                    "tr_title2": "Wi‑Fi 的重大进步",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "电池续航更长",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "像专业人士一样玩转程序",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "自由创作",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "内容创作是一项对电脑要求最严苛的任务。不过，这些组件能够简化创作过程。",
                "titleArray": [
                  {
                    "tr_title": "加速实现创意",
                    "tr_title1": "如行云流水般完成创意项目",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "增强创作性能",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "内存助力实现多任务处理",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "深入了解这款电脑",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "这是选择新电脑时需要关注的三个要点。通过确定用户使用新电脑的方式，就能很好地判断用户需要这三个关键点的程度。具体包括：",
                "titleArray": [
                  {
                    "tr_title": "处理器",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "存储",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "内存",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "自由创作",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "内容创作是一项对电脑要求最严苛的任务。不过，这些组件能够简化创作过程。",
                "titleArray": [
                  {
                    "tr_title": "更智能的创作方式",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "为影像赋予生命力",
                    "tr_title1": "携手创造",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "内存助力实现多任务处理",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "提高工作效率，随时随地",
              "tr_tileTitle1": "提高效率，快速实现",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "这款电脑在性能与便携性之间实现了完美的平衡。除了专为轻薄型设计而打造的处理器，这款电脑还拥有让您保持连接的 Wi&#8209;Fi 和让您不插电也能工作的大容量电池。",
                "tr_subtitle1": "此款电脑使功率与性能达到完美平衡，可以帮助您在更短的时间内完成更多工&#65279;作。由容量充足的内存加速的强大处理器，再加上快如闪电的 Wi-Fi，这些都&#65279;将简化您的日常任务。",
                "tr_subtitle2": "这款电脑在性能与便携性之间实现了完美的平衡。除了专为轻薄型设计而打造的处理器，这款电脑还拥有让您保持连接的 Wi‑Fi 和实现多任务处理所需的合适内存。",
                "titleArray": [
                  {
                    "tr_title": "处理能力更强",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "一流的连接性能",
                    "tr_title1": "连接重要目标",
                    "tr_title2": "Wi‑Fi 的重大进步",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "电池续航更长",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "像专业人士一样玩转程序",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "虚拟现实就绪",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "虚拟现实震撼登场",
                "tr_subtitle": "它如何营造一个完全虚拟的世界？在电脑上，您需要处理器、显卡和内存之间的协同合作。",
                "titleArray": [
                  {
                    "tr_title": "助力虚拟世界",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "细节成就真实",
                    "tr_title1": "沉浸其中",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "Samsung OLED：<br/>缓解视觉疲劳的福音",
      "tr_sub_header": "蓝光会导致视觉不适，并扰乱你的睡眠周期。Samsung OLED 经过 SGS Eye Care Display 认证，不是通过改变颜色，而是通过减少可能有害的蓝光波长，呵护眼睛并减少视疲劳。",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "减少有害蓝光"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "方方面面都出众",
      "tr_sub_header": "OLED 超轻、超薄的设计可在最大限度提高图像质量的同时尽可能减轻重量。"
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "True Black",
      "tr_sub_header": "0.0005 尼特"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "74%<br/><span class='conventional_text'>传统</span>",
      "tr_sub_header": "120%<br/><span class='samsungoled_text'>Samsung OLED</span>"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "直播",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "直播",
                "tr_subtitle": "娱乐无极限",
                "tr_subtitle1": "我们发现这部电脑的以下特性有利于欣赏在线内容：",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "采用可在线观看高清内容的处理器。"
                  },
                  {
                    "tr_title": "配备可呈现明亮精美画面的显示屏。"
                  },
                  {
                    "tr_title": "搭载可缩短缓冲和加载时间的 Wi‑Fi。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "网页浏览",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "网页浏览",
                "tr_subtitle": "将互联网尽收眼底",
                "tr_subtitle1": "我们发现这部电脑的以下特性有利于网络浏览：",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "搭载可快速加载富媒体页面的处理器。"
                  },
                  {
                    "tr_title": "具备既快速又可靠的 Wi‑Fi。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "视频聊天",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "视频聊天",
                "tr_subtitle": "清晰通透的连接",
                "tr_subtitle1": "我们发现这部电脑的以下特性有利于视频聊天：",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "采用可同时录制和分享图像的处理器。"
                  },
                  {
                    "tr_title": "配备高速、强大、可靠的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "网络摄像头可清晰呈现您的影像。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "高速多任务处理",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "高速多任务处理",
                "tr_subtitle": "事半功倍，毫不减速",
                "tr_subtitle1": "我们发现这款电脑的以下特性有利于多任务处理：",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "搭载可同时搞定多个程序的处理器。"
                  },
                  {
                    "tr_title": "配备高速、强大、可靠的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "配备可无缝切换应用程序的内存。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "视频聊天",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "视频聊天",
                "tr_subtitle": "清晰通透的连接",
                "tr_subtitle1": "我们发现这部电脑的以下特性有利于视频聊天：",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "采用可同时录制和分享图像的处理器。"
                  },
                  {
                    "tr_title": "配备高速、强大、可靠的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "网络摄像头可清晰呈现您的影像。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "休闲游戏",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "休闲游戏",
                "tr_subtitle": "游戏时间到",
                "tr_subtitle1": "我们发现这款电脑的以下特性适合玩轻量级游戏：",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "搭载可流畅运行游戏的处理器。"
                  },
                  {
                    "tr_title": "配备支持同时语音聊天和直播的内存。"
                  },
                  {
                    "tr_title": "采用可呈现精美画面的显卡。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "照片编辑",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "照片编辑",
                "tr_subtitle": "像素出色的照片",
                "tr_subtitle1": "我们发现这部电脑的以下特性有利于照片编辑：",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "搭载可大批量编辑图像的强大处理器。"
                  },
                  {
                    "tr_title": "可加速剪辑软件的内存。"
                  },
                  {
                    "tr_title": "采用雷电技术 4，助您实现高速传输文件。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "高速多任务处理",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "高速多任务处理",
                "tr_subtitle": "事半功倍，毫不减速",
                "tr_subtitle1": "我们发现这款电脑的以下特性有利于多任务处理：",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "搭载可同时搞定多个程序的处理器。"
                  },
                  {
                    "tr_title": "配备高速、强大、可靠的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "配备可无缝切换应用程序的内存。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "实时协作",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "实时协作",
                "tr_subtitle": "随时随地投入工作",
                "tr_subtitle1": "我们发现这款电脑的以下特性有利于展开在线协作：",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "搭载可玩转办公室软件的处理器。"
                  },
                  {
                    "tr_title": "配备值得信赖的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "采用可完美录制高清视频的网络摄像头。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "视频编辑",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "视频编辑",
                "tr_subtitle": "创造光影奇迹",
                "tr_subtitle1": "我们发现这部电脑的以下特性有利于视频剪辑：",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "搭载可处理大型文件的处理器。"
                  },
                  {
                    "tr_title": "采用的内存可令剪辑软件变得快速而响应敏捷。"
                  },
                  {
                    "tr_title": "配备可缩短渲染时间的显卡。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "照片编辑",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "照片编辑",
                "tr_subtitle": "像素出色的照片",
                "tr_subtitle1": "我们发现这部电脑的以下特性有利于照片编辑：",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "搭载可大批量编辑图像的强大处理器。"
                  },
                  {
                    "tr_title": "可加速剪辑软件的内存。"
                  },
                  {
                    "tr_title": "采用雷电技术 4，助您实现高速传输文件。"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "[processor_value]",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "视频编辑",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "视频编辑",
                "tr_subtitle": "创造光影奇迹",
                "tr_subtitle1": "我们发现这部电脑的以下特性有利于视频剪辑：",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "搭载可处理大型文件的处理器。"
                  },
                  {
                    "tr_title": "采用的内存可令剪辑软件变得快速而响应敏捷。"
                  },
                  {
                    "tr_title": "配备可缩短渲染时间的显卡。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "3D 建模",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "3D 建模",
                "tr_subtitle": "让创作再上新高",
                "tr_subtitle1": "我们发现这部电脑的以下特性有利于 3D 建模：",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "搭载可处理高级创意软件的处理器。"
                  },
                  {
                    "tr_title": "配备可快速加载大型文件的内存。"
                  },
                  {
                    "tr_title": "采用面向图像创建和剪辑的强大显卡。"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "实时协作",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "实时协作",
                "tr_subtitle": "随时随地投入工作",
                "tr_subtitle1": "我们发现这款电脑的以下特性有利于展开在线协作：",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "搭载可玩转办公室软件的处理器。"
                  },
                  {
                    "tr_title": "配备值得信赖的 Wi‑Fi。"
                  },
                  {
                    "tr_title": "采用可完美录制高清视频的网络摄像头。"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "英特尔® Unison™ 应用",
    "tr_title": "解锁互联世界",
    "tr_subtitle": "无缝传输文件和照片，同时在电脑上管理手机通知、电话和短信。<sup>18</sup>",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "传输文件和照片",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "拨打和接听电话",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "发送和接收短信",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "管理手机通知",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "解锁互联世界",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}