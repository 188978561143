const Library_ChromeBook_mobile_it = {
  "uiText": {
    "tr_endlessProgramsAndApps": "Un'infinità di programmi e applicazioni",
    "tr_rethinkStorage": "Una rivoluzione per lo storage",
    "tr_alwaysOnSecurity": "Sicurezza integrata",
    "tr_extendedBatteryLife": "Durata prolungata della batteria<sup>1</sup>",
    "tr_viewMore": "Visualizza più contenuti",
    "tr_processorTitle": "PROCESSORE",
    "tr_processorTitleCamel": "Processore",
    "tr_rethinkStorageTitle": "UNA RIVOLUZIONE PER LO STORAGE",
    "tr_alwaysOnSecurityTitle": "SICUREZZA INTEGRATA",
    "tr_extendedBatteryLifeTitle": "DURATA PROLUNGATA DELLA BATTERIA<sup>1</sup>",
    "tr_endlessProgramsAndAppsTitle": "UN'INFINITÀ DI PROGRAMMI E APPLICAZIONI",
    "tr_extendedBatteryLifeDescription": "[XX] ore",
    "tr_rethinkStorageDescriptionGB": "[XX] GB",
    "tr_rethinkStorageDescriptionTB": "[XX] TB",
    "tr_OS": "OS",
    "tr_Chrome_OS": "ChromeOS*",
    "tr_textGBGB": "SSD da [convertedSSD] GB + HDD&nbsp;da&nbsp;[convertedHDD]&nbsp;GB",
    "tr_textGBTB": "SSD da [convertedSSD] GB + HDD&nbsp;da&nbsp;[convertedHDD]&nbsp;TB",
    "tr_textTBGB": "SSD da [convertedSSD] TB + HDD&nbsp;da&nbsp;[convertedHDD]&nbsp;GB",
    "tr_textTBTB": "SSD da [convertedSSD] TB + HDD&nbsp;da&nbsp;[convertedHDD]&nbsp;TB",
    "tr_textGBSSD": "SSD da [convertedSSD] GB",
    "tr_textTBSSD": "SSD da [convertedSSD] TB",
    "tr_textGBHDD": "HDD da [convertedHDD] GB",
    "tr_textTBHDD": "HDD da [convertedHDD] TB",
    "tr_graphicTitle": "GRAFICA",
    "tr_storageTitle": "STORAGE",
    "tr_displayTitle": "SCHERMO",
    "tr_batteryTitle": "BATTERIA",
    "tr_memoryTitle": "MEMORIA",
    "tr_priceTitle": "PREZZO"
  },
  "rethinkStorage": {
    "details": {
      "tr_tileTitle": "Una rivoluzione per lo storage",
      "tr_title": "Un approccio innovativo allo storage",
      "tr_subtitle": "I Chromebook* uniscono lo storage sul PC con le risorse quasi illimitate di Google* Drive nel cloud. Ecco i vantaggi:",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/cloud_upload.svg",
          "tr_badgeSubTitle": "Tutti i tuoi file sono automaticamente salvati nel cloud e per recuperarli ti basta accedere al tuo account Google*.",
          "badgeSubTitle": "cloud-upload"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Lavora online oppure offline con [XX] GB di storage sul dispositivo.",
          "badgeSubTitle": "GBALONE"
        },
        {
          "badgeUrl": "assets/images/badges/one_linear.svg",
          "tr_badgeSubTitle": "Lavora online oppure offline con [XX] TB di storage sul dispositivo.",
          "badgeSubTitle": "TBALONE"
        }
      ]
    }
  },
  "alwaysOnSecurity": {
    "details": {
      "tr_tileTitle": "Sicurezza integrata",
      "tr_title": "Protezione senza&nbsp;fatica<sup class='sup-text'>2</sup>",
      "tr_subtitle": "Chromebook* aggiorna automaticamente tutte le funzioni di sicurezza, così puoi navigare sul Web e fare clic sui link in tutta tranquillità, sapendo che il tuo dispositivo è protetto al meglio."
    }
  },
  "extendedBatteryLife": {
    "tr_tileTitle": "Durata prolungata della batteria<sup>1</sup>",
    "iposTypes": [
      {
        "iposType": "CHROMEBOOK",
        "details": {
          "tr_title": "Fai di più, ricarica&nbsp;meno<sup class='sup-text'>1</sup>",
          "tr_subtitle": "Il design semplificato ed efficiente dal punto di vista energetico dei Chromebook* consente di utilizzarli per ore senza doverli ricaricare."
        }
      },
      {
        "iposType": "CHROMEBOOKEVO",
        "details": {
          "tr_title": "Ricarica meno spesso<br/>e più rapidamente.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Il design semplificato ed efficiente dal punto di vista energetico di un Chromebook* soddisfa gli elevati requisiti della piattaforma Intel® Evo™ per la batteria, permettendoti di ricaricare più rapidamente, e meno spesso, il dispositivo."
        }
      },
      {
        "iposType": "CHROMEBOOKEVOGEN12",
        "details": {
          "tr_title": "Durata della batteria che ti mantiene in movimento.<sup class='sup-text'>10</sup>",
          "tr_subtitle": "Grazie alle batterie di lunga durata e a ricarica rapida nei Chromebook sottili e leggeri*, non devi preoccuparti di cercare la presa più vicina."
        }
      }
    ]
  },
  "endlessProgramsAndApps": {
    "details": {
      "tr_tileTitle": "Un'infinità di programmi e applicazioni",
      "tr_title": "Sempre più app ogni&nbsp;giorno",
      "tr_subtitle": "Gli utenti di Chromebook* dispongono di una selezione enorme di app e programmi tra cui scegliere a cui se ne aggiungono altri di continuo.",
      "badgeIcons": [
        {
          "badgeUrl": "assets/images/badges/download_badge.svg",
          "tr_badgeSubTitle": "Scarica le app Chrome* e Android*."
        },
        {
          "badgeUrl": "assets/images/badges/reset_badge.svg",
          "tr_badgeSubTitle": "Tutte le tue app e i tuoi dati sono&nbsp;sincronizzati tramite il tuo account Google*."
        },
        {
          "badgeUrl": "assets/images/badges/connect_badge.svg",
          "tr_badgeSubTitle": "Connettiti, impara, crea e gioca in modi infiniti."
        }
      ]
    }
  },
  "processor": [
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Oltre l'immaginazione",
                "tr_subtitle": "Guarda senza problemi le serie TV e i film che preferisci sul tuo Chromebook* con processore Intel® Core™ i3 di decima generazione.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 di&nbsp;decima generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Progettati per garantire prestazioni eccezionali in qualsiasi luogo.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;decima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Con processore Intel® Core™ i5 di decima generazione"
                  },
                  {
                    "tr_badgeTitle": "Potente anche se staccato dalla corrente"
                  },
                  {
                    "tr_badgeTitle": "Ricarica veloce: 4 ore di batteria in 30 minuti<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "A melhor conectividade da categoria com o Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Perfetti per il lavoro. Perfetti per il gioco.",
                "tr_subtitle": "Comunica in videochat, scrivi e-mail e prepara presentazioni mantenendo il massimo livello di produttività su un Chromebook* con processore Intel® Core™ i5 di decima generazione.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;decima generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Progettati per garantire prestazioni eccezionali in qualsiasi luogo.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;decima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Con processore Intel® Core™ i7 di decima generazione"
                  },
                  {
                    "tr_badgeTitle": "Potente anche se staccato dalla corrente"
                  },
                  {
                    "tr_badgeTitle": "Ricarica veloce: 4 ore di batteria in 30 minuti<sup>x</sup>"
                  },
                  {
                    "tr_badgeTitle": "A melhor conectividade da categoria com o Intel® Wi‑Fi 6 (Gig+)<sup>x</sup>"
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Le prestazioni sono importanti",
                "tr_subtitle": "Modifica video con facilità, carica e condividi all'istante i tuoi ricordi grazie a un Chromebook* con processore Intel® Core™ i7 di decima generazione.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;decima generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestazioni veloci e reattive",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel® Core™ i3 di ottava generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Alimenta le tue passioni e la tua produttività",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel® Core™ i5 di ottava generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Alimenta le tue passioni e la tua produttività",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;5"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Alimenta le tue passioni e la tua produttività",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;7"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor//INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Alimenta le tue passioni e la tua produttività",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;9"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestazioni veloci e reattive",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel® Core™ m3 di ottava generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestazioni elevate per le tue attività di oggi.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel® Core™ i5 di settima generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestazioni premium per le tue attività di oggi",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel® Core™ i7 di settima generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestazioni per le tue attività di oggi",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel® Core™ m3 di settima generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestazioni elevate per ciò che ti serve",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel® Core™ i5 di sesta generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_4.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestazioni avanzate per ciò che ti serve",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel® Core™ i7 di sesta generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_M3_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_M3_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestazioni per ciò che ti serve",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel® Core™ m3 di sesta generazione"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "La rivoluzione delle prestazioni per i Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 di&nbsp;undicesima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Prestazioni fino a <br/><span class='big-blue-text'>2,4 volte  </span><span class='subt-text'>migliori<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Navigazione Web fino a <br/><span class='big-blue-text'>3,2 volte  </span><span class='subt-text'>più veloce<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Editing di video fino a <br/><span class='big-blue-text'>2,1 volte  </span><span class='subt-text'>più rapido<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Se puoi immaginarlo, puoi farlo con un Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;undicesima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Prestazioni fino a <br/><span class='big-blue-text'>2,7 volte  </span><span class='subt-text'>migliori<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Navigazione Web fino a <br/><span class='big-blue-text'>3,3 volte  </span><span class='subt-text'>più veloce<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Editing di video fino a <br/><span class='big-blue-text'>2,8 volte  </span><span class='subt-text'>più rapido<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Progettato per prestazioni in movimento",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;undicesima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Prestazioni rivoluzionarie con i processori Intel® Core™ di undicesima generazione"
                  },
                  {
                    "tr_badgeTitle": "Si avvia in meno di un secondo<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet quasi tre volte più veloce grazie alla tecnologia Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batteria di lunga durata e ricarica rapida"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_CHROME.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Il miglior processore al mondo per i Chromebook*<sup class='sup-text'>1</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;undicesima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Prestazioni fino a <br/><span class='big-blue-text'>2,9 volte  </span><span class='subt-text'>migliori<sup>4</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Navigazione Web fino a <br/><span class='big-blue-text'>3,5 volte  </span><span class='subt-text'>più veloce<sup>5</sup></span>"
                  },
                  {
                    "tr_badgeTitle": "Editing di video fino a <br/><span class='big-blue-text'>2,9 volte  </span><span class='subt-text'>più rapido<sup>6</sup></span>"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Progettato per prestazioni in movimento",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;undicesima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Prestazioni rivoluzionarie con i processori Intel® Core™ di undicesima generazione"
                  },
                  {
                    "tr_badgeTitle": "Si avvia in meno di un secondo<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet quasi tre volte più veloce grazie alla tecnologia Intel®&nbsp;Wi&#8209;Fi&nbsp;6&nbsp;(Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batteria di lunga durata e ricarica rapida"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestazioni veloci per le attività quotidiane",
                "tr_subtitle": "Ottieni prestazioni eccellenti da tutti i tuoi programmi e le tue app preferiti grazie alla potenza di un processore Intel® Pentium®",
                "tr_gen_core_title": "Processore Intel® Pentium® Gold"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestazioni veloci per le attività quotidiane",
                "tr_subtitle": "Ottieni prestazioni eccellenti da tutti i tuoi programmi e le tue app preferiti grazie alla potenza di un processore Intel® Pentium®",
                "tr_gen_core_title": "Processore Intel® Pentium® Silver"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Migliora la tua produttività giornaliera",
                "tr_subtitle": "Dall'avvio di app all'apertura di progetti, un processore Intel® Celeron® ti aiuterà a fare di più.",
                "tr_gen_core_title": "Processore Intel® Celeron®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestazioni a costi contenuti.",
                "tr_subtitle": "Per alti livelli di intrattenimento, streaming di video e produttività. Goditi film e video sul Web con dettagli straordinari. Dedica più tempo alle tue attività e meno tempo alle attese.",
                "tr_gen_core_title": "Processore Intel®&nbsp;Pentium®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestazioni veloci per le attività quotidiane",
                "tr_subtitle": "Ottieni prestazioni eccellenti da tutti i tuoi programmi e le tue app preferiti grazie alla potenza di un processore Intel® Pentium®",
                "tr_gen_core_title": "Processore Intel®&nbsp;Pentium®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_GOLD_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestazioni a costi contenuti.",
                "tr_subtitle": "Produttività migliorata: prestazioni elevate per affrontare anche le giornate più impegnative. Navigazione Web semplificata: caricamento rapido delle pagine Web ricche di contenuti per una navigazione migliorata con meno attese.",
                "tr_gen_core_title": "Processore Intel® Pentium® Gold"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_PENTIUM_SILVER_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestazioni a costi contenuti.",
                "tr_subtitle": "Tieni sotto controllo il tuo mondo digitale con prestazioni adeguate per accedere ai file dal Web. Il multitasking semplice consente di passare facilmente da un programma all'altro e di fare di più in meno tempo.",
                "tr_gen_core_title": "Processore Intel® Pentium® Silver"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON_2",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Prestazioni a costi contenuti.",
                "tr_subtitle": "Prestazioni per potenziare il lavoro e il divertimento. Goditi film e video sul Web con dettagli straordinari. Dedica più tempo alle tue attività e meno tempo alle attese.",
                "tr_gen_core_title": "Processore Intel® Celeron®"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Prestazioni affidabili dove sono maggiormente necessarie.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 di&nbsp;dodicesima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitasking<br/>semplice"
                  },
                  {
                    "tr_badgeTitle": "Sfocatura dello sfondo<br/>integrata"
                  },
                  {
                    "tr_badgeTitle": "Navigazione web<br/>reattiva"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Un aumento delle prestazioni dove è maggiormente necessario.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;dodicesima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitasking<br/>potente"
                  },
                  {
                    "tr_badgeTitle": "Soppressione automatica<br/>del rumore"
                  },
                  {
                    "tr_badgeTitle": "Streaming<br/>HD"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Le prestazioni portatili si sono evolute.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;dodicesima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Prestazioni superiori dove sono maggiormente necessarie con i processori Intel® Core™ di dodicesima generazione<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Si avvia in meno di un secondo<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet quasi tre volte più veloce grazie alla tecnologia Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batteria di lunga durata e ricarica rapida"
                  },
                  {
                    "tr_badgeTitle": "Collaborazione e connettività migliorate"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Prestazioni superiori dove sono maggiormente necessarie.<sup>11</sup>",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;dodicesima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitasking<br/>estremo"
                  },
                  {
                    "tr_badgeTitle": "Soppressione del rumore<br/>e sfocatura dello sfondo<br/>integrate"
                  },
                  {
                    "tr_badgeTitle": "Streaming in 8K<br/>senza interruzioni"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Le prestazioni portatili si sono evolute.",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;dodicesima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Prestazioni superiori dove sono maggiormente necessarie con i processori Intel® Core™ di dodicesima generazione<sup>11</sup>"
                  },
                  {
                    "tr_badgeTitle": "Si avvia in meno di un secondo<sup>8</sup>"
                  },
                  {
                    "tr_badgeTitle": "Internet quasi tre volte più veloce grazie alla tecnologia Intel® Wi‑Fi 6/6E (Gig+)<sup>9</sup>"
                  },
                  {
                    "tr_badgeTitle": "Batteria di lunga durata e ricarica rapida"
                  },
                  {
                    "tr_badgeTitle": "Collaborazione e connettività migliorate"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "L'evoluzione<br/>del Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;tredicesima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Design sottile e leggero che va oltre le prestazioni<br/><span class='marklist'>e</span> si connette facilmente con il tuo telefono esistente <sup>13</sup><br/><span class='marklist'>e</span> seleziona in modo intelligente la migliore connessione Wi-Fi o 5G<br/><span class='marklist'>e</span> si ricarica velocemente con una batteria che dura a lungo"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Continua a creare con prestazioni eccezionali",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5 di&nbsp;tredicesima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitasking<br/>avanzato"
                  },
                  {
                    "tr_badgeTitle": "Streaming fluido<br/>in 4K"
                  },
                  {
                    "tr_badgeTitle": "Creazione<br/>potente"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I7_12GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "L'evoluzione<br/>del Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;tredicesima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Design sottile e leggero che va oltre le prestazioni<br/><span class='marklist'>e</span> si connette facilmente con il tuo telefono esistente <sup>13</sup><br/><span class='marklist'>e</span> seleziona in modo intelligente la migliore connessione Wi-Fi o 5G<br/><span class='marklist'>e</span> si ricarica velocemente con una batteria che dura a lungo"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Continua a creare con prestazioni eccezionali",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7 di&nbsp;tredicesima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitasking<br/>estremo"
                  },
                  {
                    "tr_badgeTitle": "Streaming fluido<br/>in 8K"
                  },
                  {
                    "tr_badgeTitle": "Creazione<br/>avanzata"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "Processore Intel®",
                "tr_subtitle": "Le prestazioni di cui hai bisogno. La convenienza che vuoi.",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Continua a creare con prestazioni eccezionali",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3 di&nbsp;tredicesima generazione",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitasking<br/>semplice"
                  },
                  {
                    "tr_badgeTitle": "Streaming<br/>fluido"
                  },
                  {
                    "tr_badgeTitle": "Creazione rapida<br/>di contenuti"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I3_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "L'evoluzione<br/>del Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Design sottile e leggero che va oltre le prestazioni<br/><span class='marklist'>e</span> si connette facilmente con il tuo telefono esistente <sup>13</sup><br/><span class='marklist'>e</span> seleziona in modo intelligente la migliore connessione Wi-Fi o 5G<br/><span class='marklist'>e</span> si ricarica velocemente con una batteria che dura a lungo"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Continua a creare con prestazioni eccezionali",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitasking<br/>semplice"
                  },
                  {
                    "tr_badgeTitle": "Streaming<br/>fluido"
                  },
                  {
                    "tr_badgeTitle": "Creazione rapida<br/>di contenuti"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I5_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "L'evoluzione<br/>del Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Design sottile e leggero che va oltre le prestazioni<br/><span class='marklist'>e</span> si connette facilmente con il tuo telefono esistente <sup>13</sup><br/><span class='marklist'>e</span> seleziona in modo intelligente la migliore connessione Wi-Fi o 5G<br/><span class='marklist'>e</span> si ricarica velocemente con una batteria che dura a lungo"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Continua a creare con prestazioni eccezionali",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitasking<br/>avanzato"
                  },
                  {
                    "tr_badgeTitle": "Streaming fluido<br/>in 4K"
                  },
                  {
                    "tr_badgeTitle": "Creazione<br/>potente"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "L'evoluzione<br/>del Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Design sottile e leggero che va oltre le prestazioni<br/><span class='marklist'>e</span> si connette facilmente con il tuo telefono esistente <sup>13</sup><br/><span class='marklist'>e</span> seleziona in modo intelligente la migliore connessione Wi-Fi o 5G<br/><span class='marklist'>e</span> si ricarica velocemente con una batteria che dura a lungo"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_13.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Continua a creare con prestazioni eccezionali",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitasking<br/>estremo"
                  },
                  {
                    "tr_badgeTitle": "Streaming fluido<br/>in 8K"
                  },
                  {
                    "tr_badgeTitle": "Creazione<br/>avanzata"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_14",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_I7_14GEN.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "L'evoluzione<br/>del Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Design sottile e leggero che va oltre le prestazioni<br/><span class='marklist'>e</span> si connette facilmente con il tuo telefono esistente <sup>13</sup><br/><span class='marklist'>e</span> seleziona in modo intelligente la migliore connessione Wi-Fi o 5G<br/><span class='marklist'>e</span> si ricarica velocemente con una batteria che dura a lungo"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Potenzia le tue passioni con prestazioni superiori",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;i9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Editing veloce di<br/>foto e video"
                  },
                  {
                    "tr_badgeTitle": "Funzionalità offline<br/>migliorate"
                  },
                  {
                    "tr_badgeTitle": "Gioca <br/>senza problemi<br/> grazie alla lunga durata della batteria"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_3",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "L'evoluzione<br/>del Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Design sottile e leggero che va oltre le prestazioni<br/><span class='marklist'>e</span> si connette facilmente con il tuo telefono esistente <sup>13</sup><br/><span class='marklist'>e</span> seleziona in modo intelligente la migliore connessione Wi-Fi o 5G<br/><span class='marklist'>e</span> si ricarica velocemente con una batteria che dura a lungo"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/Intel_Core_3.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Continua a creare con prestazioni eccezionali",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;3",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitasking<br/>semplice"
                  },
                  {
                    "tr_badgeTitle": "Streaming<br/>fluido"
                  },
                  {
                    "tr_badgeTitle": "Creazione rapida<br/>di contenuti"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_5",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "L'evoluzione<br/>del Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Design sottile e leggero che va oltre le prestazioni<br/><span class='marklist'>e</span> si connette facilmente con il tuo telefono esistente <sup>13</sup><br/><span class='marklist'>e</span> seleziona in modo intelligente la migliore connessione Wi-Fi o 5G<br/><span class='marklist'>e</span> si ricarica velocemente con una batteria che dura a lungo"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/Intel_Core_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Continua a creare con prestazioni eccezionali",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitasking<br/>avanzato"
                  },
                  {
                    "tr_badgeTitle": "Streaming fluido<br/>in 4K"
                  },
                  {
                    "tr_badgeTitle": "Creazione<br/>potente"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_7",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "L'evoluzione<br/>del Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Design sottile e leggero che va oltre le prestazioni<br/><span class='marklist'>e</span> si connette facilmente con il tuo telefono esistente <sup>13</sup><br/><span class='marklist'>e</span> seleziona in modo intelligente la migliore connessione Wi-Fi o 5G<br/><span class='marklist'>e</span> si ricarica velocemente con una batteria che dura a lungo"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/Intel_Core_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Continua a creare con prestazioni eccezionali",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Multitasking<br/>estremo"
                  },
                  {
                    "tr_badgeTitle": "Streaming fluido<br/>in 8K"
                  },
                  {
                    "tr_badgeTitle": "Creazione<br/>avanzata"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_5_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_5.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "L'evoluzione<br/>del Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Design sottile e leggero che va oltre le prestazioni<br/><span class='marklist'>e</span> si connette facilmente con il tuo telefono esistente <sup>13</sup><br/><span class='marklist'>e</span> seleziona in modo intelligente la migliore connessione Wi-Fi o 5G<br/><span class='marklist'>e</span> si ricarica velocemente con una batteria che dura a lungo"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Libera nuove esperienze IA",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;5",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "IA generativa <br/>fino al 65% più veloce"
                  },
                  {
                    "tr_badgeTitle": "Editing di video <br/>fino a 2,3 volte più veloce con l'IA"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_7_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_7.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "L'evoluzione<br/>del Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Design sottile e leggero che va oltre le prestazioni<br/><span class='marklist'>e</span> si connette facilmente con il tuo telefono esistente <sup>13</sup><br/><span class='marklist'>e</span> seleziona in modo intelligente la migliore connessione Wi-Fi o 5G<br/><span class='marklist'>e</span> si ricarica velocemente con una batteria che dura a lungo"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Libera nuove esperienze IA",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;7",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "IA generativa <br/>fino al 65% più veloce"
                  },
                  {
                    "tr_badgeTitle": "Editing di video <br/>fino a 2,3 volte più veloce con l'IA"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ULTRA_9_MTL",
      "iposTypes": [
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/EVO_ULTRA_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop",
                "tr_title": "L'evoluzione<br/>del Chromebook*",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "Design sottile e leggero che va oltre le prestazioni<br/><span class='marklist'>e</span> si connette facilmente con il tuo telefono esistente <sup>13</sup><br/><span class='marklist'>e</span> seleziona in modo intelligente la migliore connessione Wi-Fi o 5G<br/><span class='marklist'>e</span> si ricarica velocemente con una batteria che dura a lungo"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "Processore",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_pretitle": "",
                "panelType1": "chromebook-header-BadgeIconTop-11Gen",
                "tr_title": "Libera nuove esperienze IA",
                "tr_subtitle": "",
                "tr_gen_core_title": "Processore Intel®&nbsp;Core™ Ultra&nbsp;9",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "IA generativa <br/>fino al 65% più veloce"
                  },
                  {
                    "tr_badgeTitle": "Editing di video <br/>fino a 2,3 volte più veloce con l'IA"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "legalText": {
    "tr_specTitle": "Informazioni legali",
    "tr_paragraph_array": [
      "¹Fino a 10 ore di durata della batteria a piena carica, secondo i test interni di Google*.",
      "²Nessun prodotto o componente può essere totalmente sicuro. Per ulteriori informazioni, rivolgersi al produttore o al rivenditore del sistema o consultare il sito intel.com.",
      "³Misurazioni effettuate tramite benchmark del settore, test della Guida all'uso rappresentativo e funzionalità esclusive del processore Intel® Core™ i7-1165G7. Per i carichi di lavoro e le configurazioni, consultare www.intel.com/PerformanceIndex. I risultati effettivi possono variare.",
      "⁴Misurazioni effettuate tramite multitasking con Fogli Google.",
      "⁵Misurazioni effettuate tramite Speedometer 2.0.",
      "⁶Misurazioni effettuate tramite la funzionalità di esportazione dei video di KineMaster.",
      "Per le note 4-6, tutti i test sono stati condotti confrontando il processore Intel® Core™ i3-1115G4 con il processore Intel® Celeron® N4020. Visitare la pagina www.intel.com/PerformanceIndex per ulteriori dettagli sui carichi di lavoro e sulle configurazioni. Le prestazioni variano in base all'utilizzo, alla configurazione e ad altri fattori. Maggiori informazioni su www.intel.com/PerformanceIndex. I risultati prestazionali sono basati su test eseguiti nelle date indicate nelle configurazioni e potrebbero non riflettere tutti gli aggiornamenti di sicurezza pubblicamente disponibili.",
      "⁷Tempo impiegato per passare da una carica del 100% a un livello critico della batteria durante lo svolgimento di flussi di lavoro tipici in un ambiente realistico. I risultati possono variare. Dettagli su intel.com/evo.",
      "⁸I risultati possono variare. Dettagli su intel.com/evo.",
      "⁹Quasi 3 volte più veloce: lo standard 802.11ax 2x2 a 160 MHz consente velocità teoriche massime di trasferimento dei dati di 2402 Mbps. ~3 volte più veloce dello standard Wi‑Fi 802.11ac 2x2 a 80 MHz (867 Mbps), come documentato nelle specifiche dello standard wireless IEEE 802.11* e richiede l'uso di router di rete wireless 802.11ax configurati in modo simile.",
      "¹⁰Ricarica raggiunta dal livello di arresto predefinito dell'OEM. I risultati possono variare. Dettagli su intel.com/evo.",
      "¹¹Rispetto ai processori di generazione precedente.",
      "¹²Misurazioni effettuate con funzionalità esclusive dei design Intel® Evo™, come le CPU Intel® Core™ a elevate prestazioni, i componenti premium, la compatibilità senza paragoni e le più recenti soluzioni di connettività nei design eleganti e innovativi. Tutti i design Intel® Evo™ devono soddisfare i severi limiti previsti per le esperienze degli utenti di sistemi portatili, come la reattività e la durata della batteria; le prestazioni dei singoli dispositivi possono variare. Maggiori informazioni su www.intel.com/PerformanceIndex (piattaforme).",
      "¹³La soluzione Intel® Unison™ è attualmente disponibile solo sui design Intel® Evo™ idonei sui PC Windows* e solo su telefoni Android* o iOS*. Tutti i dispositivi devono eseguire la versione del sistema operativo supportata. Le prestazioni individuali dei dispositivi Intel® Evo™ possono variare. Maggiori informazioni su intel.com/Performance-Evo.",
      "Le caratteristiche e i vantaggi delle tecnologie Intel® dipendono dalla configurazione di sistema e potrebbero richiedere hardware e software abilitati o l'attivazione di servizi. Le prestazioni variano in base alla configurazione di sistema. Nessun sistema informatico può essere totalmente sicuro. Per ulteriori informazioni, rivolgersi al produttore o al rivenditore del sistema o consultare il sito intel.com.",
      "* Altri marchi e altre denominazioni potrebbero essere rivendicati da terzi.",
      "Intel non si assume responsabilità per prezzi non corretti.",
      "© 2022 Intel Corporation. Celeron, Intel, il logo Intel, Intel Core e Pentium sono marchi di Intel Corporation o di società controllate da Intel."
    ]
  }
}