const Library_mobile_gls = {
  "processor": [
    {
      "contentType": "OTHER_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[0].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "processor[0].iposTypes[0].deviceTypes[0].details.tr_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_PROCESSORS_A",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[1].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "processor[1].iposTypes[0].deviceTypes[0].details.tr_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "FALLBACK_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[2].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "processor[2].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "processor[2].iposTypes[0].deviceTypes[0].details.tr_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHER_OLD_PROCESSORS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[3].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_title": "processor[3].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "processor[3].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_pretitle",
                    "tr_subtitle": "processor[3].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_subtitle",
                    "tr_gen_core_title": "processor[3].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_gen_core_title"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "processor[3].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_pretitle",
                    "tr_subtitle": "processor[3].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_subtitle",
                    "tr_gen_core_title": "processor[3].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_gen_core_title"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "processor[3].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_pretitle",
                    "tr_subtitle": "processor[3].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_subtitle",
                    "tr_gen_core_title": "processor[3].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_gen_core_title"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "processor[3].iposTypes[0].deviceTypes[0].details.titleArray[3].tr_pretitle",
                    "tr_subtitle": "processor[3].iposTypes[0].deviceTypes[0].details.titleArray[3].tr_subtitle",
                    "tr_gen_core_title": "processor[3].iposTypes[0].deviceTypes[0].details.titleArray[3].tr_gen_core_title"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_1.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CELERON_2.svg"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_PENTIUM_2.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_INSIDE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[4].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/intel_inside/intel_inside_processor_badge.svg",
                "tileBG": "assets/images/intel_inside/intel_inside_home_tile.svg",
                "detailBGUrl": "assets/images/intel_inside/panel1_bg.svg",
                "tr_subtitle": "processor[4].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[4].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "header-IconTop",
                "panelType2": "intel-inside-image-panel",
                "titleArray": [
                  {
                    "tr_title": "",
                    "tr_pretitle": "processor[4].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_pretitle",
                    "tr_subtitle": "processor[4].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_subtitle",
                    "imageUrl": "assets/images/intel_inside/panel2_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "processor[4].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_pretitle",
                    "tr_subtitle": "processor[4].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_subtitle",
                    "imageUrl": "assets/images/intel_inside/panel3_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "tr_pretitle": "processor[4].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_pretitle",
                    "tr_subtitle": "processor[4].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_subtitle",
                    "imageUrl": "assets/images/intel_inside/panel4_bg.svg"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "processor[4].iposTypes[0].deviceTypes[0].details.titleArray[3].tr_pretitle",
                    "tr_subtitle": "processor[4].iposTypes[0].deviceTypes[0].details.titleArray[3].tr_subtitle"
                  },
                  {
                    "tr_title": "",
                    "imageUrl": "assets/images/intel_inside/panel5_bg.svg",
                    "tr_pretitle": "processor[4].iposTypes[0].deviceTypes[0].details.titleArray[4].tr_pretitle",
                    "tr_subtitle": "processor[4].iposTypes[0].deviceTypes[0].details.titleArray[4].tr_subtitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CELERON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[5].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[5].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[5].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[5].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorCompare": {
                  "tr_title": "processor[5].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "processor[5].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CELERON_3.svg",
                      "tr_imgCaption": "processor[5].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "processor[5].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "processor[5].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[5].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[5].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[5].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[5].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[5].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[5].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[5].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "7GEN_BELOW_PROCESSOR",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[6].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/[cup_badge].svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "genType": "7Gen",
                    "tr_title": "processor[6].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_title"
                  },
                  {
                    "genType": "6Gen",
                    "tr_title": "processor[6].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_title"
                  },
                  {
                    "genType": "5Gen",
                    "tr_title": "processor[6].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_title"
                  },
                  {
                    "genType": "4Gen",
                    "tr_title": "processor[6].iposTypes[0].deviceTypes[0].details.titleArray[3].tr_title"
                  },
                  {
                    "genType": "3Gen",
                    "tr_title": "processor[6].iposTypes[0].deviceTypes[0].details.titleArray[4].tr_title"
                  },
                  {
                    "genType": "2Gen",
                    "tr_title": "processor[6].iposTypes[0].deviceTypes[0].details.titleArray[5].tr_title"
                  },
                  {
                    "genType": "1Gen",
                    "tr_title": "processor[6].iposTypes[0].deviceTypes[0].details.titleArray[6].tr_title"
                  }
                ],
                "processorCompare": {
                  "tr_title": "",
                  "background": "",
                  "tr_tableHead": [
                    "processor[6].iposTypes[0].deviceTypes[0].details.processorCompare.tr_tableHead[0]",
                    "processor[6].iposTypes[0].deviceTypes[0].details.processorCompare.tr_tableHead[1]",
                    "processor[6].iposTypes[0].deviceTypes[0].details.processorCompare.tr_tableHead[2]"
                  ],
                  "tabletr": [
                    {
                      "tr_row": "",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[7].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "processor[7].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[7].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[8].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[8].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[8].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[8].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[9].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[9].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[9].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[9].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorCompare": {
                  "tr_title": "processor[9].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "processor[9].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "processor[9].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "processor[9].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "processor[9].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[9].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[9].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[9].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[9].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[9].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[9].iposTypes[0].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[9].iposTypes[0].deviceTypes[1].details.tr_subtitle",
                "tr_title": "processor[9].iposTypes[0].deviceTypes[1].details.tr_title",
                "tr_gen_core_title": "processor[9].iposTypes[0].deviceTypes[1].details.tr_gen_core_title"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "processor[9].iposTypes[0].deviceTypes[2].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[9].iposTypes[0].deviceTypes[2].details.tr_subtitle",
                "tr_title": "processor[9].iposTypes[0].deviceTypes[2].details.tr_title",
                "tr_gen_core_title": "processor[9].iposTypes[0].deviceTypes[2].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[10].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "processor[10].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[10].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[10].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[10].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "processor[10].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[10].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[10].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[10].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[10].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[10].iposTypes[0].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "processor[10].iposTypes[0].deviceTypes[1].details.tr_subtitle",
                "tr_title": "processor[10].iposTypes[0].deviceTypes[1].details.tr_title",
                "tr_gen_core_title": "processor[10].iposTypes[0].deviceTypes[1].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[10].iposTypes[0].deviceTypes[1].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[11].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_6.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "processor[11].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[11].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[12].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[12].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[12].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[12].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[12].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[12].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[12].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[12].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[12].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[12].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[12].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[12].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[12].iposTypes[1].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "processor[12].iposTypes[1].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "processor[12].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[0].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "processor[12].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "processor[12].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "",
                      "tr_imgCaption": "processor[12].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[12].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[12].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[12].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[12].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "processor[12].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[0].tr_title",
                      "tr_subTitle": "processor[12].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[12].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[1].tr_title",
                      "tr_subTitle": "processor[12].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[12].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[2].tr_title",
                      "tr_subTitle": "processor[12].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[2].tr_subTitle"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[13].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[13].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[13].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[13].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorCompare": {
                  "tr_title": "processor[13].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "processor[13].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "processor[13].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "processor[13].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "processor[13].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[13].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[13].iposTypes[0].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[13].iposTypes[0].deviceTypes[1].details.tr_subtitle",
                "tr_title": "processor[13].iposTypes[0].deviceTypes[1].details.tr_title",
                "tr_gen_core_title": "processor[13].iposTypes[0].deviceTypes[1].details.tr_gen_core_title"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "processor[13].iposTypes[0].deviceTypes[2].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[13].iposTypes[0].deviceTypes[2].details.tr_subtitle",
                "tr_title": "processor[13].iposTypes[0].deviceTypes[2].details.tr_title",
                "tr_gen_core_title": "processor[13].iposTypes[0].deviceTypes[2].details.tr_gen_core_title"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[13].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[13].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[13].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[13].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[13].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[13].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[13].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[13].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[13].iposTypes[1].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[0].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[5].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[6].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[0].tr_title",
                      "tr_subTitle": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[1].tr_title",
                      "tr_subTitle": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[2].tr_title",
                      "tr_subTitle": "processor[13].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[2].tr_subTitle"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[13].iposTypes[1].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[13].iposTypes[1].deviceTypes[1].details.tr_subtitle",
                "tr_title": "processor[13].iposTypes[1].deviceTypes[1].details.tr_title",
                "tr_gen_core_title": "processor[13].iposTypes[1].deviceTypes[1].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[13].iposTypes[1].deviceTypes[1].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[13].iposTypes[1].deviceTypes[1].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[13].iposTypes[1].deviceTypes[1].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[13].iposTypes[1].deviceTypes[1].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[13].iposTypes[1].deviceTypes[1].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.table_head[0].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.tabletr[5].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.tabletr[6].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.table_footer[0].tr_title",
                      "tr_subTitle": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.table_footer[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.table_footer[1].tr_title",
                      "tr_subTitle": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.table_footer[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.table_footer[2].tr_title",
                      "tr_subTitle": "processor[13].iposTypes[1].deviceTypes[1].details.processorCompare.table_footer[2].tr_subTitle"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[13].iposTypes[2].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[13].iposTypes[2].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[13].iposTypes[2].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[13].iposTypes[2].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[13].iposTypes[2].deviceTypes[0].details.tr_gen_core_title_uppercase"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[14].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "processor[14].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[14].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[14].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[14].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "processor[14].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[14].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[14].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[14].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[14].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[14].iposTypes[0].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "processor[14].iposTypes[0].deviceTypes[1].details.tr_subtitle",
                "tr_title": "processor[14].iposTypes[0].deviceTypes[1].details.tr_title",
                "tr_gen_core_title": "processor[14].iposTypes[0].deviceTypes[1].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[14].iposTypes[0].deviceTypes[1].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/standard/i5_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[14].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[14].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[14].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[14].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[14].iposTypes[1].deviceTypes[0].details.tr_gen_core_title_uppercase"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[15].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_6.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "tr_title": "processor[15].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[15].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[16].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "processor[16].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[16].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[16].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[16].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[16].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[16].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[16].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[16].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[16].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[16].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[16].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[16].iposTypes[1].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "processor[16].iposTypes[1].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "processor[16].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "processor[16].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[1].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "processor[16].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_7.svg",
                      "tr_imgCaption": "processor[16].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[16].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[16].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[16].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[16].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "processor[16].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[0].tr_title",
                      "tr_subTitle": "processor[16].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[16].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[1].tr_title",
                      "tr_subTitle": "processor[16].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[16].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[2].tr_title",
                      "tr_subTitle": "processor[16].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[2].tr_subTitle"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_8",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[17].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[17].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[17].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[17].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorCompare": {
                  "tr_title": "processor[17].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_8.svg",
                      "tr_imgCaption": "processor[17].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "processor[17].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "processor[17].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "processor[17].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[17].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[17].iposTypes[0].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[17].iposTypes[0].deviceTypes[1].details.tr_subtitle",
                "tr_title": "processor[17].iposTypes[0].deviceTypes[1].details.tr_title",
                "tr_gen_core_title": "processor[17].iposTypes[0].deviceTypes[1].details.tr_gen_core_title"
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "processor[17].iposTypes[0].deviceTypes[2].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[17].iposTypes[0].deviceTypes[2].details.tr_subtitle",
                "tr_title": "processor[17].iposTypes[0].deviceTypes[2].details.tr_title",
                "tr_gen_core_title": "processor[17].iposTypes[0].deviceTypes[2].details.tr_gen_core_title"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[17].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[17].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[17].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[17].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[17].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[17].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[17].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[17].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[17].iposTypes[1].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[1].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[5].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[6].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[0].tr_title",
                      "tr_subTitle": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[1].tr_title",
                      "tr_subTitle": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[2].tr_title",
                      "tr_subTitle": "processor[17].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[2].tr_subTitle"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[17].iposTypes[1].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[17].iposTypes[1].deviceTypes[1].details.tr_subtitle",
                "tr_title": "processor[17].iposTypes[1].deviceTypes[1].details.tr_title",
                "tr_gen_core_title": "processor[17].iposTypes[1].deviceTypes[1].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[17].iposTypes[1].deviceTypes[1].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[17].iposTypes[1].deviceTypes[1].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[17].iposTypes[1].deviceTypes[1].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[17].iposTypes[1].deviceTypes[1].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[17].iposTypes[1].deviceTypes[1].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.table_head[1].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.tabletr[5].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.tabletr[6].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.table_footer[0].tr_title",
                      "tr_subTitle": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.table_footer[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.table_footer[1].tr_title",
                      "tr_subTitle": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.table_footer[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.table_footer[2].tr_title",
                      "tr_subTitle": "processor[17].iposTypes[1].deviceTypes[1].details.processorCompare.table_footer[2].tr_subTitle"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[17].iposTypes[2].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[17].iposTypes[2].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[17].iposTypes[2].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[17].iposTypes[2].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[17].iposTypes[2].deviceTypes[0].details.tr_gen_core_title_uppercase"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[18].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "processor[18].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[18].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[18].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[18].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_laptop_11gen.svg",
                "enhancementCompare": {
                  "tr_title": "processor[18].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[18].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[18].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[18].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[18].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[18].iposTypes[0].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "processor[18].iposTypes[0].deviceTypes[1].details.tr_subtitle",
                "tr_title": "processor[18].iposTypes[0].deviceTypes[1].details.tr_title",
                "tr_gen_core_title": "processor[18].iposTypes[0].deviceTypes[1].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[18].iposTypes[0].deviceTypes[1].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/standard/i7_uwp_tile_badge_desktop_11gen.png"
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[18].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[18].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[18].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[18].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[18].iposTypes[1].deviceTypes[0].details.tr_gen_core_title_uppercase"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[18].iposTypes[1].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[18].iposTypes[1].deviceTypes[1].details.tr_subtitle",
                "tr_title": "processor[18].iposTypes[1].deviceTypes[1].details.tr_title",
                "tr_gen_core_title": "processor[18].iposTypes[1].deviceTypes[1].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[18].iposTypes[1].deviceTypes[1].details.tr_gen_core_title_uppercase"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_1195G7",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[19].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[19].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[19].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[19].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[19].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[20].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "processor[20].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[20].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[21].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_7.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[21].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[21].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[21].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[21].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[21].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[21].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[21].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[21].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[21].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[21].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[21].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[21].iposTypes[1].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "processor[21].iposTypes[1].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_7.svg",
                      "tr_imgCaption": "processor[21].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_7.svg",
                      "tr_imgCaption": "processor[21].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "processor[21].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[2].tr_table_heading",
                      "imgUrl": "",
                      "tr_imgCaption": "processor[21].iposTypes[1].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[21].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[21].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[21].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[21].iposTypes[1].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "processor[21].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[0].tr_title",
                      "tr_subTitle": "processor[21].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[21].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[1].tr_title",
                      "tr_subTitle": "processor[21].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[21].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[2].tr_title",
                      "tr_subTitle": "processor[21].iposTypes[1].deviceTypes[0].details.processorCompare.table_footer[2].tr_subTitle"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_8",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[22].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[22].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[22].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[22].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[22].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[22].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[22].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[22].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[22].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[5].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[6].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[0].tr_title",
                      "tr_subTitle": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[1].tr_title",
                      "tr_subTitle": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[2].tr_title",
                      "tr_subTitle": "processor[22].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[2].tr_subTitle"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[22].iposTypes[0].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[22].iposTypes[0].deviceTypes[1].details.tr_subtitle",
                "tr_title": "processor[22].iposTypes[0].deviceTypes[1].details.tr_title",
                "tr_gen_core_title": "processor[22].iposTypes[0].deviceTypes[1].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[22].iposTypes[0].deviceTypes[1].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[22].iposTypes[0].deviceTypes[1].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[22].iposTypes[0].deviceTypes[1].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[22].iposTypes[0].deviceTypes[1].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[22].iposTypes[0].deviceTypes[1].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.table_head[2].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                      "tr_imgCaption": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.tabletr[5].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.tabletr[6].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.table_footer[0].tr_title",
                      "tr_subTitle": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.table_footer[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.table_footer[1].tr_title",
                      "tr_subTitle": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.table_footer[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.table_footer[2].tr_title",
                      "tr_subTitle": "processor[22].iposTypes[0].deviceTypes[1].details.processorCompare.table_footer[2].tr_subTitle"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[22].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_8.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[22].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[22].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[22].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[22].iposTypes[1].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[22].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[22].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[22].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[22].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[22].iposTypes[1].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[23].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I5_5.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[23].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[23].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[23].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[23].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[24].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I7.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i7-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[24].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[24].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[24].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[24].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/evo/11gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_SILVER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[25].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[25].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[25].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[25].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorCompare": {
                  "tr_title": "processor[25].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "processor[25].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "processor[25].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "processor[25].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "processor[25].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[25].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[25].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[25].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[25].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[25].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[25].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[5].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "PENTIUM_GOLD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[26].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[26].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[26].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[26].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorCompare": {
                  "tr_title": "processor[26].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_SILVER_2.svg",
                      "tr_imgCaption": "processor[26].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "processor[26].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_PENTIUM_GOLD_2.svg",
                      "tr_imgCaption": "processor[26].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_imgCaption": "processor[26].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[26].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[26].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[26].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[26].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[26].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[26].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[5].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[27].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "processor[27].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[27].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[27].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[27].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[27].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[27].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[27].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[27].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "processor[27].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[27].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[27].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[27].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "processor[27].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_td1_title",
                      "tr_td2_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_td2_title",
                      "tr_td3_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_td3_title"
                    },
                    {
                      "tr_td1_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_td1_title",
                      "tr_td2_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_td2_title",
                      "tr_td3_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_td3_title"
                    },
                    {
                      "tr_td1_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_td1_title",
                      "tr_td2_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_td2_title",
                      "tr_td3_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_td3_title"
                    },
                    {
                      "tr_td1_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_td1_title",
                      "tr_td2_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_td2_title",
                      "tr_td3_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_td3_title"
                    },
                    {
                      "tr_td1_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_td1_title",
                      "tr_td2_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_td2_title",
                      "tr_td3_title": "processor[27].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_td3_title"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[28].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "processor[28].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[28].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[28].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[28].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[28].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[28].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[28].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[28].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "processor[28].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[28].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[28].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[28].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": "",
                      "active": true
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "processor[28].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": "",
                      "active": false
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_td1_title",
                      "tr_td2_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_td2_title",
                      "tr_td3_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_td3_title"
                    },
                    {
                      "tr_td1_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_td1_title",
                      "tr_td2_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_td2_title",
                      "tr_td3_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_td3_title"
                    },
                    {
                      "tr_td1_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_td1_title",
                      "tr_td2_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_td2_title",
                      "tr_td3_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_td3_title"
                    },
                    {
                      "tr_td1_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_td1_title",
                      "tr_td2_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_td2_title",
                      "tr_td3_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_td3_title"
                    },
                    {
                      "tr_td1_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_td1_title",
                      "tr_td2_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_td2_title",
                      "tr_td3_title": "processor[28].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_td3_title"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_SPECIAL_EDITION_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[29].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/INTEL_CORE_I5_H35.png",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat2",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "processor[29].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[29].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[29].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[29].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[29].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[29].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[29].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[29].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "processor[29].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[29].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[29].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[29].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "processor[29].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                },
                "processorCompare": {
                  "tr_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_H35.svg",
                      "tr_imgCaption": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_H35.svg",
                      "tr_imgCaption": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_imgCaption": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_td1_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_td1_title",
                      "tr_td2_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_td2_title",
                      "tr_td3_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_td3_title"
                    },
                    {
                      "tr_td1_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_td1_title",
                      "tr_td2_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_td2_title",
                      "tr_td3_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_td3_title"
                    },
                    {
                      "tr_td1_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_td1_title",
                      "tr_td2_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_td2_title",
                      "tr_td3_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_td3_title"
                    },
                    {
                      "tr_td1_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_td1_title",
                      "tr_td2_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_td2_title",
                      "tr_td3_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_td3_title"
                    },
                    {
                      "tr_td1_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_td1_title",
                      "tr_td2_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_td2_title",
                      "tr_td3_title": "processor[29].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_td3_title"
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[29].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[29].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[29].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[29].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[29].iposTypes[1].deviceTypes[0].details.tr_gen_core_title_uppercase"
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[29].iposTypes[2].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "processor[29].iposTypes[2].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[29].iposTypes[2].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[29].iposTypes[2].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[29].iposTypes[2].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/gaming/H35_i7_spcl_edition_gaming_tile.svg",
                "enhancementCompare": {
                  "tr_title": "processor[29].iposTypes[2].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[29].iposTypes[2].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[29].iposTypes[2].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[29].iposTypes[2].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_SPECIAL_EDITION_H35.svg",
                      "tr_badgeIconTile": "processor[29].iposTypes[2].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H35",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[30].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "gamingPanleType2": "gaming-panelType1",
                "tr_subtitle": "processor[30].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[30].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[30].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[30].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[30].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[30].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[30].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[30].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "processor[30].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[30].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[30].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[30].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": "",
                      "active": false
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "processor[30].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": "",
                      "active": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[31].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tileBG": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "processor[31].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[31].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[31].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[31].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[31].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[31].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[31].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[31].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[32].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[32].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[32].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[32].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[32].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[32].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[32].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[32].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[32].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_RKL",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[33].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[33].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[33].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[33].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorTileBadgeUrl": "assets/images/gaming/RKL_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[33].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[33].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[33].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[33].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[33].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[34].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[34].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[34].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[34].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_subTitle2": "processor[34].iposTypes[0].deviceTypes[0].details.tr_subTitle2"
              }
            },
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[34].iposTypes[0].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[34].iposTypes[0].deviceTypes[1].details.tr_subtitle",
                "tr_title": "processor[34].iposTypes[0].deviceTypes[1].details.tr_title",
                "tr_gen_core_title": "processor[34].iposTypes[0].deviceTypes[1].details.tr_gen_core_title",
                "tr_subTitle2": "processor[34].iposTypes[0].deviceTypes[1].details.tr_subTitle2"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_HYBRID",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[35].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[35].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[35].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[35].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_subTitle2": "processor[35].iposTypes[0].deviceTypes[0].details.tr_subTitle2"
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[35].iposTypes[0].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[35].iposTypes[0].deviceTypes[1].details.tr_subtitle",
                "tr_title": "processor[35].iposTypes[0].deviceTypes[1].details.tr_title",
                "tr_gen_core_title": "processor[35].iposTypes[0].deviceTypes[1].details.tr_gen_core_title",
                "tr_subTitle2": "processor[35].iposTypes[0].deviceTypes[1].details.tr_subTitle2"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[36].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[36].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[36].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[36].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[36].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[36].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[36].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[36].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[36].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[36].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[36].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[36].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[36].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[36].iposTypes[1].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i5_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[36].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[36].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[36].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[36].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[36].iposTypes[1].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_H45",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[37].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[37].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[37].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[37].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[37].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[37].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[37].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[37].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[37].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[37].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[37].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[37].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[37].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[37].iposTypes[1].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i7_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[37].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[37].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[37].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[37].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[37].iposTypes[1].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_PERFORMANCE",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[38].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[38].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[38].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[38].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[38].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[38].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[38].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[38].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[38].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[38].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[38].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[38].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[38].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[38].iposTypes[1].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[38].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[38].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[38].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[38].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[38].iposTypes[1].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_H45_ENTHUSIAST",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[39].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[39].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[39].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[39].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[39].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[39].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[39].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[39].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[39].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[39].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[39].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[39].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[39].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[39].iposTypes[1].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/gaming/H45_i9_gaming_tile.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[39].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[39].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[39].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[39].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[39].iposTypes[1].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[40].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[40].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[40].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[40].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[40].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[40].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[40].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[40].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[40].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[40].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[40].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[40].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[40].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[40].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[40].iposTypes[1].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[40].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[40].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[40].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[40].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[40].iposTypes[1].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[40].iposTypes[1].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[40].iposTypes[1].deviceTypes[1].details.tr_subtitle",
                "tr_title": "processor[40].iposTypes[1].deviceTypes[1].details.tr_title",
                "tr_gen_core_title": "processor[40].iposTypes[1].deviceTypes[1].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[40].iposTypes[1].deviceTypes[1].details.tr_gen_core_title_uppercase",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[40].iposTypes[1].deviceTypes[1].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[40].iposTypes[1].deviceTypes[1].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[40].iposTypes[1].deviceTypes[1].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[40].iposTypes[1].deviceTypes[1].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[40].iposTypes[1].deviceTypes[1].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_10",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[41].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[41].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[41].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[41].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[41].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[41].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[41].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[41].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[41].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[5].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[6].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[0].tr_title",
                      "tr_subTitle": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[1].tr_title",
                      "tr_subTitle": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[2].tr_title",
                      "tr_subTitle": "processor[41].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[2].tr_subTitle"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[41].iposTypes[0].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[41].iposTypes[0].deviceTypes[1].details.tr_subtitle",
                "tr_title": "processor[41].iposTypes[0].deviceTypes[1].details.tr_title",
                "tr_gen_core_title": "processor[41].iposTypes[0].deviceTypes[1].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[41].iposTypes[0].deviceTypes[1].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[41].iposTypes[0].deviceTypes[1].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[41].iposTypes[0].deviceTypes[1].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[41].iposTypes[0].deviceTypes[1].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[41].iposTypes[0].deviceTypes[1].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock_blue.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_8.svg",
                      "tr_imgCaption": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_8.svg",
                      "tr_imgCaption": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.table_head[2].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                      "tr_imgCaption": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.tabletr[5].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.tabletr[6].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.table_footer[0].tr_title",
                      "tr_subTitle": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.table_footer[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.table_footer[1].tr_title",
                      "tr_subTitle": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.table_footer[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.table_footer[2].tr_title",
                      "tr_subTitle": "processor[41].iposTypes[0].deviceTypes[1].details.processorCompare.table_footer[2].tr_subTitle"
                    }
                  ]
                }
              }
            },
            {
              "deviceType": "Athena",
              "details": {
                "tr_tileTitle": "processor[41].iposTypes[0].deviceTypes[2].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_10.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[41].iposTypes[0].deviceTypes[2].details.tr_subtitle",
                "tr_title": "processor[41].iposTypes[0].deviceTypes[2].details.tr_title",
                "tr_gen_core_title": "processor[41].iposTypes[0].deviceTypes[2].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_11_UNLOCKED",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[42].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[42].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[42].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[42].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[42].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge_laptop_11gen.svg",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[42].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[42].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[42].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[42].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[42].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[42].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[42].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[42].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[42].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[42].iposTypes[1].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[42].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[42].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[42].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[42].iposTypes[1].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[42].iposTypes[1].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            },
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[42].iposTypes[1].deviceTypes[1].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_11.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "",
                "tr_subtitle": "processor[42].iposTypes[1].deviceTypes[1].details.tr_subtitle",
                "tr_title": "processor[42].iposTypes[1].deviceTypes[1].details.tr_title",
                "tr_gen_core_title": "processor[42].iposTypes[1].deviceTypes[1].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[42].iposTypes[1].deviceTypes[1].details.tr_gen_core_title_uppercase",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[42].iposTypes[1].deviceTypes[1].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[42].iposTypes[1].deviceTypes[1].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[42].iposTypes[1].deviceTypes[1].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[42].iposTypes[1].deviceTypes[1].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[42].iposTypes[1].deviceTypes[1].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[43].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[43].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[43].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[43].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[43].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[43].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[43].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[43].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[43].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[0].tr_title",
                      "tr_subTitle": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[1].tr_title",
                      "tr_subTitle": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[2].tr_title",
                      "tr_subTitle": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[2].tr_subTitle"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[0].tr_title",
                      "tr_subTitle": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[1].tr_title",
                      "tr_subTitle": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[2].tr_title",
                      "tr_subTitle": "processor[43].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[2].tr_subTitle"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[44].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[44].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[44].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[44].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[44].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[44].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[44].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[44].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[44].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[0].tr_title",
                      "tr_subTitle": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[1].tr_title",
                      "tr_subTitle": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[2].tr_title",
                      "tr_subTitle": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[2].tr_subTitle"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[0].tr_title",
                      "tr_subTitle": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[1].tr_title",
                      "tr_subTitle": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[2].tr_title",
                      "tr_subTitle": "processor[44].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[2].tr_subTitle"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_9_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[45].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "gaming-processorType2",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[45].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[45].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[45].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[45].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[45].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[45].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "tr_badgeTitle": "processor[45].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "badgeTitle": "",
                    "tr_badgeSubTitle": "processor[45].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle",
                    "badgePreTitle": "",
                    "imgUrl": "assets/images/tocheck/unlock.svg"
                  }
                ],
                "processorCompare": {
                  "tr_title": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9_GAMING.svg",
                      "tr_imgCaption": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[0].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9_GAMING.svg",
                      "tr_imgCaption": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_imgCaption"
                    },
                    {
                      "tr_table_heading": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9_GAMING.svg",
                      "tr_imgCaption": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_imgCaption"
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true
                    },
                    {
                      "tr_row": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true
                    }
                  ],
                  "table_footer": [
                    {
                      "tr_title": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[0].tr_title",
                      "tr_subTitle": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[1].tr_title",
                      "tr_subTitle": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[2].tr_title",
                      "tr_subTitle": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer[2].tr_subTitle"
                    }
                  ],
                  "table_footer_desktop": [
                    {
                      "tr_title": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[0].tr_title",
                      "tr_subTitle": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[0].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[1].tr_title",
                      "tr_subTitle": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[1].tr_subTitle"
                    },
                    {
                      "tr_title": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[2].tr_title",
                      "tr_subTitle": "processor[45].iposTypes[0].deviceTypes[0].details.processorCompare.table_footer_desktop[2].tr_subTitle"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_K_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[46].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[46].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "processor[46].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[46].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_gen_core_title_uppercase"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "processor[46].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[46].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_gen_core_title_uppercase"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "processor[46].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[46].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_gen_core_title_uppercase"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[46].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgePreTitle": "processor[46].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgePreTitle",
                    "tr_badgeSubTitle": "processor[46].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[46].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgePreTitle": "processor[46].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle",
                    "tr_badgeSubTitle": "processor[46].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "processor[46].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[46].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[46].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[46].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "processor[46].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_HX_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[47].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[47].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "processorTileBadgeUrl": "assets/images/standard/i9_uwp_tile_badge.png",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "processor[47].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[47].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_gen_core_title_uppercase"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "processor[47].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[47].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_gen_core_title_uppercase"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[47].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgePreTitle": "processor[47].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgePreTitle",
                    "tr_badgeSubTitle": "processor[47].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[47].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgePreTitle": "processor[47].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle",
                    "tr_badgeSubTitle": "processor[47].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "processor[47].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[47].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[47].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[47].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "processor[47].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[48].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[48].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "processor[48].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[48].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "bannerImgUrl": "assets/images/12th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/i3_uwp_tile_badge.svg",
                "enhancementCompare": {
                  "tr_title": "processor[48].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[48].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[48].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[48].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[48].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[49].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[49].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "processor[49].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[49].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "processor[49].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[49].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[49].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[49].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[49].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[49].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[49].iposTypes[1].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "processor[49].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[49].iposTypes[1].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "bannerImgUrl": "assets/images/12th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "processor[49].iposTypes[1].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[49].iposTypes[1].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[49].iposTypes[1].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[49].iposTypes[1].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[49].iposTypes[1].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[50].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[50].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "processor[50].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[50].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "processor[50].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[50].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[50].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[50].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[50].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[50].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[50].iposTypes[1].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "processor[50].iposTypes[1].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[50].iposTypes[1].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "bannerImgUrl": "assets/images/12th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "processor[50].iposTypes[1].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[50].iposTypes[1].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[50].iposTypes[1].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[50].iposTypes[1].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[50].iposTypes[1].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[51].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5-processor-image.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[51].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[51].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[51].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[51].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[52].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i7_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[52].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[52].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[52].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[52].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_12GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[53].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/evo_processor_i9_gen12.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[53].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[53].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[53].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[53].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[54].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[54].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[54].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[54].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[54].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "processor[54].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "processor[54].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[54].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[54].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[54].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[54].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[54].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[54].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[5].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[54].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[6].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[54].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[7].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[55].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[55].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[55].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[55].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[55].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "processor[55].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "processor[55].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[2].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[55].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[55].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[55].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[55].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[55].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[55].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[5].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[55].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[6].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[55].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[7].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_12GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[56].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-12thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "processor[56].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[56].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[56].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[56].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "processor[56].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "processor[56].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[3].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[56].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[56].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[56].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": true,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[56].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[56].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[56].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[5].tr_row",
                      "td1": false,
                      "td2": true,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[56].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[6].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": true,
                      "td4": true
                    },
                    {
                      "tr_row": "processor[56].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[7].tr_row",
                      "td1": false,
                      "td2": false,
                      "td3": false,
                      "td4": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[57].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[57].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "processor[57].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "processor[57].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[57].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[57].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[57].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[57].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[58].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[58].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "processor[58].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[58].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "processor[58].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[58].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[58].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[58].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[58].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[59].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[59].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "processor[59].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[59].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "bannerImgUrl": "assets/images/creator/13thgeni5processor.svg",
                "enhancementCompare": {
                  "tr_title": "processor[59].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[59].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[59].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[59].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[59].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[60].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[60].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "processor[60].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[60].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "processor[60].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[60].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[60].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[60].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[60].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[61].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[61].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "processor[61].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[61].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "bannerImgUrl": "assets/images/creator/13thgeni7processor.svg",
                "enhancementCompare": {
                  "tr_title": "processor[61].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[61].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[61].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[61].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[61].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_CREATOR",
      "iposTypes": [
        {
          "iposType": "Creator",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[62].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[62].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                "tr_gen_core_title": "processor[62].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[62].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[62].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgePreTitle": "processor[62].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgePreTitle",
                    "tr_badgeSubTitle": "processor[62].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[62].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgePreTitle": "processor[62].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle",
                    "tr_badgeSubTitle": "processor[62].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "processor[62].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[62].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[62].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[62].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "processor[62].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I5_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[63].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I5_12GEN.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[63].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[63].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[63].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[63].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i5-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I7_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[64].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I7_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[64].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[64].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[64].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[64].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i7-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_I9_13GEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[65].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/EVO_I9_GEN12.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/evo/i5_13gen_processor_img.svg",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "processor[65].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[65].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[65].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[65].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/evo/12gen-evo-i9-tile.svg"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[66].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[66].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "processor[66].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[66].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_gen_core_title_uppercase",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "processor[66].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[66].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_gen_core_title_uppercase",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "processor[66].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[66].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_gen_core_title_uppercase",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[66].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgePreTitle": "processor[66].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgePreTitle",
                    "tr_badgeSubTitle": "processor[66].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[66].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgePreTitle": "processor[66].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle",
                    "tr_badgeSubTitle": "processor[66].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "processor[66].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[66].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[66].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[66].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "processor[66].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_ABOVE13TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[67].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[67].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "processor[67].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[67].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_gen_core_title_uppercase",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "processor[67].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[67].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_gen_core_title_uppercase",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "processor[67].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[67].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_gen_core_title_uppercase",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[67].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgePreTitle": "processor[67].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgePreTitle",
                    "tr_badgeSubTitle": "processor[67].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[67].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgePreTitle": "processor[67].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle",
                    "tr_badgeSubTitle": "processor[67].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "processor[67].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[67].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[67].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[67].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "processor[67].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[68].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[68].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                "tr_gen_core_title": "processor[68].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/13th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "processor[68].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[68].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[68].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[68].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[68].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[69].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[69].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                "tr_gen_core_title": "processor[69].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[69].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "processor[69].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[69].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[69].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[69].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[69].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[70].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[70].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                "tr_gen_core_title": "processor[70].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[70].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "processor[70].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[70].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_9.svg",
                      "tr_badgeIconTile": "processor[70].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[70].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[70].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_14TH_GEN_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[71].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[71].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                    "tr_gen_core_title": "processor[71].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[71].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_gen_core_title_uppercase",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i5_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i5_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                    "tr_gen_core_title": "processor[71].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[71].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_gen_core_title_uppercase",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i7_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i7_hx_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                    "tr_gen_core_title": "processor[71].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[71].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_gen_core_title_uppercase",
                    "processorTileBadgeUrl": "assets/images/gaming/13th_gen_i9_k_tile_badge.png",
                    "processorTileBadgeUrl1": "assets/images/gaming/13th_gen_i9_hx_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[71].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgePreTitle": "processor[71].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgePreTitle",
                    "tr_badgeSubTitle": "processor[71].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle"
                  },
                  {
                    "tr_badgeTitle": "processor[71].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgePreTitle": "processor[71].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle",
                    "tr_badgeSubTitle": "processor[71].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "processor[71].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[71].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_9.svg",
                      "tr_badgeIconTile": "processor[71].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_9.svg",
                      "tr_badgeIconTile": "processor[71].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I9_9.svg",
                      "tr_badgeIconTile": "processor[71].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I3_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[72].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[72].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                "tr_gen_core_title": "processor[72].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "bannerImgUrl": "assets/images/13th_gen_std_i3_processor_banner.svg",
                "processorTileBadgeUrl": "assets/images/standard/14th_gen_i3_std_tile_badge.png",
                "enhancementCompare": {
                  "tr_title": "processor[72].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[72].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "processor[72].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "processor[72].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "processor[72].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[73].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[73].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                "tr_gen_core_title": "processor[73].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[73].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "bannerImgUrl": "assets/images/13th_gen_std_i5_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "processor[73].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[73].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "processor[73].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "processor[73].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "processor[73].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_14_1",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[74].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[74].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                "tr_gen_core_title": "processor[74].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[74].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "bannerImgUrl": "assets/images/13th_gen_std_i7_processor_banner.svg",
                "enhancementCompare": {
                  "tr_title": "processor[74].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[74].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I3_14_1.svg",
                      "tr_badgeIconTile": "processor[74].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I5_14_1.svg",
                      "tr_badgeIconTile": "processor[74].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_I7_14_1.svg",
                      "tr_badgeIconTile": "processor[74].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_1ST_GEN_ULTRA_GAMING",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "Desktop",
              "details": {
                "tr_tileTitle": "processor[75].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "processor[75].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "gaming-processorType12",
                "gamingPanleType2": "gaming-panelType1",
                "titleArray": [
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                    "tr_gen_core_title": "processor[75].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[75].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_gen_core_title_uppercase",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_5_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                    "tr_gen_core_title": "processor[75].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[75].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_gen_core_title_uppercase",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_7_h_tile_badge.png"
                  },
                  {
                    "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                    "tr_gen_core_title": "processor[75].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_gen_core_title",
                    "tr_gen_core_title_uppercase": "processor[75].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_gen_core_title_uppercase",
                    "processorTileBadgeUrl": "",
                    "processorTileBadgeUrl1": "assets/images/gaming/1st_gen_ultra_9_h_tile_badge.png",
                    "processorTileBadgeUrl2": "assets/images/standard/13th_gen_i9_std_tile_badge.png"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "processor[75].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgePreTitle": "processor[75].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgePreTitle",
                    "tr_badgeSubTitle": "processor[75].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle",
                    "tr_badgeSubTitle1": "processor[75].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle1"
                  },
                  {
                    "tr_badgeTitle": "processor[75].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgePreTitle": "processor[75].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle",
                    "tr_badgeSubTitle": "processor[75].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle",
                    "tr_badgeSubTitle1": "processor[75].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle1"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "processor[75].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "processor[75].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                      "tr_badgeIconTile": "processor[75].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                      "tr_badgeIconTile": "processor[75].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    },
                    {
                      "imgUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                      "tr_badgeIconTile": "processor[75].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": ""
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[76].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "processor[76].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[77].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "processor[77].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[78].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "processor[78].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[79].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tr_title": "processor[79].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[80].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tr_title": "processor[80].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_MTL_ULTRA_9",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "Laptop",
              "details": {
                "tr_tileTitle": "processor[81].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_9_9.svg",
                "tr_title": "processor[81].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "evo-mtl-video-panel",
                "gamingPanleType2": "gaming-panelType1",
                "videoUrl": "assets/lang/[XX]/redesign/evo_std_mtl_processor.mp4"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I5_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[82].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I5_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "processor[82].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[82].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[82].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "processor[82].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "processor[82].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[82].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "processor[82].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "processor[82].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "processor[82].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "processor[82].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I7_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[83].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I7_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "processor[83].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[83].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[83].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "",
                "processorCompare": {
                  "tr_title": "processor[83].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "processor[83].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[83].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "processor[83].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "processor[83].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "processor[83].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "processor[83].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_CORE_I9_13GEN_VPRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[84].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_I9_12GEN_VPRO.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/vpro-13thGen-processor-banner.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "tableFormat",
                "tr_subtitle": "",
                "tr_title": "processor[84].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[84].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "tr_gen_core_title_uppercase": "processor[84].iposTypes[0].deviceTypes[0].details.tr_gen_core_title_uppercase",
                "processorTileBadgeUrl": "assets/images/standard/VPRO12_i9.svg",
                "processorCompare": {
                  "tr_title": "processor[84].iposTypes[0].deviceTypes[0].details.processorCompare.tr_title",
                  "background": "",
                  "table_head": [
                    {
                      "tr_table_heading": "",
                      "imgUrl": "assets/images/INTEL_CORE_I5_I7_DUEL_BADGE.svg",
                      "tr_imgCaption": ""
                    },
                    {
                      "tr_table_heading": "processor[84].iposTypes[0].deviceTypes[0].details.processorCompare.table_head[1].tr_table_heading",
                      "imgUrl": "assets/images/processor/INTEL_CORE_13GEN_VPRO_BADGES.svg",
                      "tr_imgCaption": ""
                    }
                  ],
                  "tabletr": [
                    {
                      "tr_row": "processor[84].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[0].tr_row",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "processor[84].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[1].tr_row",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "processor[84].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[2].tr_row",
                      "td1": true,
                      "td2": true
                    },
                    {
                      "tr_row": "processor[84].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[3].tr_row",
                      "td1": false,
                      "td2": true
                    },
                    {
                      "tr_row": "processor[84].iposTypes[0].deviceTypes[0].details.processorCompare.tabletr[4].tr_row",
                      "td1": false,
                      "td2": true
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_RPL_MTL_vPro",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[85].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/vPro_ultra_badge.png",
                "tileBG": "",
                "bannerImgUrl": "assets/images/INTEL_VPRO_ULTRA.png",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_subtitle": "processor[85].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "processor[85].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_gen_core_title": "processor[85].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_1.png",
                    "tr_badgeTitle": "processor[85].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "processor[85].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_2.png",
                    "tr_badgeTitle": "processor[85].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "processor[85].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/vPro_ultra_panel_3.png",
                    "tr_badgeTitle": "processor[85].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "processor[85].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeSubTitle",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[86].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "processor[86].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle1": "processor[86].iposTypes[0].deviceTypes[0].details.tr_subtitle1",
                "tr_subtitle2": "processor[86].iposTypes[0].deviceTypes[0].details.tr_subtitle2",
                "tr_subtitle": "processor[86].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_gen_core_title": "processor[86].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "titleArray": [
                  {
                    "tr_title": "processor[86].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "processor[86].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "processor[86].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_title"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STD_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[87].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "processor[87].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle1": "processor[87].iposTypes[0].deviceTypes[0].details.tr_subtitle1",
                "tr_subtitle2": "processor[87].iposTypes[0].deviceTypes[0].details.tr_subtitle2",
                "tr_subtitle": "processor[87].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_gen_core_title": "processor[87].iposTypes[0].deviceTypes[0].details.tr_gen_core_title",
                "titleArray": [
                  {
                    "tr_title": "processor[87].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "processor[87].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "processor[87].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_title"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[88].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_5_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_5.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "processor[88].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle1": "processor[88].iposTypes[0].deviceTypes[0].details.tr_subtitle1",
                "tr_subtitle2": "processor[88].iposTypes[0].deviceTypes[0].details.tr_subtitle2",
                "tr_subtitle": "processor[88].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_gen_core_title": "processor[88].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO_LNL_ULTRA_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "processor[89].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/processor/INTEL_CORE_ULTRA_7_9.svg",
                "tileBG": "",
                "bannerImgUrl": "assets/images/evo_lunar_lake_banner_7.svg",
                "panelType1": "gaming-processorType12",
                "panelType2": "",
                "tr_pretitle": "processor[89].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle1": "processor[89].iposTypes[0].deviceTypes[0].details.tr_subtitle1",
                "tr_subtitle2": "processor[89].iposTypes[0].deviceTypes[0].details.tr_subtitle2",
                "tr_subtitle": "processor[89].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_gen_core_title": "processor[89].iposTypes[0].deviceTypes[0].details.tr_gen_core_title"
              }
            }
          ]
        }
      ]
    }
  ],
  "graphics": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_tileTitle1": "graphics[0].iposTypes[0].deviceTypes[0].details.tr_tileTitle1",
                "tr_title": "graphics[0].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "graphics[0].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-hybrid-GPU-badge.png",
                    "tr_badgeTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeSubTitle",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/video-editing.png",
                    "tr_badgeTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/binge-4k.svg",
                    "tr_badgeTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/games.svg",
                    "tr_badgeTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.badgeIcons[5].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics_dual_gpus.svg",
                    "tr_badgeTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.badgeIcons[6].tr_badgeTitle",
                    "tr_badgeSubTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.badgeIcons[6].tr_badgeSubTitle",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphic-integrated-GPU-badge.png",
                    "tr_badgeTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.badgeIcons[7].tr_badgeTitle",
                    "tr_badgeSubTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.badgeIcons[7].tr_badgeSubTitle",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/graphics-discrete-GPU-badge.svg",
                    "tr_badgeTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.badgeIcons[8].tr_badgeTitle",
                    "tr_badgeSubTitle": "graphics[0].iposTypes[0].deviceTypes[0].details.badgeIcons[8].tr_badgeSubTitle",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[0].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "",
                "panelType2": "gaming-graphics",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "graphics[0].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "graphics[0].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "graphics[0].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "graphics[0].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "graphics[0].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "graphics[0].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeSubTitle",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[1].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "graphics[1].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "graphics[1].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "graphics[1].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PLUS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[2].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "graphics[2].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "graphics[2].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "graphics[2].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeUrl": "assets/images/badges/intel-IRIS-plus.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[3].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "graphics[3].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "graphics[3].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_PRO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[4].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "graphics[4].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "",
                "tr_subtitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "UHD_FEATURING_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[5].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "graphics[5].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "graphics[5].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_text1": "graphics[5].iposTypes[0].deviceTypes[0].details.tr_text1",
                "tr_photo_text": "graphics[5].iposTypes[0].deviceTypes[0].details.tr_photo_text",
                "tr_video_text": "graphics[5].iposTypes[0].deviceTypes[0].details.tr_video_text",
                "tr_game_text": "graphics[5].iposTypes[0].deviceTypes[0].details.tr_game_text",
                "badgeUrl": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[5].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "graphics[5].iposTypes[1].deviceTypes[0].details.tr_title",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "graphics[5].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle": "graphics[5].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "graphics[5].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_630",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[6].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "graphics[6].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "graphics[6].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle": "graphics[6].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "graphics[6].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "Intel_UHD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[7].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "graphics[7].iposTypes[0].deviceTypes[0].details.tr_title",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "graphics[7].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle": "graphics[7].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "graphics[7].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[7].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "graphics[7].iposTypes[1].deviceTypes[0].details.tr_title",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_pretitle": "graphics[7].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle": "graphics[7].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "graphics[7].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[8].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "graphics[8].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "graphics[8].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[8].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "graphics[8].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_pretitle": "graphics[8].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "tr_subtitle": "graphics[8].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "graphics[8].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "detailPageName": "LESS_THAN_0",
                    "tr_subtitle": "graphics[8].iposTypes[1].deviceTypes[0].details.titleArray[0].tr_subtitle",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "0_TO_4",
                    "tr_subtitle": "graphics[8].iposTypes[1].deviceTypes[0].details.titleArray[1].tr_subtitle",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "4_TO_8",
                    "tr_subtitle": "graphics[8].iposTypes[1].deviceTypes[0].details.titleArray[2].tr_subtitle",
                    "tr_title": ""
                  },
                  {
                    "detailPageName": "GREATER_THAN_8",
                    "tr_subtitle": "graphics[8].iposTypes[1].deviceTypes[0].details.titleArray[3].tr_subtitle",
                    "tr_title": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_HD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[9].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "graphics[9].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "graphics[9].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle": "graphics[9].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_UHD_620",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[10].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "graphics[10].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "graphics[10].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle": "graphics[10].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[11].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "graphics[11].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "graphics[11].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "graphics[11].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[11].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "tr_pretitle": "graphics[11].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "tr_title": "graphics[11].iposTypes[1].deviceTypes[0].details.tr_title",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe.svg",
                "tr_subtitle": "graphics[11].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "graphics[11].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_IRIS_XE_MAX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[12].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "graphics[12].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "graphics[12].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "graphics[12].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[12].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "graphics[12].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_pretitle": "graphics[12].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "panelType1": "header-BadgeIconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/badges/intel-iris-xe-max.svg",
                "tr_subtitle": "graphics[12].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "graphics[12].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_tileTitle": "graphics[13].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tr_title": "graphics[13].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "graphics[13].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeUrl": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "ARC_GRAPHICS",
      "iposTypes": [
        {
          "iposType": "DEFAULT",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "graphics[14].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/arc-badge.svg",
                "tileBG": "",
                "detailBGUrl": "assets/images/arc_graphics/fallback_arc_graphics.svg",
                "tr_subtitle": "",
                "panelType1": "header-BadgeIconTop-arc",
                "panelType2": "",
                "tr_title": ""
              }
            }
          ],
          "factTagTypes": [
            {
              "factTagType": "Arc_Graphics_Panel_Content",
              "navText": [
                {
                  "specName": "Arc_Graphics_Badge_Icon",
                  "specDetails": {
                    "targetURL": "badgeIconUrl",
                    "tr_specTitle": "",
                    "tr_specText": ""
                  }
                },
                {
                  "specName": "Graphics_Boost_Workflow",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[1].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[1].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[2].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[2].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Graphics_Supercharge_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[3].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[3].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Graphics_Level_Up_Your_Game",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[4].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[4].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel2.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[5].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[5].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel2.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[6].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[6].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel3.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[7].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[7].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[8].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[8].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Unleash_Your_Creativity_With_Intel_X_Engine_12",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel3.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[9].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[9].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Portability_Meets_Performance",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel3.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[10].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[10].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_panel4.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[11].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[11].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_panel4.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[12].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[12].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Take_Control_Of_Your_Games_1",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A730M_panel4.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[13].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[13].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop2",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A310_newPanel5.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[14].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[14].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_A580",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_newPanel5.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[15].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[15].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel5.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[16].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[16].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_Platform",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[17].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[17].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Maximize_Your_Intel_System",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A350M_newPanel5.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[18].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[18].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Take_Center_Stage",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel4.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[19].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[19].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Take_Center_Stage_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel4.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[20].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[20].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[21].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[21].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming_Desktop",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A770_panel2.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[22].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[22].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Give_Your_Games_A_Boost",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A380_panel3.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[23].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[23].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Experience_Advanced_Fast_Paced_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A580_panel2.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[24].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[24].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Experience_Immersive_Hiigh_Performance_Gaming",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A750_panel2.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[25].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[25].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Graphics_Universe",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc-badge.svg",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[26].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[26].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Accelerate_Your_Frames",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel2.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[27].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[27].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Ultimate_Visuals_Are_Here",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel3.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[28].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[28].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Seamless_Photos_And_Videos",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel4.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[29].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[29].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Experience_Cutting_Edge",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/A5XXM_panel5.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[30].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[30].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Professional_Graphics",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/arc_pro_badge.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[31].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[31].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Designers_And_Engineers",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel2.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[32].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[32].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Making_Ai_Attainable",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[33].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[33].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Ray_Tracing",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel3.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[34].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[34].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Immersive_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel5.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[35].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[35].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Dynamically_Optimised_Images",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXX_pro_panel6.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[36].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[36].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Expertly_Verified_Software",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel2.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[37].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[37].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Ray_Tracing_Mobile",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel3.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[38].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[38].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Multiple_Large_Display",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel4.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[39].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[39].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Full_Memory_Bandwidth",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/AXXXM_pro_panel5.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[40].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[40].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Breathe_Life",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel2.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[41].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[41].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Real_Immersive",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel3.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[42].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[42].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Your_Best_Gameplay",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel4.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[43].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[43].specDetails.tr_specText"
                  }
                },
                {
                  "specName": "Welcome_All_Creators",
                  "specDetails": {
                    "targetURL": "assets/images/arc_graphics/Arc_Int_panel5.png",
                    "tr_specTitle": "graphics[14].iposTypes[0].factTagTypes[0].navText[44].specDetails.tr_specTitle",
                    "tr_specText": "graphics[14].iposTypes[0].factTagTypes[0].navText[44].specDetails.tr_specText"
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "os": [
    {
      "contentType": "WINDOWS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "os[0].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "os[0].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "os[0].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle": "os[0].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_noteSection": "os[0].iposTypes[0].deviceTypes[0].details.tr_noteSection",
                "titleArray": [
                  {
                    "tr_title": "os[0].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_title",
                    "tr_subtitle": "os[0].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_subtitle",
                    "tr_noteSection": "os[0].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_noteSection"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "os[0].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "header-IconTopGaming",
                "tr_pretitle": "os[0].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "tr_title": "os[0].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "os[0].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_noteSection": "os[0].iposTypes[1].deviceTypes[0].details.tr_noteSection",
                "titleArray": [
                  {
                    "tr_title": "os[0].iposTypes[1].deviceTypes[0].details.titleArray[0].tr_title",
                    "tr_subtitle": "os[0].iposTypes[1].deviceTypes[0].details.titleArray[0].tr_subtitle",
                    "tr_noteSection": "os[0].iposTypes[1].deviceTypes[0].details.titleArray[0].tr_noteSection"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWSUWP",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "os[1].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "os[1].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "os[1].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle": "os[1].iposTypes[0].deviceTypes[0].details.tr_subtitle"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "os[1].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "os[1].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "tr_title": "os[1].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "os[1].iposTypes[1].deviceTypes[0].details.tr_subtitle"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_GENERIC",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "os[2].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "os[2].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "os[2].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle": "os[2].iposTypes[0].deviceTypes[0].details.tr_subtitle"
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "os[2].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "os[2].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "tr_title": "os[2].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_subtitle": "os[2].iposTypes[1].deviceTypes[0].details.tr_subtitle"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "LINUX",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "os[3].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "",
                "tr_pretitle": "os[3].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "WINDOWS_11",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "os[4].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "image-only-panel",
                "panelType2": "header-IconTop",
                "badgeUrl": "",
                "tr_title": "",
                "tr_pretitle": "",
                "badgeIcons": [
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/1.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/2.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/3.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/4.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/5.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/6.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/7.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/8.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/9.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/10.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/11.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/12.png"
                  },
                  {
                    "imgUrl": "assets/images/lang/[XX]/operating-system/13.png"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "memory": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "memory[0].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "memory[0].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "memory[0].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "memory[0].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle"
                  },
                  {
                    "tr_badgeTitle": "memory[0].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "memory[0].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle"
                  },
                  {
                    "tr_badgeTitle": "memory[0].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "memory[0].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeSubTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "memory[1].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "memory[1].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "memory[1].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "memory[1].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "memory[2].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "memory[2].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "memory[2].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "memory[2].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "memory[3].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "memory[3].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "memory[3].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "memory[3].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "memory[4].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12th_memory.svg",
                "tr_title": "memory[4].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "memory[4].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "memory[4].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "memory[5].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "tr_title": "memory[5].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "memory[5].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_pretitle": "memory[5].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "titleArray": [
                  {
                    "tr_title": "memory[5].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_title"
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-optane-faster-content.png",
                    "tr_badgeTitle": "memory[5].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-responsive-gaming.png",
                    "tr_badgeTitle": "memory[5].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-easier-multitasking.png",
                    "tr_badgeTitle": "memory[5].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-optane-open-programs.png",
                    "tr_badgeTitle": "memory[5].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "memory[5].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "memory[5].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_pretitle": "memory[5].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "tr_pretitle1": "memory[5].iposTypes[1].deviceTypes[0].details.tr_pretitle1",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "memory[5].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgePreTitle",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "memory[5].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            },
            {
              "deviceType": "DEFAULT_A",
              "details": {
                "tr_tileTitle": "memory[5].iposTypes[1].deviceTypes[1].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "panelType2": "",
                "panelType1": "badgesAndHeadings-memory",
                "tr_title": "memory[5].iposTypes[1].deviceTypes[1].details.tr_title",
                "tr_pretitle": "memory[5].iposTypes[1].deviceTypes[1].details.tr_pretitle",
                "tr_pretitle1": "memory[5].iposTypes[1].deviceTypes[1].details.tr_pretitle1",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "memory[5].iposTypes[1].deviceTypes[1].details.badgeIcons[0].tr_badgePreTitle",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgePreTitle": "memory[5].iposTypes[1].deviceTypes[1].details.badgeIcons[1].tr_badgePreTitle",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "memory[6].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "memory[6].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "memory[6].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "memory[6].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_title",
                    "tr_subtitle": "memory[6].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_subtitle"
                  },
                  {
                    "tr_title": "memory[6].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_title",
                    "tr_subtitle": "memory[6].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_subtitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DEFAULT",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "memory[7].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "memory[7].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "memory[7].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle": "memory[7].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "memory[7].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "memory[7].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle"
                  },
                  {
                    "tr_badgeTitle": "memory[7].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "memory[7].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle"
                  },
                  {
                    "tr_badgeTitle": "memory[7].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "memory[7].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeSubTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GAMING_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "memory[8].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_title": "memory[8].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "memory[8].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle": "memory[8].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "gamingPanleType2": "gaming-panelType1",
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "memory[8].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "memory[8].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle"
                  },
                  {
                    "tr_badgeTitle": "memory[8].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "memory[8].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle"
                  },
                  {
                    "tr_badgeTitle": "memory[8].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "memory[8].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeSubTitle"
                  }
                ],
                "enhancementCompare": {
                  "tr_title": "memory[8].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "memory[8].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTick.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "memory[8].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": "memory[8].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeSubTitle"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "memory[8].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": "memory[8].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeSubTitle"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "memory[8].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": "memory[8].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeSubTitle"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "STANDARD_COMMON_12TH_MEMORY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "memory[9].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "tr_title": "memory[9].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "",
                "gamingPanleType2": "gaming-panelType1",
                "enhancementCompare": {
                  "tr_title": "memory[9].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_title",
                  "panelType2": "enhancement-compare",
                  "tr_table_heading": "memory[9].iposTypes[0].deviceTypes[0].details.enhancementCompare.tr_table_heading",
                  "imgUrl": "assets/images/processorCompareTickBlack.svg",
                  "badgeIcons": [
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "memory[9].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeIconTile",
                      "tr_badgeSubTitle": "memory[9].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[0].tr_badgeSubTitle"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "memory[9].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeIconTile",
                      "tr_badgeSubTitle": "memory[9].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[1].tr_badgeSubTitle"
                    },
                    {
                      "imgUrl": "",
                      "tr_badgeIconTile": "memory[9].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeIconTile",
                      "tr_badgeSubTitle": "memory[9].iposTypes[0].deviceTypes[0].details.enhancementCompare.badgeIcons[2].tr_badgeSubTitle"
                    }
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  ],
  "storage": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "storage[0].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "assets/images/badges/detail_page_badge_placeholder.png",
                "tr_title": "storage[0].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "storage[0].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/storage-songs-badge.png",
                    "tr_badgeTitle": "storage[0].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "storage[0].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgePreTitle",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-game-badge.png",
                    "tr_badgeTitle": "storage[0].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "storage[0].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-videos-badge.png",
                    "tr_badgeTitle": "storage[0].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "storage[0].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgePreTitle",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/storage-photos-badge.png",
                    "tr_badgeTitle": "storage[0].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "storage[0].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgePreTitle",
                    "imgUrl": ""
                  }
                ],
                "titleArray": [
                  {
                    "storageType": "HDD",
                    "tr_pretitle": "storage[0].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_pretitle"
                  },
                  {
                    "storageType": "SSD",
                    "tr_pretitle": "storage[0].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_pretitle"
                  },
                  {
                    "storageType": "SSD_AND_HDD",
                    "tr_pretitle": ""
                  },
                  {
                    "storageType": "OPTANE",
                    "tr_pretitle": "storage[0].iposTypes[0].deviceTypes[0].details.titleArray[3].tr_pretitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "storage[1].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_preTitle": "storage[1].iposTypes[0].deviceTypes[0].details.tr_preTitle",
                "tr_preTitle1": "storage[1].iposTypes[0].deviceTypes[0].details.tr_preTitle1",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "storage[1].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_subtitle",
                    "tr_title": "storage[1].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_title"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "storage[1].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_subtitle",
                    "tr_title": "storage[1].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_title"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "storage[1].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeTitle1": "storage[1].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle1",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "storage[1].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "storage[2].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "badgeUrl": "assets/images/std12storage.svg",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgeAndHeadings",
                "tr_preTitle": "storage[2].iposTypes[0].deviceTypes[0].details.tr_preTitle",
                "tr_preTitle1": "storage[2].iposTypes[0].deviceTypes[0].details.tr_preTitle1",
                "titleArray": [
                  {
                    "detailPageName": "WIN10S_Storage",
                    "tr_subtitle": "storage[2].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_subtitle",
                    "tr_title": "storage[2].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_title"
                  },
                  {
                    "detailPageName": "WIN10S_Storage-1000",
                    "tr_subtitle": "storage[2].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_subtitle",
                    "tr_title": "storage[2].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_title"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "storage[2].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "storage[2].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "storage[2].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "badgeUrl": "assets/images/intel_optane_memory.svg",
                "tr_title": "",
                "tr_pretitle": "storage[2].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle": "storage[2].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "storage[2].iposTypes[1].deviceTypes[0].details.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "storage[2].iposTypes[1].deviceTypes[0].details.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "storage[2].iposTypes[1].deviceTypes[0].details.titleArray[2].tr_title"
                  },
                  {
                    "tr_title": "storage[2].iposTypes[1].deviceTypes[0].details.titleArray[3].tr_title"
                  },
                  {
                    "tr_title": "storage[2].iposTypes[1].deviceTypes[0].details.titleArray[4].tr_title"
                  },
                  {
                    "tr_title": "storage[2].iposTypes[1].deviceTypes[0].details.titleArray[5].tr_title"
                  },
                  {
                    "tr_title": "storage[2].iposTypes[1].deviceTypes[0].details.titleArray[6].tr_title"
                  },
                  {
                    "tr_title": "storage[2].iposTypes[1].deviceTypes[0].details.titleArray[7].tr_title"
                  }
                ],
                "badgeIcons": [
                  {
                    "storageType": "GB",
                    "items": [
                      {
                        "tr_badgeTitle": "storage[2].iposTypes[1].deviceTypes[0].details.badgeIcons[0].items[0].tr_badgeTitle",
                        "tr_badgeSubTitle": "storage[2].iposTypes[1].deviceTypes[0].details.badgeIcons[0].items[0].tr_badgeSubTitle"
                      },
                      {
                        "tr_badgeTitle": "storage[2].iposTypes[1].deviceTypes[0].details.badgeIcons[0].items[1].tr_badgeTitle",
                        "tr_badgeSubTitle": "storage[2].iposTypes[1].deviceTypes[0].details.badgeIcons[0].items[1].tr_badgeSubTitle"
                      },
                      {
                        "tr_badgeTitle": "storage[2].iposTypes[1].deviceTypes[0].details.badgeIcons[0].items[2].tr_badgeTitle",
                        "tr_badgeSubTitle": "storage[2].iposTypes[1].deviceTypes[0].details.badgeIcons[0].items[2].tr_badgeSubTitle"
                      }
                    ]
                  },
                  {
                    "storageType": "TB",
                    "items": [
                      {
                        "tr_badgeTitle": "storage[2].iposTypes[1].deviceTypes[0].details.badgeIcons[1].items[0].tr_badgeTitle",
                        "tr_badgeSubTitle": "storage[2].iposTypes[1].deviceTypes[0].details.badgeIcons[1].items[0].tr_badgeSubTitle"
                      },
                      {
                        "tr_badgeTitle": "storage[2].iposTypes[1].deviceTypes[0].details.badgeIcons[1].items[1].tr_badgeTitle",
                        "tr_badgeSubTitle": "storage[2].iposTypes[1].deviceTypes[0].details.badgeIcons[1].items[1].tr_badgeSubTitle"
                      },
                      {
                        "tr_badgeTitle": "storage[2].iposTypes[1].deviceTypes[0].details.badgeIcons[1].items[2].tr_badgeTitle",
                        "tr_badgeSubTitle": "storage[2].iposTypes[1].deviceTypes[0].details.badgeIcons[1].items[2].tr_badgeSubTitle"
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "SSD_AND_HDD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "storage[3].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/std12storage.svg",
                "tr_preTitle": "storage[3].iposTypes[0].deviceTypes[0].details.tr_preTitle",
                "tr_preTitle1": "storage[3].iposTypes[0].deviceTypes[0].details.tr_preTitle1",
                "tr_preTitle2": "storage[3].iposTypes[0].deviceTypes[0].details.tr_preTitle2",
                "tr_preTitle3": "storage[3].iposTypes[0].deviceTypes[0].details.tr_preTitle3",
                "titleArray": [
                  {
                    "tr_title": "storage[3].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_title",
                    "tr_title1": "storage[3].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_title1",
                    "tr_subtitle": "storage[3].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_subtitle",
                    "tr_subtitle1": "storage[3].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_subtitle1",
                    "tr_subtitle2": "storage[3].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_subtitle2",
                    "tr_subtitle3": "storage[3].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_subtitle3"
                  }
                ],
                "badgeIcons": [
                  {
                    "tr_badgeTitle": "storage[3].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeTitle1": "storage[3].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle1",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "tr_badgeTitle": "storage[3].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OPTANE",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "storage[4].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/intel_optane_storage.svg",
                "badgeUrl2": "assets/images/std12storage.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "storage[4].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "storage[4].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "storage[4].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "tr_pretitle1": "storage[4].iposTypes[0].deviceTypes[0].details.tr_pretitle1",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "storage[4].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ],
                "titleArray": [
                  {
                    "tr_subtitle": "storage[4].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_subtitle",
                    "tr_title": "storage[4].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_title"
                  }
                ],
                "panel2BadgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-frequency-badge.png",
                    "tr_badgeTitle": "storage[4].iposTypes[0].deviceTypes[0].details.panel2BadgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "storage[4].iposTypes[0].deviceTypes[0].details.panel2BadgeIcons[0].tr_badgeSubTitle",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/optane-memory-storage-badge.svg",
                    "tr_badgeTitle": "storage[4].iposTypes[0].deviceTypes[0].details.panel2BadgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "storage[4].iposTypes[0].deviceTypes[0].details.panel2BadgeIcons[1].tr_badgeSubTitle",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "assets/images/memory-time-badge.png",
                    "tr_badgeTitle": "storage[4].iposTypes[0].deviceTypes[0].details.panel2BadgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "storage[4].iposTypes[0].deviceTypes[0].details.panel2BadgeIcons[2].tr_badgeSubTitle",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "storage[5].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "detailBGUrl": "",
                "tr_subtitle": "storage[5].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "storage[5].iposTypes[0].deviceTypes[0].details.tr_title"
              }
            }
          ]
        }
      ]
    }
  ],
  "display": [
    {
      "contentType": "TOUCH_SCREEN",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "display[0].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "display[0].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "display[0].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "display[0].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "display[0].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "display[0].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle",
                    "tr_badgePreTitle": "display[0].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "display[0].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "display[0].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeSubTitle",
                    "tr_badgePreTitle": "display[0].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "display[0].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "DISPLAY",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "display[1].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "",
                "tr_title": "display[1].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "display[1].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "display[1].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "display[1].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "display[1].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle",
                    "tr_badgePreTitle": "display[1].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "display[1].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "display[1].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeSubTitle",
                    "tr_badgePreTitle": "display[1].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgePreTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "display[1].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/display-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_title": "display[1].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_pretitle": "display[1].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle": "display[1].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "display[1].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "display[1].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle",
                    "tr_badgePreTitle": "display[1].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "display[1].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "display[1].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle",
                    "tr_badgePreTitle": "display[1].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/display-touchscreen-badge.svg",
                    "tr_badgeTitle": "display[1].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "4K",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "display[2].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_badgeTitle": "display[2].iposTypes[0].deviceTypes[0].details.tr_badgeTitle",
                "tr_title": "display[2].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "display[2].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "display[2].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  },
                  {
                    "badgeUrl": "assets/images/display-badge.svg",
                    "tr_badgeTitle": "display[2].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "display[2].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle",
                    "tr_badgePreTitle": "display[2].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/display-inch-badge.svg",
                    "tr_badgeTitle": "display[2].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "display[2].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeSubTitle",
                    "tr_badgePreTitle": "display[2].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgePreTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "display[3].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "display[3].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_title",
                    "tr_subTitle": "display[3].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_subTitle"
                  },
                  {
                    "tr_title": "display[3].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_title",
                    "tr_subTitle": "display[3].iposTypes[0].deviceTypes[0].details.titleArray[1].tr_subTitle"
                  },
                  {
                    "tr_title": "display[3].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_title",
                    "tr_subTitle": "display[3].iposTypes[0].deviceTypes[0].details.titleArray[2].tr_subTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "connectivity": [
    {
      "contentType": "VERSION_3",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "connectivity[0].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "connectivity[0].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "connectivity[0].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_title1": "connectivity[0].iposTypes[0].deviceTypes[0].details.tr_title1",
                "titleArray": [
                  {
                    "tr_title": "connectivity[0].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_title",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "connectivity[0].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-4k.png",
                    "tr_badgeTitle": "connectivity[0].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "connectivity[0].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-graphic.png",
                    "tr_badgeTitle": "connectivity[0].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "connectivity[0].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-networking.png",
                    "tr_badgeTitle": "connectivity[0].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "connectivity[0].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgePreTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "connectivity[0].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "tr_subtitle": "connectivity[0].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "connectivity[0].iposTypes[1].deviceTypes[0].details.tr_title",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_pretitle": "connectivity[0].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "connectivity[0].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "connectivity[0].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle",
                    "tr_badgePreTitle": "",
                    "imgUrl": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "connectivity[0].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "connectivity[0].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle",
                    "tr_badgePreTitle": ""
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "connectivity[0].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "connectivity[0].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeSubTitle",
                    "tr_badgePreTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_4",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "connectivity[1].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "connectivity[1].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "connectivity[1].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_title1": "connectivity[1].iposTypes[0].deviceTypes[0].details.tr_title1",
                "tr_noteSection": "connectivity[1].iposTypes[0].deviceTypes[0].details.tr_noteSection",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "connectivity[1].iposTypes[0].deviceTypes[0].details.titleArray[0].tr_title",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "connectivity[1].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "connectivity[1].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "connectivity[1].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "connectivity[1].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "connectivity[1].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "connectivity[1].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "connectivity[1].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "connectivity[1].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "connectivity[1].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgePreTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "connectivity[1].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "connectivity[1].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "connectivity[1].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_pretitle": "connectivity[1].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "tr_noteSection": "connectivity[1].iposTypes[1].deviceTypes[0].details.tr_noteSection",
                "badgeUrl": "assets/images/badges/connectivity-badge.svg",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-icon.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "connectivity[1].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "",
                    "tr_badgeSubTitle": "connectivity[1].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "connectivity[1].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "connectivity[1].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgePreTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "EVO",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "connectivity[1].iposTypes[2].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "badgesAndHeadings",
                "tr_subtitle": "connectivity[1].iposTypes[2].deviceTypes[0].details.tr_subtitle",
                "tr_title": "connectivity[1].iposTypes[2].deviceTypes[0].details.tr_title",
                "tr_noteSection": "connectivity[1].iposTypes[2].deviceTypes[0].details.tr_noteSection",
                "badgeUrl": "assets/images/evo_thunderbolt4.png",
                "tileBG": "assets/images/badges/connectivity-badge.svg",
                "titleArray": [
                  {
                    "tr_title": "connectivity[1].iposTypes[2].deviceTypes[0].details.titleArray[0].tr_title",
                    "tr_subTitle": ""
                  }
                ],
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/thunderbolt-create.png",
                    "tr_badgeTitle": "connectivity[1].iposTypes[2].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "connectivity[1].iposTypes[2].deviceTypes[0].details.badgeIcons[0].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-dock.png",
                    "tr_badgeTitle": "connectivity[1].iposTypes[2].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "connectivity[1].iposTypes[2].deviceTypes[0].details.badgeIcons[1].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-game.png",
                    "tr_badgeTitle": "connectivity[1].iposTypes[2].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "connectivity[1].iposTypes[2].deviceTypes[0].details.badgeIcons[2].tr_badgePreTitle"
                  },
                  {
                    "badgeUrl": "assets/images/thunderbolt-peformance.png",
                    "tr_badgeTitle": "connectivity[1].iposTypes[2].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "",
                    "tr_badgePreTitle": "connectivity[1].iposTypes[2].deviceTypes[0].details.badgeIcons[3].tr_badgePreTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "wifi": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "badgesAndHeadings",
                "badgeUrl": "",
                "tr_subtitle": "wifi[0].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[0].iposTypes[0].deviceTypes[0].details.tr_title",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-badge.png",
                    "tr_badgeTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle",
                    "tr_badgeSubTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeSubTitle"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle",
                    "tr_badgeSubTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeSubTitle"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeTitle",
                    "tr_badgeSubTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[3].tr_badgeSubTitle"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                    "tr_badgeTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeTitle",
                    "tr_badgeSubTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[4].tr_badgeSubTitle"
                  },
                  {
                    "badgeUrl": "assets/images/Wi-fi-6E-badge.svg",
                    "tr_badgeTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[5].tr_badgeTitle",
                    "tr_badgeSubTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[5].tr_badgeSubTitle"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[6].tr_badgeTitle",
                    "tr_badgeSubTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[6].tr_badgeSubTitle"
                  },
                  {
                    "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                    "tr_badgeTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[7].tr_badgeTitle",
                    "tr_badgeSubTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[7].tr_badgeSubTitle"
                  },
                  {
                    "badgeUrl": "assets/images/standard_generic_wifi.png",
                    "tr_badgeTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[8].tr_badgeTitle",
                    "tr_badgeSubTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[8].tr_badgeSubTitle"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-7-badge.png",
                    "tr_badgeTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[9].tr_badgeTitle",
                    "tr_badgeSubTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[9].tr_badgeSubTitle"
                  },
                  {
                    "badgeUrl": "assets/images/killerwifi-7-badge.png",
                    "tr_badgeTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[10].tr_badgeTitle",
                    "tr_badgeSubTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[10].tr_badgeSubTitle"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[11].tr_badgeTitle",
                    "tr_badgeSubTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[11].tr_badgeSubTitle"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                    "tr_badgeTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[12].tr_badgeTitle",
                    "tr_badgeSubTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[12].tr_badgeSubTitle"
                  },
                  {
                    "badgeUrl": "assets/images/wifi-6-badge.png",
                    "tr_badgeTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[13].tr_badgeTitle",
                    "tr_badgeSubTitle": "wifi[0].iposTypes[0].deviceTypes[0].details.badgeIcons[13].tr_badgeSubTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[1].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "wifi[1].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[1].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_noteSection": "wifi[1].iposTypes[0].deviceTypes[0].details.tr_noteSection",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[1].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[1].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "wifi[1].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[1].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_pretitle": "wifi[1].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[1].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[1].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[1].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[2].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6E-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "wifi[2].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[2].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_noteSection": "wifi[2].iposTypes[0].deviceTypes[0].details.tr_noteSection"
              }
            },
            {
              "deviceType": "MODEL_AX101",
              "details": {
                "tr_tileTitle": "wifi[2].iposTypes[0].deviceTypes[1].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-6-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "wifi[2].iposTypes[0].deviceTypes[1].details.tr_subtitle",
                "tr_title": "wifi[2].iposTypes[0].deviceTypes[1].details.tr_title",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[2].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "wifi[2].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[2].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_pretitle": "wifi[2].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[2].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[2].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[2].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[3].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "wifi[3].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[3].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_noteSection": ""
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "OTHERS",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[4].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/wifi-6E-Gig-badge.png",
                "tr_subtitle": "wifi[4].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[4].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_noteSection": "wifi[4].iposTypes[0].deviceTypes[0].details.tr_noteSection",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[4].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[4].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "wifi[4].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[4].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_pretitle": "wifi[4].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[4].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[4].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[4].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "NON_INTEL",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[5].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "tileBG": "",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_title": "wifi[5].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_subtitle": "wifi[5].iposTypes[0].deviceTypes[0].details.tr_subtitle"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6E_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[6].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1675_badge.svg",
                "tr_subtitle": "wifi[6].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[6].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[6].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "wifi[6].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[6].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_pretitle": "wifi[6].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[6].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[6].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[6].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "INTEL_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[7].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/[image_path].png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "wifi[7].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[7].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "wifi[7].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[7].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[7].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[7].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_6_KILLER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[8].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "tr_subtitle": "wifi[8].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[8].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[8].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/standard_wifi_killer_AX1650_badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "wifi[8].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[8].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_pretitle": "wifi[8].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[8].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[8].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[8].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_5",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[9].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "badgeUrl": "",
                "tr_subtitle": "wifi[9].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[9].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_noteSection": "wifi[9].iposTypes[0].deviceTypes[0].details.tr_noteSection",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "THIRDPARTY_6E",
      "iposTypes": [
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[10].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "wifi[10].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[10].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_pretitle": "wifi[10].iposTypes[0].deviceTypes[0].details.tr_pretitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[10].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[10].iposTypes[0].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[10].iposTypes[0].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[11].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/wifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "wifi[11].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[11].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[11].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "wifi[11].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[11].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_pretitle": "wifi[11].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[11].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[11].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[11].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "KILLER_VERSION_7",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[12].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTop",
                "panelType2": "",
                "tr_subtitle": "",
                "tr_title": "wifi[12].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_noteSection": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "wifi[12].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/killerwifi-7-badge.png",
                "panelType1": "header-IconTopGaming",
                "panelType2": "gaming-graphics",
                "tr_subtitle": "wifi[12].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "wifi[12].iposTypes[1].deviceTypes[0].details.tr_title",
                "tr_pretitle": "wifi[12].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[12].iposTypes[1].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[12].iposTypes[1].deviceTypes[0].details.badgeIcons[1].tr_badgeTitle"
                  },
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "wifi[12].iposTypes[1].deviceTypes[0].details.badgeIcons[2].tr_badgeTitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "battery": [
    {
      "contentType": "COMMON",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "battery[0].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "",
                "panelType2": "badgesAndHeadings",
                "badgeIcons": [
                  {
                    "badgeUrl": "assets/images/memory-power-icon.png",
                    "tr_badgeTitle": "battery[0].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": "battery[0].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeSubTitle"
                  }
                ],
                "tr_subtitle": "",
                "tr_title": ""
              }
            }
          ]
        },
        {
          "iposType": "GAMING",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "battery[0].iposTypes[1].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-IconTopGaming",
                "panelType2": "",
                "tr_pretitle": "battery[0].iposTypes[1].deviceTypes[0].details.tr_pretitle",
                "tr_subtitle": "battery[0].iposTypes[1].deviceTypes[0].details.tr_subtitle",
                "tr_title": "battery[0].iposTypes[1].deviceTypes[0].details.tr_title"
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "GOOD",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "battery[1].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "battery[1].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "battery[1].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_noteSection": "battery[1].iposTypes[0].deviceTypes[0].details.tr_noteSection",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "battery[1].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BETTER",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "battery[2].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "battery[2].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "battery[2].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_noteSection": "battery[2].iposTypes[0].deviceTypes[0].details.tr_noteSection",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "battery[2].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "BEST",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "battery[3].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "battery[3].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "battery[3].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_noteSection": "battery[3].iposTypes[0].deviceTypes[0].details.tr_noteSection",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "battery[3].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "contentType": "EVO",
      "iposTypes": [
        {
          "iposType": "STANDARD",
          "deviceTypes": [
            {
              "deviceType": "DEFAULT",
              "details": {
                "tr_tileTitle": "battery[4].iposTypes[0].deviceTypes[0].details.tr_tileTitle",
                "detailBGUrl": "",
                "badgeUrl": "assets/images/badges/battery-badge.svg",
                "panelType1": "header-BadgeIconTop",
                "panelType2": "",
                "tr_subtitle": "battery[4].iposTypes[0].deviceTypes[0].details.tr_subtitle",
                "tr_title": "battery[4].iposTypes[0].deviceTypes[0].details.tr_title",
                "tr_noteSection": "battery[4].iposTypes[0].deviceTypes[0].details.tr_noteSection",
                "badgeIcons": [
                  {
                    "badgeUrl": "",
                    "tr_badgeTitle": "battery[4].iposTypes[0].deviceTypes[0].details.badgeIcons[0].tr_badgeTitle",
                    "tr_badgeSubTitle": ""
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "headerTabs": [
    {
      "tabName": "homepage",
      "headerDetails": {
        "tr_specTitle": "headerTabs[0].headerDetails.tr_specTitle",
        "targetURL": "Home",
        "iconURL": "assets/images/home.svg"
      }
    },
    {
      "tabName": "why_this_pc",
      "headerDetails": {
        "tr_specTitle": "headerTabs[1].headerDetails.tr_specTitle",
        "targetURL": "WhyThisPC",
        "iconURL": "assets/images/whythispc.svg"
      }
    },
    {
      "tabName": "pc_specs",
      "headerDetails": {
        "tr_specTitle": "headerTabs[2].headerDetails.tr_specTitle",
        "targetURL": "PcSpec",
        "iconURL": "assets/images/pcspecs.svg"
      }
    },
    {
      "tabName": "remote",
      "headerDetails": {
        "tr_specTitle": "headerTabs[3].headerDetails.tr_specTitle",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "mobile",
      "headerDetails": {
        "tr_specTitle": "headerTabs[4].headerDetails.tr_specTitle",
        "targetURL": "",
        "iconURL": "assets/images/Frame_3468.svg"
      }
    },
    {
      "tabName": "legal",
      "headerDetails": {
        "tr_specTitle": "headerTabs[5].headerDetails.tr_specTitle",
        "tr_paragraph_array": [
          "headerTabs[5].headerDetails.tr_paragraph_array[0]",
          "headerTabs[5].headerDetails.tr_paragraph_array[1]",
          "headerTabs[5].headerDetails.tr_paragraph_array[2]",
          "headerTabs[5].headerDetails.tr_paragraph_array[3]",
          "headerTabs[5].headerDetails.tr_paragraph_array[4]",
          "headerTabs[5].headerDetails.tr_paragraph_array[5]",
          "headerTabs[5].headerDetails.tr_paragraph_array[6]",
          "headerTabs[5].headerDetails.tr_paragraph_array[7]",
          "headerTabs[5].headerDetails.tr_paragraph_array[8]",
          "headerTabs[5].headerDetails.tr_paragraph_array[9]",
          "headerTabs[5].headerDetails.tr_paragraph_array[10]",
          "headerTabs[5].headerDetails.tr_paragraph_array[11]",
          "headerTabs[5].headerDetails.tr_paragraph_array[12]",
          "headerTabs[5].headerDetails.tr_paragraph_array[13]",
          "headerTabs[5].headerDetails.tr_paragraph_array[14]",
          "headerTabs[5].headerDetails.tr_paragraph_array[15]",
          "headerTabs[5].headerDetails.tr_paragraph_array[16]",
          "headerTabs[5].headerDetails.tr_paragraph_array[17]",
          "headerTabs[5].headerDetails.tr_paragraph_array[18]",
          "headerTabs[5].headerDetails.tr_paragraph_array[19]",
          "headerTabs[5].headerDetails.tr_paragraph_array[20]",
          "headerTabs[5].headerDetails.tr_paragraph_array[21]",
          "headerTabs[5].headerDetails.tr_paragraph_array[22]",
          "headerTabs[5].headerDetails.tr_paragraph_array[23]",
          "headerTabs[5].headerDetails.tr_paragraph_array[24]",
          "headerTabs[5].headerDetails.tr_paragraph_array[25]",
          "headerTabs[5].headerDetails.tr_paragraph_array[26]",
          "headerTabs[5].headerDetails.tr_paragraph_array[27]",
          "headerTabs[5].headerDetails.tr_paragraph_array[28]",
          "headerTabs[5].headerDetails.tr_paragraph_array[29]",
          "headerTabs[5].headerDetails.tr_paragraph_array[30]",
          "headerTabs[5].headerDetails.tr_paragraph_array[31]",
          "headerTabs[5].headerDetails.tr_paragraph_array[32]",
          "headerTabs[5].headerDetails.tr_paragraph_array[33]",
          "headerTabs[5].headerDetails.tr_paragraph_array[34]",
          "headerTabs[5].headerDetails.tr_paragraph_array[35]",
          "headerTabs[5].headerDetails.tr_paragraph_array[36]",
          "headerTabs[5].headerDetails.tr_paragraph_array[37]",
          "headerTabs[5].headerDetails.tr_paragraph_array[38]",
          "headerTabs[5].headerDetails.tr_paragraph_array[39]",
          "headerTabs[5].headerDetails.tr_paragraph_array[40]",
          "headerTabs[5].headerDetails.tr_paragraph_array[41]",
          "headerTabs[5].headerDetails.tr_paragraph_array[42]",
          "headerTabs[5].headerDetails.tr_paragraph_array[43]",
          "headerTabs[5].headerDetails.tr_paragraph_array[44]",
          "headerTabs[5].headerDetails.tr_paragraph_array[45]",
          "headerTabs[5].headerDetails.tr_paragraph_array[46]",
          "headerTabs[5].headerDetails.tr_paragraph_array[47]",
          "headerTabs[5].headerDetails.tr_paragraph_array[48]",
          "headerTabs[5].headerDetails.tr_paragraph_array[49]",
          "headerTabs[5].headerDetails.tr_paragraph_array[50]",
          "headerTabs[5].headerDetails.tr_paragraph_array[51]",
          "headerTabs[5].headerDetails.tr_paragraph_array[52]",
          "headerTabs[5].headerDetails.tr_paragraph_array[53]",
          "headerTabs[5].headerDetails.tr_paragraph_array[54]",
          "headerTabs[5].headerDetails.tr_paragraph_array[55]",
          "headerTabs[5].headerDetails.tr_paragraph_array[56]",
          "headerTabs[5].headerDetails.tr_paragraph_array[57]",
          "headerTabs[5].headerDetails.tr_paragraph_array[58]",
          "headerTabs[5].headerDetails.tr_paragraph_array[59]",
          "headerTabs[5].headerDetails.tr_paragraph_array[60]",
          "headerTabs[5].headerDetails.tr_paragraph_array[61]",
          "headerTabs[5].headerDetails.tr_paragraph_array[62]"
        ],
        "targetURL": "",
        "iconURL": ""
      }
    },
    {
      "tabName": "navigation",
      "navigationTabs": [
        {
          "tr_specTitle": "headerTabs[6].navigationTabs[0].tr_specTitle",
          "targetURL": "mob-content",
          "iconURL": "assets/images/nav-home.svg"
        },
        {
          "tr_specTitle": "headerTabs[6].navigationTabs[1].tr_specTitle",
          "targetURL": "compare",
          "iconURL": ""
        },
        {
          "tr_specTitle": "headerTabs[6].navigationTabs[2].tr_specTitle",
          "targetURL": "trending",
          "iconURL": "assets/images/trending.svg"
        }
      ]
    }
  ],
  "trending": [
    {
      "tr_specTitle": "trending[0].tr_specTitle",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video"
    },
    {
      "tr_specTitle": "trending[1].tr_specTitle",
      "targetURL": "assets/videos/trending/IPOS_AI_Video.mp4",
      "iconURL": "",
      "videoName": "IPOS_AI_Video"
    },
    {
      "tr_specTitle": "trending[2].tr_specTitle",
      "targetURL": "assets/lang/[XX]/trending/Ultra_Processor_Video_Panel2.mp4",
      "iconURL": "",
      "videoName": "Ultra_Processor_Video_Panel2"
    },
    {
      "tr_specTitle": "trending[3].tr_specTitle",
      "targetURL": "assets/lang/[XX]/trending/text_link.mp4",
      "iconURL": "",
      "videoName": "text_link"
    },
    {
      "tr_specTitle": "trending[4].tr_specTitle",
      "targetURL": "assets/lang/[XX]/trending/photo_link.mp4",
      "iconURL": "",
      "videoName": "photo_link"
    },
    {
      "tr_specTitle": "trending[5].tr_specTitle",
      "targetURL": "assets/lang/[XX]/trending/imgUpscaling_link.mp4",
      "iconURL": "",
      "videoName": "imgUpscaling_link"
    },
    {
      "tr_specTitle": "trending[6].tr_specTitle",
      "targetURL": "assets/lang/[XX]/trending/Security_link.mp4",
      "iconURL": "",
      "videoName": "Security_link"
    },
    {
      "tr_specTitle": "trending[7].tr_specTitle",
      "targetURL": "assets/lang/[XX]/trending/copilot_link.mp4",
      "iconURL": "",
      "videoName": "copilot_link"
    },
    {
      "tr_specTitle": "trending[8].tr_specTitle",
      "targetURL": "assets/lang/[XX]/trending/Collaboration_link.mp4",
      "iconURL": "",
      "videoName": "Collaboration_link"
    },
    {
      "tr_specTitle": "trending[9].tr_specTitle",
      "targetURL": "assets/lang/[XX]/trending/Gesture_link.mp4",
      "iconURL": "",
      "videoName": "Gesture_link"
    },
    {
      "tr_specTitle": "trending[10].tr_specTitle",
      "targetURL": "assets/videos/trending/unison_evo_mtl_ultra_processor_video.mp4",
      "iconURL": "",
      "videoName": "unison_evo_mtl_ultra_processor_video"
    },
    {
      "tr_specTitle": "trending[11].tr_specTitle",
      "targetURL": "assets/videos/trending/intel_iris_plus_graphics.mp4",
      "iconURL": "",
      "videoName": "intel_iris_plus_graphics"
    },
    {
      "tr_specTitle": "trending[12].tr_specTitle",
      "targetURL": "assets/videos/trending/standard_home_detail_run_your_life_with_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_run_your_life_with_microsoft_365"
    },
    {
      "tr_specTitle": "trending[13].tr_specTitle",
      "targetURL": "assets/videos/trending/standard_home_detail_onedrive_in_microsoft_365.mp4",
      "iconURL": "",
      "videoName": "standard_home_detail_onedrive_in_microsoft_365"
    },
    {
      "tr_specTitle": "trending[14].tr_specTitle",
      "targetURL": "assets/videos/trending/windows_10_home.mp4",
      "iconURL": "",
      "videoName": "windows_10_home"
    },
    {
      "tr_specTitle": "trending[15].tr_specTitle",
      "targetURL": "assets/videos/trending/gaming_processor_detail_enthusiast _overclocking.mp4",
      "iconURL": "",
      "videoName": "gaming_processor_detail_enthusiast _overclocking"
    },
    {
      "tr_specTitle": "trending[16].tr_specTitle",
      "targetURL": "assets/videos/trending/optane_memory.mp4",
      "iconURL": "",
      "videoName": "optane_memory"
    },
    {
      "tr_specTitle": "trending[17].tr_specTitle",
      "targetURL": "assets/videos/trending/standard_detail_panel_memory_optanememory_optane-memory.mp4",
      "iconURL": "",
      "videoName": "standard_detail_panel_memory_optanememory_optane-memory"
    },
    {
      "tr_specTitle": "trending[18].tr_specTitle",
      "targetURL": "assets/lang/[XX]/trending/Samsung_OLED.mp4",
      "iconURL": "",
      "videoName": "Samsung_OLED"
    },
    {
      "tr_specTitle": "trending[19].tr_specTitle",
      "targetURL": "assets/videos/trending/standard__wifi_killer_7.mp4",
      "iconURL": "",
      "videoName": "standard__wifi_7_killer"
    },
    {
      "tr_specTitle": "trending[20].tr_specTitle",
      "targetURL": "assets/videos/trending/standard_wifi_7.mp4",
      "iconURL": "",
      "videoName": "standard_wifi_7"
    },
    {
      "tr_specTitle": "trending[21].tr_specTitle",
      "targetURL": "assets/videos/trending/wifi_7_desktop_video.mp4",
      "iconURL": "",
      "videoName": "wifi_7_desktop_video"
    },
    {
      "tr_specTitle": "trending[22].tr_specTitle",
      "targetURL": "assets/lang/[XX]/trending/nongaming__wifi_6e.mp4",
      "iconURL": "",
      "videoName": "wifi_6e"
    },
    {
      "tr_specTitle": "trending[23].tr_specTitle",
      "targetURL": "assets/videos/trending/standard_tile_wifi_intel_panel2_video.mp4",
      "iconURL": "",
      "videoName": "standard_tile_wifi_intel_panel2_video"
    },
    {
      "tr_specTitle": "trending[24].tr_specTitle",
      "targetURL": "assets/videos/trending/standard_facttag_details_thunderbolt_version3.mp4",
      "iconURL": "",
      "videoName": "standard_facttag_details_thunderbolt_version3"
    },
    {
      "tr_specTitle": "trending[25].tr_specTitle",
      "targetURL": "assets/videos/trending/gaming_graphics_detail.mp4",
      "iconURL": "",
      "videoName": "gaming_graphics_detail"
    },
    {
      "tr_specTitle": "trending[26].tr_specTitle",
      "targetURL": "assets/videos/trending/gaming_detail_panel_influencer_video.mp4",
      "iconURL": "",
      "videoName": "gaming_detail_panel_influencer_video"
    }
  ],
  "uiText": {
    "tr_welcomePageTitle": "uiText.tr_welcomePageTitle",
    "tr_welcomePagetrSubtitle": "uiText.tr_welcomePagetrSubtitle",
    "tr_welcomePagembSubtitle": "uiText.tr_welcomePagembSubtitle",
    "tr_welcomePageBtnText": "uiText.tr_welcomePageBtnText",
    "tr_welcomePageText": "uiText.tr_welcomePageText",
    "tr_scrollText": "uiText.tr_scrollText",
    "tr_toolTipTextc1": "uiText.tr_toolTipTextc1",
    "tr_toolTipTextc2": "uiText.tr_toolTipTextc2",
    "tr_toolTipTextc3": "uiText.tr_toolTipTextc3",
    "tr_toolTipTextc4": "uiText.tr_toolTipTextc4",
    "tr_toolTipTextmb1": "uiText.tr_toolTipTextmb1",
    "tr_toolTipTextmb2": "uiText.tr_toolTipTextmb2",
    "tr_toolTipTextmb3": "uiText.tr_toolTipTextmb3",
    "tr_nextButton": "uiText.tr_nextButton",
    "tr_prevButton": "uiText.tr_prevButton",
    "tr_gotItButton": "uiText.tr_gotItButton",
    "tr_skipButton": "uiText.tr_skipButton",
    "tr_placeholderText": "uiText.tr_placeholderText",
    "tr_processorHeading": "uiText.tr_processorHeading",
    "tr_processorSubheading": "uiText.tr_processorSubheading",
    "tr_memorySubheading": "uiText.tr_memorySubheading",
    "tr_storageSubheading": "uiText.tr_storageSubheading",
    "tr_coachmarkProcessor": "uiText.tr_coachmarkProcessor",
    "tr_coachmarkGraphics": "uiText.tr_coachmarkGraphics",
    "tr_coachmarkMemory": "uiText.tr_coachmarkMemory",
    "tr_coachmarkOS": "uiText.tr_coachmarkOS",
    "tr_deviceName": "uiText.tr_deviceName",
    "tr_price": "uiText.tr_price",
    "tr_compare": "uiText.tr_compare",
    "tr_viewallspecs": "uiText.tr_viewallspecs",
    "tr_operatingSystem": "uiText.tr_operatingSystem",
    "tr_AmdProcessorNonIntel": "uiText.tr_AmdProcessorNonIntel",
    "tr_QualcommProcessorNonIntel": "uiText.tr_QualcommProcessorNonIntel",
    "tr_GBText": "uiText.tr_GBText",
    "tr_remoteText": "uiText.tr_remoteText",
    "tr_scanErrorText": "uiText.tr_scanErrorText",
    "tr_scanErrorText2": "uiText.tr_scanErrorText2",
    "tr_scanMsg": "uiText.tr_scanMsg",
    "tr_backToScan": "uiText.tr_backToScan",
    "tr_scanToCompare": "uiText.tr_scanToCompare",
    "tr_compareDevice": "uiText.tr_compareDevice",
    "tr_processorTitle": "uiText.tr_processorTitle",
    "tr_graphicTitle": "uiText.tr_graphicTitle",
    "tr_storageTitle": "uiText.tr_storageTitle",
    "tr_displayTitle": "uiText.tr_displayTitle",
    "tr_batteryTitle": "uiText.tr_batteryTitle",
    "tr_memoryTitle": "uiText.tr_memoryTitle",
    "tr_wifiTitle": "uiText.tr_wifiTitle",
    "tr_connectivityTitle": "uiText.tr_connectivityTitle",
    "tr_priceTitle": "uiText.tr_priceTitle",
    "tr_operatingSystemTitle": "uiText.tr_operatingSystemTitle",
    "tr_batteryHoursText": "uiText.tr_batteryHoursText",
    "tr_hrsText": "uiText.tr_hrsText",
    "tr_touchscreeenText": "uiText.tr_touchscreeenText",
    "tr_HDText": "uiText.tr_HDText",
    "tr_FHDText": "uiText.tr_FHDText",
    "tr_4KText": "uiText.tr_4KText",
    "tr_HDTochScreenText": "uiText.tr_HDTochScreenText",
    "tr_FHDTouchScreenText": "uiText.tr_FHDTouchScreenText",
    "tr_4KTouchscreenText": "uiText.tr_4KTouchscreenText",
    "tr_wifi_gigText": "uiText.tr_wifi_gigText",
    "tr_save": "uiText.tr_save",
    "tr_device_comparison": "uiText.tr_device_comparison",
    "tr_Save_As_JPEG": "uiText.tr_Save_As_JPEG",
    "tr_Save_As_PDF": "uiText.tr_Save_As_PDF",
    "tr_Downloading_PDF": "uiText.tr_Downloading_PDF",
    "tr_pdf_file": "uiText.tr_pdf_file",
    "tr_open": "uiText.tr_open",
    "tr_logo": "uiText.tr_logo",
    "tr_laptop_name": "uiText.tr_laptop_name",
    "tr_TBText": "uiText.tr_TBText",
    "tr_optaneMemoryTitle": "uiText.tr_optaneMemoryTitle",
    "tr_inchUnit": "uiText.tr_inchUnit",
    "tr_wifi1": "uiText.tr_wifi1",
    "tr_wifi2": "uiText.tr_wifi2",
    "tr_wifi3": "uiText.tr_wifi3",
    "tr_wifi4": "uiText.tr_wifi4",
    "tr_wifi5": "uiText.tr_wifi5",
    "tr_wifi6": "uiText.tr_wifi6",
    "tr_wifi6Caps": "uiText.tr_wifi6Caps",
    "tr_wifi6e": "uiText.tr_wifi6e",
    "tr_wifi6eValue": "uiText.tr_wifi6eValue",
    "tr_wifiXX": "uiText.tr_wifiXX",
    "tr_wifiXXCaps": "uiText.tr_wifiXXCaps",
    "tr_wifi6E": "uiText.tr_wifi6E",
    "tr_IntelOptaneMemory": "uiText.tr_IntelOptaneMemory",
    "tr_Thunderbolt3": "uiText.tr_Thunderbolt3",
    "tr_Thunderbolt4": "uiText.tr_Thunderbolt4",
    "tr_processorGraphicsCaps": "uiText.tr_processorGraphicsCaps",
    "tr_processorGraphicsSmall": "uiText.tr_processorGraphicsSmall",
    "tr_graphicsCardCaps": "uiText.tr_graphicsCardCaps",
    "tr_graphicsCardSmall": "uiText.tr_graphicsCardSmall",
    "tr_processorTitleCamel": "uiText.tr_processorTitleCamel",
    "tr_graphicTitleCamel": "uiText.tr_graphicTitleCamel",
    "tr_storageTitleCamel": "uiText.tr_storageTitleCamel",
    "tr_displayTitleCamel": "uiText.tr_displayTitleCamel",
    "tr_batteryTitleCamel": "uiText.tr_batteryTitleCamel",
    "tr_memoryTitleCamel": "uiText.tr_memoryTitleCamel",
    "tr_connectivityTitleCamel": "uiText.tr_connectivityTitleCamel",
    "tr_priceTitleCamel": "uiText.tr_priceTitleCamel",
    "tr_operatingSystemTitleCamel": "uiText.tr_operatingSystemTitleCamel",
    "tr_viewallspecsCamel": "uiText.tr_viewallspecsCamel",
    "tr_displayText": "uiText.tr_displayText",
    "tr_displayTextResolution": "uiText.tr_displayTextResolution",
    "tr_displayTextResolutionTouchscreen": "uiText.tr_displayTextResolutionTouchscreen",
    "tr_OS": "uiText.tr_OS",
    "tr_Chrome_OS": "uiText.tr_Chrome_OS",
    "tr_PCSpecs_text": "uiText.tr_PCSpecs_text",
    "tr_explorePC_text": "uiText.tr_explorePC_text",
    "tr_mtlPC_text": "uiText.tr_mtlPC_text",
    "tr_optaneMemory": "uiText.tr_optaneMemory",
    "tr_displayText_Display": "uiText.tr_displayText_Display",
    "tr_displayTextResolution_Display": "uiText.tr_displayTextResolution_Display",
    "tr_displayTextResolutionTouchscreen_Display": "uiText.tr_displayTextResolutionTouchscreen_Display",
    "tr_displayTextTouchscreeenText": "uiText.tr_displayTextTouchscreeenText",
    "tr_learnMoreLabel": "uiText.tr_learnMoreLabel",
    "tr_batteryHoursTextUpperCase": "uiText.tr_batteryHoursTextUpperCase",
    "tr_processorGraphicsIrisXe": "uiText.tr_processorGraphicsIrisXe",
    "tr_processorGraphicsIrisXeMax": "uiText.tr_processorGraphicsIrisXeMax",
    "tr_Thunderbolt3UpperCase": "uiText.tr_Thunderbolt3UpperCase",
    "tr_Thunderbolt4UpperCase": "uiText.tr_Thunderbolt4UpperCase",
    "tr_processorWithArcGraphics": "uiText.tr_processorWithArcGraphics",
    "tr_processorGraphicsIrisXe12Gen": "uiText.tr_processorGraphicsIrisXe12Gen",
    "tr_processorGraphicsIrisXeMax12Gen": "uiText.tr_processorGraphicsIrisXeMax12Gen",
    "tr_samsung_oled": "uiText.tr_samsung_oled",
    "tr_oled": "uiText.tr_oled",
    "tr_processorWithArcGraphics13Gen": "uiText.tr_processorWithArcGraphics13Gen",
    "tr_processorGraphicsIrisXe13Gen": "uiText.tr_processorGraphicsIrisXe13Gen",
    "tr_processorGraphicsIrisXeMax13Gen": "uiText.tr_processorGraphicsIrisXeMax13Gen",
    "tr_arcGraphicsBadgeToModelNameMapping": {
      "tr_A350M": "uiText.tr_arcGraphicsBadgeToModelNameMapping.tr_A350M",
      "tr_A370M": "uiText.tr_arcGraphicsBadgeToModelNameMapping.tr_A370M",
      "tr_A730M": "uiText.tr_arcGraphicsBadgeToModelNameMapping.tr_A730M",
      "tr_A770M": "uiText.tr_arcGraphicsBadgeToModelNameMapping.tr_A770M",
      "tr_A310": "uiText.tr_arcGraphicsBadgeToModelNameMapping.tr_A310",
      "tr_A380": "uiText.tr_arcGraphicsBadgeToModelNameMapping.tr_A380",
      "tr_A580": "uiText.tr_arcGraphicsBadgeToModelNameMapping.tr_A580",
      "tr_A750": "uiText.tr_arcGraphicsBadgeToModelNameMapping.tr_A750",
      "tr_A770": "uiText.tr_arcGraphicsBadgeToModelNameMapping.tr_A770"
    },
    "tr_memoryValue": "uiText.tr_memoryValue",
    "tr_memoryWithOptaneDesc": "uiText.tr_memoryWithOptaneDesc",
    "tr_textGBGB": "uiText.tr_textGBGB",
    "tr_textGBTB": "uiText.tr_textGBTB",
    "tr_textTBGB": "uiText.tr_textTBGB",
    "tr_textTBTB": "uiText.tr_textTBTB",
    "tr_textGBSSD": "uiText.tr_textGBSSD",
    "tr_textTBSSD": "uiText.tr_textTBSSD",
    "tr_textGBHDD": "uiText.tr_textGBHDD",
    "tr_textTBHDD": "uiText.tr_textTBHDD",
    "tr_textStorageWithOptaneGB": "uiText.tr_textStorageWithOptaneGB",
    "tr_textStorageWithOptaneTB": "uiText.tr_textStorageWithOptaneTB",
    "tr_OSDescriptionMapping": {
      "tr_OS1": "uiText.tr_OSDescriptionMapping.tr_OS1",
      "tr_OS2": "uiText.tr_OSDescriptionMapping.tr_OS2",
      "tr_OS3": "uiText.tr_OSDescriptionMapping.tr_OS3",
      "tr_OS4": "uiText.tr_OSDescriptionMapping.tr_OS4",
      "tr_OS5": "uiText.tr_OSDescriptionMapping.tr_OS5",
      "tr_OS6": "uiText.tr_OSDescriptionMapping.tr_OS6",
      "tr_OS7": "uiText.tr_OSDescriptionMapping.tr_OS7",
      "tr_OS8": "uiText.tr_OSDescriptionMapping.tr_OS8",
      "tr_OS9": "uiText.tr_OSDescriptionMapping.tr_OS9",
      "tr_OS10": "uiText.tr_OSDescriptionMapping.tr_OS10",
      "tr_OS11": "uiText.tr_OSDescriptionMapping.tr_OS11",
      "tr_OS12": "uiText.tr_OSDescriptionMapping.tr_OS12",
      "tr_OS13": "uiText.tr_OSDescriptionMapping.tr_OS13",
      "tr_OS14": "uiText.tr_OSDescriptionMapping.tr_OS14",
      "tr_OS15": "uiText.tr_OSDescriptionMapping.tr_OS15",
      "tr_OS16": "uiText.tr_OSDescriptionMapping.tr_OS16",
      "tr_OS17": "uiText.tr_OSDescriptionMapping.tr_OS17",
      "tr_OS18": "uiText.tr_OSDescriptionMapping.tr_OS18",
      "tr_OS19": "uiText.tr_OSDescriptionMapping.tr_OS19",
      "tr_OS20": "uiText.tr_OSDescriptionMapping.tr_OS20",
      "tr_OS21": "uiText.tr_OSDescriptionMapping.tr_OS21",
      "tr_OS22": "uiText.tr_OSDescriptionMapping.tr_OS22",
      "tr_OS23": "uiText.tr_OSDescriptionMapping.tr_OS23",
      "tr_OS24": "uiText.tr_OSDescriptionMapping.tr_OS24",
      "tr_OS25": "uiText.tr_OSDescriptionMapping.tr_OS25"
    },
    "tr_GraphicsDescriptionMapping": {
      "tr_GR1": "uiText.tr_GraphicsDescriptionMapping.tr_GR1",
      "tr_GR2": "uiText.tr_GraphicsDescriptionMapping.tr_GR2",
      "tr_GR3": "uiText.tr_GraphicsDescriptionMapping.tr_GR3",
      "tr_GR4": "uiText.tr_GraphicsDescriptionMapping.tr_GR4",
      "tr_GR5": "uiText.tr_GraphicsDescriptionMapping.tr_GR5",
      "tr_GR6": "uiText.tr_GraphicsDescriptionMapping.tr_GR6",
      "tr_GR7": "uiText.tr_GraphicsDescriptionMapping.tr_GR7",
      "tr_GR8": "uiText.tr_GraphicsDescriptionMapping.tr_GR8",
      "tr_GR9": "uiText.tr_GraphicsDescriptionMapping.tr_GR9",
      "tr_GR10": "uiText.tr_GraphicsDescriptionMapping.tr_GR10",
      "tr_GR11": "uiText.tr_GraphicsDescriptionMapping.tr_GR11",
      "tr_GR12": "uiText.tr_GraphicsDescriptionMapping.tr_GR12",
      "tr_GR13": "uiText.tr_GraphicsDescriptionMapping.tr_GR13",
      "tr_GR14": "uiText.tr_GraphicsDescriptionMapping.tr_GR14",
      "tr_GR15": "uiText.tr_GraphicsDescriptionMapping.tr_GR15",
      "tr_GR16": "uiText.tr_GraphicsDescriptionMapping.tr_GR16",
      "tr_GR17": "uiText.tr_GraphicsDescriptionMapping.tr_GR17",
      "tr_GR18": "uiText.tr_GraphicsDescriptionMapping.tr_GR18",
      "tr_GR19": "uiText.tr_GraphicsDescriptionMapping.tr_GR19",
      "tr_GR20": "uiText.tr_GraphicsDescriptionMapping.tr_GR20",
      "tr_GR21": "uiText.tr_GraphicsDescriptionMapping.tr_GR21",
      "tr_GR22": "uiText.tr_GraphicsDescriptionMapping.tr_GR22",
      "tr_GR23": "uiText.tr_GraphicsDescriptionMapping.tr_GR23",
      "tr_GR24": "uiText.tr_GraphicsDescriptionMapping.tr_GR24",
      "tr_GR25": "uiText.tr_GraphicsDescriptionMapping.tr_GR25",
      "tr_GR26": "uiText.tr_GraphicsDescriptionMapping.tr_GR26",
      "tr_GR27": "uiText.tr_GraphicsDescriptionMapping.tr_GR27",
      "tr_GR28": "uiText.tr_GraphicsDescriptionMapping.tr_GR28",
      "tr_GR29": "uiText.tr_GraphicsDescriptionMapping.tr_GR29",
      "tr_GR30": "uiText.tr_GraphicsDescriptionMapping.tr_GR30",
      "tr_GR31": "uiText.tr_GraphicsDescriptionMapping.tr_GR31",
      "tr_GR32": "uiText.tr_GraphicsDescriptionMapping.tr_GR32",
      "tr_GR33": "uiText.tr_GraphicsDescriptionMapping.tr_GR33",
      "tr_GR34": "uiText.tr_GraphicsDescriptionMapping.tr_GR34",
      "tr_GR35": "uiText.tr_GraphicsDescriptionMapping.tr_GR35",
      "tr_GR36": "uiText.tr_GraphicsDescriptionMapping.tr_GR36",
      "tr_GR37": "uiText.tr_GraphicsDescriptionMapping.tr_GR37",
      "tr_GR38": "uiText.tr_GraphicsDescriptionMapping.tr_GR38",
      "tr_GR39": "uiText.tr_GraphicsDescriptionMapping.tr_GR39",
      "tr_GR40": "uiText.tr_GraphicsDescriptionMapping.tr_GR40",
      "tr_GR41": "uiText.tr_GraphicsDescriptionMapping.tr_GR41",
      "tr_GR42": "uiText.tr_GraphicsDescriptionMapping.tr_GR42",
      "tr_GR43": "uiText.tr_GraphicsDescriptionMapping.tr_GR43",
      "tr_GR44": "uiText.tr_GraphicsDescriptionMapping.tr_GR44",
      "tr_GR45": "uiText.tr_GraphicsDescriptionMapping.tr_GR45",
      "tr_GR46": "uiText.tr_GraphicsDescriptionMapping.tr_GR46",
      "tr_GR47": "uiText.tr_GraphicsDescriptionMapping.tr_GR47",
      "tr_GR48": "uiText.tr_GraphicsDescriptionMapping.tr_GR48",
      "tr_GR49": "uiText.tr_GraphicsDescriptionMapping.tr_GR49",
      "tr_GR50": "uiText.tr_GraphicsDescriptionMapping.tr_GR50",
      "tr_GR51": "uiText.tr_GraphicsDescriptionMapping.tr_GR51",
      "tr_GR52": "uiText.tr_GraphicsDescriptionMapping.tr_GR52",
      "tr_GR53": "uiText.tr_GraphicsDescriptionMapping.tr_GR53",
      "tr_GR54": "uiText.tr_GraphicsDescriptionMapping.tr_GR54",
      "tr_GR55": "uiText.tr_GraphicsDescriptionMapping.tr_GR55",
      "tr_GR56": "uiText.tr_GraphicsDescriptionMapping.tr_GR56",
      "tr_GR57": "uiText.tr_GraphicsDescriptionMapping.tr_GR57",
      "tr_GR58": "uiText.tr_GraphicsDescriptionMapping.tr_GR58",
      "tr_GR59": "uiText.tr_GraphicsDescriptionMapping.tr_GR59",
      "tr_GR60": "uiText.tr_GraphicsDescriptionMapping.tr_GR60",
      "tr_GR61": "uiText.tr_GraphicsDescriptionMapping.tr_GR61",
      "tr_GR62": "uiText.tr_GraphicsDescriptionMapping.tr_GR62",
      "tr_GR63": "uiText.tr_GraphicsDescriptionMapping.tr_GR63",
      "tr_GR64": "uiText.tr_GraphicsDescriptionMapping.tr_GR64",
      "tr_GR65": "uiText.tr_GraphicsDescriptionMapping.tr_GR65",
      "tr_GR66": "uiText.tr_GraphicsDescriptionMapping.tr_GR66",
      "tr_GR67": "uiText.tr_GraphicsDescriptionMapping.tr_GR67",
      "tr_GR68": "uiText.tr_GraphicsDescriptionMapping.tr_GR68",
      "tr_GR69": "uiText.tr_GraphicsDescriptionMapping.tr_GR69",
      "tr_GR70": "uiText.tr_GraphicsDescriptionMapping.tr_GR70",
      "tr_GR71": "uiText.tr_GraphicsDescriptionMapping.tr_GR71",
      "tr_GR72": "uiText.tr_GraphicsDescriptionMapping.tr_GR72",
      "tr_GR73": "uiText.tr_GraphicsDescriptionMapping.tr_GR73",
      "tr_GR74": "uiText.tr_GraphicsDescriptionMapping.tr_GR74",
      "tr_GR75": "uiText.tr_GraphicsDescriptionMapping.tr_GR75",
      "tr_GR76": "uiText.tr_GraphicsDescriptionMapping.tr_GR76",
      "tr_GR77": "uiText.tr_GraphicsDescriptionMapping.tr_GR77",
      "tr_GR78": "uiText.tr_GraphicsDescriptionMapping.tr_GR78",
      "tr_GR79": "uiText.tr_GraphicsDescriptionMapping.tr_GR79",
      "tr_GR80": "uiText.tr_GraphicsDescriptionMapping.tr_GR80",
      "tr_GR81": "uiText.tr_GraphicsDescriptionMapping.tr_GR81",
      "tr_GR82": "uiText.tr_GraphicsDescriptionMapping.tr_GR82",
      "tr_GR83": "uiText.tr_GraphicsDescriptionMapping.tr_GR83",
      "tr_GR84": "uiText.tr_GraphicsDescriptionMapping.tr_GR84",
      "tr_GR85": "uiText.tr_GraphicsDescriptionMapping.tr_GR85",
      "tr_GR86": "uiText.tr_GraphicsDescriptionMapping.tr_GR86",
      "tr_GR87": "uiText.tr_GraphicsDescriptionMapping.tr_GR87",
      "tr_GR88": "uiText.tr_GraphicsDescriptionMapping.tr_GR88",
      "tr_GR89": "uiText.tr_GraphicsDescriptionMapping.tr_GR89",
      "tr_GR90": "uiText.tr_GraphicsDescriptionMapping.tr_GR90",
      "tr_GR91": "uiText.tr_GraphicsDescriptionMapping.tr_GR91",
      "tr_GR92": "uiText.tr_GraphicsDescriptionMapping.tr_GR92",
      "tr_GR93": "uiText.tr_GraphicsDescriptionMapping.tr_GR93",
      "tr_GR94": "uiText.tr_GraphicsDescriptionMapping.tr_GR94",
      "tr_GR95": "uiText.tr_GraphicsDescriptionMapping.tr_GR95",
      "tr_GR96": "uiText.tr_GraphicsDescriptionMapping.tr_GR96",
      "tr_GR97": "uiText.tr_GraphicsDescriptionMapping.tr_GR97",
      "tr_GR98": "uiText.tr_GraphicsDescriptionMapping.tr_GR98",
      "tr_GR99": "uiText.tr_GraphicsDescriptionMapping.tr_GR99",
      "tr_GR100": "uiText.tr_GraphicsDescriptionMapping.tr_GR100",
      "tr_GR101": "uiText.tr_GraphicsDescriptionMapping.tr_GR101",
      "tr_GR102": "uiText.tr_GraphicsDescriptionMapping.tr_GR102",
      "tr_GR103": "uiText.tr_GraphicsDescriptionMapping.tr_GR103",
      "tr_GR104": "uiText.tr_GraphicsDescriptionMapping.tr_GR104",
      "tr_GR105": "uiText.tr_GraphicsDescriptionMapping.tr_GR105",
      "tr_GR106": "uiText.tr_GraphicsDescriptionMapping.tr_GR106",
      "tr_GR107": "uiText.tr_GraphicsDescriptionMapping.tr_GR107",
      "tr_GR108": "uiText.tr_GraphicsDescriptionMapping.tr_GR108",
      "tr_GR109": "uiText.tr_GraphicsDescriptionMapping.tr_GR109",
      "tr_GR110": "uiText.tr_GraphicsDescriptionMapping.tr_GR110",
      "tr_GR111": "uiText.tr_GraphicsDescriptionMapping.tr_GR111",
      "tr_GR112": "uiText.tr_GraphicsDescriptionMapping.tr_GR112",
      "tr_GR113": "uiText.tr_GraphicsDescriptionMapping.tr_GR113",
      "tr_GR114": "uiText.tr_GraphicsDescriptionMapping.tr_GR114",
      "tr_GR115": "uiText.tr_GraphicsDescriptionMapping.tr_GR115",
      "tr_GR116": "uiText.tr_GraphicsDescriptionMapping.tr_GR116",
      "tr_GR117": "uiText.tr_GraphicsDescriptionMapping.tr_GR117",
      "tr_GR118": "uiText.tr_GraphicsDescriptionMapping.tr_GR118",
      "tr_GR119": "uiText.tr_GraphicsDescriptionMapping.tr_GR119",
      "tr_GR120": "uiText.tr_GraphicsDescriptionMapping.tr_GR120",
      "tr_GR121": "uiText.tr_GraphicsDescriptionMapping.tr_GR121",
      "tr_GR122": "uiText.tr_GraphicsDescriptionMapping.tr_GR122",
      "tr_GR123": "uiText.tr_GraphicsDescriptionMapping.tr_GR123",
      "tr_GR124": "uiText.tr_GraphicsDescriptionMapping.tr_GR124",
      "tr_GR125": "uiText.tr_GraphicsDescriptionMapping.tr_GR125",
      "tr_GR126": "uiText.tr_GraphicsDescriptionMapping.tr_GR126",
      "tr_GR127": "uiText.tr_GraphicsDescriptionMapping.tr_GR127",
      "tr_GR128": "uiText.tr_GraphicsDescriptionMapping.tr_GR128",
      "tr_GR129": "uiText.tr_GraphicsDescriptionMapping.tr_GR129",
      "tr_GR130": "uiText.tr_GraphicsDescriptionMapping.tr_GR130",
      "tr_GR131": "uiText.tr_GraphicsDescriptionMapping.tr_GR131",
      "tr_GR132": "uiText.tr_GraphicsDescriptionMapping.tr_GR132",
      "tr_GR133": "uiText.tr_GraphicsDescriptionMapping.tr_GR133",
      "tr_GR134": "uiText.tr_GraphicsDescriptionMapping.tr_GR134",
      "tr_GR135": "uiText.tr_GraphicsDescriptionMapping.tr_GR135",
      "tr_GR136": "uiText.tr_GraphicsDescriptionMapping.tr_GR136",
      "tr_GR137": "uiText.tr_GraphicsDescriptionMapping.tr_GR137",
      "tr_GR138": "uiText.tr_GraphicsDescriptionMapping.tr_GR138",
      "tr_GR139": "uiText.tr_GraphicsDescriptionMapping.tr_GR139",
      "tr_GR140": "uiText.tr_GraphicsDescriptionMapping.tr_GR140",
      "tr_GR141": "uiText.tr_GraphicsDescriptionMapping.tr_GR141",
      "tr_GR142": "uiText.tr_GraphicsDescriptionMapping.tr_GR142",
      "tr_GR143": "uiText.tr_GraphicsDescriptionMapping.tr_GR143",
      "tr_GR144": "uiText.tr_GraphicsDescriptionMapping.tr_GR144",
      "tr_GR145": "uiText.tr_GraphicsDescriptionMapping.tr_GR145",
      "tr_GR146": "uiText.tr_GraphicsDescriptionMapping.tr_GR146",
      "tr_GR147": "uiText.tr_GraphicsDescriptionMapping.tr_GR147",
      "tr_GR148": "uiText.tr_GraphicsDescriptionMapping.tr_GR148",
      "tr_GR149": "uiText.tr_GraphicsDescriptionMapping.tr_GR149",
      "tr_GR150": "uiText.tr_GraphicsDescriptionMapping.tr_GR150",
      "tr_GR151": "uiText.tr_GraphicsDescriptionMapping.tr_GR151",
      "tr_GR152": "uiText.tr_GraphicsDescriptionMapping.tr_GR152",
      "tr_GR153": "uiText.tr_GraphicsDescriptionMapping.tr_GR153",
      "tr_GR154": "uiText.tr_GraphicsDescriptionMapping.tr_GR154",
      "tr_GR155": "uiText.tr_GraphicsDescriptionMapping.tr_GR155",
      "tr_GR156": "uiText.tr_GraphicsDescriptionMapping.tr_GR156",
      "tr_GR157": "uiText.tr_GraphicsDescriptionMapping.tr_GR157",
      "tr_GR158": "uiText.tr_GraphicsDescriptionMapping.tr_GR158",
      "tr_GR159": "uiText.tr_GraphicsDescriptionMapping.tr_GR159",
      "tr_GR160": "uiText.tr_GraphicsDescriptionMapping.tr_GR160",
      "tr_GR161": "uiText.tr_GraphicsDescriptionMapping.tr_GR161",
      "tr_GR162": "uiText.tr_GraphicsDescriptionMapping.tr_GR162",
      "tr_GR163": "uiText.tr_GraphicsDescriptionMapping.tr_GR163",
      "tr_GR164": "uiText.tr_GraphicsDescriptionMapping.tr_GR164",
      "tr_GR165": "uiText.tr_GraphicsDescriptionMapping.tr_GR165",
      "tr_GR166": "uiText.tr_GraphicsDescriptionMapping.tr_GR166",
      "tr_GR167": "uiText.tr_GraphicsDescriptionMapping.tr_GR167",
      "tr_GR168": "uiText.tr_GraphicsDescriptionMapping.tr_GR168",
      "tr_GR169": "uiText.tr_GraphicsDescriptionMapping.tr_GR169",
      "tr_GR170": "uiText.tr_GraphicsDescriptionMapping.tr_GR170",
      "tr_GR171": "uiText.tr_GraphicsDescriptionMapping.tr_GR171",
      "tr_GR172": "uiText.tr_GraphicsDescriptionMapping.tr_GR172",
      "tr_GR173": "uiText.tr_GraphicsDescriptionMapping.tr_GR173",
      "tr_GR174": "uiText.tr_GraphicsDescriptionMapping.tr_GR174",
      "tr_GR175": "uiText.tr_GraphicsDescriptionMapping.tr_GR175",
      "tr_GR176": "uiText.tr_GraphicsDescriptionMapping.tr_GR176",
      "tr_GR177": "uiText.tr_GraphicsDescriptionMapping.tr_GR177",
      "tr_GR178": "uiText.tr_GraphicsDescriptionMapping.tr_GR178",
      "tr_GR179": "uiText.tr_GraphicsDescriptionMapping.tr_GR179",
      "tr_GR180": "uiText.tr_GraphicsDescriptionMapping.tr_GR180",
      "tr_GR181": "uiText.tr_GraphicsDescriptionMapping.tr_GR181",
      "tr_GR182": "uiText.tr_GraphicsDescriptionMapping.tr_GR182",
      "tr_GR183": "uiText.tr_GraphicsDescriptionMapping.tr_GR183",
      "tr_GR184": "uiText.tr_GraphicsDescriptionMapping.tr_GR184",
      "tr_GR185": "uiText.tr_GraphicsDescriptionMapping.tr_GR185",
      "tr_GR186": "uiText.tr_GraphicsDescriptionMapping.tr_GR186",
      "tr_GR187": "uiText.tr_GraphicsDescriptionMapping.tr_GR187",
      "tr_GR188": "uiText.tr_GraphicsDescriptionMapping.tr_GR188",
      "tr_GR189": "uiText.tr_GraphicsDescriptionMapping.tr_GR189",
      "tr_GR190": "uiText.tr_GraphicsDescriptionMapping.tr_GR190",
      "tr_GR191": "uiText.tr_GraphicsDescriptionMapping.tr_GR191",
      "tr_GR192": "uiText.tr_GraphicsDescriptionMapping.tr_GR192",
      "tr_GR193": "uiText.tr_GraphicsDescriptionMapping.tr_GR193",
      "tr_GR194": "uiText.tr_GraphicsDescriptionMapping.tr_GR194",
      "tr_GR195": "uiText.tr_GraphicsDescriptionMapping.tr_GR195",
      "tr_GR196": "uiText.tr_GraphicsDescriptionMapping.tr_GR196",
      "tr_GR197": "uiText.tr_GraphicsDescriptionMapping.tr_GR197",
      "tr_GR198": "uiText.tr_GraphicsDescriptionMapping.tr_GR198",
      "tr_GR199": "uiText.tr_GraphicsDescriptionMapping.tr_GR199",
      "tr_GR200": "uiText.tr_GraphicsDescriptionMapping.tr_GR200",
      "tr_GR201": "uiText.tr_GraphicsDescriptionMapping.tr_GR201",
      "tr_GR202": "uiText.tr_GraphicsDescriptionMapping.tr_GR202",
      "tr_GR203": "uiText.tr_GraphicsDescriptionMapping.tr_GR203",
      "tr_GR204": "uiText.tr_GraphicsDescriptionMapping.tr_GR204",
      "tr_GR205": "uiText.tr_GraphicsDescriptionMapping.tr_GR205",
      "tr_GR206": "uiText.tr_GraphicsDescriptionMapping.tr_GR206",
      "tr_GR207": "uiText.tr_GraphicsDescriptionMapping.tr_GR207",
      "tr_GR208": "uiText.tr_GraphicsDescriptionMapping.tr_GR208",
      "tr_GR209": "uiText.tr_GraphicsDescriptionMapping.tr_GR209",
      "tr_GR210": "uiText.tr_GraphicsDescriptionMapping.tr_GR210",
      "tr_GR211": "uiText.tr_GraphicsDescriptionMapping.tr_GR211",
      "tr_GR212": "uiText.tr_GraphicsDescriptionMapping.tr_GR212",
      "tr_GR213": "uiText.tr_GraphicsDescriptionMapping.tr_GR213",
      "tr_GR214": "uiText.tr_GraphicsDescriptionMapping.tr_GR214",
      "tr_GR215": "uiText.tr_GraphicsDescriptionMapping.tr_GR215",
      "tr_GR216": "uiText.tr_GraphicsDescriptionMapping.tr_GR216",
      "tr_GR217": "uiText.tr_GraphicsDescriptionMapping.tr_GR217",
      "tr_GR218": "uiText.tr_GraphicsDescriptionMapping.tr_GR218",
      "tr_GR219": "uiText.tr_GraphicsDescriptionMapping.tr_GR219",
      "tr_GR220": "uiText.tr_GraphicsDescriptionMapping.tr_GR220",
      "tr_GR221": "uiText.tr_GraphicsDescriptionMapping.tr_GR221",
      "tr_GR222": "uiText.tr_GraphicsDescriptionMapping.tr_GR222",
      "tr_GR223": "uiText.tr_GraphicsDescriptionMapping.tr_GR223",
      "tr_GR224": "uiText.tr_GraphicsDescriptionMapping.tr_GR224",
      "tr_GR225": "uiText.tr_GraphicsDescriptionMapping.tr_GR225",
      "tr_GR226": "uiText.tr_GraphicsDescriptionMapping.tr_GR226",
      "tr_GR227": "uiText.tr_GraphicsDescriptionMapping.tr_GR227",
      "tr_GR228": "uiText.tr_GraphicsDescriptionMapping.tr_GR228",
      "tr_GR229": "uiText.tr_GraphicsDescriptionMapping.tr_GR229",
      "tr_GR230": "uiText.tr_GraphicsDescriptionMapping.tr_GR230",
      "tr_GR231": "uiText.tr_GraphicsDescriptionMapping.tr_GR231",
      "tr_GR232": "uiText.tr_GraphicsDescriptionMapping.tr_GR232",
      "tr_GR233": "uiText.tr_GraphicsDescriptionMapping.tr_GR233",
      "tr_GR234": "uiText.tr_GraphicsDescriptionMapping.tr_GR234",
      "tr_GR235": "uiText.tr_GraphicsDescriptionMapping.tr_GR235",
      "tr_GR236": "uiText.tr_GraphicsDescriptionMapping.tr_GR236",
      "tr_GR237": "uiText.tr_GraphicsDescriptionMapping.tr_GR237",
      "tr_GR238": "uiText.tr_GraphicsDescriptionMapping.tr_GR238",
      "tr_GR239": "uiText.tr_GraphicsDescriptionMapping.tr_GR239",
      "tr_GR240": "uiText.tr_GraphicsDescriptionMapping.tr_GR240",
      "tr_GR241": "uiText.tr_GraphicsDescriptionMapping.tr_GR241",
      "tr_GR242": "uiText.tr_GraphicsDescriptionMapping.tr_GR242",
      "tr_GR243": "uiText.tr_GraphicsDescriptionMapping.tr_GR243",
      "tr_GR244": "uiText.tr_GraphicsDescriptionMapping.tr_GR244",
      "tr_GR245": "uiText.tr_GraphicsDescriptionMapping.tr_GR245",
      "tr_GR246": "uiText.tr_GraphicsDescriptionMapping.tr_GR246",
      "tr_GR247": "uiText.tr_GraphicsDescriptionMapping.tr_GR247",
      "tr_GR248": "uiText.tr_GraphicsDescriptionMapping.tr_GR248",
      "tr_GR249": "uiText.tr_GraphicsDescriptionMapping.tr_GR249",
      "tr_GR250": "uiText.tr_GraphicsDescriptionMapping.tr_GR250",
      "tr_GR251": "uiText.tr_GraphicsDescriptionMapping.tr_GR251",
      "tr_GR252": "uiText.tr_GraphicsDescriptionMapping.tr_GR252",
      "tr_GR253": "uiText.tr_GraphicsDescriptionMapping.tr_GR253",
      "tr_GR254": "uiText.tr_GraphicsDescriptionMapping.tr_GR254",
      "tr_GR255": "uiText.tr_GraphicsDescriptionMapping.tr_GR255",
      "tr_GR256": "uiText.tr_GraphicsDescriptionMapping.tr_GR256",
      "tr_GR257": "uiText.tr_GraphicsDescriptionMapping.tr_GR257",
      "tr_GR258": "uiText.tr_GraphicsDescriptionMapping.tr_GR258",
      "tr_GR259": "uiText.tr_GraphicsDescriptionMapping.tr_GR259",
      "tr_GR260": "uiText.tr_GraphicsDescriptionMapping.tr_GR260",
      "tr_GR261": "uiText.tr_GraphicsDescriptionMapping.tr_GR261",
      "tr_GR262": "uiText.tr_GraphicsDescriptionMapping.tr_GR262",
      "tr_GR263": "uiText.tr_GraphicsDescriptionMapping.tr_GR263",
      "tr_GR264": "uiText.tr_GraphicsDescriptionMapping.tr_GR264",
      "tr_GR265": "uiText.tr_GraphicsDescriptionMapping.tr_GR265",
      "tr_GR266": "uiText.tr_GraphicsDescriptionMapping.tr_GR266",
      "tr_GR267": "uiText.tr_GraphicsDescriptionMapping.tr_GR267",
      "tr_GR268": "uiText.tr_GraphicsDescriptionMapping.tr_GR268",
      "tr_GR269": "uiText.tr_GraphicsDescriptionMapping.tr_GR269",
      "tr_GR270": "uiText.tr_GraphicsDescriptionMapping.tr_GR270",
      "tr_GR271": "uiText.tr_GraphicsDescriptionMapping.tr_GR271",
      "tr_GR272": "uiText.tr_GraphicsDescriptionMapping.tr_GR272",
      "tr_GR273": "uiText.tr_GraphicsDescriptionMapping.tr_GR273",
      "tr_GR274": "uiText.tr_GraphicsDescriptionMapping.tr_GR274",
      "tr_GR275": "uiText.tr_GraphicsDescriptionMapping.tr_GR275",
      "tr_GR276": "uiText.tr_GraphicsDescriptionMapping.tr_GR276",
      "tr_GR277": "uiText.tr_GraphicsDescriptionMapping.tr_GR277",
      "tr_GR278": "uiText.tr_GraphicsDescriptionMapping.tr_GR278",
      "tr_GR279": "uiText.tr_GraphicsDescriptionMapping.tr_GR279",
      "tr_GR280": "uiText.tr_GraphicsDescriptionMapping.tr_GR280",
      "tr_GR281": "uiText.tr_GraphicsDescriptionMapping.tr_GR281",
      "tr_GR282": "uiText.tr_GraphicsDescriptionMapping.tr_GR282",
      "tr_GR283": "uiText.tr_GraphicsDescriptionMapping.tr_GR283",
      "tr_GR284": "uiText.tr_GraphicsDescriptionMapping.tr_GR284",
      "tr_GR285": "uiText.tr_GraphicsDescriptionMapping.tr_GR285",
      "tr_GR286": "uiText.tr_GraphicsDescriptionMapping.tr_GR286",
      "tr_GR287": "uiText.tr_GraphicsDescriptionMapping.tr_GR287",
      "tr_GR288": "uiText.tr_GraphicsDescriptionMapping.tr_GR288",
      "tr_GR289": "uiText.tr_GraphicsDescriptionMapping.tr_GR289",
      "tr_GR290": "uiText.tr_GraphicsDescriptionMapping.tr_GR290",
      "tr_GR291": "uiText.tr_GraphicsDescriptionMapping.tr_GR291",
      "tr_GR292": "uiText.tr_GraphicsDescriptionMapping.tr_GR292",
      "tr_GR293": "uiText.tr_GraphicsDescriptionMapping.tr_GR293",
      "tr_GR294": "uiText.tr_GraphicsDescriptionMapping.tr_GR294",
      "tr_GR295": "uiText.tr_GraphicsDescriptionMapping.tr_GR295",
      "tr_GR296": "uiText.tr_GraphicsDescriptionMapping.tr_GR296",
      "tr_GR297": "uiText.tr_GraphicsDescriptionMapping.tr_GR297",
      "tr_GR298": "uiText.tr_GraphicsDescriptionMapping.tr_GR298",
      "tr_GR299": "uiText.tr_GraphicsDescriptionMapping.tr_GR299",
      "tr_GR300": "uiText.tr_GraphicsDescriptionMapping.tr_GR300",
      "tr_GR301": "uiText.tr_GraphicsDescriptionMapping.tr_GR301",
      "tr_GR302": "uiText.tr_GraphicsDescriptionMapping.tr_GR302",
      "tr_GR303": "uiText.tr_GraphicsDescriptionMapping.tr_GR303",
      "tr_GR304": "uiText.tr_GraphicsDescriptionMapping.tr_GR304",
      "tr_GR305": "uiText.tr_GraphicsDescriptionMapping.tr_GR305",
      "tr_GR306": "uiText.tr_GraphicsDescriptionMapping.tr_GR306",
      "tr_GR307": "uiText.tr_GraphicsDescriptionMapping.tr_GR307",
      "tr_GR308": "uiText.tr_GraphicsDescriptionMapping.tr_GR308",
      "tr_GR309": "uiText.tr_GraphicsDescriptionMapping.tr_GR309",
      "tr_GR310": "uiText.tr_GraphicsDescriptionMapping.tr_GR310",
      "tr_GR311": "uiText.tr_GraphicsDescriptionMapping.tr_GR311",
      "tr_GR312": "uiText.tr_GraphicsDescriptionMapping.tr_GR312",
      "tr_GR313": "uiText.tr_GraphicsDescriptionMapping.tr_GR313",
      "tr_GR314": "uiText.tr_GraphicsDescriptionMapping.tr_GR314",
      "tr_GR315": "uiText.tr_GraphicsDescriptionMapping.tr_GR315",
      "tr_GR316": "uiText.tr_GraphicsDescriptionMapping.tr_GR316",
      "tr_GR317": "uiText.tr_GraphicsDescriptionMapping.tr_GR317",
      "tr_GR318": "uiText.tr_GraphicsDescriptionMapping.tr_GR318",
      "tr_GR319": "uiText.tr_GraphicsDescriptionMapping.tr_GR319",
      "tr_GR320": "uiText.tr_GraphicsDescriptionMapping.tr_GR320",
      "tr_GR321": "uiText.tr_GraphicsDescriptionMapping.tr_GR321",
      "tr_GR322": "uiText.tr_GraphicsDescriptionMapping.tr_GR322",
      "tr_GR323": "uiText.tr_GraphicsDescriptionMapping.tr_GR323",
      "tr_GR324": "uiText.tr_GraphicsDescriptionMapping.tr_GR324",
      "tr_GR325": "uiText.tr_GraphicsDescriptionMapping.tr_GR325",
      "tr_GR326": "uiText.tr_GraphicsDescriptionMapping.tr_GR326",
      "tr_GR327": "uiText.tr_GraphicsDescriptionMapping.tr_GR327",
      "tr_GR328": "uiText.tr_GraphicsDescriptionMapping.tr_GR328",
      "tr_GR329": "uiText.tr_GraphicsDescriptionMapping.tr_GR329",
      "tr_GR330": "uiText.tr_GraphicsDescriptionMapping.tr_GR330",
      "tr_GR331": "uiText.tr_GraphicsDescriptionMapping.tr_GR331",
      "tr_GR332": "uiText.tr_GraphicsDescriptionMapping.tr_GR332",
      "tr_GR333": "uiText.tr_GraphicsDescriptionMapping.tr_GR333",
      "tr_GR334": "uiText.tr_GraphicsDescriptionMapping.tr_GR334",
      "tr_GR335": "uiText.tr_GraphicsDescriptionMapping.tr_GR335",
      "tr_GR336": "uiText.tr_GraphicsDescriptionMapping.tr_GR336",
      "tr_GR337": "uiText.tr_GraphicsDescriptionMapping.tr_GR337",
      "tr_GR338": "uiText.tr_GraphicsDescriptionMapping.tr_GR338",
      "tr_GR339": "uiText.tr_GraphicsDescriptionMapping.tr_GR339",
      "tr_GR340": "uiText.tr_GraphicsDescriptionMapping.tr_GR340",
      "tr_GR341": "uiText.tr_GraphicsDescriptionMapping.tr_GR341",
      "tr_GR342": "uiText.tr_GraphicsDescriptionMapping.tr_GR342",
      "tr_GR343": "uiText.tr_GraphicsDescriptionMapping.tr_GR343",
      "tr_GR344": "uiText.tr_GraphicsDescriptionMapping.tr_GR344",
      "tr_GR345": "uiText.tr_GraphicsDescriptionMapping.tr_GR345",
      "tr_GR346": "uiText.tr_GraphicsDescriptionMapping.tr_GR346",
      "tr_GR347": "uiText.tr_GraphicsDescriptionMapping.tr_GR347",
      "tr_GR348": "uiText.tr_GraphicsDescriptionMapping.tr_GR348",
      "tr_GR349": "uiText.tr_GraphicsDescriptionMapping.tr_GR349",
      "tr_GR350": "uiText.tr_GraphicsDescriptionMapping.tr_GR350",
      "tr_GR351": "uiText.tr_GraphicsDescriptionMapping.tr_GR351",
      "tr_GR352": "uiText.tr_GraphicsDescriptionMapping.tr_GR352",
      "tr_GR353": "uiText.tr_GraphicsDescriptionMapping.tr_GR353",
      "tr_GR354": "uiText.tr_GraphicsDescriptionMapping.tr_GR354",
      "tr_GR355": "uiText.tr_GraphicsDescriptionMapping.tr_GR355",
      "tr_GR356": "uiText.tr_GraphicsDescriptionMapping.tr_GR356",
      "tr_GR357": "uiText.tr_GraphicsDescriptionMapping.tr_GR357",
      "tr_GR358": "uiText.tr_GraphicsDescriptionMapping.tr_GR358",
      "tr_GR359": "uiText.tr_GraphicsDescriptionMapping.tr_GR359",
      "tr_GR360": "uiText.tr_GraphicsDescriptionMapping.tr_GR360",
      "tr_GR361": "uiText.tr_GraphicsDescriptionMapping.tr_GR361",
      "tr_GR362": "uiText.tr_GraphicsDescriptionMapping.tr_GR362",
      "tr_GR363": "uiText.tr_GraphicsDescriptionMapping.tr_GR363",
      "tr_GR364": "uiText.tr_GraphicsDescriptionMapping.tr_GR364",
      "tr_GR365": "uiText.tr_GraphicsDescriptionMapping.tr_GR365",
      "tr_GR366": "uiText.tr_GraphicsDescriptionMapping.tr_GR366",
      "tr_GR367": "uiText.tr_GraphicsDescriptionMapping.tr_GR367",
      "tr_GR368": "uiText.tr_GraphicsDescriptionMapping.tr_GR368",
      "tr_GR369": "uiText.tr_GraphicsDescriptionMapping.tr_GR369",
      "tr_GR370": "uiText.tr_GraphicsDescriptionMapping.tr_GR370",
      "tr_GR371": "uiText.tr_GraphicsDescriptionMapping.tr_GR371",
      "tr_GR372": "uiText.tr_GraphicsDescriptionMapping.tr_GR372",
      "tr_GR373": "uiText.tr_GraphicsDescriptionMapping.tr_GR373",
      "tr_GR374": "uiText.tr_GraphicsDescriptionMapping.tr_GR374",
      "tr_GR375": "uiText.tr_GraphicsDescriptionMapping.tr_GR375",
      "tr_GR376": "uiText.tr_GraphicsDescriptionMapping.tr_GR376",
      "tr_GR377": "uiText.tr_GraphicsDescriptionMapping.tr_GR377",
      "tr_GR378": "uiText.tr_GraphicsDescriptionMapping.tr_GR378",
      "tr_GR379": "uiText.tr_GraphicsDescriptionMapping.tr_GR379",
      "tr_GR380": "uiText.tr_GraphicsDescriptionMapping.tr_GR380",
      "tr_GR381": "uiText.tr_GraphicsDescriptionMapping.tr_GR381",
      "tr_GR382": "uiText.tr_GraphicsDescriptionMapping.tr_GR382",
      "tr_GR383": "uiText.tr_GraphicsDescriptionMapping.tr_GR383",
      "tr_GR384": "uiText.tr_GraphicsDescriptionMapping.tr_GR384",
      "tr_GR385": "uiText.tr_GraphicsDescriptionMapping.tr_GR385",
      "tr_GR386": "uiText.tr_GraphicsDescriptionMapping.tr_GR386",
      "tr_GR387": "uiText.tr_GraphicsDescriptionMapping.tr_GR387",
      "tr_GR388": "uiText.tr_GraphicsDescriptionMapping.tr_GR388",
      "tr_GR389": "uiText.tr_GraphicsDescriptionMapping.tr_GR389",
      "tr_GR390": "uiText.tr_GraphicsDescriptionMapping.tr_GR390",
      "tr_GR391": "uiText.tr_GraphicsDescriptionMapping.tr_GR391",
      "tr_GR392": "uiText.tr_GraphicsDescriptionMapping.tr_GR392",
      "tr_GR393": "uiText.tr_GraphicsDescriptionMapping.tr_GR393",
      "tr_GR394": "uiText.tr_GraphicsDescriptionMapping.tr_GR394",
      "tr_GR395": "uiText.tr_GraphicsDescriptionMapping.tr_GR395",
      "tr_GR396": "uiText.tr_GraphicsDescriptionMapping.tr_GR396",
      "tr_GR397": "uiText.tr_GraphicsDescriptionMapping.tr_GR397",
      "tr_GR398": "uiText.tr_GraphicsDescriptionMapping.tr_GR398",
      "tr_GR399": "uiText.tr_GraphicsDescriptionMapping.tr_GR399",
      "tr_GR400": "uiText.tr_GraphicsDescriptionMapping.tr_GR400",
      "tr_GR401": "uiText.tr_GraphicsDescriptionMapping.tr_GR401",
      "tr_GR402": "uiText.tr_GraphicsDescriptionMapping.tr_GR402",
      "tr_GR403": "uiText.tr_GraphicsDescriptionMapping.tr_GR403",
      "tr_GR404": "uiText.tr_GraphicsDescriptionMapping.tr_GR404",
      "tr_GR405": "uiText.tr_GraphicsDescriptionMapping.tr_GR405",
      "tr_GR406": "uiText.tr_GraphicsDescriptionMapping.tr_GR406",
      "tr_GR407": "uiText.tr_GraphicsDescriptionMapping.tr_GR407",
      "tr_GR408": "uiText.tr_GraphicsDescriptionMapping.tr_GR408",
      "tr_GR409": "uiText.tr_GraphicsDescriptionMapping.tr_GR409",
      "tr_GR410": "uiText.tr_GraphicsDescriptionMapping.tr_GR410",
      "tr_GR411": "uiText.tr_GraphicsDescriptionMapping.tr_GR411",
      "tr_GR412": "uiText.tr_GraphicsDescriptionMapping.tr_GR412",
      "tr_GR413": "uiText.tr_GraphicsDescriptionMapping.tr_GR413",
      "tr_GR414": "uiText.tr_GraphicsDescriptionMapping.tr_GR414",
      "tr_GR415": "uiText.tr_GraphicsDescriptionMapping.tr_GR415",
      "tr_GR416": "uiText.tr_GraphicsDescriptionMapping.tr_GR416",
      "tr_GR417": "uiText.tr_GraphicsDescriptionMapping.tr_GR417",
      "tr_GR418": "uiText.tr_GraphicsDescriptionMapping.tr_GR418",
      "tr_GR419": "uiText.tr_GraphicsDescriptionMapping.tr_GR419",
      "tr_GR420": "uiText.tr_GraphicsDescriptionMapping.tr_GR420",
      "tr_GR421": "uiText.tr_GraphicsDescriptionMapping.tr_GR421",
      "tr_GR422": "uiText.tr_GraphicsDescriptionMapping.tr_GR422",
      "tr_GR423": "uiText.tr_GraphicsDescriptionMapping.tr_GR423",
      "tr_GR424": "uiText.tr_GraphicsDescriptionMapping.tr_GR424",
      "tr_GR425": "uiText.tr_GraphicsDescriptionMapping.tr_GR425",
      "tr_GR426": "uiText.tr_GraphicsDescriptionMapping.tr_GR426",
      "tr_GR427": "uiText.tr_GraphicsDescriptionMapping.tr_GR427",
      "tr_GR428": "uiText.tr_GraphicsDescriptionMapping.tr_GR428",
      "tr_GR429": "uiText.tr_GraphicsDescriptionMapping.tr_GR429",
      "tr_GR430": "uiText.tr_GraphicsDescriptionMapping.tr_GR430",
      "tr_GR431": "uiText.tr_GraphicsDescriptionMapping.tr_GR431",
      "tr_GR432": "uiText.tr_GraphicsDescriptionMapping.tr_GR432",
      "tr_GR433": "uiText.tr_GraphicsDescriptionMapping.tr_GR433",
      "tr_GR434": "uiText.tr_GraphicsDescriptionMapping.tr_GR434",
      "tr_GR435": "uiText.tr_GraphicsDescriptionMapping.tr_GR435",
      "tr_GR436": "uiText.tr_GraphicsDescriptionMapping.tr_GR436",
      "tr_GR437": "uiText.tr_GraphicsDescriptionMapping.tr_GR437",
      "tr_GR438": "uiText.tr_GraphicsDescriptionMapping.tr_GR438",
      "tr_GR439": "uiText.tr_GraphicsDescriptionMapping.tr_GR439",
      "tr_GR440": "uiText.tr_GraphicsDescriptionMapping.tr_GR440",
      "tr_GR441": "uiText.tr_GraphicsDescriptionMapping.tr_GR441",
      "tr_GR442": "uiText.tr_GraphicsDescriptionMapping.tr_GR442",
      "tr_GR443": "uiText.tr_GraphicsDescriptionMapping.tr_GR443",
      "tr_GR444": "uiText.tr_GraphicsDescriptionMapping.tr_GR444",
      "tr_GR445": "uiText.tr_GraphicsDescriptionMapping.tr_GR445",
      "tr_GR446": "uiText.tr_GraphicsDescriptionMapping.tr_GR446",
      "tr_GR447": "uiText.tr_GraphicsDescriptionMapping.tr_GR447",
      "tr_GR448": "uiText.tr_GraphicsDescriptionMapping.tr_GR448",
      "tr_GR449": "uiText.tr_GraphicsDescriptionMapping.tr_GR449",
      "tr_GR450": "uiText.tr_GraphicsDescriptionMapping.tr_GR450",
      "tr_GR451": "uiText.tr_GraphicsDescriptionMapping.tr_GR451",
      "tr_GR452": "uiText.tr_GraphicsDescriptionMapping.tr_GR452",
      "tr_GR453": "uiText.tr_GraphicsDescriptionMapping.tr_GR453",
      "tr_GR454": "uiText.tr_GraphicsDescriptionMapping.tr_GR454",
      "tr_GR455": "uiText.tr_GraphicsDescriptionMapping.tr_GR455",
      "tr_GR456": "uiText.tr_GraphicsDescriptionMapping.tr_GR456",
      "tr_GR457": "uiText.tr_GraphicsDescriptionMapping.tr_GR457",
      "tr_GR458": "uiText.tr_GraphicsDescriptionMapping.tr_GR458",
      "tr_GR459": "uiText.tr_GraphicsDescriptionMapping.tr_GR459",
      "tr_GR460": "uiText.tr_GraphicsDescriptionMapping.tr_GR460",
      "tr_GR461": "uiText.tr_GraphicsDescriptionMapping.tr_GR461",
      "tr_GR462": "uiText.tr_GraphicsDescriptionMapping.tr_GR462",
      "tr_GR463": "uiText.tr_GraphicsDescriptionMapping.tr_GR463",
      "tr_GR464": "uiText.tr_GraphicsDescriptionMapping.tr_GR464",
      "tr_GR465": "uiText.tr_GraphicsDescriptionMapping.tr_GR465",
      "tr_GR466": "uiText.tr_GraphicsDescriptionMapping.tr_GR466",
      "tr_GR467": "uiText.tr_GraphicsDescriptionMapping.tr_GR467",
      "tr_GR468": "uiText.tr_GraphicsDescriptionMapping.tr_GR468",
      "tr_GR469": "uiText.tr_GraphicsDescriptionMapping.tr_GR469",
      "tr_GR470": "uiText.tr_GraphicsDescriptionMapping.tr_GR470",
      "tr_GR471": "uiText.tr_GraphicsDescriptionMapping.tr_GR471",
      "tr_GR472": "uiText.tr_GraphicsDescriptionMapping.tr_GR472",
      "tr_GR473": "uiText.tr_GraphicsDescriptionMapping.tr_GR473",
      "tr_GR474": "uiText.tr_GraphicsDescriptionMapping.tr_GR474",
      "tr_GR475": "uiText.tr_GraphicsDescriptionMapping.tr_GR475",
      "tr_GR476": "uiText.tr_GraphicsDescriptionMapping.tr_GR476",
      "tr_GR477": "uiText.tr_GraphicsDescriptionMapping.tr_GR477",
      "tr_GR478": "uiText.tr_GraphicsDescriptionMapping.tr_GR478",
      "tr_GR479": "uiText.tr_GraphicsDescriptionMapping.tr_GR479",
      "tr_GR480": "uiText.tr_GraphicsDescriptionMapping.tr_GR480",
      "tr_GR481": "uiText.tr_GraphicsDescriptionMapping.tr_GR481",
      "tr_GR482": "uiText.tr_GraphicsDescriptionMapping.tr_GR482",
      "tr_GR483": "uiText.tr_GraphicsDescriptionMapping.tr_GR483",
      "tr_GR484": "uiText.tr_GraphicsDescriptionMapping.tr_GR484",
      "tr_GR485": "uiText.tr_GraphicsDescriptionMapping.tr_GR485",
      "tr_GR486": "uiText.tr_GraphicsDescriptionMapping.tr_GR486",
      "tr_GR487": "uiText.tr_GraphicsDescriptionMapping.tr_GR487",
      "tr_GR488": "uiText.tr_GraphicsDescriptionMapping.tr_GR488",
      "tr_GR489": "uiText.tr_GraphicsDescriptionMapping.tr_GR489",
      "tr_GR490": "uiText.tr_GraphicsDescriptionMapping.tr_GR490",
      "tr_GR491": "uiText.tr_GraphicsDescriptionMapping.tr_GR491",
      "tr_GR492": "uiText.tr_GraphicsDescriptionMapping.tr_GR492",
      "tr_GR493": "uiText.tr_GraphicsDescriptionMapping.tr_GR493",
      "tr_GR494": "uiText.tr_GraphicsDescriptionMapping.tr_GR494",
      "tr_GR495": "uiText.tr_GraphicsDescriptionMapping.tr_GR495",
      "tr_GR496": "uiText.tr_GraphicsDescriptionMapping.tr_GR496",
      "tr_GR497": "uiText.tr_GraphicsDescriptionMapping.tr_GR497",
      "tr_GR498": "uiText.tr_GraphicsDescriptionMapping.tr_GR498",
      "tr_GR499": "uiText.tr_GraphicsDescriptionMapping.tr_GR499",
      "tr_GR500": "uiText.tr_GraphicsDescriptionMapping.tr_GR500",
      "tr_GR501": "uiText.tr_GraphicsDescriptionMapping.tr_GR501",
      "tr_GR502": "uiText.tr_GraphicsDescriptionMapping.tr_GR502",
      "tr_GR503": "uiText.tr_GraphicsDescriptionMapping.tr_GR503",
      "tr_GR504": "uiText.tr_GraphicsDescriptionMapping.tr_GR504",
      "tr_GR505": "uiText.tr_GraphicsDescriptionMapping.tr_GR505",
      "tr_GR506": "uiText.tr_GraphicsDescriptionMapping.tr_GR506",
      "tr_GR507": "uiText.tr_GraphicsDescriptionMapping.tr_GR507",
      "tr_GR508": "uiText.tr_GraphicsDescriptionMapping.tr_GR508",
      "tr_GR509": "uiText.tr_GraphicsDescriptionMapping.tr_GR509",
      "tr_GR510": "uiText.tr_GraphicsDescriptionMapping.tr_GR510",
      "tr_GR511": "uiText.tr_GraphicsDescriptionMapping.tr_GR511",
      "tr_GR512": "uiText.tr_GraphicsDescriptionMapping.tr_GR512",
      "tr_GR513": "uiText.tr_GraphicsDescriptionMapping.tr_GR513",
      "tr_GR514": "uiText.tr_GraphicsDescriptionMapping.tr_GR514",
      "tr_GR515": "uiText.tr_GraphicsDescriptionMapping.tr_GR515",
      "tr_GR516": "uiText.tr_GraphicsDescriptionMapping.tr_GR516",
      "tr_GR517": "uiText.tr_GraphicsDescriptionMapping.tr_GR517",
      "tr_GR518": "uiText.tr_GraphicsDescriptionMapping.tr_GR518",
      "tr_GR519": "uiText.tr_GraphicsDescriptionMapping.tr_GR519",
      "tr_GR520": "uiText.tr_GraphicsDescriptionMapping.tr_GR520",
      "tr_GR521": "uiText.tr_GraphicsDescriptionMapping.tr_GR521",
      "tr_GR522": "uiText.tr_GraphicsDescriptionMapping.tr_GR522",
      "tr_GR523": "uiText.tr_GraphicsDescriptionMapping.tr_GR523",
      "tr_GR524": "uiText.tr_GraphicsDescriptionMapping.tr_GR524",
      "tr_GR525": "uiText.tr_GraphicsDescriptionMapping.tr_GR525",
      "tr_GR526": "uiText.tr_GraphicsDescriptionMapping.tr_GR526",
      "tr_GR527": "uiText.tr_GraphicsDescriptionMapping.tr_GR527",
      "tr_GR528": "uiText.tr_GraphicsDescriptionMapping.tr_GR528",
      "tr_GR529": "uiText.tr_GraphicsDescriptionMapping.tr_GR529",
      "tr_GR530": "uiText.tr_GraphicsDescriptionMapping.tr_GR530",
      "tr_GR531": "uiText.tr_GraphicsDescriptionMapping.tr_GR531",
      "tr_GR532": "uiText.tr_GraphicsDescriptionMapping.tr_GR532",
      "tr_GR533": "uiText.tr_GraphicsDescriptionMapping.tr_GR533",
      "tr_GR534": "uiText.tr_GraphicsDescriptionMapping.tr_GR534",
      "tr_GR535": "uiText.tr_GraphicsDescriptionMapping.tr_GR535",
      "tr_GR536": "uiText.tr_GraphicsDescriptionMapping.tr_GR536",
      "tr_GR537": "uiText.tr_GraphicsDescriptionMapping.tr_GR537",
      "tr_GR538": "uiText.tr_GraphicsDescriptionMapping.tr_GR538",
      "tr_GR539": "uiText.tr_GraphicsDescriptionMapping.tr_GR539",
      "tr_GR540": "uiText.tr_GraphicsDescriptionMapping.tr_GR540",
      "tr_GR541": "uiText.tr_GraphicsDescriptionMapping.tr_GR541",
      "tr_GR542": "uiText.tr_GraphicsDescriptionMapping.tr_GR542",
      "tr_GR543": "uiText.tr_GraphicsDescriptionMapping.tr_GR543",
      "tr_GR544": "uiText.tr_GraphicsDescriptionMapping.tr_GR544",
      "tr_GR545": "uiText.tr_GraphicsDescriptionMapping.tr_GR545",
      "tr_GR546": "uiText.tr_GraphicsDescriptionMapping.tr_GR546",
      "tr_GR547": "uiText.tr_GraphicsDescriptionMapping.tr_GR547",
      "tr_GR548": "uiText.tr_GraphicsDescriptionMapping.tr_GR548",
      "tr_GR549": "uiText.tr_GraphicsDescriptionMapping.tr_GR549",
      "tr_GR550": "uiText.tr_GraphicsDescriptionMapping.tr_GR550",
      "tr_GR551": "uiText.tr_GraphicsDescriptionMapping.tr_GR551",
      "tr_GR552": "uiText.tr_GraphicsDescriptionMapping.tr_GR552",
      "tr_GR553": "uiText.tr_GraphicsDescriptionMapping.tr_GR553",
      "tr_GR554": "uiText.tr_GraphicsDescriptionMapping.tr_GR554",
      "tr_GR555": "uiText.tr_GraphicsDescriptionMapping.tr_GR555",
      "tr_GR556": "uiText.tr_GraphicsDescriptionMapping.tr_GR556",
      "tr_GR557": "uiText.tr_GraphicsDescriptionMapping.tr_GR557",
      "tr_GR558": "uiText.tr_GraphicsDescriptionMapping.tr_GR558",
      "tr_GR559": "uiText.tr_GraphicsDescriptionMapping.tr_GR559",
      "tr_GR560": "uiText.tr_GraphicsDescriptionMapping.tr_GR560",
      "tr_GR561": "uiText.tr_GraphicsDescriptionMapping.tr_GR561",
      "tr_GR562": "uiText.tr_GraphicsDescriptionMapping.tr_GR562",
      "tr_GR563": "uiText.tr_GraphicsDescriptionMapping.tr_GR563",
      "tr_GR564": "uiText.tr_GraphicsDescriptionMapping.tr_GR564",
      "tr_GR565": "uiText.tr_GraphicsDescriptionMapping.tr_GR565",
      "tr_GR566": "uiText.tr_GraphicsDescriptionMapping.tr_GR566",
      "tr_GR567": "uiText.tr_GraphicsDescriptionMapping.tr_GR567",
      "tr_GR568": "uiText.tr_GraphicsDescriptionMapping.tr_GR568",
      "tr_GR569": "uiText.tr_GraphicsDescriptionMapping.tr_GR569",
      "tr_GR570": "uiText.tr_GraphicsDescriptionMapping.tr_GR570",
      "tr_GR571": "uiText.tr_GraphicsDescriptionMapping.tr_GR571",
      "tr_GR572": "uiText.tr_GraphicsDescriptionMapping.tr_GR572",
      "tr_GR573": "uiText.tr_GraphicsDescriptionMapping.tr_GR573",
      "tr_GR574": "uiText.tr_GraphicsDescriptionMapping.tr_GR574",
      "tr_GR575": "uiText.tr_GraphicsDescriptionMapping.tr_GR575",
      "tr_GR576": "uiText.tr_GraphicsDescriptionMapping.tr_GR576",
      "tr_GR577": "uiText.tr_GraphicsDescriptionMapping.tr_GR577",
      "tr_GR578": "uiText.tr_GraphicsDescriptionMapping.tr_GR578",
      "tr_GR579": "uiText.tr_GraphicsDescriptionMapping.tr_GR579",
      "tr_GR580": "uiText.tr_GraphicsDescriptionMapping.tr_GR580",
      "tr_GR581": "uiText.tr_GraphicsDescriptionMapping.tr_GR581",
      "tr_GR582": "uiText.tr_GraphicsDescriptionMapping.tr_GR582",
      "tr_GR583": "uiText.tr_GraphicsDescriptionMapping.tr_GR583",
      "tr_GR584": "uiText.tr_GraphicsDescriptionMapping.tr_GR584",
      "tr_GR585": "uiText.tr_GraphicsDescriptionMapping.tr_GR585",
      "tr_GR586": "uiText.tr_GraphicsDescriptionMapping.tr_GR586",
      "tr_GR587": "uiText.tr_GraphicsDescriptionMapping.tr_GR587",
      "tr_GR588": "uiText.tr_GraphicsDescriptionMapping.tr_GR588",
      "tr_GR589": "uiText.tr_GraphicsDescriptionMapping.tr_GR589",
      "tr_GR590": "uiText.tr_GraphicsDescriptionMapping.tr_GR590",
      "tr_GR591": "uiText.tr_GraphicsDescriptionMapping.tr_GR591",
      "tr_GR592": "uiText.tr_GraphicsDescriptionMapping.tr_GR592",
      "tr_GR593": "uiText.tr_GraphicsDescriptionMapping.tr_GR593",
      "tr_GR594": "uiText.tr_GraphicsDescriptionMapping.tr_GR594",
      "tr_GR595": "uiText.tr_GraphicsDescriptionMapping.tr_GR595",
      "tr_GR596": "uiText.tr_GraphicsDescriptionMapping.tr_GR596",
      "tr_GR597": "uiText.tr_GraphicsDescriptionMapping.tr_GR597",
      "tr_GR598": "uiText.tr_GraphicsDescriptionMapping.tr_GR598",
      "tr_GR599": "uiText.tr_GraphicsDescriptionMapping.tr_GR599",
      "tr_GR600": "uiText.tr_GraphicsDescriptionMapping.tr_GR600",
      "tr_GR601": "uiText.tr_GraphicsDescriptionMapping.tr_GR601",
      "tr_GR602": "uiText.tr_GraphicsDescriptionMapping.tr_GR602",
      "tr_GR603": "uiText.tr_GraphicsDescriptionMapping.tr_GR603",
      "tr_GR604": "uiText.tr_GraphicsDescriptionMapping.tr_GR604",
      "tr_GR605": "uiText.tr_GraphicsDescriptionMapping.tr_GR605",
      "tr_GR606": "uiText.tr_GraphicsDescriptionMapping.tr_GR606",
      "tr_GR607": "uiText.tr_GraphicsDescriptionMapping.tr_GR607",
      "tr_GR608": "uiText.tr_GraphicsDescriptionMapping.tr_GR608",
      "tr_GR609": "uiText.tr_GraphicsDescriptionMapping.tr_GR609",
      "tr_GR610": "uiText.tr_GraphicsDescriptionMapping.tr_GR610",
      "tr_GR611": "uiText.tr_GraphicsDescriptionMapping.tr_GR611",
      "tr_GR612": "uiText.tr_GraphicsDescriptionMapping.tr_GR612",
      "tr_GR613": "uiText.tr_GraphicsDescriptionMapping.tr_GR613",
      "tr_GR614": "uiText.tr_GraphicsDescriptionMapping.tr_GR614",
      "tr_GR615": "uiText.tr_GraphicsDescriptionMapping.tr_GR615",
      "tr_GR616": "uiText.tr_GraphicsDescriptionMapping.tr_GR616",
      "tr_GR617": "uiText.tr_GraphicsDescriptionMapping.tr_GR617",
      "tr_GR618": "uiText.tr_GraphicsDescriptionMapping.tr_GR618",
      "tr_GR619": "uiText.tr_GraphicsDescriptionMapping.tr_GR619",
      "tr_GR620": "uiText.tr_GraphicsDescriptionMapping.tr_GR620",
      "tr_GR621": "uiText.tr_GraphicsDescriptionMapping.tr_GR621",
      "tr_GR622": "uiText.tr_GraphicsDescriptionMapping.tr_GR622",
      "tr_GR623": "uiText.tr_GraphicsDescriptionMapping.tr_GR623",
      "tr_GR624": "uiText.tr_GraphicsDescriptionMapping.tr_GR624",
      "tr_GR625": "uiText.tr_GraphicsDescriptionMapping.tr_GR625",
      "tr_GR626": "uiText.tr_GraphicsDescriptionMapping.tr_GR626",
      "tr_GR627": "uiText.tr_GraphicsDescriptionMapping.tr_GR627",
      "tr_GR628": "uiText.tr_GraphicsDescriptionMapping.tr_GR628",
      "tr_GR629": "uiText.tr_GraphicsDescriptionMapping.tr_GR629",
      "tr_GR630": "uiText.tr_GraphicsDescriptionMapping.tr_GR630",
      "tr_GR631": "uiText.tr_GraphicsDescriptionMapping.tr_GR631",
      "tr_GR632": "uiText.tr_GraphicsDescriptionMapping.tr_GR632",
      "tr_GR633": "uiText.tr_GraphicsDescriptionMapping.tr_GR633",
      "tr_GR634": "uiText.tr_GraphicsDescriptionMapping.tr_GR634",
      "tr_GR635": "uiText.tr_GraphicsDescriptionMapping.tr_GR635",
      "tr_GR636": "uiText.tr_GraphicsDescriptionMapping.tr_GR636",
      "tr_GR637": "uiText.tr_GraphicsDescriptionMapping.tr_GR637",
      "tr_GR638": "uiText.tr_GraphicsDescriptionMapping.tr_GR638",
      "tr_GR639": "uiText.tr_GraphicsDescriptionMapping.tr_GR639",
      "tr_GR640": "uiText.tr_GraphicsDescriptionMapping.tr_GR640",
      "tr_GR641": "uiText.tr_GraphicsDescriptionMapping.tr_GR641",
      "tr_GR642": "uiText.tr_GraphicsDescriptionMapping.tr_GR642",
      "tr_GR643": "uiText.tr_GraphicsDescriptionMapping.tr_GR643",
      "tr_GR644": "uiText.tr_GraphicsDescriptionMapping.tr_GR644",
      "tr_GR645": "uiText.tr_GraphicsDescriptionMapping.tr_GR645",
      "tr_GR646": "uiText.tr_GraphicsDescriptionMapping.tr_GR646",
      "tr_GR647": "uiText.tr_GraphicsDescriptionMapping.tr_GR647",
      "tr_GR648": "uiText.tr_GraphicsDescriptionMapping.tr_GR648",
      "tr_GR649": "uiText.tr_GraphicsDescriptionMapping.tr_GR649",
      "tr_GR650": "uiText.tr_GraphicsDescriptionMapping.tr_GR650",
      "tr_GR651": "uiText.tr_GraphicsDescriptionMapping.tr_GR651",
      "tr_GR652": "uiText.tr_GraphicsDescriptionMapping.tr_GR652",
      "tr_GR653": "uiText.tr_GraphicsDescriptionMapping.tr_GR653",
      "tr_GR654": "uiText.tr_GraphicsDescriptionMapping.tr_GR654",
      "tr_GR655": "uiText.tr_GraphicsDescriptionMapping.tr_GR655",
      "tr_GR656": "uiText.tr_GraphicsDescriptionMapping.tr_GR656",
      "tr_GR657": "uiText.tr_GraphicsDescriptionMapping.tr_GR657",
      "tr_GR658": "uiText.tr_GraphicsDescriptionMapping.tr_GR658",
      "tr_GR659": "uiText.tr_GraphicsDescriptionMapping.tr_GR659",
      "tr_GR660": "uiText.tr_GraphicsDescriptionMapping.tr_GR660",
      "tr_GR661": "uiText.tr_GraphicsDescriptionMapping.tr_GR661",
      "tr_GR662": "uiText.tr_GraphicsDescriptionMapping.tr_GR662",
      "tr_GR663": "uiText.tr_GraphicsDescriptionMapping.tr_GR663",
      "tr_GR664": "uiText.tr_GraphicsDescriptionMapping.tr_GR664",
      "tr_GR665": "uiText.tr_GraphicsDescriptionMapping.tr_GR665",
      "tr_GR666": "uiText.tr_GraphicsDescriptionMapping.tr_GR666",
      "tr_GR667": "uiText.tr_GraphicsDescriptionMapping.tr_GR667",
      "tr_GR668": "uiText.tr_GraphicsDescriptionMapping.tr_GR668",
      "tr_GR669": "uiText.tr_GraphicsDescriptionMapping.tr_GR669",
      "tr_GR670": "uiText.tr_GraphicsDescriptionMapping.tr_GR670",
      "tr_GR671": "uiText.tr_GraphicsDescriptionMapping.tr_GR671",
      "tr_GR672": "uiText.tr_GraphicsDescriptionMapping.tr_GR672",
      "tr_GR673": "uiText.tr_GraphicsDescriptionMapping.tr_GR673",
      "tr_GR674": "uiText.tr_GraphicsDescriptionMapping.tr_GR674",
      "tr_GR675": "uiText.tr_GraphicsDescriptionMapping.tr_GR675",
      "tr_GR676": "uiText.tr_GraphicsDescriptionMapping.tr_GR676",
      "tr_GR677": "uiText.tr_GraphicsDescriptionMapping.tr_GR677",
      "tr_GR678": "uiText.tr_GraphicsDescriptionMapping.tr_GR678",
      "tr_GR679": "uiText.tr_GraphicsDescriptionMapping.tr_GR679",
      "tr_GR680": "uiText.tr_GraphicsDescriptionMapping.tr_GR680",
      "tr_GR681": "uiText.tr_GraphicsDescriptionMapping.tr_GR681",
      "tr_GR682": "uiText.tr_GraphicsDescriptionMapping.tr_GR682",
      "tr_GR683": "uiText.tr_GraphicsDescriptionMapping.tr_GR683",
      "tr_GR684": "uiText.tr_GraphicsDescriptionMapping.tr_GR684",
      "tr_GR685": "uiText.tr_GraphicsDescriptionMapping.tr_GR685",
      "tr_GR686": "uiText.tr_GraphicsDescriptionMapping.tr_GR686",
      "tr_GR687": "uiText.tr_GraphicsDescriptionMapping.tr_GR687",
      "tr_GR688": "uiText.tr_GraphicsDescriptionMapping.tr_GR688",
      "tr_GR689": "uiText.tr_GraphicsDescriptionMapping.tr_GR689",
      "tr_GR690": "uiText.tr_GraphicsDescriptionMapping.tr_GR690",
      "tr_GR691": "uiText.tr_GraphicsDescriptionMapping.tr_GR691",
      "tr_GR692": "uiText.tr_GraphicsDescriptionMapping.tr_GR692",
      "tr_GR693": "uiText.tr_GraphicsDescriptionMapping.tr_GR693",
      "tr_GR694": "uiText.tr_GraphicsDescriptionMapping.tr_GR694",
      "tr_GR695": "uiText.tr_GraphicsDescriptionMapping.tr_GR695",
      "tr_GR696": "uiText.tr_GraphicsDescriptionMapping.tr_GR696",
      "tr_GR697": "uiText.tr_GraphicsDescriptionMapping.tr_GR697",
      "tr_GR698": "uiText.tr_GraphicsDescriptionMapping.tr_GR698",
      "tr_GR699": "uiText.tr_GraphicsDescriptionMapping.tr_GR699",
      "tr_GR700": "uiText.tr_GraphicsDescriptionMapping.tr_GR700",
      "tr_GR701": "uiText.tr_GraphicsDescriptionMapping.tr_GR701",
      "tr_GR702": "uiText.tr_GraphicsDescriptionMapping.tr_GR702",
      "tr_GR703": "uiText.tr_GraphicsDescriptionMapping.tr_GR703",
      "tr_GR704": "uiText.tr_GraphicsDescriptionMapping.tr_GR704",
      "tr_GR705": "uiText.tr_GraphicsDescriptionMapping.tr_GR705",
      "tr_GR706": "uiText.tr_GraphicsDescriptionMapping.tr_GR706",
      "tr_GR707": "uiText.tr_GraphicsDescriptionMapping.tr_GR707",
      "tr_GR708": "uiText.tr_GraphicsDescriptionMapping.tr_GR708",
      "tr_GR709": "uiText.tr_GraphicsDescriptionMapping.tr_GR709",
      "tr_GR710": "uiText.tr_GraphicsDescriptionMapping.tr_GR710",
      "tr_GR711": "uiText.tr_GraphicsDescriptionMapping.tr_GR711",
      "tr_GR712": "uiText.tr_GraphicsDescriptionMapping.tr_GR712",
      "tr_GR713": "uiText.tr_GraphicsDescriptionMapping.tr_GR713",
      "tr_GR714": "uiText.tr_GraphicsDescriptionMapping.tr_GR714",
      "tr_GR715": "uiText.tr_GraphicsDescriptionMapping.tr_GR715",
      "tr_GR716": "uiText.tr_GraphicsDescriptionMapping.tr_GR716",
      "tr_GR717": "uiText.tr_GraphicsDescriptionMapping.tr_GR717",
      "tr_GR718": "uiText.tr_GraphicsDescriptionMapping.tr_GR718",
      "tr_GR719": "uiText.tr_GraphicsDescriptionMapping.tr_GR719",
      "tr_GR720": "uiText.tr_GraphicsDescriptionMapping.tr_GR720",
      "tr_GR721": "uiText.tr_GraphicsDescriptionMapping.tr_GR721",
      "tr_GR722": "uiText.tr_GraphicsDescriptionMapping.tr_GR722",
      "tr_GR723": "uiText.tr_GraphicsDescriptionMapping.tr_GR723",
      "tr_GR724": "uiText.tr_GraphicsDescriptionMapping.tr_GR724",
      "tr_GR725": "uiText.tr_GraphicsDescriptionMapping.tr_GR725",
      "tr_GR726": "uiText.tr_GraphicsDescriptionMapping.tr_GR726",
      "tr_GR727": "uiText.tr_GraphicsDescriptionMapping.tr_GR727",
      "tr_GR728": "uiText.tr_GraphicsDescriptionMapping.tr_GR728",
      "tr_GR729": "uiText.tr_GraphicsDescriptionMapping.tr_GR729",
      "tr_GR730": "uiText.tr_GraphicsDescriptionMapping.tr_GR730",
      "tr_GR731": "uiText.tr_GraphicsDescriptionMapping.tr_GR731",
      "tr_GR732": "uiText.tr_GraphicsDescriptionMapping.tr_GR732",
      "tr_GR733": "uiText.tr_GraphicsDescriptionMapping.tr_GR733",
      "tr_GR734": "uiText.tr_GraphicsDescriptionMapping.tr_GR734",
      "tr_GR735": "uiText.tr_GraphicsDescriptionMapping.tr_GR735",
      "tr_GR736": "uiText.tr_GraphicsDescriptionMapping.tr_GR736",
      "tr_GR737": "uiText.tr_GraphicsDescriptionMapping.tr_GR737",
      "tr_GR738": "uiText.tr_GraphicsDescriptionMapping.tr_GR738",
      "tr_GR739": "uiText.tr_GraphicsDescriptionMapping.tr_GR739",
      "tr_GR740": "uiText.tr_GraphicsDescriptionMapping.tr_GR740",
      "tr_GR741": "uiText.tr_GraphicsDescriptionMapping.tr_GR741",
      "tr_GR742": "uiText.tr_GraphicsDescriptionMapping.tr_GR742",
      "tr_GR743": "uiText.tr_GraphicsDescriptionMapping.tr_GR743",
      "tr_GR744": "uiText.tr_GraphicsDescriptionMapping.tr_GR744",
      "tr_GR745": "uiText.tr_GraphicsDescriptionMapping.tr_GR745",
      "tr_GR746": "uiText.tr_GraphicsDescriptionMapping.tr_GR746",
      "tr_GR747": "uiText.tr_GraphicsDescriptionMapping.tr_GR747",
      "tr_GR748": "uiText.tr_GraphicsDescriptionMapping.tr_GR748",
      "tr_GR749": "uiText.tr_GraphicsDescriptionMapping.tr_GR749",
      "tr_GR750": "uiText.tr_GraphicsDescriptionMapping.tr_GR750",
      "tr_GR751": "uiText.tr_GraphicsDescriptionMapping.tr_GR751",
      "tr_GR752": "uiText.tr_GraphicsDescriptionMapping.tr_GR752",
      "tr_GR753": "uiText.tr_GraphicsDescriptionMapping.tr_GR753",
      "tr_GR754": "uiText.tr_GraphicsDescriptionMapping.tr_GR754",
      "tr_GR755": "uiText.tr_GraphicsDescriptionMapping.tr_GR755",
      "tr_GR756": "uiText.tr_GraphicsDescriptionMapping.tr_GR756",
      "tr_GR757": "uiText.tr_GraphicsDescriptionMapping.tr_GR757",
      "tr_GR758": "uiText.tr_GraphicsDescriptionMapping.tr_GR758",
      "tr_GR759": "uiText.tr_GraphicsDescriptionMapping.tr_GR759",
      "tr_GR760": "uiText.tr_GraphicsDescriptionMapping.tr_GR760",
      "tr_GR761": "uiText.tr_GraphicsDescriptionMapping.tr_GR761",
      "tr_GR762": "uiText.tr_GraphicsDescriptionMapping.tr_GR762",
      "tr_GR763": "uiText.tr_GraphicsDescriptionMapping.tr_GR763",
      "tr_GR764": "uiText.tr_GraphicsDescriptionMapping.tr_GR764",
      "tr_GR765": "uiText.tr_GraphicsDescriptionMapping.tr_GR765",
      "tr_GR766": "uiText.tr_GraphicsDescriptionMapping.tr_GR766",
      "tr_GR767": "uiText.tr_GraphicsDescriptionMapping.tr_GR767",
      "tr_GR768": "uiText.tr_GraphicsDescriptionMapping.tr_GR768",
      "tr_GR769": "uiText.tr_GraphicsDescriptionMapping.tr_GR769",
      "tr_GR770": "uiText.tr_GraphicsDescriptionMapping.tr_GR770",
      "tr_GR771": "uiText.tr_GraphicsDescriptionMapping.tr_GR771",
      "tr_GR772": "uiText.tr_GraphicsDescriptionMapping.tr_GR772",
      "tr_GR773": "uiText.tr_GraphicsDescriptionMapping.tr_GR773",
      "tr_GR774": "uiText.tr_GraphicsDescriptionMapping.tr_GR774",
      "tr_GR775": "uiText.tr_GraphicsDescriptionMapping.tr_GR775",
      "tr_GR776": "uiText.tr_GraphicsDescriptionMapping.tr_GR776",
      "tr_GR777": "uiText.tr_GraphicsDescriptionMapping.tr_GR777",
      "tr_GR778": "uiText.tr_GraphicsDescriptionMapping.tr_GR778",
      "tr_GR779": "uiText.tr_GraphicsDescriptionMapping.tr_GR779",
      "tr_GR780": "uiText.tr_GraphicsDescriptionMapping.tr_GR780",
      "tr_GR781": "uiText.tr_GraphicsDescriptionMapping.tr_GR781",
      "tr_GR782": "uiText.tr_GraphicsDescriptionMapping.tr_GR782",
      "tr_GR783": "uiText.tr_GraphicsDescriptionMapping.tr_GR783",
      "tr_GR784": "uiText.tr_GraphicsDescriptionMapping.tr_GR784",
      "tr_GR785": "uiText.tr_GraphicsDescriptionMapping.tr_GR785",
      "tr_GR786": "uiText.tr_GraphicsDescriptionMapping.tr_GR786",
      "tr_GR787": "uiText.tr_GraphicsDescriptionMapping.tr_GR787",
      "tr_GR788": "uiText.tr_GraphicsDescriptionMapping.tr_GR788",
      "tr_GR789": "uiText.tr_GraphicsDescriptionMapping.tr_GR789",
      "tr_GR790": "uiText.tr_GraphicsDescriptionMapping.tr_GR790",
      "tr_GR791": "uiText.tr_GraphicsDescriptionMapping.tr_GR791",
      "tr_GR792": "uiText.tr_GraphicsDescriptionMapping.tr_GR792",
      "tr_GR793": "uiText.tr_GraphicsDescriptionMapping.tr_GR793",
      "tr_GR794": "uiText.tr_GraphicsDescriptionMapping.tr_GR794",
      "tr_GR795": "uiText.tr_GraphicsDescriptionMapping.tr_GR795",
      "tr_GR796": "uiText.tr_GraphicsDescriptionMapping.tr_GR796",
      "tr_GR797": "uiText.tr_GraphicsDescriptionMapping.tr_GR797",
      "tr_GR798": "uiText.tr_GraphicsDescriptionMapping.tr_GR798",
      "tr_GR799": "uiText.tr_GraphicsDescriptionMapping.tr_GR799",
      "tr_GR800": "uiText.tr_GraphicsDescriptionMapping.tr_GR800",
      "tr_GR801": "uiText.tr_GraphicsDescriptionMapping.tr_GR801",
      "tr_GR802": "uiText.tr_GraphicsDescriptionMapping.tr_GR802",
      "tr_GR803": "uiText.tr_GraphicsDescriptionMapping.tr_GR803",
      "tr_GR804": "uiText.tr_GraphicsDescriptionMapping.tr_GR804",
      "tr_GR805": "uiText.tr_GraphicsDescriptionMapping.tr_GR805",
      "tr_GR806": "uiText.tr_GraphicsDescriptionMapping.tr_GR806",
      "tr_GR807": "uiText.tr_GraphicsDescriptionMapping.tr_GR807",
      "tr_GR808": "uiText.tr_GraphicsDescriptionMapping.tr_GR808",
      "tr_GR809": "uiText.tr_GraphicsDescriptionMapping.tr_GR809",
      "tr_GR810": "uiText.tr_GraphicsDescriptionMapping.tr_GR810",
      "tr_GR811": "uiText.tr_GraphicsDescriptionMapping.tr_GR811",
      "tr_GR812": "uiText.tr_GraphicsDescriptionMapping.tr_GR812",
      "tr_GR813": "uiText.tr_GraphicsDescriptionMapping.tr_GR813",
      "tr_GR814": "uiText.tr_GraphicsDescriptionMapping.tr_GR814",
      "tr_GR815": "uiText.tr_GraphicsDescriptionMapping.tr_GR815"
    },
    "tr_WifiDescMapping": {
      "tr_Wifi1": "uiText.tr_WifiDescMapping.tr_Wifi1",
      "tr_Wifi2": "uiText.tr_WifiDescMapping.tr_Wifi2",
      "tr_Wifi3": "uiText.tr_WifiDescMapping.tr_Wifi3",
      "tr_Wifi4": "uiText.tr_WifiDescMapping.tr_Wifi4",
      "tr_Wifi5": "uiText.tr_WifiDescMapping.tr_Wifi5",
      "tr_Wifi6": "uiText.tr_WifiDescMapping.tr_Wifi6",
      "tr_Wifi7": "uiText.tr_WifiDescMapping.tr_Wifi7",
      "tr_Wifi8": "uiText.tr_WifiDescMapping.tr_Wifi8",
      "tr_Wifi9": "uiText.tr_WifiDescMapping.tr_Wifi9",
      "tr_Wifi10": "uiText.tr_WifiDescMapping.tr_Wifi10",
      "tr_Wifi11": "uiText.tr_WifiDescMapping.tr_Wifi11",
      "tr_Wifi12": "uiText.tr_WifiDescMapping.tr_Wifi12",
      "tr_Wifi13": "uiText.tr_WifiDescMapping.tr_Wifi13",
      "tr_Wifi14": "uiText.tr_WifiDescMapping.tr_Wifi14",
      "tr_Wifi15": "uiText.tr_WifiDescMapping.tr_Wifi15",
      "tr_Wifi16": "uiText.tr_WifiDescMapping.tr_Wifi16",
      "tr_Wifi17": "uiText.tr_WifiDescMapping.tr_Wifi17",
      "tr_Wifi18": "uiText.tr_WifiDescMapping.tr_Wifi18",
      "tr_Wifi19": "uiText.tr_WifiDescMapping.tr_Wifi19",
      "tr_Wifi20": "uiText.tr_WifiDescMapping.tr_Wifi20",
      "tr_Wifi21": "uiText.tr_WifiDescMapping.tr_Wifi21",
      "tr_Wifi22": "uiText.tr_WifiDescMapping.tr_Wifi22",
      "tr_Wifi23": "uiText.tr_WifiDescMapping.tr_Wifi23",
      "tr_Wifi24": "uiText.tr_WifiDescMapping.tr_Wifi24",
      "tr_Wifi25": "uiText.tr_WifiDescMapping.tr_Wifi25",
      "tr_Wifi26": "uiText.tr_WifiDescMapping.tr_Wifi26",
      "tr_Wifi27": "uiText.tr_WifiDescMapping.tr_Wifi27",
      "tr_Wifi28": "uiText.tr_WifiDescMapping.tr_Wifi28",
      "tr_Wifi29": "uiText.tr_WifiDescMapping.tr_Wifi29",
      "tr_Wifi30": "uiText.tr_WifiDescMapping.tr_Wifi30",
      "tr_Wifi31": "uiText.tr_WifiDescMapping.tr_Wifi31",
      "tr_Wifi32": "uiText.tr_WifiDescMapping.tr_Wifi32",
      "tr_Wifi33": "uiText.tr_WifiDescMapping.tr_Wifi33",
      "tr_Wifi34": "uiText.tr_WifiDescMapping.tr_Wifi34",
      "tr_Wifi35": "uiText.tr_WifiDescMapping.tr_Wifi35",
      "tr_Wifi36": "uiText.tr_WifiDescMapping.tr_Wifi36",
      "tr_Wifi37": "uiText.tr_WifiDescMapping.tr_Wifi37",
      "tr_Wifi38": "uiText.tr_WifiDescMapping.tr_Wifi38",
      "tr_Wifi39": "uiText.tr_WifiDescMapping.tr_Wifi39",
      "tr_Wifi40": "uiText.tr_WifiDescMapping.tr_Wifi40",
      "tr_Wifi41": "uiText.tr_WifiDescMapping.tr_Wifi41",
      "tr_Wifi42": "uiText.tr_WifiDescMapping.tr_Wifi42",
      "tr_Wifi43": "uiText.tr_WifiDescMapping.tr_Wifi43",
      "tr_Wifi44": "uiText.tr_WifiDescMapping.tr_Wifi44",
      "tr_Wifi45": "uiText.tr_WifiDescMapping.tr_Wifi45",
      "tr_Wifi46": "uiText.tr_WifiDescMapping.tr_Wifi46",
      "tr_Wifi47": "uiText.tr_WifiDescMapping.tr_Wifi47",
      "tr_Wifi48": "uiText.tr_WifiDescMapping.tr_Wifi48",
      "tr_Wifi49": "uiText.tr_WifiDescMapping.tr_Wifi49",
      "tr_Wifi50": "uiText.tr_WifiDescMapping.tr_Wifi50",
      "tr_Wifi51": "uiText.tr_WifiDescMapping.tr_Wifi51",
      "tr_Wifi52": "uiText.tr_WifiDescMapping.tr_Wifi52",
      "tr_Wifi53": "uiText.tr_WifiDescMapping.tr_Wifi53",
      "tr_Wifi54": "uiText.tr_WifiDescMapping.tr_Wifi54",
      "tr_Wifi55": "uiText.tr_WifiDescMapping.tr_Wifi55",
      "tr_Wifi56": "uiText.tr_WifiDescMapping.tr_Wifi56",
      "tr_Wifi57": "uiText.tr_WifiDescMapping.tr_Wifi57",
      "tr_Wifi58": "uiText.tr_WifiDescMapping.tr_Wifi58",
      "tr_Wifi59": "uiText.tr_WifiDescMapping.tr_Wifi59",
      "tr_Wifi60": "uiText.tr_WifiDescMapping.tr_Wifi60"
    },
    "tr_textBuyNow": "uiText.tr_textBuyNow",
    "tr_textTryagain": "uiText.tr_textTryagain",
    "tr_textBuynowErr": "uiText.tr_textBuynowErr",
    "tr_chatbotPlaceholder_text": "uiText.tr_chatbotPlaceholder_text",
    "tr_deviceSpecs_text": "uiText.tr_deviceSpecs_text"
  },
  "backgroundMedia": [
    {
      "uiType": "S11",
      "backgroundURL": "url(../assets/images/11th_gen_background.png)"
    },
    {
      "uiType": "G11",
      "backgroundURL": "url(../assets/images/gaming_background.png)"
    },
    {
      "uiType": "evo",
      "backgroundURL": "url(../assets/images/evo_background.png)"
    },
    {
      "uiType": "athena",
      "backgroundURL": "url(../assets/images/mobile-tiles-athena-background.jpg)"
    },
    {
      "uiType": "NI",
      "backgroundURL": "url(../assets/images/ni_background.png)"
    },
    {
      "uiType": "S10",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S8_9",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S7",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "G12",
      "backgroundURL": "url(../assets/images/12th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "default",
      "backgroundURL": "url(../assets/images/mobile-tiles-10-gen-background.jpg)"
    },
    {
      "uiType": "S12",
      "backgroundURL": "url(../assets/images/12th_gen_bg.svg)"
    },
    {
      "uiType": "G13",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "INTEL_INSIDE",
      "backgroundURL": "url(../assets/images/intel_inside/intel_inside_bg.png)"
    },
    {
      "uiType": "G14",
      "backgroundURL": "url(../assets/images/13th_gen_gaming_background.jpg)"
    },
    {
      "uiType": "S14_AI_IPOS",
      "backgroundURL": "url(../assets/images/standard/std_mtl_background.svg)"
    },
    {
      "uiType": "EVO_AI_IPOS",
      "backgroundURL": "url(../assets/images/evo/evo_mtl_background.svg)"
    },
    {
      "uiType": "GAMING_AI_IPOS",
      "backgroundURL": "url(../assets/images/gaming_ai_ipos_background.svg)"
    }
  ],
  "hybridDetails": [
    {
      "badgeTitle": "I3_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I3_HYBRID.svg",
      "tr_gen_core_title": "hybridDetails[0].tr_gen_core_title"
    },
    {
      "badgeTitle": "I5_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I5_HYBRID.svg",
      "tr_gen_core_title": "hybridDetails[1].tr_gen_core_title"
    },
    {
      "badgeTitle": "I7_HYBRID",
      "badgeURL": "assets/images/processor/INTEL_CORE_I7_HYBRID.svg"
    }
  ],
  "tiles": [
    {
      "tilesType": "GAMING",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[0].tilesDeviceTypes[0].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "avengers",
              "tileBadgeUrl": "assets/images/gaming/avengers-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "tiles[0].tilesDeviceTypes[0].tilesDetails[0].tileDetails.tr_title",
                "tr_subtitle": "tiles[0].tilesDeviceTypes[0].tilesDetails[0].tileDetails.tr_subtitle",
                "videoUrl": "assets/lang/[XX]/gaming/videos/avengers_[XX].mp4",
                "videoPosterUrl": "assets/images/gaming/avengers-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "tiles[0].tilesDeviceTypes[0].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "esl",
              "tileBadgeUrl": "assets/images/gaming/esl-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "tiles[0].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_title",
                "tr_subtitle": "tiles[0].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle",
                "videoUrl": "assets/videos/gaming/esl_video.mp4",
                "videoPosterUrl": "assets/images/gaming/esl-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "tiles[0].tilesDeviceTypes[0].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "influencer",
              "tileBadgeUrl": "assets/images/gaming/influencer-tile-new-content.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "tiles[0].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_title",
                "tr_subtitle": "tiles[0].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle",
                "tr_pretitle": "tiles[0].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_pretitle",
                "videoUrl": "assets/videos/gaming/influencer-new-video.mp4",
                "videoPosterUrl": "assets/images/gaming/influencer-new-vdo-thumbnail.svg",
                "subtitleUrl": "assets/subtitles/gaming/intel_soniq_influencer/Intel_Soniq_Influencer_022822.[XX].vtt"
              }
            },
            {
              "tr_tileTitle": "tiles[0].tilesDeviceTypes[0].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "tr_title": "tiles[0].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_title",
                "tr_subtitle": "tiles[0].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_subtitle",
                "videoUrl": "assets/videos/gaming/vr_video.mp4",
                "videoPosterUrl": "assets/images/gaming/vr-thumbnail.png"
              }
            },
            {
              "tr_tileTitle": "tiles[0].tilesDeviceTypes[0].tilesDetails[4].tr_tileTitle",
              "tileUrlName": "xboxcontent",
              "tileBadgeUrl": "assets/images/gaming/xboxTile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "xbox-content-tile-panel",
                "tr_title": "tiles[0].tilesDeviceTypes[0].tilesDetails[4].tileDetails.tr_title",
                "tr_subtitle": "tiles[0].tilesDeviceTypes[0].tilesDetails[4].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[0].tilesDeviceTypes[0].tilesDetails[4].tileDetails.titleArray[0].tr_title",
                    "tr_subtitle": "tiles[0].tilesDeviceTypes[0].tilesDetails[4].tileDetails.titleArray[0].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[0].tilesDeviceTypes[0].tilesDetails[4].tileDetails.titleArray[1].tr_title",
                    "tr_subtitle": "tiles[0].tilesDeviceTypes[0].tilesDetails[4].tileDetails.titleArray[1].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[0].tilesDeviceTypes[0].tilesDetails[4].tileDetails.titleArray[2].tr_title",
                    "tr_subtitle": "tiles[0].tilesDeviceTypes[0].tilesDetails[4].tileDetails.titleArray[2].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[0].tilesDeviceTypes[0].tilesDetails[4].tileDetails.titleArray[3].tr_title",
                    "tr_subtitle": "tiles[0].tilesDeviceTypes[0].tilesDetails[4].tileDetails.titleArray[3].tr_subtitle"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD",
      "tilesDeviceTypes": [
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[0].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "controlmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/controlmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/controlmore.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "stayinframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/stayinframe-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/stayinframe.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[0].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "lighting",
                    "tileBadgeUrl": "assets/images/standard/redesign/lighting-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/lighting.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/effects-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/effects.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "remix",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[0].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "spentmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spentmore-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/spentmore.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[0].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "smoothstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smooth-streaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smooth-streaming.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "MTL_STD_REDESIGN_ULTRA_7_9",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[1].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/standard/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "aiexperience",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_experience-tile7.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_experience7.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "favouritesongs",
                    "tileBadgeUrl": "assets/images/standard/redesign/remix-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/remix.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "realtime",
                    "tileBadgeUrl": "assets/images/standard/redesign/real-time-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/realtime.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[1].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/standard/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[1].tilesDetails[1].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastestway.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[1].tilesDetails[1].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "effects",
                    "tileBadgeUrl": "assets/images/standard/redesign/pictureperfect-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/pictureperfect.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[1].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[1].tilesDetails[2].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "boostyourproductivity",
                    "tileBadgeUrl": "assets/images/standard/redesign/boost-your-productivity-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/boost-your-productivity.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[1].tilesDetails[2].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "morefreedom",
                    "tileBadgeUrl": "assets/images/standard/redesign/morefreedom-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/morefreedom.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[1].tilesDetails[2].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[1].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[1].tilesDetails[3].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "smoothHDstreaming",
                    "tileBadgeUrl": "assets/images/standard/redesign/smoothHDstreaming-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/smoothHDstreaming.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[1].tilesDetails[3].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "fastimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/fastimmersive-tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/fastimmersive.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[2].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[2].tilesDetails[0].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[2].tilesDetails[0].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "createframe",
                    "tileBadgeUrl": "assets/images/standard/redesign/create_frame_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/create_frame.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[2].tilesDetails[0].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "youtypeit",
                    "tileBadgeUrl": "assets/images/standard/redesign/you_type_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/you_type.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[2].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[2].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[2].tilesDetails[2].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[2].tilesDetails[2].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[2].tilesDetails[2].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[2].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[2].tilesDetails[3].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "LNL_STD_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[3].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "creative",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[3].tilesDetails[0].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "photoediting",
                    "tileBadgeUrl": "assets/images/standard/redesign/photo_editing_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/photo_editing.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[3].tilesDetails[0].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "aishortcut",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_shortcut_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_shortcut.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[3].tilesDetails[0].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "favouritesong",
                    "tileBadgeUrl": "assets/images/standard/redesign/favourite_song_tile.svg",
                    "tileVideoUrl": "assets/lang/[XX]/redesign/favourite_song.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[3].tilesDetails[0].tileDetails.titleArray[3].tr_title",
                    "preTitle": "",
                    "detailPageName": "savehours",
                    "tileBadgeUrl": "assets/images/standard/redesign/save_hours_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/save_hours.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[3].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "performance",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "cheatcode",
                    "tileBadgeUrl": "assets/images/standard/redesign/cheat_code_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/cheat_code.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "spendmore",
                    "tileBadgeUrl": "assets/images/standard/redesign/spend_more_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/spend_more.svg"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "ultraimmersive",
                    "tileBadgeUrl": "assets/images/standard/redesign/ultra_immersive_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/ultra_immersive.svg"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[3].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "productive",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[3].tilesDetails[2].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "aicompanion",
                    "tileBadgeUrl": "assets/images/standard/redesign/ai_companion_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/ai_companion.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[3].tilesDetails[2].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "videocall",
                    "tileBadgeUrl": "assets/images/standard/redesign/video_call_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/video_call.mp4"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[3].tilesDetails[2].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/standard/redesign/your_world_lnl_tile.svg",
                    "tileVideoUrl": "assets/videos/standard/redesign/your_world_lnl.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[3].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "secure",
              "tileBadgeUrl": "",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[3].tilesDetails[3].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "builtinprotection",
                    "tileBadgeUrl": "assets/images/standard/redesign/protection_tile.svg",
                    "tileVideoUrl": "assets/images/standard/redesign/protection.svg"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[4].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "tiles[1].tilesDeviceTypes[4].tilesDetails[0].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray[0].tr_title",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray[1].tr_title",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray[2].tr_title",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray1[0].tr_title",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray1[1].tr_title",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray1[2].tr_title",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[4].tilesDetails[1].tr_tileTitle",
              "tr_tileTitle1": "tiles[1].tilesDeviceTypes[4].tilesDetails[1].tr_tileTitle1",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "tiles[1].tilesDeviceTypes[4].tilesDetails[1].tileDetails.tr_subtitle",
                "tr_subtitle1": "tiles[1].tilesDeviceTypes[4].tilesDetails[1].tileDetails.tr_subtitle1",
                "tr_subtitle2": "tiles[1].tilesDeviceTypes[4].tilesDetails[1].tileDetails.tr_subtitle2",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[1].tileDetails.titleArray[0].tr_title",
                    "tr_title1": "tiles[1].tilesDeviceTypes[4].tilesDetails[1].tileDetails.titleArray[0].tr_title1",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[1].tileDetails.titleArray[1].tr_title",
                    "tr_title1": "tiles[1].tilesDeviceTypes[4].tilesDetails[1].tileDetails.titleArray[1].tr_title1",
                    "tr_title2": "tiles[1].tilesDeviceTypes[4].tilesDetails[1].tileDetails.titleArray[1].tr_title2",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[1].tileDetails.titleArray[2].tr_title",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[1].tileDetails.titleArray[3].tr_title",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[4].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "tiles[1].tilesDeviceTypes[4].tilesDetails[2].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[2].tileDetails.titleArray[0].tr_title",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[2].tileDetails.titleArray[1].tr_title",
                    "tr_title1": "tiles[1].tilesDeviceTypes[4].tilesDetails[2].tileDetails.titleArray[1].tr_title1",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[2].tileDetails.titleArray[2].tr_title",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[1].tilesDeviceTypes[4].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "tiles[1].tilesDeviceTypes[4].tilesDetails[3].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[3].tileDetails.titleArray[0].tr_title",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[3].tileDetails.titleArray[1].tr_title",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "tiles[1].tilesDeviceTypes[4].tilesDetails[3].tileDetails.titleArray[2].tr_title",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "UWP",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[2].tilesDeviceTypes[0].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/standard/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "tiles[2].tilesDeviceTypes[0].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "tiles[2].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[2].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[0].tr_title",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[2].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[1].tr_title",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "tiles[2].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[2].tr_title",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "tiles[2].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray1[0].tr_title",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[2].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray1[1].tr_title",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "tiles[2].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray1[2].tr_title",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[2].tilesDeviceTypes[0].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "memory",
              "tileBadgeUrl": "assets/images/standard/memory-tile.svg"
            },
            {
              "tr_tileTitle": "tiles[2].tilesDeviceTypes[0].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "display",
              "tileBadgeUrl": "assets/images/standard/display-tile.svg"
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[3].tilesDeviceTypes[0].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "tiles[3].tilesDeviceTypes[0].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "tiles[3].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_title",
                "tr_subtitle": "tiles[3].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle",
                "tr_subtitle1": "tiles[3].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle1",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "tiles[3].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "tiles[3].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "tiles[3].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[3].tilesDeviceTypes[0].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "tiles[3].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_title",
                "tr_subtitle": "tiles[3].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle",
                "tr_subtitle1": "tiles[3].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle1",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "tiles[3].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "tiles[3].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "tiles[3].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[3].tilesDeviceTypes[0].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "tiles[3].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_title",
                "tr_subtitle": "tiles[3].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[3].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[0].tr_title",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[3].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[1].tr_title",
                    "tr_title1": "tiles[3].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[1].tr_title1",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[4].tilesDeviceTypes[0].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "tiles[4].tilesDeviceTypes[0].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "tiles[4].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_title",
                "tr_subtitle": "tiles[4].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle",
                "tr_subtitle1": "tiles[4].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle1",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "tiles[4].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "tiles[4].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "tiles[4].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[4].tilesDeviceTypes[0].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "tiles[4].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_title",
                "tr_subtitle": "tiles[4].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle",
                "tr_subtitle1": "tiles[4].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle1",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "tiles[4].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "tiles[4].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "tiles[4].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[4].tilesDeviceTypes[0].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "tiles[4].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_title",
                "tr_subtitle": "tiles[4].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[4].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[0].tr_title",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[4].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[1].tr_title",
                    "tr_title1": "tiles[4].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[1].tr_title1",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "Laptop",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[5].tilesDeviceTypes[0].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "tiles[5].tilesDeviceTypes[0].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "tiles[5].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_title",
                "tr_subtitle": "tiles[5].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle",
                "tr_subtitle1": "tiles[5].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle1",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "tiles[5].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "tiles[5].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "tiles[5].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[5].tilesDeviceTypes[0].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "tiles[5].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_title",
                "tr_subtitle": "tiles[5].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle",
                "tr_subtitle1": "tiles[5].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle1",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "tiles[5].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "tiles[5].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "tiles[5].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[5].tilesDeviceTypes[0].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/gaming/vr-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "tiles[5].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_title",
                "tr_subtitle": "tiles[5].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[5].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[0].tr_title",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[5].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[1].tr_title",
                    "tr_title1": "tiles[5].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[1].tr_title1",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "EVO",
      "tilesDeviceTypes": [
        {
          "deviceType": "11GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.tr_title",
                "tr_subtitle": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray[0].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray[0].tr_subtitle"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray1[0].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray1[0].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray1[1].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray1[1].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray1[2].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray1[2].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray1[3].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray1[3].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray1[4].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray1[4].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray1[5].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray1[5].tr_subtitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.tr_title",
                "tr_subtitle": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray[0].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray[0].tr_subtitle"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray1[0].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray1[0].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray1[1].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray1[1].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray1[2].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray1[2].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray1[3].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray1[3].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray1[4].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray1[4].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray1[5].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[1].tilesDetails[0].tileDetails.titleArray1[5].tr_subtitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "11GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[2].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.tr_title",
                "tr_subtitle": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray[0].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray[0].tr_subtitle"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray1[0].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray1[0].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray1[1].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray1[1].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray1[2].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray1[2].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray1[3].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray1[3].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray1[4].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray1[4].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray1[5].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[2].tilesDetails[1].tileDetails.titleArray1[5].tr_subtitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "12GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[3].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.tr_title",
                "tr_subtitle": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray[0].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray[0].tr_subtitle"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray1[0].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray1[0].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray1[1].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray1[1].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray1[2].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray1[2].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray1[3].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray1[3].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray1[4].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray1[4].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray1[5].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[3].tilesDetails[1].tileDetails.titleArray1[5].tr_subtitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.tr_title",
                "tr_subtitle": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray[0].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray[0].tr_subtitle"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray1[0].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray1[0].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray1[1].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray1[1].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray1[2].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray1[2].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray1[3].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray1[3].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray1[4].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray1[4].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray1[5].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[4].tilesDetails[0].tileDetails.titleArray1[5].tr_subtitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "13GEN_EVO_UWP",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[5].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "evocontent",
              "tileBadgeUrl": "assets/images/evo/evocontent-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "evo-content-tile-panel",
                "tr_title": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.tr_title",
                "tr_subtitle": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.titleArray[0].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.titleArray[0].tr_subtitle"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.titleArray1[0].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.titleArray1[0].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.titleArray1[1].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.titleArray1[1].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.titleArray1[2].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.titleArray1[2].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.titleArray1[3].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.titleArray1[3].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.titleArray1[4].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.titleArray1[4].tr_subtitle"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.titleArray1[5].tr_title",
                    "tr_subtitle": "tiles[6].tilesDeviceTypes[5].tilesDetails[1].tileDetails.titleArray1[5].tr_subtitle"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_5",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[6].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[6].tilesDetails[0].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[6].tilesDetails[0].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[6].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[6].tilesDetails[1].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "morecreative",
                    "tileBadgeUrl": "assets/images/evo/redesign/morecreative-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/morecreative.mp4"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[6].tilesDetails[1].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "photoeditng",
                    "tileBadgeUrl": "assets/images/evo/redesign/photoeditng-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/photoeditng.mp4"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[6].tilesDetails[1].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "originalArt",
                    "tileBadgeUrl": "assets/images/evo/redesign/originalArt-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/originalArt.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[6].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[6].tilesDetails[2].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[6].tilesDetails[2].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[6].tilesDetails[2].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[6].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[6].tilesDetails[3].tileDetails.titleArray[0].tr_title",
                    "preTitle": "gesturecontrol",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/gesturecontrol-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/gesturecontrol.mp4"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[6].tilesDetails[3].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[6].tilesDetails[3].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        },
        {
          "deviceType": "EVO_REDESIGN_ULTRA_7",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[7].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "spotlight",
              "tileBadgeUrl": "assets/images/evo/redesign/spotlight.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[7].tilesDetails[0].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "whatIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whatIntelEvo-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whatIntelEvo.mp4"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[7].tilesDetails[0].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "whyIntelEvo",
                    "tileBadgeUrl": "assets/images/evo/redesign/whyIntelEvo7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/whyIntelEvo7.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[7].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "creativity",
              "tileBadgeUrl": "assets/images/evo/redesign/creativity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[7].tilesDetails[1].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "videoplayback",
                    "tileBadgeUrl": "assets/images/evo/redesign/videoplayback-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/videoplayback.mp4"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[7].tilesDetails[1].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "powerAI",
                    "tileBadgeUrl": "assets/images/evo/redesign/power-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/power-ai.mp4"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[7].tilesDetails[1].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "fastestway",
                    "tileBadgeUrl": "assets/images/evo/redesign/fastestway-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/fastestway.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[7].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/evo/redesign/productivity.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[7].tilesDetails[2].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "rewind-ai",
                    "tileBadgeUrl": "assets/images/evo/redesign/rewind-ai-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/rewind-ai.mp4"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[7].tilesDetails[2].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "deepfakes",
                    "tileBadgeUrl": "assets/images/evo/redesign/deepfakes-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/deepfakes.mp4"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[7].tilesDetails[2].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "yourworld",
                    "tileBadgeUrl": "assets/images/evo/redesign/yourworld-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/yourworld.mp4"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[6].tilesDeviceTypes[7].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "mobility",
              "tileBadgeUrl": "assets/images/evo/redesign/entertainment.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "",
                "titleArray": [
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[7].tilesDetails[3].tileDetails.titleArray[0].tr_title",
                    "preTitle": "",
                    "detailPageName": "virtuallyanywhere",
                    "tileBadgeUrl": "assets/images/evo/redesign/virtuallyanywhere7-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/virtuallyanywhere7.mp4"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[7].tilesDetails[3].tileDetails.titleArray[1].tr_title",
                    "preTitle": "",
                    "detailPageName": "batteryLife-entertainment",
                    "tileBadgeUrl": "assets/images/evo/redesign/batteryLife-entertainment-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/batteryLife-entertainment.mp4"
                  },
                  {
                    "tr_title": "tiles[6].tilesDeviceTypes[7].tilesDetails[3].tileDetails.titleArray[2].tr_title",
                    "preTitle": "",
                    "detailPageName": "automaticallyConnected",
                    "tileBadgeUrl": "assets/images/evo/redesign/automaticallyConnected-tile.svg",
                    "tileVideoUrl": "assets/videos/evo/redesign/automaticallyConnected.mp4"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_11",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[7].tilesDeviceTypes[0].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "entertainment",
              "tileBadgeUrl": "assets/images/standard/entertainment-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "tiles[7].tilesDeviceTypes[0].tilesDetails[0].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray[0].tr_title",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray[1].tr_title",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray[2].tr_title",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ],
                "titleArray1": [
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray1[0].tr_title",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray1[1].tr_title",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray1[2].tr_title",
                    "preTitle": "[display_value]",
                    "detailPageName": "display"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[7].tilesDeviceTypes[0].tilesDetails[1].tr_tileTitle",
              "tr_tileTitle1": "tiles[7].tilesDeviceTypes[0].tilesDetails[1].tr_tileTitle1",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "tiles[7].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle",
                "tr_subtitle1": "tiles[7].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle1",
                "tr_subtitle2": "tiles[7].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle2",
                "titleArray": [
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[0].tr_title",
                    "tr_title1": "tiles[7].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[0].tr_title1",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[1].tr_title",
                    "tr_title1": "tiles[7].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[1].tr_title1",
                    "tr_title2": "tiles[7].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[1].tr_title2",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[2].tr_title",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[3].tr_title",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[7].tilesDeviceTypes[0].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/standard/content-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "tiles[7].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[0].tr_title",
                    "tr_title1": "tiles[7].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[0].tr_title1",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[1].tr_title",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[2].tr_title",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[7].tilesDeviceTypes[0].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "lookinside",
              "tileBadgeUrl": "assets/images/standard/lookinside-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "tiles[7].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[0].tr_title",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[1].tr_title",
                    "preTitle": "[storage_value]",
                    "detailPageName": "storage"
                  },
                  {
                    "tr_title": "tiles[7].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[2].tr_title",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "CREATOR_12",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "tiles[8].tilesDeviceTypes[0].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "processor",
              "tileBadgeUrl": "assets/images/creator/[processor_badge].svg"
            },
            {
              "tr_tileTitle": "tiles[8].tilesDeviceTypes[0].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "content",
              "tileBadgeUrl": "assets/images/creator/freedom_new.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "tiles[8].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[8].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[0].tr_title",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[8].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[1].tr_title",
                    "tr_title1": "tiles[8].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[1].tr_title1",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  },
                  {
                    "tr_title": "tiles[8].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[2].tr_title",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[8].tilesDeviceTypes[0].tilesDetails[2].tr_tileTitle",
              "tr_tileTitle1": "tiles[8].tilesDeviceTypes[0].tilesDetails[2].tr_tileTitle1",
              "tileUrlName": "productivity",
              "tileBadgeUrl": "assets/images/standard/productivity-tile.svg",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "",
                "tr_subtitle": "tiles[8].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle",
                "tr_subtitle1": "tiles[8].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle1",
                "tr_subtitle2": "tiles[8].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle2",
                "titleArray": [
                  {
                    "tr_title": "tiles[8].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[0].tr_title",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[8].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[1].tr_title",
                    "tr_title1": "tiles[8].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[1].tr_title1",
                    "tr_title2": "tiles[8].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[1].tr_title2",
                    "preTitle": "[wifi_value]",
                    "detailPageName": "wifi"
                  },
                  {
                    "tr_title": "tiles[8].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[2].tr_title",
                    "preTitle": "[battery_value]",
                    "detailPageName": "battery"
                  },
                  {
                    "tr_title": "tiles[8].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[3].tr_title",
                    "preTitle": "[memory_value]",
                    "detailPageName": "memory"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "tiles[8].tilesDeviceTypes[0].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "vr",
              "tileBadgeUrl": "assets/images/creator/new_vr_gen12_creator.png",
              "tileDetails": {
                "panelType1": "gaming-tile-panel",
                "panelType2": "gaming-tile-panel2",
                "tr_title": "tiles[8].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_title",
                "tr_subtitle": "tiles[8].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_subtitle",
                "titleArray": [
                  {
                    "tr_title": "tiles[8].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[0].tr_title",
                    "preTitle": "[processor_value]",
                    "detailPageName": "processor"
                  },
                  {
                    "tr_title": "tiles[8].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[1].tr_title",
                    "tr_title1": "tiles[8].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[1].tr_title1",
                    "preTitle": "[graphics_value]",
                    "detailPageName": "graphics"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "samsungOled": [
    {
      "panelType": "oled_first_panel",
      "badgeUrl": "assets/images/oled/OLED_logo.svg"
    },
    {
      "panelType": "oled_second_panel",
      "tr_header": "samsungOled[1].tr_header",
      "tr_sub_header": "samsungOled[1].tr_sub_header",
      "badgeUrl": "assets/images/oled/oled-graph.svg",
      "tr_title1": "samsungOled[1].tr_title1"
    },
    {
      "panelType": "oled_third_panel",
      "tr_header": "samsungOled[2].tr_header",
      "tr_sub_header": "samsungOled[2].tr_sub_header"
    },
    {
      "panelType": "oled_fourth_panel",
      "tr_header": "samsungOled[3].tr_header",
      "tr_sub_header": "samsungOled[3].tr_sub_header"
    },
    {
      "panelType": "oled_fifth_panel",
      "tr_header": "samsungOled[4].tr_header",
      "tr_sub_header": "samsungOled[4].tr_sub_header"
    }
  ],
  "rebuildUsageModel": [
    {
      "tilesType": "STANDARD_REBUILD_I3",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "streaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/streaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_streaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_title",
                "tr_subtitle": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle",
                "tr_subtitle1": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle1",
                "tileBG": "#794584",
                "titleArray": [
                  {
                    "tr_title": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "webbrowsing",
              "tileBadgeUrl": "assets/images/standard/rebuild/webbrowsing.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_webbrowsing.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_title",
                "tr_subtitle": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle",
                "tr_subtitle1": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle1",
                "tileBG": "#87A944",
                "titleArray": [
                  {
                    "tr_title": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[1].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_title",
                "tr_subtitle": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_subtitle",
                "tr_subtitle1": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_subtitle1",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[0].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I5",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[0].tileDetails.tr_title",
                "tr_subtitle": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[0].tileDetails.tr_subtitle",
                "tr_subtitle1": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[0].tileDetails.tr_subtitle1",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "videochatting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videochatting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videochatting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_title",
                "tr_subtitle": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle",
                "tr_subtitle1": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle1",
                "tileBG": "#E96115",
                "titleArray": [
                  {
                    "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "casualgaming",
              "tileBadgeUrl": "assets/images/standard/rebuild/casualgaming.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_casualgaming.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_title",
                "tr_subtitle": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle",
                "tr_subtitle1": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle1",
                "tileBG": "#000000",
                "titleArray": [
                  {
                    "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_title",
                "tr_subtitle": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_subtitle",
                "tr_subtitle1": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_subtitle1",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[1].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I7",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "highspeed",
              "tileBadgeUrl": "assets/images/standard/rebuild/highspeed.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_highspeed.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[0].tileDetails.tr_title",
                "tr_subtitle": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[0].tileDetails.tr_subtitle",
                "tr_subtitle1": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[0].tileDetails.tr_subtitle1",
                "tileBG": "#0093C7",
                "titleArray": [
                  {
                    "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[0].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_title",
                "tr_subtitle": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle",
                "tr_subtitle1": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle1",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_title",
                "tr_subtitle": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle",
                "tr_subtitle1": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle1",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "photoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/photoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_photoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_title",
                "tr_subtitle": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_subtitle",
                "tr_subtitle1": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_subtitle1",
                "tileBG": "#659FBA",
                "titleArray": [
                  {
                    "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[2].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      "tilesType": "STANDARD_REBUILD_I9",
      "tilesDeviceTypes": [
        {
          "deviceType": "DEFAULT",
          "tilesDetails": [
            {
              "tr_tileTitle": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[0].tr_tileTitle",
              "tileUrlName": "processor",
              "tileBadgeUrl": "[processor_badge]"
            },
            {
              "tr_tileTitle": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[1].tr_tileTitle",
              "tileUrlName": "videoediting",
              "tileBadgeUrl": "assets/images/standard/rebuild/videoediting.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_videoediting.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_title",
                "tr_subtitle": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle",
                "tr_subtitle1": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[1].tileDetails.tr_subtitle1",
                "tileBG": "#00376D",
                "titleArray": [
                  {
                    "tr_title": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[1].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[2].tr_tileTitle",
              "tileUrlName": "threedmodeling",
              "tileBadgeUrl": "assets/images/standard/rebuild/threedmodeling.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_threedmodeling.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_title",
                "tr_subtitle": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle",
                "tr_subtitle1": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[2].tileDetails.tr_subtitle1",
                "tileBG": "#00285A",
                "titleArray": [
                  {
                    "tr_title": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[2].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            },
            {
              "tr_tileTitle": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[3].tr_tileTitle",
              "tileUrlName": "realtime",
              "tileBadgeUrl": "assets/images/standard/rebuild/realtime.svg",
              "tileBadgeUrl1": "assets/images/standard/rebuild/detailpage_realtime.svg",
              "tileDetails": {
                "panelType1": "rebuild-usage-panel",
                "tr_title": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_title",
                "tr_subtitle": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_subtitle",
                "tr_subtitle1": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[3].tileDetails.tr_subtitle1",
                "tileBG": "#D42931",
                "titleArray": [
                  {
                    "tr_title": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[0].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[1].tr_title"
                  },
                  {
                    "tr_title": "rebuildUsageModel[3].tilesDeviceTypes[0].tilesDetails[3].tileDetails.titleArray[2].tr_title"
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ],
  "unison": {
    "panelType1": "gaming-tile-panel",
    "panelType2": "unison-panel",
    "tr_tileTitle": "unison.tr_tileTitle",
    "tr_title": "unison.tr_title",
    "tr_subtitle": "unison.tr_subtitle",
    "detailBGUrl": "assets/images/unison/unison_panel1_img.png",
    "tileBG": "assets/images/unison/unison_hometile_img.png",
    "titleArray": [
      {
        "tr_title": "unison.titleArray[0].tr_title",
        "imgUrl": "assets/images/unison/unison_img1.png"
      },
      {
        "tr_title": "unison.titleArray[1].tr_title",
        "imgUrl": "assets/images/unison/unison_img2.png"
      },
      {
        "tr_title": "unison.titleArray[2].tr_title",
        "imgUrl": "assets/images/unison/unison_img3.png"
      },
      {
        "tr_title": "unison.titleArray[3].tr_title",
        "imgUrl": "assets/images/unison/unison_img4.png"
      },
      {
        "tr_title": "unison.titleArray[4].tr_title",
        "imgUrl": "assets/images/unison/unison_img5.png"
      }
    ]
  }
}